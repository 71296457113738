const departments = [
    {
      id: "000e8975d97b4e80ef00a955",
      display: "Women",
      type: "d",
      message_id: "women",
      aliases: [],
      size_sets: [],
      sizes: [],
      slug: "Women",
      alias_slugs: [],
      parent_id: "55b966335632a0146f000001",
      categories: [
        {
          id: "00248975d97b4e80ef00a955",
          aliases: ["Clutches & Wallets", "Handbags"],
          display: "Bags",
          size_sets: [
            {
              id: "1400579fd97b4e26a60056e3",
              name: "Standard",
              tags: ["standard"],
              category_id: "00248975d97b4e80ef00a955",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "women_bags",
          slug: "Bags",
          alias_slugs: ["Clutches_&_Wallets", "Handbags"],
          parent_id: "000e8975d97b4e80ef00a955",
          category_features: [
            {
              id: "00d89287d97b4e80ef00a955",
              display: "Baby Bags",
              type: "cf",
              message_id: "women_bags_baby_bags",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Baby_Bags",
              alias_slugs: [],
              parent_id: "00248975d97b4e80ef00a955",
            },
            {
              id: "00da9287d97b4e80ef00a955",
              display: "Backpacks",
              type: "cf",
              message_id: "women_bags_backpacks",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Backpacks",
              alias_slugs: [],
              parent_id: "00248975d97b4e80ef00a955",
            },
            {
              id: "00ee9287d97b4e80ef00a955",
              display: "Clutches & Wristlets",
              type: "cf",
              message_id: "women_bags_clutches_wristlets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Clutches_&_Wristlets",
              alias_slugs: [],
              parent_id: "00248975d97b4e80ef00a955",
            },
            {
              id: "00dc9287d97b4e80ef00a955",
              display: "Cosmetic Bags & Cases",
              type: "cf",
              message_id: "women_bags_cosmetic_bags_cases",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cosmetic_Bags_&_Cases",
              alias_slugs: [],
              parent_id: "00248975d97b4e80ef00a955",
            },
            {
              id: "00de9287d97b4e80ef00a955",
              display: "Crossbody Bags",
              type: "cf",
              message_id: "women_bags_crossbody_bags",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Crossbody_Bags",
              alias_slugs: [],
              parent_id: "00248975d97b4e80ef00a955",
            },
            {
              id: "00e09287d97b4e80ef00a955",
              display: "Hobos",
              type: "cf",
              message_id: "women_bags_hobos",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Hobos",
              alias_slugs: [],
              parent_id: "00248975d97b4e80ef00a955",
            },
            {
              id: "00e29287d97b4e80ef00a955",
              display: "Laptop Bags",
              type: "cf",
              message_id: "women_bags_laptop_bags",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Laptop_Bags",
              alias_slugs: [],
              parent_id: "00248975d97b4e80ef00a955",
            },
            {
              id: "00e49287d97b4e80ef00a955",
              display: "Mini Bags",
              type: "cf",
              message_id: "women_bags_mini_bags",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Mini_Bags",
              alias_slugs: [],
              parent_id: "00248975d97b4e80ef00a955",
            },
            {
              id: "00e69287d97b4e80ef00a955",
              display: "Satchels",
              type: "cf",
              message_id: "women_bags_satchels",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Satchels",
              alias_slugs: [],
              parent_id: "00248975d97b4e80ef00a955",
            },
            {
              id: "00e89287d97b4e80ef00a955",
              display: "Shoulder Bags",
              type: "cf",
              message_id: "women_bags_shoulder_bags",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Shoulder_Bags",
              alias_slugs: [],
              parent_id: "00248975d97b4e80ef00a955",
            },
            {
              id: "00ea9287d97b4e80ef00a955",
              display: "Totes",
              type: "cf",
              message_id: "women_bags_totes",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Totes",
              alias_slugs: [],
              parent_id: "00248975d97b4e80ef00a955",
            },
            {
              id: "00ec9287d97b4e80ef00a955",
              display: "Travel Bags",
              type: "cf",
              message_id: "women_bags_travel_bags",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Travel_Bags",
              alias_slugs: [],
              parent_id: "00248975d97b4e80ef00a955",
            },
            {
              id: "00f09287d97b4e80ef00a955",
              display: "Wallets",
              type: "cf",
              message_id: "women_bags_wallets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Wallets",
              alias_slugs: [],
              parent_id: "00248975d97b4e80ef00a955",
            },
          ],
        },
        {
          id: "00268975d97b4e80ef00a955",
          aliases: ["Boots"],
          display: "Shoes",
          size_sets: [
            {
              id: "1500579fd97b4e26a60056e3",
              name: "Standard",
              tags: ["standard"],
              category_id: "00268975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["in."],
                  ["8.7"],
                  ["8.9"],
                  ["9.1"],
                  ["9.2"],
                  ["9.3"],
                  ["9.4"],
                  ["9.6"],
                  ["9.8"],
                  ["10"],
                  ["10.2"],
                  ["10.4"],
                  ["10.6"],
                  ["10.8"],
                  ["11"],
                  ["11.2"],
                  ["11.3"],
                  ["11.5"],
                ],
                centimeters: [
                  ["cm."],
                  ["22.1"],
                  ["22.6"],
                  ["23.1"],
                  ["23.1"],
                  ["23.6"],
                  ["23.9"],
                  ["24.4"],
                  ["24.9"],
                  ["25.4"],
                  ["25.9"],
                  ["26.4"],
                  ["26.9"],
                  ["27.4"],
                  ["27.9"],
                  ["28.4"],
                  ["28.7"],
                  ["29.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["5", "4.5,5", "34.5,35", "3"],
                  ["5.5,6", "5.5,6", "35.5,36", "3.5"],
                  ["5.5,6", "5.5,6", "35.5,36", "4"],
                  ["6.5,7", "6.5,7", "36.5,37", "4.5"],
                  ["6.5,7", "6.5,7", "36.5,37", "5"],
                  ["7.5,8", "7.5", "8", "5.5"],
                  ["7.5,8", "7.5,8", "37.5,38", "6"],
                  ["8.5,9", "8.5,9", "38.5,39", "6.5"],
                  ["8.5,9", "8.5,9", "38.5,39", "7"],
                  ["9.5,10", "9.5,10", "39.5,40", "7.5"],
                  ["9.5,10", "9.5,10", "39.5,40", "8"],
                  ["10.5,11", "10.5,11", "40.5,41", "8.5"],
                  ["10.5,11", "10.5,11", "40.5,41", "9"],
                  ["11.5,12", "11.5,12", "41.5,42", "9.5"],
                  ["11.5,12", "11.5,12", "41.5,42", "10"],
                  ["12.5,13", "12.5,13", "42.5,43", "10.5"],
                  ["12.5,13", "12.5,13", "42.5,43", "11"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["5", "4.5,5", "34.5,35", "3"],
                ["5.5", "5.5,6", "35.5,36", "3.5,4"],
                ["6", "5.5,6", "35.5,36", "3.5,4"],
                ["6.5", "6.5,7", "36.5,37", "4.5,5"],
                ["7", "6.5,7", "36.5,37", "4.5,5"],
                ["7.5", "7.5,8", "37.5,38", "5.5,6"],
                ["8", "7.5,8", "37.5,38", "5.5,6"],
                ["8.5", "8.5,9", "38.5,39", "6.5,7"],
                ["9", "8.5,9", "38.5,39", "6.5,7"],
                ["9.5", "9.5,10", "39.5,40", "7.5,8"],
                ["10", "9.5,10", "39.5,40", "7.5,8"],
                ["10.5", "10.5,11", "40.5,41", "8.5,9"],
                ["11", "10.5,11", "40.5,41", "8.5,9"],
                ["11.5", "11.5,12", "41.5,42", "9.5,10"],
                ["12", "11.5,12", "41.5,42", "9.5,10"],
                ["12.5", "12.5,13", "42.5,43", "10.5,11"],
                ["13", "12.5,13", "42.5,43", "10.5,11"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "5",
                      short: "5",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5",
                      equivalent_sizes: {
                        au: ["4.5AU", "5AU"],
                        eu: ["34.5EU", "35EU"],
                        uk: ["3UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5.5",
                      short: "5.5",
                      long: "5.5",
                      display: "5.5",
                      display_with_size_set: "5.5",
                      display_with_size_system: "US 5.5",
                      display_with_system_and_set: "US 5.5",
                      equivalent_sizes: {
                        au: ["5.5AU", "6AU"],
                        eu: ["35.5EU", "36EU"],
                        uk: ["3.5UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["5.5AU", "6AU"],
                        eu: ["35.5EU", "36EU"],
                        uk: ["3.5UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6.5",
                      short: "6.5",
                      long: "6.5",
                      display: "6.5",
                      display_with_size_set: "6.5",
                      display_with_size_system: "US 6.5",
                      display_with_system_and_set: "US 6.5",
                      equivalent_sizes: {
                        au: ["6.5AU", "7AU"],
                        eu: ["36.5EU", "37EU"],
                        uk: ["4.5UK", "5UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7",
                      short: "7",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7",
                      equivalent_sizes: {
                        au: ["6.5AU", "7AU"],
                        eu: ["36.5EU", "37EU"],
                        uk: ["4.5UK", "5UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7.5",
                      short: "7.5",
                      long: "7.5",
                      display: "7.5",
                      display_with_size_set: "7.5",
                      display_with_size_system: "US 7.5",
                      display_with_system_and_set: "US 7.5",
                      equivalent_sizes: {
                        au: ["7.5AU", "8AU"],
                        eu: ["37.5EU", "38EU"],
                        uk: ["5.5UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["7.5AU", "8AU"],
                        eu: ["37.5EU", "38EU"],
                        uk: ["5.5UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8.5",
                      short: "8.5",
                      long: "8.5",
                      display: "8.5",
                      display_with_size_set: "8.5",
                      display_with_size_system: "US 8.5",
                      display_with_system_and_set: "US 8.5",
                      equivalent_sizes: {
                        au: ["8.5AU", "9AU"],
                        eu: ["38.5EU", "39EU"],
                        uk: ["6.5UK", "7UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9",
                      short: "9",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9",
                      equivalent_sizes: {
                        au: ["8.5AU", "9AU"],
                        eu: ["38.5EU", "39EU"],
                        uk: ["6.5UK", "7UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9.5",
                      short: "9.5",
                      long: "9.5",
                      display: "9.5",
                      display_with_size_set: "9.5",
                      display_with_size_system: "US 9.5",
                      display_with_system_and_set: "US 9.5",
                      equivalent_sizes: {
                        au: ["9.5AU", "10AU"],
                        eu: ["39.5EU", "40EU"],
                        uk: ["7.5UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["9.5AU", "10AU"],
                        eu: ["39.5EU", "40EU"],
                        uk: ["7.5UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10.5",
                      short: "10.5",
                      long: "10.5",
                      display: "10.5",
                      display_with_size_set: "10.5",
                      display_with_size_system: "US 10.5",
                      display_with_system_and_set: "US 10.5",
                      equivalent_sizes: {
                        au: ["10.5AU", "11AU"],
                        eu: ["40.5EU", "41EU"],
                        uk: ["8.5UK", "9UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11",
                      short: "11",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11",
                      equivalent_sizes: {
                        au: ["10.5AU", "11AU"],
                        eu: ["40.5EU", "41EU"],
                        uk: ["8.5UK", "9UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11.5",
                      short: "11.5",
                      long: "11.5",
                      display: "11.5",
                      display_with_size_set: "11.5",
                      display_with_size_system: "US 11.5",
                      display_with_system_and_set: "US 11.5",
                      equivalent_sizes: {
                        au: ["11.5AU", "12AU"],
                        eu: ["41.5EU", "42EU"],
                        uk: ["9.5UK", "10UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["11.5AU", "12AU"],
                        eu: ["41.5EU", "42EU"],
                        uk: ["9.5UK", "10UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12.5",
                      short: "12.5",
                      long: "12.5",
                      display: "12.5",
                      display_with_size_set: "12.5",
                      display_with_size_system: "US 12.5",
                      display_with_system_and_set: "US 12.5",
                      equivalent_sizes: {
                        au: ["12.5AU", "13AU"],
                        eu: ["42.5EU", "43EU"],
                        uk: ["10.5UK", "11UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13",
                      short: "13",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13",
                      equivalent_sizes: {
                        au: ["12.5AU", "13AU"],
                        eu: ["42.5EU", "43EU"],
                        uk: ["10.5UK", "11UK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4.5AU",
                      short: "4.5",
                      long: "4.5",
                      display: "4.5",
                      display_with_size_set: "4.5",
                      display_with_size_system: "AU 4.5",
                      display_with_system_and_set: "AU 4.5",
                      equivalent_sizes: {
                        us: ["5"],
                        eu: ["34.5EU", "35EU"],
                        uk: ["3UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5AU",
                      short: "5",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5",
                      display_with_size_system: "AU 5",
                      display_with_system_and_set: "AU 5",
                      equivalent_sizes: {
                        us: ["5"],
                        eu: ["34.5EU", "35EU"],
                        uk: ["3UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5.5AU",
                      short: "5.5",
                      long: "5.5",
                      display: "5.5",
                      display_with_size_set: "5.5",
                      display_with_size_system: "AU 5.5",
                      display_with_system_and_set: "AU 5.5",
                      equivalent_sizes: {
                        us: ["5.5", "6"],
                        eu: ["35.5EU", "36EU"],
                        uk: ["3.5UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["5.5", "6"],
                        eu: ["35.5EU", "36EU"],
                        uk: ["3.5UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6.5AU",
                      short: "6.5",
                      long: "6.5",
                      display: "6.5",
                      display_with_size_set: "6.5",
                      display_with_size_system: "AU 6.5",
                      display_with_system_and_set: "AU 6.5",
                      equivalent_sizes: {
                        us: ["6.5", "7"],
                        eu: ["36.5EU", "37EU"],
                        uk: ["4.5UK", "5UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "7AU",
                      short: "7",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7",
                      display_with_size_system: "AU 7",
                      display_with_system_and_set: "AU 7",
                      equivalent_sizes: {
                        us: ["6.5", "7"],
                        eu: ["36.5EU", "37EU"],
                        uk: ["4.5UK", "5UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "7.5AU",
                      short: "7.5",
                      long: "7.5",
                      display: "7.5",
                      display_with_size_set: "7.5",
                      display_with_size_system: "AU 7.5",
                      display_with_system_and_set: "AU 7.5",
                      equivalent_sizes: {
                        us: ["7.5", "8"],
                        eu: ["37.5EU", "38EU"],
                        uk: ["5.5UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["7.5", "8"],
                        eu: ["37.5EU", "38EU"],
                        uk: ["5.5UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8.5AU",
                      short: "8.5",
                      long: "8.5",
                      display: "8.5",
                      display_with_size_set: "8.5",
                      display_with_size_system: "AU 8.5",
                      display_with_system_and_set: "AU 8.5",
                      equivalent_sizes: {
                        us: ["8.5", "9"],
                        eu: ["38.5EU", "39EU"],
                        uk: ["6.5UK", "7UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "9AU",
                      short: "9",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9",
                      display_with_size_system: "AU 9",
                      display_with_system_and_set: "AU 9",
                      equivalent_sizes: {
                        us: ["8.5", "9"],
                        eu: ["38.5EU", "39EU"],
                        uk: ["6.5UK", "7UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "9.5AU",
                      short: "9.5",
                      long: "9.5",
                      display: "9.5",
                      display_with_size_set: "9.5",
                      display_with_size_system: "AU 9.5",
                      display_with_system_and_set: "AU 9.5",
                      equivalent_sizes: {
                        us: ["9.5", "10"],
                        eu: ["39.5EU", "40EU"],
                        uk: ["7.5UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["9.5", "10"],
                        eu: ["39.5EU", "40EU"],
                        uk: ["7.5UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10.5AU",
                      short: "10.5",
                      long: "10.5",
                      display: "10.5",
                      display_with_size_set: "10.5",
                      display_with_size_system: "AU 10.5",
                      display_with_system_and_set: "AU 10.5",
                      equivalent_sizes: {
                        us: ["10.5", "11"],
                        eu: ["40.5EU", "41EU"],
                        uk: ["8.5UK", "9UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11AU",
                      short: "11",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11",
                      equivalent_sizes: {
                        us: ["10.5", "11"],
                        eu: ["40.5EU", "41EU"],
                        uk: ["8.5UK", "9UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11.5AU",
                      short: "11.5",
                      long: "11.5",
                      display: "11.5",
                      display_with_size_set: "11.5",
                      display_with_size_system: "AU 11.5",
                      display_with_system_and_set: "AU 11.5",
                      equivalent_sizes: {
                        us: ["11.5", "12"],
                        eu: ["41.5EU", "42EU"],
                        uk: ["9.5UK", "10UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["11.5", "12"],
                        eu: ["41.5EU", "42EU"],
                        uk: ["9.5UK", "10UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12.5AU",
                      short: "12.5",
                      long: "12.5",
                      display: "12.5",
                      display_with_size_set: "12.5",
                      display_with_size_system: "AU 12.5",
                      display_with_system_and_set: "AU 12.5",
                      equivalent_sizes: {
                        us: ["12.5", "13"],
                        eu: ["42.5EU", "43EU"],
                        uk: ["10.5UK", "11UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "13AU",
                      short: "13",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13",
                      display_with_size_system: "AU 13",
                      display_with_system_and_set: "AU 13",
                      equivalent_sizes: {
                        us: ["12.5", "13"],
                        eu: ["42.5EU", "43EU"],
                        uk: ["10.5UK", "11UK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "34.5EU",
                      short: "34.5",
                      long: "34.5",
                      display: "34.5",
                      display_with_size_set: "34.5",
                      display_with_size_system: "EU 34.5",
                      display_with_system_and_set: "EU 34.5",
                      equivalent_sizes: {
                        us: ["5"],
                        au: ["4.5AU", "5AU"],
                        uk: ["3UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "35EU",
                      short: "35",
                      long: "35",
                      display: "35",
                      display_with_size_set: "35",
                      display_with_size_system: "EU 35",
                      display_with_system_and_set: "EU 35",
                      equivalent_sizes: {
                        us: ["5"],
                        au: ["4.5AU", "5AU"],
                        uk: ["3UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "35.5EU",
                      short: "35.5",
                      long: "35.5",
                      display: "35.5",
                      display_with_size_set: "35.5",
                      display_with_size_system: "EU 35.5",
                      display_with_system_and_set: "EU 35.5",
                      equivalent_sizes: {
                        us: ["5.5", "6"],
                        au: ["5.5AU", "6AU"],
                        uk: ["3.5UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["5.5", "6"],
                        au: ["5.5AU", "6AU"],
                        uk: ["3.5UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36.5EU",
                      short: "36.5",
                      long: "36.5",
                      display: "36.5",
                      display_with_size_set: "36.5",
                      display_with_size_system: "EU 36.5",
                      display_with_system_and_set: "EU 36.5",
                      equivalent_sizes: {
                        us: ["6.5", "7"],
                        au: ["6.5AU", "7AU"],
                        uk: ["4.5UK", "5UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "37EU",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "EU 37",
                      display_with_system_and_set: "EU 37",
                      equivalent_sizes: {
                        us: ["6.5", "7"],
                        au: ["6.5AU", "7AU"],
                        uk: ["4.5UK", "5UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "37.5EU",
                      short: "37.5",
                      long: "37.5",
                      display: "37.5",
                      display_with_size_set: "37.5",
                      display_with_size_system: "EU 37.5",
                      display_with_system_and_set: "EU 37.5",
                      equivalent_sizes: {
                        us: ["7.5", "8"],
                        au: ["7.5AU", "8AU"],
                        uk: ["5.5UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["7.5", "8"],
                        au: ["7.5AU", "8AU"],
                        uk: ["5.5UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38.5EU",
                      short: "38.5",
                      long: "38.5",
                      display: "38.5",
                      display_with_size_set: "38.5",
                      display_with_size_system: "EU 38.5",
                      display_with_system_and_set: "EU 38.5",
                      equivalent_sizes: {
                        us: ["8.5", "9"],
                        au: ["8.5AU", "9AU"],
                        uk: ["6.5UK", "7UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "39EU",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "EU 39",
                      display_with_system_and_set: "EU 39",
                      equivalent_sizes: {
                        us: ["8.5", "9"],
                        au: ["8.5AU", "9AU"],
                        uk: ["6.5UK", "7UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "39.5EU",
                      short: "39.5",
                      long: "39.5",
                      display: "39.5",
                      display_with_size_set: "39.5",
                      display_with_size_system: "EU 39.5",
                      display_with_system_and_set: "EU 39.5",
                      equivalent_sizes: {
                        us: ["9.5", "10"],
                        au: ["9.5AU", "10AU"],
                        uk: ["7.5UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["9.5", "10"],
                        au: ["9.5AU", "10AU"],
                        uk: ["7.5UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40.5EU",
                      short: "40.5",
                      long: "40.5",
                      display: "40.5",
                      display_with_size_set: "40.5",
                      display_with_size_system: "EU 40.5",
                      display_with_system_and_set: "EU 40.5",
                      equivalent_sizes: {
                        us: ["10.5", "11"],
                        au: ["10.5AU", "11AU"],
                        uk: ["8.5UK", "9UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "41EU",
                      short: "41",
                      long: "41",
                      display: "41",
                      display_with_size_set: "41",
                      display_with_size_system: "EU 41",
                      display_with_system_and_set: "EU 41",
                      equivalent_sizes: {
                        us: ["10.5", "11"],
                        au: ["10.5AU", "11AU"],
                        uk: ["8.5UK", "9UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "41.5EU",
                      short: "41.5",
                      long: "41.5",
                      display: "41.5",
                      display_with_size_set: "41.5",
                      display_with_size_system: "EU 41.5",
                      display_with_system_and_set: "EU 41.5",
                      equivalent_sizes: {
                        us: ["11.5", "12"],
                        au: ["11.5AU", "12AU"],
                        uk: ["9.5UK", "10UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["11.5", "12"],
                        au: ["11.5AU", "12AU"],
                        uk: ["9.5UK", "10UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42.5EU",
                      short: "42.5",
                      long: "42.5",
                      display: "42.5",
                      display_with_size_set: "42.5",
                      display_with_size_system: "EU 42.5",
                      display_with_system_and_set: "EU 42.5",
                      equivalent_sizes: {
                        us: ["12.5", "13"],
                        au: ["12.5AU", "13AU"],
                        uk: ["10.5UK", "11UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "43EU",
                      short: "43",
                      long: "43",
                      display: "43",
                      display_with_size_set: "43",
                      display_with_size_system: "EU 43",
                      display_with_system_and_set: "EU 43",
                      equivalent_sizes: {
                        us: ["12.5", "13"],
                        au: ["12.5AU", "13AU"],
                        uk: ["10.5UK", "11UK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "3UK",
                      short: "3",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3",
                      equivalent_sizes: {
                        us: ["5"],
                        au: ["4.5AU", "5AU"],
                        eu: ["34.5EU", "35EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3.5UK",
                      short: "3.5",
                      long: "3.5",
                      display: "3.5",
                      display_with_size_set: "3.5",
                      display_with_size_system: "UK 3.5",
                      display_with_system_and_set: "UK 3.5",
                      equivalent_sizes: {
                        us: ["5.5", "6"],
                        au: ["5.5AU", "6AU"],
                        eu: ["35.5EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["5.5", "6"],
                        au: ["5.5AU", "6AU"],
                        eu: ["35.5EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4.5UK",
                      short: "4.5",
                      long: "4.5",
                      display: "4.5",
                      display_with_size_set: "4.5",
                      display_with_size_system: "UK 4.5",
                      display_with_system_and_set: "UK 4.5",
                      equivalent_sizes: {
                        us: ["6.5", "7"],
                        au: ["6.5AU", "7AU"],
                        eu: ["36.5EU", "37EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5UK",
                      short: "5",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5",
                      display_with_size_system: "UK 5",
                      display_with_system_and_set: "UK 5",
                      equivalent_sizes: {
                        us: ["6.5", "7"],
                        au: ["6.5AU", "7AU"],
                        eu: ["36.5EU", "37EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5.5UK",
                      short: "5.5",
                      long: "5.5",
                      display: "5.5",
                      display_with_size_set: "5.5",
                      display_with_size_system: "UK 5.5",
                      display_with_system_and_set: "UK 5.5",
                      equivalent_sizes: {
                        us: ["7.5", "8"],
                        au: ["7.5AU"],
                        eu: ["8AU"],
                        uk: ["37.5EU", "38EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["7.5", "8"],
                        au: ["7.5AU", "8AU"],
                        eu: ["37.5EU", "38EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6.5UK",
                      short: "6.5",
                      long: "6.5",
                      display: "6.5",
                      display_with_size_set: "6.5",
                      display_with_size_system: "UK 6.5",
                      display_with_system_and_set: "UK 6.5",
                      equivalent_sizes: {
                        us: ["8.5", "9"],
                        au: ["8.5AU", "9AU"],
                        eu: ["38.5EU", "39EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "7UK",
                      short: "7",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7",
                      display_with_size_system: "UK 7",
                      display_with_system_and_set: "UK 7",
                      equivalent_sizes: {
                        us: ["8.5", "9"],
                        au: ["8.5AU", "9AU"],
                        eu: ["38.5EU", "39EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "7.5UK",
                      short: "7.5",
                      long: "7.5",
                      display: "7.5",
                      display_with_size_set: "7.5",
                      display_with_size_system: "UK 7.5",
                      display_with_system_and_set: "UK 7.5",
                      equivalent_sizes: {
                        us: ["9.5", "10"],
                        au: ["9.5AU", "10AU"],
                        eu: ["39.5EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["9.5", "10"],
                        au: ["9.5AU", "10AU"],
                        eu: ["39.5EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8.5UK",
                      short: "8.5",
                      long: "8.5",
                      display: "8.5",
                      display_with_size_set: "8.5",
                      display_with_size_system: "UK 8.5",
                      display_with_system_and_set: "UK 8.5",
                      equivalent_sizes: {
                        us: ["10.5", "11"],
                        au: ["10.5AU", "11AU"],
                        eu: ["40.5EU", "41EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9UK",
                      short: "9",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9",
                      display_with_size_system: "UK 9",
                      display_with_system_and_set: "UK 9",
                      equivalent_sizes: {
                        us: ["10.5", "11"],
                        au: ["10.5AU", "11AU"],
                        eu: ["40.5EU", "41EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9.5UK",
                      short: "9.5",
                      long: "9.5",
                      display: "9.5",
                      display_with_size_set: "9.5",
                      display_with_size_system: "UK 9.5",
                      display_with_system_and_set: "UK 9.5",
                      equivalent_sizes: {
                        us: ["11.5", "12"],
                        au: ["11.5AU", "12AU"],
                        eu: ["41.5EU", "42EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["11.5", "12"],
                        au: ["11.5AU", "12AU"],
                        eu: ["41.5EU", "42EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10.5UK",
                      short: "10.5",
                      long: "10.5",
                      display: "10.5",
                      display_with_size_set: "10.5",
                      display_with_size_system: "UK 10.5",
                      display_with_system_and_set: "UK 10.5",
                      equivalent_sizes: {
                        us: ["12.5", "13"],
                        au: ["12.5AU", "13AU"],
                        eu: ["42.5EU", "43EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11UK",
                      short: "11",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11",
                      equivalent_sizes: {
                        us: ["12.5", "13"],
                        au: ["12.5AU", "13AU"],
                        eu: ["42.5EU", "43EU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "women_shoes",
          slug: "Shoes",
          alias_slugs: ["Boots"],
          parent_id: "000e8975d97b4e80ef00a955",
          category_features: [
            {
              id: "00f29287d97b4e80ef00a955",
              display: "Ankle Boots & Booties",
              type: "cf",
              message_id: "women_shoes_ankle_boots_booties",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Ankle_Boots_&_Booties",
              alias_slugs: [],
              parent_id: "00268975d97b4e80ef00a955",
            },
            {
              id: "00fe9287d97b4e80ef00a955",
              display: "Athletic Shoes",
              type: "cf",
              message_id: "women_shoes_athletic_shoes",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Athletic_Shoes",
              alias_slugs: [],
              parent_id: "00268975d97b4e80ef00a955",
            },
            {
              id: "00f69287d97b4e80ef00a955",
              display: "Combat & Moto Boots",
              type: "cf",
              message_id: "women_shoes_combat_moto_boots",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Combat_&_Moto_Boots",
              alias_slugs: [],
              parent_id: "00268975d97b4e80ef00a955",
            },
            {
              id: "01009287d97b4e80ef00a955",
              display: "Espadrilles",
              type: "cf",
              message_id: "women_shoes_espadrilles",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Espadrilles",
              alias_slugs: [],
              parent_id: "00268975d97b4e80ef00a955",
            },
            {
              id: "01029287d97b4e80ef00a955",
              display: "Flats & Loafers",
              type: "cf",
              message_id: "women_shoes_flats_loafers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Flats_&_Loafers",
              alias_slugs: [],
              parent_id: "00268975d97b4e80ef00a955",
            },
            {
              id: "00fa9287d97b4e80ef00a955",
              display: "Heeled Boots",
              type: "cf",
              message_id: "women_shoes_heeled_boots",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Heeled_Boots",
              alias_slugs: [],
              parent_id: "00268975d97b4e80ef00a955",
            },
            {
              id: "010a9287d97b4e80ef00a955",
              display: "Heels",
              type: "cf",
              message_id: "women_shoes_heels",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Heels",
              alias_slugs: [],
              parent_id: "00268975d97b4e80ef00a955",
            },
            {
              id: "00fc9287d97b4e80ef00a955",
              display: "Lace Up Boots",
              type: "cf",
              message_id: "women_shoes_lace_up_boots",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Lace_Up_Boots",
              alias_slugs: [],
              parent_id: "00268975d97b4e80ef00a955",
            },
            {
              id: "01049287d97b4e80ef00a955",
              display: "Moccasins",
              type: "cf",
              message_id: "women_shoes_moccasins",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Moccasins",
              alias_slugs: [],
              parent_id: "00268975d97b4e80ef00a955",
            },
            {
              id: "01069287d97b4e80ef00a955",
              display: "Mules & Clogs",
              type: "cf",
              message_id: "women_shoes_mules_clogs",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Mules_&_Clogs",
              alias_slugs: [],
              parent_id: "00268975d97b4e80ef00a955",
            },
            {
              id: "00f89287d97b4e80ef00a955",
              display: "Over the Knee Boots",
              type: "cf",
              message_id: "women_shoes_over_the_knee_boots",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Over_the_Knee_Boots",
              alias_slugs: [],
              parent_id: "00268975d97b4e80ef00a955",
            },
            {
              id: "01089287d97b4e80ef00a955",
              display: "Platforms",
              type: "cf",
              message_id: "women_shoes_platforms",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Platforms",
              alias_slugs: [],
              parent_id: "00268975d97b4e80ef00a955",
            },
            {
              id: "010c9287d97b4e80ef00a955",
              display: "Sandals",
              type: "cf",
              message_id: "women_shoes_sandals",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sandals",
              alias_slugs: [],
              parent_id: "00268975d97b4e80ef00a955",
            },
            {
              id: "010e9287d97b4e80ef00a955",
              display: "Slippers",
              type: "cf",
              message_id: "women_shoes_slippers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Slippers",
              alias_slugs: [],
              parent_id: "00268975d97b4e80ef00a955",
            },
            {
              id: "01109287d97b4e80ef00a955",
              display: "Sneakers",
              type: "cf",
              message_id: "women_shoes_sneakers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sneakers",
              alias_slugs: [],
              parent_id: "00268975d97b4e80ef00a955",
            },
            {
              id: "01129287d97b4e80ef00a955",
              display: "Wedges",
              type: "cf",
              message_id: "women_shoes_wedges",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Wedges",
              alias_slugs: [],
              parent_id: "00268975d97b4e80ef00a955",
            },
            {
              id: "00f49287d97b4e80ef00a955",
              display: "Winter & Rain Boots",
              type: "cf",
              message_id: "women_shoes_winter_rain_boots",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Winter_&_Rain_Boots",
              alias_slugs: [],
              parent_id: "00268975d97b4e80ef00a955",
            },
          ],
        },
        {
          id: "002a8975d97b4e80ef00a955",
          display: "Accessories",
          size_sets: [
            {
              id: "1700579fd97b4e26a60056e3",
              name: "Standard",
              tags: ["standard"],
              category_id: "002a8975d97b4e80ef00a955",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "women_accessories",
          aliases: [],
          slug: "Accessories",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
          category_features: [
            {
              id: "011e9287d97b4e80ef00a955",
              display: "Belts",
              type: "cf",
              message_id: "women_accessories_belts",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Belts",
              alias_slugs: [],
              parent_id: "002a8975d97b4e80ef00a955",
            },
            {
              id: "877df9aaaabb083120f45ec2",
              display: "Face Masks",
              type: "cf",
              message_id: "women_accessories_face_masks",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Face_Masks",
              alias_slugs: [],
              parent_id: "002a8975d97b4e80ef00a955",
            },
            {
              id: "01209287d97b4e80ef00a955",
              display: "Glasses",
              type: "cf",
              message_id: "women_accessories_glasses",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Glasses",
              alias_slugs: [],
              parent_id: "002a8975d97b4e80ef00a955",
            },
            {
              id: "01229287d97b4e80ef00a955",
              display: "Gloves & Mittens",
              type: "cf",
              message_id: "women_accessories_gloves_mittens",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Gloves_&_Mittens",
              alias_slugs: [],
              parent_id: "002a8975d97b4e80ef00a955",
            },
            {
              id: "01249287d97b4e80ef00a955",
              display: "Hair Accessories",
              type: "cf",
              message_id: "women_accessories_hair_accessories",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Hair_Accessories",
              alias_slugs: [],
              parent_id: "002a8975d97b4e80ef00a955",
            },
            {
              id: "01269287d97b4e80ef00a955",
              display: "Hats",
              type: "cf",
              message_id: "women_accessories_hats",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Hats",
              alias_slugs: [],
              parent_id: "002a8975d97b4e80ef00a955",
            },
            {
              id: "01289287d97b4e80ef00a955",
              display: "Hosiery & Socks",
              type: "cf",
              message_id: "women_accessories_hosiery_socks",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Hosiery_&_Socks",
              alias_slugs: [],
              parent_id: "002a8975d97b4e80ef00a955",
            },
            {
              id: "012a9287d97b4e80ef00a955",
              display: "Key & Card Holders",
              type: "cf",
              message_id: "women_accessories_key_card_holders",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Key_&_Card_Holders",
              alias_slugs: [],
              parent_id: "002a8975d97b4e80ef00a955",
            },
            {
              id: "012c9287d97b4e80ef00a955",
              display: "Laptop Cases",
              type: "cf",
              message_id: "women_accessories_laptop_cases",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Laptop_Cases",
              alias_slugs: [],
              parent_id: "002a8975d97b4e80ef00a955",
            },
            {
              id: "012e9287d97b4e80ef00a955",
              display: "Phone Cases",
              type: "cf",
              message_id: "women_accessories_phone_cases",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Phone_Cases",
              alias_slugs: [],
              parent_id: "002a8975d97b4e80ef00a955",
            },
            {
              id: "01309287d97b4e80ef00a955",
              display: "Scarves & Wraps",
              type: "cf",
              message_id: "women_accessories_scarves_wraps",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Scarves_&_Wraps",
              alias_slugs: [],
              parent_id: "002a8975d97b4e80ef00a955",
            },
            {
              id: "01329287d97b4e80ef00a955",
              display: "Sunglasses",
              type: "cf",
              message_id: "women_accessories_sunglasses",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sunglasses",
              alias_slugs: [],
              parent_id: "002a8975d97b4e80ef00a955",
            },
            {
              id: "01349287d97b4e80ef00a955",
              display: "Tablet Cases",
              type: "cf",
              message_id: "women_accessories_tablet_cases",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Tablet_Cases",
              alias_slugs: [],
              parent_id: "002a8975d97b4e80ef00a955",
            },
            {
              id: "01369287d97b4e80ef00a955",
              display: "Umbrellas",
              type: "cf",
              message_id: "women_accessories_umbrellas",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Umbrellas",
              alias_slugs: [],
              parent_id: "002a8975d97b4e80ef00a955",
            },
            {
              id: "01389287d97b4e80ef00a955",
              display: "Watches",
              type: "cf",
              message_id: "women_accessories_watches",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Watches",
              alias_slugs: [],
              parent_id: "002a8975d97b4e80ef00a955",
            },
          ],
        },
        {
          id: "00108975d97b4e80ef00a955",
          aliases: ["Dresses & Skirts"],
          display: "Dresses",
          size_sets: [
            {
              id: "0100579fd97b4e26a60056e3",
              name: "Standard",
              tags: ["standard"],
              category_id: "00108975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "6AU"],
                        eu: ["32EU", "34EU"],
                        uk: ["4UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["00"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        eu: ["30EU", "32EU", "34EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        eu: ["32EU", "34EU", "36EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["00"],
                        au: ["2AU", "4AU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["00"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["30EU", "32EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["32EU", "34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "0200579fd97b4e26a60056e3",
              name: "Plus",
              tags: ["plus"],
              category_id: "00108975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34", "58,60,62", "34"],
                  ["30,30W,32,32W", "36,38", "60,62", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU", "28AU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU", "30AU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20", "20W", "22W"],
                        au: ["20AU", "22AU", "24AU", "26AU"],
                        uk: ["20UK", "22UK", "24UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "1a00579fd97b4e26a60056e3",
              name: "Petite",
              tags: ["petite"],
              category_id: "00108975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P,32P", "2P"],
                  ["00P,0P", "2P,4P,6P", "30P,32P,34P", "4P"],
                  ["0P,2P", "4P,6P,8P", "32P,34P,36P", "6P"],
                  ["2P,4P", "10P,6P,8P", "34P,36P,38P", "8P"],
                  ["4P,6P", "10P,12P,8P", "36P,38P,40P", "10P"],
                  ["6P,8P", "10P,12P,14P", "38P,40P,42P", "12P"],
                  ["8P,10P", "12P,14P,16P", "40P,42P,44P", "14P"],
                  ["10P,12P", "14P,16P,18P", "42P,44P,46P", "16P"],
                  ["12P,14P", "16P,18P,20P", "44P,46P,48P", "18P"],
                  ["14P,16P", "18P,20P,22P", "46P,48P,50P", "20P"],
                  ["16P,18P", "20P,22P,24P", "48P,50P,52P", "22P"],
                  ["18P,20P", "22P,24P", "50P,52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P,4P", "30P,32P", "2P,4P"],
                ["0P", "4P,6P", "32P,34P", "4P,6P"],
                ["2P", "6P,8P", "34P,36P", "6P,8P"],
                ["4P", "8P,10P", "36P,38P", "8P,10P"],
                ["6P", "10P,12P", "38P,40P", "10P,12P"],
                ["8P", "12P,14P", "40P,42P", "12P,14P"],
                ["10P", "14P,16P", "42P,44P", "14P,16P"],
                ["12P", "16P,18P", "44P,46P", "16P,18P"],
                ["14P", "18P,20P", "46P,48P", "18P,20P"],
                ["16P", "20P,22P", "48P,50P", "20P,22P"],
                ["18P", "22P,24P", "50P,52P", "22P,24P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "6PAU"],
                        eu: ["32PEU", "34PEU"],
                        uk: ["4PUK", "6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU"],
                        uk: ["6PUK", "8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "10PAU"],
                        eu: ["36PEU", "38PEU"],
                        uk: ["8PUK", "10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "12PAU"],
                        eu: ["38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "14PAU"],
                        eu: ["40PEU", "42PEU"],
                        uk: ["12PUK", "14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "16PAU"],
                        eu: ["42PEU", "44PEU"],
                        uk: ["14PUK", "16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "18PAU"],
                        eu: ["44PEU", "46PEU"],
                        uk: ["16PUK", "18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "20PAU"],
                        eu: ["46PEU", "48PEU"],
                        uk: ["18PUK", "20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "22PAU"],
                        eu: ["48PEU", "50PEU"],
                        uk: ["20PUK", "22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["6PAU", "8PAU", "10PAU"],
                        uk: ["6PUK", "8PUK", "10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["8PAU", "10PAU", "12PAU"],
                        uk: ["8PUK", "10PUK", "12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "1b00579fd97b4e26a60056e3",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "00108975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "4,6", "30,32", "4"],
                  ["00,0,1", "4,6,8", "30,32,34", "6"],
                  ["0,1", "6,8", "32,34", "8"],
                  ["3,5", "10,11", "34,36", "10"],
                  ["3,5", "10,11", "34,36", "11"],
                  ["7,9", "12,14", "38,40", "12"],
                  ["7,9", "12,14", "38,40", "14"],
                  ["11,13", "16,18", "42,44", "16"],
                  ["11,13", "16,18,20", "42,44", "18"],
                  ["15,17", "18,20", "44,46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "4,6", "30,32", "4,6"],
                ["0", "6,8", "32,34", "6,8"],
                ["1", "6,8", "32,34", "6,8"],
                ["3", "10,11", "34,36", "10,11"],
                ["5", "10,11", "34,36", "10,11"],
                ["7", "12,14", "38,40", "12,14"],
                ["9", "12,14", "38,40", "12,14"],
                ["11", "16,18", "42,44", "16,18"],
                ["13", "16,18", "42,44", "16,18"],
                ["15", "20,22", "44,46", "20,22"],
                ["17", "20,22", "44,46", "20,22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J", "3J", "5J"],
                        au: ["6JAU", "8JAU", "10JAU", "11JAU"],
                        uk: ["6JUK", "8JUK", "10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J", "15J", "17J"],
                        au: ["16JAU", "18JAU", "20JAU", "22JAU"],
                        uk: ["16JUK", "18JUK", "20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "30004308d97b4ea2b60057c7",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "00108975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "women_dresses",
          slug: "Dresses",
          alias_slugs: ["Dresses_&_Skirts"],
          parent_id: "000e8975d97b4e80ef00a955",
          category_features: [
            {
              id: "00449287d97b4e80ef00a955",
              display: "Asymmetrical",
              type: "cf",
              message_id: "women_dresses_asymmetrical",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Asymmetrical",
              alias_slugs: [],
              parent_id: "00108975d97b4e80ef00a955",
            },
            {
              id: "004c9287d97b4e80ef00a955",
              display: "Backless",
              type: "cf",
              message_id: "women_dresses_backless",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Backless",
              alias_slugs: [],
              parent_id: "00108975d97b4e80ef00a955",
            },
            {
              id: "00429287d97b4e80ef00a955",
              display: "High Low",
              type: "cf",
              message_id: "women_dresses_high_low",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "High_Low",
              alias_slugs: [],
              parent_id: "00108975d97b4e80ef00a955",
            },
            {
              id: "00469287d97b4e80ef00a955",
              display: "Long Sleeve",
              type: "cf",
              message_id: "women_dresses_long_sleeve",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Long_Sleeve",
              alias_slugs: [],
              parent_id: "00108975d97b4e80ef00a955",
            },
            {
              id: "00409287d97b4e80ef00a955",
              display: "Maxi",
              type: "cf",
              message_id: "women_dresses_maxi",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Maxi",
              alias_slugs: [],
              parent_id: "00108975d97b4e80ef00a955",
            },
            {
              id: "003e9287d97b4e80ef00a955",
              display: "Midi",
              type: "cf",
              message_id: "women_dresses_midi",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Midi",
              alias_slugs: [],
              parent_id: "00108975d97b4e80ef00a955",
            },
            {
              id: "003c9287d97b4e80ef00a955",
              display: "Mini",
              type: "cf",
              message_id: "women_dresses_mini",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Mini",
              alias_slugs: [],
              parent_id: "00108975d97b4e80ef00a955",
            },
            {
              id: "00489287d97b4e80ef00a955",
              display: "One Shoulder",
              type: "cf",
              message_id: "women_dresses_one_shoulder",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "One_Shoulder",
              alias_slugs: [],
              parent_id: "00108975d97b4e80ef00a955",
            },
            {
              id: "010082cfd97b4ecc3f0056aa",
              display: "Prom",
              type: "cf",
              message_id: "women_dresses_prom",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Prom",
              alias_slugs: [],
              parent_id: "00108975d97b4e80ef00a955",
            },
            {
              id: "004a9287d97b4e80ef00a955",
              display: "Strapless",
              type: "cf",
              message_id: "women_dresses_strapless",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Strapless",
              alias_slugs: [],
              parent_id: "00108975d97b4e80ef00a955",
            },
            {
              id: "020082d1d97b4ecc3f0056aa",
              display: "Wedding",
              type: "cf",
              message_id: "women_dresses_wedding",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Wedding",
              alias_slugs: [],
              parent_id: "00108975d97b4e80ef00a955",
            },
          ],
        },
        {
          id: "00208975d97b4e80ef00a955",
          display: "Intimates & Sleepwear",
          size_sets: [
            {
              id: "1000579fd97b4e26a60056e3",
              name: "Standard",
              tags: ["standard"],
              category_id: "00208975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Band ", "Cup "],
                  ["26-28", "30-31"],
                  ["26-28", "31-32"],
                  ["28-30", "28-32"],
                  ["28-30", "32-33"],
                  ["28-30", "33-34"],
                  ["28-30", "34-35"],
                  ["28-30", "35-36"],
                  ["28-30", "36-37"],
                  ["28-30", "37-38"],
                  ["28-30", "38-39"],
                  ["28-30", "39-40"],
                  ["30-32", "30-34"],
                  ["30-32", "34-3576.2-81.3"],
                  ["30-32", "35-36"],
                  ["30-32", "36-37"],
                  ["30-32", "37-38"],
                  ["30-32", "38-39"],
                  ["30-32", "39-40"],
                  ["30-32", "40-41"],
                  ["30-32", "41-42"],
                  ["32-34", "32-36"],
                  ["32-34", "36-37"],
                  ["32-34", "37-38"],
                  ["32-34", "38-39"],
                  ["32-34", "39-40"],
                  ["32-34", "40-41"],
                  ["32-34", "41-42"],
                  ["32-34", "42-43"],
                  ["32-34", "43-44"],
                ],
                centimeters: [
                  ["Band ", "Cup "],
                  ["66.0-71.1", "76.2-78.7"],
                  ["66.0-71.1", "78.7-81.3"],
                  ["71.1-76.2", "71.1-81.3"],
                  ["71.1-76.2", "81.3-83.9"],
                  ["71.1-76.2", "83.9-86.4"],
                  ["71.1-76.2", "86.4-88.9"],
                  ["71.1-76.2", "88.9-91.4"],
                  ["71.1-76.2", "91.4-94.0"],
                  ["71.1-76.2", "94.0-96.5"],
                  ["71.1-76.2", "96.5-99.1"],
                  ["71.1-76.2", "99.1-101.6"],
                  ["76.2-81.3", "76.2-86.4"],
                  ["86.4-88.9", "88.9-91.4"],
                  ["76.2-81.3", "91.4-94.0"],
                  ["76.2-81.3", "94.0-96.5"],
                  ["76.2-81.3", "96.5-99.1"],
                  ["76.2-81.3", "99.1-101.6"],
                  ["76.2-81.3", "101.6-104.1"],
                  ["76.2-81.3", "104.1-106.7"],
                  ["76.2-81.3", "81.3-91.4"],
                  ["81.3-86.4", "91.4-94.0"],
                  ["81.3-86.4", "94.0-96.5"],
                  ["81.3-86.4", "96.5-99.1"],
                  ["81.3-86.4", "99.1-101.6"],
                  ["81.3-86.4", "101.6-104.1"],
                  ["81.3-86.4", "104.1-106.7"],
                  ["81.3-86.4", "106.7-109.2"],
                  ["81.3-86.4", "109.2-111.8"],
                  ["81.3-86.4", null],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["30A", "8A", "65B", "30A"],
                  ["30B", "8B", "65C", "30B"],
                  ["32AA", "10AA", "70A", "32AA"],
                  ["32A", "10A", "70B", "32A"],
                  ["32B", "10B", "70C", "32B"],
                  ["32C", "10C", "70D", "32C"],
                  ["32D", "10D", "70E", "32D"],
                  ["32E (DD)", "10E (DD)", "70F", "32E (DD)"],
                  ["32F (3D)", "10F (3D)", "70G", "32F (3D)"],
                  ["32G (4D)", "10G (4D)", "70H", "32G (4D)"],
                  ["32H (5D)", "10H (5D)", "70I", "32H (5D)"],
                  ["34AA", "12AA", "75A", "34AA"],
                  ["34A", "12A", "75B", "34A"],
                  ["34B", "12B", "75C", "34B"],
                  ["34C", "12C", "75D", "34C"],
                  ["34D", "12D", "75E", "34D"],
                  ["34E (DD)", "12E (DD)", "75F", "34E (DD)"],
                  ["34F (3D)", "12F (3D)", "75G", "34F (3D)"],
                  ["34G (4D)", "12G (4D)", "75H", "34G (4D)"],
                  ["34H (5D)", "12H (5D)", "75I", "34H (5D)"],
                  ["36AA", "14AA", "80A", "36AA"],
                  ["36A", "14A", "80B", "36A"],
                  ["36B", "14B", "80C", "36B"],
                  ["36C", "14C", "80D", "36C"],
                  ["36D", "14D", "80E", "36D"],
                  ["36E (DD)", "14E (DD)", "80F", "36E (DD)"],
                  ["36F (3D)", "14F (3D)", "80G", "36F (3D)"],
                  ["36G (4D)", "14G (4D)", "80H", "36G (4D)"],
                  ["36H (5D)", "14H (5D)", "80I", "36H (5D)"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["30A", "8A", "65B", "30A"],
                ["30B", "8B", "65C", "30B"],
                ["32AA", "10AA", "70A", "32AA"],
                ["32A", "10A", "70B", "32A"],
                ["32B", "10B", "70C", "32B"],
                ["32C", "10C", "70D", "32C"],
                ["32D", "10D", "70E", "32D"],
                ["32E (DD)", "10E (DD)", "70F", "32E (DD)"],
                ["32F (3D)", "10F (3D)", "70G", "32F (3D)"],
                ["32G (4D)", "10G (4D)", "70H", "32G (4D)"],
                ["32H (5D)", "10H (5D)", "70I", "32H (5D)"],
                ["34AA", "12AA", "75A", "34AA"],
                ["34A", "12A", "75B", "34A"],
                ["34B", "12B", "75C", "34B"],
                ["34C", "12C", "75D", "34C"],
                ["34D", "12D", "75E", "34D"],
                ["34E (DD)", "12E (DD)", "75F", "34E (DD)"],
                ["34F (3D)", "12F (3D)", "75G", "34F (3D)"],
                ["34G (4D)", "12G (4D)", "75H", "34G (4D)"],
                ["34H (5D)", "12H (5D)", "75I", "34H (5D)"],
                ["36AA", "14AA", "80A", "36AA"],
                ["36A", "14A", "80B", "36A"],
                ["36B", "14B", "80C", "36B"],
                ["36C", "14C", "80D", "36C"],
                ["36D", "14D", "80E", "36D"],
                ["36E (DD)", "14E (DD)", "80F", "36E (DD)"],
                ["36F (3D)", "14F (3D)", "80G", "36F (3D)"],
                ["36G (4D)", "14G (4D)", "80H", "36G (4D)"],
                ["36H (5D)", "14H (5D)", "80I", "36H (5D)"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30A",
                      short: "30A",
                      long: "30A",
                      display: "30A",
                      display_with_size_set: "30A",
                      display_with_size_system: "US 30A",
                      display_with_system_and_set: "US 30A",
                      equivalent_sizes: {
                        au: ["8AAU"],
                        eu: ["65BEU"],
                        uk: ["30AUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30B",
                      short: "30B",
                      long: "30B",
                      display: "30B",
                      display_with_size_set: "30B",
                      display_with_size_system: "US 30B",
                      display_with_system_and_set: "US 30B",
                      equivalent_sizes: {
                        au: ["8BAU"],
                        eu: ["65CEU"],
                        uk: ["30BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32AA",
                      short: "32AA",
                      long: "32AA",
                      display: "32AA",
                      display_with_size_set: "32AA",
                      display_with_size_system: "US 32AA",
                      display_with_system_and_set: "US 32AA",
                      equivalent_sizes: {
                        au: ["10AAAU"],
                        eu: ["70AEU"],
                        uk: ["32AAUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32A",
                      short: "32A",
                      long: "32A",
                      display: "32A",
                      display_with_size_set: "32A",
                      display_with_size_system: "US 32A",
                      display_with_system_and_set: "US 32A",
                      equivalent_sizes: {
                        au: ["10AAU"],
                        eu: ["70BEU"],
                        uk: ["32AUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32B",
                      short: "32B",
                      long: "32B",
                      display: "32B",
                      display_with_size_set: "32B",
                      display_with_size_system: "US 32B",
                      display_with_system_and_set: "US 32B",
                      equivalent_sizes: {
                        au: ["10BAU"],
                        eu: ["70CEU"],
                        uk: ["32BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32C",
                      short: "32C",
                      long: "32C",
                      display: "32C",
                      display_with_size_set: "32C",
                      display_with_size_system: "US 32C",
                      display_with_system_and_set: "US 32C",
                      equivalent_sizes: {
                        au: ["10CAU"],
                        eu: ["70DEU"],
                        uk: ["32CUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32D",
                      short: "32D",
                      long: "32D",
                      display: "32D",
                      display_with_size_set: "32D",
                      display_with_size_system: "US 32D",
                      display_with_system_and_set: "US 32D",
                      equivalent_sizes: {
                        au: ["10DAU"],
                        eu: ["70EEU"],
                        uk: ["32DUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32E (DD)",
                      short: "32E (DD)",
                      long: "32E (DD)",
                      display: "32E (DD)",
                      display_with_size_set: "32E (DD)",
                      display_with_size_system: "US 32E (DD)",
                      display_with_system_and_set: "US 32E (DD)",
                      equivalent_sizes: {
                        au: ["10EAU"],
                        eu: ["70FEU"],
                        uk: ["32E (DD)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32F (3D)",
                      short: "32F (3D)",
                      long: "32F (3D)",
                      display: "32F (3D)",
                      display_with_size_set: "32F (3D)",
                      display_with_size_system: "US 32F (3D)",
                      display_with_system_and_set: "US 32F (3D)",
                      equivalent_sizes: {
                        au: ["10F(DDD)AU"],
                        eu: ["70GEU"],
                        uk: ["32F (3D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32G (4D)",
                      short: "32G (4D)",
                      long: "32G (4D)",
                      display: "32G (4D)",
                      display_with_size_set: "32G (4D)",
                      display_with_size_system: "US 32G (4D)",
                      display_with_system_and_set: "US 32G (4D)",
                      equivalent_sizes: {
                        au: ["10G (4D)AU"],
                        eu: ["70H (4D)EU"],
                        uk: ["32G (4D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32H (5D)",
                      short: "32H (5D)",
                      long: "32H (5D)",
                      display: "32H (5D)",
                      display_with_size_set: "32H (5D)",
                      display_with_size_system: "US 32H (5D)",
                      display_with_system_and_set: "US 32H (5D)",
                      equivalent_sizes: {
                        au: ["10H (5D)AU"],
                        eu: ["70I (5D)EU"],
                        uk: ["32H (5D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34AA",
                      short: "34AA",
                      long: "34AA",
                      display: "34AA",
                      display_with_size_set: "34AA",
                      display_with_size_system: "US 34AA",
                      display_with_system_and_set: "US 34AA",
                      equivalent_sizes: {
                        au: ["12AAAU"],
                        eu: ["75AEU"],
                        uk: ["34AAUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34A",
                      short: "34A",
                      long: "34A",
                      display: "34A",
                      display_with_size_set: "34A",
                      display_with_size_system: "US 34A",
                      display_with_system_and_set: "US 34A",
                      equivalent_sizes: {
                        au: ["12AAU"],
                        eu: ["75BEU"],
                        uk: ["34AUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34B",
                      short: "34B",
                      long: "34B",
                      display: "34B",
                      display_with_size_set: "34B",
                      display_with_size_system: "US 34B",
                      display_with_system_and_set: "US 34B",
                      equivalent_sizes: {
                        au: ["12BAU"],
                        eu: ["75CEU"],
                        uk: ["34BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34C",
                      short: "34C",
                      long: "34C",
                      display: "34C",
                      display_with_size_set: "34C",
                      display_with_size_system: "US 34C",
                      display_with_system_and_set: "US 34C",
                      equivalent_sizes: {
                        au: ["12CAU"],
                        eu: ["75DEU"],
                        uk: ["34CUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34D",
                      short: "34D",
                      long: "34D",
                      display: "34D",
                      display_with_size_set: "34D",
                      display_with_size_system: "US 34D",
                      display_with_system_and_set: "US 34D",
                      equivalent_sizes: {
                        au: ["12DAU"],
                        eu: ["75EEU"],
                        uk: ["34DUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34E (DD)",
                      short: "34E (DD)",
                      long: "34E (DD)",
                      display: "34E (DD)",
                      display_with_size_set: "34E (DD)",
                      display_with_size_system: "US 34E (DD)",
                      display_with_system_and_set: "US 34E (DD)",
                      equivalent_sizes: {
                        au: ["12EAU"],
                        eu: ["75FEU"],
                        uk: ["34E (DD)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34F (3D)",
                      short: "34F (3D)",
                      long: "34F (3D)",
                      display: "34F (3D)",
                      display_with_size_set: "34F (3D)",
                      display_with_size_system: "US 34F (3D)",
                      display_with_system_and_set: "US 34F (3D)",
                      equivalent_sizes: {
                        au: ["12FAU"],
                        eu: ["75GEU"],
                        uk: ["34F (3D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34G (4D)",
                      short: "34G (4D)",
                      long: "34G (4D)",
                      display: "34G (4D)",
                      display_with_size_set: "34G (4D)",
                      display_with_size_system: "US 34G (4D)",
                      display_with_system_and_set: "US 34G (4D)",
                      equivalent_sizes: {
                        au: ["12G (D)AU"],
                        eu: ["75H (D)EU"],
                        uk: ["34G (4D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34H (5D)",
                      short: "34H (5D)",
                      long: "34H (5D)",
                      display: "34H (5D)",
                      display_with_size_set: "34H (5D)",
                      display_with_size_system: "US 34H (5D)",
                      display_with_system_and_set: "US 34H (5D)",
                      equivalent_sizes: {
                        au: ["12H (5D)AU"],
                        eu: ["75I (5D)EU"],
                        uk: ["34H (5D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36AA",
                      short: "36AA",
                      long: "36AA",
                      display: "36AA",
                      display_with_size_set: "36AA",
                      display_with_size_system: "US 36AA",
                      display_with_system_and_set: "US 36AA",
                      equivalent_sizes: {
                        au: ["14AAAU"],
                        eu: ["80AEU"],
                        uk: ["36AAUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36A",
                      short: "36A",
                      long: "36A",
                      display: "36A",
                      display_with_size_set: "36A",
                      display_with_size_system: "US 36A",
                      display_with_system_and_set: "US 36A",
                      equivalent_sizes: {
                        au: ["14AAU"],
                        eu: ["80BEU"],
                        uk: ["36AUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36B",
                      short: "36B",
                      long: "36B",
                      display: "36B",
                      display_with_size_set: "36B",
                      display_with_size_system: "US 36B",
                      display_with_system_and_set: "US 36B",
                      equivalent_sizes: {
                        au: ["14BAU"],
                        eu: ["80CEU"],
                        uk: ["36BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36C",
                      short: "36C",
                      long: "36C",
                      display: "36C",
                      display_with_size_set: "36C",
                      display_with_size_system: "US 36C",
                      display_with_system_and_set: "US 36C",
                      equivalent_sizes: {
                        au: ["14CAU"],
                        eu: ["80DEU"],
                        uk: ["36CUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36D",
                      short: "36D",
                      long: "36D",
                      display: "36D",
                      display_with_size_set: "36D",
                      display_with_size_system: "US 36D",
                      display_with_system_and_set: "US 36D",
                      equivalent_sizes: {
                        au: ["14DAU"],
                        eu: ["80EEU"],
                        uk: ["36DUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36E (DD)",
                      short: "36E (DD)",
                      long: "36E (DD)",
                      display: "36E (DD)",
                      display_with_size_set: "36E (DD)",
                      display_with_size_system: "US 36E (DD)",
                      display_with_system_and_set: "US 36E (DD)",
                      equivalent_sizes: {
                        au: ["14EAU"],
                        eu: ["80FEU"],
                        uk: ["36E (DD)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36F (3D)",
                      short: "36F (3D)",
                      long: "36F (3D)",
                      display: "36F (3D)",
                      display_with_size_set: "36F (3D)",
                      display_with_size_system: "US 36F (3D)",
                      display_with_system_and_set: "US 36F (3D)",
                      equivalent_sizes: {
                        au: ["14FAU"],
                        eu: ["80GEU"],
                        uk: ["36F (3D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36G (4D)",
                      short: "36G (4D)",
                      long: "36G (4D)",
                      display: "36G (4D)",
                      display_with_size_set: "36G (4D)",
                      display_with_size_system: "US 36G (4D)",
                      display_with_system_and_set: "US 36G (4D)",
                      equivalent_sizes: {
                        au: ["14G (4D)AU"],
                        eu: ["80H (4D)EU"],
                        uk: ["36G (4D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36H (5D)",
                      short: "36H (5D)",
                      long: "36H (5D)",
                      display: "36H (5D)",
                      display_with_size_set: "36H (5D)",
                      display_with_size_system: "US 36H (5D)",
                      display_with_system_and_set: "US 36H (5D)",
                      equivalent_sizes: {
                        au: ["14H (5D)AU"],
                        eu: ["80I (5D)EU"],
                        uk: ["36H (5D)UK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AAU",
                      short: "8A",
                      long: "8A",
                      display: "8A",
                      display_with_size_set: "8A",
                      display_with_size_system: "AU 8A",
                      display_with_system_and_set: "AU 8A",
                      equivalent_sizes: {
                        us: ["30A"],
                        eu: ["65BEU"],
                        uk: ["30AUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8BAU",
                      short: "8B",
                      long: "8B",
                      display: "8B",
                      display_with_size_set: "8B",
                      display_with_size_system: "AU 8B",
                      display_with_system_and_set: "AU 8B",
                      equivalent_sizes: {
                        us: ["30B"],
                        eu: ["65CEU"],
                        uk: ["30BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AAAU",
                      short: "10AA",
                      long: "10AA",
                      display: "10AA",
                      display_with_size_set: "10AA",
                      display_with_size_system: "AU 10AA",
                      display_with_system_and_set: "AU 10AA",
                      equivalent_sizes: {
                        us: ["32AA"],
                        eu: ["70AEU"],
                        uk: ["32AAUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AAU",
                      short: "10A",
                      long: "10A",
                      display: "10A",
                      display_with_size_set: "10A",
                      display_with_size_system: "AU 10A",
                      display_with_system_and_set: "AU 10A",
                      equivalent_sizes: {
                        us: ["32A"],
                        eu: ["70BEU"],
                        uk: ["32AUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10BAU",
                      short: "10B",
                      long: "10B",
                      display: "10B",
                      display_with_size_set: "10B",
                      display_with_size_system: "AU 10B",
                      display_with_system_and_set: "AU 10B",
                      equivalent_sizes: {
                        us: ["32B"],
                        eu: ["70CEU"],
                        uk: ["32BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10CAU",
                      short: "10C",
                      long: "10C",
                      display: "10C",
                      display_with_size_set: "10C",
                      display_with_size_system: "AU 10C",
                      display_with_system_and_set: "AU 10C",
                      equivalent_sizes: {
                        us: ["32C"],
                        eu: ["70DEU"],
                        uk: ["32CUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10DAU",
                      short: "10D",
                      long: "10D",
                      display: "10D",
                      display_with_size_set: "10D",
                      display_with_size_system: "AU 10D",
                      display_with_system_and_set: "AU 10D",
                      equivalent_sizes: {
                        us: ["32D"],
                        eu: ["70EEU"],
                        uk: ["32DUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10EAU",
                      short: "10E (DD)",
                      long: "10E (DD)",
                      display: "10E (DD)",
                      display_with_size_set: "10E (DD)",
                      display_with_size_system: "AU 10E (DD)",
                      display_with_system_and_set: "AU 10E (DD)",
                      equivalent_sizes: {
                        us: ["32E (DD)"],
                        eu: ["70FEU"],
                        uk: ["32E (DD)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10F(DDD)AU",
                      short: "10F (3D)",
                      long: "10F (3D)",
                      display: "10F (3D)",
                      display_with_size_set: "10F (3D)",
                      display_with_size_system: "AU 10F (3D)",
                      display_with_system_and_set: "AU 10F (3D)",
                      equivalent_sizes: {
                        us: ["32F (3D)"],
                        eu: ["70GEU"],
                        uk: ["32F (3D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10G (4D)AU",
                      short: "10G (4D)",
                      long: "10G (4D)",
                      display: "10G (4D)",
                      display_with_size_set: "10G (4D)",
                      display_with_size_system: "AU 10G (4D)",
                      display_with_system_and_set: "AU 10G (4D)",
                      equivalent_sizes: {
                        us: ["32G (4D)"],
                        eu: ["70H (4D)EU"],
                        uk: ["32G (4D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10H (5D)AU",
                      short: "10H (5D)",
                      long: "10H (5D)",
                      display: "10H (5D)",
                      display_with_size_set: "10H (5D)",
                      display_with_size_system: "AU 10H (5D)",
                      display_with_system_and_set: "AU 10H (5D)",
                      equivalent_sizes: {
                        us: ["32H (5D)"],
                        eu: ["70I (5D)EU"],
                        uk: ["32H (5D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AAAU",
                      short: "12AA",
                      long: "12AA",
                      display: "12AA",
                      display_with_size_set: "12AA",
                      display_with_size_system: "AU 12AA",
                      display_with_system_and_set: "AU 12AA",
                      equivalent_sizes: {
                        us: ["34AA"],
                        eu: ["75AEU"],
                        uk: ["34AAUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AAU",
                      short: "12A",
                      long: "12A",
                      display: "12A",
                      display_with_size_set: "12A",
                      display_with_size_system: "AU 12A",
                      display_with_system_and_set: "AU 12A",
                      equivalent_sizes: {
                        us: ["34A"],
                        eu: ["75BEU"],
                        uk: ["34AUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12BAU",
                      short: "12B",
                      long: "12B",
                      display: "12B",
                      display_with_size_set: "12B",
                      display_with_size_system: "AU 12B",
                      display_with_system_and_set: "AU 12B",
                      equivalent_sizes: {
                        us: ["34B"],
                        eu: ["75CEU"],
                        uk: ["34BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12CAU",
                      short: "12C",
                      long: "12C",
                      display: "12C",
                      display_with_size_set: "12C",
                      display_with_size_system: "AU 12C",
                      display_with_system_and_set: "AU 12C",
                      equivalent_sizes: {
                        us: ["34C"],
                        eu: ["75DEU"],
                        uk: ["34CUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12DAU",
                      short: "12D",
                      long: "12D",
                      display: "12D",
                      display_with_size_set: "12D",
                      display_with_size_system: "AU 12D",
                      display_with_system_and_set: "AU 12D",
                      equivalent_sizes: {
                        us: ["34D"],
                        eu: ["75EEU"],
                        uk: ["34DUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12EAU",
                      short: "12E (DD)",
                      long: "12E (DD)",
                      display: "12E (DD)",
                      display_with_size_set: "12E (DD)",
                      display_with_size_system: "AU 12E (DD)",
                      display_with_system_and_set: "AU 12E (DD)",
                      equivalent_sizes: {
                        us: ["34E (DD)"],
                        eu: ["75FEU"],
                        uk: ["34E (DD)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12FAU",
                      short: "12F (3D)",
                      long: "12F (3D)",
                      display: "12F (3D)",
                      display_with_size_set: "12F (3D)",
                      display_with_size_system: "AU 12F (3D)",
                      display_with_system_and_set: "AU 12F (3D)",
                      equivalent_sizes: {
                        us: ["34F (3D)"],
                        eu: ["75GEU"],
                        uk: ["34F (3D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12G (D)AU",
                      short: "12G (4D)",
                      long: "12G (4D)",
                      display: "12G (4D)",
                      display_with_size_set: "12G (4D)",
                      display_with_size_system: "AU 12G (4D)",
                      display_with_system_and_set: "AU 12G (4D)",
                      equivalent_sizes: {
                        us: ["34G (4D)"],
                        eu: ["75H (D)EU"],
                        uk: ["34G (4D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12H (5D)AU",
                      short: "12H (5D)",
                      long: "12H (5D)",
                      display: "12H (5D)",
                      display_with_size_set: "12H (5D)",
                      display_with_size_system: "AU 12H (5D)",
                      display_with_system_and_set: "AU 12H (5D)",
                      equivalent_sizes: {
                        us: ["34H (5D)"],
                        eu: ["75I (5D)EU"],
                        uk: ["34H (5D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AAAU",
                      short: "14AA",
                      long: "14AA",
                      display: "14AA",
                      display_with_size_set: "14AA",
                      display_with_size_system: "AU 14AA",
                      display_with_system_and_set: "AU 14AA",
                      equivalent_sizes: {
                        us: ["36AA"],
                        eu: ["80AEU"],
                        uk: ["36AAUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AAU",
                      short: "14A",
                      long: "14A",
                      display: "14A",
                      display_with_size_set: "14A",
                      display_with_size_system: "AU 14A",
                      display_with_system_and_set: "AU 14A",
                      equivalent_sizes: {
                        us: ["36A"],
                        eu: ["80BEU"],
                        uk: ["36AUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14BAU",
                      short: "14B",
                      long: "14B",
                      display: "14B",
                      display_with_size_set: "14B",
                      display_with_size_system: "AU 14B",
                      display_with_system_and_set: "AU 14B",
                      equivalent_sizes: {
                        us: ["36B"],
                        eu: ["80CEU"],
                        uk: ["36BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14CAU",
                      short: "14C",
                      long: "14C",
                      display: "14C",
                      display_with_size_set: "14C",
                      display_with_size_system: "AU 14C",
                      display_with_system_and_set: "AU 14C",
                      equivalent_sizes: {
                        us: ["36C"],
                        eu: ["80DEU"],
                        uk: ["36CUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14DAU",
                      short: "14D",
                      long: "14D",
                      display: "14D",
                      display_with_size_set: "14D",
                      display_with_size_system: "AU 14D",
                      display_with_system_and_set: "AU 14D",
                      equivalent_sizes: {
                        us: ["36D"],
                        eu: ["80EEU"],
                        uk: ["36DUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14EAU",
                      short: "14E (DD)",
                      long: "14E (DD)",
                      display: "14E (DD)",
                      display_with_size_set: "14E (DD)",
                      display_with_size_system: "AU 14E (DD)",
                      display_with_system_and_set: "AU 14E (DD)",
                      equivalent_sizes: {
                        us: ["36E (DD)"],
                        eu: ["80FEU"],
                        uk: ["36E (DD)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14FAU",
                      short: "14F (3D)",
                      long: "14F (3D)",
                      display: "14F (3D)",
                      display_with_size_set: "14F (3D)",
                      display_with_size_system: "AU 14F (3D)",
                      display_with_system_and_set: "AU 14F (3D)",
                      equivalent_sizes: {
                        us: ["36F (3D)"],
                        eu: ["80GEU"],
                        uk: ["36F (3D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14G (4D)AU",
                      short: "14G (4D)",
                      long: "14G (4D)",
                      display: "14G (4D)",
                      display_with_size_set: "14G (4D)",
                      display_with_size_system: "AU 14G (4D)",
                      display_with_system_and_set: "AU 14G (4D)",
                      equivalent_sizes: {
                        us: ["36G (4D)"],
                        eu: ["80H (4D)EU"],
                        uk: ["36G (4D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14H (5D)AU",
                      short: "14H (5D)",
                      long: "14H (5D)",
                      display: "14H (5D)",
                      display_with_size_set: "14H (5D)",
                      display_with_size_system: "AU 14H (5D)",
                      display_with_system_and_set: "AU 14H (5D)",
                      equivalent_sizes: {
                        us: ["36H (5D)"],
                        eu: ["80I (5D)EU"],
                        uk: ["36H (5D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "65BEU",
                      short: "65B",
                      long: "65B",
                      display: "65B",
                      display_with_size_set: "65B",
                      display_with_size_system: "EU 65B",
                      display_with_system_and_set: "EU 65B",
                      equivalent_sizes: {
                        us: ["30A"],
                        au: ["8AAU"],
                        uk: ["30AUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "65CEU",
                      short: "65C",
                      long: "65C",
                      display: "65C",
                      display_with_size_set: "65C",
                      display_with_size_system: "EU 65C",
                      display_with_system_and_set: "EU 65C",
                      equivalent_sizes: {
                        us: ["30B"],
                        au: ["8BAU"],
                        uk: ["30BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "70AEU",
                      short: "70A",
                      long: "70A",
                      display: "70A",
                      display_with_size_set: "70A",
                      display_with_size_system: "EU 70A",
                      display_with_system_and_set: "EU 70A",
                      equivalent_sizes: {
                        us: ["32AA"],
                        au: ["10AAAU"],
                        uk: ["32AAUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "70BEU",
                      short: "70B",
                      long: "70B",
                      display: "70B",
                      display_with_size_set: "70B",
                      display_with_size_system: "EU 70B",
                      display_with_system_and_set: "EU 70B",
                      equivalent_sizes: {
                        us: ["32A"],
                        au: ["10AAU"],
                        uk: ["32AUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "70CEU",
                      short: "70C",
                      long: "70C",
                      display: "70C",
                      display_with_size_set: "70C",
                      display_with_size_system: "EU 70C",
                      display_with_system_and_set: "EU 70C",
                      equivalent_sizes: {
                        us: ["32B"],
                        au: ["10BAU"],
                        uk: ["32BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "70DEU",
                      short: "70D",
                      long: "70D",
                      display: "70D",
                      display_with_size_set: "70D",
                      display_with_size_system: "EU 70D",
                      display_with_system_and_set: "EU 70D",
                      equivalent_sizes: {
                        us: ["32C"],
                        au: ["10CAU"],
                        uk: ["32CUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "70EEU",
                      short: "70E",
                      long: "70E",
                      display: "70E",
                      display_with_size_set: "70E",
                      display_with_size_system: "EU 70E",
                      display_with_system_and_set: "EU 70E",
                      equivalent_sizes: {
                        us: ["32D"],
                        au: ["10DAU"],
                        uk: ["32DUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "70FEU",
                      short: "70F",
                      long: "70F",
                      display: "70F",
                      display_with_size_set: "70F",
                      display_with_size_system: "EU 70F",
                      display_with_system_and_set: "EU 70F",
                      equivalent_sizes: {
                        us: ["32E (DD)"],
                        au: ["10EAU"],
                        uk: ["32E (DD)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "70GEU",
                      short: "70G",
                      long: "70G",
                      display: "70G",
                      display_with_size_set: "70G",
                      display_with_size_system: "EU 70G",
                      display_with_system_and_set: "EU 70G",
                      equivalent_sizes: {
                        us: ["32F (3D)"],
                        au: ["10F(DDD)AU"],
                        uk: ["32F (3D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "70H (4D)EU",
                      short: "70H",
                      long: "70H",
                      display: "70H",
                      display_with_size_set: "70H",
                      display_with_size_system: "EU 70H",
                      display_with_system_and_set: "EU 70H",
                      equivalent_sizes: {
                        us: ["32G (4D)"],
                        au: ["10G (4D)AU"],
                        uk: ["32G (4D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "70I (5D)EU",
                      short: "70I",
                      long: "70I",
                      display: "70I",
                      display_with_size_set: "70I",
                      display_with_size_system: "EU 70I",
                      display_with_system_and_set: "EU 70I",
                      equivalent_sizes: {
                        us: ["32H (5D)"],
                        au: ["10H (5D)AU"],
                        uk: ["32H (5D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "75AEU",
                      short: "75A",
                      long: "75A",
                      display: "75A",
                      display_with_size_set: "75A",
                      display_with_size_system: "EU 75A",
                      display_with_system_and_set: "EU 75A",
                      equivalent_sizes: {
                        us: ["34AA"],
                        au: ["12AAAU"],
                        uk: ["34AAUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "75BEU",
                      short: "75B",
                      long: "75B",
                      display: "75B",
                      display_with_size_set: "75B",
                      display_with_size_system: "EU 75B",
                      display_with_system_and_set: "EU 75B",
                      equivalent_sizes: {
                        us: ["34A"],
                        au: ["12AAU"],
                        uk: ["34AUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "75CEU",
                      short: "75C",
                      long: "75C",
                      display: "75C",
                      display_with_size_set: "75C",
                      display_with_size_system: "EU 75C",
                      display_with_system_and_set: "EU 75C",
                      equivalent_sizes: {
                        us: ["34B"],
                        au: ["12BAU"],
                        uk: ["34BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "75DEU",
                      short: "75D",
                      long: "75D",
                      display: "75D",
                      display_with_size_set: "75D",
                      display_with_size_system: "EU 75D",
                      display_with_system_and_set: "EU 75D",
                      equivalent_sizes: {
                        us: ["34C"],
                        au: ["12CAU"],
                        uk: ["34CUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "75EEU",
                      short: "75E",
                      long: "75E",
                      display: "75E",
                      display_with_size_set: "75E",
                      display_with_size_system: "EU 75E",
                      display_with_system_and_set: "EU 75E",
                      equivalent_sizes: {
                        us: ["34D"],
                        au: ["12DAU"],
                        uk: ["34DUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "75FEU",
                      short: "75F",
                      long: "75F",
                      display: "75F",
                      display_with_size_set: "75F",
                      display_with_size_system: "EU 75F",
                      display_with_system_and_set: "EU 75F",
                      equivalent_sizes: {
                        us: ["34E (DD)"],
                        au: ["12EAU"],
                        uk: ["34E (DD)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "75GEU",
                      short: "75G",
                      long: "75G",
                      display: "75G",
                      display_with_size_set: "75G",
                      display_with_size_system: "EU 75G",
                      display_with_system_and_set: "EU 75G",
                      equivalent_sizes: {
                        us: ["34F (3D)"],
                        au: ["12FAU"],
                        uk: ["34F (3D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "75H (D)EU",
                      short: "75H",
                      long: "75H",
                      display: "75H",
                      display_with_size_set: "75H",
                      display_with_size_system: "EU 75H",
                      display_with_system_and_set: "EU 75H",
                      equivalent_sizes: {
                        us: ["34G (4D)"],
                        au: ["12G (D)AU"],
                        uk: ["34G (4D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "75I (5D)EU",
                      short: "75I",
                      long: "75I",
                      display: "75I",
                      display_with_size_set: "75I",
                      display_with_size_system: "EU 75I",
                      display_with_system_and_set: "EU 75I",
                      equivalent_sizes: {
                        us: ["34H (5D)"],
                        au: ["12H (5D)AU"],
                        uk: ["34H (5D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "80AEU",
                      short: "80A",
                      long: "80A",
                      display: "80A",
                      display_with_size_set: "80A",
                      display_with_size_system: "EU 80A",
                      display_with_system_and_set: "EU 80A",
                      equivalent_sizes: {
                        us: ["36AA"],
                        au: ["14AAAU"],
                        uk: ["36AAUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "80BEU",
                      short: "80B",
                      long: "80B",
                      display: "80B",
                      display_with_size_set: "80B",
                      display_with_size_system: "EU 80B",
                      display_with_system_and_set: "EU 80B",
                      equivalent_sizes: {
                        us: ["36A"],
                        au: ["14AAU"],
                        uk: ["36AUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "80CEU",
                      short: "80C",
                      long: "80C",
                      display: "80C",
                      display_with_size_set: "80C",
                      display_with_size_system: "EU 80C",
                      display_with_system_and_set: "EU 80C",
                      equivalent_sizes: {
                        us: ["36B"],
                        au: ["14BAU"],
                        uk: ["36BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "80DEU",
                      short: "80D",
                      long: "80D",
                      display: "80D",
                      display_with_size_set: "80D",
                      display_with_size_system: "EU 80D",
                      display_with_system_and_set: "EU 80D",
                      equivalent_sizes: {
                        us: ["36C"],
                        au: ["14CAU"],
                        uk: ["36CUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "80EEU",
                      short: "80E",
                      long: "80E",
                      display: "80E",
                      display_with_size_set: "80E",
                      display_with_size_system: "EU 80E",
                      display_with_system_and_set: "EU 80E",
                      equivalent_sizes: {
                        us: ["36D"],
                        au: ["14DAU"],
                        uk: ["36DUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "80FEU",
                      short: "80F",
                      long: "80F",
                      display: "80F",
                      display_with_size_set: "80F",
                      display_with_size_system: "EU 80F",
                      display_with_system_and_set: "EU 80F",
                      equivalent_sizes: {
                        us: ["36E (DD)"],
                        au: ["14EAU"],
                        uk: ["36E (DD)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "80GEU",
                      short: "80G",
                      long: "80G",
                      display: "80G",
                      display_with_size_set: "80G",
                      display_with_size_system: "EU 80G",
                      display_with_system_and_set: "EU 80G",
                      equivalent_sizes: {
                        us: ["36F (3D)"],
                        au: ["14FAU"],
                        uk: ["36F (3D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "80H (4D)EU",
                      short: "80H",
                      long: "80H",
                      display: "80H",
                      display_with_size_set: "80H",
                      display_with_size_system: "EU 80H",
                      display_with_system_and_set: "EU 80H",
                      equivalent_sizes: {
                        us: ["36G (4D)"],
                        au: ["14G (4D)AU"],
                        uk: ["36G (4D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "80I (5D)EU",
                      short: "80I",
                      long: "80I",
                      display: "80I",
                      display_with_size_set: "80I",
                      display_with_size_system: "EU 80I",
                      display_with_system_and_set: "EU 80I",
                      equivalent_sizes: {
                        us: ["36H (5D)"],
                        au: ["14H (5D)AU"],
                        uk: ["36H (5D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30AUK",
                      short: "30A",
                      long: "30A",
                      display: "30A",
                      display_with_size_set: "30A",
                      display_with_size_system: "UK 30A",
                      display_with_system_and_set: "UK 30A",
                      equivalent_sizes: {
                        us: ["30A"],
                        au: ["8AAU"],
                        eu: ["65BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30BUK",
                      short: "30B",
                      long: "30B",
                      display: "30B",
                      display_with_size_set: "30B",
                      display_with_size_system: "UK 30B",
                      display_with_system_and_set: "UK 30B",
                      equivalent_sizes: {
                        us: ["30B"],
                        au: ["8BAU"],
                        eu: ["65CEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32AAUK",
                      short: "32AA",
                      long: "32AA",
                      display: "32AA",
                      display_with_size_set: "32AA",
                      display_with_size_system: "UK 32AA",
                      display_with_system_and_set: "UK 32AA",
                      equivalent_sizes: {
                        us: ["32AA"],
                        au: ["10AAAU"],
                        eu: ["70AEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32AUK",
                      short: "32A",
                      long: "32A",
                      display: "32A",
                      display_with_size_set: "32A",
                      display_with_size_system: "UK 32A",
                      display_with_system_and_set: "UK 32A",
                      equivalent_sizes: {
                        us: ["32A"],
                        au: ["10AAU"],
                        eu: ["70BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32BUK",
                      short: "32B",
                      long: "32B",
                      display: "32B",
                      display_with_size_set: "32B",
                      display_with_size_system: "UK 32B",
                      display_with_system_and_set: "UK 32B",
                      equivalent_sizes: {
                        us: ["32B"],
                        au: ["10BAU"],
                        eu: ["70CEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32CUK",
                      short: "32C",
                      long: "32C",
                      display: "32C",
                      display_with_size_set: "32C",
                      display_with_size_system: "UK 32C",
                      display_with_system_and_set: "UK 32C",
                      equivalent_sizes: {
                        us: ["32C"],
                        au: ["10CAU"],
                        eu: ["70DEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32DUK",
                      short: "32D",
                      long: "32D",
                      display: "32D",
                      display_with_size_set: "32D",
                      display_with_size_system: "UK 32D",
                      display_with_system_and_set: "UK 32D",
                      equivalent_sizes: {
                        us: ["32D"],
                        au: ["10DAU"],
                        eu: ["70EEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32E (DD)UK",
                      short: "32E (DD)",
                      long: "32E (DD)",
                      display: "32E (DD)",
                      display_with_size_set: "32E (DD)",
                      display_with_size_system: "UK 32E (DD)",
                      display_with_system_and_set: "UK 32E (DD)",
                      equivalent_sizes: {
                        us: ["32E (DD)"],
                        au: ["10EAU"],
                        eu: ["70FEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32F (3D)UK",
                      short: "32F (3D)",
                      long: "32F (3D)",
                      display: "32F (3D)",
                      display_with_size_set: "32F (3D)",
                      display_with_size_system: "UK 32F (3D)",
                      display_with_system_and_set: "UK 32F (3D)",
                      equivalent_sizes: {
                        us: ["32F (3D)"],
                        au: ["10F(DDD)AU"],
                        eu: ["70GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32G (4D)UK",
                      short: "32G (4D)",
                      long: "32G (4D)",
                      display: "32G (4D)",
                      display_with_size_set: "32G (4D)",
                      display_with_size_system: "UK 32G (4D)",
                      display_with_system_and_set: "UK 32G (4D)",
                      equivalent_sizes: {
                        us: ["32G (4D)"],
                        au: ["10G (4D)AU"],
                        eu: ["70H (4D)EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32H (5D)UK",
                      short: "32H (5D)",
                      long: "32H (5D)",
                      display: "32H (5D)",
                      display_with_size_set: "32H (5D)",
                      display_with_size_system: "UK 32H (5D)",
                      display_with_system_and_set: "UK 32H (5D)",
                      equivalent_sizes: {
                        us: ["32H (5D)"],
                        au: ["10H (5D)AU"],
                        eu: ["70I (5D)EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34AAUK",
                      short: "34AA",
                      long: "34AA",
                      display: "34AA",
                      display_with_size_set: "34AA",
                      display_with_size_system: "UK 34AA",
                      display_with_system_and_set: "UK 34AA",
                      equivalent_sizes: {
                        us: ["34AA"],
                        au: ["12AAAU"],
                        eu: ["75AEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34AUK",
                      short: "34A",
                      long: "34A",
                      display: "34A",
                      display_with_size_set: "34A",
                      display_with_size_system: "UK 34A",
                      display_with_system_and_set: "UK 34A",
                      equivalent_sizes: {
                        us: ["34A"],
                        au: ["12AAU"],
                        eu: ["75BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34BUK",
                      short: "34B",
                      long: "34B",
                      display: "34B",
                      display_with_size_set: "34B",
                      display_with_size_system: "UK 34B",
                      display_with_system_and_set: "UK 34B",
                      equivalent_sizes: {
                        us: ["34B"],
                        au: ["12BAU"],
                        eu: ["75CEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34CUK",
                      short: "34C",
                      long: "34C",
                      display: "34C",
                      display_with_size_set: "34C",
                      display_with_size_system: "UK 34C",
                      display_with_system_and_set: "UK 34C",
                      equivalent_sizes: {
                        us: ["34C"],
                        au: ["12CAU"],
                        eu: ["75DEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34DUK",
                      short: "34D",
                      long: "34D",
                      display: "34D",
                      display_with_size_set: "34D",
                      display_with_size_system: "UK 34D",
                      display_with_system_and_set: "UK 34D",
                      equivalent_sizes: {
                        us: ["34D"],
                        au: ["12DAU"],
                        eu: ["75EEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34E (DD)UK",
                      short: "34E (DD)",
                      long: "34E (DD)",
                      display: "34E (DD)",
                      display_with_size_set: "34E (DD)",
                      display_with_size_system: "UK 34E (DD)",
                      display_with_system_and_set: "UK 34E (DD)",
                      equivalent_sizes: {
                        us: ["34E (DD)"],
                        au: ["12EAU"],
                        eu: ["75FEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34F (3D)UK",
                      short: "34F (3D)",
                      long: "34F (3D)",
                      display: "34F (3D)",
                      display_with_size_set: "34F (3D)",
                      display_with_size_system: "UK 34F (3D)",
                      display_with_system_and_set: "UK 34F (3D)",
                      equivalent_sizes: {
                        us: ["34F (3D)"],
                        au: ["12FAU"],
                        eu: ["75GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34G (4D)UK",
                      short: "34G (4D)",
                      long: "34G (4D)",
                      display: "34G (4D)",
                      display_with_size_set: "34G (4D)",
                      display_with_size_system: "UK 34G (4D)",
                      display_with_system_and_set: "UK 34G (4D)",
                      equivalent_sizes: {
                        us: ["34G (4D)"],
                        au: ["12G (D)AU"],
                        eu: ["75H (D)EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34H (5D)UK",
                      short: "34H (5D)",
                      long: "34H (5D)",
                      display: "34H (5D)",
                      display_with_size_set: "34H (5D)",
                      display_with_size_system: "UK 34H (5D)",
                      display_with_system_and_set: "UK 34H (5D)",
                      equivalent_sizes: {
                        us: ["34H (5D)"],
                        au: ["12H (5D)AU"],
                        eu: ["75I (5D)EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36AAUK",
                      short: "36AA",
                      long: "36AA",
                      display: "36AA",
                      display_with_size_set: "36AA",
                      display_with_size_system: "UK 36AA",
                      display_with_system_and_set: "UK 36AA",
                      equivalent_sizes: {
                        us: ["36AA"],
                        au: ["14AAAU"],
                        eu: ["80AEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36AUK",
                      short: "36A",
                      long: "36A",
                      display: "36A",
                      display_with_size_set: "36A",
                      display_with_size_system: "UK 36A",
                      display_with_system_and_set: "UK 36A",
                      equivalent_sizes: {
                        us: ["36A"],
                        au: ["14AAU"],
                        eu: ["80BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36BUK",
                      short: "36B",
                      long: "36B",
                      display: "36B",
                      display_with_size_set: "36B",
                      display_with_size_system: "UK 36B",
                      display_with_system_and_set: "UK 36B",
                      equivalent_sizes: {
                        us: ["36B"],
                        au: ["14BAU"],
                        eu: ["80CEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36CUK",
                      short: "36C",
                      long: "36C",
                      display: "36C",
                      display_with_size_set: "36C",
                      display_with_size_system: "UK 36C",
                      display_with_system_and_set: "UK 36C",
                      equivalent_sizes: {
                        us: ["36C"],
                        au: ["14CAU"],
                        eu: ["80DEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36DUK",
                      short: "36D",
                      long: "36D",
                      display: "36D",
                      display_with_size_set: "36D",
                      display_with_size_system: "UK 36D",
                      display_with_system_and_set: "UK 36D",
                      equivalent_sizes: {
                        us: ["36D"],
                        au: ["14DAU"],
                        eu: ["80EEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36E (DD)UK",
                      short: "36E (DD)",
                      long: "36E (DD)",
                      display: "36E (DD)",
                      display_with_size_set: "36E (DD)",
                      display_with_size_system: "UK 36E (DD)",
                      display_with_system_and_set: "UK 36E (DD)",
                      equivalent_sizes: {
                        us: ["36E (DD)"],
                        au: ["14EAU"],
                        eu: ["80FEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36F (3D)UK",
                      short: "36F (3D)",
                      long: "36F (3D)",
                      display: "36F (3D)",
                      display_with_size_set: "36F (3D)",
                      display_with_size_system: "UK 36F (3D)",
                      display_with_system_and_set: "UK 36F (3D)",
                      equivalent_sizes: {
                        us: ["36F (3D)"],
                        au: ["14FAU"],
                        eu: ["80GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36G (4D)UK",
                      short: "36G (4D)",
                      long: "36G (4D)",
                      display: "36G (4D)",
                      display_with_size_set: "36G (4D)",
                      display_with_size_system: "UK 36G (4D)",
                      display_with_system_and_set: "UK 36G (4D)",
                      equivalent_sizes: {
                        us: ["36G (4D)"],
                        au: ["14G (4D)AU"],
                        eu: ["80H (4D)EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36H (5D)UK",
                      short: "36H (5D)",
                      long: "36H (5D)",
                      display: "36H (5D)",
                      display_with_size_set: "36H (5D)",
                      display_with_size_system: "UK 36H (5D)",
                      display_with_system_and_set: "UK 36H (5D)",
                      equivalent_sizes: {
                        us: ["36H (5D)"],
                        au: ["14H (5D)AU"],
                        eu: ["80I (5D)EU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "1100579fd97b4e26a60056e3",
              name: "Plus",
              tags: ["plus"],
              category_id: "00208975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Band ", "Cup "],
                  ["34-36", "38-39"],
                  ["34-36", "39-40"],
                  ["34-36", "40-41"],
                  ["34-36", "41-42"],
                  ["34-36", "42-43"],
                  ["34-36", "43-44"],
                  ["34-36", "44-45"],
                  ["34-36", "45-46"],
                  ["36-38", "40-41"],
                  ["36-38", "41-42"],
                  ["36-38", "42-43"],
                  ["36-38", "43-44"],
                  ["36-38", "44-45"],
                  ["36-38", "45-46"],
                  ["36-38", "46-47"],
                  ["36-38", "47-48"],
                  ["38-40", "43-44"],
                  ["38-40", "44-45"],
                  ["38-40", "45-46"],
                  ["38-40", "46-47"],
                  ["38-40", "47-48"],
                  ["38-40", "48-49"],
                  ["38-40", "49-50"],
                  ["40-42", "45-46"],
                  ["40-42", "46-47"],
                  ["40-42", "47-48"],
                  ["40-42", "48-49"],
                  ["40-42", "49-50"],
                  ["40-42", "50-51"],
                  ["40-42", "51-52"],
                  ["42-44", "47-48"],
                  ["42-44", "48-49"],
                  ["42-44", "49-50"],
                  ["42-44", "50-51"],
                  ["42-44", "51-52"],
                  ["42-44", "52-53"],
                  ["44-46", "49-50"],
                  ["44-46", "50-51"],
                  ["44-46", "51-52"],
                  ["44-46", "52-53"],
                  ["44-46", "53-54"],
                  ["44-46", "54-55"],
                ],
                centimeters: [
                  ["Band ", "Cup "],
                  ["86.4-91.4", "96.5-99.1"],
                  ["86.4-91.4", "99.1-101.6"],
                  ["86.4-91.4", "101.6-104.1"],
                  ["86.4-91.4", "104.1-106.7"],
                  ["86.4-91.4", "106.7-109.2"],
                  ["86.4-91.4", "109.2-111.8"],
                  ["86.4-91.4", "111.8-114.3"],
                  ["86.4-91.4", "114.3-116.8"],
                  ["91.4-96.5", "101.6-104.1"],
                  ["91.4-96.5", "104.1-106.7"],
                  ["91.4-96.5", "106.7-109.2"],
                  ["91.4-96.5", "109.2-111.8"],
                  ["91.4-96.5", "111.8-114.3"],
                  ["91.4-96.5", "114.3-116.8"],
                  ["91.4-96.5", "116.8-119.4"],
                  ["91.4-96.5", "119.4-121.9"],
                  ["96.5-101.6", "109.2-111.8"],
                  ["96.5-101.6", "111.8-114.3"],
                  ["96.5-101.6", "114.3-116.8"],
                  ["96.5-101.6", "116.8-119.4"],
                  ["96.5-101.6", "119.4-121.9"],
                  ["96.5-101.6", "121.9-124.5"],
                  ["96.5-101.6", "124.5-127"],
                  ["101.6-106.7", "114.3-116.8"],
                  ["101.6-106.7", "116.8-119.4"],
                  ["101.6-106.7", "119.4-121.9"],
                  ["101.6-106.7", "121.9-124.5"],
                  ["101.6-106.7", "124.5-127"],
                  ["101.6-106.7", "127-129.5"],
                  ["101.6-106.7", "129.5-132.1"],
                  ["106.7-111.8", "119.4-121.9"],
                  ["106.7-111.8", "121.9-124.5"],
                  ["106.7-111.8", "124.5-127"],
                  ["106.7-111.8", "127-129.5"],
                  ["106.7-111.8", "129.5-132.1"],
                  ["106.7-111.8", "132.1-134.6"],
                  ["111.8-116.9", "124.5-127"],
                  ["111.8-116.9", "127-129.5"],
                  ["111.8-116.9", "129.5-132.1"],
                  ["111.8-116.9", "132.1-134.6"],
                  ["111.8-116.9", "134.6-137.2"],
                  ["111.8-116.9", "137.2-139.7"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["38A", "16A", "85B", "38A"],
                  ["38B", "16B", "85C", "38B"],
                  ["38C", "16C", "85D", "38C"],
                  ["38D", "16D", "85E", "38D"],
                  ["38E (DD)", "16E", "85F", "38E (DD)"],
                  ["38F (3D)", "16F", "85G", "38F (3D)"],
                  ["38G (4D)", "16G", "85H", "38G (4D)"],
                  ["38H (5D)", "16H", "85I", "38H (5D)"],
                  ["40A", "18A", "90B", "40A"],
                  ["40B", "18B", "90C", "40B"],
                  ["40C", "18C", "90D", "40C"],
                  ["40D", "18D", "90E", "40D"],
                  ["40E (DD)", "18E", "90F", "40E (DD)"],
                  ["40F (3D)", "18F", "90G", "40F (3D)"],
                  ["40G (4D)", "18G", "90H", "40G (4D)"],
                  ["40H (5D)", "18H", "90I", "40H (5D)"],
                  ["42B", "20B", "95C", "42B"],
                  ["42C", "20C", "95D", "42C"],
                  ["42D", "20D", "95E", "42D"],
                  ["42E (DD)", "20E", "95F", "42E (DD)"],
                  ["42F (3D)", "20F", "95G", "42F (3D)"],
                  ["42G (4D)", "20G", "95H", "42G (4D)"],
                  ["42H (5D)", "20H", "95I", "42H (5D)"],
                  ["44B", "22B", "100C", "44B"],
                  ["44C", "22C", "100D", "44C"],
                  ["44D", "22D", "100E", "44D"],
                  ["44E (DD)", "22E", "100F", "44E (DD)"],
                  ["44F (3D)", "22F", "100G", "44F (3D)"],
                  ["44G (4D)", "22G", "100H", "44G (4D)"],
                  ["44H (5D)", "22H", "100I", "44H (5D)"],
                  ["46B", "24B", "105C", "46B"],
                  ["46C", "24C", "105D", "46C"],
                  ["46D", "24D", "105E", "46D"],
                  ["46DD", "24DD", "105F", "46DD"],
                  ["46F (3D)", "24F", "105G", "46F (3D)"],
                  ["46G (4D)", "24G", "105H", "46G (4D)"],
                  ["48B", "26B", "110C", "48B"],
                  ["48C", "26C", "110D", "48C"],
                  ["48D", "26D", "110E", "48D"],
                  ["48DD", "26DD", "110F", "48DD"],
                  ["48F (3D)", "26F", "110G", "48F (3D)"],
                  ["48G (4D)", "26G", "110H", "48G (4D)"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
                ["38A", "16A", "85B", "38A"],
                ["38B", "16B", "85C", "38B"],
                ["38C", "16C", "85D", "38C"],
                ["38D", "16D", "85E", "38D"],
                ["38E (DD)", "16E", "85F", "38E (DD)"],
                ["38F (3D)", "16F", "85G", "38F (3D)"],
                ["38G (4D)", "16G", "85H", "38G (4D)"],
                ["38H (5D)", "16H", "85I", "38H (5D)"],
                ["40A", "18A", "90B", "40A"],
                ["40B", "18B", "90C", "40B"],
                ["40C", "18C", "90D", "40C"],
                ["40D", "18D", "90E", "40D"],
                ["40E (DD)", "18E", "90F", "40E (DD)"],
                ["40F (3D)", "18F", "90G", "40F (3D)"],
                ["40G (4D)", "18G", "90H", "40G (4D)"],
                ["40H (5D)", "18H", "90I", "40H (5D)"],
                ["42A", "-", "-", "42A"],
                ["42B", "20B", "95C", "42B"],
                ["42C", "20C", "95D", "42C"],
                ["42D", "20D", "95E", "42D"],
                ["42E (DD)", "20E", "95F", "42E (DD)"],
                ["42F (3D)", "20F", "95G", "42F (3D)"],
                ["42G (4D)", "20G", "95H", "42G (4D)"],
                ["42H (5D)", "20H", "95I", "42H (5D)"],
                ["44A", "-", "-", "44A"],
                ["44B", "22B", "100C", "44B"],
                ["44C", "22C", "100D", "44C"],
                ["44D", "22D", "100E", "44D"],
                ["44E (DD)", "22E", "100F", "44E (DD)"],
                ["44F (3D)", "22F", "100G", "44F (3D)"],
                ["44G (4D)", "22G", "100H", "44G (4D)"],
                ["44H (5D)", "22H", "100I", "44H (5D)"],
                ["46A", "-", "-", "46A"],
                ["46B", "24B", "105C", "46B"],
                ["46C", "24C", "105D", "46C"],
                ["46D", "24D", "105E", "46D"],
                ["46DD", "24DD", "105F", "46DD"],
                ["46F (3D)", "24F", "105G", "46F (3D)"],
                ["46G (4D)", "24G", "105H", "46G (4D)"],
                ["46H (5D)", "-", "-", "46H (5D)"],
                ["48A", "-", "-", "48A"],
                ["48B", "26B", "110C", "48B"],
                ["48C", "26C", "110D", "48C"],
                ["48D", "26D", "110E", "48D"],
                ["48DD", "26DD", "110F", "48DD"],
                ["48F (3D)", "26F", "110G", "48F (3D)"],
                ["48G (4D)", "26G", "110H", "48G (4D)"],
                ["48H (5D)", "-", "-", "48H (5D)"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38A",
                      short: "38A",
                      long: "38A",
                      display: "38A",
                      display_with_size_set: "38A",
                      display_with_size_system: "US 38A",
                      display_with_system_and_set: "US 38A",
                      equivalent_sizes: {
                        au: ["16AAU"],
                        eu: ["85BEU"],
                        uk: ["38AUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38B",
                      short: "38B",
                      long: "38B",
                      display: "38B",
                      display_with_size_set: "38B",
                      display_with_size_system: "US 38B",
                      display_with_system_and_set: "US 38B",
                      equivalent_sizes: {
                        au: ["16BAU"],
                        eu: ["85CEU"],
                        uk: ["38BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38C",
                      short: "38C",
                      long: "38C",
                      display: "38C",
                      display_with_size_set: "38C",
                      display_with_size_system: "US 38C",
                      display_with_system_and_set: "US 38C",
                      equivalent_sizes: {
                        au: ["16CAU"],
                        eu: ["85DEU"],
                        uk: ["38CUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38D",
                      short: "38D",
                      long: "38D",
                      display: "38D",
                      display_with_size_set: "38D",
                      display_with_size_system: "US 38D",
                      display_with_system_and_set: "US 38D",
                      equivalent_sizes: {
                        au: ["16DAU"],
                        eu: ["85EEU"],
                        uk: ["38DUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38E (DD)",
                      short: "38E (DD)",
                      long: "38E (DD)",
                      display: "38E (DD)",
                      display_with_size_set: "38E (DD)",
                      display_with_size_system: "US 38E",
                      display_with_system_and_set: "US 38E (DD)",
                      equivalent_sizes: {
                        au: ["16EAU"],
                        eu: ["85FEU"],
                        uk: ["38E (DD)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38F (3D)",
                      short: "38F (3D)",
                      long: "38F (3D)",
                      display: "38F (3D)",
                      display_with_size_set: "38F (3D)",
                      display_with_size_system: "US 38F",
                      display_with_system_and_set: "US 38F (3D)",
                      equivalent_sizes: {
                        au: ["16FAU"],
                        eu: ["85GEU"],
                        uk: ["38F (3D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38G (4D)",
                      short: "38G (4D)",
                      long: "38G (4D)",
                      display: "38G (4D)",
                      display_with_size_set: "38G (4D)",
                      display_with_size_system: "US 38G",
                      display_with_system_and_set: "US 38G (4D)",
                      equivalent_sizes: {
                        au: ["16G (4D)AU"],
                        eu: ["85H (4D)EU"],
                        uk: ["38G (4D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38H (5D)",
                      short: "38H (5D)",
                      long: "38H (5D)",
                      display: "38H (5D)",
                      display_with_size_set: "38H (5D)",
                      display_with_size_system: "US 38H",
                      display_with_system_and_set: "US 38H (5D)",
                      equivalent_sizes: {
                        au: ["16H (5D)AU"],
                        eu: ["85I (5D)EU"],
                        uk: ["38H (5D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40A",
                      short: "40A",
                      long: "40A",
                      display: "40A",
                      display_with_size_set: "40A",
                      display_with_size_system: "US 40A",
                      display_with_system_and_set: "US 40A",
                      equivalent_sizes: {
                        au: ["18AAU"],
                        eu: ["90BEU"],
                        uk: ["40AUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40B",
                      short: "40B",
                      long: "40B",
                      display: "40B",
                      display_with_size_set: "40B",
                      display_with_size_system: "US 40B",
                      display_with_system_and_set: "US 40B",
                      equivalent_sizes: {
                        au: ["18BAU"],
                        eu: ["90CEU"],
                        uk: ["40BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40C",
                      short: "40C",
                      long: "40C",
                      display: "40C",
                      display_with_size_set: "40C",
                      display_with_size_system: "US 40C",
                      display_with_system_and_set: "US 40C",
                      equivalent_sizes: {
                        au: ["18CAU"],
                        eu: ["90DEU"],
                        uk: ["40CUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40D",
                      short: "40D",
                      long: "40D",
                      display: "40D",
                      display_with_size_set: "40D",
                      display_with_size_system: "US 40D",
                      display_with_system_and_set: "US 40D",
                      equivalent_sizes: {
                        au: ["18DAU"],
                        eu: ["90EEU"],
                        uk: ["40DUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40E (DD)",
                      short: "40E (DD)",
                      long: "40E (DD)",
                      display: "40E (DD)",
                      display_with_size_set: "40E (DD)",
                      display_with_size_system: "US 40E",
                      display_with_system_and_set: "US 40E (DD)",
                      equivalent_sizes: {
                        au: ["18EAU"],
                        eu: ["90FEU"],
                        uk: ["40E (DD)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40F (3D)",
                      short: "40F (3D)",
                      long: "40F (3D)",
                      display: "40F (3D)",
                      display_with_size_set: "40F (3D)",
                      display_with_size_system: "US 40F",
                      display_with_system_and_set: "US 40F (3D)",
                      equivalent_sizes: {
                        au: ["18FAU"],
                        eu: ["90GEU"],
                        uk: ["40F (3D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40G (4D)",
                      short: "40G (4D)",
                      long: "40G (4D)",
                      display: "40G (4D)",
                      display_with_size_set: "40G (4D)",
                      display_with_size_system: "US 40G",
                      display_with_system_and_set: "US 40G (4D)",
                      equivalent_sizes: {
                        au: ["18G (D)AU"],
                        eu: ["90H (D)EU"],
                        uk: ["40G (4D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40H (5D)",
                      short: "40H (5D)",
                      long: "40H (5D)",
                      display: "40H (5D)",
                      display_with_size_set: "40H (5D)",
                      display_with_size_system: "US 40H",
                      display_with_system_and_set: "US 40H (5D)",
                      equivalent_sizes: {
                        au: ["18H (5D)AU"],
                        eu: ["90I (5D)EU"],
                        uk: ["40H (5D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42A",
                      short: "42A",
                      long: "42A",
                      display: "42A",
                      display_with_size_set: "42A",
                      display_with_size_system: "US 42A",
                      display_with_system_and_set: "US 42A",
                      equivalent_sizes: {
                        uk: ["42AUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42B",
                      short: "42B",
                      long: "42B",
                      display: "42B",
                      display_with_size_set: "42B",
                      display_with_size_system: "US 42B",
                      display_with_system_and_set: "US 42B",
                      equivalent_sizes: {
                        au: ["20BAU"],
                        eu: ["95CEU"],
                        uk: ["42BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42C",
                      short: "42C",
                      long: "42C",
                      display: "42C",
                      display_with_size_set: "42C",
                      display_with_size_system: "US 42C",
                      display_with_system_and_set: "US 42C",
                      equivalent_sizes: {
                        au: ["20CAU"],
                        eu: ["95DEU"],
                        uk: ["42CUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42D",
                      short: "42D",
                      long: "42D",
                      display: "42D",
                      display_with_size_set: "42D",
                      display_with_size_system: "US 42D",
                      display_with_system_and_set: "US 42D",
                      equivalent_sizes: {
                        au: ["20DAU"],
                        eu: ["95EEU"],
                        uk: ["42DUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42E (DD)",
                      short: "42E (DD)",
                      long: "42E (DD)",
                      display: "42E (DD)",
                      display_with_size_set: "42E (DD)",
                      display_with_size_system: "US 42E",
                      display_with_system_and_set: "US 42E (DD)",
                      equivalent_sizes: {
                        au: ["20EAU"],
                        eu: ["95FEU"],
                        uk: ["42E (DD)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42F (3D)",
                      short: "42F (3D)",
                      long: "42F (3D)",
                      display: "42F (3D)",
                      display_with_size_set: "42F (3D)",
                      display_with_size_system: "US 42F",
                      display_with_system_and_set: "US 42F (3D)",
                      equivalent_sizes: {
                        au: ["20FAU"],
                        eu: ["95GEU"],
                        uk: ["42F (3D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42G (4D)",
                      short: "42G (4D)",
                      long: "42G (4D)",
                      display: "42G (4D)",
                      display_with_size_set: "42G (4D)",
                      display_with_size_system: "US 42G",
                      display_with_system_and_set: "US 42G (4D)",
                      equivalent_sizes: {
                        au: ["20G (D)AU"],
                        eu: ["95H (D)EU"],
                        uk: ["42G (4D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42H (5D)",
                      short: "42H (5D)",
                      long: "42H (5D)",
                      display: "42H (5D)",
                      display_with_size_set: "42H (5D)",
                      display_with_size_system: "US 42H",
                      display_with_system_and_set: "US 42H (5D)",
                      equivalent_sizes: {
                        au: ["20H (5D)AU"],
                        eu: ["95I (5D)EU"],
                        uk: ["42H (5D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44A",
                      short: "44A",
                      long: "44A",
                      display: "44A",
                      display_with_size_set: "44A",
                      display_with_size_system: "US 44A",
                      display_with_system_and_set: "US 44A",
                      equivalent_sizes: {
                        uk: ["44AUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44B",
                      short: "44B",
                      long: "44B",
                      display: "44B",
                      display_with_size_set: "44B",
                      display_with_size_system: "US 44B",
                      display_with_system_and_set: "US 44B",
                      equivalent_sizes: {
                        au: ["22BAU"],
                        eu: ["100CEU"],
                        uk: ["44BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44C",
                      short: "44C",
                      long: "44C",
                      display: "44C",
                      display_with_size_set: "44C",
                      display_with_size_system: "US 44C",
                      display_with_system_and_set: "US 44C",
                      equivalent_sizes: {
                        au: ["22CAU"],
                        eu: ["100DEU"],
                        uk: ["44CUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44D",
                      short: "44D",
                      long: "44D",
                      display: "44D",
                      display_with_size_set: "44D",
                      display_with_size_system: "US 44D",
                      display_with_system_and_set: "US 44D",
                      equivalent_sizes: {
                        au: ["22DAU"],
                        eu: ["100EEU"],
                        uk: ["44DUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44E (DD)",
                      short: "44E (DD)",
                      long: "44E (DD)",
                      display: "44E (DD)",
                      display_with_size_set: "44E (DD)",
                      display_with_size_system: "US 44E",
                      display_with_system_and_set: "US 44E (DD)",
                      equivalent_sizes: {
                        au: ["22EAU"],
                        eu: ["100FEU"],
                        uk: ["44E (DD)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44F (3D)",
                      short: "44F (3D)",
                      long: "44F (3D)",
                      display: "44F (3D)",
                      display_with_size_set: "44F (3D)",
                      display_with_size_system: "US 44F",
                      display_with_system_and_set: "US 44F (3D)",
                      equivalent_sizes: {
                        au: ["22FAU"],
                        eu: ["100GEU"],
                        uk: ["44F (3D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44G (4D)",
                      short: "44G (4D)",
                      long: "44G (4D)",
                      display: "44G (4D)",
                      display_with_size_set: "44G (4D)",
                      display_with_size_system: "US 44G",
                      display_with_system_and_set: "US 44G (4D)",
                      equivalent_sizes: {
                        au: ["22G (D)AU"],
                        eu: ["100H (D)EU"],
                        uk: ["44G (4D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44H (5D)",
                      short: "44H (5D)",
                      long: "44H (5D)",
                      display: "44H (5D)",
                      display_with_size_set: "44H (5D)",
                      display_with_size_system: "US 44H",
                      display_with_system_and_set: "US 44H (5D)",
                      equivalent_sizes: {
                        au: ["22H (5D)AU"],
                        eu: ["100I (5D)EU"],
                        uk: ["44H (5D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46A",
                      short: "46A",
                      long: "46A",
                      display: "46A",
                      display_with_size_set: "46A",
                      display_with_size_system: "US 46A",
                      display_with_system_and_set: "US 46A",
                      equivalent_sizes: {
                        uk: ["46AUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46B",
                      short: "46B",
                      long: "46B",
                      display: "46B",
                      display_with_size_set: "46B",
                      display_with_size_system: "US 46B",
                      display_with_system_and_set: "US 46B",
                      equivalent_sizes: {
                        au: ["24BAU"],
                        eu: ["105CEU"],
                        uk: ["46BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46C",
                      short: "46C",
                      long: "46C",
                      display: "46C",
                      display_with_size_set: "46C",
                      display_with_size_system: "US 46C",
                      display_with_system_and_set: "US 46C",
                      equivalent_sizes: {
                        au: ["24CAU"],
                        eu: ["105DEU"],
                        uk: ["46CUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46D",
                      short: "46D",
                      long: "46D",
                      display: "46D",
                      display_with_size_set: "46D",
                      display_with_size_system: "US 46D",
                      display_with_system_and_set: "US 46D",
                      equivalent_sizes: {
                        au: ["24DAU"],
                        eu: ["105EEU"],
                        uk: ["46DUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46DD",
                      short: "46DD",
                      long: "46DD",
                      display: "46DD",
                      display_with_size_set: "46DD",
                      display_with_size_system: "US 46DD",
                      display_with_system_and_set: "US 46DD",
                      equivalent_sizes: {
                        au: ["24DDAU"],
                        eu: ["105FEU"],
                        uk: ["46DDUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46F (3D)",
                      short: "46F (3D)",
                      long: "46F (3D)",
                      display: "46F (3D)",
                      display_with_size_set: "46F (3D)",
                      display_with_size_system: "US 46F",
                      display_with_system_and_set: "US 46F (3D)",
                      equivalent_sizes: {
                        au: ["24FAU"],
                        eu: ["105GEU"],
                        uk: ["46F (3D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46G (4D)",
                      short: "46G (4D)",
                      long: "46G (4D)",
                      display: "46G (4D)",
                      display_with_size_set: "46G (4D)",
                      display_with_size_system: "US 46G",
                      display_with_system_and_set: "US 46G (4D)",
                      equivalent_sizes: {
                        au: ["24G (D)AU"],
                        eu: ["105H (D)EU"],
                        uk: ["46G (4D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46H (5D)",
                      short: "46H (5D)",
                      long: "46H (5D)",
                      display: "46H (5D)",
                      display_with_size_set: "46H (5D)",
                      display_with_size_system: "US 46H",
                      display_with_system_and_set: "US 46H (5D)",
                      equivalent_sizes: {
                        uk: ["46H (5D)UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48A",
                      short: "48A",
                      long: "48A",
                      display: "48A",
                      display_with_size_set: "48A",
                      display_with_size_system: "US 48A",
                      display_with_system_and_set: "US 48A",
                      equivalent_sizes: {
                        uk: ["48AUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48B",
                      short: "48B",
                      long: "48B",
                      display: "48B",
                      display_with_size_set: "48B",
                      display_with_size_system: "US 48B",
                      display_with_system_and_set: "US 48B",
                      equivalent_sizes: {
                        au: ["26BAU"],
                        eu: ["110CEU"],
                        uk: ["48BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48C",
                      short: "48C",
                      long: "48C",
                      display: "48C",
                      display_with_size_set: "48C",
                      display_with_size_system: "US 48C",
                      display_with_system_and_set: "US 48C",
                      equivalent_sizes: {
                        au: ["26CAU"],
                        eu: ["110DEU"],
                        uk: ["48CUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48D",
                      short: "48D",
                      long: "48D",
                      display: "48D",
                      display_with_size_set: "48D",
                      display_with_size_system: "US 48D",
                      display_with_system_and_set: "US 48D",
                      equivalent_sizes: {
                        au: ["26DAU"],
                        eu: ["110EEU"],
                        uk: ["48DUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48DD",
                      short: "48DD",
                      long: "48DD",
                      display: "48DD",
                      display_with_size_set: "48DD",
                      display_with_size_system: "US 48DD",
                      display_with_system_and_set: "US 48DD",
                      equivalent_sizes: {
                        au: ["26DDAU"],
                        eu: ["110FEU"],
                        uk: ["48DDUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48F",
                      short: "48F",
                      long: "48F (3D)",
                      display: "48F (3D)",
                      display_with_size_set: "48F",
                      display_with_size_system: "US 48F",
                      display_with_system_and_set: "US 48F",
                      equivalent_sizes: {
                        au: ["26FAU"],
                        eu: ["110GEU"],
                        uk: ["48FUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48G",
                      short: "48G",
                      long: "48G (4D)",
                      display: "48G (4D)",
                      display_with_size_set: "48G",
                      display_with_size_system: "US 48G",
                      display_with_system_and_set: "US 48G",
                      equivalent_sizes: {
                        au: ["26GAU"],
                        eu: ["110HEU"],
                        uk: ["48GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48H (5D)",
                      short: "48H (5D)",
                      long: "48H (5D)",
                      display: "48H (5D)",
                      display_with_size_set: "48H (5D)",
                      display_with_size_system: "US 48H",
                      display_with_system_and_set: "US 48H (5D)",
                      equivalent_sizes: {
                        uk: ["48H (5D)UK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "16AAU",
                      short: "16A",
                      long: "16A",
                      display: "16A",
                      display_with_size_set: "16A",
                      display_with_size_system: "AU 16A",
                      display_with_system_and_set: "AU 16A",
                      equivalent_sizes: {
                        us: ["38A"],
                        eu: ["85BEU"],
                        uk: ["38AUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16BAU",
                      short: "16B",
                      long: "16B",
                      display: "16B",
                      display_with_size_set: "16B",
                      display_with_size_system: "AU 16B",
                      display_with_system_and_set: "AU 16B",
                      equivalent_sizes: {
                        us: ["38B"],
                        eu: ["85CEU"],
                        uk: ["38BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16CAU",
                      short: "16C",
                      long: "16C",
                      display: "16C",
                      display_with_size_set: "16C",
                      display_with_size_system: "AU 16C",
                      display_with_system_and_set: "AU 16C",
                      equivalent_sizes: {
                        us: ["38C"],
                        eu: ["85DEU"],
                        uk: ["38CUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16DAU",
                      short: "16D",
                      long: "16D",
                      display: "16D",
                      display_with_size_set: "16D",
                      display_with_size_system: "AU 16D",
                      display_with_system_and_set: "AU 16D",
                      equivalent_sizes: {
                        us: ["38D"],
                        eu: ["85EEU"],
                        uk: ["38DUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16EAU",
                      short: "16E (DD)",
                      long: "16E",
                      display: "16E",
                      display_with_size_set: "16E (DD)",
                      display_with_size_system: "AU 16E",
                      display_with_system_and_set: "AU 16E (DD)",
                      equivalent_sizes: {
                        us: ["38E (DD)"],
                        eu: ["85FEU"],
                        uk: ["38E (DD)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16FAU",
                      short: "16F (3D)",
                      long: "16F",
                      display: "16F",
                      display_with_size_set: "16F (3D)",
                      display_with_size_system: "AU 16F",
                      display_with_system_and_set: "AU 16F (3D)",
                      equivalent_sizes: {
                        us: ["38F (3D)"],
                        eu: ["85GEU"],
                        uk: ["38F (3D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16G (4D)AU",
                      short: "16G (4D)",
                      long: "16G",
                      display: "16G",
                      display_with_size_set: "16G (4D)",
                      display_with_size_system: "AU 16G",
                      display_with_system_and_set: "AU 16G (4D)",
                      equivalent_sizes: {
                        us: ["38G (4D)"],
                        eu: ["85H (4D)EU"],
                        uk: ["38G (4D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16H (5D)AU",
                      short: "16H (5D)",
                      long: "16H",
                      display: "16H",
                      display_with_size_set: "16H (5D)",
                      display_with_size_system: "AU 16H",
                      display_with_system_and_set: "AU 16H (5D)",
                      equivalent_sizes: {
                        us: ["38H (5D)"],
                        eu: ["85I (5D)EU"],
                        uk: ["38H (5D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18AAU",
                      short: "18A",
                      long: "18A",
                      display: "18A",
                      display_with_size_set: "18A",
                      display_with_size_system: "AU 18A",
                      display_with_system_and_set: "AU 18A",
                      equivalent_sizes: {
                        us: ["40A"],
                        eu: ["90BEU"],
                        uk: ["40AUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18BAU",
                      short: "18B",
                      long: "18B",
                      display: "18B",
                      display_with_size_set: "18B",
                      display_with_size_system: "AU 18B",
                      display_with_system_and_set: "AU 18B",
                      equivalent_sizes: {
                        us: ["40B"],
                        eu: ["90CEU"],
                        uk: ["40BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18CAU",
                      short: "18C",
                      long: "18C",
                      display: "18C",
                      display_with_size_set: "18C",
                      display_with_size_system: "AU 18C",
                      display_with_system_and_set: "AU 18C",
                      equivalent_sizes: {
                        us: ["40C"],
                        eu: ["90DEU"],
                        uk: ["40CUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18DAU",
                      short: "18D",
                      long: "18D",
                      display: "18D",
                      display_with_size_set: "18D",
                      display_with_size_system: "AU 18D",
                      display_with_system_and_set: "AU 18D",
                      equivalent_sizes: {
                        us: ["40D"],
                        eu: ["90EEU"],
                        uk: ["40DUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18EAU",
                      short: "18E (DD)",
                      long: "18E",
                      display: "18E",
                      display_with_size_set: "18E (DD)",
                      display_with_size_system: "AU 18E",
                      display_with_system_and_set: "AU 18E (DD)",
                      equivalent_sizes: {
                        us: ["40E (DD)"],
                        eu: ["90FEU"],
                        uk: ["40E (DD)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18FAU",
                      short: "18F (3D)",
                      long: "18F",
                      display: "18F",
                      display_with_size_set: "18F (3D)",
                      display_with_size_system: "AU 18F",
                      display_with_system_and_set: "AU 18F (3D)",
                      equivalent_sizes: {
                        us: ["40F (3D)"],
                        eu: ["90GEU"],
                        uk: ["40F (3D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18G (D)AU",
                      short: "18G (4D)",
                      long: "18G",
                      display: "18G",
                      display_with_size_set: "18G (4D)",
                      display_with_size_system: "AU 18G",
                      display_with_system_and_set: "AU 18G (4D)",
                      equivalent_sizes: {
                        us: ["40G (4D)"],
                        eu: ["90H (D)EU"],
                        uk: ["40G (4D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18H (5D)AU",
                      short: "18H (5D)",
                      long: "18H",
                      display: "18H",
                      display_with_size_set: "18H (5D)",
                      display_with_size_system: "AU 18H",
                      display_with_system_and_set: "AU 18H (5D)",
                      equivalent_sizes: {
                        us: ["40H (5D)"],
                        eu: ["90I (5D)EU"],
                        uk: ["40H (5D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20BAU",
                      short: "20B",
                      long: "20B",
                      display: "20B",
                      display_with_size_set: "20B",
                      display_with_size_system: "AU 20B",
                      display_with_system_and_set: "AU 20B",
                      equivalent_sizes: {
                        us: ["42B"],
                        eu: ["95CEU"],
                        uk: ["42BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20CAU",
                      short: "20C",
                      long: "20C",
                      display: "20C",
                      display_with_size_set: "20C",
                      display_with_size_system: "AU 20C",
                      display_with_system_and_set: "AU 20C",
                      equivalent_sizes: {
                        us: ["42C"],
                        eu: ["95DEU"],
                        uk: ["42CUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20DAU",
                      short: "20D",
                      long: "20D",
                      display: "20D",
                      display_with_size_set: "20D",
                      display_with_size_system: "AU 20D",
                      display_with_system_and_set: "AU 20D",
                      equivalent_sizes: {
                        us: ["42D"],
                        eu: ["95EEU"],
                        uk: ["42DUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20EAU",
                      short: "20E (DD)",
                      long: "20E",
                      display: "20E",
                      display_with_size_set: "20E (DD)",
                      display_with_size_system: "AU 20E",
                      display_with_system_and_set: "AU 20E (DD)",
                      equivalent_sizes: {
                        us: ["42E (DD)"],
                        eu: ["95FEU"],
                        uk: ["42E (DD)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20FAU",
                      short: "20F (3D)",
                      long: "20F",
                      display: "20F",
                      display_with_size_set: "20F (3D)",
                      display_with_size_system: "AU 20F",
                      display_with_system_and_set: "AU 20F (3D)",
                      equivalent_sizes: {
                        us: ["42F (3D)"],
                        eu: ["95GEU"],
                        uk: ["42F (3D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20G (D)AU",
                      short: "20G (4D)",
                      long: "20G",
                      display: "20G",
                      display_with_size_set: "20G (4D)",
                      display_with_size_system: "AU 20G",
                      display_with_system_and_set: "AU 20G (4D)",
                      equivalent_sizes: {
                        us: ["42G (4D)"],
                        eu: ["95H (D)EU"],
                        uk: ["42G (4D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20H (5D)AU",
                      short: "20H (5D)",
                      long: "20H",
                      display: "20H",
                      display_with_size_set: "20H (5D)",
                      display_with_size_system: "AU 20H",
                      display_with_system_and_set: "AU 20H (5D)",
                      equivalent_sizes: {
                        us: ["42H (5D)"],
                        eu: ["95I (5D)EU"],
                        uk: ["42H (5D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22BAU",
                      short: "22B",
                      long: "22B",
                      display: "22B",
                      display_with_size_set: "22B",
                      display_with_size_system: "AU 22B",
                      display_with_system_and_set: "AU 22B",
                      equivalent_sizes: {
                        us: ["44B"],
                        eu: ["100CEU"],
                        uk: ["44BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22CAU",
                      short: "22C",
                      long: "22C",
                      display: "22C",
                      display_with_size_set: "22C",
                      display_with_size_system: "AU 22C",
                      display_with_system_and_set: "AU 22C",
                      equivalent_sizes: {
                        us: ["44C"],
                        eu: ["100DEU"],
                        uk: ["44CUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22DAU",
                      short: "22D",
                      long: "22D",
                      display: "22D",
                      display_with_size_set: "22D",
                      display_with_size_system: "AU 22D",
                      display_with_system_and_set: "AU 22D",
                      equivalent_sizes: {
                        us: ["44D"],
                        eu: ["100EEU"],
                        uk: ["44DUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22EAU",
                      short: "22E (DD)",
                      long: "22E",
                      display: "22E",
                      display_with_size_set: "22E (DD)",
                      display_with_size_system: "AU 22E",
                      display_with_system_and_set: "AU 22E (DD)",
                      equivalent_sizes: {
                        us: ["44E (DD)"],
                        eu: ["100FEU"],
                        uk: ["44E (DD)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22FAU",
                      short: "22F (3D)",
                      long: "22F",
                      display: "22F",
                      display_with_size_set: "22F (3D)",
                      display_with_size_system: "AU 22F",
                      display_with_system_and_set: "AU 22F (3D)",
                      equivalent_sizes: {
                        us: ["44F (3D)"],
                        eu: ["100GEU"],
                        uk: ["44F (3D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22G (D)AU",
                      short: "22G (4D)",
                      long: "22G",
                      display: "22G",
                      display_with_size_set: "22G (4D)",
                      display_with_size_system: "AU 22G",
                      display_with_system_and_set: "AU 22G (4D)",
                      equivalent_sizes: {
                        us: ["44G (4D)"],
                        eu: ["100H (D)EU"],
                        uk: ["44G (4D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22H (5D)AU",
                      short: "22H (5D)",
                      long: "22H",
                      display: "22H",
                      display_with_size_set: "22H (5D)",
                      display_with_size_system: "AU 22H",
                      display_with_system_and_set: "AU 22H (5D)",
                      equivalent_sizes: {
                        us: ["44H (5D)"],
                        eu: ["100I (5D)EU"],
                        uk: ["44H (5D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24BAU",
                      short: "24B",
                      long: "24B",
                      display: "24B",
                      display_with_size_set: "24B",
                      display_with_size_system: "AU 24B",
                      display_with_system_and_set: "AU 24B",
                      equivalent_sizes: {
                        us: ["46B"],
                        eu: ["105CEU"],
                        uk: ["46BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24CAU",
                      short: "24C",
                      long: "24C",
                      display: "24C",
                      display_with_size_set: "24C",
                      display_with_size_system: "AU 24C",
                      display_with_system_and_set: "AU 24C",
                      equivalent_sizes: {
                        us: ["46C"],
                        eu: ["105DEU"],
                        uk: ["46CUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24DAU",
                      short: "24D",
                      long: "24D",
                      display: "24D",
                      display_with_size_set: "24D",
                      display_with_size_system: "AU 24D",
                      display_with_system_and_set: "AU 24D",
                      equivalent_sizes: {
                        us: ["46D"],
                        eu: ["105EEU"],
                        uk: ["46DUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24DDAU",
                      short: "24DD",
                      long: "24DD",
                      display: "24DD",
                      display_with_size_set: "24DD",
                      display_with_size_system: "AU 24DD",
                      display_with_system_and_set: "AU 24DD",
                      equivalent_sizes: {
                        us: ["46DD"],
                        eu: ["105FEU"],
                        uk: ["46DDUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24FAU",
                      short: "24F (3D)",
                      long: "24F",
                      display: "24F",
                      display_with_size_set: "24F (3D)",
                      display_with_size_system: "AU 24F",
                      display_with_system_and_set: "AU 24F (3D)",
                      equivalent_sizes: {
                        us: ["46F (3D)"],
                        eu: ["105GEU"],
                        uk: ["46F (3D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24G (D)AU",
                      short: "24G (4D)",
                      long: "24G",
                      display: "24G",
                      display_with_size_set: "24G (4D)",
                      display_with_size_system: "AU 24G",
                      display_with_system_and_set: "AU 24G (4D)",
                      equivalent_sizes: {
                        us: ["46G (4D)"],
                        eu: ["105H (D)EU"],
                        uk: ["46G (4D)UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26BAU",
                      short: "26B",
                      long: "26B",
                      display: "26B",
                      display_with_size_set: "26B",
                      display_with_size_system: "AU 26B",
                      display_with_system_and_set: "AU 26B",
                      equivalent_sizes: {
                        us: ["48B"],
                        eu: ["110CEU"],
                        uk: ["48BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26CAU",
                      short: "26C",
                      long: "26C",
                      display: "26C",
                      display_with_size_set: "26C",
                      display_with_size_system: "AU 26C",
                      display_with_system_and_set: "AU 26C",
                      equivalent_sizes: {
                        us: ["48C"],
                        eu: ["110DEU"],
                        uk: ["48CUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26DAU",
                      short: "26D",
                      long: "26D",
                      display: "26D",
                      display_with_size_set: "26D",
                      display_with_size_system: "AU 26D",
                      display_with_system_and_set: "AU 26D",
                      equivalent_sizes: {
                        us: ["48D"],
                        eu: ["110EEU"],
                        uk: ["48DUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26DDAU",
                      short: "26DD",
                      long: "26DD",
                      display: "26DD",
                      display_with_size_set: "26DD",
                      display_with_size_system: "AU 26DD",
                      display_with_system_and_set: "AU 26DD",
                      equivalent_sizes: {
                        us: ["48DD"],
                        eu: ["110FEU"],
                        uk: ["48DDUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26FAU",
                      short: "26F",
                      long: "26F",
                      display: "26F",
                      display_with_size_set: "26F",
                      display_with_size_system: "AU 26F",
                      display_with_system_and_set: "AU 26F",
                      equivalent_sizes: {
                        us: ["48F"],
                        eu: ["110GEU"],
                        uk: ["48FUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26GAU",
                      short: "26G",
                      long: "26G",
                      display: "26G",
                      display_with_size_set: "26G",
                      display_with_size_system: "AU 26G",
                      display_with_system_and_set: "AU 26G",
                      equivalent_sizes: {
                        us: ["48G"],
                        eu: ["110HEU"],
                        uk: ["48GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "85BEU",
                      short: "85B",
                      long: "85B",
                      display: "85B",
                      display_with_size_set: "85B",
                      display_with_size_system: "EU 85B",
                      display_with_system_and_set: "EU 85B",
                      equivalent_sizes: {
                        us: ["38A"],
                        au: ["16AAU"],
                        uk: ["38AUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "85CEU",
                      short: "85C",
                      long: "85C",
                      display: "85C",
                      display_with_size_set: "85C",
                      display_with_size_system: "EU 85C",
                      display_with_system_and_set: "EU 85C",
                      equivalent_sizes: {
                        us: ["38B"],
                        au: ["16BAU"],
                        uk: ["38BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "85DEU",
                      short: "85D",
                      long: "85D",
                      display: "85D",
                      display_with_size_set: "85D",
                      display_with_size_system: "EU 85D",
                      display_with_system_and_set: "EU 85D",
                      equivalent_sizes: {
                        us: ["38C"],
                        au: ["16CAU"],
                        uk: ["38CUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "85EEU",
                      short: "85E",
                      long: "85E",
                      display: "85E",
                      display_with_size_set: "85E",
                      display_with_size_system: "EU 85E",
                      display_with_system_and_set: "EU 85E",
                      equivalent_sizes: {
                        us: ["38D"],
                        au: ["16DAU"],
                        uk: ["38DUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "85FEU",
                      short: "85F (DD)",
                      long: "85F",
                      display: "85F",
                      display_with_size_set: "85F (DD)",
                      display_with_size_system: "EU 85F",
                      display_with_system_and_set: "EU 85F (DD)",
                      equivalent_sizes: {
                        us: ["38E (DD)"],
                        au: ["16EAU"],
                        uk: ["38E (DD)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "85GEU",
                      short: "85G (3D)",
                      long: "85G",
                      display: "85G",
                      display_with_size_set: "85G (3D)",
                      display_with_size_system: "EU 85G",
                      display_with_system_and_set: "EU 85G (3D)",
                      equivalent_sizes: {
                        us: ["38F (3D)"],
                        au: ["16FAU"],
                        uk: ["38F (3D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "85H (4D)EU",
                      short: "85H (4D)",
                      long: "85H",
                      display: "85H",
                      display_with_size_set: "85H (4D)",
                      display_with_size_system: "EU 85H",
                      display_with_system_and_set: "EU 85H (4D)",
                      equivalent_sizes: {
                        us: ["38G (4D)"],
                        au: ["16G (4D)AU"],
                        uk: ["38G (4D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "85I (5D)EU",
                      short: "85I (5D)",
                      long: "85I",
                      display: "85I",
                      display_with_size_set: "85I (5D)",
                      display_with_size_system: "EU 85I",
                      display_with_system_and_set: "EU 85I (5D)",
                      equivalent_sizes: {
                        us: ["38H (5D)"],
                        au: ["16H (5D)AU"],
                        uk: ["38H (5D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "90BEU",
                      short: "90B",
                      long: "90B",
                      display: "90B",
                      display_with_size_set: "90B",
                      display_with_size_system: "EU 90B",
                      display_with_system_and_set: "EU 90B",
                      equivalent_sizes: {
                        us: ["40A"],
                        au: ["18AAU"],
                        uk: ["40AUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "90CEU",
                      short: "90C",
                      long: "90C",
                      display: "90C",
                      display_with_size_set: "90C",
                      display_with_size_system: "EU 90C",
                      display_with_system_and_set: "EU 90C",
                      equivalent_sizes: {
                        us: ["40B"],
                        au: ["18BAU"],
                        uk: ["40BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "90DEU",
                      short: "90D",
                      long: "90D",
                      display: "90D",
                      display_with_size_set: "90D",
                      display_with_size_system: "EU 90D",
                      display_with_system_and_set: "EU 90D",
                      equivalent_sizes: {
                        us: ["40C"],
                        au: ["18CAU"],
                        uk: ["40CUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "90EEU",
                      short: "90E",
                      long: "90E",
                      display: "90E",
                      display_with_size_set: "90E",
                      display_with_size_system: "EU 90E",
                      display_with_system_and_set: "EU 90E",
                      equivalent_sizes: {
                        us: ["40D"],
                        au: ["18DAU"],
                        uk: ["40DUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "90FEU",
                      short: "90F (DD)",
                      long: "90F",
                      display: "90F",
                      display_with_size_set: "90F (DD)",
                      display_with_size_system: "EU 90F",
                      display_with_system_and_set: "EU 90F (DD)",
                      equivalent_sizes: {
                        us: ["40E (DD)"],
                        au: ["18EAU"],
                        uk: ["40E (DD)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "90GEU",
                      short: "90G (3D)",
                      long: "90G",
                      display: "90G",
                      display_with_size_set: "90G (3D)",
                      display_with_size_system: "EU 90G",
                      display_with_system_and_set: "EU 90G (3D)",
                      equivalent_sizes: {
                        us: ["40F (3D)"],
                        au: ["18FAU"],
                        uk: ["40F (3D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "90H (D)EU",
                      short: "90H (4D)",
                      long: "90H",
                      display: "90H",
                      display_with_size_set: "90H (4D)",
                      display_with_size_system: "EU 90H",
                      display_with_system_and_set: "EU 90H (4D)",
                      equivalent_sizes: {
                        us: ["40G (4D)"],
                        au: ["18G (D)AU"],
                        uk: ["40G (4D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "90I (5D)EU",
                      short: "90I (5D)",
                      long: "90I",
                      display: "90I",
                      display_with_size_set: "90I (5D)",
                      display_with_size_system: "EU 90I",
                      display_with_system_and_set: "EU 90I (5D)",
                      equivalent_sizes: {
                        us: ["40H (5D)"],
                        au: ["18H (5D)AU"],
                        uk: ["40H (5D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "95CEU",
                      short: "95C",
                      long: "95C",
                      display: "95C",
                      display_with_size_set: "95C",
                      display_with_size_system: "EU 95C",
                      display_with_system_and_set: "EU 95C",
                      equivalent_sizes: {
                        us: ["42B"],
                        au: ["20BAU"],
                        uk: ["42BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "95DEU",
                      short: "95D",
                      long: "95D",
                      display: "95D",
                      display_with_size_set: "95D",
                      display_with_size_system: "EU 95D",
                      display_with_system_and_set: "EU 95D",
                      equivalent_sizes: {
                        us: ["42C"],
                        au: ["20CAU"],
                        uk: ["42CUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "95EEU",
                      short: "95E",
                      long: "95E",
                      display: "95E",
                      display_with_size_set: "95E",
                      display_with_size_system: "EU 95E",
                      display_with_system_and_set: "EU 95E",
                      equivalent_sizes: {
                        us: ["42D"],
                        au: ["20DAU"],
                        uk: ["42DUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "95FEU",
                      short: "95F (DD)",
                      long: "95F",
                      display: "95F",
                      display_with_size_set: "95F (DD)",
                      display_with_size_system: "EU 95F",
                      display_with_system_and_set: "EU 95F (DD)",
                      equivalent_sizes: {
                        us: ["42E (DD)"],
                        au: ["20EAU"],
                        uk: ["42E (DD)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "95GEU",
                      short: "95G (3D)",
                      long: "95G",
                      display: "95G",
                      display_with_size_set: "95G (3D)",
                      display_with_size_system: "EU 95G",
                      display_with_system_and_set: "EU 95G (3D)",
                      equivalent_sizes: {
                        us: ["42F (3D)"],
                        au: ["20FAU"],
                        uk: ["42F (3D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "95H (D)EU",
                      short: "95H (4D)",
                      long: "95H",
                      display: "95H",
                      display_with_size_set: "95H (4D)",
                      display_with_size_system: "EU 95H",
                      display_with_system_and_set: "EU 95H (4D)",
                      equivalent_sizes: {
                        us: ["42G (4D)"],
                        au: ["20G (D)AU"],
                        uk: ["42G (4D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "95I (5D)EU",
                      short: "95I (5D)",
                      long: "95I",
                      display: "95I",
                      display_with_size_set: "95I (5D)",
                      display_with_size_system: "EU 95I",
                      display_with_system_and_set: "EU 95I (5D)",
                      equivalent_sizes: {
                        us: ["42H (5D)"],
                        au: ["20H (5D)AU"],
                        uk: ["42H (5D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "100CEU",
                      short: "100C",
                      long: "100C",
                      display: "100C",
                      display_with_size_set: "100C",
                      display_with_size_system: "EU 100C",
                      display_with_system_and_set: "EU 100C",
                      equivalent_sizes: {
                        us: ["44B"],
                        au: ["22BAU"],
                        uk: ["44BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "100DEU",
                      short: "100D",
                      long: "100D",
                      display: "100D",
                      display_with_size_set: "100D",
                      display_with_size_system: "EU 100D",
                      display_with_system_and_set: "EU 100D",
                      equivalent_sizes: {
                        us: ["44C"],
                        au: ["22CAU"],
                        uk: ["44CUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "100EEU",
                      short: "100E",
                      long: "100E",
                      display: "100E",
                      display_with_size_set: "100E",
                      display_with_size_system: "EU 100E",
                      display_with_system_and_set: "EU 100E",
                      equivalent_sizes: {
                        us: ["44D"],
                        au: ["22DAU"],
                        uk: ["44DUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "100FEU",
                      short: "100F (DD)",
                      long: "100F",
                      display: "100F",
                      display_with_size_set: "100F (DD)",
                      display_with_size_system: "EU 100F",
                      display_with_system_and_set: "EU 100F (DD)",
                      equivalent_sizes: {
                        us: ["44E (DD)"],
                        au: ["22EAU"],
                        uk: ["44E (DD)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "100GEU",
                      short: "100G (3D)",
                      long: "100G",
                      display: "100G",
                      display_with_size_set: "100G (3D)",
                      display_with_size_system: "EU 100G",
                      display_with_system_and_set: "EU 100G (3D)",
                      equivalent_sizes: {
                        us: ["44F (3D)"],
                        au: ["22FAU"],
                        uk: ["44F (3D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "100H (D)EU",
                      short: "100H (4D)",
                      long: "100H",
                      display: "100H",
                      display_with_size_set: "100H (4D)",
                      display_with_size_system: "EU 100H",
                      display_with_system_and_set: "EU 100H (4D)",
                      equivalent_sizes: {
                        us: ["44G (4D)"],
                        au: ["22G (D)AU"],
                        uk: ["44G (4D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "100I (5D)EU",
                      short: "100I (5D)",
                      long: "100I",
                      display: "100I",
                      display_with_size_set: "100I (5D)",
                      display_with_size_system: "EU 100I",
                      display_with_system_and_set: "EU 100I (5D)",
                      equivalent_sizes: {
                        us: ["44H (5D)"],
                        au: ["22H (5D)AU"],
                        uk: ["44H (5D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "105CEU",
                      short: "105C",
                      long: "105C",
                      display: "105C",
                      display_with_size_set: "105C",
                      display_with_size_system: "EU 105C",
                      display_with_system_and_set: "EU 105C",
                      equivalent_sizes: {
                        us: ["46B"],
                        au: ["24BAU"],
                        uk: ["46BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "105DEU",
                      short: "105D",
                      long: "105D",
                      display: "105D",
                      display_with_size_set: "105D",
                      display_with_size_system: "EU 105D",
                      display_with_system_and_set: "EU 105D",
                      equivalent_sizes: {
                        us: ["46C"],
                        au: ["24CAU"],
                        uk: ["46CUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "105EEU",
                      short: "105E",
                      long: "105E",
                      display: "105E",
                      display_with_size_set: "105E",
                      display_with_size_system: "EU 105E",
                      display_with_system_and_set: "EU 105E",
                      equivalent_sizes: {
                        us: ["46D"],
                        au: ["24DAU"],
                        uk: ["46DUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "105FEU",
                      short: "105F",
                      long: "105F",
                      display: "105F",
                      display_with_size_set: "105F",
                      display_with_size_system: "EU 105F",
                      display_with_system_and_set: "EU 105F",
                      equivalent_sizes: {
                        us: ["46DD"],
                        au: ["24DDAU"],
                        uk: ["46DDUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "105GEU",
                      short: "105G (3D)",
                      long: "105G",
                      display: "105G",
                      display_with_size_set: "105G (3D)",
                      display_with_size_system: "EU 105G",
                      display_with_system_and_set: "EU 105G (3D)",
                      equivalent_sizes: {
                        us: ["46F (3D)"],
                        au: ["24FAU"],
                        uk: ["46F (3D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "105H (D)EU",
                      short: "105H (4D)",
                      long: "105H",
                      display: "105H",
                      display_with_size_set: "105H (4D)",
                      display_with_size_system: "EU 105H",
                      display_with_system_and_set: "EU 105H (4D)",
                      equivalent_sizes: {
                        us: ["46G (4D)"],
                        au: ["24G (D)AU"],
                        uk: ["46G (4D)UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "110CEU",
                      short: "110C",
                      long: "110C",
                      display: "110C",
                      display_with_size_set: "110C",
                      display_with_size_system: "EU 110C",
                      display_with_system_and_set: "EU 110C",
                      equivalent_sizes: {
                        us: ["48B"],
                        au: ["26BAU"],
                        uk: ["48BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "110DEU",
                      short: "110D",
                      long: "110D",
                      display: "110D",
                      display_with_size_set: "110D",
                      display_with_size_system: "EU 110D",
                      display_with_system_and_set: "EU 110D",
                      equivalent_sizes: {
                        us: ["48C"],
                        au: ["26CAU"],
                        uk: ["48CUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "110EEU",
                      short: "110E",
                      long: "110E",
                      display: "110E",
                      display_with_size_set: "110E",
                      display_with_size_system: "EU 110E",
                      display_with_system_and_set: "EU 110E",
                      equivalent_sizes: {
                        us: ["48D"],
                        au: ["26DAU"],
                        uk: ["48DUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "110FEU",
                      short: "110F",
                      long: "110F",
                      display: "110F",
                      display_with_size_set: "110F",
                      display_with_size_system: "EU 110F",
                      display_with_system_and_set: "EU 110F",
                      equivalent_sizes: {
                        us: ["48DD"],
                        au: ["26DDAU"],
                        uk: ["48DDUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "110GEU",
                      short: "110G",
                      long: "110G",
                      display: "110G",
                      display_with_size_set: "110G",
                      display_with_size_system: "EU 110G",
                      display_with_system_and_set: "EU 110G",
                      equivalent_sizes: {
                        us: ["48F"],
                        au: ["26FAU"],
                        uk: ["48FUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "110HEU",
                      short: "110H",
                      long: "110H",
                      display: "110H",
                      display_with_size_set: "110H",
                      display_with_size_system: "EU 110H",
                      display_with_system_and_set: "EU 110H",
                      equivalent_sizes: {
                        us: ["48G"],
                        au: ["26GAU"],
                        uk: ["48GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38AUK",
                      short: "38A",
                      long: "38A",
                      display: "38A",
                      display_with_size_set: "38A",
                      display_with_size_system: "UK 38A",
                      display_with_system_and_set: "UK 38A",
                      equivalent_sizes: {
                        us: ["38A"],
                        au: ["16AAU"],
                        eu: ["85BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38BUK",
                      short: "38B",
                      long: "38B",
                      display: "38B",
                      display_with_size_set: "38B",
                      display_with_size_system: "UK 38B",
                      display_with_system_and_set: "UK 38B",
                      equivalent_sizes: {
                        us: ["38B"],
                        au: ["16BAU"],
                        eu: ["85CEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38CUK",
                      short: "38C",
                      long: "38C",
                      display: "38C",
                      display_with_size_set: "38C",
                      display_with_size_system: "UK 38C",
                      display_with_system_and_set: "UK 38C",
                      equivalent_sizes: {
                        us: ["38C"],
                        au: ["16CAU"],
                        eu: ["85DEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38DUK",
                      short: "38D",
                      long: "38D",
                      display: "38D",
                      display_with_size_set: "38D",
                      display_with_size_system: "UK 38D",
                      display_with_system_and_set: "UK 38D",
                      equivalent_sizes: {
                        us: ["38D"],
                        au: ["16DAU"],
                        eu: ["85EEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38E (DD)UK",
                      short: "38E (DD)",
                      long: "38E (DD)",
                      display: "38E (DD)",
                      display_with_size_set: "38E (DD)",
                      display_with_size_system: "UK 38E",
                      display_with_system_and_set: "UK 38E (DD)",
                      equivalent_sizes: {
                        us: ["38E (DD)"],
                        au: ["16EAU"],
                        eu: ["85FEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38F (3D)UK",
                      short: "38F (3D)",
                      long: "38F (3D)",
                      display: "38F (3D)",
                      display_with_size_set: "38F (3D)",
                      display_with_size_system: "UK 38F",
                      display_with_system_and_set: "UK 38F (3D)",
                      equivalent_sizes: {
                        us: ["38F (3D)"],
                        au: ["16FAU"],
                        eu: ["85GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38G (4D)UK",
                      short: "38G (4D)",
                      long: "38G (4D)",
                      display: "38G (4D)",
                      display_with_size_set: "38G (4D)",
                      display_with_size_system: "UK 38G",
                      display_with_system_and_set: "UK 38G (4D)",
                      equivalent_sizes: {
                        us: ["38G (4D)"],
                        au: ["16G (4D)AU"],
                        eu: ["85H (4D)EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38H (5D)UK",
                      short: "38H (5D)",
                      long: "38H (5D)",
                      display: "38H (5D)",
                      display_with_size_set: "38H (5D)",
                      display_with_size_system: "UK 38H",
                      display_with_system_and_set: "UK 38H (5D)",
                      equivalent_sizes: {
                        us: ["38H (5D)"],
                        au: ["16H (5D)AU"],
                        eu: ["85I (5D)EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40AUK",
                      short: "40A",
                      long: "40A",
                      display: "40A",
                      display_with_size_set: "40A",
                      display_with_size_system: "UK 40A",
                      display_with_system_and_set: "UK 40A",
                      equivalent_sizes: {
                        us: ["40A"],
                        au: ["18AAU"],
                        eu: ["90BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40BUK",
                      short: "40B",
                      long: "40B",
                      display: "40B",
                      display_with_size_set: "40B",
                      display_with_size_system: "UK 40B",
                      display_with_system_and_set: "UK 40B",
                      equivalent_sizes: {
                        us: ["40B"],
                        au: ["18BAU"],
                        eu: ["90CEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40CUK",
                      short: "40C",
                      long: "40C",
                      display: "40C",
                      display_with_size_set: "40C",
                      display_with_size_system: "UK 40C",
                      display_with_system_and_set: "UK 40C",
                      equivalent_sizes: {
                        us: ["40C"],
                        au: ["18CAU"],
                        eu: ["90DEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40DUK",
                      short: "40D",
                      long: "40D",
                      display: "40D",
                      display_with_size_set: "40D",
                      display_with_size_system: "UK 40D",
                      display_with_system_and_set: "UK 40D",
                      equivalent_sizes: {
                        us: ["40D"],
                        au: ["18DAU"],
                        eu: ["90EEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40E (DD)UK",
                      short: "40E (DD)",
                      long: "40E (DD)",
                      display: "40E (DD)",
                      display_with_size_set: "40E (DD)",
                      display_with_size_system: "UK 40E",
                      display_with_system_and_set: "UK 40E (DD)",
                      equivalent_sizes: {
                        us: ["40E (DD)"],
                        au: ["18EAU"],
                        eu: ["90FEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40F (3D)UK",
                      short: "40F (3D)",
                      long: "40F (3D)",
                      display: "40F (3D)",
                      display_with_size_set: "40F (3D)",
                      display_with_size_system: "UK 40F",
                      display_with_system_and_set: "UK 40F (3D)",
                      equivalent_sizes: {
                        us: ["40F (3D)"],
                        au: ["18FAU"],
                        eu: ["90GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40G (4D)UK",
                      short: "40G (4D)",
                      long: "40G (4D)",
                      display: "40G (4D)",
                      display_with_size_set: "40G (4D)",
                      display_with_size_system: "UK 40G",
                      display_with_system_and_set: "UK 40G (4D)",
                      equivalent_sizes: {
                        us: ["40G (4D)"],
                        au: ["18G (D)AU"],
                        eu: ["90H (D)EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40H (5D)UK",
                      short: "40H (5D)",
                      long: "40H (5D)",
                      display: "40H (5D)",
                      display_with_size_set: "40H (5D)",
                      display_with_size_system: "UK 40H",
                      display_with_system_and_set: "UK 40H (5D)",
                      equivalent_sizes: {
                        us: ["40H (5D)"],
                        au: ["18H (5D)AU"],
                        eu: ["90I (5D)EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42AUK",
                      short: "42A",
                      long: "42A",
                      display: "42A",
                      display_with_size_set: "42A",
                      display_with_size_system: "UK 42A",
                      display_with_system_and_set: "UK 42A",
                      equivalent_sizes: {
                        us: ["42A"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42BUK",
                      short: "42B",
                      long: "42B",
                      display: "42B",
                      display_with_size_set: "42B",
                      display_with_size_system: "UK 42B",
                      display_with_system_and_set: "UK 42B",
                      equivalent_sizes: {
                        us: ["42B"],
                        au: ["20BAU"],
                        eu: ["95CEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42CUK",
                      short: "42C",
                      long: "42C",
                      display: "42C",
                      display_with_size_set: "42C",
                      display_with_size_system: "UK 42C",
                      display_with_system_and_set: "UK 42C",
                      equivalent_sizes: {
                        us: ["42C"],
                        au: ["20CAU"],
                        eu: ["95DEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42DUK",
                      short: "42D",
                      long: "42D",
                      display: "42D",
                      display_with_size_set: "42D",
                      display_with_size_system: "UK 42D",
                      display_with_system_and_set: "UK 42D",
                      equivalent_sizes: {
                        us: ["42D"],
                        au: ["20DAU"],
                        eu: ["95EEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42E (DD)UK",
                      short: "42E (DD)",
                      long: "42E (DD)",
                      display: "42E (DD)",
                      display_with_size_set: "42E (DD)",
                      display_with_size_system: "UK 42E",
                      display_with_system_and_set: "UK 42E (DD)",
                      equivalent_sizes: {
                        us: ["42E (DD)"],
                        au: ["20EAU"],
                        eu: ["95FEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42F (3D)UK",
                      short: "42F (3D)",
                      long: "42F (3D)",
                      display: "42F (3D)",
                      display_with_size_set: "42F (3D)",
                      display_with_size_system: "UK 42F",
                      display_with_system_and_set: "UK 42F (3D)",
                      equivalent_sizes: {
                        us: ["42F (3D)"],
                        au: ["20FAU"],
                        eu: ["95GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42G (4D)UK",
                      short: "42G (4D)",
                      long: "42G (4D)",
                      display: "42G (4D)",
                      display_with_size_set: "42G (4D)",
                      display_with_size_system: "UK 42G",
                      display_with_system_and_set: "UK 42G (4D)",
                      equivalent_sizes: {
                        us: ["42G (4D)"],
                        au: ["20G (D)AU"],
                        eu: ["95H (D)EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42H (5D)UK",
                      short: "42H (5D)",
                      long: "42H (5D)",
                      display: "42H (5D)",
                      display_with_size_set: "42H (5D)",
                      display_with_size_system: "UK 42H",
                      display_with_system_and_set: "UK 42H (5D)",
                      equivalent_sizes: {
                        us: ["42H (5D)"],
                        au: ["20H (5D)AU"],
                        eu: ["95I (5D)EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44AUK",
                      short: "44A",
                      long: "44A",
                      display: "44A",
                      display_with_size_set: "44A",
                      display_with_size_system: "UK 44A",
                      display_with_system_and_set: "UK 44A",
                      equivalent_sizes: {
                        us: ["44A"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44BUK",
                      short: "44B",
                      long: "44B",
                      display: "44B",
                      display_with_size_set: "44B",
                      display_with_size_system: "UK 44B",
                      display_with_system_and_set: "UK 44B",
                      equivalent_sizes: {
                        us: ["44B"],
                        au: ["22BAU"],
                        eu: ["100CEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44CUK",
                      short: "44C",
                      long: "44C",
                      display: "44C",
                      display_with_size_set: "44C",
                      display_with_size_system: "UK 44C",
                      display_with_system_and_set: "UK 44C",
                      equivalent_sizes: {
                        us: ["44C"],
                        au: ["22CAU"],
                        eu: ["100DEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44DUK",
                      short: "44D",
                      long: "44D",
                      display: "44D",
                      display_with_size_set: "44D",
                      display_with_size_system: "UK 44D",
                      display_with_system_and_set: "UK 44D",
                      equivalent_sizes: {
                        us: ["44D"],
                        au: ["22DAU"],
                        eu: ["100EEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44E (DD)UK",
                      short: "44E (DD)",
                      long: "44E (DD)",
                      display: "44E (DD)",
                      display_with_size_set: "44E (DD)",
                      display_with_size_system: "UK 44E",
                      display_with_system_and_set: "UK 44E (DD)",
                      equivalent_sizes: {
                        us: ["44E (DD)"],
                        au: ["22EAU"],
                        eu: ["100FEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44F (3D)UK",
                      short: "44F (3D)",
                      long: "44F (3D)",
                      display: "44F (3D)",
                      display_with_size_set: "44F (3D)",
                      display_with_size_system: "UK 44F",
                      display_with_system_and_set: "UK 44F (3D)",
                      equivalent_sizes: {
                        us: ["44F (3D)"],
                        au: ["22FAU"],
                        eu: ["100GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44G (4D)UK",
                      short: "44G (4D)",
                      long: "44G (4D)",
                      display: "44G (4D)",
                      display_with_size_set: "44G (4D)",
                      display_with_size_system: "UK 44G",
                      display_with_system_and_set: "UK 44G (4D)",
                      equivalent_sizes: {
                        us: ["44G (4D)"],
                        au: ["22G (D)AU"],
                        eu: ["100H (D)EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44H (5D)UK",
                      short: "44H (5D)",
                      long: "44H (5D)",
                      display: "44H (5D)",
                      display_with_size_set: "44H (5D)",
                      display_with_size_system: "UK 44H",
                      display_with_system_and_set: "UK 44H (5D)",
                      equivalent_sizes: {
                        us: ["44H (5D)"],
                        au: ["22H (5D)AU"],
                        eu: ["100I (5D)EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46AUK",
                      short: "46A",
                      long: "46A",
                      display: "46A",
                      display_with_size_set: "46A",
                      display_with_size_system: "UK 46A",
                      display_with_system_and_set: "UK 46A",
                      equivalent_sizes: {
                        us: ["46A"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46BUK",
                      short: "46B",
                      long: "46B",
                      display: "46B",
                      display_with_size_set: "46B",
                      display_with_size_system: "UK 46B",
                      display_with_system_and_set: "UK 46B",
                      equivalent_sizes: {
                        us: ["46B"],
                        au: ["24BAU"],
                        eu: ["105CEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46CUK",
                      short: "46C",
                      long: "46C",
                      display: "46C",
                      display_with_size_set: "46C",
                      display_with_size_system: "UK 46C",
                      display_with_system_and_set: "UK 46C",
                      equivalent_sizes: {
                        us: ["46C"],
                        au: ["24CAU"],
                        eu: ["105DEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46DUK",
                      short: "46D",
                      long: "46D",
                      display: "46D",
                      display_with_size_set: "46D",
                      display_with_size_system: "UK 46D",
                      display_with_system_and_set: "UK 46D",
                      equivalent_sizes: {
                        us: ["46D"],
                        au: ["24DAU"],
                        eu: ["105EEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46DDUK",
                      short: "46DD",
                      long: "46DD",
                      display: "46DD",
                      display_with_size_set: "46DD",
                      display_with_size_system: "UK 46DD",
                      display_with_system_and_set: "UK 46DD",
                      equivalent_sizes: {
                        us: ["46DD"],
                        au: ["24DDAU"],
                        eu: ["105FEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46F (3D)UK",
                      short: "46F (3D)",
                      long: "46F (3D)",
                      display: "46F (3D)",
                      display_with_size_set: "46F (3D)",
                      display_with_size_system: "UK 46F",
                      display_with_system_and_set: "UK 46F (3D)",
                      equivalent_sizes: {
                        us: ["46F (3D)"],
                        au: ["24FAU"],
                        eu: ["105GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46G (4D)UK",
                      short: "46G (4D)",
                      long: "46G (4D)",
                      display: "46G (4D)",
                      display_with_size_set: "46G (4D)",
                      display_with_size_system: "UK 46G",
                      display_with_system_and_set: "UK 46G (4D)",
                      equivalent_sizes: {
                        us: ["46G (4D)"],
                        au: ["24G (D)AU"],
                        eu: ["105H (D)EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46H (5D)UK",
                      short: "46H (5D)",
                      long: "46H (5D)",
                      display: "46H (5D)",
                      display_with_size_set: "46H (5D)",
                      display_with_size_system: "UK 46H",
                      display_with_system_and_set: "UK 46H (5D)",
                      equivalent_sizes: {
                        us: ["46H (5D)"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48AUK",
                      short: "48A",
                      long: "48A",
                      display: "48A",
                      display_with_size_set: "48A",
                      display_with_size_system: "UK 48A",
                      display_with_system_and_set: "UK 48A",
                      equivalent_sizes: {
                        us: ["48A"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48BUK",
                      short: "48B",
                      long: "48B",
                      display: "48B",
                      display_with_size_set: "48B",
                      display_with_size_system: "UK 48B",
                      display_with_system_and_set: "UK 48B",
                      equivalent_sizes: {
                        us: ["48B"],
                        au: ["26BAU"],
                        eu: ["110CEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48CUK",
                      short: "48C",
                      long: "48C",
                      display: "48C",
                      display_with_size_set: "48C",
                      display_with_size_system: "UK 48C",
                      display_with_system_and_set: "UK 48C",
                      equivalent_sizes: {
                        us: ["48C"],
                        au: ["26CAU"],
                        eu: ["110DEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48DUK",
                      short: "48D",
                      long: "48D",
                      display: "48D",
                      display_with_size_set: "48D",
                      display_with_size_system: "UK 48D",
                      display_with_system_and_set: "UK 48D",
                      equivalent_sizes: {
                        us: ["48D"],
                        au: ["26DAU"],
                        eu: ["110EEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48DDUK",
                      short: "48DD",
                      long: "48DD",
                      display: "48DD",
                      display_with_size_set: "48DD",
                      display_with_size_system: "UK 48DD",
                      display_with_system_and_set: "UK 48DD",
                      equivalent_sizes: {
                        us: ["48DD"],
                        au: ["26DDAU"],
                        eu: ["110FEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48FUK",
                      short: "48F",
                      long: "48F (3D)",
                      display: "48F (3D)",
                      display_with_size_set: "48F",
                      display_with_size_system: "UK 48F",
                      display_with_system_and_set: "UK 48F",
                      equivalent_sizes: {
                        us: ["48F"],
                        au: ["26FAU"],
                        eu: ["110GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48GUK",
                      short: "48G",
                      long: "48G (4D)",
                      display: "48G (4D)",
                      display_with_size_set: "48G",
                      display_with_size_system: "UK 48G",
                      display_with_system_and_set: "UK 48G",
                      equivalent_sizes: {
                        us: ["48G"],
                        au: ["26GAU"],
                        eu: ["110HEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48H (5D)UK",
                      short: "48H (5D)",
                      long: "48H (5D)",
                      display: "48H (5D)",
                      display_with_size_set: "48H (5D)",
                      display_with_size_system: "UK 48H",
                      display_with_system_and_set: "UK 48H (5D)",
                      equivalent_sizes: {
                        us: ["48H (5D)"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "39004308d97b4ea2b60057c7",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "00208975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Band ", "Cup "],
                  ["34-36", "38-39"],
                  ["34-36", "39-40"],
                  ["34-36", "40-41"],
                  ["34-36", "41-42"],
                  ["34-36", "42-43"],
                  ["34-36", "43-44"],
                  ["34-36", "44-45"],
                  ["34-36", "45-46"],
                  ["36-38", "40-41"],
                  ["36-38", "41-42"],
                  ["36-38", "42-43"],
                  ["36-38", "43-44"],
                  ["36-38", "44-45"],
                  ["36-38", "45-46"],
                  ["36-38", "46-47"],
                  ["36-38", "47-48"],
                  ["38-40", "43-44"],
                  ["38-40", "44-45"],
                  ["38-40", "45-46"],
                  ["38-40", "46-47"],
                  ["38-40", "47-48"],
                  ["38-40", "48-49"],
                  ["38-40", "49-50"],
                  ["40-42", "45-46"],
                  ["40-42", "46-47"],
                  ["40-42", "47-48"],
                  ["40-42", "48-49"],
                  ["40-42", "49-50"],
                  ["40-42", "50-51"],
                  ["40-42", "51-52"],
                  ["42-44", "47-48"],
                  ["42-44", "48-49"],
                  ["42-44", "49-50"],
                  ["42-44", "50-51"],
                  ["42-44", "51-52"],
                  ["42-44", "52-53"],
                  ["44-46", "49-50"],
                  ["44-46", "50-51"],
                  ["44-46", "51-52"],
                  ["44-46", "52-53"],
                  ["44-46", "53-54"],
                  ["44-46", "54-55"],
                ],
                centimeters: [
                  ["Band ", "Cup "],
                  ["86.4-91.4", "96.5-99.1"],
                  ["86.4-91.4", "99.1-101.6"],
                  ["86.4-91.4", "101.6-104.1"],
                  ["86.4-91.4", "104.1-106.7"],
                  ["86.4-91.4", "106.7-109.2"],
                  ["86.4-91.4", "109.2-111.8"],
                  ["86.4-91.4", "111.8-114.3"],
                  ["86.4-91.4", "114.3-116.8"],
                  ["91.4-96.5", "101.6-104.1"],
                  ["91.4-96.5", "104.1-106.7"],
                  ["91.4-96.5", "106.7-109.2"],
                  ["91.4-96.5", "109.2-111.8"],
                  ["91.4-96.5", "111.8-114.3"],
                  ["91.4-96.5", "114.3-116.8"],
                  ["91.4-96.5", "116.8-119.4"],
                  ["91.4-96.5", "119.4-121.9"],
                  ["96.5-101.6", "109.2-111.8"],
                  ["96.5-101.6", "111.8-114.3"],
                  ["96.5-101.6", "114.3-116.8"],
                  ["96.5-101.6", "116.8-119.4"],
                  ["96.5-101.6", "119.4-121.9"],
                  ["96.5-101.6", "121.9-124.5"],
                  ["96.5-101.6", "124.5-127"],
                  ["101.6-106.7", "114.3-116.8"],
                  ["101.6-106.7", "116.8-119.4"],
                  ["101.6-106.7", "119.4-121.9"],
                  ["101.6-106.7", "121.9-124.5"],
                  ["101.6-106.7", "124.5-127"],
                  ["101.6-106.7", "127-129.5"],
                  ["101.6-106.7", "129.5-132.1"],
                  ["106.7-111.8", "119.4-121.9"],
                  ["106.7-111.8", "121.9-124.5"],
                  ["106.7-111.8", "124.5-127"],
                  ["106.7-111.8", "127-129.5"],
                  ["106.7-111.8", "129.5-132.1"],
                  ["106.7-111.8", "132.1-134.6"],
                  ["111.8-116.9", "124.5-127"],
                  ["111.8-116.9", "127-129.5"],
                  ["111.8-116.9", "129.5-132.1"],
                  ["111.8-116.9", "132.1-134.6"],
                  ["111.8-116.9", "134.6-137.2"],
                  ["111.8-116.9", "137.2-139.7"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["38A", "16A", "85B", "38A"],
                  ["38B", "16B", "85C", "38B"],
                  ["38C", "16C", "85D", "38C"],
                  ["38D", "16D", "85E", "38D"],
                  ["38E (DD)", "16E", "85F", "38E (DD)"],
                  ["38F (3D)", "16F", "85G", "38F (3D)"],
                  ["38G (4D)", "16G", "85H", "38G (4D)"],
                  ["38H (5D)", "16H", "85I", "38H (5D)"],
                  ["40A", "18A", "90B", "40A"],
                  ["40B", "18B", "90C", "40B"],
                  ["40C", "18C", "90D", "40C"],
                  ["40D", "18D", "90E", "40D"],
                  ["40E (DD)", "18E (DD)", "90F", "40E (DD)"],
                  ["40F (3D)", "18F (3D)", "90G", "40F (3D)"],
                  ["40G (4D)", "18G", "90H", "40G (4D)"],
                  ["40H (5D)", "18H", "90I", "40H (5D)"],
                  ["42B", "20B", "95C", "42B"],
                  ["42C", "20C", "95D", "42C"],
                  ["42D", "20D", "95E", "42D"],
                  ["42E (DD)", "20E (DD)", "95F", "42E (DD)"],
                  ["42F (3D)", "20F (3D)", "95G", "42F (3D)"],
                  ["42G (4D)", "20G", "95H", "42G (4D)"],
                  ["42H (5D)", "20H", "95I", "42H (5D)"],
                  ["44B", "22B", "100C", "44B"],
                  ["44C", "22C", "100D", "44C"],
                  ["44D", "22D", "100E", "44D"],
                  ["44E (DD)", "22E (DD)", "100F", "44E (DD)"],
                  ["44F (3D)", "22F (3D)", "100G", "44F (3D)"],
                  ["44G (4D)", "22G", "100H", "44G (4D)"],
                  ["44H (5D)", "22H", "100I", "44H (5D)"],
                  ["46B", "24B", "105C", "46B"],
                  ["46C", "24C", "105D", "46C"],
                  ["46D", "24D", "105E", "46D"],
                  ["46DD", "24E", "105F", "46DD"],
                  ["46F (3D)", "24F (3D)", "105G", "46F (3D)"],
                  ["46G (4D)", "24G", "105H", "46G (4D)"],
                  ["48B", "26B", "110C", "48B"],
                  ["48C", "26C", "110D", "48C"],
                  ["48D", "26D", "110E", "48D"],
                  ["48DD", "26E", "110F", "48DD"],
                  ["48F (3D)", "26F", "110G", "48F (3D)"],
                  ["48G (4D)", "26G", "110H", "48G (4D)"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["30A", "8A", "65B", "30A"],
                ["30B", "8B", "65C", "30B"],
                ["32AA", "10AA", "70A", "32AA"],
                ["32A", "10A", "70B", "32A"],
                ["32B", "10B", "70C", "32B"],
                ["32C", "10C", "70D", "32C"],
                ["32D", "10D", "70E", "32D"],
                ["32E (DD)", "10E (DD)", "70F", "32E (DD)"],
                ["32F (3D)", "10F (3D)", "70G", "32F (3D)"],
                ["32G (4D)", "10G", "70H", "32G (4D)"],
                ["32H (5D)", "10H", "70I", "32H (5D)"],
                ["34AA", "12AA", "75A", "34AA"],
                ["34A", "12A", "75B", "34A"],
                ["34B", "12B", "75C", "34B"],
                ["34C", "12C", "75D", "34C"],
                ["34D", "12D", "75E", "34D"],
                ["34E (DD)", "12E (DD)", "75F", "34E (DD)"],
                ["34F (3D)", "12F (3D)", "75G", "34F (3D)"],
                ["34G (4D)", "12G", "75H", "34G (4D)"],
                ["34H (5D)", "12H", "75I", "34H (5D)"],
                ["36AA", "14AA", "80A", "36AA"],
                ["36A", "14A", "80B", "36A"],
                ["36B", "14B", "80C", "36B"],
                ["36C", "14C", "80D", "36C"],
                ["36D", "14D", "80E", "36D"],
                ["36E (DD)", "14E", "80F", "36E (DD)"],
                ["36F (3D)", "14F (3D)", "80G", "36F (3D)"],
                ["36G (4D)", "14G", "80H", "36G (4D)"],
                ["36H (5D)", "14H", "80I", "36H (5D)"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
                ["38A", "16A", "85B", "38A"],
                ["38B", "16B", "85C", "38B"],
                ["38C", "16C", "85D", "38C"],
                ["38D", "16D", "85E", "38D"],
                ["38E (DD)", "16E", "85F", "38E (DD)"],
                ["38F (3D)", "16F", "85G", "38F (3D)"],
                ["38G (4D)", "16G", "85H", "38G (4D)"],
                ["38H (5D)", "16H", "85I", "38H (5D)"],
                ["40A", "18A", "90B", "40A"],
                ["40B", "18B", "90C", "40B"],
                ["40C", "18C", "90D", "40C"],
                ["40D", "18D", "90E", "40D"],
                ["40E (DD)", "18E (DD)", "90F", "40E (DD)"],
                ["40F (3D)", "18F (3D)", "90G", "40F (3D)"],
                ["40G (4D)", "18G", "90H", "40G (4D)"],
                ["40H (5D)", "18H", "90I", "40H (5D)"],
                ["42A", "-", "-", "42A"],
                ["42B", "20B", "95C", "42B"],
                ["42C", "20C", "95D", "42C"],
                ["42D", "20D", "95E", "42D"],
                ["42E (DD)", "20E (DD)", "95F", "42E (DD)"],
                ["42F (3D)", "20F (3D)", "95G", "42F (3D)"],
                ["42G (4D)", "20G", "95H", "42G (4D)"],
                ["42H (5D)", "20H", "95I", "42H (5D)"],
                ["44A", "-", "-", "44A"],
                ["44B", "22B", "100C", "44B"],
                ["44C", "22C", "100D", "44C"],
                ["44D", "22D", "100E", "44D"],
                ["44E (DD)", "22E (DD)", "100F", "44E (DD)"],
                ["44F (3D)", "22F (3D)", "100G", "44F (3D)"],
                ["44G (4D)", "22G", "100H", "44G (4D)"],
                ["44H (5D)", "22H", "100I", "44H (5D)"],
                ["46A", "-", "-", "46A"],
                ["46B", "24B", "105C", "46B"],
                ["46C", "24C", "105D", "46C"],
                ["46D", "24D", "105E", "46D"],
                ["46DD", "24E", "105F", "46DD"],
                ["46F (3D)", "24F (3D)", "105G", "46F (3D)"],
                ["46G (4D)", "24G", "105H", "46G (4D)"],
                ["46H (5D)", "-", "-", "46H (5D)"],
                ["48A", "-", "-", "48A"],
                ["48B", "26B", "110C", "48B"],
                ["48C", "26C", "110D", "48C"],
                ["48D", "26D", "110E", "48D"],
                ["48DD", "26E", "110F", "48DD"],
                ["48F (3D)", "26F", "110G", "48F (3D)"],
                ["48G (4D)", "26G", "110H", "48G (4D)"],
                ["48H (5D)", "-", "-", "48H (5D)"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OSM",
                      short: "OS (Maternity)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Maternity)",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS (Maternity)",
                      equivalent_sizes: {
                        au: ["OSMAU"],
                        eu: ["OSMEU"],
                        uk: ["OSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30AM",
                      short: "30A (Maternity)",
                      long: "30A",
                      display: "30A",
                      display_with_size_set: "30A (Maternity)",
                      display_with_size_system: "US 30A",
                      display_with_system_and_set: "US 30A (Maternity)",
                      equivalent_sizes: {
                        au: ["8AMAU"],
                        eu: ["65BMEU"],
                        uk: ["30AMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30BM",
                      short: "30B (Maternity)",
                      long: "30B",
                      display: "30B",
                      display_with_size_set: "30B (Maternity)",
                      display_with_size_system: "US 30B",
                      display_with_system_and_set: "US 30B (Maternity)",
                      equivalent_sizes: {
                        au: ["8BMAU"],
                        eu: ["65CMEU"],
                        uk: ["30BMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32AAM",
                      short: "32AA (Maternity)",
                      long: "32AA",
                      display: "32AA",
                      display_with_size_set: "32AA (Maternity)",
                      display_with_size_system: "US 32AA",
                      display_with_system_and_set: "US 32AA (Maternity)",
                      equivalent_sizes: {
                        au: ["10AAMAU"],
                        eu: ["70AMEU"],
                        uk: ["32AAMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32AM",
                      short: "32A (Maternity)",
                      long: "32A",
                      display: "32A",
                      display_with_size_set: "32A (Maternity)",
                      display_with_size_system: "US 32A",
                      display_with_system_and_set: "US 32A (Maternity)",
                      equivalent_sizes: {
                        au: ["10AMAU"],
                        eu: ["70BMEU"],
                        uk: ["32AMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32BM",
                      short: "32B (Maternity)",
                      long: "32B",
                      display: "32B",
                      display_with_size_set: "32B (Maternity)",
                      display_with_size_system: "US 32B",
                      display_with_system_and_set: "US 32B (Maternity)",
                      equivalent_sizes: {
                        au: ["10BMAU"],
                        eu: ["70CMEU"],
                        uk: ["32BMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32CM",
                      short: "32C (Maternity)",
                      long: "32C",
                      display: "32C",
                      display_with_size_set: "32C (Maternity)",
                      display_with_size_system: "US 32C",
                      display_with_system_and_set: "US 32C (Maternity)",
                      equivalent_sizes: {
                        au: ["10CMAU"],
                        eu: ["70DMEU"],
                        uk: ["32CMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32DM",
                      short: "32D (Maternity)",
                      long: "32D",
                      display: "32D",
                      display_with_size_set: "32D (Maternity)",
                      display_with_size_system: "US 32D",
                      display_with_system_and_set: "US 32D (Maternity)",
                      equivalent_sizes: {
                        au: ["10DMAU"],
                        eu: ["70EMEU"],
                        uk: ["32DMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32E (DD)M",
                      short: "32E (DD) (Maternity)",
                      long: "32E (DD)",
                      display: "32E (DD)",
                      display_with_size_set: "32E (DD) (Maternity)",
                      display_with_size_system: "US 32E",
                      display_with_system_and_set: "US 32E (DD) (Maternity)",
                      equivalent_sizes: {
                        au: ["10EMAU"],
                        eu: ["70FMEU"],
                        uk: ["32E (DD)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32F (3D)M",
                      short: "32F (3D) (Maternity)",
                      long: "32F (3D)",
                      display: "32F (3D)",
                      display_with_size_set: "32F (3D) (Maternity)",
                      display_with_size_system: "US 32F",
                      display_with_system_and_set: "US 32F (3D) (Maternity)",
                      equivalent_sizes: {
                        au: ["10F(DDD)MAU"],
                        eu: ["70GMEU"],
                        uk: ["32F (3D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32G (4D)M",
                      short: "32G (4D) (Maternity)",
                      long: "32G (4D)",
                      display: "32G (4D)",
                      display_with_size_set: "32G (4D) (Maternity)",
                      display_with_size_system: "US 32G",
                      display_with_system_and_set: "US 32G (4D) (Maternity)",
                      equivalent_sizes: {
                        au: ["10G (4D)MAU"],
                        eu: ["70H (4D)MEU"],
                        uk: ["32G (4D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32H (5D)M",
                      short: "32H (5D) (Maternity)",
                      long: "32H (5D)",
                      display: "32H (5D)",
                      display_with_size_set: "32H (5D) (Maternity)",
                      display_with_size_system: "US 32H",
                      display_with_system_and_set: "US 32H (5D) (Maternity)",
                      equivalent_sizes: {
                        au: ["10H (5D)MAU"],
                        eu: ["70I (5D)MEU"],
                        uk: ["32H (5D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34AAM",
                      short: "34AA (Maternity)",
                      long: "34AA",
                      display: "34AA",
                      display_with_size_set: "34AA (Maternity)",
                      display_with_size_system: "US 34AA",
                      display_with_system_and_set: "US 34AA (Maternity)",
                      equivalent_sizes: {
                        au: ["12AAMAU"],
                        eu: ["75AMEU"],
                        uk: ["34AAMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34AM",
                      short: "34A (Maternity)",
                      long: "34A",
                      display: "34A",
                      display_with_size_set: "34A (Maternity)",
                      display_with_size_system: "US 34A",
                      display_with_system_and_set: "US 34A (Maternity)",
                      equivalent_sizes: {
                        au: ["12AMAU"],
                        eu: ["75BMEU"],
                        uk: ["34AMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34BM",
                      short: "34B (Maternity)",
                      long: "34B",
                      display: "34B",
                      display_with_size_set: "34B (Maternity)",
                      display_with_size_system: "US 34B",
                      display_with_system_and_set: "US 34B (Maternity)",
                      equivalent_sizes: {
                        au: ["12BMAU"],
                        eu: ["75CMEU"],
                        uk: ["34BMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34CM",
                      short: "34C (Maternity)",
                      long: "34C",
                      display: "34C",
                      display_with_size_set: "34C (Maternity)",
                      display_with_size_system: "US 34C",
                      display_with_system_and_set: "US 34C (Maternity)",
                      equivalent_sizes: {
                        au: ["12CMAU"],
                        eu: ["75DMEU"],
                        uk: ["34CMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34DM",
                      short: "34D (Maternity)",
                      long: "34D",
                      display: "34D",
                      display_with_size_set: "34D (Maternity)",
                      display_with_size_system: "US 34D",
                      display_with_system_and_set: "US 34D (Maternity)",
                      equivalent_sizes: {
                        au: ["12DMAU"],
                        eu: ["75EMEU"],
                        uk: ["34DMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34E (DD)M",
                      short: "34E (DD) (Maternity)",
                      long: "34E (DD)",
                      display: "34E (DD)",
                      display_with_size_set: "34E (DD) (Maternity)",
                      display_with_size_system: "US 34E",
                      display_with_system_and_set: "US 34E (DD) (Maternity)",
                      equivalent_sizes: {
                        au: ["12EMAU"],
                        eu: ["75FMEU"],
                        uk: ["34E (DD)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34F (3D)M",
                      short: "34F (3D) (Maternity)",
                      long: "34F (3D)",
                      display: "34F (3D)",
                      display_with_size_set: "34F (3D) (Maternity)",
                      display_with_size_system: "US 34F",
                      display_with_system_and_set: "US 34F (3D) (Maternity)",
                      equivalent_sizes: {
                        au: ["12FMAU"],
                        eu: ["75GMEU"],
                        uk: ["34F (3D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34G (4D)M",
                      short: "34G (4D) (Maternity)",
                      long: "34G (4D)",
                      display: "34G (4D)",
                      display_with_size_set: "34G (4D) (Maternity)",
                      display_with_size_system: "US 34G",
                      display_with_system_and_set: "US 34G (4D) (Maternity)",
                      equivalent_sizes: {
                        au: ["12G (D)MAU"],
                        eu: ["75H (D)MEU"],
                        uk: ["34G (4D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34H (5D)M",
                      short: "34H (5D) (Maternity)",
                      long: "34H (5D)",
                      display: "34H (5D)",
                      display_with_size_set: "34H (5D) (Maternity)",
                      display_with_size_system: "US 34H",
                      display_with_system_and_set: "US 34H (5D) (Maternity)",
                      equivalent_sizes: {
                        au: ["12H (5D)MAU"],
                        eu: ["75I (5D)MEU"],
                        uk: ["34H (5D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36AAM",
                      short: "36AA (Maternity)",
                      long: "36AA",
                      display: "36AA",
                      display_with_size_set: "36AA (Maternity)",
                      display_with_size_system: "US 36AA",
                      display_with_system_and_set: "US 36AA (Maternity)",
                      equivalent_sizes: {
                        au: ["14AAMAU"],
                        eu: ["80AMEU"],
                        uk: ["36AAMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36AM",
                      short: "36A (Maternity)",
                      long: "36A",
                      display: "36A",
                      display_with_size_set: "36A (Maternity)",
                      display_with_size_system: "US 36A",
                      display_with_system_and_set: "US 36A (Maternity)",
                      equivalent_sizes: {
                        au: ["14AMAU"],
                        eu: ["80BMEU"],
                        uk: ["36AMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36BM",
                      short: "36B (Maternity)",
                      long: "36B",
                      display: "36B",
                      display_with_size_set: "36B (Maternity)",
                      display_with_size_system: "US 36B",
                      display_with_system_and_set: "US 36B (Maternity)",
                      equivalent_sizes: {
                        au: ["14BMAU"],
                        eu: ["80CMEU"],
                        uk: ["36BMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36CM",
                      short: "36C (Maternity)",
                      long: "36C",
                      display: "36C",
                      display_with_size_set: "36C (Maternity)",
                      display_with_size_system: "US 36C",
                      display_with_system_and_set: "US 36C (Maternity)",
                      equivalent_sizes: {
                        au: ["14CMAU"],
                        eu: ["80DMEU"],
                        uk: ["36CMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36DM",
                      short: "36D (Maternity)",
                      long: "36D",
                      display: "36D",
                      display_with_size_set: "36D (Maternity)",
                      display_with_size_system: "US 36D",
                      display_with_system_and_set: "US 36D (Maternity)",
                      equivalent_sizes: {
                        au: ["14DMAU"],
                        eu: ["80EMEU"],
                        uk: ["36DMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36E (DD)M",
                      short: "36E (DD) (Maternity)",
                      long: "36E (DD)",
                      display: "36E (DD)",
                      display_with_size_set: "36E (DD) (Maternity)",
                      display_with_size_system: "US 36E",
                      display_with_system_and_set: "US 36E (DD) (Maternity)",
                      equivalent_sizes: {
                        au: ["14EMAU"],
                        eu: ["80FMEU"],
                        uk: ["36E (DD)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36F (3D)M",
                      short: "36F (3D) (Maternity)",
                      long: "36F (3D)",
                      display: "36F (3D)",
                      display_with_size_set: "36F (3D) (Maternity)",
                      display_with_size_system: "US 36F",
                      display_with_system_and_set: "US 36F (3D) (Maternity)",
                      equivalent_sizes: {
                        au: ["14FMAU"],
                        eu: ["80GMEU"],
                        uk: ["36F (3D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36G (4D)M",
                      short: "36G (4D) (Maternity)",
                      long: "36G (4D)",
                      display: "36G (4D)",
                      display_with_size_set: "36G (4D) (Maternity)",
                      display_with_size_system: "US 36G",
                      display_with_system_and_set: "US 36G (4D) (Maternity)",
                      equivalent_sizes: {
                        au: ["14G (4D)MAU"],
                        eu: ["80H (4D)MEU"],
                        uk: ["36G (4D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36H (5D)M",
                      short: "36H (5D) (Maternity)",
                      long: "36H (5D)",
                      display: "36H (5D)",
                      display_with_size_set: "36H (5D) (Maternity)",
                      display_with_size_system: "US 36H",
                      display_with_system_and_set: "US 36H (5D) (Maternity)",
                      equivalent_sizes: {
                        au: ["14H (5D)MAU"],
                        eu: ["80I (5D)MEU"],
                        uk: ["36H (5D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38AM",
                      short: "38A (Maternity)",
                      long: "38A",
                      display: "38A",
                      display_with_size_set: "38A (Maternity)",
                      display_with_size_system: "US 38A",
                      display_with_system_and_set: "US 38A (Maternity)",
                      equivalent_sizes: {
                        au: ["16AMAU"],
                        eu: ["85BMEU"],
                        uk: ["38AMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38BM",
                      short: "38B (Maternity)",
                      long: "38B",
                      display: "38B",
                      display_with_size_set: "38B (Maternity)",
                      display_with_size_system: "US 38B",
                      display_with_system_and_set: "US 38B (Maternity)",
                      equivalent_sizes: {
                        au: ["16BMAU"],
                        eu: ["85CMEU"],
                        uk: ["38BMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38CM",
                      short: "38C (Maternity)",
                      long: "38C",
                      display: "38C",
                      display_with_size_set: "38C (Maternity)",
                      display_with_size_system: "US 38C",
                      display_with_system_and_set: "US 38C (Maternity)",
                      equivalent_sizes: {
                        au: ["16CMAU"],
                        eu: ["85DMEU"],
                        uk: ["38CMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38DM",
                      short: "38D (Maternity)",
                      long: "38D",
                      display: "38D",
                      display_with_size_set: "38D (Maternity)",
                      display_with_size_system: "US 38D",
                      display_with_system_and_set: "US 38D (Maternity)",
                      equivalent_sizes: {
                        au: ["16DMAU"],
                        eu: ["85EMEU"],
                        uk: ["38DMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38E (DD)M",
                      short: "38E (DD) (Maternity)",
                      long: "38E (DD)",
                      display: "38E (DD)",
                      display_with_size_set: "38E (DD) (Maternity)",
                      display_with_size_system: "US 38E",
                      display_with_system_and_set: "US 38E (DD) (Maternity)",
                      equivalent_sizes: {
                        au: ["16EMAU"],
                        eu: ["85FMEU"],
                        uk: ["38E (DD)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38F (3D)M",
                      short: "38F (3D) (Maternity)",
                      long: "38F (3D)",
                      display: "38F (3D)",
                      display_with_size_set: "38F (3D) (Maternity)",
                      display_with_size_system: "US 38F",
                      display_with_system_and_set: "US 38F (3D) (Maternity)",
                      equivalent_sizes: {
                        au: ["16FMAU"],
                        eu: ["85GMEU"],
                        uk: ["38F (3D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38G (4D)M",
                      short: "38G (4D) (Maternity)",
                      long: "38G (4D)",
                      display: "38G (4D)",
                      display_with_size_set: "38G (4D) (Maternity)",
                      display_with_size_system: "US 38G",
                      display_with_system_and_set: "US 38G (4D) (Maternity)",
                      equivalent_sizes: {
                        au: ["16G (4D)MAU"],
                        eu: ["85H (4D)MEU"],
                        uk: ["38G (4D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38H (5D)M",
                      short: "38H (5D) (Maternity)",
                      long: "38H (5D)",
                      display: "38H (5D)",
                      display_with_size_set: "38H (5D) (Maternity)",
                      display_with_size_system: "US 38H",
                      display_with_system_and_set: "US 38H (5D) (Maternity)",
                      equivalent_sizes: {
                        au: ["16H (5D)MAU"],
                        eu: ["85I (5D)MEU"],
                        uk: ["38H (5D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40AM",
                      short: "40A (Maternity)",
                      long: "40A",
                      display: "40A",
                      display_with_size_set: "40A (Maternity)",
                      display_with_size_system: "US 40A",
                      display_with_system_and_set: "US 40A (Maternity)",
                      equivalent_sizes: {
                        au: ["18AMAU"],
                        eu: ["90BMEU"],
                        uk: ["40AMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40BM",
                      short: "40B (Maternity)",
                      long: "40B",
                      display: "40B",
                      display_with_size_set: "40B (Maternity)",
                      display_with_size_system: "US 40B",
                      display_with_system_and_set: "US 40B (Maternity)",
                      equivalent_sizes: {
                        au: ["18BMAU"],
                        eu: ["90CMEU"],
                        uk: ["40BMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40CM",
                      short: "40C (Maternity)",
                      long: "40C",
                      display: "40C",
                      display_with_size_set: "40C (Maternity)",
                      display_with_size_system: "US 40C",
                      display_with_system_and_set: "US 40C (Maternity)",
                      equivalent_sizes: {
                        au: ["18CMAU"],
                        eu: ["90DMEU"],
                        uk: ["40CMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40DM",
                      short: "40D (Maternity)",
                      long: "40D",
                      display: "40D",
                      display_with_size_set: "40D (Maternity)",
                      display_with_size_system: "US 40D",
                      display_with_system_and_set: "US 40D (Maternity)",
                      equivalent_sizes: {
                        au: ["18DMAU"],
                        eu: ["90EMEU"],
                        uk: ["40DMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40E (DD)M",
                      short: "40E (DD) (Maternity)",
                      long: "40E (DD)",
                      display: "40E (DD)",
                      display_with_size_set: "40E (DD) (Maternity)",
                      display_with_size_system: "US 40E",
                      display_with_system_and_set: "US 40E (DD) (Maternity)",
                      equivalent_sizes: {
                        au: ["18EMAU"],
                        eu: ["90FMEU"],
                        uk: ["40E (DD)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40F (3D)M",
                      short: "40F (3D) (Maternity)",
                      long: "40F (3D)",
                      display: "40F (3D)",
                      display_with_size_set: "40F (3D) (Maternity)",
                      display_with_size_system: "US 40F",
                      display_with_system_and_set: "US 40F (3D) (Maternity)",
                      equivalent_sizes: {
                        au: ["18FMAU"],
                        eu: ["90GMEU"],
                        uk: ["40F (3D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40G (4D)M",
                      short: "40G (4D) (Maternity)",
                      long: "40G (4D)",
                      display: "40G (4D)",
                      display_with_size_set: "40G (4D) (Maternity)",
                      display_with_size_system: "US 40G",
                      display_with_system_and_set: "US 40G (4D) (Maternity)",
                      equivalent_sizes: {
                        au: ["18G (D)MAU"],
                        eu: ["90H (D)MEU"],
                        uk: ["40G (4D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40H (5D)M",
                      short: "40H (5D) (Maternity)",
                      long: "40H (5D)",
                      display: "40H (5D)",
                      display_with_size_set: "40H (5D) (Maternity)",
                      display_with_size_system: "US 40H",
                      display_with_system_and_set: "US 40H (5D) (Maternity)",
                      equivalent_sizes: {
                        au: ["18H (5D)MAU"],
                        eu: ["90I (5D)MEU"],
                        uk: ["40H (5D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42AM",
                      short: "42A (Maternity)",
                      long: "42A",
                      display: "42A",
                      display_with_size_set: "42A (Maternity)",
                      display_with_size_system: "US 42A",
                      display_with_system_and_set: "US 42A (Maternity)",
                      equivalent_sizes: {
                        uk: ["42AMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42BM",
                      short: "42B (Maternity)",
                      long: "42B",
                      display: "42B",
                      display_with_size_set: "42B (Maternity)",
                      display_with_size_system: "US 42B",
                      display_with_system_and_set: "US 42B (Maternity)",
                      equivalent_sizes: {
                        au: ["20BMAU"],
                        eu: ["95CMEU"],
                        uk: ["42BMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42CM",
                      short: "42C (Maternity)",
                      long: "42C",
                      display: "42C",
                      display_with_size_set: "42C (Maternity)",
                      display_with_size_system: "US 42C",
                      display_with_system_and_set: "US 42C (Maternity)",
                      equivalent_sizes: {
                        au: ["20CMAU"],
                        eu: ["95DMEU"],
                        uk: ["42CMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42DM",
                      short: "42D (Maternity)",
                      long: "42D",
                      display: "42D",
                      display_with_size_set: "42D (Maternity)",
                      display_with_size_system: "US 42D",
                      display_with_system_and_set: "US 42D (Maternity)",
                      equivalent_sizes: {
                        au: ["20DMAU"],
                        eu: ["95EMEU"],
                        uk: ["42DMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42E (DD)M",
                      short: "42E (DD) (Maternity)",
                      long: "42E (DD)",
                      display: "42E (DD)",
                      display_with_size_set: "42E (DD) (Maternity)",
                      display_with_size_system: "US 42E",
                      display_with_system_and_set: "US 42E (DD) (Maternity)",
                      equivalent_sizes: {
                        au: ["20EMAU"],
                        eu: ["95FMEU"],
                        uk: ["42E (DD)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42F (3D)M",
                      short: "42F (3D) (Maternity)",
                      long: "42F (3D)",
                      display: "42F (3D)",
                      display_with_size_set: "42F (3D) (Maternity)",
                      display_with_size_system: "US 42F",
                      display_with_system_and_set: "US 42F (3D) (Maternity)",
                      equivalent_sizes: {
                        au: ["20FMAU"],
                        eu: ["95GMEU"],
                        uk: ["42F (3D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42G (4D)M",
                      short: "42G (4D) (Maternity)",
                      long: "42G (4D)",
                      display: "42G (4D)",
                      display_with_size_set: "42G (4D) (Maternity)",
                      display_with_size_system: "US 42G",
                      display_with_system_and_set: "US 42G (4D) (Maternity)",
                      equivalent_sizes: {
                        au: ["20G (D)MAU"],
                        eu: ["95H (D)MEU"],
                        uk: ["42G (4D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42H (5D)M",
                      short: "42H (5D) (Maternity)",
                      long: "42H (5D)",
                      display: "42H (5D)",
                      display_with_size_set: "42H (5D) (Maternity)",
                      display_with_size_system: "US 42H",
                      display_with_system_and_set: "US 42H (5D) (Maternity)",
                      equivalent_sizes: {
                        au: ["20H (5D)MAU"],
                        eu: ["95I (5D)MEU"],
                        uk: ["42H (5D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44AM",
                      short: "44A (Maternity)",
                      long: "44A",
                      display: "44A",
                      display_with_size_set: "44A (Maternity)",
                      display_with_size_system: "US 44A",
                      display_with_system_and_set: "US 44A (Maternity)",
                      equivalent_sizes: {
                        uk: ["44AMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44BM",
                      short: "44B (Maternity)",
                      long: "44B",
                      display: "44B",
                      display_with_size_set: "44B (Maternity)",
                      display_with_size_system: "US 44B",
                      display_with_system_and_set: "US 44B (Maternity)",
                      equivalent_sizes: {
                        au: ["22BMAU"],
                        eu: ["100CMEU"],
                        uk: ["44BMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44CM",
                      short: "44C (Maternity)",
                      long: "44C",
                      display: "44C",
                      display_with_size_set: "44C (Maternity)",
                      display_with_size_system: "US 44C",
                      display_with_system_and_set: "US 44C (Maternity)",
                      equivalent_sizes: {
                        au: ["22CMAU"],
                        eu: ["100DMEU"],
                        uk: ["44CMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44DM",
                      short: "44D (Maternity)",
                      long: "44D",
                      display: "44D",
                      display_with_size_set: "44D (Maternity)",
                      display_with_size_system: "US 44D",
                      display_with_system_and_set: "US 44D (Maternity)",
                      equivalent_sizes: {
                        au: ["22DMAU"],
                        eu: ["100EMEU"],
                        uk: ["44DMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44E (DD)M",
                      short: "44E (DD) (Maternity)",
                      long: "44E (DD)",
                      display: "44E (DD)",
                      display_with_size_set: "44E (DD) (Maternity)",
                      display_with_size_system: "US 44E",
                      display_with_system_and_set: "US 44E (DD) (Maternity)",
                      equivalent_sizes: {
                        au: ["22EMAU"],
                        eu: ["100FMEU"],
                        uk: ["44E (DD)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44F (3D)M",
                      short: "44F (3D) (Maternity)",
                      long: "44F (3D)",
                      display: "44F (3D)",
                      display_with_size_set: "44F (3D) (Maternity)",
                      display_with_size_system: "US 44F",
                      display_with_system_and_set: "US 44F (3D) (Maternity)",
                      equivalent_sizes: {
                        au: ["22FMAU"],
                        eu: ["100GMEU"],
                        uk: ["44F (3D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44G (4D)M",
                      short: "44G (4D) (Maternity)",
                      long: "44G (4D)",
                      display: "44G (4D)",
                      display_with_size_set: "44G (4D) (Maternity)",
                      display_with_size_system: "US 44G",
                      display_with_system_and_set: "US 44G (4D) (Maternity)",
                      equivalent_sizes: {
                        au: ["22G (D)MAU"],
                        eu: ["100H (D)MEU"],
                        uk: ["44G (4D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44H (5D)M",
                      short: "44H (5D) (Maternity)",
                      long: "44H (5D)",
                      display: "44H (5D)",
                      display_with_size_set: "44H (5D) (Maternity)",
                      display_with_size_system: "US 44H",
                      display_with_system_and_set: "US 44H (5D) (Maternity)",
                      equivalent_sizes: {
                        au: ["22H (5D)MAU"],
                        eu: ["100I (5D)MEU"],
                        uk: ["44H (5D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46AM",
                      short: "46A (Maternity)",
                      long: "46A",
                      display: "46A",
                      display_with_size_set: "46A (Maternity)",
                      display_with_size_system: "US 46A",
                      display_with_system_and_set: "US 46A (Maternity)",
                      equivalent_sizes: {
                        uk: ["46AMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46BM",
                      short: "46B (Maternity)",
                      long: "46B",
                      display: "46B",
                      display_with_size_set: "46B (Maternity)",
                      display_with_size_system: "US 46B",
                      display_with_system_and_set: "US 46B (Maternity)",
                      equivalent_sizes: {
                        au: ["24BMAU"],
                        eu: ["105CMEU"],
                        uk: ["46BMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46CM",
                      short: "46C (Maternity)",
                      long: "46C",
                      display: "46C",
                      display_with_size_set: "46C (Maternity)",
                      display_with_size_system: "US 46C",
                      display_with_system_and_set: "US 46C (Maternity)",
                      equivalent_sizes: {
                        au: ["24CMAU"],
                        eu: ["105DMEU"],
                        uk: ["46CMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46DM",
                      short: "46D (Maternity)",
                      long: "46D",
                      display: "46D",
                      display_with_size_set: "46D (Maternity)",
                      display_with_size_system: "US 46D",
                      display_with_system_and_set: "US 46D (Maternity)",
                      equivalent_sizes: {
                        au: ["24DMAU"],
                        eu: ["105EMEU"],
                        uk: ["46DMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46DDM",
                      short: "46DD (Maternity)",
                      long: "46DD",
                      display: "46DD",
                      display_with_size_set: "46DD (Maternity)",
                      display_with_size_system: "US 46DD",
                      display_with_system_and_set: "US 46DD (Maternity)",
                      equivalent_sizes: {
                        au: ["24EMAU"],
                        eu: ["105FMEU"],
                        uk: ["46DDMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46F (3D)M",
                      short: "46F (3D) (Maternity)",
                      long: "46F (3D)",
                      display: "46F (3D)",
                      display_with_size_set: "46F (3D) (Maternity)",
                      display_with_size_system: "US 46F",
                      display_with_system_and_set: "US 46F (3D) (Maternity)",
                      equivalent_sizes: {
                        au: ["24FMAU"],
                        eu: ["105GMEU"],
                        uk: ["46F (3D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46G (4D)M",
                      short: "46G (4D) (Maternity)",
                      long: "46G (4D)",
                      display: "46G (4D)",
                      display_with_size_set: "46G (4D) (Maternity)",
                      display_with_size_system: "US 46G",
                      display_with_system_and_set: "US 46G (4D) (Maternity)",
                      equivalent_sizes: {
                        au: ["24G (D)MAU"],
                        eu: ["105H (D)MEU"],
                        uk: ["46G (4D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46H (5D)M",
                      short: "46H (5D) (Maternity)",
                      long: "46H (5D)",
                      display: "46H (5D)",
                      display_with_size_set: "46H (5D) (Maternity)",
                      display_with_size_system: "US 46H",
                      display_with_system_and_set: "US 46H (5D) (Maternity)",
                      equivalent_sizes: {
                        uk: ["46H (5D)MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48AM",
                      short: "48A (Maternity)",
                      long: "48A",
                      display: "48A",
                      display_with_size_set: "48A (Maternity)",
                      display_with_size_system: "US 48A",
                      display_with_system_and_set: "US 48A (Maternity)",
                      equivalent_sizes: {
                        uk: ["48AMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48BM",
                      short: "48B (Maternity)",
                      long: "48B",
                      display: "48B",
                      display_with_size_set: "48B (Maternity)",
                      display_with_size_system: "US 48B",
                      display_with_system_and_set: "US 48B (Maternity)",
                      equivalent_sizes: {
                        au: ["26BMAU"],
                        eu: ["110CMEU"],
                        uk: ["48BMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48CM",
                      short: "48C (Maternity)",
                      long: "48C",
                      display: "48C",
                      display_with_size_set: "48C (Maternity)",
                      display_with_size_system: "US 48C",
                      display_with_system_and_set: "US 48C (Maternity)",
                      equivalent_sizes: {
                        au: ["26CMAU"],
                        eu: ["110DMEU"],
                        uk: ["48CMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48DM",
                      short: "48D (Maternity)",
                      long: "48D",
                      display: "48D",
                      display_with_size_set: "48D (Maternity)",
                      display_with_size_system: "US 48D",
                      display_with_system_and_set: "US 48D (Maternity)",
                      equivalent_sizes: {
                        au: ["26DMAU"],
                        eu: ["110EMEU"],
                        uk: ["48DMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48DDM",
                      short: "48DD (Maternity)",
                      long: "48DD",
                      display: "48DD",
                      display_with_size_set: "48DD (Maternity)",
                      display_with_size_system: "US 48DD",
                      display_with_system_and_set: "US 48DD (Maternity)",
                      equivalent_sizes: {
                        au: ["26EMAU"],
                        eu: ["110FMEU"],
                        uk: ["48DDMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48FM",
                      short: "48F (Maternity)",
                      long: "48F (3D)",
                      display: "48F (3D)",
                      display_with_size_set: "48F (Maternity)",
                      display_with_size_system: "US 48F",
                      display_with_system_and_set: "US 48F (Maternity)",
                      equivalent_sizes: {
                        au: ["26FMAU"],
                        eu: ["110GMEU"],
                        uk: ["48FMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48GM",
                      short: "48G (Maternity)",
                      long: "48G (4D)",
                      display: "48G (4D)",
                      display_with_size_set: "48G (Maternity)",
                      display_with_size_system: "US 48G",
                      display_with_system_and_set: "US 48G (Maternity)",
                      equivalent_sizes: {
                        au: ["26GMAU"],
                        eu: ["110HMEU"],
                        uk: ["48GMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48H (5D)M",
                      short: "48H (5D) (Maternity)",
                      long: "48H (5D)",
                      display: "48H (5D)",
                      display_with_size_set: "48H (5D) (Maternity)",
                      display_with_size_system: "US 48H",
                      display_with_system_and_set: "US 48H (5D) (Maternity)",
                      equivalent_sizes: {
                        uk: ["48H (5D)MUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSMAU",
                      short: "OS (Maternity)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Maternity)",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8BMAU",
                      short: "8B (Maternity)",
                      long: "8B",
                      display: "8B",
                      display_with_size_set: "8B (Maternity)",
                      display_with_size_system: "AU 8B",
                      display_with_system_and_set: "AU 8B (Maternity)",
                      equivalent_sizes: {
                        us: ["30BM"],
                        eu: ["65CMEU"],
                        uk: ["30BMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AMAU",
                      short: "8A (Maternity)",
                      long: "8A",
                      display: "8A",
                      display_with_size_set: "8A (Maternity)",
                      display_with_size_system: "AU 8A",
                      display_with_system_and_set: "AU 8A (Maternity)",
                      equivalent_sizes: {
                        us: ["30AM"],
                        eu: ["65BMEU"],
                        uk: ["30AMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AAMAU",
                      short: "10AA (Maternity)",
                      long: "10AA",
                      display: "10AA",
                      display_with_size_set: "10AA (Maternity)",
                      display_with_size_system: "AU 10AA",
                      display_with_system_and_set: "AU 10AA (Maternity)",
                      equivalent_sizes: {
                        us: ["32AAM"],
                        eu: ["70AMEU"],
                        uk: ["32AAMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AMAU",
                      short: "10A (Maternity)",
                      long: "10A",
                      display: "10A",
                      display_with_size_set: "10A (Maternity)",
                      display_with_size_system: "AU 10A",
                      display_with_system_and_set: "AU 10A (Maternity)",
                      equivalent_sizes: {
                        us: ["32AM"],
                        eu: ["70BMEU"],
                        uk: ["32AMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10BMAU",
                      short: "10B (Maternity)",
                      long: "10B",
                      display: "10B",
                      display_with_size_set: "10B (Maternity)",
                      display_with_size_system: "AU 10B",
                      display_with_system_and_set: "AU 10B (Maternity)",
                      equivalent_sizes: {
                        us: ["32BM"],
                        eu: ["70CMEU"],
                        uk: ["32BMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10CMAU",
                      short: "10C (Maternity)",
                      long: "10C",
                      display: "10C",
                      display_with_size_set: "10C (Maternity)",
                      display_with_size_system: "AU 10C",
                      display_with_system_and_set: "AU 10C (Maternity)",
                      equivalent_sizes: {
                        us: ["32CM"],
                        eu: ["70DMEU"],
                        uk: ["32CMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10DMAU",
                      short: "10D (Maternity)",
                      long: "10D",
                      display: "10D",
                      display_with_size_set: "10D (Maternity)",
                      display_with_size_system: "AU 10D",
                      display_with_system_and_set: "AU 10D (Maternity)",
                      equivalent_sizes: {
                        us: ["32DM"],
                        eu: ["70EMEU"],
                        uk: ["32DMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10EMAU",
                      short: "10E (DD)",
                      long: "10E (DD)",
                      display: "10E (DD)",
                      display_with_size_set: "10E (DD)",
                      display_with_size_system: "AU 10E (DD)",
                      display_with_system_and_set: "AU 10E (DD)",
                      equivalent_sizes: {
                        us: ["32E (DD)M"],
                        eu: ["70FMEU"],
                        uk: ["32E (DD)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10F(DDD)MAU",
                      short: "10F (3D)",
                      long: "10F (3D)",
                      display: "10F (3D)",
                      display_with_size_set: "10F (3D)",
                      display_with_size_system: "AU 10F (3D)",
                      display_with_system_and_set: "AU 10F (3D)",
                      equivalent_sizes: {
                        us: ["32F (3D)M"],
                        eu: ["70GMEU"],
                        uk: ["32F (3D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10G (4D)MAU",
                      short: "10G",
                      long: "10G",
                      display: "10G",
                      display_with_size_set: "10G",
                      display_with_size_system: "AU 10G",
                      display_with_system_and_set: "AU 10G",
                      equivalent_sizes: {
                        us: ["32G (4D)M"],
                        eu: ["70H (4D)MEU"],
                        uk: ["32G (4D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10H (5D)MAU",
                      short: "10H",
                      long: "10H",
                      display: "10H",
                      display_with_size_set: "10H",
                      display_with_size_system: "AU 10H",
                      display_with_system_and_set: "AU 10H",
                      equivalent_sizes: {
                        us: ["32H (5D)M"],
                        eu: ["70I (5D)MEU"],
                        uk: ["32H (5D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AAMAU",
                      short: "12AA (Maternity)",
                      long: "12AA",
                      display: "12AA",
                      display_with_size_set: "12AA (Maternity)",
                      display_with_size_system: "AU 12AA",
                      display_with_system_and_set: "AU 12AA (Maternity)",
                      equivalent_sizes: {
                        us: ["34AAM"],
                        eu: ["75AMEU"],
                        uk: ["34AAMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AMAU",
                      short: "12A (Maternity)",
                      long: "12A",
                      display: "12A",
                      display_with_size_set: "12A (Maternity)",
                      display_with_size_system: "AU 12A",
                      display_with_system_and_set: "AU 12A (Maternity)",
                      equivalent_sizes: {
                        us: ["34AM"],
                        eu: ["75BMEU"],
                        uk: ["34AMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12BMAU",
                      short: "12B (Maternity)",
                      long: "12B",
                      display: "12B",
                      display_with_size_set: "12B (Maternity)",
                      display_with_size_system: "AU 12B",
                      display_with_system_and_set: "AU 12B (Maternity)",
                      equivalent_sizes: {
                        us: ["34BM"],
                        eu: ["75CMEU"],
                        uk: ["34BMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12CMAU",
                      short: "12C (Maternity)",
                      long: "12C",
                      display: "12C",
                      display_with_size_set: "12C (Maternity)",
                      display_with_size_system: "AU 12C",
                      display_with_system_and_set: "AU 12C (Maternity)",
                      equivalent_sizes: {
                        us: ["34CM"],
                        eu: ["75DMEU"],
                        uk: ["34CMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12DMAU",
                      short: "12D (Maternity)",
                      long: "12D",
                      display: "12D",
                      display_with_size_set: "12D (Maternity)",
                      display_with_size_system: "AU 12D",
                      display_with_system_and_set: "AU 12D (Maternity)",
                      equivalent_sizes: {
                        us: ["34DM"],
                        eu: ["75EMEU"],
                        uk: ["34DMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12EMAU",
                      short: "12E (DD)",
                      long: "12E (DD)",
                      display: "12E (DD)",
                      display_with_size_set: "12E (DD)",
                      display_with_size_system: "AU 12E (DD)",
                      display_with_system_and_set: "AU 12E (DD)",
                      equivalent_sizes: {
                        us: ["34E (DD)M"],
                        eu: ["75FMEU"],
                        uk: ["34E (DD)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12FMAU",
                      short: "12F (3D)",
                      long: "12F (3D)",
                      display: "12F (3D)",
                      display_with_size_set: "12F (3D)",
                      display_with_size_system: "AU 12F (3D)",
                      display_with_system_and_set: "AU 12F (3D)",
                      equivalent_sizes: {
                        us: ["34F (3D)M"],
                        eu: ["75GMEU"],
                        uk: ["34F (3D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12G (D)MAU",
                      short: "12G",
                      long: "12G",
                      display: "12G",
                      display_with_size_set: "12G",
                      display_with_size_system: "AU 12G",
                      display_with_system_and_set: "AU 12G",
                      equivalent_sizes: {
                        us: ["34G (4D)M"],
                        eu: ["75H (D)MEU"],
                        uk: ["34G (4D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12H (5D)MAU",
                      short: "12H",
                      long: "12H",
                      display: "12H",
                      display_with_size_set: "12H",
                      display_with_size_system: "AU 12H",
                      display_with_system_and_set: "AU 12H",
                      equivalent_sizes: {
                        us: ["34H (5D)M"],
                        eu: ["75I (5D)MEU"],
                        uk: ["34H (5D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AAMAU",
                      short: "14AA (Maternity)",
                      long: "14AA",
                      display: "14AA",
                      display_with_size_set: "14AA (Maternity)",
                      display_with_size_system: "AU 14AA",
                      display_with_system_and_set: "AU 14AA (Maternity)",
                      equivalent_sizes: {
                        us: ["36AAM"],
                        eu: ["80AMEU"],
                        uk: ["36AAMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AMAU",
                      short: "14A (Maternity)",
                      long: "14A",
                      display: "14A",
                      display_with_size_set: "14A (Maternity)",
                      display_with_size_system: "AU 14A",
                      display_with_system_and_set: "AU 14A (Maternity)",
                      equivalent_sizes: {
                        us: ["36AM"],
                        eu: ["80BMEU"],
                        uk: ["36AMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14BMAU",
                      short: "14B (Maternity)",
                      long: "14B",
                      display: "14B",
                      display_with_size_set: "14B (Maternity)",
                      display_with_size_system: "AU 14B",
                      display_with_system_and_set: "AU 14B (Maternity)",
                      equivalent_sizes: {
                        us: ["36BM"],
                        eu: ["80CMEU"],
                        uk: ["36BMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14CMAU",
                      short: "14C (Maternity)",
                      long: "14C",
                      display: "14C",
                      display_with_size_set: "14C (Maternity)",
                      display_with_size_system: "AU 14C",
                      display_with_system_and_set: "AU 14C (Maternity)",
                      equivalent_sizes: {
                        us: ["36CM"],
                        eu: ["80DMEU"],
                        uk: ["36CMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14DMAU",
                      short: "14D (Maternity)",
                      long: "14D",
                      display: "14D",
                      display_with_size_set: "14D (Maternity)",
                      display_with_size_system: "AU 14D",
                      display_with_system_and_set: "AU 14D (Maternity)",
                      equivalent_sizes: {
                        us: ["36DM"],
                        eu: ["80EMEU"],
                        uk: ["36DMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14EMAU",
                      short: "14E (DD)",
                      long: "14E",
                      display: "14E",
                      display_with_size_set: "14E (DD)",
                      display_with_size_system: "AU 14E",
                      display_with_system_and_set: "AU 14E (DD)",
                      equivalent_sizes: {
                        us: ["36E (DD)M"],
                        eu: ["80FMEU"],
                        uk: ["36E (DD)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14FMAU",
                      short: "14F (3D)",
                      long: "14F (3D)",
                      display: "14F (3D)",
                      display_with_size_set: "14F (3D)",
                      display_with_size_system: "AU 14F (3D)",
                      display_with_system_and_set: "AU 14F (3D)",
                      equivalent_sizes: {
                        us: ["36F (3D)M"],
                        eu: ["80GMEU"],
                        uk: ["36F (3D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14G (4D)MAU",
                      short: "14G",
                      long: "14G",
                      display: "14G",
                      display_with_size_set: "14G",
                      display_with_size_system: "AU 14G",
                      display_with_system_and_set: "AU 14G",
                      equivalent_sizes: {
                        us: ["36G (4D)M"],
                        eu: ["80H (4D)MEU"],
                        uk: ["36G (4D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14H (5D)MAU",
                      short: "14H",
                      long: "14H",
                      display: "14H",
                      display_with_size_set: "14H",
                      display_with_size_system: "AU 14H",
                      display_with_system_and_set: "AU 14H",
                      equivalent_sizes: {
                        us: ["36H (5D)M"],
                        eu: ["80I (5D)MEU"],
                        uk: ["36H (5D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AMAU",
                      short: "16A (Maternity)",
                      long: "16A",
                      display: "16A",
                      display_with_size_set: "16A (Maternity)",
                      display_with_size_system: "AU 16A",
                      display_with_system_and_set: "AU 16A (Maternity)",
                      equivalent_sizes: {
                        us: ["38AM"],
                        eu: ["85BMEU"],
                        uk: ["38AMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16BMAU",
                      short: "16B (Maternity)",
                      long: "16B",
                      display: "16B",
                      display_with_size_set: "16B (Maternity)",
                      display_with_size_system: "AU 16B",
                      display_with_system_and_set: "AU 16B (Maternity)",
                      equivalent_sizes: {
                        us: ["38BM"],
                        eu: ["85CMEU"],
                        uk: ["38BMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16CMAU",
                      short: "16C (Maternity)",
                      long: "16C",
                      display: "16C",
                      display_with_size_set: "16C (Maternity)",
                      display_with_size_system: "AU 16C",
                      display_with_system_and_set: "AU 16C (Maternity)",
                      equivalent_sizes: {
                        us: ["38CM"],
                        eu: ["85DMEU"],
                        uk: ["38CMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16DMAU",
                      short: "16D (Maternity)",
                      long: "16D",
                      display: "16D",
                      display_with_size_set: "16D (Maternity)",
                      display_with_size_system: "AU 16D",
                      display_with_system_and_set: "AU 16D (Maternity)",
                      equivalent_sizes: {
                        us: ["38DM"],
                        eu: ["85EMEU"],
                        uk: ["38DMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16EMAU",
                      short: "16E (DD)",
                      long: "16E",
                      display: "16E",
                      display_with_size_set: "16E (DD)",
                      display_with_size_system: "AU 16E",
                      display_with_system_and_set: "AU 16E (DD)",
                      equivalent_sizes: {
                        us: ["38E (DD)M"],
                        eu: ["85FMEU"],
                        uk: ["38E (DD)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16FMAU",
                      short: "16F (3D)",
                      long: "16F",
                      display: "16F",
                      display_with_size_set: "16F (3D)",
                      display_with_size_system: "AU 16F",
                      display_with_system_and_set: "AU 16F (3D)",
                      equivalent_sizes: {
                        us: ["38F (3D)M"],
                        eu: ["85GMEU"],
                        uk: ["38F (3D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16G (4D)MAU",
                      short: "16G",
                      long: "16G",
                      display: "16G",
                      display_with_size_set: "16G",
                      display_with_size_system: "AU 16G",
                      display_with_system_and_set: "AU 16G",
                      equivalent_sizes: {
                        us: ["38G (4D)M"],
                        eu: ["85H (4D)MEU"],
                        uk: ["38G (4D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16H (5D)MAU",
                      short: "16H",
                      long: "16H",
                      display: "16H",
                      display_with_size_set: "16H",
                      display_with_size_system: "AU 16H",
                      display_with_system_and_set: "AU 16H",
                      equivalent_sizes: {
                        us: ["38H (5D)M"],
                        eu: ["85I (5D)MEU"],
                        uk: ["38H (5D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18AMAU",
                      short: "18A (Maternity)",
                      long: "18A",
                      display: "18A",
                      display_with_size_set: "18A (Maternity)",
                      display_with_size_system: "AU 18A",
                      display_with_system_and_set: "AU 18A (Maternity)",
                      equivalent_sizes: {
                        us: ["40AM"],
                        eu: ["90BMEU"],
                        uk: ["40AMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18BMAU",
                      short: "18B (Maternity)",
                      long: "18B",
                      display: "18B",
                      display_with_size_set: "18B (Maternity)",
                      display_with_size_system: "AU 18B",
                      display_with_system_and_set: "AU 18B (Maternity)",
                      equivalent_sizes: {
                        us: ["40BM"],
                        eu: ["90CMEU"],
                        uk: ["40BMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18CMAU",
                      short: "18C (Maternity)",
                      long: "18C",
                      display: "18C",
                      display_with_size_set: "18C (Maternity)",
                      display_with_size_system: "AU 18C",
                      display_with_system_and_set: "AU 18C (Maternity)",
                      equivalent_sizes: {
                        us: ["40CM"],
                        eu: ["90DMEU"],
                        uk: ["40CMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18DMAU",
                      short: "18D (Maternity)",
                      long: "18D",
                      display: "18D",
                      display_with_size_set: "18D (Maternity)",
                      display_with_size_system: "AU 18D",
                      display_with_system_and_set: "AU 18D (Maternity)",
                      equivalent_sizes: {
                        us: ["40DM"],
                        eu: ["90EMEU"],
                        uk: ["40DMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18EMAU",
                      short: "18E (DD)",
                      long: "18E (DD)",
                      display: "18E (DD)",
                      display_with_size_set: "18E (DD)",
                      display_with_size_system: "AU 18E (DD)",
                      display_with_system_and_set: "AU 18E (DD)",
                      equivalent_sizes: {
                        us: ["40E (DD)M"],
                        eu: ["90FMEU"],
                        uk: ["40E (DD)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18FMAU",
                      short: "18F (3D)",
                      long: "18F (3D)",
                      display: "18F (3D)",
                      display_with_size_set: "18F (3D)",
                      display_with_size_system: "AU 18F (3D)",
                      display_with_system_and_set: "AU 18F (3D)",
                      equivalent_sizes: {
                        us: ["40F (3D)M"],
                        eu: ["90GMEU"],
                        uk: ["40F (3D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18G (D)MAU",
                      short: "18G",
                      long: "18G",
                      display: "18G",
                      display_with_size_set: "18G",
                      display_with_size_system: "AU 18G",
                      display_with_system_and_set: "AU 18G",
                      equivalent_sizes: {
                        us: ["40G (4D)M"],
                        eu: ["90H (D)MEU"],
                        uk: ["40G (4D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18H (5D)MAU",
                      short: "18H",
                      long: "18H",
                      display: "18H",
                      display_with_size_set: "18H",
                      display_with_size_system: "AU 18H",
                      display_with_system_and_set: "AU 18H",
                      equivalent_sizes: {
                        us: ["40H (5D)M"],
                        eu: ["90I (5D)MEU"],
                        uk: ["40H (5D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20BMAU",
                      short: "20B (Maternity)",
                      long: "20B",
                      display: "20B",
                      display_with_size_set: "20B (Maternity)",
                      display_with_size_system: "AU 20B",
                      display_with_system_and_set: "AU 20B (Maternity)",
                      equivalent_sizes: {
                        us: ["42BM"],
                        eu: ["95CMEU"],
                        uk: ["42BMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20CMAU",
                      short: "20C (Maternity)",
                      long: "20C",
                      display: "20C",
                      display_with_size_set: "20C (Maternity)",
                      display_with_size_system: "AU 20C",
                      display_with_system_and_set: "AU 20C (Maternity)",
                      equivalent_sizes: {
                        us: ["42CM"],
                        eu: ["95DMEU"],
                        uk: ["42CMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20DMAU",
                      short: "20D (Maternity)",
                      long: "20D",
                      display: "20D",
                      display_with_size_set: "20D (Maternity)",
                      display_with_size_system: "AU 20D",
                      display_with_system_and_set: "AU 20D (Maternity)",
                      equivalent_sizes: {
                        us: ["42DM"],
                        eu: ["95EMEU"],
                        uk: ["42DMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20EMAU",
                      short: "20E (DD)",
                      long: "20E (DD)",
                      display: "20E (DD)",
                      display_with_size_set: "20E (DD)",
                      display_with_size_system: "AU 20E (DD)",
                      display_with_system_and_set: "AU 20E (DD)",
                      equivalent_sizes: {
                        us: ["42E (DD)M"],
                        eu: ["95FMEU"],
                        uk: ["42E (DD)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20FMAU",
                      short: "20F (3D)",
                      long: "20F (3D)",
                      display: "20F (3D)",
                      display_with_size_set: "20F (3D)",
                      display_with_size_system: "AU 20F (3D)",
                      display_with_system_and_set: "AU 20F (3D)",
                      equivalent_sizes: {
                        us: ["42F (3D)M"],
                        eu: ["95GMEU"],
                        uk: ["42F (3D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20G (D)MAU",
                      short: "20G",
                      long: "20G",
                      display: "20G",
                      display_with_size_set: "20G",
                      display_with_size_system: "AU 20G",
                      display_with_system_and_set: "AU 20G",
                      equivalent_sizes: {
                        us: ["42G (4D)M"],
                        eu: ["95H (D)MEU"],
                        uk: ["42G (4D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20H (5D)MAU",
                      short: "20H",
                      long: "20H",
                      display: "20H",
                      display_with_size_set: "20H",
                      display_with_size_system: "AU 20H",
                      display_with_system_and_set: "AU 20H",
                      equivalent_sizes: {
                        us: ["42H (5D)M"],
                        eu: ["95I (5D)MEU"],
                        uk: ["42H (5D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22BMAU",
                      short: "22B (Maternity)",
                      long: "22B",
                      display: "22B",
                      display_with_size_set: "22B (Maternity)",
                      display_with_size_system: "AU 22B",
                      display_with_system_and_set: "AU 22B (Maternity)",
                      equivalent_sizes: {
                        us: ["44BM"],
                        eu: ["100CMEU"],
                        uk: ["44BMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22CMAU",
                      short: "22C (Maternity)",
                      long: "22C",
                      display: "22C",
                      display_with_size_set: "22C (Maternity)",
                      display_with_size_system: "AU 22C",
                      display_with_system_and_set: "AU 22C (Maternity)",
                      equivalent_sizes: {
                        us: ["44CM"],
                        eu: ["100DMEU"],
                        uk: ["44CMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22DMAU",
                      short: "22D (Maternity)",
                      long: "22D",
                      display: "22D",
                      display_with_size_set: "22D (Maternity)",
                      display_with_size_system: "AU 22D",
                      display_with_system_and_set: "AU 22D (Maternity)",
                      equivalent_sizes: {
                        us: ["44DM"],
                        eu: ["100EMEU"],
                        uk: ["44DMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22EMAU",
                      short: "22E (DD)",
                      long: "22E (DD)",
                      display: "22E (DD)",
                      display_with_size_set: "22E (DD)",
                      display_with_size_system: "AU 22E (DD)",
                      display_with_system_and_set: "AU 22E (DD)",
                      equivalent_sizes: {
                        us: ["44E (DD)M"],
                        eu: ["100FMEU"],
                        uk: ["44E (DD)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22FMAU",
                      short: "22F (3D)",
                      long: "22F (3D)",
                      display: "22F (3D)",
                      display_with_size_set: "22F (3D)",
                      display_with_size_system: "AU 22F (3D)",
                      display_with_system_and_set: "AU 22F (3D)",
                      equivalent_sizes: {
                        us: ["44F (3D)M"],
                        eu: ["100GMEU"],
                        uk: ["44F (3D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22G (D)MAU",
                      short: "22G",
                      long: "22G",
                      display: "22G",
                      display_with_size_set: "22G",
                      display_with_size_system: "AU 22G",
                      display_with_system_and_set: "AU 22G",
                      equivalent_sizes: {
                        us: ["44G (4D)M"],
                        eu: ["100H (D)MEU"],
                        uk: ["44G (4D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22H (5D)MAU",
                      short: "22H",
                      long: "22H",
                      display: "22H",
                      display_with_size_set: "22H",
                      display_with_size_system: "AU 22H",
                      display_with_system_and_set: "AU 22H",
                      equivalent_sizes: {
                        us: ["44H (5D)M"],
                        eu: ["100I (5D)MEU"],
                        uk: ["44H (5D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24BMAU",
                      short: "24B (Maternity)",
                      long: "24B",
                      display: "24B",
                      display_with_size_set: "24B (Maternity)",
                      display_with_size_system: "AU 24B",
                      display_with_system_and_set: "AU 24B (Maternity)",
                      equivalent_sizes: {
                        us: ["46BM"],
                        eu: ["105CMEU"],
                        uk: ["46BMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24CMAU",
                      short: "24C (Maternity)",
                      long: "24C",
                      display: "24C",
                      display_with_size_set: "24C (Maternity)",
                      display_with_size_system: "AU 24C",
                      display_with_system_and_set: "AU 24C (Maternity)",
                      equivalent_sizes: {
                        us: ["46CM"],
                        eu: ["105DMEU"],
                        uk: ["46CMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24DMAU",
                      short: "24D (Maternity)",
                      long: "24D",
                      display: "24D",
                      display_with_size_set: "24D (Maternity)",
                      display_with_size_system: "AU 24D",
                      display_with_system_and_set: "AU 24D (Maternity)",
                      equivalent_sizes: {
                        us: ["46DM"],
                        eu: ["105EMEU"],
                        uk: ["46DMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24EMAU",
                      short: "24E (Maternity)",
                      long: "24E",
                      display: "24E",
                      display_with_size_set: "24E (Maternity)",
                      display_with_size_system: "AU 24E",
                      display_with_system_and_set: "AU 24E (Maternity)",
                      equivalent_sizes: {
                        us: ["46DDM"],
                        eu: ["105FMEU"],
                        uk: ["46DDMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24FMAU",
                      short: "24F (3D)",
                      long: "24F (3D)",
                      display: "24F (3D)",
                      display_with_size_set: "24F (3D)",
                      display_with_size_system: "AU 24F (3D)",
                      display_with_system_and_set: "AU 24F (3D)",
                      equivalent_sizes: {
                        us: ["46F (3D)M"],
                        eu: ["105GMEU"],
                        uk: ["46F (3D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24G (D)MAU",
                      short: "24G",
                      long: "24G",
                      display: "24G",
                      display_with_size_set: "24G",
                      display_with_size_system: "AU 24G",
                      display_with_system_and_set: "AU 24G",
                      equivalent_sizes: {
                        us: ["46G (4D)M"],
                        eu: ["105H (D)MEU"],
                        uk: ["46G (4D)MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26BMAU",
                      short: "26B (Maternity)",
                      long: "26B",
                      display: "26B",
                      display_with_size_set: "26B (Maternity)",
                      display_with_size_system: "AU 26B",
                      display_with_system_and_set: "AU 26B (Maternity)",
                      equivalent_sizes: {
                        us: ["48BM"],
                        eu: ["110CMEU"],
                        uk: ["48BMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26CMAU",
                      short: "26C (Maternity)",
                      long: "26C",
                      display: "26C",
                      display_with_size_set: "26C (Maternity)",
                      display_with_size_system: "AU 26C",
                      display_with_system_and_set: "AU 26C (Maternity)",
                      equivalent_sizes: {
                        us: ["48CM"],
                        eu: ["110DMEU"],
                        uk: ["48CMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26DMAU",
                      short: "26D (Maternity)",
                      long: "26D",
                      display: "26D",
                      display_with_size_set: "26D (Maternity)",
                      display_with_size_system: "AU 26D",
                      display_with_system_and_set: "AU 26D (Maternity)",
                      equivalent_sizes: {
                        us: ["48DM"],
                        eu: ["110EMEU"],
                        uk: ["48DMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26EMAU",
                      short: "26E (Maternity)",
                      long: "26E",
                      display: "26E",
                      display_with_size_set: "26E (Maternity)",
                      display_with_size_system: "AU 26E",
                      display_with_system_and_set: "AU 26E (Maternity)",
                      equivalent_sizes: {
                        us: ["48DDM"],
                        eu: ["110FMEU"],
                        uk: ["48DDMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26FMAU",
                      short: "26F (Maternity)",
                      long: "26F",
                      display: "26F",
                      display_with_size_set: "26F (Maternity)",
                      display_with_size_system: "AU 26F",
                      display_with_system_and_set: "AU 26F (Maternity)",
                      equivalent_sizes: {
                        us: ["48FM"],
                        eu: ["110GMEU"],
                        uk: ["48FMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26GMAU",
                      short: "26G (Maternity)",
                      long: "26G",
                      display: "26G",
                      display_with_size_set: "26G (Maternity)",
                      display_with_size_system: "AU 26G",
                      display_with_system_and_set: "AU 26G (Maternity)",
                      equivalent_sizes: {
                        us: ["48GM"],
                        eu: ["110HMEU"],
                        uk: ["48GMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSMEU",
                      short: "OS (Maternity)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Maternity)",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "65BMEU",
                      short: "65B (Maternity)",
                      long: "65B",
                      display: "65B",
                      display_with_size_set: "65B (Maternity)",
                      display_with_size_system: "EU 65B",
                      display_with_system_and_set: "EU 65B (Maternity)",
                      equivalent_sizes: {
                        us: ["30AM"],
                        au: ["8AMAU"],
                        uk: ["30AMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "65CMEU",
                      short: "65C (Maternity)",
                      long: "65C",
                      display: "65C",
                      display_with_size_set: "65C (Maternity)",
                      display_with_size_system: "EU 65C",
                      display_with_system_and_set: "EU 65C (Maternity)",
                      equivalent_sizes: {
                        us: ["30BM"],
                        au: ["8BMAU"],
                        uk: ["30BMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "70AMEU",
                      short: "70A (Maternity)",
                      long: "70A",
                      display: "70A",
                      display_with_size_set: "70A (Maternity)",
                      display_with_size_system: "EU 70A",
                      display_with_system_and_set: "EU 70A (Maternity)",
                      equivalent_sizes: {
                        us: ["32AAM"],
                        au: ["10AAMAU"],
                        uk: ["32AAMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "70BMEU",
                      short: "70B (Maternity)",
                      long: "70B",
                      display: "70B",
                      display_with_size_set: "70B (Maternity)",
                      display_with_size_system: "EU 70B",
                      display_with_system_and_set: "EU 70B (Maternity)",
                      equivalent_sizes: {
                        us: ["32AM"],
                        au: ["10AMAU"],
                        uk: ["32AMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "70CMEU",
                      short: "70C (Maternity)",
                      long: "70C",
                      display: "70C",
                      display_with_size_set: "70C (Maternity)",
                      display_with_size_system: "EU 70C",
                      display_with_system_and_set: "EU 70C (Maternity)",
                      equivalent_sizes: {
                        us: ["32BM"],
                        au: ["10BMAU"],
                        uk: ["32BMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "70DMEU",
                      short: "70D (Maternity)",
                      long: "70D",
                      display: "70D",
                      display_with_size_set: "70D (Maternity)",
                      display_with_size_system: "EU 70D",
                      display_with_system_and_set: "EU 70D (Maternity)",
                      equivalent_sizes: {
                        us: ["32CM"],
                        au: ["10CMAU"],
                        uk: ["32CMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "70EMEU",
                      short: "70E (Maternity)",
                      long: "70E",
                      display: "70E",
                      display_with_size_set: "70E (Maternity)",
                      display_with_size_system: "EU 70E",
                      display_with_system_and_set: "EU 70E (Maternity)",
                      equivalent_sizes: {
                        us: ["32DM"],
                        au: ["10DMAU"],
                        uk: ["32DMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "70FMEU",
                      short: "70F (Maternity)",
                      long: "70F",
                      display: "70F",
                      display_with_size_set: "70F (Maternity)",
                      display_with_size_system: "EU 70F",
                      display_with_system_and_set: "EU 70F (Maternity)",
                      equivalent_sizes: {
                        us: ["32E (DD)M"],
                        au: ["10EMAU"],
                        uk: ["32E (DD)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "70GMEU",
                      short: "70G (Maternity)",
                      long: "70G",
                      display: "70G",
                      display_with_size_set: "70G (Maternity)",
                      display_with_size_system: "EU 70G",
                      display_with_system_and_set: "EU 70G (Maternity)",
                      equivalent_sizes: {
                        us: ["32F (3D)M"],
                        au: ["10F(DDD)MAU"],
                        uk: ["32F (3D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "70H (4D)MEU",
                      short: "70H (Maternity)",
                      long: "70H",
                      display: "70H",
                      display_with_size_set: "70H (Maternity)",
                      display_with_size_system: "EU 70H",
                      display_with_system_and_set: "EU 70H (Maternity)",
                      equivalent_sizes: {
                        us: ["32G (4D)M"],
                        au: ["10G (4D)MAU"],
                        uk: ["32G (4D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "70I (5D)MEU",
                      short: "70I (Maternity)",
                      long: "70I",
                      display: "70I",
                      display_with_size_set: "70I (Maternity)",
                      display_with_size_system: "EU 70I",
                      display_with_system_and_set: "EU 70I (Maternity)",
                      equivalent_sizes: {
                        us: ["32H (5D)M"],
                        au: ["10H (5D)MAU"],
                        uk: ["32H (5D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "75AMEU",
                      short: "75A (Maternity)",
                      long: "75A",
                      display: "75A",
                      display_with_size_set: "75A (Maternity)",
                      display_with_size_system: "EU 75A",
                      display_with_system_and_set: "EU 75A (Maternity)",
                      equivalent_sizes: {
                        us: ["34AAM"],
                        au: ["12AAMAU"],
                        uk: ["34AAMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "75BMEU",
                      short: "75B (Maternity)",
                      long: "75B",
                      display: "75B",
                      display_with_size_set: "75B (Maternity)",
                      display_with_size_system: "EU 75B",
                      display_with_system_and_set: "EU 75B (Maternity)",
                      equivalent_sizes: {
                        us: ["34AM"],
                        au: ["12AMAU"],
                        uk: ["34AMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "75CMEU",
                      short: "75C (Maternity)",
                      long: "75C",
                      display: "75C",
                      display_with_size_set: "75C (Maternity)",
                      display_with_size_system: "EU 75C",
                      display_with_system_and_set: "EU 75C (Maternity)",
                      equivalent_sizes: {
                        us: ["34BM"],
                        au: ["12BMAU"],
                        uk: ["34BMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "75DMEU",
                      short: "75D (Maternity)",
                      long: "75D",
                      display: "75D",
                      display_with_size_set: "75D (Maternity)",
                      display_with_size_system: "EU 75D",
                      display_with_system_and_set: "EU 75D (Maternity)",
                      equivalent_sizes: {
                        us: ["34CM"],
                        au: ["12CMAU"],
                        uk: ["34CMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "75EMEU",
                      short: "75E (Maternity)",
                      long: "75E",
                      display: "75E",
                      display_with_size_set: "75E (Maternity)",
                      display_with_size_system: "EU 75E",
                      display_with_system_and_set: "EU 75E (Maternity)",
                      equivalent_sizes: {
                        us: ["34DM"],
                        au: ["12DMAU"],
                        uk: ["34DMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "75FMEU",
                      short: "75F (Maternity)",
                      long: "75F",
                      display: "75F",
                      display_with_size_set: "75F (Maternity)",
                      display_with_size_system: "EU 75F",
                      display_with_system_and_set: "EU 75F (Maternity)",
                      equivalent_sizes: {
                        us: ["34E (DD)M"],
                        au: ["12EMAU"],
                        uk: ["34E (DD)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "75GMEU",
                      short: "75G (Maternity)",
                      long: "75G",
                      display: "75G",
                      display_with_size_set: "75G (Maternity)",
                      display_with_size_system: "EU 75G",
                      display_with_system_and_set: "EU 75G (Maternity)",
                      equivalent_sizes: {
                        us: ["34F (3D)M"],
                        au: ["12FMAU"],
                        uk: ["34F (3D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "75H (D)MEU",
                      short: "75H (Maternity)",
                      long: "75H",
                      display: "75H",
                      display_with_size_set: "75H (Maternity)",
                      display_with_size_system: "EU 75H",
                      display_with_system_and_set: "EU 75H (Maternity)",
                      equivalent_sizes: {
                        us: ["34G (4D)M"],
                        au: ["12G (D)MAU"],
                        uk: ["34G (4D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "75I (5D)MEU",
                      short: "75I (Maternity)",
                      long: "75I",
                      display: "75I",
                      display_with_size_set: "75I (Maternity)",
                      display_with_size_system: "EU 75I",
                      display_with_system_and_set: "EU 75I (Maternity)",
                      equivalent_sizes: {
                        us: ["34H (5D)M"],
                        au: ["12H (5D)MAU"],
                        uk: ["34H (5D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "80AMEU",
                      short: "80A (Maternity)",
                      long: "80A",
                      display: "80A",
                      display_with_size_set: "80A (Maternity)",
                      display_with_size_system: "EU 80A",
                      display_with_system_and_set: "EU 80A (Maternity)",
                      equivalent_sizes: {
                        us: ["36AAM"],
                        au: ["14AAMAU"],
                        uk: ["36AAMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "80BMEU",
                      short: "80B (Maternity)",
                      long: "80B",
                      display: "80B",
                      display_with_size_set: "80B (Maternity)",
                      display_with_size_system: "EU 80B",
                      display_with_system_and_set: "EU 80B (Maternity)",
                      equivalent_sizes: {
                        us: ["36AM"],
                        au: ["14AMAU"],
                        uk: ["36AMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "80CMEU",
                      short: "80C (Maternity)",
                      long: "80C",
                      display: "80C",
                      display_with_size_set: "80C (Maternity)",
                      display_with_size_system: "EU 80C",
                      display_with_system_and_set: "EU 80C (Maternity)",
                      equivalent_sizes: {
                        us: ["36BM"],
                        au: ["14BMAU"],
                        uk: ["36BMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "80DMEU",
                      short: "80D (Maternity)",
                      long: "80D",
                      display: "80D",
                      display_with_size_set: "80D (Maternity)",
                      display_with_size_system: "EU 80D",
                      display_with_system_and_set: "EU 80D (Maternity)",
                      equivalent_sizes: {
                        us: ["36CM"],
                        au: ["14CMAU"],
                        uk: ["36CMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "80EMEU",
                      short: "80E (Maternity)",
                      long: "80E",
                      display: "80E",
                      display_with_size_set: "80E (Maternity)",
                      display_with_size_system: "EU 80E",
                      display_with_system_and_set: "EU 80E (Maternity)",
                      equivalent_sizes: {
                        us: ["36DM"],
                        au: ["14DMAU"],
                        uk: ["36DMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "80FMEU",
                      short: "80F (Maternity)",
                      long: "80F",
                      display: "80F",
                      display_with_size_set: "80F (Maternity)",
                      display_with_size_system: "EU 80F",
                      display_with_system_and_set: "EU 80F (Maternity)",
                      equivalent_sizes: {
                        us: ["36E (DD)M"],
                        au: ["14EMAU"],
                        uk: ["36E (DD)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "80GMEU",
                      short: "80G (Maternity)",
                      long: "80G",
                      display: "80G",
                      display_with_size_set: "80G (Maternity)",
                      display_with_size_system: "EU 80G",
                      display_with_system_and_set: "EU 80G (Maternity)",
                      equivalent_sizes: {
                        us: ["36F (3D)M"],
                        au: ["14FMAU"],
                        uk: ["36F (3D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "80H (4D)MEU",
                      short: "80H (Maternity)",
                      long: "80H",
                      display: "80H",
                      display_with_size_set: "80H (Maternity)",
                      display_with_size_system: "EU 80H",
                      display_with_system_and_set: "EU 80H (Maternity)",
                      equivalent_sizes: {
                        us: ["36G (4D)M"],
                        au: ["14G (4D)MAU"],
                        uk: ["36G (4D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "80I (5D)MEU",
                      short: "80I (Maternity)",
                      long: "80I",
                      display: "80I",
                      display_with_size_set: "80I (Maternity)",
                      display_with_size_system: "EU 80I",
                      display_with_system_and_set: "EU 80I (Maternity)",
                      equivalent_sizes: {
                        us: ["36H (5D)M"],
                        au: ["14H (5D)MAU"],
                        uk: ["36H (5D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "85BMEU",
                      short: "85B (Maternity)",
                      long: "85B",
                      display: "85B",
                      display_with_size_set: "85B (Maternity)",
                      display_with_size_system: "EU 85B",
                      display_with_system_and_set: "EU 85B (Maternity)",
                      equivalent_sizes: {
                        us: ["38AM"],
                        au: ["16AMAU"],
                        uk: ["38AMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "85CMEU",
                      short: "85C (Maternity)",
                      long: "85C",
                      display: "85C",
                      display_with_size_set: "85C (Maternity)",
                      display_with_size_system: "EU 85C",
                      display_with_system_and_set: "EU 85C (Maternity)",
                      equivalent_sizes: {
                        us: ["38BM"],
                        au: ["16BMAU"],
                        uk: ["38BMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "85DMEU",
                      short: "85D (Maternity)",
                      long: "85D",
                      display: "85D",
                      display_with_size_set: "85D (Maternity)",
                      display_with_size_system: "EU 85D",
                      display_with_system_and_set: "EU 85D (Maternity)",
                      equivalent_sizes: {
                        us: ["38CM"],
                        au: ["16CMAU"],
                        uk: ["38CMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "85EMEU",
                      short: "85E (Maternity)",
                      long: "85E",
                      display: "85E",
                      display_with_size_set: "85E (Maternity)",
                      display_with_size_system: "EU 85E",
                      display_with_system_and_set: "EU 85E (Maternity)",
                      equivalent_sizes: {
                        us: ["38DM"],
                        au: ["16DMAU"],
                        uk: ["38DMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "85FMEU",
                      short: "85F (Maternity)",
                      long: "85F",
                      display: "85F",
                      display_with_size_set: "85F (Maternity)",
                      display_with_size_system: "EU 85F",
                      display_with_system_and_set: "EU 85F (Maternity)",
                      equivalent_sizes: {
                        us: ["38E (DD)M"],
                        au: ["16EMAU"],
                        uk: ["38E (DD)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "85GMEU",
                      short: "85G (Maternity)",
                      long: "85G",
                      display: "85G",
                      display_with_size_set: "85G (Maternity)",
                      display_with_size_system: "EU 85G",
                      display_with_system_and_set: "EU 85G (Maternity)",
                      equivalent_sizes: {
                        us: ["38F (3D)M"],
                        au: ["16FMAU"],
                        uk: ["38F (3D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "85H (4D)MEU",
                      short: "85H (Maternity)",
                      long: "85H",
                      display: "85H",
                      display_with_size_set: "85H (Maternity)",
                      display_with_size_system: "EU 85H",
                      display_with_system_and_set: "EU 85H (Maternity)",
                      equivalent_sizes: {
                        us: ["38G (4D)M"],
                        au: ["16G (4D)MAU"],
                        uk: ["38G (4D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "85I (5D)MEU",
                      short: "85I (Maternity)",
                      long: "85I",
                      display: "85I",
                      display_with_size_set: "85I (Maternity)",
                      display_with_size_system: "EU 85I",
                      display_with_system_and_set: "EU 85I (Maternity)",
                      equivalent_sizes: {
                        us: ["38H (5D)M"],
                        au: ["16H (5D)MAU"],
                        uk: ["38H (5D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "90BMEU",
                      short: "90B (Maternity)",
                      long: "90B",
                      display: "90B",
                      display_with_size_set: "90B (Maternity)",
                      display_with_size_system: "EU 90B",
                      display_with_system_and_set: "EU 90B (Maternity)",
                      equivalent_sizes: {
                        us: ["40AM"],
                        au: ["18AMAU"],
                        uk: ["40AMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "90CMEU",
                      short: "90C (Maternity)",
                      long: "90C",
                      display: "90C",
                      display_with_size_set: "90C (Maternity)",
                      display_with_size_system: "EU 90C",
                      display_with_system_and_set: "EU 90C (Maternity)",
                      equivalent_sizes: {
                        us: ["40BM"],
                        au: ["18BMAU"],
                        uk: ["40BMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "90DMEU",
                      short: "90D (Maternity)",
                      long: "90D",
                      display: "90D",
                      display_with_size_set: "90D (Maternity)",
                      display_with_size_system: "EU 90D",
                      display_with_system_and_set: "EU 90D (Maternity)",
                      equivalent_sizes: {
                        us: ["40CM"],
                        au: ["18CMAU"],
                        uk: ["40CMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "90EMEU",
                      short: "90E (Maternity)",
                      long: "90E",
                      display: "90E",
                      display_with_size_set: "90E (Maternity)",
                      display_with_size_system: "EU 90E",
                      display_with_system_and_set: "EU 90E (Maternity)",
                      equivalent_sizes: {
                        us: ["40DM"],
                        au: ["18DMAU"],
                        uk: ["40DMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "90FMEU",
                      short: "90F (Maternity)",
                      long: "90F",
                      display: "90F",
                      display_with_size_set: "90F (Maternity)",
                      display_with_size_system: "EU 90F",
                      display_with_system_and_set: "EU 90F (Maternity)",
                      equivalent_sizes: {
                        us: ["40E (DD)M"],
                        au: ["18EMAU"],
                        uk: ["40E (DD)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "90GMEU",
                      short: "90G (Maternity)",
                      long: "90G",
                      display: "90G",
                      display_with_size_set: "90G (Maternity)",
                      display_with_size_system: "EU 90G",
                      display_with_system_and_set: "EU 90G (Maternity)",
                      equivalent_sizes: {
                        us: ["40F (3D)M"],
                        au: ["18FMAU"],
                        uk: ["40F (3D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "90H (D)MEU",
                      short: "90H (Maternity)",
                      long: "90H",
                      display: "90H",
                      display_with_size_set: "90H (Maternity)",
                      display_with_size_system: "EU 90H",
                      display_with_system_and_set: "EU 90H (Maternity)",
                      equivalent_sizes: {
                        us: ["40G (4D)M"],
                        au: ["18G (D)MAU"],
                        uk: ["40G (4D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "90I (5D)MEU",
                      short: "90I (Maternity)",
                      long: "90I",
                      display: "90I",
                      display_with_size_set: "90I (Maternity)",
                      display_with_size_system: "EU 90I",
                      display_with_system_and_set: "EU 90I (Maternity)",
                      equivalent_sizes: {
                        us: ["40H (5D)M"],
                        au: ["18H (5D)MAU"],
                        uk: ["40H (5D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "95CMEU",
                      short: "95C (Maternity)",
                      long: "95C",
                      display: "95C",
                      display_with_size_set: "95C (Maternity)",
                      display_with_size_system: "EU 95C",
                      display_with_system_and_set: "EU 95C (Maternity)",
                      equivalent_sizes: {
                        us: ["42BM"],
                        au: ["20BMAU"],
                        uk: ["42BMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "95DMEU",
                      short: "95D (Maternity)",
                      long: "95D",
                      display: "95D",
                      display_with_size_set: "95D (Maternity)",
                      display_with_size_system: "EU 95D",
                      display_with_system_and_set: "EU 95D (Maternity)",
                      equivalent_sizes: {
                        us: ["42CM"],
                        au: ["20CMAU"],
                        uk: ["42CMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "95EMEU",
                      short: "95E (Maternity)",
                      long: "95E",
                      display: "95E",
                      display_with_size_set: "95E (Maternity)",
                      display_with_size_system: "EU 95E",
                      display_with_system_and_set: "EU 95E (Maternity)",
                      equivalent_sizes: {
                        us: ["42DM"],
                        au: ["20DMAU"],
                        uk: ["42DMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "95FMEU",
                      short: "95F (Maternity)",
                      long: "95F",
                      display: "95F",
                      display_with_size_set: "95F (Maternity)",
                      display_with_size_system: "EU 95F",
                      display_with_system_and_set: "EU 95F (Maternity)",
                      equivalent_sizes: {
                        us: ["42E (DD)M"],
                        au: ["20EMAU"],
                        uk: ["42E (DD)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "95GMEU",
                      short: "95G (Maternity)",
                      long: "95G",
                      display: "95G",
                      display_with_size_set: "95G (Maternity)",
                      display_with_size_system: "EU 95G",
                      display_with_system_and_set: "EU 95G (Maternity)",
                      equivalent_sizes: {
                        us: ["42F (3D)M"],
                        au: ["20FMAU"],
                        uk: ["42F (3D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "95H (D)MEU",
                      short: "95H (Maternity)",
                      long: "95H",
                      display: "95H",
                      display_with_size_set: "95H (Maternity)",
                      display_with_size_system: "EU 95H",
                      display_with_system_and_set: "EU 95H (Maternity)",
                      equivalent_sizes: {
                        us: ["42G (4D)M"],
                        au: ["20G (D)MAU"],
                        uk: ["42G (4D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "95I (5D)MEU",
                      short: "95I (Maternity)",
                      long: "95I",
                      display: "95I",
                      display_with_size_set: "95I (Maternity)",
                      display_with_size_system: "EU 95I",
                      display_with_system_and_set: "EU 95I (Maternity)",
                      equivalent_sizes: {
                        us: ["42H (5D)M"],
                        au: ["20H (5D)MAU"],
                        uk: ["42H (5D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "100CMEU",
                      short: "100C (Maternity)",
                      long: "100C",
                      display: "100C",
                      display_with_size_set: "100C (Maternity)",
                      display_with_size_system: "EU 100C",
                      display_with_system_and_set: "EU 100C (Maternity)",
                      equivalent_sizes: {
                        us: ["44BM"],
                        au: ["22BMAU"],
                        uk: ["44BMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "100DMEU",
                      short: "100D (Maternity)",
                      long: "100D",
                      display: "100D",
                      display_with_size_set: "100D (Maternity)",
                      display_with_size_system: "EU 100D",
                      display_with_system_and_set: "EU 100D (Maternity)",
                      equivalent_sizes: {
                        us: ["44CM"],
                        au: ["22CMAU"],
                        uk: ["44CMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "100EMEU",
                      short: "100E (Maternity)",
                      long: "100E",
                      display: "100E",
                      display_with_size_set: "100E (Maternity)",
                      display_with_size_system: "EU 100E",
                      display_with_system_and_set: "EU 100E (Maternity)",
                      equivalent_sizes: {
                        us: ["44DM"],
                        au: ["22DMAU"],
                        uk: ["44DMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "100FMEU",
                      short: "100F (Maternity)",
                      long: "100F",
                      display: "100F",
                      display_with_size_set: "100F (Maternity)",
                      display_with_size_system: "EU 100F",
                      display_with_system_and_set: "EU 100F (Maternity)",
                      equivalent_sizes: {
                        us: ["44E (DD)M"],
                        au: ["22EMAU"],
                        uk: ["44E (DD)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "100GMEU",
                      short: "100G (Maternity)",
                      long: "100G",
                      display: "100G",
                      display_with_size_set: "100G (Maternity)",
                      display_with_size_system: "EU 100G",
                      display_with_system_and_set: "EU 100G (Maternity)",
                      equivalent_sizes: {
                        us: ["44F (3D)M"],
                        au: ["22FMAU"],
                        uk: ["44F (3D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "100H (D)MEU",
                      short: "100H (Maternity)",
                      long: "100H",
                      display: "100H",
                      display_with_size_set: "100H (Maternity)",
                      display_with_size_system: "EU 100H",
                      display_with_system_and_set: "EU 100H (Maternity)",
                      equivalent_sizes: {
                        us: ["44G (4D)M"],
                        au: ["22G (D)MAU"],
                        uk: ["44G (4D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "100I (5D)MEU",
                      short: "100I (Maternity)",
                      long: "100I",
                      display: "100I",
                      display_with_size_set: "100I (Maternity)",
                      display_with_size_system: "EU 100I",
                      display_with_system_and_set: "EU 100I (Maternity)",
                      equivalent_sizes: {
                        us: ["44H (5D)M"],
                        au: ["22H (5D)MAU"],
                        uk: ["44H (5D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "105CMEU",
                      short: "105C (Maternity)",
                      long: "105C",
                      display: "105C",
                      display_with_size_set: "105C (Maternity)",
                      display_with_size_system: "EU 105C",
                      display_with_system_and_set: "EU 105C (Maternity)",
                      equivalent_sizes: {
                        us: ["46BM"],
                        au: ["24BMAU"],
                        uk: ["46BMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "105DMEU",
                      short: "105D (Maternity)",
                      long: "105D",
                      display: "105D",
                      display_with_size_set: "105D (Maternity)",
                      display_with_size_system: "EU 105D",
                      display_with_system_and_set: "EU 105D (Maternity)",
                      equivalent_sizes: {
                        us: ["46CM"],
                        au: ["24CMAU"],
                        uk: ["46CMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "105EMEU",
                      short: "105E (Maternity)",
                      long: "105E",
                      display: "105E",
                      display_with_size_set: "105E (Maternity)",
                      display_with_size_system: "EU 105E",
                      display_with_system_and_set: "EU 105E (Maternity)",
                      equivalent_sizes: {
                        us: ["46DM"],
                        au: ["24DMAU"],
                        uk: ["46DMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "105FMEU",
                      short: "105F (Maternity)",
                      long: "105F",
                      display: "105F",
                      display_with_size_set: "105F (Maternity)",
                      display_with_size_system: "EU 105F",
                      display_with_system_and_set: "EU 105F (Maternity)",
                      equivalent_sizes: {
                        us: ["46DDM"],
                        au: ["24EMAU"],
                        uk: ["46DDMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "105GMEU",
                      short: "105G (Maternity)",
                      long: "105G",
                      display: "105G",
                      display_with_size_set: "105G (Maternity)",
                      display_with_size_system: "EU 105G",
                      display_with_system_and_set: "EU 105G (Maternity)",
                      equivalent_sizes: {
                        us: ["46F (3D)M"],
                        au: ["24FMAU"],
                        uk: ["46F (3D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "105H (D)MEU",
                      short: "105H (Maternity)",
                      long: "105H",
                      display: "105H",
                      display_with_size_set: "105H (Maternity)",
                      display_with_size_system: "EU 105H",
                      display_with_system_and_set: "EU 105H (Maternity)",
                      equivalent_sizes: {
                        us: ["46G (4D)M"],
                        au: ["24G (D)MAU"],
                        uk: ["46G (4D)MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "110CMEU",
                      short: "110C (Maternity)",
                      long: "110C",
                      display: "110C",
                      display_with_size_set: "110C (Maternity)",
                      display_with_size_system: "EU 110C",
                      display_with_system_and_set: "EU 110C (Maternity)",
                      equivalent_sizes: {
                        us: ["48BM"],
                        au: ["26BMAU"],
                        uk: ["48BMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "110DMEU",
                      short: "110D (Maternity)",
                      long: "110D",
                      display: "110D",
                      display_with_size_set: "110D (Maternity)",
                      display_with_size_system: "EU 110D",
                      display_with_system_and_set: "EU 110D (Maternity)",
                      equivalent_sizes: {
                        us: ["48CM"],
                        au: ["26CMAU"],
                        uk: ["48CMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "110EMEU",
                      short: "110E (Maternity)",
                      long: "110E",
                      display: "110E",
                      display_with_size_set: "110E (Maternity)",
                      display_with_size_system: "EU 110E",
                      display_with_system_and_set: "EU 110E (Maternity)",
                      equivalent_sizes: {
                        us: ["48DM"],
                        au: ["26DMAU"],
                        uk: ["48DMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "110FMEU",
                      short: "110F (Maternity)",
                      long: "110F",
                      display: "110F",
                      display_with_size_set: "110F (Maternity)",
                      display_with_size_system: "EU 110F",
                      display_with_system_and_set: "EU 110F (Maternity)",
                      equivalent_sizes: {
                        us: ["48DDM"],
                        au: ["26EMAU"],
                        uk: ["48DDMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "110GMEU",
                      short: "110G (Maternity)",
                      long: "110G",
                      display: "110G",
                      display_with_size_set: "110G (Maternity)",
                      display_with_size_system: "EU 110G",
                      display_with_system_and_set: "EU 110G (Maternity)",
                      equivalent_sizes: {
                        us: ["48FM"],
                        au: ["26FMAU"],
                        uk: ["48FMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "110HMEU",
                      short: "110H (Maternity)",
                      long: "110H",
                      display: "110H",
                      display_with_size_set: "110H (Maternity)",
                      display_with_size_system: "EU 110H",
                      display_with_system_and_set: "EU 110H (Maternity)",
                      equivalent_sizes: {
                        us: ["48GM"],
                        au: ["26GMAU"],
                        uk: ["48GMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSMUK",
                      short: "OS (Maternity)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Maternity)",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS (Maternity)",
                      equivalent_sizes: {
                        us: ["OSM"],
                        au: ["OSMAU"],
                        eu: ["OSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30AMUK",
                      short: "30A (Maternity)",
                      long: "30A",
                      display: "30A",
                      display_with_size_set: "30A (Maternity)",
                      display_with_size_system: "UK 30A",
                      display_with_system_and_set: "UK 30A (Maternity)",
                      equivalent_sizes: {
                        us: ["30AM"],
                        au: ["8AMAU"],
                        eu: ["65BMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30BMUK",
                      short: "30B (Maternity)",
                      long: "30B",
                      display: "30B",
                      display_with_size_set: "30B (Maternity)",
                      display_with_size_system: "UK 30B",
                      display_with_system_and_set: "UK 30B (Maternity)",
                      equivalent_sizes: {
                        us: ["30BM"],
                        au: ["8BMAU"],
                        eu: ["65CMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32AAMUK",
                      short: "32AA (Maternity)",
                      long: "32AA",
                      display: "32AA",
                      display_with_size_set: "32AA (Maternity)",
                      display_with_size_system: "UK 32AA",
                      display_with_system_and_set: "UK 32AA (Maternity)",
                      equivalent_sizes: {
                        us: ["32AAM"],
                        au: ["10AAMAU"],
                        eu: ["70AMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32AMUK",
                      short: "32A (Maternity)",
                      long: "32A",
                      display: "32A",
                      display_with_size_set: "32A (Maternity)",
                      display_with_size_system: "UK 32A",
                      display_with_system_and_set: "UK 32A (Maternity)",
                      equivalent_sizes: {
                        us: ["32AM"],
                        au: ["10AMAU"],
                        eu: ["70BMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32BMUK",
                      short: "32B (Maternity)",
                      long: "32B",
                      display: "32B",
                      display_with_size_set: "32B (Maternity)",
                      display_with_size_system: "UK 32B",
                      display_with_system_and_set: "UK 32B (Maternity)",
                      equivalent_sizes: {
                        us: ["32BM"],
                        au: ["10BMAU"],
                        eu: ["70CMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32CMUK",
                      short: "32C (Maternity)",
                      long: "32C",
                      display: "32C",
                      display_with_size_set: "32C (Maternity)",
                      display_with_size_system: "UK 32C",
                      display_with_system_and_set: "UK 32C (Maternity)",
                      equivalent_sizes: {
                        us: ["32CM"],
                        au: ["10CMAU"],
                        eu: ["70DMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32DMUK",
                      short: "32D (Maternity)",
                      long: "32D",
                      display: "32D",
                      display_with_size_set: "32D (Maternity)",
                      display_with_size_system: "UK 32D",
                      display_with_system_and_set: "UK 32D (Maternity)",
                      equivalent_sizes: {
                        us: ["32DM"],
                        au: ["10DMAU"],
                        eu: ["70EMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32E (DD)MUK",
                      short: "32E (DD) (Maternity)",
                      long: "32E (DD)",
                      display: "32E (DD)",
                      display_with_size_set: "32E (DD) (Maternity)",
                      display_with_size_system: "UK 32E",
                      display_with_system_and_set: "UK 32E (DD) (Maternity)",
                      equivalent_sizes: {
                        us: ["32E (DD)M"],
                        au: ["10EMAU"],
                        eu: ["70FMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32F (3D)MUK",
                      short: "32F (3D) (Maternity)",
                      long: "32F (3D)",
                      display: "32F (3D)",
                      display_with_size_set: "32F (3D) (Maternity)",
                      display_with_size_system: "UK 32F",
                      display_with_system_and_set: "UK 32F (3D) (Maternity)",
                      equivalent_sizes: {
                        us: ["32F (3D)M"],
                        au: ["10F(DDD)MAU"],
                        eu: ["70GMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32G (4D)MUK",
                      short: "32G (4D) (Maternity)",
                      long: "32G (4D)",
                      display: "32G (4D)",
                      display_with_size_set: "32G (4D) (Maternity)",
                      display_with_size_system: "UK 32G",
                      display_with_system_and_set: "UK 32G (4D) (Maternity)",
                      equivalent_sizes: {
                        us: ["32G (4D)M"],
                        au: ["10G (4D)MAU"],
                        eu: ["70H (4D)MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32H (5D)MUK",
                      short: "32H (5D) (Maternity)",
                      long: "32H (5D)",
                      display: "32H (5D)",
                      display_with_size_set: "32H (5D) (Maternity)",
                      display_with_size_system: "UK 32H",
                      display_with_system_and_set: "UK 32H (5D) (Maternity)",
                      equivalent_sizes: {
                        us: ["32H (5D)M"],
                        au: ["10H (5D)MAU"],
                        eu: ["70I (5D)MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34AAMUK",
                      short: "34AA (Maternity)",
                      long: "34AA",
                      display: "34AA",
                      display_with_size_set: "34AA (Maternity)",
                      display_with_size_system: "UK 34AA",
                      display_with_system_and_set: "UK 34AA (Maternity)",
                      equivalent_sizes: {
                        us: ["34AAM"],
                        au: ["12AAMAU"],
                        eu: ["75AMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34AMUK",
                      short: "34A (Maternity)",
                      long: "34A",
                      display: "34A",
                      display_with_size_set: "34A (Maternity)",
                      display_with_size_system: "UK 34A",
                      display_with_system_and_set: "UK 34A (Maternity)",
                      equivalent_sizes: {
                        us: ["34AM"],
                        au: ["12AMAU"],
                        eu: ["75BMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34BMUK",
                      short: "34B (Maternity)",
                      long: "34B",
                      display: "34B",
                      display_with_size_set: "34B (Maternity)",
                      display_with_size_system: "UK 34B",
                      display_with_system_and_set: "UK 34B (Maternity)",
                      equivalent_sizes: {
                        us: ["34BM"],
                        au: ["12BMAU"],
                        eu: ["75CMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34CMUK",
                      short: "34C (Maternity)",
                      long: "34C",
                      display: "34C",
                      display_with_size_set: "34C (Maternity)",
                      display_with_size_system: "UK 34C",
                      display_with_system_and_set: "UK 34C (Maternity)",
                      equivalent_sizes: {
                        us: ["34CM"],
                        au: ["12CMAU"],
                        eu: ["75DMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34DMUK",
                      short: "34D (Maternity)",
                      long: "34D",
                      display: "34D",
                      display_with_size_set: "34D (Maternity)",
                      display_with_size_system: "UK 34D",
                      display_with_system_and_set: "UK 34D (Maternity)",
                      equivalent_sizes: {
                        us: ["34DM"],
                        au: ["12DMAU"],
                        eu: ["75EMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34E (DD)MUK",
                      short: "34E (DD) (Maternity)",
                      long: "34E (DD)",
                      display: "34E (DD)",
                      display_with_size_set: "34E (DD) (Maternity)",
                      display_with_size_system: "UK 34E",
                      display_with_system_and_set: "UK 34E (DD) (Maternity)",
                      equivalent_sizes: {
                        us: ["34E (DD)M"],
                        au: ["12EMAU"],
                        eu: ["75FMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34F (3D)MUK",
                      short: "34F (3D) (Maternity)",
                      long: "34F (3D)",
                      display: "34F (3D)",
                      display_with_size_set: "34F (3D) (Maternity)",
                      display_with_size_system: "UK 34F",
                      display_with_system_and_set: "UK 34F (3D) (Maternity)",
                      equivalent_sizes: {
                        us: ["34F (3D)M"],
                        au: ["12FMAU"],
                        eu: ["75GMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34G (4D)MUK",
                      short: "34G (4D) (Maternity)",
                      long: "34G (4D)",
                      display: "34G (4D)",
                      display_with_size_set: "34G (4D) (Maternity)",
                      display_with_size_system: "UK 34G",
                      display_with_system_and_set: "UK 34G (4D) (Maternity)",
                      equivalent_sizes: {
                        us: ["34G (4D)M"],
                        au: ["12G (D)MAU"],
                        eu: ["75H (D)MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34H (5D)MUK",
                      short: "34H (5D) (Maternity)",
                      long: "34H (5D)",
                      display: "34H (5D)",
                      display_with_size_set: "34H (5D) (Maternity)",
                      display_with_size_system: "UK 34H",
                      display_with_system_and_set: "UK 34H (5D) (Maternity)",
                      equivalent_sizes: {
                        us: ["34H (5D)M"],
                        au: ["12H (5D)MAU"],
                        eu: ["75I (5D)MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36AAMUK",
                      short: "36AA (Maternity)",
                      long: "36AA",
                      display: "36AA",
                      display_with_size_set: "36AA (Maternity)",
                      display_with_size_system: "UK 36AA",
                      display_with_system_and_set: "UK 36AA (Maternity)",
                      equivalent_sizes: {
                        us: ["36AAM"],
                        au: ["14AAMAU"],
                        eu: ["80AMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36AMUK",
                      short: "36A (Maternity)",
                      long: "36A",
                      display: "36A",
                      display_with_size_set: "36A (Maternity)",
                      display_with_size_system: "UK 36A",
                      display_with_system_and_set: "UK 36A (Maternity)",
                      equivalent_sizes: {
                        us: ["36AM"],
                        au: ["14AMAU"],
                        eu: ["80BMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36BMUK",
                      short: "36B (Maternity)",
                      long: "36B",
                      display: "36B",
                      display_with_size_set: "36B (Maternity)",
                      display_with_size_system: "UK 36B",
                      display_with_system_and_set: "UK 36B (Maternity)",
                      equivalent_sizes: {
                        us: ["36BM"],
                        au: ["14BMAU"],
                        eu: ["80CMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36CMUK",
                      short: "36C (Maternity)",
                      long: "36C",
                      display: "36C",
                      display_with_size_set: "36C (Maternity)",
                      display_with_size_system: "UK 36C",
                      display_with_system_and_set: "UK 36C (Maternity)",
                      equivalent_sizes: {
                        us: ["36CM"],
                        au: ["14CMAU"],
                        eu: ["80DMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36DMUK",
                      short: "36D (Maternity)",
                      long: "36D",
                      display: "36D",
                      display_with_size_set: "36D (Maternity)",
                      display_with_size_system: "UK 36D",
                      display_with_system_and_set: "UK 36D (Maternity)",
                      equivalent_sizes: {
                        us: ["36DM"],
                        au: ["14DMAU"],
                        eu: ["80EMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36E (DD)MUK",
                      short: "36E (DD) (Maternity)",
                      long: "36E (DD)",
                      display: "36E (DD)",
                      display_with_size_set: "36E (DD) (Maternity)",
                      display_with_size_system: "UK 36E",
                      display_with_system_and_set: "UK 36E (DD) (Maternity)",
                      equivalent_sizes: {
                        us: ["36E (DD)M"],
                        au: ["14EMAU"],
                        eu: ["80FMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36F (3D)MUK",
                      short: "36F (3D) (Maternity)",
                      long: "36F (3D)",
                      display: "36F (3D)",
                      display_with_size_set: "36F (3D) (Maternity)",
                      display_with_size_system: "UK 36F",
                      display_with_system_and_set: "UK 36F (3D) (Maternity)",
                      equivalent_sizes: {
                        us: ["36F (3D)M"],
                        au: ["14FMAU"],
                        eu: ["80GMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36G (4D)MUK",
                      short: "36G (4D) (Maternity)",
                      long: "36G (4D)",
                      display: "36G (4D)",
                      display_with_size_set: "36G (4D) (Maternity)",
                      display_with_size_system: "UK 36G",
                      display_with_system_and_set: "UK 36G (4D) (Maternity)",
                      equivalent_sizes: {
                        us: ["36G (4D)M"],
                        au: ["14G (4D)MAU"],
                        eu: ["80H (4D)MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36H (5D)MUK",
                      short: "36H (5D) (Maternity)",
                      long: "36H (5D)",
                      display: "36H (5D)",
                      display_with_size_set: "36H (5D) (Maternity)",
                      display_with_size_system: "UK 36H",
                      display_with_system_and_set: "UK 36H (5D) (Maternity)",
                      equivalent_sizes: {
                        us: ["36H (5D)M"],
                        au: ["14H (5D)MAU"],
                        eu: ["80I (5D)MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38AMUK",
                      short: "38A (Maternity)",
                      long: "38A",
                      display: "38A",
                      display_with_size_set: "38A (Maternity)",
                      display_with_size_system: "UK 38A",
                      display_with_system_and_set: "UK 38A (Maternity)",
                      equivalent_sizes: {
                        us: ["38AM"],
                        au: ["16AMAU"],
                        eu: ["85BMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38BMUK",
                      short: "38B (Maternity)",
                      long: "38B",
                      display: "38B",
                      display_with_size_set: "38B (Maternity)",
                      display_with_size_system: "UK 38B",
                      display_with_system_and_set: "UK 38B (Maternity)",
                      equivalent_sizes: {
                        us: ["38BM"],
                        au: ["16BMAU"],
                        eu: ["85CMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38CMUK",
                      short: "38C (Maternity)",
                      long: "38C",
                      display: "38C",
                      display_with_size_set: "38C (Maternity)",
                      display_with_size_system: "UK 38C",
                      display_with_system_and_set: "UK 38C (Maternity)",
                      equivalent_sizes: {
                        us: ["38CM"],
                        au: ["16CMAU"],
                        eu: ["85DMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38DMUK",
                      short: "38D (Maternity)",
                      long: "38D",
                      display: "38D",
                      display_with_size_set: "38D (Maternity)",
                      display_with_size_system: "UK 38D",
                      display_with_system_and_set: "UK 38D (Maternity)",
                      equivalent_sizes: {
                        us: ["38DM"],
                        au: ["16DMAU"],
                        eu: ["85EMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38E (DD)MUK",
                      short: "38E (DD) (Maternity)",
                      long: "38E (DD)",
                      display: "38E (DD)",
                      display_with_size_set: "38E (DD) (Maternity)",
                      display_with_size_system: "UK 38E",
                      display_with_system_and_set: "UK 38E (DD) (Maternity)",
                      equivalent_sizes: {
                        us: ["38E (DD)M"],
                        au: ["16EMAU"],
                        eu: ["85FMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38F (3D)MUK",
                      short: "38F (3D) (Maternity)",
                      long: "38F (3D)",
                      display: "38F (3D)",
                      display_with_size_set: "38F (3D) (Maternity)",
                      display_with_size_system: "UK 38F",
                      display_with_system_and_set: "UK 38F (3D) (Maternity)",
                      equivalent_sizes: {
                        us: ["38F (3D)M"],
                        au: ["16FMAU"],
                        eu: ["85GMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38G (4D)MUK",
                      short: "38G (4D) (Maternity)",
                      long: "38G (4D)",
                      display: "38G (4D)",
                      display_with_size_set: "38G (4D) (Maternity)",
                      display_with_size_system: "UK 38G",
                      display_with_system_and_set: "UK 38G (4D) (Maternity)",
                      equivalent_sizes: {
                        us: ["38G (4D)M"],
                        au: ["16G (4D)MAU"],
                        eu: ["85H (4D)MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38H (5D)MUK",
                      short: "38H (5D) (Maternity)",
                      long: "38H (5D)",
                      display: "38H (5D)",
                      display_with_size_set: "38H (5D) (Maternity)",
                      display_with_size_system: "UK 38H",
                      display_with_system_and_set: "UK 38H (5D) (Maternity)",
                      equivalent_sizes: {
                        us: ["38H (5D)M"],
                        au: ["16H (5D)MAU"],
                        eu: ["85I (5D)MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40AMUK",
                      short: "40A (Maternity)",
                      long: "40A",
                      display: "40A",
                      display_with_size_set: "40A (Maternity)",
                      display_with_size_system: "UK 40A",
                      display_with_system_and_set: "UK 40A (Maternity)",
                      equivalent_sizes: {
                        us: ["40AM"],
                        au: ["18AMAU"],
                        eu: ["90BMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40BMUK",
                      short: "40B (Maternity)",
                      long: "40B",
                      display: "40B",
                      display_with_size_set: "40B (Maternity)",
                      display_with_size_system: "UK 40B",
                      display_with_system_and_set: "UK 40B (Maternity)",
                      equivalent_sizes: {
                        us: ["40BM"],
                        au: ["18BMAU"],
                        eu: ["90CMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40CMUK",
                      short: "40C (Maternity)",
                      long: "40C",
                      display: "40C",
                      display_with_size_set: "40C (Maternity)",
                      display_with_size_system: "UK 40C",
                      display_with_system_and_set: "UK 40C (Maternity)",
                      equivalent_sizes: {
                        us: ["40CM"],
                        au: ["18CMAU"],
                        eu: ["90DMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40DMUK",
                      short: "40D (Maternity)",
                      long: "40D",
                      display: "40D",
                      display_with_size_set: "40D (Maternity)",
                      display_with_size_system: "UK 40D",
                      display_with_system_and_set: "UK 40D (Maternity)",
                      equivalent_sizes: {
                        us: ["40DM"],
                        au: ["18DMAU"],
                        eu: ["90EMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40E (DD)MUK",
                      short: "40E (DD) (Maternity)",
                      long: "40E (DD)",
                      display: "40E (DD)",
                      display_with_size_set: "40E (DD) (Maternity)",
                      display_with_size_system: "UK 40E",
                      display_with_system_and_set: "UK 40E (DD) (Maternity)",
                      equivalent_sizes: {
                        us: ["40E (DD)M"],
                        au: ["18EMAU"],
                        eu: ["90FMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40F (3D)MUK",
                      short: "40F (3D) (Maternity)",
                      long: "40F (3D)",
                      display: "40F (3D)",
                      display_with_size_set: "40F (3D) (Maternity)",
                      display_with_size_system: "UK 40F",
                      display_with_system_and_set: "UK 40F (3D) (Maternity)",
                      equivalent_sizes: {
                        us: ["40F (3D)M"],
                        au: ["18FMAU"],
                        eu: ["90GMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40G (4D)MUK",
                      short: "40G (4D) (Maternity)",
                      long: "40G (4D)",
                      display: "40G (4D)",
                      display_with_size_set: "40G (4D) (Maternity)",
                      display_with_size_system: "UK 40G",
                      display_with_system_and_set: "UK 40G (4D) (Maternity)",
                      equivalent_sizes: {
                        us: ["40G (4D)M"],
                        au: ["18G (D)MAU"],
                        eu: ["90H (D)MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40H (5D)MUK",
                      short: "40H (5D) (Maternity)",
                      long: "40H (5D)",
                      display: "40H (5D)",
                      display_with_size_set: "40H (5D) (Maternity)",
                      display_with_size_system: "UK 40H",
                      display_with_system_and_set: "UK 40H (5D) (Maternity)",
                      equivalent_sizes: {
                        us: ["40H (5D)M"],
                        au: ["18H (5D)MAU"],
                        eu: ["90I (5D)MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42AMUK",
                      short: "42A (Maternity)",
                      long: "42A",
                      display: "42A",
                      display_with_size_set: "42A (Maternity)",
                      display_with_size_system: "UK 42A",
                      display_with_system_and_set: "UK 42A (Maternity)",
                      equivalent_sizes: {
                        us: ["42AM"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42BMUK",
                      short: "42B (Maternity)",
                      long: "42B",
                      display: "42B",
                      display_with_size_set: "42B (Maternity)",
                      display_with_size_system: "UK 42B",
                      display_with_system_and_set: "UK 42B (Maternity)",
                      equivalent_sizes: {
                        us: ["42BM"],
                        au: ["20BMAU"],
                        eu: ["95CMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42CMUK",
                      short: "42C (Maternity)",
                      long: "42C",
                      display: "42C",
                      display_with_size_set: "42C (Maternity)",
                      display_with_size_system: "UK 42C",
                      display_with_system_and_set: "UK 42C (Maternity)",
                      equivalent_sizes: {
                        us: ["42CM"],
                        au: ["20CMAU"],
                        eu: ["95DMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42DMUK",
                      short: "42D (Maternity)",
                      long: "42D",
                      display: "42D",
                      display_with_size_set: "42D (Maternity)",
                      display_with_size_system: "UK 42D",
                      display_with_system_and_set: "UK 42D (Maternity)",
                      equivalent_sizes: {
                        us: ["42DM"],
                        au: ["20DMAU"],
                        eu: ["95EMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42E (DD)MUK",
                      short: "42E (DD) (Maternity)",
                      long: "42E (DD)",
                      display: "42E (DD)",
                      display_with_size_set: "42E (DD) (Maternity)",
                      display_with_size_system: "UK 42E",
                      display_with_system_and_set: "UK 42E (DD) (Maternity)",
                      equivalent_sizes: {
                        us: ["42E (DD)M"],
                        au: ["20EMAU"],
                        eu: ["95FMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42F (3D)MUK",
                      short: "42F (3D) (Maternity)",
                      long: "42F (3D)",
                      display: "42F (3D)",
                      display_with_size_set: "42F (3D) (Maternity)",
                      display_with_size_system: "UK 42F",
                      display_with_system_and_set: "UK 42F (3D) (Maternity)",
                      equivalent_sizes: {
                        us: ["42F (3D)M"],
                        au: ["20FMAU"],
                        eu: ["95GMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42G (4D)MUK",
                      short: "42G (4D) (Maternity)",
                      long: "42G (4D)",
                      display: "42G (4D)",
                      display_with_size_set: "42G (4D) (Maternity)",
                      display_with_size_system: "UK 42G",
                      display_with_system_and_set: "UK 42G (4D) (Maternity)",
                      equivalent_sizes: {
                        us: ["42G (4D)M"],
                        au: ["20G (D)MAU"],
                        eu: ["95H (D)MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42H (5D)MUK",
                      short: "42H (5D) (Maternity)",
                      long: "42H (5D)",
                      display: "42H (5D)",
                      display_with_size_set: "42H (5D) (Maternity)",
                      display_with_size_system: "UK 42H",
                      display_with_system_and_set: "UK 42H (5D) (Maternity)",
                      equivalent_sizes: {
                        us: ["42H (5D)M"],
                        au: ["20H (5D)MAU"],
                        eu: ["95I (5D)MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44AMUK",
                      short: "44A (Maternity)",
                      long: "44A",
                      display: "44A",
                      display_with_size_set: "44A (Maternity)",
                      display_with_size_system: "UK 44A",
                      display_with_system_and_set: "UK 44A (Maternity)",
                      equivalent_sizes: {
                        us: ["44AM"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44BMUK",
                      short: "44B (Maternity)",
                      long: "44B",
                      display: "44B",
                      display_with_size_set: "44B (Maternity)",
                      display_with_size_system: "UK 44B",
                      display_with_system_and_set: "UK 44B (Maternity)",
                      equivalent_sizes: {
                        us: ["44BM"],
                        au: ["22BMAU"],
                        eu: ["100CMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44CMUK",
                      short: "44C (Maternity)",
                      long: "44C",
                      display: "44C",
                      display_with_size_set: "44C (Maternity)",
                      display_with_size_system: "UK 44C",
                      display_with_system_and_set: "UK 44C (Maternity)",
                      equivalent_sizes: {
                        us: ["44CM"],
                        au: ["22CMAU"],
                        eu: ["100DMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44DMUK",
                      short: "44D (Maternity)",
                      long: "44D",
                      display: "44D",
                      display_with_size_set: "44D (Maternity)",
                      display_with_size_system: "UK 44D",
                      display_with_system_and_set: "UK 44D (Maternity)",
                      equivalent_sizes: {
                        us: ["44DM"],
                        au: ["22DMAU"],
                        eu: ["100EMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44E (DD)MUK",
                      short: "44E (DD) (Maternity)",
                      long: "44E (DD)",
                      display: "44E (DD)",
                      display_with_size_set: "44E (DD) (Maternity)",
                      display_with_size_system: "UK 44E",
                      display_with_system_and_set: "UK 44E (DD) (Maternity)",
                      equivalent_sizes: {
                        us: ["44E (DD)M"],
                        au: ["22EMAU"],
                        eu: ["100FMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44F (3D)MUK",
                      short: "44F (3D) (Maternity)",
                      long: "44F (3D)",
                      display: "44F (3D)",
                      display_with_size_set: "44F (3D) (Maternity)",
                      display_with_size_system: "UK 44F",
                      display_with_system_and_set: "UK 44F (3D) (Maternity)",
                      equivalent_sizes: {
                        us: ["44F (3D)M"],
                        au: ["22FMAU"],
                        eu: ["100GMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44G (4D)MUK",
                      short: "44G (4D) (Maternity)",
                      long: "44G (4D)",
                      display: "44G (4D)",
                      display_with_size_set: "44G (4D) (Maternity)",
                      display_with_size_system: "UK 44G",
                      display_with_system_and_set: "UK 44G (4D) (Maternity)",
                      equivalent_sizes: {
                        us: ["44G (4D)M"],
                        au: ["22G (D)MAU"],
                        eu: ["100H (D)MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44H (5D)MUK",
                      short: "44H (5D) (Maternity)",
                      long: "44H (5D)",
                      display: "44H (5D)",
                      display_with_size_set: "44H (5D) (Maternity)",
                      display_with_size_system: "UK 44H",
                      display_with_system_and_set: "UK 44H (5D) (Maternity)",
                      equivalent_sizes: {
                        us: ["44H (5D)M"],
                        au: ["22H (5D)MAU"],
                        eu: ["100I (5D)MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46AMUK",
                      short: "46A (Maternity)",
                      long: "46A",
                      display: "46A",
                      display_with_size_set: "46A (Maternity)",
                      display_with_size_system: "UK 46A",
                      display_with_system_and_set: "UK 46A (Maternity)",
                      equivalent_sizes: {
                        us: ["46AM"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46BMUK",
                      short: "46B (Maternity)",
                      long: "46B",
                      display: "46B",
                      display_with_size_set: "46B (Maternity)",
                      display_with_size_system: "UK 46B",
                      display_with_system_and_set: "UK 46B (Maternity)",
                      equivalent_sizes: {
                        us: ["46BM"],
                        au: ["24BMAU"],
                        eu: ["105CMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46CMUK",
                      short: "46C (Maternity)",
                      long: "46C",
                      display: "46C",
                      display_with_size_set: "46C (Maternity)",
                      display_with_size_system: "UK 46C",
                      display_with_system_and_set: "UK 46C (Maternity)",
                      equivalent_sizes: {
                        us: ["46CM"],
                        au: ["24CMAU"],
                        eu: ["105DMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46DMUK",
                      short: "46D (Maternity)",
                      long: "46D",
                      display: "46D",
                      display_with_size_set: "46D (Maternity)",
                      display_with_size_system: "UK 46D",
                      display_with_system_and_set: "UK 46D (Maternity)",
                      equivalent_sizes: {
                        us: ["46DM"],
                        au: ["24DMAU"],
                        eu: ["105EMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46DDMUK",
                      short: "46DD (Maternity)",
                      long: "46DD",
                      display: "46DD",
                      display_with_size_set: "46DD (Maternity)",
                      display_with_size_system: "UK 46DD",
                      display_with_system_and_set: "UK 46DD (Maternity)",
                      equivalent_sizes: {
                        us: ["46DDM"],
                        au: ["24EMAU"],
                        eu: ["105FMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46F (3D)MUK",
                      short: "46F (3D) (Maternity)",
                      long: "46F (3D)",
                      display: "46F (3D)",
                      display_with_size_set: "46F (3D) (Maternity)",
                      display_with_size_system: "UK 46F",
                      display_with_system_and_set: "UK 46F (3D) (Maternity)",
                      equivalent_sizes: {
                        us: ["46F (3D)M"],
                        au: ["24FMAU"],
                        eu: ["105GMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46G (4D)MUK",
                      short: "46G (4D) (Maternity)",
                      long: "46G (4D)",
                      display: "46G (4D)",
                      display_with_size_set: "46G (4D) (Maternity)",
                      display_with_size_system: "UK 46G",
                      display_with_system_and_set: "UK 46G (4D) (Maternity)",
                      equivalent_sizes: {
                        us: ["46G (4D)M"],
                        au: ["24G (D)MAU"],
                        eu: ["105H (D)MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46H (5D)MUK",
                      short: "46H (5D) (Maternity)",
                      long: "46H (5D)",
                      display: "46H (5D)",
                      display_with_size_set: "46H (5D) (Maternity)",
                      display_with_size_system: "UK 46H",
                      display_with_system_and_set: "UK 46H (5D) (Maternity)",
                      equivalent_sizes: {
                        us: ["46H (5D)M"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48AMUK",
                      short: "48A (Maternity)",
                      long: "48A",
                      display: "48A",
                      display_with_size_set: "48A (Maternity)",
                      display_with_size_system: "UK 48A",
                      display_with_system_and_set: "UK 48A (Maternity)",
                      equivalent_sizes: {
                        us: ["48AM"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48BMUK",
                      short: "48B (Maternity)",
                      long: "48B",
                      display: "48B",
                      display_with_size_set: "48B (Maternity)",
                      display_with_size_system: "UK 48B",
                      display_with_system_and_set: "UK 48B (Maternity)",
                      equivalent_sizes: {
                        us: ["48BM"],
                        au: ["26BMAU"],
                        eu: ["110CMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48CMUK",
                      short: "48C (Maternity)",
                      long: "48C",
                      display: "48C",
                      display_with_size_set: "48C (Maternity)",
                      display_with_size_system: "UK 48C",
                      display_with_system_and_set: "UK 48C (Maternity)",
                      equivalent_sizes: {
                        us: ["48CM"],
                        au: ["26CMAU"],
                        eu: ["110DMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48DMUK",
                      short: "48D (Maternity)",
                      long: "48D",
                      display: "48D",
                      display_with_size_set: "48D (Maternity)",
                      display_with_size_system: "UK 48D",
                      display_with_system_and_set: "UK 48D (Maternity)",
                      equivalent_sizes: {
                        us: ["48DM"],
                        au: ["26DMAU"],
                        eu: ["110EMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48DDMUK",
                      short: "48DD (Maternity)",
                      long: "48DD",
                      display: "48DD",
                      display_with_size_set: "48DD (Maternity)",
                      display_with_size_system: "UK 48DD",
                      display_with_system_and_set: "UK 48DD (Maternity)",
                      equivalent_sizes: {
                        us: ["48DDM"],
                        au: ["26EMAU"],
                        eu: ["110FMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48FMUK",
                      short: "48F (Maternity)",
                      long: "48F (3D)",
                      display: "48F (3D)",
                      display_with_size_set: "48F (Maternity)",
                      display_with_size_system: "UK 48F",
                      display_with_system_and_set: "UK 48F (Maternity)",
                      equivalent_sizes: {
                        us: ["48FM"],
                        au: ["26FMAU"],
                        eu: ["110GMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48GMUK",
                      short: "48G (Maternity)",
                      long: "48G (4D)",
                      display: "48G (4D)",
                      display_with_size_set: "48G (Maternity)",
                      display_with_size_system: "UK 48G",
                      display_with_system_and_set: "UK 48G (Maternity)",
                      equivalent_sizes: {
                        us: ["48GM"],
                        au: ["26GMAU"],
                        eu: ["110HMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48H (5D)MUK",
                      short: "48H (5D) (Maternity)",
                      long: "48H (5D)",
                      display: "48H (5D)",
                      display_with_size_set: "48H (5D) (Maternity)",
                      display_with_size_system: "UK 48H",
                      display_with_system_and_set: "UK 48H (5D) (Maternity)",
                      equivalent_sizes: {
                        us: ["48H (5D)M"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "women_intimates_sleepwear",
          aliases: [],
          slug: "Intimates_&_Sleepwear",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
          category_features: [
            {
              id: "00c49287d97b4e80ef00a955",
              display: "Bandeaus",
              type: "cf",
              message_id: "women_intimates_sleepwear_bandeaus",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bandeaus",
              alias_slugs: [],
              parent_id: "00208975d97b4e80ef00a955",
            },
            {
              id: "00c29287d97b4e80ef00a955",
              display: "Bras",
              type: "cf",
              message_id: "women_intimates_sleepwear_bras",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bras",
              alias_slugs: [],
              parent_id: "00208975d97b4e80ef00a955",
            },
            {
              id: "00ce9287d97b4e80ef00a955",
              display: "Chemises & Slips",
              type: "cf",
              message_id: "women_intimates_sleepwear_chemises_slips",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Chemises_&_Slips",
              alias_slugs: [],
              parent_id: "00208975d97b4e80ef00a955",
            },
            {
              id: "00ca9287d97b4e80ef00a955",
              display: "Pajamas",
              type: "cf",
              message_id: "women_intimates_sleepwear_pajamas",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Pajamas",
              alias_slugs: [],
              parent_id: "00208975d97b4e80ef00a955",
            },
            {
              id: "00c69287d97b4e80ef00a955",
              display: "Panties",
              type: "cf",
              message_id: "women_intimates_sleepwear_panties",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Panties",
              alias_slugs: [],
              parent_id: "00208975d97b4e80ef00a955",
            },
            {
              id: "00cc9287d97b4e80ef00a955",
              display: "Robes",
              type: "cf",
              message_id: "women_intimates_sleepwear_robes",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Robes",
              alias_slugs: [],
              parent_id: "00208975d97b4e80ef00a955",
            },
            {
              id: "00c89287d97b4e80ef00a955",
              display: "Shapewear",
              type: "cf",
              message_id: "women_intimates_sleepwear_shapewear",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Shapewear",
              alias_slugs: [],
              parent_id: "00208975d97b4e80ef00a955",
            },
            {
              id: "867df9aaaabb083120f45ec2",
              display: "Sports Bras",
              type: "cf",
              message_id: "women_intimates_sleepwear_sports_bras",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sports_Bras",
              alias_slugs: [],
              parent_id: "00208975d97b4e80ef00a955",
            },
          ],
        },
        {
          id: "00148975d97b4e80ef00a955",
          aliases: ["Jackets & Blazers", "Outerwear"],
          display: "Jackets & Coats",
          size_sets: [
            {
              id: "0500579fd97b4e26a60056e3",
              name: "Standard",
              tags: ["standard"],
              category_id: "00148975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "6AU"],
                        eu: ["32EU", "34EU"],
                        uk: ["4UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["00"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        eu: ["30EU", "32EU", "34EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        eu: ["32EU", "34EU", "36EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["00"],
                        au: ["2AU", "4AU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["00"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["30EU", "32EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["32EU", "34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "0600579fd97b4e26a60056e3",
              name: "Plus",
              tags: ["plus"],
              category_id: "00148975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34", "58,60,62", "34"],
                  ["30,30W,32,32W", "36,38", "60,62", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU", "28AU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU", "30AU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20", "20W", "22W"],
                        au: ["20AU", "22AU", "24AU", "26AU"],
                        uk: ["20UK", "22UK", "24UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "2300579fd97b4e26a60056e3",
              name: "Petite",
              tags: ["petite"],
              category_id: "00148975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P,32P", "2P"],
                  ["00P,0P", "2P,4P,6P", "30P,32P,34P", "4P"],
                  ["0P,2P", "4P,6P,8P", "32P,34P,36P", "6P"],
                  ["2P,4P", "10P,6P,8P", "34P,36P,38P", "8P"],
                  ["4P,6P", "10P,12P,8P", "36P,38P,40P", "10P"],
                  ["6P,8P", "10P,12P,14P", "38P,40P,42P", "12P"],
                  ["8P,10P", "12P,14P,16P", "40P,42P,44P", "14P"],
                  ["10P,12P", "14P,16P,18P", "42P,44P,46P", "16P"],
                  ["12P,14P", "16P,18P,20P", "44P,46P,48P", "18P"],
                  ["14P,16P", "18P,20P,22P", "46P,48P,50P", "20P"],
                  ["16P,18P", "20P,22P,24P", "48P,50P,52P", "22P"],
                  ["18P,20P", "22P,24P", "50P,52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P,4P", "30P,32P", "2P,4P"],
                ["0P", "4P,6P", "32P,34P", "4P,6P"],
                ["2P", "6P,8P", "34P,36P", "6P,8P"],
                ["4P", "8P,10P", "36P,38P", "8P,10P"],
                ["6P", "10P,12P", "38P,40P", "10P,12P"],
                ["8P", "12P,14P", "40P,42P", "12P,14P"],
                ["10P", "14P,16P", "42P,44P", "14P,16P"],
                ["12P", "16P,18P", "44P,46P", "16P,18P"],
                ["14P", "18P,20P", "46P,48P", "18P,20P"],
                ["16P", "20P,22P", "48P,50P", "20P,22P"],
                ["18P", "22P,24P", "50P,52P", "22P,24P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "6PAU"],
                        eu: ["32PEU", "34PEU"],
                        uk: ["4PUK", "6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU"],
                        uk: ["6PUK", "8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "10PAU"],
                        eu: ["36PEU", "38PEU"],
                        uk: ["8PUK", "10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "12PAU"],
                        eu: ["38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "14PAU"],
                        eu: ["40PEU", "42PEU"],
                        uk: ["12PUK", "14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "16PAU"],
                        eu: ["42PEU", "44PEU"],
                        uk: ["14PUK", "16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "18PAU"],
                        eu: ["44PEU", "46PEU"],
                        uk: ["16PUK", "18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "20PAU"],
                        eu: ["46PEU", "48PEU"],
                        uk: ["18PUK", "20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "22PAU"],
                        eu: ["48PEU", "50PEU"],
                        uk: ["20PUK", "22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["6PAU", "8PAU", "10PAU"],
                        uk: ["6PUK", "8PUK", "10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["8PAU", "10PAU", "12PAU"],
                        uk: ["8PUK", "10PUK", "12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "2900579fd97b4e26a60056e3",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "00148975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "4,6", "30,32", "4"],
                  ["00,0,1", "4,6,8", "30,32,34", "6"],
                  ["0,1", "6,8", "32,34", "8"],
                  ["3,5", "10,11", "34,36", "10"],
                  ["3,5", "10,11", "34,36", "11"],
                  ["7,9", "12,14", "38,40", "12"],
                  ["7,9", "12,14", "38,40", "14"],
                  ["11,13", "16,18", "42,44", "16"],
                  ["11,13", "16,18,20", "42,44", "18"],
                  ["15,17", "18,20", "44,46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "4,6", "30,32", "4,6"],
                ["0", "6,8", "32,34", "6,8"],
                ["1", "6,8", "32,34", "6,8"],
                ["3", "10,11", "34,36", "10,11"],
                ["5", "10,11", "34,36", "10,11"],
                ["7", "12,14", "38,40", "12,14"],
                ["9", "12,14", "38,40", "12,14"],
                ["11", "16,18", "42,44", "16,18"],
                ["13", "16,18", "42,44", "16,18"],
                ["15", "20,22", "44,46", "20,22"],
                ["17", "20,22", "44,46", "20,22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J", "3J", "5J"],
                        au: ["6JAU", "8JAU", "10JAU", "11JAU"],
                        uk: ["6JUK", "8JUK", "10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J", "15J", "17J"],
                        au: ["16JAU", "18JAU", "20JAU", "22JAU"],
                        uk: ["16JUK", "18JUK", "20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "34004308d97b4ea2b60057c7",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "00148975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "women_jackets_coats",
          slug: "Jackets_&_Coats",
          alias_slugs: ["Jackets_&_Blazers", "Outerwear"],
          parent_id: "000e8975d97b4e80ef00a955",
          category_features: [
            {
              id: "00609287d97b4e80ef00a955",
              display: "Blazers & Suit Jackets",
              type: "cf",
              aliases: ["Blazers"],
              message_id: "women_jackets_coats_blazers_suit_jackets",
              size_sets: [],
              sizes: [],
              slug: "Blazers_&_Suit_Jackets",
              alias_slugs: ["Blazers"],
              parent_id: "00148975d97b4e80ef00a955",
            },
            {
              id: "797df9aaaabb083120f45ec2",
              display: "Bomber Jackets",
              type: "cf",
              message_id: "women_jackets_coats_bomber_jackets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bomber_Jackets",
              alias_slugs: [],
              parent_id: "00148975d97b4e80ef00a955",
            },
            {
              id: "00629287d97b4e80ef00a955",
              display: "Capes",
              type: "cf",
              message_id: "women_jackets_coats_capes",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Capes",
              alias_slugs: [],
              parent_id: "00148975d97b4e80ef00a955",
            },
            {
              id: "00649287d97b4e80ef00a955",
              display: "Jean Jackets",
              type: "cf",
              message_id: "women_jackets_coats_jean_jackets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Jean_Jackets",
              alias_slugs: [],
              parent_id: "00148975d97b4e80ef00a955",
            },
            {
              id: "787df9aaaabb083120f45ec2",
              display: "Leather Jackets",
              type: "cf",
              message_id: "women_jackets_coats_leather_jackets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Leather_Jackets",
              alias_slugs: [],
              parent_id: "00148975d97b4e80ef00a955",
            },
            {
              id: "00669287d97b4e80ef00a955",
              display: "Pea Coats",
              type: "cf",
              message_id: "women_jackets_coats_pea_coats",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Pea_Coats",
              alias_slugs: [],
              parent_id: "00148975d97b4e80ef00a955",
            },
            {
              id: "00689287d97b4e80ef00a955",
              display: "Puffers",
              type: "cf",
              message_id: "women_jackets_coats_puffers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Puffers",
              alias_slugs: [],
              parent_id: "00148975d97b4e80ef00a955",
            },
            {
              id: "7b7df9aaaabb083120f45ec2",
              display: "Ski & Snow Jackets",
              type: "cf",
              message_id: "women_jackets_coats_ski_snow_jackets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Ski_&_Snow_Jackets",
              alias_slugs: [],
              parent_id: "00148975d97b4e80ef00a955",
            },
            {
              id: "7a7df9aaaabb083120f45ec2",
              display: "Teddy Jackets",
              type: "cf",
              message_id: "women_jackets_coats_teddy_jackets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Teddy_Jackets",
              alias_slugs: [],
              parent_id: "00148975d97b4e80ef00a955",
            },
            {
              id: "006a9287d97b4e80ef00a955",
              display: "Trench Coats",
              type: "cf",
              message_id: "women_jackets_coats_trench_coats",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Trench_Coats",
              alias_slugs: [],
              parent_id: "00148975d97b4e80ef00a955",
            },
            {
              id: "006c9287d97b4e80ef00a955",
              display: "Utility Jackets",
              type: "cf",
              message_id: "women_jackets_coats_utility_jackets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Utility_Jackets",
              alias_slugs: [],
              parent_id: "00148975d97b4e80ef00a955",
            },
            {
              id: "7c7df9aaaabb083120f45ec2",
              display: "Varsity Jackets",
              type: "cf",
              message_id: "women_jackets_coats_varsity_jackets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Varsity_Jackets",
              alias_slugs: [],
              parent_id: "00148975d97b4e80ef00a955",
            },
            {
              id: "006e9287d97b4e80ef00a955",
              display: "Vests",
              type: "cf",
              message_id: "women_jackets_coats_vests",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Vests",
              alias_slugs: [],
              parent_id: "00148975d97b4e80ef00a955",
            },
          ],
        },
        {
          id: "001a8975d97b4e80ef00a955",
          aliases: ["Denim"],
          display: "Jeans",
          size_sets: [
            {
              id: "0b00579fd97b4e26a60056e3",
              name: "Standard",
              tags: ["standard"],
              category_id: "001a8975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00,23", "0,23", "23,28", "0"],
                  ["00,24", "24,2", "24,30", "2"],
                  ["0,25", "25,4", "25,32", "4"],
                  ["2,26", "26,6", "26,34", "6"],
                  ["4,27", "27,8", "27,36", "8"],
                  ["6,28", "10,28", "28,38", "10"],
                  ["8,29", "12,29", "29,40", "12"],
                  ["10,30", "14,30", "30,42", "14"],
                  ["12,31", "16,31", "31,44", "16"],
                  ["00,23", "0,23", "23,28", "23"],
                  ["00,24", "24,2", "24,30", "24"],
                  ["0,25", "25,4", "25,32", "25"],
                  ["2,26", "26,6", "26,34", "26"],
                  ["4,27", "27,8", "27,36", "27"],
                  ["6,28", "10,28", "28,38", "28"],
                  ["8,29", "12,29", "29,40", "29"],
                  ["10,30", "14,30", "30,42", "30"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["23", "0,23", "23,28", "0,23"],
                ["24", "2,24", "24,30", "2,24"],
                ["25", "4,25", "25,32", "4,25"],
                ["26", "6,26", "26,34", "6,26"],
                ["27", "8,27", "27,36", "8,27"],
                ["28", "10,28", "28,38", "10,28"],
                ["29", "12,29", "29,40", "12,29"],
                ["30", "14,30", "30,42", "14,30"],
                ["31", "16,31", "31,44", "16,31"],
                ["00", "0,2,23,24", "23,24,28,30", "0,2,23,24"],
                ["0", "4,25", "25,32", "4,25"],
                ["2", "6,26", "26,34", "6,26"],
                ["4", "8,27", "27,36", "8,27"],
                ["6", "10,28", "28,38", "10,28"],
                ["8", "12,29", "29,40", "12,29"],
                ["10", "14,30", "30,42", "14,30"],
                ["12", "16,31", "31,44", "16,31"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "23",
                      short: "23",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23",
                      display_with_size_system: "US 23",
                      display_with_system_and_set: "US 23",
                      equivalent_sizes: {
                        au: ["0AU", "23AU"],
                        eu: ["23EU", "28EU"],
                        uk: ["0UK", "23UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["2AU", "24AU"],
                        eu: ["24EU", "30EU"],
                        uk: ["2UK", "24UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "25",
                      short: "25",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25",
                      display_with_size_system: "US 25",
                      display_with_system_and_set: "US 25",
                      equivalent_sizes: {
                        au: ["4AU", "25AU"],
                        eu: ["25EU", "32EU"],
                        uk: ["4UK", "25UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["6AU", "26AU"],
                        eu: ["26EU", "34EU"],
                        uk: ["6UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "27",
                      short: "27",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27",
                      display_with_size_system: "US 27",
                      display_with_system_and_set: "US 27",
                      equivalent_sizes: {
                        au: ["8AU", "27AU"],
                        eu: ["27EU", "36EU"],
                        uk: ["8UK", "27UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["10AU", "28AU"],
                        eu: ["28EU", "38EU"],
                        uk: ["10UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "29",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "US 29",
                      display_with_system_and_set: "US 29",
                      equivalent_sizes: {
                        au: ["12AU", "29AU"],
                        eu: ["29EU", "40EU"],
                        uk: ["12UK", "29UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["14AU", "30AU"],
                        eu: ["30EU", "42EU"],
                        uk: ["14UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "31",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "US 31",
                      display_with_system_and_set: "US 31",
                      equivalent_sizes: {
                        au: ["16AU", "31AU"],
                        eu: ["31EU", "44EU"],
                        uk: ["16UK", "31UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["0AU", "2AU", "23AU", "24AU"],
                        eu: ["23EU", "24EU", "28EU", "30EU"],
                        uk: ["0UK", "2UK", "23UK", "24UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "25AU"],
                        eu: ["25EU", "32EU"],
                        uk: ["4UK", "25UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "26AU"],
                        eu: ["26EU", "34EU"],
                        uk: ["6UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["8AU", "27AU"],
                        eu: ["27EU", "36EU"],
                        uk: ["8UK", "27UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "28AU"],
                        eu: ["28EU", "38EU"],
                        uk: ["10UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["12AU", "29AU"],
                        eu: ["29EU", "40EU"],
                        uk: ["12UK", "29UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "30AU"],
                        eu: ["30EU", "42EU"],
                        uk: ["14UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["16AU", "31AU"],
                        eu: ["31EU", "44EU"],
                        uk: ["16UK", "31UK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "0AU",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "AU 0",
                      display_with_system_and_set: "AU 0",
                      equivalent_sizes: {
                        us: ["00", "23"],
                        eu: ["23EU", "28EU"],
                        uk: ["0UK", "23UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["00", "24"],
                        eu: ["24EU", "30EU"],
                        uk: ["24UK", "2UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["0", "25"],
                        eu: ["25EU", "32EU"],
                        uk: ["25UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["2", "26"],
                        eu: ["26EU", "34EU"],
                        uk: ["26UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["4", "27"],
                        eu: ["27EU", "36EU"],
                        uk: ["27UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "28"],
                        eu: ["28EU", "38EU"],
                        uk: ["10UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["8", "29"],
                        eu: ["29EU", "40EU"],
                        uk: ["12UK", "29UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "30"],
                        eu: ["30EU", "42EU"],
                        uk: ["14UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["12", "31"],
                        eu: ["31EU", "44EU"],
                        uk: ["16UK", "31UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "23AU",
                      short: "23",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23",
                      display_with_size_system: "AU 23",
                      display_with_system_and_set: "AU 23",
                      equivalent_sizes: {
                        us: ["00", "23"],
                        eu: ["23EU", "28EU"],
                        uk: ["0UK", "23UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["00", "24"],
                        eu: ["24EU", "30EU"],
                        uk: ["24UK", "2UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "25AU",
                      short: "25",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25",
                      display_with_size_system: "AU 25",
                      display_with_system_and_set: "AU 25",
                      equivalent_sizes: {
                        us: ["0", "25"],
                        eu: ["25EU", "32EU"],
                        uk: ["25UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["2", "26"],
                        eu: ["26EU", "34EU"],
                        uk: ["26UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "27AU",
                      short: "27",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27",
                      display_with_size_system: "AU 27",
                      display_with_system_and_set: "AU 27",
                      equivalent_sizes: {
                        us: ["4", "27"],
                        eu: ["27EU", "36EU"],
                        uk: ["27UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["6", "28"],
                        eu: ["28EU", "38EU"],
                        uk: ["10UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "29AU",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "AU 29",
                      display_with_system_and_set: "AU 29",
                      equivalent_sizes: {
                        us: ["8", "29"],
                        eu: ["29EU", "40EU"],
                        uk: ["12UK", "29UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["10", "30"],
                        eu: ["30EU", "42EU"],
                        uk: ["14UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "31AU",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "AU 31",
                      display_with_system_and_set: "AU 31",
                      equivalent_sizes: {
                        us: ["12", "31"],
                        eu: ["31EU", "44EU"],
                        uk: ["16UK", "31UK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "23EU",
                      short: "23",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23",
                      display_with_size_system: "EU 23",
                      display_with_system_and_set: "EU 23",
                      equivalent_sizes: {
                        us: ["00", "23"],
                        au: ["0AU", "23AU"],
                        uk: ["0UK", "23UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "24EU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "EU 24",
                      display_with_system_and_set: "EU 24",
                      equivalent_sizes: {
                        us: ["00", "24"],
                        au: ["2AU", "24AU"],
                        uk: ["2UK", "24UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "25EU",
                      short: "25",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25",
                      display_with_size_system: "EU 25",
                      display_with_system_and_set: "EU 25",
                      equivalent_sizes: {
                        us: ["0", "25"],
                        au: ["4AU", "25AU"],
                        uk: ["4UK", "25UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "26EU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "EU 26",
                      display_with_system_and_set: "EU 26",
                      equivalent_sizes: {
                        us: ["2", "26"],
                        au: ["6AU", "26AU"],
                        uk: ["6UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "27EU",
                      short: "27",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27",
                      display_with_size_system: "EU 27",
                      display_with_system_and_set: "EU 27",
                      equivalent_sizes: {
                        us: ["4", "27"],
                        au: ["8AU", "27AU"],
                        uk: ["8UK", "27UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "28EU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "EU 28",
                      display_with_system_and_set: "EU 28",
                      equivalent_sizes: {
                        us: ["00", "6", "23", "28"],
                        au: ["0AU", "10AU", "23AU", "28AU"],
                        uk: ["0UK", "10UK", "23UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "29EU",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "EU 29",
                      display_with_system_and_set: "EU 29",
                      equivalent_sizes: {
                        us: ["8", "29"],
                        au: ["12AU", "29AU"],
                        uk: ["12UK", "29UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["00", "10", "24", "30"],
                        au: ["2AU", "14AU", "24AU", "30AU"],
                        uk: ["2UK", "14UK", "24UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "31EU",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "EU 31",
                      display_with_system_and_set: "EU 31",
                      equivalent_sizes: {
                        us: ["12", "31"],
                        au: ["16AU", "31AU"],
                        uk: ["16UK", "31UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["0", "25"],
                        au: ["4AU", "25AU"],
                        uk: ["4UK", "25UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["2", "26"],
                        au: ["6AU", "26AU"],
                        uk: ["6UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["4", "27"],
                        au: ["8AU", "27AU"],
                        uk: ["8UK", "27UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "28"],
                        au: ["10AU", "28AU"],
                        uk: ["10UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["8", "29"],
                        au: ["12AU", "29AU"],
                        uk: ["12UK", "29UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "30"],
                        au: ["14AU", "30AU"],
                        uk: ["14UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["12", "31"],
                        au: ["16AU", "31AU"],
                        uk: ["16UK", "31UK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "0UK",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "UK 0",
                      display_with_system_and_set: "UK 0",
                      equivalent_sizes: {
                        us: ["00", "23"],
                        au: ["0AU", "23AU"],
                        eu: ["23EU", "28EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["00", "24"],
                        au: ["24AU", "2AU"],
                        eu: ["24EU", "30EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["0", "25"],
                        au: ["25AU", "4AU"],
                        eu: ["25EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["2", "26"],
                        au: ["26AU", "6AU"],
                        eu: ["26EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["4", "27"],
                        au: ["27AU", "8AU"],
                        eu: ["27EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "28"],
                        au: ["10AU", "28AU"],
                        eu: ["28EU", "38EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["8", "29"],
                        au: ["12AU", "29AU"],
                        eu: ["29EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "30"],
                        au: ["14AU", "30AU"],
                        eu: ["30EU", "42EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["12", "31"],
                        au: ["16AU", "31AU"],
                        eu: ["31EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "23UK",
                      short: "23",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23",
                      display_with_size_system: "UK 23",
                      display_with_system_and_set: "UK 23",
                      equivalent_sizes: {
                        us: ["00", "23"],
                        au: ["0AU", "23AU"],
                        eu: ["23EU", "28EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["00", "24"],
                        au: ["24AU", "2AU"],
                        eu: ["24EU", "30EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "25UK",
                      short: "25",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25",
                      display_with_size_system: "UK 25",
                      display_with_system_and_set: "UK 25",
                      equivalent_sizes: {
                        us: ["0", "25"],
                        au: ["25AU", "4AU"],
                        eu: ["25EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["2", "26"],
                        au: ["26AU", "6AU"],
                        eu: ["26EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "27UK",
                      short: "27",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27",
                      display_with_size_system: "UK 27",
                      display_with_system_and_set: "UK 27",
                      equivalent_sizes: {
                        us: ["4", "27"],
                        au: ["27AU", "8AU"],
                        eu: ["27EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["6", "28"],
                        au: ["10AU", "28AU"],
                        eu: ["28EU", "38EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "29UK",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "UK 29",
                      display_with_system_and_set: "UK 29",
                      equivalent_sizes: {
                        us: ["8", "29"],
                        au: ["12AU", "29AU"],
                        eu: ["29EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["10", "30"],
                        au: ["14AU", "30AU"],
                        eu: ["30EU", "42EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "31UK",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "UK 31",
                      display_with_system_and_set: "UK 31",
                      equivalent_sizes: {
                        us: ["12", "31"],
                        au: ["16AU", "31AU"],
                        eu: ["31EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "0c00579fd97b4e26a60056e3",
              name: "Plus",
              tags: ["plus"],
              category_id: "001a8975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W,32", "18,20,32,33", "32,46,48", "18"],
                  [
                    "14,14W,16W,16,18W,18,20W,32,33,34",
                    "18,20,22,33,34",
                    "32,33,34,46,48,50",
                    "20",
                  ],
                  ["16,16W,18,18W,20W,33,34", "20,22,34", "33,34,48,50", "22"],
                  ["20,20W,22W", "24", "50,52", "24"],
                  ["20,20W,22,22W,24W", "26,36", "50,52,54", "26"],
                  ["22,22W,24W,24W,26W", "28", "52,54,56", "28"],
                  ["24W,24W,26W,26W,28W", "30,32,32", "54,56,58", "30"],
                  [
                    "14W,14,16W,26W,26W,28W,28W,30W,32",
                    "18,30,32,32,34,34",
                    "32,46,48,56,58,60",
                    "32",
                  ],
                  [
                    "18W,18,20W,28W,28W,30W,30W,32W,34",
                    "20,22,32,34,34",
                    "34,48,50,58,60,62",
                    "34",
                  ],
                  ["30W,30W,32,32W", "26,36", "60,62", "36"],
                  [
                    "14W,14,16W,26W,26W,28W,28W,30W,32",
                    "18,30,32,32,34,34",
                    "32,46,48,56,58,60",
                    "32",
                  ],
                  ["16,16W,18W,33", "18,20,33", "33,48,50", "33"],
                  [
                    "18W,18,20W,28W,28W,30W,30W,32W,34",
                    "20,22,32,34,34",
                    "34,48,50,58,60,62",
                    "34",
                  ],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["32", "18,20,32,36,38", "32,46,48,62", "18,20,32,36,38"],
                ["33", "20,22,33", "33,48,50", "20,22,33"],
                ["34", "20,22,34", "34,48,50", "20,22,34"],
                ["14", "18,20,32", "32,46,48", "18,20,32"],
                ["14W", "18,20,32", "32,46,48", "18,20,32"],
                ["16", "20,22,33", "33,48,50", "20,22,33"],
                ["16W", "18,20,22,32,33", "32,33,46,48,50", "18,20,22,32,33"],
                ["18", "20,22,34", "34,48,50", "20,22,34"],
                ["18W", "20,22,33,34", "33,34,48,50", "20,22,33,34"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "20,22,24,26,34", "34,48,50,52", "20,22,24,26,34"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "18,20,32,36,38", "32,46,48,62", "18,20,32,36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "32AU", "36AU", "38AU"],
                        eu: ["32PlusEU", "46EU", "48EU", "62EU"],
                        uk: ["18UK", "20UK", "32UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "33",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "US 33",
                      display_with_system_and_set: "US 33",
                      equivalent_sizes: {
                        au: ["20AU", "22AU", "33AU"],
                        eu: ["33EU", "48EU", "50EU"],
                        uk: ["20UK", "22UK", "33UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "US 34",
                      display_with_system_and_set: "US 34",
                      equivalent_sizes: {
                        au: ["20AU", "22AU", "34AU"],
                        eu: ["34PlusEU", "48EU", "50EU"],
                        uk: ["20UK", "22UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "32AU"],
                        eu: ["32PlusEU", "46EU", "48EU"],
                        uk: ["18UK", "20UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "32AU"],
                        eu: ["32PlusEU", "46EU", "48EU"],
                        uk: ["18UK", "20UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU", "33AU"],
                        eu: ["33EU", "48EU", "50EU"],
                        uk: ["20UK", "22UK", "33UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU", "32AU", "33AU"],
                        eu: ["32PlusEU", "33EU", "46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK", "32UK", "33UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU", "34AU"],
                        eu: ["34PlusEU", "48EU", "50EU"],
                        uk: ["20UK", "22UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU", "33AU", "34AU"],
                        eu: ["33EU", "34PlusEU", "48EU", "50EU"],
                        uk: ["20UK", "22UK", "33UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24PlusAU", "26PlusAU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24PlusUK", "26PlusUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU", "24PlusAU", "26PlusAU", "34AU"],
                        eu: ["34PlusEU", "48EU", "50EU", "52EU"],
                        uk: ["20UK", "22UK", "24PlusUK", "26PlusUK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26PlusAU", "28PlusAU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26PlusUK", "28PlusUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24PlusAU", "26PlusAU", "28PlusAU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24PlusUK", "26PlusUK", "28PlusUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24Plus",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28PlusAU", "30PlusAU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28PlusUK", "30PlusUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26PlusAU", "28PlusAU", "30PlusAU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26PlusUK", "28PlusUK", "30PlusUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26Plus",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30PlusAU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30PlusUK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28PlusAU", "30PlusAU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28PlusUK", "30PlusUK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28Plus",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30PlusAU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30PlusUK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30Plus",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32Plus",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "32AU", "36AU", "38AU"],
                        eu: ["32PlusEU", "46EU", "48EU", "62EU"],
                        uk: ["18UK", "20UK", "32UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W", "32"],
                        eu: ["32PlusEU", "46EU", "48EU"],
                        uk: ["18UK", "20UK", "32UK", "33UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: [
                          "14",
                          "14W",
                          "16W",
                          "16",
                          "18W",
                          "18",
                          "20W",
                          "32",
                          "33",
                          "34",
                        ],
                        eu: [
                          "32PlusEU",
                          "33EU",
                          "34PlusEU",
                          "46EU",
                          "48EU",
                          "50EU",
                        ],
                        uk: ["18UK", "20UK", "22UK", "33UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20W", "33", "34"],
                        eu: ["33EU", "34PlusEU", "48EU", "50EU"],
                        uk: ["20UK", "22UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PlusAU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24PlusUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26PlusAU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["26PlusUK", "36UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28PlusAU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24W", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["28PlusUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30PlusAU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24W", "24W", "26W", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["30PlusUK", "32UK", "32PlusUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: [
                          "14W",
                          "14",
                          "16W",
                          "26W",
                          "26W",
                          "28W",
                          "28W",
                          "30W",
                          "32",
                        ],
                        eu: ["32PlusEU", "46EU", "48EU", "56EU", "58EU", "60EU"],
                        uk: [
                          "18UK",
                          "30PlusUK",
                          "32UK",
                          "32PlusUK",
                          "34UK",
                          "34PlusUK",
                        ],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32PlusAU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: [
                          "14W",
                          "14",
                          "16W",
                          "26W",
                          "26W",
                          "28W",
                          "28W",
                          "30W",
                          "32",
                        ],
                        eu: ["32PlusEU", "46EU", "48EU", "56EU", "58EU", "60EU"],
                        uk: ["30PlusUK", "32UK", "32PlusUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "33AU",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "AU 33",
                      display_with_system_and_set: "AU 33",
                      equivalent_sizes: {
                        us: ["16", "16W", "18W", "33"],
                        eu: ["33EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "33UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34PlusAU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: [
                          "18W",
                          "18",
                          "20W",
                          "28W",
                          "28W",
                          "30W",
                          "30W",
                          "32W",
                          "34",
                        ],
                        eu: ["34PlusEU", "48EU", "50EU", "58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "34PlusUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: [
                          "18W",
                          "18",
                          "20W",
                          "28W",
                          "28W",
                          "30W",
                          "30W",
                          "32W",
                          "34",
                        ],
                        eu: ["34PlusEU", "48EU", "50EU", "58EU", "60EU", "62EU"],
                        uk: ["20UK", "22UK", "32UK", "34UK", "34PlusUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30W", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["26PlusUK", "36UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36PlusAU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30W", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36PlusUK", "38UK", "38PlusUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38PlusAU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36PlusUK", "38UK", "38PlusUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36PlusUK", "38UK", "38PlusUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "32PlusEU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W", "32"],
                        au: ["18AU", "20AU", "32AU"],
                        uk: ["18UK", "20UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "33EU",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "EU 33",
                      display_with_system_and_set: "EU 33",
                      equivalent_sizes: {
                        us: ["16", "16W", "18W", "33"],
                        au: ["20AU", "22AU", "33AU"],
                        uk: ["20UK", "22UK", "33UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PlusEU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["18", "18W", "20W", "34"],
                        au: ["20AU", "22AU", "34AU"],
                        uk: ["20UK", "22UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W", "32"],
                        au: ["18AU", "20AU", "32AU"],
                        uk: ["18UK", "20UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: [
                          "14",
                          "14W",
                          "16W",
                          "16",
                          "18W",
                          "18",
                          "20W",
                          "32",
                          "33",
                          "34",
                        ],
                        au: ["18AU", "20AU", "22AU", "32AU", "33AU", "34AU"],
                        uk: ["18UK", "20UK", "22UK", "32UK", "33UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: [
                          "16",
                          "16W",
                          "18W",
                          "18",
                          "20",
                          "20W",
                          "22W",
                          "33",
                          "34",
                        ],
                        au: [
                          "20AU",
                          "22AU",
                          "24PlusAU",
                          "26PlusAU",
                          "33AU",
                          "34AU",
                        ],
                        uk: [
                          "20UK",
                          "22UK",
                          "24PlusUK",
                          "26PlusUK",
                          "33UK",
                          "34UK",
                        ],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24PlusAU", "26PlusAU", "28PlusAU"],
                        uk: ["24PlusUK", "26PlusUK", "28PlusUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24W", "24W", "26W"],
                        au: ["26PlusAU", "28PlusAU", "30PlusAU"],
                        uk: ["26PlusUK", "28PlusUK", "30PlusUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24W", "24W", "26W", "26W", "28W"],
                        au: ["28PlusAU", "30PlusAU", "32AU"],
                        uk: ["28PlusUK", "30PlusUK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56PlusEU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24W", "24W", "26W", "26W", "28W"],
                        au: ["28PlusAU", "30PlusAU", "32AU"],
                        uk: ["28PlusUK", "30PlusUK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58PlusEU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26W", "26W", "28W", "28W", "30W"],
                        au: ["30PlusAU", "32AU", "34AU"],
                        uk: ["30PlusUK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26W", "26W", "28W", "28W", "30W"],
                        au: ["30PlusAU", "32AU", "34AU"],
                        uk: ["30PlusUK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60PlusEU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28W", "28W", "30W", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28W", "28W", "30W", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30W", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62PlusEU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30W", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W", "32"],
                        au: ["18AU", "20AU", "32AU", "33AU"],
                        eu: ["32PlusEU", "46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: [
                          "14",
                          "14W",
                          "16W",
                          "16",
                          "18W",
                          "18",
                          "20W",
                          "32",
                          "33",
                          "34",
                        ],
                        au: ["18AU", "20AU", "22AU", "33AU", "34AU"],
                        eu: [
                          "32PlusEU",
                          "33EU",
                          "34PlusEU",
                          "46EU",
                          "48EU",
                          "50EU",
                        ],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20W", "33", "34"],
                        au: ["20AU", "22AU", "34AU"],
                        eu: ["33EU", "34PlusEU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PlusUK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24PlusAU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26PlusUK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["26PlusAU", "36AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28PlusUK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24W", "24W", "26W"],
                        au: ["28PlusAU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30PlusUK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24W", "24W", "26W", "26W", "28W"],
                        au: ["30PlusAU", "32AU", "32PlusAU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: [
                          "14W",
                          "14",
                          "16W",
                          "26W",
                          "26W",
                          "28W",
                          "28W",
                          "30W",
                          "32",
                        ],
                        au: [
                          "18AU",
                          "30PlusAU",
                          "32AU",
                          "32PlusAU",
                          "34AU",
                          "34PlusAU",
                        ],
                        eu: ["32PlusEU", "46EU", "48EU", "56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32PlusUK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: [
                          "14W",
                          "14",
                          "16W",
                          "26W",
                          "26W",
                          "28W",
                          "28W",
                          "30W",
                          "32",
                        ],
                        au: ["30PlusAU", "32AU", "32PlusAU"],
                        eu: ["32PlusEU", "46EU", "48EU", "56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "33UK",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "UK 33",
                      display_with_system_and_set: "UK 33",
                      equivalent_sizes: {
                        us: ["16", "16W", "18W", "33"],
                        au: ["18AU", "20AU", "33AU"],
                        eu: ["33EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34PlusUK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: [
                          "18W",
                          "18",
                          "20W",
                          "28W",
                          "28W",
                          "30W",
                          "30W",
                          "32W",
                          "34",
                        ],
                        au: ["32AU", "34AU", "34PlusAU"],
                        eu: ["34PlusEU", "48EU", "50EU", "58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: [
                          "18W",
                          "18",
                          "20W",
                          "28W",
                          "28W",
                          "30W",
                          "30W",
                          "32W",
                          "34",
                        ],
                        au: ["20AU", "22AU", "32AU", "34AU", "34PlusAU"],
                        eu: ["34PlusEU", "48EU", "50EU", "58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30W", "30W", "32", "32W"],
                        au: ["26PlusAU", "36AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36PlusUK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30W", "30W", "32", "32W"],
                        au: ["36PlusAU", "38AU", "38PlusAU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38PlusUK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36PlusAU", "38AU", "38PlusAU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36PlusAU", "38AU", "38PlusAU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "2500579fd97b4e26a60056e3",
              name: "Petite",
              tags: ["petite"],
              category_id: "001a8975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["28-29", "21-22", "30-31"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["28-29", "21-22", "30-31"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["71.1-73.7", "53.3-55.9", "76.2-78.7"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["71.1-73.7", "53.3-55.9", "76.2-78.7"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P,23P", "0P,23P", "23P,28P", "0P"],
                  ["00P,24P", "24P,2P", "24P,30P", "2P"],
                  ["0P,25P", "25P,4P", "25P,32P", "4P"],
                  ["2P,26P", "26P,6P", "26P,34P", "6P"],
                  ["4P,27P", "27P,8P", "27P,36P", "8P"],
                  ["6P,28P", "10P,28P", "28P,38P", "10P"],
                  ["8P,29P", "12P,29P", "29P,40P", "12P"],
                  ["10P,30P", "14P,30P", "30P,42P", "14P"],
                  ["12P,31P", "16P,31P", "31P,44P", "16P"],
                  ["14P,32P", "18P,32P", "32P,46P", "18P"],
                  ["16P,33P", "20P,33P", "33P,48P", "20P"],
                  ["18P,34P", "22P,34P", "34P,50P", "22P"],
                  ["00P,23P", "0P,23P", "23P,28P", "23P"],
                  ["00P,24P", "24P,2P", "24P,30P", "24P"],
                  ["0P,25P", "25P,4P", "25P,32P", "25P"],
                  ["2P,26P", "26P,6P", "26P,34P", "26P"],
                  ["4P,27P", "27P,8P", "27P,36P", "27P"],
                  ["6P,28P", "10P,28P", "28P,38P", "28P"],
                  ["8P,29P", "12P,29P", "29P,40P", "29P"],
                  ["10P,30P", "14P,30P", "30P,42P", "30P"],
                  ["12P,31P", "16P,31P", "31P,44P", "31P"],
                  ["14P,32P", "18P,32P", "32P,46P", "32P"],
                  ["16P,33P", "20P,33P", "33P,48P", "33P"],
                  ["18P,34P", "22P,34P", "34P,50P", "34P"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["23P", "0P,23P", "23P,28P", "0P,23P"],
                ["24P", "2P,24P", "24P,30P", "2P,24P"],
                ["25P", "4P,25P", "25P,32P", "4P,25P"],
                ["26P", "6P,26P", "26P,34P", "6P,26P"],
                ["27P", "8P,27P", "27P,36P", "8P,27P"],
                ["28P", "10P,28P", "28P,38P", "10P,28P"],
                ["29P", "12P,29P", "29P,40P", "12P,29P"],
                ["30P", "14P,30P", "30P,42P", "14P,30P"],
                ["31P", "16P,31P", "31P,44P", "16P,31P"],
                ["32P", "18P,32P", "32P,46P", "18P,32P"],
                ["33P", "20P,33P", "33P,48P", "20P,33P"],
                ["34P", "22P,34P", "34P,50P", "22P,34P"],
                ["00P", "0P,2P,23P,24P", "23P,24P,28P,30P", "0P,2P,23P,24P"],
                ["0P", "4P,25P", "25P,32P", "4P,25P"],
                ["2P", "6P,26P", "26P,34P", "6P,26P"],
                ["4P", "8P,27P", "27P,36P", "8P,27P"],
                ["6P", "10P,28P", "28P,38P", "10P,28P"],
                ["8P", "12P,29P", "29P,40P", "12P,29P"],
                ["10P", "14P,30P", "30P,42P", "14P,30P"],
                ["12P", "16P,31P", "31P,44P", "16P,31P"],
                ["14P", "18P,32P", "32P,46P", "18P,32P"],
                ["16P", "20P,33P", "33P,48P", "20P,33P"],
                ["18P", "22P,34P", "34P,50P", "22P,34P"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "23P",
                      short: "23P",
                      long: "23P",
                      display: "23P",
                      display_with_size_set: "23P",
                      display_with_size_system: "US 23P",
                      display_with_system_and_set: "US 23P",
                      equivalent_sizes: {
                        au: ["0PAU", "23PAU"],
                        eu: ["23PEU", "28PEU"],
                        uk: ["0PUK", "23PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24P",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "US 24P",
                      display_with_system_and_set: "US 24P",
                      equivalent_sizes: {
                        au: ["2PAU", "24PAU"],
                        eu: ["24PEU", "30PEU"],
                        uk: ["2PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "25P",
                      short: "25P",
                      long: "25P",
                      display: "25P",
                      display_with_size_set: "25P",
                      display_with_size_system: "US 25P",
                      display_with_system_and_set: "US 25P",
                      equivalent_sizes: {
                        au: ["4PAU", "25PAU"],
                        eu: ["25PEU", "32PEU"],
                        uk: ["4PUK", "25PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26P",
                      short: "26P",
                      long: "26P",
                      display: "26P",
                      display_with_size_set: "26P",
                      display_with_size_system: "US 26P",
                      display_with_system_and_set: "US 26P",
                      equivalent_sizes: {
                        au: ["6PAU", "26PAU"],
                        eu: ["26PEU", "34PEU"],
                        uk: ["6PUK", "26PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "27P",
                      short: "27P",
                      long: "27P",
                      display: "27P",
                      display_with_size_set: "27P",
                      display_with_size_system: "US 27P",
                      display_with_system_and_set: "US 27P",
                      equivalent_sizes: {
                        au: ["8PAU", "27PAU"],
                        eu: ["27PEU", "36PEU"],
                        uk: ["8PUK", "27PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28P",
                      short: "28P",
                      long: "28P",
                      display: "28P",
                      display_with_size_set: "28P",
                      display_with_size_system: "US 28P",
                      display_with_system_and_set: "US 28P",
                      equivalent_sizes: {
                        au: ["10PAU", "28PAU"],
                        eu: ["28PEU", "38PEU"],
                        uk: ["10PUK", "28PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "29P",
                      short: "29P",
                      long: "29P",
                      display: "29P",
                      display_with_size_set: "29P",
                      display_with_size_system: "US 29P",
                      display_with_system_and_set: "US 29P",
                      equivalent_sizes: {
                        au: ["12PAU", "29PAU"],
                        eu: ["29PEU", "40PEU"],
                        uk: ["12PUK", "29PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30P",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "US 30P",
                      display_with_system_and_set: "US 30P",
                      equivalent_sizes: {
                        au: ["14PAU", "30PAU"],
                        eu: ["30PEU", "42PEU"],
                        uk: ["14PUK", "30PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "31P",
                      short: "31P",
                      long: "31P",
                      display: "31P",
                      display_with_size_set: "31P",
                      display_with_size_system: "US 31P",
                      display_with_system_and_set: "US 31P",
                      equivalent_sizes: {
                        au: ["16PAU", "31PAU"],
                        eu: ["31PEU", "44PEU"],
                        uk: ["16PUK", "31PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32P",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "US 32P",
                      display_with_system_and_set: "US 32P",
                      equivalent_sizes: {
                        au: ["18PAU", "32PAU"],
                        eu: ["32PEU", "46PEU"],
                        uk: ["18PUK", "32PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "33P",
                      short: "33P",
                      long: "33P",
                      display: "33P",
                      display_with_size_set: "33P",
                      display_with_size_system: "US 33P",
                      display_with_system_and_set: "US 33P",
                      equivalent_sizes: {
                        au: ["20PAU", "33PAU"],
                        eu: ["33PEU", "48PEU"],
                        uk: ["20PUK", "33PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34P",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "US 34P",
                      display_with_system_and_set: "US 34P",
                      equivalent_sizes: {
                        au: ["22PAU", "34PAU"],
                        eu: ["34PEU", "50PEU"],
                        uk: ["22PUK", "34PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["0PAU", "2PAU", "23PAU", "24PAU"],
                        eu: ["23PEU", "24PEU", "28PEU", "30PEU"],
                        uk: ["0PUK", "2PUK", "23PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "25PAU"],
                        eu: ["25PEU", "32PEU"],
                        uk: ["4PUK", "25PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "26PAU"],
                        eu: ["26PEU", "34PEU"],
                        uk: ["6PUK", "26PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "27PAU"],
                        eu: ["27PEU", "36PEU"],
                        uk: ["8PUK", "27PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "28PAU"],
                        eu: ["28PEU", "38PEU"],
                        uk: ["10PUK", "28PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "29PAU"],
                        eu: ["29PEU", "40PEU"],
                        uk: ["12PUK", "29PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "30PAU"],
                        eu: ["30PEU", "42PEU"],
                        uk: ["14PUK", "30PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "31PAU"],
                        eu: ["31PEU", "44PEU"],
                        uk: ["16PUK", "31PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "32PAU"],
                        eu: ["32PEU", "46PEU"],
                        uk: ["18PUK", "32PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "33PAU"],
                        eu: ["33PEU", "48PEU"],
                        uk: ["20PUK", "33PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "34PAU"],
                        eu: ["34PEU", "50PEU"],
                        uk: ["22PUK", "34PUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "0PAU",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "AU 0P",
                      display_with_system_and_set: "AU 0P",
                      equivalent_sizes: {
                        us: ["00P", "23P"],
                        eu: ["23PEU", "28PEU"],
                        uk: ["0PUK", "23PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P", "24P"],
                        eu: ["24PEU", "30PEU"],
                        uk: ["24PUK", "2PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["0P", "25P"],
                        eu: ["25PEU", "32PEU"],
                        uk: ["25PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["2P", "26P"],
                        eu: ["26PEU", "34PEU"],
                        uk: ["26PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["4P", "27P"],
                        eu: ["27PEU", "36PEU"],
                        uk: ["27PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["6P", "28P"],
                        eu: ["28PEU", "38PEU"],
                        uk: ["10PUK", "28PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["8P", "29P"],
                        eu: ["29PEU", "40PEU"],
                        uk: ["12PUK", "29PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["10P", "30P"],
                        eu: ["30PEU", "42PEU"],
                        uk: ["14PUK", "30PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["12P", "31P"],
                        eu: ["31PEU", "44PEU"],
                        uk: ["16PUK", "31PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["14P", "32P"],
                        eu: ["32PEU", "46PEU"],
                        uk: ["18PUK", "32PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["16P", "33P"],
                        eu: ["33PEU", "48PEU"],
                        uk: ["20PUK", "33PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["18P", "34P"],
                        eu: ["34PEU", "50PEU"],
                        uk: ["22PUK", "34PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "23PAU",
                      short: "23P",
                      long: "23P",
                      display: "23P",
                      display_with_size_set: "23P",
                      display_with_size_system: "AU 23P",
                      display_with_system_and_set: "AU 23P",
                      equivalent_sizes: {
                        us: ["00P", "23P"],
                        eu: ["23PEU", "28PEU"],
                        uk: ["0PUK", "23PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["00P", "24P"],
                        eu: ["24PEU", "30PEU"],
                        uk: ["24PUK", "2PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "25PAU",
                      short: "25P",
                      long: "25P",
                      display: "25P",
                      display_with_size_set: "25P",
                      display_with_size_system: "AU 25P",
                      display_with_system_and_set: "AU 25P",
                      equivalent_sizes: {
                        us: ["0P", "25P"],
                        eu: ["25PEU", "32PEU"],
                        uk: ["25PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26PAU",
                      short: "26P",
                      long: "26P",
                      display: "26P",
                      display_with_size_set: "26P",
                      display_with_size_system: "AU 26P",
                      display_with_system_and_set: "AU 26P",
                      equivalent_sizes: {
                        us: ["2P", "26P"],
                        eu: ["26PEU", "34PEU"],
                        uk: ["26PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "27PAU",
                      short: "27P",
                      long: "27P",
                      display: "27P",
                      display_with_size_set: "27P",
                      display_with_size_system: "AU 27P",
                      display_with_system_and_set: "AU 27P",
                      equivalent_sizes: {
                        us: ["4P", "27P"],
                        eu: ["27PEU", "36PEU"],
                        uk: ["27PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28PAU",
                      short: "28P",
                      long: "28P",
                      display: "28P",
                      display_with_size_set: "28P",
                      display_with_size_system: "AU 28P",
                      display_with_system_and_set: "AU 28P",
                      equivalent_sizes: {
                        us: ["6P", "28P"],
                        eu: ["28PEU", "38PEU"],
                        uk: ["10PUK", "28PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "29PAU",
                      short: "29P",
                      long: "29P",
                      display: "29P",
                      display_with_size_set: "29P",
                      display_with_size_system: "AU 29P",
                      display_with_system_and_set: "AU 29P",
                      equivalent_sizes: {
                        us: ["8P", "29P"],
                        eu: ["29PEU", "40PEU"],
                        uk: ["12PUK", "29PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30PAU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "AU 30P",
                      display_with_system_and_set: "AU 30P",
                      equivalent_sizes: {
                        us: ["10P", "30P"],
                        eu: ["30PEU", "42PEU"],
                        uk: ["14PUK", "30PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "31PAU",
                      short: "31P",
                      long: "31P",
                      display: "31P",
                      display_with_size_set: "31P",
                      display_with_size_system: "AU 31P",
                      display_with_system_and_set: "AU 31P",
                      equivalent_sizes: {
                        us: ["12P", "31P"],
                        eu: ["31PEU", "44PEU"],
                        uk: ["16PUK", "31PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32PAU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "AU 32P",
                      display_with_system_and_set: "AU 32P",
                      equivalent_sizes: {
                        us: ["14P", "32P"],
                        eu: ["32PEU", "46PEU"],
                        uk: ["18PUK", "32PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "33PAU",
                      short: "33P",
                      long: "33P",
                      display: "33P",
                      display_with_size_set: "33P",
                      display_with_size_system: "AU 33P",
                      display_with_system_and_set: "AU 33P",
                      equivalent_sizes: {
                        us: ["16P", "33P"],
                        eu: ["33PEU", "48PEU"],
                        uk: ["20PUK", "33PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34PAU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "AU 34P",
                      display_with_system_and_set: "AU 34P",
                      equivalent_sizes: {
                        us: ["18P", "34P"],
                        eu: ["34PEU", "50PEU"],
                        uk: ["22PUK", "34PUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "23PEU",
                      short: "23P",
                      long: "23P",
                      display: "23P",
                      display_with_size_set: "23P",
                      display_with_size_system: "EU 23P",
                      display_with_system_and_set: "EU 23P",
                      equivalent_sizes: {
                        us: ["00P", "23P"],
                        au: ["0PAU", "23PAU"],
                        uk: ["0PUK", "23PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "24PEU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "EU 24P",
                      display_with_system_and_set: "EU 24P",
                      equivalent_sizes: {
                        us: ["00P", "24P"],
                        au: ["2PAU", "24PAU"],
                        uk: ["2PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "25PEU",
                      short: "25P",
                      long: "25P",
                      display: "25P",
                      display_with_size_set: "25P",
                      display_with_size_system: "EU 25P",
                      display_with_system_and_set: "EU 25P",
                      equivalent_sizes: {
                        us: ["0P", "25P"],
                        au: ["4PAU", "25PAU"],
                        uk: ["4PUK", "25PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "26PEU",
                      short: "26P",
                      long: "26P",
                      display: "26P",
                      display_with_size_set: "26P",
                      display_with_size_system: "EU 26P",
                      display_with_system_and_set: "EU 26P",
                      equivalent_sizes: {
                        us: ["2P", "26P"],
                        au: ["6PAU", "26PAU"],
                        uk: ["6PUK", "26PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "27PEU",
                      short: "27P",
                      long: "27P",
                      display: "27P",
                      display_with_size_set: "27P",
                      display_with_size_system: "EU 27P",
                      display_with_system_and_set: "EU 27P",
                      equivalent_sizes: {
                        us: ["4P", "27P"],
                        au: ["8PAU", "27PAU"],
                        uk: ["8PUK", "27PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "28PEU",
                      short: "28P",
                      long: "28P",
                      display: "28P",
                      display_with_size_set: "28P",
                      display_with_size_system: "EU 28P",
                      display_with_system_and_set: "EU 28P",
                      equivalent_sizes: {
                        us: ["00P", "6P", "23P", "28P"],
                        au: ["0PAU", "10PAU", "23PAU", "28PAU"],
                        uk: ["0PUK", "10PUK", "23PUK", "28PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "29PEU",
                      short: "29P",
                      long: "29P",
                      display: "29P",
                      display_with_size_set: "29P",
                      display_with_size_system: "EU 29P",
                      display_with_system_and_set: "EU 29P",
                      equivalent_sizes: {
                        us: ["8P", "29P"],
                        au: ["12PAU", "29PAU"],
                        uk: ["12PUK", "29PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P", "10P", "24P", "30P"],
                        au: ["2PAU", "14PAU", "24PAU", "30PAU"],
                        uk: ["2PUK", "14PUK", "24PUK", "30PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "31PEU",
                      short: "31P",
                      long: "31P",
                      display: "31P",
                      display_with_size_set: "31P",
                      display_with_size_system: "EU 31P",
                      display_with_system_and_set: "EU 31P",
                      equivalent_sizes: {
                        us: ["12P", "31P"],
                        au: ["16PAU", "31PAU"],
                        uk: ["16PUK", "31PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["0P", "14P", "25P", "32P"],
                        au: ["4PAU", "18PAU", "25PAU", "32PAU"],
                        uk: ["4PUK", "18PUK", "25PUK", "32PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "33PEU",
                      short: "33P",
                      long: "33P",
                      display: "33P",
                      display_with_size_set: "33P",
                      display_with_size_system: "EU 33P",
                      display_with_system_and_set: "EU 33P",
                      equivalent_sizes: {
                        us: ["16P", "33P"],
                        au: ["20PAU", "33PAU"],
                        uk: ["20PUK", "33PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["2P", "18P", "26P", "34P"],
                        au: ["6PAU", "22PAU", "26PAU", "34PAU"],
                        uk: ["6PUK", "22PUK", "26PUK", "34PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["4P", "27P"],
                        au: ["8PAU", "27PAU"],
                        uk: ["8PUK", "27PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["6P", "28P"],
                        au: ["10PAU", "28PAU"],
                        uk: ["10PUK", "28PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["8P", "29P"],
                        au: ["12PAU", "29PAU"],
                        uk: ["12PUK", "29PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["10P", "30P"],
                        au: ["14PAU", "30PAU"],
                        uk: ["14PUK", "30PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["12P", "31P"],
                        au: ["16PAU", "31PAU"],
                        uk: ["16PUK", "31PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["14P", "32P"],
                        au: ["18PAU", "32PAU"],
                        uk: ["18PUK", "32PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["16P", "33P"],
                        au: ["20PAU", "33PAU"],
                        uk: ["20PUK", "33PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["18P", "34P"],
                        au: ["22PAU", "34PAU"],
                        uk: ["22PUK", "34PUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "0PUK",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "UK 0P",
                      display_with_system_and_set: "UK 0P",
                      equivalent_sizes: {
                        us: ["00P", "23P"],
                        au: ["0PAU", "23PAU"],
                        eu: ["23PEU", "28PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P", "24P"],
                        au: ["24PAU", "2PAU"],
                        eu: ["24PEU", "30PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["0P", "25P"],
                        au: ["25PAU", "4PAU"],
                        eu: ["25PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["2P", "26P"],
                        au: ["26PAU", "6PAU"],
                        eu: ["26PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["4P", "27P"],
                        au: ["27PAU", "8PAU"],
                        eu: ["27PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["6P", "28P"],
                        au: ["10PAU", "28PAU"],
                        eu: ["28PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["8P", "29P"],
                        au: ["12PAU", "29PAU"],
                        eu: ["29PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["10P", "30P"],
                        au: ["14PAU", "30PAU"],
                        eu: ["30PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["12P", "31P"],
                        au: ["16PAU", "31PAU"],
                        eu: ["31PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["14P", "32P"],
                        au: ["18PAU", "32PAU"],
                        eu: ["32PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["16P", "33P"],
                        au: ["20PAU", "33PAU"],
                        eu: ["33PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["18P", "34P"],
                        au: ["22PAU", "34PAU"],
                        eu: ["34PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "23PUK",
                      short: "23P",
                      long: "23P",
                      display: "23P",
                      display_with_size_set: "23P",
                      display_with_size_system: "UK 23P",
                      display_with_system_and_set: "UK 23P",
                      equivalent_sizes: {
                        us: ["00P", "23P"],
                        au: ["0PAU", "23PAU"],
                        eu: ["23PEU", "28PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["00P", "24P"],
                        au: ["24PAU", "2PAU"],
                        eu: ["24PEU", "30PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "25PUK",
                      short: "25P",
                      long: "25P",
                      display: "25P",
                      display_with_size_set: "25P",
                      display_with_size_system: "UK 25P",
                      display_with_system_and_set: "UK 25P",
                      equivalent_sizes: {
                        us: ["0P", "25P"],
                        au: ["25PAU", "4PAU"],
                        eu: ["25PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26PUK",
                      short: "26P",
                      long: "26P",
                      display: "26P",
                      display_with_size_set: "26P",
                      display_with_size_system: "UK 26P",
                      display_with_system_and_set: "UK 26P",
                      equivalent_sizes: {
                        us: ["2P", "26P"],
                        au: ["26PAU", "6PAU"],
                        eu: ["26PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "27PUK",
                      short: "27P",
                      long: "27P",
                      display: "27P",
                      display_with_size_set: "27P",
                      display_with_size_system: "UK 27P",
                      display_with_system_and_set: "UK 27P",
                      equivalent_sizes: {
                        us: ["4P", "27P"],
                        au: ["27PAU", "8PAU"],
                        eu: ["27PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28PUK",
                      short: "28P",
                      long: "28P",
                      display: "28P",
                      display_with_size_set: "28P",
                      display_with_size_system: "UK 28P",
                      display_with_system_and_set: "UK 28P",
                      equivalent_sizes: {
                        us: ["6P", "28P"],
                        au: ["10PAU", "28PAU"],
                        eu: ["28PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "29PUK",
                      short: "29P",
                      long: "29P",
                      display: "29P",
                      display_with_size_set: "29P",
                      display_with_size_system: "UK 29P",
                      display_with_system_and_set: "UK 29P",
                      equivalent_sizes: {
                        us: ["8P", "29P"],
                        au: ["12PAU", "29PAU"],
                        eu: ["29PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30PUK",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "UK 30P",
                      display_with_system_and_set: "UK 30P",
                      equivalent_sizes: {
                        us: ["10P", "30P"],
                        au: ["14PAU", "30PAU"],
                        eu: ["30PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "31PUK",
                      short: "31P",
                      long: "31P",
                      display: "31P",
                      display_with_size_set: "31P",
                      display_with_size_system: "UK 31P",
                      display_with_system_and_set: "UK 31P",
                      equivalent_sizes: {
                        us: ["12P", "31P"],
                        au: ["16PAU", "31PAU"],
                        eu: ["31PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32PUK",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "UK 32P",
                      display_with_system_and_set: "UK 32P",
                      equivalent_sizes: {
                        us: ["14P", "32P"],
                        au: ["18PAU", "32PAU"],
                        eu: ["32PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "33PUK",
                      short: "33P",
                      long: "33P",
                      display: "33P",
                      display_with_size_set: "33P",
                      display_with_size_system: "UK 33P",
                      display_with_system_and_set: "UK 33P",
                      equivalent_sizes: {
                        us: ["16P", "33P"],
                        au: ["20PAU", "33PAU"],
                        eu: ["33PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34PUK",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "UK 34P",
                      display_with_system_and_set: "UK 34P",
                      equivalent_sizes: {
                        us: ["18P", "34P"],
                        au: ["22PAU", "34PAU"],
                        eu: ["34PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "2b00579fd97b4e26a60056e3",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "001a8975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "4", "30", "4"],
                  ["0", "6", "32", "6"],
                  ["1", "8", "34", "8"],
                  ["3", "10", "36", "10"],
                  ["5", "11", "38", "11"],
                  ["7", "12", "40", "12"],
                  ["9", "14", "42", "14"],
                  ["11", "16", "44", "16"],
                  ["13", "18", "46", "18"],
                  ["15", "20", "48", "20"],
                  ["17", "22", "50", "22"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "4", "30", "4"],
                ["0", "6", "32", "6"],
                ["1", "8", "34", "8"],
                ["3", "10", "36", "10"],
                ["5", "11", "38", "11"],
                ["7", "12", "40", "12"],
                ["9", "14", "42", "14"],
                ["11", "16", "44", "16"],
                ["13", "18", "46", "18"],
                ["15", "20", "48", "20"],
                ["17", "22", "50", "22"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU"],
                        eu: ["30JEU"],
                        uk: ["4JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU"],
                        eu: ["32JEU"],
                        uk: ["6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["8JAU"],
                        eu: ["34JEU"],
                        uk: ["8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU"],
                        eu: ["36JEU"],
                        uk: ["10JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["11JAU"],
                        eu: ["38JEU"],
                        uk: ["11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU"],
                        eu: ["40JEU"],
                        uk: ["12JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["14JAU"],
                        eu: ["42JEU"],
                        uk: ["14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU"],
                        eu: ["44JEU"],
                        uk: ["16JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["18JAU"],
                        eu: ["46JEU"],
                        uk: ["18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU"],
                        eu: ["48JEU"],
                        uk: ["20JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["22JAU"],
                        eu: ["50JEU"],
                        uk: ["22JUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU"],
                        uk: ["4JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J"],
                        eu: ["32JEU"],
                        uk: ["6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["1J"],
                        eu: ["34JEU"],
                        uk: ["8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J"],
                        eu: ["36JEU"],
                        uk: ["10JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["5J"],
                        eu: ["38JEU"],
                        uk: ["11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J"],
                        eu: ["40JEU"],
                        uk: ["12JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["9J"],
                        eu: ["42JEU"],
                        uk: ["14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J"],
                        eu: ["44JEU"],
                        uk: ["16JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["13J"],
                        eu: ["46JEU"],
                        uk: ["18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J"],
                        eu: ["48JEU"],
                        uk: ["20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["17J"],
                        eu: ["50JEU"],
                        uk: ["22JUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU"],
                        uk: ["4JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J"],
                        au: ["6JAU"],
                        uk: ["6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["1J"],
                        au: ["8JAU"],
                        uk: ["8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J"],
                        au: ["10JAU"],
                        uk: ["10JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["5J"],
                        au: ["11JAU"],
                        uk: ["11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J"],
                        au: ["12JAU"],
                        uk: ["12JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["9J"],
                        au: ["14JAU"],
                        uk: ["14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J"],
                        au: ["16JAU"],
                        uk: ["16JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["13J"],
                        au: ["18JAU"],
                        uk: ["18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48JEU",
                      short: "48 (Juniors)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Juniors)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J"],
                        au: ["20JAU"],
                        uk: ["20JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50JEU",
                      short: "50 (Juniors)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Juniors)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Juniors)",
                      equivalent_sizes: {
                        us: ["17J"],
                        au: ["22JAU"],
                        uk: ["22JUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU"],
                        eu: ["30JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J"],
                        au: ["6JAU"],
                        eu: ["32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["1J"],
                        au: ["8JAU"],
                        eu: ["34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J"],
                        au: ["10JAU"],
                        eu: ["36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["5J"],
                        au: ["11JAU"],
                        eu: ["38JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J"],
                        au: ["12JAU"],
                        eu: ["40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["9J"],
                        au: ["14JAU"],
                        eu: ["42JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J"],
                        au: ["16JAU"],
                        eu: ["44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["13J"],
                        au: ["18JAU"],
                        eu: ["46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J"],
                        au: ["20JAU"],
                        eu: ["48JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["17J"],
                        au: ["22JAU"],
                        eu: ["50JEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "36004308d97b4ea2b60057c7",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "001a8975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-23", "31-33"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-23", "31-33"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-58.4", "78.7-83.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-58.4", "78.7-83.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00,23", "0,23", "23,28", "0"],
                  ["00,24", "24,2", "24,30", "2"],
                  ["0,25", "25,4", "25,32", "4"],
                  ["2,26", "26,6", "26,34", "6"],
                  ["2,26", "26,6", "26,34", "6"],
                  ["6,28", "10,28", "28,38", "10"],
                  ["8,29", "12,29", "29,40", "12"],
                  ["10,30", "14,30", "30,42", "14"],
                  ["12,31", "16,31", "31,44", "16"],
                  ["14,14W,16W,32", "18,20,32", "32,46,48", "18"],
                  [
                    "14,14W,16W,16,18W,18,20W,32,33,34",
                    "18,20,22,32,33,34",
                    "32,33,34,46,48,50",
                    "20",
                  ],
                  ["16,16W,18,18W,20W,33,34", "20,22,33,34", "33,34,48,50", "22"],
                  ["00,20,20W,22W,24", "24,26,2", "24,30,50,52", "24"],
                  [
                    "2,20,20W,22,22W,24W,26",
                    "24,26,28,6",
                    "26,34,50,52,54",
                    "26",
                  ],
                  [
                    "6,22,22W,24,24W,26W,28",
                    "10,26,28,30",
                    "28,38,52,54,56",
                    "28",
                  ],
                  [
                    "10,24,24W,26,26W,28W,30",
                    "14,28,30,32",
                    "30,42,54,56,58",
                    "30",
                  ],
                  [
                    "14W,14,16W,26W,26,28W,28,30W,32",
                    "18,20,30,32,34",
                    "32,46,48,56,58,60",
                    "32",
                  ],
                  [
                    "18W,18,20W,28W,28,30W,30,32W,34",
                    "20,22,32,34,36",
                    "34,48,50,58,60,62",
                    "34",
                  ],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["00,23", "0,23", "23,28", "23"],
                  ["00,20,20W,22W,24", "24,26,2", "24,30,50,52", "24"],
                  ["0,25", "25,4", "25,32", "25"],
                  [
                    "2,20,20W,22,22W,24W,26",
                    "24,26,28,6",
                    "26,34,50,52,54",
                    "26",
                  ],
                  ["4,27", "27,8", "27,36", "27"],
                  [
                    "6,22,22W,24,24W,26W,28",
                    "10,26,28,30",
                    "28,38,52,54,56",
                    "28",
                  ],
                  ["8,29", "12,29", "29,40", "29"],
                  [
                    "10,24,24W,26,26W,28W,30",
                    "14,28,30,32",
                    "30,42,54,56,58",
                    "30",
                  ],
                  ["12,31", "16,31", "31,44", "31"],
                  [
                    "14W,14,16W,26W,26,28W,28,30W,32",
                    "18,20,30,32,34",
                    "32,46,48,56,58,60",
                    "32",
                  ],
                  ["16,16W,18W,33", "20,22,33", "33,48,50", "33"],
                  [
                    "18W,18,20W,28W,28,30W,30,32W,34",
                    "20,22,32,34,36",
                    "34,48,50,58,60,62",
                    "34",
                  ],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["23", "0,23", "23,28", "0,23"],
                ["24", "2,24,28,30", "24,30,54,56", "2,24,28,30"],
                ["25", "4,25", "25,32", "4,25"],
                ["26", "6,26,30,32", "26,34,56,58", "6,26,30,32"],
                ["27", "8,27", "27,36", "8,27"],
                ["28", "10,28,32,34", "28,38,58,60", "10,28,32,34"],
                ["29", "12,29", "29,40", "12,29"],
                ["30", "14,30,34,36", "30,42,60,62", "14,30,34,36"],
                ["31", "16,31", "31,44", "16,31"],
                ["00", "0,2,23,24", "23,24,28,30", "0,2,23,24"],
                ["0", "4,25", "25,32", "4,25"],
                ["2", "6,26", "26,34", "6,26"],
                ["4", "8,27", "27,36", "8,27"],
                ["6", "10,28", "28,38", "10,28"],
                ["8", "12,29", "29,40", "12,29"],
                ["10", "14,30", "30,42", "14,30"],
                ["12", "16,31", "31,44", "16,31"],
                ["32", "18,20,32,36,38", "32,46,48,62", "18,20,32,36,38"],
                ["33", "20,22,33", "33,48,50", "20,22,33"],
                ["34", "20,22,34", "34,48,50", "20,22,34"],
                ["14", "18,20,32", "32,46,48", "18,20,32"],
                ["14W", "18,20,32", "32,46,48", "18,20,32"],
                ["16", "20,22,33", "33,48,50", "20,22,33"],
                ["16W", "18,20,22,32,33", "32,33,46,48,50", "18,20,22,32,33"],
                ["18", "20,22,34", "34,48,50", "20,22,34"],
                ["18W", "20,22,33,34", "33,34,48,50", "20,22,33,34"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "20,22,24,26,34", "34,48,50,52", "20,22,24,26,34"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "2,24,28,30", "24,30,54,56", "2,24,28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "6,26,30,32", "26,34,56,58", "6,26,30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "10,28,32,34", "28,38,58,60", "10,28,32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "14,30,34,36", "30,42,60,62", "14,30,34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "18,20,32,36,38", "32,46,48,62", "18,20,32,36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "23M",
                      short: "23 (Maternity)",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23 (Maternity)",
                      display_with_size_system: "US 23",
                      display_with_system_and_set: "US 23 (Maternity)",
                      equivalent_sizes: {
                        au: ["0MAU", "23MAU"],
                        eu: ["23MEU", "28MEU"],
                        uk: ["0MUK", "23MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "24MAU", "28MAU", "30MAU"],
                        eu: ["24MEU", "30MEU", "54MEU", "56MEU"],
                        uk: ["2MUK", "24MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "25M",
                      short: "25 (Maternity)",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25 (Maternity)",
                      display_with_size_system: "US 25",
                      display_with_system_and_set: "US 25 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "25MAU"],
                        eu: ["25MEU", "32MEU"],
                        uk: ["4MUK", "25MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "26MAU", "30MAU", "32MAU"],
                        eu: ["26MEU", "34MEU", "56MEU", "58MEU"],
                        uk: ["6MUK", "26MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "27M",
                      short: "27 (Maternity)",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27 (Maternity)",
                      display_with_size_system: "US 27",
                      display_with_system_and_set: "US 27 (Maternity)",
                      equivalent_sizes: {
                        au: ["8MAU", "27MAU"],
                        eu: ["27MEU", "36MEU"],
                        uk: ["8MUK", "27MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "28MAU", "32MAU", "34MAU"],
                        eu: ["28MEU", "38MEU", "58MEU", "60MEU"],
                        uk: ["10MUK", "28MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "29M",
                      short: "29 (Maternity)",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29 (Maternity)",
                      display_with_size_system: "US 29",
                      display_with_system_and_set: "US 29 (Maternity)",
                      equivalent_sizes: {
                        au: ["12MAU", "29MAU"],
                        eu: ["29MEU", "40MEU"],
                        uk: ["12MUK", "29MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "30MAU", "34MAU", "36MAU"],
                        eu: ["30MEU", "42MEU", "60MEU", "62MEU"],
                        uk: ["14MUK", "30MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "31M",
                      short: "31 (Maternity)",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31 (Maternity)",
                      display_with_size_system: "US 31",
                      display_with_system_and_set: "US 31 (Maternity)",
                      equivalent_sizes: {
                        au: ["16MAU", "31MAU"],
                        eu: ["31MEU", "44MEU"],
                        uk: ["16MUK", "31MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["0MAU", "2MAU", "23MAU", "24MAU"],
                        eu: ["23MEU", "24MEU", "28MEU", "30MEU"],
                        uk: ["0MUK", "2MUK", "23MUK", "24MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "25MAU"],
                        eu: ["25MEU", "32MEU"],
                        uk: ["4MUK", "25MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "26MAU"],
                        eu: ["26MEU", "34MEU"],
                        uk: ["6MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["8MAU", "27MAU"],
                        eu: ["27MEU", "36MEU"],
                        uk: ["8MUK", "27MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "28MAU"],
                        eu: ["28MEU", "38MEU"],
                        uk: ["10MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["12MAU", "29MAU"],
                        eu: ["29MEU", "40MEU"],
                        uk: ["12MUK", "29MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "30MAU"],
                        eu: ["30MEU", "42MEU"],
                        uk: ["14MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["16MAU", "31MAU"],
                        eu: ["31MEU", "44MEU"],
                        uk: ["16MUK", "31MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "32MAU", "36MAU", "38MAU"],
                        eu: ["32MEU", "46MEU", "48MEU", "62MEU"],
                        uk: ["18MUK", "20MUK", "32MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "33M",
                      short: "33 (Maternity)",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33 (Maternity)",
                      display_with_size_system: "US 33",
                      display_with_system_and_set: "US 33 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU", "33MAU"],
                        eu: ["33MEU", "48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK", "33MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34M",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "US 34",
                      display_with_system_and_set: "US 34 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU", "34MAU"],
                        eu: ["34MEU", "48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "32MAU"],
                        eu: ["32MEU", "46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "32MAU"],
                        eu: ["32MEU", "46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU", "33MAU"],
                        eu: ["33MEU", "48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK", "33MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU", "32MAU", "33MAU"],
                        eu: ["32MEU", "33MEU", "46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK", "32MUK", "33MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU", "34MAU"],
                        eu: ["34MEU", "48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU", "33MAU", "34MAU"],
                        eu: ["33MEU", "34MEU", "48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK", "33MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU", "24MAU", "26MAU", "34MAU"],
                        eu: ["34MEU", "48MEU", "50MEU", "52MEU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24PlusM",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "24MAU", "28MAU", "30MAU"],
                        eu: ["24MEU", "30MEU", "54MEU", "56MEU"],
                        uk: ["2MUK", "24MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26PlusM",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "26MAU", "30MAU", "32MAU"],
                        eu: ["26MEU", "34MEU", "56MEU", "58MEU"],
                        uk: ["6MUK", "26MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28PlusM",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "28MAU", "32MAU", "34MAU"],
                        eu: ["28MEU", "38MEU", "58MEU", "60MEU"],
                        uk: ["10MUK", "28MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30PlusM",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "30MAU", "34MAU", "36MAU"],
                        eu: ["30MEU", "42MEU", "60MEU", "62MEU"],
                        uk: ["14MUK", "30MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32PlusM",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "32MAU", "36MAU", "38MAU"],
                        eu: ["32MEU", "46MEU", "48MEU", "62MEU"],
                        uk: ["18MUK", "20MUK", "32MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "0MAU",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "AU 0",
                      display_with_system_and_set: "AU 0 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "23M"],
                        eu: ["23MEU", "28MEU"],
                        uk: ["0MUK", "23MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "24M"],
                        eu: ["24MEU", "30MEU"],
                        uk: ["24MUK", "2MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "25M"],
                        eu: ["25MEU", "32MEU"],
                        uk: ["25MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "26M"],
                        eu: ["26MEU", "34MEU"],
                        uk: ["26MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["4M", "27M"],
                        eu: ["27MEU", "36MEU"],
                        uk: ["27MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "28M"],
                        eu: ["28MEU", "38MEU"],
                        uk: ["10MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["8M", "29M"],
                        eu: ["29MEU", "40MEU"],
                        uk: ["12MUK", "29MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "30M"],
                        eu: ["30MEU", "42MEU"],
                        uk: ["14MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["12M", "31M"],
                        eu: ["31MEU", "44MEU"],
                        uk: ["16MUK", "31MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM", "32M"],
                        eu: ["32MEU", "46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "14M",
                          "14WM",
                          "16WM",
                          "16M",
                          "18WM",
                          "18M",
                          "20WM",
                          "32M",
                          "33M",
                          "34M",
                        ],
                        eu: [
                          "32MEU",
                          "33MEU",
                          "34MEU",
                          "46MEU",
                          "48MEU",
                          "50MEU",
                        ],
                        uk: [
                          "18MUK",
                          "20MUK",
                          "22MUK",
                          "32MUK",
                          "33MUK",
                          "34MUK",
                        ],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20WM", "33M", "34M"],
                        eu: ["33MEU", "34MEU", "48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK", "33MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "23MAU",
                      short: "23 (Maternity)",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23 (Maternity)",
                      display_with_size_system: "AU 23",
                      display_with_system_and_set: "AU 23 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "23M"],
                        eu: ["23MEU", "28MEU"],
                        uk: ["0MUK", "23MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "20M", "20WM", "22WM", "24M"],
                        eu: ["24MEU", "30MEU", "50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK", "2MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "25MAU",
                      short: "25 (Maternity)",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25 (Maternity)",
                      display_with_size_system: "AU 25",
                      display_with_system_and_set: "AU 25 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "25M"],
                        eu: ["25MEU", "32MEU"],
                        uk: ["25MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "20M", "20WM", "22M", "22WM", "24WM", "26M"],
                        eu: ["26MEU", "34MEU", "50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "27MAU",
                      short: "27 (Maternity)",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27 (Maternity)",
                      display_with_size_system: "AU 27",
                      display_with_system_and_set: "AU 27 (Maternity)",
                      equivalent_sizes: {
                        us: ["4M", "27M"],
                        eu: ["27MEU", "36MEU"],
                        uk: ["27MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "22M", "22WM", "24M", "24WM", "26WM", "28M"],
                        eu: ["28MEU", "38MEU", "52MEU", "54MEU", "56MEU"],
                        uk: ["10MUK", "26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "29MAU",
                      short: "29 (Maternity)",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29 (Maternity)",
                      display_with_size_system: "AU 29",
                      display_with_system_and_set: "AU 29 (Maternity)",
                      equivalent_sizes: {
                        us: ["8M", "29M"],
                        eu: ["29MEU", "40MEU"],
                        uk: ["12MUK", "29MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "24M", "24WM", "26M", "26WM", "28WM", "30M"],
                        eu: ["30MEU", "42MEU", "54MEU", "56MEU", "58MEU"],
                        uk: ["14MUK", "28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "31MAU",
                      short: "31 (Maternity)",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31 (Maternity)",
                      display_with_size_system: "AU 31",
                      display_with_system_and_set: "AU 31 (Maternity)",
                      equivalent_sizes: {
                        us: ["12M", "31M"],
                        eu: ["31MEU", "44MEU"],
                        uk: ["16MUK", "31MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "14WM",
                          "14M",
                          "16WM",
                          "26WM",
                          "26M",
                          "28WM",
                          "28M",
                          "30WM",
                          "32M",
                        ],
                        eu: [
                          "32MEU",
                          "46MEU",
                          "48MEU",
                          "56MEU",
                          "58MEU",
                          "60MEU",
                        ],
                        uk: ["18MUK", "20MUK", "30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "33MAU",
                      short: "33 (Maternity)",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33 (Maternity)",
                      display_with_size_system: "AU 33",
                      display_with_system_and_set: "AU 33 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18WM", "33M"],
                        eu: ["33MEU", "48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK", "33MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "18WM",
                          "18M",
                          "20WM",
                          "28WM",
                          "28M",
                          "30WM",
                          "30M",
                          "32WM",
                          "34M",
                        ],
                        eu: [
                          "34MEU",
                          "48MEU",
                          "50MEU",
                          "58MEU",
                          "60MEU",
                          "62MEU",
                        ],
                        uk: ["20MUK", "22MUK", "32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2PlusMAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "24M"],
                        eu: ["24MEU", "30MEU"],
                        uk: ["2MUK", "24MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PlusMAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "26M"],
                        eu: ["26MEU", "34MEU"],
                        uk: ["6MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PlusMAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "28M"],
                        eu: ["28MEU", "38MEU"],
                        uk: ["10MUK", "28PlusMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PlusMAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "30M"],
                        eu: ["30MEU", "42MEU"],
                        uk: ["14MUK", "30PlusMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PlusMAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM", "32M"],
                        eu: ["32MEU", "46MEU", "48MEU"],
                        uk: ["18PlusMUK", "20PlusMUK", "32PlusMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PlusMAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "20M", "20WM", "22WM", "24M"],
                        eu: ["24MEU", "30MEU", "50MEU", "52MEU"],
                        uk: ["2MUK", "24PlusMUK", "26PlusMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26PlusMAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "20M", "20WM", "22M", "22WM", "24WM", "26M"],
                        eu: ["26MEU", "34MEU", "50MEU", "52MEU", "54MEU"],
                        uk: ["6PlusMUK", "24PlusMUK", "26PlusMUK", "28PlusMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28PlusMAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "22M", "22WM", "24M", "24WM", "26WM", "28M"],
                        eu: ["28MEU", "38MEU", "52MEU", "54MEU", "56MEU"],
                        uk: ["10PlusMUK", "28PlusMUK", "30PlusMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30PlusMAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "24M", "24WM", "26M", "26WM", "28WM", "30M"],
                        eu: ["30MEU", "42MEU", "54MEU", "56MEU", "58MEU"],
                        uk: ["14PlusMUK", "30PlusMUK", "32PlusMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32PlusMAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "14WM",
                          "14M",
                          "16WM",
                          "26WM",
                          "26M",
                          "28WM",
                          "28M",
                          "30WM",
                          "32M",
                        ],
                        eu: [
                          "32MEU",
                          "46MEU",
                          "48MEU",
                          "56MEU",
                          "58MEU",
                          "60MEU",
                        ],
                        uk: [
                          "18PlusMUK",
                          "20PlusMUK",
                          "30PlusMUK",
                          "32PlusMUK",
                          "34PlusMUK",
                        ],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "23MEU",
                      short: "23 (Maternity)",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23 (Maternity)",
                      display_with_size_system: "EU 23",
                      display_with_system_and_set: "EU 23 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "23M"],
                        au: ["0MAU", "23MAU"],
                        uk: ["0MUK", "23MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "24MEU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "EU 24",
                      display_with_system_and_set: "EU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "24M"],
                        au: ["2MAU", "24MAU"],
                        uk: ["2MUK", "24MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "25MEU",
                      short: "25 (Maternity)",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25 (Maternity)",
                      display_with_size_system: "EU 25",
                      display_with_system_and_set: "EU 25 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "25M"],
                        au: ["4MAU", "25MAU"],
                        uk: ["4MUK", "25MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "26MEU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "EU 26",
                      display_with_system_and_set: "EU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "26M"],
                        au: ["6MAU", "26MAU"],
                        uk: ["6MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "27MEU",
                      short: "27 (Maternity)",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27 (Maternity)",
                      display_with_size_system: "EU 27",
                      display_with_system_and_set: "EU 27 (Maternity)",
                      equivalent_sizes: {
                        us: ["4M", "27M"],
                        au: ["8MAU", "27MAU"],
                        uk: ["8MUK", "27MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "28MEU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "EU 28",
                      display_with_system_and_set: "EU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "6M", "23M", "28M"],
                        au: ["0MAU", "10MAU", "23MAU", "28MAU"],
                        uk: ["0MUK", "10MUK", "23MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "29MEU",
                      short: "29 (Maternity)",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29 (Maternity)",
                      display_with_size_system: "EU 29",
                      display_with_system_and_set: "EU 29 (Maternity)",
                      equivalent_sizes: {
                        us: ["8M", "29M"],
                        au: ["12MAU", "29MAU"],
                        uk: ["12MUK", "29MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "10M", "24M", "30M"],
                        au: ["2MAU", "14MAU", "24MAU", "30MAU"],
                        uk: ["2MUK", "14MUK", "24MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "31MEU",
                      short: "31 (Maternity)",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31 (Maternity)",
                      display_with_size_system: "EU 31",
                      display_with_system_and_set: "EU 31 (Maternity)",
                      equivalent_sizes: {
                        us: ["12M", "31M"],
                        au: ["16MAU", "31MAU"],
                        uk: ["16MUK", "31MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "14M", "14WM", "16WM", "25M", "32M"],
                        au: ["4MAU", "18MAU", "20MAU", "25MAU", "32MAU"],
                        uk: ["4MUK", "18MUK", "20MUK", "25MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "33MEU",
                      short: "33 (Maternity)",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33 (Maternity)",
                      display_with_size_system: "EU 33",
                      display_with_system_and_set: "EU 33 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18WM", "33M"],
                        au: ["20MAU", "22MAU", "33MAU"],
                        uk: ["20MUK", "22MUK", "33MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "18M", "18WM", "20WM", "26M", "34M"],
                        au: ["6MAU", "20MAU", "22MAU", "26MAU", "34MAU"],
                        uk: ["6MUK", "20MUK", "22MUK", "26MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["4M", "27M"],
                        au: ["8MAU", "27MAU"],
                        uk: ["8MUK", "27MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "28M"],
                        au: ["10MAU", "28MAU"],
                        uk: ["10MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["8M", "29M"],
                        au: ["12MAU", "29MAU"],
                        uk: ["12MUK", "29MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "30M"],
                        au: ["14MAU", "30MAU"],
                        uk: ["14MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["12M", "31M"],
                        au: ["16MAU", "31MAU"],
                        uk: ["16MUK", "31MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM", "32M"],
                        au: ["18MAU", "20MAU", "32MAU"],
                        uk: ["18MUK", "20MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "14M",
                          "14WM",
                          "16WM",
                          "16M",
                          "18WM",
                          "18M",
                          "20WM",
                          "32M",
                          "33M",
                          "34M",
                        ],
                        au: [
                          "18MAU",
                          "20MAU",
                          "22MAU",
                          "32MAU",
                          "33MAU",
                          "34MAU",
                        ],
                        uk: [
                          "18MUK",
                          "20MUK",
                          "22MUK",
                          "32MUK",
                          "33MUK",
                          "34MUK",
                        ],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "16M",
                          "16WM",
                          "18WM",
                          "18M",
                          "20M",
                          "20WM",
                          "22WM",
                          "33M",
                          "34M",
                        ],
                        au: [
                          "20MAU",
                          "22MAU",
                          "24MAU",
                          "26MAU",
                          "33MAU",
                          "34MAU",
                        ],
                        uk: [
                          "20MUK",
                          "22MUK",
                          "24MUK",
                          "26MUK",
                          "33MUK",
                          "34MUK",
                        ],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "24PlusMEU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "EU 24",
                      display_with_system_and_set: "EU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "24M"],
                        au: ["2MAU", "24MAU"],
                        uk: ["2MUK", "24MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "26PlusMEU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "EU 26",
                      display_with_system_and_set: "EU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "26M"],
                        au: ["6MAU", "26MAU"],
                        uk: ["6MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "28PlusMEU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "EU 28",
                      display_with_system_and_set: "EU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "6M", "23M", "28M"],
                        au: ["0MAU", "10MAU", "23MAU", "28MAU"],
                        uk: ["0MUK", "10MUK", "23MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "30PlusMEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "10M", "24M", "30M"],
                        au: ["2MAU", "14MAU", "24MAU", "30MAU"],
                        uk: ["2MUK", "14MUK", "24MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PlusMEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "14M", "14WM", "16WM", "25M", "32M"],
                        au: ["4MAU", "18MAU", "20MAU", "25MAU", "32MAU"],
                        uk: ["4MUK", "18MUK", "20MUK", "25MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PlusMEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "18M", "18WM", "20WM", "26M", "34M"],
                        au: ["6MAU", "20MAU", "22MAU", "26MAU", "34MAU"],
                        uk: ["6MUK", "20MUK", "22MUK", "26MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PlusMEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "28M"],
                        au: ["10MAU", "28MAU"],
                        uk: ["10MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PlusMEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "30M"],
                        au: ["14MAU", "30MAU"],
                        uk: ["14MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PlusMEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM", "32M"],
                        au: ["18MAU", "20MAU", "32MAU"],
                        uk: ["18MUK", "20MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PlusMUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "24M"],
                        au: ["6MAU", "26MAU"],
                        eu: ["24MEU", "30MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PlusMUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "26M"],
                        au: ["6PlusMAU"],
                        eu: ["26MEU", "34MEU"],
                        uk: ["6PlusMUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PlusMUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "28M"],
                        au: ["10PlusMAU"],
                        eu: ["28MEU", "38MEU"],
                        uk: ["10PlusMUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PlusMUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "30M"],
                        au: ["14PlusMAU"],
                        eu: ["30MEU", "42MEU"],
                        uk: ["14PlusMUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PlusMUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM", "32M"],
                        au: ["18PlusMAU"],
                        eu: ["32MEU", "46MEU", "48MEU"],
                        uk: ["18PlusMUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PlusMUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "20M", "20WM", "22WM", "24M"],
                        au: ["24PlusMAU"],
                        eu: ["24MEU", "30MEU", "50MEU", "52MEU"],
                        uk: ["24PlusMUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26PlusMUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "20M", "20WM", "22M", "22WM", "24WM", "26M"],
                        au: ["26PlusMAU"],
                        eu: ["26MEU", "34MEU", "50MEU", "52MEU", "54MEU"],
                        uk: ["26PlusMUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28PlusMUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "22M", "22WM", "24M", "24WM", "26WM", "28M"],
                        au: ["28PlusMAU"],
                        eu: ["28MEU", "38MEU", "52MEU", "54MEU", "56MEU"],
                        uk: ["28PlusMUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30PlusMUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "24M", "24WM", "26M", "26WM", "28WM", "30M"],
                        au: ["30PlusMAU"],
                        eu: ["30MEU", "42MEU", "54MEU", "56MEU", "58MEU"],
                        uk: ["30PlusMUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32PlusMUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "14WM",
                          "14M",
                          "16WM",
                          "26WM",
                          "26M",
                          "28WM",
                          "28M",
                          "30WM",
                          "32M",
                        ],
                        au: ["32PlusMAU"],
                        eu: [
                          "32MEU",
                          "46MEU",
                          "48MEU",
                          "56MEU",
                          "58MEU",
                          "60MEU",
                        ],
                        uk: ["32PlusMUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0MUK",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "UK 0",
                      display_with_system_and_set: "UK 0 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "23M"],
                        au: ["0MAU", "23MAU"],
                        eu: ["23MEU", "28MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "24M"],
                        au: ["24MAU", "2MAU"],
                        eu: ["24MEU", "30MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "25M"],
                        au: ["25MAU", "4MAU"],
                        eu: ["25MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "26M"],
                        au: ["26MAU", "6MAU"],
                        eu: ["26MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["4M", "27M"],
                        au: ["27MAU", "8MAU"],
                        eu: ["27MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "28M"],
                        au: ["10MAU", "28MAU"],
                        eu: ["28MEU", "38MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["8M", "29M"],
                        au: ["12MAU", "29MAU"],
                        eu: ["29MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "30M"],
                        au: ["14MAU", "30MAU"],
                        eu: ["30MEU", "42MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["12M", "31M"],
                        au: ["16MAU", "31MAU"],
                        eu: ["31MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM", "32M"],
                        au: ["18MAU", "20MAU", "32MAU"],
                        eu: ["32MEU", "46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "14M",
                          "14WM",
                          "16WM",
                          "16M",
                          "18WM",
                          "18M",
                          "20WM",
                          "32M",
                          "33M",
                          "34M",
                        ],
                        au: [
                          "18MAU",
                          "20MAU",
                          "22MAU",
                          "32MAU",
                          "33MAU",
                          "34MAU",
                        ],
                        eu: [
                          "32MEU",
                          "33MEU",
                          "34MEU",
                          "46MEU",
                          "48MEU",
                          "50MEU",
                        ],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20WM", "33M", "34M"],
                        au: ["20MAU", "22MAU", "33MAU", "34MAU"],
                        eu: ["33MEU", "34MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "23MUK",
                      short: "23 (Maternity)",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23 (Maternity)",
                      display_with_size_system: "UK 23",
                      display_with_system_and_set: "UK 23 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "23M"],
                        au: ["0MAU", "23MAU"],
                        eu: ["23MEU", "28MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "20M", "20WM", "22WM", "24M"],
                        au: ["24MAU", "26MAU", "2MAU"],
                        eu: ["24MEU", "30MEU", "50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "25MUK",
                      short: "25 (Maternity)",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25 (Maternity)",
                      display_with_size_system: "UK 25",
                      display_with_system_and_set: "UK 25 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "25M"],
                        au: ["25MAU", "4MAU"],
                        eu: ["25MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "20M", "20WM", "22M", "22WM", "24WM", "26M"],
                        au: ["24MAU", "26MAU", "28MAU", "6MAU"],
                        eu: ["26MEU", "34MEU", "50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "27MUK",
                      short: "27 (Maternity)",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27 (Maternity)",
                      display_with_size_system: "UK 27",
                      display_with_system_and_set: "UK 27 (Maternity)",
                      equivalent_sizes: {
                        us: ["4M", "27M"],
                        au: ["27MAU", "8MAU"],
                        eu: ["27MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "22M", "22WM", "24M", "24WM", "26WM", "28M"],
                        au: ["10MAU", "26MAU", "28MAU", "30MAU"],
                        eu: ["28MEU", "38MEU", "52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "29MUK",
                      short: "29 (Maternity)",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29 (Maternity)",
                      display_with_size_system: "UK 29",
                      display_with_system_and_set: "UK 29 (Maternity)",
                      equivalent_sizes: {
                        us: ["8M", "29M"],
                        au: ["12MAU", "29MAU"],
                        eu: ["29MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "24M", "24WM", "26M", "26WM", "28WM", "30M"],
                        au: ["14MAU", "28MAU", "30MAU", "32MAU"],
                        eu: ["30MEU", "42MEU", "54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "31MUK",
                      short: "31 (Maternity)",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31 (Maternity)",
                      display_with_size_system: "UK 31",
                      display_with_system_and_set: "UK 31 (Maternity)",
                      equivalent_sizes: {
                        us: ["12M", "31M"],
                        au: ["16MAU", "31MAU"],
                        eu: ["31MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "14WM",
                          "14M",
                          "16WM",
                          "26WM",
                          "26M",
                          "28WM",
                          "28M",
                          "30WM",
                          "32M",
                        ],
                        au: ["18MAU", "20MAU", "30MAU", "32MAU", "34MAU"],
                        eu: [
                          "32MEU",
                          "46MEU",
                          "48MEU",
                          "56MEU",
                          "58MEU",
                          "60MEU",
                        ],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "33MUK",
                      short: "33 (Maternity)",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33 (Maternity)",
                      display_with_size_system: "UK 33",
                      display_with_system_and_set: "UK 33 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18WM", "33M"],
                        au: ["20MAU", "22MAU", "33MAU"],
                        eu: ["33MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "18WM",
                          "18M",
                          "20WM",
                          "28WM",
                          "28M",
                          "30WM",
                          "30M",
                          "32WM",
                          "34M",
                        ],
                        au: ["20MAU", "22MAU", "32MAU", "34MAU", "36MAU"],
                        eu: [
                          "34MEU",
                          "48MEU",
                          "50MEU",
                          "58MEU",
                          "60MEU",
                          "62MEU",
                        ],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "women_jeans",
          slug: "Jeans",
          alias_slugs: ["Denim"],
          parent_id: "000e8975d97b4e80ef00a955",
          category_features: [
            {
              id: "008e9287d97b4e80ef00a955",
              display: "Ankle & Cropped",
              type: "cf",
              message_id: "women_jeans_ankle_cropped",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Ankle_&_Cropped",
              alias_slugs: [],
              parent_id: "001a8975d97b4e80ef00a955",
            },
            {
              id: "00909287d97b4e80ef00a955",
              display: "Boot Cut",
              type: "cf",
              message_id: "women_jeans_boot_cut",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Boot_Cut",
              alias_slugs: [],
              parent_id: "001a8975d97b4e80ef00a955",
            },
            {
              id: "00929287d97b4e80ef00a955",
              display: "Boyfriend",
              type: "cf",
              message_id: "women_jeans_boyfriend",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Boyfriend",
              alias_slugs: [],
              parent_id: "001a8975d97b4e80ef00a955",
            },
            {
              id: "00949287d97b4e80ef00a955",
              display: "Flare & Wide Leg",
              type: "cf",
              message_id: "women_jeans_flare_wide_leg",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Flare_&_Wide_Leg",
              alias_slugs: [],
              parent_id: "001a8975d97b4e80ef00a955",
            },
            {
              id: "807df9aaaabb083120f45ec2",
              display: "High Rise",
              type: "cf",
              message_id: "women_jeans_high_rise",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "High_Rise",
              alias_slugs: [],
              parent_id: "001a8975d97b4e80ef00a955",
            },
            {
              id: "817df9aaaabb083120f45ec2",
              display: "Jeggings",
              type: "cf",
              message_id: "women_jeans_jeggings",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Jeggings",
              alias_slugs: [],
              parent_id: "001a8975d97b4e80ef00a955",
            },
            {
              id: "00969287d97b4e80ef00a955",
              display: "Overalls",
              type: "cf",
              message_id: "women_jeans_overalls",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Overalls",
              alias_slugs: [],
              parent_id: "001a8975d97b4e80ef00a955",
            },
            {
              id: "00989287d97b4e80ef00a955",
              display: "Skinny",
              type: "cf",
              message_id: "women_jeans_skinny",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Skinny",
              alias_slugs: [],
              parent_id: "001a8975d97b4e80ef00a955",
            },
            {
              id: "009a9287d97b4e80ef00a955",
              display: "Straight Leg",
              type: "cf",
              message_id: "women_jeans_straight_leg",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Straight_Leg",
              alias_slugs: [],
              parent_id: "001a8975d97b4e80ef00a955",
            },
          ],
        },
        {
          id: "001c8975d97b4e80ef00a955",
          display: "Pants & Jumpsuits",
          size_sets: [
            {
              id: "0d00579fd97b4e26a60056e3",
              name: "Standard",
              tags: ["standard"],
              category_id: "001c8975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00,23", "0,23", "23,28", "0"],
                  ["00,24", "24,2", "24,30", "2"],
                  ["0,25", "25,4", "25,32", "4"],
                  ["2,26", "26,6", "26,34", "6"],
                  ["4,27", "27,8", "27,36", "8"],
                  ["6,28", "10,28", "28,38", "10"],
                  ["8,29", "12,29", "29,40", "12"],
                  ["10,30", "14,30", "30,42", "14"],
                  ["12,31", "16,31", "31,44", "16"],
                  ["00,23", "0,23", "23,28", "23"],
                  ["00,24", "24,2", "24,30", "24"],
                  ["0,25", "25,4", "25,32", "25"],
                  ["2,26", "26,6", "26,34", "26"],
                  ["4,27", "27,8", "27,36", "27"],
                  ["6,28", "10,28", "28,38", "28"],
                  ["8,29", "12,29", "29,40", "29"],
                  ["10,30", "14,30", "30,42", "30"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["23", "0,23", "23,28", "0,23"],
                ["24", "2,24", "24,30", "2,24"],
                ["25", "4,25", "25,32", "4,25"],
                ["26", "6,26", "26,34", "6,26"],
                ["27", "8,27", "27,36", "8,27"],
                ["28", "10,28", "28,38", "10,28"],
                ["29", "12,29", "29,40", "12,29"],
                ["30", "14,30", "30,42", "14,30"],
                ["31", "16,31", "31,44", "16,31"],
                ["00", "0,2,23,24", "23,24,28,30", "0,2,23,24"],
                ["0", "4,25", "25,32", "4,25"],
                ["2", "6,26", "26,34", "6,26"],
                ["4", "8,27", "27,36", "8,27"],
                ["6", "10,28", "28,38", "10,28"],
                ["8", "12,29", "29,40", "12,29"],
                ["10", "14,30", "30,42", "14,30"],
                ["12", "16,31", "31,44", "16,31"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "23",
                      short: "23",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23",
                      display_with_size_system: "US 23",
                      display_with_system_and_set: "US 23",
                      equivalent_sizes: {
                        au: ["0AU", "23AU"],
                        eu: ["23EU", "28EU"],
                        uk: ["0UK", "23UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["2AU", "24AU"],
                        eu: ["24EU", "30EU"],
                        uk: ["2UK", "24UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "25",
                      short: "25",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25",
                      display_with_size_system: "US 25",
                      display_with_system_and_set: "US 25",
                      equivalent_sizes: {
                        au: ["4AU", "25AU"],
                        eu: ["25EU", "32EU"],
                        uk: ["4UK", "25UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["6AU", "26AU"],
                        eu: ["26EU", "34EU"],
                        uk: ["6UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "27",
                      short: "27",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27",
                      display_with_size_system: "US 27",
                      display_with_system_and_set: "US 27",
                      equivalent_sizes: {
                        au: ["8AU", "27AU"],
                        eu: ["27EU", "36EU"],
                        uk: ["8UK", "27UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["10AU", "28AU"],
                        eu: ["28EU", "38EU"],
                        uk: ["10UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "29",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "US 29",
                      display_with_system_and_set: "US 29",
                      equivalent_sizes: {
                        au: ["12AU", "29AU"],
                        eu: ["29EU", "40EU"],
                        uk: ["12UK", "29UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["14AU", "30AU"],
                        eu: ["30EU", "42EU"],
                        uk: ["14UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "31",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "US 31",
                      display_with_system_and_set: "US 31",
                      equivalent_sizes: {
                        au: ["16AU", "31AU"],
                        eu: ["31EU", "44EU"],
                        uk: ["16UK", "31UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["0AU", "2AU", "23AU", "24AU"],
                        eu: ["23EU", "24EU", "28EU", "30EU"],
                        uk: ["0UK", "2UK", "23UK", "24UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "25AU"],
                        eu: ["25EU", "32EU"],
                        uk: ["4UK", "25UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "26AU"],
                        eu: ["26EU", "34EU"],
                        uk: ["6UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["8AU", "27AU"],
                        eu: ["27EU", "36EU"],
                        uk: ["8UK", "27UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "28AU"],
                        eu: ["28EU", "38EU"],
                        uk: ["10UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["12AU", "29AU"],
                        eu: ["29EU", "40EU"],
                        uk: ["12UK", "29UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "30AU"],
                        eu: ["30EU", "42EU"],
                        uk: ["14UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["16AU", "31AU"],
                        eu: ["31EU", "44EU"],
                        uk: ["16UK", "31UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "0AU",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "AU 0",
                      display_with_system_and_set: "AU 0",
                      equivalent_sizes: {
                        us: ["00", "23"],
                        eu: ["23EU", "28EU"],
                        uk: ["0UK", "23UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["00", "24"],
                        eu: ["24EU", "30EU"],
                        uk: ["24UK", "2UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["0", "25"],
                        eu: ["25EU", "32EU"],
                        uk: ["25UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["2", "26"],
                        eu: ["26EU", "34EU"],
                        uk: ["26UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["4", "27"],
                        eu: ["27EU", "36EU"],
                        uk: ["27UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "28"],
                        eu: ["28EU", "38EU"],
                        uk: ["10UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["8", "29"],
                        eu: ["29EU", "40EU"],
                        uk: ["12UK", "29UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "30"],
                        eu: ["30EU", "42EU"],
                        uk: ["14UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["12", "31"],
                        eu: ["31EU", "44EU"],
                        uk: ["16UK", "31UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "23AU",
                      short: "23",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23",
                      display_with_size_system: "AU 23",
                      display_with_system_and_set: "AU 23",
                      equivalent_sizes: {
                        us: ["00", "23"],
                        eu: ["23EU", "28EU"],
                        uk: ["0UK", "23UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["00", "24"],
                        eu: ["24EU", "30EU"],
                        uk: ["24UK", "2UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "25AU",
                      short: "25",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25",
                      display_with_size_system: "AU 25",
                      display_with_system_and_set: "AU 25",
                      equivalent_sizes: {
                        us: ["0", "25"],
                        eu: ["25EU", "32EU"],
                        uk: ["25UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["2", "26"],
                        eu: ["26EU", "34EU"],
                        uk: ["26UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "27AU",
                      short: "27",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27",
                      display_with_size_system: "AU 27",
                      display_with_system_and_set: "AU 27",
                      equivalent_sizes: {
                        us: ["4", "27"],
                        eu: ["27EU", "36EU"],
                        uk: ["27UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["6", "28"],
                        eu: ["28EU", "38EU"],
                        uk: ["10UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "29AU",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "AU 29",
                      display_with_system_and_set: "AU 29",
                      equivalent_sizes: {
                        us: ["8", "29"],
                        eu: ["29EU", "40EU"],
                        uk: ["12UK", "29UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["10", "30"],
                        eu: ["30EU", "42EU"],
                        uk: ["14UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "31AU",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "AU 31",
                      display_with_system_and_set: "AU 31",
                      equivalent_sizes: {
                        us: ["12", "31"],
                        eu: ["31EU", "44EU"],
                        uk: ["16UK", "31UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "23EU",
                      short: "23",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23",
                      display_with_size_system: "EU 23",
                      display_with_system_and_set: "EU 23",
                      equivalent_sizes: {
                        us: ["00", "23"],
                        au: ["0AU", "23AU"],
                        uk: ["0UK", "23UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "24EU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "EU 24",
                      display_with_system_and_set: "EU 24",
                      equivalent_sizes: {
                        us: ["00", "24"],
                        au: ["2AU", "24AU"],
                        uk: ["2UK", "24UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "25EU",
                      short: "25",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25",
                      display_with_size_system: "EU 25",
                      display_with_system_and_set: "EU 25",
                      equivalent_sizes: {
                        us: ["0", "25"],
                        au: ["4AU", "25AU"],
                        uk: ["4UK", "25UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "26EU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "EU 26",
                      display_with_system_and_set: "EU 26",
                      equivalent_sizes: {
                        us: ["2", "26"],
                        au: ["6AU", "26AU"],
                        uk: ["6UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "27EU",
                      short: "27",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27",
                      display_with_size_system: "EU 27",
                      display_with_system_and_set: "EU 27",
                      equivalent_sizes: {
                        us: ["4", "27"],
                        au: ["8AU", "27AU"],
                        uk: ["8UK", "27UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "28EU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "EU 28",
                      display_with_system_and_set: "EU 28",
                      equivalent_sizes: {
                        us: ["00", "6", "23", "28"],
                        au: ["0AU", "10AU", "23AU", "28AU"],
                        uk: ["0UK", "10UK", "23UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "29EU",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "EU 29",
                      display_with_system_and_set: "EU 29",
                      equivalent_sizes: {
                        us: ["8", "29"],
                        au: ["12AU", "29AU"],
                        uk: ["12UK", "29UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["00", "10", "24", "30"],
                        au: ["2AU", "14AU", "24AU", "30AU"],
                        uk: ["2UK", "14UK", "24UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "31EU",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "EU 31",
                      display_with_system_and_set: "EU 31",
                      equivalent_sizes: {
                        us: ["12", "31"],
                        au: ["16AU", "31AU"],
                        uk: ["16UK", "31UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["0", "25"],
                        au: ["4AU", "25AU"],
                        uk: ["4UK", "25UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["2", "26"],
                        au: ["6AU", "26AU"],
                        uk: ["6UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["4", "27"],
                        au: ["8AU", "27AU"],
                        uk: ["8UK", "27UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "28"],
                        au: ["10AU", "28AU"],
                        uk: ["10UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["8", "29"],
                        au: ["12AU", "29AU"],
                        uk: ["12UK", "29UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "30"],
                        au: ["14AU", "30AU"],
                        uk: ["14UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["12", "31"],
                        au: ["16AU", "31AU"],
                        uk: ["16UK", "31UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "0UK",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "UK 0",
                      display_with_system_and_set: "UK 0",
                      equivalent_sizes: {
                        us: ["00", "23"],
                        au: ["0AU", "23AU"],
                        eu: ["23EU", "28EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["00", "24"],
                        au: ["24AU", "2AU"],
                        eu: ["24EU", "30EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["0", "25"],
                        au: ["25AU", "4AU"],
                        eu: ["25EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["2", "26"],
                        au: ["26AU", "6AU"],
                        eu: ["26EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["4", "27"],
                        au: ["27AU", "8AU"],
                        eu: ["27EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "28"],
                        au: ["10AU", "28AU"],
                        eu: ["28EU", "38EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["8", "29"],
                        au: ["12AU", "29AU"],
                        eu: ["29EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "30"],
                        au: ["14AU", "30AU"],
                        eu: ["30EU", "42EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["12", "31"],
                        au: ["16AU", "31AU"],
                        eu: ["31EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "23UK",
                      short: "23",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23",
                      display_with_size_system: "UK 23",
                      display_with_system_and_set: "UK 23",
                      equivalent_sizes: {
                        us: ["00", "23"],
                        au: ["0AU", "23AU"],
                        eu: ["23EU", "28EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["00", "24"],
                        au: ["24AU", "2AU"],
                        eu: ["24EU", "30EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "25UK",
                      short: "25",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25",
                      display_with_size_system: "UK 25",
                      display_with_system_and_set: "UK 25",
                      equivalent_sizes: {
                        us: ["0", "25"],
                        au: ["25AU", "4AU"],
                        eu: ["25EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["2", "26"],
                        au: ["26AU", "6AU"],
                        eu: ["26EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "27UK",
                      short: "27",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27",
                      display_with_size_system: "UK 27",
                      display_with_system_and_set: "UK 27",
                      equivalent_sizes: {
                        us: ["4", "27"],
                        au: ["27AU", "8AU"],
                        eu: ["27EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["6", "28"],
                        au: ["10AU", "28AU"],
                        eu: ["28EU", "38EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "29UK",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "UK 29",
                      display_with_system_and_set: "UK 29",
                      equivalent_sizes: {
                        us: ["8", "29"],
                        au: ["12AU", "29AU"],
                        eu: ["29EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["10", "30"],
                        au: ["14AU", "30AU"],
                        eu: ["30EU", "42EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "31UK",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "UK 31",
                      display_with_system_and_set: "UK 31",
                      equivalent_sizes: {
                        us: ["12", "31"],
                        au: ["16AU", "31AU"],
                        eu: ["31EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "0e00579fd97b4e26a60056e3",
              name: "Plus",
              tags: ["plus"],
              category_id: "001c8975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W,32", "18,20,32,33", "32,46,48", "18"],
                  [
                    "14,14W,16W,16,18W,18,20W,32,33,34",
                    "18,20,22,33,34",
                    "32,33,34,46,48,50",
                    "20",
                  ],
                  ["16,16W,18,18W,20W,33,34", "20,22,34", "33,34,48,50", "22"],
                  ["20,20W,22W", "24", "50,52", "24"],
                  ["20,20W,22W", "26,36", "50,52", "26"],
                  ["22,22W,24W", "28", "52,54", "28"],
                  ["24W,24W,26W", "30,32,32", "54,56", "30"],
                  [
                    "14W,14,16W,26W,26W,28W,28W,30W,32",
                    "18,30,32,32,34,34",
                    "32,46,48,56,58,60",
                    "32",
                  ],
                  [
                    "18W,18,20W,28W,28W,30W,30W,32W,34",
                    "20,22,32,34,34",
                    "34,48,50,58,60,62",
                    "34",
                  ],
                  ["30W,30W,32,32W", "26,36", "60,62", "36"],
                  [
                    "14W,14,16W,26W,26W,28W,28W,30W,32",
                    "18,30,32,32,34,34",
                    "32,46,48,56,58,60",
                    "32",
                  ],
                  ["16,16W,18W,33", "18,20,33", "33,48,50", "33"],
                  [
                    "18W,18,20W,28W,28W,30W,30W,32W,34",
                    "20,22,32,34,34",
                    "34,48,50,58,60,62",
                    "34",
                  ],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["32", "18,20,32,36,38", "32,46,48,62", "18,20,32,36,38"],
                ["33", "20,22,33", "33,48,50", "20,22,33"],
                ["34", "20,22,34", "34,48,50", "20,22,34"],
                ["14", "18,20,32", "32,46,48", "18,20,32"],
                ["14W", "18,20,32", "32,46,48", "18,20,32"],
                ["16", "20,22,33", "33,48,50", "20,22,33"],
                ["16W", "18,20,22,32,33", "32,33,46,48,50", "18,20,22,32,33"],
                ["18", "20,22,34", "34,48,50", "20,22,34"],
                ["18W", "20,22,33,34", "33,34,48,50", "20,22,33,34"],
                ["20", "24,26W", "50,52", "24,26W"],
                ["20W", "20,22,24,26W,34", "34,48,50,52", "20,22,24,26W,34"],
                ["22", "26,28W", "52,54", "26,28W"],
                ["22W", "24,26,26W,28W", "50,52,54", "24,26,26W,28W"],
                ["24", "28,30W", "54,56", "28,30W"],
                ["24W", "26,28,28W,30W", "52,54,56", "26,28,28W,30W"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,30W,32", "54,56,58", "28,30,30W,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "18,20,32,36,38", "32,46,48,62", "18,20,32,36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "32AU", "36AU", "38AU"],
                        eu: ["32PlusEU", "46EU", "48EU", "62EU"],
                        uk: ["18UK", "20UK", "32UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "33",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "US 33",
                      display_with_system_and_set: "US 33",
                      equivalent_sizes: {
                        au: ["20AU", "22AU", "33AU"],
                        eu: ["33EU", "48EU", "50EU"],
                        uk: ["20UK", "22UK", "33UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "US 34",
                      display_with_system_and_set: "US 34",
                      equivalent_sizes: {
                        au: ["20AU", "22AU", "34AU"],
                        eu: ["34", "48EU", "50EU"],
                        uk: ["20UK", "22UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "32AU"],
                        eu: ["32PlusEU", "46EU", "48EU"],
                        uk: ["18UK", "20UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "32AU"],
                        eu: ["32PlusEU", "46EU", "48EU"],
                        uk: ["18UK", "20UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU", "33AU"],
                        eu: ["33EU", "48EU", "50EU"],
                        uk: ["20UK", "22UK", "33UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU", "32AU", "33AU"],
                        eu: ["32PlusEU", "33EU", "46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK", "32UK", "33UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU", "34AU"],
                        eu: ["34", "48EU", "50EU"],
                        uk: ["20UK", "22UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU", "33AU", "34AU"],
                        eu: ["33EU", "34", "48EU", "50EU"],
                        uk: ["20UK", "22UK", "33UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24PlusAU", "26W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24PlusUK", "26W"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU", "24PlusAU", "26W", "34AU"],
                        eu: ["34", "48EU", "50EU", "52EU"],
                        uk: ["20UK", "22UK", "24PlusUK", "26W", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26PlusAU", "28W"],
                        eu: ["52EU", "54EU"],
                        uk: ["26PlusUK", "28W"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24PlusAU", "26PlusAU", "26W", "28W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24PlusUK", "26PlusUK", "26W", "28W"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24Plus",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28PlusAU", "30W"],
                        eu: ["54EU", "56EU"],
                        uk: ["28PlusUK", "30W"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26PlusAU", "28PlusAU", "28W", "30W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26PlusUK", "28PlusUK", "28W", "30W"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26Plus",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30PlusAU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30PlusUK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28PlusAU", "30PlusAU", "30W", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28PlusUK", "30PlusUK", "30W", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28Plus",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30PlusAU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30PlusUK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30Plus",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32Plus",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "32AU", "36AU", "38AU"],
                        eu: ["32PlusEU", "46EU", "48EU", "62EU"],
                        uk: ["18UK", "20UK", "32UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18PlusAU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W", "32"],
                        eu: ["32PlusEU", "46EU", "48EU"],
                        uk: ["18PlusUK", "20UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W", "32"],
                        eu: ["32PlusEU", "46EU", "48EU"],
                        uk: ["18UK", "20UK", "32UK", "33UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: [
                          "14",
                          "14W",
                          "16W",
                          "16",
                          "18W",
                          "18",
                          "20W",
                          "32",
                          "33",
                          "34",
                        ],
                        eu: ["32PlusEU", "33EU", "34", "46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK", "33UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20W", "33", "34"],
                        eu: ["33EU", "34", "48EU", "50EU"],
                        uk: ["20UK", "22UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PlusAU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24PlusUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26PlusAU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["26PlusUK", "36UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28PlusAU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24W"],
                        eu: ["52EU", "54EU"],
                        uk: ["28PlusUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30PlusAU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24W", "24W", "26W"],
                        eu: ["54EU", "56EU"],
                        uk: ["30PlusUK", "32UK", "32PlusUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: [
                          "14W",
                          "14",
                          "16W",
                          "26W",
                          "26W",
                          "28W",
                          "28W",
                          "30W",
                          "32",
                        ],
                        eu: ["32PlusEU", "46EU", "48EU", "56EU", "58EU", "60EU"],
                        uk: [
                          "18UK",
                          "30PlusUK",
                          "32UK",
                          "32PlusUK",
                          "34UK",
                          "34PlusUK",
                        ],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32PlusAU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: [
                          "14W",
                          "14",
                          "16W",
                          "26W",
                          "26W",
                          "28W",
                          "28W",
                          "30W",
                          "32",
                        ],
                        eu: ["32PlusEU", "46EU", "48EU", "56EU", "58EU", "60EU"],
                        uk: ["30PlusUK", "32UK", "32PlusUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "33AU",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "AU 33",
                      display_with_system_and_set: "AU 33",
                      equivalent_sizes: {
                        us: ["16", "16W", "18W", "33"],
                        eu: ["33EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "33UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: [
                          "18W",
                          "18",
                          "20W",
                          "28W",
                          "28W",
                          "30W",
                          "30W",
                          "32W",
                          "34",
                        ],
                        eu: ["34", "48EU", "50EU", "58EU", "60EU", "62EU"],
                        uk: ["20UK", "22UK", "32UK", "34UK", "34PlusUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34PlusAU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: [
                          "18W",
                          "18",
                          "20W",
                          "28W",
                          "28W",
                          "30W",
                          "30W",
                          "32W",
                          "34",
                        ],
                        eu: ["34", "48EU", "50EU", "58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "34PlusUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30W", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["26PlusUK", "36UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36PlusAU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30W", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36PlusUK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36PlusUK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "32PlusEU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W", "32"],
                        au: ["18AU", "20AU", "32AU"],
                        uk: ["18UK", "20UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "33EU",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "EU 33",
                      display_with_system_and_set: "EU 33",
                      equivalent_sizes: {
                        us: ["16", "16W", "18W", "33"],
                        au: ["20AU", "22AU", "33AU"],
                        uk: ["20UK", "22UK", "33UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PlusEU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["18", "18W", "20W", "34"],
                        au: ["20AU", "22AU", "34AU"],
                        uk: ["20UK", "22UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W", "32"],
                        au: ["18AU", "20AU", "32AU"],
                        uk: ["18UK", "20UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: [
                          "14",
                          "14W",
                          "16W",
                          "16",
                          "18W",
                          "18",
                          "20W",
                          "32",
                          "33",
                          "34",
                        ],
                        au: ["18AU", "20AU", "22AU", "32AU", "33AU", "34AU"],
                        uk: ["18UK", "20UK", "22UK", "32UK", "33UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: [
                          "16",
                          "16W",
                          "18W",
                          "18",
                          "20",
                          "20W",
                          "22W",
                          "33",
                          "34",
                        ],
                        au: ["20AU", "22AU", "24PlusAU", "26W", "33AU", "34AU"],
                        uk: ["20UK", "22UK", "24PlusUK", "26W", "33UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24PlusAU", "26PlusAU", "26W", "28W"],
                        uk: ["24PlusUK", "26PlusUK", "26W", "28W"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24W", "24W", "26W"],
                        au: ["26PlusAU", "28PlusAU", "28W", "30W"],
                        uk: ["26PlusUK", "28PlusUK", "28W", "30W"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24W", "24W", "26W", "26W", "28W"],
                        au: ["28PlusAU", "30PlusAU", "30W", "32AU"],
                        uk: ["28PlusUK", "30PlusUK", "30W", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26W", "26W", "28W", "28W", "30W"],
                        au: ["30PlusAU", "32AU", "34AU"],
                        uk: ["30PlusUK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28W", "28W", "30W", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30W", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18PlusUK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W", "32"],
                        au: ["18PlusAU", "20AU", "32AU"],
                        eu: ["32PlusEU", "46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W", "32"],
                        au: ["18AU", "20AU", "32AU", "33AU"],
                        eu: ["32PlusEU", "46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: [
                          "14",
                          "14W",
                          "16W",
                          "16",
                          "18W",
                          "18",
                          "20W",
                          "32",
                          "33",
                          "34",
                        ],
                        au: ["18AU", "20AU", "22AU", "33AU", "34AU"],
                        eu: ["32PlusEU", "33EU", "34", "46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20W", "33", "34"],
                        au: ["20AU", "22AU", "34AU"],
                        eu: ["33EU", "34", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PlusUK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24PlusAU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26PlusUK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["26PlusAU", "36AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28PlusUK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24W"],
                        au: ["28PlusAU"],
                        eu: ["52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30PlusUK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24W", "24W", "26W"],
                        au: ["30PlusAU", "32AU", "32PlusAU"],
                        eu: ["54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: [
                          "14W",
                          "14",
                          "16W",
                          "26W",
                          "26W",
                          "28W",
                          "28W",
                          "30W",
                          "32",
                        ],
                        au: [
                          "18AU",
                          "30PlusAU",
                          "32AU",
                          "32PlusAU",
                          "34AU",
                          "34PlusAU",
                        ],
                        eu: ["32PlusEU", "46EU", "48EU", "56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32PlusUK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: [
                          "14W",
                          "14",
                          "16W",
                          "26W",
                          "26W",
                          "28W",
                          "28W",
                          "30W",
                          "32",
                        ],
                        au: ["30PlusAU", "32AU", "32PlusAU"],
                        eu: ["32PlusEU", "46EU", "48EU", "56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "33UK",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "UK 33",
                      display_with_system_and_set: "UK 33",
                      equivalent_sizes: {
                        us: ["16", "16W", "18W", "33"],
                        au: ["18AU", "20AU", "33AU"],
                        eu: ["33EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: [
                          "18W",
                          "18",
                          "20W",
                          "28W",
                          "28W",
                          "30W",
                          "30W",
                          "32W",
                          "34",
                        ],
                        au: ["20AU", "22AU", "32AU", "34AU", "34PlusAU"],
                        eu: ["34", "48EU", "50EU", "58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34PlusUK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: [
                          "18W",
                          "18",
                          "20W",
                          "28W",
                          "28W",
                          "30W",
                          "30W",
                          "32W",
                          "34",
                        ],
                        au: ["32AU", "34AU", "34PlusAU"],
                        eu: ["34", "48EU", "50EU", "58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30W", "30W", "32", "32W"],
                        au: ["26PlusAU", "36AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36PlusUK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30W", "30W", "32", "32W"],
                        au: ["36PlusAU", "38AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36PlusAU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "2600579fd97b4e26a60056e3",
              name: "Petite",
              tags: ["petite"],
              category_id: "001c8975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["28-29", "21-22", "30-31"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["28-29", "21-22", "30-31"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["71.1-73.7", "53.3-55.9", "76.2-78.7"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["71.1-73.7", "53.3-55.9", "76.2-78.7"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P,23P", "0P,23P", "23P,28P", "0P"],
                  ["00P,24P", "24P,2P", "24P,30P", "2P"],
                  ["0P,25P", "25P,4P", "25P,32P", "4P"],
                  ["2P,26P", "26P,6P", "26P,34P", "6P"],
                  ["4P,27P", "27P,8P", "27P,36P", "8P"],
                  ["6P,28P", "10P,28P", "28P,38P", "10P"],
                  ["8P,29P", "12P,29P", "29P,40P", "12P"],
                  ["10P,30P", "14P,30P", "30P,42P", "14P"],
                  ["12P,31P", "16P,31P", "31P,44P", "16P"],
                  ["14P,32P", "18P,32P", "32P,46P", "18P"],
                  ["16P,33P", "20P,33P", "33P,48P", "20P"],
                  ["18P,34P", "22P,34P", "34P,50P", "22P"],
                  ["00P,23P", "0P,23P", "23P,28P", "23P"],
                  ["00P,24P", "24P,2P", "24P,30P", "24P"],
                  ["0P,25P", "25P,4P", "25P,32P", "25P"],
                  ["2P,26P", "26P,6P", "26P,34P", "26P"],
                  ["4P,27P", "27P,8P", "27P,36P", "27P"],
                  ["6P,28P", "10P,28P", "28P,38P", "28P"],
                  ["8P,29P", "12P,29P", "29P,40P", "29P"],
                  ["10P,30P", "14P,30P", "30P,42P", "30P"],
                  ["12P,31P", "16P,31P", "31P,44P", "31P"],
                  ["14P,32P", "18P,32P", "32P,46P", "32P"],
                  ["16P,33P", "20P,33P", "33P,48P", "33P"],
                  ["18P,34P", "22P,34P", "34P,50P", "34P"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["23P", "0P,23P", "23P,28P", "0P,23P"],
                ["24P", "2P,24P", "24P,30P", "2P,24P"],
                ["25P", "4P,25P", "25P,32P", "4P,25P"],
                ["26P", "6P,26P", "26P,34P", "6P,26P"],
                ["27P", "8P,27P", "27P,36P", "8P,27P"],
                ["28P", "10P,28P", "28P,38P", "10P,28P"],
                ["29P", "12P,29P", "29P,40P", "12P,29P"],
                ["30P", "14P,30P", "30P,42P", "14P,30P"],
                ["31P", "16P,31P", "31P,44P", "16P,31P"],
                ["32P", "18P,32P", "32P,46P", "18P,32P"],
                ["33P", "20P,33P", "33P,48P", "20P,33P"],
                ["34P", "22P,34P", "34P,50P", "22P,34P"],
                ["00P", "0P,2P,23P,24P", "23P,24P,28P,30P", "0P,2P,23P,24P"],
                ["0P", "4P,25P", "25P,32P", "4P,25P"],
                ["2P", "6P,26P", "26P,34P", "6P,26P"],
                ["4P", "8P,27P", "27P,36P", "8P,27P"],
                ["6P", "10P,28P", "28P,38P", "10P,28P"],
                ["8P", "12P,29P", "29P,40P", "12P,29P"],
                ["10P", "14P,30P", "30P,42P", "14P,30P"],
                ["12P", "16P,31P", "31P,44P", "16P,31P"],
                ["14P", "18P,32P", "32P,46P", "18P,32P"],
                ["16P", "20P,33P", "33P,48P", "20P,33P"],
                ["18P", "22P,34P", "34P,50P", "22P,34P"],
                ["20P", "-", "-", "-"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "23P",
                      short: "23P",
                      long: "23P",
                      display: "23P",
                      display_with_size_set: "23P",
                      display_with_size_system: "US 23P",
                      display_with_system_and_set: "US 23P",
                      equivalent_sizes: {
                        au: ["0PAU", "23PAU"],
                        eu: ["23PEU", "28PEU"],
                        uk: ["0PUK", "23PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24P",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "US 24P",
                      display_with_system_and_set: "US 24P",
                      equivalent_sizes: {
                        au: ["2PAU", "24PAU"],
                        eu: ["24PEU", "30PEU"],
                        uk: ["2PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "25P",
                      short: "25P",
                      long: "25P",
                      display: "25P",
                      display_with_size_set: "25P",
                      display_with_size_system: "US 25P",
                      display_with_system_and_set: "US 25P",
                      equivalent_sizes: {
                        au: ["4PAU", "25PAU"],
                        eu: ["25PEU", "32PEU"],
                        uk: ["4PUK", "25PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26P",
                      short: "26P",
                      long: "26P",
                      display: "26P",
                      display_with_size_set: "26P",
                      display_with_size_system: "US 26P",
                      display_with_system_and_set: "US 26P",
                      equivalent_sizes: {
                        au: ["6PAU", "26PAU"],
                        eu: ["26PEU", "34PEU"],
                        uk: ["6PUK", "26PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "27P",
                      short: "27P",
                      long: "27P",
                      display: "27P",
                      display_with_size_set: "27P",
                      display_with_size_system: "US 27P",
                      display_with_system_and_set: "US 27P",
                      equivalent_sizes: {
                        au: ["8PAU", "27PAU"],
                        eu: ["27PEU", "36PEU"],
                        uk: ["8PUK", "27PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28P",
                      short: "28P",
                      long: "28P",
                      display: "28P",
                      display_with_size_set: "28P",
                      display_with_size_system: "US 28P",
                      display_with_system_and_set: "US 28P",
                      equivalent_sizes: {
                        au: ["10PAU", "28PAU"],
                        eu: ["28PEU", "38PEU"],
                        uk: ["10PUK", "28PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "29P",
                      short: "29P",
                      long: "29P",
                      display: "29P",
                      display_with_size_set: "29P",
                      display_with_size_system: "US 29P",
                      display_with_system_and_set: "US 29P",
                      equivalent_sizes: {
                        au: ["12PAU", "29PAU"],
                        eu: ["29PEU", "40PEU"],
                        uk: ["12PUK", "29PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30P",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "US 30P",
                      display_with_system_and_set: "US 30P",
                      equivalent_sizes: {
                        au: ["14PAU", "30PAU"],
                        eu: ["30PEU", "42PEU"],
                        uk: ["14PUK", "30PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "31P",
                      short: "31P",
                      long: "31P",
                      display: "31P",
                      display_with_size_set: "31P",
                      display_with_size_system: "US 31P",
                      display_with_system_and_set: "US 31P",
                      equivalent_sizes: {
                        au: ["16PAU", "31PAU"],
                        eu: ["31PEU", "44PEU"],
                        uk: ["16PUK", "31PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32P",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "US 32P",
                      display_with_system_and_set: "US 32P",
                      equivalent_sizes: {
                        au: ["18PAU", "32PAU"],
                        eu: ["32PEU", "46PEU"],
                        uk: ["18PUK", "32PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "33P",
                      short: "33P",
                      long: "33P",
                      display: "33P",
                      display_with_size_set: "33P",
                      display_with_size_system: "US 33P",
                      display_with_system_and_set: "US 33P",
                      equivalent_sizes: {
                        au: ["20PAU", "33PAU"],
                        eu: ["33PEU", "48PEU"],
                        uk: ["20PUK", "33PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34P",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "US 34P",
                      display_with_system_and_set: "US 34P",
                      equivalent_sizes: {
                        au: ["22PAU", "34PAU"],
                        eu: ["34PEU", "50PEU"],
                        uk: ["22PUK", "34PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["0PAU", "2PAU", "23PAU", "24PAU"],
                        eu: ["23PEU", "24PEU", "28PEU", "30PEU"],
                        uk: ["0PUK", "2PUK", "23PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "25PAU"],
                        eu: ["25PEU", "32PEU"],
                        uk: ["4PUK", "25PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "26PAU"],
                        eu: ["26PEU", "34PEU"],
                        uk: ["6PUK", "26PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "27PAU"],
                        eu: ["27PEU", "36PEU"],
                        uk: ["8PUK", "27PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "28PAU"],
                        eu: ["28PEU", "38PEU"],
                        uk: ["10PUK", "28PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "29PAU"],
                        eu: ["29PEU", "40PEU"],
                        uk: ["12PUK", "29PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "30PAU"],
                        eu: ["30PEU", "42PEU"],
                        uk: ["14PUK", "30PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "31PAU"],
                        eu: ["31PEU", "44PEU"],
                        uk: ["16PUK", "31PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "32PAU"],
                        eu: ["32PEU", "46PEU"],
                        uk: ["18PUK", "32PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "33PAU"],
                        eu: ["33PEU", "48PEU"],
                        uk: ["20PUK", "33PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "34PAU"],
                        eu: ["34PEU", "50PEU"],
                        uk: ["22PUK", "34PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {},
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "0PAU",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "AU 0P",
                      display_with_system_and_set: "AU 0P",
                      equivalent_sizes: {
                        us: ["00P", "23P"],
                        eu: ["23PEU", "28PEU"],
                        uk: ["0PUK", "23PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P", "24P"],
                        eu: ["24PEU", "30PEU"],
                        uk: ["24PUK", "2PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["0P", "25P"],
                        eu: ["25PEU", "32PEU"],
                        uk: ["25PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["2P", "26P"],
                        eu: ["26PEU", "34PEU"],
                        uk: ["26PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["4P", "27P"],
                        eu: ["27PEU", "36PEU"],
                        uk: ["27PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["6P", "28P"],
                        eu: ["28PEU", "38PEU"],
                        uk: ["10PUK", "28PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["8P", "29P"],
                        eu: ["29PEU", "40PEU"],
                        uk: ["12PUK", "29PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["10P", "30P"],
                        eu: ["30PEU", "42PEU"],
                        uk: ["14PUK", "30PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["12P", "31P"],
                        eu: ["31PEU", "44PEU"],
                        uk: ["16PUK", "31PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["14P", "32P"],
                        eu: ["32PEU", "46PEU"],
                        uk: ["18PUK", "32PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["16P", "33P"],
                        eu: ["33PEU", "48PEU"],
                        uk: ["20PUK", "33PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["18P", "34P"],
                        eu: ["34PEU", "50PEU"],
                        uk: ["22PUK", "34PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "23PAU",
                      short: "23P",
                      long: "23P",
                      display: "23P",
                      display_with_size_set: "23P",
                      display_with_size_system: "AU 23P",
                      display_with_system_and_set: "AU 23P",
                      equivalent_sizes: {
                        us: ["00P", "23P"],
                        eu: ["23PEU", "28PEU"],
                        uk: ["0PUK", "23PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["00P", "24P"],
                        eu: ["24PEU", "30PEU"],
                        uk: ["24PUK", "2PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "25PAU",
                      short: "25P",
                      long: "25P",
                      display: "25P",
                      display_with_size_set: "25P",
                      display_with_size_system: "AU 25P",
                      display_with_system_and_set: "AU 25P",
                      equivalent_sizes: {
                        us: ["0P", "25P"],
                        eu: ["25PEU", "32PEU"],
                        uk: ["25PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26PAU",
                      short: "26P",
                      long: "26P",
                      display: "26P",
                      display_with_size_set: "26P",
                      display_with_size_system: "AU 26P",
                      display_with_system_and_set: "AU 26P",
                      equivalent_sizes: {
                        us: ["2P", "26P"],
                        eu: ["26PEU", "34PEU"],
                        uk: ["26PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "27PAU",
                      short: "27P",
                      long: "27P",
                      display: "27P",
                      display_with_size_set: "27P",
                      display_with_size_system: "AU 27P",
                      display_with_system_and_set: "AU 27P",
                      equivalent_sizes: {
                        us: ["4P", "27P"],
                        eu: ["27PEU", "36PEU"],
                        uk: ["27PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28PAU",
                      short: "28P",
                      long: "28P",
                      display: "28P",
                      display_with_size_set: "28P",
                      display_with_size_system: "AU 28P",
                      display_with_system_and_set: "AU 28P",
                      equivalent_sizes: {
                        us: ["6P", "28P"],
                        eu: ["28PEU", "38PEU"],
                        uk: ["10PUK", "28PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "29PAU",
                      short: "29P",
                      long: "29P",
                      display: "29P",
                      display_with_size_set: "29P",
                      display_with_size_system: "AU 29P",
                      display_with_system_and_set: "AU 29P",
                      equivalent_sizes: {
                        us: ["8P", "29P"],
                        eu: ["29PEU", "40PEU"],
                        uk: ["12PUK", "29PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30PAU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "AU 30P",
                      display_with_system_and_set: "AU 30P",
                      equivalent_sizes: {
                        us: ["10P", "30P"],
                        eu: ["30PEU", "42PEU"],
                        uk: ["14PUK", "30PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "31PAU",
                      short: "31P",
                      long: "31P",
                      display: "31P",
                      display_with_size_set: "31P",
                      display_with_size_system: "AU 31P",
                      display_with_system_and_set: "AU 31P",
                      equivalent_sizes: {
                        us: ["12P", "31P"],
                        eu: ["31PEU", "44PEU"],
                        uk: ["16PUK", "31PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32PAU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "AU 32P",
                      display_with_system_and_set: "AU 32P",
                      equivalent_sizes: {
                        us: ["14P", "32P"],
                        eu: ["32PEU", "46PEU"],
                        uk: ["18PUK", "32PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "33PAU",
                      short: "33P",
                      long: "33P",
                      display: "33P",
                      display_with_size_set: "33P",
                      display_with_size_system: "AU 33P",
                      display_with_system_and_set: "AU 33P",
                      equivalent_sizes: {
                        us: ["16P", "33P"],
                        eu: ["33PEU", "48PEU"],
                        uk: ["20PUK", "33PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34PAU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "AU 34P",
                      display_with_system_and_set: "AU 34P",
                      equivalent_sizes: {
                        us: ["18P", "34P"],
                        eu: ["34PEU", "50PEU"],
                        uk: ["22PUK", "34PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "23PEU",
                      short: "23P",
                      long: "23P",
                      display: "23P",
                      display_with_size_set: "23P",
                      display_with_size_system: "EU 23P",
                      display_with_system_and_set: "EU 23P",
                      equivalent_sizes: {
                        us: ["00P", "23P"],
                        au: ["0PAU", "23PAU"],
                        uk: ["0PUK", "23PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "24PEU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "EU 24P",
                      display_with_system_and_set: "EU 24P",
                      equivalent_sizes: {
                        us: ["00P", "24P"],
                        au: ["2PAU", "24PAU"],
                        uk: ["2PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "25PEU",
                      short: "25P",
                      long: "25P",
                      display: "25P",
                      display_with_size_set: "25P",
                      display_with_size_system: "EU 25P",
                      display_with_system_and_set: "EU 25P",
                      equivalent_sizes: {
                        us: ["0P", "25P"],
                        au: ["4PAU", "25PAU"],
                        uk: ["4PUK", "25PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "26PEU",
                      short: "26P",
                      long: "26P",
                      display: "26P",
                      display_with_size_set: "26P",
                      display_with_size_system: "EU 26P",
                      display_with_system_and_set: "EU 26P",
                      equivalent_sizes: {
                        us: ["2P", "26P"],
                        au: ["6PAU", "26PAU"],
                        uk: ["6PUK", "26PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "27PEU",
                      short: "27P",
                      long: "27P",
                      display: "27P",
                      display_with_size_set: "27P",
                      display_with_size_system: "EU 27P",
                      display_with_system_and_set: "EU 27P",
                      equivalent_sizes: {
                        us: ["4P", "27P"],
                        au: ["8PAU", "27PAU"],
                        uk: ["8PUK", "27PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "28PEU",
                      short: "28P",
                      long: "28P",
                      display: "28P",
                      display_with_size_set: "28P",
                      display_with_size_system: "EU 28P",
                      display_with_system_and_set: "EU 28P",
                      equivalent_sizes: {
                        us: ["00P", "6P", "23P", "28P"],
                        au: ["0PAU", "10PAU", "23PAU", "28PAU"],
                        uk: ["0PUK", "10PUK", "23PUK", "28PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "29PEU",
                      short: "29P",
                      long: "29P",
                      display: "29P",
                      display_with_size_set: "29P",
                      display_with_size_system: "EU 29P",
                      display_with_system_and_set: "EU 29P",
                      equivalent_sizes: {
                        us: ["8P", "29P"],
                        au: ["12PAU", "29PAU"],
                        uk: ["12PUK", "29PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P", "10P", "24P", "30P"],
                        au: ["2PAU", "14PAU", "24PAU", "30PAU"],
                        uk: ["2PUK", "14PUK", "24PUK", "30PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "31PEU",
                      short: "31P",
                      long: "31P",
                      display: "31P",
                      display_with_size_set: "31P",
                      display_with_size_system: "EU 31P",
                      display_with_system_and_set: "EU 31P",
                      equivalent_sizes: {
                        us: ["12P", "31P"],
                        au: ["16PAU", "31PAU"],
                        uk: ["16PUK", "31PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["0P", "14P", "25P", "32P"],
                        au: ["4PAU", "18PAU", "25PAU", "32PAU"],
                        uk: ["4PUK", "18PUK", "25PUK", "32PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "33PEU",
                      short: "33P",
                      long: "33P",
                      display: "33P",
                      display_with_size_set: "33P",
                      display_with_size_system: "EU 33P",
                      display_with_system_and_set: "EU 33P",
                      equivalent_sizes: {
                        us: ["16P", "33P"],
                        au: ["20PAU", "33PAU"],
                        uk: ["20PUK", "33PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["2P", "18P", "26P", "34P"],
                        au: ["6PAU", "22PAU", "26PAU", "34PAU"],
                        uk: ["6PUK", "22PUK", "26PUK", "34PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["4P", "27P"],
                        au: ["8PAU", "27PAU"],
                        uk: ["8PUK", "27PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["6P", "28P"],
                        au: ["10PAU", "28PAU"],
                        uk: ["10PUK", "28PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["8P", "29P"],
                        au: ["12PAU", "29PAU"],
                        uk: ["12PUK", "29PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["10P", "30P"],
                        au: ["14PAU", "30PAU"],
                        uk: ["14PUK", "30PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["12P", "31P"],
                        au: ["16PAU", "31PAU"],
                        uk: ["16PUK", "31PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["14P", "32P"],
                        au: ["18PAU", "32PAU"],
                        uk: ["18PUK", "32PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["16P", "33P"],
                        au: ["20PAU", "33PAU"],
                        uk: ["20PUK", "33PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["18P", "34P"],
                        au: ["22PAU", "34PAU"],
                        uk: ["22PUK", "34PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "0PUK",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "UK 0P",
                      display_with_system_and_set: "UK 0P",
                      equivalent_sizes: {
                        us: ["00P", "23P"],
                        au: ["0PAU", "23PAU"],
                        eu: ["23PEU", "28PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P", "24P"],
                        au: ["24PAU", "2PAU"],
                        eu: ["24PEU", "30PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["0P", "25P"],
                        au: ["25PAU", "4PAU"],
                        eu: ["25PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["2P", "26P"],
                        au: ["26PAU", "6PAU"],
                        eu: ["26PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["4P", "27P"],
                        au: ["27PAU", "8PAU"],
                        eu: ["27PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["6P", "28P"],
                        au: ["10PAU", "28PAU"],
                        eu: ["28PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["8P", "29P"],
                        au: ["12PAU", "29PAU"],
                        eu: ["29PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["10P", "30P"],
                        au: ["14PAU", "30PAU"],
                        eu: ["30PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["12P", "31P"],
                        au: ["16PAU", "31PAU"],
                        eu: ["31PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["14P", "32P"],
                        au: ["18PAU", "32PAU"],
                        eu: ["32PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["16P", "33P"],
                        au: ["20PAU", "33PAU"],
                        eu: ["33PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["18P", "34P"],
                        au: ["22PAU", "34PAU"],
                        eu: ["34PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "23PUK",
                      short: "23P",
                      long: "23P",
                      display: "23P",
                      display_with_size_set: "23P",
                      display_with_size_system: "UK 23P",
                      display_with_system_and_set: "UK 23P",
                      equivalent_sizes: {
                        us: ["00P", "23P"],
                        au: ["0PAU", "23PAU"],
                        eu: ["23PEU", "28PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["00P", "24P"],
                        au: ["24PAU", "2PAU"],
                        eu: ["24PEU", "30PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "25PUK",
                      short: "25P",
                      long: "25P",
                      display: "25P",
                      display_with_size_set: "25P",
                      display_with_size_system: "UK 25P",
                      display_with_system_and_set: "UK 25P",
                      equivalent_sizes: {
                        us: ["0P", "25P"],
                        au: ["25PAU", "4PAU"],
                        eu: ["25PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26PUK",
                      short: "26P",
                      long: "26P",
                      display: "26P",
                      display_with_size_set: "26P",
                      display_with_size_system: "UK 26P",
                      display_with_system_and_set: "UK 26P",
                      equivalent_sizes: {
                        us: ["2P", "26P"],
                        au: ["26PAU", "6PAU"],
                        eu: ["26PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "27PUK",
                      short: "27P",
                      long: "27P",
                      display: "27P",
                      display_with_size_set: "27P",
                      display_with_size_system: "UK 27P",
                      display_with_system_and_set: "UK 27P",
                      equivalent_sizes: {
                        us: ["4P", "27P"],
                        au: ["27PAU", "8PAU"],
                        eu: ["27PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28PUK",
                      short: "28P",
                      long: "28P",
                      display: "28P",
                      display_with_size_set: "28P",
                      display_with_size_system: "UK 28P",
                      display_with_system_and_set: "UK 28P",
                      equivalent_sizes: {
                        us: ["6P", "28P"],
                        au: ["10PAU", "28PAU"],
                        eu: ["28PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "29PUK",
                      short: "29P",
                      long: "29P",
                      display: "29P",
                      display_with_size_set: "29P",
                      display_with_size_system: "UK 29P",
                      display_with_system_and_set: "UK 29P",
                      equivalent_sizes: {
                        us: ["8P", "29P"],
                        au: ["12PAU", "29PAU"],
                        eu: ["29PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30PUK",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "UK 30P",
                      display_with_system_and_set: "UK 30P",
                      equivalent_sizes: {
                        us: ["10P", "30P"],
                        au: ["14PAU", "30PAU"],
                        eu: ["30PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "31PUK",
                      short: "31P",
                      long: "31P",
                      display: "31P",
                      display_with_size_set: "31P",
                      display_with_size_system: "UK 31P",
                      display_with_system_and_set: "UK 31P",
                      equivalent_sizes: {
                        us: ["12P", "31P"],
                        au: ["16PAU", "31PAU"],
                        eu: ["31PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32PUK",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "UK 32P",
                      display_with_system_and_set: "UK 32P",
                      equivalent_sizes: {
                        us: ["14P", "32P"],
                        au: ["18PAU", "32PAU"],
                        eu: ["32PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "33PUK",
                      short: "33P",
                      long: "33P",
                      display: "33P",
                      display_with_size_set: "33P",
                      display_with_size_system: "UK 33P",
                      display_with_system_and_set: "UK 33P",
                      equivalent_sizes: {
                        us: ["16P", "33P"],
                        au: ["20PAU", "33PAU"],
                        eu: ["33PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34PUK",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "UK 34P",
                      display_with_system_and_set: "UK 34P",
                      equivalent_sizes: {
                        us: ["18P", "34P"],
                        au: ["22PAU", "34PAU"],
                        eu: ["34PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "2c00579fd97b4e26a60056e3",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "001c8975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "4", "30", "4"],
                  ["0", "6", "32", "6"],
                  ["1", "8", "34", "8"],
                  ["3", "10", "36", "10"],
                  ["5", "11", "38", "11"],
                  ["7", "12", "40", "12"],
                  ["9", "14", "42", "14"],
                  ["11", "16", "44", "16"],
                  ["13", "18", "46", "18"],
                  ["15", "20", "48", "20"],
                  ["17", "22", "50", "22"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "4", "30", "4"],
                ["0", "6", "32", "6"],
                ["1", "8", "34", "8"],
                ["3", "10", "36", "10"],
                ["5", "11", "38", "11"],
                ["7", "12", "40", "12"],
                ["9", "14", "42", "14"],
                ["11", "16", "44", "16"],
                ["13", "18", "46", "18"],
                ["15", "20", "48", "20"],
                ["17", "22", "50", "22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU"],
                        eu: ["30JEU"],
                        uk: ["4JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU"],
                        eu: ["32JEU"],
                        uk: ["6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["8JAU"],
                        eu: ["34JEU"],
                        uk: ["8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU"],
                        eu: ["36JEU"],
                        uk: ["10JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["11JAU"],
                        eu: ["38JEU"],
                        uk: ["11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU"],
                        eu: ["40JEU"],
                        uk: ["12JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["14JAU"],
                        eu: ["42JEU"],
                        uk: ["14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU"],
                        eu: ["44JEU"],
                        uk: ["16JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["18JAU"],
                        eu: ["46JEU"],
                        uk: ["18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU"],
                        eu: ["48JEU"],
                        uk: ["20JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["22JAU"],
                        eu: ["50JEU"],
                        uk: ["22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU"],
                        uk: ["4JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J"],
                        eu: ["32JEU"],
                        uk: ["6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["1J"],
                        eu: ["34JEU"],
                        uk: ["8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J"],
                        eu: ["36JEU"],
                        uk: ["10JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["5J"],
                        eu: ["38JEU"],
                        uk: ["11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J"],
                        eu: ["40JEU"],
                        uk: ["12JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["9J"],
                        eu: ["42JEU"],
                        uk: ["14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J"],
                        eu: ["44JEU"],
                        uk: ["16JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["13J"],
                        eu: ["46JEU"],
                        uk: ["18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J"],
                        eu: ["48JEU"],
                        uk: ["20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["17J"],
                        eu: ["50JEU"],
                        uk: ["22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU"],
                        uk: ["4JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J"],
                        au: ["6JAU"],
                        uk: ["6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["1J"],
                        au: ["8JAU"],
                        uk: ["8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J"],
                        au: ["10JAU"],
                        uk: ["10JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["5J"],
                        au: ["11JAU"],
                        uk: ["11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J"],
                        au: ["12JAU"],
                        uk: ["12JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["9J"],
                        au: ["14JAU"],
                        uk: ["14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J"],
                        au: ["16JAU"],
                        uk: ["16JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["13J"],
                        au: ["18JAU"],
                        uk: ["18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48JEU",
                      short: "48 (Juniors)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Juniors)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J"],
                        au: ["20JAU"],
                        uk: ["20JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50JEU",
                      short: "50 (Juniors)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Juniors)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Juniors)",
                      equivalent_sizes: {
                        us: ["17J"],
                        au: ["22JAU"],
                        uk: ["22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU"],
                        eu: ["30JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J"],
                        au: ["6JAU"],
                        eu: ["32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["1J"],
                        au: ["8JAU"],
                        eu: ["34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J"],
                        au: ["10JAU"],
                        eu: ["36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["5J"],
                        au: ["11JAU"],
                        eu: ["38JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J"],
                        au: ["12JAU"],
                        eu: ["40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["9J"],
                        au: ["14JAU"],
                        eu: ["42JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J"],
                        au: ["16JAU"],
                        eu: ["44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["13J"],
                        au: ["18JAU"],
                        eu: ["46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J"],
                        au: ["20JAU"],
                        eu: ["48JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["17J"],
                        au: ["22JAU"],
                        eu: ["50JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "37004308d97b4ea2b60057c7",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "001c8975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-23", "31-33"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-23", "31-33"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-58.4", "78.7-83.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-58.4", "78.7-83.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00,23", "0,23", "23,28", "0"],
                  ["00,24", "24,2", "24,30", "2"],
                  ["0,25", "25,4", "25,32", "4"],
                  ["2,26", "26,6", "26,34", "6"],
                  ["2,26", "26,6", "26,34", "6"],
                  ["6,28", "10,28", "28,38", "10"],
                  ["8,29", "12,29", "29,40", "12"],
                  ["10,30", "14,30", "30,42", "14"],
                  ["12,31", "16,31", "31,44", "16"],
                  ["14,14W,16W,32", "18,20,32", "32,46,48", "18"],
                  [
                    "14,14W,16W,16,18W,18,20W,32,33,34",
                    "18,20,22,32,33,34",
                    "32,33,34,46,48,50",
                    "20",
                  ],
                  ["16,16W,18,18W,20W,33,34", "20,22,33,34", "33,34,48,50", "22"],
                  ["00,20,20W,22W,24", "24,26,2", "24,30,50,52", "24"],
                  [
                    "2,20,20W,22,22W,24W,26",
                    "24,26,28,6",
                    "26,34,50,52,54",
                    "26",
                  ],
                  [
                    "6,22,22W,24,24W,26W,28",
                    "10,26,28,30",
                    "28,38,52,54,56",
                    "28",
                  ],
                  [
                    "10,24,24W,26,26W,28W,30",
                    "14,28,30,32",
                    "30,42,54,56,58",
                    "30",
                  ],
                  [
                    "14W,14,16W,26W,26,28W,28,30W,32",
                    "18,20,30,32,34",
                    "32,46,48,56,58,60",
                    "32",
                  ],
                  [
                    "18W,18,20W,28W,28,30W,30,32W,34",
                    "20,22,32,34,36",
                    "34,48,50,58,60,62",
                    "34",
                  ],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["00,23", "0,23", "23,28", "23"],
                  ["00,20,20W,22W,24", "24,26,2", "24,30,50,52", "24"],
                  ["0,25", "25,4", "25,32", "25"],
                  [
                    "2,20,20W,22,22W,24W,26",
                    "24,26,28,6",
                    "26,34,50,52,54",
                    "26",
                  ],
                  ["4,27", "27,8", "27,36", "27"],
                  [
                    "6,22,22W,24,24W,26W,28",
                    "10,26,28,30",
                    "28,38,52,54,56",
                    "28",
                  ],
                  ["8,29", "12,29", "29,40", "29"],
                  [
                    "10,24,24W,26,26W,28W,30",
                    "14,28,30,32",
                    "30,42,54,56,58",
                    "30",
                  ],
                  ["12,31", "16,31", "31,44", "31"],
                  [
                    "14W,14,16W,26W,26,28W,28,30W,32",
                    "18,20,30,32,34",
                    "32,46,48,56,58,60",
                    "32",
                  ],
                  ["16,16W,18W,33", "20,22,33", "33,48,50", "33"],
                  [
                    "18W,18,20W,28W,28,30W,30,32W,34",
                    "20,22,32,34,36",
                    "34,48,50,58,60,62",
                    "34",
                  ],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["23", "0,23", "23,28", "0,23"],
                ["24", "2,24,28,30", "24,30,54,56", "2,24,28,30"],
                ["25", "4,25", "25,32", "4,25"],
                ["26", "6,26,30,32", "26,34,56,58", "6,26,30,32"],
                ["27", "8,27", "27,36", "8,27"],
                ["28", "10,28,32,34", "28,38,58,60", "10,28,32,34"],
                ["29", "12,29", "29,40", "12,29"],
                ["30", "14,30,34,36", "30,42,60,62", "14,30,34,36"],
                ["31", "16,31", "31,44", "16,31"],
                ["00", "0,2,23,24", "23,24,28,30", "0,2,23,24"],
                ["0", "4,25", "25,32", "4,25"],
                ["2", "6,26", "26,34", "6,26"],
                ["4", "8,27", "27,36", "8,27"],
                ["6", "10,28", "28,38", "10,28"],
                ["8", "12,29", "29,40", "12,29"],
                ["10", "14,30", "30,42", "14,30"],
                ["12", "16,31", "31,44", "16,31"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["32", "18,20,32,36,38", "32,46,48,62", "18,20,32,36,38"],
                ["33", "20,22,33", "33,48,50", "20,22,33"],
                ["34", "20,22,34", "34,48,50", "20,22,34"],
                ["14", "18,20,32", "32,46,48", "18,20,32"],
                ["14W", "18,20,32", "32,46,48", "18,20,32"],
                ["16", "20,22,33", "33,48,50", "20,22,33"],
                ["16W", "18,20,22,32,33", "32,33,46,48,50", "18,20,22,32,33"],
                ["18", "20,22,34", "34,48,50", "20,22,34"],
                ["18W", "20,22,33,34", "33,34,48,50", "20,22,33,34"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "20,22,24,26,34", "34,48,50,52", "20,22,24,26,34"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "2,24,28,30", "24,30,54,56", "2,24,28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "6,26,30,32", "26,34,56,58", "6,26,30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "10,28,32,34", "28,38,58,60", "10,28,32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "14,30,34,36", "30,42,60,62", "14,30,34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "18,20,32,36,38", "32,46,48,62", "18,20,32,36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "23M",
                      short: "23 (Maternity)",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23 (Maternity)",
                      display_with_size_system: "US 23",
                      display_with_system_and_set: "US 23 (Maternity)",
                      equivalent_sizes: {
                        au: ["0MAU", "23MAU"],
                        eu: ["23MEU", "28MEU"],
                        uk: ["0MUK", "23MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "24MAU", "28MAU", "30MAU"],
                        eu: ["24MEU", "30MEU", "54MEU", "56MEU"],
                        uk: ["2MUK", "24MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "25M",
                      short: "25 (Maternity)",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25 (Maternity)",
                      display_with_size_system: "US 25",
                      display_with_system_and_set: "US 25 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "25MAU"],
                        eu: ["25MEU", "32MEU"],
                        uk: ["4MUK", "25MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "26MAU", "30MAU", "32MAU"],
                        eu: ["26MEU", "34MEU", "56MEU", "58MEU"],
                        uk: ["6MUK", "26MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "27M",
                      short: "27 (Maternity)",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27 (Maternity)",
                      display_with_size_system: "US 27",
                      display_with_system_and_set: "US 27 (Maternity)",
                      equivalent_sizes: {
                        au: ["8MAU", "27MAU"],
                        eu: ["27MEU", "36MEU"],
                        uk: ["8MUK", "27MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "28MAU", "32MAU", "34MAU"],
                        eu: ["28MEU", "38MEU", "58MEU", "60MEU"],
                        uk: ["10MUK", "28MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "29M",
                      short: "29 (Maternity)",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29 (Maternity)",
                      display_with_size_system: "US 29",
                      display_with_system_and_set: "US 29 (Maternity)",
                      equivalent_sizes: {
                        au: ["12MAU", "29MAU"],
                        eu: ["29MEU", "40MEU"],
                        uk: ["12MUK", "29MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "30MAU", "34MAU", "36MAU"],
                        eu: ["30MEU", "42MEU", "60MEU", "62MEU"],
                        uk: ["14MUK", "30MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "31M",
                      short: "31 (Maternity)",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31 (Maternity)",
                      display_with_size_system: "US 31",
                      display_with_system_and_set: "US 31 (Maternity)",
                      equivalent_sizes: {
                        au: ["16MAU", "31MAU"],
                        eu: ["31MEU", "44MEU"],
                        uk: ["16MUK", "31MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["0MAU", "2MAU", "23MAU", "24MAU"],
                        eu: ["23MEU", "24MEU", "28MEU", "30MEU"],
                        uk: ["0MUK", "2MUK", "23MUK", "24MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "25MAU"],
                        eu: ["25MEU", "32MEU"],
                        uk: ["4MUK", "25MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "26MAU"],
                        eu: ["26MEU", "34MEU"],
                        uk: ["6MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["8MAU", "27MAU"],
                        eu: ["27MEU", "36MEU"],
                        uk: ["8MUK", "27MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "28MAU"],
                        eu: ["28MEU", "38MEU"],
                        uk: ["10MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["12MAU", "29MAU"],
                        eu: ["29MEU", "40MEU"],
                        uk: ["12MUK", "29MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "30MAU"],
                        eu: ["30MEU", "42MEU"],
                        uk: ["14MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["16MAU", "31MAU"],
                        eu: ["31MEU", "44MEU"],
                        uk: ["16MUK", "31MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "32MAU", "36MAU", "38MAU"],
                        eu: ["32MEU", "46MEU", "48MEU", "62MEU"],
                        uk: ["18MUK", "20MUK", "32MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "33M",
                      short: "33 (Maternity)",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33 (Maternity)",
                      display_with_size_system: "US 33",
                      display_with_system_and_set: "US 33 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU", "33MAU"],
                        eu: ["33MEU", "48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK", "33MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34M",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "US 34",
                      display_with_system_and_set: "US 34 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU", "34MAU"],
                        eu: ["34MEU", "48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "32MAU"],
                        eu: ["32MEU", "46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "32MAU"],
                        eu: ["32MEU", "46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU", "33MAU"],
                        eu: ["33MEU", "48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK", "33MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU", "32MAU", "33MAU"],
                        eu: ["32MEU", "33MEU", "46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK", "32MUK", "33MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU", "34MAU"],
                        eu: ["34MEU", "48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU", "33MAU", "34MAU"],
                        eu: ["33MEU", "34MEU", "48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK", "33MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU", "24MAU", "26MAU", "34MAU"],
                        eu: ["34MEU", "48MEU", "50MEU", "52MEU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24PlusM",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "24MAU", "28MAU", "30MAU"],
                        eu: ["24MEU", "30MEU", "54MEU", "56MEU"],
                        uk: ["2MUK", "24MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26PlusM",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "26MAU", "30MAU", "32MAU"],
                        eu: ["26MEU", "34MEU", "56MEU", "58MEU"],
                        uk: ["6MUK", "26MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28PlusM",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "28MAU", "32MAU", "34MAU"],
                        eu: ["28MEU", "38MEU", "58MEU", "60MEU"],
                        uk: ["10MUK", "28MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30PlusM",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "30MAU", "34MAU", "36MAU"],
                        eu: ["30MEU", "42MEU", "60MEU", "62MEU"],
                        uk: ["14MUK", "30MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32PlusM",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "32MAU", "36MAU", "38MAU"],
                        eu: ["32MEU", "46MEU", "48MEU", "62MEU"],
                        uk: ["18MUK", "20MUK", "32MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "0MAU",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "AU 0",
                      display_with_system_and_set: "AU 0 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "23M"],
                        eu: ["23MEU", "28MEU"],
                        uk: ["0MUK", "23MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "24M"],
                        eu: ["24MEU", "30MEU"],
                        uk: ["24MUK", "2MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "25M"],
                        eu: ["25MEU", "32MEU"],
                        uk: ["25MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "26M"],
                        eu: ["26MEU", "34MEU"],
                        uk: ["26MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["4M", "27M"],
                        eu: ["27MEU", "36MEU"],
                        uk: ["27MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "28M"],
                        eu: ["28MEU", "38MEU"],
                        uk: ["10MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["8M", "29M"],
                        eu: ["29MEU", "40MEU"],
                        uk: ["12MUK", "29MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "30M"],
                        eu: ["30MEU", "42MEU"],
                        uk: ["14MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["12M", "31M"],
                        eu: ["31MEU", "44MEU"],
                        uk: ["16MUK", "31MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM", "32M"],
                        eu: ["32MEU", "46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "14M",
                          "14WM",
                          "16WM",
                          "16M",
                          "18WM",
                          "18M",
                          "20WM",
                          "32M",
                          "33M",
                          "34M",
                        ],
                        eu: [
                          "32MEU",
                          "33MEU",
                          "34MEU",
                          "46MEU",
                          "48MEU",
                          "50MEU",
                        ],
                        uk: [
                          "18MUK",
                          "20MUK",
                          "22MUK",
                          "32MUK",
                          "33MUK",
                          "34MUK",
                        ],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20WM", "33M", "34M"],
                        eu: ["33MEU", "34MEU", "48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK", "33MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "23MAU",
                      short: "23 (Maternity)",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23 (Maternity)",
                      display_with_size_system: "AU 23",
                      display_with_system_and_set: "AU 23 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "23M"],
                        eu: ["23MEU", "28MEU"],
                        uk: ["0MUK", "23MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "20M", "20WM", "22WM", "24M"],
                        eu: ["24MEU", "30MEU", "50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK", "2MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "25MAU",
                      short: "25 (Maternity)",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25 (Maternity)",
                      display_with_size_system: "AU 25",
                      display_with_system_and_set: "AU 25 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "25M"],
                        eu: ["25MEU", "32MEU"],
                        uk: ["25MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "20M", "20WM", "22M", "22WM", "24WM", "26M"],
                        eu: ["26MEU", "34MEU", "50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "27MAU",
                      short: "27 (Maternity)",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27 (Maternity)",
                      display_with_size_system: "AU 27",
                      display_with_system_and_set: "AU 27 (Maternity)",
                      equivalent_sizes: {
                        us: ["4M", "27M"],
                        eu: ["27MEU", "36MEU"],
                        uk: ["27MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "22M", "22WM", "24M", "24WM", "26WM", "28M"],
                        eu: ["28MEU", "38MEU", "52MEU", "54MEU", "56MEU"],
                        uk: ["10MUK", "26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "29MAU",
                      short: "29 (Maternity)",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29 (Maternity)",
                      display_with_size_system: "AU 29",
                      display_with_system_and_set: "AU 29 (Maternity)",
                      equivalent_sizes: {
                        us: ["8M", "29M"],
                        eu: ["29MEU", "40MEU"],
                        uk: ["12MUK", "29MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "24M", "24WM", "26M", "26WM", "28WM", "30M"],
                        eu: ["30MEU", "42MEU", "54MEU", "56MEU", "58MEU"],
                        uk: ["14MUK", "28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "31MAU",
                      short: "31 (Maternity)",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31 (Maternity)",
                      display_with_size_system: "AU 31",
                      display_with_system_and_set: "AU 31 (Maternity)",
                      equivalent_sizes: {
                        us: ["12M", "31M"],
                        eu: ["31MEU", "44MEU"],
                        uk: ["16MUK", "31MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "14WM",
                          "14M",
                          "16WM",
                          "26WM",
                          "26M",
                          "28WM",
                          "28M",
                          "30WM",
                          "32M",
                        ],
                        eu: [
                          "32MEU",
                          "46MEU",
                          "48MEU",
                          "56MEU",
                          "58MEU",
                          "60MEU",
                        ],
                        uk: ["18MUK", "20MUK", "30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "33MAU",
                      short: "33 (Maternity)",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33 (Maternity)",
                      display_with_size_system: "AU 33",
                      display_with_system_and_set: "AU 33 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18WM", "33M"],
                        eu: ["33MEU", "48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK", "33MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "18WM",
                          "18M",
                          "20WM",
                          "28WM",
                          "28M",
                          "30WM",
                          "30M",
                          "32WM",
                          "34M",
                        ],
                        eu: [
                          "34MEU",
                          "48MEU",
                          "50MEU",
                          "58MEU",
                          "60MEU",
                          "62MEU",
                        ],
                        uk: ["20MUK", "22MUK", "32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2PlusMAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "24M"],
                        eu: ["24MEU", "30MEU"],
                        uk: ["2PlusMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PlusMAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "26M"],
                        eu: ["26MEU", "34MEU"],
                        uk: ["6PlusMAU"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PlusMAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "28M"],
                        eu: ["28MEU", "38MEU"],
                        uk: ["10PlusMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PlusMAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "30M"],
                        eu: ["30MEU", "42MEU"],
                        uk: ["14PlusMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PlusMAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM", "32M"],
                        eu: ["32MEU", "46MEU", "48MEU"],
                        uk: ["18PlusMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PlusMAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "20M", "20WM", "22WM", "24M"],
                        eu: ["24MEU", "30MEU", "50MEU", "52MEU"],
                        uk: ["24PlusMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26PlusMAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "20M", "20WM", "22M", "22WM", "24WM", "26M"],
                        eu: ["26MEU", "34MEU", "50MEU", "52MEU", "54MEU"],
                        uk: ["26PlusMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28PlusMAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "22M", "22WM", "24M", "24WM", "26WM", "28M"],
                        eu: ["28MEU", "38MEU", "52MEU", "54MEU", "56MEU"],
                        uk: ["28PlusMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30PlusMAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "24M", "24WM", "26M", "26WM", "28WM", "30M"],
                        eu: ["30MEU", "42MEU", "54MEU", "56MEU", "58MEU"],
                        uk: ["30PlusMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32PlusMAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "14WM",
                          "14M",
                          "16WM",
                          "26WM",
                          "26M",
                          "28WM",
                          "28M",
                          "30WM",
                          "32M",
                        ],
                        eu: [
                          "32MEU",
                          "46MEU",
                          "48MEU",
                          "56MEU",
                          "58MEU",
                          "60MEU",
                        ],
                        uk: ["32PlusMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "23MEU",
                      short: "23 (Maternity)",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23 (Maternity)",
                      display_with_size_system: "EU 23",
                      display_with_system_and_set: "EU 23 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "23M"],
                        au: ["0MAU", "23MAU"],
                        uk: ["0MUK", "23MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "24MEU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "EU 24",
                      display_with_system_and_set: "EU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "24M"],
                        au: ["2MAU", "24MAU"],
                        uk: ["2MUK", "24MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "25MEU",
                      short: "25 (Maternity)",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25 (Maternity)",
                      display_with_size_system: "EU 25",
                      display_with_system_and_set: "EU 25 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "25M"],
                        au: ["4MAU", "25MAU"],
                        uk: ["4MUK", "25MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "26MEU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "EU 26",
                      display_with_system_and_set: "EU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "26M"],
                        au: ["6MAU", "26MAU"],
                        uk: ["6MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "27MEU",
                      short: "27 (Maternity)",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27 (Maternity)",
                      display_with_size_system: "EU 27",
                      display_with_system_and_set: "EU 27 (Maternity)",
                      equivalent_sizes: {
                        us: ["4M", "27M"],
                        au: ["8MAU", "27MAU"],
                        uk: ["8MUK", "27MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "28MEU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "EU 28",
                      display_with_system_and_set: "EU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "6M", "23M", "28M"],
                        au: ["0MAU", "10MAU", "23MAU", "28MAU"],
                        uk: ["0MUK", "10MUK", "23MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "29MEU",
                      short: "29 (Maternity)",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29 (Maternity)",
                      display_with_size_system: "EU 29",
                      display_with_system_and_set: "EU 29 (Maternity)",
                      equivalent_sizes: {
                        us: ["8M", "29M"],
                        au: ["12MAU", "29MAU"],
                        uk: ["12MUK", "29MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "10M", "24M", "30M"],
                        au: ["2MAU", "14MAU", "24MAU", "30MAU"],
                        uk: ["2MUK", "14MUK", "24MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "31MEU",
                      short: "31 (Maternity)",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31 (Maternity)",
                      display_with_size_system: "EU 31",
                      display_with_system_and_set: "EU 31 (Maternity)",
                      equivalent_sizes: {
                        us: ["12M", "31M"],
                        au: ["16MAU", "31MAU"],
                        uk: ["16MUK", "31MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "14M", "14WM", "16WM", "25M", "32M"],
                        au: ["4MAU", "18MAU", "20MAU", "25MAU", "32MAU"],
                        uk: ["4MUK", "18MUK", "20MUK", "25MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "33MEU",
                      short: "33 (Maternity)",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33 (Maternity)",
                      display_with_size_system: "EU 33",
                      display_with_system_and_set: "EU 33 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18WM", "33M"],
                        au: ["20MAU", "22MAU", "33MAU"],
                        uk: ["20MUK", "22MUK", "33MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "18M", "18WM", "20WM", "26M", "34M"],
                        au: ["6MAU", "20MAU", "22MAU", "26MAU", "34MAU"],
                        uk: ["6MUK", "20MUK", "22MUK", "26MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["4M", "27M"],
                        au: ["8MAU", "27MAU"],
                        uk: ["8MUK", "27MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "28M"],
                        au: ["10MAU", "28MAU"],
                        uk: ["10MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["8M", "29M"],
                        au: ["12MAU", "29MAU"],
                        uk: ["12MUK", "29MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "30M"],
                        au: ["14MAU", "30MAU"],
                        uk: ["14MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["12M", "31M"],
                        au: ["16MAU", "31MAU"],
                        uk: ["16MUK", "31MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM", "32M"],
                        au: ["18MAU", "20MAU", "32MAU"],
                        uk: ["18MUK", "20MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "14M",
                          "14WM",
                          "16WM",
                          "16M",
                          "18WM",
                          "18M",
                          "20WM",
                          "32M",
                          "33M",
                          "34M",
                        ],
                        au: [
                          "18MAU",
                          "20MAU",
                          "22MAU",
                          "32MAU",
                          "33MAU",
                          "34MAU",
                        ],
                        uk: [
                          "18MUK",
                          "20MUK",
                          "22MUK",
                          "32MUK",
                          "33MUK",
                          "34MUK",
                        ],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "16M",
                          "16WM",
                          "18WM",
                          "18M",
                          "20M",
                          "20WM",
                          "22WM",
                          "33M",
                          "34M",
                        ],
                        au: [
                          "20MAU",
                          "22MAU",
                          "24MAU",
                          "26MAU",
                          "33MAU",
                          "34MAU",
                        ],
                        uk: [
                          "20MUK",
                          "22MUK",
                          "24MUK",
                          "26MUK",
                          "33MUK",
                          "34MUK",
                        ],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "24PlusMEU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "EU 24",
                      display_with_system_and_set: "EU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "24M"],
                        au: ["2MAU", "24MAU"],
                        uk: ["2MUK", "24MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "26PlusMEU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "EU 26",
                      display_with_system_and_set: "EU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "26M"],
                        au: ["6MAU", "26MAU"],
                        uk: ["6MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "28PlusMEU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "EU 28",
                      display_with_system_and_set: "EU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "6M", "23M", "28M"],
                        au: ["0MAU", "10MAU", "23MAU", "28MAU"],
                        uk: ["0MUK", "10MUK", "23MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "30PlusMEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "10M", "24M", "30M"],
                        au: ["2MAU", "14MAU", "24MAU", "30MAU"],
                        uk: ["2MUK", "14MUK", "24MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PlusMEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "14M", "14WM", "16WM", "25M", "32M"],
                        au: ["4MAU", "18MAU", "20MAU", "25MAU", "32MAU"],
                        uk: ["4MUK", "18MUK", "20MUK", "25MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PlusMEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "18M", "18WM", "20WM", "26M", "34M"],
                        au: ["6MAU", "20MAU", "22MAU", "26MAU", "34MAU"],
                        uk: ["6MUK", "20MUK", "22MUK", "26MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PlusMEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "28M"],
                        au: ["10MAU", "28MAU"],
                        uk: ["10MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PlusMEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "30M"],
                        au: ["14MAU", "30MAU"],
                        uk: ["14MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PlusMEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM", "32M"],
                        au: ["18MAU", "20MAU", "32MAU"],
                        uk: ["18MUK", "20MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PlusMUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "24M"],
                        au: ["2PlusMAU"],
                        eu: ["24MEU", "30MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PlusMUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "26M"],
                        au: ["6PlusMAU"],
                        eu: ["26MEU", "34MEU"],
                        uk: ["6PlusMUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PlusMUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "28M"],
                        au: ["10PlusMAU"],
                        eu: ["28MEU", "38MEU"],
                        uk: ["10PlusMUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PlusMUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "30M"],
                        au: ["14PlusMAU"],
                        eu: ["30MEU", "42MEU"],
                        uk: ["14PlusMUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PlusMUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM", "32M"],
                        au: ["18PlusMAU"],
                        eu: ["32MEU", "46MEU", "48MEU"],
                        uk: ["18PlusMUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PlusMUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "20M", "20WM", "22WM", "24M"],
                        au: ["24PlusMAU"],
                        eu: ["24MEU", "30MEU", "50MEU", "52MEU"],
                        uk: ["24PlusMUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26PlusMUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "20M", "20WM", "22M", "22WM", "24WM", "26M"],
                        au: ["26PlusMAU"],
                        eu: ["26MEU", "34MEU", "50MEU", "52MEU", "54MEU"],
                        uk: ["26PlusMUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28PlusMUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "22M", "22WM", "24M", "24WM", "26WM", "28M"],
                        au: ["28PlusMAU"],
                        eu: ["28MEU", "38MEU", "52MEU", "54MEU", "56MEU"],
                        uk: ["28PlusMUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30PlusMUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "24M", "24WM", "26M", "26WM", "28WM", "30M"],
                        au: ["30PlusMAU"],
                        eu: ["30MEU", "42MEU", "54MEU", "56MEU", "58MEU"],
                        uk: ["30PlusMUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32PlusMUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "14WM",
                          "14M",
                          "16WM",
                          "26WM",
                          "26M",
                          "28WM",
                          "28M",
                          "30WM",
                          "32M",
                        ],
                        au: ["32PlusMAU"],
                        eu: [
                          "32MEU",
                          "46MEU",
                          "48MEU",
                          "56MEU",
                          "58MEU",
                          "60MEU",
                        ],
                        uk: ["32PlusMUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0MUK",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "UK 0",
                      display_with_system_and_set: "UK 0 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "23M"],
                        au: ["0MAU", "23MAU"],
                        eu: ["23MEU", "28MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "24M"],
                        au: ["24MAU", "2MAU"],
                        eu: ["24MEU", "30MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "25M"],
                        au: ["25MAU", "4MAU"],
                        eu: ["25MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "26M"],
                        au: ["26MAU", "6MAU"],
                        eu: ["26MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["4M", "27M"],
                        au: ["27MAU", "8MAU"],
                        eu: ["27MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "28M"],
                        au: ["10MAU", "28MAU"],
                        eu: ["28MEU", "38MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["8M", "29M"],
                        au: ["12MAU", "29MAU"],
                        eu: ["29MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "30M"],
                        au: ["14MAU", "30MAU"],
                        eu: ["30MEU", "42MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["12M", "31M"],
                        au: ["16MAU", "31MAU"],
                        eu: ["31MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM", "32M"],
                        au: ["18MAU", "20MAU", "32MAU"],
                        eu: ["32MEU", "46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "14M",
                          "14WM",
                          "16WM",
                          "16M",
                          "18WM",
                          "18M",
                          "20WM",
                          "32M",
                          "33M",
                          "34M",
                        ],
                        au: [
                          "18MAU",
                          "20MAU",
                          "22MAU",
                          "32MAU",
                          "33MAU",
                          "34MAU",
                        ],
                        eu: [
                          "32MEU",
                          "33MEU",
                          "34MEU",
                          "46MEU",
                          "48MEU",
                          "50MEU",
                        ],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20WM", "33M", "34M"],
                        au: ["20MAU", "22MAU", "33MAU", "34MAU"],
                        eu: ["33MEU", "34MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "23MUK",
                      short: "23 (Maternity)",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23 (Maternity)",
                      display_with_size_system: "UK 23",
                      display_with_system_and_set: "UK 23 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "23M"],
                        au: ["0MAU", "23MAU"],
                        eu: ["23MEU", "28MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "20M", "20WM", "22WM", "24M"],
                        au: ["24MAU", "26MAU", "2MAU"],
                        eu: ["24MEU", "30MEU", "50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "25MUK",
                      short: "25 (Maternity)",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25 (Maternity)",
                      display_with_size_system: "UK 25",
                      display_with_system_and_set: "UK 25 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "25M"],
                        au: ["25MAU", "4MAU"],
                        eu: ["25MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "20M", "20WM", "22M", "22WM", "24WM", "26M"],
                        au: ["24MAU", "26MAU", "28MAU", "6MAU"],
                        eu: ["26MEU", "34MEU", "50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "27MUK",
                      short: "27 (Maternity)",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27 (Maternity)",
                      display_with_size_system: "UK 27",
                      display_with_system_and_set: "UK 27 (Maternity)",
                      equivalent_sizes: {
                        us: ["4M", "27M"],
                        au: ["27MAU", "8MAU"],
                        eu: ["27MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "22M", "22WM", "24M", "24WM", "26WM", "28M"],
                        au: ["10MAU", "26MAU", "28MAU", "30MAU"],
                        eu: ["28MEU", "38MEU", "52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "29MUK",
                      short: "29 (Maternity)",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29 (Maternity)",
                      display_with_size_system: "UK 29",
                      display_with_system_and_set: "UK 29 (Maternity)",
                      equivalent_sizes: {
                        us: ["8M", "29M"],
                        au: ["12MAU", "29MAU"],
                        eu: ["29MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "24M", "24WM", "26M", "26WM", "28WM", "30M"],
                        au: ["14MAU", "28MAU", "30MAU", "32MAU"],
                        eu: ["30MEU", "42MEU", "54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "31MUK",
                      short: "31 (Maternity)",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31 (Maternity)",
                      display_with_size_system: "UK 31",
                      display_with_system_and_set: "UK 31 (Maternity)",
                      equivalent_sizes: {
                        us: ["12M", "31M"],
                        au: ["16MAU", "31MAU"],
                        eu: ["31MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "14WM",
                          "14M",
                          "16WM",
                          "26WM",
                          "26M",
                          "28WM",
                          "28M",
                          "30WM",
                          "32M",
                        ],
                        au: ["18MAU", "20MAU", "30MAU", "32MAU", "34MAU"],
                        eu: [
                          "32MEU",
                          "46MEU",
                          "48MEU",
                          "56MEU",
                          "58MEU",
                          "60MEU",
                        ],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "33MUK",
                      short: "33 (Maternity)",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33 (Maternity)",
                      display_with_size_system: "UK 33",
                      display_with_system_and_set: "UK 33 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18WM", "33M"],
                        au: ["20MAU", "22MAU", "33MAU"],
                        eu: ["33MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "18WM",
                          "18M",
                          "20WM",
                          "28WM",
                          "28M",
                          "30WM",
                          "30M",
                          "32WM",
                          "34M",
                        ],
                        au: ["20MAU", "22MAU", "32MAU", "34MAU", "36MAU"],
                        eu: [
                          "34MEU",
                          "48MEU",
                          "50MEU",
                          "58MEU",
                          "60MEU",
                          "62MEU",
                        ],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          aliases: ["Pants"],
          message_id: "women_pants_jumpsuits",
          slug: "Pants_&_Jumpsuits",
          alias_slugs: ["Pants"],
          parent_id: "000e8975d97b4e80ef00a955",
          category_features: [
            {
              id: "00a69287d97b4e80ef00a955",
              display: "Ankle & Cropped",
              type: "cf",
              message_id: "women_pants_jumpsuits_ankle_cropped",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Ankle_&_Cropped",
              alias_slugs: [],
              parent_id: "001c8975d97b4e80ef00a955",
            },
            {
              id: "00a89287d97b4e80ef00a955",
              display: "Boot Cut & Flare",
              type: "cf",
              message_id: "women_pants_jumpsuits_boot_cut_flare",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Boot_Cut_&_Flare",
              alias_slugs: [],
              parent_id: "001c8975d97b4e80ef00a955",
            },
            {
              id: "00aa9287d97b4e80ef00a955",
              display: "Capris",
              type: "cf",
              message_id: "women_pants_jumpsuits_capris",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Capris",
              alias_slugs: [],
              parent_id: "001c8975d97b4e80ef00a955",
            },
            {
              id: "00ae9287d97b4e80ef00a955",
              display: "Jumpsuits & Rompers",
              type: "cf",
              message_id: "women_pants_jumpsuits_jumpsuits_rompers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Jumpsuits_&_Rompers",
              alias_slugs: [],
              parent_id: "001c8975d97b4e80ef00a955",
            },
            {
              id: "00b09287d97b4e80ef00a955",
              display: "Leggings",
              type: "cf",
              message_id: "women_pants_jumpsuits_leggings",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Leggings",
              alias_slugs: [],
              parent_id: "001c8975d97b4e80ef00a955",
            },
            {
              id: "827df9aaaabb083120f45ec2",
              display: "Pantsuits",
              type: "cf",
              message_id: "women_pants_jumpsuits_pantsuits",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Pantsuits",
              alias_slugs: [],
              parent_id: "001c8975d97b4e80ef00a955",
            },
            {
              id: "00b29287d97b4e80ef00a955",
              display: "Skinny",
              type: "cf",
              message_id: "women_pants_jumpsuits_skinny",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Skinny",
              alias_slugs: [],
              parent_id: "001c8975d97b4e80ef00a955",
            },
            {
              id: "00b49287d97b4e80ef00a955",
              display: "Straight Leg",
              type: "cf",
              message_id: "women_pants_jumpsuits_straight_leg",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Straight_Leg",
              alias_slugs: [],
              parent_id: "001c8975d97b4e80ef00a955",
            },
            {
              id: "00ac9287d97b4e80ef00a955",
              display: "Track Pants & Joggers",
              type: "cf",
              message_id: "women_pants_jumpsuits_track_pants_joggers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Track_Pants_&_Joggers",
              alias_slugs: [],
              parent_id: "001c8975d97b4e80ef00a955",
            },
            {
              id: "00b69287d97b4e80ef00a955",
              display: "Trousers",
              type: "cf",
              message_id: "women_pants_jumpsuits_trousers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Trousers",
              alias_slugs: [],
              parent_id: "001c8975d97b4e80ef00a955",
            },
            {
              id: "00b89287d97b4e80ef00a955",
              display: "Wide Leg",
              type: "cf",
              message_id: "women_pants_jumpsuits_wide_leg",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Wide_Leg",
              alias_slugs: [],
              parent_id: "001c8975d97b4e80ef00a955",
            },
          ],
        },
        {
          id: "001e8975d97b4e80ef00a955",
          display: "Shorts",
          size_sets: [
            {
              id: "1c00579fd97b4e26a60056e3",
              name: "Standard",
              tags: ["standard"],
              category_id: "001e8975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00,23", "0,23", "23,28", "0"],
                  ["00,24", "24,2", "24,30", "2"],
                  ["0,25", "25,4", "25,32", "4"],
                  ["2,26", "26,6", "26,34", "6"],
                  ["4,27", "27,8", "27,36", "8"],
                  ["6,28", "10,28", "28,38", "10"],
                  ["8,29", "12,29", "29,40", "12"],
                  ["10,30", "14,30", "30,42", "14"],
                  ["12,31", "16,31", "31,44", "16"],
                  ["00,23", "0,23", "23,28", "23"],
                  ["00,24", "24,2", "24,30", "24"],
                  ["0,25", "25,4", "25,32", "25"],
                  ["2,26", "26,6", "26,34", "26"],
                  ["4,27", "27,8", "27,36", "27"],
                  ["6,28", "10,28", "28,38", "28"],
                  ["8,29", "12,29", "29,40", "29"],
                  ["10,30", "14,30", "30,42", "30"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["23", "0,23", "23,28", "0,23"],
                ["24", "2,24", "24,30", "2,24"],
                ["25", "4,25", "25,32", "4,25"],
                ["26", "6,26", "26,34", "6,26"],
                ["27", "8,27", "27,36", "8,27"],
                ["28", "10,28", "28,38", "10,28"],
                ["29", "12,29", "29,40", "12,29"],
                ["30", "14,30", "30,42", "14,30"],
                ["31", "16,31", "31,44", "16,31"],
                ["00", "0,2,23,24", "23,24,28,30", "0,2,23,24"],
                ["0", "4,25", "25,32", "4,25"],
                ["2", "6,26", "26,34", "6,26"],
                ["4", "8,27", "27,36", "8,27"],
                ["6", "10,28", "28,38", "10,28"],
                ["8", "12,29", "29,40", "12,29"],
                ["10", "14,30", "30,42", "14,30"],
                ["12", "16,31", "31,44", "16,31"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "23",
                      short: "23",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23",
                      display_with_size_system: "US 23",
                      display_with_system_and_set: "US 23",
                      equivalent_sizes: {
                        au: ["0AU", "23AU"],
                        eu: ["23EU", "28EU"],
                        uk: ["0UK", "23UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["2AU", "24AU"],
                        eu: ["24EU", "30EU"],
                        uk: ["2UK", "24UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "25",
                      short: "25",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25",
                      display_with_size_system: "US 25",
                      display_with_system_and_set: "US 25",
                      equivalent_sizes: {
                        au: ["4AU", "25AU"],
                        eu: ["25EU", "32EU"],
                        uk: ["4UK", "25UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["6AU", "26AU"],
                        eu: ["26EU", "34EU"],
                        uk: ["6UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "27",
                      short: "27",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27",
                      display_with_size_system: "US 27",
                      display_with_system_and_set: "US 27",
                      equivalent_sizes: {
                        au: ["8AU", "27AU"],
                        eu: ["27EU", "36EU"],
                        uk: ["8UK", "27UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["10AU", "28AU"],
                        eu: ["28EU", "38EU"],
                        uk: ["10UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "29",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "US 29",
                      display_with_system_and_set: "US 29",
                      equivalent_sizes: {
                        au: ["12AU", "29AU"],
                        eu: ["29EU", "40EU"],
                        uk: ["12UK", "29UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["14AU", "30AU"],
                        eu: ["30EU", "42EU"],
                        uk: ["14UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "31",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "US 31",
                      display_with_system_and_set: "US 31",
                      equivalent_sizes: {
                        au: ["16AU", "31AU"],
                        eu: ["31EU", "44EU"],
                        uk: ["16UK", "31UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["0AU", "2AU", "23AU", "24AU"],
                        eu: ["23EU", "24EU", "28EU", "30EU"],
                        uk: ["0UK", "2UK", "23UK", "24UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "25AU"],
                        eu: ["25EU", "32EU"],
                        uk: ["4UK", "25UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "26AU"],
                        eu: ["26EU", "34EU"],
                        uk: ["6UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["8AU", "27AU"],
                        eu: ["27EU", "36EU"],
                        uk: ["8UK", "27UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "28AU"],
                        eu: ["28EU", "38EU"],
                        uk: ["10UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["12AU", "29AU"],
                        eu: ["29EU", "40EU"],
                        uk: ["12UK", "29UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "30AU"],
                        eu: ["30EU", "42EU"],
                        uk: ["14UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["16AU", "31AU"],
                        eu: ["31EU", "44EU"],
                        uk: ["16UK", "31UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "0AU",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "AU 0",
                      display_with_system_and_set: "AU 0",
                      equivalent_sizes: {
                        us: ["00", "23"],
                        eu: ["23EU", "28EU"],
                        uk: ["0UK", "23UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["00", "24"],
                        eu: ["24EU", "30EU"],
                        uk: ["24UK", "2UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["0", "25"],
                        eu: ["25EU", "32EU"],
                        uk: ["25UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["2", "26"],
                        eu: ["26EU", "34EU"],
                        uk: ["26UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["4", "27"],
                        eu: ["27EU", "36EU"],
                        uk: ["27UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "28"],
                        eu: ["28EU", "38EU"],
                        uk: ["10UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["8", "29"],
                        eu: ["29EU", "40EU"],
                        uk: ["12UK", "29UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "30"],
                        eu: ["30EU", "42EU"],
                        uk: ["14UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["12", "31"],
                        eu: ["31EU", "44EU"],
                        uk: ["16UK", "31UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "23AU",
                      short: "23",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23",
                      display_with_size_system: "AU 23",
                      display_with_system_and_set: "AU 23",
                      equivalent_sizes: {
                        us: ["00", "23"],
                        eu: ["23EU", "28EU"],
                        uk: ["0UK", "23UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["00", "24"],
                        eu: ["24EU", "30EU"],
                        uk: ["24UK", "2UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "25AU",
                      short: "25",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25",
                      display_with_size_system: "AU 25",
                      display_with_system_and_set: "AU 25",
                      equivalent_sizes: {
                        us: ["0", "25"],
                        eu: ["25EU", "32EU"],
                        uk: ["25UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["2", "26"],
                        eu: ["26EU", "34EU"],
                        uk: ["26UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "27AU",
                      short: "27",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27",
                      display_with_size_system: "AU 27",
                      display_with_system_and_set: "AU 27",
                      equivalent_sizes: {
                        us: ["4", "27"],
                        eu: ["27EU", "36EU"],
                        uk: ["27UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["6", "28"],
                        eu: ["28EU", "38EU"],
                        uk: ["10UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "29AU",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "AU 29",
                      display_with_system_and_set: "AU 29",
                      equivalent_sizes: {
                        us: ["8", "29"],
                        eu: ["29EU", "40EU"],
                        uk: ["12UK", "29UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["10", "30"],
                        eu: ["30EU", "42EU"],
                        uk: ["14UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "31AU",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "AU 31",
                      display_with_system_and_set: "AU 31",
                      equivalent_sizes: {
                        us: ["12", "31"],
                        eu: ["31EU", "44EU"],
                        uk: ["16UK", "31UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "23EU",
                      short: "23",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23",
                      display_with_size_system: "EU 23",
                      display_with_system_and_set: "EU 23",
                      equivalent_sizes: {
                        us: ["00", "23"],
                        au: ["0AU", "23AU"],
                        uk: ["0UK", "23UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "24EU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "EU 24",
                      display_with_system_and_set: "EU 24",
                      equivalent_sizes: {
                        us: ["00", "24"],
                        au: ["2AU", "24AU"],
                        uk: ["2UK", "24UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "25EU",
                      short: "25",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25",
                      display_with_size_system: "EU 25",
                      display_with_system_and_set: "EU 25",
                      equivalent_sizes: {
                        us: ["0", "25"],
                        au: ["4AU", "25AU"],
                        uk: ["4UK", "25UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "26EU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "EU 26",
                      display_with_system_and_set: "EU 26",
                      equivalent_sizes: {
                        us: ["2", "26"],
                        au: ["6AU", "26AU"],
                        uk: ["6UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "27EU",
                      short: "27",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27",
                      display_with_size_system: "EU 27",
                      display_with_system_and_set: "EU 27",
                      equivalent_sizes: {
                        us: ["4", "27"],
                        au: ["8AU", "27AU"],
                        uk: ["8UK", "27UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "28EU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "EU 28",
                      display_with_system_and_set: "EU 28",
                      equivalent_sizes: {
                        us: ["00", "6", "23", "28"],
                        au: ["0AU", "10AU", "23AU", "28AU"],
                        uk: ["0UK", "10UK", "23UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "29EU",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "EU 29",
                      display_with_system_and_set: "EU 29",
                      equivalent_sizes: {
                        us: ["8", "29"],
                        au: ["12AU", "29AU"],
                        uk: ["12UK", "29UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["00", "10", "24", "30"],
                        au: ["2AU", "14AU", "24AU", "30AU"],
                        uk: ["2UK", "14UK", "24UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "31EU",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "EU 31",
                      display_with_system_and_set: "EU 31",
                      equivalent_sizes: {
                        us: ["12", "31"],
                        au: ["16AU", "31AU"],
                        uk: ["16UK", "31UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["0", "25"],
                        au: ["4AU", "25AU"],
                        uk: ["4UK", "25UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["2", "26"],
                        au: ["6AU", "26AU"],
                        uk: ["6UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["4", "27"],
                        au: ["8AU", "27AU"],
                        uk: ["8UK", "27UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "28"],
                        au: ["10AU", "28AU"],
                        uk: ["10UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["8", "29"],
                        au: ["12AU", "29AU"],
                        uk: ["12UK", "29UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "30"],
                        au: ["14AU", "30AU"],
                        uk: ["14UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["12", "31"],
                        au: ["16AU", "31AU"],
                        uk: ["16UK", "31UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "0UK",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "UK 0",
                      display_with_system_and_set: "UK 0",
                      equivalent_sizes: {
                        us: ["00", "23"],
                        au: ["0AU", "23AU"],
                        eu: ["23EU", "28EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["00", "24"],
                        au: ["24AU", "2AU"],
                        eu: ["24EU", "30EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["0", "25"],
                        au: ["25AU", "4AU"],
                        eu: ["25EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["2", "26"],
                        au: ["26AU", "6AU"],
                        eu: ["26EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["4", "27"],
                        au: ["27AU", "8AU"],
                        eu: ["27EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "28"],
                        au: ["10AU", "28AU"],
                        eu: ["28EU", "38EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["8", "29"],
                        au: ["12AU", "29AU"],
                        eu: ["29EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "30"],
                        au: ["14AU", "30AU"],
                        eu: ["30EU", "42EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["12", "31"],
                        au: ["16AU", "31AU"],
                        eu: ["31EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "23UK",
                      short: "23",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23",
                      display_with_size_system: "UK 23",
                      display_with_system_and_set: "UK 23",
                      equivalent_sizes: {
                        us: ["00", "23"],
                        au: ["0AU", "23AU"],
                        eu: ["23EU", "28EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["00", "24"],
                        au: ["24AU", "2AU"],
                        eu: ["24EU", "30EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "25UK",
                      short: "25",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25",
                      display_with_size_system: "UK 25",
                      display_with_system_and_set: "UK 25",
                      equivalent_sizes: {
                        us: ["0", "25"],
                        au: ["25AU", "4AU"],
                        eu: ["25EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["2", "26"],
                        au: ["26AU", "6AU"],
                        eu: ["26EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "27UK",
                      short: "27",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27",
                      display_with_size_system: "UK 27",
                      display_with_system_and_set: "UK 27",
                      equivalent_sizes: {
                        us: ["4", "27"],
                        au: ["27AU", "8AU"],
                        eu: ["27EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["6", "28"],
                        au: ["10AU", "28AU"],
                        eu: ["28EU", "38EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "29UK",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "UK 29",
                      display_with_system_and_set: "UK 29",
                      equivalent_sizes: {
                        us: ["8", "29"],
                        au: ["12AU", "29AU"],
                        eu: ["29EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["10", "30"],
                        au: ["14AU", "30AU"],
                        eu: ["30EU", "42EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "31UK",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "UK 31",
                      display_with_system_and_set: "UK 31",
                      equivalent_sizes: {
                        us: ["12", "31"],
                        au: ["16AU", "31AU"],
                        eu: ["31EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "0f00579fd97b4e26a60056e3",
              name: "Plus",
              tags: ["plus"],
              category_id: "001e8975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W,32", "18,20,32,33", "32,46,48", "18"],
                  [
                    "14,14W,16W,16,18W,18,20W,32,33,34",
                    "18,20,22,33,34",
                    "32,33,34,46,48,50",
                    "20",
                  ],
                  ["16,16W,18,18W,20W,33,34", "20,22,34", "33,34,48,50", "22"],
                  ["20,20W,22W", "24", "50,52", "24"],
                  ["20,20W,22,22W,24W", "26,36", "50,52,54", "26"],
                  ["22,22W,24W,24W,26W", "28", "52,54,56", "28"],
                  ["24W,24W,26W,26W,28W", "30,32", "54,56,58", "30"],
                  [
                    "14W,14,16W,26W,26W,28W,28W,30W,32",
                    "18,30,32,34",
                    "32,46,48,56,58,60",
                    "32",
                  ],
                  [
                    "18W,18,20W,28W,28W,30W,30W,32W,34",
                    "20,22,32,34",
                    "34,48,50,58,60,62",
                    "34",
                  ],
                  ["30W,30W,32,32W", "26,36", "60,62", "36"],
                  [
                    "14W,14,16W,26W,26W,28W,28W,30W,32",
                    "18,30,32,34",
                    "32,46,48,56,58,60",
                    "32",
                  ],
                  ["16,16W,18W,33", "18,20,33", "33,48,50", "33"],
                  [
                    "18W,18,20W,28W,28W,30W,30W,32W,34",
                    "20,22,32,34",
                    "34,48,50,58,60,62",
                    "34",
                  ],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["32", "18,20,32,36,38", "32,46,48,62", "18,20,32,36,38"],
                ["33", "20,22,33", "33,48,50", "20,22,33"],
                ["34", "20,22,34", "34,48,50", "20,22,34"],
                ["14", "18,20,32", "32,46,48", "18,20,32"],
                ["14W", "18,20,32", "32,46,48", "18,20,32"],
                ["16", "20,22,33", "33,48,50", "20,22,33"],
                ["16W", "18,20,22,32,33", "32,33,46,48,50", "18,20,22,32,33"],
                ["18", "20,22,34", "34,48,50", "20,22,34"],
                ["18W", "20,22,33,34", "33,34,48,50", "20,22,33,34"],
                ["20", "24,26W", "50,52", "24,26W"],
                ["20W", "20,22,24,26W,34", "34,48,50,52", "20,22,24,26W,34"],
                ["22", "26W,28W", "52,54", "26W,28W"],
                ["22W", "24,26W,28W", "50,52,54", "24,26W,28W"],
                ["24", "28W,30W", "54,56", "28W,30W"],
                ["24W", "26W,28W,30W", "52,54,56", "26W,28W,30W"],
                ["26", "30W,32", "56,58", "30W,32"],
                ["26W", "28W,30W,32", "54,56,58", "28W,30W,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30W,32,34", "56,58,60", "30W,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "18,20,32,36,38", "32,46,48,62", "18,20,32,36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "32AU", "36AU", "38AU"],
                        eu: ["32PlusEU", "46EU", "48EU", "62EU"],
                        uk: ["18UK", "20UK", "32UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "33",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "US 33",
                      display_with_system_and_set: "US 33",
                      equivalent_sizes: {
                        au: ["20AU", "22AU", "33AU"],
                        eu: ["33EU", "48EU", "50EU"],
                        uk: ["20UK", "22UK", "33UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "US 34",
                      display_with_system_and_set: "US 34",
                      equivalent_sizes: {
                        au: ["20AU", "22AU", "34AU"],
                        eu: ["34", "48EU", "50EU"],
                        uk: ["20UK", "22UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "32AU"],
                        eu: ["32PlusEU", "46EU", "48EU"],
                        uk: ["18UK", "20UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "32AU"],
                        eu: ["32PlusEU", "46EU", "48EU"],
                        uk: ["18UK", "20UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU", "33AU"],
                        eu: ["33EU", "48EU", "50EU"],
                        uk: ["20UK", "22UK", "33UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU", "32AU", "33AU"],
                        eu: ["32PlusEU", "33EU", "46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK", "32UK", "33UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU", "34AU"],
                        eu: ["34", "48EU", "50EU"],
                        uk: ["20UK", "22UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU", "33AU", "34AU"],
                        eu: ["33EU", "34", "48EU", "50EU"],
                        uk: ["20UK", "22UK", "33UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24PlusAU", "26W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24PlusUK", "26W"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU", "24PlusAU", "26W", "34AU"],
                        eu: ["34", "48EU", "50EU", "52EU"],
                        uk: ["20UK", "22UK", "24PlusUK", "26W", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26W", "28W"],
                        eu: ["52EU", "54EU"],
                        uk: ["26W", "28W"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24PlusAU", "26W", "28W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24PlusUK", "26W", "28W"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24Plus",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28W", "30W"],
                        eu: ["54EU", "56EU"],
                        uk: ["28W", "30W"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26W", "28W", "30W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26W", "28W", "30W"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26Plus",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30W", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30W", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28W", "30W", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28W", "30W", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28Plus",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30W", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30W", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30Plus",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32Plus",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "32AU", "36AU", "38AU"],
                        eu: ["32PlusEU", "46EU", "48EU", "62EU"],
                        uk: ["18UK", "20UK", "32UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W", "32"],
                        eu: ["32PlusEU", "46EU", "48EU"],
                        uk: ["18UK", "20UK", "32UK", "33UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: [
                          "14",
                          "14W",
                          "16W",
                          "16",
                          "18W",
                          "18",
                          "20W",
                          "32",
                          "33",
                          "34",
                        ],
                        eu: ["32PlusEU", "33EU", "34", "46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK", "33UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20W", "33", "34"],
                        eu: ["33EU", "34", "48EU", "50EU"],
                        uk: ["20UK", "22UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PlusAU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24PlusUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26PlusAU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["26PlusUK", "36UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28PlusAU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24W", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["28PlusUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30PlusAU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24W", "24W", "26W", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["30PlusUK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: [
                          "14W",
                          "14",
                          "16W",
                          "26W",
                          "26W",
                          "28W",
                          "28W",
                          "30W",
                          "32",
                        ],
                        eu: ["32PlusEU", "46EU", "48EU", "56EU", "58EU", "60EU"],
                        uk: ["18UK", "30PlusUK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "33AU",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "AU 33",
                      display_with_system_and_set: "AU 33",
                      equivalent_sizes: {
                        us: ["16", "16W", "18W", "33"],
                        eu: ["33EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "33UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: [
                          "18W",
                          "18",
                          "20W",
                          "28W",
                          "28W",
                          "30W",
                          "30W",
                          "32W",
                          "34",
                        ],
                        eu: ["34", "48EU", "50EU", "58EU", "60EU", "62EU"],
                        uk: ["20UK", "22UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30W", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["26PlusUK", "36UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "32PlusEU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W", "32"],
                        au: ["18AU", "20AU", "32AU"],
                        uk: ["18UK", "20UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "33EU",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "EU 33",
                      display_with_system_and_set: "EU 33",
                      equivalent_sizes: {
                        us: ["16", "16W", "18W", "33"],
                        au: ["20AU", "22AU", "33AU"],
                        uk: ["20UK", "22UK", "33UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PlusEU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["18", "18W", "20W", "34"],
                        au: ["20AU", "22AU", "34AU"],
                        uk: ["20UK", "22UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W", "32"],
                        au: ["18AU", "20AU", "32AU"],
                        uk: ["18UK", "20UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: [
                          "14",
                          "14W",
                          "16W",
                          "16",
                          "18W",
                          "18",
                          "20W",
                          "32",
                          "33",
                          "34",
                        ],
                        au: ["18AU", "20AU", "22AU", "32AU", "33AU", "34AU"],
                        uk: ["18UK", "20UK", "22UK", "32UK", "33UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: [
                          "16",
                          "16W",
                          "18W",
                          "18",
                          "20",
                          "20W",
                          "22W",
                          "33",
                          "34",
                        ],
                        au: ["20AU", "22AU", "24PlusAU", "26W", "33AU", "34AU"],
                        uk: ["20UK", "22UK", "24PlusUK", "26W", "33UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24PlusAU", "26W", "28W"],
                        uk: ["24PlusUK", "26W", "28W"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24W", "24W", "26W"],
                        au: ["26W", "28W", "30W"],
                        uk: ["26W", "28W", "30W"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24W", "24W", "26W", "26W", "28W"],
                        au: ["28W", "30W", "32AU"],
                        uk: ["28W", "30W", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26W", "26W", "28W", "28W", "30W"],
                        au: ["30W", "32AU", "34AU"],
                        uk: ["30W", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28W", "28W", "30W", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30W", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W", "32"],
                        au: ["18AU", "20AU", "32AU", "33AU"],
                        eu: ["32PlusEU", "46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: [
                          "14",
                          "14W",
                          "16W",
                          "16",
                          "18W",
                          "18",
                          "20W",
                          "32",
                          "33",
                          "34",
                        ],
                        au: ["18AU", "20AU", "22AU", "33AU", "34AU"],
                        eu: ["32PlusEU", "33EU", "34", "46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20W", "33", "34"],
                        au: ["20AU", "22AU", "34AU"],
                        eu: ["33EU", "34", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PlusUK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24PlusAU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26PlusUK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["26PlusAU", "36AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28PlusUK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24W", "24W", "26W"],
                        au: ["28PlusAU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30PlusUK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24W", "24W", "26W", "26W", "28W"],
                        au: ["30PlusAU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: [
                          "14W",
                          "14",
                          "16W",
                          "26W",
                          "26W",
                          "28W",
                          "28W",
                          "30W",
                          "32",
                        ],
                        au: ["18AU", "30PlusAU", "32AU", "34AU"],
                        eu: ["32PlusEU", "46EU", "48EU", "56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "33UK",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "UK 33",
                      display_with_system_and_set: "UK 33",
                      equivalent_sizes: {
                        us: ["16", "16W", "18W", "33"],
                        au: ["18AU", "20AU", "33AU"],
                        eu: ["33EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: [
                          "18W",
                          "18",
                          "20W",
                          "28W",
                          "28W",
                          "30W",
                          "30W",
                          "32W",
                          "34",
                        ],
                        au: ["20AU", "22AU", "32AU", "34AU"],
                        eu: ["34", "48EU", "50EU", "58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30W", "30W", "32", "32W"],
                        au: ["26PlusAU", "36AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "2700579fd97b4e26a60056e3",
              name: "Petite",
              tags: ["petite"],
              category_id: "001e8975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["28-29", "21-22", "30-31"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["28-29", "21-22", "30-31"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["71.1-73.7", "53.3-55.9", "76.2-78.7"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["71.1-73.7", "53.3-55.9", "76.2-78.7"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P,23P", "0P,23P", "23P,28P", "0P"],
                  ["00P,24P", "24P,2P", "24P,30P", "2P"],
                  ["0P,25P", "25P,4P", "25P,32P", "4P"],
                  ["2P,26P", "26P,6P", "26P,34P", "6P"],
                  ["4P,27P", "27P,8P", "27P,36P", "8P"],
                  ["6P,28P", "10P,28P", "28P,38P", "10P"],
                  ["8P,29P", "12P,29P", "29P,40P", "12P"],
                  ["10P,30P", "14P,30P", "30P,42P", "14P"],
                  ["12P,31P", "16P,31P", "31P,44P", "16P"],
                  ["14P,32P", "18P,32P", "32P,46P", "18P"],
                  ["16P,33P", "20P,33P", "33P,48P", "20P"],
                  ["18P,34P", "22P,34P", "34P,50P", "22P"],
                  ["00P,23P", "0P,23P", "23P,28P", "23P"],
                  ["00P,24P", "24P,2P", "24P,30P", "24P"],
                  ["0P,25P", "25P,4P", "25P,32P", "25P"],
                  ["2P,26P", "26P,6P", "26P,34P", "26P"],
                  ["4P,27P", "27P,8P", "27P,36P", "27P"],
                  ["6P,28P", "10P,28P", "28P,38P", "28P"],
                  ["8P,29P", "12P,29P", "29P,40P", "29P"],
                  ["10P,30P", "14P,30P", "30P,42P", "30P"],
                  ["12P,31P", "16P,31P", "31P,44P", "31P"],
                  ["14P,32P", "18P,32P", "32P,46P", "32P"],
                  ["16P,33P", "20P,33P", "33P,48P", "33P"],
                  ["18P,34P", "22P,34P", "34P,50P", "34P"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["23P", "0P,23P", "23P,28P", "0P,23P"],
                ["24P", "2P,24P", "24P,30P", "2P,24P"],
                ["25P", "4P,25P", "25P,32P", "4P,25P"],
                ["26P", "6P,26P", "26P,34P", "6P,26P"],
                ["27P", "8P,27P", "27P,36P", "8P,27P"],
                ["28P", "10P,28P", "28P,38P", "10P,28P"],
                ["29P", "12P,29P", "29P,40P", "12P,29P"],
                ["30P", "14P,30P", "30P,42P", "14P,30P"],
                ["31P", "16P,31P", "31P,44P", "16P,31P"],
                ["32P", "18P,32P", "32P,46P", "18P,32P"],
                ["33P", "20P,33P", "33P,48P", "20P,33P"],
                ["34P", "22P,34P", "34P,50P", "22P,34P"],
                ["00P", "0P,2P,23P,24P", "23P,24P,28P,30P", "0P,2P,23P,24P"],
                ["0P", "4P,25P", "25P,32P", "4P,25P"],
                ["2P", "6P,26P", "26P,34P", "6P,26P"],
                ["4P", "8P,27P", "27P,36P", "8P,27P"],
                ["6P", "10P,28P", "28P,38P", "10P,28P"],
                ["8P", "12P,29P", "29P,40P", "12P,29P"],
                ["10P", "14P,30P", "30P,42P", "14P,30P"],
                ["12P", "16P,31P", "31P,44P", "16P,31P"],
                ["14P", "18P,32P", "32P,46P", "18P,32P"],
                ["16P", "20P,33P", "33P,48P", "20P,33P"],
                ["18P", "22P,34P", "34P,50P", "22P,34P"],
                ["20P", "-", "-", "-"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "23P",
                      short: "23P",
                      long: "23P",
                      display: "23P",
                      display_with_size_set: "23P",
                      display_with_size_system: "US 23P",
                      display_with_system_and_set: "US 23P",
                      equivalent_sizes: {
                        au: ["0PAU", "23PAU"],
                        eu: ["23PEU", "28PEU"],
                        uk: ["0PUK", "23PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24P",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "US 24P",
                      display_with_system_and_set: "US 24P",
                      equivalent_sizes: {
                        au: ["2PAU", "24PAU"],
                        eu: ["24PEU", "30PEU"],
                        uk: ["2PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "25P",
                      short: "25P",
                      long: "25P",
                      display: "25P",
                      display_with_size_set: "25P",
                      display_with_size_system: "US 25P",
                      display_with_system_and_set: "US 25P",
                      equivalent_sizes: {
                        au: ["4PAU", "25PAU"],
                        eu: ["25PEU", "32PEU"],
                        uk: ["4PUK", "25PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26P",
                      short: "26P",
                      long: "26P",
                      display: "26P",
                      display_with_size_set: "26P",
                      display_with_size_system: "US 26P",
                      display_with_system_and_set: "US 26P",
                      equivalent_sizes: {
                        au: ["6PAU", "26PAU"],
                        eu: ["26PEU", "34PEU"],
                        uk: ["6PUK", "26PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "27P",
                      short: "27P",
                      long: "27P",
                      display: "27P",
                      display_with_size_set: "27P",
                      display_with_size_system: "US 27P",
                      display_with_system_and_set: "US 27P",
                      equivalent_sizes: {
                        au: ["8PAU", "27PAU"],
                        eu: ["27PEU", "36PEU"],
                        uk: ["8PUK", "27PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28P",
                      short: "28P",
                      long: "28P",
                      display: "28P",
                      display_with_size_set: "28P",
                      display_with_size_system: "US 28P",
                      display_with_system_and_set: "US 28P",
                      equivalent_sizes: {
                        au: ["10PAU", "28PAU"],
                        eu: ["28PEU", "38PEU"],
                        uk: ["10PUK", "28PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "29P",
                      short: "29P",
                      long: "29P",
                      display: "29P",
                      display_with_size_set: "29P",
                      display_with_size_system: "US 29P",
                      display_with_system_and_set: "US 29P",
                      equivalent_sizes: {
                        au: ["12PAU", "29PAU"],
                        eu: ["29PEU", "40PEU"],
                        uk: ["12PUK", "29PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30P",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "US 30P",
                      display_with_system_and_set: "US 30P",
                      equivalent_sizes: {
                        au: ["14PAU", "30PAU"],
                        eu: ["30PEU", "42PEU"],
                        uk: ["14PUK", "30PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "31P",
                      short: "31P",
                      long: "31P",
                      display: "31P",
                      display_with_size_set: "31P",
                      display_with_size_system: "US 31P",
                      display_with_system_and_set: "US 31P",
                      equivalent_sizes: {
                        au: ["16PAU", "31PAU"],
                        eu: ["31PEU", "44PEU"],
                        uk: ["16PUK", "31PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32P",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "US 32P",
                      display_with_system_and_set: "US 32P",
                      equivalent_sizes: {
                        au: ["18PAU", "32PAU"],
                        eu: ["32PEU", "46PEU"],
                        uk: ["18PUK", "32PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "33P",
                      short: "33P",
                      long: "33P",
                      display: "33P",
                      display_with_size_set: "33P",
                      display_with_size_system: "US 33P",
                      display_with_system_and_set: "US 33P",
                      equivalent_sizes: {
                        au: ["20PAU", "33PAU"],
                        eu: ["33PEU", "48PEU"],
                        uk: ["20PUK", "33PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34P",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "US 34P",
                      display_with_system_and_set: "US 34P",
                      equivalent_sizes: {
                        au: ["22PAU", "34PAU"],
                        eu: ["34PEU", "50PEU"],
                        uk: ["22PUK", "34PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["0PAU", "2PAU", "23PAU", "24PAU"],
                        eu: ["23PEU", "24PEU", "28PEU", "30PEU"],
                        uk: ["0PUK", "2PUK", "23PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "25PAU"],
                        eu: ["25PEU", "32PEU"],
                        uk: ["4PUK", "25PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "26PAU"],
                        eu: ["26PEU", "34PEU"],
                        uk: ["6PUK", "26PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "27PAU"],
                        eu: ["27PEU", "36PEU"],
                        uk: ["8PUK", "27PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "28PAU"],
                        eu: ["28PEU", "38PEU"],
                        uk: ["10PUK", "28PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "29PAU"],
                        eu: ["29PEU", "40PEU"],
                        uk: ["12PUK", "29PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "30PAU"],
                        eu: ["30PEU", "42PEU"],
                        uk: ["14PUK", "30PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "31PAU"],
                        eu: ["31PEU", "44PEU"],
                        uk: ["16PUK", "31PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "32PAU"],
                        eu: ["32PEU", "46PEU"],
                        uk: ["18PUK", "32PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "33PAU"],
                        eu: ["33PEU", "48PEU"],
                        uk: ["20PUK", "33PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "34PAU"],
                        eu: ["34PEU", "50PEU"],
                        uk: ["22PUK", "34PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {},
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "0PAU",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "AU 0P",
                      display_with_system_and_set: "AU 0P",
                      equivalent_sizes: {
                        us: ["00P", "23P"],
                        eu: ["23PEU", "28PEU"],
                        uk: ["0PUK", "23PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P", "24P"],
                        eu: ["24PEU", "30PEU"],
                        uk: ["24PUK", "2PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["0P", "25P"],
                        eu: ["25PEU", "32PEU"],
                        uk: ["25PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["2P", "26P"],
                        eu: ["26PEU", "34PEU"],
                        uk: ["26PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["4P", "27P"],
                        eu: ["27PEU", "36PEU"],
                        uk: ["27PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["6P", "28P"],
                        eu: ["28PEU", "38PEU"],
                        uk: ["10PUK", "28PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["8P", "29P"],
                        eu: ["29PEU", "40PEU"],
                        uk: ["12PUK", "29PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["10P", "30P"],
                        eu: ["30PEU", "42PEU"],
                        uk: ["14PUK", "30PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["12P", "31P"],
                        eu: ["31PEU", "44PEU"],
                        uk: ["16PUK", "31PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["14P", "32P"],
                        eu: ["32PEU", "46PEU"],
                        uk: ["18PUK", "32PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["16P", "33P"],
                        eu: ["33PEU", "48PEU"],
                        uk: ["20PUK", "33PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["18P", "34P"],
                        eu: ["34PEU", "50PEU"],
                        uk: ["22PUK", "34PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "23PAU",
                      short: "23P",
                      long: "23P",
                      display: "23P",
                      display_with_size_set: "23P",
                      display_with_size_system: "AU 23P",
                      display_with_system_and_set: "AU 23P",
                      equivalent_sizes: {
                        us: ["00P", "23P"],
                        eu: ["23PEU", "28PEU"],
                        uk: ["0PUK", "23PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["00P", "24P"],
                        eu: ["24PEU", "30PEU"],
                        uk: ["24PUK", "2PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "25PAU",
                      short: "25P",
                      long: "25P",
                      display: "25P",
                      display_with_size_set: "25P",
                      display_with_size_system: "AU 25P",
                      display_with_system_and_set: "AU 25P",
                      equivalent_sizes: {
                        us: ["0P", "25P"],
                        eu: ["25PEU", "32PEU"],
                        uk: ["25PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26PAU",
                      short: "26P",
                      long: "26P",
                      display: "26P",
                      display_with_size_set: "26P",
                      display_with_size_system: "AU 26P",
                      display_with_system_and_set: "AU 26P",
                      equivalent_sizes: {
                        us: ["2P", "26P"],
                        eu: ["26PEU", "34PEU"],
                        uk: ["26PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "27PAU",
                      short: "27P",
                      long: "27P",
                      display: "27P",
                      display_with_size_set: "27P",
                      display_with_size_system: "AU 27P",
                      display_with_system_and_set: "AU 27P",
                      equivalent_sizes: {
                        us: ["4P", "27P"],
                        eu: ["27PEU", "36PEU"],
                        uk: ["27PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28PAU",
                      short: "28P",
                      long: "28P",
                      display: "28P",
                      display_with_size_set: "28P",
                      display_with_size_system: "AU 28P",
                      display_with_system_and_set: "AU 28P",
                      equivalent_sizes: {
                        us: ["6P", "28P"],
                        eu: ["28PEU", "38PEU"],
                        uk: ["10PUK", "28PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "29PAU",
                      short: "29P",
                      long: "29P",
                      display: "29P",
                      display_with_size_set: "29P",
                      display_with_size_system: "AU 29P",
                      display_with_system_and_set: "AU 29P",
                      equivalent_sizes: {
                        us: ["8P", "29P"],
                        eu: ["29PEU", "40PEU"],
                        uk: ["12PUK", "29PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30PAU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "AU 30P",
                      display_with_system_and_set: "AU 30P",
                      equivalent_sizes: {
                        us: ["10P", "30P"],
                        eu: ["30PEU", "42PEU"],
                        uk: ["14PUK", "30PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "31PAU",
                      short: "31P",
                      long: "31P",
                      display: "31P",
                      display_with_size_set: "31P",
                      display_with_size_system: "AU 31P",
                      display_with_system_and_set: "AU 31P",
                      equivalent_sizes: {
                        us: ["12P", "31P"],
                        eu: ["31PEU", "44PEU"],
                        uk: ["16PUK", "31PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32PAU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "AU 32P",
                      display_with_system_and_set: "AU 32P",
                      equivalent_sizes: {
                        us: ["14P", "32P"],
                        eu: ["32PEU", "46PEU"],
                        uk: ["18PUK", "32PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "33PAU",
                      short: "33P",
                      long: "33P",
                      display: "33P",
                      display_with_size_set: "33P",
                      display_with_size_system: "AU 33P",
                      display_with_system_and_set: "AU 33P",
                      equivalent_sizes: {
                        us: ["16P", "33P"],
                        eu: ["33PEU", "48PEU"],
                        uk: ["20PUK", "33PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34PAU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "AU 34P",
                      display_with_system_and_set: "AU 34P",
                      equivalent_sizes: {
                        us: ["18P", "34P"],
                        eu: ["34PEU", "50PEU"],
                        uk: ["22PUK", "34PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "23PEU",
                      short: "23P",
                      long: "23P",
                      display: "23P",
                      display_with_size_set: "23P",
                      display_with_size_system: "EU 23P",
                      display_with_system_and_set: "EU 23P",
                      equivalent_sizes: {
                        us: ["00P", "23P"],
                        au: ["0PAU", "23PAU"],
                        uk: ["0PUK", "23PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "24PEU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "EU 24P",
                      display_with_system_and_set: "EU 24P",
                      equivalent_sizes: {
                        us: ["00P", "24P"],
                        au: ["2PAU", "24PAU"],
                        uk: ["2PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "25PEU",
                      short: "25P",
                      long: "25P",
                      display: "25P",
                      display_with_size_set: "25P",
                      display_with_size_system: "EU 25P",
                      display_with_system_and_set: "EU 25P",
                      equivalent_sizes: {
                        us: ["0P", "25P"],
                        au: ["4PAU", "25PAU"],
                        uk: ["4PUK", "25PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "26PEU",
                      short: "26P",
                      long: "26P",
                      display: "26P",
                      display_with_size_set: "26P",
                      display_with_size_system: "EU 26P",
                      display_with_system_and_set: "EU 26P",
                      equivalent_sizes: {
                        us: ["2P", "26P"],
                        au: ["6PAU", "26PAU"],
                        uk: ["6PUK", "26PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "27PEU",
                      short: "27P",
                      long: "27P",
                      display: "27P",
                      display_with_size_set: "27P",
                      display_with_size_system: "EU 27P",
                      display_with_system_and_set: "EU 27P",
                      equivalent_sizes: {
                        us: ["4P", "27P"],
                        au: ["8PAU", "27PAU"],
                        uk: ["8PUK", "27PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "28PEU",
                      short: "28P",
                      long: "28P",
                      display: "28P",
                      display_with_size_set: "28P",
                      display_with_size_system: "EU 28P",
                      display_with_system_and_set: "EU 28P",
                      equivalent_sizes: {
                        us: ["00P", "6P", "23P", "28P"],
                        au: ["0PAU", "10PAU", "23PAU", "28PAU"],
                        uk: ["0PUK", "10PUK", "23PUK", "28PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "29PEU",
                      short: "29P",
                      long: "29P",
                      display: "29P",
                      display_with_size_set: "29P",
                      display_with_size_system: "EU 29P",
                      display_with_system_and_set: "EU 29P",
                      equivalent_sizes: {
                        us: ["8P", "29P"],
                        au: ["12PAU", "29PAU"],
                        uk: ["12PUK", "29PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P", "10P", "24P", "30P"],
                        au: ["2PAU", "14PAU", "24PAU", "30PAU"],
                        uk: ["2PUK", "14PUK", "24PUK", "30PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "31PEU",
                      short: "31P",
                      long: "31P",
                      display: "31P",
                      display_with_size_set: "31P",
                      display_with_size_system: "EU 31P",
                      display_with_system_and_set: "EU 31P",
                      equivalent_sizes: {
                        us: ["12P", "31P"],
                        au: ["16PAU", "31PAU"],
                        uk: ["16PUK", "31PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["0P", "14P", "25P", "32P"],
                        au: ["4PAU", "18PAU", "25PAU", "32PAU"],
                        uk: ["4PUK", "18PUK", "25PUK", "32PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "33PEU",
                      short: "33P",
                      long: "33P",
                      display: "33P",
                      display_with_size_set: "33P",
                      display_with_size_system: "EU 33P",
                      display_with_system_and_set: "EU 33P",
                      equivalent_sizes: {
                        us: ["16P", "33P"],
                        au: ["20PAU", "33PAU"],
                        uk: ["20PUK", "33PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["2P", "18P", "26P", "34P"],
                        au: ["6PAU", "22PAU", "26PAU", "34PAU"],
                        uk: ["6PUK", "22PUK", "26PUK", "34PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["4P", "27P"],
                        au: ["8PAU", "27PAU"],
                        uk: ["8PUK", "27PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["6P", "28P"],
                        au: ["10PAU", "28PAU"],
                        uk: ["10PUK", "28PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["8P", "29P"],
                        au: ["12PAU", "29PAU"],
                        uk: ["12PUK", "29PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["10P", "30P"],
                        au: ["14PAU", "30PAU"],
                        uk: ["14PUK", "30PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["12P", "31P"],
                        au: ["16PAU", "31PAU"],
                        uk: ["16PUK", "31PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["14P", "32P"],
                        au: ["18PAU", "32PAU"],
                        uk: ["18PUK", "32PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["16P", "33P"],
                        au: ["20PAU", "33PAU"],
                        uk: ["20PUK", "33PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["18P", "34P"],
                        au: ["22PAU", "34PAU"],
                        uk: ["22PUK", "34PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "0PUK",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "UK 0P",
                      display_with_system_and_set: "UK 0P",
                      equivalent_sizes: {
                        us: ["00P", "23P"],
                        au: ["0PAU", "23PAU"],
                        eu: ["23PEU", "28PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P", "24P"],
                        au: ["24PAU", "2PAU"],
                        eu: ["24PEU", "30PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["0P", "25P"],
                        au: ["25PAU", "4PAU"],
                        eu: ["25PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["2P", "26P"],
                        au: ["26PAU", "6PAU"],
                        eu: ["26PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["4P", "27P"],
                        au: ["27PAU", "8PAU"],
                        eu: ["27PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["6P", "28P"],
                        au: ["10PAU", "28PAU"],
                        eu: ["28PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["8P", "29P"],
                        au: ["12PAU", "29PAU"],
                        eu: ["29PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["10P", "30P"],
                        au: ["14PAU", "30PAU"],
                        eu: ["30PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["12P", "31P"],
                        au: ["16PAU", "31PAU"],
                        eu: ["31PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["14P", "32P"],
                        au: ["18PAU", "32PAU"],
                        eu: ["32PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["16P", "33P"],
                        au: ["20PAU", "33PAU"],
                        eu: ["33PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["18P", "34P"],
                        au: ["22PAU", "34PAU"],
                        eu: ["34PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "23PUK",
                      short: "23P",
                      long: "23P",
                      display: "23P",
                      display_with_size_set: "23P",
                      display_with_size_system: "UK 23P",
                      display_with_system_and_set: "UK 23P",
                      equivalent_sizes: {
                        us: ["00P", "23P"],
                        au: ["0PAU", "23PAU"],
                        eu: ["23PEU", "28PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["00P", "24P"],
                        au: ["24PAU", "2PAU"],
                        eu: ["24PEU", "30PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "25PUK",
                      short: "25P",
                      long: "25P",
                      display: "25P",
                      display_with_size_set: "25P",
                      display_with_size_system: "UK 25P",
                      display_with_system_and_set: "UK 25P",
                      equivalent_sizes: {
                        us: ["0P", "25P"],
                        au: ["25PAU", "4PAU"],
                        eu: ["25PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26PUK",
                      short: "26P",
                      long: "26P",
                      display: "26P",
                      display_with_size_set: "26P",
                      display_with_size_system: "UK 26P",
                      display_with_system_and_set: "UK 26P",
                      equivalent_sizes: {
                        us: ["2P", "26P"],
                        au: ["26PAU", "6PAU"],
                        eu: ["26PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "27PUK",
                      short: "27P",
                      long: "27P",
                      display: "27P",
                      display_with_size_set: "27P",
                      display_with_size_system: "UK 27P",
                      display_with_system_and_set: "UK 27P",
                      equivalent_sizes: {
                        us: ["4P", "27P"],
                        au: ["27PAU", "8PAU"],
                        eu: ["27PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28PUK",
                      short: "28P",
                      long: "28P",
                      display: "28P",
                      display_with_size_set: "28P",
                      display_with_size_system: "UK 28P",
                      display_with_system_and_set: "UK 28P",
                      equivalent_sizes: {
                        us: ["6P", "28P"],
                        au: ["10PAU", "28PAU"],
                        eu: ["28PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "29PUK",
                      short: "29P",
                      long: "29P",
                      display: "29P",
                      display_with_size_set: "29P",
                      display_with_size_system: "UK 29P",
                      display_with_system_and_set: "UK 29P",
                      equivalent_sizes: {
                        us: ["8P", "29P"],
                        au: ["12PAU", "29PAU"],
                        eu: ["29PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30PUK",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "UK 30P",
                      display_with_system_and_set: "UK 30P",
                      equivalent_sizes: {
                        us: ["10P", "30P"],
                        au: ["14PAU", "30PAU"],
                        eu: ["30PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "31PUK",
                      short: "31P",
                      long: "31P",
                      display: "31P",
                      display_with_size_set: "31P",
                      display_with_size_system: "UK 31P",
                      display_with_system_and_set: "UK 31P",
                      equivalent_sizes: {
                        us: ["12P", "31P"],
                        au: ["16PAU", "31PAU"],
                        eu: ["31PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32PUK",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "UK 32P",
                      display_with_system_and_set: "UK 32P",
                      equivalent_sizes: {
                        us: ["14P", "32P"],
                        au: ["18PAU", "32PAU"],
                        eu: ["32PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "33PUK",
                      short: "33P",
                      long: "33P",
                      display: "33P",
                      display_with_size_set: "33P",
                      display_with_size_system: "UK 33P",
                      display_with_system_and_set: "UK 33P",
                      equivalent_sizes: {
                        us: ["16P", "33P"],
                        au: ["20PAU", "33PAU"],
                        eu: ["33PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34PUK",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "UK 34P",
                      display_with_system_and_set: "UK 34P",
                      equivalent_sizes: {
                        us: ["18P", "34P"],
                        au: ["22PAU", "34PAU"],
                        eu: ["34PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "2d00579fd97b4e26a60056e3",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "001e8975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "4", "30", "4"],
                  ["0", "6", "32", "6"],
                  ["1", "8", "34", "8"],
                  ["3", "10", "36", "10"],
                  ["5", "11", "38", "11"],
                  ["7", "12", "40", "12"],
                  ["9", "14", "42", "14"],
                  ["11", "16", "44", "16"],
                  ["13", "18", "46", "18"],
                  ["15", "20", "48", "20"],
                  ["17", "22", "50", "22"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "4", "30", "4"],
                ["0", "6", "32", "6"],
                ["1", "8", "34", "8"],
                ["3", "10", "36", "10"],
                ["5", "11", "38", "11"],
                ["7", "12", "40", "12"],
                ["9", "14", "42", "14"],
                ["11", "16", "44", "16"],
                ["13", "18", "46", "18"],
                ["15", "20", "48", "20"],
                ["17", "22", "50", "22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU"],
                        eu: ["30JEU"],
                        uk: ["4JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU"],
                        eu: ["32JEU"],
                        uk: ["6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["8JAU"],
                        eu: ["34JEU"],
                        uk: ["8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU"],
                        eu: ["36JEU"],
                        uk: ["10JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["11JAU"],
                        eu: ["38JEU"],
                        uk: ["11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU"],
                        eu: ["40JEU"],
                        uk: ["12JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["14JAU"],
                        eu: ["42JEU"],
                        uk: ["14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU"],
                        eu: ["44JEU"],
                        uk: ["16JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["18JAU"],
                        eu: ["46JEU"],
                        uk: ["18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU"],
                        eu: ["48JEU"],
                        uk: ["20JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["22JAU"],
                        eu: ["50JEU"],
                        uk: ["22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU"],
                        uk: ["4JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J"],
                        eu: ["32JEU"],
                        uk: ["6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["1J"],
                        eu: ["34JEU"],
                        uk: ["8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J"],
                        eu: ["36JEU"],
                        uk: ["10JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["5J"],
                        eu: ["38JEU"],
                        uk: ["11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J"],
                        eu: ["40JEU"],
                        uk: ["12JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["9J"],
                        eu: ["42JEU"],
                        uk: ["14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J"],
                        eu: ["44JEU"],
                        uk: ["16JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["13J"],
                        eu: ["46JEU"],
                        uk: ["18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J"],
                        eu: ["48JEU"],
                        uk: ["20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["17J"],
                        eu: ["50JEU"],
                        uk: ["22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU"],
                        uk: ["4JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J"],
                        au: ["6JAU"],
                        uk: ["6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["1J"],
                        au: ["8JAU"],
                        uk: ["8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J"],
                        au: ["10JAU"],
                        uk: ["10JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["5J"],
                        au: ["11JAU"],
                        uk: ["11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J"],
                        au: ["12JAU"],
                        uk: ["12JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["9J"],
                        au: ["14JAU"],
                        uk: ["14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J"],
                        au: ["16JAU"],
                        uk: ["16JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["13J"],
                        au: ["18JAU"],
                        uk: ["18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48JEU",
                      short: "48 (Juniors)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Juniors)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J"],
                        au: ["20JAU"],
                        uk: ["20JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50JEU",
                      short: "50 (Juniors)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Juniors)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Juniors)",
                      equivalent_sizes: {
                        us: ["17J"],
                        au: ["22JAU"],
                        uk: ["22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU"],
                        eu: ["30JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J"],
                        au: ["6JAU"],
                        eu: ["32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["1J"],
                        au: ["8JAU"],
                        eu: ["34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J"],
                        au: ["10JAU"],
                        eu: ["36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["5J"],
                        au: ["11JAU"],
                        eu: ["38JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J"],
                        au: ["12JAU"],
                        eu: ["40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["9J"],
                        au: ["14JAU"],
                        eu: ["42JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J"],
                        au: ["16JAU"],
                        eu: ["44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["13J"],
                        au: ["18JAU"],
                        eu: ["46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J"],
                        au: ["20JAU"],
                        eu: ["48JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["17J"],
                        au: ["22JAU"],
                        eu: ["50JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "38004308d97b4ea2b60057c7",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "001e8975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-23", "31-33"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-23", "31-33"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-58.4", "78.7-83.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-58.4", "78.7-83.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00,23", "0,23", "23,28", "0"],
                  ["00,24", "24,2", "24,30", "2"],
                  ["0,25", "25,4", "25,32", "4"],
                  ["2,26", "26,6", "26,34", "6"],
                  ["2,26", "26,6", "26,34", "6"],
                  ["6,28", "10,28", "28,38", "10"],
                  ["8,29", "12,29", "29,40", "12"],
                  ["10,30", "14,30", "30,42", "14"],
                  ["12,31", "16,31", "31,44", "16"],
                  ["14,14W,16W,32", "18,20,32", "32,46,48", "18"],
                  [
                    "14,14W,16W,16,18W,18,20W,32,33,34",
                    "18,20,22,32,33,34",
                    "32,33,34,46,48,50",
                    "20",
                  ],
                  ["16,16W,18,18W,20W,33,34", "20,22,33,34", "33,34,48,50", "22"],
                  ["00,20,20W,22W,24", "24,26,2", "24,30,50,52", "24"],
                  [
                    "2,20,20W,22,22W,24W,26",
                    "24,26,28,6",
                    "26,34,50,52,54",
                    "26",
                  ],
                  [
                    "6,22,22W,24,24W,26W,28",
                    "10,26,28,30",
                    "28,38,52,54,56",
                    "28",
                  ],
                  [
                    "10,24,24W,26,26W,28W,30",
                    "14,28,30,32",
                    "30,42,54,56,58",
                    "30",
                  ],
                  [
                    "14W,14,16W,26W,26,28W,28,30W,32",
                    "18,20,30,32,34",
                    "32,46,48,56,58,60",
                    "32",
                  ],
                  [
                    "18W,18,20W,28W,28,30W,30,32W,34",
                    "20,22,32,34,36",
                    "34,48,50,58,60,62",
                    "34",
                  ],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["00,23", "0,23", "23,28", "23"],
                  ["00,20,20W,22W,24", "24,26,2", "24,30,50,52", "24"],
                  ["0,25", "25,4", "25,32", "25"],
                  [
                    "2,20,20W,22,22W,24W,26",
                    "24,26,28,6",
                    "26,34,50,52,54",
                    "26",
                  ],
                  ["4,27", "27,8", "27,36", "27"],
                  [
                    "6,22,22W,24,24W,26W,28",
                    "10,26,28,30",
                    "28,38,52,54,56",
                    "28",
                  ],
                  ["8,29", "12,29", "29,40", "29"],
                  [
                    "10,24,24W,26,26W,28W,30",
                    "14,28,30,32",
                    "30,42,54,56,58",
                    "30",
                  ],
                  ["12,31", "16,31", "31,44", "31"],
                  [
                    "14W,14,16W,26W,26,28W,28,30W,32",
                    "18,20,30,32,34",
                    "32,46,48,56,58,60",
                    "32",
                  ],
                  ["16,16W,18W,33", "20,22,33", "33,48,50", "33"],
                  [
                    "18W,18,20W,28W,28,30W,30,32W,34",
                    "20,22,32,34,36",
                    "34,48,50,58,60,62",
                    "34",
                  ],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["23", "0,23", "23,28", "0,23"],
                ["24", "2,24,28,30", "24,30,54,56", "2,24,28,30"],
                ["25", "4,25", "25,32", "4,25"],
                ["26", "6,26,30,32", "26,34,56,58", "6,26,30,32"],
                ["27", "8,27", "27,36", "8,27"],
                ["28", "10,28,32,34", "28,38,58,60", "10,28,32,34"],
                ["29", "12,29", "29,40", "12,29"],
                ["30", "14,30,34,36", "30,42,60,62", "14,30,34,36"],
                ["31", "16,31", "31,44", "16,31"],
                ["00", "0,2,23,24", "23,24,28,30", "0,2,23,24"],
                ["0", "4,25", "25,32", "4,25"],
                ["2", "6,26", "26,34", "6,26"],
                ["4", "8,27", "27,36", "8,27"],
                ["6", "10,28", "28,38", "10,28"],
                ["8", "12,29", "29,40", "12,29"],
                ["10", "14,30", "30,42", "14,30"],
                ["12", "16,31", "31,44", "16,31"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["32", "18,20,32,36,38", "32,46,48,62", "18,20,32,36,38"],
                ["33", "20,22,33", "33,48,50", "20,22,33"],
                ["34", "20,22,34", "34,48,50", "20,22,34"],
                ["14", "18,20,32", "32,46,48", "18,20,32"],
                ["14W", "18,20,32", "32,46,48", "18,20,32"],
                ["16", "20,22,33", "33,48,50", "20,22,33"],
                ["16W", "18,20,22,32,33", "32,33,46,48,50", "18,20,22,32,33"],
                ["18", "20,22,34", "34,48,50", "20,22,34"],
                ["18W", "20,22,33,34", "33,34,48,50", "20,22,33,34"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "20,22,24,26,34", "34,48,50,52", "20,22,24,26,34"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "2,24,28,30", "24,30,54,56", "2,24,28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "6,26,30,32", "26,34,56,58", "6,26,30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "10,28,32,34", "28,38,58,60", "10,28,32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "14,30,34,36", "30,42,60,62", "14,30,34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "18,20,32,36,38", "32,46,48,62", "18,20,32,36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "23M",
                      short: "23 (Maternity)",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23 (Maternity)",
                      display_with_size_system: "US 23",
                      display_with_system_and_set: "US 23 (Maternity)",
                      equivalent_sizes: {
                        au: ["0MAU", "23MAU"],
                        eu: ["23MEU", "28MEU"],
                        uk: ["0MUK", "23MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "24MAU", "28MAU", "30MAU"],
                        eu: ["24MEU", "30MEU", "54MEU", "56MEU"],
                        uk: ["2MUK", "24MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "25M",
                      short: "25 (Maternity)",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25 (Maternity)",
                      display_with_size_system: "US 25",
                      display_with_system_and_set: "US 25 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "25MAU"],
                        eu: ["25MEU", "32MEU"],
                        uk: ["4MUK", "25MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "26MAU", "30MAU", "32MAU"],
                        eu: ["26MEU", "34MEU", "56MEU", "58MEU"],
                        uk: ["6MUK", "26MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "27M",
                      short: "27 (Maternity)",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27 (Maternity)",
                      display_with_size_system: "US 27",
                      display_with_system_and_set: "US 27 (Maternity)",
                      equivalent_sizes: {
                        au: ["8MAU", "27MAU"],
                        eu: ["27MEU", "36MEU"],
                        uk: ["8MUK", "27MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "28MAU", "32MAU", "34MAU"],
                        eu: ["28MEU", "38MEU", "58MEU", "60MEU"],
                        uk: ["10MUK", "28MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "29M",
                      short: "29 (Maternity)",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29 (Maternity)",
                      display_with_size_system: "US 29",
                      display_with_system_and_set: "US 29 (Maternity)",
                      equivalent_sizes: {
                        au: ["12MAU", "29MAU"],
                        eu: ["29MEU", "40MEU"],
                        uk: ["12MUK", "29MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "30MAU", "34MAU", "36MAU"],
                        eu: ["30MEU", "42MEU", "60MEU", "62MEU"],
                        uk: ["14MUK", "30MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "31M",
                      short: "31 (Maternity)",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31 (Maternity)",
                      display_with_size_system: "US 31",
                      display_with_system_and_set: "US 31 (Maternity)",
                      equivalent_sizes: {
                        au: ["16MAU", "31MAU"],
                        eu: ["31MEU", "44MEU"],
                        uk: ["16MUK", "31MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["0MAU", "2MAU", "23MAU", "24MAU"],
                        eu: ["23MEU", "24MEU", "28MEU", "30MEU"],
                        uk: ["0MUK", "2MUK", "23MUK", "24MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "25MAU"],
                        eu: ["25MEU", "32MEU"],
                        uk: ["4MUK", "25MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "26MAU"],
                        eu: ["26MEU", "34MEU"],
                        uk: ["6MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["8MAU", "27MAU"],
                        eu: ["27MEU", "36MEU"],
                        uk: ["8MUK", "27MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "28MAU"],
                        eu: ["28MEU", "38MEU"],
                        uk: ["10MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["12MAU", "29MAU"],
                        eu: ["29MEU", "40MEU"],
                        uk: ["12MUK", "29MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "30MAU"],
                        eu: ["30MEU", "42MEU"],
                        uk: ["14MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["16MAU", "31MAU"],
                        eu: ["31MEU", "44MEU"],
                        uk: ["16MUK", "31MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "32MAU", "36MAU", "38MAU"],
                        eu: ["32MEU", "46MEU", "48MEU", "62MEU"],
                        uk: ["18MUK", "20MUK", "32MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "33M",
                      short: "33 (Maternity)",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33 (Maternity)",
                      display_with_size_system: "US 33",
                      display_with_system_and_set: "US 33 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU", "33MAU"],
                        eu: ["33MEU", "48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK", "33MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34M",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "US 34",
                      display_with_system_and_set: "US 34 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU", "34MAU"],
                        eu: ["34MEU", "48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "32MAU"],
                        eu: ["32MEU", "46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "32MAU"],
                        eu: ["32MEU", "46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU", "33MAU"],
                        eu: ["33MEU", "48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK", "33MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU", "32MAU", "33MAU"],
                        eu: ["32MEU", "33MEU", "46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK", "32MUK", "33MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU", "34MAU"],
                        eu: ["34MEU", "48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU", "33MAU", "34MAU"],
                        eu: ["33MEU", "34MEU", "48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK", "33MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU", "24MAU", "26MAU", "34MAU"],
                        eu: ["34MEU", "48MEU", "50MEU", "52MEU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24PlusM",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "24MAU", "28MAU", "30MAU"],
                        eu: ["24MEU", "30MEU", "54MEU", "56MEU"],
                        uk: ["2MUK", "24MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26PlusM",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "26MAU", "30MAU", "32MAU"],
                        eu: ["26MEU", "34MEU", "56MEU", "58MEU"],
                        uk: ["6MUK", "26MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28PlusM",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "28MAU", "32MAU", "34MAU"],
                        eu: ["28MEU", "38MEU", "58MEU", "60MEU"],
                        uk: ["10MUK", "28MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30PlusM",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "30MAU", "34MAU", "36MAU"],
                        eu: ["30MEU", "42MEU", "60MEU", "62MEU"],
                        uk: ["14MUK", "30MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32PlusM",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "32MAU", "36MAU", "38MAU"],
                        eu: ["32MEU", "46MEU", "48MEU", "62MEU"],
                        uk: ["18MUK", "20MUK", "32MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "0MAU",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "AU 0",
                      display_with_system_and_set: "AU 0 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "23M"],
                        eu: ["23MEU", "28MEU"],
                        uk: ["0MUK", "23MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "24M"],
                        eu: ["24MEU", "30MEU"],
                        uk: ["24MUK", "2MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "25M"],
                        eu: ["25MEU", "32MEU"],
                        uk: ["25MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "26M"],
                        eu: ["26MEU", "34MEU"],
                        uk: ["26MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["4M", "27M"],
                        eu: ["27MEU", "36MEU"],
                        uk: ["27MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "28M"],
                        eu: ["28MEU", "38MEU"],
                        uk: ["10MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["8M", "29M"],
                        eu: ["29MEU", "40MEU"],
                        uk: ["12MUK", "29MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "30M"],
                        eu: ["30MEU", "42MEU"],
                        uk: ["14MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["12M", "31M"],
                        eu: ["31MEU", "44MEU"],
                        uk: ["16MUK", "31MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM", "32M"],
                        eu: ["32MEU", "46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "14M",
                          "14WM",
                          "16WM",
                          "16M",
                          "18WM",
                          "18M",
                          "20WM",
                          "32M",
                          "33M",
                          "34M",
                        ],
                        eu: [
                          "32MEU",
                          "33MEU",
                          "34MEU",
                          "46MEU",
                          "48MEU",
                          "50MEU",
                        ],
                        uk: [
                          "18MUK",
                          "20MUK",
                          "22MUK",
                          "32MUK",
                          "33MUK",
                          "34MUK",
                        ],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20WM", "33M", "34M"],
                        eu: ["33MEU", "34MEU", "48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK", "33MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "23MAU",
                      short: "23 (Maternity)",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23 (Maternity)",
                      display_with_size_system: "AU 23",
                      display_with_system_and_set: "AU 23 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "23M"],
                        eu: ["23MEU", "28MEU"],
                        uk: ["0MUK", "23MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "20M", "20WM", "22WM", "24M"],
                        eu: ["24MEU", "30MEU", "50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK", "2MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "25MAU",
                      short: "25 (Maternity)",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25 (Maternity)",
                      display_with_size_system: "AU 25",
                      display_with_system_and_set: "AU 25 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "25M"],
                        eu: ["25MEU", "32MEU"],
                        uk: ["25MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "20M", "20WM", "22M", "22WM", "24WM", "26M"],
                        eu: ["26MEU", "34MEU", "50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "27MAU",
                      short: "27 (Maternity)",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27 (Maternity)",
                      display_with_size_system: "AU 27",
                      display_with_system_and_set: "AU 27 (Maternity)",
                      equivalent_sizes: {
                        us: ["4M", "27M"],
                        eu: ["27MEU", "36MEU"],
                        uk: ["27MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "22M", "22WM", "24M", "24WM", "26WM", "28M"],
                        eu: ["28MEU", "38MEU", "52MEU", "54MEU", "56MEU"],
                        uk: ["10MUK", "26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "29MAU",
                      short: "29 (Maternity)",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29 (Maternity)",
                      display_with_size_system: "AU 29",
                      display_with_system_and_set: "AU 29 (Maternity)",
                      equivalent_sizes: {
                        us: ["8M", "29M"],
                        eu: ["29MEU", "40MEU"],
                        uk: ["12MUK", "29MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "24M", "24WM", "26M", "26WM", "28WM", "30M"],
                        eu: ["30MEU", "42MEU", "54MEU", "56MEU", "58MEU"],
                        uk: ["14MUK", "28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "31MAU",
                      short: "31 (Maternity)",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31 (Maternity)",
                      display_with_size_system: "AU 31",
                      display_with_system_and_set: "AU 31 (Maternity)",
                      equivalent_sizes: {
                        us: ["12M", "31M"],
                        eu: ["31MEU", "44MEU"],
                        uk: ["16MUK", "31MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "14WM",
                          "14M",
                          "16WM",
                          "26WM",
                          "26M",
                          "28WM",
                          "28M",
                          "30WM",
                          "32M",
                        ],
                        eu: [
                          "32MEU",
                          "46MEU",
                          "48MEU",
                          "56MEU",
                          "58MEU",
                          "60MEU",
                        ],
                        uk: ["18MUK", "20MUK", "30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "33MAU",
                      short: "33 (Maternity)",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33 (Maternity)",
                      display_with_size_system: "AU 33",
                      display_with_system_and_set: "AU 33 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18WM", "33M"],
                        eu: ["33MEU", "48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK", "33MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "18WM",
                          "18M",
                          "20WM",
                          "28WM",
                          "28M",
                          "30WM",
                          "30M",
                          "32WM",
                          "34M",
                        ],
                        eu: [
                          "34MEU",
                          "48MEU",
                          "50MEU",
                          "58MEU",
                          "60MEU",
                          "62MEU",
                        ],
                        uk: ["20MUK", "22MUK", "32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "23MEU",
                      short: "23 (Maternity)",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23 (Maternity)",
                      display_with_size_system: "EU 23",
                      display_with_system_and_set: "EU 23 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "23M"],
                        au: ["0MAU", "23MAU"],
                        uk: ["0MUK", "23MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "24MEU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "EU 24",
                      display_with_system_and_set: "EU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "24M"],
                        au: ["2MAU", "24MAU"],
                        uk: ["2MUK", "24MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "25MEU",
                      short: "25 (Maternity)",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25 (Maternity)",
                      display_with_size_system: "EU 25",
                      display_with_system_and_set: "EU 25 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "25M"],
                        au: ["4MAU", "25MAU"],
                        uk: ["4MUK", "25MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "26MEU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "EU 26",
                      display_with_system_and_set: "EU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "26M"],
                        au: ["6MAU", "26MAU"],
                        uk: ["6MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "27MEU",
                      short: "27 (Maternity)",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27 (Maternity)",
                      display_with_size_system: "EU 27",
                      display_with_system_and_set: "EU 27 (Maternity)",
                      equivalent_sizes: {
                        us: ["4M", "27M"],
                        au: ["8MAU", "27MAU"],
                        uk: ["8MUK", "27MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "28MEU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "EU 28",
                      display_with_system_and_set: "EU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "6M", "23M", "28M"],
                        au: ["0MAU", "10MAU", "23MAU", "28MAU"],
                        uk: ["0MUK", "10MUK", "23MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "29MEU",
                      short: "29 (Maternity)",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29 (Maternity)",
                      display_with_size_system: "EU 29",
                      display_with_system_and_set: "EU 29 (Maternity)",
                      equivalent_sizes: {
                        us: ["8M", "29M"],
                        au: ["12MAU", "29MAU"],
                        uk: ["12MUK", "29MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "10M", "24M", "30M"],
                        au: ["2MAU", "14MAU", "24MAU", "30MAU"],
                        uk: ["2MUK", "14MUK", "24MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "31MEU",
                      short: "31 (Maternity)",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31 (Maternity)",
                      display_with_size_system: "EU 31",
                      display_with_system_and_set: "EU 31 (Maternity)",
                      equivalent_sizes: {
                        us: ["12M", "31M"],
                        au: ["16MAU", "31MAU"],
                        uk: ["16MUK", "31MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "14M", "14WM", "16WM", "25M", "32M"],
                        au: ["4MAU", "18MAU", "20MAU", "25MAU", "32MAU"],
                        uk: ["4MUK", "18MUK", "20MUK", "25MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "33MEU",
                      short: "33 (Maternity)",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33 (Maternity)",
                      display_with_size_system: "EU 33",
                      display_with_system_and_set: "EU 33 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18WM", "33M"],
                        au: ["20MAU", "22MAU", "33MAU"],
                        uk: ["20MUK", "22MUK", "33MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "18M", "18WM", "20WM", "26M", "34M"],
                        au: ["6MAU", "20MAU", "22MAU", "26MAU", "34MAU"],
                        uk: ["6MUK", "20MUK", "22MUK", "26MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["4M", "27M"],
                        au: ["8MAU", "27MAU"],
                        uk: ["8MUK", "27MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "28M"],
                        au: ["10MAU", "28MAU"],
                        uk: ["10MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["8M", "29M"],
                        au: ["12MAU", "29MAU"],
                        uk: ["12MUK", "29MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "30M"],
                        au: ["14MAU", "30MAU"],
                        uk: ["14MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["12M", "31M"],
                        au: ["16MAU", "31MAU"],
                        uk: ["16MUK", "31MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM", "32M"],
                        au: ["18MAU", "20MAU", "32MAU"],
                        uk: ["18MUK", "20MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "14M",
                          "14WM",
                          "16WM",
                          "16M",
                          "18WM",
                          "18M",
                          "20WM",
                          "32M",
                          "33M",
                          "34M",
                        ],
                        au: [
                          "18MAU",
                          "20MAU",
                          "22MAU",
                          "32MAU",
                          "33MAU",
                          "34MAU",
                        ],
                        uk: [
                          "18MUK",
                          "20MUK",
                          "22MUK",
                          "32MUK",
                          "33MUK",
                          "34MUK",
                        ],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "16M",
                          "16WM",
                          "18WM",
                          "18M",
                          "20M",
                          "20WM",
                          "22WM",
                          "33M",
                          "34M",
                        ],
                        au: [
                          "20MAU",
                          "22MAU",
                          "24MAU",
                          "26MAU",
                          "33MAU",
                          "34MAU",
                        ],
                        uk: [
                          "20MUK",
                          "22MUK",
                          "24MUK",
                          "26MUK",
                          "33MUK",
                          "34MUK",
                        ],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "0MUK",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "UK 0",
                      display_with_system_and_set: "UK 0 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "23M"],
                        au: ["0MAU", "23MAU"],
                        eu: ["23MEU", "28MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "24M"],
                        au: ["24MAU", "2MAU"],
                        eu: ["24MEU", "30MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "25M"],
                        au: ["25MAU", "4MAU"],
                        eu: ["25MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "26M"],
                        au: ["26MAU", "6MAU"],
                        eu: ["26MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["4M", "27M"],
                        au: ["27MAU", "8MAU"],
                        eu: ["27MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "28M"],
                        au: ["10MAU", "28MAU"],
                        eu: ["28MEU", "38MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["8M", "29M"],
                        au: ["12MAU", "29MAU"],
                        eu: ["29MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "30M"],
                        au: ["14MAU", "30MAU"],
                        eu: ["30MEU", "42MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["12M", "31M"],
                        au: ["16MAU", "31MAU"],
                        eu: ["31MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM", "32M"],
                        au: ["18MAU", "20MAU", "32MAU"],
                        eu: ["32MEU", "46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "14M",
                          "14WM",
                          "16WM",
                          "16M",
                          "18WM",
                          "18M",
                          "20WM",
                          "32M",
                          "33M",
                          "34M",
                        ],
                        au: [
                          "18MAU",
                          "20MAU",
                          "22MAU",
                          "32MAU",
                          "33MAU",
                          "34MAU",
                        ],
                        eu: [
                          "32MEU",
                          "33MEU",
                          "34MEU",
                          "46MEU",
                          "48MEU",
                          "50MEU",
                        ],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20WM", "33M", "34M"],
                        au: ["20MAU", "22MAU", "33MAU", "34MAU"],
                        eu: ["33MEU", "34MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "23MUK",
                      short: "23 (Maternity)",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23 (Maternity)",
                      display_with_size_system: "UK 23",
                      display_with_system_and_set: "UK 23 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "23M"],
                        au: ["0MAU", "23MAU"],
                        eu: ["23MEU", "28MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "20M", "20WM", "22WM", "24M"],
                        au: ["24MAU", "26MAU", "2MAU"],
                        eu: ["24MEU", "30MEU", "50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "25MUK",
                      short: "25 (Maternity)",
                      long: "25",
                      display: "25",
                      display_with_size_set: "25 (Maternity)",
                      display_with_size_system: "UK 25",
                      display_with_system_and_set: "UK 25 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "25M"],
                        au: ["25MAU", "4MAU"],
                        eu: ["25MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "20M", "20WM", "22M", "22WM", "24WM", "26M"],
                        au: ["24MAU", "26MAU", "28MAU", "6MAU"],
                        eu: ["26MEU", "34MEU", "50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "27MUK",
                      short: "27 (Maternity)",
                      long: "27",
                      display: "27",
                      display_with_size_set: "27 (Maternity)",
                      display_with_size_system: "UK 27",
                      display_with_system_and_set: "UK 27 (Maternity)",
                      equivalent_sizes: {
                        us: ["4M", "27M"],
                        au: ["27MAU", "8MAU"],
                        eu: ["27MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "22M", "22WM", "24M", "24WM", "26WM", "28M"],
                        au: ["10MAU", "26MAU", "28MAU", "30MAU"],
                        eu: ["28MEU", "38MEU", "52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "29MUK",
                      short: "29 (Maternity)",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29 (Maternity)",
                      display_with_size_system: "UK 29",
                      display_with_system_and_set: "UK 29 (Maternity)",
                      equivalent_sizes: {
                        us: ["8M", "29M"],
                        au: ["12MAU", "29MAU"],
                        eu: ["29MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "24M", "24WM", "26M", "26WM", "28WM", "30M"],
                        au: ["14MAU", "28MAU", "30MAU", "32MAU"],
                        eu: ["30MEU", "42MEU", "54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "31MUK",
                      short: "31 (Maternity)",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31 (Maternity)",
                      display_with_size_system: "UK 31",
                      display_with_system_and_set: "UK 31 (Maternity)",
                      equivalent_sizes: {
                        us: ["12M", "31M"],
                        au: ["16MAU", "31MAU"],
                        eu: ["31MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "14WM",
                          "14M",
                          "16WM",
                          "26WM",
                          "26M",
                          "28WM",
                          "28M",
                          "30WM",
                          "32M",
                        ],
                        au: ["18MAU", "20MAU", "30MAU", "32MAU", "34MAU"],
                        eu: [
                          "32MEU",
                          "46MEU",
                          "48MEU",
                          "56MEU",
                          "58MEU",
                          "60MEU",
                        ],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "33MUK",
                      short: "33 (Maternity)",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33 (Maternity)",
                      display_with_size_system: "UK 33",
                      display_with_system_and_set: "UK 33 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18WM", "33M"],
                        au: ["20MAU", "22MAU", "33MAU"],
                        eu: ["33MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: [
                          "18WM",
                          "18M",
                          "20WM",
                          "28WM",
                          "28M",
                          "30WM",
                          "30M",
                          "32WM",
                          "34M",
                        ],
                        au: ["20MAU", "22MAU", "32MAU", "34MAU", "36MAU"],
                        eu: [
                          "34MEU",
                          "48MEU",
                          "50MEU",
                          "58MEU",
                          "60MEU",
                          "62MEU",
                        ],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "women_shorts",
          aliases: [],
          slug: "Shorts",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
          category_features: [
            {
              id: "837df9aaaabb083120f45ec2",
              display: "Athletic Shorts",
              type: "cf",
              message_id: "women_shorts_athletic_shorts",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Athletic_Shorts",
              alias_slugs: [],
              parent_id: "001e8975d97b4e80ef00a955",
            },
            {
              id: "00be9287d97b4e80ef00a955",
              display: "Bermudas",
              type: "cf",
              message_id: "women_shorts_bermudas",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bermudas",
              alias_slugs: [],
              parent_id: "001e8975d97b4e80ef00a955",
            },
            {
              id: "847df9aaaabb083120f45ec2",
              display: "Bike Shorts",
              type: "cf",
              message_id: "women_shorts_bike_shorts",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bike_Shorts",
              alias_slugs: [],
              parent_id: "001e8975d97b4e80ef00a955",
            },
            {
              id: "00c09287d97b4e80ef00a955",
              display: "Cargos",
              type: "cf",
              message_id: "women_shorts_cargos",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cargos",
              alias_slugs: [],
              parent_id: "001e8975d97b4e80ef00a955",
            },
            {
              id: "857df9aaaabb083120f45ec2",
              display: "High Waist",
              type: "cf",
              message_id: "women_shorts_high_waist",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "High_Waist",
              alias_slugs: [],
              parent_id: "001e8975d97b4e80ef00a955",
            },
            {
              id: "00bc9287d97b4e80ef00a955",
              display: "Jean Shorts",
              type: "cf",
              message_id: "women_shorts_jean_shorts",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Jean_Shorts",
              alias_slugs: [],
              parent_id: "001e8975d97b4e80ef00a955",
            },
            {
              id: "00ba9287d97b4e80ef00a955",
              display: "Skorts",
              type: "cf",
              message_id: "women_shorts_skorts",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Skorts",
              alias_slugs: [],
              parent_id: "001e8975d97b4e80ef00a955",
            },
          ],
        },
        {
          id: "00128975d97b4e80ef00a955",
          display: "Skirts",
          size_sets: [
            {
              id: "0300579fd97b4e26a60056e3",
              name: "Standard",
              tags: ["standard"],
              category_id: "00128975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "6AU"],
                        eu: ["32EU", "34EU"],
                        uk: ["4UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["00"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        eu: ["30EU", "32EU", "34EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        eu: ["32EU", "34EU", "36EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["00"],
                        au: ["2AU", "4AU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["00"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["30EU", "32EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["32EU", "34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "0400579fd97b4e26a60056e3",
              name: "Plus",
              tags: ["plus"],
              category_id: "00128975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34", "58,60,62", "34"],
                  ["30,30W,32,32W", "36,38", "60,62", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU", "28AU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU", "30AU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20", "20W", "22W"],
                        au: ["20AU", "22AU", "24AU", "26AU"],
                        uk: ["20UK", "22UK", "24UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "1d00579fd97b4e26a60056e3",
              name: "Petite",
              tags: ["petite"],
              category_id: "00128975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P,32P", "2P"],
                  ["00P,0P", "2P,4P,6P", "30P,32P,34P", "4P"],
                  ["0P,2P", "4P,6P,8P", "32P,34P,36P", "6P"],
                  ["2P,4P", "10P,6P,8P", "34P,36P,38P", "8P"],
                  ["4P,6P", "10P,12P,8P", "36P,38P,40P", "10P"],
                  ["6P,8P", "10P,12P,14P", "38P,40P,42P", "12P"],
                  ["8P,10P", "12P,14P,16P", "40P,42P,44P", "14P"],
                  ["10P,12P", "14P,16P,18P", "42P,44P,46P", "16P"],
                  ["12P,14P", "16P,18P,20P", "44P,46P,48P", "18P"],
                  ["14P,16P", "18P,20P,22P", "46P,48P,50P", "20P"],
                  ["16P,18P", "20P,22P,24P", "48P,50P,52P", "22P"],
                  ["18P,20P", "22P,24P", "50P,52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P,4P", "30P,32P", "2P,4P"],
                ["0P", "4P,6P", "32P,34P", "4P,6P"],
                ["2P", "6P,8P", "34P,36P", "6P,8P"],
                ["4P", "8P,10P", "36P,38P", "8P,10P"],
                ["6P", "10P,12P", "38P,40P", "10P,12P"],
                ["8P", "12P,14P", "40P,42P", "12P,14P"],
                ["10P", "14P,16P", "42P,44P", "14P,16P"],
                ["12P", "16P,18P", "44P,46P", "16P,18P"],
                ["14P", "18P,20P", "46P,48P", "18P,20P"],
                ["16P", "20P,22P", "48P,50P", "20P,22P"],
                ["18P", "22P,24P", "50P,52P", "22P,24P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "6PAU"],
                        eu: ["32PEU", "34PEU"],
                        uk: ["4PUK", "6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU"],
                        uk: ["6PUK", "8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "10PAU"],
                        eu: ["36PEU", "38PEU"],
                        uk: ["8PUK", "10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "12PAU"],
                        eu: ["38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "14PAU"],
                        eu: ["40PEU", "42PEU"],
                        uk: ["12PUK", "14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "16PAU"],
                        eu: ["42PEU", "44PEU"],
                        uk: ["14PUK", "16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "18PAU"],
                        eu: ["44PEU", "46PEU"],
                        uk: ["16PUK", "18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "20PAU"],
                        eu: ["46PEU", "48PEU"],
                        uk: ["18PUK", "20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "22PAU"],
                        eu: ["48PEU", "50PEU"],
                        uk: ["20PUK", "22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["6PAU", "8PAU", "10PAU"],
                        uk: ["6PUK", "8PUK", "10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["8PAU", "10PAU", "12PAU"],
                        uk: ["8PUK", "10PUK", "12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "1e00579fd97b4e26a60056e3",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "00128975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "4,6", "30,32", "4"],
                  ["00,0,1", "4,6,8", "30,32,34", "6"],
                  ["0,1", "6,8", "32,34", "8"],
                  ["3,5", "10,11", "34,36", "10"],
                  ["3,5", "10,11", "34,36", "11"],
                  ["7,9", "12,14", "38,40", "12"],
                  ["7,9", "12,14", "38,40", "14"],
                  ["11,13", "16,18", "42,44", "16"],
                  ["11,13", "16,18,20", "42,44", "18"],
                  ["15,17", "18,20", "44,46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "4,6", "30,32", "4,6"],
                ["0", "6,8", "32,34", "6,8"],
                ["1", "6,8", "32,34", "6,8"],
                ["3", "10,11", "34,36", "10,11"],
                ["5", "10,11", "34,36", "10,11"],
                ["7", "12,14", "38,40", "12,14"],
                ["9", "12,14", "38,40", "12,14"],
                ["11", "16,18", "42,44", "16,18"],
                ["13", "16,18", "42,44", "16,18"],
                ["15", "20,22", "44,46", "20,22"],
                ["17", "20,22", "44,46", "20,22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J", "3J", "5J"],
                        au: ["6JAU", "8JAU", "10JAU", "11JAU"],
                        uk: ["6JUK", "8JUK", "10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J", "15J", "17J"],
                        au: ["16JAU", "18JAU", "20JAU", "22JAU"],
                        uk: ["16JUK", "18JUK", "20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "31004308d97b4ea2b60057c7",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "00128975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "women_skirts",
          aliases: [],
          slug: "Skirts",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
          category_features: [
            {
              id: "00589287d97b4e80ef00a955",
              display: "A-Line or Full",
              type: "cf",
              message_id: "women_skirts_aline_or_full",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "A_Line_or_Full",
              alias_slugs: [],
              parent_id: "00128975d97b4e80ef00a955",
            },
            {
              id: "00569287d97b4e80ef00a955",
              display: "Asymmetrical",
              type: "cf",
              message_id: "women_skirts_asymmetrical",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Asymmetrical",
              alias_slugs: [],
              parent_id: "00128975d97b4e80ef00a955",
            },
            {
              id: "005a9287d97b4e80ef00a955",
              display: "Circle & Skater",
              type: "cf",
              message_id: "women_skirts_circle_skater",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Circle_&_Skater",
              alias_slugs: [],
              parent_id: "00128975d97b4e80ef00a955",
            },
            {
              id: "00549287d97b4e80ef00a955",
              display: "High Low",
              type: "cf",
              message_id: "women_skirts_high_low",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "High_Low",
              alias_slugs: [],
              parent_id: "00128975d97b4e80ef00a955",
            },
            {
              id: "00529287d97b4e80ef00a955",
              display: "Maxi",
              type: "cf",
              message_id: "women_skirts_maxi",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Maxi",
              alias_slugs: [],
              parent_id: "00128975d97b4e80ef00a955",
            },
            {
              id: "00509287d97b4e80ef00a955",
              display: "Midi",
              type: "cf",
              message_id: "women_skirts_midi",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Midi",
              alias_slugs: [],
              parent_id: "00128975d97b4e80ef00a955",
            },
            {
              id: "004e9287d97b4e80ef00a955",
              display: "Mini",
              type: "cf",
              message_id: "women_skirts_mini",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Mini",
              alias_slugs: [],
              parent_id: "00128975d97b4e80ef00a955",
            },
            {
              id: "005c9287d97b4e80ef00a955",
              display: "Pencil",
              type: "cf",
              message_id: "women_skirts_pencil",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Pencil",
              alias_slugs: [],
              parent_id: "00128975d97b4e80ef00a955",
            },
            {
              id: "005e9287d97b4e80ef00a955",
              display: "Skirt Sets",
              type: "cf",
              message_id: "women_skirts_skirt_sets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Skirt_Sets",
              alias_slugs: [],
              parent_id: "00128975d97b4e80ef00a955",
            },
          ],
        },
        {
          id: "00168975d97b4e80ef00a955",
          display: "Sweaters",
          size_sets: [
            {
              id: "0700579fd97b4e26a60056e3",
              name: "Standard",
              tags: ["standard"],
              category_id: "00168975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "6AU"],
                        eu: ["32EU", "34EU"],
                        uk: ["4UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["00"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        eu: ["30EU", "32EU", "34EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        eu: ["32EU", "34EU", "36EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["00"],
                        au: ["2AU", "4AU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["00"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["30EU", "32EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["32EU", "34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "0800579fd97b4e26a60056e3",
              name: "Plus",
              tags: ["plus"],
              category_id: "00168975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34", "58,60,62", "34"],
                  ["30,30W,32,32W", "36,38", "60,62", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU", "28AU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU", "30AU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20", "20W", "22W"],
                        au: ["20AU", "22AU", "24AU", "26AU"],
                        uk: ["20UK", "22UK", "24UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "2200579fd97b4e26a60056e3",
              name: "Petite",
              tags: ["petite"],
              category_id: "00168975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P,32P", "2P"],
                  ["00P,0P", "2P,4P,6P", "30P,32P,34P", "4P"],
                  ["0P,2P", "4P,6P,8P", "32P,34P,36P", "6P"],
                  ["2P,4P", "10P,6P,8P", "34P,36P,38P", "8P"],
                  ["4P,6P", "10P,12P,8P", "36P,38P,40P", "10P"],
                  ["6P,8P", "10P,12P,14P", "38P,40P,42P", "12P"],
                  ["8P,10P", "12P,14P,16P", "40P,42P,44P", "14P"],
                  ["10P,12P", "14P,16P,18P", "42P,44P,46P", "16P"],
                  ["12P,14P", "16P,18P,20P", "44P,46P,48P", "18P"],
                  ["14P,16P", "18P,20P,22P", "46P,48P,50P", "20P"],
                  ["16P,18P", "20P,22P,24P", "48P,50P,52P", "22P"],
                  ["18P,20P", "22P,24P", "50P,52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P,4P", "30P,32P", "2P,4P"],
                ["0P", "4P,6P", "32P,34P", "4P,6P"],
                ["2P", "6P,8P", "34P,36P", "6P,8P"],
                ["4P", "8P,10P", "36P,38P", "8P,10P"],
                ["6P", "10P,12P", "38P,40P", "10P,12P"],
                ["8P", "12P,14P", "40P,42P", "12P,14P"],
                ["10P", "14P,16P", "42P,44P", "14P,16P"],
                ["12P", "16P,18P", "44P,46P", "16P,18P"],
                ["14P", "18P,20P", "46P,48P", "18P,20P"],
                ["16P", "20P,22P", "48P,50P", "20P,22P"],
                ["18P", "22P,24P", "50P,52P", "22P,24P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "6PAU"],
                        eu: ["32PEU", "34PEU"],
                        uk: ["4PUK", "6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU"],
                        uk: ["6PUK", "8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "10PAU"],
                        eu: ["36PEU", "38PEU"],
                        uk: ["8PUK", "10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "12PAU"],
                        eu: ["38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "14PAU"],
                        eu: ["40PEU", "42PEU"],
                        uk: ["12PUK", "14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "16PAU"],
                        eu: ["42PEU", "44PEU"],
                        uk: ["14PUK", "16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "18PAU"],
                        eu: ["44PEU", "46PEU"],
                        uk: ["16PUK", "18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "20PAU"],
                        eu: ["46PEU", "48PEU"],
                        uk: ["18PUK", "20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "22PAU"],
                        eu: ["48PEU", "50PEU"],
                        uk: ["20PUK", "22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["6PAU", "8PAU", "10PAU"],
                        uk: ["6PUK", "8PUK", "10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["8PAU", "10PAU", "12PAU"],
                        uk: ["8PUK", "10PUK", "12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "2800579fd97b4e26a60056e3",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "00168975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "4,6", "30,32", "4"],
                  ["00,0,1", "4,6,8", "30,32,34", "6"],
                  ["0,1", "6,8", "32,34", "8"],
                  ["3,5", "10,11", "34,36", "10"],
                  ["3,5", "10,11", "34,36", "11"],
                  ["7,9", "12,14", "38,40", "12"],
                  ["7,9", "12,14", "38,40", "14"],
                  ["11,13", "16,18", "42,44", "16"],
                  ["11,13", "16,18,20", "42,44", "18"],
                  ["15,17", "18,20", "44,46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "4,6", "30,32", "4,6"],
                ["0", "6,8", "32,34", "6,8"],
                ["1", "6,8", "32,34", "6,8"],
                ["3", "10,11", "34,36", "10,11"],
                ["5", "10,11", "34,36", "10,11"],
                ["7", "12,14", "38,40", "12,14"],
                ["9", "12,14", "38,40", "12,14"],
                ["11", "16,18", "42,44", "16,18"],
                ["13", "16,18", "42,44", "16,18"],
                ["15", "20,22", "44,46", "20,22"],
                ["17", "20,22", "44,46", "20,22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J", "3J", "5J"],
                        au: ["6JAU", "8JAU", "10JAU", "11JAU"],
                        uk: ["6JUK", "8JUK", "10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J", "15J", "17J"],
                        au: ["16JAU", "18JAU", "20JAU", "22JAU"],
                        uk: ["16JUK", "18JUK", "20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "33004308d97b4ea2b60057c7",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "00168975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "women_sweaters",
          aliases: [],
          slug: "Sweaters",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
          category_features: [
            {
              id: "00709287d97b4e80ef00a955",
              display: "Cardigans",
              type: "cf",
              message_id: "women_sweaters_cardigans",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cardigans",
              alias_slugs: [],
              parent_id: "00168975d97b4e80ef00a955",
            },
            {
              id: "00769287d97b4e80ef00a955",
              display: "Cowl & Turtlenecks",
              type: "cf",
              message_id: "women_sweaters_cowl_turtlenecks",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cowl_&_Turtlenecks",
              alias_slugs: [],
              parent_id: "00168975d97b4e80ef00a955",
            },
            {
              id: "00729287d97b4e80ef00a955",
              display: "Crew & Scoop Necks",
              type: "cf",
              message_id: "women_sweaters_crew_scoop_necks",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Crew_&_Scoop_Necks",
              alias_slugs: [],
              parent_id: "00168975d97b4e80ef00a955",
            },
            {
              id: "7d7df9aaaabb083120f45ec2",
              display: "Off-the-Shoulder Sweaters",
              type: "cf",
              message_id: "women_sweaters_offtheshoulder_sweaters",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Off_the_Shoulder_Sweaters",
              alias_slugs: [],
              parent_id: "00168975d97b4e80ef00a955",
            },
            {
              id: "00749287d97b4e80ef00a955",
              display: "Shrugs & Ponchos",
              type: "cf",
              message_id: "women_sweaters_shrugs_ponchos",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Shrugs_&_Ponchos",
              alias_slugs: [],
              parent_id: "00168975d97b4e80ef00a955",
            },
            {
              id: "00789287d97b4e80ef00a955",
              display: "V-Necks",
              type: "cf",
              message_id: "women_sweaters_vnecks",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "V_Necks",
              alias_slugs: [],
              parent_id: "00168975d97b4e80ef00a955",
            },
          ],
        },
        {
          id: "00228975d97b4e80ef00a955",
          display: "Swim",
          size_sets: [
            {
              id: "1200579fd97b4e26a60056e3",
              name: "Standard",
              tags: ["standard"],
              category_id: "00228975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30", "2"],
                  ["0", "2,4,6", "32", "4"],
                  ["2", "4,6,8", "34", "6"],
                  ["4", "6,8", "36", "8"],
                  ["6", "10,12", "38", "10"],
                  ["8", "10,12", "40", "12"],
                  ["10", "14,16", "42", "14"],
                  ["12", "14,16", "44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2", "30", "2"],
                ["0", "4", "32", "4"],
                ["2", "6", "34", "6"],
                ["4", "8", "36", "8"],
                ["6", "10", "38", "10"],
                ["8", "12", "40", "12"],
                ["10", "14", "42", "14"],
                ["12", "16", "44", "16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU"],
                        eu: ["30EU"],
                        uk: ["2UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU"],
                        eu: ["32EU"],
                        uk: ["4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU"],
                        eu: ["34EU"],
                        uk: ["6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["8AU"],
                        eu: ["36EU"],
                        uk: ["8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU"],
                        eu: ["38EU"],
                        uk: ["10UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["12AU"],
                        eu: ["40EU"],
                        uk: ["12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU"],
                        eu: ["42EU"],
                        uk: ["14UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["16AU"],
                        eu: ["44EU"],
                        uk: ["16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["00"],
                        eu: ["30EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["0"],
                        eu: ["32EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["2"],
                        eu: ["34EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["4"],
                        eu: ["36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6"],
                        eu: ["38EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["8"],
                        eu: ["40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10"],
                        eu: ["42EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["12"],
                        eu: ["44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["00"],
                        au: ["2AU"],
                        uk: ["2UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["4AU"],
                        uk: ["4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["2"],
                        au: ["6AU"],
                        uk: ["6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["4"],
                        au: ["8AU"],
                        uk: ["8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6"],
                        au: ["10AU"],
                        uk: ["10UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["8"],
                        au: ["12AU"],
                        uk: ["12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10"],
                        au: ["14AU"],
                        uk: ["14UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["12"],
                        au: ["16AU"],
                        uk: ["16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["00"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["2"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["4"],
                        au: ["6AU", "8AU"],
                        eu: ["36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["8"],
                        au: ["10AU", "12AU"],
                        eu: ["40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["12"],
                        au: ["14AU", "16AU"],
                        eu: ["44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "1300579fd97b4e26a60056e3",
              name: "Plus",
              tags: ["plus"],
              category_id: "00228975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W", "18,20", "46", "18"],
                  ["16,16W", "18,20,22", "48", "20"],
                  ["18,18W", "20,22", "50", "22"],
                  ["20,20W", "24,26", "50", "24"],
                  ["22,22W", "24,26,28", "52", "26"],
                  ["24,24W", "26,28,30", "54", "28"],
                  ["26,26W", "28,30,32", "56", "30"],
                  ["28,28W", "30,32,34", "58", "32"],
                  ["30,30W", "32,34", "60", "34"],
                  ["30,30W", "36,38", "60", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18", "46", "18"],
                ["14W", "18", "46", "18"],
                ["16", "20", "48", "20"],
                ["16W", "20", "48", "20"],
                ["18", "22", "50", "22"],
                ["18W", "22", "50", "22"],
                ["20", "24", "50", "24"],
                ["20W", "24", "50", "24"],
                ["22", "26", "52", "26"],
                ["22W", "26", "52", "26"],
                ["24", "28", "54", "28"],
                ["24W", "28", "54", "28"],
                ["26", "30", "56", "30"],
                ["26W", "30", "56", "30"],
                ["28", "32", "58", "32"],
                ["28W", "32", "58", "32"],
                ["30", "34,36", "60", "34,36"],
                ["30W", "34,36", "60", "34,36"],
                ["32", "38", "62", "38"],
                ["32W", "38", "62", "38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU"],
                        eu: ["46EU"],
                        uk: ["18UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU"],
                        eu: ["46EU"],
                        uk: ["18UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU"],
                        eu: ["48EU"],
                        uk: ["20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["20AU"],
                        eu: ["48EU"],
                        uk: ["20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["22AU"],
                        eu: ["50EU"],
                        uk: ["22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["22AU"],
                        eu: ["50EU"],
                        uk: ["22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU"],
                        eu: ["50EU"],
                        uk: ["24UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU"],
                        eu: ["50EU"],
                        uk: ["24UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU"],
                        eu: ["52EU"],
                        uk: ["26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["26AU"],
                        eu: ["52EU"],
                        uk: ["26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU"],
                        eu: ["54EU"],
                        uk: ["28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["28AU"],
                        eu: ["54EU"],
                        uk: ["28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU"],
                        eu: ["56EU"],
                        uk: ["30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["30AU"],
                        eu: ["56EU"],
                        uk: ["30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU"],
                        eu: ["58EU"],
                        uk: ["32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["32AU"],
                        eu: ["58EU"],
                        uk: ["32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["38AU"],
                        eu: ["62EU"],
                        uk: ["38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["38AU"],
                        eu: ["62EU"],
                        uk: ["38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W"],
                        eu: ["46EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["16", "16W"],
                        eu: ["48EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["18", "18W"],
                        eu: ["50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W"],
                        eu: ["50EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["22", "22W"],
                        eu: ["52EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["24", "24W"],
                        eu: ["54EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["26", "26W"],
                        eu: ["56EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["28", "28W"],
                        eu: ["58EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["30", "30W"],
                        eu: ["60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W"],
                        eu: ["60EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W"],
                        au: ["18AU"],
                        uk: ["18UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["16", "16W"],
                        au: ["20AU"],
                        uk: ["20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["18", "18W", "20", "20W"],
                        au: ["22AU", "24AU"],
                        uk: ["22UK", "24UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["22", "22W"],
                        au: ["26AU"],
                        uk: ["26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["24", "24W"],
                        au: ["28AU"],
                        uk: ["28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["26", "26W"],
                        au: ["30AU"],
                        uk: ["30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["28", "28W"],
                        au: ["32AU"],
                        uk: ["32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["30", "30W"],
                        au: ["34AU", "36AU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["38AU"],
                        uk: ["38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W"],
                        au: ["18AU", "20AU"],
                        eu: ["46EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["16", "16W"],
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["18", "18W"],
                        au: ["20AU", "22AU"],
                        eu: ["50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W"],
                        au: ["24AU", "26AU"],
                        eu: ["50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["22", "22W"],
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["24", "24W"],
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["26", "26W"],
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["28", "28W"],
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["30", "30W"],
                        au: ["32AU", "34AU"],
                        eu: ["60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W"],
                        au: ["36AU", "38AU"],
                        eu: ["60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "2400579fd97b4e26a60056e3",
              name: "Petite",
              tags: ["petite"],
              category_id: "00228975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P", "2P"],
                  ["0P", "2P,4P,6P", "32P", "4P"],
                  ["2P", "4P,6P,8P", "34P", "6P"],
                  ["4P", "10P,6P,8P", "36P", "8P"],
                  ["6P", "10P,12P,8P", "38P", "10P"],
                  ["8P", "10P,12P,14P", "40P", "12P"],
                  ["10P", "12P,14P,16P", "42P", "14P"],
                  ["12P", "14P,16P,18P", "44P", "16P"],
                  ["14P", "16P,18P,20P", "46P", "18P"],
                  ["16P", "18P,20P,22P", "48P", "20P"],
                  ["18P", "20P,22P,24P", "50P", "22P"],
                  ["20P", "22P,24P", "52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P", "30P", "2P"],
                ["0P", "4P", "32P", "4P"],
                ["2P", "6P", "34P", "6P"],
                ["4P", "8P", "36P", "8P"],
                ["6P", "10P", "38P", "10P"],
                ["8P", "12P", "40P", "12P"],
                ["10P", "14P", "42P", "14P"],
                ["12P", "16P", "44P", "16P"],
                ["14P", "18P", "46P", "18P"],
                ["16P", "20P", "48P", "20P"],
                ["18P", "22P", "50P", "22P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU"],
                        eu: ["30PEU"],
                        uk: ["2PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU"],
                        eu: ["32PEU"],
                        uk: ["4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU"],
                        eu: ["34PEU"],
                        uk: ["6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU"],
                        eu: ["36PEU"],
                        uk: ["8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU"],
                        eu: ["38PEU"],
                        uk: ["10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU"],
                        eu: ["40PEU"],
                        uk: ["12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU"],
                        eu: ["42PEU"],
                        uk: ["14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU"],
                        eu: ["44PEU"],
                        uk: ["16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU"],
                        eu: ["46PEU"],
                        uk: ["18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU"],
                        eu: ["48PEU"],
                        uk: ["20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU"],
                        eu: ["50PEU"],
                        uk: ["22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["0P"],
                        eu: ["32PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["2P"],
                        eu: ["34PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["4P"],
                        eu: ["36PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["6P"],
                        eu: ["38PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["8P"],
                        eu: ["40PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["10P"],
                        eu: ["42PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["12P"],
                        eu: ["44PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["14P"],
                        eu: ["46PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["16P"],
                        eu: ["48PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["18P"],
                        eu: ["50PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["20P"],
                        eu: ["52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU"],
                        uk: ["2PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["0P"],
                        au: ["4PAU"],
                        uk: ["4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["2P"],
                        au: ["6PAU"],
                        uk: ["6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["4P"],
                        au: ["8PAU"],
                        uk: ["8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["6P"],
                        au: ["10PAU"],
                        uk: ["10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["8P"],
                        au: ["12PAU"],
                        uk: ["12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["10P"],
                        au: ["14PAU"],
                        uk: ["14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["12P"],
                        au: ["16PAU"],
                        uk: ["16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["14P"],
                        au: ["18PAU"],
                        uk: ["18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["16P"],
                        au: ["20PAU"],
                        uk: ["20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["18P"],
                        au: ["22PAU"],
                        uk: ["22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["20P"],
                        au: ["24PAU"],
                        uk: ["24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "2a00579fd97b4e26a60056e3",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "00228975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "4,6", "30", "4"],
                  ["0", "4,6,8", "32", "6"],
                  ["1", "6,8", "34", "8"],
                  ["3", "10,11", "34", "10"],
                  ["5", "10,11", "36", "11"],
                  ["7", "12,14", "38", "12"],
                  ["9", "12,14", "40", "14"],
                  ["11", "16,18", "42", "16"],
                  ["13,15", "16,18,20", "44", "18"],
                  ["17", "18,20", "46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "4", "30", "4"],
                ["0", "6", "32", "6"],
                ["1", "8", "34", "8"],
                ["3", "10", "34", "10"],
                ["5", "11", "36", "11"],
                ["7", "12", "38", "12"],
                ["9", "14", "40", "14"],
                ["11", "16", "42", "16"],
                ["13", "18", "44", "18"],
                ["15", "18", "44", "18"],
                ["17", "20", "46", "20"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU"],
                        eu: ["30JEU"],
                        uk: ["4JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU"],
                        eu: ["32JEU"],
                        uk: ["6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["8JAU"],
                        eu: ["34JEU"],
                        uk: ["8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU"],
                        eu: ["34JEU"],
                        uk: ["10JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["11JAU"],
                        eu: ["36JEU"],
                        uk: ["11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU"],
                        eu: ["38JEU"],
                        uk: ["12JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["14JAU"],
                        eu: ["40JEU"],
                        uk: ["14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU"],
                        eu: ["42JEU"],
                        uk: ["16JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["18JAU"],
                        eu: ["44JEU"],
                        uk: ["18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["18JAU"],
                        eu: ["44JEU"],
                        uk: ["18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU"],
                        eu: ["46JEU"],
                        uk: ["20JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J"],
                        eu: ["32JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["1J"],
                        eu: ["34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J"],
                        eu: ["34JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["5J"],
                        eu: ["36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J"],
                        eu: ["38JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["9J"],
                        eu: ["40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J"],
                        eu: ["42JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["13J", "15J"],
                        eu: ["44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["17J"],
                        eu: ["46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU"],
                        uk: ["4JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J"],
                        au: ["6JAU"],
                        uk: ["6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["1J", "3J"],
                        au: ["8JAU", "10JAU"],
                        uk: ["8JUK", "10JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["5J"],
                        au: ["11JAU"],
                        uk: ["11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J"],
                        au: ["12JAU"],
                        uk: ["12JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["9J"],
                        au: ["14JAU"],
                        uk: ["14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J"],
                        au: ["16JAU"],
                        uk: ["16JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["13J", "15J"],
                        au: ["18JAU"],
                        uk: ["18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["17J"],
                        au: ["20JAU"],
                        uk: ["20JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["13J", "15J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "35004308d97b4ea2b60057c7",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "00228975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "women_swim",
          aliases: [],
          slug: "Swim",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
          category_features: [
            {
              id: "00d09287d97b4e80ef00a955",
              display: "Bikinis",
              type: "cf",
              message_id: "women_swim_bikinis",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bikinis",
              alias_slugs: [],
              parent_id: "00228975d97b4e80ef00a955",
            },
            {
              id: "00d49287d97b4e80ef00a955",
              display: "Coverups",
              type: "cf",
              message_id: "women_swim_coverups",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Coverups",
              alias_slugs: [],
              parent_id: "00228975d97b4e80ef00a955",
            },
            {
              id: "00d29287d97b4e80ef00a955",
              display: "One Pieces",
              type: "cf",
              message_id: "women_swim_one_pieces",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "One_Pieces",
              alias_slugs: [],
              parent_id: "00228975d97b4e80ef00a955",
            },
            {
              id: "00d69287d97b4e80ef00a955",
              display: "Sarongs",
              type: "cf",
              message_id: "women_swim_sarongs",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sarongs",
              alias_slugs: [],
              parent_id: "00228975d97b4e80ef00a955",
            },
          ],
        },
        {
          id: "00188975d97b4e80ef00a955",
          display: "Tops",
          size_sets: [
            {
              id: "0900579fd97b4e26a60056e3",
              name: "Standard",
              tags: ["standard"],
              category_id: "00188975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "6AU"],
                        eu: ["32EU", "34EU"],
                        uk: ["4UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["00"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        eu: ["30EU", "32EU", "34EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        eu: ["32EU", "34EU", "36EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["00"],
                        au: ["2AU", "4AU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["00"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["30EU", "32EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["32EU", "34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "0a00579fd97b4e26a60056e3",
              name: "Plus",
              tags: ["plus"],
              category_id: "00188975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34", "58,60,62", "34"],
                  ["30,30W,32,32W", "36,38", "60,62", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU", "28AU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU", "30AU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20", "20W", "22W"],
                        au: ["20AU", "22AU", "24AU", "26AU"],
                        uk: ["20UK", "22UK", "24UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "1f00579fd97b4e26a60056e3",
              name: "Petite",
              tags: ["petite"],
              category_id: "00188975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P,32P", "2P"],
                  ["00P,0P", "2P,4P,6P", "30P,32P,34P", "4P"],
                  ["0P,2P", "4P,6P,8P", "32P,34P,36P", "6P"],
                  ["2P,4P", "10P,6P,8P", "34P,36P,38P", "8P"],
                  ["4P,6P", "10P,12P,8P", "36P,38P,40P", "10P"],
                  ["6P,8P", "10P,12P,14P", "38P,40P,42P", "12P"],
                  ["8P,10P", "12P,14P,16P", "40P,42P,44P", "14P"],
                  ["10P,12P", "14P,16P,18P", "42P,44P,46P", "16P"],
                  ["12P,14P", "16P,18P,20P", "44P,46P,48P", "18P"],
                  ["14P,16P", "18P,20P,22P", "46P,48P,50P", "20P"],
                  ["16P,18P", "20P,22P,24P", "48P,50P,52P", "22P"],
                  ["18P,20P", "22P,24P", "50P,52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P,4P", "30P,32P", "2P,4P"],
                ["0P", "4P,6P", "32P,34P", "4P,6P"],
                ["2P", "6P,8P", "34P,36P", "6P,8P"],
                ["4P", "8P,10P", "36P,38P", "8P,10P"],
                ["6P", "10P,12P", "38P,40P", "10P,12P"],
                ["8P", "12P,14P", "40P,42P", "12P,14P"],
                ["10P", "14P,16P", "42P,44P", "14P,16P"],
                ["12P", "16P,18P", "44P,46P", "16P,18P"],
                ["14P", "18P,20P", "46P,48P", "18P,20P"],
                ["16P", "20P,22P", "48P,50P", "20P,22P"],
                ["18P", "22P,24P", "50P,52P", "22P,24P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "6PAU"],
                        eu: ["32PEU", "34PEU"],
                        uk: ["4PUK", "6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU"],
                        uk: ["6PUK", "8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "10PAU"],
                        eu: ["36PEU", "38PEU"],
                        uk: ["8PUK", "10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "12PAU"],
                        eu: ["38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "14PAU"],
                        eu: ["40PEU", "42PEU"],
                        uk: ["12PUK", "14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "16PAU"],
                        eu: ["42PEU", "44PEU"],
                        uk: ["14PUK", "16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "18PAU"],
                        eu: ["44PEU", "46PEU"],
                        uk: ["16PUK", "18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "20PAU"],
                        eu: ["46PEU", "48PEU"],
                        uk: ["18PUK", "20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "22PAU"],
                        eu: ["48PEU", "50PEU"],
                        uk: ["20PUK", "22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["6PAU", "8PAU", "10PAU"],
                        uk: ["6PUK", "8PUK", "10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["8PAU", "10PAU", "12PAU"],
                        uk: ["8PUK", "10PUK", "12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "2100579fd97b4e26a60056e3",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "00188975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "4,6", "30,32", "4"],
                  ["00,0,1", "4,6,8", "30,32,34", "6"],
                  ["0,1", "6,8", "32,34", "8"],
                  ["3,5", "10,11", "34,36", "10"],
                  ["3,5", "10,11", "34,36", "11"],
                  ["7,9", "12,14", "38,40", "12"],
                  ["7,9", "12,14", "38,40", "14"],
                  ["11,13", "16,18", "42,44", "16"],
                  ["11,13", "16,18,20", "42,44", "18"],
                  ["15,17", "18,20", "44,46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "4,6", "30,32", "4,6"],
                ["0", "6,8", "32,34", "6,8"],
                ["1", "6,8", "32,34", "6,8"],
                ["3", "10,11", "34,36", "10,11"],
                ["5", "10,11", "34,36", "10,11"],
                ["7", "12,14", "38,40", "12,14"],
                ["9", "12,14", "38,40", "12,14"],
                ["11", "16,18", "42,44", "16,18"],
                ["13", "16,18", "42,44", "16,18"],
                ["15", "20,22", "44,46", "20,22"],
                ["17", "20,22", "44,46", "20,22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J", "3J", "5J"],
                        au: ["6JAU", "8JAU", "10JAU", "11JAU"],
                        uk: ["6JUK", "8JUK", "10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J", "15J", "17J"],
                        au: ["16JAU", "18JAU", "20JAU", "22JAU"],
                        uk: ["16JUK", "18JUK", "20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "32004308d97b4ea2b60057c7",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "00188975d97b4e80ef00a955",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "women_tops",
          aliases: [],
          slug: "Tops",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
          category_features: [
            {
              id: "007a9287d97b4e80ef00a955",
              display: "Blouses",
              type: "cf",
              message_id: "women_tops_blouses",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Blouses",
              alias_slugs: [],
              parent_id: "00188975d97b4e80ef00a955",
            },
            {
              id: "7e7df9aaaabb083120f45ec2",
              display: "Bodysuits",
              type: "cf",
              message_id: "women_tops_bodysuits",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bodysuits",
              alias_slugs: [],
              parent_id: "00188975d97b4e80ef00a955",
            },
            {
              id: "007c9287d97b4e80ef00a955",
              display: "Button Down Shirts",
              type: "cf",
              message_id: "women_tops_button_down_shirts",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Button_Down_Shirts",
              alias_slugs: [],
              parent_id: "00188975d97b4e80ef00a955",
            },
            {
              id: "007e9287d97b4e80ef00a955",
              display: "Camisoles",
              type: "cf",
              message_id: "women_tops_camisoles",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Camisoles",
              alias_slugs: [],
              parent_id: "00188975d97b4e80ef00a955",
            },
            {
              id: "00809287d97b4e80ef00a955",
              display: "Crop Tops",
              type: "cf",
              message_id: "women_tops_crop_tops",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Crop_Tops",
              alias_slugs: [],
              parent_id: "00188975d97b4e80ef00a955",
            },
            {
              id: "7f7df9aaaabb083120f45ec2",
              display: "Jerseys",
              type: "cf",
              message_id: "women_tops_jerseys",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Jerseys",
              alias_slugs: [],
              parent_id: "00188975d97b4e80ef00a955",
            },
            {
              id: "00829287d97b4e80ef00a955",
              display: "Muscle Tees",
              type: "cf",
              message_id: "women_tops_muscle_tees",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Muscle_Tees",
              alias_slugs: [],
              parent_id: "00188975d97b4e80ef00a955",
            },
            {
              id: "00849287d97b4e80ef00a955",
              display: "Sweatshirts & Hoodies",
              type: "cf",
              message_id: "women_tops_sweatshirts_hoodies",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sweatshirts_&_Hoodies",
              alias_slugs: [],
              parent_id: "00188975d97b4e80ef00a955",
            },
            {
              id: "00869287d97b4e80ef00a955",
              display: "Tank Tops",
              type: "cf",
              message_id: "women_tops_tank_tops",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Tank_Tops",
              alias_slugs: [],
              parent_id: "00188975d97b4e80ef00a955",
            },
            {
              id: "00889287d97b4e80ef00a955",
              display: "Tees - Long Sleeve",
              type: "cf",
              message_id: "women_tops_tees_long_sleeve",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Tees___Long_Sleeve",
              alias_slugs: [],
              parent_id: "00188975d97b4e80ef00a955",
            },
            {
              id: "008a9287d97b4e80ef00a955",
              display: "Tees - Short Sleeve",
              type: "cf",
              message_id: "women_tops_tees_short_sleeve",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Tees___Short_Sleeve",
              alias_slugs: [],
              parent_id: "00188975d97b4e80ef00a955",
            },
            {
              id: "008c9287d97b4e80ef00a955",
              display: "Tunics",
              type: "cf",
              message_id: "women_tops_tunics",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Tunics",
              alias_slugs: [],
              parent_id: "00188975d97b4e80ef00a955",
            },
          ],
        },
        {
          id: "002e8975d97b4e80ef00a955",
          display: "Other",
          size_sets: [
            {
              id: "1900579fd97b4e26a60056e3",
              name: "Standard",
              tags: ["standard"],
              category_id: "002e8975d97b4e80ef00a955",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "women_other",
          aliases: [],
          slug: "Other",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
        },
        {
          id: "00288975d97b4e80ef00a955",
          display: "Jewelry",
          size_sets: [
            {
              id: "1600579fd97b4e26a60056e3",
              name: "Standard",
              tags: ["standard"],
              category_id: "00288975d97b4e80ef00a955",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "women_jewelry",
          aliases: [],
          slug: "Jewelry",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
          category_features: [
            {
              id: "01149287d97b4e80ef00a955",
              display: "Bracelets",
              type: "cf",
              message_id: "women_jewelry_bracelets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bracelets",
              alias_slugs: [],
              parent_id: "00288975d97b4e80ef00a955",
            },
            {
              id: "011c9287d97b4e80ef00a955",
              display: "Brooches",
              type: "cf",
              message_id: "women_jewelry_brooches",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Brooches",
              alias_slugs: [],
              parent_id: "00288975d97b4e80ef00a955",
            },
            {
              id: "01169287d97b4e80ef00a955",
              display: "Earrings",
              type: "cf",
              message_id: "women_jewelry_earrings",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Earrings",
              alias_slugs: [],
              parent_id: "00288975d97b4e80ef00a955",
            },
            {
              id: "01189287d97b4e80ef00a955",
              display: "Necklaces",
              type: "cf",
              message_id: "women_jewelry_necklaces",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Necklaces",
              alias_slugs: [],
              parent_id: "00288975d97b4e80ef00a955",
            },
            {
              id: "011a9287d97b4e80ef00a955",
              display: "Rings",
              type: "cf",
              message_id: "women_jewelry_rings",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Rings",
              alias_slugs: [],
              parent_id: "00288975d97b4e80ef00a955",
            },
          ],
        },
        {
          id: "002c8975d97b4e80ef00a955",
          display: "Makeup",
          size_sets: [
            {
              id: "1800579fd97b4e26a60056e3",
              name: "Standard",
              tags: ["standard"],
              category_id: "002c8975d97b4e80ef00a955",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "women_makeup",
          aliases: [],
          slug: "Makeup",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
          category_features: [
            {
              id: "013a9287d97b4e80ef00a955",
              display: "Blush",
              type: "cf",
              message_id: "women_makeup_blush",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Blush",
              alias_slugs: [],
              parent_id: "002c8975d97b4e80ef00a955",
            },
            {
              id: "013c9287d97b4e80ef00a955",
              display: "Bronzer & Contour",
              type: "cf",
              aliases: ["Bronzer"],
              message_id: "women_makeup_bronzer_contour",
              size_sets: [],
              sizes: [],
              slug: "Bronzer_&_Contour",
              alias_slugs: ["Bronzer"],
              parent_id: "002c8975d97b4e80ef00a955",
            },
            {
              id: "01429287d97b4e80ef00a955",
              display: "Brows",
              type: "cf",
              aliases: ["Eyebrow Filler"],
              message_id: "women_makeup_brows",
              size_sets: [],
              sizes: [],
              slug: "Brows",
              alias_slugs: ["Eyebrow_Filler"],
              parent_id: "002c8975d97b4e80ef00a955",
            },
            {
              id: "015a9287d97b4e80ef00a955",
              display: "Brushes & Tools",
              type: "cf",
              message_id: "women_makeup_brushes_tools",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Brushes_&_Tools",
              alias_slugs: [],
              parent_id: "002c8975d97b4e80ef00a955",
            },
            {
              id: "013e9287d97b4e80ef00a955",
              display: "Concealer",
              type: "cf",
              message_id: "women_makeup_concealer",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Concealer",
              alias_slugs: [],
              parent_id: "002c8975d97b4e80ef00a955",
            },
            {
              id: "01409287d97b4e80ef00a955",
              display: "Eye Primer",
              type: "cf",
              message_id: "women_makeup_eye_primer",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Eye_Primer",
              alias_slugs: [],
              parent_id: "002c8975d97b4e80ef00a955",
            },
            {
              id: "01449287d97b4e80ef00a955",
              display: "Eyeliner",
              type: "cf",
              message_id: "women_makeup_eyeliner",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Eyeliner",
              alias_slugs: [],
              parent_id: "002c8975d97b4e80ef00a955",
            },
            {
              id: "01469287d97b4e80ef00a955",
              display: "Eyeshadow",
              type: "cf",
              message_id: "women_makeup_eyeshadow",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Eyeshadow",
              alias_slugs: [],
              parent_id: "002c8975d97b4e80ef00a955",
            },
            {
              id: "014e9287d97b4e80ef00a955",
              display: "Foundation",
              type: "cf",
              message_id: "women_makeup_foundation",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Foundation",
              alias_slugs: [],
              parent_id: "002c8975d97b4e80ef00a955",
            },
            {
              id: "01569287d97b4e80ef00a955",
              display: "Highlighter",
              type: "cf",
              aliases: ["Luminizer"],
              message_id: "women_makeup_highlighter",
              size_sets: [],
              sizes: [],
              slug: "Highlighter",
              alias_slugs: ["Luminizer"],
              parent_id: "002c8975d97b4e80ef00a955",
            },
            {
              id: "014c9287d97b4e80ef00a955",
              display: "Lashes",
              type: "cf",
              aliases: ["False Eyelashes"],
              message_id: "women_makeup_lashes",
              size_sets: [],
              sizes: [],
              slug: "Lashes",
              alias_slugs: ["False_Eyelashes"],
              parent_id: "002c8975d97b4e80ef00a955",
            },
            {
              id: "01509287d97b4e80ef00a955",
              display: "Lip Balm & Gloss",
              type: "cf",
              message_id: "women_makeup_lip_balm_gloss",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Lip_Balm_&_Gloss",
              alias_slugs: [],
              parent_id: "002c8975d97b4e80ef00a955",
            },
            {
              id: "01529287d97b4e80ef00a955",
              display: "Lip Liner",
              type: "cf",
              message_id: "women_makeup_lip_liner",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Lip_Liner",
              alias_slugs: [],
              parent_id: "002c8975d97b4e80ef00a955",
            },
            {
              id: "01549287d97b4e80ef00a955",
              display: "Lipstick",
              type: "cf",
              message_id: "women_makeup_lipstick",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Lipstick",
              alias_slugs: [],
              parent_id: "002c8975d97b4e80ef00a955",
            },
            {
              id: "01589287d97b4e80ef00a955",
              display: "Mascara",
              type: "cf",
              message_id: "women_makeup_mascara",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Mascara",
              alias_slugs: [],
              parent_id: "002c8975d97b4e80ef00a955",
            },
            {
              id: "897df9aaaabb083120f45ec2",
              display: "Nail Tools",
              type: "cf",
              message_id: "women_makeup_nail_tools",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Nail_Tools",
              alias_slugs: [],
              parent_id: "002c8975d97b4e80ef00a955",
            },
            {
              id: "887df9aaaabb083120f45ec2",
              display: "Press-On Nails",
              type: "cf",
              message_id: "women_makeup_presson_nails",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Press_On_Nails",
              alias_slugs: [],
              parent_id: "002c8975d97b4e80ef00a955",
            },
            {
              id: "014a9287d97b4e80ef00a955",
              display: "Primer",
              type: "cf",
              aliases: ["Face Primer"],
              message_id: "women_makeup_primer",
              size_sets: [],
              sizes: [],
              slug: "Primer",
              alias_slugs: ["Face_Primer"],
              parent_id: "002c8975d97b4e80ef00a955",
            },
            {
              id: "01489287d97b4e80ef00a955",
              display: "Setting Powder & Spray",
              type: "cf",
              aliases: ["Face Powder"],
              message_id: "women_makeup_setting_powder_spray",
              size_sets: [],
              sizes: [],
              slug: "Setting_Powder_&_Spray",
              alias_slugs: ["Face_Powder"],
              parent_id: "002c8975d97b4e80ef00a955",
            },
          ],
        },
        {
          id: "6e7df9aaaabb083120f45ec2",
          display: "Skincare",
          type: "c",
          size_sets: [
            {
              id: "cc7df9aaaabb083120f45ec2",
              name: "Standard",
              tags: ["standard"],
              category_id: "6e7df9aaaabb083120f45ec2",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_skincare",
          aliases: [],
          slug: "Skincare",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
          category_features: [
            {
              id: "8a7df9aaaabb083120f45ec2",
              display: "Acne & Blemish",
              type: "cf",
              message_id: "women_skincare_acne_blemish",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Acne_&_Blemish",
              alias_slugs: [],
              parent_id: "6e7df9aaaabb083120f45ec2",
            },
            {
              id: "8b7df9aaaabb083120f45ec2",
              display: "Cleanser & Exfoliant",
              type: "cf",
              message_id: "women_skincare_cleanser_exfoliant",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cleanser_&_Exfoliant",
              alias_slugs: [],
              parent_id: "6e7df9aaaabb083120f45ec2",
            },
            {
              id: "8c7df9aaaabb083120f45ec2",
              display: "Eye Cream",
              type: "cf",
              message_id: "women_skincare_eye_cream",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Eye_Cream",
              alias_slugs: [],
              parent_id: "6e7df9aaaabb083120f45ec2",
            },
            {
              id: "957df9aaaabb083120f45ec2",
              display: "Makeup Remover",
              type: "cf",
              message_id: "women_skincare_makeup_remover",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Makeup_Remover",
              alias_slugs: [],
              parent_id: "6e7df9aaaabb083120f45ec2",
            },
            {
              id: "8d7df9aaaabb083120f45ec2",
              display: "Mask",
              type: "cf",
              message_id: "women_skincare_mask",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Mask",
              alias_slugs: [],
              parent_id: "6e7df9aaaabb083120f45ec2",
            },
            {
              id: "8e7df9aaaabb083120f45ec2",
              display: "Moisturizer",
              type: "cf",
              message_id: "women_skincare_moisturizer",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Moisturizer",
              alias_slugs: [],
              parent_id: "6e7df9aaaabb083120f45ec2",
            },
            {
              id: "8f7df9aaaabb083120f45ec2",
              display: "Peel",
              type: "cf",
              message_id: "women_skincare_peel",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Peel",
              alias_slugs: [],
              parent_id: "6e7df9aaaabb083120f45ec2",
            },
            {
              id: "907df9aaaabb083120f45ec2",
              display: "Serum & Face Oil",
              type: "cf",
              message_id: "women_skincare_serum_face_oil",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Serum_&_Face_Oil",
              alias_slugs: [],
              parent_id: "6e7df9aaaabb083120f45ec2",
            },
            {
              id: "927df9aaaabb083120f45ec2",
              display: "Suncare",
              type: "cf",
              message_id: "women_skincare_suncare",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Suncare",
              alias_slugs: [],
              parent_id: "6e7df9aaaabb083120f45ec2",
            },
            {
              id: "937df9aaaabb083120f45ec2",
              display: "Toner",
              type: "cf",
              message_id: "women_skincare_toner",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Toner",
              alias_slugs: [],
              parent_id: "6e7df9aaaabb083120f45ec2",
            },
            {
              id: "947df9aaaabb083120f45ec2",
              display: "Tools",
              type: "cf",
              message_id: "women_skincare_tools",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Tools",
              alias_slugs: [],
              parent_id: "6e7df9aaaabb083120f45ec2",
            },
          ],
        },
        {
          id: "6f7df9aaaabb083120f45ec2",
          display: "Hair",
          type: "c",
          size_sets: [
            {
              id: "cd7df9aaaabb083120f45ec2",
              name: "Standard",
              tags: ["standard"],
              category_id: "6f7df9aaaabb083120f45ec2",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_hair",
          aliases: [],
          slug: "Hair",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
          category_features: [
            {
              id: "967df9aaaabb083120f45ec2",
              display: "Color",
              type: "cf",
              message_id: "women_hair_color",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Color",
              alias_slugs: [],
              parent_id: "6f7df9aaaabb083120f45ec2",
            },
            {
              id: "977df9aaaabb083120f45ec2",
              display: "Conditioner",
              type: "cf",
              message_id: "women_hair_conditioner",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Conditioner",
              alias_slugs: [],
              parent_id: "6f7df9aaaabb083120f45ec2",
            },
            {
              id: "987df9aaaabb083120f45ec2",
              display: "Hairspray",
              type: "cf",
              message_id: "women_hair_hairspray",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Hairspray",
              alias_slugs: [],
              parent_id: "6f7df9aaaabb083120f45ec2",
            },
            {
              id: "997df9aaaabb083120f45ec2",
              display: "Heat Protectant",
              type: "cf",
              message_id: "women_hair_heat_protectant",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Heat_Protectant",
              alias_slugs: [],
              parent_id: "6f7df9aaaabb083120f45ec2",
            },
            {
              id: "9a7df9aaaabb083120f45ec2",
              display: "Shampoo",
              type: "cf",
              message_id: "women_hair_shampoo",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Shampoo",
              alias_slugs: [],
              parent_id: "6f7df9aaaabb083120f45ec2",
            },
            {
              id: "9d7df9aaaabb083120f45ec2",
              display: "Styling",
              type: "cf",
              message_id: "women_hair_styling",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Styling",
              alias_slugs: [],
              parent_id: "6f7df9aaaabb083120f45ec2",
            },
            {
              id: "9b7df9aaaabb083120f45ec2",
              display: "Tools",
              type: "cf",
              message_id: "women_hair_tools",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Tools",
              alias_slugs: [],
              parent_id: "6f7df9aaaabb083120f45ec2",
            },
            {
              id: "9c7df9aaaabb083120f45ec2",
              display: "Treatment & Mask",
              type: "cf",
              message_id: "women_hair_treatment_mask",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Treatment_&_Mask",
              alias_slugs: [],
              parent_id: "6f7df9aaaabb083120f45ec2",
            },
            {
              id: "9e7df9aaaabb083120f45ec2",
              display: "Wigs & Extensions",
              type: "cf",
              message_id: "women_hair_wigs_extensions",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Wigs_&_Extensions",
              alias_slugs: [],
              parent_id: "6f7df9aaaabb083120f45ec2",
            },
          ],
        },
        {
          id: "707df9aaaabb083120f45ec2",
          display: "Bath & Body",
          type: "c",
          size_sets: [
            {
              id: "ce7df9aaaabb083120f45ec2",
              name: "Standard",
              tags: ["standard"],
              category_id: "707df9aaaabb083120f45ec2",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_bath_body",
          aliases: [],
          slug: "Bath_&_Body",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
          category_features: [
            {
              id: "9f7df9aaaabb083120f45ec2",
              display: "Bath Soak & Bubbles",
              type: "cf",
              message_id: "women_bath_body_bath_soak_bubbles",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bath_Soak_&_Bubbles",
              alias_slugs: [],
              parent_id: "707df9aaaabb083120f45ec2",
            },
            {
              id: "a17df9aaaabb083120f45ec2",
              display: "Body Wash",
              type: "cf",
              message_id: "women_bath_body_body_wash",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Body_Wash",
              alias_slugs: [],
              parent_id: "707df9aaaabb083120f45ec2",
            },
            {
              id: "a27df9aaaabb083120f45ec2",
              display: "Exfoliant & Scrub",
              type: "cf",
              message_id: "women_bath_body_exfoliant_scrub",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Exfoliant_&_Scrub",
              alias_slugs: [],
              parent_id: "707df9aaaabb083120f45ec2",
            },
            {
              id: "a37df9aaaabb083120f45ec2",
              display: "Hair Removal",
              type: "cf",
              message_id: "women_bath_body_hair_removal",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Hair_Removal",
              alias_slugs: [],
              parent_id: "707df9aaaabb083120f45ec2",
            },
            {
              id: "a47df9aaaabb083120f45ec2",
              display: "Hand & Foot Care",
              type: "cf",
              message_id: "women_bath_body_hand_foot_care",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Hand_&_Foot_Care",
              alias_slugs: [],
              parent_id: "707df9aaaabb083120f45ec2",
            },
            {
              id: "a77df9aaaabb083120f45ec2",
              display: "Hand Soap",
              type: "cf",
              message_id: "women_bath_body_hand_soap",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Hand_Soap",
              alias_slugs: [],
              parent_id: "707df9aaaabb083120f45ec2",
            },
            {
              id: "a07df9aaaabb083120f45ec2",
              display: "Moisturizer & Body Oil",
              type: "cf",
              message_id: "women_bath_body_moisturizer_body_oil",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Moisturizer_&_Body_Oil",
              alias_slugs: [],
              parent_id: "707df9aaaabb083120f45ec2",
            },
            {
              id: "a57df9aaaabb083120f45ec2",
              display: "Suncare & Tanning",
              type: "cf",
              message_id: "women_bath_body_suncare_tanning",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Suncare_&_Tanning",
              alias_slugs: [],
              parent_id: "707df9aaaabb083120f45ec2",
            },
            {
              id: "a67df9aaaabb083120f45ec2",
              display: "Tools",
              type: "cf",
              message_id: "women_bath_body_tools",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Tools",
              alias_slugs: [],
              parent_id: "707df9aaaabb083120f45ec2",
            },
          ],
        },
        {
          id: "9ab476dc402403bf28a2606b",
          display: "Ao Dais",
          type: "c",
          size_sets: [
            {
              id: "f3b476dd402403bf28a2606b",
              name: "Standard",
              tags: ["standard"],
              category_id: "9ab476dc402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "6AU"],
                        eu: ["32EU", "34EU"],
                        uk: ["4UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["0"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        eu: ["30EU", "32EU", "34EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        eu: ["32EU", "34EU", "36EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["30EU", "32EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["32EU", "34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "f4b476dd402403bf28a2606b",
              name: "Plus",
              tags: ["plus"],
              category_id: "9ab476dc402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34", "58,60,62", "34"],
                  ["30,30W,32,32W", "36,38", "60,62", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU", "28AU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU", "30AU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20", "20W", "22W"],
                        au: ["20AU", "22AU", "24AU", "26AU"],
                        uk: ["20UK", "22UK", "24UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "f5b476dd402403bf28a2606b",
              name: "Petite",
              tags: ["petite"],
              category_id: "9ab476dc402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P,32P", "2P"],
                  ["00P,0P", "2P,4P,6P", "30P,32P,34P", "4P"],
                  ["0P,2P", "4P,6P,8P", "32P,34P,36P", "6P"],
                  ["2P,4P", "10P,6P,8P", "34P,36P,38P", "8P"],
                  ["4P,6P", "10P,12P,8P", "36P,38P,40P", "10P"],
                  ["6P,8P", "10P,12P,14P", "38P,40P,42P", "12P"],
                  ["8P,10P", "12P,14P,16P", "40P,42P,44P", "14P"],
                  ["10P,12P", "14P,16P,18P", "42P,44P,46P", "16P"],
                  ["12P,14P", "16P,18P,20P", "44P,46P,48P", "18P"],
                  ["14P,16P", "18P,20P,22P", "46P,48P,50P", "20P"],
                  ["16P,18P", "20P,22P,24P", "48P,50P,52P", "22P"],
                  ["18P,20P", "22P,24P", "50P,52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P,4P", "30P,32P", "2P,4P"],
                ["0P", "4P,6P", "32P,34P", "4P,6P"],
                ["2P", "6P,8P", "34P,36P", "6P,8P"],
                ["4P", "8P,10P", "36P,38P", "8P,10P"],
                ["6P", "10P,12P", "38P,40P", "10P,12P"],
                ["8P", "12P,14P", "40P,42P", "12P,14P"],
                ["10P", "14P,16P", "42P,44P", "14P,16P"],
                ["12P", "16P,18P", "44P,46P", "16P,18P"],
                ["14P", "18P,20P", "46P,48P", "18P,20P"],
                ["16P", "20P,22P", "48P,50P", "20P,22P"],
                ["18P", "22P,24P", "50P,52P", "22P,24P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "6PAU"],
                        eu: ["32PEU", "34PEU"],
                        uk: ["4PUK", "6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU"],
                        uk: ["6PUK", "8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "10PAU"],
                        eu: ["36PEU", "38PEU"],
                        uk: ["8PUK", "10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "12PAU"],
                        eu: ["38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "14PAU"],
                        eu: ["40PEU", "42PEU"],
                        uk: ["12PUK", "14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "16PAU"],
                        eu: ["42PEU", "44PEU"],
                        uk: ["14PUK", "16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "18PAU"],
                        eu: ["44PEU", "46PEU"],
                        uk: ["16PUK", "18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "20PAU"],
                        eu: ["46PEU", "48PEU"],
                        uk: ["18PUK", "20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "22PAU"],
                        eu: ["48PEU", "50PEU"],
                        uk: ["20PUK", "22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["6PAU", "8PAU", "10PAU"],
                        uk: ["6PUK", "8PUK", "10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["8PAU", "10PAU", "12PAU"],
                        uk: ["8PUK", "10PUK", "12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "f6b476dd402403bf28a2606b",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "9ab476dc402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "4,6", "30,32", "4"],
                  ["0,0,1", "4,6,8", "30,32,34", "6"],
                  ["0,1", "6,8", "32,34", "8"],
                  ["3,5", "10,11", "34,36", "10"],
                  ["3,5", "10,11", "34,36", "11"],
                  ["7,9", "12,14", "38,40", "12"],
                  ["7,9", "12,14", "38,40", "14"],
                  ["11,13", "16,18", "42,44", "16"],
                  ["11,13", "16,18,20", "42,44", "18"],
                  ["15,17", "18,20", "44,46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["0", "4,6", "30,32", "4,6"],
                ["0", "6,8", "32,34", "6,8"],
                ["1", "6,8", "32,34", "6,8"],
                ["3", "10,11", "34,36", "10,11"],
                ["5", "10,11", "34,36", "10,11"],
                ["7", "12,14", "38,40", "12,14"],
                ["9", "12,14", "38,40", "12,14"],
                ["11", "16,18", "42,44", "16,18"],
                ["13", "16,18", "42,44", "16,18"],
                ["15", "20,22", "44,46", "20,22"],
                ["17", "20,22", "44,46", "20,22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J", "3J", "5J"],
                        au: ["6JAU", "8JAU", "10JAU", "11JAU"],
                        uk: ["6JUK", "8JUK", "10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J", "15J", "17J"],
                        au: ["16JAU", "18JAU", "20JAU", "22JAU"],
                        uk: ["16JUK", "18JUK", "20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "f7b476dd402403bf28a2606b",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "9ab476dc402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_ao_dais",
          aliases: [],
          slug: "Ao_Dais",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
        },
        {
          id: "9bb476dc402403bf28a2606b",
          display: "Cheongsams & Qipaos",
          type: "c",
          size_sets: [
            {
              id: "f8b476dd402403bf28a2606b",
              name: "Standard",
              tags: ["standard"],
              category_id: "9bb476dc402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "6AU"],
                        eu: ["32EU", "34EU"],
                        uk: ["4UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["0"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        eu: ["30EU", "32EU", "34EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        eu: ["32EU", "34EU", "36EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["30EU", "32EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["32EU", "34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "f9b476dd402403bf28a2606b",
              name: "Plus",
              tags: ["plus"],
              category_id: "9bb476dc402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34", "58,60,62", "34"],
                  ["30,30W,32,32W", "36,38", "60,62", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU", "28AU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU", "30AU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20", "20W", "22W"],
                        au: ["20AU", "22AU", "24AU", "26AU"],
                        uk: ["20UK", "22UK", "24UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "fab476dd402403bf28a2606b",
              name: "Petite",
              tags: ["petite"],
              category_id: "9bb476dc402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P,32P", "2P"],
                  ["00P,0P", "2P,4P,6P", "30P,32P,34P", "4P"],
                  ["0P,2P", "4P,6P,8P", "32P,34P,36P", "6P"],
                  ["2P,4P", "10P,6P,8P", "34P,36P,38P", "8P"],
                  ["4P,6P", "10P,12P,8P", "36P,38P,40P", "10P"],
                  ["6P,8P", "10P,12P,14P", "38P,40P,42P", "12P"],
                  ["8P,10P", "12P,14P,16P", "40P,42P,44P", "14P"],
                  ["10P,12P", "14P,16P,18P", "42P,44P,46P", "16P"],
                  ["12P,14P", "16P,18P,20P", "44P,46P,48P", "18P"],
                  ["14P,16P", "18P,20P,22P", "46P,48P,50P", "20P"],
                  ["16P,18P", "20P,22P,24P", "48P,50P,52P", "22P"],
                  ["18P,20P", "22P,24P", "50P,52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P,4P", "30P,32P", "2P,4P"],
                ["0P", "4P,6P", "32P,34P", "4P,6P"],
                ["2P", "6P,8P", "34P,36P", "6P,8P"],
                ["4P", "8P,10P", "36P,38P", "8P,10P"],
                ["6P", "10P,12P", "38P,40P", "10P,12P"],
                ["8P", "12P,14P", "40P,42P", "12P,14P"],
                ["10P", "14P,16P", "42P,44P", "14P,16P"],
                ["12P", "16P,18P", "44P,46P", "16P,18P"],
                ["14P", "18P,20P", "46P,48P", "18P,20P"],
                ["16P", "20P,22P", "48P,50P", "20P,22P"],
                ["18P", "22P,24P", "50P,52P", "22P,24P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "6PAU"],
                        eu: ["32PEU", "34PEU"],
                        uk: ["4PUK", "6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU"],
                        uk: ["6PUK", "8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "10PAU"],
                        eu: ["36PEU", "38PEU"],
                        uk: ["8PUK", "10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "12PAU"],
                        eu: ["38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "14PAU"],
                        eu: ["40PEU", "42PEU"],
                        uk: ["12PUK", "14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "16PAU"],
                        eu: ["42PEU", "44PEU"],
                        uk: ["14PUK", "16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "18PAU"],
                        eu: ["44PEU", "46PEU"],
                        uk: ["16PUK", "18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "20PAU"],
                        eu: ["46PEU", "48PEU"],
                        uk: ["18PUK", "20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "22PAU"],
                        eu: ["48PEU", "50PEU"],
                        uk: ["20PUK", "22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["6PAU", "8PAU", "10PAU"],
                        uk: ["6PUK", "8PUK", "10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["8PAU", "10PAU", "12PAU"],
                        uk: ["8PUK", "10PUK", "12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "fbb476dd402403bf28a2606b",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "9bb476dc402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "4,6", "30,32", "4"],
                  ["0,0,1", "4,6,8", "30,32,34", "6"],
                  ["0,1", "6,8", "32,34", "8"],
                  ["3,5", "10,11", "34,36", "10"],
                  ["3,5", "10,11", "34,36", "11"],
                  ["7,9", "12,14", "38,40", "12"],
                  ["7,9", "12,14", "38,40", "14"],
                  ["11,13", "16,18", "42,44", "16"],
                  ["11,13", "16,18,20", "42,44", "18"],
                  ["15,17", "18,20", "44,46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["0", "4,6", "30,32", "4,6"],
                ["0", "6,8", "32,34", "6,8"],
                ["1", "6,8", "32,34", "6,8"],
                ["3", "10,11", "34,36", "10,11"],
                ["5", "10,11", "34,36", "10,11"],
                ["7", "12,14", "38,40", "12,14"],
                ["9", "12,14", "38,40", "12,14"],
                ["11", "16,18", "42,44", "16,18"],
                ["13", "16,18", "42,44", "16,18"],
                ["15", "20,22", "44,46", "20,22"],
                ["17", "20,22", "44,46", "20,22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J", "3J", "5J"],
                        au: ["6JAU", "8JAU", "10JAU", "11JAU"],
                        uk: ["6JUK", "8JUK", "10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J", "15J", "17J"],
                        au: ["16JAU", "18JAU", "20JAU", "22JAU"],
                        uk: ["16JUK", "18JUK", "20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "fcb476dd402403bf28a2606b",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "9bb476dc402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_cheongsams_qipaos",
          aliases: [],
          slug: "Cheongsams_&_Qipaos",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
        },
        {
          id: "9db476dc402403bf28a2606b",
          display: "Dirndls",
          type: "c",
          size_sets: [
            {
              id: "9d9ecb3e402403cd9fcf606c",
              name: "Standard",
              tags: ["standard"],
              category_id: "9db476dc402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "6AU"],
                        eu: ["32EU", "34EU"],
                        uk: ["4UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["0"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        eu: ["30EU", "32EU", "34EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        eu: ["32EU", "34EU", "36EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["30EU", "32EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["32EU", "34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "9e9ecb3e402403cd9fcf606c",
              name: "Plus",
              tags: ["plus"],
              category_id: "9db476dc402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34", "58,60,62", "34"],
                  ["30,30W,32,32W", "36,38", "60,62", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU", "28AU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU", "30AU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20", "20W", "22W"],
                        au: ["20AU", "22AU", "24AU", "26AU"],
                        uk: ["20UK", "22UK", "24UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "9f9ecb3e402403cd9fcf606c",
              name: "Petite",
              tags: ["petite"],
              category_id: "9db476dc402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P,32P", "2P"],
                  ["00P,0P", "2P,4P,6P", "30P,32P,34P", "4P"],
                  ["0P,2P", "4P,6P,8P", "32P,34P,36P", "6P"],
                  ["2P,4P", "10P,6P,8P", "34P,36P,38P", "8P"],
                  ["4P,6P", "10P,12P,8P", "36P,38P,40P", "10P"],
                  ["6P,8P", "10P,12P,14P", "38P,40P,42P", "12P"],
                  ["8P,10P", "12P,14P,16P", "40P,42P,44P", "14P"],
                  ["10P,12P", "14P,16P,18P", "42P,44P,46P", "16P"],
                  ["12P,14P", "16P,18P,20P", "44P,46P,48P", "18P"],
                  ["14P,16P", "18P,20P,22P", "46P,48P,50P", "20P"],
                  ["16P,18P", "20P,22P,24P", "48P,50P,52P", "22P"],
                  ["18P,20P", "22P,24P", "50P,52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P,4P", "30P,32P", "2P,4P"],
                ["0P", "4P,6P", "32P,34P", "4P,6P"],
                ["2P", "6P,8P", "34P,36P", "6P,8P"],
                ["4P", "8P,10P", "36P,38P", "8P,10P"],
                ["6P", "10P,12P", "38P,40P", "10P,12P"],
                ["8P", "12P,14P", "40P,42P", "12P,14P"],
                ["10P", "14P,16P", "42P,44P", "14P,16P"],
                ["12P", "16P,18P", "44P,46P", "16P,18P"],
                ["14P", "18P,20P", "46P,48P", "18P,20P"],
                ["16P", "20P,22P", "48P,50P", "20P,22P"],
                ["18P", "22P,24P", "50P,52P", "22P,24P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "6PAU"],
                        eu: ["32PEU", "34PEU"],
                        uk: ["4PUK", "6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU"],
                        uk: ["6PUK", "8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "10PAU"],
                        eu: ["36PEU", "38PEU"],
                        uk: ["8PUK", "10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "12PAU"],
                        eu: ["38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "14PAU"],
                        eu: ["40PEU", "42PEU"],
                        uk: ["12PUK", "14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "16PAU"],
                        eu: ["42PEU", "44PEU"],
                        uk: ["14PUK", "16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "18PAU"],
                        eu: ["44PEU", "46PEU"],
                        uk: ["16PUK", "18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "20PAU"],
                        eu: ["46PEU", "48PEU"],
                        uk: ["18PUK", "20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "22PAU"],
                        eu: ["48PEU", "50PEU"],
                        uk: ["20PUK", "22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["6PAU", "8PAU", "10PAU"],
                        uk: ["6PUK", "8PUK", "10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["8PAU", "10PAU", "12PAU"],
                        uk: ["8PUK", "10PUK", "12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "a09ecb3e402403cd9fcf606c",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "9db476dc402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "4,6", "30,32", "4"],
                  ["0,0,1", "4,6,8", "30,32,34", "6"],
                  ["0,1", "6,8", "32,34", "8"],
                  ["3,5", "10,11", "34,36", "10"],
                  ["3,5", "10,11", "34,36", "11"],
                  ["7,9", "12,14", "38,40", "12"],
                  ["7,9", "12,14", "38,40", "14"],
                  ["11,13", "16,18", "42,44", "16"],
                  ["11,13", "16,18,20", "42,44", "18"],
                  ["15,17", "18,20", "44,46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["0", "4,6", "30,32", "4,6"],
                ["0", "6,8", "32,34", "6,8"],
                ["1", "6,8", "32,34", "6,8"],
                ["3", "10,11", "34,36", "10,11"],
                ["5", "10,11", "34,36", "10,11"],
                ["7", "12,14", "38,40", "12,14"],
                ["9", "12,14", "38,40", "12,14"],
                ["11", "16,18", "42,44", "16,18"],
                ["13", "16,18", "42,44", "16,18"],
                ["15", "20,22", "44,46", "20,22"],
                ["17", "20,22", "44,46", "20,22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J", "3J", "5J"],
                        au: ["6JAU", "8JAU", "10JAU", "11JAU"],
                        uk: ["6JUK", "8JUK", "10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J", "15J", "17J"],
                        au: ["16JAU", "18JAU", "20JAU", "22JAU"],
                        uk: ["16JUK", "18JUK", "20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "a19ecb3e402403cd9fcf606c",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "9db476dc402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_dirndls",
          aliases: [],
          slug: "Dirndls",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
        },
        {
          id: "a2b476dd402403bf28a2606b",
          display: "Dupattas & Stoles",
          type: "c",
          size_sets: [
            {
              id: "bcb476dd402403bf28a2606b",
              name: "Standard",
              tags: ["standard"],
              category_id: "a2b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_dupattas_stoles",
          aliases: [],
          slug: "Dupattas_&_Stoles",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
        },
        {
          id: "9cb476dc402403bf28a2606b",
          display: "Hanboks",
          type: "c",
          size_sets: [
            {
              id: "fdb476dd402403bf28a2606b",
              name: "Standard",
              tags: ["standard"],
              category_id: "9cb476dc402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "6AU"],
                        eu: ["32EU", "34EU"],
                        uk: ["4UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["0"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        eu: ["30EU", "32EU", "34EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        eu: ["32EU", "34EU", "36EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["30EU", "32EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["32EU", "34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "ffb476dd402403bf28a2606b",
              name: "Plus",
              tags: ["plus"],
              category_id: "9cb476dc402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34", "58,60,62", "34"],
                  ["30,30W,32,32W", "36,38", "60,62", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU", "28AU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU", "30AU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20", "20W", "22W"],
                        au: ["20AU", "22AU", "24AU", "26AU"],
                        uk: ["20UK", "22UK", "24UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "9a9ecb3e402403cd9fcf606c",
              name: "Petite",
              tags: ["petite"],
              category_id: "9cb476dc402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P,32P", "2P"],
                  ["00P,0P", "2P,4P,6P", "30P,32P,34P", "4P"],
                  ["0P,2P", "4P,6P,8P", "32P,34P,36P", "6P"],
                  ["2P,4P", "10P,6P,8P", "34P,36P,38P", "8P"],
                  ["4P,6P", "10P,12P,8P", "36P,38P,40P", "10P"],
                  ["6P,8P", "10P,12P,14P", "38P,40P,42P", "12P"],
                  ["8P,10P", "12P,14P,16P", "40P,42P,44P", "14P"],
                  ["10P,12P", "14P,16P,18P", "42P,44P,46P", "16P"],
                  ["12P,14P", "16P,18P,20P", "44P,46P,48P", "18P"],
                  ["14P,16P", "18P,20P,22P", "46P,48P,50P", "20P"],
                  ["16P,18P", "20P,22P,24P", "48P,50P,52P", "22P"],
                  ["18P,20P", "22P,24P", "50P,52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P,4P", "30P,32P", "2P,4P"],
                ["0P", "4P,6P", "32P,34P", "4P,6P"],
                ["2P", "6P,8P", "34P,36P", "6P,8P"],
                ["4P", "8P,10P", "36P,38P", "8P,10P"],
                ["6P", "10P,12P", "38P,40P", "10P,12P"],
                ["8P", "12P,14P", "40P,42P", "12P,14P"],
                ["10P", "14P,16P", "42P,44P", "14P,16P"],
                ["12P", "16P,18P", "44P,46P", "16P,18P"],
                ["14P", "18P,20P", "46P,48P", "18P,20P"],
                ["16P", "20P,22P", "48P,50P", "20P,22P"],
                ["18P", "22P,24P", "50P,52P", "22P,24P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "6PAU"],
                        eu: ["32PEU", "34PEU"],
                        uk: ["4PUK", "6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU"],
                        uk: ["6PUK", "8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "10PAU"],
                        eu: ["36PEU", "38PEU"],
                        uk: ["8PUK", "10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "12PAU"],
                        eu: ["38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "14PAU"],
                        eu: ["40PEU", "42PEU"],
                        uk: ["12PUK", "14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "16PAU"],
                        eu: ["42PEU", "44PEU"],
                        uk: ["14PUK", "16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "18PAU"],
                        eu: ["44PEU", "46PEU"],
                        uk: ["16PUK", "18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "20PAU"],
                        eu: ["46PEU", "48PEU"],
                        uk: ["18PUK", "20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "22PAU"],
                        eu: ["48PEU", "50PEU"],
                        uk: ["20PUK", "22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["6PAU", "8PAU", "10PAU"],
                        uk: ["6PUK", "8PUK", "10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["8PAU", "10PAU", "12PAU"],
                        uk: ["8PUK", "10PUK", "12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "9b9ecb3e402403cd9fcf606c",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "9cb476dc402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "4,6", "30,32", "4"],
                  ["0,0,1", "4,6,8", "30,32,34", "6"],
                  ["0,1", "6,8", "32,34", "8"],
                  ["3,5", "10,11", "34,36", "10"],
                  ["3,5", "10,11", "34,36", "11"],
                  ["7,9", "12,14", "38,40", "12"],
                  ["7,9", "12,14", "38,40", "14"],
                  ["11,13", "16,18", "42,44", "16"],
                  ["11,13", "16,18,20", "42,44", "18"],
                  ["15,17", "18,20", "44,46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["0", "4,6", "30,32", "4,6"],
                ["0", "6,8", "32,34", "6,8"],
                ["1", "6,8", "32,34", "6,8"],
                ["3", "10,11", "34,36", "10,11"],
                ["5", "10,11", "34,36", "10,11"],
                ["7", "12,14", "38,40", "12,14"],
                ["9", "12,14", "38,40", "12,14"],
                ["11", "16,18", "42,44", "16,18"],
                ["13", "16,18", "42,44", "16,18"],
                ["15", "20,22", "44,46", "20,22"],
                ["17", "20,22", "44,46", "20,22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J", "3J", "5J"],
                        au: ["6JAU", "8JAU", "10JAU", "11JAU"],
                        uk: ["6JUK", "8JUK", "10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J", "15J", "17J"],
                        au: ["16JAU", "18JAU", "20JAU", "22JAU"],
                        uk: ["16JUK", "18JUK", "20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "9c9ecb3e402403cd9fcf606c",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "9cb476dc402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_hanboks",
          aliases: [],
          slug: "Hanboks",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
        },
        {
          id: "a3b476dd402403bf28a2606b",
          display: "Harem Pants",
          type: "c",
          size_sets: [
            {
              id: "c1b476dd402403bf28a2606b",
              name: "Standard",
              tags: ["standard"],
              category_id: "a3b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "6AU"],
                        eu: ["32EU", "34EU"],
                        uk: ["4UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["0"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        eu: ["30EU", "32EU", "34EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        eu: ["32EU", "34EU", "36EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["30EU", "32EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["32EU", "34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "c2b476dd402403bf28a2606b",
              name: "Plus",
              tags: ["plus"],
              category_id: "a3b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34", "58,60,62", "34"],
                  ["30,30W,32,32W", "36,38", "60,62", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU", "28AU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU", "30AU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20", "20W", "22W"],
                        au: ["20AU", "22AU", "24AU", "26AU"],
                        uk: ["20UK", "22UK", "24UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "c3b476dd402403bf28a2606b",
              name: "Petite",
              tags: ["petite"],
              category_id: "a3b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P,32P", "2P"],
                  ["00P,0P", "2P,4P,6P", "30P,32P,34P", "4P"],
                  ["0P,2P", "4P,6P,8P", "32P,34P,36P", "6P"],
                  ["2P,4P", "10P,6P,8P", "34P,36P,38P", "8P"],
                  ["4P,6P", "10P,12P,8P", "36P,38P,40P", "10P"],
                  ["6P,8P", "10P,12P,14P", "38P,40P,42P", "12P"],
                  ["8P,10P", "12P,14P,16P", "40P,42P,44P", "14P"],
                  ["10P,12P", "14P,16P,18P", "42P,44P,46P", "16P"],
                  ["12P,14P", "16P,18P,20P", "44P,46P,48P", "18P"],
                  ["14P,16P", "18P,20P,22P", "46P,48P,50P", "20P"],
                  ["16P,18P", "20P,22P,24P", "48P,50P,52P", "22P"],
                  ["18P,20P", "22P,24P", "50P,52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P,4P", "30P,32P", "2P,4P"],
                ["0P", "4P,6P", "32P,34P", "4P,6P"],
                ["2P", "6P,8P", "34P,36P", "6P,8P"],
                ["4P", "8P,10P", "36P,38P", "8P,10P"],
                ["6P", "10P,12P", "38P,40P", "10P,12P"],
                ["8P", "12P,14P", "40P,42P", "12P,14P"],
                ["10P", "14P,16P", "42P,44P", "14P,16P"],
                ["12P", "16P,18P", "44P,46P", "16P,18P"],
                ["14P", "18P,20P", "46P,48P", "18P,20P"],
                ["16P", "20P,22P", "48P,50P", "20P,22P"],
                ["18P", "22P,24P", "50P,52P", "22P,24P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "6PAU"],
                        eu: ["32PEU", "34PEU"],
                        uk: ["4PUK", "6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU"],
                        uk: ["6PUK", "8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "10PAU"],
                        eu: ["36PEU", "38PEU"],
                        uk: ["8PUK", "10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "12PAU"],
                        eu: ["38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "14PAU"],
                        eu: ["40PEU", "42PEU"],
                        uk: ["12PUK", "14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "16PAU"],
                        eu: ["42PEU", "44PEU"],
                        uk: ["14PUK", "16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "18PAU"],
                        eu: ["44PEU", "46PEU"],
                        uk: ["16PUK", "18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "20PAU"],
                        eu: ["46PEU", "48PEU"],
                        uk: ["18PUK", "20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "22PAU"],
                        eu: ["48PEU", "50PEU"],
                        uk: ["20PUK", "22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["6PAU", "8PAU", "10PAU"],
                        uk: ["6PUK", "8PUK", "10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["8PAU", "10PAU", "12PAU"],
                        uk: ["8PUK", "10PUK", "12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "c4b476dd402403bf28a2606b",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "a3b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "4,6", "30,32", "4"],
                  ["0,0,1", "4,6,8", "30,32,34", "6"],
                  ["0,1", "6,8", "32,34", "8"],
                  ["3,5", "10,11", "34,36", "10"],
                  ["3,5", "10,11", "34,36", "11"],
                  ["7,9", "12,14", "38,40", "12"],
                  ["7,9", "12,14", "38,40", "14"],
                  ["11,13", "16,18", "42,44", "16"],
                  ["11,13", "16,18,20", "42,44", "18"],
                  ["15,17", "18,20", "44,46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["0", "4,6", "30,32", "4,6"],
                ["0", "6,8", "32,34", "6,8"],
                ["1", "6,8", "32,34", "6,8"],
                ["3", "10,11", "34,36", "10,11"],
                ["5", "10,11", "34,36", "10,11"],
                ["7", "12,14", "38,40", "12,14"],
                ["9", "12,14", "38,40", "12,14"],
                ["11", "16,18", "42,44", "16,18"],
                ["13", "16,18", "42,44", "16,18"],
                ["15", "20,22", "44,46", "20,22"],
                ["17", "20,22", "44,46", "20,22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J", "3J", "5J"],
                        au: ["6JAU", "8JAU", "10JAU", "11JAU"],
                        uk: ["6JUK", "8JUK", "10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J", "15J", "17J"],
                        au: ["16JAU", "18JAU", "20JAU", "22JAU"],
                        uk: ["16JUK", "18JUK", "20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "c5b476dd402403bf28a2606b",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "a3b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_harem_pants",
          aliases: [],
          slug: "Harem_Pants",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
        },
        {
          id: "9eb476dc402403bf28a2606b",
          display: "Hijabs",
          type: "c",
          size_sets: [
            {
              id: "be9ecb3e402403cd9fcf606c",
              name: "Standard",
              tags: ["standard"],
              category_id: "9eb476dc402403bf28a2606b",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_hijabs",
          aliases: [],
          slug: "Hijabs",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
        },
        {
          id: "9fb476dd402403bf28a2606b",
          display: "Huipils",
          type: "c",
          size_sets: [
            {
              id: "a29ecb3e402403cd9fcf606c",
              name: "Standard",
              tags: ["standard"],
              category_id: "9fb476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "6AU"],
                        eu: ["32EU", "34EU"],
                        uk: ["4UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["0"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        eu: ["30EU", "32EU", "34EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        eu: ["32EU", "34EU", "36EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["30EU", "32EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["32EU", "34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "a39ecb3e402403cd9fcf606c",
              name: "Plus",
              tags: ["plus"],
              category_id: "9fb476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34", "58,60,62", "34"],
                  ["30,30W,32,32W", "36,38", "60,62", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU", "28AU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU", "30AU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20", "20W", "22W"],
                        au: ["20AU", "22AU", "24AU", "26AU"],
                        uk: ["20UK", "22UK", "24UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "a49ecb3e402403cd9fcf606c",
              name: "Petite",
              tags: ["petite"],
              category_id: "9fb476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P,32P", "2P"],
                  ["00P,0P", "2P,4P,6P", "30P,32P,34P", "4P"],
                  ["0P,2P", "4P,6P,8P", "32P,34P,36P", "6P"],
                  ["2P,4P", "10P,6P,8P", "34P,36P,38P", "8P"],
                  ["4P,6P", "10P,12P,8P", "36P,38P,40P", "10P"],
                  ["6P,8P", "10P,12P,14P", "38P,40P,42P", "12P"],
                  ["8P,10P", "12P,14P,16P", "40P,42P,44P", "14P"],
                  ["10P,12P", "14P,16P,18P", "42P,44P,46P", "16P"],
                  ["12P,14P", "16P,18P,20P", "44P,46P,48P", "18P"],
                  ["14P,16P", "18P,20P,22P", "46P,48P,50P", "20P"],
                  ["16P,18P", "20P,22P,24P", "48P,50P,52P", "22P"],
                  ["18P,20P", "22P,24P", "50P,52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P,4P", "30P,32P", "2P,4P"],
                ["0P", "4P,6P", "32P,34P", "4P,6P"],
                ["2P", "6P,8P", "34P,36P", "6P,8P"],
                ["4P", "8P,10P", "36P,38P", "8P,10P"],
                ["6P", "10P,12P", "38P,40P", "10P,12P"],
                ["8P", "12P,14P", "40P,42P", "12P,14P"],
                ["10P", "14P,16P", "42P,44P", "14P,16P"],
                ["12P", "16P,18P", "44P,46P", "16P,18P"],
                ["14P", "18P,20P", "46P,48P", "18P,20P"],
                ["16P", "20P,22P", "48P,50P", "20P,22P"],
                ["18P", "22P,24P", "50P,52P", "22P,24P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "6PAU"],
                        eu: ["32PEU", "34PEU"],
                        uk: ["4PUK", "6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU"],
                        uk: ["6PUK", "8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "10PAU"],
                        eu: ["36PEU", "38PEU"],
                        uk: ["8PUK", "10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "12PAU"],
                        eu: ["38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "14PAU"],
                        eu: ["40PEU", "42PEU"],
                        uk: ["12PUK", "14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "16PAU"],
                        eu: ["42PEU", "44PEU"],
                        uk: ["14PUK", "16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "18PAU"],
                        eu: ["44PEU", "46PEU"],
                        uk: ["16PUK", "18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "20PAU"],
                        eu: ["46PEU", "48PEU"],
                        uk: ["18PUK", "20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "22PAU"],
                        eu: ["48PEU", "50PEU"],
                        uk: ["20PUK", "22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["6PAU", "8PAU", "10PAU"],
                        uk: ["6PUK", "8PUK", "10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["8PAU", "10PAU", "12PAU"],
                        uk: ["8PUK", "10PUK", "12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "a59ecb3e402403cd9fcf606c",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "9fb476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "4,6", "30,32", "4"],
                  ["00,0,1", "4,6,8", "30,32,34", "6"],
                  ["0,1", "6,8", "32,34", "8"],
                  ["3,5", "10,11", "34,36", "10"],
                  ["3,5", "10,11", "34,36", "11"],
                  ["7,9", "12,14", "38,40", "12"],
                  ["7,9", "12,14", "38,40", "14"],
                  ["11,13", "16,18", "42,44", "16"],
                  ["11,13", "16,18,20", "42,44", "18"],
                  ["15,17", "18,20", "44,46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "4,6", "30,32", "4,6"],
                ["0", "6,8", "32,34", "6,8"],
                ["1", "6,8", "32,34", "6,8"],
                ["3", "10,11", "34,36", "10,11"],
                ["5", "10,11", "34,36", "10,11"],
                ["7", "12,14", "38,40", "12,14"],
                ["9", "12,14", "38,40", "12,14"],
                ["11", "16,18", "42,44", "16,18"],
                ["13", "16,18", "42,44", "16,18"],
                ["15", "20,22", "44,46", "20,22"],
                ["17", "20,22", "44,46", "20,22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J", "3J", "5J"],
                        au: ["6JAU", "8JAU", "10JAU", "11JAU"],
                        uk: ["6JUK", "8JUK", "10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J", "15J", "17J"],
                        au: ["16JAU", "18JAU", "20JAU", "22JAU"],
                        uk: ["16JUK", "18JUK", "20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "bf9ecb3e402403cd9fcf606c",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "9fb476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_huipils",
          aliases: [],
          slug: "Huipils",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
        },
        {
          id: "a0b476dd402403bf28a2606b",
          display: "Kaftans",
          type: "c",
          size_sets: [
            {
              id: "a69ecb3e402403cd9fcf606c",
              name: "Standard",
              tags: ["standard"],
              category_id: "a0b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "6AU"],
                        eu: ["32EU", "34EU"],
                        uk: ["4UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["0"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        eu: ["30EU", "32EU", "34EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        eu: ["32EU", "34EU", "36EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["30EU", "32EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["32EU", "34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "a79ecb3e402403cd9fcf606c",
              name: "Plus",
              tags: ["plus"],
              category_id: "a0b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34", "58,60,62", "34"],
                  ["30,30W,32,32W", "36,38", "60,62", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU", "28AU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU", "30AU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20", "20W", "22W"],
                        au: ["20AU", "22AU", "24AU", "26AU"],
                        uk: ["20UK", "22UK", "24UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "a89ecb3e402403cd9fcf606c",
              name: "Petite",
              tags: ["petite"],
              category_id: "a0b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P,32P", "2P"],
                  ["00P,0P", "2P,4P,6P", "30P,32P,34P", "4P"],
                  ["0P,2P", "4P,6P,8P", "32P,34P,36P", "6P"],
                  ["2P,4P", "10P,6P,8P", "34P,36P,38P", "8P"],
                  ["4P,6P", "10P,12P,8P", "36P,38P,40P", "10P"],
                  ["6P,8P", "10P,12P,14P", "38P,40P,42P", "12P"],
                  ["8P,10P", "12P,14P,16P", "40P,42P,44P", "14P"],
                  ["10P,12P", "14P,16P,18P", "42P,44P,46P", "16P"],
                  ["12P,14P", "16P,18P,20P", "44P,46P,48P", "18P"],
                  ["14P,16P", "18P,20P,22P", "46P,48P,50P", "20P"],
                  ["16P,18P", "20P,22P,24P", "48P,50P,52P", "22P"],
                  ["18P,20P", "22P,24P", "50P,52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P,4P", "30P,32P", "2P,4P"],
                ["0P", "4P,6P", "32P,34P", "4P,6P"],
                ["2P", "6P,8P", "34P,36P", "6P,8P"],
                ["4P", "8P,10P", "36P,38P", "8P,10P"],
                ["6P", "10P,12P", "38P,40P", "10P,12P"],
                ["8P", "12P,14P", "40P,42P", "12P,14P"],
                ["10P", "14P,16P", "42P,44P", "14P,16P"],
                ["12P", "16P,18P", "44P,46P", "16P,18P"],
                ["14P", "18P,20P", "46P,48P", "18P,20P"],
                ["16P", "20P,22P", "48P,50P", "20P,22P"],
                ["18P", "22P,24P", "50P,52P", "22P,24P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "6PAU"],
                        eu: ["32PEU", "34PEU"],
                        uk: ["4PUK", "6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU"],
                        uk: ["6PUK", "8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "10PAU"],
                        eu: ["36PEU", "38PEU"],
                        uk: ["8PUK", "10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "12PAU"],
                        eu: ["38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "14PAU"],
                        eu: ["40PEU", "42PEU"],
                        uk: ["12PUK", "14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "16PAU"],
                        eu: ["42PEU", "44PEU"],
                        uk: ["14PUK", "16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "18PAU"],
                        eu: ["44PEU", "46PEU"],
                        uk: ["16PUK", "18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "20PAU"],
                        eu: ["46PEU", "48PEU"],
                        uk: ["18PUK", "20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "22PAU"],
                        eu: ["48PEU", "50PEU"],
                        uk: ["20PUK", "22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["6PAU", "8PAU", "10PAU"],
                        uk: ["6PUK", "8PUK", "10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["8PAU", "10PAU", "12PAU"],
                        uk: ["8PUK", "10PUK", "12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "a99ecb3e402403cd9fcf606c",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "a0b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "4,6", "30,32", "4"],
                  ["0,0,1", "4,6,8", "30,32,34", "6"],
                  ["0,1", "6,8", "32,34", "8"],
                  ["3,5", "10,11", "34,36", "10"],
                  ["3,5", "10,11", "34,36", "11"],
                  ["7,9", "12,14", "38,40", "12"],
                  ["7,9", "12,14", "38,40", "14"],
                  ["11,13", "16,18", "42,44", "16"],
                  ["11,13", "16,18,20", "42,44", "18"],
                  ["15,17", "18,20", "44,46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["0", "4,6", "30,32", "4,6"],
                ["0", "6,8", "32,34", "6,8"],
                ["1", "6,8", "32,34", "6,8"],
                ["3", "10,11", "34,36", "10,11"],
                ["5", "10,11", "34,36", "10,11"],
                ["7", "12,14", "38,40", "12,14"],
                ["9", "12,14", "38,40", "12,14"],
                ["11", "16,18", "42,44", "16,18"],
                ["13", "16,18", "42,44", "16,18"],
                ["15", "20,22", "44,46", "20,22"],
                ["17", "20,22", "44,46", "20,22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J", "3J", "5J"],
                        au: ["6JAU", "8JAU", "10JAU", "11JAU"],
                        uk: ["6JUK", "8JUK", "10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J", "15J", "17J"],
                        au: ["16JAU", "18JAU", "20JAU", "22JAU"],
                        uk: ["16JUK", "18JUK", "20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "aa9ecb3e402403cd9fcf606c",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "a0b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_kaftans",
          aliases: [],
          slug: "Kaftans",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
        },
        {
          id: "a1b476dd402403bf28a2606b",
          display: "Kimonos & Yukatas",
          type: "c",
          size_sets: [
            {
              id: "ab9ecb3e402403cd9fcf606c",
              name: "Standard",
              tags: ["standard"],
              category_id: "a1b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "6AU"],
                        eu: ["32EU", "34EU"],
                        uk: ["4UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["0"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        eu: ["30EU", "32EU", "34EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        eu: ["32EU", "34EU", "36EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["30EU", "32EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["32EU", "34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "ac9ecb3e402403cd9fcf606c",
              name: "Plus",
              tags: ["plus"],
              category_id: "a1b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34", "58,60,62", "34"],
                  ["30,30W,32,32W", "36,38", "60,62", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU", "28AU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU", "30AU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20", "20W", "22W"],
                        au: ["20AU", "22AU", "24AU", "26AU"],
                        uk: ["20UK", "22UK", "24UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "ad9ecb3e402403cd9fcf606c",
              name: "Petite",
              tags: ["petite"],
              category_id: "a1b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P,32P", "2P"],
                  ["00P,0P", "2P,4P,6P", "30P,32P,34P", "4P"],
                  ["0P,2P", "4P,6P,8P", "32P,34P,36P", "6P"],
                  ["2P,4P", "10P,6P,8P", "34P,36P,38P", "8P"],
                  ["4P,6P", "10P,12P,8P", "36P,38P,40P", "10P"],
                  ["6P,8P", "10P,12P,14P", "38P,40P,42P", "12P"],
                  ["8P,10P", "12P,14P,16P", "40P,42P,44P", "14P"],
                  ["10P,12P", "14P,16P,18P", "42P,44P,46P", "16P"],
                  ["12P,14P", "16P,18P,20P", "44P,46P,48P", "18P"],
                  ["14P,16P", "18P,20P,22P", "46P,48P,50P", "20P"],
                  ["16P,18P", "20P,22P,24P", "48P,50P,52P", "22P"],
                  ["18P,20P", "22P,24P", "50P,52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P,4P", "30P,32P", "2P,4P"],
                ["0P", "4P,6P", "32P,34P", "4P,6P"],
                ["2P", "6P,8P", "34P,36P", "6P,8P"],
                ["4P", "8P,10P", "36P,38P", "8P,10P"],
                ["6P", "10P,12P", "38P,40P", "10P,12P"],
                ["8P", "12P,14P", "40P,42P", "12P,14P"],
                ["10P", "14P,16P", "42P,44P", "14P,16P"],
                ["12P", "16P,18P", "44P,46P", "16P,18P"],
                ["14P", "18P,20P", "46P,48P", "18P,20P"],
                ["16P", "20P,22P", "48P,50P", "20P,22P"],
                ["18P", "22P,24P", "50P,52P", "22P,24P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "6PAU"],
                        eu: ["32PEU", "34PEU"],
                        uk: ["4PUK", "6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU"],
                        uk: ["6PUK", "8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "10PAU"],
                        eu: ["36PEU", "38PEU"],
                        uk: ["8PUK", "10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "12PAU"],
                        eu: ["38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "14PAU"],
                        eu: ["40PEU", "42PEU"],
                        uk: ["12PUK", "14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "16PAU"],
                        eu: ["42PEU", "44PEU"],
                        uk: ["14PUK", "16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "18PAU"],
                        eu: ["44PEU", "46PEU"],
                        uk: ["16PUK", "18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "20PAU"],
                        eu: ["46PEU", "48PEU"],
                        uk: ["18PUK", "20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "22PAU"],
                        eu: ["48PEU", "50PEU"],
                        uk: ["20PUK", "22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["6PAU", "8PAU", "10PAU"],
                        uk: ["6PUK", "8PUK", "10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["8PAU", "10PAU", "12PAU"],
                        uk: ["8PUK", "10PUK", "12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "ae9ecb3e402403cd9fcf606c",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "a1b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "4,6", "30,32", "4"],
                  ["0,0,1", "4,6,8", "30,32,34", "6"],
                  ["0,1", "6,8", "32,34", "8"],
                  ["3,5", "10,11", "34,36", "10"],
                  ["3,5", "10,11", "34,36", "11"],
                  ["7,9", "12,14", "38,40", "12"],
                  ["7,9", "12,14", "38,40", "14"],
                  ["11,13", "16,18", "42,44", "16"],
                  ["11,13", "16,18,20", "42,44", "18"],
                  ["15,17", "18,20", "44,46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["0", "4,6", "30,32", "4,6"],
                ["0", "6,8", "32,34", "6,8"],
                ["1", "6,8", "32,34", "6,8"],
                ["3", "10,11", "34,36", "10,11"],
                ["5", "10,11", "34,36", "10,11"],
                ["7", "12,14", "38,40", "12,14"],
                ["9", "12,14", "38,40", "12,14"],
                ["11", "16,18", "42,44", "16,18"],
                ["13", "16,18", "42,44", "16,18"],
                ["15", "20,22", "44,46", "20,22"],
                ["17", "20,22", "44,46", "20,22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J", "3J", "5J"],
                        au: ["6JAU", "8JAU", "10JAU", "11JAU"],
                        uk: ["6JUK", "8JUK", "10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J", "15J", "17J"],
                        au: ["16JAU", "18JAU", "20JAU", "22JAU"],
                        uk: ["16JUK", "18JUK", "20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "af9ecb3e402403cd9fcf606c",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "a1b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_kimonos_yukatas",
          aliases: [],
          slug: "Kimonos_&_Yukatas",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
        },
        {
          id: "a4b476dd402403bf28a2606b",
          display: "Kurta Bottoms",
          type: "c",
          size_sets: [
            {
              id: "cbb476dd402403bf28a2606b",
              name: "Standard",
              tags: ["standard"],
              category_id: "a4b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "6AU"],
                        eu: ["32EU", "34EU"],
                        uk: ["4UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["0"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        eu: ["30EU", "32EU", "34EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        eu: ["32EU", "34EU", "36EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["30EU", "32EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["32EU", "34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "ccb476dd402403bf28a2606b",
              name: "Plus",
              tags: ["plus"],
              category_id: "a4b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34", "58,60,62", "34"],
                  ["30,30W,32,32W", "36,38", "60,62", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU", "28AU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU", "30AU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20", "20W", "22W"],
                        au: ["20AU", "22AU", "24AU", "26AU"],
                        uk: ["20UK", "22UK", "24UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "cdb476dd402403bf28a2606b",
              name: "Petite",
              tags: ["petite"],
              category_id: "a4b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P,32P", "2P"],
                  ["00P,0P", "2P,4P,6P", "30P,32P,34P", "4P"],
                  ["0P,2P", "4P,6P,8P", "32P,34P,36P", "6P"],
                  ["2P,4P", "10P,6P,8P", "34P,36P,38P", "8P"],
                  ["4P,6P", "10P,12P,8P", "36P,38P,40P", "10P"],
                  ["6P,8P", "10P,12P,14P", "38P,40P,42P", "12P"],
                  ["8P,10P", "12P,14P,16P", "40P,42P,44P", "14P"],
                  ["10P,12P", "14P,16P,18P", "42P,44P,46P", "16P"],
                  ["12P,14P", "16P,18P,20P", "44P,46P,48P", "18P"],
                  ["14P,16P", "18P,20P,22P", "46P,48P,50P", "20P"],
                  ["16P,18P", "20P,22P,24P", "48P,50P,52P", "22P"],
                  ["18P,20P", "22P,24P", "50P,52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P,4P", "30P,32P", "2P,4P"],
                ["0P", "4P,6P", "32P,34P", "4P,6P"],
                ["2P", "6P,8P", "34P,36P", "6P,8P"],
                ["4P", "8P,10P", "36P,38P", "8P,10P"],
                ["6P", "10P,12P", "38P,40P", "10P,12P"],
                ["8P", "12P,14P", "40P,42P", "12P,14P"],
                ["10P", "14P,16P", "42P,44P", "14P,16P"],
                ["12P", "16P,18P", "44P,46P", "16P,18P"],
                ["14P", "18P,20P", "46P,48P", "18P,20P"],
                ["16P", "20P,22P", "48P,50P", "20P,22P"],
                ["18P", "22P,24P", "50P,52P", "22P,24P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "6PAU"],
                        eu: ["32PEU", "34PEU"],
                        uk: ["4PUK", "6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU"],
                        uk: ["6PUK", "8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "10PAU"],
                        eu: ["36PEU", "38PEU"],
                        uk: ["8PUK", "10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "12PAU"],
                        eu: ["38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "14PAU"],
                        eu: ["40PEU", "42PEU"],
                        uk: ["12PUK", "14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "16PAU"],
                        eu: ["42PEU", "44PEU"],
                        uk: ["14PUK", "16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "18PAU"],
                        eu: ["44PEU", "46PEU"],
                        uk: ["16PUK", "18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "20PAU"],
                        eu: ["46PEU", "48PEU"],
                        uk: ["18PUK", "20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "22PAU"],
                        eu: ["48PEU", "50PEU"],
                        uk: ["20PUK", "22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["6PAU", "8PAU", "10PAU"],
                        uk: ["6PUK", "8PUK", "10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["8PAU", "10PAU", "12PAU"],
                        uk: ["8PUK", "10PUK", "12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "ceb476dd402403bf28a2606b",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "a4b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "4,6", "30,32", "4"],
                  ["0,0,1", "4,6,8", "30,32,34", "6"],
                  ["0,1", "6,8", "32,34", "8"],
                  ["3,5", "10,11", "34,36", "10"],
                  ["3,5", "10,11", "34,36", "11"],
                  ["7,9", "12,14", "38,40", "12"],
                  ["7,9", "12,14", "38,40", "14"],
                  ["11,13", "16,18", "42,44", "16"],
                  ["11,13", "16,18,20", "42,44", "18"],
                  ["15,17", "18,20", "44,46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["0", "4,6", "30,32", "4,6"],
                ["0", "6,8", "32,34", "6,8"],
                ["1", "6,8", "32,34", "6,8"],
                ["3", "10,11", "34,36", "10,11"],
                ["5", "10,11", "34,36", "10,11"],
                ["7", "12,14", "38,40", "12,14"],
                ["9", "12,14", "38,40", "12,14"],
                ["11", "16,18", "42,44", "16,18"],
                ["13", "16,18", "42,44", "16,18"],
                ["15", "20,22", "44,46", "20,22"],
                ["17", "20,22", "44,46", "20,22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J", "3J", "5J"],
                        au: ["6JAU", "8JAU", "10JAU", "11JAU"],
                        uk: ["6JUK", "8JUK", "10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J", "15J", "17J"],
                        au: ["16JAU", "18JAU", "20JAU", "22JAU"],
                        uk: ["16JUK", "18JUK", "20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "cfb476dd402403bf28a2606b",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "a4b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_kurta_bottoms",
          aliases: [],
          slug: "Kurta_Bottoms",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
        },
        {
          id: "a5b476dd402403bf28a2606b",
          display: "Kurtas",
          type: "c",
          size_sets: [
            {
              id: "c6b476dd402403bf28a2606b",
              name: "Standard",
              tags: ["standard"],
              category_id: "a5b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "6AU"],
                        eu: ["32EU", "34EU"],
                        uk: ["4UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["0"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        eu: ["30EU", "32EU", "34EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        eu: ["32EU", "34EU", "36EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["30EU", "32EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["32EU", "34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "c7b476dd402403bf28a2606b",
              name: "Plus",
              tags: ["plus"],
              category_id: "a5b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34", "58,60,62", "34"],
                  ["30,30W,32,32W", "36,38", "60,62", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU", "28AU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU", "30AU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20", "20W", "22W"],
                        au: ["20AU", "22AU", "24AU", "26AU"],
                        uk: ["20UK", "22UK", "24UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "c8b476dd402403bf28a2606b",
              name: "Petite",
              tags: ["petite"],
              category_id: "a5b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P,32P", "2P"],
                  ["00P,0P", "2P,4P,6P", "30P,32P,34P", "4P"],
                  ["0P,2P", "4P,6P,8P", "32P,34P,36P", "6P"],
                  ["2P,4P", "10P,6P,8P", "34P,36P,38P", "8P"],
                  ["4P,6P", "10P,12P,8P", "36P,38P,40P", "10P"],
                  ["6P,8P", "10P,12P,14P", "38P,40P,42P", "12P"],
                  ["8P,10P", "12P,14P,16P", "40P,42P,44P", "14P"],
                  ["10P,12P", "14P,16P,18P", "42P,44P,46P", "16P"],
                  ["12P,14P", "16P,18P,20P", "44P,46P,48P", "18P"],
                  ["14P,16P", "18P,20P,22P", "46P,48P,50P", "20P"],
                  ["16P,18P", "20P,22P,24P", "48P,50P,52P", "22P"],
                  ["18P,20P", "22P,24P", "50P,52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P,4P", "30P,32P", "2P,4P"],
                ["0P", "4P,6P", "32P,34P", "4P,6P"],
                ["2P", "6P,8P", "34P,36P", "6P,8P"],
                ["4P", "8P,10P", "36P,38P", "8P,10P"],
                ["6P", "10P,12P", "38P,40P", "10P,12P"],
                ["8P", "12P,14P", "40P,42P", "12P,14P"],
                ["10P", "14P,16P", "42P,44P", "14P,16P"],
                ["12P", "16P,18P", "44P,46P", "16P,18P"],
                ["14P", "18P,20P", "46P,48P", "18P,20P"],
                ["16P", "20P,22P", "48P,50P", "20P,22P"],
                ["18P", "22P,24P", "50P,52P", "22P,24P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "6PAU"],
                        eu: ["32PEU", "34PEU"],
                        uk: ["4PUK", "6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU"],
                        uk: ["6PUK", "8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "10PAU"],
                        eu: ["36PEU", "38PEU"],
                        uk: ["8PUK", "10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "12PAU"],
                        eu: ["38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "14PAU"],
                        eu: ["40PEU", "42PEU"],
                        uk: ["12PUK", "14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "16PAU"],
                        eu: ["42PEU", "44PEU"],
                        uk: ["14PUK", "16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "18PAU"],
                        eu: ["44PEU", "46PEU"],
                        uk: ["16PUK", "18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "20PAU"],
                        eu: ["46PEU", "48PEU"],
                        uk: ["18PUK", "20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "22PAU"],
                        eu: ["48PEU", "50PEU"],
                        uk: ["20PUK", "22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["6PAU", "8PAU", "10PAU"],
                        uk: ["6PUK", "8PUK", "10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["8PAU", "10PAU", "12PAU"],
                        uk: ["8PUK", "10PUK", "12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "c9b476dd402403bf28a2606b",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "a5b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "4,6", "30,32", "4"],
                  ["0,0,1", "4,6,8", "30,32,34", "6"],
                  ["0,1", "6,8", "32,34", "8"],
                  ["3,5", "10,11", "34,36", "10"],
                  ["3,5", "10,11", "34,36", "11"],
                  ["7,9", "12,14", "38,40", "12"],
                  ["7,9", "12,14", "38,40", "14"],
                  ["11,13", "16,18", "42,44", "16"],
                  ["11,13", "16,18,20", "42,44", "18"],
                  ["15,17", "18,20", "44,46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["0", "4,6", "30,32", "4,6"],
                ["0", "6,8", "32,34", "6,8"],
                ["1", "6,8", "32,34", "6,8"],
                ["3", "10,11", "34,36", "10,11"],
                ["5", "10,11", "34,36", "10,11"],
                ["7", "12,14", "38,40", "12,14"],
                ["9", "12,14", "38,40", "12,14"],
                ["11", "16,18", "42,44", "16,18"],
                ["13", "16,18", "42,44", "16,18"],
                ["15", "20,22", "44,46", "20,22"],
                ["17", "20,22", "44,46", "20,22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J", "3J", "5J"],
                        au: ["6JAU", "8JAU", "10JAU", "11JAU"],
                        uk: ["6JUK", "8JUK", "10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J", "15J", "17J"],
                        au: ["16JAU", "18JAU", "20JAU", "22JAU"],
                        uk: ["16JUK", "18JUK", "20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "cab476dd402403bf28a2606b",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "a5b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_kurtas",
          aliases: [],
          slug: "Kurtas",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
        },
        {
          id: "a6b476dd402403bf28a2606b",
          display: "Lehengas",
          type: "c",
          size_sets: [
            {
              id: "d0b476dd402403bf28a2606b",
              name: "Standard",
              tags: ["standard"],
              category_id: "a6b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "6AU"],
                        eu: ["32EU", "34EU"],
                        uk: ["4UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["0"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        eu: ["30EU", "32EU", "34EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        eu: ["32EU", "34EU", "36EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["30EU", "32EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["32EU", "34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "d1b476dd402403bf28a2606b",
              name: "Plus",
              tags: ["plus"],
              category_id: "a6b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34", "58,60,62", "34"],
                  ["30,30W,32,32W", "36,38", "60,62", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU", "28AU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU", "30AU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20", "20W", "22W"],
                        au: ["20AU", "22AU", "24AU", "26AU"],
                        uk: ["20UK", "22UK", "24UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "d2b476dd402403bf28a2606b",
              name: "Petite",
              tags: ["petite"],
              category_id: "a6b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P,32P", "2P"],
                  ["00P,0P", "2P,4P,6P", "30P,32P,34P", "4P"],
                  ["0P,2P", "4P,6P,8P", "32P,34P,36P", "6P"],
                  ["2P,4P", "10P,6P,8P", "34P,36P,38P", "8P"],
                  ["4P,6P", "10P,12P,8P", "36P,38P,40P", "10P"],
                  ["6P,8P", "10P,12P,14P", "38P,40P,42P", "12P"],
                  ["8P,10P", "12P,14P,16P", "40P,42P,44P", "14P"],
                  ["10P,12P", "14P,16P,18P", "42P,44P,46P", "16P"],
                  ["12P,14P", "16P,18P,20P", "44P,46P,48P", "18P"],
                  ["14P,16P", "18P,20P,22P", "46P,48P,50P", "20P"],
                  ["16P,18P", "20P,22P,24P", "48P,50P,52P", "22P"],
                  ["18P,20P", "22P,24P", "50P,52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P,4P", "30P,32P", "2P,4P"],
                ["0P", "4P,6P", "32P,34P", "4P,6P"],
                ["2P", "6P,8P", "34P,36P", "6P,8P"],
                ["4P", "8P,10P", "36P,38P", "8P,10P"],
                ["6P", "10P,12P", "38P,40P", "10P,12P"],
                ["8P", "12P,14P", "40P,42P", "12P,14P"],
                ["10P", "14P,16P", "42P,44P", "14P,16P"],
                ["12P", "16P,18P", "44P,46P", "16P,18P"],
                ["14P", "18P,20P", "46P,48P", "18P,20P"],
                ["16P", "20P,22P", "48P,50P", "20P,22P"],
                ["18P", "22P,24P", "50P,52P", "22P,24P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "6PAU"],
                        eu: ["32PEU", "34PEU"],
                        uk: ["4PUK", "6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU"],
                        uk: ["6PUK", "8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "10PAU"],
                        eu: ["36PEU", "38PEU"],
                        uk: ["8PUK", "10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "12PAU"],
                        eu: ["38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "14PAU"],
                        eu: ["40PEU", "42PEU"],
                        uk: ["12PUK", "14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "16PAU"],
                        eu: ["42PEU", "44PEU"],
                        uk: ["14PUK", "16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "18PAU"],
                        eu: ["44PEU", "46PEU"],
                        uk: ["16PUK", "18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "20PAU"],
                        eu: ["46PEU", "48PEU"],
                        uk: ["18PUK", "20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "22PAU"],
                        eu: ["48PEU", "50PEU"],
                        uk: ["20PUK", "22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["6PAU", "8PAU", "10PAU"],
                        uk: ["6PUK", "8PUK", "10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["8PAU", "10PAU", "12PAU"],
                        uk: ["8PUK", "10PUK", "12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "d3b476dd402403bf28a2606b",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "a6b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "4,6", "30,32", "4"],
                  ["0,0,1", "4,6,8", "30,32,34", "6"],
                  ["0,1", "6,8", "32,34", "8"],
                  ["3,5", "10,11", "34,36", "10"],
                  ["3,5", "10,11", "34,36", "11"],
                  ["7,9", "12,14", "38,40", "12"],
                  ["7,9", "12,14", "38,40", "14"],
                  ["11,13", "16,18", "42,44", "16"],
                  ["11,13", "16,18,20", "42,44", "18"],
                  ["15,17", "18,20", "44,46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["0", "4,6", "30,32", "4,6"],
                ["0", "6,8", "32,34", "6,8"],
                ["1", "6,8", "32,34", "6,8"],
                ["3", "10,11", "34,36", "10,11"],
                ["5", "10,11", "34,36", "10,11"],
                ["7", "12,14", "38,40", "12,14"],
                ["9", "12,14", "38,40", "12,14"],
                ["11", "16,18", "42,44", "16,18"],
                ["13", "16,18", "42,44", "16,18"],
                ["15", "20,22", "44,46", "20,22"],
                ["17", "20,22", "44,46", "20,22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J", "3J", "5J"],
                        au: ["6JAU", "8JAU", "10JAU", "11JAU"],
                        uk: ["6JUK", "8JUK", "10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J", "15J", "17J"],
                        au: ["16JAU", "18JAU", "20JAU", "22JAU"],
                        uk: ["16JUK", "18JUK", "20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "d4b476dd402403bf28a2606b",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "a6b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_lehengas",
          aliases: [],
          slug: "Lehengas",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
        },
        {
          id: "a7b476dd402403bf28a2606b",
          display: "Palazzo Pants",
          type: "c",
          size_sets: [
            {
              id: "d5b476dd402403bf28a2606b",
              name: "Standard",
              tags: ["standard"],
              category_id: "a7b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "6AU"],
                        eu: ["32EU", "34EU"],
                        uk: ["4UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["0"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        eu: ["30EU", "32EU", "34EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        eu: ["32EU", "34EU", "36EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["30EU", "32EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["32EU", "34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "d6b476dd402403bf28a2606b",
              name: "Plus",
              tags: ["plus"],
              category_id: "a7b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34", "58,60,62", "34"],
                  ["30,30W,32,32W", "36,38", "60,62", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU", "28AU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU", "30AU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20", "20W", "22W"],
                        au: ["20AU", "22AU", "24AU", "26AU"],
                        uk: ["20UK", "22UK", "24UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "d7b476dd402403bf28a2606b",
              name: "Petite",
              tags: ["petite"],
              category_id: "a7b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P,32P", "2P"],
                  ["00P,0P", "2P,4P,6P", "30P,32P,34P", "4P"],
                  ["0P,2P", "4P,6P,8P", "32P,34P,36P", "6P"],
                  ["2P,4P", "10P,6P,8P", "34P,36P,38P", "8P"],
                  ["4P,6P", "10P,12P,8P", "36P,38P,40P", "10P"],
                  ["6P,8P", "10P,12P,14P", "38P,40P,42P", "12P"],
                  ["8P,10P", "12P,14P,16P", "40P,42P,44P", "14P"],
                  ["10P,12P", "14P,16P,18P", "42P,44P,46P", "16P"],
                  ["12P,14P", "16P,18P,20P", "44P,46P,48P", "18P"],
                  ["14P,16P", "18P,20P,22P", "46P,48P,50P", "20P"],
                  ["16P,18P", "20P,22P,24P", "48P,50P,52P", "22P"],
                  ["18P,20P", "22P,24P", "50P,52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P,4P", "30P,32P", "2P,4P"],
                ["0P", "4P,6P", "32P,34P", "4P,6P"],
                ["2P", "6P,8P", "34P,36P", "6P,8P"],
                ["4P", "8P,10P", "36P,38P", "8P,10P"],
                ["6P", "10P,12P", "38P,40P", "10P,12P"],
                ["8P", "12P,14P", "40P,42P", "12P,14P"],
                ["10P", "14P,16P", "42P,44P", "14P,16P"],
                ["12P", "16P,18P", "44P,46P", "16P,18P"],
                ["14P", "18P,20P", "46P,48P", "18P,20P"],
                ["16P", "20P,22P", "48P,50P", "20P,22P"],
                ["18P", "22P,24P", "50P,52P", "22P,24P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "6PAU"],
                        eu: ["32PEU", "34PEU"],
                        uk: ["4PUK", "6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU"],
                        uk: ["6PUK", "8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "10PAU"],
                        eu: ["36PEU", "38PEU"],
                        uk: ["8PUK", "10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "12PAU"],
                        eu: ["38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "14PAU"],
                        eu: ["40PEU", "42PEU"],
                        uk: ["12PUK", "14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "16PAU"],
                        eu: ["42PEU", "44PEU"],
                        uk: ["14PUK", "16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "18PAU"],
                        eu: ["44PEU", "46PEU"],
                        uk: ["16PUK", "18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "20PAU"],
                        eu: ["46PEU", "48PEU"],
                        uk: ["18PUK", "20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "22PAU"],
                        eu: ["48PEU", "50PEU"],
                        uk: ["20PUK", "22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["6PAU", "8PAU", "10PAU"],
                        uk: ["6PUK", "8PUK", "10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["8PAU", "10PAU", "12PAU"],
                        uk: ["8PUK", "10PUK", "12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "d8b476dd402403bf28a2606b",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "a7b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "4,6", "30,32", "4"],
                  ["0,0,1", "4,6,8", "30,32,34", "6"],
                  ["0,1", "6,8", "32,34", "8"],
                  ["3,5", "10,11", "34,36", "10"],
                  ["3,5", "10,11", "34,36", "11"],
                  ["7,9", "12,14", "38,40", "12"],
                  ["7,9", "12,14", "38,40", "14"],
                  ["11,13", "16,18", "42,44", "16"],
                  ["11,13", "16,18,20", "42,44", "18"],
                  ["15,17", "18,20", "44,46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["0", "4,6", "30,32", "4,6"],
                ["0", "6,8", "32,34", "6,8"],
                ["1", "6,8", "32,34", "6,8"],
                ["3", "10,11", "34,36", "10,11"],
                ["5", "10,11", "34,36", "10,11"],
                ["7", "12,14", "38,40", "12,14"],
                ["9", "12,14", "38,40", "12,14"],
                ["11", "16,18", "42,44", "16,18"],
                ["13", "16,18", "42,44", "16,18"],
                ["15", "20,22", "44,46", "20,22"],
                ["17", "20,22", "44,46", "20,22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J", "3J", "5J"],
                        au: ["6JAU", "8JAU", "10JAU", "11JAU"],
                        uk: ["6JUK", "8JUK", "10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J", "15J", "17J"],
                        au: ["16JAU", "18JAU", "20JAU", "22JAU"],
                        uk: ["16JUK", "18JUK", "20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "d9b476dd402403bf28a2606b",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "a7b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_palazzo_pants",
          aliases: [],
          slug: "Palazzo_Pants",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
        },
        {
          id: "a8b476dd402403bf28a2606b",
          display: "Patiala Pants",
          type: "c",
          size_sets: [
            {
              id: "dab476dd402403bf28a2606b",
              name: "Standard",
              tags: ["standard"],
              category_id: "a8b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "6AU"],
                        eu: ["32EU", "34EU"],
                        uk: ["4UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["0"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        eu: ["30EU", "32EU", "34EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        eu: ["32EU", "34EU", "36EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["30EU", "32EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["32EU", "34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "dbb476dd402403bf28a2606b",
              name: "Plus",
              tags: ["plus"],
              category_id: "a8b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W", "18", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "20", "46,48,50", "20"],
                  ["16,16W,18,18W", "22", "48,50", "22"],
                  ["20,20W,22W", "24", "50,52", "24"],
                  ["20,20W,22,22W,24W", "26", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "28", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "30", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "32", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "34", "58,60,62", "34"],
                  ["30,30W,32,32W", "36", "60,62", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU", "28AU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU", "30AU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        eu: ["48EU", "50EU"],
                        uk: ["22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20", "20W", "22W"],
                        au: ["20AU", "22AU", "24AU", "26AU"],
                        uk: ["20UK", "22UK", "24UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU"],
                        eu: ["46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["20AU"],
                        eu: ["46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        au: ["22AU"],
                        eu: ["48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["26AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["28AU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["30AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["32AU"],
                        eu: ["56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["34AU"],
                        eu: ["58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["36AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["38AU"],
                        eu: ["62EU"],
                        uk: ["38UK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "dcb476dd402403bf28a2606b",
              name: "Petite",
              tags: ["petite"],
              category_id: "a8b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P,32P", "2P"],
                  ["00P,0P", "2P,4P,6P", "30P,32P,34P", "4P"],
                  ["0P,2P", "4P,6P,8P", "32P,34P,36P", "6P"],
                  ["2P,4P", "10P,6P,8P", "34P,36P,38P", "8P"],
                  ["4P,6P", "10P,12P,8P", "36P,38P,40P", "10P"],
                  ["6P,8P", "10P,12P,14P", "38P,40P,42P", "12P"],
                  ["8P,10P", "12P,14P,16P", "40P,42P,44P", "14P"],
                  ["10P,12P", "14P,16P,18P", "42P,44P,46P", "16P"],
                  ["12P,14P", "16P,18P,20P", "44P,46P,48P", "18P"],
                  ["14P,16P", "18P,20P,22P", "46P,48P,50P", "20P"],
                  ["16P,18P", "20P,22P,24P", "48P,50P,52P", "22P"],
                  ["18P,20P", "22P,24P", "50P,52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P,4P", "30P,32P", "2P,4P"],
                ["0P", "4P,6P", "32P,34P", "4P,6P"],
                ["2P", "6P,8P", "34P,36P", "6P,8P"],
                ["4P", "8P,10P", "36P,38P", "8P,10P"],
                ["6P", "10P,12P", "38P,40P", "10P,12P"],
                ["8P", "12P,14P", "40P,42P", "12P,14P"],
                ["10P", "14P,16P", "42P,44P", "14P,16P"],
                ["12P", "16P,18P", "44P,46P", "16P,18P"],
                ["14P", "18P,20P", "46P,48P", "18P,20P"],
                ["16P", "20P,22P", "48P,50P", "20P,22P"],
                ["18P", "22P,24P", "50P,52P", "22P,24P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "6PAU"],
                        eu: ["32PEU", "34PEU"],
                        uk: ["4PUK", "6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU"],
                        uk: ["6PUK", "8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "10PAU"],
                        eu: ["36PEU", "38PEU"],
                        uk: ["8PUK", "10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "12PAU"],
                        eu: ["38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "14PAU"],
                        eu: ["40PEU", "42PEU"],
                        uk: ["12PUK", "14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "16PAU"],
                        eu: ["42PEU", "44PEU"],
                        uk: ["14PUK", "16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "18PAU"],
                        eu: ["44PEU", "46PEU"],
                        uk: ["16PUK", "18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "20PAU"],
                        eu: ["46PEU", "48PEU"],
                        uk: ["18PUK", "20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "22PAU"],
                        eu: ["48PEU", "50PEU"],
                        uk: ["20PUK", "22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["6PAU", "8PAU", "10PAU"],
                        uk: ["6PUK", "8PUK", "10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["8PAU", "10PAU", "12PAU"],
                        uk: ["8PUK", "10PUK", "12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "ddb476dd402403bf28a2606b",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "a8b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "4,6", "30,32", "4"],
                  ["0,0,1", "4,6,8", "30,32,34", "6"],
                  ["0,1", "6,8", "32,34", "8"],
                  ["3,5", "10,11", "34,36", "10"],
                  ["3,5", "10,11", "34,36", "11"],
                  ["7,9", "12,14", "38,40", "12"],
                  ["7,9", "12,14", "38,40", "14"],
                  ["11,13", "16,18", "42,44", "16"],
                  ["11,13", "16,18,20", "42,44", "18"],
                  ["15,17", "18,20", "44,46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["0", "4,6", "30,32", "4,6"],
                ["0", "6,8", "32,34", "6,8"],
                ["1", "6,8", "32,34", "6,8"],
                ["3", "10,11", "34,36", "10,11"],
                ["5", "10,11", "34,36", "10,11"],
                ["7", "12,14", "38,40", "12,14"],
                ["9", "12,14", "38,40", "12,14"],
                ["11", "16,18", "42,44", "16,18"],
                ["13", "16,18", "42,44", "16,18"],
                ["15", "20,22", "44,46", "20,22"],
                ["17", "20,22", "44,46", "20,22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J", "3J", "5J"],
                        au: ["6JAU", "8JAU", "10JAU", "11JAU"],
                        uk: ["6JUK", "8JUK", "10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J", "15J", "17J"],
                        au: ["16JAU", "18JAU", "20JAU", "22JAU"],
                        uk: ["16JUK", "18JUK", "20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "deb476dd402403bf28a2606b",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "a8b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_patiala_pants",
          aliases: [],
          slug: "Patiala_Pants",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
        },
        {
          id: "a9b476dd402403bf28a2606b",
          display: "Salwars",
          type: "c",
          size_sets: [
            {
              id: "dfb476dd402403bf28a2606b",
              name: "Standard",
              tags: ["standard"],
              category_id: "a9b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "6AU"],
                        eu: ["32EU", "34EU"],
                        uk: ["4UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["0"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        eu: ["30EU", "32EU", "34EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        eu: ["32EU", "34EU", "36EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["30EU", "32EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["32EU", "34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "e0b476dd402403bf28a2606b",
              name: "Plus",
              tags: ["plus"],
              category_id: "a9b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34", "58,60,62", "34"],
                  ["30,30W,32,32W", "36,38", "60,62", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU", "28AU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU", "30AU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20", "20W", "22W"],
                        au: ["20AU", "22AU", "24AU", "26AU"],
                        uk: ["20UK", "22UK", "24UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "e1b476dd402403bf28a2606b",
              name: "Petite",
              tags: ["petite"],
              category_id: "a9b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P,32P", "2P"],
                  ["00P,0P", "2P,4P,6P", "30P,32P,34P", "4P"],
                  ["0P,2P", "4P,6P,8P", "32P,34P,36P", "6P"],
                  ["2P,4P", "10P,6P,8P", "34P,36P,38P", "8P"],
                  ["4P,6P", "10P,12P,8P", "36P,38P,40P", "10P"],
                  ["6P,8P", "10P,12P,14P", "38P,40P,42P", "12P"],
                  ["8P,10P", "12P,14P,16P", "40P,42P,44P", "14P"],
                  ["10P,12P", "14P,16P,18P", "42P,44P,46P", "16P"],
                  ["12P,14P", "16P,18P,20P", "44P,46P,48P", "18P"],
                  ["14P,16P", "18P,20P,22P", "46P,48P,50P", "20P"],
                  ["16P,18P", "20P,22P,24P", "48P,50P,52P", "22P"],
                  ["18P,20P", "22P,24P", "50P,52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P,4P", "30P,32P", "2P,4P"],
                ["0P", "4P,6P", "32P,34P", "4P,6P"],
                ["2P", "6P,8P", "34P,36P", "6P,8P"],
                ["4P", "8P,10P", "36P,38P", "8P,10P"],
                ["6P", "10P,12P", "38P,40P", "10P,12P"],
                ["8P", "12P,14P", "40P,42P", "12P,14P"],
                ["10P", "14P,16P", "42P,44P", "14P,16P"],
                ["12P", "16P,18P", "44P,46P", "16P,18P"],
                ["14P", "18P,20P", "46P,48P", "18P,20P"],
                ["16P", "20P,22P", "48P,50P", "20P,22P"],
                ["18P", "22P,24P", "50P,52P", "22P,24P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "6PAU"],
                        eu: ["32PEU", "34PEU"],
                        uk: ["4PUK", "6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU"],
                        uk: ["6PUK", "8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "10PAU"],
                        eu: ["36PEU", "38PEU"],
                        uk: ["8PUK", "10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "12PAU"],
                        eu: ["38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "14PAU"],
                        eu: ["40PEU", "42PEU"],
                        uk: ["12PUK", "14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "16PAU"],
                        eu: ["42PEU", "44PEU"],
                        uk: ["14PUK", "16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "18PAU"],
                        eu: ["44PEU", "46PEU"],
                        uk: ["16PUK", "18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "20PAU"],
                        eu: ["46PEU", "48PEU"],
                        uk: ["18PUK", "20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "22PAU"],
                        eu: ["48PEU", "50PEU"],
                        uk: ["20PUK", "22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["6PAU", "8PAU", "10PAU"],
                        uk: ["6PUK", "8PUK", "10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["8PAU", "10PAU", "12PAU"],
                        uk: ["8PUK", "10PUK", "12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "e2b476dd402403bf28a2606b",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "a9b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "4,6", "30,32", "4"],
                  ["0,0,1", "4,6,8", "30,32,34", "6"],
                  ["0,1", "6,8", "32,34", "8"],
                  ["3,5", "10,11", "34,36", "10"],
                  ["3,5", "10,11", "34,36", "11"],
                  ["7,9", "12,14", "38,40", "12"],
                  ["7,9", "12,14", "38,40", "14"],
                  ["11,13", "16,18", "42,44", "16"],
                  ["11,13", "16,18,20", "42,44", "18"],
                  ["15,17", "18,20", "44,46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["0", "4,6", "30,32", "4,6"],
                ["0", "6,8", "32,34", "6,8"],
                ["1", "6,8", "32,34", "6,8"],
                ["3", "10,11", "34,36", "10,11"],
                ["5", "10,11", "34,36", "10,11"],
                ["7", "12,14", "38,40", "12,14"],
                ["9", "12,14", "38,40", "12,14"],
                ["11", "16,18", "42,44", "16,18"],
                ["13", "16,18", "42,44", "16,18"],
                ["15", "20,22", "44,46", "20,22"],
                ["17", "20,22", "44,46", "20,22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J", "3J", "5J"],
                        au: ["6JAU", "8JAU", "10JAU", "11JAU"],
                        uk: ["6JUK", "8JUK", "10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J", "15J", "17J"],
                        au: ["16JAU", "18JAU", "20JAU", "22JAU"],
                        uk: ["16JUK", "18JUK", "20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "e3b476dd402403bf28a2606b",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "a9b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_salwars",
          aliases: [],
          slug: "Salwars",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
        },
        {
          id: "aab476dd402403bf28a2606b",
          display: "Saree Blouses",
          type: "c",
          size_sets: [
            {
              id: "e4b476dd402403bf28a2606b",
              name: "Standard",
              tags: ["standard"],
              category_id: "aab476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "6AU"],
                        eu: ["32EU", "34EU"],
                        uk: ["4UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["0"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        eu: ["30EU", "32EU", "34EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        eu: ["32EU", "34EU", "36EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["30EU", "32EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["32EU", "34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "e5b476dd402403bf28a2606b",
              name: "Plus",
              tags: ["plus"],
              category_id: "aab476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34", "58,60,62", "34"],
                  ["30,30W,32,32W", "36,38", "60,62", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU", "28AU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU", "30AU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20", "20W", "22W"],
                        au: ["20AU", "22AU", "24AU", "26AU"],
                        uk: ["20UK", "22UK", "24UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "e6b476dd402403bf28a2606b",
              name: "Petite",
              tags: ["petite"],
              category_id: "aab476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P,32P", "2P"],
                  ["00P,0P", "2P,4P,6P", "30P,32P,34P", "4P"],
                  ["0P,2P", "4P,6P,8P", "32P,34P,36P", "6P"],
                  ["2P,4P", "10P,6P,8P", "34P,36P,38P", "8P"],
                  ["4P,6P", "10P,12P,8P", "36P,38P,40P", "10P"],
                  ["6P,8P", "10P,12P,14P", "38P,40P,42P", "12P"],
                  ["8P,10P", "12P,14P,16P", "40P,42P,44P", "14P"],
                  ["10P,12P", "14P,16P,18P", "42P,44P,46P", "16P"],
                  ["12P,14P", "16P,18P,20P", "44P,46P,48P", "18P"],
                  ["14P,16P", "18P,20P,22P", "46P,48P,50P", "20P"],
                  ["16P,18P", "20P,22P,24P", "48P,50P,52P", "22P"],
                  ["18P,20P", "22P,24P", "50P,52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P,4P", "30P,32P", "2P,4P"],
                ["0P", "4P,6P", "32P,34P", "4P,6P"],
                ["2P", "6P,8P", "34P,36P", "6P,8P"],
                ["4P", "8P,10P", "36P,38P", "8P,10P"],
                ["6P", "10P,12P", "38P,40P", "10P,12P"],
                ["8P", "12P,14P", "40P,42P", "12P,14P"],
                ["10P", "14P,16P", "42P,44P", "14P,16P"],
                ["12P", "16P,18P", "44P,46P", "16P,18P"],
                ["14P", "18P,20P", "46P,48P", "18P,20P"],
                ["16P", "20P,22P", "48P,50P", "20P,22P"],
                ["18P", "22P,24P", "50P,52P", "22P,24P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "6PAU"],
                        eu: ["32PEU", "34PEU"],
                        uk: ["4PUK", "6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU"],
                        uk: ["6PUK", "8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "10PAU"],
                        eu: ["36PEU", "38PEU"],
                        uk: ["8PUK", "10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "12PAU"],
                        eu: ["38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "14PAU"],
                        eu: ["40PEU", "42PEU"],
                        uk: ["12PUK", "14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "16PAU"],
                        eu: ["42PEU", "44PEU"],
                        uk: ["14PUK", "16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "18PAU"],
                        eu: ["44PEU", "46PEU"],
                        uk: ["16PUK", "18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "20PAU"],
                        eu: ["46PEU", "48PEU"],
                        uk: ["18PUK", "20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "22PAU"],
                        eu: ["48PEU", "50PEU"],
                        uk: ["20PUK", "22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["6PAU", "8PAU", "10PAU"],
                        uk: ["6PUK", "8PUK", "10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["8PAU", "10PAU", "12PAU"],
                        uk: ["8PUK", "10PUK", "12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "e7b476dd402403bf28a2606b",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "aab476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "4,6", "30,32", "4"],
                  ["0,0,1", "4,6,8", "30,32,34", "6"],
                  ["0,1", "6,8", "32,34", "8"],
                  ["3,5", "10,11", "34,36", "10"],
                  ["3,5", "10,11", "34,36", "11"],
                  ["7,9", "12,14", "38,40", "12"],
                  ["7,9", "12,14", "38,40", "14"],
                  ["11,13", "16,18", "42,44", "16"],
                  ["11,13", "16,18,20", "42,44", "18"],
                  ["15,17", "18,20", "44,46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["0", "4,6", "30,32", "4,6"],
                ["0", "6,8", "32,34", "6,8"],
                ["1", "6,8", "32,34", "6,8"],
                ["3", "10,11", "34,36", "10,11"],
                ["5", "10,11", "34,36", "10,11"],
                ["7", "12,14", "38,40", "12,14"],
                ["9", "12,14", "38,40", "12,14"],
                ["11", "16,18", "42,44", "16,18"],
                ["13", "16,18", "42,44", "16,18"],
                ["15", "20,22", "44,46", "20,22"],
                ["17", "20,22", "44,46", "20,22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J", "3J", "5J"],
                        au: ["6JAU", "8JAU", "10JAU", "11JAU"],
                        uk: ["6JUK", "8JUK", "10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J", "15J", "17J"],
                        au: ["16JAU", "18JAU", "20JAU", "22JAU"],
                        uk: ["16JUK", "18JUK", "20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "e8b476dd402403bf28a2606b",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "aab476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_saree_blouses",
          aliases: [],
          slug: "Saree_Blouses",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
        },
        {
          id: "abb476dd402403bf28a2606b",
          display: "Sarees",
          type: "c",
          size_sets: [
            {
              id: "e9b476dd402403bf28a2606b",
              name: "Standard",
              tags: ["standard"],
              category_id: "abb476dd402403bf28a2606b",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_sarees",
          aliases: [],
          slug: "Sarees",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
        },
        {
          id: "acb476dd402403bf28a2606b",
          display: "Treggings",
          type: "c",
          size_sets: [
            {
              id: "eeb476dd402403bf28a2606b",
              name: "Standard",
              tags: ["standard"],
              category_id: "acb476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00",
                      equivalent_sizes: {
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["4AU", "6AU"],
                        eu: ["32EU", "34EU"],
                        uk: ["4UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXS",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS",
                      equivalent_sizes: {
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2AU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["0"],
                        eu: ["30EU", "32EU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        eu: ["30EU", "32EU", "34EU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        eu: ["32EU", "34EU", "36EU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        eu: ["34EU", "36EU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        eu: ["38EU", "40EU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16AU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        eu: ["42EU", "44EU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSAU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        eu: ["XXSEU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30EU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        uk: ["2UK", "4UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32EU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        uk: ["2UK", "4UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34EU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        uk: ["4UK", "6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36EU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        uk: ["6UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        uk: ["10UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        uk: ["14UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSEU",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        uk: ["XXSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2UK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["0"],
                        au: ["2AU", "4AU"],
                        eu: ["30EU", "32EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["00", "0"],
                        au: ["2AU", "4AU", "6AU"],
                        eu: ["30EU", "32EU", "34EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["0", "2", "4"],
                        au: ["4AU", "6AU", "8AU"],
                        eu: ["32EU", "34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["2", "4"],
                        au: ["6AU", "8AU"],
                        eu: ["34EU", "36EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["6", "8"],
                        au: ["10AU", "12AU"],
                        eu: ["38EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["10", "12"],
                        au: ["14AU", "16AU"],
                        eu: ["42EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSUK",
                      short: "XXS",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS",
                      equivalent_sizes: {
                        us: ["XXS"],
                        au: ["XXSAU"],
                        eu: ["XXSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "efb476dd402403bf28a2606b",
              name: "Plus",
              tags: ["plus"],
              category_id: "acb476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34", "58,60,62", "34"],
                  ["30,30W,32,32W", "36,38", "60,62", "36"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14W",
                      short: "14W",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16W",
                      short: "16W",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W",
                      equivalent_sizes: {
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18W",
                      short: "18W",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W",
                      equivalent_sizes: {
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20W",
                      short: "20W",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22",
                      equivalent_sizes: {
                        au: ["26AU", "28AU"],
                        eu: ["52EU", "54EU"],
                        uk: ["26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22W",
                      short: "22W",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W",
                      equivalent_sizes: {
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24",
                      equivalent_sizes: {
                        au: ["28AU", "30AU"],
                        eu: ["54EU", "56EU"],
                        uk: ["28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24W",
                      short: "24W",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W",
                      equivalent_sizes: {
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26",
                      equivalent_sizes: {
                        au: ["30AU", "32AU"],
                        eu: ["56EU", "58EU"],
                        uk: ["30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26W",
                      short: "26W",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W",
                      equivalent_sizes: {
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28",
                      equivalent_sizes: {
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28W",
                      short: "28W",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W",
                      equivalent_sizes: {
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30",
                      equivalent_sizes: {
                        au: ["34AU", "36AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30W",
                      short: "30W",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W",
                      equivalent_sizes: {
                        au: ["32AU", "34AU", "36AU"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32",
                      equivalent_sizes: {
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32W",
                      short: "32W",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W",
                      equivalent_sizes: {
                        au: ["34AU", "36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXL",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL",
                      equivalent_sizes: {
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0X",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X",
                      equivalent_sizes: {
                        au: ["0XAU"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1X",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X",
                      equivalent_sizes: {
                        au: ["1XAU"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2X",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X",
                      equivalent_sizes: {
                        au: ["2XAU"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3X",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X",
                      equivalent_sizes: {
                        au: ["3XAU"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4X",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X",
                      equivalent_sizes: {
                        au: ["4XAU"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5X",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X",
                      equivalent_sizes: {
                        au: ["5XAU"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "18AU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        eu: ["46EU", "48EU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20AU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        eu: ["46EU", "48EU", "50EU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22AU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        eu: ["48EU", "50EU"],
                        uk: ["20UK", "22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24AU",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        eu: ["50EU", "52EU"],
                        uk: ["24UK", "26UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26AU",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        eu: ["50EU", "52EU", "54EU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        eu: ["52EU", "54EU", "56EU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        eu: ["54EU", "56EU", "58EU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        eu: ["56EU", "58EU", "60EU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        eu: ["58EU", "60EU", "62EU"],
                        uk: ["32UK", "34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        eu: ["60EU", "62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        eu: ["62EU"],
                        uk: ["36UK", "38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLAU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        eu: ["XXXLEU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XAU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        eu: ["0XEU"],
                        uk: ["0XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XAU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        eu: ["1XEU"],
                        uk: ["1XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XAU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        eu: ["2XEU"],
                        uk: ["2XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XAU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        eu: ["3XEU"],
                        uk: ["3XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XAU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        eu: ["4XEU"],
                        uk: ["4XUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XAU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        eu: ["5XEU"],
                        uk: ["5XUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        uk: ["18UK", "20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        uk: ["18UK", "20UK", "22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50EU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W", "20", "20W", "22W"],
                        au: ["20AU", "22AU", "24AU", "26AU"],
                        uk: ["20UK", "22UK", "24UK", "26UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52EU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        uk: ["24UK", "26UK", "28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54EU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        uk: ["26UK", "28UK", "30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56EU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        uk: ["28UK", "30UK", "32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58EU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        uk: ["30UK", "32UK", "34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60EU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU", "36AU"],
                        uk: ["32UK", "34UK", "36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62EU",
                      short: "62",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["34AU", "36AU", "38AU"],
                        uk: ["34UK", "36UK", "38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLEU",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        uk: ["XXXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XEU",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        uk: ["0XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XEU",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        uk: ["1XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XEU",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        uk: ["2XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XEU",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        uk: ["3XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XEU",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        uk: ["4XUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XEU",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        uk: ["5XUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "18UK",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18",
                      equivalent_sizes: {
                        us: ["14", "14W", "16W"],
                        au: ["18AU", "20AU"],
                        eu: ["46EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20",
                      equivalent_sizes: {
                        us: ["14", "14W", "16", "16W", "18", "18W"],
                        au: ["18AU", "20AU", "22AU"],
                        eu: ["46EU", "48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22",
                      equivalent_sizes: {
                        us: ["16", "16W", "18", "18W"],
                        au: ["20AU", "22AU"],
                        eu: ["48EU", "50EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24UK",
                      short: "24",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24",
                      equivalent_sizes: {
                        us: ["20", "20W", "22W"],
                        au: ["24AU", "26AU"],
                        eu: ["50EU", "52EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26UK",
                      short: "26",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26",
                      equivalent_sizes: {
                        us: ["20", "20W", "22", "22W", "24W"],
                        au: ["24AU", "26AU", "28AU"],
                        eu: ["50EU", "52EU", "54EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["22", "22W", "24", "24W", "26W"],
                        au: ["26AU", "28AU", "30AU"],
                        eu: ["52EU", "54EU", "56EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["24", "24W", "26", "26W", "28W"],
                        au: ["28AU", "30AU", "32AU"],
                        eu: ["54EU", "56EU", "58EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["26", "26W", "28", "28W", "30W"],
                        au: ["30AU", "32AU", "34AU"],
                        eu: ["56EU", "58EU", "60EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["28", "28W", "30", "30W", "32W"],
                        au: ["32AU", "34AU"],
                        eu: ["58EU", "60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["30", "30W", "32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["60EU", "62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["32", "32W"],
                        au: ["36AU", "38AU"],
                        eu: ["62EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLUK",
                      short: "XXXL",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL",
                      equivalent_sizes: {
                        us: ["XXXL"],
                        au: ["XXXLAU"],
                        eu: ["XXXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XUK",
                      short: "0X",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X",
                      equivalent_sizes: {
                        us: ["0X"],
                        au: ["0XAU"],
                        eu: ["0XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XUK",
                      short: "1X",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X",
                      equivalent_sizes: {
                        us: ["1X"],
                        au: ["1XAU"],
                        eu: ["1XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XUK",
                      short: "2X",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X",
                      equivalent_sizes: {
                        us: ["2X"],
                        au: ["2XAU"],
                        eu: ["2XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XUK",
                      short: "3X",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X",
                      equivalent_sizes: {
                        us: ["3X"],
                        au: ["3XAU"],
                        eu: ["3XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XUK",
                      short: "4X",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X",
                      equivalent_sizes: {
                        us: ["4X"],
                        au: ["4XAU"],
                        eu: ["4XEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XUK",
                      short: "5X",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X",
                      equivalent_sizes: {
                        us: ["5X"],
                        au: ["5XAU"],
                        eu: ["5XEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "f0b476dd402403bf28a2606b",
              name: "Petite",
              tags: ["petite"],
              category_id: "acb476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-32", "24-25", "33-34"],
                  ["32-34", "25-27", "34-36"],
                  ["34-36", "27-29", "36-38"],
                  ["36-38", "29-31", "38-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                  ["44-46", "37-39", "46-48"],
                  ["46-48", "39-41", "48-50"],
                  ["48-50", "41-43", "50-52"],
                  ["29-30", "22-23", "31-32"],
                  ["30-31", "23-24", "32-33"],
                  ["31-34", "24-27", "33-36"],
                  ["34-38", "27-31", "36-40"],
                  ["38-40", "31-33", "40-42"],
                  ["40-42", "33-35", "42-44"],
                  ["42-44", "35-37", "44-46"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-81.3", "61.0-63.5", "83.8-86.4"],
                  ["81.3-86.4", "63.5-68.6", "86.4-91.4"],
                  ["86.4-91.4", "68.6-73.7", "91.4-96.5"],
                  ["91.4-96.5", "73.7-78.7", "96.5-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                  ["111.8-116.8", "94.0-99.1", "116.8-121.9"],
                  ["116.8-121.9", "99.1-104.1", "121.9-127.0"],
                  ["121.9-127.0", "104.1-109.2", "127.0-132.1"],
                  ["73.7-76.2", "55.9-58.4", "78.7-81.3"],
                  ["76.2-78.7", "58.4-61.0", "81.3-83.8"],
                  ["78.7-86.4", "61.0-68.6", "83.8-91.4"],
                  ["86.4-96.5", "68.6-78.7", "91.4-101.6"],
                  ["96.5-101.6", "78.7-83.8", "101.6-106.7"],
                  ["101.6-106.7", "83.8-88.9", "106.7-111.8"],
                  ["106.7-111.8", "88.9-94.0", "111.8-116.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00P", "2P,4P", "30P,32P", "2P"],
                  ["00P,0P", "2P,4P,6P", "30P,32P,34P", "4P"],
                  ["0P,2P", "4P,6P,8P", "32P,34P,36P", "6P"],
                  ["2P,4P", "10P,6P,8P", "34P,36P,38P", "8P"],
                  ["4P,6P", "10P,12P,8P", "36P,38P,40P", "10P"],
                  ["6P,8P", "10P,12P,14P", "38P,40P,42P", "12P"],
                  ["8P,10P", "12P,14P,16P", "40P,42P,44P", "14P"],
                  ["10P,12P", "14P,16P,18P", "42P,44P,46P", "16P"],
                  ["12P,14P", "16P,18P,20P", "44P,46P,48P", "18P"],
                  ["14P,16P", "18P,20P,22P", "46P,48P,50P", "20P"],
                  ["16P,18P", "20P,22P,24P", "48P,50P,52P", "22P"],
                  ["18P,20P", "22P,24P", "50P,52P", "24P"],
                  ["XXSP", "XXSP", "XXSP", "XXSP"],
                  ["XSP", "XSP", "XSP", "XSP"],
                  ["SP", "SP", "SP", "SP"],
                  ["MP", "MP", "MP", "MP"],
                  ["LP", "LP", "LP", "LP"],
                  ["XLP", "XLP", "XLP", "XLP"],
                  ["XXLP", "XXLP", "XXLP", "XXLP"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00P", "2P,4P", "30P,32P", "2P,4P"],
                ["0P", "4P,6P", "32P,34P", "4P,6P"],
                ["2P", "6P,8P", "34P,36P", "6P,8P"],
                ["4P", "8P,10P", "36P,38P", "8P,10P"],
                ["6P", "10P,12P", "38P,40P", "10P,12P"],
                ["8P", "12P,14P", "40P,42P", "12P,14P"],
                ["10P", "14P,16P", "42P,44P", "14P,16P"],
                ["12P", "16P,18P", "44P,46P", "16P,18P"],
                ["14P", "18P,20P", "46P,48P", "18P,20P"],
                ["16P", "20P,22P", "48P,50P", "20P,22P"],
                ["18P", "22P,24P", "50P,52P", "22P,24P"],
                ["20P", "24P", "52P", "24P"],
                ["XXSP", "XXSP", "XXSP", "XXSP"],
                ["XSP", "XSP", "XSP", "XSP"],
                ["SP", "SP", "SP", "SP"],
                ["MP", "MP", "MP", "MP"],
                ["LP", "LP", "LP", "LP"],
                ["XLP", "XLP", "XLP", "XLP"],
                ["XXLP", "XXLP", "XXLP", "XXLP"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00P",
                      short: "00P",
                      long: "00P",
                      display: "00P",
                      display_with_size_set: "00P",
                      display_with_size_system: "US 00P",
                      display_with_system_and_set: "US 00P",
                      equivalent_sizes: {
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0P",
                      short: "0P",
                      long: "0P",
                      display: "0P",
                      display_with_size_set: "0P",
                      display_with_size_system: "US 0P",
                      display_with_system_and_set: "US 0P",
                      equivalent_sizes: {
                        au: ["4PAU", "6PAU"],
                        eu: ["32PEU", "34PEU"],
                        uk: ["4PUK", "6PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2P",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "US 2P",
                      display_with_system_and_set: "US 2P",
                      equivalent_sizes: {
                        au: ["6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU"],
                        uk: ["6PUK", "8PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4P",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "US 4P",
                      display_with_system_and_set: "US 4P",
                      equivalent_sizes: {
                        au: ["8PAU", "10PAU"],
                        eu: ["36PEU", "38PEU"],
                        uk: ["8PUK", "10PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6P",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "US 6P",
                      display_with_system_and_set: "US 6P",
                      equivalent_sizes: {
                        au: ["10PAU", "12PAU"],
                        eu: ["38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8P",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "US 8P",
                      display_with_system_and_set: "US 8P",
                      equivalent_sizes: {
                        au: ["12PAU", "14PAU"],
                        eu: ["40PEU", "42PEU"],
                        uk: ["12PUK", "14PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10P",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "US 10P",
                      display_with_system_and_set: "US 10P",
                      equivalent_sizes: {
                        au: ["14PAU", "16PAU"],
                        eu: ["42PEU", "44PEU"],
                        uk: ["14PUK", "16PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12P",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "US 12P",
                      display_with_system_and_set: "US 12P",
                      equivalent_sizes: {
                        au: ["16PAU", "18PAU"],
                        eu: ["44PEU", "46PEU"],
                        uk: ["16PUK", "18PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14P",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "US 14P",
                      display_with_system_and_set: "US 14P",
                      equivalent_sizes: {
                        au: ["18PAU", "20PAU"],
                        eu: ["46PEU", "48PEU"],
                        uk: ["18PUK", "20PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16P",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "US 16P",
                      display_with_system_and_set: "US 16P",
                      equivalent_sizes: {
                        au: ["20PAU", "22PAU"],
                        eu: ["48PEU", "50PEU"],
                        uk: ["20PUK", "22PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18P",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "US 18P",
                      display_with_system_and_set: "US 18P",
                      equivalent_sizes: {
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20P",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "US 20P",
                      display_with_system_and_set: "US 20P",
                      equivalent_sizes: {
                        au: ["24PAU"],
                        eu: ["52PEU"],
                        uk: ["24PUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSP",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "US XXSP",
                      display_with_system_and_set: "US XXSP",
                      equivalent_sizes: {
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSP",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "US XSP",
                      display_with_system_and_set: "US XSP",
                      equivalent_sizes: {
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SP",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "US SP",
                      display_with_system_and_set: "US SP",
                      equivalent_sizes: {
                        au: ["SPAU"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MP",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "US MP",
                      display_with_system_and_set: "US MP",
                      equivalent_sizes: {
                        au: ["MPAU"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LP",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "US LP",
                      display_with_system_and_set: "US LP",
                      equivalent_sizes: {
                        au: ["LPAU"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLP",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "US XLP",
                      display_with_system_and_set: "US XLP",
                      equivalent_sizes: {
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLP",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "US XXLP",
                      display_with_system_and_set: "US XXLP",
                      equivalent_sizes: {
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2PAU",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "AU 2P",
                      display_with_system_and_set: "AU 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        eu: ["30PEU", "32PEU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4PAU",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "AU 4P",
                      display_with_system_and_set: "AU 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6PAU",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "AU 6P",
                      display_with_system_and_set: "AU 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8PAU",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "AU 8P",
                      display_with_system_and_set: "AU 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                        uk: ["10PUK", "6PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10PAU",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "AU 10P",
                      display_with_system_and_set: "AU 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                        uk: ["10PUK", "12PUK", "8PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12PAU",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "AU 12P",
                      display_with_system_and_set: "AU 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14PAU",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "AU 14P",
                      display_with_system_and_set: "AU 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16PAU",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "AU 16P",
                      display_with_system_and_set: "AU 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18PAU",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "AU 18P",
                      display_with_system_and_set: "AU 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20PAU",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "AU 20P",
                      display_with_system_and_set: "AU 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22PAU",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "AU 22P",
                      display_with_system_and_set: "AU 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24PAU",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "AU 24P",
                      display_with_system_and_set: "AU 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        eu: ["50PEU", "52PEU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSPAU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "AU XXSP",
                      display_with_system_and_set: "AU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        eu: ["XXSPEU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSPAU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "AU XSP",
                      display_with_system_and_set: "AU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        eu: ["XSPEU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SPAU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "AU SP",
                      display_with_system_and_set: "AU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        eu: ["SPEU"],
                        uk: ["SPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MPAU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "AU MP",
                      display_with_system_and_set: "AU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        eu: ["MPEU"],
                        uk: ["MPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LPAU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "AU LP",
                      display_with_system_and_set: "AU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        eu: ["LPEU"],
                        uk: ["LPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLPAU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "AU XLP",
                      display_with_system_and_set: "AU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        eu: ["XLPEU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLPAU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "AU XXLP",
                      display_with_system_and_set: "AU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        eu: ["XXLPEU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30PEU",
                      short: "30P",
                      long: "30P",
                      display: "30P",
                      display_with_size_set: "30P",
                      display_with_size_system: "EU 30P",
                      display_with_system_and_set: "EU 30P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        uk: ["2PUK", "4PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32PEU",
                      short: "32P",
                      long: "32P",
                      display: "32P",
                      display_with_size_set: "32P",
                      display_with_size_system: "EU 32P",
                      display_with_system_and_set: "EU 32P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        uk: ["2PUK", "4PUK", "6PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34PEU",
                      short: "34P",
                      long: "34P",
                      display: "34P",
                      display_with_size_set: "34P",
                      display_with_size_system: "EU 34P",
                      display_with_system_and_set: "EU 34P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        uk: ["4PUK", "6PUK", "8PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36PEU",
                      short: "36P",
                      long: "36P",
                      display: "36P",
                      display_with_size_set: "36P",
                      display_with_size_system: "EU 36P",
                      display_with_system_and_set: "EU 36P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["6PAU", "8PAU", "10PAU"],
                        uk: ["6PUK", "8PUK", "10PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38PEU",
                      short: "38P",
                      long: "38P",
                      display: "38P",
                      display_with_size_set: "38P",
                      display_with_size_system: "EU 38P",
                      display_with_system_and_set: "EU 38P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["8PAU", "10PAU", "12PAU"],
                        uk: ["8PUK", "10PUK", "12PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40PEU",
                      short: "40P",
                      long: "40P",
                      display: "40P",
                      display_with_size_set: "40P",
                      display_with_size_system: "EU 40P",
                      display_with_system_and_set: "EU 40P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        uk: ["10PUK", "12PUK", "14PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42PEU",
                      short: "42P",
                      long: "42P",
                      display: "42P",
                      display_with_size_set: "42P",
                      display_with_size_system: "EU 42P",
                      display_with_system_and_set: "EU 42P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        uk: ["12PUK", "14PUK", "16PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44PEU",
                      short: "44P",
                      long: "44P",
                      display: "44P",
                      display_with_size_set: "44P",
                      display_with_size_system: "EU 44P",
                      display_with_system_and_set: "EU 44P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        uk: ["14PUK", "16PUK", "18PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46PEU",
                      short: "46P",
                      long: "46P",
                      display: "46P",
                      display_with_size_set: "46P",
                      display_with_size_system: "EU 46P",
                      display_with_system_and_set: "EU 46P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        uk: ["16PUK", "18PUK", "20PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48PEU",
                      short: "48P",
                      long: "48P",
                      display: "48P",
                      display_with_size_set: "48P",
                      display_with_size_system: "EU 48P",
                      display_with_system_and_set: "EU 48P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        uk: ["18PUK", "20PUK", "22PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50PEU",
                      short: "50P",
                      long: "50P",
                      display: "50P",
                      display_with_size_set: "50P",
                      display_with_size_system: "EU 50P",
                      display_with_system_and_set: "EU 50P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        uk: ["20PUK", "22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52PEU",
                      short: "52P",
                      long: "52P",
                      display: "52P",
                      display_with_size_set: "52P",
                      display_with_size_system: "EU 52P",
                      display_with_system_and_set: "EU 52P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        uk: ["22PUK", "24PUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSPEU",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "EU XXSP",
                      display_with_system_and_set: "EU XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        uk: ["XXSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSPEU",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "EU XSP",
                      display_with_system_and_set: "EU XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        uk: ["XSPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SPEU",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "EU SP",
                      display_with_system_and_set: "EU SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        uk: ["SPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MPEU",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "EU MP",
                      display_with_system_and_set: "EU MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        uk: ["MPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LPEU",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "EU LP",
                      display_with_system_and_set: "EU LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        uk: ["LPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLPEU",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "EU XLP",
                      display_with_system_and_set: "EU XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        uk: ["XLPUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLPEU",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "EU XXLP",
                      display_with_system_and_set: "EU XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        uk: ["XXLPUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2PUK",
                      short: "2P",
                      long: "2P",
                      display: "2P",
                      display_with_size_set: "2P",
                      display_with_size_system: "UK 2P",
                      display_with_system_and_set: "UK 2P",
                      equivalent_sizes: {
                        us: ["00P"],
                        au: ["2PAU", "4PAU"],
                        eu: ["30PEU", "32PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4PUK",
                      short: "4P",
                      long: "4P",
                      display: "4P",
                      display_with_size_set: "4P",
                      display_with_size_system: "UK 4P",
                      display_with_system_and_set: "UK 4P",
                      equivalent_sizes: {
                        us: ["00P", "0P"],
                        au: ["2PAU", "4PAU", "6PAU"],
                        eu: ["30PEU", "32PEU", "34PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6PUK",
                      short: "6P",
                      long: "6P",
                      display: "6P",
                      display_with_size_set: "6P",
                      display_with_size_system: "UK 6P",
                      display_with_system_and_set: "UK 6P",
                      equivalent_sizes: {
                        us: ["0P", "2P"],
                        au: ["4PAU", "6PAU", "8PAU"],
                        eu: ["32PEU", "34PEU", "36PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8PUK",
                      short: "8P",
                      long: "8P",
                      display: "8P",
                      display_with_size_set: "8P",
                      display_with_size_system: "UK 8P",
                      display_with_system_and_set: "UK 8P",
                      equivalent_sizes: {
                        us: ["2P", "4P"],
                        au: ["10PAU", "6PAU", "8PAU"],
                        eu: ["34PEU", "36PEU", "38PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10PUK",
                      short: "10P",
                      long: "10P",
                      display: "10P",
                      display_with_size_set: "10P",
                      display_with_size_system: "UK 10P",
                      display_with_system_and_set: "UK 10P",
                      equivalent_sizes: {
                        us: ["4P", "6P"],
                        au: ["10PAU", "12PAU", "8PAU"],
                        eu: ["36PEU", "38PEU", "40PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12PUK",
                      short: "12P",
                      long: "12P",
                      display: "12P",
                      display_with_size_set: "12P",
                      display_with_size_system: "UK 12P",
                      display_with_system_and_set: "UK 12P",
                      equivalent_sizes: {
                        us: ["6P", "8P"],
                        au: ["10PAU", "12PAU", "14PAU"],
                        eu: ["38PEU", "40PEU", "42PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14PUK",
                      short: "14P",
                      long: "14P",
                      display: "14P",
                      display_with_size_set: "14P",
                      display_with_size_system: "UK 14P",
                      display_with_system_and_set: "UK 14P",
                      equivalent_sizes: {
                        us: ["8P", "10P"],
                        au: ["12PAU", "14PAU", "16PAU"],
                        eu: ["40PEU", "42PEU", "44PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16PUK",
                      short: "16P",
                      long: "16P",
                      display: "16P",
                      display_with_size_set: "16P",
                      display_with_size_system: "UK 16P",
                      display_with_system_and_set: "UK 16P",
                      equivalent_sizes: {
                        us: ["10P", "12P"],
                        au: ["14PAU", "16PAU", "18PAU"],
                        eu: ["42PEU", "44PEU", "46PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18PUK",
                      short: "18P",
                      long: "18P",
                      display: "18P",
                      display_with_size_set: "18P",
                      display_with_size_system: "UK 18P",
                      display_with_system_and_set: "UK 18P",
                      equivalent_sizes: {
                        us: ["12P", "14P"],
                        au: ["16PAU", "18PAU", "20PAU"],
                        eu: ["44PEU", "46PEU", "48PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20PUK",
                      short: "20P",
                      long: "20P",
                      display: "20P",
                      display_with_size_set: "20P",
                      display_with_size_system: "UK 20P",
                      display_with_system_and_set: "UK 20P",
                      equivalent_sizes: {
                        us: ["14P", "16P"],
                        au: ["18PAU", "20PAU", "22PAU"],
                        eu: ["46PEU", "48PEU", "50PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22PUK",
                      short: "22P",
                      long: "22P",
                      display: "22P",
                      display_with_size_set: "22P",
                      display_with_size_system: "UK 22P",
                      display_with_system_and_set: "UK 22P",
                      equivalent_sizes: {
                        us: ["16P", "18P"],
                        au: ["20PAU", "22PAU", "24PAU"],
                        eu: ["48PEU", "50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24PUK",
                      short: "24P",
                      long: "24P",
                      display: "24P",
                      display_with_size_set: "24P",
                      display_with_size_system: "UK 24P",
                      display_with_system_and_set: "UK 24P",
                      equivalent_sizes: {
                        us: ["18P", "20P"],
                        au: ["22PAU", "24PAU"],
                        eu: ["50PEU", "52PEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSPUK",
                      short: "XXSP",
                      long: "XXSP",
                      display: "XXSP",
                      display_with_size_set: "XXSP",
                      display_with_size_system: "UK XXSP",
                      display_with_system_and_set: "UK XXSP",
                      equivalent_sizes: {
                        us: ["XXSP"],
                        au: ["XXSPAU"],
                        eu: ["XXSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSPUK",
                      short: "XSP",
                      long: "XSP",
                      display: "XSP",
                      display_with_size_set: "XSP",
                      display_with_size_system: "UK XSP",
                      display_with_system_and_set: "UK XSP",
                      equivalent_sizes: {
                        us: ["XSP"],
                        au: ["XSPAU"],
                        eu: ["XSPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SPUK",
                      short: "SP",
                      long: "SP",
                      display: "SP",
                      display_with_size_set: "SP",
                      display_with_size_system: "UK SP",
                      display_with_system_and_set: "UK SP",
                      equivalent_sizes: {
                        us: ["SP"],
                        au: ["SPAU"],
                        eu: ["SPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MPUK",
                      short: "MP",
                      long: "MP",
                      display: "MP",
                      display_with_size_set: "MP",
                      display_with_size_system: "UK MP",
                      display_with_system_and_set: "UK MP",
                      equivalent_sizes: {
                        us: ["MP"],
                        au: ["MPAU"],
                        eu: ["MPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LPUK",
                      short: "LP",
                      long: "LP",
                      display: "LP",
                      display_with_size_set: "LP",
                      display_with_size_system: "UK LP",
                      display_with_system_and_set: "UK LP",
                      equivalent_sizes: {
                        us: ["LP"],
                        au: ["LPAU"],
                        eu: ["LPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLPUK",
                      short: "XLP",
                      long: "XLP",
                      display: "XLP",
                      display_with_size_set: "XLP",
                      display_with_size_system: "UK XLP",
                      display_with_system_and_set: "UK XLP",
                      equivalent_sizes: {
                        us: ["XLP"],
                        au: ["XLPAU"],
                        eu: ["XLPEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLPUK",
                      short: "XXLP",
                      long: "XXLP",
                      display: "XXLP",
                      display_with_size_set: "XXLP",
                      display_with_size_system: "UK XXLP",
                      display_with_system_and_set: "UK XXLP",
                      equivalent_sizes: {
                        us: ["XXLP"],
                        au: ["XXLPAU"],
                        eu: ["XXLPEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "f1b476dd402403bf28a2606b",
              name: "Juniors",
              tags: ["juniors"],
              category_id: "acb476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-34", "24-26", "34.5-36.5"],
                  ["34-36", "26-28", "36.5-38.5"],
                  ["36-38", "28-30", "38.5-39.5"],
                  ["38-40", "30-32", "39.5-40.5"],
                  ["40-42", "32-33", "40.5-42"],
                  ["42-44", "33-34", "42-44"],
                  ["44-46", "34-36", "44-48"],
                  ["46-48", "36-37", "48-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                  ["30-32", "23-24", "33.5-34.5"],
                  ["32-36", "24-28", "34.5-38.5"],
                  ["36-40", "28-32", "38.5-40.5"],
                  ["40-44", "32-34", "40.5-44"],
                  ["44-48", "34-37", "44-50"],
                  ["48-50", "37-40", "50-52"],
                  ["50", "40", "52"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-86.4", "61.0-66.0", "87.6-92.7"],
                  ["86.4-91.4", "66.0-71.1", "92.7-97.8"],
                  ["91.4-96.5", "71.1-76.2", "97.8-100.3"],
                  ["96.5-101.6", "76.2-81.3", "100.3-102.9"],
                  ["101.6-106.7", "81.3-83.8", "102.9-106.7"],
                  ["106.7-111.8", "83.8-86.4", "106.7-111.8"],
                  ["111.8-116.8", "86.4-91.4", "111.8-121.9"],
                  ["116.8-121.9", "91.4-94.0", "121.9-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                  ["76.2-81.3", "58.4-61.0", "85.1-87.6"],
                  ["81.3-91.4", "61.0-71.1", "87.6-97.8"],
                  ["91.4-101.6", "71.1-81.3", "97.8-102.9"],
                  ["101.6-111.8", "81.3-86.4", "102.9-111.8"],
                  ["111.8-121.9", "86.4-94.0", "111.8-127.0"],
                  ["121.9-127.0", "94.0-101.6", "127.0-132.1"],
                  ["127", "101.6", "132.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0", "4,6", "30,32", "4"],
                  ["0,0,1", "4,6,8", "30,32,34", "6"],
                  ["0,1", "6,8", "32,34", "8"],
                  ["3,5", "10,11", "34,36", "10"],
                  ["3,5", "10,11", "34,36", "11"],
                  ["7,9", "12,14", "38,40", "12"],
                  ["7,9", "12,14", "38,40", "14"],
                  ["11,13", "16,18", "42,44", "16"],
                  ["11,13", "16,18,20", "42,44", "18"],
                  ["15,17", "18,20", "44,46", "20"],
                  ["15,17", "20,22", "44,46", "22"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["0", "4,6", "30,32", "4,6"],
                ["0", "6,8", "32,34", "6,8"],
                ["1", "6,8", "32,34", "6,8"],
                ["3", "10,11", "34,36", "10,11"],
                ["5", "10,11", "34,36", "10,11"],
                ["7", "12,14", "38,40", "12,14"],
                ["9", "12,14", "38,40", "12,14"],
                ["11", "16,18", "42,44", "16,18"],
                ["13", "16,18", "42,44", "16,18"],
                ["15", "20,22", "44,46", "20,22"],
                ["17", "20,22", "44,46", "20,22"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00J",
                      short: "00 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "00 (Juniors)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Juniors)",
                      equivalent_sizes: {
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0J",
                      short: "0 (Juniors)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Juniors)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1J",
                      short: "1 (Juniors)",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1 (Juniors)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Juniors)",
                      equivalent_sizes: {
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3J",
                      short: "3 (Juniors)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Juniors)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5J",
                      short: "5 (Juniors)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Juniors)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Juniors)",
                      equivalent_sizes: {
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7J",
                      short: "7 (Juniors)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Juniors)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9J",
                      short: "9 (Juniors)",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9 (Juniors)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Juniors)",
                      equivalent_sizes: {
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11J",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13J",
                      short: "13 (Juniors)",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13 (Juniors)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Juniors)",
                      equivalent_sizes: {
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15J",
                      short: "15 (Juniors)",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15 (Juniors)",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17J",
                      short: "17 (Juniors)",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17 (Juniors)",
                      display_with_size_system: "US 17",
                      display_with_system_and_set: "US 17 (Juniors)",
                      equivalent_sizes: {
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSJ",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Juniors)",
                      equivalent_sizes: {
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSJ",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Juniors)",
                      equivalent_sizes: {
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SJ",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Juniors)",
                      equivalent_sizes: {
                        au: ["SJAU"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MJ",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Juniors)",
                      equivalent_sizes: {
                        au: ["MJAU"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LJ",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Juniors)",
                      equivalent_sizes: {
                        au: ["LJAU"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLJ",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Juniors)",
                      equivalent_sizes: {
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLJ",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Juniors)",
                      equivalent_sizes: {
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "4JAU",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        eu: ["30JEU", "32JEU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6JAU",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8JAU",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        eu: ["32JEU", "34JEU"],
                        uk: ["6JUK", "8JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10JAU",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11JAU",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        eu: ["34JEU", "36JEU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12JAU",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14JAU",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        eu: ["38JEU", "40JEU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16JAU",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18JAU",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        eu: ["42JEU", "44JEU"],
                        uk: ["16JUK", "18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20JAU",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["18JUK", "20JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22JAU",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        eu: ["44JEU", "46JEU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSJAU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        eu: ["XXSJEU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSJAU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        eu: ["XSJEU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SJAU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        eu: ["SJEU"],
                        uk: ["SJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MJAU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        eu: ["MJEU"],
                        uk: ["MJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LJAU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        eu: ["LJEU"],
                        uk: ["LJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLJAU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        eu: ["XLJEU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLJAU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        eu: ["XXLJEU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30JEU",
                      short: "30 (Juniors)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Juniors)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        uk: ["4JUK", "6JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32JEU",
                      short: "32 (Juniors)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Juniors)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        uk: ["4JUK", "6JUK", "8JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34JEU",
                      short: "34 (Juniors)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Juniors)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J", "3J", "5J"],
                        au: ["6JAU", "8JAU", "10JAU", "11JAU"],
                        uk: ["6JUK", "8JUK", "10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36JEU",
                      short: "36 (Juniors)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Juniors)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        uk: ["10JUK", "11JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38JEU",
                      short: "38 (Juniors)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Juniors)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40JEU",
                      short: "40 (Juniors)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Juniors)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        uk: ["12JUK", "14JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42JEU",
                      short: "42 (Juniors)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Juniors)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        uk: ["16JUK", "18JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44JEU",
                      short: "44 (Juniors)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Juniors)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J", "15J", "17J"],
                        au: ["16JAU", "18JAU", "20JAU", "22JAU"],
                        uk: ["16JUK", "18JUK", "20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46JEU",
                      short: "46 (Juniors)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Juniors)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        uk: ["20JUK", "22JUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSJEU",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        uk: ["XXSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSJEU",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        uk: ["XSJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SJEU",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        uk: ["SJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MJEU",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        uk: ["MJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LJEU",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        uk: ["LJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLJEU",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        uk: ["XLJUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLJEU",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        uk: ["XXLJUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4JUK",
                      short: "4 (Juniors)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Juniors)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J"],
                        au: ["4JAU", "6JAU"],
                        eu: ["30JEU", "32JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6JUK",
                      short: "6 (Juniors)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Juniors)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Juniors)",
                      equivalent_sizes: {
                        us: ["00J", "0J", "1J"],
                        au: ["4JAU", "6JAU", "8JAU"],
                        eu: ["30JEU", "32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8JUK",
                      short: "8 (Juniors)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Juniors)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Juniors)",
                      equivalent_sizes: {
                        us: ["0J", "1J"],
                        au: ["6JAU", "8JAU"],
                        eu: ["32JEU", "34JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10JUK",
                      short: "10 (Juniors)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Juniors)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11JUK",
                      short: "11 (Juniors)",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11 (Juniors)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Juniors)",
                      equivalent_sizes: {
                        us: ["3J", "5J"],
                        au: ["10JAU", "11JAU"],
                        eu: ["34JEU", "36JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12JUK",
                      short: "12 (Juniors)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Juniors)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14JUK",
                      short: "14 (Juniors)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Juniors)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Juniors)",
                      equivalent_sizes: {
                        us: ["7J", "9J"],
                        au: ["12JAU", "14JAU"],
                        eu: ["38JEU", "40JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16JUK",
                      short: "16 (Juniors)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Juniors)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18JUK",
                      short: "18 (Juniors)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Juniors)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Juniors)",
                      equivalent_sizes: {
                        us: ["11J", "13J"],
                        au: ["16JAU", "18JAU", "20JAU"],
                        eu: ["42JEU", "44JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20JUK",
                      short: "20 (Juniors)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Juniors)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["18JAU", "20JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22JUK",
                      short: "22 (Juniors)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Juniors)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Juniors)",
                      equivalent_sizes: {
                        us: ["15J", "17J"],
                        au: ["20JAU", "22JAU"],
                        eu: ["44JEU", "46JEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSJUK",
                      short: "XXS (Juniors)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Juniors)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Juniors)",
                      equivalent_sizes: {
                        us: ["XXSJ"],
                        au: ["XXSJAU"],
                        eu: ["XXSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSJUK",
                      short: "XS (Juniors)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Juniors)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Juniors)",
                      equivalent_sizes: {
                        us: ["XSJ"],
                        au: ["XSJAU"],
                        eu: ["XSJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SJUK",
                      short: "S (Juniors)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Juniors)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Juniors)",
                      equivalent_sizes: {
                        us: ["SJ"],
                        au: ["SJAU"],
                        eu: ["SJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MJUK",
                      short: "M (Juniors)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Juniors)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Juniors)",
                      equivalent_sizes: {
                        us: ["MJ"],
                        au: ["MJAU"],
                        eu: ["MJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LJUK",
                      short: "L (Juniors)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Juniors)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Juniors)",
                      equivalent_sizes: {
                        us: ["LJ"],
                        au: ["LJAU"],
                        eu: ["LJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLJUK",
                      short: "XL (Juniors)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Juniors)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Juniors)",
                      equivalent_sizes: {
                        us: ["XLJ"],
                        au: ["XLJAU"],
                        eu: ["XLJEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLJUK",
                      short: "XXL (Juniors)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Juniors)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Juniors)",
                      equivalent_sizes: {
                        us: ["XXLJ"],
                        au: ["XXLJAU"],
                        eu: ["XXLJEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "f2b476dd402403bf28a2606b",
              name: "Maternity",
              tags: ["maternity"],
              category_id: "acb476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Bust ", "Waist ", "Hips "],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-33", "24-26", "34-35"],
                  ["32-34", "26-28", "35-36"],
                  ["35-38", "28-30", "37-39"],
                  ["36-38", "28-31", "38-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-46", "36-38", "46-48"],
                  ["46-48", "38-41", "48-51"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-57", "47-50", "57-60"],
                  ["57-59", "50-52", "60-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                  ["64-67", "57-60", "67-70"],
                  ["67-70", "60-64", "70-74"],
                  ["29-31", "22-24", "31-33"],
                  ["29-32", "22-25", "31-34"],
                  ["31-34", "24-28", "34-36"],
                  ["35-38", "28-31", "37-40"],
                  ["39-41", "31-33", "41-43"],
                  ["40-42", "32-34", "42-44"],
                  ["44-48", "36-41", "46-51"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["48-51", "41-44", "51-54"],
                  ["51-54", "44-47", "54-57"],
                  ["54-59", "47-52", "57-62"],
                  ["59-62", "52-55", "62-65"],
                  ["62-64", "55-57", "65-67"],
                ],
                centimeters: [
                  ["Bust ", "Waist ", "Hips "],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-83.8", "63.5-66.0", "86.4-88.9"],
                  ["81.3-86.4", "66.0-71.1", "88.9-91.4"],
                  ["88.9-96.5", "71.1-76.2", "94.0-99.1"],
                  ["91.4-96.5", "71.1-78.7", "96.5-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-116.8", "91.4-96.5", "116.8-121.9"],
                  ["116.8-121.9", "96.5-104.1", "121.9-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-144.8", "119.4-127.0", "144.8-152.4"],
                  ["144.8-149.9", "127.0-132.1", "152.4-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                  ["162.6-170.2", "144.8-152.4", "170.2-177.8"],
                  ["170.2-177.8", "152.4-162.6", "177.8-188.0"],
                  ["73.7-78.7", "55.9-61.0", "78.7-83.8"],
                  ["73.7-81.3", "55.9-63.5", "78.7-86.4"],
                  ["78.7-86.4", "63.5-71.1", "86.4-91.4"],
                  ["88.9-96.5", "71.1-78.7", "94.0-101.6"],
                  ["99.1-104.1", "78.7-83.8", "104.1-109.2"],
                  ["101.6-106.7", "81.3-86.4", "106.7-111.8"],
                  ["111.8-121.9", "91.4-104.1", "116.8-129.5"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["121.9-129.5", "104.1-111.8", "129.5-137.2"],
                  ["129.5-137.2", "111.8-119.4", "137.2-144.8"],
                  ["137.2-149.9", "119.4-132.1", "144.8-157.5"],
                  ["149.9-157.5", "132.1-139.7", "157.5-165.1"],
                  ["157.5-162.6", "139.7-144.8", "165.1-170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["00", "2,4", "30,32", "2"],
                  ["00,0", "2,4,6", "30,32,34", "4"],
                  ["0,2,4", "4,6,8", "32,34,36", "6"],
                  ["2,4", "6,8", "34,36", "8"],
                  ["6,8", "10,12", "38,40", "10"],
                  ["6,8", "10,12", "38,40", "12"],
                  ["10,12", "14,16", "42,44", "14"],
                  ["10,12", "14,16", "42,44", "16"],
                  ["14,14W,16W", "18,20", "46,48", "18"],
                  ["14,14W,16,16W,18,18W", "18,20,22", "46,48,50", "20"],
                  ["16,16W,18,18W", "20,22", "48,50", "22"],
                  ["20,20W,22W", "24,26", "50,52", "24"],
                  ["20,20W,22,22W,24W", "24,26,28", "50,52,54", "26"],
                  ["22,22W,24,24W,26W", "26,28,30", "52,54,56", "28"],
                  ["24,24W,26,26W,28W", "28,30,32", "54,56,58", "30"],
                  ["26,26W,28,28W,30W", "30,32,34", "56,58,60", "32"],
                  ["28,28W,30,30W,32W", "32,34,36", "58,60,62", "34"],
                  ["30,30W,32,32W", "34,36,38", "60,62", "36"],
                  ["XXS", "XXS", "XXS", "XXS"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["XXXL", "XXXL", "XXXL", "XXXL"],
                  ["0X", "0X", "0X", "0X"],
                  ["1X", "1X", "1X", "1X"],
                  ["2X", "2X", "2X", "2X"],
                  ["3X", "3X", "3X", "3X"],
                  ["4X", "4X", "4X", "4X"],
                  ["5X", "5X", "5X", "5X"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["00", "2,4", "30,32", "2,4"],
                ["0", "4,6", "32,34", "4,6"],
                ["2", "6,8", "34,36", "6,8"],
                ["4", "6,8", "34,36", "6,8"],
                ["6", "10,12", "38,40", "10,12"],
                ["8", "10,12", "38,40", "10,12"],
                ["10", "14,16", "42,44", "14,16"],
                ["12", "14,16", "42,44", "14,16"],
                ["XXS", "XXS", "XXS", "XXS"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["14", "18,20", "46,48", "18,20"],
                ["14W", "18,20", "46,48", "18,20"],
                ["16", "20,22", "48,50", "20,22"],
                ["16W", "18,20,22", "46,48,50", "18,20,22"],
                ["18", "20,22", "48,50", "20,22"],
                ["18W", "20,22", "48,50", "20,22"],
                ["20", "24,26", "50,52", "24,26"],
                ["20W", "24,26", "50,52", "24,26"],
                ["22", "26,28", "52,54", "26,28"],
                ["22W", "24,26,28", "50,52,54", "24,26,28"],
                ["24", "28,30", "54,56", "28,30"],
                ["24W", "26,28,30", "52,54,56", "26,28,30"],
                ["26", "30,32", "56,58", "30,32"],
                ["26W", "28,30,32", "54,56,58", "28,30,32"],
                ["28", "32,34", "58,60", "32,34"],
                ["28W", "30,32,34", "56,58,60", "30,32,34"],
                ["30", "34,36", "60,62", "34,36"],
                ["30W", "32,34,36", "58,60,62", "32,34,36"],
                ["32", "36,38", "62", "36,38"],
                ["32W", "34,36,38", "60,62", "34,36,38"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["XXXL", "XXXL", "XXXL", "XXXL"],
                ["0X", "0X", "0X", "0X"],
                ["1X", "1X", "1X", "1X"],
                ["2X", "2X", "2X", "2X"],
                ["3X", "3X", "3X", "3X"],
                ["4X", "4X", "4X", "4X"],
                ["5X", "5X", "5X", "5X"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "00M",
                      short: "00 (Maternity)",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00 (Maternity)",
                      display_with_size_system: "US 00",
                      display_with_system_and_set: "US 00 (Maternity)",
                      equivalent_sizes: {
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0M",
                      short: "0 (Maternity)",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0 (Maternity)",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0 (Maternity)",
                      equivalent_sizes: {
                        au: ["4MAU", "6MAU"],
                        eu: ["32MEU", "34MEU"],
                        uk: ["4MUK", "6MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2M",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4M",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Maternity)",
                      equivalent_sizes: {
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6M",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8M",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Maternity)",
                      equivalent_sizes: {
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10M",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12M",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Maternity)",
                      equivalent_sizes: {
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXSM",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "US XXS",
                      display_with_system_and_set: "US XXS (Maternity)",
                      equivalent_sizes: {
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSM",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Maternity)",
                      equivalent_sizes: {
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SM",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Maternity)",
                      equivalent_sizes: {
                        au: ["SMAU"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MM",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Maternity)",
                      equivalent_sizes: {
                        au: ["MMAU"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LM",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Maternity)",
                      equivalent_sizes: {
                        au: ["LMAU"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLM",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Maternity)",
                      equivalent_sizes: {
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14M",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14WM",
                      short: "14W (Maternity)",
                      long: "14W",
                      display: "14W",
                      display_with_size_set: "14W (Maternity)",
                      display_with_size_system: "US 14W",
                      display_with_system_and_set: "US 14W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16M",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16WM",
                      short: "16W (Maternity)",
                      long: "16W",
                      display: "16W",
                      display_with_size_set: "16W (Maternity)",
                      display_with_size_system: "US 16W",
                      display_with_system_and_set: "US 16W (Maternity)",
                      equivalent_sizes: {
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18M",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18WM",
                      short: "18W (Maternity)",
                      long: "18W",
                      display: "18W",
                      display_with_size_set: "18W (Maternity)",
                      display_with_size_system: "US 18W",
                      display_with_system_and_set: "US 18W (Maternity)",
                      equivalent_sizes: {
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20M",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20WM",
                      short: "20W (Maternity)",
                      long: "20W",
                      display: "20W",
                      display_with_size_set: "20W (Maternity)",
                      display_with_size_system: "US 20W",
                      display_with_system_and_set: "US 20W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22M",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "US 22",
                      display_with_system_and_set: "US 22 (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU"],
                        eu: ["52MEU", "54MEU"],
                        uk: ["26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22WM",
                      short: "22W (Maternity)",
                      long: "22W",
                      display: "22W",
                      display_with_size_set: "22W (Maternity)",
                      display_with_size_system: "US 22W",
                      display_with_system_and_set: "US 22W (Maternity)",
                      equivalent_sizes: {
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24M",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24 (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU"],
                        eu: ["54MEU", "56MEU"],
                        uk: ["28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24WM",
                      short: "24W (Maternity)",
                      long: "24W",
                      display: "24W",
                      display_with_size_set: "24W (Maternity)",
                      display_with_size_system: "US 24W",
                      display_with_system_and_set: "US 24W (Maternity)",
                      equivalent_sizes: {
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26M",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "US 26",
                      display_with_system_and_set: "US 26 (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU"],
                        eu: ["56MEU", "58MEU"],
                        uk: ["30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "26WM",
                      short: "26W (Maternity)",
                      long: "26W",
                      display: "26W",
                      display_with_size_set: "26W (Maternity)",
                      display_with_size_system: "US 26W",
                      display_with_system_and_set: "US 26W (Maternity)",
                      equivalent_sizes: {
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28M",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "US 28",
                      display_with_system_and_set: "US 28 (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU"],
                        eu: ["58MEU", "60MEU"],
                        uk: ["32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28WM",
                      short: "28W (Maternity)",
                      long: "28W",
                      display: "28W",
                      display_with_size_set: "28W (Maternity)",
                      display_with_size_system: "US 28W",
                      display_with_system_and_set: "US 28W (Maternity)",
                      equivalent_sizes: {
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30M",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "US 30",
                      display_with_system_and_set: "US 30 (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30WM",
                      short: "30W (Maternity)",
                      long: "30W",
                      display: "30W",
                      display_with_size_set: "30W (Maternity)",
                      display_with_size_system: "US 30W",
                      display_with_system_and_set: "US 30W (Maternity)",
                      equivalent_sizes: {
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32M",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "US 32",
                      display_with_system_and_set: "US 32 (Maternity)",
                      equivalent_sizes: {
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32WM",
                      short: "32W (Maternity)",
                      long: "32W",
                      display: "32W",
                      display_with_size_set: "32W (Maternity)",
                      display_with_size_system: "US 32W",
                      display_with_system_and_set: "US 32W (Maternity)",
                      equivalent_sizes: {
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLM",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXXLM",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "US XXXL",
                      display_with_system_and_set: "US XXXL (Maternity)",
                      equivalent_sizes: {
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0XM",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "US 0X",
                      display_with_system_and_set: "US 0X (Maternity)",
                      equivalent_sizes: {
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1XM",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "US 1X",
                      display_with_system_and_set: "US 1X (Maternity)",
                      equivalent_sizes: {
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XM",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "US 2X",
                      display_with_system_and_set: "US 2X (Maternity)",
                      equivalent_sizes: {
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XM",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "US 3X",
                      display_with_system_and_set: "US 3X (Maternity)",
                      equivalent_sizes: {
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XM",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "US 4X",
                      display_with_system_and_set: "US 4X (Maternity)",
                      equivalent_sizes: {
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XM",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "US 5X",
                      display_with_system_and_set: "US 5X (Maternity)",
                      equivalent_sizes: {
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "2MAU",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        eu: ["30MEU", "32MEU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4MAU",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6MAU",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8MAU",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        eu: ["34MEU", "36MEU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10MAU",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12MAU",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        eu: ["38MEU", "40MEU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14MAU",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16MAU",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        eu: ["42MEU", "44MEU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18MAU",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        eu: ["46MEU", "48MEU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20MAU",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "22MAU",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "AU 22",
                      display_with_system_and_set: "AU 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        eu: ["48MEU", "50MEU"],
                        uk: ["20MUK", "22MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "24MAU",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "AU 24",
                      display_with_system_and_set: "AU 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        eu: ["50MEU", "52MEU"],
                        uk: ["24MUK", "26MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "26MAU",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "AU 26",
                      display_with_system_and_set: "AU 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28MAU",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30MAU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32MAU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34MAU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36MAU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        eu: ["60MEU", "62MEU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38MAU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        eu: ["62MEU"],
                        uk: ["36MUK", "38MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXSMAU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "AU XXS",
                      display_with_system_and_set: "AU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        eu: ["XXSMEU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSMAU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        eu: ["XSMEU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SMAU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        eu: ["SMEU"],
                        uk: ["SMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MMAU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        eu: ["MMEU"],
                        uk: ["MMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LMAU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        eu: ["LMEU"],
                        uk: ["LMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLMAU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        eu: ["XLMEU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLMAU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        eu: ["XXLMEU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXXLMAU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "AU XXXL",
                      display_with_system_and_set: "AU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        eu: ["XXXLMEU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0XMAU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "AU 0X",
                      display_with_system_and_set: "AU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        eu: ["0XMEU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1XMAU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "AU 1X",
                      display_with_system_and_set: "AU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        eu: ["1XMEU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XMAU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "AU 2X",
                      display_with_system_and_set: "AU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        eu: ["2XMEU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XMAU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "AU 3X",
                      display_with_system_and_set: "AU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        eu: ["3XMEU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XMAU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "AU 4X",
                      display_with_system_and_set: "AU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        eu: ["4XMEU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XMAU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "AU 5X",
                      display_with_system_and_set: "AU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        eu: ["5XMEU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "30MEU",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        uk: ["2MUK", "4MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32MEU",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        uk: ["2MUK", "4MUK", "6MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34MEU",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        uk: ["4MUK", "6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36MEU",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        uk: ["6MUK", "8MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38MEU",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40MEU",
                      short: "40 (Maternity)",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40 (Maternity)",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        uk: ["10MUK", "12MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42MEU",
                      short: "42 (Maternity)",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42 (Maternity)",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44MEU",
                      short: "44 (Maternity)",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44 (Maternity)",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        uk: ["14MUK", "16MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46MEU",
                      short: "46 (Maternity)",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46 (Maternity)",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        uk: ["18MUK", "20MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48MEU",
                      short: "48 (Maternity)",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48 (Maternity)",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        uk: ["18MUK", "20MUK", "22MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50MEU",
                      short: "50 (Maternity)",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50 (Maternity)",
                      display_with_size_system: "EU 50",
                      display_with_system_and_set: "EU 50 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM", "20M", "20WM", "22WM"],
                        au: ["20MAU", "22MAU", "24MAU", "26MAU"],
                        uk: ["20MUK", "22MUK", "24MUK", "26MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52MEU",
                      short: "52 (Maternity)",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52 (Maternity)",
                      display_with_size_system: "EU 52",
                      display_with_system_and_set: "EU 52 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        uk: ["24MUK", "26MUK", "28MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54MEU",
                      short: "54 (Maternity)",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54 (Maternity)",
                      display_with_size_system: "EU 54",
                      display_with_system_and_set: "EU 54 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        uk: ["26MUK", "28MUK", "30MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56MEU",
                      short: "56 (Maternity)",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56 (Maternity)",
                      display_with_size_system: "EU 56",
                      display_with_system_and_set: "EU 56 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        uk: ["28MUK", "30MUK", "32MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58MEU",
                      short: "58 (Maternity)",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58 (Maternity)",
                      display_with_size_system: "EU 58",
                      display_with_system_and_set: "EU 58 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        uk: ["30MUK", "32MUK", "34MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60MEU",
                      short: "60 (Maternity)",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60 (Maternity)",
                      display_with_size_system: "EU 60",
                      display_with_system_and_set: "EU 60 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        uk: ["32MUK", "34MUK", "36MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62MEU",
                      short: "62 (Maternity)",
                      long: "62",
                      display: "62",
                      display_with_size_set: "62 (Maternity)",
                      display_with_size_system: "EU 62",
                      display_with_system_and_set: "EU 62 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        uk: ["34MUK", "36MUK", "38MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXSMEU",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "EU XXS",
                      display_with_system_and_set: "EU XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        uk: ["XXSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSMEU",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        uk: ["XSMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SMEU",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        uk: ["SMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MMEU",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        uk: ["MMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LMEU",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        uk: ["LMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLMEU",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        uk: ["XLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLMEU",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        uk: ["XXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXXLMEU",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "EU XXXL",
                      display_with_system_and_set: "EU XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        uk: ["XXXLMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0XMEU",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "EU 0X",
                      display_with_system_and_set: "EU 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        uk: ["0XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "1XMEU",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "EU 1X",
                      display_with_system_and_set: "EU 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        uk: ["1XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XMEU",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "EU 2X",
                      display_with_system_and_set: "EU 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        uk: ["2XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XMEU",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "EU 3X",
                      display_with_system_and_set: "EU 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        uk: ["3XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XMEU",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "EU 4X",
                      display_with_system_and_set: "EU 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        uk: ["4XMUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XMEU",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "EU 5X",
                      display_with_system_and_set: "EU 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        uk: ["5XMUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2MUK",
                      short: "2 (Maternity)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Maternity)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M"],
                        au: ["2MAU", "4MAU"],
                        eu: ["30MEU", "32MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4MUK",
                      short: "4 (Maternity)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Maternity)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Maternity)",
                      equivalent_sizes: {
                        us: ["00M", "0M"],
                        au: ["2MAU", "4MAU", "6MAU"],
                        eu: ["30MEU", "32MEU", "34MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MUK",
                      short: "6 (Maternity)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Maternity)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Maternity)",
                      equivalent_sizes: {
                        us: ["0M", "2M", "4M"],
                        au: ["4MAU", "6MAU", "8MAU"],
                        eu: ["32MEU", "34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8MUK",
                      short: "8 (Maternity)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Maternity)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Maternity)",
                      equivalent_sizes: {
                        us: ["2M", "4M"],
                        au: ["6MAU", "8MAU"],
                        eu: ["34MEU", "36MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10MUK",
                      short: "10 (Maternity)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Maternity)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MUK",
                      short: "12 (Maternity)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Maternity)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Maternity)",
                      equivalent_sizes: {
                        us: ["6M", "8M"],
                        au: ["10MAU", "12MAU"],
                        eu: ["38MEU", "40MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14MUK",
                      short: "14 (Maternity)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Maternity)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16MUK",
                      short: "16 (Maternity)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Maternity)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Maternity)",
                      equivalent_sizes: {
                        us: ["10M", "12M"],
                        au: ["14MAU", "16MAU"],
                        eu: ["42MEU", "44MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MUK",
                      short: "18 (Maternity)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Maternity)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16WM"],
                        au: ["18MAU", "20MAU"],
                        eu: ["46MEU", "48MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20MUK",
                      short: "20 (Maternity)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Maternity)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Maternity)",
                      equivalent_sizes: {
                        us: ["14M", "14WM", "16M", "16WM", "18M", "18WM"],
                        au: ["18MAU", "20MAU", "22MAU"],
                        eu: ["46MEU", "48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22MUK",
                      short: "22 (Maternity)",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22 (Maternity)",
                      display_with_size_system: "UK 22",
                      display_with_system_and_set: "UK 22 (Maternity)",
                      equivalent_sizes: {
                        us: ["16M", "16WM", "18M", "18WM"],
                        au: ["20MAU", "22MAU"],
                        eu: ["48MEU", "50MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MUK",
                      short: "24 (Maternity)",
                      long: "24",
                      display: "24",
                      display_with_size_set: "24 (Maternity)",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22WM"],
                        au: ["24MAU", "26MAU"],
                        eu: ["50MEU", "52MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "26MUK",
                      short: "26 (Maternity)",
                      long: "26",
                      display: "26",
                      display_with_size_set: "26 (Maternity)",
                      display_with_size_system: "UK 26",
                      display_with_system_and_set: "UK 26 (Maternity)",
                      equivalent_sizes: {
                        us: ["20M", "20WM", "22M", "22WM", "24WM"],
                        au: ["24MAU", "26MAU", "28MAU"],
                        eu: ["50MEU", "52MEU", "54MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28MUK",
                      short: "28 (Maternity)",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28 (Maternity)",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28 (Maternity)",
                      equivalent_sizes: {
                        us: ["22M", "22WM", "24M", "24WM", "26WM"],
                        au: ["26MAU", "28MAU", "30MAU"],
                        eu: ["52MEU", "54MEU", "56MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30MUK",
                      short: "30 (Maternity)",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30 (Maternity)",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30 (Maternity)",
                      equivalent_sizes: {
                        us: ["24M", "24WM", "26M", "26WM", "28WM"],
                        au: ["28MAU", "30MAU", "32MAU"],
                        eu: ["54MEU", "56MEU", "58MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32MUK",
                      short: "32 (Maternity)",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32 (Maternity)",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32 (Maternity)",
                      equivalent_sizes: {
                        us: ["26M", "26WM", "28M", "28WM", "30WM"],
                        au: ["30MAU", "32MAU", "34MAU"],
                        eu: ["56MEU", "58MEU", "60MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34MUK",
                      short: "34 (Maternity)",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34 (Maternity)",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34 (Maternity)",
                      equivalent_sizes: {
                        us: ["28M", "28WM", "30M", "30WM", "32WM"],
                        au: ["32MAU", "34MAU", "36MAU"],
                        eu: ["58MEU", "60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36MUK",
                      short: "36 (Maternity)",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36 (Maternity)",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36 (Maternity)",
                      equivalent_sizes: {
                        us: ["30M", "30WM", "32M", "32WM"],
                        au: ["34MAU", "36MAU", "38MAU"],
                        eu: ["60MEU", "62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38MUK",
                      short: "38 (Maternity)",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38 (Maternity)",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38 (Maternity)",
                      equivalent_sizes: {
                        us: ["32M", "32WM"],
                        au: ["36MAU", "38MAU"],
                        eu: ["62MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXSMUK",
                      short: "XXS (Maternity)",
                      long: "XXS",
                      display: "XXS",
                      display_with_size_set: "XXS (Maternity)",
                      display_with_size_system: "UK XXS",
                      display_with_system_and_set: "UK XXS (Maternity)",
                      equivalent_sizes: {
                        us: ["XXSM"],
                        au: ["XXSMAU"],
                        eu: ["XXSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSMUK",
                      short: "XS (Maternity)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Maternity)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Maternity)",
                      equivalent_sizes: {
                        us: ["XSM"],
                        au: ["XSMAU"],
                        eu: ["XSMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SMUK",
                      short: "S (Maternity)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Maternity)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Maternity)",
                      equivalent_sizes: {
                        us: ["SM"],
                        au: ["SMAU"],
                        eu: ["SMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MMUK",
                      short: "M (Maternity)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Maternity)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Maternity)",
                      equivalent_sizes: {
                        us: ["MM"],
                        au: ["MMAU"],
                        eu: ["MMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LMUK",
                      short: "L (Maternity)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Maternity)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Maternity)",
                      equivalent_sizes: {
                        us: ["LM"],
                        au: ["LMAU"],
                        eu: ["LMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLMUK",
                      short: "XL (Maternity)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Maternity)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Maternity)",
                      equivalent_sizes: {
                        us: ["XLM"],
                        au: ["XLMAU"],
                        eu: ["XLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLMUK",
                      short: "XXL (Maternity)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Maternity)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXLM"],
                        au: ["XXLMAU"],
                        eu: ["XXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXXLMUK",
                      short: "XXXL (Maternity)",
                      long: "XXXL",
                      display: "XXXL",
                      display_with_size_set: "XXXL (Maternity)",
                      display_with_size_system: "UK XXXL",
                      display_with_system_and_set: "UK XXXL (Maternity)",
                      equivalent_sizes: {
                        us: ["XXXLM"],
                        au: ["XXXLMAU"],
                        eu: ["XXXLMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0XMUK",
                      short: "0X (Maternity)",
                      long: "0X",
                      display: "0X",
                      display_with_size_set: "0X (Maternity)",
                      display_with_size_system: "UK 0X",
                      display_with_system_and_set: "UK 0X (Maternity)",
                      equivalent_sizes: {
                        us: ["0XM"],
                        au: ["0XMAU"],
                        eu: ["0XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1XMUK",
                      short: "1X (Maternity)",
                      long: "1X",
                      display: "1X",
                      display_with_size_set: "1X (Maternity)",
                      display_with_size_system: "UK 1X",
                      display_with_system_and_set: "UK 1X (Maternity)",
                      equivalent_sizes: {
                        us: ["1XM"],
                        au: ["1XMAU"],
                        eu: ["1XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XMUK",
                      short: "2X (Maternity)",
                      long: "2X",
                      display: "2X",
                      display_with_size_set: "2X (Maternity)",
                      display_with_size_system: "UK 2X",
                      display_with_system_and_set: "UK 2X (Maternity)",
                      equivalent_sizes: {
                        us: ["2XM"],
                        au: ["2XMAU"],
                        eu: ["2XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XMUK",
                      short: "3X (Maternity)",
                      long: "3X",
                      display: "3X",
                      display_with_size_set: "3X (Maternity)",
                      display_with_size_system: "UK 3X",
                      display_with_system_and_set: "UK 3X (Maternity)",
                      equivalent_sizes: {
                        us: ["3XM"],
                        au: ["3XMAU"],
                        eu: ["3XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XMUK",
                      short: "4X (Maternity)",
                      long: "4X",
                      display: "4X",
                      display_with_size_set: "4X (Maternity)",
                      display_with_size_system: "UK 4X",
                      display_with_system_and_set: "UK 4X (Maternity)",
                      equivalent_sizes: {
                        us: ["4XM"],
                        au: ["4XMAU"],
                        eu: ["4XMEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XMUK",
                      short: "5X (Maternity)",
                      long: "5X",
                      display: "5X",
                      display_with_size_set: "5X (Maternity)",
                      display_with_size_system: "UK 5X",
                      display_with_system_and_set: "UK 5X (Maternity)",
                      equivalent_sizes: {
                        us: ["5XM"],
                        au: ["5XMAU"],
                        eu: ["5XMEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "women_treggings",
          aliases: [],
          slug: "Treggings",
          alias_slugs: [],
          parent_id: "000e8975d97b4e80ef00a955",
        },
      ],
    },
    {
      id: "01008c10d97b4e1245005764",
      display: "Men",
      type: "d",
      message_id: "men",
      aliases: [],
      size_sets: [],
      sizes: [],
      slug: "Men",
      alias_slugs: [],
      parent_id: "55b966335632a0146f000001",
      categories: [
        {
          id: "03008c10d97b4e1245005764",
          display: "Bags",
          size_sets: [
            {
              id: "02003667d97b4e6bdb005784",
              name: "Standard",
              tags: ["standard"],
              category_id: "03008c10d97b4e1245005764",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "men_bags",
          aliases: [],
          slug: "Bags",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
          category_features: [
            {
              id: "0f009813d97b4e3995005764",
              display: "Backpacks",
              type: "cf",
              message_id: "men_bags_backpacks",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Backpacks",
              alias_slugs: [],
              parent_id: "03008c10d97b4e1245005764",
            },
            {
              id: "a97df9aaaabb083120f45ec2",
              display: "Belt Bags",
              type: "cf",
              message_id: "men_bags_belt_bags",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Belt_Bags",
              alias_slugs: [],
              parent_id: "03008c10d97b4e1245005764",
            },
            {
              id: "10009813d97b4e3995005764",
              display: "Briefcases",
              type: "cf",
              message_id: "men_bags_briefcases",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Briefcases",
              alias_slugs: [],
              parent_id: "03008c10d97b4e1245005764",
            },
            {
              id: "11009813d97b4e3995005764",
              display: "Duffel Bags",
              type: "cf",
              message_id: "men_bags_duffel_bags",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Duffel_Bags",
              alias_slugs: [],
              parent_id: "03008c10d97b4e1245005764",
            },
            {
              id: "12009813d97b4e3995005764",
              display: "Laptop Bags",
              type: "cf",
              message_id: "men_bags_laptop_bags",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Laptop_Bags",
              alias_slugs: [],
              parent_id: "03008c10d97b4e1245005764",
            },
            {
              id: "13009813d97b4e3995005764",
              display: "Luggage & Travel Bags",
              type: "cf",
              message_id: "men_bags_luggage_travel_bags",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Luggage_&_Travel_Bags",
              alias_slugs: [],
              parent_id: "03008c10d97b4e1245005764",
            },
            {
              id: "14009813d97b4e3995005764",
              display: "Messenger Bags",
              type: "cf",
              message_id: "men_bags_messenger_bags",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Messenger_Bags",
              alias_slugs: [],
              parent_id: "03008c10d97b4e1245005764",
            },
            {
              id: "ab7df9aaaabb083120f45ec2",
              display: "Toiletry Bags",
              type: "cf",
              message_id: "men_bags_toiletry_bags",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Toiletry_Bags",
              alias_slugs: [],
              parent_id: "03008c10d97b4e1245005764",
            },
            {
              id: "16009813d97b4e3995005764",
              display: "Wallets",
              type: "cf",
              message_id: "men_bags_wallets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Wallets",
              alias_slugs: [],
              parent_id: "03008c10d97b4e1245005764",
            },
          ],
        },
        {
          id: "08008c10d97b4e1245005764",
          display: "Shoes",
          size_sets: [
            {
              id: "07003667d97b4e6bdb005784",
              name: "Standard",
              tags: ["standard"],
              category_id: "08008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["in."],
                  ["8.7"],
                  ["8.8"],
                  ["9"],
                  ["9.1"],
                  ["9.3"],
                  ["9.5"],
                  ["9.6"],
                  ["9.8"],
                  ["9.9"],
                  ["10.1"],
                  ["10.3"],
                  ["10.4"],
                  ["10.6"],
                  ["10.8"],
                  ["10.9"],
                  ["11.1"],
                  ["11.3"],
                  ["11.4"],
                  ["11.6"],
                  ["11.7"],
                  ["11.9"],
                  ["12.1"],
                  ["12.2"],
                  ["12.4"],
                  ["12.5"],
                ],
                centimeters: [
                  ["cm."],
                  ["22.1"],
                  ["22.4"],
                  ["22.9"],
                  ["23.1"],
                  ["23.6"],
                  ["24.1"],
                  ["24.4"],
                  ["24.9"],
                  ["25.1"],
                  ["25.7"],
                  ["26.2"],
                  ["26.4"],
                  ["26.9"],
                  ["27.4"],
                  ["27.7"],
                  ["28.2"],
                  ["28.7"],
                  ["29.0"],
                  ["29.5"],
                  ["29.7"],
                  ["30.2"],
                  ["30.7"],
                  ["31.0"],
                  ["31.5"],
                  ["31.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["4", "3,3.5", "37,37.5", "4"],
                  ["4,4.5,5", "3,3.5,4", "37,37.5,38", "4.5"],
                  ["4.5,5", "3.5,4", "37.5,38", "5"],
                  ["5.5,6", "4.5,5", "38.5,39", "5.5"],
                  ["5.5,6", "4.5,5", "38.5,39", "6"],
                  ["6.5,7", "5.5,6", "39.5,40", "6.5"],
                  ["6.5,7", "5.5,6", "39.5,40", "7"],
                  ["7.5,8", "6.5,7", "40.5,41", "7.5"],
                  ["7.5,8", "6.5,7", "40.5,41", "8"],
                  ["8.5,9", "7.5,8", "41.5,42", "8.5"],
                  ["8.5,9", "7.5,8", "41.5,42", "9"],
                  ["9.5,10", "8.5,9", "42.5,43", "9.5"],
                  ["9.5,10", "8.5,9", "42.5,43", "10"],
                  ["10.5,11", "9.5,10", "43.5,44", "10.5"],
                  ["10.5,11", "9.5,10", "43.5,44", "11"],
                  ["11.5,12", "10.5,11", "44.5,45", "11.5"],
                  ["11.5,12", "10.5,11", "44.5,45", "12"],
                  ["12.5,13", "11.5,12", "45.5,46", "12.5"],
                  ["12.5,13", "11.5,12", "45.5,46", "13"],
                  ["13.5,14", "12.5,13", "46.5,47", "13.5"],
                  ["13.5,14", "12.5,13", "46.5,47", "14"],
                  ["14.5,15", "13.5,14", "47.5,48", "14.5"],
                  ["14.5,15", "13.5,14", "47.5,48", "15"],
                  ["15.5,16", "14.5,15", "48.5,49", "15.5"],
                  ["15.5,16", "14.5,15", "48.5,49", "16"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["4", "3,3.5", "37,37.5", "4,4.5"],
                ["4.5", "3.5,4", "37.5,38", "4.5,5"],
                ["5", "3.5,4", "37.5,38", "4.5,5"],
                ["5.5", "4.5,5", "38.5,39", "5.5,6"],
                ["6", "4.5,5", "38.5,39", "5.5,6"],
                ["6.5", "5.5,6", "39.5,40", "6.5,6"],
                ["7", "5.5,6", "39.5,40", "6.5,6"],
                ["7.5", "6.5,7", "40.5,41", "7.5,8"],
                ["8", "6.5,7", "40.5,41", "7.5,8"],
                ["8.5", "7.5,8", "41.5,42", "8.5,9"],
                ["9", "7.5,8", "41.5,42", "8.5,9"],
                ["9.5", "8.5,9", "42.5,43", "9.5,10"],
                ["10", "8.5,9", "42.5,43", "9.5,10"],
                ["10.5", "9.5,10", "43.5,44", "10.5,11"],
                ["11", "9.5,10", "43.5,44", "10.5,11"],
                ["11.5", "10.5,11", "44.5,45", "11.5,12"],
                ["12", "10.5,11", "44.5,45", "11.5,12"],
                ["12.5", "11.5,12", "45.5,46", "12.5,13"],
                ["13", "11.5,12", "45.5,46", "12.5,13"],
                ["13.5", "12.5,13", "46.5,47", "13.5,14"],
                ["14", "12.5,13", "46.5,47", "13.5,14"],
                ["14.5", "13.5,14", "47.5,48", "14.5,15"],
                ["15", "13.5,14", "47.5,48", "14.5,15"],
                ["15.5", "14.5,15", "48.5,49", "15.5,16"],
                ["16", "14.5,15", "48.5,49", "15.5,16"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "4",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["3AU", "3.5AU"],
                        eu: ["37EU", "37.5EU"],
                        uk: ["4UK", "4.5UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4.5",
                      short: "4.5",
                      long: "4.5",
                      display: "4.5",
                      display_with_size_set: "4.5",
                      display_with_size_system: "US 4.5",
                      display_with_system_and_set: "US 4.5",
                      equivalent_sizes: {
                        au: ["3.5AU", "4AU"],
                        eu: ["37.5EU", "38EU"],
                        uk: ["4.5UK", "5UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5",
                      short: "5",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5",
                      equivalent_sizes: {
                        au: ["3.5AU", "4AU"],
                        eu: ["37.5EU", "38EU"],
                        uk: ["4.5UK", "5UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5.5",
                      short: "5.5",
                      long: "5.5",
                      display: "5.5",
                      display_with_size_set: "5.5",
                      display_with_size_system: "US 5.5",
                      display_with_system_and_set: "US 5.5",
                      equivalent_sizes: {
                        au: ["4.5AU", "5AU"],
                        eu: ["38.5EU", "39EU"],
                        uk: ["5.5UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["4.5AU", "5AU"],
                        eu: ["38.5EU", "39EU"],
                        uk: ["5.5UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6.5",
                      short: "6.5",
                      long: "6.5",
                      display: "6.5",
                      display_with_size_set: "6.5",
                      display_with_size_system: "US 6.5",
                      display_with_system_and_set: "US 6.5",
                      equivalent_sizes: {
                        au: ["5.5AU", "6AU"],
                        eu: ["39.5EU", "40EU"],
                        uk: ["6.5UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7",
                      short: "7",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7",
                      equivalent_sizes: {
                        au: ["5.5AU", "6AU"],
                        eu: ["39.5EU", "40EU"],
                        uk: ["6.5UK", "6UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7.5",
                      short: "7.5",
                      long: "7.5",
                      display: "7.5",
                      display_with_size_set: "7.5",
                      display_with_size_system: "US 7.5",
                      display_with_system_and_set: "US 7.5",
                      equivalent_sizes: {
                        au: ["6.5AU", "7AU"],
                        eu: ["40.5EU", "41EU"],
                        uk: ["7.5UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8",
                      equivalent_sizes: {
                        au: ["6.5AU", "7AU"],
                        eu: ["40.5EU", "41EU"],
                        uk: ["7.5UK", "8UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8.5",
                      short: "8.5",
                      long: "8.5",
                      display: "8.5",
                      display_with_size_set: "8.5",
                      display_with_size_system: "US 8.5",
                      display_with_system_and_set: "US 8.5",
                      equivalent_sizes: {
                        au: ["7.5AU", "8AU"],
                        eu: ["41.5EU", "42EU"],
                        uk: ["8.5UK", "9UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9",
                      short: "9",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9",
                      equivalent_sizes: {
                        au: ["7.5AU", "8AU"],
                        eu: ["41.5EU", "42EU"],
                        uk: ["8.5UK", "9UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9.5",
                      short: "9.5",
                      long: "9.5",
                      display: "9.5",
                      display_with_size_set: "9.5",
                      display_with_size_system: "US 9.5",
                      display_with_system_and_set: "US 9.5",
                      equivalent_sizes: {
                        au: ["8.5AU", "9AU"],
                        eu: ["42.5EU", "43EU"],
                        uk: ["9.5UK", "10UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10",
                      equivalent_sizes: {
                        au: ["8.5AU", "9AU"],
                        eu: ["42.5EU", "43EU"],
                        uk: ["9.5UK", "10UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10.5",
                      short: "10.5",
                      long: "10.5",
                      display: "10.5",
                      display_with_size_set: "10.5",
                      display_with_size_system: "US 10.5",
                      display_with_system_and_set: "US 10.5",
                      equivalent_sizes: {
                        au: ["9.5AU", "10AU"],
                        eu: ["43.5EU", "44EU"],
                        uk: ["10.5UK", "11UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11",
                      short: "11",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11",
                      equivalent_sizes: {
                        au: ["9.5AU", "10AU"],
                        eu: ["43.5EU", "44EU"],
                        uk: ["10.5UK", "11UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11.5",
                      short: "11.5",
                      long: "11.5",
                      display: "11.5",
                      display_with_size_set: "11.5",
                      display_with_size_system: "US 11.5",
                      display_with_system_and_set: "US 11.5",
                      equivalent_sizes: {
                        au: ["10.5AU", "11AU"],
                        eu: ["44.5EU", "45EU"],
                        uk: ["11.5UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12",
                      equivalent_sizes: {
                        au: ["10.5AU", "11AU"],
                        eu: ["44.5EU", "45EU"],
                        uk: ["11.5UK", "12UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12.5",
                      short: "12.5",
                      long: "12.5",
                      display: "12.5",
                      display_with_size_set: "12.5",
                      display_with_size_system: "US 12.5",
                      display_with_system_and_set: "US 12.5",
                      equivalent_sizes: {
                        au: ["11.5AU", "12AU"],
                        eu: ["45.5EU", "46EU"],
                        uk: ["12.5UK", "13UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13",
                      short: "13",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13",
                      equivalent_sizes: {
                        au: ["11.5AU", "12AU"],
                        eu: ["45.5EU", "46EU"],
                        uk: ["12.5UK", "13UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13.5",
                      short: "13.5",
                      long: "13.5",
                      display: "13.5",
                      display_with_size_set: "13.5",
                      display_with_size_system: "US 13.5",
                      display_with_system_and_set: "US 13.5",
                      equivalent_sizes: {
                        au: ["12.5AU", "13AU"],
                        eu: ["46.5EU", "47EU"],
                        uk: ["13.5UK", "14UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14",
                      equivalent_sizes: {
                        au: ["12.5AU", "13AU"],
                        eu: ["46.5EU", "47EU"],
                        uk: ["13.5UK", "14UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14.5",
                      short: "14.5",
                      long: "14.5",
                      display: "14.5",
                      display_with_size_set: "14.5",
                      display_with_size_system: "US 14.5",
                      display_with_system_and_set: "US 14.5",
                      equivalent_sizes: {
                        au: ["13.5AU", "14AU"],
                        eu: ["47.5EU", "48EU"],
                        uk: ["14.5UK", "15UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15",
                      short: "15",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15",
                      display_with_size_system: "US 15",
                      display_with_system_and_set: "US 15",
                      equivalent_sizes: {
                        au: ["13.5AU", "14AU"],
                        eu: ["47.5EU", "48EU"],
                        uk: ["14.5UK", "15UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15.5",
                      short: "15.5",
                      long: "15.5",
                      display: "15.5",
                      display_with_size_set: "15.5",
                      display_with_size_system: "US 15.5",
                      display_with_system_and_set: "US 15.5",
                      equivalent_sizes: {
                        au: ["14.5AU", "15AU"],
                        eu: ["48.5EU", "49EU"],
                        uk: ["15.5UK", "16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16",
                      equivalent_sizes: {
                        au: ["14.5AU", "15AU"],
                        eu: ["48.5EU", "49EU"],
                        uk: ["15.5UK", "16UK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "3AU",
                      short: "3",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3",
                      display_with_size_system: "AU 3",
                      display_with_system_and_set: "AU 3",
                      equivalent_sizes: {
                        us: ["4"],
                        eu: ["37EU", "37.5EU"],
                        uk: ["4UK", "4.5UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3.5AU",
                      short: "3.5",
                      long: "3.5",
                      display: "3.5",
                      display_with_size_set: "3.5",
                      display_with_size_system: "AU 3.5",
                      display_with_system_and_set: "AU 3.5",
                      equivalent_sizes: {
                        us: ["4", "4.5", "5"],
                        eu: ["37EU", "37.5EU", "38EU"],
                        uk: ["4UK", "4.5UK", "5UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4AU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["4.5", "5"],
                        eu: ["37.5EU", "38EU"],
                        uk: ["4.5UK", "5UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4.5AU",
                      short: "4.5",
                      long: "4.5",
                      display: "4.5",
                      display_with_size_set: "4.5",
                      display_with_size_system: "AU 4.5",
                      display_with_system_and_set: "AU 4.5",
                      equivalent_sizes: {
                        us: ["5.5", "6"],
                        eu: ["38.5EU", "39EU"],
                        uk: ["5.5UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5AU",
                      short: "5",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5",
                      display_with_size_system: "AU 5",
                      display_with_system_and_set: "AU 5",
                      equivalent_sizes: {
                        us: ["5.5", "6"],
                        eu: ["38.5EU", "39EU"],
                        uk: ["5.5UK", "6UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5.5AU",
                      short: "5.5",
                      long: "5.5",
                      display: "5.5",
                      display_with_size_set: "5.5",
                      display_with_size_system: "AU 5.5",
                      display_with_system_and_set: "AU 5.5",
                      equivalent_sizes: {
                        us: ["6.5", "7"],
                        eu: ["39.5EU", "40EU"],
                        uk: ["6.5UK", "7UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6AU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["6.5", "7"],
                        eu: ["39.5EU", "40EU"],
                        uk: ["6.5UK", "7UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6.5AU",
                      short: "6.5",
                      long: "6.5",
                      display: "6.5",
                      display_with_size_set: "6.5",
                      display_with_size_system: "AU 6.5",
                      display_with_system_and_set: "AU 6.5",
                      equivalent_sizes: {
                        us: ["7.5", "8"],
                        eu: ["40.5EU", "41EU"],
                        uk: ["7.5UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "7AU",
                      short: "7",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7",
                      display_with_size_system: "AU 7",
                      display_with_system_and_set: "AU 7",
                      equivalent_sizes: {
                        us: ["7.5", "8"],
                        eu: ["40.5EU", "41EU"],
                        uk: ["7.5UK", "8UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "7.5AU",
                      short: "7.5",
                      long: "7.5",
                      display: "7.5",
                      display_with_size_set: "7.5",
                      display_with_size_system: "AU 7.5",
                      display_with_system_and_set: "AU 7.5",
                      equivalent_sizes: {
                        us: ["8.5", "9"],
                        eu: ["41.5EU", "42EU"],
                        uk: ["8.5UK", "9UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8AU",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8",
                      equivalent_sizes: {
                        us: ["8.5", "9"],
                        eu: ["41.5EU", "42EU"],
                        uk: ["8.5UK", "9UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8.5AU",
                      short: "8.5",
                      long: "8.5",
                      display: "8.5",
                      display_with_size_set: "8.5",
                      display_with_size_system: "AU 8.5",
                      display_with_system_and_set: "AU 8.5",
                      equivalent_sizes: {
                        us: ["9.5", "10"],
                        eu: ["42.5EU", "43EU"],
                        uk: ["9.5UK", "10UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "9AU",
                      short: "9",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9",
                      display_with_size_system: "AU 9",
                      display_with_system_and_set: "AU 9",
                      equivalent_sizes: {
                        us: ["9.5", "10"],
                        eu: ["42.5EU", "43EU"],
                        uk: ["9.5UK", "10UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "9.5AU",
                      short: "9.5",
                      long: "9.5",
                      display: "9.5",
                      display_with_size_set: "9.5",
                      display_with_size_system: "AU 9.5",
                      display_with_system_and_set: "AU 9.5",
                      equivalent_sizes: {
                        us: ["10.5", "11"],
                        eu: ["43.5EU", "44EU"],
                        uk: ["10.5UK", "11UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10AU",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10",
                      equivalent_sizes: {
                        us: ["10.5", "11"],
                        eu: ["43.5EU", "44EU"],
                        uk: ["10.5UK", "11UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10.5AU",
                      short: "10.5",
                      long: "10.5",
                      display: "10.5",
                      display_with_size_set: "10.5",
                      display_with_size_system: "AU 10.5",
                      display_with_system_and_set: "AU 10.5",
                      equivalent_sizes: {
                        us: ["11.5", "12"],
                        eu: ["44.5EU", "45EU"],
                        uk: ["11.5UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11AU",
                      short: "11",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11",
                      equivalent_sizes: {
                        us: ["11.5", "12"],
                        eu: ["44.5EU", "45EU"],
                        uk: ["11.5UK", "12UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11.5AU",
                      short: "11.5",
                      long: "11.5",
                      display: "11.5",
                      display_with_size_set: "11.5",
                      display_with_size_system: "AU 11.5",
                      display_with_system_and_set: "AU 11.5",
                      equivalent_sizes: {
                        us: ["12.5", "13"],
                        eu: ["45.5EU", "46EU"],
                        uk: ["12.5UK", "13UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12AU",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12",
                      equivalent_sizes: {
                        us: ["12.5", "13"],
                        eu: ["45.5EU", "46EU"],
                        uk: ["12.5UK", "13UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12.5AU",
                      short: "12.5",
                      long: "12.5",
                      display: "12.5",
                      display_with_size_set: "12.5",
                      display_with_size_system: "AU 12.5",
                      display_with_system_and_set: "AU 12.5",
                      equivalent_sizes: {
                        us: ["13.5", "14"],
                        eu: ["46.5EU", "47EU"],
                        uk: ["13.5UK", "14UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "13AU",
                      short: "13",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13",
                      display_with_size_system: "AU 13",
                      display_with_system_and_set: "AU 13",
                      equivalent_sizes: {
                        us: ["13.5", "14"],
                        eu: ["46.5EU", "47EU"],
                        uk: ["13.5UK", "14UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "13.5AU",
                      short: "13.5",
                      long: "13.5",
                      display: "13.5",
                      display_with_size_set: "13.5",
                      display_with_size_system: "AU 13.5",
                      display_with_system_and_set: "AU 13.5",
                      equivalent_sizes: {
                        us: ["14.5", "15"],
                        eu: ["47.5EU", "48EU"],
                        uk: ["14.5UK", "15UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14AU",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14",
                      equivalent_sizes: {
                        us: ["14.5", "15"],
                        eu: ["47.5EU", "48EU"],
                        uk: ["14.5UK", "15UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14.5AU",
                      short: "14.5",
                      long: "14.5",
                      display: "14.5",
                      display_with_size_set: "14.5",
                      display_with_size_system: "AU 14.5",
                      display_with_system_and_set: "AU 14.5",
                      equivalent_sizes: {
                        us: ["15.5", "16"],
                        eu: ["48.5EU", "49EU"],
                        uk: ["15.5UK", "16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "15AU",
                      short: "15",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15",
                      display_with_size_system: "AU 15",
                      display_with_system_and_set: "AU 15",
                      equivalent_sizes: {
                        us: ["15.5", "16"],
                        eu: ["48.5EU", "49EU"],
                        uk: ["15.5UK", "16UK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "37EU",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "EU 37",
                      display_with_system_and_set: "EU 37",
                      equivalent_sizes: {
                        us: ["4"],
                        au: ["3AU", "3.5AU"],
                        uk: ["4UK", "4.5UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "37.5EU",
                      short: "37.5",
                      long: "37.5",
                      display: "37.5",
                      display_with_size_set: "37.5",
                      display_with_size_system: "EU 37.5",
                      display_with_system_and_set: "EU 37.5",
                      equivalent_sizes: {
                        us: ["4", "4.5", "5"],
                        au: ["3AU", "3.5AU", "4AU"],
                        uk: ["4UK", "4.5UK", "5UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38EU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38",
                      equivalent_sizes: {
                        us: ["4.5", "5"],
                        au: ["3.5AU", "4AU"],
                        uk: ["4.5UK", "5UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38.5EU",
                      short: "38.5",
                      long: "38.5",
                      display: "38.5",
                      display_with_size_set: "38.5",
                      display_with_size_system: "EU 38.5",
                      display_with_system_and_set: "EU 38.5",
                      equivalent_sizes: {
                        us: ["5.5", "6"],
                        au: ["4.5AU", "5AU"],
                        uk: ["5.5UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "39EU",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "EU 39",
                      display_with_system_and_set: "EU 39",
                      equivalent_sizes: {
                        us: ["5.5", "6"],
                        au: ["4.5AU", "5AU"],
                        uk: ["5.5UK", "6UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "39.5EU",
                      short: "39.5",
                      long: "39.5",
                      display: "39.5",
                      display_with_size_set: "39.5",
                      display_with_size_system: "EU 39.5",
                      display_with_system_and_set: "EU 39.5",
                      equivalent_sizes: {
                        us: ["6.5", "7"],
                        au: ["5.5AU", "6AU"],
                        uk: ["6.5UK", "7UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40EU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "EU 40",
                      display_with_system_and_set: "EU 40",
                      equivalent_sizes: {
                        us: ["6.5", "7"],
                        au: ["5.5AU", "6AU"],
                        uk: ["6.5UK", "7UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40.5EU",
                      short: "40.5",
                      long: "40.5",
                      display: "40.5",
                      display_with_size_set: "40.5",
                      display_with_size_system: "EU 40.5",
                      display_with_system_and_set: "EU 40.5",
                      equivalent_sizes: {
                        us: ["7.5", "8"],
                        au: ["6.5AU", "7AU"],
                        uk: ["7.5UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "41EU",
                      short: "41",
                      long: "41",
                      display: "41",
                      display_with_size_set: "41",
                      display_with_size_system: "EU 41",
                      display_with_system_and_set: "EU 41",
                      equivalent_sizes: {
                        us: ["7.5", "8"],
                        au: ["6.5AU", "7AU"],
                        uk: ["7.5UK", "8UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "41.5EU",
                      short: "41.5",
                      long: "41.5",
                      display: "41.5",
                      display_with_size_set: "41.5",
                      display_with_size_system: "EU 41.5",
                      display_with_system_and_set: "EU 41.5",
                      equivalent_sizes: {
                        us: ["8.5", "9"],
                        au: ["7.5AU", "8AU"],
                        uk: ["8.5UK", "9UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42EU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "EU 42",
                      display_with_system_and_set: "EU 42",
                      equivalent_sizes: {
                        us: ["8.5", "9"],
                        au: ["7.5AU", "8AU"],
                        uk: ["8.5UK", "9UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "42.5EU",
                      short: "42.5",
                      long: "42.5",
                      display: "42.5",
                      display_with_size_set: "42.5",
                      display_with_size_system: "EU 42.5",
                      display_with_system_and_set: "EU 42.5",
                      equivalent_sizes: {
                        us: ["9.5", "10"],
                        au: ["8.5AU", "9AU"],
                        uk: ["9.5UK", "10UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "43EU",
                      short: "43",
                      long: "43",
                      display: "43",
                      display_with_size_set: "43",
                      display_with_size_system: "EU 43",
                      display_with_system_and_set: "EU 43",
                      equivalent_sizes: {
                        us: ["9.5", "10"],
                        au: ["8.5AU", "9AU"],
                        uk: ["9.5UK", "10UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "43.5EU",
                      short: "43.5",
                      long: "43.5",
                      display: "43.5",
                      display_with_size_set: "43.5",
                      display_with_size_system: "EU 43.5",
                      display_with_system_and_set: "EU 43.5",
                      equivalent_sizes: {
                        us: ["10.5", "11"],
                        au: ["9.5AU", "10AU"],
                        uk: ["10.5UK", "11UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44EU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "EU 44",
                      display_with_system_and_set: "EU 44",
                      equivalent_sizes: {
                        us: ["10.5", "11"],
                        au: ["9.5AU", "10AU"],
                        uk: ["10.5UK", "11UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44.5EU",
                      short: "44.5",
                      long: "44.5",
                      display: "44.5",
                      display_with_size_set: "44.5",
                      display_with_size_system: "EU 44.5",
                      display_with_system_and_set: "EU 44.5",
                      equivalent_sizes: {
                        us: ["11.5", "12"],
                        au: ["10.5AU", "11AU"],
                        uk: ["11.5UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "45EU",
                      short: "45",
                      long: "45",
                      display: "45",
                      display_with_size_set: "45",
                      display_with_size_system: "EU 45",
                      display_with_system_and_set: "EU 45",
                      equivalent_sizes: {
                        us: ["11.5", "12"],
                        au: ["10.5AU", "11AU"],
                        uk: ["11.5UK", "12UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "45.5EU",
                      short: "45.5",
                      long: "45.5",
                      display: "45.5",
                      display_with_size_set: "45.5",
                      display_with_size_system: "EU 45.5",
                      display_with_system_and_set: "EU 45.5",
                      equivalent_sizes: {
                        us: ["12.5", "13"],
                        au: ["11.5AU", "12AU"],
                        uk: ["12.5UK", "13UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46EU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "EU 46",
                      display_with_system_and_set: "EU 46",
                      equivalent_sizes: {
                        us: ["12.5", "13"],
                        au: ["11.5AU", "12AU"],
                        uk: ["12.5UK", "13UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46.5EU",
                      short: "46.5",
                      long: "46.5",
                      display: "46.5",
                      display_with_size_set: "46.5",
                      display_with_size_system: "EU 46.5",
                      display_with_system_and_set: "EU 46.5",
                      equivalent_sizes: {
                        us: ["13.5", "14"],
                        au: ["12.5AU", "13AU"],
                        uk: ["13.5UK", "14UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "47EU",
                      short: "47",
                      long: "47",
                      display: "47",
                      display_with_size_set: "47",
                      display_with_size_system: "EU 47",
                      display_with_system_and_set: "EU 47",
                      equivalent_sizes: {
                        us: ["13.5", "14"],
                        au: ["12.5AU", "13AU"],
                        uk: ["13.5UK", "14UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "47.5EU",
                      short: "47.5",
                      long: "47.5",
                      display: "47.5",
                      display_with_size_set: "47.5",
                      display_with_size_system: "EU 47.5",
                      display_with_system_and_set: "EU 47.5",
                      equivalent_sizes: {
                        us: ["14.5", "15"],
                        au: ["13.5AU", "14AU"],
                        uk: ["14.5UK", "15UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48EU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "EU 48",
                      display_with_system_and_set: "EU 48",
                      equivalent_sizes: {
                        us: ["14.5", "15"],
                        au: ["13.5AU", "14AU"],
                        uk: ["14.5UK", "15UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48.5EU",
                      short: "48.5",
                      long: "48.5",
                      display: "48.5",
                      display_with_size_set: "48.5",
                      display_with_size_system: "EU 48.5",
                      display_with_system_and_set: "EU 48.5",
                      equivalent_sizes: {
                        us: ["15.5", "16"],
                        au: ["14.5AU", "15AU"],
                        uk: ["15.5UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "49EU",
                      short: "49",
                      long: "49",
                      display: "49",
                      display_with_size_set: "49",
                      display_with_size_system: "EU 49",
                      display_with_system_and_set: "EU 49",
                      equivalent_sizes: {
                        us: ["15.5", "16"],
                        au: ["14.5AU", "15AU"],
                        uk: ["15.5UK", "16UK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "4UK",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4",
                      equivalent_sizes: {
                        us: ["4"],
                        au: ["3AU", "3.5AU"],
                        eu: ["37EU", "37.5EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4.5UK",
                      short: "4.5",
                      long: "4.5",
                      display: "4.5",
                      display_with_size_set: "4.5",
                      display_with_size_system: "UK 4.5",
                      display_with_system_and_set: "UK 4.5",
                      equivalent_sizes: {
                        us: ["4", "4.5", "5"],
                        au: ["3AU", "3.5AU", "4AU"],
                        eu: ["37EU", "37.5EU", "38EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5UK",
                      short: "5",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5",
                      display_with_size_system: "UK 5",
                      display_with_system_and_set: "UK 5",
                      equivalent_sizes: {
                        us: ["4.5", "5"],
                        au: ["3.5AU", "4AU"],
                        eu: ["37.5EU", "38EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5.5UK",
                      short: "5.5",
                      long: "5.5",
                      display: "5.5",
                      display_with_size_set: "5.5",
                      display_with_size_system: "UK 5.5",
                      display_with_system_and_set: "UK 5.5",
                      equivalent_sizes: {
                        us: ["5.5", "6"],
                        au: ["4.5AU", "5AU"],
                        eu: ["38.5EU", "39EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6UK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["5.5", "6"],
                        au: ["4.5AU", "5AU"],
                        eu: ["38.5EU", "39EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6.5UK",
                      short: "6.5",
                      long: "6.5",
                      display: "6.5",
                      display_with_size_set: "6.5",
                      display_with_size_system: "UK 6.5",
                      display_with_system_and_set: "UK 6.5",
                      equivalent_sizes: {
                        us: ["6.5", "7"],
                        au: ["5.5AU", "6AU"],
                        eu: ["39.5EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "7UK",
                      short: "7",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7",
                      display_with_size_system: "UK 7",
                      display_with_system_and_set: "UK 7",
                      equivalent_sizes: {
                        us: ["6.5", "7"],
                        au: ["5.5AU", "6AU"],
                        eu: ["39.5EU", "40EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "7.5UK",
                      short: "7.5",
                      long: "7.5",
                      display: "7.5",
                      display_with_size_set: "7.5",
                      display_with_size_system: "UK 7.5",
                      display_with_system_and_set: "UK 7.5",
                      equivalent_sizes: {
                        us: ["7.5", "8"],
                        au: ["6.5AU", "7AU"],
                        eu: ["40.5EU", "41EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8UK",
                      short: "8",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8",
                      equivalent_sizes: {
                        us: ["7.5", "8"],
                        au: ["6.5AU", "7AU"],
                        eu: ["40.5EU", "41EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8.5UK",
                      short: "8.5",
                      long: "8.5",
                      display: "8.5",
                      display_with_size_set: "8.5",
                      display_with_size_system: "UK 8.5",
                      display_with_system_and_set: "UK 8.5",
                      equivalent_sizes: {
                        us: ["8.5", "9"],
                        au: ["7.5AU", "8AU"],
                        eu: ["41.5EU", "42EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9UK",
                      short: "9",
                      long: "9",
                      display: "9",
                      display_with_size_set: "9",
                      display_with_size_system: "UK 9",
                      display_with_system_and_set: "UK 9",
                      equivalent_sizes: {
                        us: ["8.5", "9"],
                        au: ["7.5AU", "8AU"],
                        eu: ["41.5EU", "42EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9.5UK",
                      short: "9.5",
                      long: "9.5",
                      display: "9.5",
                      display_with_size_set: "9.5",
                      display_with_size_system: "UK 9.5",
                      display_with_system_and_set: "UK 9.5",
                      equivalent_sizes: {
                        us: ["9.5", "10"],
                        au: ["8.5AU", "9AU"],
                        eu: ["42.5EU", "43EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10UK",
                      short: "10",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10",
                      equivalent_sizes: {
                        us: ["9.5", "10"],
                        au: ["8.5AU", "9AU"],
                        eu: ["42.5EU", "43EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10.5UK",
                      short: "10.5",
                      long: "10.5",
                      display: "10.5",
                      display_with_size_set: "10.5",
                      display_with_size_system: "UK 10.5",
                      display_with_system_and_set: "UK 10.5",
                      equivalent_sizes: {
                        us: ["10.5", "11"],
                        au: ["9.5AU", "10AU"],
                        eu: ["43.5EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11UK",
                      short: "11",
                      long: "11",
                      display: "11",
                      display_with_size_set: "11",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11",
                      equivalent_sizes: {
                        us: ["10.5", "11"],
                        au: ["9.5AU", "10AU"],
                        eu: ["43.5EU", "44EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11.5UK",
                      short: "11.5",
                      long: "11.5",
                      display: "11.5",
                      display_with_size_set: "11.5",
                      display_with_size_system: "UK 11.5",
                      display_with_system_and_set: "UK 11.5",
                      equivalent_sizes: {
                        us: ["11.5", "12"],
                        au: ["10.5AU", "11AU"],
                        eu: ["44.5EU", "45EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12UK",
                      short: "12",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12",
                      equivalent_sizes: {
                        us: ["11.5", "12"],
                        au: ["10.5AU", "11AU"],
                        eu: ["44.5EU", "45EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12.5UK",
                      short: "12.5",
                      long: "12.5",
                      display: "12.5",
                      display_with_size_set: "12.5",
                      display_with_size_system: "UK 12.5",
                      display_with_system_and_set: "UK 12.5",
                      equivalent_sizes: {
                        us: ["12.5", "13"],
                        au: ["11.5AU", "12AU"],
                        eu: ["45.5EU", "46EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "13UK",
                      short: "13",
                      long: "13",
                      display: "13",
                      display_with_size_set: "13",
                      display_with_size_system: "UK 13",
                      display_with_system_and_set: "UK 13",
                      equivalent_sizes: {
                        us: ["12.5", "13"],
                        au: ["11.5AU", "12AU"],
                        eu: ["45.5EU", "46EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "13.5UK",
                      short: "13.5",
                      long: "13.5",
                      display: "13.5",
                      display_with_size_set: "13.5",
                      display_with_size_system: "UK 13.5",
                      display_with_system_and_set: "UK 13.5",
                      equivalent_sizes: {
                        us: ["13.5", "14"],
                        au: ["12.5AU", "13AU"],
                        eu: ["46.5EU", "47EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14UK",
                      short: "14",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14",
                      equivalent_sizes: {
                        us: ["13.5", "14"],
                        au: ["12.5AU", "13AU"],
                        eu: ["46.5EU", "47EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14.5UK",
                      short: "14.5",
                      long: "14.5",
                      display: "14.5",
                      display_with_size_set: "14.5",
                      display_with_size_system: "UK 14.5",
                      display_with_system_and_set: "UK 14.5",
                      equivalent_sizes: {
                        us: ["14.5", "15"],
                        au: ["13.5AU", "14AU"],
                        eu: ["47.5EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "15UK",
                      short: "15",
                      long: "15",
                      display: "15",
                      display_with_size_set: "15",
                      display_with_size_system: "UK 15",
                      display_with_system_and_set: "UK 15",
                      equivalent_sizes: {
                        us: ["14.5", "15"],
                        au: ["13.5AU", "14AU"],
                        eu: ["47.5EU", "48EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "15.5UK",
                      short: "15.5",
                      long: "15.5",
                      display: "15.5",
                      display_with_size_set: "15.5",
                      display_with_size_system: "UK 15.5",
                      display_with_system_and_set: "UK 15.5",
                      equivalent_sizes: {
                        us: ["15.5", "16"],
                        au: ["14.5AU", "15AU"],
                        eu: ["48.5EU", "49EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16",
                      equivalent_sizes: {
                        us: ["15.5", "16"],
                        au: ["14.5AU", "15AU"],
                        eu: ["48.5EU", "49EU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "men_shoes",
          aliases: [],
          slug: "Shoes",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
          category_features: [
            {
              id: "38009813d97b4e3995005764",
              display: "Athletic Shoes",
              type: "cf",
              message_id: "men_shoes_athletic_shoes",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Athletic_Shoes",
              alias_slugs: [],
              parent_id: "08008c10d97b4e1245005764",
            },
            {
              id: "39009813d97b4e3995005764",
              display: "Boat Shoes",
              type: "cf",
              message_id: "men_shoes_boat_shoes",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Boat_Shoes",
              alias_slugs: [],
              parent_id: "08008c10d97b4e1245005764",
            },
            {
              id: "04002f3cd97b4edf70005784",
              display: "Boots",
              type: "cf",
              message_id: "men_shoes_boots",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Boots",
              alias_slugs: [],
              parent_id: "08008c10d97b4e1245005764",
            },
            {
              id: "34009813d97b4e3995005764",
              display: "Chukka Boots",
              type: "cf",
              message_id: "men_shoes_chukka_boots",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Chukka_Boots",
              alias_slugs: [],
              parent_id: "08008c10d97b4e1245005764",
            },
            {
              id: "36009813d97b4e3995005764",
              display: "Cowboy & Western Boots",
              type: "cf",
              message_id: "men_shoes_cowboy_western_boots",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cowboy_&_Western_Boots",
              alias_slugs: [],
              parent_id: "08008c10d97b4e1245005764",
            },
            {
              id: "3a009813d97b4e3995005764",
              display: "Loafers & Slip-Ons",
              type: "cf",
              message_id: "men_shoes_loafers_slipons",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Loafers_&_Slip_Ons",
              alias_slugs: [],
              parent_id: "08008c10d97b4e1245005764",
            },
            {
              id: "3b009813d97b4e3995005764",
              display: "Oxfords & Derbys",
              type: "cf",
              message_id: "men_shoes_oxfords_derbys",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Oxfords_&_Derbys",
              alias_slugs: [],
              parent_id: "08008c10d97b4e1245005764",
            },
            {
              id: "37009813d97b4e3995005764",
              display: "Rain & Snow Boots",
              type: "cf",
              message_id: "men_shoes_rain_snow_boots",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Rain_&_Snow_Boots",
              alias_slugs: [],
              parent_id: "08008c10d97b4e1245005764",
            },
            {
              id: "3c009813d97b4e3995005764",
              display: "Sandals & Flip-Flops",
              type: "cf",
              message_id: "men_shoes_sandals_flipflops",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sandals_&_Flip_Flops",
              alias_slugs: [],
              parent_id: "08008c10d97b4e1245005764",
            },
            {
              id: "3d009813d97b4e3995005764",
              display: "Sneakers",
              type: "cf",
              message_id: "men_shoes_sneakers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sneakers",
              alias_slugs: [],
              parent_id: "08008c10d97b4e1245005764",
            },
          ],
        },
        {
          id: "02008c10d97b4e1245005764",
          display: "Accessories",
          size_sets: [
            {
              id: "01003667d97b4e6bdb005784",
              name: "Standard",
              tags: ["standard"],
              category_id: "02008c10d97b4e1245005764",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "men_accessories",
          aliases: [],
          slug: "Accessories",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
          category_features: [
            {
              id: "01009813d97b4e3995005764",
              display: "Belts",
              type: "cf",
              message_id: "men_accessories_belts",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Belts",
              alias_slugs: [],
              parent_id: "02008c10d97b4e1245005764",
            },
            {
              id: "03009813d97b4e3995005764",
              display: "Cuff Links",
              type: "cf",
              message_id: "men_accessories_cuff_links",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cuff_Links",
              alias_slugs: [],
              parent_id: "02008c10d97b4e1245005764",
            },
            {
              id: "a87df9aaaabb083120f45ec2",
              display: "Face Masks",
              type: "cf",
              message_id: "men_accessories_face_masks",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Face_Masks",
              alias_slugs: [],
              parent_id: "02008c10d97b4e1245005764",
            },
            {
              id: "01002f3cd97b4edf70005784",
              display: "Glasses",
              type: "cf",
              message_id: "men_accessories_glasses",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Glasses",
              alias_slugs: [],
              parent_id: "02008c10d97b4e1245005764",
            },
            {
              id: "04009813d97b4e3995005764",
              display: "Gloves",
              type: "cf",
              message_id: "men_accessories_gloves",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Gloves",
              alias_slugs: [],
              parent_id: "02008c10d97b4e1245005764",
            },
            {
              id: "05009813d97b4e3995005764",
              display: "Hats",
              type: "cf",
              message_id: "men_accessories_hats",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Hats",
              alias_slugs: [],
              parent_id: "02008c10d97b4e1245005764",
            },
            {
              id: "06009813d97b4e3995005764",
              display: "Jewelry",
              type: "cf",
              message_id: "men_accessories_jewelry",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Jewelry",
              alias_slugs: [],
              parent_id: "02008c10d97b4e1245005764",
            },
            {
              id: "0f00d070d97b4eaedd005776",
              display: "Key & Card Holders",
              type: "cf",
              message_id: "men_accessories_key_card_holders",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Key_&_Card_Holders",
              alias_slugs: [],
              parent_id: "02008c10d97b4e1245005764",
            },
            {
              id: "1100d070d97b4eaedd005776",
              display: "Money Clips",
              type: "cf",
              message_id: "men_accessories_money_clips",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Money_Clips",
              alias_slugs: [],
              parent_id: "02008c10d97b4e1245005764",
            },
            {
              id: "08009813d97b4e3995005764",
              display: "Phone Cases",
              type: "cf",
              message_id: "men_accessories_phone_cases",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Phone_Cases",
              alias_slugs: [],
              parent_id: "02008c10d97b4e1245005764",
            },
            {
              id: "09009813d97b4e3995005764",
              display: "Pocket Squares",
              type: "cf",
              message_id: "men_accessories_pocket_squares",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Pocket_Squares",
              alias_slugs: [],
              parent_id: "02008c10d97b4e1245005764",
            },
            {
              id: "0a009813d97b4e3995005764",
              display: "Scarves",
              type: "cf",
              message_id: "men_accessories_scarves",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Scarves",
              alias_slugs: [],
              parent_id: "02008c10d97b4e1245005764",
            },
            {
              id: "0b009813d97b4e3995005764",
              display: "Sunglasses",
              type: "cf",
              message_id: "men_accessories_sunglasses",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sunglasses",
              alias_slugs: [],
              parent_id: "02008c10d97b4e1245005764",
            },
            {
              id: "0c009813d97b4e3995005764",
              display: "Suspenders",
              type: "cf",
              message_id: "men_accessories_suspenders",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Suspenders",
              alias_slugs: [],
              parent_id: "02008c10d97b4e1245005764",
            },
            {
              id: "0d009813d97b4e3995005764",
              display: "Ties",
              type: "cf",
              message_id: "men_accessories_ties",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Ties",
              alias_slugs: [],
              parent_id: "02008c10d97b4e1245005764",
            },
            {
              id: "0e009813d97b4e3995005764",
              display: "Watches",
              type: "cf",
              message_id: "men_accessories_watches",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Watches",
              alias_slugs: [],
              parent_id: "02008c10d97b4e1245005764",
            },
          ],
        },
        {
          id: "04008c10d97b4e1245005764",
          display: "Jackets & Coats",
          size_sets: [
            {
              id: "03003667d97b4e6bdb005784",
              name: "Standard",
              tags: ["standard"],
              category_id: "04008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["34-36", "28-30"],
                  ["36-38", "30-32"],
                  ["38-40", "32-34"],
                  ["40-42", "34-36"],
                  ["42-44", "36-38"],
                  ["44-46", "38-40"],
                  ["46-48", "40-42"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["86.4-91.4", "71.1-76.2"],
                  ["91.4-96.5", "76.2-81.3"],
                  ["96.5-101.6", "81.3-86.4"],
                  ["101.6-106.7", "86.4-91.4"],
                  ["106.7-111.8", "91.4-96.5"],
                  ["111.8-116.9", "96.5-101.6"],
                  ["116.9-121.9", "101.6-106.7"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "0b1e4cd26058864a961862cf",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "04008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["46-48", "40-42"],
                  ["49-51", "43-45"],
                  ["52-54", "46-48"],
                  ["55-57", "49-51"],
                  ["58-60", "52-54"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["116.8-121.9", "101.6-106.6"],
                  ["124.4-129.5", "109.2-114.3"],
                  ["132.1-137.2", "116.8-121.9"],
                  ["139.7-144.8", "124.4-129.5"],
                  ["147.3-152.4", "132-137.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["3XL", "3XL", "3XL", "3XL"],
                  ["4XL", "4XL", "4XL", "4XL"],
                  ["5XL", "5XL", "5XL", "5XL"],
                  ["6XL", "6XL", "6XL", "6XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["3XL", "3XL", "3XL", "3XL"],
                ["4XL", "4XL", "4XL", "4XL"],
                ["5XL", "5XL", "5XL", "5XL"],
                ["6XL", "6XL", "6XL", "6XL"],
                ["LT", "LT", "LT", "LT"],
                ["XLT", "XLT", "XLT", "XLT"],
                ["2XLT", "2XLT", "2XLT", "2XLT"],
                ["3XLT", "3XLT", "3XLT", "3XLT"],
                ["4XLT", "4XLT", "4XLT", "4XLT"],
                ["5XLT", "5XLT", "5XLT", "5XLT"],
                ["6XLT", "6XLT", "6XLT", "6XLT"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XL",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "US 3XL",
                      display_with_system_and_set: "US 3XL",
                      equivalent_sizes: {
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XL",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "US 4XL",
                      display_with_system_and_set: "US 4XL",
                      equivalent_sizes: {
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XL",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "US 5XL",
                      display_with_system_and_set: "US 5XL",
                      equivalent_sizes: {
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XL",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "US 6XL",
                      display_with_system_and_set: "US 6XL",
                      equivalent_sizes: {
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LT",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "US LT",
                      display_with_system_and_set: "US LT",
                      equivalent_sizes: {
                        au: ["LTAU"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLT",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "US XLT",
                      display_with_system_and_set: "US XLT",
                      equivalent_sizes: {
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XLT",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "US 2XLT",
                      display_with_system_and_set: "US 2XLT",
                      equivalent_sizes: {
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XLT",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "US 3XLT",
                      display_with_system_and_set: "US 3XLT",
                      equivalent_sizes: {
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XLT",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "US 4XLT",
                      display_with_system_and_set: "US 4XLT",
                      equivalent_sizes: {
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XLT",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "US 5XLT",
                      display_with_system_and_set: "US 5XLT",
                      equivalent_sizes: {
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XLT",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "US 6XLT",
                      display_with_system_and_set: "US 6XLT",
                      equivalent_sizes: {
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLAU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "AU 3XL",
                      display_with_system_and_set: "AU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLAU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "AU 4XL",
                      display_with_system_and_set: "AU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLAU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "AU 5XL",
                      display_with_system_and_set: "AU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLAU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "AU 6XL",
                      display_with_system_and_set: "AU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LTAU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "AU LT",
                      display_with_system_and_set: "AU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLTAU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "AU XLT",
                      display_with_system_and_set: "AU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XLTAU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "AU 2XLT",
                      display_with_system_and_set: "AU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLTAU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "AU 3XLT",
                      display_with_system_and_set: "AU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLTAU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "AU 4XLT",
                      display_with_system_and_set: "AU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLTAU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "AU 5XLT",
                      display_with_system_and_set: "AU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLTAU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "AU 6XLT",
                      display_with_system_and_set: "AU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLEU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "EU 3XL",
                      display_with_system_and_set: "EU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLEU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "EU 4XL",
                      display_with_system_and_set: "EU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLEU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "EU 5XL",
                      display_with_system_and_set: "EU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLEU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "EU 6XL",
                      display_with_system_and_set: "EU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LTEU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "EU LT",
                      display_with_system_and_set: "EU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        uk: ["LTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLTEU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "EU XLT",
                      display_with_system_and_set: "EU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XLTEU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "EU 2XLT",
                      display_with_system_and_set: "EU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLTEU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "EU 3XLT",
                      display_with_system_and_set: "EU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLTEU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "EU 4XLT",
                      display_with_system_and_set: "EU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLTEU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "EU 5XLT",
                      display_with_system_and_set: "EU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLTEU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "EU 6XLT",
                      display_with_system_and_set: "EU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLUK",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "UK 3XL",
                      display_with_system_and_set: "UK 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLUK",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "UK 4XL",
                      display_with_system_and_set: "UK 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLUK",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "UK 5XL",
                      display_with_system_and_set: "UK 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLUK",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "UK 6XL",
                      display_with_system_and_set: "UK 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LTUK",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "UK LT",
                      display_with_system_and_set: "UK LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        eu: ["LTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLTUK",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "UK XLT",
                      display_with_system_and_set: "UK XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XLTUK",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "UK 2XLT",
                      display_with_system_and_set: "UK 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLTUK",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "UK 3XLT",
                      display_with_system_and_set: "UK 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLTUK",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "UK 4XLT",
                      display_with_system_and_set: "UK 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLTUK",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "UK 5XLT",
                      display_with_system_and_set: "UK 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLTUK",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "UK 6XLT",
                      display_with_system_and_set: "UK 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "men_jackets_coats",
          aliases: [],
          slug: "Jackets_&_Coats",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
          category_features: [
            {
              id: "18009813d97b4e3995005764",
              display: "Bomber & Varsity",
              type: "cf",
              message_id: "men_jackets_coats_bomber_varsity",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bomber_&_Varsity",
              alias_slugs: [],
              parent_id: "04008c10d97b4e1245005764",
            },
            {
              id: "19009813d97b4e3995005764",
              display: "Lightweight & Shirt Jackets",
              type: "cf",
              message_id: "men_jackets_coats_lightweight_shirt_jackets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Lightweight_&_Shirt_Jackets",
              alias_slugs: [],
              parent_id: "04008c10d97b4e1245005764",
            },
            {
              id: "1a009813d97b4e3995005764",
              display: "Military & Field",
              type: "cf",
              message_id: "men_jackets_coats_military_field",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Military_&_Field",
              alias_slugs: [],
              parent_id: "04008c10d97b4e1245005764",
            },
            {
              id: "1c009813d97b4e3995005764",
              display: "Pea Coats",
              type: "cf",
              message_id: "men_jackets_coats_pea_coats",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Pea_Coats",
              alias_slugs: [],
              parent_id: "04008c10d97b4e1245005764",
            },
            {
              id: "1d009813d97b4e3995005764",
              display: "Performance Jackets",
              type: "cf",
              message_id: "men_jackets_coats_performance_jackets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Performance_Jackets",
              alias_slugs: [],
              parent_id: "04008c10d97b4e1245005764",
            },
            {
              id: "1e009813d97b4e3995005764",
              display: "Puffers",
              type: "cf",
              message_id: "men_jackets_coats_puffers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Puffers",
              alias_slugs: [],
              parent_id: "04008c10d97b4e1245005764",
            },
            {
              id: "1f009813d97b4e3995005764",
              display: "Raincoats",
              type: "cf",
              message_id: "men_jackets_coats_raincoats",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Raincoats",
              alias_slugs: [],
              parent_id: "04008c10d97b4e1245005764",
            },
            {
              id: "20009813d97b4e3995005764",
              display: "Ski & Snowboard",
              type: "cf",
              message_id: "men_jackets_coats_ski_snowboard",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Ski_&_Snowboard",
              alias_slugs: [],
              parent_id: "04008c10d97b4e1245005764",
            },
            {
              id: "21009813d97b4e3995005764",
              display: "Trench Coats",
              type: "cf",
              message_id: "men_jackets_coats_trench_coats",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Trench_Coats",
              alias_slugs: [],
              parent_id: "04008c10d97b4e1245005764",
            },
            {
              id: "23009813d97b4e3995005764",
              display: "Vests",
              type: "cf",
              message_id: "men_jackets_coats_vests",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Vests",
              alias_slugs: [],
              parent_id: "04008c10d97b4e1245005764",
            },
            {
              id: "22009813d97b4e3995005764",
              display: "Windbreakers",
              type: "cf",
              message_id: "men_jackets_coats_windbreakers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Windbreakers",
              alias_slugs: [],
              parent_id: "04008c10d97b4e1245005764",
            },
          ],
        },
        {
          id: "05008c10d97b4e1245005764",
          display: "Jeans",
          size_sets: [
            {
              id: "04003667d97b4e6bdb005784",
              name: "Standard",
              tags: ["standard"],
              category_id: "05008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Waist "],
                  ["28"],
                  ["29"],
                  ["30"],
                  ["31"],
                  ["32"],
                  ["33"],
                  ["34"],
                  ["35"],
                  ["36"],
                  ["37"],
                  ["38"],
                  ["39"],
                  ["40"],
                  ["41"],
                ],
                centimeters: [
                  ["Waist "],
                  ["71.1"],
                  ["73.7"],
                  ["76.2"],
                  ["78.7"],
                  ["81.3"],
                  ["83.8"],
                  ["86.4"],
                  ["88.9"],
                  ["91.4"],
                  ["94.0"],
                  ["96.5"],
                  ["99.1"],
                  ["101.6"],
                  ["104.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["Waist 28", "28", "71.12", "28"],
                  ["Waist 29", "29", "73.66", "29"],
                  ["Waist 30", "30", "76.2", "30"],
                  ["Waist 31", "31", "78.8", "31"],
                  ["Waist 32", "32", "81.3", "32"],
                  ["Waist 33", "33", "83.8", "33"],
                  ["Waist 34", "34", "86.4", "34"],
                  ["Waist 35", "35", "88.9", "35"],
                  ["Waist 36", "36", "91.4", "36"],
                  ["Waist 37", "37", "94", "37"],
                  ["Waist 38", "38", "96.5", "38"],
                  ["Waist 39", "39", "99.1", "39"],
                  ["Waist 40", "40", "101.6", "40"],
                  ["Waist 41", "41", "104.1", "41"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["Waist 28", "28", "71.12", "28"],
                ["Waist 29", "29", "73.66", "29"],
                ["Waist 30", "30", "76.2", "30"],
                ["Waist 31", "31", "78.8", "31"],
                ["Waist 32", "32", "81.3", "32"],
                ["Waist 33", "33", "83.8", "33"],
                ["Waist 34", "34", "86.4", "34"],
                ["Waist 35", "35", "88.9", "35"],
                ["Waist 36", "36", "91.4", "36"],
                ["Waist 37", "37", "94", "37"],
                ["Waist 38", "38", "96.5", "38"],
                ["Waist 39", "39", "99.1", "39"],
                ["Waist 40", "40", "101.6", "40"],
                ["Waist 41", "41", "104.1", "41"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "28",
                      short: "Waist 28",
                      long: "Waist 28",
                      display: "Waist 28",
                      display_with_size_set: "Waist 28",
                      display_with_size_system: "US Waist 28",
                      display_with_system_and_set: "US Waist 28",
                      equivalent_sizes: {
                        au: ["28AU"],
                        eu: ["71.12EU"],
                        uk: ["28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "29",
                      short: "Waist 29",
                      long: "Waist 29",
                      display: "Waist 29",
                      display_with_size_set: "Waist 29",
                      display_with_size_system: "US Waist 29",
                      display_with_system_and_set: "US Waist 29",
                      equivalent_sizes: {
                        au: ["29AU"],
                        eu: ["73.66EU"],
                        uk: ["29UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "Waist 30",
                      long: "Waist 30",
                      display: "Waist 30",
                      display_with_size_set: "Waist 30",
                      display_with_size_system: "US Waist 30",
                      display_with_system_and_set: "US Waist 30",
                      equivalent_sizes: {
                        au: ["30AU"],
                        eu: ["76.2EU"],
                        uk: ["30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "31",
                      short: "Waist 31",
                      long: "Waist 31",
                      display: "Waist 31",
                      display_with_size_set: "Waist 31",
                      display_with_size_system: "US Waist 31",
                      display_with_system_and_set: "US Waist 31",
                      equivalent_sizes: {
                        au: ["31AU"],
                        eu: ["78.8EU"],
                        uk: ["31UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "Waist 32",
                      long: "Waist 32",
                      display: "Waist 32",
                      display_with_size_set: "Waist 32",
                      display_with_size_system: "US Waist 32",
                      display_with_system_and_set: "US Waist 32",
                      equivalent_sizes: {
                        au: ["32AU"],
                        eu: ["81.3EU"],
                        uk: ["32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "33",
                      short: "Waist 33",
                      long: "Waist 33",
                      display: "Waist 33",
                      display_with_size_set: "Waist 33",
                      display_with_size_system: "US Waist 33",
                      display_with_system_and_set: "US Waist 33",
                      equivalent_sizes: {
                        au: ["33AU"],
                        eu: ["83.8EU"],
                        uk: ["33UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34",
                      short: "Waist 34",
                      long: "Waist 34",
                      display: "Waist 34",
                      display_with_size_set: "Waist 34",
                      display_with_size_system: "US Waist 34",
                      display_with_system_and_set: "US Waist 34",
                      equivalent_sizes: {
                        au: ["34AU"],
                        eu: ["86.4EU"],
                        uk: ["34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "35",
                      short: "Waist 35",
                      long: "Waist 35",
                      display: "Waist 35",
                      display_with_size_set: "Waist 35",
                      display_with_size_system: "US Waist 35",
                      display_with_system_and_set: "US Waist 35",
                      equivalent_sizes: {
                        au: ["35AU"],
                        eu: ["88.9EU"],
                        uk: ["35UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36",
                      short: "Waist 36",
                      long: "Waist 36",
                      display: "Waist 36",
                      display_with_size_set: "Waist 36",
                      display_with_size_system: "US Waist 36",
                      display_with_system_and_set: "US Waist 36",
                      equivalent_sizes: {
                        au: ["36AU"],
                        eu: ["91.4EU"],
                        uk: ["36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "37",
                      short: "Waist 37",
                      long: "Waist 37",
                      display: "Waist 37",
                      display_with_size_set: "Waist 37",
                      display_with_size_system: "US Waist 37",
                      display_with_system_and_set: "US Waist 37",
                      equivalent_sizes: {
                        au: ["37AU"],
                        eu: ["94EU"],
                        uk: ["37UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38",
                      short: "Waist 38",
                      long: "Waist 38",
                      display: "Waist 38",
                      display_with_size_set: "Waist 38",
                      display_with_size_system: "US Waist 38",
                      display_with_system_and_set: "US Waist 38",
                      equivalent_sizes: {
                        au: ["38AU"],
                        eu: ["96.5EU"],
                        uk: ["38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "39",
                      short: "Waist 39",
                      long: "Waist 39",
                      display: "Waist 39",
                      display_with_size_set: "Waist 39",
                      display_with_size_system: "US Waist 39",
                      display_with_system_and_set: "US Waist 39",
                      equivalent_sizes: {
                        au: ["39AU"],
                        eu: ["99.1EU"],
                        uk: ["39UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40",
                      short: "Waist 40",
                      long: "Waist 40",
                      display: "Waist 40",
                      display_with_size_set: "Waist 40",
                      display_with_size_system: "US Waist 40",
                      display_with_system_and_set: "US Waist 40",
                      equivalent_sizes: {
                        au: ["40AU"],
                        eu: ["101.6EU"],
                        uk: ["40UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "41",
                      short: "Waist 41",
                      long: "Waist 41",
                      display: "Waist 41",
                      display_with_size_set: "Waist 41",
                      display_with_size_system: "US Waist 41",
                      display_with_system_and_set: "US Waist 41",
                      equivalent_sizes: {
                        au: ["41AU"],
                        eu: ["104.1EU"],
                        uk: ["41UK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["28"],
                        eu: ["71.12EU"],
                        uk: ["28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "29AU",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "AU 29",
                      display_with_system_and_set: "AU 29",
                      equivalent_sizes: {
                        us: ["29"],
                        eu: ["73.66EU"],
                        uk: ["29UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["30"],
                        eu: ["76.2EU"],
                        uk: ["30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "31AU",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "AU 31",
                      display_with_system_and_set: "AU 31",
                      equivalent_sizes: {
                        us: ["31"],
                        eu: ["78.8EU"],
                        uk: ["31UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["32"],
                        eu: ["81.3EU"],
                        uk: ["32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "33AU",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "AU 33",
                      display_with_system_and_set: "AU 33",
                      equivalent_sizes: {
                        us: ["33"],
                        eu: ["83.8EU"],
                        uk: ["33UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["34"],
                        eu: ["86.4EU"],
                        uk: ["34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "35AU",
                      short: "35",
                      long: "35",
                      display: "35",
                      display_with_size_set: "35",
                      display_with_size_system: "AU 35",
                      display_with_system_and_set: "AU 35",
                      equivalent_sizes: {
                        us: ["35"],
                        eu: ["88.9EU"],
                        uk: ["35UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["36"],
                        eu: ["91.4EU"],
                        uk: ["36UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "37AU",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "AU 37",
                      display_with_system_and_set: "AU 37",
                      equivalent_sizes: {
                        us: ["37"],
                        eu: ["94EU"],
                        uk: ["37UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["38"],
                        eu: ["96.5EU"],
                        uk: ["38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "39AU",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "AU 39",
                      display_with_system_and_set: "AU 39",
                      equivalent_sizes: {
                        us: ["39"],
                        eu: ["99.1EU"],
                        uk: ["39UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "40AU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "AU 40",
                      display_with_system_and_set: "AU 40",
                      equivalent_sizes: {
                        us: ["40"],
                        eu: ["101.6EU"],
                        uk: ["40UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "41AU",
                      short: "41",
                      long: "41",
                      display: "41",
                      display_with_size_set: "41",
                      display_with_size_system: "AU 41",
                      display_with_system_and_set: "AU 41",
                      equivalent_sizes: {
                        us: ["41"],
                        eu: ["104.1EU"],
                        uk: ["41UK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "71.12EU",
                      short: "71.12",
                      long: "71.12",
                      display: "71.12",
                      display_with_size_set: "71.12",
                      display_with_size_system: "EU 71.12",
                      display_with_system_and_set: "EU 71.12",
                      equivalent_sizes: {
                        us: ["28"],
                        au: ["28AU"],
                        uk: ["28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "73.66EU",
                      short: "73.66",
                      long: "73.66",
                      display: "73.66",
                      display_with_size_set: "73.66",
                      display_with_size_system: "EU 73.66",
                      display_with_system_and_set: "EU 73.66",
                      equivalent_sizes: {
                        us: ["29"],
                        au: ["29AU"],
                        uk: ["29UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "76.2EU",
                      short: "76.2",
                      long: "76.2",
                      display: "76.2",
                      display_with_size_set: "76.2",
                      display_with_size_system: "EU 76.2",
                      display_with_system_and_set: "EU 76.2",
                      equivalent_sizes: {
                        us: ["30"],
                        au: ["30AU"],
                        uk: ["30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "78.8EU",
                      short: "78.8",
                      long: "78.8",
                      display: "78.8",
                      display_with_size_set: "78.8",
                      display_with_size_system: "EU 78.8",
                      display_with_system_and_set: "EU 78.8",
                      equivalent_sizes: {
                        us: ["31"],
                        au: ["31AU"],
                        uk: ["31UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "81.3EU",
                      short: "81.3",
                      long: "81.3",
                      display: "81.3",
                      display_with_size_set: "81.3",
                      display_with_size_system: "EU 81.3",
                      display_with_system_and_set: "EU 81.3",
                      equivalent_sizes: {
                        us: ["32"],
                        au: ["32AU"],
                        uk: ["32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "83.8EU",
                      short: "83.8",
                      long: "83.8",
                      display: "83.8",
                      display_with_size_set: "83.8",
                      display_with_size_system: "EU 83.8",
                      display_with_system_and_set: "EU 83.8",
                      equivalent_sizes: {
                        us: ["33"],
                        au: ["33AU"],
                        uk: ["33UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "86.4EU",
                      short: "86.4",
                      long: "86.4",
                      display: "86.4",
                      display_with_size_set: "86.4",
                      display_with_size_system: "EU 86.4",
                      display_with_system_and_set: "EU 86.4",
                      equivalent_sizes: {
                        us: ["34"],
                        au: ["34AU"],
                        uk: ["34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "88.9EU",
                      short: "88.9",
                      long: "88.9",
                      display: "88.9",
                      display_with_size_set: "88.9",
                      display_with_size_system: "EU 88.9",
                      display_with_system_and_set: "EU 88.9",
                      equivalent_sizes: {
                        us: ["35"],
                        au: ["35AU"],
                        uk: ["35UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "91.4EU",
                      short: "91.4",
                      long: "91.4",
                      display: "91.4",
                      display_with_size_set: "91.4",
                      display_with_size_system: "EU 91.4",
                      display_with_system_and_set: "EU 91.4",
                      equivalent_sizes: {
                        us: ["36"],
                        au: ["36AU"],
                        uk: ["36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "94EU",
                      short: "94",
                      long: "94",
                      display: "94",
                      display_with_size_set: "94",
                      display_with_size_system: "EU 94",
                      display_with_system_and_set: "EU 94",
                      equivalent_sizes: {
                        us: ["37"],
                        au: ["37AU"],
                        uk: ["37UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "96.5EU",
                      short: "96.5",
                      long: "96.5",
                      display: "96.5",
                      display_with_size_set: "96.5",
                      display_with_size_system: "EU 96.5",
                      display_with_system_and_set: "EU 96.5",
                      equivalent_sizes: {
                        us: ["38"],
                        au: ["38AU"],
                        uk: ["38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "99.1EU",
                      short: "99.1",
                      long: "99.1",
                      display: "99.1",
                      display_with_size_set: "99.1",
                      display_with_size_system: "EU 99.1",
                      display_with_system_and_set: "EU 99.1",
                      equivalent_sizes: {
                        us: ["39"],
                        au: ["39AU"],
                        uk: ["39UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "101.6EU",
                      short: "101.6",
                      long: "101.6",
                      display: "101.6",
                      display_with_size_set: "101.6",
                      display_with_size_system: "EU 101.6",
                      display_with_system_and_set: "EU 101.6",
                      equivalent_sizes: {
                        us: ["40"],
                        au: ["40AU"],
                        uk: ["40UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "104.1EU",
                      short: "104.1",
                      long: "104.1",
                      display: "104.1",
                      display_with_size_set: "104.1",
                      display_with_size_system: "EU 104.1",
                      display_with_system_and_set: "EU 104.1",
                      equivalent_sizes: {
                        us: ["41"],
                        au: ["41AU"],
                        uk: ["41UK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["28"],
                        au: ["28AU"],
                        eu: ["71.12EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "29UK",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "UK 29",
                      display_with_system_and_set: "UK 29",
                      equivalent_sizes: {
                        us: ["29"],
                        au: ["29AU"],
                        eu: ["73.66EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["30"],
                        au: ["30AU"],
                        eu: ["76.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "31UK",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "UK 31",
                      display_with_system_and_set: "UK 31",
                      equivalent_sizes: {
                        us: ["31"],
                        au: ["31AU"],
                        eu: ["78.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["32"],
                        au: ["32AU"],
                        eu: ["81.3EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "33UK",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "UK 33",
                      display_with_system_and_set: "UK 33",
                      equivalent_sizes: {
                        us: ["33"],
                        au: ["33AU"],
                        eu: ["83.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["34"],
                        au: ["34AU"],
                        eu: ["86.4EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "35UK",
                      short: "35",
                      long: "35",
                      display: "35",
                      display_with_size_set: "35",
                      display_with_size_system: "UK 35",
                      display_with_system_and_set: "UK 35",
                      equivalent_sizes: {
                        us: ["35"],
                        au: ["35AU"],
                        eu: ["88.9EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["36"],
                        au: ["36AU"],
                        eu: ["91.4EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "37UK",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "UK 37",
                      display_with_system_and_set: "UK 37",
                      equivalent_sizes: {
                        us: ["37"],
                        au: ["37AU"],
                        eu: ["94EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["38"],
                        au: ["38AU"],
                        eu: ["96.5EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "39UK",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "UK 39",
                      display_with_system_and_set: "UK 39",
                      equivalent_sizes: {
                        us: ["39"],
                        au: ["39AU"],
                        eu: ["99.1EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40UK",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "UK 40",
                      display_with_system_and_set: "UK 40",
                      equivalent_sizes: {
                        us: ["40"],
                        au: ["40AU"],
                        eu: ["101.6EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "41UK",
                      short: "41",
                      long: "41",
                      display: "41",
                      display_with_size_set: "41",
                      display_with_size_system: "UK 41",
                      display_with_system_and_set: "UK 41",
                      equivalent_sizes: {
                        us: ["41"],
                        au: ["41AU"],
                        eu: ["104.1EU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "0c1e4cd26058864a961862cf",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "05008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Waist "],
                  ["42"],
                  ["43"],
                  ["44"],
                  ["46"],
                  ["48"],
                  ["50"],
                  ["52"],
                  ["54"],
                  ["56"],
                  ["58"],
                  ["60"],
                ],
                centimeters: [
                  ["Waist "],
                  ["106.7"],
                  ["109.2"],
                  ["111.8"],
                  ["116.8"],
                  ["121.9"],
                  ["127.0"],
                  ["132.1"],
                  ["137.2"],
                  ["142.2"],
                  ["147.3"],
                  ["152.4"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["Waist 42", "42", "106.7", "42"],
                  ["Waist 43", "43", "109.2", "43"],
                  ["Waist 44", "44", "111.8", "44"],
                  ["Waist 46", "46", "116.8", "46"],
                  ["Waist 48", "48", "121.9", "48"],
                  ["Waist 50", "50", "127.0", "50"],
                  ["Waist 52", "52", "132.1", "52"],
                  ["Waist 54", "54", "137.2", "54"],
                  ["Waist 56", "56", "142.2", "56"],
                  ["Waist 58", "58", "147.3", "58"],
                  ["Waist 60", "60", "152.4", "60"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["Waist 36", "36", "91.4", "36"],
                ["Waist 37", "37", "94", "37"],
                ["Waist 38", "38", "96.5", "38"],
                ["Waist 39", "39", "99.1", "39"],
                ["Waist 40", "40", "101.6", "40"],
                ["Waist 41", "41", "104.1", "41"],
                ["Waist 42", "42", "106.7", "42"],
                ["Waist 43", "43", "109.2", "43"],
                ["Waist 44", "44", "111.8", "44"],
                ["Waist 46", "46", "116.8", "46"],
                ["Waist 48", "48", "121.9", "48"],
                ["Waist 50", "50", "127.0", "50"],
                ["Waist 52", "52", "132.1", "52"],
                ["Waist 54", "54", "137.2", "54"],
                ["Waist 56", "56", "142.2", "56"],
                ["Waist 58", "58", "147.3", "58"],
                ["Waist 60", "60", "152.4", "60"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "36BT",
                      short: "Waist 36",
                      long: "Waist 36",
                      display: "Waist 36",
                      display_with_size_set: "Waist 36",
                      display_with_size_system: "US Waist 36",
                      display_with_system_and_set: "US Waist 36",
                      equivalent_sizes: {
                        au: ["36BTAU"],
                        eu: ["91.4BTEU"],
                        uk: ["36BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "37BT",
                      short: "Waist 37",
                      long: "Waist 37",
                      display: "Waist 37",
                      display_with_size_set: "Waist 37",
                      display_with_size_system: "US Waist 37",
                      display_with_system_and_set: "US Waist 37",
                      equivalent_sizes: {
                        au: ["37BTAU"],
                        eu: ["94BTEU"],
                        uk: ["37BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38BT",
                      short: "Waist 38",
                      long: "Waist 38",
                      display: "Waist 38",
                      display_with_size_set: "Waist 38",
                      display_with_size_system: "US Waist 38",
                      display_with_system_and_set: "US Waist 38",
                      equivalent_sizes: {
                        au: ["38BTAU"],
                        eu: ["96.5BTEU"],
                        uk: ["38BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "39BT",
                      short: "Waist 39",
                      long: "Waist 39",
                      display: "Waist 39",
                      display_with_size_set: "Waist 39",
                      display_with_size_system: "US Waist 39",
                      display_with_system_and_set: "US Waist 39",
                      equivalent_sizes: {
                        au: ["39BTAU"],
                        eu: ["99.1BTEU"],
                        uk: ["39BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40BT",
                      short: "Waist 40",
                      long: "Waist 40",
                      display: "Waist 40",
                      display_with_size_set: "Waist 40",
                      display_with_size_system: "US Waist 40",
                      display_with_system_and_set: "US Waist 40",
                      equivalent_sizes: {
                        au: ["40BTAU"],
                        eu: ["101.6BTEU"],
                        uk: ["40BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "41BT",
                      short: "Waist 41",
                      long: "Waist 41",
                      display: "Waist 41",
                      display_with_size_set: "Waist 41",
                      display_with_size_system: "US Waist 41",
                      display_with_system_and_set: "US Waist 41",
                      equivalent_sizes: {
                        au: ["41BTAU"],
                        eu: ["104.1BTEU"],
                        uk: ["41BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42",
                      short: "Waist 42",
                      long: "Waist 42",
                      display: "Waist 42",
                      display_with_size_set: "Waist 42",
                      display_with_size_system: "US Waist 42",
                      display_with_system_and_set: "US Waist 42",
                      equivalent_sizes: {
                        au: ["42AU"],
                        eu: ["106.7EU"],
                        uk: ["42UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "43",
                      short: "Waist 43",
                      long: "Waist 43",
                      display: "Waist 43",
                      display_with_size_set: "Waist 43",
                      display_with_size_system: "US Waist 43",
                      display_with_system_and_set: "US Waist 43",
                      equivalent_sizes: {
                        au: ["43AU"],
                        eu: ["109.2EU"],
                        uk: ["43UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44",
                      short: "Waist 44",
                      long: "Waist 44",
                      display: "Waist 44",
                      display_with_size_set: "Waist 44",
                      display_with_size_system: "US Waist 44",
                      display_with_system_and_set: "US Waist 44",
                      equivalent_sizes: {
                        au: ["44AU"],
                        eu: ["111.8EU"],
                        uk: ["44UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46",
                      short: "Waist 46",
                      long: "Waist 46",
                      display: "Waist 46",
                      display_with_size_set: "Waist 46",
                      display_with_size_system: "US Waist 46",
                      display_with_system_and_set: "US Waist 46",
                      equivalent_sizes: {
                        au: ["46AU"],
                        eu: ["116.8EU"],
                        uk: ["46UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48",
                      short: "Waist 48",
                      long: "Waist 48",
                      display: "Waist 48",
                      display_with_size_set: "Waist 48",
                      display_with_size_system: "US Waist 48",
                      display_with_system_and_set: "US Waist 48",
                      equivalent_sizes: {
                        au: ["48AU"],
                        eu: ["121.9EU"],
                        uk: ["48UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "50",
                      short: "Waist 50",
                      long: "Waist 50",
                      display: "Waist 50",
                      display_with_size_set: "Waist 50",
                      display_with_size_system: "US Waist 50",
                      display_with_system_and_set: "US Waist 50",
                      equivalent_sizes: {
                        au: ["50AU"],
                        eu: ["127.0EU"],
                        uk: ["50UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "52",
                      short: "Waist 52",
                      long: "Waist 52",
                      display: "Waist 52",
                      display_with_size_set: "Waist 52",
                      display_with_size_system: "US Waist 52",
                      display_with_system_and_set: "US Waist 52",
                      equivalent_sizes: {
                        au: ["52AU"],
                        eu: ["132.1EU"],
                        uk: ["52UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "54",
                      short: "Waist 54",
                      long: "Waist 54",
                      display: "Waist 54",
                      display_with_size_set: "Waist 54",
                      display_with_size_system: "US Waist 54",
                      display_with_system_and_set: "US Waist 54",
                      equivalent_sizes: {
                        au: ["54AU"],
                        eu: ["137.2EU"],
                        uk: ["54UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "56",
                      short: "Waist 56",
                      long: "Waist 56",
                      display: "Waist 56",
                      display_with_size_set: "Waist 56",
                      display_with_size_system: "US Waist 56",
                      display_with_system_and_set: "US Waist 56",
                      equivalent_sizes: {
                        au: ["56AU"],
                        eu: ["142.2EU"],
                        uk: ["56UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "58",
                      short: "Waist 58",
                      long: "Waist 58",
                      display: "Waist 58",
                      display_with_size_set: "Waist 58",
                      display_with_size_system: "US Waist 58",
                      display_with_system_and_set: "US Waist 58",
                      equivalent_sizes: {
                        au: ["58AU"],
                        eu: ["147.3EU"],
                        uk: ["58UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "60",
                      short: "Waist 60",
                      long: "Waist 60",
                      display: "Waist 60",
                      display_with_size_set: "Waist 60",
                      display_with_size_system: "US Waist 60",
                      display_with_system_and_set: "US Waist 60",
                      equivalent_sizes: {
                        au: ["60AU"],
                        eu: ["152.4EU"],
                        uk: ["60UK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "36BTAU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["36BT"],
                        eu: ["91.4BTEU"],
                        uk: ["36BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "37BTAU",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "AU 37",
                      display_with_system_and_set: "AU 37",
                      equivalent_sizes: {
                        us: ["37BT"],
                        eu: ["94BTEU"],
                        uk: ["37BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38BTAU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["38BT"],
                        eu: ["96.5BTEU"],
                        uk: ["38BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "39BTAU",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "AU 39",
                      display_with_system_and_set: "AU 39",
                      equivalent_sizes: {
                        us: ["39BT"],
                        eu: ["99.1BTEU"],
                        uk: ["39BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "40BTAU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "AU 40",
                      display_with_system_and_set: "AU 40",
                      equivalent_sizes: {
                        us: ["40BT"],
                        eu: ["101.6BTEU"],
                        uk: ["40BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "41BTAU",
                      short: "41",
                      long: "41",
                      display: "41",
                      display_with_size_set: "41",
                      display_with_size_system: "AU 41",
                      display_with_system_and_set: "AU 41",
                      equivalent_sizes: {
                        us: ["41BT"],
                        eu: ["104.1BTEU"],
                        uk: ["41BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "42AU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "AU 42",
                      display_with_system_and_set: "AU 42",
                      equivalent_sizes: {
                        us: ["42"],
                        eu: ["106.7EU"],
                        uk: ["42UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "43AU",
                      short: "43",
                      long: "43",
                      display: "43",
                      display_with_size_set: "43",
                      display_with_size_system: "AU 43",
                      display_with_system_and_set: "AU 43",
                      equivalent_sizes: {
                        us: ["43"],
                        eu: ["109.2EU"],
                        uk: ["43UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "44AU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "AU 44",
                      display_with_system_and_set: "AU 44",
                      equivalent_sizes: {
                        us: ["44"],
                        eu: ["111.8EU"],
                        uk: ["44UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "46AU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "AU 46",
                      display_with_system_and_set: "AU 46",
                      equivalent_sizes: {
                        us: ["46"],
                        eu: ["116.8EU"],
                        uk: ["46UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "48AU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "AU 48",
                      display_with_system_and_set: "AU 48",
                      equivalent_sizes: {
                        us: ["48"],
                        eu: ["121.9EU"],
                        uk: ["48UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "50AU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "AU 50",
                      display_with_system_and_set: "AU 50",
                      equivalent_sizes: {
                        us: ["50"],
                        eu: ["127.0EU"],
                        uk: ["50UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "52AU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "AU 52",
                      display_with_system_and_set: "AU 52",
                      equivalent_sizes: {
                        us: ["52"],
                        eu: ["132.1EU"],
                        uk: ["52UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "54AU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "AU 54",
                      display_with_system_and_set: "AU 54",
                      equivalent_sizes: {
                        us: ["54"],
                        eu: ["137.2EU"],
                        uk: ["54UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "56AU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "AU 56",
                      display_with_system_and_set: "AU 56",
                      equivalent_sizes: {
                        us: ["56"],
                        eu: ["142.2EU"],
                        uk: ["56UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "58AU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "AU 58",
                      display_with_system_and_set: "AU 58",
                      equivalent_sizes: {
                        us: ["58"],
                        eu: ["147.3EU"],
                        uk: ["58UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "60AU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "AU 60",
                      display_with_system_and_set: "AU 60",
                      equivalent_sizes: {
                        us: ["60"],
                        eu: ["152.4EU"],
                        uk: ["60UK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "91.4BTEU",
                      short: "91.4",
                      long: "91.4",
                      display: "91.4",
                      display_with_size_set: "91.4",
                      display_with_size_system: "EU 91.4",
                      display_with_system_and_set: "EU 91.4",
                      equivalent_sizes: {
                        us: ["36BT"],
                        au: ["36BTAU"],
                        uk: ["36BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "94BTEU",
                      short: "94",
                      long: "94",
                      display: "94",
                      display_with_size_set: "94",
                      display_with_size_system: "EU 94",
                      display_with_system_and_set: "EU 94",
                      equivalent_sizes: {
                        us: ["37BT"],
                        au: ["37BTAU"],
                        uk: ["37BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "96.5BTEU",
                      short: "96.5",
                      long: "96.5",
                      display: "96.5",
                      display_with_size_set: "96.5",
                      display_with_size_system: "EU 96.5",
                      display_with_system_and_set: "EU 96.5",
                      equivalent_sizes: {
                        us: ["38BT"],
                        au: ["38BTAU"],
                        uk: ["38BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "99.1BTEU",
                      short: "99.1",
                      long: "99.1",
                      display: "99.1",
                      display_with_size_set: "99.1",
                      display_with_size_system: "EU 99.1",
                      display_with_system_and_set: "EU 99.1",
                      equivalent_sizes: {
                        us: ["39BT"],
                        au: ["39BTAU"],
                        uk: ["39BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "101.6BTEU",
                      short: "101.6",
                      long: "101.6",
                      display: "101.6",
                      display_with_size_set: "101.6",
                      display_with_size_system: "EU 101.6",
                      display_with_system_and_set: "EU 101.6",
                      equivalent_sizes: {
                        us: ["40BT"],
                        au: ["40BTAU"],
                        uk: ["40BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "104.1BTEU",
                      short: "104.1",
                      long: "104.1",
                      display: "104.1",
                      display_with_size_set: "104.1",
                      display_with_size_system: "EU 104.1",
                      display_with_system_and_set: "EU 104.1",
                      equivalent_sizes: {
                        us: ["41BT"],
                        au: ["41BTAU"],
                        uk: ["41BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "106.7EU",
                      short: "106.7",
                      long: "106.7",
                      display: "106.7",
                      display_with_size_set: "106.7",
                      display_with_size_system: "EU 106.7",
                      display_with_system_and_set: "EU 106.7",
                      equivalent_sizes: {
                        us: ["42"],
                        au: ["42AU"],
                        uk: ["42UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "109.2EU",
                      short: "109.2",
                      long: "109.2",
                      display: "109.2",
                      display_with_size_set: "109.2",
                      display_with_size_system: "EU 109.2",
                      display_with_system_and_set: "EU 109.2",
                      equivalent_sizes: {
                        us: ["43"],
                        au: ["43AU"],
                        uk: ["43UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "111.8EU",
                      short: "111.8",
                      long: "111.8",
                      display: "111.8",
                      display_with_size_set: "111.8",
                      display_with_size_system: "EU 111.8",
                      display_with_system_and_set: "EU 111.8",
                      equivalent_sizes: {
                        us: ["44"],
                        au: ["44AU"],
                        uk: ["44UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "116.8EU",
                      short: "116.8",
                      long: "116.8",
                      display: "116.8",
                      display_with_size_set: "116.8",
                      display_with_size_system: "EU 116.8",
                      display_with_system_and_set: "EU 116.8",
                      equivalent_sizes: {
                        us: ["46"],
                        au: ["46AU"],
                        uk: ["46UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "121.9EU",
                      short: "121.9",
                      long: "121.9",
                      display: "121.9",
                      display_with_size_set: "121.9",
                      display_with_size_system: "EU 121.9",
                      display_with_system_and_set: "EU 121.9",
                      equivalent_sizes: {
                        us: ["48"],
                        au: ["48AU"],
                        uk: ["48UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "127.0EU",
                      short: "127.0",
                      long: "127.0",
                      display: "127.0",
                      display_with_size_set: "127.0",
                      display_with_size_system: "EU 127.0",
                      display_with_system_and_set: "EU 127.0",
                      equivalent_sizes: {
                        us: ["50"],
                        au: ["50AU"],
                        uk: ["50UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "132.1EU",
                      short: "132.1",
                      long: "132.1",
                      display: "132.1",
                      display_with_size_set: "132.1",
                      display_with_size_system: "EU 132.1",
                      display_with_system_and_set: "EU 132.1",
                      equivalent_sizes: {
                        us: ["52"],
                        au: ["52AU"],
                        uk: ["52UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "137.2EU",
                      short: "137.2",
                      long: "137.2",
                      display: "137.2",
                      display_with_size_set: "137.2",
                      display_with_size_system: "EU 137.2",
                      display_with_system_and_set: "EU 137.2",
                      equivalent_sizes: {
                        us: ["54"],
                        au: ["54AU"],
                        uk: ["54UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "142.2EU",
                      short: "142.2",
                      long: "142.2",
                      display: "142.2",
                      display_with_size_set: "142.2",
                      display_with_size_system: "EU 142.2",
                      display_with_system_and_set: "EU 142.2",
                      equivalent_sizes: {
                        us: ["56"],
                        au: ["56AU"],
                        uk: ["56UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "147.3EU",
                      short: "147.3",
                      long: "147.3",
                      display: "147.3",
                      display_with_size_set: "147.3",
                      display_with_size_system: "EU 147.3",
                      display_with_system_and_set: "EU 147.3",
                      equivalent_sizes: {
                        us: ["58"],
                        au: ["58AU"],
                        uk: ["58UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "152.4EU",
                      short: "152.4",
                      long: "152.4",
                      display: "152.4",
                      display_with_size_set: "152.4",
                      display_with_size_system: "EU 152.4",
                      display_with_system_and_set: "EU 152.4",
                      equivalent_sizes: {
                        us: ["60"],
                        au: ["60AU"],
                        uk: ["60UK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "36BTUK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["36BT"],
                        au: ["36BTAU"],
                        eu: ["91.4BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "37BTUK",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "UK 37",
                      display_with_system_and_set: "UK 37",
                      equivalent_sizes: {
                        us: ["37BT"],
                        au: ["37BTAU"],
                        eu: ["94BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38BTUK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["38BT"],
                        au: ["38BTUK"],
                        eu: ["96.5BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "39BTUK",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "UK 39",
                      display_with_system_and_set: "UK 39",
                      equivalent_sizes: {
                        us: ["39BT"],
                        au: ["39BTUK"],
                        eu: ["99.1BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40BTUK",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "UK 40",
                      display_with_system_and_set: "UK 40",
                      equivalent_sizes: {
                        us: ["40BT"],
                        au: ["40BTUK"],
                        eu: ["101.6BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "41BTUK",
                      short: "41",
                      long: "41",
                      display: "41",
                      display_with_size_set: "41",
                      display_with_size_system: "UK 41",
                      display_with_system_and_set: "UK 41",
                      equivalent_sizes: {
                        us: ["41BT"],
                        au: ["41BTUK"],
                        eu: ["104.1BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42UK",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "UK 42",
                      display_with_system_and_set: "UK 42",
                      equivalent_sizes: {
                        us: ["42"],
                        au: ["42AU"],
                        eu: ["106.7EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "43UK",
                      short: "43",
                      long: "43",
                      display: "43",
                      display_with_size_set: "43",
                      display_with_size_system: "UK 43",
                      display_with_system_and_set: "UK 43",
                      equivalent_sizes: {
                        us: ["43"],
                        au: ["43AU"],
                        eu: ["109.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44UK",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "UK 44",
                      display_with_system_and_set: "UK 44",
                      equivalent_sizes: {
                        us: ["44"],
                        au: ["44AU"],
                        eu: ["111.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46UK",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "UK 46",
                      display_with_system_and_set: "UK 46",
                      equivalent_sizes: {
                        us: ["46"],
                        au: ["46AU"],
                        eu: ["116.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48UK",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "UK 48",
                      display_with_system_and_set: "UK 48",
                      equivalent_sizes: {
                        us: ["48"],
                        au: ["48AU"],
                        eu: ["121.9EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "50UK",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "UK 50",
                      display_with_system_and_set: "UK 50",
                      equivalent_sizes: {
                        us: ["50"],
                        au: ["50AU"],
                        eu: ["127.0EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "52UK",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "UK 52",
                      display_with_system_and_set: "UK 52",
                      equivalent_sizes: {
                        us: ["52"],
                        au: ["52AU"],
                        eu: ["132.1EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "54UK",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "UK 54",
                      display_with_system_and_set: "UK 54",
                      equivalent_sizes: {
                        us: ["54"],
                        au: ["54AU"],
                        eu: ["137.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "56UK",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "UK 56",
                      display_with_system_and_set: "UK 56",
                      equivalent_sizes: {
                        us: ["56"],
                        au: ["56AU"],
                        eu: ["142.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "58UK",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "UK 58",
                      display_with_system_and_set: "UK 58",
                      equivalent_sizes: {
                        us: ["58"],
                        au: ["58AU"],
                        eu: ["147.3EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "60UK",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "UK 60",
                      display_with_system_and_set: "UK 60",
                      equivalent_sizes: {
                        us: ["60"],
                        au: ["60AU"],
                        eu: ["152.4EU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "men_jeans",
          aliases: [],
          slug: "Jeans",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
          category_features: [
            {
              id: "24009813d97b4e3995005764",
              display: "Bootcut",
              type: "cf",
              message_id: "men_jeans_bootcut",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bootcut",
              alias_slugs: [],
              parent_id: "05008c10d97b4e1245005764",
            },
            {
              id: "25009813d97b4e3995005764",
              display: "Relaxed",
              type: "cf",
              message_id: "men_jeans_relaxed",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Relaxed",
              alias_slugs: [],
              parent_id: "05008c10d97b4e1245005764",
            },
            {
              id: "29009813d97b4e3995005764",
              display: "Skinny",
              type: "cf",
              message_id: "men_jeans_skinny",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Skinny",
              alias_slugs: [],
              parent_id: "05008c10d97b4e1245005764",
            },
            {
              id: "28009813d97b4e3995005764",
              display: "Slim",
              type: "cf",
              message_id: "men_jeans_slim",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Slim",
              alias_slugs: [],
              parent_id: "05008c10d97b4e1245005764",
            },
            {
              id: "27009813d97b4e3995005764",
              display: "Slim Straight",
              type: "cf",
              message_id: "men_jeans_slim_straight",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Slim_Straight",
              alias_slugs: [],
              parent_id: "05008c10d97b4e1245005764",
            },
            {
              id: "26009813d97b4e3995005764",
              display: "Straight",
              type: "cf",
              message_id: "men_jeans_straight",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Straight",
              alias_slugs: [],
              parent_id: "05008c10d97b4e1245005764",
            },
          ],
        },
        {
          id: "06008c10d97b4e1245005764",
          display: "Pants",
          size_sets: [
            {
              id: "01009fc1d97b4e911e005769",
              name: "Standard",
              tags: ["standard"],
              category_id: "06008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Waist "],
                  ["28-29"],
                  ["30-31"],
                  ["32-35"],
                  ["36-38"],
                  ["39-40"],
                  ["41-42"],
                  ["43-44"],
                ],
                centimeters: [
                  ["Waist "],
                  ["71.1-73.7"],
                  ["76.2-78.7"],
                  ["81.3-88.9"],
                  ["91.4-96.5"],
                  ["99.1-101.6"],
                  ["104.1-106.7"],
                  ["109.2-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["Waist 28", "28", "71.12", "28"],
                ["Waist 29", "29", "73.66", "29"],
                ["Waist 30", "30", "76.2", "30"],
                ["Waist 31", "31", "78.8", "31"],
                ["Waist 32", "32", "81.3", "32"],
                ["Waist 33", "33", "83.8", "33"],
                ["Waist 34", "34", "86.4", "34"],
                ["Waist 35", "35", "88.9", "35"],
                ["Waist 36", "36", "91.4", "36"],
                ["Waist 37", "37", "94", "37"],
                ["Waist 38", "38", "96.5", "38"],
                ["Waist 39", "39", "99.1", "39"],
                ["Waist 40", "40", "101.6", "40"],
                ["Waist 41", "41", "104.1", "41"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "Waist 28",
                      long: "Waist 28",
                      display: "Waist 28",
                      display_with_size_set: "Waist 28",
                      display_with_size_system: "US Waist 28",
                      display_with_system_and_set: "US Waist 28",
                      equivalent_sizes: {
                        au: ["28AU"],
                        eu: ["71.12EU"],
                        uk: ["28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "29",
                      short: "Waist 29",
                      long: "Waist 29",
                      display: "Waist 29",
                      display_with_size_set: "Waist 29",
                      display_with_size_system: "US Waist 29",
                      display_with_system_and_set: "US Waist 29",
                      equivalent_sizes: {
                        au: ["29AU"],
                        eu: ["73.66EU"],
                        uk: ["29UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "Waist 30",
                      long: "Waist 30",
                      display: "Waist 30",
                      display_with_size_set: "Waist 30",
                      display_with_size_system: "US Waist 30",
                      display_with_system_and_set: "US Waist 30",
                      equivalent_sizes: {
                        au: ["30AU"],
                        eu: ["76.2EU"],
                        uk: ["30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "31",
                      short: "Waist 31",
                      long: "Waist 31",
                      display: "Waist 31",
                      display_with_size_set: "Waist 31",
                      display_with_size_system: "US Waist 31",
                      display_with_system_and_set: "US Waist 31",
                      equivalent_sizes: {
                        au: ["31AU"],
                        eu: ["78.8EU"],
                        uk: ["31UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "Waist 32",
                      long: "Waist 32",
                      display: "Waist 32",
                      display_with_size_set: "Waist 32",
                      display_with_size_system: "US Waist 32",
                      display_with_system_and_set: "US Waist 32",
                      equivalent_sizes: {
                        au: ["32AU"],
                        eu: ["81.3EU"],
                        uk: ["32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "33",
                      short: "Waist 33",
                      long: "Waist 33",
                      display: "Waist 33",
                      display_with_size_set: "Waist 33",
                      display_with_size_system: "US Waist 33",
                      display_with_system_and_set: "US Waist 33",
                      equivalent_sizes: {
                        au: ["33AU"],
                        eu: ["83.8EU"],
                        uk: ["33UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34",
                      short: "Waist 34",
                      long: "Waist 34",
                      display: "Waist 34",
                      display_with_size_set: "Waist 34",
                      display_with_size_system: "US Waist 34",
                      display_with_system_and_set: "US Waist 34",
                      equivalent_sizes: {
                        au: ["34AU"],
                        eu: ["86.4EU"],
                        uk: ["34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "35",
                      short: "Waist 35",
                      long: "Waist 35",
                      display: "Waist 35",
                      display_with_size_set: "Waist 35",
                      display_with_size_system: "US Waist 35",
                      display_with_system_and_set: "US Waist 35",
                      equivalent_sizes: {
                        au: ["35AU"],
                        eu: ["88.9EU"],
                        uk: ["35UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36",
                      short: "Waist 36",
                      long: "Waist 36",
                      display: "Waist 36",
                      display_with_size_set: "Waist 36",
                      display_with_size_system: "US Waist 36",
                      display_with_system_and_set: "US Waist 36",
                      equivalent_sizes: {
                        au: ["36AU"],
                        eu: ["91.4EU"],
                        uk: ["36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "37",
                      short: "Waist 37",
                      long: "Waist 37",
                      display: "Waist 37",
                      display_with_size_set: "Waist 37",
                      display_with_size_system: "US Waist 37",
                      display_with_system_and_set: "US Waist 37",
                      equivalent_sizes: {
                        au: ["37AU"],
                        eu: ["94EU"],
                        uk: ["37UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38",
                      short: "Waist 38",
                      long: "Waist 38",
                      display: "Waist 38",
                      display_with_size_set: "Waist 38",
                      display_with_size_system: "US Waist 38",
                      display_with_system_and_set: "US Waist 38",
                      equivalent_sizes: {
                        au: ["38AU"],
                        eu: ["96.5EU"],
                        uk: ["38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "39",
                      short: "Waist 39",
                      long: "Waist 39",
                      display: "Waist 39",
                      display_with_size_set: "Waist 39",
                      display_with_size_system: "US Waist 39",
                      display_with_system_and_set: "US Waist 39",
                      equivalent_sizes: {
                        au: ["39AU"],
                        eu: ["99.1EU"],
                        uk: ["39UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40",
                      short: "Waist 40",
                      long: "Waist 40",
                      display: "Waist 40",
                      display_with_size_set: "Waist 40",
                      display_with_size_system: "US Waist 40",
                      display_with_system_and_set: "US Waist 40",
                      equivalent_sizes: {
                        au: ["40AU"],
                        eu: ["101.6EU"],
                        uk: ["40UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "41",
                      short: "Waist 41",
                      long: "Waist 41",
                      display: "Waist 41",
                      display_with_size_set: "Waist 41",
                      display_with_size_system: "US Waist 41",
                      display_with_system_and_set: "US Waist 41",
                      equivalent_sizes: {
                        au: ["41AU"],
                        eu: ["104.1EU"],
                        uk: ["41UK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["28"],
                        eu: ["71.12EU"],
                        uk: ["28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "29AU",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "AU 29",
                      display_with_system_and_set: "AU 29",
                      equivalent_sizes: {
                        us: ["29"],
                        eu: ["73.66EU"],
                        uk: ["29UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["30"],
                        eu: ["76.2EU"],
                        uk: ["30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "31AU",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "AU 31",
                      display_with_system_and_set: "AU 31",
                      equivalent_sizes: {
                        us: ["31"],
                        eu: ["78.8EU"],
                        uk: ["31UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["32"],
                        eu: ["81.3EU"],
                        uk: ["32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "33AU",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "AU 33",
                      display_with_system_and_set: "AU 33",
                      equivalent_sizes: {
                        us: ["33"],
                        eu: ["83.8EU"],
                        uk: ["33UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["34"],
                        eu: ["86.4EU"],
                        uk: ["34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "35AU",
                      short: "35",
                      long: "35",
                      display: "35",
                      display_with_size_set: "35",
                      display_with_size_system: "AU 35",
                      display_with_system_and_set: "AU 35",
                      equivalent_sizes: {
                        us: ["35"],
                        eu: ["88.9EU"],
                        uk: ["35UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["36"],
                        eu: ["91.4EU"],
                        uk: ["36UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "37AU",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "AU 37",
                      display_with_system_and_set: "AU 37",
                      equivalent_sizes: {
                        us: ["37"],
                        eu: ["94EU"],
                        uk: ["37UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["38"],
                        eu: ["96.5EU"],
                        uk: ["38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "39AU",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "AU 39",
                      display_with_system_and_set: "AU 39",
                      equivalent_sizes: {
                        us: ["39"],
                        eu: ["99.1EU"],
                        uk: ["39UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "40AU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "AU 40",
                      display_with_system_and_set: "AU 40",
                      equivalent_sizes: {
                        us: ["40"],
                        eu: ["101.6EU"],
                        uk: ["40UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "41AU",
                      short: "41",
                      long: "41",
                      display: "41",
                      display_with_size_set: "41",
                      display_with_size_system: "AU 41",
                      display_with_system_and_set: "AU 41",
                      equivalent_sizes: {
                        us: ["41"],
                        eu: ["104.1EU"],
                        uk: ["41UK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "71.12EU",
                      short: "71.12",
                      long: "71.12",
                      display: "71.12",
                      display_with_size_set: "71.12",
                      display_with_size_system: "EU 71.12",
                      display_with_system_and_set: "EU 71.12",
                      equivalent_sizes: {
                        us: ["28"],
                        au: ["28AU"],
                        uk: ["28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "73.66EU",
                      short: "73.66",
                      long: "73.66",
                      display: "73.66",
                      display_with_size_set: "73.66",
                      display_with_size_system: "EU 73.66",
                      display_with_system_and_set: "EU 73.66",
                      equivalent_sizes: {
                        us: ["29"],
                        au: ["29AU"],
                        uk: ["29UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "76.2EU",
                      short: "76.2",
                      long: "76.2",
                      display: "76.2",
                      display_with_size_set: "76.2",
                      display_with_size_system: "EU 76.2",
                      display_with_system_and_set: "EU 76.2",
                      equivalent_sizes: {
                        us: ["30"],
                        au: ["30AU"],
                        uk: ["30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "78.8EU",
                      short: "78.8",
                      long: "78.8",
                      display: "78.8",
                      display_with_size_set: "78.8",
                      display_with_size_system: "EU 78.8",
                      display_with_system_and_set: "EU 78.8",
                      equivalent_sizes: {
                        us: ["31"],
                        au: ["31AU"],
                        uk: ["31UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "81.3EU",
                      short: "81.3",
                      long: "81.3",
                      display: "81.3",
                      display_with_size_set: "81.3",
                      display_with_size_system: "EU 81.3",
                      display_with_system_and_set: "EU 81.3",
                      equivalent_sizes: {
                        us: ["32"],
                        au: ["32AU"],
                        uk: ["32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "83.8EU",
                      short: "83.8",
                      long: "83.8",
                      display: "83.8",
                      display_with_size_set: "83.8",
                      display_with_size_system: "EU 83.8",
                      display_with_system_and_set: "EU 83.8",
                      equivalent_sizes: {
                        us: ["33"],
                        au: ["33AU"],
                        uk: ["33UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "86.4EU",
                      short: "86.4",
                      long: "86.4",
                      display: "86.4",
                      display_with_size_set: "86.4",
                      display_with_size_system: "EU 86.4",
                      display_with_system_and_set: "EU 86.4",
                      equivalent_sizes: {
                        us: ["34"],
                        au: ["34AU"],
                        uk: ["34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "88.9EU",
                      short: "88.9",
                      long: "88.9",
                      display: "88.9",
                      display_with_size_set: "88.9",
                      display_with_size_system: "EU 88.9",
                      display_with_system_and_set: "EU 88.9",
                      equivalent_sizes: {
                        us: ["35"],
                        au: ["35AU"],
                        uk: ["35UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "91.4EU",
                      short: "91.4",
                      long: "91.4",
                      display: "91.4",
                      display_with_size_set: "91.4",
                      display_with_size_system: "EU 91.4",
                      display_with_system_and_set: "EU 91.4",
                      equivalent_sizes: {
                        us: ["36"],
                        au: ["36AU"],
                        uk: ["36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "94EU",
                      short: "94",
                      long: "94",
                      display: "94",
                      display_with_size_set: "94",
                      display_with_size_system: "EU 94",
                      display_with_system_and_set: "EU 94",
                      equivalent_sizes: {
                        us: ["37"],
                        au: ["37AU"],
                        uk: ["37UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "96.5EU",
                      short: "96.5",
                      long: "96.5",
                      display: "96.5",
                      display_with_size_set: "96.5",
                      display_with_size_system: "EU 96.5",
                      display_with_system_and_set: "EU 96.5",
                      equivalent_sizes: {
                        us: ["38"],
                        au: ["38AU"],
                        uk: ["38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "99.1EU",
                      short: "99.1",
                      long: "99.1",
                      display: "99.1",
                      display_with_size_set: "99.1",
                      display_with_size_system: "EU 99.1",
                      display_with_system_and_set: "EU 99.1",
                      equivalent_sizes: {
                        us: ["39"],
                        au: ["39AU"],
                        uk: ["39UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "101.6EU",
                      short: "101.6",
                      long: "101.6",
                      display: "101.6",
                      display_with_size_set: "101.6",
                      display_with_size_system: "EU 101.6",
                      display_with_system_and_set: "EU 101.6",
                      equivalent_sizes: {
                        us: ["40"],
                        au: ["40AU"],
                        uk: ["40UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "104.1EU",
                      short: "104.1",
                      long: "104.1",
                      display: "104.1",
                      display_with_size_set: "104.1",
                      display_with_size_system: "EU 104.1",
                      display_with_system_and_set: "EU 104.1",
                      equivalent_sizes: {
                        us: ["41"],
                        au: ["41AU"],
                        uk: ["41UK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["28"],
                        au: ["28AU"],
                        eu: ["71.12EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "29UK",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "UK 29",
                      display_with_system_and_set: "UK 29",
                      equivalent_sizes: {
                        us: ["29"],
                        au: ["29AU"],
                        eu: ["73.66EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["30"],
                        au: ["30AU"],
                        eu: ["76.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "31UK",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "UK 31",
                      display_with_system_and_set: "UK 31",
                      equivalent_sizes: {
                        us: ["31"],
                        au: ["31AU"],
                        eu: ["78.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["32"],
                        au: ["32AU"],
                        eu: ["81.3EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "33UK",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "UK 33",
                      display_with_system_and_set: "UK 33",
                      equivalent_sizes: {
                        us: ["33"],
                        au: ["33AU"],
                        eu: ["83.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["34"],
                        au: ["34AU"],
                        eu: ["86.4EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "35UK",
                      short: "35",
                      long: "35",
                      display: "35",
                      display_with_size_set: "35",
                      display_with_size_system: "UK 35",
                      display_with_system_and_set: "UK 35",
                      equivalent_sizes: {
                        us: ["35"],
                        au: ["35AU"],
                        eu: ["88.9EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["36"],
                        au: ["36AU"],
                        eu: ["91.4EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "37UK",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "UK 37",
                      display_with_system_and_set: "UK 37",
                      equivalent_sizes: {
                        us: ["37"],
                        au: ["37AU"],
                        eu: ["94EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["38"],
                        au: ["38AU"],
                        eu: ["96.5EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "39UK",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "UK 39",
                      display_with_system_and_set: "UK 39",
                      equivalent_sizes: {
                        us: ["39"],
                        au: ["39AU"],
                        eu: ["99.1EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40UK",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "UK 40",
                      display_with_system_and_set: "UK 40",
                      equivalent_sizes: {
                        us: ["40"],
                        au: ["40AU"],
                        eu: ["101.6EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "41UK",
                      short: "41",
                      long: "41",
                      display: "41",
                      display_with_size_set: "41",
                      display_with_size_system: "UK 41",
                      display_with_system_and_set: "UK 41",
                      equivalent_sizes: {
                        us: ["41"],
                        au: ["41AU"],
                        eu: ["104.1EU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "0d1e4cd26058864a961862cf",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "06008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Waist "],
                  ["42-44"],
                  ["46-48"],
                  ["50-52"],
                  ["54-56"],
                  ["58-60"],
                  ["40-44"],
                  ["45-48.5"],
                  ["49-52"],
                  ["52-55"],
                  ["56-60"],
                  ["62-64"],
                ],
                centimeters: [
                  ["Waist "],
                  ["106.7-111.8"],
                  ["116.8-121.9"],
                  ["127-132.1"],
                  ["137.2-142.2"],
                  ["147.3-152.4"],
                  ["99.1-101.6"],
                  ["106.7-111.8"],
                  ["116.8-121.9"],
                  ["127-132.1"],
                  ["142.2-152.5"],
                  ["157.4-162.5"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["3XL", "3XL", "3XL", "3XL"],
                  ["4XL", "4XL", "4XL", "4XL"],
                  ["5XL", "5XL", "5XL", "5XL"],
                  ["6XL", "6XL", "6XL", "6XL"],
                  ["XLT", "XLT", "XLT", "XLT"],
                  ["3XLT", "3XLT", "3XLT", "3XLT"],
                  ["4XLT", "4XLT", "4XLT", "4XLT"],
                  ["5XLT", "5XLT", "5XLT", "5XLT"],
                  ["6XLT", "6XLT", "6XLT", "6XLT"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["Waist 36", "36", "91.4", "36"],
                ["Waist 37", "37", "94", "37"],
                ["Waist 38", "38", "96.5", "38"],
                ["Waist 39", "39", "99.1", "39"],
                ["Waist 40", "40", "101.6", "40"],
                ["Waist 42", "42", "106.7", "42"],
                ["Waist 43", "43", "109.2", "43"],
                ["Waist 44", "44", "111.8", "44"],
                ["Waist 46", "46", "116.8", "46"],
                ["Waist 48", "48", "121.9", "48"],
                ["Waist 50", "50", "127.0", "50"],
                ["Waist 52", "52", "132.1", "52"],
                ["Waist 54", "54", "137.2", "54"],
                ["Waist 56", "56", "142.2", "56"],
                ["Waist 58", "58", "147.3", "58"],
                ["Waist 60", "60", "152.4", "60"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["3XL", "3XL", "3XL", "3XL"],
                ["4XL", "4XL", "4XL", "4XL"],
                ["5XL", "5XL", "5XL", "5XL"],
                ["6XL", "6XL", "6XL", "6XL"],
                ["LT", "LT", "LT", "LT"],
                ["XLT", "XLT", "XLT", "XLT"],
                ["2XLT", "2XLT", "2XLT", "2XLT"],
                ["3XLT", "3XLT", "3XLT", "3XLT"],
                ["4XLT", "4XLT", "4XLT", "4XLT"],
                ["5XLT", "5XLT", "5XLT", "5XLT"],
                ["6XLT", "6XLT", "6XLT", "6XLT"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "36BT",
                      short: "Waist 36",
                      long: "Waist 36",
                      display: "Waist 36",
                      display_with_size_set: "Waist 36",
                      display_with_size_system: "US Waist 36",
                      display_with_system_and_set: "US Waist 36",
                      equivalent_sizes: {
                        au: ["36BTAU"],
                        eu: ["91.4BTEU"],
                        uk: ["36BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "37BT",
                      short: "Waist 37",
                      long: "Waist 37",
                      display: "Waist 37",
                      display_with_size_set: "Waist 37",
                      display_with_size_system: "US Waist 37",
                      display_with_system_and_set: "US Waist 37",
                      equivalent_sizes: {
                        au: ["37BTAU"],
                        eu: ["94BTEU"],
                        uk: ["37BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38BT",
                      short: "Waist 38",
                      long: "Waist 38",
                      display: "Waist 38",
                      display_with_size_set: "Waist 38",
                      display_with_size_system: "US Waist 38",
                      display_with_system_and_set: "US Waist 38",
                      equivalent_sizes: {
                        au: ["38BTAU"],
                        eu: ["96.5BTEU"],
                        uk: ["38BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "39BT",
                      short: "Waist 39",
                      long: "Waist 39",
                      display: "Waist 39",
                      display_with_size_set: "Waist 39",
                      display_with_size_system: "US Waist 39",
                      display_with_system_and_set: "US Waist 39",
                      equivalent_sizes: {
                        au: ["39BTAU"],
                        eu: ["99.1BTEU"],
                        uk: ["39BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40BT",
                      short: "Waist 40",
                      long: "Waist 40",
                      display: "Waist 40",
                      display_with_size_set: "Waist 40",
                      display_with_size_system: "US Waist 40",
                      display_with_system_and_set: "US Waist 40",
                      equivalent_sizes: {
                        au: ["40BTAU"],
                        eu: ["101.6BTEU"],
                        uk: ["40BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42",
                      short: "Waist 42",
                      long: "Waist 42",
                      display: "Waist 42",
                      display_with_size_set: "Waist 42",
                      display_with_size_system: "US Waist 42",
                      display_with_system_and_set: "US Waist 42",
                      equivalent_sizes: {
                        au: ["42AU"],
                        eu: ["106.7EU"],
                        uk: ["42UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "43",
                      short: "Waist 43",
                      long: "Waist 43",
                      display: "Waist 43",
                      display_with_size_set: "Waist 43",
                      display_with_size_system: "US Waist 43",
                      display_with_system_and_set: "US Waist 43",
                      equivalent_sizes: {
                        au: ["43AU"],
                        eu: ["109.2EU"],
                        uk: ["43UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44",
                      short: "Waist 44",
                      long: "Waist 44",
                      display: "Waist 44",
                      display_with_size_set: "Waist 44",
                      display_with_size_system: "US Waist 44",
                      display_with_system_and_set: "US Waist 44",
                      equivalent_sizes: {
                        au: ["44AU"],
                        eu: ["111.8EU"],
                        uk: ["44UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46",
                      short: "Waist 46",
                      long: "Waist 46",
                      display: "Waist 46",
                      display_with_size_set: "Waist 46",
                      display_with_size_system: "US Waist 46",
                      display_with_system_and_set: "US Waist 46",
                      equivalent_sizes: {
                        au: ["46AU"],
                        eu: ["116.8EU"],
                        uk: ["46UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48",
                      short: "Waist 48",
                      long: "Waist 48",
                      display: "Waist 48",
                      display_with_size_set: "Waist 48",
                      display_with_size_system: "US Waist 48",
                      display_with_system_and_set: "US Waist 48",
                      equivalent_sizes: {
                        au: ["48AU"],
                        eu: ["121.9EU"],
                        uk: ["48UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "50",
                      short: "Waist 50",
                      long: "Waist 50",
                      display: "Waist 50",
                      display_with_size_set: "Waist 50",
                      display_with_size_system: "US Waist 50",
                      display_with_system_and_set: "US Waist 50",
                      equivalent_sizes: {
                        au: ["50AU"],
                        eu: ["127.0EU"],
                        uk: ["50UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "52",
                      short: "Waist 52",
                      long: "Waist 52",
                      display: "Waist 52",
                      display_with_size_set: "Waist 52",
                      display_with_size_system: "US Waist 52",
                      display_with_system_and_set: "US Waist 52",
                      equivalent_sizes: {
                        au: ["52AU"],
                        eu: ["132.1EU"],
                        uk: ["52UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "54",
                      short: "Waist 54",
                      long: "Waist 54",
                      display: "Waist 54",
                      display_with_size_set: "Waist 54",
                      display_with_size_system: "US Waist 54",
                      display_with_system_and_set: "US Waist 54",
                      equivalent_sizes: {
                        au: ["54AU"],
                        eu: ["137.2EU"],
                        uk: ["54UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "56",
                      short: "Waist 56",
                      long: "Waist 56",
                      display: "Waist 56",
                      display_with_size_set: "Waist 56",
                      display_with_size_system: "US Waist 56",
                      display_with_system_and_set: "US Waist 56",
                      equivalent_sizes: {
                        au: ["56AU"],
                        eu: ["142.2EU"],
                        uk: ["56UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "58",
                      short: "Waist 58",
                      long: "Waist 58",
                      display: "Waist 58",
                      display_with_size_set: "Waist 58",
                      display_with_size_system: "US Waist 58",
                      display_with_system_and_set: "US Waist 58",
                      equivalent_sizes: {
                        au: ["58AU"],
                        eu: ["147.3EU"],
                        uk: ["58UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "60",
                      short: "Waist 60",
                      long: "Waist 60",
                      display: "Waist 60",
                      display_with_size_set: "Waist 60",
                      display_with_size_system: "US Waist 60",
                      display_with_system_and_set: "US Waist 60",
                      equivalent_sizes: {
                        au: ["60AU"],
                        eu: ["152.4EU"],
                        uk: ["60UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XL",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "US 3XL",
                      display_with_system_and_set: "US 3XL",
                      equivalent_sizes: {
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XL",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "US 4XL",
                      display_with_system_and_set: "US 4XL",
                      equivalent_sizes: {
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XL",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "US 5XL",
                      display_with_system_and_set: "US 5XL",
                      equivalent_sizes: {
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XL",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "US 6XL",
                      display_with_system_and_set: "US 6XL",
                      equivalent_sizes: {
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LT",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "US LT",
                      display_with_system_and_set: "US LT",
                      equivalent_sizes: {
                        au: ["LTAU"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLT",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "US XLT",
                      display_with_system_and_set: "US XLT",
                      equivalent_sizes: {
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XLT",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "US 2XLT",
                      display_with_system_and_set: "US 2XLT",
                      equivalent_sizes: {
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XLT",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "US 3XLT",
                      display_with_system_and_set: "US 3XLT",
                      equivalent_sizes: {
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XLT",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "US 4XLT",
                      display_with_system_and_set: "US 4XLT",
                      equivalent_sizes: {
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XLT",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "US 5XLT",
                      display_with_system_and_set: "US 5XLT",
                      equivalent_sizes: {
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XLT",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "US 6XLT",
                      display_with_system_and_set: "US 6XLT",
                      equivalent_sizes: {
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "36BTAU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["36BT"],
                        eu: ["91.4BTEU"],
                        uk: ["36BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "37BTAU",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "AU 37",
                      display_with_system_and_set: "AU 37",
                      equivalent_sizes: {
                        us: ["37BT"],
                        eu: ["94BTEU"],
                        uk: ["37BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38BTAU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["38BT"],
                        eu: ["96.5BTEU"],
                        uk: ["38BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "39BTAU",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "AU 39",
                      display_with_system_and_set: "AU 39",
                      equivalent_sizes: {
                        us: ["39BT"],
                        eu: ["99.1BTEU"],
                        uk: ["39BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "40BTAU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "AU 40",
                      display_with_system_and_set: "AU 40",
                      equivalent_sizes: {
                        us: ["40BT"],
                        eu: ["101.6BTEU"],
                        uk: ["40BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "42AU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "AU 42",
                      display_with_system_and_set: "AU 42",
                      equivalent_sizes: {
                        us: ["42"],
                        eu: ["106.7EU"],
                        uk: ["42UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "43AU",
                      short: "43",
                      long: "43",
                      display: "43",
                      display_with_size_set: "43",
                      display_with_size_system: "AU 43",
                      display_with_system_and_set: "AU 43",
                      equivalent_sizes: {
                        us: ["43"],
                        eu: ["109.2EU"],
                        uk: ["43UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "44AU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "AU 44",
                      display_with_system_and_set: "AU 44",
                      equivalent_sizes: {
                        us: ["44"],
                        eu: ["111.8EU"],
                        uk: ["44UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "46AU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "AU 46",
                      display_with_system_and_set: "AU 46",
                      equivalent_sizes: {
                        us: ["46"],
                        eu: ["116.8EU"],
                        uk: ["46UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "48AU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "AU 48",
                      display_with_system_and_set: "AU 48",
                      equivalent_sizes: {
                        us: ["48"],
                        eu: ["121.9EU"],
                        uk: ["48UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "50AU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "AU 50",
                      display_with_system_and_set: "AU 50",
                      equivalent_sizes: {
                        us: ["50"],
                        eu: ["127.0EU"],
                        uk: ["50UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "52AU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "AU 52",
                      display_with_system_and_set: "AU 52",
                      equivalent_sizes: {
                        us: ["52"],
                        eu: ["132.1EU"],
                        uk: ["52UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "54AU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "AU 54",
                      display_with_system_and_set: "AU 54",
                      equivalent_sizes: {
                        us: ["54"],
                        eu: ["137.2EU"],
                        uk: ["54UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "56AU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "AU 56",
                      display_with_system_and_set: "AU 56",
                      equivalent_sizes: {
                        us: ["56"],
                        eu: ["142.2EU"],
                        uk: ["56UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "58AU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "AU 58",
                      display_with_system_and_set: "AU 58",
                      equivalent_sizes: {
                        us: ["58"],
                        eu: ["147.3EU"],
                        uk: ["58UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "60AU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "AU 60",
                      display_with_system_and_set: "AU 60",
                      equivalent_sizes: {
                        us: ["60"],
                        eu: ["152.4EU"],
                        uk: ["60UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLAU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "AU 3XL",
                      display_with_system_and_set: "AU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLAU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "AU 4XL",
                      display_with_system_and_set: "AU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLAU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "AU 5XL",
                      display_with_system_and_set: "AU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLAU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "AU 6XL",
                      display_with_system_and_set: "AU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LTAU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "AU LT",
                      display_with_system_and_set: "AU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLTAU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "AU XLT",
                      display_with_system_and_set: "AU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XLTAU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "AU 2XLT",
                      display_with_system_and_set: "AU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLTAU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "AU 3XLT",
                      display_with_system_and_set: "AU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLTAU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "AU 4XLT",
                      display_with_system_and_set: "AU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLTAU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "AU 5XLT",
                      display_with_system_and_set: "AU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLTAU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "AU 6XLT",
                      display_with_system_and_set: "AU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "91.4BTEU",
                      short: "91.4",
                      long: "91.4",
                      display: "91.4",
                      display_with_size_set: "91.4",
                      display_with_size_system: "EU 91.4",
                      display_with_system_and_set: "EU 91.4",
                      equivalent_sizes: {
                        us: ["36BT"],
                        au: ["36BTAU"],
                        uk: ["36BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "94BTEU",
                      short: "94",
                      long: "94",
                      display: "94",
                      display_with_size_set: "94",
                      display_with_size_system: "EU 94",
                      display_with_system_and_set: "EU 94",
                      equivalent_sizes: {
                        us: ["37BT"],
                        au: ["37BTAU"],
                        uk: ["37BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "96.5BTEU",
                      short: "96.5",
                      long: "96.5",
                      display: "96.5",
                      display_with_size_set: "96.5",
                      display_with_size_system: "EU 96.5",
                      display_with_system_and_set: "EU 96.5",
                      equivalent_sizes: {
                        us: ["38BT"],
                        au: ["38BTAU"],
                        uk: ["38BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "99.1BTEU",
                      short: "99.1",
                      long: "99.1",
                      display: "99.1",
                      display_with_size_set: "99.1",
                      display_with_size_system: "EU 99.1",
                      display_with_system_and_set: "EU 99.1",
                      equivalent_sizes: {
                        us: ["39BT"],
                        au: ["39BTAU"],
                        uk: ["39BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "101.6BTEU",
                      short: "101.6",
                      long: "101.6",
                      display: "101.6",
                      display_with_size_set: "101.6",
                      display_with_size_system: "EU 101.6",
                      display_with_system_and_set: "EU 101.6",
                      equivalent_sizes: {
                        us: ["40BT"],
                        au: ["40BTAU"],
                        uk: ["40BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "106.7EU",
                      short: "106.7",
                      long: "106.7",
                      display: "106.7",
                      display_with_size_set: "106.7",
                      display_with_size_system: "EU 106.7",
                      display_with_system_and_set: "EU 106.7",
                      equivalent_sizes: {
                        us: ["42"],
                        au: ["42AU"],
                        uk: ["42UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "109.2EU",
                      short: "109.2",
                      long: "109.2",
                      display: "109.2",
                      display_with_size_set: "109.2",
                      display_with_size_system: "EU 109.2",
                      display_with_system_and_set: "EU 109.2",
                      equivalent_sizes: {
                        us: ["43"],
                        au: ["43AU"],
                        uk: ["43UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "111.8EU",
                      short: "111.8",
                      long: "111.8",
                      display: "111.8",
                      display_with_size_set: "111.8",
                      display_with_size_system: "EU 111.8",
                      display_with_system_and_set: "EU 111.8",
                      equivalent_sizes: {
                        us: ["44"],
                        au: ["44AU"],
                        uk: ["44UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "116.8EU",
                      short: "116.8",
                      long: "116.8",
                      display: "116.8",
                      display_with_size_set: "116.8",
                      display_with_size_system: "EU 116.8",
                      display_with_system_and_set: "EU 116.8",
                      equivalent_sizes: {
                        us: ["46"],
                        au: ["46AU"],
                        uk: ["46UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "121.9EU",
                      short: "121.9",
                      long: "121.9",
                      display: "121.9",
                      display_with_size_set: "121.9",
                      display_with_size_system: "EU 121.9",
                      display_with_system_and_set: "EU 121.9",
                      equivalent_sizes: {
                        us: ["48"],
                        au: ["48AU"],
                        uk: ["48UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "127.0EU",
                      short: "127.0",
                      long: "127.0",
                      display: "127.0",
                      display_with_size_set: "127.0",
                      display_with_size_system: "EU 127.0",
                      display_with_system_and_set: "EU 127.0",
                      equivalent_sizes: {
                        us: ["50"],
                        au: ["50AU"],
                        uk: ["50UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "132.1EU",
                      short: "132.1",
                      long: "132.1",
                      display: "132.1",
                      display_with_size_set: "132.1",
                      display_with_size_system: "EU 132.1",
                      display_with_system_and_set: "EU 132.1",
                      equivalent_sizes: {
                        us: ["52"],
                        au: ["52AU"],
                        uk: ["52UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "137.2EU",
                      short: "137.2",
                      long: "137.2",
                      display: "137.2",
                      display_with_size_set: "137.2",
                      display_with_size_system: "EU 137.2",
                      display_with_system_and_set: "EU 137.2",
                      equivalent_sizes: {
                        us: ["54"],
                        au: ["54AU"],
                        uk: ["54UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "142.2EU",
                      short: "142.2",
                      long: "142.2",
                      display: "142.2",
                      display_with_size_set: "142.2",
                      display_with_size_system: "EU 142.2",
                      display_with_system_and_set: "EU 142.2",
                      equivalent_sizes: {
                        us: ["56"],
                        au: ["56AU"],
                        uk: ["56UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "147.3EU",
                      short: "147.3",
                      long: "147.3",
                      display: "147.3",
                      display_with_size_set: "147.3",
                      display_with_size_system: "EU 147.3",
                      display_with_system_and_set: "EU 147.3",
                      equivalent_sizes: {
                        us: ["58"],
                        au: ["58AU"],
                        uk: ["58UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "152.4EU",
                      short: "152.4",
                      long: "152.4",
                      display: "152.4",
                      display_with_size_set: "152.4",
                      display_with_size_system: "EU 152.4",
                      display_with_system_and_set: "EU 152.4",
                      equivalent_sizes: {
                        us: ["60"],
                        au: ["60AU"],
                        uk: ["60UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLEU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "EU 3XL",
                      display_with_system_and_set: "EU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLEU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "EU 4XL",
                      display_with_system_and_set: "EU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLEU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "EU 5XL",
                      display_with_system_and_set: "EU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLEU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "EU 6XL",
                      display_with_system_and_set: "EU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LTEU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "EU LT",
                      display_with_system_and_set: "EU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        uk: ["LTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLTEU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "EU XLT",
                      display_with_system_and_set: "EU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XLTEU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "EU 2XLT",
                      display_with_system_and_set: "EU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLTEU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "EU 3XLT",
                      display_with_system_and_set: "EU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLTEU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "EU 4XLT",
                      display_with_system_and_set: "EU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLTEU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "EU 5XLT",
                      display_with_system_and_set: "EU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLTEU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "EU 6XLT",
                      display_with_system_and_set: "EU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "36BTUK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["36BT"],
                        au: ["36BTAU"],
                        eu: ["91.4BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "37BTUK",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "UK 37",
                      display_with_system_and_set: "UK 37",
                      equivalent_sizes: {
                        us: ["37BT"],
                        au: ["37BTAU"],
                        eu: ["94BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38BTUK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["38BT"],
                        au: ["38BTUK"],
                        eu: ["96.5BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "39BTUK",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "UK 39",
                      display_with_system_and_set: "UK 39",
                      equivalent_sizes: {
                        us: ["39BT"],
                        au: ["39BTUK"],
                        eu: ["99.1BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40BTUK",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "UK 40",
                      display_with_system_and_set: "UK 40",
                      equivalent_sizes: {
                        us: ["40BT"],
                        au: ["40BTUK"],
                        eu: ["101.6BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42UK",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "UK 42",
                      display_with_system_and_set: "UK 42",
                      equivalent_sizes: {
                        us: ["42"],
                        au: ["42AU"],
                        eu: ["106.7EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "43UK",
                      short: "43",
                      long: "43",
                      display: "43",
                      display_with_size_set: "43",
                      display_with_size_system: "UK 43",
                      display_with_system_and_set: "UK 43",
                      equivalent_sizes: {
                        us: ["43"],
                        au: ["43AU"],
                        eu: ["109.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44UK",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "UK 44",
                      display_with_system_and_set: "UK 44",
                      equivalent_sizes: {
                        us: ["44"],
                        au: ["44AU"],
                        eu: ["111.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46UK",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "UK 46",
                      display_with_system_and_set: "UK 46",
                      equivalent_sizes: {
                        us: ["46"],
                        au: ["46AU"],
                        eu: ["116.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48UK",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "UK 48",
                      display_with_system_and_set: "UK 48",
                      equivalent_sizes: {
                        us: ["48"],
                        au: ["48AU"],
                        eu: ["121.9EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "50UK",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "UK 50",
                      display_with_system_and_set: "UK 50",
                      equivalent_sizes: {
                        us: ["50"],
                        au: ["50AU"],
                        eu: ["127.0EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "52UK",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "UK 52",
                      display_with_system_and_set: "UK 52",
                      equivalent_sizes: {
                        us: ["52"],
                        au: ["52AU"],
                        eu: ["132.1EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "54UK",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "UK 54",
                      display_with_system_and_set: "UK 54",
                      equivalent_sizes: {
                        us: ["54"],
                        au: ["54AU"],
                        eu: ["137.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "56UK",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "UK 56",
                      display_with_system_and_set: "UK 56",
                      equivalent_sizes: {
                        us: ["56"],
                        au: ["56AU"],
                        eu: ["142.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "58UK",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "UK 58",
                      display_with_system_and_set: "UK 58",
                      equivalent_sizes: {
                        us: ["58"],
                        au: ["58AU"],
                        eu: ["147.3EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "60UK",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "UK 60",
                      display_with_system_and_set: "UK 60",
                      equivalent_sizes: {
                        us: ["60"],
                        au: ["60AU"],
                        eu: ["152.4EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLUK",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "UK 3XL",
                      display_with_system_and_set: "UK 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLUK",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "UK 4XL",
                      display_with_system_and_set: "UK 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLUK",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "UK 5XL",
                      display_with_system_and_set: "UK 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLUK",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "UK 6XL",
                      display_with_system_and_set: "UK 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LTUK",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "UK LT",
                      display_with_system_and_set: "UK LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        eu: ["LTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLTUK",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "UK XLT",
                      display_with_system_and_set: "UK XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XLTUK",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "UK 2XLT",
                      display_with_system_and_set: "UK 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLTUK",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "UK 3XLT",
                      display_with_system_and_set: "UK 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLTUK",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "UK 4XLT",
                      display_with_system_and_set: "UK 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLTUK",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "UK 5XLT",
                      display_with_system_and_set: "UK 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLTUK",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "UK 6XLT",
                      display_with_system_and_set: "UK 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "men_pants",
          aliases: [],
          slug: "Pants",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
          category_features: [
            {
              id: "2b009813d97b4e3995005764",
              display: "Cargo",
              type: "cf",
              message_id: "men_pants_cargo",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cargo",
              alias_slugs: [],
              parent_id: "06008c10d97b4e1245005764",
            },
            {
              id: "2a009813d97b4e3995005764",
              display: "Chinos & Khakis",
              type: "cf",
              message_id: "men_pants_chinos_khakis",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Chinos_&_Khakis",
              alias_slugs: [],
              parent_id: "06008c10d97b4e1245005764",
            },
            {
              id: "2c009813d97b4e3995005764",
              display: "Corduroy",
              type: "cf",
              message_id: "men_pants_corduroy",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Corduroy",
              alias_slugs: [],
              parent_id: "06008c10d97b4e1245005764",
            },
            {
              id: "2d009813d97b4e3995005764",
              display: "Dress",
              type: "cf",
              message_id: "men_pants_dress",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Dress",
              alias_slugs: [],
              parent_id: "06008c10d97b4e1245005764",
            },
            {
              id: "2f009813d97b4e3995005764",
              display: "Sweatpants & Joggers",
              type: "cf",
              message_id: "men_pants_sweatpants_joggers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sweatpants_&_Joggers",
              alias_slugs: [],
              parent_id: "06008c10d97b4e1245005764",
            },
          ],
        },
        {
          id: "07008c10d97b4e1245005764",
          display: "Shirts",
          size_sets: [
            {
              id: "03009fc1d97b4e911e005769",
              name: "Standard",
              tags: ["standard"],
              category_id: "07008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["39", "35"],
                  ["41", "37"],
                  ["43", "39"],
                  ["45", "41"],
                  ["47", "43"],
                  ["49", "45"],
                  ["51", "47"],
                  ["53", "49"],
                  ["55", "51"],
                  ["57", "53"],
                  ["59", "55"],
                  ["61", "57"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["99.1", "88.9"],
                  ["104.1", "94.0"],
                  ["109.2", "99.1"],
                  ["114.3", "104.1"],
                  ["119.4", "109.2"],
                  ["124.5", "114.3"],
                  ["129.5", "119.4"],
                  ["134.6", "124.5"],
                  ["139.7", "129.5"],
                  ["144.8", "134.6"],
                  ["149.9", "139.7"],
                  ["154.9", "144.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["Neck 14.5", "36.9", "36.9", "Neck 14.5"],
                  ["Neck 15", "38.1", "38.1", "Neck 15"],
                  ["Neck 15.5", "39.4", "39.4", "Neck 15.5"],
                  ["Neck 16", "40.6", "40.6", "Neck 16"],
                  ["Neck 16", "41.9", "41.9", "Neck 16.5"],
                  ["Neck 17", "43.2", "43.2", "Neck 17"],
                  ["Neck 17.5", "44.5", "44.5", "Neck 17.5"],
                  ["Neck 18", "45.8", "45.8", "Neck 18"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["Neck 14.5", "36.9", "36.9", "Neck 14.5"],
                ["Neck 15", "38.1", "38.1", "Neck 15"],
                ["Neck 15.5", "39.4", "39.4", "Neck 15.5"],
                ["Neck 16", "40.6", "40.6", "Neck 16"],
                ["Neck 16.5", "41.9", "41.9", "Neck 16.5"],
                ["Neck 17", "43.2", "43.2", "Neck 17"],
                ["Neck 17.5", "44.5", "44.5", "Neck 17.5"],
                ["Neck 18", "45.8", "45.8", "Neck 18"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14.5",
                      short: "Neck 14.5",
                      long: "Neck 14.5",
                      display: "Neck 14.5",
                      display_with_size_set: "Neck 14.5",
                      display_with_size_system: "US Neck 14.5",
                      display_with_system_and_set: "US Neck 14.5",
                      equivalent_sizes: {
                        au: ["36.9AU"],
                        eu: ["36.9EU"],
                        uk: ["14.5UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15",
                      short: "Neck 15",
                      long: "Neck 15",
                      display: "Neck 15",
                      display_with_size_set: "Neck 15",
                      display_with_size_system: "US Neck 15",
                      display_with_system_and_set: "US Neck 15",
                      equivalent_sizes: {
                        au: ["38.1AU"],
                        eu: ["38.1EU"],
                        uk: ["15UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "15.5",
                      short: "Neck 15.5",
                      long: "Neck 15.5",
                      display: "Neck 15.5",
                      display_with_size_set: "Neck 15.5",
                      display_with_size_system: "US Neck 15.5",
                      display_with_system_and_set: "US Neck 15.5",
                      equivalent_sizes: {
                        au: ["39.4AU"],
                        eu: ["39.4EU"],
                        uk: ["15.5UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16",
                      short: "Neck 16",
                      long: "Neck 16",
                      display: "Neck 16",
                      display_with_size_set: "Neck 16",
                      display_with_size_system: "US Neck 16",
                      display_with_system_and_set: "US Neck 16",
                      equivalent_sizes: {
                        au: ["40.6AU"],
                        eu: ["40.6EU"],
                        uk: ["16UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16.5",
                      short: "Neck 16.5",
                      long: "Neck 16.5",
                      display: "Neck 16.5",
                      display_with_size_set: "Neck 16.5",
                      display_with_size_system: "US Neck 16.5",
                      display_with_system_and_set: "US Neck 16.5",
                      equivalent_sizes: {
                        au: ["41.9AU"],
                        eu: ["41.9EU"],
                        uk: ["16.5UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17",
                      short: "Neck 17",
                      long: "Neck 17",
                      display: "Neck 17",
                      display_with_size_set: "Neck 17",
                      display_with_size_system: "US Neck 17",
                      display_with_system_and_set: "US Neck 17",
                      equivalent_sizes: {
                        au: ["43.2AU"],
                        eu: ["43.2EU"],
                        uk: ["17UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17.5",
                      short: "Neck 17.5",
                      long: "Neck 17.5",
                      display: "Neck 17.5",
                      display_with_size_set: "Neck 17.5",
                      display_with_size_system: "US Neck 17.5",
                      display_with_system_and_set: "US Neck 17.5",
                      equivalent_sizes: {
                        au: ["44.5AU"],
                        eu: ["44.5EU"],
                        uk: ["17.5UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18",
                      short: "Neck 18",
                      long: "Neck 18",
                      display: "Neck 18",
                      display_with_size_set: "Neck 18",
                      display_with_size_system: "US Neck 18",
                      display_with_system_and_set: "US Neck 18",
                      equivalent_sizes: {
                        au: ["45.8AU"],
                        eu: ["45.8EU"],
                        uk: ["18UK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36.9AU",
                      short: "36.9",
                      long: "36.9",
                      display: "36.9",
                      display_with_size_set: "36.9",
                      display_with_size_system: "AU 36.9",
                      display_with_system_and_set: "AU 36.9",
                      equivalent_sizes: {
                        us: ["14.5"],
                        eu: ["36.9EU"],
                        uk: ["14.5UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38.1AU",
                      short: "38.1",
                      long: "38.1",
                      display: "38.1",
                      display_with_size_set: "38.1",
                      display_with_size_system: "AU 38.1",
                      display_with_system_and_set: "AU 38.1",
                      equivalent_sizes: {
                        us: ["15"],
                        eu: ["38.1EU"],
                        uk: ["15UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "39.4AU",
                      short: "39.4",
                      long: "39.4",
                      display: "39.4",
                      display_with_size_set: "39.4",
                      display_with_size_system: "AU 39.4",
                      display_with_system_and_set: "AU 39.4",
                      equivalent_sizes: {
                        us: ["15.5"],
                        eu: ["39.4EU"],
                        uk: ["15.5UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "40.6AU",
                      short: "40.6",
                      long: "40.6",
                      display: "40.6",
                      display_with_size_set: "40.6",
                      display_with_size_system: "AU 40.6",
                      display_with_system_and_set: "AU 40.6",
                      equivalent_sizes: {
                        us: ["16"],
                        eu: ["40.6EU"],
                        uk: ["16UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "41.9AU",
                      short: "41.9",
                      long: "41.9",
                      display: "41.9",
                      display_with_size_set: "41.9",
                      display_with_size_system: "AU 41.9",
                      display_with_system_and_set: "AU 41.9",
                      equivalent_sizes: {
                        us: ["16.5"],
                        eu: ["41.9EU"],
                        uk: ["16.5UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "43.2AU",
                      short: "43.2",
                      long: "43.2",
                      display: "43.2",
                      display_with_size_set: "43.2",
                      display_with_size_system: "AU 43.2",
                      display_with_system_and_set: "AU 43.2",
                      equivalent_sizes: {
                        us: ["17"],
                        eu: ["43.2EU"],
                        uk: ["17UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "44.5AU",
                      short: "44.5",
                      long: "44.5",
                      display: "44.5",
                      display_with_size_set: "44.5",
                      display_with_size_system: "AU 44.5",
                      display_with_system_and_set: "AU 44.5",
                      equivalent_sizes: {
                        us: ["17.5"],
                        eu: ["44.5EU"],
                        uk: ["17.5UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "45.8AU",
                      short: "45.8",
                      long: "45.8",
                      display: "45.8",
                      display_with_size_set: "45.8",
                      display_with_size_system: "AU 45.8",
                      display_with_system_and_set: "AU 45.8",
                      equivalent_sizes: {
                        us: ["18"],
                        eu: ["45.8EU"],
                        uk: ["18UK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36.9EU",
                      short: "36.9",
                      long: "36.9",
                      display: "36.9",
                      display_with_size_set: "36.9",
                      display_with_size_system: "EU 36.9",
                      display_with_system_and_set: "EU 36.9",
                      equivalent_sizes: {
                        us: ["14.5"],
                        au: ["36.9AU"],
                        uk: ["14.5UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38.1EU",
                      short: "38.1",
                      long: "38.1",
                      display: "38.1",
                      display_with_size_set: "38.1",
                      display_with_size_system: "EU 38.1",
                      display_with_system_and_set: "EU 38.1",
                      equivalent_sizes: {
                        us: ["15"],
                        au: ["38.1AU"],
                        uk: ["15UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "39.4EU",
                      short: "39.4",
                      long: "39.4",
                      display: "39.4",
                      display_with_size_set: "39.4",
                      display_with_size_system: "EU 39.4",
                      display_with_system_and_set: "EU 39.4",
                      equivalent_sizes: {
                        us: ["15.5"],
                        au: ["39.4AU"],
                        uk: ["15.5UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "40.6EU",
                      short: "40.6",
                      long: "40.6",
                      display: "40.6",
                      display_with_size_set: "40.6",
                      display_with_size_system: "EU 40.6",
                      display_with_system_and_set: "EU 40.6",
                      equivalent_sizes: {
                        us: ["16"],
                        au: ["40.6AU"],
                        uk: ["16UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "41.9EU",
                      short: "41.9",
                      long: "41.9",
                      display: "41.9",
                      display_with_size_set: "41.9",
                      display_with_size_system: "EU 41.9",
                      display_with_system_and_set: "EU 41.9",
                      equivalent_sizes: {
                        us: ["16.5"],
                        au: ["41.9AU"],
                        uk: ["16.5UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "43.2EU",
                      short: "43.2",
                      long: "43.2",
                      display: "43.2",
                      display_with_size_set: "43.2",
                      display_with_size_system: "EU 43.2",
                      display_with_system_and_set: "EU 43.2",
                      equivalent_sizes: {
                        us: ["17"],
                        au: ["43.2AU"],
                        uk: ["17UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44.5EU",
                      short: "44.5",
                      long: "44.5",
                      display: "44.5",
                      display_with_size_set: "44.5",
                      display_with_size_system: "EU 44.5",
                      display_with_system_and_set: "EU 44.5",
                      equivalent_sizes: {
                        us: ["17.5"],
                        au: ["44.5AU"],
                        uk: ["17.5UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "45.8EU",
                      short: "45.8",
                      long: "45.8",
                      display: "45.8",
                      display_with_size_set: "45.8",
                      display_with_size_system: "EU 45.8",
                      display_with_system_and_set: "EU 45.8",
                      equivalent_sizes: {
                        us: ["18"],
                        au: ["45.8AU"],
                        uk: ["18UK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14.5UK",
                      short: "Neck 14.5",
                      long: "Neck 14.5",
                      display: "Neck 14.5",
                      display_with_size_set: "Neck 14.5",
                      display_with_size_system: "UK Neck 14.5",
                      display_with_system_and_set: "UK Neck 14.5",
                      equivalent_sizes: {
                        us: ["14.5"],
                        au: ["36.9AU"],
                        eu: ["36.9EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "15UK",
                      short: "Neck 15",
                      long: "Neck 15",
                      display: "Neck 15",
                      display_with_size_set: "Neck 15",
                      display_with_size_system: "UK Neck 15",
                      display_with_system_and_set: "UK Neck 15",
                      equivalent_sizes: {
                        us: ["15"],
                        au: ["38.1AU"],
                        eu: ["38.1EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "15.5UK",
                      short: "Neck 15.5",
                      long: "Neck 15.5",
                      display: "Neck 15.5",
                      display_with_size_set: "Neck 15.5",
                      display_with_size_system: "UK Neck 15.5",
                      display_with_system_and_set: "UK Neck 15.5",
                      equivalent_sizes: {
                        us: ["15.5"],
                        au: ["39.4AU"],
                        eu: ["39.4EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16UK",
                      short: "Neck 16",
                      long: "Neck 16",
                      display: "Neck 16",
                      display_with_size_set: "Neck 16",
                      display_with_size_system: "UK Neck 16",
                      display_with_system_and_set: "UK Neck 16",
                      equivalent_sizes: {
                        us: ["16"],
                        au: ["40.6AU"],
                        eu: ["40.6EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16.5UK",
                      short: "Neck 16.5",
                      long: "Neck 16.5",
                      display: "Neck 16.5",
                      display_with_size_set: "Neck 16.5",
                      display_with_size_system: "UK Neck 16.5",
                      display_with_system_and_set: "UK Neck 16.5",
                      equivalent_sizes: {
                        us: ["16"],
                        au: ["41.9AU"],
                        eu: ["41.9EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "17UK",
                      short: "Neck 17",
                      long: "Neck 17",
                      display: "Neck 17",
                      display_with_size_set: "Neck 17",
                      display_with_size_system: "UK Neck 17",
                      display_with_system_and_set: "UK Neck 17",
                      equivalent_sizes: {
                        us: ["17"],
                        au: ["43.2AU"],
                        eu: ["43.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "17.5UK",
                      short: "Neck 17.5",
                      long: "Neck 17.5",
                      display: "Neck 17.5",
                      display_with_size_set: "Neck 17.5",
                      display_with_size_system: "UK Neck 17.5",
                      display_with_system_and_set: "UK Neck 17.5",
                      equivalent_sizes: {
                        us: ["17.5"],
                        au: ["44.5AU"],
                        eu: ["44.5EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18UK",
                      short: "Neck 18",
                      long: "Neck 18",
                      display: "Neck 18",
                      display_with_size_set: "Neck 18",
                      display_with_size_system: "UK Neck 18",
                      display_with_system_and_set: "UK Neck 18",
                      equivalent_sizes: {
                        us: ["18"],
                        au: ["45.8AU"],
                        eu: ["45.8EU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "0e1e4cd26058864a961862cf",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "07008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Shoulder ", "Chest ", "Waist "],
                  ["21.5", "55", "51"],
                  ["22", "57", "53"],
                  ["22.5", "59", "55"],
                  ["23", "61", "57"],
                  ["23.5", "63", "59"],
                  ["24", "65", "61"],
                  ["24.5", "67", "63"],
                  ["25", "69", "65"],
                  ["25.5", "71", "67"],
                ],
                centimeters: [
                  ["Shoulder ", "Chest ", "Waist "],
                  ["54.6", "139.7", "129.5"],
                  ["55.9", "144.8", "134.6"],
                  ["57.2", "149.9", "139.7"],
                  ["58.4", "154.9", "144.8"],
                  ["59.7", "160.0", "149.9"],
                  ["61.0", "165.1", "154.9"],
                  ["62.2", "170.2", "160.0"],
                  ["63.5", "175.3", "165.1"],
                  ["64.8", "180.3", "170.2"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["Neck 18.5", "47", "47", "Neck 18.5"],
                  ["Neck 19", "48.3", "48.3", "Neck 19"],
                  ["Neck 19.5", "49.5", "49.5", "Neck 19.5"],
                  ["Neck 20", "50.8", "50.8", "Neck 20"],
                  ["Neck 20.5", "52.1", "52.1", "Neck 20.5"],
                  ["Neck 21", "53.3", "53.3", "Neck 21"],
                  ["Neck 21.5", "54.6", "54.6", "Neck 21.5"],
                  ["Neck 22", "55.9", "55.9", "Neck 22"],
                  ["Neck 22.5", "-", "-", "Neck 22.5"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["Neck 16.5", "41.9", "41.9", "Neck 16.5"],
                ["Neck 17", "43.2", "43.2", "Neck 17"],
                ["Neck 17.5", "44.5", "44.5", "Neck 17.5"],
                ["Neck 18", "45.8", "45.8", "Neck 18"],
                ["Neck 18.5", "47", "47", "Neck 18.5"],
                ["Neck 19", "48.3", "48.3", "Neck 19"],
                ["Neck 19.5", "49.5", "49.5", "Neck 19.5"],
                ["Neck 20", "50.8", "50.8", "Neck 20"],
                ["Neck 20.5", "52.1", "52.1", "Neck 20.5"],
                ["Neck 21", "53.3", "53.3", "Neck 21"],
                ["Neck 21.5", "54.6", "54.6", "Neck 21.5"],
                ["Neck 22", "55.9", "55.9", "Neck 22"],
                ["Neck 22.5", "57.5AU", "57.5EU", "Neck 22.5"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["3XL", "3XL", "3XL", "3XL"],
                ["4XL", "4XL", "4XL", "4XL"],
                ["5XL", "5XL", "5XL", "5XL"],
                ["6XL", "6XL", "6XL", "6XL"],
                ["LT", "LT", "LT", "LT"],
                ["XLT", "XLT", "XLT", "XLT"],
                ["2XLT", "2XLT", "2XLT", "2XLT"],
                ["3XLT", "3XLT", "3XLT", "3XLT"],
                ["4XLT", "4XLT", "4XLT", "4XLT"],
                ["5XLT", "5XLT", "5XLT", "5XLT"],
                ["6XLT", "6XLT", "6XLT", "6XLT"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "16.5BT",
                      short: "Neck 16.5",
                      long: "Neck 16.5",
                      display: "Neck 16.5",
                      display_with_size_set: "Neck 16.5",
                      display_with_size_system: "US Neck 16.5",
                      display_with_system_and_set: "US Neck 16.5",
                      equivalent_sizes: {
                        au: ["41.9BTAU"],
                        eu: ["41.9BTEU"],
                        uk: ["16.5BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17BT",
                      short: "Neck 17",
                      long: "Neck 17",
                      display: "Neck 17",
                      display_with_size_set: "Neck 17",
                      display_with_size_system: "US Neck 17",
                      display_with_system_and_set: "US Neck 17",
                      equivalent_sizes: {
                        au: ["43.2BTAU"],
                        eu: ["43.2BTEU"],
                        uk: ["17BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "17.5BT",
                      short: "Neck 17.5",
                      long: "Neck 17.5",
                      display: "Neck 17.5",
                      display_with_size_set: "Neck 17.5",
                      display_with_size_system: "US Neck 17.5",
                      display_with_system_and_set: "US Neck 17.5",
                      equivalent_sizes: {
                        au: ["44.5BTAU"],
                        eu: ["44.5BTEU"],
                        uk: ["17.5BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18BT",
                      short: "Neck 18",
                      long: "Neck 18",
                      display: "Neck 18",
                      display_with_size_set: "Neck 18",
                      display_with_size_system: "US Neck 18",
                      display_with_system_and_set: "US Neck 18",
                      equivalent_sizes: {
                        au: ["45.8BTAU"],
                        eu: ["45.8BTEU"],
                        uk: ["18BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18.5",
                      short: "Neck 18.5",
                      long: "Neck 18.5",
                      display: "Neck 18.5",
                      display_with_size_set: "Neck 18.5",
                      display_with_size_system: "US Neck 18.5",
                      display_with_system_and_set: "US Neck 18.5",
                      equivalent_sizes: {
                        au: ["47AU"],
                        eu: ["47EU"],
                        uk: ["18.5UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "19",
                      short: "Neck 19",
                      long: "Neck 19",
                      display: "Neck 19",
                      display_with_size_set: "Neck 19",
                      display_with_size_system: "US Neck 19",
                      display_with_system_and_set: "US Neck 19",
                      equivalent_sizes: {
                        au: ["48.3AU"],
                        eu: ["48.3EU"],
                        uk: ["19UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "19.5",
                      short: "Neck 19.5",
                      long: "Neck 19.5",
                      display: "Neck 19.5",
                      display_with_size_set: "Neck 19.5",
                      display_with_size_system: "US Neck 19.5",
                      display_with_system_and_set: "US Neck 19.5",
                      equivalent_sizes: {
                        au: ["49.5AU"],
                        eu: ["49.5EU"],
                        uk: ["19.5UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20",
                      short: "Neck 20",
                      long: "Neck 20",
                      display: "Neck 20",
                      display_with_size_set: "Neck 20",
                      display_with_size_system: "US Neck 20",
                      display_with_system_and_set: "US Neck 20",
                      equivalent_sizes: {
                        au: ["50.8AU"],
                        eu: ["50.8EU"],
                        uk: ["20UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20.5",
                      short: "Neck 20.5",
                      long: "Neck 20.5",
                      display: "Neck 20.5",
                      display_with_size_set: "Neck 20.5",
                      display_with_size_system: "US Neck 20.5",
                      display_with_system_and_set: "US Neck 20.5",
                      equivalent_sizes: {
                        au: ["52.1AU"],
                        eu: ["52.1EU"],
                        uk: ["20.5UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "21",
                      short: "Neck 21",
                      long: "Neck 21",
                      display: "Neck 21",
                      display_with_size_set: "Neck 21",
                      display_with_size_system: "US Neck 21",
                      display_with_system_and_set: "US Neck 21",
                      equivalent_sizes: {
                        au: ["53.3AU"],
                        eu: ["53.3EU"],
                        uk: ["21UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "21.5",
                      short: "Neck 21.5",
                      long: "Neck 21.5",
                      display: "Neck 21.5",
                      display_with_size_set: "Neck 21.5",
                      display_with_size_system: "US Neck 21.5",
                      display_with_system_and_set: "US Neck 21.5",
                      equivalent_sizes: {
                        au: ["54.6AU"],
                        eu: ["54.6EU"],
                        uk: ["21.5UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22",
                      short: "Neck 22",
                      long: "Neck 22",
                      display: "Neck 22",
                      display_with_size_set: "Neck 22",
                      display_with_size_system: "US Neck 22",
                      display_with_system_and_set: "US Neck 22",
                      equivalent_sizes: {
                        au: ["55.9AU"],
                        eu: ["55.9EU"],
                        uk: ["22UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "22.5",
                      short: "Neck 22.5",
                      long: "Neck 22.5",
                      display: "Neck 22.5",
                      display_with_size_set: "Neck 22.5",
                      display_with_size_system: "US Neck 22.5",
                      display_with_system_and_set: "US Neck 22.5",
                      equivalent_sizes: {
                        au: ["57.5AU"],
                        eu: ["57.5EU"],
                        uk: ["22.5UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XL",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "US 3XL",
                      display_with_system_and_set: "US 3XL",
                      equivalent_sizes: {
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XL",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "US 4XL",
                      display_with_system_and_set: "US 4XL",
                      equivalent_sizes: {
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XL",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "US 5XL",
                      display_with_system_and_set: "US 5XL",
                      equivalent_sizes: {
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XL",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "US 6XL",
                      display_with_system_and_set: "US 6XL",
                      equivalent_sizes: {
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LT",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "US LT",
                      display_with_system_and_set: "US LT",
                      equivalent_sizes: {
                        au: ["LTAU"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLT",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "US XLT",
                      display_with_system_and_set: "US XLT",
                      equivalent_sizes: {
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XLT",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "US 2XLT",
                      display_with_system_and_set: "US 2XLT",
                      equivalent_sizes: {
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XLT",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "US 3XLT",
                      display_with_system_and_set: "US 3XLT",
                      equivalent_sizes: {
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XLT",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "US 4XLT",
                      display_with_system_and_set: "US 4XLT",
                      equivalent_sizes: {
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XLT",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "US 5XLT",
                      display_with_system_and_set: "US 5XLT",
                      equivalent_sizes: {
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XLT",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "US 6XLT",
                      display_with_system_and_set: "US 6XLT",
                      equivalent_sizes: {
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "41.9BTAU",
                      short: "41.9",
                      long: "41.9",
                      display: "41.9",
                      display_with_size_set: "41.9",
                      display_with_size_system: "AU 41.9",
                      display_with_system_and_set: "AU 41.9",
                      equivalent_sizes: {
                        us: ["16.5BT"],
                        eu: ["41.9BTEU"],
                        uk: ["16.5BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "43.2BTAU",
                      short: "43.2",
                      long: "43.2",
                      display: "43.2",
                      display_with_size_set: "43.2",
                      display_with_size_system: "AU 43.2",
                      display_with_system_and_set: "AU 43.2",
                      equivalent_sizes: {
                        us: ["17BT"],
                        eu: ["43.2BTEU"],
                        uk: ["17BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "44.5BTAU",
                      short: "44.5",
                      long: "44.5",
                      display: "44.5",
                      display_with_size_set: "44.5",
                      display_with_size_system: "AU 44.5",
                      display_with_system_and_set: "AU 44.5",
                      equivalent_sizes: {
                        us: ["17.5BT"],
                        eu: ["44.5BTEU"],
                        uk: ["17.5BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "45.8BTAU",
                      short: "45.8",
                      long: "45.8",
                      display: "45.8",
                      display_with_size_set: "45.8",
                      display_with_size_system: "AU 45.8",
                      display_with_system_and_set: "AU 45.8",
                      equivalent_sizes: {
                        us: ["18BT"],
                        eu: ["45.8BTEU"],
                        uk: ["18BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "47AU",
                      short: "47",
                      long: "47",
                      display: "47",
                      display_with_size_set: "47",
                      display_with_size_system: "AU 47",
                      display_with_system_and_set: "AU 47",
                      equivalent_sizes: {
                        us: ["18.5"],
                        eu: ["47EU"],
                        uk: ["18.5UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "48.3AU",
                      short: "48.3",
                      long: "48.3",
                      display: "48.3",
                      display_with_size_set: "48.3",
                      display_with_size_system: "AU 48.3",
                      display_with_system_and_set: "AU 48.3",
                      equivalent_sizes: {
                        us: ["19"],
                        eu: ["48.3EU"],
                        uk: ["19UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "49.5AU",
                      short: "49.5",
                      long: "49.5",
                      display: "49.5",
                      display_with_size_set: "49.5",
                      display_with_size_system: "AU 49.5",
                      display_with_system_and_set: "AU 49.5",
                      equivalent_sizes: {
                        us: ["19.5"],
                        eu: ["49.5EU"],
                        uk: ["19.5UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "50.8AU",
                      short: "50.8",
                      long: "50.8",
                      display: "50.8",
                      display_with_size_set: "50.8",
                      display_with_size_system: "AU 50.8",
                      display_with_system_and_set: "AU 50.8",
                      equivalent_sizes: {
                        us: ["20"],
                        eu: ["50.8EU"],
                        uk: ["20UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "52.1AU",
                      short: "52.1",
                      long: "52.1",
                      display: "52.1",
                      display_with_size_set: "52.1",
                      display_with_size_system: "AU 52.1",
                      display_with_system_and_set: "AU 52.1",
                      equivalent_sizes: {
                        us: ["20.5"],
                        eu: ["52.1EU"],
                        uk: ["20.5UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "53.3AU",
                      short: "53.3",
                      long: "53.3",
                      display: "53.3",
                      display_with_size_set: "53.3",
                      display_with_size_system: "AU 53.3",
                      display_with_system_and_set: "AU 53.3",
                      equivalent_sizes: {
                        us: ["21"],
                        eu: ["53.3EU"],
                        uk: ["21UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "54.6AU",
                      short: "54.6",
                      long: "54.6",
                      display: "54.6",
                      display_with_size_set: "54.6",
                      display_with_size_system: "AU 54.6",
                      display_with_system_and_set: "AU 54.6",
                      equivalent_sizes: {
                        us: ["21.5"],
                        eu: ["54.6EU"],
                        uk: ["21.5UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "55.9AU",
                      short: "55.9",
                      long: "55.9",
                      display: "55.9",
                      display_with_size_set: "55.9",
                      display_with_size_system: "AU 55.9",
                      display_with_system_and_set: "AU 55.9",
                      equivalent_sizes: {
                        us: ["22"],
                        eu: ["55.9EU"],
                        uk: ["22UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "57.2AU",
                      short: "57.2",
                      long: "57.2",
                      display: "57.2",
                      display_with_size_set: "57.2",
                      display_with_size_system: "AU 57.2",
                      display_with_system_and_set: "AU 57.2",
                      equivalent_sizes: {
                        us: ["22.5"],
                        eu: ["57.2EU"],
                        uk: ["22.5UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLAU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "AU 3XL",
                      display_with_system_and_set: "AU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLAU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "AU 4XL",
                      display_with_system_and_set: "AU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLAU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "AU 5XL",
                      display_with_system_and_set: "AU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLAU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "AU 6XL",
                      display_with_system_and_set: "AU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LTAU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "AU LT",
                      display_with_system_and_set: "AU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLTAU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "AU XLT",
                      display_with_system_and_set: "AU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XLTAU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "AU 2XLT",
                      display_with_system_and_set: "AU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLTAU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "AU 3XLT",
                      display_with_system_and_set: "AU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLTAU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "AU 4XLT",
                      display_with_system_and_set: "AU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLTAU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "AU 5XLT",
                      display_with_system_and_set: "AU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLTAU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "AU 6XLT",
                      display_with_system_and_set: "AU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "41.9BTEU",
                      short: "41.9",
                      long: "41.9",
                      display: "41.9",
                      display_with_size_set: "41.9",
                      display_with_size_system: "EU 41.9",
                      display_with_system_and_set: "EU 41.9",
                      equivalent_sizes: {
                        us: ["16.5BT"],
                        au: ["41.9BTAU"],
                        uk: ["16.5BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "43.2BTEU",
                      short: "43.2",
                      long: "43.2",
                      display: "43.2",
                      display_with_size_set: "43.2",
                      display_with_size_system: "EU 43.2",
                      display_with_system_and_set: "EU 43.2",
                      equivalent_sizes: {
                        us: ["17BT"],
                        au: ["43.2BTAU"],
                        uk: ["17BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44.5BTEU",
                      short: "44.5",
                      long: "44.5",
                      display: "44.5",
                      display_with_size_set: "44.5",
                      display_with_size_system: "EU 44.5",
                      display_with_system_and_set: "EU 44.5",
                      equivalent_sizes: {
                        us: ["17.5BT"],
                        au: ["44.5BTAU"],
                        uk: ["17.5BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "45.8BTEU",
                      short: "45.8",
                      long: "45.8",
                      display: "45.8",
                      display_with_size_set: "45.8",
                      display_with_size_system: "EU 45.8",
                      display_with_system_and_set: "EU 45.8",
                      equivalent_sizes: {
                        us: ["18BT"],
                        au: ["45.8BTAU"],
                        uk: ["18BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "47EU",
                      short: "47",
                      long: "47",
                      display: "47",
                      display_with_size_set: "47",
                      display_with_size_system: "EU 47",
                      display_with_system_and_set: "EU 47",
                      equivalent_sizes: {
                        us: ["18.5"],
                        au: ["47AU"],
                        uk: ["18.5UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48.3EU",
                      short: "48.3",
                      long: "48.3",
                      display: "48.3",
                      display_with_size_set: "48.3",
                      display_with_size_system: "EU 48.3",
                      display_with_system_and_set: "EU 48.3",
                      equivalent_sizes: {
                        us: ["19"],
                        au: ["48.3AU"],
                        uk: ["19UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "49.5EU",
                      short: "49.5",
                      long: "49.5",
                      display: "49.5",
                      display_with_size_set: "49.5",
                      display_with_size_system: "EU 49.5",
                      display_with_system_and_set: "EU 49.5",
                      equivalent_sizes: {
                        us: ["19.5"],
                        au: ["49.5AU"],
                        uk: ["19.5UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50.8EU",
                      short: "50.8",
                      long: "50.8",
                      display: "50.8",
                      display_with_size_set: "50.8",
                      display_with_size_system: "EU 50.8",
                      display_with_system_and_set: "EU 50.8",
                      equivalent_sizes: {
                        us: ["20"],
                        au: ["50.8AU"],
                        uk: ["20UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52.1EU",
                      short: "52.1",
                      long: "52.1",
                      display: "52.1",
                      display_with_size_set: "52.1",
                      display_with_size_system: "EU 52.1",
                      display_with_system_and_set: "EU 52.1",
                      equivalent_sizes: {
                        us: ["20.5"],
                        au: ["52.1AU"],
                        uk: ["20.5UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "53.3EU",
                      short: "53.3",
                      long: "53.3",
                      display: "53.3",
                      display_with_size_set: "53.3",
                      display_with_size_system: "EU 53.3",
                      display_with_system_and_set: "EU 53.3",
                      equivalent_sizes: {
                        us: ["21"],
                        au: ["53.3AU"],
                        uk: ["21UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54.6EU",
                      short: "54.6",
                      long: "54.6",
                      display: "54.6",
                      display_with_size_set: "54.6",
                      display_with_size_system: "EU 54.6",
                      display_with_system_and_set: "EU 54.6",
                      equivalent_sizes: {
                        us: ["21.5"],
                        au: ["54.6AU"],
                        uk: ["21.5UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "55.9EU",
                      short: "55.9",
                      long: "55.9",
                      display: "55.9",
                      display_with_size_set: "55.9",
                      display_with_size_system: "EU 55.9",
                      display_with_system_and_set: "EU 55.9",
                      equivalent_sizes: {
                        us: ["22"],
                        au: ["55.9AU"],
                        uk: ["22UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "57.2EU",
                      short: "57.2",
                      long: "57.2",
                      display: "57.2",
                      display_with_size_set: "57.2",
                      display_with_size_system: "EU 57.2",
                      display_with_system_and_set: "EU 57.2",
                      equivalent_sizes: {
                        us: ["22.5"],
                        au: ["57.2AU"],
                        uk: ["22.5UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLEU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "EU 3XL",
                      display_with_system_and_set: "EU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLEU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "EU 4XL",
                      display_with_system_and_set: "EU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLEU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "EU 5XL",
                      display_with_system_and_set: "EU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLEU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "EU 6XL",
                      display_with_system_and_set: "EU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LTEU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "EU LT",
                      display_with_system_and_set: "EU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        uk: ["LTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLTEU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "EU XLT",
                      display_with_system_and_set: "EU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XLTEU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "EU 2XLT",
                      display_with_system_and_set: "EU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLTEU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "EU 3XLT",
                      display_with_system_and_set: "EU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLTEU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "EU 4XLT",
                      display_with_system_and_set: "EU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLTEU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "EU 5XLT",
                      display_with_system_and_set: "EU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLTEU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "EU 6XLT",
                      display_with_system_and_set: "EU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "16.5BTUK",
                      short: "Neck 16.5",
                      long: "Neck 16.5",
                      display: "Neck 16.5",
                      display_with_size_set: "Neck 16.5",
                      display_with_size_system: "UK Neck 16.5",
                      display_with_system_and_set: "UK Neck 16.5",
                      equivalent_sizes: {
                        us: ["16.5BT"],
                        au: ["41.9BTAU"],
                        eu: ["41.9BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "17BTUK",
                      short: "Neck 17",
                      long: "Neck 17",
                      display: "Neck 17",
                      display_with_size_set: "Neck 17",
                      display_with_size_system: "UK Neck 17",
                      display_with_system_and_set: "UK Neck 17",
                      equivalent_sizes: {
                        us: ["17BT"],
                        au: ["43.2BTAU"],
                        eu: ["43.2BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "17.5BTUK",
                      short: "Neck 17.5",
                      long: "Neck 17.5",
                      display: "Neck 17.5",
                      display_with_size_set: "Neck 17.5",
                      display_with_size_system: "UK Neck 17.5",
                      display_with_system_and_set: "UK Neck 17.5",
                      equivalent_sizes: {
                        us: ["17.5BT"],
                        au: ["44.5BTAU"],
                        eu: ["44.5BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18BTUK",
                      short: "Neck 18",
                      long: "Neck 18",
                      display: "Neck 18",
                      display_with_size_set: "Neck 18",
                      display_with_size_system: "UK Neck 18",
                      display_with_system_and_set: "UK Neck 18",
                      equivalent_sizes: {
                        us: ["18BT"],
                        au: ["45.8BTAU"],
                        eu: ["45.8BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18.5UK",
                      short: "Neck 18.5",
                      long: "Neck 18.5",
                      display: "Neck 18.5",
                      display_with_size_set: "Neck 18.5",
                      display_with_size_system: "UK Neck 18.5",
                      display_with_system_and_set: "UK Neck 18.5",
                      equivalent_sizes: {
                        us: ["18.5"],
                        au: ["47AU"],
                        eu: ["47EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "19UK",
                      short: "Neck 19",
                      long: "Neck 19",
                      display: "Neck 19",
                      display_with_size_set: "Neck 19",
                      display_with_size_system: "UK Neck 19",
                      display_with_system_and_set: "UK Neck 19",
                      equivalent_sizes: {
                        us: ["19"],
                        au: ["48.3AU"],
                        eu: ["48.3EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "19.5UK",
                      short: "Neck 19.5",
                      long: "Neck 19.5",
                      display: "Neck 19.5",
                      display_with_size_set: "Neck 19.5",
                      display_with_size_system: "UK Neck 19.5",
                      display_with_system_and_set: "UK Neck 19.5",
                      equivalent_sizes: {
                        us: ["19.5"],
                        au: ["49.5AU"],
                        eu: ["49.5EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20UK",
                      short: "Neck 20",
                      long: "Neck 20",
                      display: "Neck 20",
                      display_with_size_set: "Neck 20",
                      display_with_size_system: "UK Neck 20",
                      display_with_system_and_set: "UK Neck 20",
                      equivalent_sizes: {
                        us: ["20"],
                        au: ["50.8AU"],
                        eu: ["50.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20.5UK",
                      short: "Neck 20.5",
                      long: "Neck 20.5",
                      display: "Neck 20.5",
                      display_with_size_set: "Neck 20.5",
                      display_with_size_system: "UK Neck 20.5",
                      display_with_system_and_set: "UK Neck 20.5",
                      equivalent_sizes: {
                        us: ["20.5"],
                        au: ["52.1AU"],
                        eu: ["52.1EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "21UK",
                      short: "Neck 21",
                      long: "Neck 21",
                      display: "Neck 21",
                      display_with_size_set: "Neck 21",
                      display_with_size_system: "UK Neck 21",
                      display_with_system_and_set: "UK Neck 21",
                      equivalent_sizes: {
                        us: ["21"],
                        au: ["53.3AU"],
                        eu: ["53.3EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "21.5UK",
                      short: "Neck 21.5",
                      long: "Neck 21.5",
                      display: "Neck 21.5",
                      display_with_size_set: "Neck 21.5",
                      display_with_size_system: "UK Neck 21.5",
                      display_with_system_and_set: "UK Neck 21.5",
                      equivalent_sizes: {
                        us: ["21.5"],
                        au: ["54.6AU"],
                        eu: ["54.6EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22UK",
                      short: "Neck 22",
                      long: "Neck 22",
                      display: "Neck 22",
                      display_with_size_set: "Neck 22",
                      display_with_size_system: "UK Neck 22",
                      display_with_system_and_set: "UK Neck 22",
                      equivalent_sizes: {
                        us: ["22"],
                        au: ["55.9AU"],
                        eu: ["55.9EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "22.5UK",
                      short: "Neck 22.5",
                      long: "Neck 22.5",
                      display: "Neck 22.5",
                      display_with_size_set: "Neck 22.5",
                      display_with_size_system: "UK Neck 22.5",
                      display_with_system_and_set: "UK Neck 22.5",
                      equivalent_sizes: {
                        us: ["22.5"],
                        au: ["57.5AU"],
                        eu: ["57.5EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLUK",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "UK 3XL",
                      display_with_system_and_set: "UK 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLUK",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "UK 4XL",
                      display_with_system_and_set: "UK 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLUK",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "UK 5XL",
                      display_with_system_and_set: "UK 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLUK",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "UK 6XL",
                      display_with_system_and_set: "UK 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LTUK",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "UK LT",
                      display_with_system_and_set: "UK LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        eu: ["LTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLTUK",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "UK XLT",
                      display_with_system_and_set: "UK XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XLTUK",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "UK 2XLT",
                      display_with_system_and_set: "UK 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLTUK",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "UK 3XLT",
                      display_with_system_and_set: "UK 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLTUK",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "UK 4XLT",
                      display_with_system_and_set: "UK 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLTUK",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "UK 5XLT",
                      display_with_system_and_set: "UK 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLTUK",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "UK 6XLT",
                      display_with_system_and_set: "UK 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "men_shirts",
          aliases: [],
          slug: "Shirts",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
          category_features: [
            {
              id: "30009813d97b4e3995005764",
              display: "Casual Button Down Shirts",
              type: "cf",
              message_id: "men_shirts_casual_button_down_shirts",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Casual_Button_Down_Shirts",
              alias_slugs: [],
              parent_id: "07008c10d97b4e1245005764",
            },
            {
              id: "31009813d97b4e3995005764",
              display: "Dress Shirts",
              type: "cf",
              message_id: "men_shirts_dress_shirts",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Dress_Shirts",
              alias_slugs: [],
              parent_id: "07008c10d97b4e1245005764",
            },
            {
              id: "ac7df9aaaabb083120f45ec2",
              display: "Jerseys",
              type: "cf",
              message_id: "men_shirts_jerseys",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Jerseys",
              alias_slugs: [],
              parent_id: "07008c10d97b4e1245005764",
            },
            {
              id: "32009813d97b4e3995005764",
              display: "Polos",
              type: "cf",
              message_id: "men_shirts_polos",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Polos",
              alias_slugs: [],
              parent_id: "07008c10d97b4e1245005764",
            },
            {
              id: "02002f3cd97b4edf70005784",
              display: "Sweatshirts & Hoodies",
              type: "cf",
              message_id: "men_shirts_sweatshirts_hoodies",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sweatshirts_&_Hoodies",
              alias_slugs: [],
              parent_id: "07008c10d97b4e1245005764",
            },
            {
              id: "3400d070d97b4eaedd005776",
              display: "Tank Tops",
              type: "cf",
              message_id: "men_shirts_tank_tops",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Tank_Tops",
              alias_slugs: [],
              parent_id: "07008c10d97b4e1245005764",
            },
            {
              id: "03002f3cd97b4edf70005784",
              display: "Tees - Long Sleeve",
              type: "cf",
              message_id: "men_shirts_tees_long_sleeve",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Tees___Long_Sleeve",
              alias_slugs: [],
              parent_id: "07008c10d97b4e1245005764",
            },
            {
              id: "33009813d97b4e3995005764",
              display: "Tees - Short Sleeve",
              type: "cf",
              message_id: "men_shirts_tees_short_sleeve",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Tees___Short_Sleeve",
              alias_slugs: [],
              parent_id: "07008c10d97b4e1245005764",
            },
          ],
        },
        {
          id: "09008c10d97b4e1245005764",
          display: "Shorts",
          size_sets: [
            {
              id: "05003667d97b4e6bdb005784",
              name: "Standard",
              tags: ["standard"],
              category_id: "09008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Waist "],
                  ["28-29"],
                  ["30-31"],
                  ["32-35"],
                  ["36-38"],
                  ["39-40"],
                  ["41-42"],
                  ["43-44"],
                ],
                centimeters: [
                  ["Waist "],
                  ["71.1-73.7"],
                  ["76.2-78.7"],
                  ["81.3-88.9"],
                  ["91.4-96.5"],
                  ["99.1-101.6"],
                  ["104.1-106.7"],
                  ["109.2-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["Waist 28", "28", "71.12", "28"],
                ["Waist 29", "29", "73.66", "29"],
                ["Waist 30", "30", "76.2", "30"],
                ["Waist 31", "31", "78.8", "31"],
                ["Waist 32", "32", "81.3", "32"],
                ["Waist 33", "33", "83.8", "33"],
                ["Waist 34", "34", "86.4", "34"],
                ["Waist 35", "35", "88.9", "35"],
                ["Waist 36", "36", "91.4", "36"],
                ["Waist 37", "37", "94", "37"],
                ["Waist 38", "38", "96.5", "38"],
                ["Waist 39", "39", "99.1", "39"],
                ["Waist 40", "40", "101.6", "40"],
                ["Waist 41", "41", "104.1", "41"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "Waist 28",
                      long: "Waist 28",
                      display: "Waist 28",
                      display_with_size_set: "Waist 28",
                      display_with_size_system: "US Waist 28",
                      display_with_system_and_set: "US Waist 28",
                      equivalent_sizes: {
                        au: ["28AU"],
                        eu: ["71.12EU"],
                        uk: ["28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "29",
                      short: "Waist 29",
                      long: "Waist 29",
                      display: "Waist 29",
                      display_with_size_set: "Waist 29",
                      display_with_size_system: "US Waist 29",
                      display_with_system_and_set: "US Waist 29",
                      equivalent_sizes: {
                        au: ["29AU"],
                        eu: ["73.66EU"],
                        uk: ["29UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "Waist 30",
                      long: "Waist 30",
                      display: "Waist 30",
                      display_with_size_set: "Waist 30",
                      display_with_size_system: "US Waist 30",
                      display_with_system_and_set: "US Waist 30",
                      equivalent_sizes: {
                        au: ["30AU"],
                        eu: ["76.2EU"],
                        uk: ["30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "31",
                      short: "Waist 31",
                      long: "Waist 31",
                      display: "Waist 31",
                      display_with_size_set: "Waist 31",
                      display_with_size_system: "US Waist 31",
                      display_with_system_and_set: "US Waist 31",
                      equivalent_sizes: {
                        au: ["31AU"],
                        eu: ["78.8EU"],
                        uk: ["31UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "Waist 32",
                      long: "Waist 32",
                      display: "Waist 32",
                      display_with_size_set: "Waist 32",
                      display_with_size_system: "US Waist 32",
                      display_with_system_and_set: "US Waist 32",
                      equivalent_sizes: {
                        au: ["32AU"],
                        eu: ["81.3EU"],
                        uk: ["32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "33",
                      short: "Waist 33",
                      long: "Waist 33",
                      display: "Waist 33",
                      display_with_size_set: "Waist 33",
                      display_with_size_system: "US Waist 33",
                      display_with_system_and_set: "US Waist 33",
                      equivalent_sizes: {
                        au: ["33AU"],
                        eu: ["83.8EU"],
                        uk: ["33UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34",
                      short: "Waist 34",
                      long: "Waist 34",
                      display: "Waist 34",
                      display_with_size_set: "Waist 34",
                      display_with_size_system: "US Waist 34",
                      display_with_system_and_set: "US Waist 34",
                      equivalent_sizes: {
                        au: ["34AU"],
                        eu: ["86.4EU"],
                        uk: ["34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "35",
                      short: "Waist 35",
                      long: "Waist 35",
                      display: "Waist 35",
                      display_with_size_set: "Waist 35",
                      display_with_size_system: "US Waist 35",
                      display_with_system_and_set: "US Waist 35",
                      equivalent_sizes: {
                        au: ["35AU"],
                        eu: ["88.9EU"],
                        uk: ["35UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36",
                      short: "Waist 36",
                      long: "Waist 36",
                      display: "Waist 36",
                      display_with_size_set: "Waist 36",
                      display_with_size_system: "US Waist 36",
                      display_with_system_and_set: "US Waist 36",
                      equivalent_sizes: {
                        au: ["36AU"],
                        eu: ["91.4EU"],
                        uk: ["36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "37",
                      short: "Waist 37",
                      long: "Waist 37",
                      display: "Waist 37",
                      display_with_size_set: "Waist 37",
                      display_with_size_system: "US Waist 37",
                      display_with_system_and_set: "US Waist 37",
                      equivalent_sizes: {
                        au: ["37AU"],
                        eu: ["94EU"],
                        uk: ["37UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38",
                      short: "Waist 38",
                      long: "Waist 38",
                      display: "Waist 38",
                      display_with_size_set: "Waist 38",
                      display_with_size_system: "US Waist 38",
                      display_with_system_and_set: "US Waist 38",
                      equivalent_sizes: {
                        au: ["38AU"],
                        eu: ["96.5EU"],
                        uk: ["38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "39",
                      short: "Waist 39",
                      long: "Waist 39",
                      display: "Waist 39",
                      display_with_size_set: "Waist 39",
                      display_with_size_system: "US Waist 39",
                      display_with_system_and_set: "US Waist 39",
                      equivalent_sizes: {
                        au: ["39AU"],
                        eu: ["99.1EU"],
                        uk: ["39UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40",
                      short: "Waist 40",
                      long: "Waist 40",
                      display: "Waist 40",
                      display_with_size_set: "Waist 40",
                      display_with_size_system: "US Waist 40",
                      display_with_system_and_set: "US Waist 40",
                      equivalent_sizes: {
                        au: ["40AU"],
                        eu: ["101.6EU"],
                        uk: ["40UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "41",
                      short: "Waist 41",
                      long: "Waist 41",
                      display: "Waist 41",
                      display_with_size_set: "Waist 41",
                      display_with_size_system: "US Waist 41",
                      display_with_system_and_set: "US Waist 41",
                      equivalent_sizes: {
                        au: ["41AU"],
                        eu: ["104.1EU"],
                        uk: ["41UK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["28"],
                        eu: ["71.12EU"],
                        uk: ["28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "29AU",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "AU 29",
                      display_with_system_and_set: "AU 29",
                      equivalent_sizes: {
                        us: ["29"],
                        eu: ["73.66EU"],
                        uk: ["29UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["30"],
                        eu: ["76.2EU"],
                        uk: ["30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "31AU",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "AU 31",
                      display_with_system_and_set: "AU 31",
                      equivalent_sizes: {
                        us: ["31"],
                        eu: ["78.8EU"],
                        uk: ["31UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["32"],
                        eu: ["81.3EU"],
                        uk: ["32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "33AU",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "AU 33",
                      display_with_system_and_set: "AU 33",
                      equivalent_sizes: {
                        us: ["33"],
                        eu: ["83.8EU"],
                        uk: ["33UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["34"],
                        eu: ["86.4EU"],
                        uk: ["34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "35AU",
                      short: "35",
                      long: "35",
                      display: "35",
                      display_with_size_set: "35",
                      display_with_size_system: "AU 35",
                      display_with_system_and_set: "AU 35",
                      equivalent_sizes: {
                        us: ["35"],
                        eu: ["88.9EU"],
                        uk: ["35UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["36"],
                        eu: ["91.4EU"],
                        uk: ["36UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "37AU",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "AU 37",
                      display_with_system_and_set: "AU 37",
                      equivalent_sizes: {
                        us: ["37"],
                        eu: ["94EU"],
                        uk: ["37UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["38"],
                        eu: ["96.5EU"],
                        uk: ["38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "39AU",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "AU 39",
                      display_with_system_and_set: "AU 39",
                      equivalent_sizes: {
                        us: ["39"],
                        eu: ["99.1EU"],
                        uk: ["39UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "40AU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "AU 40",
                      display_with_system_and_set: "AU 40",
                      equivalent_sizes: {
                        us: ["40"],
                        eu: ["101.6EU"],
                        uk: ["40UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "41AU",
                      short: "41",
                      long: "41",
                      display: "41",
                      display_with_size_set: "41",
                      display_with_size_system: "AU 41",
                      display_with_system_and_set: "AU 41",
                      equivalent_sizes: {
                        us: ["41"],
                        eu: ["104.1EU"],
                        uk: ["41UK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "71.12EU",
                      short: "71.12",
                      long: "71.12",
                      display: "71.12",
                      display_with_size_set: "71.12",
                      display_with_size_system: "EU 71.12",
                      display_with_system_and_set: "EU 71.12",
                      equivalent_sizes: {
                        us: ["28"],
                        au: ["28AU"],
                        uk: ["28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "73.66EU",
                      short: "73.66",
                      long: "73.66",
                      display: "73.66",
                      display_with_size_set: "73.66",
                      display_with_size_system: "EU 73.66",
                      display_with_system_and_set: "EU 73.66",
                      equivalent_sizes: {
                        us: ["29"],
                        au: ["29AU"],
                        uk: ["29UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "76.2EU",
                      short: "76.2",
                      long: "76.2",
                      display: "76.2",
                      display_with_size_set: "76.2",
                      display_with_size_system: "EU 76.2",
                      display_with_system_and_set: "EU 76.2",
                      equivalent_sizes: {
                        us: ["30"],
                        au: ["30AU"],
                        uk: ["30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "78.8EU",
                      short: "78.8",
                      long: "78.8",
                      display: "78.8",
                      display_with_size_set: "78.8",
                      display_with_size_system: "EU 78.8",
                      display_with_system_and_set: "EU 78.8",
                      equivalent_sizes: {
                        us: ["31"],
                        au: ["31AU"],
                        uk: ["31UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "81.3EU",
                      short: "81.3",
                      long: "81.3",
                      display: "81.3",
                      display_with_size_set: "81.3",
                      display_with_size_system: "EU 81.3",
                      display_with_system_and_set: "EU 81.3",
                      equivalent_sizes: {
                        us: ["32"],
                        au: ["32AU"],
                        uk: ["32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "83.8EU",
                      short: "83.8",
                      long: "83.8",
                      display: "83.8",
                      display_with_size_set: "83.8",
                      display_with_size_system: "EU 83.8",
                      display_with_system_and_set: "EU 83.8",
                      equivalent_sizes: {
                        us: ["33"],
                        au: ["33AU"],
                        uk: ["33UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "86.4EU",
                      short: "86.4",
                      long: "86.4",
                      display: "86.4",
                      display_with_size_set: "86.4",
                      display_with_size_system: "EU 86.4",
                      display_with_system_and_set: "EU 86.4",
                      equivalent_sizes: {
                        us: ["34"],
                        au: ["34AU"],
                        uk: ["34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "88.9EU",
                      short: "88.9",
                      long: "88.9",
                      display: "88.9",
                      display_with_size_set: "88.9",
                      display_with_size_system: "EU 88.9",
                      display_with_system_and_set: "EU 88.9",
                      equivalent_sizes: {
                        us: ["35"],
                        au: ["35AU"],
                        uk: ["35UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "91.4EU",
                      short: "91.4",
                      long: "91.4",
                      display: "91.4",
                      display_with_size_set: "91.4",
                      display_with_size_system: "EU 91.4",
                      display_with_system_and_set: "EU 91.4",
                      equivalent_sizes: {
                        us: ["36"],
                        au: ["36AU"],
                        uk: ["36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "94EU",
                      short: "94",
                      long: "94",
                      display: "94",
                      display_with_size_set: "94",
                      display_with_size_system: "EU 94",
                      display_with_system_and_set: "EU 94",
                      equivalent_sizes: {
                        us: ["37"],
                        au: ["37AU"],
                        uk: ["37UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "96.5EU",
                      short: "96.5",
                      long: "96.5",
                      display: "96.5",
                      display_with_size_set: "96.5",
                      display_with_size_system: "EU 96.5",
                      display_with_system_and_set: "EU 96.5",
                      equivalent_sizes: {
                        us: ["38"],
                        au: ["38AU"],
                        uk: ["38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "99.1EU",
                      short: "99.1",
                      long: "99.1",
                      display: "99.1",
                      display_with_size_set: "99.1",
                      display_with_size_system: "EU 99.1",
                      display_with_system_and_set: "EU 99.1",
                      equivalent_sizes: {
                        us: ["39"],
                        au: ["39AU"],
                        uk: ["39UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "101.6EU",
                      short: "101.6",
                      long: "101.6",
                      display: "101.6",
                      display_with_size_set: "101.6",
                      display_with_size_system: "EU 101.6",
                      display_with_system_and_set: "EU 101.6",
                      equivalent_sizes: {
                        us: ["40"],
                        au: ["40AU"],
                        uk: ["40UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "104.1EU",
                      short: "104.1",
                      long: "104.1",
                      display: "104.1",
                      display_with_size_set: "104.1",
                      display_with_size_system: "EU 104.1",
                      display_with_system_and_set: "EU 104.1",
                      equivalent_sizes: {
                        us: ["41"],
                        au: ["41AU"],
                        uk: ["41UK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["28"],
                        au: ["28AU"],
                        eu: ["71.12EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "29UK",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "UK 29",
                      display_with_system_and_set: "UK 29",
                      equivalent_sizes: {
                        us: ["29"],
                        au: ["29AU"],
                        eu: ["73.66EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["30"],
                        au: ["30AU"],
                        eu: ["76.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "31UK",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "UK 31",
                      display_with_system_and_set: "UK 31",
                      equivalent_sizes: {
                        us: ["31"],
                        au: ["31AU"],
                        eu: ["78.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["32"],
                        au: ["32AU"],
                        eu: ["81.3EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "33UK",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "UK 33",
                      display_with_system_and_set: "UK 33",
                      equivalent_sizes: {
                        us: ["33"],
                        au: ["33AU"],
                        eu: ["83.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["34"],
                        au: ["34AU"],
                        eu: ["86.4EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "35UK",
                      short: "35",
                      long: "35",
                      display: "35",
                      display_with_size_set: "35",
                      display_with_size_system: "UK 35",
                      display_with_system_and_set: "UK 35",
                      equivalent_sizes: {
                        us: ["35"],
                        au: ["35AU"],
                        eu: ["88.9EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["36"],
                        au: ["36AU"],
                        eu: ["91.4EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "37UK",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "UK 37",
                      display_with_system_and_set: "UK 37",
                      equivalent_sizes: {
                        us: ["37"],
                        au: ["37AU"],
                        eu: ["94EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["38"],
                        au: ["38AU"],
                        eu: ["96.5EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "39UK",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "UK 39",
                      display_with_system_and_set: "UK 39",
                      equivalent_sizes: {
                        us: ["39"],
                        au: ["39AU"],
                        eu: ["99.1EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40UK",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "UK 40",
                      display_with_system_and_set: "UK 40",
                      equivalent_sizes: {
                        us: ["40"],
                        au: ["40AU"],
                        eu: ["101.6EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "41UK",
                      short: "41",
                      long: "41",
                      display: "41",
                      display_with_size_set: "41",
                      display_with_size_system: "UK 41",
                      display_with_system_and_set: "UK 41",
                      equivalent_sizes: {
                        us: ["41"],
                        au: ["41AU"],
                        eu: ["104.1EU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "0f1e4cd26058864a961862cf",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "09008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Waist "],
                  ["42-44"],
                  ["46-48"],
                  ["50-52"],
                  ["54-56"],
                  ["58-60"],
                  ["40-44"],
                  ["45-48.5"],
                  ["49-52"],
                  ["52-55"],
                  ["56-60"],
                  ["62-64"],
                ],
                centimeters: [
                  ["Waist "],
                  ["106.7-111.8"],
                  ["116.8-121.9"],
                  ["127-132.1"],
                  ["137.2-142.2"],
                  ["147.3-152.4"],
                  ["99.1-101.6"],
                  ["106.7-111.8"],
                  ["116.8-121.9"],
                  ["127-132.1"],
                  ["142.2-152.5"],
                  ["157.4-162.5"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["3XL", "3XL", "3XL", "3XL"],
                  ["4XL", "4XL", "4XL", "4XL"],
                  ["5XL", "5XL", "5XL", "5XL"],
                  ["6XL", "6XL", "6XL", "6XL"],
                  ["XLT", "XLT", "XLT", "XLT"],
                  ["3XLT", "3XLT", "3XLT", "3XLT"],
                  ["4XLT", "4XLT", "4XLT", "4XLT"],
                  ["5XLT", "5XLT", "5XLT", "5XLT"],
                  ["6XLT", "6XLT", "6XLT", "6XLT"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["Waist 36", "36", "91.4", "36"],
                ["Waist 37", "37", "94", "37"],
                ["Waist 38", "38", "96.5", "38"],
                ["Waist 39", "39", "99.1", "39"],
                ["Waist 40", "40", "101.6", "40"],
                ["Waist 42", "42", "106.7", "42"],
                ["Waist 43", "43", "109.2", "43"],
                ["Waist 44", "44", "111.8", "44"],
                ["Waist 46", "46", "116.8", "46"],
                ["Waist 48", "48", "121.9", "48"],
                ["Waist 50", "50", "127.0", "50"],
                ["Waist 52", "52", "132.1", "52"],
                ["Waist 54", "54", "137.2", "54"],
                ["Waist 56", "56", "142.2", "56"],
                ["Waist 58", "58", "147.3", "58"],
                ["Waist 60", "60", "152.4", "60"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["3XL", "3XL", "3XL", "3XL"],
                ["4XL", "4XL", "4XL", "4XL"],
                ["5XL", "5XL", "5XL", "5XL"],
                ["6XL", "6XL", "6XL", "6XL"],
                ["LT", "LT", "LT", "LT"],
                ["XLT", "XLT", "XLT", "XLT"],
                ["2XLT", "2XLT", "2XLT", "2XLT"],
                ["3XLT", "3XLT", "3XLT", "3XLT"],
                ["4XLT", "4XLT", "4XLT", "4XLT"],
                ["5XLT", "5XLT", "5XLT", "5XLT"],
                ["6XLT", "6XLT", "6XLT", "6XLT"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "36BT",
                      short: "Waist 36",
                      long: "Waist 36",
                      display: "Waist 36",
                      display_with_size_set: "Waist 36",
                      display_with_size_system: "US Waist 36",
                      display_with_system_and_set: "US Waist 36",
                      equivalent_sizes: {
                        au: ["36BTAU"],
                        eu: ["91.4BTEU"],
                        uk: ["36BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "37BT",
                      short: "Waist 37",
                      long: "Waist 37",
                      display: "Waist 37",
                      display_with_size_set: "Waist 37",
                      display_with_size_system: "US Waist 37",
                      display_with_system_and_set: "US Waist 37",
                      equivalent_sizes: {
                        au: ["37BTAU"],
                        eu: ["94BTEU"],
                        uk: ["37BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38BT",
                      short: "Waist 38",
                      long: "Waist 38",
                      display: "Waist 38",
                      display_with_size_set: "Waist 38",
                      display_with_size_system: "US Waist 38",
                      display_with_system_and_set: "US Waist 38",
                      equivalent_sizes: {
                        au: ["38BTAU"],
                        eu: ["96.5BTEU"],
                        uk: ["38BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "39BT",
                      short: "Waist 39",
                      long: "Waist 39",
                      display: "Waist 39",
                      display_with_size_set: "Waist 39",
                      display_with_size_system: "US Waist 39",
                      display_with_system_and_set: "US Waist 39",
                      equivalent_sizes: {
                        au: ["39BTAU"],
                        eu: ["99.1BTEU"],
                        uk: ["39BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40BT",
                      short: "Waist 40",
                      long: "Waist 40",
                      display: "Waist 40",
                      display_with_size_set: "Waist 40",
                      display_with_size_system: "US Waist 40",
                      display_with_system_and_set: "US Waist 40",
                      equivalent_sizes: {
                        au: ["40BTAU"],
                        eu: ["101.6BTEU"],
                        uk: ["40BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42",
                      short: "Waist 42",
                      long: "Waist 42",
                      display: "Waist 42",
                      display_with_size_set: "Waist 42",
                      display_with_size_system: "US Waist 42",
                      display_with_system_and_set: "US Waist 42",
                      equivalent_sizes: {
                        au: ["42AU"],
                        eu: ["106.7EU"],
                        uk: ["42UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "43",
                      short: "Waist 43",
                      long: "Waist 43",
                      display: "Waist 43",
                      display_with_size_set: "Waist 43",
                      display_with_size_system: "US Waist 43",
                      display_with_system_and_set: "US Waist 43",
                      equivalent_sizes: {
                        au: ["43AU"],
                        eu: ["109.2EU"],
                        uk: ["43UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44",
                      short: "Waist 44",
                      long: "Waist 44",
                      display: "Waist 44",
                      display_with_size_set: "Waist 44",
                      display_with_size_system: "US Waist 44",
                      display_with_system_and_set: "US Waist 44",
                      equivalent_sizes: {
                        au: ["44AU"],
                        eu: ["111.8EU"],
                        uk: ["44UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46",
                      short: "Waist 46",
                      long: "Waist 46",
                      display: "Waist 46",
                      display_with_size_set: "Waist 46",
                      display_with_size_system: "US Waist 46",
                      display_with_system_and_set: "US Waist 46",
                      equivalent_sizes: {
                        au: ["46AU"],
                        eu: ["116.8EU"],
                        uk: ["46UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48",
                      short: "Waist 48",
                      long: "Waist 48",
                      display: "Waist 48",
                      display_with_size_set: "Waist 48",
                      display_with_size_system: "US Waist 48",
                      display_with_system_and_set: "US Waist 48",
                      equivalent_sizes: {
                        au: ["48AU"],
                        eu: ["121.9EU"],
                        uk: ["48UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "50",
                      short: "Waist 50",
                      long: "Waist 50",
                      display: "Waist 50",
                      display_with_size_set: "Waist 50",
                      display_with_size_system: "US Waist 50",
                      display_with_system_and_set: "US Waist 50",
                      equivalent_sizes: {
                        au: ["50AU"],
                        eu: ["127.0EU"],
                        uk: ["50UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "52",
                      short: "Waist 52",
                      long: "Waist 52",
                      display: "Waist 52",
                      display_with_size_set: "Waist 52",
                      display_with_size_system: "US Waist 52",
                      display_with_system_and_set: "US Waist 52",
                      equivalent_sizes: {
                        au: ["52AU"],
                        eu: ["132.1EU"],
                        uk: ["52UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "54",
                      short: "Waist 54",
                      long: "Waist 54",
                      display: "Waist 54",
                      display_with_size_set: "Waist 54",
                      display_with_size_system: "US Waist 54",
                      display_with_system_and_set: "US Waist 54",
                      equivalent_sizes: {
                        au: ["54AU"],
                        eu: ["137.2EU"],
                        uk: ["54UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "56",
                      short: "Waist 56",
                      long: "Waist 56",
                      display: "Waist 56",
                      display_with_size_set: "Waist 56",
                      display_with_size_system: "US Waist 56",
                      display_with_system_and_set: "US Waist 56",
                      equivalent_sizes: {
                        au: ["56AU"],
                        eu: ["142.2EU"],
                        uk: ["56UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "58",
                      short: "Waist 58",
                      long: "Waist 58",
                      display: "Waist 58",
                      display_with_size_set: "Waist 58",
                      display_with_size_system: "US Waist 58",
                      display_with_system_and_set: "US Waist 58",
                      equivalent_sizes: {
                        au: ["58AU"],
                        eu: ["147.3EU"],
                        uk: ["58UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "60",
                      short: "Waist 60",
                      long: "Waist 60",
                      display: "Waist 60",
                      display_with_size_set: "Waist 60",
                      display_with_size_system: "US Waist 60",
                      display_with_system_and_set: "US Waist 60",
                      equivalent_sizes: {
                        au: ["60AU"],
                        eu: ["152.4EU"],
                        uk: ["60UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XL",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "US 3XL",
                      display_with_system_and_set: "US 3XL",
                      equivalent_sizes: {
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XL",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "US 4XL",
                      display_with_system_and_set: "US 4XL",
                      equivalent_sizes: {
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XL",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "US 5XL",
                      display_with_system_and_set: "US 5XL",
                      equivalent_sizes: {
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XL",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "US 6XL",
                      display_with_system_and_set: "US 6XL",
                      equivalent_sizes: {
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LT",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "US LT",
                      display_with_system_and_set: "US LT",
                      equivalent_sizes: {
                        au: ["LTAU"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLT",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "US XLT",
                      display_with_system_and_set: "US XLT",
                      equivalent_sizes: {
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XLT",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "US 2XLT",
                      display_with_system_and_set: "US 2XLT",
                      equivalent_sizes: {
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XLT",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "US 3XLT",
                      display_with_system_and_set: "US 3XLT",
                      equivalent_sizes: {
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XLT",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "US 4XLT",
                      display_with_system_and_set: "US 4XLT",
                      equivalent_sizes: {
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XLT",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "US 5XLT",
                      display_with_system_and_set: "US 5XLT",
                      equivalent_sizes: {
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XLT",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "US 6XLT",
                      display_with_system_and_set: "US 6XLT",
                      equivalent_sizes: {
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "36BTAU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["36BT"],
                        eu: ["91.4BTEU"],
                        uk: ["36BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "37BTAU",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "AU 37",
                      display_with_system_and_set: "AU 37",
                      equivalent_sizes: {
                        us: ["37BT"],
                        eu: ["94BTEU"],
                        uk: ["37BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38BTAU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["38BT"],
                        eu: ["96.5BTEU"],
                        uk: ["38BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "39BTAU",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "AU 39",
                      display_with_system_and_set: "AU 39",
                      equivalent_sizes: {
                        us: ["39BT"],
                        eu: ["99.1BTEU"],
                        uk: ["39BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "40BTAU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "AU 40",
                      display_with_system_and_set: "AU 40",
                      equivalent_sizes: {
                        us: ["40BT"],
                        eu: ["101.6BTEU"],
                        uk: ["40BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "42AU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "AU 42",
                      display_with_system_and_set: "AU 42",
                      equivalent_sizes: {
                        us: ["42"],
                        eu: ["106.7EU"],
                        uk: ["42UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "43AU",
                      short: "43",
                      long: "43",
                      display: "43",
                      display_with_size_set: "43",
                      display_with_size_system: "AU 43",
                      display_with_system_and_set: "AU 43",
                      equivalent_sizes: {
                        us: ["43"],
                        eu: ["109.2EU"],
                        uk: ["43UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "44AU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "AU 44",
                      display_with_system_and_set: "AU 44",
                      equivalent_sizes: {
                        us: ["44"],
                        eu: ["111.8EU"],
                        uk: ["44UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "46AU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "AU 46",
                      display_with_system_and_set: "AU 46",
                      equivalent_sizes: {
                        us: ["46"],
                        eu: ["116.8EU"],
                        uk: ["46UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "48AU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "AU 48",
                      display_with_system_and_set: "AU 48",
                      equivalent_sizes: {
                        us: ["48"],
                        eu: ["121.9EU"],
                        uk: ["48UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "50AU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "AU 50",
                      display_with_system_and_set: "AU 50",
                      equivalent_sizes: {
                        us: ["50"],
                        eu: ["127.0EU"],
                        uk: ["50UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "52AU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "AU 52",
                      display_with_system_and_set: "AU 52",
                      equivalent_sizes: {
                        us: ["52"],
                        eu: ["132.1EU"],
                        uk: ["52UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "54AU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "AU 54",
                      display_with_system_and_set: "AU 54",
                      equivalent_sizes: {
                        us: ["54"],
                        eu: ["137.2EU"],
                        uk: ["54UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "56AU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "AU 56",
                      display_with_system_and_set: "AU 56",
                      equivalent_sizes: {
                        us: ["56"],
                        eu: ["142.2EU"],
                        uk: ["56UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "58AU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "AU 58",
                      display_with_system_and_set: "AU 58",
                      equivalent_sizes: {
                        us: ["58"],
                        eu: ["147.3EU"],
                        uk: ["58UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "60AU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "AU 60",
                      display_with_system_and_set: "AU 60",
                      equivalent_sizes: {
                        us: ["60"],
                        eu: ["152.4EU"],
                        uk: ["60UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLAU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "AU 3XL",
                      display_with_system_and_set: "AU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLAU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "AU 4XL",
                      display_with_system_and_set: "AU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLAU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "AU 5XL",
                      display_with_system_and_set: "AU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLAU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "AU 6XL",
                      display_with_system_and_set: "AU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LTAU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "AU LT",
                      display_with_system_and_set: "AU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLTAU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "AU XLT",
                      display_with_system_and_set: "AU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XLTAU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "AU 2XLT",
                      display_with_system_and_set: "AU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLTAU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "AU 3XLT",
                      display_with_system_and_set: "AU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLTAU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "AU 4XLT",
                      display_with_system_and_set: "AU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLTAU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "AU 5XLT",
                      display_with_system_and_set: "AU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLTAU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "AU 6XLT",
                      display_with_system_and_set: "AU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "91.4BTEU",
                      short: "91.4",
                      long: "91.4",
                      display: "91.4",
                      display_with_size_set: "91.4",
                      display_with_size_system: "EU 91.4",
                      display_with_system_and_set: "EU 91.4",
                      equivalent_sizes: {
                        us: ["36BT"],
                        au: ["36BTAU"],
                        uk: ["36BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "94BTEU",
                      short: "94",
                      long: "94",
                      display: "94",
                      display_with_size_set: "94",
                      display_with_size_system: "EU 94",
                      display_with_system_and_set: "EU 94",
                      equivalent_sizes: {
                        us: ["37BT"],
                        au: ["37BTAU"],
                        uk: ["37BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "96.5BTEU",
                      short: "96.5",
                      long: "96.5",
                      display: "96.5",
                      display_with_size_set: "96.5",
                      display_with_size_system: "EU 96.5",
                      display_with_system_and_set: "EU 96.5",
                      equivalent_sizes: {
                        us: ["38BT"],
                        au: ["38BTAU"],
                        uk: ["38BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "99.1BTEU",
                      short: "99.1",
                      long: "99.1",
                      display: "99.1",
                      display_with_size_set: "99.1",
                      display_with_size_system: "EU 99.1",
                      display_with_system_and_set: "EU 99.1",
                      equivalent_sizes: {
                        us: ["39BT"],
                        au: ["39BTAU"],
                        uk: ["39BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "101.6BTEU",
                      short: "101.6",
                      long: "101.6",
                      display: "101.6",
                      display_with_size_set: "101.6",
                      display_with_size_system: "EU 101.6",
                      display_with_system_and_set: "EU 101.6",
                      equivalent_sizes: {
                        us: ["40BT"],
                        au: ["40BTAU"],
                        uk: ["40BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "106.7EU",
                      short: "106.7",
                      long: "106.7",
                      display: "106.7",
                      display_with_size_set: "106.7",
                      display_with_size_system: "EU 106.7",
                      display_with_system_and_set: "EU 106.7",
                      equivalent_sizes: {
                        us: ["42"],
                        au: ["42AU"],
                        uk: ["42UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "109.2EU",
                      short: "109.2",
                      long: "109.2",
                      display: "109.2",
                      display_with_size_set: "109.2",
                      display_with_size_system: "EU 109.2",
                      display_with_system_and_set: "EU 109.2",
                      equivalent_sizes: {
                        us: ["43"],
                        au: ["43AU"],
                        uk: ["43UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "111.8EU",
                      short: "111.8",
                      long: "111.8",
                      display: "111.8",
                      display_with_size_set: "111.8",
                      display_with_size_system: "EU 111.8",
                      display_with_system_and_set: "EU 111.8",
                      equivalent_sizes: {
                        us: ["44"],
                        au: ["44AU"],
                        uk: ["44UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "116.8EU",
                      short: "116.8",
                      long: "116.8",
                      display: "116.8",
                      display_with_size_set: "116.8",
                      display_with_size_system: "EU 116.8",
                      display_with_system_and_set: "EU 116.8",
                      equivalent_sizes: {
                        us: ["46"],
                        au: ["46AU"],
                        uk: ["46UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "121.9EU",
                      short: "121.9",
                      long: "121.9",
                      display: "121.9",
                      display_with_size_set: "121.9",
                      display_with_size_system: "EU 121.9",
                      display_with_system_and_set: "EU 121.9",
                      equivalent_sizes: {
                        us: ["48"],
                        au: ["48AU"],
                        uk: ["48UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "127.0EU",
                      short: "127.0",
                      long: "127.0",
                      display: "127.0",
                      display_with_size_set: "127.0",
                      display_with_size_system: "EU 127.0",
                      display_with_system_and_set: "EU 127.0",
                      equivalent_sizes: {
                        us: ["50"],
                        au: ["50AU"],
                        uk: ["50UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "132.1EU",
                      short: "132.1",
                      long: "132.1",
                      display: "132.1",
                      display_with_size_set: "132.1",
                      display_with_size_system: "EU 132.1",
                      display_with_system_and_set: "EU 132.1",
                      equivalent_sizes: {
                        us: ["52"],
                        au: ["52AU"],
                        uk: ["52UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "137.2EU",
                      short: "137.2",
                      long: "137.2",
                      display: "137.2",
                      display_with_size_set: "137.2",
                      display_with_size_system: "EU 137.2",
                      display_with_system_and_set: "EU 137.2",
                      equivalent_sizes: {
                        us: ["54"],
                        au: ["54AU"],
                        uk: ["54UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "142.2EU",
                      short: "142.2",
                      long: "142.2",
                      display: "142.2",
                      display_with_size_set: "142.2",
                      display_with_size_system: "EU 142.2",
                      display_with_system_and_set: "EU 142.2",
                      equivalent_sizes: {
                        us: ["56"],
                        au: ["56AU"],
                        uk: ["56UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "147.3EU",
                      short: "147.3",
                      long: "147.3",
                      display: "147.3",
                      display_with_size_set: "147.3",
                      display_with_size_system: "EU 147.3",
                      display_with_system_and_set: "EU 147.3",
                      equivalent_sizes: {
                        us: ["58"],
                        au: ["58AU"],
                        uk: ["58UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "152.4EU",
                      short: "152.4",
                      long: "152.4",
                      display: "152.4",
                      display_with_size_set: "152.4",
                      display_with_size_system: "EU 152.4",
                      display_with_system_and_set: "EU 152.4",
                      equivalent_sizes: {
                        us: ["60"],
                        au: ["60AU"],
                        uk: ["60UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLEU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "EU 3XL",
                      display_with_system_and_set: "EU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLEU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "EU 4XL",
                      display_with_system_and_set: "EU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLEU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "EU 5XL",
                      display_with_system_and_set: "EU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLEU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "EU 6XL",
                      display_with_system_and_set: "EU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LTEU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "EU LT",
                      display_with_system_and_set: "EU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        uk: ["LTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLTEU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "EU XLT",
                      display_with_system_and_set: "EU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XLTEU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "EU 2XLT",
                      display_with_system_and_set: "EU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLTEU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "EU 3XLT",
                      display_with_system_and_set: "EU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLTEU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "EU 4XLT",
                      display_with_system_and_set: "EU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLTEU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "EU 5XLT",
                      display_with_system_and_set: "EU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLTEU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "EU 6XLT",
                      display_with_system_and_set: "EU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "36BTUK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["36BT"],
                        au: ["36BTAU"],
                        eu: ["91.4BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "37BTUK",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "UK 37",
                      display_with_system_and_set: "UK 37",
                      equivalent_sizes: {
                        us: ["37BT"],
                        au: ["37BTAU"],
                        eu: ["94BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38BTUK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["38BT"],
                        au: ["38BTUK"],
                        eu: ["96.5BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "39BTUK",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "UK 39",
                      display_with_system_and_set: "UK 39",
                      equivalent_sizes: {
                        us: ["39BT"],
                        au: ["39BTUK"],
                        eu: ["99.1BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40BTUK",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "UK 40",
                      display_with_system_and_set: "UK 40",
                      equivalent_sizes: {
                        us: ["40BT"],
                        au: ["40BTUK"],
                        eu: ["101.6BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42UK",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "UK 42",
                      display_with_system_and_set: "UK 42",
                      equivalent_sizes: {
                        us: ["42"],
                        au: ["42AU"],
                        eu: ["106.7EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "43UK",
                      short: "43",
                      long: "43",
                      display: "43",
                      display_with_size_set: "43",
                      display_with_size_system: "UK 43",
                      display_with_system_and_set: "UK 43",
                      equivalent_sizes: {
                        us: ["43"],
                        au: ["43AU"],
                        eu: ["109.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44UK",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "UK 44",
                      display_with_system_and_set: "UK 44",
                      equivalent_sizes: {
                        us: ["44"],
                        au: ["44AU"],
                        eu: ["111.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46UK",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "UK 46",
                      display_with_system_and_set: "UK 46",
                      equivalent_sizes: {
                        us: ["46"],
                        au: ["46AU"],
                        eu: ["116.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48UK",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "UK 48",
                      display_with_system_and_set: "UK 48",
                      equivalent_sizes: {
                        us: ["48"],
                        au: ["48AU"],
                        eu: ["121.9EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "50UK",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "UK 50",
                      display_with_system_and_set: "UK 50",
                      equivalent_sizes: {
                        us: ["50"],
                        au: ["50AU"],
                        eu: ["127.0EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "52UK",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "UK 52",
                      display_with_system_and_set: "UK 52",
                      equivalent_sizes: {
                        us: ["52"],
                        au: ["52AU"],
                        eu: ["132.1EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "54UK",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "UK 54",
                      display_with_system_and_set: "UK 54",
                      equivalent_sizes: {
                        us: ["54"],
                        au: ["54AU"],
                        eu: ["137.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "56UK",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "UK 56",
                      display_with_system_and_set: "UK 56",
                      equivalent_sizes: {
                        us: ["56"],
                        au: ["56AU"],
                        eu: ["142.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "58UK",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "UK 58",
                      display_with_system_and_set: "UK 58",
                      equivalent_sizes: {
                        us: ["58"],
                        au: ["58AU"],
                        eu: ["147.3EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "60UK",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "UK 60",
                      display_with_system_and_set: "UK 60",
                      equivalent_sizes: {
                        us: ["60"],
                        au: ["60AU"],
                        eu: ["152.4EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLUK",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "UK 3XL",
                      display_with_system_and_set: "UK 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLUK",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "UK 4XL",
                      display_with_system_and_set: "UK 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLUK",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "UK 5XL",
                      display_with_system_and_set: "UK 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLUK",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "UK 6XL",
                      display_with_system_and_set: "UK 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LTUK",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "UK LT",
                      display_with_system_and_set: "UK LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        eu: ["LTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLTUK",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "UK XLT",
                      display_with_system_and_set: "UK XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XLTUK",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "UK 2XLT",
                      display_with_system_and_set: "UK 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLTUK",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "UK 3XLT",
                      display_with_system_and_set: "UK 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLTUK",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "UK 4XLT",
                      display_with_system_and_set: "UK 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLTUK",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "UK 5XLT",
                      display_with_system_and_set: "UK 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLTUK",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "UK 6XLT",
                      display_with_system_and_set: "UK 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "men_shorts",
          aliases: [],
          slug: "Shorts",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
          category_features: [
            {
              id: "3e009813d97b4e3995005764",
              display: "Athletic",
              type: "cf",
              message_id: "men_shorts_athletic",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Athletic",
              alias_slugs: [],
              parent_id: "09008c10d97b4e1245005764",
            },
            {
              id: "3f009813d97b4e3995005764",
              display: "Cargo",
              type: "cf",
              message_id: "men_shorts_cargo",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cargo",
              alias_slugs: [],
              parent_id: "09008c10d97b4e1245005764",
            },
            {
              id: "40009813d97b4e3995005764",
              display: "Flat Front",
              type: "cf",
              message_id: "men_shorts_flat_front",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Flat_Front",
              alias_slugs: [],
              parent_id: "09008c10d97b4e1245005764",
            },
            {
              id: "41009813d97b4e3995005764",
              display: "Hybrids",
              type: "cf",
              message_id: "men_shorts_hybrids",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Hybrids",
              alias_slugs: [],
              parent_id: "09008c10d97b4e1245005764",
            },
            {
              id: "05002f3cd97b4edf70005784",
              display: "Jean Shorts",
              type: "cf",
              message_id: "men_shorts_jean_shorts",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Jean_Shorts",
              alias_slugs: [],
              parent_id: "09008c10d97b4e1245005764",
            },
          ],
        },
        {
          id: "0a008c10d97b4e1245005764",
          display: "Suits & Blazers",
          size_sets: [
            {
              id: "06003667d97b4e6bdb005784",
              name: "Standard",
              tags: ["standard"],
              category_id: "0a008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["34", "28"],
                  ["35", "29"],
                  ["36", "30"],
                  ["37", "31"],
                  ["38", "32"],
                  ["39", "33"],
                  ["40", "34"],
                  ["41", "35"],
                  ["42", "36"],
                  ["43", "37"],
                  ["44", "38"],
                  ["45", "39"],
                  ["46", "40"],
                  ["47", "41"],
                  ["48", "42"],
                  ["49", "43"],
                  ["50", "44"],
                  ["51", "45"],
                  ["52", "46"],
                  ["53", "47"],
                  ["54", "48"],
                  ["55", "49"],
                  ["56", "50"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["86.4", "71.1"],
                  ["88.9", "73.7"],
                  ["91.4", "76.2"],
                  ["94", "78.7"],
                  ["96.5", "81.3"],
                  ["99.1", "83.8"],
                  ["101.6", "86.4"],
                  ["104.1", "88.9"],
                  ["106.7", "91.4"],
                  ["109.2", "94"],
                  ["111.8", "96.5"],
                  ["114.3", "99.1"],
                  ["116.8", "101.6"],
                  ["119.4", "104.1"],
                  ["121.9", "106.7"],
                  ["124.5", "109.2"],
                  ["127", "111.8"],
                  ["129.5", "114.3"],
                  ["132.1", "116.8"],
                  ["134.6", "119.4"],
                  ["137.2", "121.9"],
                  ["139.7", "124.5"],
                  ["142.2", "127"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["34S", "34S", "44S", "34S"],
                  ["35L", "35L", "45L", "35L"],
                  ["36L", "36L", "46L", "36L"],
                  ["37L", "37L", "47L", "37L"],
                  ["38L", "38L", "48L", "38L"],
                  ["39L", "39L", "49L", "39L"],
                  ["40L", "40L", "50L", "40L"],
                  ["41R", "41R", "51R", "41R"],
                  ["42L", "42L", "52L", "42L"],
                  ["43L", "43L", "53L", "43L"],
                  ["44L", "44L", "54L", "44L"],
                  ["45L", "45L", "55L", "45L"],
                  ["46R", "46R", "56R", "46R"],
                  ["47L", "47L", "57L", "47L"],
                  ["48L", "48L", "58L", "48L"],
                  ["49R", "49R", "59R", "49R"],
                  ["50L", "50L", "60L", "50L"],
                  ["51L", "51L", "61L", "51L"],
                  ["52R", "52R", "62R", "52R"],
                  ["53L", "53L", "63L", "53L"],
                  ["54L", "54L", "64L", "54L"],
                  ["55L", "55L", "65L", "55L"],
                  ["56L", "56L", "66L", "56L"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["34S", "34S", "44S", "34S"],
                ["34R", "34R", "44R", "34R"],
                ["34L", "34L", "44L", "34L"],
                ["35S", "35S", "45S", "35S"],
                ["35R", "35R", "45R", "35R"],
                ["35L", "35L", "45L", "35L"],
                ["36S", "36S", "46S", "36S"],
                ["36R", "36R", "46R", "36R"],
                ["36L", "36L", "46L", "36L"],
                ["37S", "37S", "47S", "37S"],
                ["37R", "37R", "47R", "37R"],
                ["37L", "37L", "47L", "37L"],
                ["38S", "38S", "48S", "38S"],
                ["38R", "38R", "48R", "38R"],
                ["38L", "38L", "48L", "38L"],
                ["39S", "39S", "49S", "39S"],
                ["39R", "39R", "49R", "39R"],
                ["39L", "39L", "49L", "39L"],
                ["40S", "40S", "50S", "40S"],
                ["40R", "40R", "50R", "40R"],
                ["40L", "40L", "50L", "40L"],
                ["41S", "41S", "51S", "41S"],
                ["41R", "41R", "51R", "41R"],
                ["41L", "41L", "51L", "41L"],
                ["42S", "42S", "52S", "42S"],
                ["42R", "42R", "52R", "42R"],
                ["42L", "42L", "52L", "42L"],
                ["43S", "43S", "53S", "43S"],
                ["43R", "43R", "53R", "43R"],
                ["43L", "43L", "53L", "43L"],
                ["44S", "44S", "54S", "44S"],
                ["44R", "44R", "54R", "44R"],
                ["44L", "44L", "54L", "44L"],
                ["45S", "45S", "55S", "45S"],
                ["45R", "45R", "55R", "45R"],
                ["45L", "45L", "55L", "45L"],
                ["46S", "46S", "56S", "46S"],
                ["46R", "46R", "56R", "46R"],
                ["46L", "46L", "56L", "46L"],
                ["47S", "47S", "57S", "47S"],
                ["47R", "47R", "57R", "47R"],
                ["47L", "47L", "57L", "47L"],
                ["48S", "48S", "58S", "48S"],
                ["48R", "48R", "58R", "48R"],
                ["48L", "48L", "58L", "48L"],
                ["49S", "49S", "59S", "49S"],
                ["49R", "49R", "59R", "49R"],
                ["49L", "49L", "59L", "49L"],
                ["50S", "50S", "60S", "50S"],
                ["50R", "50R", "60R", "50R"],
                ["50L", "50L", "60L", "50L"],
                ["51S", "51S", "61S", "51S"],
                ["51R", "51R", "61R", "51R"],
                ["51L", "51L", "61L", "51L"],
                ["52S", "52S", "62S", "52S"],
                ["52R", "52R", "62R", "52R"],
                ["52L", "52L", "62L", "52L"],
                ["53S", "53S", "63S", "53S"],
                ["53R", "53R", "63R", "53R"],
                ["53L", "53L", "63L", "53L"],
                ["54S", "54S", "64S", "54S"],
                ["54R", "54R", "64R", "54R"],
                ["54L", "54L", "64L", "54L"],
                ["55S", "55S", "65S", "55S"],
                ["55R", "55R", "65R", "55R"],
                ["55L", "55L", "65L", "55L"],
                ["56S", "56S", "66S", "56S"],
                ["56R", "56R", "66R", "56R"],
                ["56L", "56L", "66L", "56L"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "34S",
                      short: "34S",
                      long: "34S",
                      display: "34S",
                      display_with_size_set: "34S",
                      display_with_size_system: "US 34S",
                      display_with_system_and_set: "US 34S",
                      equivalent_sizes: {
                        au: ["34SAU"],
                        eu: ["44SEU"],
                        uk: ["34SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34R",
                      short: "34R",
                      long: "34R",
                      display: "34R",
                      display_with_size_set: "34R",
                      display_with_size_system: "US 34R",
                      display_with_system_and_set: "US 34R",
                      equivalent_sizes: {
                        au: ["34RAU"],
                        eu: ["44REU"],
                        uk: ["34RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34L",
                      short: "34L",
                      long: "34L",
                      display: "34L",
                      display_with_size_set: "34L",
                      display_with_size_system: "US 34L",
                      display_with_system_and_set: "US 34L",
                      equivalent_sizes: {
                        au: ["34LAU"],
                        eu: ["44LEU"],
                        uk: ["34LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "35S",
                      short: "35S",
                      long: "35S",
                      display: "35S",
                      display_with_size_set: "35S",
                      display_with_size_system: "US 35S",
                      display_with_system_and_set: "US 35S",
                      equivalent_sizes: {
                        au: ["35SAU"],
                        eu: ["45SEU"],
                        uk: ["35SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "35R",
                      short: "35R",
                      long: "35R",
                      display: "35R",
                      display_with_size_set: "35R",
                      display_with_size_system: "US 35R",
                      display_with_system_and_set: "US 35R",
                      equivalent_sizes: {
                        au: ["35RAU"],
                        eu: ["45REU"],
                        uk: ["35RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "35L",
                      short: "35L",
                      long: "35L",
                      display: "35L",
                      display_with_size_set: "35L",
                      display_with_size_system: "US 35L",
                      display_with_system_and_set: "US 35L",
                      equivalent_sizes: {
                        au: ["35LAU"],
                        eu: ["45LEU"],
                        uk: ["35LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36S",
                      short: "36S",
                      long: "36S",
                      display: "36S",
                      display_with_size_set: "36S",
                      display_with_size_system: "US 36S",
                      display_with_system_and_set: "US 36S",
                      equivalent_sizes: {
                        au: ["36SAU"],
                        eu: ["46SEU"],
                        uk: ["36SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36R",
                      short: "36R",
                      long: "36R",
                      display: "36R",
                      display_with_size_set: "36R",
                      display_with_size_system: "US 36R",
                      display_with_system_and_set: "US 36R",
                      equivalent_sizes: {
                        au: ["36RAU"],
                        eu: ["46REU"],
                        uk: ["36RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36L",
                      short: "36L",
                      long: "36L",
                      display: "36L",
                      display_with_size_set: "36L",
                      display_with_size_system: "US 36L",
                      display_with_system_and_set: "US 36L",
                      equivalent_sizes: {
                        au: ["36LAU"],
                        eu: ["46LEU"],
                        uk: ["36LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "37S",
                      short: "37S",
                      long: "37S",
                      display: "37S",
                      display_with_size_set: "37S",
                      display_with_size_system: "US 37S",
                      display_with_system_and_set: "US 37S",
                      equivalent_sizes: {
                        au: ["37SAU"],
                        eu: ["47SEU"],
                        uk: ["37SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "37R",
                      short: "37R",
                      long: "37R",
                      display: "37R",
                      display_with_size_set: "37R",
                      display_with_size_system: "US 37R",
                      display_with_system_and_set: "US 37R",
                      equivalent_sizes: {
                        au: ["37RAU"],
                        eu: ["47REU"],
                        uk: ["37RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "37L",
                      short: "37L",
                      long: "37L",
                      display: "37L",
                      display_with_size_set: "37L",
                      display_with_size_system: "US 37L",
                      display_with_system_and_set: "US 37L",
                      equivalent_sizes: {
                        au: ["37LAU"],
                        eu: ["47LEU"],
                        uk: ["37LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38S",
                      short: "38S",
                      long: "38S",
                      display: "38S",
                      display_with_size_set: "38S",
                      display_with_size_system: "US 38S",
                      display_with_system_and_set: "US 38S",
                      equivalent_sizes: {
                        au: ["38SAU"],
                        eu: ["48SEU"],
                        uk: ["38SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38R",
                      short: "38R",
                      long: "38R",
                      display: "38R",
                      display_with_size_set: "38R",
                      display_with_size_system: "US 38R",
                      display_with_system_and_set: "US 38R",
                      equivalent_sizes: {
                        au: ["38RAU"],
                        eu: ["48REU"],
                        uk: ["38RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38L",
                      short: "38L",
                      long: "38L",
                      display: "38L",
                      display_with_size_set: "38L",
                      display_with_size_system: "US 38L",
                      display_with_system_and_set: "US 38L",
                      equivalent_sizes: {
                        au: ["38LAU"],
                        eu: ["48LEU"],
                        uk: ["38LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "39S",
                      short: "39S",
                      long: "39S",
                      display: "39S",
                      display_with_size_set: "39S",
                      display_with_size_system: "US 39S",
                      display_with_system_and_set: "US 39S",
                      equivalent_sizes: {
                        au: ["39SAU"],
                        eu: ["49SEU"],
                        uk: ["39SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "39R",
                      short: "39R",
                      long: "39R",
                      display: "39R",
                      display_with_size_set: "39R",
                      display_with_size_system: "US 39R",
                      display_with_system_and_set: "US 39R",
                      equivalent_sizes: {
                        au: ["39RAU"],
                        eu: ["49REU"],
                        uk: ["39RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "39L",
                      short: "39L",
                      long: "39L",
                      display: "39L",
                      display_with_size_set: "39L",
                      display_with_size_system: "US 39L",
                      display_with_system_and_set: "US 39L",
                      equivalent_sizes: {
                        au: ["39LAU"],
                        eu: ["49LEU"],
                        uk: ["39LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40S",
                      short: "40S",
                      long: "40S",
                      display: "40S",
                      display_with_size_set: "40S",
                      display_with_size_system: "US 40S",
                      display_with_system_and_set: "US 40S",
                      equivalent_sizes: {
                        au: ["40SAU"],
                        eu: ["50SEU"],
                        uk: ["40SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40R",
                      short: "40R",
                      long: "40R",
                      display: "40R",
                      display_with_size_set: "40R",
                      display_with_size_system: "US 40R",
                      display_with_system_and_set: "US 40R",
                      equivalent_sizes: {
                        au: ["40RAU"],
                        eu: ["50REU"],
                        uk: ["40RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40L",
                      short: "40L",
                      long: "40L",
                      display: "40L",
                      display_with_size_set: "40L",
                      display_with_size_system: "US 40L",
                      display_with_system_and_set: "US 40L",
                      equivalent_sizes: {
                        au: ["40LAU"],
                        eu: ["50LEU"],
                        uk: ["40LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "41S",
                      short: "41S",
                      long: "41S",
                      display: "41S",
                      display_with_size_set: "41S",
                      display_with_size_system: "US 41S",
                      display_with_system_and_set: "US 41S",
                      equivalent_sizes: {
                        au: ["41SAU"],
                        eu: ["51SEU"],
                        uk: ["41SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "41R",
                      short: "41R",
                      long: "41R",
                      display: "41R",
                      display_with_size_set: "41R",
                      display_with_size_system: "US 41R",
                      display_with_system_and_set: "US 41R",
                      equivalent_sizes: {
                        au: ["41RAU"],
                        eu: ["51REU"],
                        uk: ["41RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "41L",
                      short: "41L",
                      long: "41L",
                      display: "41L",
                      display_with_size_set: "41L",
                      display_with_size_system: "US 41L",
                      display_with_system_and_set: "US 41L",
                      equivalent_sizes: {
                        au: ["41LAU"],
                        eu: ["51LEU"],
                        uk: ["41LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42S",
                      short: "42S",
                      long: "42S",
                      display: "42S",
                      display_with_size_set: "42S",
                      display_with_size_system: "US 42S",
                      display_with_system_and_set: "US 42S",
                      equivalent_sizes: {
                        au: ["42SAU"],
                        eu: ["52SEU"],
                        uk: ["42SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42R",
                      short: "42R",
                      long: "42R",
                      display: "42R",
                      display_with_size_set: "42R",
                      display_with_size_system: "US 42R",
                      display_with_system_and_set: "US 42R",
                      equivalent_sizes: {
                        au: ["42RAU"],
                        eu: ["52REU"],
                        uk: ["42RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42L",
                      short: "42L",
                      long: "42L",
                      display: "42L",
                      display_with_size_set: "42L",
                      display_with_size_system: "US 42L",
                      display_with_system_and_set: "US 42L",
                      equivalent_sizes: {
                        au: ["42LAU"],
                        eu: ["52LEU"],
                        uk: ["42LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "43S",
                      short: "43S",
                      long: "43S",
                      display: "43S",
                      display_with_size_set: "43S",
                      display_with_size_system: "US 43S",
                      display_with_system_and_set: "US 43S",
                      equivalent_sizes: {
                        au: ["43SAU"],
                        eu: ["53SEU"],
                        uk: ["43SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "43R",
                      short: "43R",
                      long: "43R",
                      display: "43R",
                      display_with_size_set: "43R",
                      display_with_size_system: "US 43R",
                      display_with_system_and_set: "US 43R",
                      equivalent_sizes: {
                        au: ["43RAU"],
                        eu: ["53REU"],
                        uk: ["43RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "43L",
                      short: "43L",
                      long: "43L",
                      display: "43L",
                      display_with_size_set: "43L",
                      display_with_size_system: "US 43L",
                      display_with_system_and_set: "US 43L",
                      equivalent_sizes: {
                        au: ["43LAU"],
                        eu: ["53LEU"],
                        uk: ["43LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44S",
                      short: "44S",
                      long: "44S",
                      display: "44S",
                      display_with_size_set: "44S",
                      display_with_size_system: "US 44S",
                      display_with_system_and_set: "US 44S",
                      equivalent_sizes: {
                        au: ["44SAU"],
                        eu: ["54SEU"],
                        uk: ["44SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44R",
                      short: "44R",
                      long: "44R",
                      display: "44R",
                      display_with_size_set: "44R",
                      display_with_size_system: "US 44R",
                      display_with_system_and_set: "US 44R",
                      equivalent_sizes: {
                        au: ["44RAU"],
                        eu: ["54REU"],
                        uk: ["44RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44L",
                      short: "44L",
                      long: "44L",
                      display: "44L",
                      display_with_size_set: "44L",
                      display_with_size_system: "US 44L",
                      display_with_system_and_set: "US 44L",
                      equivalent_sizes: {
                        au: ["44LAU"],
                        eu: ["54LEU"],
                        uk: ["44LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "45S",
                      short: "45S",
                      long: "45S",
                      display: "45S",
                      display_with_size_set: "45S",
                      display_with_size_system: "US 45S",
                      display_with_system_and_set: "US 45S",
                      equivalent_sizes: {
                        au: ["45SAU"],
                        eu: ["55SEU"],
                        uk: ["45SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "45R",
                      short: "45R",
                      long: "45R",
                      display: "45R",
                      display_with_size_set: "45R",
                      display_with_size_system: "US 45R",
                      display_with_system_and_set: "US 45R",
                      equivalent_sizes: {
                        au: ["45RAU"],
                        eu: ["55REU"],
                        uk: ["45RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "45L",
                      short: "45L",
                      long: "45L",
                      display: "45L",
                      display_with_size_set: "45L",
                      display_with_size_system: "US 45L",
                      display_with_system_and_set: "US 45L",
                      equivalent_sizes: {
                        au: ["45LAU"],
                        eu: ["55LEU"],
                        uk: ["45LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46S",
                      short: "46S",
                      long: "46S",
                      display: "46S",
                      display_with_size_set: "46S",
                      display_with_size_system: "US 46S",
                      display_with_system_and_set: "US 46S",
                      equivalent_sizes: {
                        au: ["46SAU"],
                        eu: ["56SEU"],
                        uk: ["46SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46R",
                      short: "46R",
                      long: "46R",
                      display: "46R",
                      display_with_size_set: "46R",
                      display_with_size_system: "US 46R",
                      display_with_system_and_set: "US 46R",
                      equivalent_sizes: {
                        au: ["46RAU"],
                        eu: ["56REU"],
                        uk: ["46RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46L",
                      short: "46L",
                      long: "46L",
                      display: "46L",
                      display_with_size_set: "46L",
                      display_with_size_system: "US 46L",
                      display_with_system_and_set: "US 46L",
                      equivalent_sizes: {
                        au: ["46LAU"],
                        eu: ["56LEU"],
                        uk: ["46LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "47S",
                      short: "47S",
                      long: "47S",
                      display: "47S",
                      display_with_size_set: "47S",
                      display_with_size_system: "US 47S",
                      display_with_system_and_set: "US 47S",
                      equivalent_sizes: {
                        au: ["47SAU"],
                        eu: ["57SEU"],
                        uk: ["47SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "47R",
                      short: "47R",
                      long: "47R",
                      display: "47R",
                      display_with_size_set: "47R",
                      display_with_size_system: "US 47R",
                      display_with_system_and_set: "US 47R",
                      equivalent_sizes: {
                        au: ["47RAU"],
                        eu: ["57REU"],
                        uk: ["47RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "47L",
                      short: "47L",
                      long: "47L",
                      display: "47L",
                      display_with_size_set: "47L",
                      display_with_size_system: "US 47L",
                      display_with_system_and_set: "US 47L",
                      equivalent_sizes: {
                        au: ["47LAU"],
                        eu: ["57LEU"],
                        uk: ["47LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48S",
                      short: "48S",
                      long: "48S",
                      display: "48S",
                      display_with_size_set: "48S",
                      display_with_size_system: "US 48S",
                      display_with_system_and_set: "US 48S",
                      equivalent_sizes: {
                        au: ["48SAU"],
                        eu: ["58SEU"],
                        uk: ["48SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48R",
                      short: "48R",
                      long: "48R",
                      display: "48R",
                      display_with_size_set: "48R",
                      display_with_size_system: "US 48R",
                      display_with_system_and_set: "US 48R",
                      equivalent_sizes: {
                        au: ["48RAU"],
                        eu: ["58REU"],
                        uk: ["48RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48L",
                      short: "48L",
                      long: "48L",
                      display: "48L",
                      display_with_size_set: "48L",
                      display_with_size_system: "US 48L",
                      display_with_system_and_set: "US 48L",
                      equivalent_sizes: {
                        au: ["48LAU"],
                        eu: ["58LEU"],
                        uk: ["48LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "49S",
                      short: "49S",
                      long: "49S",
                      display: "49S",
                      display_with_size_set: "49S",
                      display_with_size_system: "US 49S",
                      display_with_system_and_set: "US 49S",
                      equivalent_sizes: {
                        au: ["49SAU"],
                        eu: ["59SEU"],
                        uk: ["49SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "49R",
                      short: "49R",
                      long: "49R",
                      display: "49R",
                      display_with_size_set: "49R",
                      display_with_size_system: "US 49R",
                      display_with_system_and_set: "US 49R",
                      equivalent_sizes: {
                        au: ["49RAU"],
                        eu: ["59REU"],
                        uk: ["49RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "49L",
                      short: "49L",
                      long: "49L",
                      display: "49L",
                      display_with_size_set: "49L",
                      display_with_size_system: "US 49L",
                      display_with_system_and_set: "US 49L",
                      equivalent_sizes: {
                        au: ["49LAU"],
                        eu: ["59LEU"],
                        uk: ["49LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "50S",
                      short: "50S",
                      long: "50S",
                      display: "50S",
                      display_with_size_set: "50S",
                      display_with_size_system: "US 50S",
                      display_with_system_and_set: "US 50S",
                      equivalent_sizes: {
                        au: ["50SAU"],
                        eu: ["60SEU"],
                        uk: ["50SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "50R",
                      short: "50R",
                      long: "50R",
                      display: "50R",
                      display_with_size_set: "50R",
                      display_with_size_system: "US 50R",
                      display_with_system_and_set: "US 50R",
                      equivalent_sizes: {
                        au: ["50RAU"],
                        eu: ["60REU"],
                        uk: ["50RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "50L",
                      short: "50L",
                      long: "50L",
                      display: "50L",
                      display_with_size_set: "50L",
                      display_with_size_system: "US 50L",
                      display_with_system_and_set: "US 50L",
                      equivalent_sizes: {
                        au: ["50LAU"],
                        eu: ["60LEU"],
                        uk: ["50LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "51S",
                      short: "51S",
                      long: "51S",
                      display: "51S",
                      display_with_size_set: "51S",
                      display_with_size_system: "US 51S",
                      display_with_system_and_set: "US 51S",
                      equivalent_sizes: {
                        au: ["51SAU"],
                        eu: ["61SEU"],
                        uk: ["51SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "51R",
                      short: "51R",
                      long: "51R",
                      display: "51R",
                      display_with_size_set: "51R",
                      display_with_size_system: "US 51R",
                      display_with_system_and_set: "US 51R",
                      equivalent_sizes: {
                        au: ["51RAU"],
                        eu: ["61REU"],
                        uk: ["51RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "51L",
                      short: "51L",
                      long: "51L",
                      display: "51L",
                      display_with_size_set: "51L",
                      display_with_size_system: "US 51L",
                      display_with_system_and_set: "US 51L",
                      equivalent_sizes: {
                        au: ["51LAU"],
                        eu: ["61LEU"],
                        uk: ["51LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "52S",
                      short: "52S",
                      long: "52S",
                      display: "52S",
                      display_with_size_set: "52S",
                      display_with_size_system: "US 52S",
                      display_with_system_and_set: "US 52S",
                      equivalent_sizes: {
                        au: ["52SAU"],
                        eu: ["62SEU"],
                        uk: ["52SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "52R",
                      short: "52R",
                      long: "52R",
                      display: "52R",
                      display_with_size_set: "52R",
                      display_with_size_system: "US 52R",
                      display_with_system_and_set: "US 52R",
                      equivalent_sizes: {
                        au: ["52RAU"],
                        eu: ["62REU"],
                        uk: ["52RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "52L",
                      short: "52L",
                      long: "52L",
                      display: "52L",
                      display_with_size_set: "52L",
                      display_with_size_system: "US 52L",
                      display_with_system_and_set: "US 52L",
                      equivalent_sizes: {
                        au: ["52LAU"],
                        eu: ["62LEU"],
                        uk: ["52LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "53S",
                      short: "53S",
                      long: "53S",
                      display: "53S",
                      display_with_size_set: "53S",
                      display_with_size_system: "US 53S",
                      display_with_system_and_set: "US 53S",
                      equivalent_sizes: {
                        au: ["53SAU"],
                        eu: ["63SEU"],
                        uk: ["53SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "53R",
                      short: "53R",
                      long: "53R",
                      display: "53R",
                      display_with_size_set: "53R",
                      display_with_size_system: "US 53R",
                      display_with_system_and_set: "US 53R",
                      equivalent_sizes: {
                        au: ["53RAU"],
                        eu: ["63REU"],
                        uk: ["53RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "53L",
                      short: "53L",
                      long: "53L",
                      display: "53L",
                      display_with_size_set: "53L",
                      display_with_size_system: "US 53L",
                      display_with_system_and_set: "US 53L",
                      equivalent_sizes: {
                        au: ["53LAU"],
                        eu: ["63LEU"],
                        uk: ["53LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "54S",
                      short: "54S",
                      long: "54S",
                      display: "54S",
                      display_with_size_set: "54S",
                      display_with_size_system: "US 54S",
                      display_with_system_and_set: "US 54S",
                      equivalent_sizes: {
                        au: ["54SAU"],
                        eu: ["64SEU"],
                        uk: ["54SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "54R",
                      short: "54R",
                      long: "54R",
                      display: "54R",
                      display_with_size_set: "54R",
                      display_with_size_system: "US 54R",
                      display_with_system_and_set: "US 54R",
                      equivalent_sizes: {
                        au: ["54RAU"],
                        eu: ["64REU"],
                        uk: ["54RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "54L",
                      short: "54L",
                      long: "54L",
                      display: "54L",
                      display_with_size_set: "54L",
                      display_with_size_system: "US 54L",
                      display_with_system_and_set: "US 54L",
                      equivalent_sizes: {
                        au: ["54LAU"],
                        eu: ["64LEU"],
                        uk: ["54LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "55S",
                      short: "55S",
                      long: "55S",
                      display: "55S",
                      display_with_size_set: "55S",
                      display_with_size_system: "US 55S",
                      display_with_system_and_set: "US 55S",
                      equivalent_sizes: {
                        au: ["55SAU"],
                        eu: ["65SEU"],
                        uk: ["55SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "55R",
                      short: "55R",
                      long: "55R",
                      display: "55R",
                      display_with_size_set: "55R",
                      display_with_size_system: "US 55R",
                      display_with_system_and_set: "US 55R",
                      equivalent_sizes: {
                        au: ["55RAU"],
                        eu: ["65REU"],
                        uk: ["55RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "55L",
                      short: "55L",
                      long: "55L",
                      display: "55L",
                      display_with_size_set: "55L",
                      display_with_size_system: "US 55L",
                      display_with_system_and_set: "US 55L",
                      equivalent_sizes: {
                        au: ["55LAU"],
                        eu: ["65LEU"],
                        uk: ["55LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "56S",
                      short: "56S",
                      long: "56S",
                      display: "56S",
                      display_with_size_set: "56S",
                      display_with_size_system: "US 56S",
                      display_with_system_and_set: "US 56S",
                      equivalent_sizes: {
                        au: ["56SAU"],
                        eu: ["66SEU"],
                        uk: ["56SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "56R",
                      short: "56R",
                      long: "56R",
                      display: "56R",
                      display_with_size_set: "56R",
                      display_with_size_system: "US 56R",
                      display_with_system_and_set: "US 56R",
                      equivalent_sizes: {
                        au: ["56RAU"],
                        eu: ["66REU"],
                        uk: ["56RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "56L",
                      short: "56L",
                      long: "56L",
                      display: "56L",
                      display_with_size_set: "56L",
                      display_with_size_system: "US 56L",
                      display_with_system_and_set: "US 56L",
                      equivalent_sizes: {
                        au: ["56LAU"],
                        eu: ["66LEU"],
                        uk: ["56LUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "34RAU",
                      short: "34R",
                      long: "34R",
                      display: "34R",
                      display_with_size_set: "34R",
                      display_with_size_system: "AU 34R",
                      display_with_system_and_set: "AU 34R",
                      equivalent_sizes: {
                        us: ["34R"],
                        eu: ["44REU"],
                        uk: ["34RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34SAU",
                      short: "34S",
                      long: "34S",
                      display: "34S",
                      display_with_size_set: "34S",
                      display_with_size_system: "AU 34S",
                      display_with_system_and_set: "AU 34S",
                      equivalent_sizes: {
                        us: ["34S"],
                        eu: ["44SEU"],
                        uk: ["34SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34LAU",
                      short: "34L",
                      long: "34L",
                      display: "34L",
                      display_with_size_set: "34L",
                      display_with_size_system: "AU 34L",
                      display_with_system_and_set: "AU 34L",
                      equivalent_sizes: {
                        us: ["34L"],
                        eu: ["44LEU"],
                        uk: ["34LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "35RAU",
                      short: "35R",
                      long: "35R",
                      display: "35R",
                      display_with_size_set: "35R",
                      display_with_size_system: "AU 35R",
                      display_with_system_and_set: "AU 35R",
                      equivalent_sizes: {
                        us: ["35R"],
                        eu: ["45REU"],
                        uk: ["35RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "35SAU",
                      short: "35S",
                      long: "35S",
                      display: "35S",
                      display_with_size_set: "35S",
                      display_with_size_system: "AU 35S",
                      display_with_system_and_set: "AU 35S",
                      equivalent_sizes: {
                        us: ["35S"],
                        eu: ["45SEU"],
                        uk: ["35SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "35LAU",
                      short: "35L",
                      long: "35L",
                      display: "35L",
                      display_with_size_set: "35L",
                      display_with_size_system: "AU 35L",
                      display_with_system_and_set: "AU 35L",
                      equivalent_sizes: {
                        us: ["35L"],
                        eu: ["45LEU"],
                        uk: ["35LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36RAU",
                      short: "36R",
                      long: "36R",
                      display: "36R",
                      display_with_size_set: "36R",
                      display_with_size_system: "AU 36R",
                      display_with_system_and_set: "AU 36R",
                      equivalent_sizes: {
                        us: ["36R"],
                        eu: ["46REU"],
                        uk: ["36RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36SAU",
                      short: "36S",
                      long: "36S",
                      display: "36S",
                      display_with_size_set: "36S",
                      display_with_size_system: "AU 36S",
                      display_with_system_and_set: "AU 36S",
                      equivalent_sizes: {
                        us: ["36S"],
                        eu: ["46SEU"],
                        uk: ["36SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36LAU",
                      short: "36L",
                      long: "36L",
                      display: "36L",
                      display_with_size_set: "36L",
                      display_with_size_system: "AU 36L",
                      display_with_system_and_set: "AU 36L",
                      equivalent_sizes: {
                        us: ["36L"],
                        eu: ["46LEU"],
                        uk: ["36LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "37RAU",
                      short: "37R",
                      long: "37R",
                      display: "37R",
                      display_with_size_set: "37R",
                      display_with_size_system: "AU 37R",
                      display_with_system_and_set: "AU 37R",
                      equivalent_sizes: {
                        us: ["37R"],
                        eu: ["47REU"],
                        uk: ["37RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "37SAU",
                      short: "37S",
                      long: "37S",
                      display: "37S",
                      display_with_size_set: "37S",
                      display_with_size_system: "AU 37S",
                      display_with_system_and_set: "AU 37S",
                      equivalent_sizes: {
                        us: ["37S"],
                        eu: ["47SEU"],
                        uk: ["37SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "37LAU",
                      short: "37L",
                      long: "37L",
                      display: "37L",
                      display_with_size_set: "37L",
                      display_with_size_system: "AU 37L",
                      display_with_system_and_set: "AU 37L",
                      equivalent_sizes: {
                        us: ["37L"],
                        eu: ["47LEU"],
                        uk: ["37LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38RAU",
                      short: "38R",
                      long: "38R",
                      display: "38R",
                      display_with_size_set: "38R",
                      display_with_size_system: "AU 38R",
                      display_with_system_and_set: "AU 38R",
                      equivalent_sizes: {
                        us: ["38R"],
                        eu: ["48REU"],
                        uk: ["38RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38SAU",
                      short: "38S",
                      long: "38S",
                      display: "38S",
                      display_with_size_set: "38S",
                      display_with_size_system: "AU 38S",
                      display_with_system_and_set: "AU 38S",
                      equivalent_sizes: {
                        us: ["38S"],
                        eu: ["48SEU"],
                        uk: ["38SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38LAU",
                      short: "38L",
                      long: "38L",
                      display: "38L",
                      display_with_size_set: "38L",
                      display_with_size_system: "AU 38L",
                      display_with_system_and_set: "AU 38L",
                      equivalent_sizes: {
                        us: ["38L"],
                        eu: ["48LEU"],
                        uk: ["38LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "39RAU",
                      short: "39R",
                      long: "39R",
                      display: "39R",
                      display_with_size_set: "39R",
                      display_with_size_system: "AU 39R",
                      display_with_system_and_set: "AU 39R",
                      equivalent_sizes: {
                        us: ["39R"],
                        eu: ["49REU"],
                        uk: ["39RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "39SAU",
                      short: "39S",
                      long: "39S",
                      display: "39S",
                      display_with_size_set: "39S",
                      display_with_size_system: "AU 39S",
                      display_with_system_and_set: "AU 39S",
                      equivalent_sizes: {
                        us: ["39S"],
                        eu: ["49SEU"],
                        uk: ["39SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "39LAU",
                      short: "39L",
                      long: "39L",
                      display: "39L",
                      display_with_size_set: "39L",
                      display_with_size_system: "AU 39L",
                      display_with_system_and_set: "AU 39L",
                      equivalent_sizes: {
                        us: ["39L"],
                        eu: ["49LEU"],
                        uk: ["39LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "40RAU",
                      short: "40R",
                      long: "40R",
                      display: "40R",
                      display_with_size_set: "40R",
                      display_with_size_system: "AU 40R",
                      display_with_system_and_set: "AU 40R",
                      equivalent_sizes: {
                        us: ["40R"],
                        eu: ["50REU"],
                        uk: ["40RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "40SAU",
                      short: "40S",
                      long: "40S",
                      display: "40S",
                      display_with_size_set: "40S",
                      display_with_size_system: "AU 40S",
                      display_with_system_and_set: "AU 40S",
                      equivalent_sizes: {
                        us: ["40S"],
                        eu: ["50SEU"],
                        uk: ["40SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "40LAU",
                      short: "40L",
                      long: "40L",
                      display: "40L",
                      display_with_size_set: "40L",
                      display_with_size_system: "AU 40L",
                      display_with_system_and_set: "AU 40L",
                      equivalent_sizes: {
                        us: ["40L"],
                        eu: ["50LEU"],
                        uk: ["40LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "41RAU",
                      short: "41R",
                      long: "41R",
                      display: "41R",
                      display_with_size_set: "41R",
                      display_with_size_system: "AU 41R",
                      display_with_system_and_set: "AU 41R",
                      equivalent_sizes: {
                        us: ["41R"],
                        eu: ["51REU"],
                        uk: ["41RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "41SAU",
                      short: "41S",
                      long: "41S",
                      display: "41S",
                      display_with_size_set: "41S",
                      display_with_size_system: "AU 41S",
                      display_with_system_and_set: "AU 41S",
                      equivalent_sizes: {
                        us: ["41S"],
                        eu: ["51SEU"],
                        uk: ["41SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "41LAU",
                      short: "41L",
                      long: "41L",
                      display: "41L",
                      display_with_size_set: "41L",
                      display_with_size_system: "AU 41L",
                      display_with_system_and_set: "AU 41L",
                      equivalent_sizes: {
                        us: ["41L"],
                        eu: ["51LEU"],
                        uk: ["41LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "42RAU",
                      short: "42R",
                      long: "42R",
                      display: "42R",
                      display_with_size_set: "42R",
                      display_with_size_system: "AU 42R",
                      display_with_system_and_set: "AU 42R",
                      equivalent_sizes: {
                        us: ["42R"],
                        eu: ["52REU"],
                        uk: ["42RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "42SAU",
                      short: "42S",
                      long: "42S",
                      display: "42S",
                      display_with_size_set: "42S",
                      display_with_size_system: "AU 42S",
                      display_with_system_and_set: "AU 42S",
                      equivalent_sizes: {
                        us: ["42S"],
                        eu: ["52SEU"],
                        uk: ["42SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "42LAU",
                      short: "42L",
                      long: "42L",
                      display: "42L",
                      display_with_size_set: "42L",
                      display_with_size_system: "AU 42L",
                      display_with_system_and_set: "AU 42L",
                      equivalent_sizes: {
                        us: ["42L"],
                        eu: ["52LEU"],
                        uk: ["42LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "43RAU",
                      short: "43R",
                      long: "43R",
                      display: "43R",
                      display_with_size_set: "43R",
                      display_with_size_system: "AU 43R",
                      display_with_system_and_set: "AU 43R",
                      equivalent_sizes: {
                        us: ["43R"],
                        eu: ["53REU"],
                        uk: ["43RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "43SAU",
                      short: "43S",
                      long: "43S",
                      display: "43S",
                      display_with_size_set: "43S",
                      display_with_size_system: "AU 43S",
                      display_with_system_and_set: "AU 43S",
                      equivalent_sizes: {
                        us: ["43S"],
                        eu: ["53SEU"],
                        uk: ["43SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "43LAU",
                      short: "43L",
                      long: "43L",
                      display: "43L",
                      display_with_size_set: "43L",
                      display_with_size_system: "AU 43L",
                      display_with_system_and_set: "AU 43L",
                      equivalent_sizes: {
                        us: ["43L"],
                        eu: ["53LEU"],
                        uk: ["43LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "44RAU",
                      short: "44R",
                      long: "44R",
                      display: "44R",
                      display_with_size_set: "44R",
                      display_with_size_system: "AU 44R",
                      display_with_system_and_set: "AU 44R",
                      equivalent_sizes: {
                        us: ["44R"],
                        eu: ["54REU"],
                        uk: ["44RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "44SAU",
                      short: "44S",
                      long: "44S",
                      display: "44S",
                      display_with_size_set: "44S",
                      display_with_size_system: "AU 44S",
                      display_with_system_and_set: "AU 44S",
                      equivalent_sizes: {
                        us: ["44S"],
                        eu: ["54SEU"],
                        uk: ["44SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "44LAU",
                      short: "44L",
                      long: "44L",
                      display: "44L",
                      display_with_size_set: "44L",
                      display_with_size_system: "AU 44L",
                      display_with_system_and_set: "AU 44L",
                      equivalent_sizes: {
                        us: ["44L"],
                        eu: ["54LEU"],
                        uk: ["44LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "45RAU",
                      short: "45R",
                      long: "45R",
                      display: "45R",
                      display_with_size_set: "45R",
                      display_with_size_system: "AU 45R",
                      display_with_system_and_set: "AU 45R",
                      equivalent_sizes: {
                        us: ["45R"],
                        eu: ["55REU"],
                        uk: ["45RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "45SAU",
                      short: "45S",
                      long: "45S",
                      display: "45S",
                      display_with_size_set: "45S",
                      display_with_size_system: "AU 45S",
                      display_with_system_and_set: "AU 45S",
                      equivalent_sizes: {
                        us: ["45S"],
                        eu: ["55SEU"],
                        uk: ["45SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "45LAU",
                      short: "45L",
                      long: "45L",
                      display: "45L",
                      display_with_size_set: "45L",
                      display_with_size_system: "AU 45L",
                      display_with_system_and_set: "AU 45L",
                      equivalent_sizes: {
                        us: ["45L"],
                        eu: ["55LEU"],
                        uk: ["45LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "46RAU",
                      short: "46R",
                      long: "46R",
                      display: "46R",
                      display_with_size_set: "46R",
                      display_with_size_system: "AU 46R",
                      display_with_system_and_set: "AU 46R",
                      equivalent_sizes: {
                        us: ["46R"],
                        eu: ["56REU"],
                        uk: ["46RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "46SAU",
                      short: "46S",
                      long: "46S",
                      display: "46S",
                      display_with_size_set: "46S",
                      display_with_size_system: "AU 46S",
                      display_with_system_and_set: "AU 46S",
                      equivalent_sizes: {
                        us: ["46S"],
                        eu: ["56SEU"],
                        uk: ["46SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "46LAU",
                      short: "46L",
                      long: "46L",
                      display: "46L",
                      display_with_size_set: "46L",
                      display_with_size_system: "AU 46L",
                      display_with_system_and_set: "AU 46L",
                      equivalent_sizes: {
                        us: ["46L"],
                        eu: ["56LEU"],
                        uk: ["46LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "47RAU",
                      short: "47R",
                      long: "47R",
                      display: "47R",
                      display_with_size_set: "47R",
                      display_with_size_system: "AU 47R",
                      display_with_system_and_set: "AU 47R",
                      equivalent_sizes: {
                        us: ["47R"],
                        eu: ["57REU"],
                        uk: ["47RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "47SAU",
                      short: "47S",
                      long: "47S",
                      display: "47S",
                      display_with_size_set: "47S",
                      display_with_size_system: "AU 47S",
                      display_with_system_and_set: "AU 47S",
                      equivalent_sizes: {
                        us: ["47S"],
                        eu: ["57SEU"],
                        uk: ["47SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "47LAU",
                      short: "47L",
                      long: "47L",
                      display: "47L",
                      display_with_size_set: "47L",
                      display_with_size_system: "AU 47L",
                      display_with_system_and_set: "AU 47L",
                      equivalent_sizes: {
                        us: ["47L"],
                        eu: ["57LEU"],
                        uk: ["47LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "48RAU",
                      short: "48R",
                      long: "48R",
                      display: "48R",
                      display_with_size_set: "48R",
                      display_with_size_system: "AU 48R",
                      display_with_system_and_set: "AU 48R",
                      equivalent_sizes: {
                        us: ["48R"],
                        eu: ["58REU"],
                        uk: ["48RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "48SAU",
                      short: "48S",
                      long: "48S",
                      display: "48S",
                      display_with_size_set: "48S",
                      display_with_size_system: "AU 48S",
                      display_with_system_and_set: "AU 48S",
                      equivalent_sizes: {
                        us: ["48S"],
                        eu: ["58SEU"],
                        uk: ["48SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "48LAU",
                      short: "48L",
                      long: "48L",
                      display: "48L",
                      display_with_size_set: "48L",
                      display_with_size_system: "AU 48L",
                      display_with_system_and_set: "AU 48L",
                      equivalent_sizes: {
                        us: ["48L"],
                        eu: ["58LEU"],
                        uk: ["48LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "49RAU",
                      short: "49R",
                      long: "49R",
                      display: "49R",
                      display_with_size_set: "49R",
                      display_with_size_system: "AU 49R",
                      display_with_system_and_set: "AU 49R",
                      equivalent_sizes: {
                        us: ["49R"],
                        eu: ["59REU"],
                        uk: ["49RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "49SAU",
                      short: "49S",
                      long: "49S",
                      display: "49S",
                      display_with_size_set: "49S",
                      display_with_size_system: "AU 49S",
                      display_with_system_and_set: "AU 49S",
                      equivalent_sizes: {
                        us: ["49S"],
                        eu: ["59SEU"],
                        uk: ["49SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "49LAU",
                      short: "49L",
                      long: "49L",
                      display: "49L",
                      display_with_size_set: "49L",
                      display_with_size_system: "AU 49L",
                      display_with_system_and_set: "AU 49L",
                      equivalent_sizes: {
                        us: ["49L"],
                        eu: ["59LEU"],
                        uk: ["49LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "50RAU",
                      short: "50R",
                      long: "50R",
                      display: "50R",
                      display_with_size_set: "50R",
                      display_with_size_system: "AU 50R",
                      display_with_system_and_set: "AU 50R",
                      equivalent_sizes: {
                        us: ["50R"],
                        eu: ["60REU"],
                        uk: ["50RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "50SAU",
                      short: "50S",
                      long: "50S",
                      display: "50S",
                      display_with_size_set: "50S",
                      display_with_size_system: "AU 50S",
                      display_with_system_and_set: "AU 50S",
                      equivalent_sizes: {
                        us: ["50S"],
                        eu: ["60SEU"],
                        uk: ["50SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "50LAU",
                      short: "50L",
                      long: "50L",
                      display: "50L",
                      display_with_size_set: "50L",
                      display_with_size_system: "AU 50L",
                      display_with_system_and_set: "AU 50L",
                      equivalent_sizes: {
                        us: ["50L"],
                        eu: ["60LEU"],
                        uk: ["50LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "51RAU",
                      short: "51R",
                      long: "51R",
                      display: "51R",
                      display_with_size_set: "51R",
                      display_with_size_system: "AU 51R",
                      display_with_system_and_set: "AU 51R",
                      equivalent_sizes: {
                        us: ["51R"],
                        eu: ["61REU"],
                        uk: ["51RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "51SAU",
                      short: "51S",
                      long: "51S",
                      display: "51S",
                      display_with_size_set: "51S",
                      display_with_size_system: "AU 51S",
                      display_with_system_and_set: "AU 51S",
                      equivalent_sizes: {
                        us: ["51S"],
                        eu: ["61SEU"],
                        uk: ["51SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "51LAU",
                      short: "51L",
                      long: "51L",
                      display: "51L",
                      display_with_size_set: "51L",
                      display_with_size_system: "AU 51L",
                      display_with_system_and_set: "AU 51L",
                      equivalent_sizes: {
                        us: ["51L"],
                        eu: ["61LEU"],
                        uk: ["51LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "52RAU",
                      short: "52R",
                      long: "52R",
                      display: "52R",
                      display_with_size_set: "52R",
                      display_with_size_system: "AU 52R",
                      display_with_system_and_set: "AU 52R",
                      equivalent_sizes: {
                        us: ["52R"],
                        eu: ["62REU"],
                        uk: ["52RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "52SAU",
                      short: "52S",
                      long: "52S",
                      display: "52S",
                      display_with_size_set: "52S",
                      display_with_size_system: "AU 52S",
                      display_with_system_and_set: "AU 52S",
                      equivalent_sizes: {
                        us: ["52S"],
                        eu: ["62SEU"],
                        uk: ["52SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "52LAU",
                      short: "52L",
                      long: "52L",
                      display: "52L",
                      display_with_size_set: "52L",
                      display_with_size_system: "AU 52L",
                      display_with_system_and_set: "AU 52L",
                      equivalent_sizes: {
                        us: ["52L"],
                        eu: ["62LEU"],
                        uk: ["52LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "53RAU",
                      short: "53R",
                      long: "53R",
                      display: "53R",
                      display_with_size_set: "53R",
                      display_with_size_system: "AU 53R",
                      display_with_system_and_set: "AU 53R",
                      equivalent_sizes: {
                        us: ["53R"],
                        eu: ["63REU"],
                        uk: ["53RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "53SAU",
                      short: "53S",
                      long: "53S",
                      display: "53S",
                      display_with_size_set: "53S",
                      display_with_size_system: "AU 53S",
                      display_with_system_and_set: "AU 53S",
                      equivalent_sizes: {
                        us: ["53S"],
                        eu: ["63SEU"],
                        uk: ["53SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "53LAU",
                      short: "53L",
                      long: "53L",
                      display: "53L",
                      display_with_size_set: "53L",
                      display_with_size_system: "AU 53L",
                      display_with_system_and_set: "AU 53L",
                      equivalent_sizes: {
                        us: ["53L"],
                        eu: ["63LEU"],
                        uk: ["53LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "54RAU",
                      short: "54R",
                      long: "54R",
                      display: "54R",
                      display_with_size_set: "54R",
                      display_with_size_system: "AU 54R",
                      display_with_system_and_set: "AU 54R",
                      equivalent_sizes: {
                        us: ["54R"],
                        eu: ["64REU"],
                        uk: ["54RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "54SAU",
                      short: "54S",
                      long: "54S",
                      display: "54S",
                      display_with_size_set: "54S",
                      display_with_size_system: "AU 54S",
                      display_with_system_and_set: "AU 54S",
                      equivalent_sizes: {
                        us: ["54S"],
                        eu: ["64SEU"],
                        uk: ["54SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "54LAU",
                      short: "54L",
                      long: "54L",
                      display: "54L",
                      display_with_size_set: "54L",
                      display_with_size_system: "AU 54L",
                      display_with_system_and_set: "AU 54L",
                      equivalent_sizes: {
                        us: ["54L"],
                        eu: ["64LEU"],
                        uk: ["54LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "55RAU",
                      short: "55R",
                      long: "55R",
                      display: "55R",
                      display_with_size_set: "55R",
                      display_with_size_system: "AU 55R",
                      display_with_system_and_set: "AU 55R",
                      equivalent_sizes: {
                        us: ["55R"],
                        eu: ["65REU"],
                        uk: ["55RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "55SAU",
                      short: "55S",
                      long: "55S",
                      display: "55S",
                      display_with_size_set: "55S",
                      display_with_size_system: "AU 55S",
                      display_with_system_and_set: "AU 55S",
                      equivalent_sizes: {
                        us: ["55S"],
                        eu: ["65SEU"],
                        uk: ["55SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "55LAU",
                      short: "55L",
                      long: "55L",
                      display: "55L",
                      display_with_size_set: "55L",
                      display_with_size_system: "AU 55L",
                      display_with_system_and_set: "AU 55L",
                      equivalent_sizes: {
                        us: ["55L"],
                        eu: ["65LEU"],
                        uk: ["55LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "56RAU",
                      short: "56R",
                      long: "56R",
                      display: "56R",
                      display_with_size_set: "56R",
                      display_with_size_system: "AU 56R",
                      display_with_system_and_set: "AU 56R",
                      equivalent_sizes: {
                        us: ["56R"],
                        eu: ["66REU"],
                        uk: ["56RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "56SAU",
                      short: "56S",
                      long: "56S",
                      display: "56S",
                      display_with_size_set: "56S",
                      display_with_size_system: "AU 56S",
                      display_with_system_and_set: "AU 56S",
                      equivalent_sizes: {
                        us: ["56S"],
                        eu: ["66SEU"],
                        uk: ["56SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "56LAU",
                      short: "56L",
                      long: "56L",
                      display: "56L",
                      display_with_size_set: "56L",
                      display_with_size_system: "AU 56L",
                      display_with_system_and_set: "AU 56L",
                      equivalent_sizes: {
                        us: ["56L"],
                        eu: ["66LEU"],
                        uk: ["56LUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "44REU",
                      short: "44R",
                      long: "44R",
                      display: "44R",
                      display_with_size_set: "44R",
                      display_with_size_system: "EU 44R",
                      display_with_system_and_set: "EU 44R",
                      equivalent_sizes: {
                        us: ["34R"],
                        au: ["34RAU"],
                        uk: ["34RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44SEU",
                      short: "44S",
                      long: "44S",
                      display: "44S",
                      display_with_size_set: "44S",
                      display_with_size_system: "EU 44S",
                      display_with_system_and_set: "EU 44S",
                      equivalent_sizes: {
                        us: ["34S"],
                        au: ["34SAU"],
                        uk: ["34SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "44LEU",
                      short: "44L",
                      long: "44L",
                      display: "44L",
                      display_with_size_set: "44L",
                      display_with_size_system: "EU 44L",
                      display_with_system_and_set: "EU 44L",
                      equivalent_sizes: {
                        us: ["34L"],
                        au: ["34LAU"],
                        uk: ["34LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "45REU",
                      short: "45R",
                      long: "45R",
                      display: "45R",
                      display_with_size_set: "45R",
                      display_with_size_system: "EU 45R",
                      display_with_system_and_set: "EU 45R",
                      equivalent_sizes: {
                        us: ["35R"],
                        au: ["35RAU"],
                        uk: ["35RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "45SEU",
                      short: "45S",
                      long: "45S",
                      display: "45S",
                      display_with_size_set: "45S",
                      display_with_size_system: "EU 45S",
                      display_with_system_and_set: "EU 45S",
                      equivalent_sizes: {
                        us: ["35S"],
                        au: ["35SAU"],
                        uk: ["35SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "45LEU",
                      short: "45L",
                      long: "45L",
                      display: "45L",
                      display_with_size_set: "45L",
                      display_with_size_system: "EU 45L",
                      display_with_system_and_set: "EU 45L",
                      equivalent_sizes: {
                        us: ["35L"],
                        au: ["35LAU"],
                        uk: ["35LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46REU",
                      short: "46R",
                      long: "46R",
                      display: "46R",
                      display_with_size_set: "46R",
                      display_with_size_system: "EU 46R",
                      display_with_system_and_set: "EU 46R",
                      equivalent_sizes: {
                        us: ["36R"],
                        au: ["36RAU"],
                        uk: ["36RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46SEU",
                      short: "46S",
                      long: "46S",
                      display: "46S",
                      display_with_size_set: "46S",
                      display_with_size_system: "EU 46S",
                      display_with_system_and_set: "EU 46S",
                      equivalent_sizes: {
                        us: ["36S"],
                        au: ["36SAU"],
                        uk: ["36SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "46LEU",
                      short: "46L",
                      long: "46L",
                      display: "46L",
                      display_with_size_set: "46L",
                      display_with_size_system: "EU 46L",
                      display_with_system_and_set: "EU 46L",
                      equivalent_sizes: {
                        us: ["36L"],
                        au: ["36LAU"],
                        uk: ["36LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "47REU",
                      short: "47R",
                      long: "47R",
                      display: "47R",
                      display_with_size_set: "47R",
                      display_with_size_system: "EU 47R",
                      display_with_system_and_set: "EU 47R",
                      equivalent_sizes: {
                        us: ["37R"],
                        au: ["37RAU"],
                        uk: ["37RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "47SEU",
                      short: "47S",
                      long: "47S",
                      display: "47S",
                      display_with_size_set: "47S",
                      display_with_size_system: "EU 47S",
                      display_with_system_and_set: "EU 47S",
                      equivalent_sizes: {
                        us: ["37S"],
                        au: ["37SAU"],
                        uk: ["37SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "47LEU",
                      short: "47L",
                      long: "47L",
                      display: "47L",
                      display_with_size_set: "47L",
                      display_with_size_system: "EU 47L",
                      display_with_system_and_set: "EU 47L",
                      equivalent_sizes: {
                        us: ["37L"],
                        au: ["37LAU"],
                        uk: ["37LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48REU",
                      short: "48R",
                      long: "48R",
                      display: "48R",
                      display_with_size_set: "48R",
                      display_with_size_system: "EU 48R",
                      display_with_system_and_set: "EU 48R",
                      equivalent_sizes: {
                        us: ["38R"],
                        au: ["38RAU"],
                        uk: ["38RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48SEU",
                      short: "48S",
                      long: "48S",
                      display: "48S",
                      display_with_size_set: "48S",
                      display_with_size_system: "EU 48S",
                      display_with_system_and_set: "EU 48S",
                      equivalent_sizes: {
                        us: ["38S"],
                        au: ["38SAU"],
                        uk: ["38SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "48LEU",
                      short: "48L",
                      long: "48L",
                      display: "48L",
                      display_with_size_set: "48L",
                      display_with_size_system: "EU 48L",
                      display_with_system_and_set: "EU 48L",
                      equivalent_sizes: {
                        us: ["38L"],
                        au: ["38LAU"],
                        uk: ["38LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "49REU",
                      short: "49R",
                      long: "49R",
                      display: "49R",
                      display_with_size_set: "49R",
                      display_with_size_system: "EU 49R",
                      display_with_system_and_set: "EU 49R",
                      equivalent_sizes: {
                        us: ["39R"],
                        au: ["39RAU"],
                        uk: ["39RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "49SEU",
                      short: "49S",
                      long: "49S",
                      display: "49S",
                      display_with_size_set: "49S",
                      display_with_size_system: "EU 49S",
                      display_with_system_and_set: "EU 49S",
                      equivalent_sizes: {
                        us: ["39S"],
                        au: ["39SAU"],
                        uk: ["39SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "49LEU",
                      short: "49L",
                      long: "49L",
                      display: "49L",
                      display_with_size_set: "49L",
                      display_with_size_system: "EU 49L",
                      display_with_system_and_set: "EU 49L",
                      equivalent_sizes: {
                        us: ["39L"],
                        au: ["39LAU"],
                        uk: ["39LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50REU",
                      short: "50R",
                      long: "50R",
                      display: "50R",
                      display_with_size_set: "50R",
                      display_with_size_system: "EU 50R",
                      display_with_system_and_set: "EU 50R",
                      equivalent_sizes: {
                        us: ["40R"],
                        au: ["40RAU"],
                        uk: ["40RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50SEU",
                      short: "50S",
                      long: "50S",
                      display: "50S",
                      display_with_size_set: "50S",
                      display_with_size_system: "EU 50S",
                      display_with_system_and_set: "EU 50S",
                      equivalent_sizes: {
                        us: ["40S"],
                        au: ["40SAU"],
                        uk: ["40SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "50LEU",
                      short: "50L",
                      long: "50L",
                      display: "50L",
                      display_with_size_set: "50L",
                      display_with_size_system: "EU 50L",
                      display_with_system_and_set: "EU 50L",
                      equivalent_sizes: {
                        us: ["40L"],
                        au: ["40LAU"],
                        uk: ["40LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "51REU",
                      short: "51R",
                      long: "51R",
                      display: "51R",
                      display_with_size_set: "51R",
                      display_with_size_system: "EU 51R",
                      display_with_system_and_set: "EU 51R",
                      equivalent_sizes: {
                        us: ["41R"],
                        au: ["41RAU"],
                        uk: ["41RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "51SEU",
                      short: "51S",
                      long: "51S",
                      display: "51S",
                      display_with_size_set: "51S",
                      display_with_size_system: "EU 51S",
                      display_with_system_and_set: "EU 51S",
                      equivalent_sizes: {
                        us: ["41S"],
                        au: ["41SAU"],
                        uk: ["41SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "51LEU",
                      short: "51L",
                      long: "51L",
                      display: "51L",
                      display_with_size_set: "51L",
                      display_with_size_system: "EU 51L",
                      display_with_system_and_set: "EU 51L",
                      equivalent_sizes: {
                        us: ["41L"],
                        au: ["41LAU"],
                        uk: ["41LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52REU",
                      short: "52R",
                      long: "52R",
                      display: "52R",
                      display_with_size_set: "52R",
                      display_with_size_system: "EU 52R",
                      display_with_system_and_set: "EU 52R",
                      equivalent_sizes: {
                        us: ["42R"],
                        au: ["42RAU"],
                        uk: ["42RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52SEU",
                      short: "52S",
                      long: "52S",
                      display: "52S",
                      display_with_size_set: "52S",
                      display_with_size_system: "EU 52S",
                      display_with_system_and_set: "EU 52S",
                      equivalent_sizes: {
                        us: ["42S"],
                        au: ["42SAU"],
                        uk: ["42SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "52LEU",
                      short: "52L",
                      long: "52L",
                      display: "52L",
                      display_with_size_set: "52L",
                      display_with_size_system: "EU 52L",
                      display_with_system_and_set: "EU 52L",
                      equivalent_sizes: {
                        us: ["42L"],
                        au: ["42LAU"],
                        uk: ["42LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "53REU",
                      short: "53R",
                      long: "53R",
                      display: "53R",
                      display_with_size_set: "53R",
                      display_with_size_system: "EU 53R",
                      display_with_system_and_set: "EU 53R",
                      equivalent_sizes: {
                        us: ["43R"],
                        au: ["43RAU"],
                        uk: ["43RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "53SEU",
                      short: "53S",
                      long: "53S",
                      display: "53S",
                      display_with_size_set: "53S",
                      display_with_size_system: "EU 53S",
                      display_with_system_and_set: "EU 53S",
                      equivalent_sizes: {
                        us: ["43S"],
                        au: ["43SAU"],
                        uk: ["43SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "53LEU",
                      short: "53L",
                      long: "53L",
                      display: "53L",
                      display_with_size_set: "53L",
                      display_with_size_system: "EU 53L",
                      display_with_system_and_set: "EU 53L",
                      equivalent_sizes: {
                        us: ["43L"],
                        au: ["43LAU"],
                        uk: ["43LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54REU",
                      short: "54R",
                      long: "54R",
                      display: "54R",
                      display_with_size_set: "54R",
                      display_with_size_system: "EU 54R",
                      display_with_system_and_set: "EU 54R",
                      equivalent_sizes: {
                        us: ["44R"],
                        au: ["44RAU"],
                        uk: ["44RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54SEU",
                      short: "54S",
                      long: "54S",
                      display: "54S",
                      display_with_size_set: "54S",
                      display_with_size_system: "EU 54S",
                      display_with_system_and_set: "EU 54S",
                      equivalent_sizes: {
                        us: ["44S"],
                        au: ["44SAU"],
                        uk: ["44SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "54LEU",
                      short: "54L",
                      long: "54L",
                      display: "54L",
                      display_with_size_set: "54L",
                      display_with_size_system: "EU 54L",
                      display_with_system_and_set: "EU 54L",
                      equivalent_sizes: {
                        us: ["44L"],
                        au: ["44LAU"],
                        uk: ["44LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "55REU",
                      short: "55R",
                      long: "55R",
                      display: "55R",
                      display_with_size_set: "55R",
                      display_with_size_system: "EU 55R",
                      display_with_system_and_set: "EU 55R",
                      equivalent_sizes: {
                        us: ["45R"],
                        au: ["45RAU"],
                        uk: ["45RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "55SEU",
                      short: "55S",
                      long: "55S",
                      display: "55S",
                      display_with_size_set: "55S",
                      display_with_size_system: "EU 55S",
                      display_with_system_and_set: "EU 55S",
                      equivalent_sizes: {
                        us: ["45S"],
                        au: ["45SAU"],
                        uk: ["45SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "55LEU",
                      short: "55L",
                      long: "55L",
                      display: "55L",
                      display_with_size_set: "55L",
                      display_with_size_system: "EU 55L",
                      display_with_system_and_set: "EU 55L",
                      equivalent_sizes: {
                        us: ["45L"],
                        au: ["45LAU"],
                        uk: ["45LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56REU",
                      short: "56R",
                      long: "56R",
                      display: "56R",
                      display_with_size_set: "56R",
                      display_with_size_system: "EU 56R",
                      display_with_system_and_set: "EU 56R",
                      equivalent_sizes: {
                        us: ["46R"],
                        au: ["46RAU"],
                        uk: ["46RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56SEU",
                      short: "56S",
                      long: "56S",
                      display: "56S",
                      display_with_size_set: "56S",
                      display_with_size_system: "EU 56S",
                      display_with_system_and_set: "EU 56S",
                      equivalent_sizes: {
                        us: ["46S"],
                        au: ["46SAU"],
                        uk: ["46SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "56LEU",
                      short: "56L",
                      long: "56L",
                      display: "56L",
                      display_with_size_set: "56L",
                      display_with_size_system: "EU 56L",
                      display_with_system_and_set: "EU 56L",
                      equivalent_sizes: {
                        us: ["46L"],
                        au: ["46LAU"],
                        uk: ["46LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "57REU",
                      short: "57R",
                      long: "57R",
                      display: "57R",
                      display_with_size_set: "57R",
                      display_with_size_system: "EU 57R",
                      display_with_system_and_set: "EU 57R",
                      equivalent_sizes: {
                        us: ["47R"],
                        au: ["47RAU"],
                        uk: ["47RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "57SEU",
                      short: "57S",
                      long: "57S",
                      display: "57S",
                      display_with_size_set: "57S",
                      display_with_size_system: "EU 57S",
                      display_with_system_and_set: "EU 57S",
                      equivalent_sizes: {
                        us: ["47S"],
                        au: ["47SAU"],
                        uk: ["47SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "57LEU",
                      short: "57L",
                      long: "57L",
                      display: "57L",
                      display_with_size_set: "57L",
                      display_with_size_system: "EU 57L",
                      display_with_system_and_set: "EU 57L",
                      equivalent_sizes: {
                        us: ["47L"],
                        au: ["47LAU"],
                        uk: ["47LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58REU",
                      short: "58R",
                      long: "58R",
                      display: "58R",
                      display_with_size_set: "58R",
                      display_with_size_system: "EU 58R",
                      display_with_system_and_set: "EU 58R",
                      equivalent_sizes: {
                        us: ["48R"],
                        au: ["48RAU"],
                        uk: ["48RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58SEU",
                      short: "58S",
                      long: "58S",
                      display: "58S",
                      display_with_size_set: "58S",
                      display_with_size_system: "EU 58S",
                      display_with_system_and_set: "EU 58S",
                      equivalent_sizes: {
                        us: ["48S"],
                        au: ["48SAU"],
                        uk: ["48SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "58LEU",
                      short: "58L",
                      long: "58L",
                      display: "58L",
                      display_with_size_set: "58L",
                      display_with_size_system: "EU 58L",
                      display_with_system_and_set: "EU 58L",
                      equivalent_sizes: {
                        us: ["48L"],
                        au: ["48LAU"],
                        uk: ["48LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "59REU",
                      short: "59R",
                      long: "59R",
                      display: "59R",
                      display_with_size_set: "59R",
                      display_with_size_system: "EU 59R",
                      display_with_system_and_set: "EU 59R",
                      equivalent_sizes: {
                        us: ["49R"],
                        au: ["49RAU"],
                        uk: ["49RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "59SEU",
                      short: "59S",
                      long: "59S",
                      display: "59S",
                      display_with_size_set: "59S",
                      display_with_size_system: "EU 59S",
                      display_with_system_and_set: "EU 59S",
                      equivalent_sizes: {
                        us: ["49S"],
                        au: ["49SAU"],
                        uk: ["49SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "59LEU",
                      short: "59L",
                      long: "59L",
                      display: "59L",
                      display_with_size_set: "59L",
                      display_with_size_system: "EU 59L",
                      display_with_system_and_set: "EU 59L",
                      equivalent_sizes: {
                        us: ["49L"],
                        au: ["49LAU"],
                        uk: ["49LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60REU",
                      short: "60R",
                      long: "60R",
                      display: "60R",
                      display_with_size_set: "60R",
                      display_with_size_system: "EU 60R",
                      display_with_system_and_set: "EU 60R",
                      equivalent_sizes: {
                        us: ["50R"],
                        au: ["50RAU"],
                        uk: ["50RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60SEU",
                      short: "60S",
                      long: "60S",
                      display: "60S",
                      display_with_size_set: "60S",
                      display_with_size_system: "EU 60S",
                      display_with_system_and_set: "EU 60S",
                      equivalent_sizes: {
                        us: ["50S"],
                        au: ["50SAU"],
                        uk: ["50SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "60LEU",
                      short: "60L",
                      long: "60L",
                      display: "60L",
                      display_with_size_set: "60L",
                      display_with_size_system: "EU 60L",
                      display_with_system_and_set: "EU 60L",
                      equivalent_sizes: {
                        us: ["50L"],
                        au: ["50LAU"],
                        uk: ["50LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "61REU",
                      short: "61R",
                      long: "61R",
                      display: "61R",
                      display_with_size_set: "61R",
                      display_with_size_system: "EU 61R",
                      display_with_system_and_set: "EU 61R",
                      equivalent_sizes: {
                        us: ["51R"],
                        au: ["51RAU"],
                        uk: ["51RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "61SEU",
                      short: "61S",
                      long: "61S",
                      display: "61S",
                      display_with_size_set: "61S",
                      display_with_size_system: "EU 61S",
                      display_with_system_and_set: "EU 61S",
                      equivalent_sizes: {
                        us: ["51S"],
                        au: ["51SAU"],
                        uk: ["51SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "61LEU",
                      short: "61L",
                      long: "61L",
                      display: "61L",
                      display_with_size_set: "61L",
                      display_with_size_system: "EU 61L",
                      display_with_system_and_set: "EU 61L",
                      equivalent_sizes: {
                        us: ["51L"],
                        au: ["51LAU"],
                        uk: ["51LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62REU",
                      short: "62R",
                      long: "62R",
                      display: "62R",
                      display_with_size_set: "62R",
                      display_with_size_system: "EU 62R",
                      display_with_system_and_set: "EU 62R",
                      equivalent_sizes: {
                        us: ["52R"],
                        au: ["52RAU"],
                        uk: ["52RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62SEU",
                      short: "62S",
                      long: "62S",
                      display: "62S",
                      display_with_size_set: "62S",
                      display_with_size_system: "EU 62S",
                      display_with_system_and_set: "EU 62S",
                      equivalent_sizes: {
                        us: ["52S"],
                        au: ["52SAU"],
                        uk: ["52SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "62LEU",
                      short: "62L",
                      long: "62L",
                      display: "62L",
                      display_with_size_set: "62L",
                      display_with_size_system: "EU 62L",
                      display_with_system_and_set: "EU 62L",
                      equivalent_sizes: {
                        us: ["52L"],
                        au: ["52LAU"],
                        uk: ["52LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "63REU",
                      short: "63R",
                      long: "63R",
                      display: "63R",
                      display_with_size_set: "63R",
                      display_with_size_system: "EU 63R",
                      display_with_system_and_set: "EU 63R",
                      equivalent_sizes: {
                        us: ["53R"],
                        au: ["53RAU"],
                        uk: ["53RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "63SEU",
                      short: "63S",
                      long: "63S",
                      display: "63S",
                      display_with_size_set: "63S",
                      display_with_size_system: "EU 63S",
                      display_with_system_and_set: "EU 63S",
                      equivalent_sizes: {
                        us: ["53S"],
                        au: ["53SAU"],
                        uk: ["53SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "63LEU",
                      short: "63L",
                      long: "63L",
                      display: "63L",
                      display_with_size_set: "63L",
                      display_with_size_system: "EU 63L",
                      display_with_system_and_set: "EU 63L",
                      equivalent_sizes: {
                        us: ["53L"],
                        au: ["53LAU"],
                        uk: ["53LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "64REU",
                      short: "64R",
                      long: "64R",
                      display: "64R",
                      display_with_size_set: "64R",
                      display_with_size_system: "EU 64R",
                      display_with_system_and_set: "EU 64R",
                      equivalent_sizes: {
                        us: ["54R"],
                        au: ["54RAU"],
                        uk: ["54RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "64SEU",
                      short: "64S",
                      long: "64S",
                      display: "64S",
                      display_with_size_set: "64S",
                      display_with_size_system: "EU 64S",
                      display_with_system_and_set: "EU 64S",
                      equivalent_sizes: {
                        us: ["54S"],
                        au: ["54SAU"],
                        uk: ["54SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "64LEU",
                      short: "64L",
                      long: "64L",
                      display: "64L",
                      display_with_size_set: "64L",
                      display_with_size_system: "EU 64L",
                      display_with_system_and_set: "EU 64L",
                      equivalent_sizes: {
                        us: ["54L"],
                        au: ["54LAU"],
                        uk: ["54LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "65REU",
                      short: "65R",
                      long: "65R",
                      display: "65R",
                      display_with_size_set: "65R",
                      display_with_size_system: "EU 65R",
                      display_with_system_and_set: "EU 65R",
                      equivalent_sizes: {
                        us: ["55R"],
                        au: ["55RAU"],
                        uk: ["55RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "65SEU",
                      short: "65S",
                      long: "65S",
                      display: "65S",
                      display_with_size_set: "65S",
                      display_with_size_system: "EU 65S",
                      display_with_system_and_set: "EU 65S",
                      equivalent_sizes: {
                        us: ["55S"],
                        au: ["55SAU"],
                        uk: ["55SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "65LEU",
                      short: "65L",
                      long: "65L",
                      display: "65L",
                      display_with_size_set: "65L",
                      display_with_size_system: "EU 65L",
                      display_with_system_and_set: "EU 65L",
                      equivalent_sizes: {
                        us: ["55L"],
                        au: ["55LAU"],
                        uk: ["55LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "66REU",
                      short: "66R",
                      long: "66R",
                      display: "66R",
                      display_with_size_set: "66R",
                      display_with_size_system: "EU 66R",
                      display_with_system_and_set: "EU 66R",
                      equivalent_sizes: {
                        us: ["56R"],
                        au: ["56RAU"],
                        uk: ["56RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "66SEU",
                      short: "66S",
                      long: "66S",
                      display: "66S",
                      display_with_size_set: "66S",
                      display_with_size_system: "EU 66S",
                      display_with_system_and_set: "EU 66S",
                      equivalent_sizes: {
                        us: ["56S"],
                        au: ["56SAU"],
                        uk: ["56SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "66LEU",
                      short: "66L",
                      long: "66L",
                      display: "66L",
                      display_with_size_set: "66L",
                      display_with_size_system: "EU 66L",
                      display_with_system_and_set: "EU 66L",
                      equivalent_sizes: {
                        us: ["56L"],
                        au: ["56LAU"],
                        uk: ["56LUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "34RUK",
                      short: "34R",
                      long: "34R",
                      display: "34R",
                      display_with_size_set: "34R",
                      display_with_size_system: "UK 34R",
                      display_with_system_and_set: "UK 34R",
                      equivalent_sizes: {
                        us: ["34R"],
                        au: ["34RAU"],
                        eu: ["44REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34SUK",
                      short: "34S",
                      long: "34S",
                      display: "34S",
                      display_with_size_set: "34S",
                      display_with_size_system: "UK 34S",
                      display_with_system_and_set: "UK 34S",
                      equivalent_sizes: {
                        us: ["34S"],
                        au: ["34SAU"],
                        eu: ["44SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34LUK",
                      short: "34L",
                      long: "34L",
                      display: "34L",
                      display_with_size_set: "34L",
                      display_with_size_system: "UK 34L",
                      display_with_system_and_set: "UK 34L",
                      equivalent_sizes: {
                        us: ["34L"],
                        au: ["34LAU"],
                        eu: ["44LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "35RUK",
                      short: "35R",
                      long: "35R",
                      display: "35R",
                      display_with_size_set: "35R",
                      display_with_size_system: "UK 35R",
                      display_with_system_and_set: "UK 35R",
                      equivalent_sizes: {
                        us: ["35R"],
                        au: ["35RAU"],
                        eu: ["45REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "35SUK",
                      short: "35S",
                      long: "35S",
                      display: "35S",
                      display_with_size_set: "35S",
                      display_with_size_system: "UK 35S",
                      display_with_system_and_set: "UK 35S",
                      equivalent_sizes: {
                        us: ["35S"],
                        au: ["35SAU"],
                        eu: ["45SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "35LUK",
                      short: "35L",
                      long: "35L",
                      display: "35L",
                      display_with_size_set: "35L",
                      display_with_size_system: "UK 35L",
                      display_with_system_and_set: "UK 35L",
                      equivalent_sizes: {
                        us: ["35L"],
                        au: ["35LAU"],
                        eu: ["45LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36RUK",
                      short: "36R",
                      long: "36R",
                      display: "36R",
                      display_with_size_set: "36R",
                      display_with_size_system: "UK 36R",
                      display_with_system_and_set: "UK 36R",
                      equivalent_sizes: {
                        us: ["36R"],
                        au: ["36RAU"],
                        eu: ["46REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36SUK",
                      short: "36S",
                      long: "36S",
                      display: "36S",
                      display_with_size_set: "36S",
                      display_with_size_system: "UK 36S",
                      display_with_system_and_set: "UK 36S",
                      equivalent_sizes: {
                        us: ["36S"],
                        au: ["36SAU"],
                        eu: ["46SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36LUK",
                      short: "36L",
                      long: "36L",
                      display: "36L",
                      display_with_size_set: "36L",
                      display_with_size_system: "UK 36L",
                      display_with_system_and_set: "UK 36L",
                      equivalent_sizes: {
                        us: ["36L"],
                        au: ["36LAU"],
                        eu: ["46LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "37RUK",
                      short: "37R",
                      long: "37R",
                      display: "37R",
                      display_with_size_set: "37R",
                      display_with_size_system: "UK 37R",
                      display_with_system_and_set: "UK 37R",
                      equivalent_sizes: {
                        us: ["37R"],
                        au: ["37RAU"],
                        eu: ["47REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "37SUK",
                      short: "37S",
                      long: "37S",
                      display: "37S",
                      display_with_size_set: "37S",
                      display_with_size_system: "UK 37S",
                      display_with_system_and_set: "UK 37S",
                      equivalent_sizes: {
                        us: ["37S"],
                        au: ["37SAU"],
                        eu: ["47SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "37LUK",
                      short: "37L",
                      long: "37L",
                      display: "37L",
                      display_with_size_set: "37L",
                      display_with_size_system: "UK 37L",
                      display_with_system_and_set: "UK 37L",
                      equivalent_sizes: {
                        us: ["37L"],
                        au: ["37LAU"],
                        eu: ["47LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38RUK",
                      short: "38R",
                      long: "38R",
                      display: "38R",
                      display_with_size_set: "38R",
                      display_with_size_system: "UK 38R",
                      display_with_system_and_set: "UK 38R",
                      equivalent_sizes: {
                        us: ["38R"],
                        au: ["38RAU"],
                        eu: ["48REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38SUK",
                      short: "38S",
                      long: "38S",
                      display: "38S",
                      display_with_size_set: "38S",
                      display_with_size_system: "UK 38S",
                      display_with_system_and_set: "UK 38S",
                      equivalent_sizes: {
                        us: ["38S"],
                        au: ["38SAU"],
                        eu: ["48SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38LUK",
                      short: "38L",
                      long: "38L",
                      display: "38L",
                      display_with_size_set: "38L",
                      display_with_size_system: "UK 38L",
                      display_with_system_and_set: "UK 38L",
                      equivalent_sizes: {
                        us: ["38L"],
                        au: ["38LAU"],
                        eu: ["48LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "39RUK",
                      short: "39R",
                      long: "39R",
                      display: "39R",
                      display_with_size_set: "39R",
                      display_with_size_system: "UK 39R",
                      display_with_system_and_set: "UK 39R",
                      equivalent_sizes: {
                        us: ["39R"],
                        au: ["39RAU"],
                        eu: ["49REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "39SUK",
                      short: "39S",
                      long: "39S",
                      display: "39S",
                      display_with_size_set: "39S",
                      display_with_size_system: "UK 39S",
                      display_with_system_and_set: "UK 39S",
                      equivalent_sizes: {
                        us: ["39S"],
                        au: ["39SAU"],
                        eu: ["49SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "39LUK",
                      short: "39L",
                      long: "39L",
                      display: "39L",
                      display_with_size_set: "39L",
                      display_with_size_system: "UK 39L",
                      display_with_system_and_set: "UK 39L",
                      equivalent_sizes: {
                        us: ["39L"],
                        au: ["39LAU"],
                        eu: ["49LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40RUK",
                      short: "40R",
                      long: "40R",
                      display: "40R",
                      display_with_size_set: "40R",
                      display_with_size_system: "UK 40R",
                      display_with_system_and_set: "UK 40R",
                      equivalent_sizes: {
                        us: ["40R"],
                        au: ["40RAU"],
                        eu: ["50REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40SUK",
                      short: "40S",
                      long: "40S",
                      display: "40S",
                      display_with_size_set: "40S",
                      display_with_size_system: "UK 40S",
                      display_with_system_and_set: "UK 40S",
                      equivalent_sizes: {
                        us: ["40S"],
                        au: ["40SAU"],
                        eu: ["50SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40LUK",
                      short: "40L",
                      long: "40L",
                      display: "40L",
                      display_with_size_set: "40L",
                      display_with_size_system: "UK 40L",
                      display_with_system_and_set: "UK 40L",
                      equivalent_sizes: {
                        us: ["40L"],
                        au: ["40LAU"],
                        eu: ["50LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "41RUK",
                      short: "41R",
                      long: "41R",
                      display: "41R",
                      display_with_size_set: "41R",
                      display_with_size_system: "UK 41R",
                      display_with_system_and_set: "UK 41R",
                      equivalent_sizes: {
                        us: ["41R"],
                        au: ["41RAU"],
                        eu: ["51REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "41SUK",
                      short: "41S",
                      long: "41S",
                      display: "41S",
                      display_with_size_set: "41S",
                      display_with_size_system: "UK 41S",
                      display_with_system_and_set: "UK 41S",
                      equivalent_sizes: {
                        us: ["41S"],
                        au: ["41SAU"],
                        eu: ["51SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "41LUK",
                      short: "41L",
                      long: "41L",
                      display: "41L",
                      display_with_size_set: "41L",
                      display_with_size_system: "UK 41L",
                      display_with_system_and_set: "UK 41L",
                      equivalent_sizes: {
                        us: ["41L"],
                        au: ["41LAU"],
                        eu: ["51LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42RUK",
                      short: "42R",
                      long: "42R",
                      display: "42R",
                      display_with_size_set: "42R",
                      display_with_size_system: "UK 42R",
                      display_with_system_and_set: "UK 42R",
                      equivalent_sizes: {
                        us: ["42R"],
                        au: ["42RAU"],
                        eu: ["52REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42SUK",
                      short: "42S",
                      long: "42S",
                      display: "42S",
                      display_with_size_set: "42S",
                      display_with_size_system: "UK 42S",
                      display_with_system_and_set: "UK 42S",
                      equivalent_sizes: {
                        us: ["42S"],
                        au: ["42SAU"],
                        eu: ["52SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42LUK",
                      short: "42L",
                      long: "42L",
                      display: "42L",
                      display_with_size_set: "42L",
                      display_with_size_system: "UK 42L",
                      display_with_system_and_set: "UK 42L",
                      equivalent_sizes: {
                        us: ["42L"],
                        au: ["42LAU"],
                        eu: ["52LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "43RUK",
                      short: "43R",
                      long: "43R",
                      display: "43R",
                      display_with_size_set: "43R",
                      display_with_size_system: "UK 43R",
                      display_with_system_and_set: "UK 43R",
                      equivalent_sizes: {
                        us: ["43R"],
                        au: ["43RAU"],
                        eu: ["53REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "43SUK",
                      short: "43S",
                      long: "43S",
                      display: "43S",
                      display_with_size_set: "43S",
                      display_with_size_system: "UK 43S",
                      display_with_system_and_set: "UK 43S",
                      equivalent_sizes: {
                        us: ["43S"],
                        au: ["43SAU"],
                        eu: ["53SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "43LUK",
                      short: "43L",
                      long: "43L",
                      display: "43L",
                      display_with_size_set: "43L",
                      display_with_size_system: "UK 43L",
                      display_with_system_and_set: "UK 43L",
                      equivalent_sizes: {
                        us: ["43L"],
                        au: ["43LAU"],
                        eu: ["53LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44RUK",
                      short: "44R",
                      long: "44R",
                      display: "44R",
                      display_with_size_set: "44R",
                      display_with_size_system: "UK 44R",
                      display_with_system_and_set: "UK 44R",
                      equivalent_sizes: {
                        us: ["44R"],
                        au: ["44RAU"],
                        eu: ["54REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44SUK",
                      short: "44S",
                      long: "44S",
                      display: "44S",
                      display_with_size_set: "44S",
                      display_with_size_system: "UK 44S",
                      display_with_system_and_set: "UK 44S",
                      equivalent_sizes: {
                        us: ["44S"],
                        au: ["44SAU"],
                        eu: ["54SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44LUK",
                      short: "44L",
                      long: "44L",
                      display: "44L",
                      display_with_size_set: "44L",
                      display_with_size_system: "UK 44L",
                      display_with_system_and_set: "UK 44L",
                      equivalent_sizes: {
                        us: ["44L"],
                        au: ["44LAU"],
                        eu: ["54LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "45RUK",
                      short: "45R",
                      long: "45R",
                      display: "45R",
                      display_with_size_set: "45R",
                      display_with_size_system: "UK 45R",
                      display_with_system_and_set: "UK 45R",
                      equivalent_sizes: {
                        us: ["45R"],
                        au: ["45RAU"],
                        eu: ["55REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "45SUK",
                      short: "45S",
                      long: "45S",
                      display: "45S",
                      display_with_size_set: "45S",
                      display_with_size_system: "UK 45S",
                      display_with_system_and_set: "UK 45S",
                      equivalent_sizes: {
                        us: ["45S"],
                        au: ["45SAU"],
                        eu: ["55SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "45LUK",
                      short: "45L",
                      long: "45L",
                      display: "45L",
                      display_with_size_set: "45L",
                      display_with_size_system: "UK 45L",
                      display_with_system_and_set: "UK 45L",
                      equivalent_sizes: {
                        us: ["45L"],
                        au: ["45LAU"],
                        eu: ["55LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46RUK",
                      short: "46R",
                      long: "46R",
                      display: "46R",
                      display_with_size_set: "46R",
                      display_with_size_system: "UK 46R",
                      display_with_system_and_set: "UK 46R",
                      equivalent_sizes: {
                        us: ["46R"],
                        au: ["46RAU"],
                        eu: ["56REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46SUK",
                      short: "46S",
                      long: "46S",
                      display: "46S",
                      display_with_size_set: "46S",
                      display_with_size_system: "UK 46S",
                      display_with_system_and_set: "UK 46S",
                      equivalent_sizes: {
                        us: ["46S"],
                        au: ["46SAU"],
                        eu: ["56SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46LUK",
                      short: "46L",
                      long: "46L",
                      display: "46L",
                      display_with_size_set: "46L",
                      display_with_size_system: "UK 46L",
                      display_with_system_and_set: "UK 46L",
                      equivalent_sizes: {
                        us: ["46L"],
                        au: ["46LAU"],
                        eu: ["56LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "47RUK",
                      short: "47R",
                      long: "47R",
                      display: "47R",
                      display_with_size_set: "47R",
                      display_with_size_system: "UK 47R",
                      display_with_system_and_set: "UK 47R",
                      equivalent_sizes: {
                        us: ["47R"],
                        au: ["47RAU"],
                        eu: ["57REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "47SUK",
                      short: "47S",
                      long: "47S",
                      display: "47S",
                      display_with_size_set: "47S",
                      display_with_size_system: "UK 47S",
                      display_with_system_and_set: "UK 47S",
                      equivalent_sizes: {
                        us: ["47S"],
                        au: ["47SAU"],
                        eu: ["57SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "47LUK",
                      short: "47L",
                      long: "47L",
                      display: "47L",
                      display_with_size_set: "47L",
                      display_with_size_system: "UK 47L",
                      display_with_system_and_set: "UK 47L",
                      equivalent_sizes: {
                        us: ["47L"],
                        au: ["47LAU"],
                        eu: ["57LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48RUK",
                      short: "48R",
                      long: "48R",
                      display: "48R",
                      display_with_size_set: "48R",
                      display_with_size_system: "UK 48R",
                      display_with_system_and_set: "UK 48R",
                      equivalent_sizes: {
                        us: ["48R"],
                        au: ["48RAU"],
                        eu: ["58REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48SUK",
                      short: "48S",
                      long: "48S",
                      display: "48S",
                      display_with_size_set: "48S",
                      display_with_size_system: "UK 48S",
                      display_with_system_and_set: "UK 48S",
                      equivalent_sizes: {
                        us: ["48S"],
                        au: ["48SAU"],
                        eu: ["58SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48LUK",
                      short: "48L",
                      long: "48L",
                      display: "48L",
                      display_with_size_set: "48L",
                      display_with_size_system: "UK 48L",
                      display_with_system_and_set: "UK 48L",
                      equivalent_sizes: {
                        us: ["48L"],
                        au: ["48LAU"],
                        eu: ["58LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "49RUK",
                      short: "49R",
                      long: "49R",
                      display: "49R",
                      display_with_size_set: "49R",
                      display_with_size_system: "UK 49R",
                      display_with_system_and_set: "UK 49R",
                      equivalent_sizes: {
                        us: ["49R"],
                        au: ["49RAU"],
                        eu: ["59REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "49SUK",
                      short: "49S",
                      long: "49S",
                      display: "49S",
                      display_with_size_set: "49S",
                      display_with_size_system: "UK 49S",
                      display_with_system_and_set: "UK 49S",
                      equivalent_sizes: {
                        us: ["49S"],
                        au: ["49SAU"],
                        eu: ["59SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "49LUK",
                      short: "49L",
                      long: "49L",
                      display: "49L",
                      display_with_size_set: "49L",
                      display_with_size_system: "UK 49L",
                      display_with_system_and_set: "UK 49L",
                      equivalent_sizes: {
                        us: ["49L"],
                        au: ["49LAU"],
                        eu: ["59LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "50RUK",
                      short: "50R",
                      long: "50R",
                      display: "50R",
                      display_with_size_set: "50R",
                      display_with_size_system: "UK 50R",
                      display_with_system_and_set: "UK 50R",
                      equivalent_sizes: {
                        us: ["50R"],
                        au: ["50RAU"],
                        eu: ["60REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "50SUK",
                      short: "50S",
                      long: "50S",
                      display: "50S",
                      display_with_size_set: "50S",
                      display_with_size_system: "UK 50S",
                      display_with_system_and_set: "UK 50S",
                      equivalent_sizes: {
                        us: ["50S"],
                        au: ["50SAU"],
                        eu: ["60SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "50LUK",
                      short: "50L",
                      long: "50L",
                      display: "50L",
                      display_with_size_set: "50L",
                      display_with_size_system: "UK 50L",
                      display_with_system_and_set: "UK 50L",
                      equivalent_sizes: {
                        us: ["50L"],
                        au: ["50LAU"],
                        eu: ["60LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "51RUK",
                      short: "51R",
                      long: "51R",
                      display: "51R",
                      display_with_size_set: "51R",
                      display_with_size_system: "UK 51R",
                      display_with_system_and_set: "UK 51R",
                      equivalent_sizes: {
                        us: ["51R"],
                        au: ["51RAU"],
                        eu: ["61REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "51SUK",
                      short: "51S",
                      long: "51S",
                      display: "51S",
                      display_with_size_set: "51S",
                      display_with_size_system: "UK 51S",
                      display_with_system_and_set: "UK 51S",
                      equivalent_sizes: {
                        us: ["51S"],
                        au: ["51SAU"],
                        eu: ["61SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "51LUK",
                      short: "51L",
                      long: "51L",
                      display: "51L",
                      display_with_size_set: "51L",
                      display_with_size_system: "UK 51L",
                      display_with_system_and_set: "UK 51L",
                      equivalent_sizes: {
                        us: ["51L"],
                        au: ["51LAU"],
                        eu: ["61LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "52RUK",
                      short: "52R",
                      long: "52R",
                      display: "52R",
                      display_with_size_set: "52R",
                      display_with_size_system: "UK 52R",
                      display_with_system_and_set: "UK 52R",
                      equivalent_sizes: {
                        us: ["52R"],
                        au: ["52RAU"],
                        eu: ["62REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "52SUK",
                      short: "52S",
                      long: "52S",
                      display: "52S",
                      display_with_size_set: "52S",
                      display_with_size_system: "UK 52S",
                      display_with_system_and_set: "UK 52S",
                      equivalent_sizes: {
                        us: ["52S"],
                        au: ["52SAU"],
                        eu: ["62SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "52LUK",
                      short: "52L",
                      long: "52L",
                      display: "52L",
                      display_with_size_set: "52L",
                      display_with_size_system: "UK 52L",
                      display_with_system_and_set: "UK 52L",
                      equivalent_sizes: {
                        us: ["52L"],
                        au: ["52LAU"],
                        eu: ["62LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "53RUK",
                      short: "53R",
                      long: "53R",
                      display: "53R",
                      display_with_size_set: "53R",
                      display_with_size_system: "UK 53R",
                      display_with_system_and_set: "UK 53R",
                      equivalent_sizes: {
                        us: ["53R"],
                        au: ["53RAU"],
                        eu: ["63REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "53SUK",
                      short: "53S",
                      long: "53S",
                      display: "53S",
                      display_with_size_set: "53S",
                      display_with_size_system: "UK 53S",
                      display_with_system_and_set: "UK 53S",
                      equivalent_sizes: {
                        us: ["53S"],
                        au: ["53SAU"],
                        eu: ["63SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "53LUK",
                      short: "53L",
                      long: "53L",
                      display: "53L",
                      display_with_size_set: "53L",
                      display_with_size_system: "UK 53L",
                      display_with_system_and_set: "UK 53L",
                      equivalent_sizes: {
                        us: ["53L"],
                        au: ["53LAU"],
                        eu: ["63LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "54RUK",
                      short: "54R",
                      long: "54R",
                      display: "54R",
                      display_with_size_set: "54R",
                      display_with_size_system: "UK 54R",
                      display_with_system_and_set: "UK 54R",
                      equivalent_sizes: {
                        us: ["54R"],
                        au: ["54RAU"],
                        eu: ["64REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "54SUK",
                      short: "54S",
                      long: "54S",
                      display: "54S",
                      display_with_size_set: "54S",
                      display_with_size_system: "UK 54S",
                      display_with_system_and_set: "UK 54S",
                      equivalent_sizes: {
                        us: ["54S"],
                        au: ["54SAU"],
                        eu: ["64SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "54LUK",
                      short: "54L",
                      long: "54L",
                      display: "54L",
                      display_with_size_set: "54L",
                      display_with_size_system: "UK 54L",
                      display_with_system_and_set: "UK 54L",
                      equivalent_sizes: {
                        us: ["54L"],
                        au: ["54LAU"],
                        eu: ["64LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "55RUK",
                      short: "55R",
                      long: "55R",
                      display: "55R",
                      display_with_size_set: "55R",
                      display_with_size_system: "UK 55R",
                      display_with_system_and_set: "UK 55R",
                      equivalent_sizes: {
                        us: ["55R"],
                        au: ["55RAU"],
                        eu: ["65REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "55SUK",
                      short: "55S",
                      long: "55S",
                      display: "55S",
                      display_with_size_set: "55S",
                      display_with_size_system: "UK 55S",
                      display_with_system_and_set: "UK 55S",
                      equivalent_sizes: {
                        us: ["55S"],
                        au: ["55SAU"],
                        eu: ["65SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "55LUK",
                      short: "55L",
                      long: "55L",
                      display: "55L",
                      display_with_size_set: "55L",
                      display_with_size_system: "UK 55L",
                      display_with_system_and_set: "UK 55L",
                      equivalent_sizes: {
                        us: ["55L"],
                        au: ["55LAU"],
                        eu: ["65LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "56RUK",
                      short: "56R",
                      long: "56R",
                      display: "56R",
                      display_with_size_set: "56R",
                      display_with_size_system: "UK 56R",
                      display_with_system_and_set: "UK 56R",
                      equivalent_sizes: {
                        us: ["56R"],
                        au: ["56RAU"],
                        eu: ["66REU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "56SUK",
                      short: "56S",
                      long: "56S",
                      display: "56S",
                      display_with_size_set: "56S",
                      display_with_size_system: "UK 56S",
                      display_with_system_and_set: "UK 56S",
                      equivalent_sizes: {
                        us: ["56S"],
                        au: ["56SAU"],
                        eu: ["66SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "56LUK",
                      short: "56L",
                      long: "56L",
                      display: "56L",
                      display_with_size_set: "56L",
                      display_with_size_system: "UK 56L",
                      display_with_system_and_set: "UK 56L",
                      equivalent_sizes: {
                        us: ["56L"],
                        au: ["56LAU"],
                        eu: ["66LEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "101e4cd26058864a961862cf",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "0a008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["58", "52"],
                  ["58", "52"],
                  ["58", "52"],
                  ["60", "53"],
                  ["60", "53"],
                  ["60", "53"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["147.3", "132.1"],
                  ["147.3", "132.1"],
                  ["147.3", "132.1"],
                  ["152.4", "134.6"],
                  ["152.4", "134.6"],
                  ["152.4", "134.6"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["58S", "58S", "-", "58S"],
                  ["58R", "58R", "68", "58R"],
                  ["58L", "58L", "-", "58L"],
                  ["60S", "60S", "-", "60S"],
                  ["60R", "60R", "70", "60R"],
                  ["60L", "60L", "-", "60L"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["58S", "58S", "-", "58S"],
                ["58R", "58R", "68", "58R"],
                ["58L", "58L", "-", "58L"],
                ["60S", "60S", "-", "60S"],
                ["60R", "60R", "70", "60R"],
                ["60L", "60L", "-", "60L"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "58S",
                      short: "58S",
                      long: "58S",
                      display: "58S",
                      display_with_size_set: "58S",
                      display_with_size_system: "US 58S",
                      display_with_system_and_set: "US 58S",
                      equivalent_sizes: {
                        au: ["58SAU"],
                        uk: ["58SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "58R",
                      short: "58R",
                      long: "58R",
                      display: "58R",
                      display_with_size_set: "58R",
                      display_with_size_system: "US 58R",
                      display_with_system_and_set: "US 58R",
                      equivalent_sizes: {
                        au: ["58RAU"],
                        eu: ["68EU"],
                        uk: ["58RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "58L",
                      short: "58L",
                      long: "58L",
                      display: "58L",
                      display_with_size_set: "58L",
                      display_with_size_system: "US 58L",
                      display_with_system_and_set: "US 58L",
                      equivalent_sizes: {
                        au: ["58LAU"],
                        uk: ["58LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "60S",
                      short: "60S",
                      long: "60S",
                      display: "60S",
                      display_with_size_set: "60S",
                      display_with_size_system: "US 60S",
                      display_with_system_and_set: "US 60S",
                      equivalent_sizes: {
                        au: ["60SAU"],
                        uk: ["60SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "60R",
                      short: "60R",
                      long: "60R",
                      display: "60R",
                      display_with_size_set: "60R",
                      display_with_size_system: "US 60R",
                      display_with_system_and_set: "US 60R",
                      equivalent_sizes: {
                        au: ["60RAU"],
                        eu: ["70EU"],
                        uk: ["60RUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "60L",
                      short: "60L",
                      long: "60L",
                      display: "60L",
                      display_with_size_set: "60L",
                      display_with_size_system: "US 60L",
                      display_with_system_and_set: "US 60L",
                      equivalent_sizes: {
                        au: ["60LAU"],
                        uk: ["60LUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "58SAU",
                      short: "58S",
                      long: "58S",
                      display: "58S",
                      display_with_size_set: "58S",
                      display_with_size_system: "AU 58S",
                      display_with_system_and_set: "AU 58S",
                      equivalent_sizes: {
                        us: ["58S"],
                        uk: ["58SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "58RAU",
                      short: "58R",
                      long: "58R",
                      display: "58R",
                      display_with_size_set: "58R",
                      display_with_size_system: "AU 58R",
                      display_with_system_and_set: "AU 58R",
                      equivalent_sizes: {
                        us: ["58R"],
                        eu: ["68EU"],
                        uk: ["58RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "58LAU",
                      short: "58L",
                      long: "58L",
                      display: "58L",
                      display_with_size_set: "58L",
                      display_with_size_system: "AU 58L",
                      display_with_system_and_set: "AU 58L",
                      equivalent_sizes: {
                        us: ["58L"],
                        uk: ["58LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "60SAU",
                      short: "60S",
                      long: "60S",
                      display: "60S",
                      display_with_size_set: "60S",
                      display_with_size_system: "AU 60S",
                      display_with_system_and_set: "AU 60S",
                      equivalent_sizes: {
                        us: ["60S"],
                        uk: ["60SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "60RAU",
                      short: "60R",
                      long: "60R",
                      display: "60R",
                      display_with_size_set: "60R",
                      display_with_size_system: "AU 60R",
                      display_with_system_and_set: "AU 60R",
                      equivalent_sizes: {
                        us: ["60R"],
                        eu: ["70EU"],
                        uk: ["60RUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "60LAU",
                      short: "60L",
                      long: "60L",
                      display: "60L",
                      display_with_size_set: "60L",
                      display_with_size_system: "AU 60L",
                      display_with_system_and_set: "AU 60L",
                      equivalent_sizes: {
                        us: ["60L"],
                        uk: ["60LUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "68EU",
                      short: "68",
                      long: "68",
                      display: "68",
                      display_with_size_set: "68",
                      display_with_size_system: "EU 68",
                      display_with_system_and_set: "EU 68",
                      equivalent_sizes: {
                        us: ["58R"],
                        au: ["58RAU"],
                        uk: ["58RUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "70EU",
                      short: "70",
                      long: "70",
                      display: "70",
                      display_with_size_set: "70",
                      display_with_size_system: "EU 70",
                      display_with_system_and_set: "EU 70",
                      equivalent_sizes: {
                        us: ["60R"],
                        au: ["60RAU"],
                        uk: ["60RUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "58SUK",
                      short: "58S",
                      long: "58S",
                      display: "58S",
                      display_with_size_set: "58S",
                      display_with_size_system: "UK 58S",
                      display_with_system_and_set: "UK 58S",
                      equivalent_sizes: {
                        us: ["58S"],
                        au: ["58SAU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "58RUK",
                      short: "58R",
                      long: "58R",
                      display: "58R",
                      display_with_size_set: "58R",
                      display_with_size_system: "UK 58R",
                      display_with_system_and_set: "UK 58R",
                      equivalent_sizes: {
                        us: ["58R"],
                        au: ["58RAU"],
                        eu: ["68EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "58LUK",
                      short: "58L",
                      long: "58L",
                      display: "58L",
                      display_with_size_set: "58L",
                      display_with_size_system: "UK 58L",
                      display_with_system_and_set: "UK 58L",
                      equivalent_sizes: {
                        us: ["58L"],
                        au: ["58LAU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "60SUK",
                      short: "60S",
                      long: "60S",
                      display: "60S",
                      display_with_size_set: "60S",
                      display_with_size_system: "UK 60S",
                      display_with_system_and_set: "UK 60S",
                      equivalent_sizes: {
                        us: ["60S"],
                        au: ["60SAU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "60RUK",
                      short: "60R",
                      long: "60R",
                      display: "60R",
                      display_with_size_set: "60R",
                      display_with_size_system: "UK 60R",
                      display_with_system_and_set: "UK 60R",
                      equivalent_sizes: {
                        us: ["60R"],
                        au: ["60RAU"],
                        eu: ["70EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "60LUK",
                      short: "60L",
                      long: "60L",
                      display: "60L",
                      display_with_size_set: "60L",
                      display_with_size_system: "UK 60L",
                      display_with_system_and_set: "UK 60L",
                      equivalent_sizes: {
                        us: ["60L"],
                        au: ["60LAU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "men_suits_blazers",
          aliases: [],
          slug: "Suits_&_Blazers",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
          category_features: [
            {
              id: "44009813d97b4e3995005764",
              display: "Sport Coats & Blazers",
              type: "cf",
              message_id: "men_suits_blazers_sport_coats_blazers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sport_Coats_&_Blazers",
              alias_slugs: [],
              parent_id: "0a008c10d97b4e1245005764",
            },
            {
              id: "43009813d97b4e3995005764",
              display: "Suits",
              type: "cf",
              message_id: "men_suits_blazers_suits",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Suits",
              alias_slugs: [],
              parent_id: "0a008c10d97b4e1245005764",
            },
            {
              id: "45009813d97b4e3995005764",
              display: "Tuxedos",
              type: "cf",
              message_id: "men_suits_blazers_tuxedos",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Tuxedos",
              alias_slugs: [],
              parent_id: "0a008c10d97b4e1245005764",
            },
            {
              id: "46009813d97b4e3995005764",
              display: "Vests",
              type: "cf",
              message_id: "men_suits_blazers_vests",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Vests",
              alias_slugs: [],
              parent_id: "0a008c10d97b4e1245005764",
            },
          ],
        },
        {
          id: "0b008c10d97b4e1245005764",
          display: "Sweaters",
          size_sets: [
            {
              id: "08003667d97b4e6bdb005784",
              name: "Standard",
              tags: ["standard"],
              category_id: "0b008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["34-36", "28-30"],
                  ["36-38", "30-32"],
                  ["38-40", "32-34"],
                  ["40-42", "34-36"],
                  ["42-44", "36-38"],
                  ["44-46", "38-40"],
                  ["46-48", "40-42"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["86.4-91.4", "71.1-76.2"],
                  ["91.4-96.5", "76.2-81.3"],
                  ["96.5-101.6", "81.3-86.4"],
                  ["101.6-106.7", "86.4-91.4"],
                  ["106.7-111.8", "91.4-96.5"],
                  ["111.8-116.9", "96.5-101.6"],
                  ["116.9-121.9", "101.6-106.7"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "111e4cd26058864a961862cf",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "0b008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["46-48", "40-42"],
                  ["49-51", "43-45"],
                  ["52-54", "46-48"],
                  ["55-57", "49-51"],
                  ["58-60", "52-54"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["116.8-121.9", "101.6-106.6"],
                  ["124.4-129.5", "109.2-114.3"],
                  ["132.1-137.2", "116.8-121.9"],
                  ["139.7-144.8", "124.4-129.5"],
                  ["147.3-152.4", "132-137.1"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["3XL", "3XL", "3XL", "3XL"],
                  ["4XL", "4XL", "4XL", "4XL"],
                  ["5XL", "5XL", "5XL", "5XL"],
                  ["6XL", "6XL", "6XL", "6XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["3XL", "3XL", "3XL", "3XL"],
                ["4XL", "4XL", "4XL", "4XL"],
                ["5XL", "5XL", "5XL", "5XL"],
                ["6XL", "6XL", "6XL", "6XL"],
                ["LT", "LT", "LT", "LT"],
                ["XLT", "XLT", "XLT", "XLT"],
                ["2XLT", "2XLT", "2XLT", "2XLT"],
                ["3XLT", "3XLT", "3XLT", "3XLT"],
                ["4XLT", "4XLT", "4XLT", "4XLT"],
                ["5XLT", "5XLT", "5XLT", "5XLT"],
                ["6XLT", "6XLT", "6XLT", "6XLT"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XL",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "US 3XL",
                      display_with_system_and_set: "US 3XL",
                      equivalent_sizes: {
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XL",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "US 4XL",
                      display_with_system_and_set: "US 4XL",
                      equivalent_sizes: {
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XL",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "US 5XL",
                      display_with_system_and_set: "US 5XL",
                      equivalent_sizes: {
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XL",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "US 6XL",
                      display_with_system_and_set: "US 6XL",
                      equivalent_sizes: {
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LT",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "US LT",
                      display_with_system_and_set: "US LT",
                      equivalent_sizes: {
                        au: ["LTAU"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLT",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "US XLT",
                      display_with_system_and_set: "US XLT",
                      equivalent_sizes: {
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XLT",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "US 2XLT",
                      display_with_system_and_set: "US 2XLT",
                      equivalent_sizes: {
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XLT",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "US 3XLT",
                      display_with_system_and_set: "US 3XLT",
                      equivalent_sizes: {
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XLT",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "US 4XLT",
                      display_with_system_and_set: "US 4XLT",
                      equivalent_sizes: {
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XLT",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "US 5XLT",
                      display_with_system_and_set: "US 5XLT",
                      equivalent_sizes: {
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XLT",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "US 6XLT",
                      display_with_system_and_set: "US 6XLT",
                      equivalent_sizes: {
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLAU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "AU 3XL",
                      display_with_system_and_set: "AU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLAU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "AU 4XL",
                      display_with_system_and_set: "AU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLAU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "AU 5XL",
                      display_with_system_and_set: "AU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLAU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "AU 6XL",
                      display_with_system_and_set: "AU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LTAU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "AU LT",
                      display_with_system_and_set: "AU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLTAU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "AU XLT",
                      display_with_system_and_set: "AU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XLTAU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "AU 2XLT",
                      display_with_system_and_set: "AU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLTAU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "AU 3XLT",
                      display_with_system_and_set: "AU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLTAU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "AU 4XLT",
                      display_with_system_and_set: "AU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLTAU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "AU 5XLT",
                      display_with_system_and_set: "AU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLTAU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "AU 6XLT",
                      display_with_system_and_set: "AU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLEU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "EU 3XL",
                      display_with_system_and_set: "EU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLEU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "EU 4XL",
                      display_with_system_and_set: "EU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLEU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "EU 5XL",
                      display_with_system_and_set: "EU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLEU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "EU 6XL",
                      display_with_system_and_set: "EU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LTEU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "EU LT",
                      display_with_system_and_set: "EU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        uk: ["LTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLTEU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "EU XLT",
                      display_with_system_and_set: "EU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XLTEU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "EU 2XLT",
                      display_with_system_and_set: "EU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLTEU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "EU 3XLT",
                      display_with_system_and_set: "EU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLTEU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "EU 4XLT",
                      display_with_system_and_set: "EU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLTEU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "EU 5XLT",
                      display_with_system_and_set: "EU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLTEU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "EU 6XLT",
                      display_with_system_and_set: "EU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLUK",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "UK 3XL",
                      display_with_system_and_set: "UK 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLUK",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "UK 4XL",
                      display_with_system_and_set: "UK 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLUK",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "UK 5XL",
                      display_with_system_and_set: "UK 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLUK",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "UK 6XL",
                      display_with_system_and_set: "UK 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LTUK",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "UK LT",
                      display_with_system_and_set: "UK LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        eu: ["LTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLTUK",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "UK XLT",
                      display_with_system_and_set: "UK XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XLTUK",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "UK 2XLT",
                      display_with_system_and_set: "UK 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLTUK",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "UK 3XLT",
                      display_with_system_and_set: "UK 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLTUK",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "UK 4XLT",
                      display_with_system_and_set: "UK 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLTUK",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "UK 5XLT",
                      display_with_system_and_set: "UK 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLTUK",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "UK 6XLT",
                      display_with_system_and_set: "UK 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "men_sweaters",
          aliases: [],
          slug: "Sweaters",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
          category_features: [
            {
              id: "49009813d97b4e3995005764",
              display: "Cardigan",
              type: "cf",
              message_id: "men_sweaters_cardigan",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cardigan",
              alias_slugs: [],
              parent_id: "0b008c10d97b4e1245005764",
            },
            {
              id: "4a009813d97b4e3995005764",
              display: "Crewneck",
              type: "cf",
              message_id: "men_sweaters_crewneck",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Crewneck",
              alias_slugs: [],
              parent_id: "0b008c10d97b4e1245005764",
            },
            {
              id: "4c009813d97b4e3995005764",
              display: "Turtleneck",
              type: "cf",
              message_id: "men_sweaters_turtleneck",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Turtleneck",
              alias_slugs: [],
              parent_id: "0b008c10d97b4e1245005764",
            },
            {
              id: "4d009813d97b4e3995005764",
              display: "V-Neck",
              type: "cf",
              message_id: "men_sweaters_vneck",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "V_Neck",
              alias_slugs: [],
              parent_id: "0b008c10d97b4e1245005764",
            },
            {
              id: "4f009813d97b4e3995005764",
              display: "Zip Up",
              type: "cf",
              message_id: "men_sweaters_zip_up",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Zip_Up",
              alias_slugs: [],
              parent_id: "0b008c10d97b4e1245005764",
            },
          ],
        },
        {
          id: "0d008c10d97b4e1245005764",
          display: "Swim",
          size_sets: [
            {
              id: "09003667d97b4e6bdb005784",
              name: "Standard",
              tags: ["standard"],
              category_id: "0d008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Waist "],
                  ["28-30"],
                  ["30-32"],
                  ["32-34"],
                  ["34-36"],
                  ["36-38"],
                  ["38-40"],
                  ["40-42"],
                ],
                centimeters: [
                  ["Waist "],
                  ["71.1-76.2"],
                  ["76.2-81.3"],
                  ["81.3-86.4"],
                  ["86.4-91.4"],
                  ["91.4-96.5"],
                  ["96.5-101.6"],
                  ["101.6-106.7"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "c3fc8ac54024036cbb2b62f2",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "0d008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Waist "],
                  ["40-42"],
                  ["43-45"],
                  ["46-48"],
                  ["49-51"],
                  ["52-54"],
                  ["36-39"],
                  ["40-44"],
                  ["45-48.5"],
                  ["49-52"],
                  ["52-55"],
                  ["56-60"],
                  ["62-64"],
                ],
                centimeters: [
                  ["Waist "],
                  ["101.6-106.6"],
                  ["109.2-114.3"],
                  ["116.8-121.9"],
                  ["124.4-129.5"],
                  ["132-137.1"],
                  ["91.4-99"],
                  ["101.6-111.7"],
                  ["114.3-123.1"],
                  ["124.4-132"],
                  ["132-139.7"],
                  ["142.2-152.4"],
                  ["157.4-162.5"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["3XL", "3XL", "3XL", "3XL"],
                  ["4XL", "4XL", "4XL", "4XL"],
                  ["5XL", "5XL", "5XL", "5XL"],
                  ["6XL", "6XL", "6XL", "6XL"],
                  ["LT", "LT", "LT", "LT"],
                  ["XLT", "XLT", "XLT", "XLT"],
                  ["2XLT", "2XLT", "2XLT", "2XLT"],
                  ["3XLT", "3XLT", "3XLT", "3XLT"],
                  ["4XLT", "4XLT", "4XLT", "4XLT"],
                  ["5XLT", "5XLT", "5XLT", "5XLT"],
                  ["6XLT", "6XLT", "6XLT", "6XLT"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["3XL", "3XL", "3XL", "3XL"],
                ["4XL", "4XL", "4XL", "4XL"],
                ["5XL", "5XL", "5XL", "5XL"],
                ["6XL", "6XL", "6XL", "6XL"],
                ["LT", "LT", "LT", "LT"],
                ["XLT", "XLT", "XLT", "XLT"],
                ["2XLT", "2XLT", "2XLT", "2XLT"],
                ["3XLT", "3XLT", "3XLT", "3XLT"],
                ["4XLT", "4XLT", "4XLT", "4XLT"],
                ["5XLT", "5XLT", "5XLT", "5XLT"],
                ["6XLT", "6XLT", "6XLT", "6XLT"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XL",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "US 3XL",
                      display_with_system_and_set: "US 3XL",
                      equivalent_sizes: {
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XL",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "US 4XL",
                      display_with_system_and_set: "US 4XL",
                      equivalent_sizes: {
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XL",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "US 5XL",
                      display_with_system_and_set: "US 5XL",
                      equivalent_sizes: {
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XL",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "US 6XL",
                      display_with_system_and_set: "US 6XL",
                      equivalent_sizes: {
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LT",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "US LT",
                      display_with_system_and_set: "US LT",
                      equivalent_sizes: {
                        au: ["LTAU"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLT",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "US XLT",
                      display_with_system_and_set: "US XLT",
                      equivalent_sizes: {
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XLT",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "US 2XLT",
                      display_with_system_and_set: "US 2XLT",
                      equivalent_sizes: {
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XLT",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "US 3XLT",
                      display_with_system_and_set: "US 3XLT",
                      equivalent_sizes: {
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XLT",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "US 4XLT",
                      display_with_system_and_set: "US 4XLT",
                      equivalent_sizes: {
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XLT",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "US 5XLT",
                      display_with_system_and_set: "US 5XLT",
                      equivalent_sizes: {
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XLT",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "US 6XLT",
                      display_with_system_and_set: "US 6XLT",
                      equivalent_sizes: {
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLAU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "AU 3XL",
                      display_with_system_and_set: "AU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLAU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "AU 4XL",
                      display_with_system_and_set: "AU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLAU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "AU 5XL",
                      display_with_system_and_set: "AU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLAU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "AU 6XL",
                      display_with_system_and_set: "AU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LTAU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "AU LT",
                      display_with_system_and_set: "AU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLTAU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "AU XLT",
                      display_with_system_and_set: "AU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XLTAU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "AU 2XLT",
                      display_with_system_and_set: "AU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLTAU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "AU 3XLT",
                      display_with_system_and_set: "AU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLTAU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "AU 4XLT",
                      display_with_system_and_set: "AU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLTAU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "AU 5XLT",
                      display_with_system_and_set: "AU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLTAU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "AU 6XLT",
                      display_with_system_and_set: "AU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLEU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "EU 3XL",
                      display_with_system_and_set: "EU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLEU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "EU 4XL",
                      display_with_system_and_set: "EU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLEU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "EU 5XL",
                      display_with_system_and_set: "EU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLEU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "EU 6XL",
                      display_with_system_and_set: "EU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LTEU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "EU LT",
                      display_with_system_and_set: "EU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        uk: ["LTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLTEU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "EU XLT",
                      display_with_system_and_set: "EU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XLTEU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "EU 2XLT",
                      display_with_system_and_set: "EU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLTEU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "EU 3XLT",
                      display_with_system_and_set: "EU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLTEU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "EU 4XLT",
                      display_with_system_and_set: "EU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLTEU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "EU 5XLT",
                      display_with_system_and_set: "EU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLTEU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "EU 6XLT",
                      display_with_system_and_set: "EU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLUK",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "UK 3XL",
                      display_with_system_and_set: "UK 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLUK",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "UK 4XL",
                      display_with_system_and_set: "UK 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLUK",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "UK 5XL",
                      display_with_system_and_set: "UK 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLUK",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "UK 6XL",
                      display_with_system_and_set: "UK 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LTUK",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "UK LT",
                      display_with_system_and_set: "UK LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        eu: ["LTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLTUK",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "UK XLT",
                      display_with_system_and_set: "UK XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XLTUK",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "UK 2XLT",
                      display_with_system_and_set: "UK 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLTUK",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "UK 3XLT",
                      display_with_system_and_set: "UK 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLTUK",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "UK 4XLT",
                      display_with_system_and_set: "UK 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLTUK",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "UK 5XLT",
                      display_with_system_and_set: "UK 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLTUK",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "UK 6XLT",
                      display_with_system_and_set: "UK 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "men_swim",
          aliases: [],
          slug: "Swim",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
          category_features: [
            {
              id: "54009813d97b4e3995005764",
              display: "Board Shorts",
              type: "cf",
              message_id: "men_swim_board_shorts",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Board_Shorts",
              alias_slugs: [],
              parent_id: "0d008c10d97b4e1245005764",
            },
            {
              id: "56009813d97b4e3995005764",
              display: "Hybrids",
              type: "cf",
              message_id: "men_swim_hybrids",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Hybrids",
              alias_slugs: [],
              parent_id: "0d008c10d97b4e1245005764",
            },
            {
              id: "57009813d97b4e3995005764",
              display: "Rash Guards",
              type: "cf",
              message_id: "men_swim_rash_guards",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Rash_Guards",
              alias_slugs: [],
              parent_id: "0d008c10d97b4e1245005764",
            },
            {
              id: "55009813d97b4e3995005764",
              display: "Swim Trunks",
              type: "cf",
              message_id: "men_swim_swim_trunks",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Swim_Trunks",
              alias_slugs: [],
              parent_id: "0d008c10d97b4e1245005764",
            },
          ],
        },
        {
          id: "0e008c10d97b4e1245005764",
          display: "Underwear & Socks",
          size_sets: [
            {
              id: "0a003667d97b4e6bdb005784",
              name: "Standard",
              tags: ["standard"],
              category_id: "0e008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Waist "],
                  ["28-30"],
                  ["30-32"],
                  ["32-34"],
                  ["34-36"],
                  ["36-38"],
                  ["38-40"],
                  ["40-42"],
                ],
                centimeters: [
                  ["Waist "],
                  ["71.1-76.2"],
                  ["76.2-81.3"],
                  ["81.3-86.4"],
                  ["86.4-91.4"],
                  ["91.4-96.5"],
                  ["96.5-101.6"],
                  ["101.6-106.7"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "c4fc8ac54024036cbb2b62f2",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "0e008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Waist "],
                  ["40-42"],
                  ["43-45"],
                  ["46-48"],
                  ["49-51"],
                  ["52-54"],
                  ["36-39"],
                  ["40-44"],
                  ["45-48.5"],
                  ["49-52"],
                  ["52-55"],
                  ["56-60"],
                  ["62-64"],
                ],
                centimeters: [
                  ["Waist "],
                  ["101.6-106.6"],
                  ["109.2-114.3"],
                  ["116.8-121.9"],
                  ["124.4-129.5"],
                  ["132-137.1"],
                  ["91.4-99"],
                  ["101.6-111.7"],
                  ["114.3-123.1"],
                  ["124.4-132"],
                  ["132-139.7"],
                  ["142.2-152.4"],
                  ["157.4-162.5"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["3XL", "3XL", "3XL", "3XL"],
                  ["4XL", "4XL", "4XL", "4XL"],
                  ["5XL", "5XL", "5XL", "5XL"],
                  ["6XL", "6XL", "6XL", "6XL"],
                  ["LT", "LT", "LT", "LT"],
                  ["XLT", "XLT", "XLT", "XLT"],
                  ["2XLT", "2XLT", "2XLT", "2XLT"],
                  ["3XLT", "3XLT", "3XLT", "3XLT"],
                  ["4XLT", "4XLT", "4XLT", "4XLT"],
                  ["5XLT", "5XLT", "5XLT", "5XLT"],
                  ["6XLT", "6XLT", "6XLT", "6XLT"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["3XL", "3XL", "3XL", "3XL"],
                ["4XL", "4XL", "4XL", "4XL"],
                ["5XL", "5XL", "5XL", "5XL"],
                ["6XL", "6XL", "6XL", "6XL"],
                ["LT", "LT", "LT", "LT"],
                ["XLT", "XLT", "XLT", "XLT"],
                ["2XLT", "2XLT", "2XLT", "2XLT"],
                ["3XLT", "3XLT", "3XLT", "3XLT"],
                ["4XLT", "4XLT", "4XLT", "4XLT"],
                ["5XLT", "5XLT", "5XLT", "5XLT"],
                ["6XLT", "6XLT", "6XLT", "6XLT"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XL",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "US 3XL",
                      display_with_system_and_set: "US 3XL",
                      equivalent_sizes: {
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XL",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "US 4XL",
                      display_with_system_and_set: "US 4XL",
                      equivalent_sizes: {
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XL",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "US 5XL",
                      display_with_system_and_set: "US 5XL",
                      equivalent_sizes: {
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XL",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "US 6XL",
                      display_with_system_and_set: "US 6XL",
                      equivalent_sizes: {
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LT",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "US LT",
                      display_with_system_and_set: "US LT",
                      equivalent_sizes: {
                        au: ["LTAU"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLT",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "US XLT",
                      display_with_system_and_set: "US XLT",
                      equivalent_sizes: {
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XLT",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "US 2XLT",
                      display_with_system_and_set: "US 2XLT",
                      equivalent_sizes: {
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XLT",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "US 3XLT",
                      display_with_system_and_set: "US 3XLT",
                      equivalent_sizes: {
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XLT",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "US 4XLT",
                      display_with_system_and_set: "US 4XLT",
                      equivalent_sizes: {
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XLT",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "US 5XLT",
                      display_with_system_and_set: "US 5XLT",
                      equivalent_sizes: {
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XLT",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "US 6XLT",
                      display_with_system_and_set: "US 6XLT",
                      equivalent_sizes: {
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLAU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "AU 3XL",
                      display_with_system_and_set: "AU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLAU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "AU 4XL",
                      display_with_system_and_set: "AU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLAU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "AU 5XL",
                      display_with_system_and_set: "AU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLAU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "AU 6XL",
                      display_with_system_and_set: "AU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LTAU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "AU LT",
                      display_with_system_and_set: "AU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLTAU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "AU XLT",
                      display_with_system_and_set: "AU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XLTAU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "AU 2XLT",
                      display_with_system_and_set: "AU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLTAU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "AU 3XLT",
                      display_with_system_and_set: "AU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLTAU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "AU 4XLT",
                      display_with_system_and_set: "AU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLTAU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "AU 5XLT",
                      display_with_system_and_set: "AU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLTAU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "AU 6XLT",
                      display_with_system_and_set: "AU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLEU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "EU 3XL",
                      display_with_system_and_set: "EU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLEU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "EU 4XL",
                      display_with_system_and_set: "EU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLEU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "EU 5XL",
                      display_with_system_and_set: "EU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLEU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "EU 6XL",
                      display_with_system_and_set: "EU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LTEU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "EU LT",
                      display_with_system_and_set: "EU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        uk: ["LTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLTEU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "EU XLT",
                      display_with_system_and_set: "EU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XLTEU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "EU 2XLT",
                      display_with_system_and_set: "EU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLTEU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "EU 3XLT",
                      display_with_system_and_set: "EU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLTEU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "EU 4XLT",
                      display_with_system_and_set: "EU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLTEU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "EU 5XLT",
                      display_with_system_and_set: "EU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLTEU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "EU 6XLT",
                      display_with_system_and_set: "EU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLUK",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "UK 3XL",
                      display_with_system_and_set: "UK 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLUK",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "UK 4XL",
                      display_with_system_and_set: "UK 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLUK",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "UK 5XL",
                      display_with_system_and_set: "UK 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLUK",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "UK 6XL",
                      display_with_system_and_set: "UK 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LTUK",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "UK LT",
                      display_with_system_and_set: "UK LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        eu: ["LTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLTUK",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "UK XLT",
                      display_with_system_and_set: "UK XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XLTUK",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "UK 2XLT",
                      display_with_system_and_set: "UK 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLTUK",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "UK 3XLT",
                      display_with_system_and_set: "UK 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLTUK",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "UK 4XLT",
                      display_with_system_and_set: "UK 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLTUK",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "UK 5XLT",
                      display_with_system_and_set: "UK 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLTUK",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "UK 6XLT",
                      display_with_system_and_set: "UK 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "men_underwear_socks",
          aliases: [],
          slug: "Underwear_&_Socks",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
          category_features: [
            {
              id: "62009813d97b4e3995005764",
              display: "Athletic Socks",
              type: "cf",
              message_id: "men_underwear_socks_athletic_socks",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Athletic_Socks",
              alias_slugs: [],
              parent_id: "0e008c10d97b4e1245005764",
            },
            {
              id: "59009813d97b4e3995005764",
              display: "Boxer Briefs",
              type: "cf",
              message_id: "men_underwear_socks_boxer_briefs",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Boxer_Briefs",
              alias_slugs: [],
              parent_id: "0e008c10d97b4e1245005764",
            },
            {
              id: "5a009813d97b4e3995005764",
              display: "Boxers",
              type: "cf",
              message_id: "men_underwear_socks_boxers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Boxers",
              alias_slugs: [],
              parent_id: "0e008c10d97b4e1245005764",
            },
            {
              id: "5b009813d97b4e3995005764",
              display: "Briefs",
              type: "cf",
              message_id: "men_underwear_socks_briefs",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Briefs",
              alias_slugs: [],
              parent_id: "0e008c10d97b4e1245005764",
            },
            {
              id: "61009813d97b4e3995005764",
              display: "Casual Socks",
              type: "cf",
              message_id: "men_underwear_socks_casual_socks",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Casual_Socks",
              alias_slugs: [],
              parent_id: "0e008c10d97b4e1245005764",
            },
            {
              id: "60009813d97b4e3995005764",
              display: "Dress Socks",
              type: "cf",
              message_id: "men_underwear_socks_dress_socks",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Dress_Socks",
              alias_slugs: [],
              parent_id: "0e008c10d97b4e1245005764",
            },
            {
              id: "5e009813d97b4e3995005764",
              display: "Undershirts",
              type: "cf",
              message_id: "men_underwear_socks_undershirts",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Undershirts",
              alias_slugs: [],
              parent_id: "0e008c10d97b4e1245005764",
            },
          ],
        },
        {
          id: "10008c10d97b4e1245005764",
          display: "Other",
          size_sets: [
            {
              id: "0b003667d97b4e6bdb005784",
              name: "Standard",
              tags: ["standard"],
              category_id: "10008c10d97b4e1245005764",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "men_other",
          aliases: [],
          slug: "Other",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
        },
        {
          id: "717df9aaaabb083120f45ec2",
          display: "Grooming",
          type: "c",
          size_sets: [
            {
              id: "cf7df9aaaabb083120f45ec2",
              name: "Standard",
              tags: ["standard"],
              category_id: "717df9aaaabb083120f45ec2",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "men_grooming",
          aliases: [],
          slug: "Grooming",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
          category_features: [
            {
              id: "ad7df9aaaabb083120f45ec2",
              display: "Cleanser",
              type: "cf",
              message_id: "men_grooming_cleanser",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cleanser",
              alias_slugs: [],
              parent_id: "717df9aaaabb083120f45ec2",
            },
            {
              id: "b17df9aaaabb083120f45ec2",
              display: "Grooming Tools",
              type: "cf",
              message_id: "men_grooming_grooming_tools",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Grooming_Tools",
              alias_slugs: [],
              parent_id: "717df9aaaabb083120f45ec2",
            },
            {
              id: "ae7df9aaaabb083120f45ec2",
              display: "Hair Care",
              type: "cf",
              message_id: "men_grooming_hair_care",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Hair_Care",
              alias_slugs: [],
              parent_id: "717df9aaaabb083120f45ec2",
            },
            {
              id: "af7df9aaaabb083120f45ec2",
              display: "Moisturizer",
              type: "cf",
              message_id: "men_grooming_moisturizer",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Moisturizer",
              alias_slugs: [],
              parent_id: "717df9aaaabb083120f45ec2",
            },
            {
              id: "b07df9aaaabb083120f45ec2",
              display: "Shaving",
              type: "cf",
              message_id: "men_grooming_shaving",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Shaving",
              alias_slugs: [],
              parent_id: "717df9aaaabb083120f45ec2",
            },
            {
              id: "b37df9aaaabb083120f45ec2",
              display: "Suncare",
              type: "cf",
              message_id: "men_grooming_suncare",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Suncare",
              alias_slugs: [],
              parent_id: "717df9aaaabb083120f45ec2",
            },
            {
              id: "b27df9aaaabb083120f45ec2",
              display: "Treatments",
              type: "cf",
              message_id: "men_grooming_treatments",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Treatments",
              alias_slugs: [],
              parent_id: "717df9aaaabb083120f45ec2",
            },
          ],
        },
        {
          id: "b2b476dd402403bf28a2606b",
          display: "Agbadas & Dashikis",
          type: "c",
          size_sets: [
            {
              id: "b69ecb3e402403cd9fcf606c",
              name: "Standard",
              tags: ["standard"],
              category_id: "b2b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["34-36", "28-30"],
                  ["36-38", "30-32"],
                  ["38-40", "32-34"],
                  ["40-42", "34-36"],
                  ["42-44", "36-38"],
                  ["44-46", "38-40"],
                  ["46-48", "40-42"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["86.4-91.4", "71.1-76.2"],
                  ["91.4-96.5", "76.2-81.3"],
                  ["96.5-101.6", "81.3-86.4"],
                  ["101.6-106.7", "86.4-91.4"],
                  ["106.7-111.8", "91.4-96.5"],
                  ["111.8-116.9", "96.5-101.6"],
                  ["116.9-121.9", "101.6-106.7"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "d479bbb5aabb08aed90362fb",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "b2b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["46-48", "40-42"],
                  ["49-51", "43-45"],
                  ["52-54", "46-48"],
                  ["55-57", "49-51"],
                  ["58-60", "52-54"],
                  ["42-44", "36-39"],
                  ["46-48", "40-44"],
                  ["50-52", "45-48.5"],
                  ["54-56", "49-52"],
                  ["56-58", "52-55"],
                  ["58-60", "56-60"],
                  ["62-64", "62-64"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["116.8-121.9", "101.6-106.6"],
                  ["124.4-129.5", "109.2-114.3"],
                  ["132.1-137.2.6", "116.8-121.9"],
                  ["139.7-144.8", "124.4-129.5"],
                  ["147.3-152.4", "132-137.1"],
                  ["106.7-111.7", "91.4-99"],
                  ["116.8-121.9", "101.6-111.7"],
                  ["127-132", "114.3-123.1"],
                  ["137.1-142.2", "124.4-132"],
                  ["142.2-147.3", "132-139.7"],
                  ["147.3-152.4", "142.2-152.4"],
                  ["157.4-162.5", "157.4-162.5"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["3XL", "3XL", "3XL", "3XL"],
                  ["4XL", "4XL", "4XL", "4XL"],
                  ["5XL", "5XL", "5XL", "5XL"],
                  ["6XL", "6XL", "6XL", "6XL"],
                  ["LT", "LT", "LT", "LT"],
                  ["XLT", "XLT", "XLT", "XLT"],
                  ["2XLT", "2XLT", "2XLT", "2XLT"],
                  ["3XLT", "3XLT", "3XLT", "3XLT"],
                  ["4XLT", "4XLT", "4XLT", "4XLT"],
                  ["5XLT", "5XLT", "5XLT", "5XLT"],
                  ["6XLT", "6XLT", "6XLT", "6XLT"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["3XL", "3XL", "3XL", "3XL"],
                ["4XL", "4XL", "4XL", "4XL"],
                ["5XL", "5XL", "5XL", "5XL"],
                ["6XL", "6XL", "6XL", "6XL"],
                ["LT", "LT", "LT", "LT"],
                ["XLT", "XLT", "XLT", "XLT"],
                ["2XLT", "2XLT", "2XLT", "2XLT"],
                ["3XLT", "3XLT", "3XLT", "3XLT"],
                ["4XLT", "4XLT", "4XLT", "4XLT"],
                ["5XLT", "5XLT", "5XLT", "5XLT"],
                ["6XLT", "6XLT", "6XLT", "6XLT"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XL",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "US 3XL",
                      display_with_system_and_set: "US 3XL",
                      equivalent_sizes: {
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XL",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "US 4XL",
                      display_with_system_and_set: "US 4XL",
                      equivalent_sizes: {
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XL",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "US 5XL",
                      display_with_system_and_set: "US 5XL",
                      equivalent_sizes: {
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XL",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "US 6XL",
                      display_with_system_and_set: "US 6XL",
                      equivalent_sizes: {
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LT",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "US LT",
                      display_with_system_and_set: "US LT",
                      equivalent_sizes: {
                        au: ["LTAU"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLT",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "US XLT",
                      display_with_system_and_set: "US XLT",
                      equivalent_sizes: {
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XLT",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "US 2XLT",
                      display_with_system_and_set: "US 2XLT",
                      equivalent_sizes: {
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XLT",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "US 3XLT",
                      display_with_system_and_set: "US 3XLT",
                      equivalent_sizes: {
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XLT",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "US 4XLT",
                      display_with_system_and_set: "US 4XLT",
                      equivalent_sizes: {
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XLT",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "US 5XLT",
                      display_with_system_and_set: "US 5XLT",
                      equivalent_sizes: {
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XLT",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "US 6XLT",
                      display_with_system_and_set: "US 6XLT",
                      equivalent_sizes: {
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLAU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "AU 3XL",
                      display_with_system_and_set: "AU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLAU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "AU 4XL",
                      display_with_system_and_set: "AU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLAU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "AU 5XL",
                      display_with_system_and_set: "AU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLAU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "AU 6XL",
                      display_with_system_and_set: "AU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LTAU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "AU LT",
                      display_with_system_and_set: "AU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLTAU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "AU XLT",
                      display_with_system_and_set: "AU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XLTAU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "AU 2XLT",
                      display_with_system_and_set: "AU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLTAU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "AU 3XLT",
                      display_with_system_and_set: "AU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLTAU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "AU 4XLT",
                      display_with_system_and_set: "AU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLTAU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "AU 5XLT",
                      display_with_system_and_set: "AU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLTAU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "AU 6XLT",
                      display_with_system_and_set: "AU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLEU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "EU 3XL",
                      display_with_system_and_set: "EU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLEU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "EU 4XL",
                      display_with_system_and_set: "EU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLEU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "EU 5XL",
                      display_with_system_and_set: "EU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLEU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "EU 6XL",
                      display_with_system_and_set: "EU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LTEU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "EU LT",
                      display_with_system_and_set: "EU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        uk: ["LTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLTEU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "EU XLT",
                      display_with_system_and_set: "EU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XLTEU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "EU 2XLT",
                      display_with_system_and_set: "EU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLTEU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "EU 3XLT",
                      display_with_system_and_set: "EU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLTEU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "EU 4XLT",
                      display_with_system_and_set: "EU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLTEU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "EU 5XLT",
                      display_with_system_and_set: "EU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLTEU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "EU 6XLT",
                      display_with_system_and_set: "EU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLUK",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "UK 3XL",
                      display_with_system_and_set: "UK 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLUK",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "UK 4XL",
                      display_with_system_and_set: "UK 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLUK",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "UK 5XL",
                      display_with_system_and_set: "UK 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLUK",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "UK 6XL",
                      display_with_system_and_set: "UK 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LTUK",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "UK LT",
                      display_with_system_and_set: "UK LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        eu: ["LTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLTUK",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "UK XLT",
                      display_with_system_and_set: "UK XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XLTUK",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "UK 2XLT",
                      display_with_system_and_set: "UK 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLTUK",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "UK 3XLT",
                      display_with_system_and_set: "UK 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLTUK",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "UK 4XLT",
                      display_with_system_and_set: "UK 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLTUK",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "UK 5XLT",
                      display_with_system_and_set: "UK 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLTUK",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "UK 6XLT",
                      display_with_system_and_set: "UK 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "men_agbadas_dashikis",
          aliases: [],
          slug: "Agbadas_&_Dashikis",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
        },
        {
          id: "b3b476dd402403bf28a2606b",
          display: "Ao Dais",
          type: "c",
          size_sets: [
            {
              id: "b79ecb3e402403cd9fcf606c",
              name: "Standard",
              tags: ["standard"],
              category_id: "b3b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["34-36", "28-30"],
                  ["36-38", "30-32"],
                  ["38-40", "32-34"],
                  ["40-42", "34-36"],
                  ["42-44", "36-38"],
                  ["44-46", "38-40"],
                  ["46-48", "40-42"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["86.4-91.4", "71.1-76.2"],
                  ["91.4-96.5", "76.2-81.3"],
                  ["96.5-101.6", "81.3-86.4"],
                  ["101.6-106.7", "86.4-91.4"],
                  ["106.7-111.8", "91.4-96.5"],
                  ["111.8-116.9", "96.5-101.6"],
                  ["116.9-121.9", "101.6-106.7"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "d579bbb5aabb08aed90362fb",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "b3b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["46-48", "40-42"],
                  ["49-51", "43-45"],
                  ["52-54", "46-48"],
                  ["55-57", "49-51"],
                  ["58-60", "52-54"],
                  ["42-44", "36-39"],
                  ["46-48", "40-44"],
                  ["50-52", "45-48.5"],
                  ["54-56", "49-52"],
                  ["56-58", "52-55"],
                  ["58-60", "56-60"],
                  ["62-64", "62-64"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["116.8-121.9", "101.6-106.6"],
                  ["124.4-129.5", "109.2-114.3"],
                  ["132.1-137.2.6", "116.8-121.9"],
                  ["139.7-144.8", "124.4-129.5"],
                  ["147.3-152.4", "132-137.1"],
                  ["106.7-111.7", "91.4-99"],
                  ["116.8-121.9", "101.6-111.7"],
                  ["127-132", "114.3-123.1"],
                  ["137.1-142.2", "124.4-132"],
                  ["142.2-147.3", "132-139.7"],
                  ["147.3-152.4", "142.2-152.4"],
                  ["157.4-162.5", "157.4-162.5"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["3XL", "3XL", "3XL", "3XL"],
                  ["4XL", "4XL", "4XL", "4XL"],
                  ["5XL", "5XL", "5XL", "5XL"],
                  ["6XL", "6XL", "6XL", "6XL"],
                  ["LT", "LT", "LT", "LT"],
                  ["XLT", "XLT", "XLT", "XLT"],
                  ["2XLT", "2XLT", "2XLT", "2XLT"],
                  ["3XLT", "3XLT", "3XLT", "3XLT"],
                  ["4XLT", "4XLT", "4XLT", "4XLT"],
                  ["5XLT", "5XLT", "5XLT", "5XLT"],
                  ["6XLT", "6XLT", "6XLT", "6XLT"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["3XL", "3XL", "3XL", "3XL"],
                ["4XL", "4XL", "4XL", "4XL"],
                ["5XL", "5XL", "5XL", "5XL"],
                ["6XL", "6XL", "6XL", "6XL"],
                ["LT", "LT", "LT", "LT"],
                ["XLT", "XLT", "XLT", "XLT"],
                ["2XLT", "2XLT", "2XLT", "2XLT"],
                ["3XLT", "3XLT", "3XLT", "3XLT"],
                ["4XLT", "4XLT", "4XLT", "4XLT"],
                ["5XLT", "5XLT", "5XLT", "5XLT"],
                ["6XLT", "6XLT", "6XLT", "6XLT"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XL",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "US 3XL",
                      display_with_system_and_set: "US 3XL",
                      equivalent_sizes: {
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XL",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "US 4XL",
                      display_with_system_and_set: "US 4XL",
                      equivalent_sizes: {
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XL",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "US 5XL",
                      display_with_system_and_set: "US 5XL",
                      equivalent_sizes: {
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XL",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "US 6XL",
                      display_with_system_and_set: "US 6XL",
                      equivalent_sizes: {
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LT",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "US LT",
                      display_with_system_and_set: "US LT",
                      equivalent_sizes: {
                        au: ["LTAU"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLT",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "US XLT",
                      display_with_system_and_set: "US XLT",
                      equivalent_sizes: {
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XLT",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "US 2XLT",
                      display_with_system_and_set: "US 2XLT",
                      equivalent_sizes: {
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XLT",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "US 3XLT",
                      display_with_system_and_set: "US 3XLT",
                      equivalent_sizes: {
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XLT",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "US 4XLT",
                      display_with_system_and_set: "US 4XLT",
                      equivalent_sizes: {
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XLT",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "US 5XLT",
                      display_with_system_and_set: "US 5XLT",
                      equivalent_sizes: {
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XLT",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "US 6XLT",
                      display_with_system_and_set: "US 6XLT",
                      equivalent_sizes: {
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLAU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "AU 3XL",
                      display_with_system_and_set: "AU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLAU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "AU 4XL",
                      display_with_system_and_set: "AU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLAU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "AU 5XL",
                      display_with_system_and_set: "AU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLAU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "AU 6XL",
                      display_with_system_and_set: "AU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LTAU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "AU LT",
                      display_with_system_and_set: "AU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLTAU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "AU XLT",
                      display_with_system_and_set: "AU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XLTAU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "AU 2XLT",
                      display_with_system_and_set: "AU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLTAU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "AU 3XLT",
                      display_with_system_and_set: "AU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLTAU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "AU 4XLT",
                      display_with_system_and_set: "AU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLTAU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "AU 5XLT",
                      display_with_system_and_set: "AU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLTAU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "AU 6XLT",
                      display_with_system_and_set: "AU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLEU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "EU 3XL",
                      display_with_system_and_set: "EU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLEU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "EU 4XL",
                      display_with_system_and_set: "EU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLEU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "EU 5XL",
                      display_with_system_and_set: "EU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLEU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "EU 6XL",
                      display_with_system_and_set: "EU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LTEU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "EU LT",
                      display_with_system_and_set: "EU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        uk: ["LTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLTEU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "EU XLT",
                      display_with_system_and_set: "EU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XLTEU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "EU 2XLT",
                      display_with_system_and_set: "EU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLTEU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "EU 3XLT",
                      display_with_system_and_set: "EU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLTEU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "EU 4XLT",
                      display_with_system_and_set: "EU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLTEU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "EU 5XLT",
                      display_with_system_and_set: "EU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLTEU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "EU 6XLT",
                      display_with_system_and_set: "EU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLUK",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "UK 3XL",
                      display_with_system_and_set: "UK 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLUK",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "UK 4XL",
                      display_with_system_and_set: "UK 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLUK",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "UK 5XL",
                      display_with_system_and_set: "UK 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLUK",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "UK 6XL",
                      display_with_system_and_set: "UK 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LTUK",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "UK LT",
                      display_with_system_and_set: "UK LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        eu: ["LTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLTUK",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "UK XLT",
                      display_with_system_and_set: "UK XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XLTUK",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "UK 2XLT",
                      display_with_system_and_set: "UK 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLTUK",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "UK 3XLT",
                      display_with_system_and_set: "UK 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLTUK",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "UK 4XLT",
                      display_with_system_and_set: "UK 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLTUK",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "UK 5XLT",
                      display_with_system_and_set: "UK 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLTUK",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "UK 6XLT",
                      display_with_system_and_set: "UK 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "men_ao_dais",
          aliases: [],
          slug: "Ao_Dais",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
        },
        {
          id: "b4b476dd402403bf28a2606b",
          display: "Changshans",
          type: "c",
          size_sets: [
            {
              id: "b89ecb3e402403cd9fcf606c",
              name: "Standard",
              tags: ["standard"],
              category_id: "b4b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["34-36", "28-30"],
                  ["36-38", "30-32"],
                  ["38-40", "32-34"],
                  ["40-42", "34-36"],
                  ["42-44", "36-38"],
                  ["44-46", "38-40"],
                  ["46-48", "40-42"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["86.4-91.4", "71.1-76.2"],
                  ["91.4-96.5", "76.2-81.3"],
                  ["96.5-101.6", "81.3-86.4"],
                  ["101.6-106.7", "86.4-91.4"],
                  ["106.7-111.8", "91.4-96.5"],
                  ["111.8-116.9", "96.5-101.6"],
                  ["116.9-121.9", "101.6-106.7"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "d679bbb6aabb08aed90362fb",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "b4b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["46-48", "40-42"],
                  ["49-51", "43-45"],
                  ["52-54", "46-48"],
                  ["55-57", "49-51"],
                  ["58-60", "52-54"],
                  ["42-44", "36-39"],
                  ["46-48", "40-44"],
                  ["50-52", "45-48.5"],
                  ["54-56", "49-52"],
                  ["56-58", "52-55"],
                  ["58-60", "56-60"],
                  ["62-64", "62-64"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["116.8-121.9", "101.6-106.6"],
                  ["124.4-129.5", "109.2-114.3"],
                  ["132.1-137.2.6", "116.8-121.9"],
                  ["139.7-144.8", "124.4-129.5"],
                  ["147.3-152.4", "132-137.1"],
                  ["106.7-111.7", "91.4-99"],
                  ["116.8-121.9", "101.6-111.7"],
                  ["127-132", "114.3-123.1"],
                  ["137.1-142.2", "124.4-132"],
                  ["142.2-147.3", "132-139.7"],
                  ["147.3-152.4", "142.2-152.4"],
                  ["157.4-162.5", "157.4-162.5"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["3XL", "3XL", "3XL", "3XL"],
                  ["4XL", "4XL", "4XL", "4XL"],
                  ["5XL", "5XL", "5XL", "5XL"],
                  ["6XL", "6XL", "6XL", "6XL"],
                  ["LT", "LT", "LT", "LT"],
                  ["XLT", "XLT", "XLT", "XLT"],
                  ["2XLT", "2XLT", "2XLT", "2XLT"],
                  ["3XLT", "3XLT", "3XLT", "3XLT"],
                  ["4XLT", "4XLT", "4XLT", "4XLT"],
                  ["5XLT", "5XLT", "5XLT", "5XLT"],
                  ["6XLT", "6XLT", "6XLT", "6XLT"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["3XL", "3XL", "3XL", "3XL"],
                ["4XL", "4XL", "4XL", "4XL"],
                ["5XL", "5XL", "5XL", "5XL"],
                ["6XL", "6XL", "6XL", "6XL"],
                ["LT", "LT", "LT", "LT"],
                ["XLT", "XLT", "XLT", "XLT"],
                ["2XLT", "2XLT", "2XLT", "2XLT"],
                ["3XLT", "3XLT", "3XLT", "3XLT"],
                ["4XLT", "4XLT", "4XLT", "4XLT"],
                ["5XLT", "5XLT", "5XLT", "5XLT"],
                ["6XLT", "6XLT", "6XLT", "6XLT"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XL",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "US 3XL",
                      display_with_system_and_set: "US 3XL",
                      equivalent_sizes: {
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XL",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "US 4XL",
                      display_with_system_and_set: "US 4XL",
                      equivalent_sizes: {
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XL",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "US 5XL",
                      display_with_system_and_set: "US 5XL",
                      equivalent_sizes: {
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XL",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "US 6XL",
                      display_with_system_and_set: "US 6XL",
                      equivalent_sizes: {
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LT",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "US LT",
                      display_with_system_and_set: "US LT",
                      equivalent_sizes: {
                        au: ["LTAU"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLT",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "US XLT",
                      display_with_system_and_set: "US XLT",
                      equivalent_sizes: {
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XLT",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "US 2XLT",
                      display_with_system_and_set: "US 2XLT",
                      equivalent_sizes: {
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XLT",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "US 3XLT",
                      display_with_system_and_set: "US 3XLT",
                      equivalent_sizes: {
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XLT",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "US 4XLT",
                      display_with_system_and_set: "US 4XLT",
                      equivalent_sizes: {
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XLT",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "US 5XLT",
                      display_with_system_and_set: "US 5XLT",
                      equivalent_sizes: {
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XLT",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "US 6XLT",
                      display_with_system_and_set: "US 6XLT",
                      equivalent_sizes: {
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLAU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "AU 3XL",
                      display_with_system_and_set: "AU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLAU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "AU 4XL",
                      display_with_system_and_set: "AU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLAU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "AU 5XL",
                      display_with_system_and_set: "AU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLAU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "AU 6XL",
                      display_with_system_and_set: "AU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LTAU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "AU LT",
                      display_with_system_and_set: "AU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLTAU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "AU XLT",
                      display_with_system_and_set: "AU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XLTAU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "AU 2XLT",
                      display_with_system_and_set: "AU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLTAU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "AU 3XLT",
                      display_with_system_and_set: "AU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLTAU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "AU 4XLT",
                      display_with_system_and_set: "AU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLTAU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "AU 5XLT",
                      display_with_system_and_set: "AU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLTAU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "AU 6XLT",
                      display_with_system_and_set: "AU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLEU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "EU 3XL",
                      display_with_system_and_set: "EU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLEU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "EU 4XL",
                      display_with_system_and_set: "EU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLEU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "EU 5XL",
                      display_with_system_and_set: "EU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLEU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "EU 6XL",
                      display_with_system_and_set: "EU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LTEU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "EU LT",
                      display_with_system_and_set: "EU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        uk: ["LTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLTEU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "EU XLT",
                      display_with_system_and_set: "EU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XLTEU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "EU 2XLT",
                      display_with_system_and_set: "EU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLTEU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "EU 3XLT",
                      display_with_system_and_set: "EU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLTEU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "EU 4XLT",
                      display_with_system_and_set: "EU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLTEU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "EU 5XLT",
                      display_with_system_and_set: "EU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLTEU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "EU 6XLT",
                      display_with_system_and_set: "EU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLUK",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "UK 3XL",
                      display_with_system_and_set: "UK 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLUK",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "UK 4XL",
                      display_with_system_and_set: "UK 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLUK",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "UK 5XL",
                      display_with_system_and_set: "UK 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLUK",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "UK 6XL",
                      display_with_system_and_set: "UK 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LTUK",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "UK LT",
                      display_with_system_and_set: "UK LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        eu: ["LTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLTUK",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "UK XLT",
                      display_with_system_and_set: "UK XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XLTUK",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "UK 2XLT",
                      display_with_system_and_set: "UK 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLTUK",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "UK 3XLT",
                      display_with_system_and_set: "UK 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLTUK",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "UK 4XLT",
                      display_with_system_and_set: "UK 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLTUK",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "UK 5XLT",
                      display_with_system_and_set: "UK 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLTUK",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "UK 6XLT",
                      display_with_system_and_set: "UK 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "men_changshans",
          aliases: [],
          slug: "Changshans",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
        },
        {
          id: "b5b476dd402403bf28a2606b",
          display: "Hanboks",
          type: "c",
          size_sets: [
            {
              id: "b99ecb3e402403cd9fcf606c",
              name: "Standard",
              tags: ["standard"],
              category_id: "b5b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["34-36", "28-30"],
                  ["36-38", "30-32"],
                  ["38-40", "32-34"],
                  ["40-42", "34-36"],
                  ["42-44", "36-38"],
                  ["44-46", "38-40"],
                  ["46-48", "40-42"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["86.4-91.4", "71.1-76.2"],
                  ["91.4-96.5", "76.2-81.3"],
                  ["96.5-101.6", "81.3-86.4"],
                  ["101.6-106.7", "86.4-91.4"],
                  ["106.7-111.8", "91.4-96.5"],
                  ["111.8-116.9", "96.5-101.6"],
                  ["116.9-121.9", "101.6-106.7"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "d779bbb6aabb08aed90362fb",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "b5b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["46-48", "40-42"],
                  ["49-51", "43-45"],
                  ["52-54", "46-48"],
                  ["55-57", "49-51"],
                  ["58-60", "52-54"],
                  ["42-44", "36-39"],
                  ["46-48", "40-44"],
                  ["50-52", "45-48.5"],
                  ["54-56", "49-52"],
                  ["56-58", "52-55"],
                  ["58-60", "56-60"],
                  ["62-64", "62-64"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["116.8-121.9", "101.6-106.6"],
                  ["124.4-129.5", "109.2-114.3"],
                  ["132.1-137.2.6", "116.8-121.9"],
                  ["139.7-144.8", "124.4-129.5"],
                  ["147.3-152.4", "132-137.1"],
                  ["106.7-111.7", "91.4-99"],
                  ["116.8-121.9", "101.6-111.7"],
                  ["127-132", "114.3-123.1"],
                  ["137.1-142.2", "124.4-132"],
                  ["142.2-147.3", "132-139.7"],
                  ["147.3-152.4", "142.2-152.4"],
                  ["157.4-162.5", "157.4-162.5"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["3XL", "3XL", "3XL", "3XL"],
                  ["4XL", "4XL", "4XL", "4XL"],
                  ["5XL", "5XL", "5XL", "5XL"],
                  ["6XL", "6XL", "6XL", "6XL"],
                  ["LT", "LT", "LT", "LT"],
                  ["XLT", "XLT", "XLT", "XLT"],
                  ["2XLT", "2XLT", "2XLT", "2XLT"],
                  ["3XLT", "3XLT", "3XLT", "3XLT"],
                  ["4XLT", "4XLT", "4XLT", "4XLT"],
                  ["5XLT", "5XLT", "5XLT", "5XLT"],
                  ["6XLT", "6XLT", "6XLT", "6XLT"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["3XL", "3XL", "3XL", "3XL"],
                ["4XL", "4XL", "4XL", "4XL"],
                ["5XL", "5XL", "5XL", "5XL"],
                ["6XL", "6XL", "6XL", "6XL"],
                ["LT", "LT", "LT", "LT"],
                ["XLT", "XLT", "XLT", "XLT"],
                ["2XLT", "2XLT", "2XLT", "2XLT"],
                ["3XLT", "3XLT", "3XLT", "3XLT"],
                ["4XLT", "4XLT", "4XLT", "4XLT"],
                ["5XLT", "5XLT", "5XLT", "5XLT"],
                ["6XLT", "6XLT", "6XLT", "6XLT"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XL",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "US 3XL",
                      display_with_system_and_set: "US 3XL",
                      equivalent_sizes: {
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XL",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "US 4XL",
                      display_with_system_and_set: "US 4XL",
                      equivalent_sizes: {
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XL",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "US 5XL",
                      display_with_system_and_set: "US 5XL",
                      equivalent_sizes: {
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XL",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "US 6XL",
                      display_with_system_and_set: "US 6XL",
                      equivalent_sizes: {
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LT",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "US LT",
                      display_with_system_and_set: "US LT",
                      equivalent_sizes: {
                        au: ["LTAU"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLT",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "US XLT",
                      display_with_system_and_set: "US XLT",
                      equivalent_sizes: {
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XLT",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "US 2XLT",
                      display_with_system_and_set: "US 2XLT",
                      equivalent_sizes: {
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XLT",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "US 3XLT",
                      display_with_system_and_set: "US 3XLT",
                      equivalent_sizes: {
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XLT",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "US 4XLT",
                      display_with_system_and_set: "US 4XLT",
                      equivalent_sizes: {
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XLT",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "US 5XLT",
                      display_with_system_and_set: "US 5XLT",
                      equivalent_sizes: {
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XLT",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "US 6XLT",
                      display_with_system_and_set: "US 6XLT",
                      equivalent_sizes: {
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLAU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "AU 3XL",
                      display_with_system_and_set: "AU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLAU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "AU 4XL",
                      display_with_system_and_set: "AU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLAU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "AU 5XL",
                      display_with_system_and_set: "AU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLAU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "AU 6XL",
                      display_with_system_and_set: "AU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LTAU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "AU LT",
                      display_with_system_and_set: "AU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLTAU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "AU XLT",
                      display_with_system_and_set: "AU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XLTAU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "AU 2XLT",
                      display_with_system_and_set: "AU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLTAU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "AU 3XLT",
                      display_with_system_and_set: "AU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLTAU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "AU 4XLT",
                      display_with_system_and_set: "AU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLTAU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "AU 5XLT",
                      display_with_system_and_set: "AU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLTAU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "AU 6XLT",
                      display_with_system_and_set: "AU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLEU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "EU 3XL",
                      display_with_system_and_set: "EU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLEU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "EU 4XL",
                      display_with_system_and_set: "EU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLEU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "EU 5XL",
                      display_with_system_and_set: "EU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLEU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "EU 6XL",
                      display_with_system_and_set: "EU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LTEU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "EU LT",
                      display_with_system_and_set: "EU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        uk: ["LTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLTEU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "EU XLT",
                      display_with_system_and_set: "EU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XLTEU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "EU 2XLT",
                      display_with_system_and_set: "EU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLTEU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "EU 3XLT",
                      display_with_system_and_set: "EU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLTEU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "EU 4XLT",
                      display_with_system_and_set: "EU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLTEU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "EU 5XLT",
                      display_with_system_and_set: "EU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLTEU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "EU 6XLT",
                      display_with_system_and_set: "EU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLUK",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "UK 3XL",
                      display_with_system_and_set: "UK 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLUK",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "UK 4XL",
                      display_with_system_and_set: "UK 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLUK",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "UK 5XL",
                      display_with_system_and_set: "UK 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLUK",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "UK 6XL",
                      display_with_system_and_set: "UK 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LTUK",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "UK LT",
                      display_with_system_and_set: "UK LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        eu: ["LTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLTUK",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "UK XLT",
                      display_with_system_and_set: "UK XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XLTUK",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "UK 2XLT",
                      display_with_system_and_set: "UK 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLTUK",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "UK 3XLT",
                      display_with_system_and_set: "UK 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLTUK",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "UK 4XLT",
                      display_with_system_and_set: "UK 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLTUK",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "UK 5XLT",
                      display_with_system_and_set: "UK 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLTUK",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "UK 6XLT",
                      display_with_system_and_set: "UK 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "men_hanboks",
          aliases: [],
          slug: "Hanboks",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
        },
        {
          id: "b6b476dd402403bf28a2606b",
          display: "Kaftans",
          type: "c",
          size_sets: [
            {
              id: "ba9ecb3e402403cd9fcf606c",
              name: "Standard",
              tags: ["standard"],
              category_id: "b6b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["34-36", "28-30"],
                  ["36-38", "30-32"],
                  ["38-40", "32-34"],
                  ["40-42", "34-36"],
                  ["42-44", "36-38"],
                  ["44-46", "38-40"],
                  ["46-48", "40-42"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["86.4-91.4", "71.1-76.2"],
                  ["91.4-96.5", "76.2-81.3"],
                  ["96.5-101.6", "81.3-86.4"],
                  ["101.6-106.7", "86.4-91.4"],
                  ["106.7-111.8", "91.4-96.5"],
                  ["111.8-116.9", "96.5-101.6"],
                  ["116.9-121.9", "101.6-106.7"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "d879bbb6aabb08aed90362fb",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "b6b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["46-48", "40-42"],
                  ["49-51", "43-45"],
                  ["52-54", "46-48"],
                  ["55-57", "49-51"],
                  ["58-60", "52-54"],
                  ["42-44", "36-39"],
                  ["46-48", "40-44"],
                  ["50-52", "45-48.5"],
                  ["54-56", "49-52"],
                  ["56-58", "52-55"],
                  ["58-60", "56-60"],
                  ["62-64", "62-64"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["116.8-121.9", "101.6-106.6"],
                  ["124.4-129.5", "109.2-114.3"],
                  ["132.1-137.2.6", "116.8-121.9"],
                  ["139.7-144.8", "124.4-129.5"],
                  ["147.3-152.4", "132-137.1"],
                  ["106.7-111.7", "91.4-99"],
                  ["116.8-121.9", "101.6-111.7"],
                  ["127-132", "114.3-123.1"],
                  ["137.1-142.2", "124.4-132"],
                  ["142.2-147.3", "132-139.7"],
                  ["147.3-152.4", "142.2-152.4"],
                  ["157.4-162.5", "157.4-162.5"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["3XL", "3XL", "3XL", "3XL"],
                  ["4XL", "4XL", "4XL", "4XL"],
                  ["5XL", "5XL", "5XL", "5XL"],
                  ["6XL", "6XL", "6XL", "6XL"],
                  ["LT", "LT", "LT", "LT"],
                  ["XLT", "XLT", "XLT", "XLT"],
                  ["2XLT", "2XLT", "2XLT", "2XLT"],
                  ["3XLT", "3XLT", "3XLT", "3XLT"],
                  ["4XLT", "4XLT", "4XLT", "4XLT"],
                  ["5XLT", "5XLT", "5XLT", "5XLT"],
                  ["6XLT", "6XLT", "6XLT", "6XLT"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["3XL", "3XL", "3XL", "3XL"],
                ["4XL", "4XL", "4XL", "4XL"],
                ["5XL", "5XL", "5XL", "5XL"],
                ["6XL", "6XL", "6XL", "6XL"],
                ["LT", "LT", "LT", "LT"],
                ["XLT", "XLT", "XLT", "XLT"],
                ["2XLT", "2XLT", "2XLT", "2XLT"],
                ["3XLT", "3XLT", "3XLT", "3XLT"],
                ["4XLT", "4XLT", "4XLT", "4XLT"],
                ["5XLT", "5XLT", "5XLT", "5XLT"],
                ["6XLT", "6XLT", "6XLT", "6XLT"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XL",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "US 3XL",
                      display_with_system_and_set: "US 3XL",
                      equivalent_sizes: {
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XL",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "US 4XL",
                      display_with_system_and_set: "US 4XL",
                      equivalent_sizes: {
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XL",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "US 5XL",
                      display_with_system_and_set: "US 5XL",
                      equivalent_sizes: {
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XL",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "US 6XL",
                      display_with_system_and_set: "US 6XL",
                      equivalent_sizes: {
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LT",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "US LT",
                      display_with_system_and_set: "US LT",
                      equivalent_sizes: {
                        au: ["LTAU"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLT",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "US XLT",
                      display_with_system_and_set: "US XLT",
                      equivalent_sizes: {
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XLT",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "US 2XLT",
                      display_with_system_and_set: "US 2XLT",
                      equivalent_sizes: {
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XLT",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "US 3XLT",
                      display_with_system_and_set: "US 3XLT",
                      equivalent_sizes: {
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XLT",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "US 4XLT",
                      display_with_system_and_set: "US 4XLT",
                      equivalent_sizes: {
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XLT",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "US 5XLT",
                      display_with_system_and_set: "US 5XLT",
                      equivalent_sizes: {
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XLT",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "US 6XLT",
                      display_with_system_and_set: "US 6XLT",
                      equivalent_sizes: {
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLAU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "AU 3XL",
                      display_with_system_and_set: "AU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLAU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "AU 4XL",
                      display_with_system_and_set: "AU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLAU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "AU 5XL",
                      display_with_system_and_set: "AU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLAU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "AU 6XL",
                      display_with_system_and_set: "AU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LTAU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "AU LT",
                      display_with_system_and_set: "AU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLTAU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "AU XLT",
                      display_with_system_and_set: "AU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XLTAU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "AU 2XLT",
                      display_with_system_and_set: "AU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLTAU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "AU 3XLT",
                      display_with_system_and_set: "AU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLTAU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "AU 4XLT",
                      display_with_system_and_set: "AU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLTAU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "AU 5XLT",
                      display_with_system_and_set: "AU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLTAU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "AU 6XLT",
                      display_with_system_and_set: "AU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLEU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "EU 3XL",
                      display_with_system_and_set: "EU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLEU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "EU 4XL",
                      display_with_system_and_set: "EU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLEU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "EU 5XL",
                      display_with_system_and_set: "EU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLEU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "EU 6XL",
                      display_with_system_and_set: "EU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LTEU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "EU LT",
                      display_with_system_and_set: "EU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        uk: ["LTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLTEU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "EU XLT",
                      display_with_system_and_set: "EU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XLTEU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "EU 2XLT",
                      display_with_system_and_set: "EU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLTEU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "EU 3XLT",
                      display_with_system_and_set: "EU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLTEU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "EU 4XLT",
                      display_with_system_and_set: "EU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLTEU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "EU 5XLT",
                      display_with_system_and_set: "EU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLTEU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "EU 6XLT",
                      display_with_system_and_set: "EU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLUK",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "UK 3XL",
                      display_with_system_and_set: "UK 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLUK",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "UK 4XL",
                      display_with_system_and_set: "UK 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLUK",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "UK 5XL",
                      display_with_system_and_set: "UK 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLUK",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "UK 6XL",
                      display_with_system_and_set: "UK 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LTUK",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "UK LT",
                      display_with_system_and_set: "UK LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        eu: ["LTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLTUK",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "UK XLT",
                      display_with_system_and_set: "UK XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XLTUK",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "UK 2XLT",
                      display_with_system_and_set: "UK 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLTUK",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "UK 3XLT",
                      display_with_system_and_set: "UK 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLTUK",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "UK 4XLT",
                      display_with_system_and_set: "UK 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLTUK",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "UK 5XLT",
                      display_with_system_and_set: "UK 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLTUK",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "UK 6XLT",
                      display_with_system_and_set: "UK 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "men_kaftans",
          aliases: [],
          slug: "Kaftans",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
        },
        {
          id: "b7b476dd402403bf28a2606b",
          display: "Keffiyehs",
          type: "c",
          size_sets: [
            {
              id: "bd9ecb3e402403cd9fcf606c",
              name: "Standard",
              tags: ["standard"],
              category_id: "b7b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "men_keffiyehs",
          aliases: [],
          slug: "Keffiyehs",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
        },
        {
          id: "b8b476dd402403bf28a2606b",
          display: "Kilts",
          type: "c",
          size_sets: [
            {
              id: "b19ecb3e402403cd9fcf606c",
              name: "Standard",
              tags: ["standard"],
              category_id: "b8b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["34-36", "28-30"],
                  ["36-38", "30-32"],
                  ["38-40", "32-34"],
                  ["40-42", "34-36"],
                  ["42-44", "36-38"],
                  ["44-46", "38-40"],
                  ["46-48", "40-42"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["86.4-91.4", "71.1-76.2"],
                  ["91.4-96.5", "76.2-81.3"],
                  ["96.5-101.6", "81.3-86.4"],
                  ["101.6-106.7", "86.4-91.4"],
                  ["106.7-111.8", "91.4-96.5"],
                  ["111.8-116.9", "96.5-101.6"],
                  ["116.9-121.9", "101.6-106.7"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["Waist 28", "28", "71.12", "28"],
                ["Waist 29", "29", "73.66", "29"],
                ["Waist 30", "30", "76.2", "30"],
                ["Waist 31", "31", "78.8", "31"],
                ["Waist 32", "32", "81.3", "32"],
                ["Waist 33", "33", "83.8", "33"],
                ["Waist 34", "34", "86.4", "34"],
                ["Waist 35", "35", "88.9", "35"],
                ["Waist 36", "36", "91.4", "36"],
                ["Waist 37", "37", "94", "37"],
                ["Waist 38", "38", "96.5", "38"],
                ["Waist 39", "39", "99.1", "39"],
                ["Waist 40", "40", "101.6", "40"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "Waist 28",
                      long: "Waist 28",
                      display: "Waist 28",
                      display_with_size_set: "Waist 28",
                      display_with_size_system: "US Waist 28",
                      display_with_system_and_set: "US Waist 28",
                      equivalent_sizes: {
                        au: ["28AU"],
                        eu: ["71.12EU"],
                        uk: ["28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "29",
                      short: "Waist 29",
                      long: "Waist 29",
                      display: "Waist 29",
                      display_with_size_set: "Waist 29",
                      display_with_size_system: "US Waist 29",
                      display_with_system_and_set: "US Waist 29",
                      equivalent_sizes: {
                        au: ["29AU"],
                        eu: ["73.66EU"],
                        uk: ["29UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "Waist 30",
                      long: "Waist 30",
                      display: "Waist 30",
                      display_with_size_set: "Waist 30",
                      display_with_size_system: "US Waist 30",
                      display_with_system_and_set: "US Waist 30",
                      equivalent_sizes: {
                        au: ["30AU"],
                        eu: ["76.2EU"],
                        uk: ["30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "31",
                      short: "Waist 31",
                      long: "Waist 31",
                      display: "Waist 31",
                      display_with_size_set: "Waist 31",
                      display_with_size_system: "US Waist 31",
                      display_with_system_and_set: "US Waist 31",
                      equivalent_sizes: {
                        au: ["31AU"],
                        eu: ["78.8EU"],
                        uk: ["31UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "Waist 32",
                      long: "Waist 32",
                      display: "Waist 32",
                      display_with_size_set: "Waist 32",
                      display_with_size_system: "US Waist 32",
                      display_with_system_and_set: "US Waist 32",
                      equivalent_sizes: {
                        au: ["32AU"],
                        eu: ["81.3EU"],
                        uk: ["32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "33",
                      short: "Waist 33",
                      long: "Waist 33",
                      display: "Waist 33",
                      display_with_size_set: "Waist 33",
                      display_with_size_system: "US Waist 33",
                      display_with_system_and_set: "US Waist 33",
                      equivalent_sizes: {
                        au: ["33AU"],
                        eu: ["83.8EU"],
                        uk: ["33UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34",
                      short: "Waist 34",
                      long: "Waist 34",
                      display: "Waist 34",
                      display_with_size_set: "Waist 34",
                      display_with_size_system: "US Waist 34",
                      display_with_system_and_set: "US Waist 34",
                      equivalent_sizes: {
                        au: ["34AU"],
                        eu: ["86.4EU"],
                        uk: ["34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "35",
                      short: "Waist 35",
                      long: "Waist 35",
                      display: "Waist 35",
                      display_with_size_set: "Waist 35",
                      display_with_size_system: "US Waist 35",
                      display_with_system_and_set: "US Waist 35",
                      equivalent_sizes: {
                        au: ["35AU"],
                        eu: ["88.9EU"],
                        uk: ["35UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36",
                      short: "Waist 36",
                      long: "Waist 36",
                      display: "Waist 36",
                      display_with_size_set: "Waist 36",
                      display_with_size_system: "US Waist 36",
                      display_with_system_and_set: "US Waist 36",
                      equivalent_sizes: {
                        au: ["36AU"],
                        eu: ["91.4EU"],
                        uk: ["36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "37",
                      short: "Waist 37",
                      long: "Waist 37",
                      display: "Waist 37",
                      display_with_size_set: "Waist 37",
                      display_with_size_system: "US Waist 37",
                      display_with_system_and_set: "US Waist 37",
                      equivalent_sizes: {
                        au: ["37AU"],
                        eu: ["94EU"],
                        uk: ["37UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38",
                      short: "Waist 38",
                      long: "Waist 38",
                      display: "Waist 38",
                      display_with_size_set: "Waist 38",
                      display_with_size_system: "US Waist 38",
                      display_with_system_and_set: "US Waist 38",
                      equivalent_sizes: {
                        au: ["38AU"],
                        eu: ["96.5EU"],
                        uk: ["38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "39",
                      short: "Waist 39",
                      long: "Waist 39",
                      display: "Waist 39",
                      display_with_size_set: "Waist 39",
                      display_with_size_system: "US Waist 39",
                      display_with_system_and_set: "US Waist 39",
                      equivalent_sizes: {
                        au: ["39AU"],
                        eu: ["99.1EU"],
                        uk: ["39UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40",
                      short: "Waist 40",
                      long: "Waist 40",
                      display: "Waist 40",
                      display_with_size_set: "Waist 40",
                      display_with_size_system: "US Waist 40",
                      display_with_system_and_set: "US Waist 40",
                      equivalent_sizes: {
                        au: ["40AU"],
                        eu: ["101.6EU"],
                        uk: ["40UK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["28"],
                        eu: ["71.12EU"],
                        uk: ["28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "29AU",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "AU 29",
                      display_with_system_and_set: "AU 29",
                      equivalent_sizes: {
                        us: ["29"],
                        eu: ["73.66EU"],
                        uk: ["29UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["30"],
                        eu: ["76.2EU"],
                        uk: ["30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "31AU",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "AU 31",
                      display_with_system_and_set: "AU 31",
                      equivalent_sizes: {
                        us: ["31"],
                        eu: ["78.8EU"],
                        uk: ["31UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["32"],
                        eu: ["81.3EU"],
                        uk: ["32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "33AU",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "AU 33",
                      display_with_system_and_set: "AU 33",
                      equivalent_sizes: {
                        us: ["33"],
                        eu: ["83.8EU"],
                        uk: ["33UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["34"],
                        eu: ["86.4EU"],
                        uk: ["34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "35AU",
                      short: "35",
                      long: "35",
                      display: "35",
                      display_with_size_set: "35",
                      display_with_size_system: "AU 35",
                      display_with_system_and_set: "AU 35",
                      equivalent_sizes: {
                        us: ["35"],
                        eu: ["88.9EU"],
                        uk: ["35UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["36"],
                        eu: ["91.4EU"],
                        uk: ["36UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "37AU",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "AU 37",
                      display_with_system_and_set: "AU 37",
                      equivalent_sizes: {
                        us: ["37"],
                        eu: ["94EU"],
                        uk: ["37UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["38"],
                        eu: ["96.5EU"],
                        uk: ["38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "39AU",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "AU 39",
                      display_with_system_and_set: "AU 39",
                      equivalent_sizes: {
                        us: ["39"],
                        eu: ["99.1EU"],
                        uk: ["39UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "40AU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "AU 40",
                      display_with_system_and_set: "AU 40",
                      equivalent_sizes: {
                        us: ["40"],
                        eu: ["101.6EU"],
                        uk: ["40UK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "71.12EU",
                      short: "71.12",
                      long: "71.12",
                      display: "71.12",
                      display_with_size_set: "71.12",
                      display_with_size_system: "EU 71.12",
                      display_with_system_and_set: "EU 71.12",
                      equivalent_sizes: {
                        us: ["28"],
                        au: ["28AU"],
                        uk: ["28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "73.66EU",
                      short: "73.66",
                      long: "73.66",
                      display: "73.66",
                      display_with_size_set: "73.66",
                      display_with_size_system: "EU 73.66",
                      display_with_system_and_set: "EU 73.66",
                      equivalent_sizes: {
                        us: ["29"],
                        au: ["29AU"],
                        uk: ["29UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "76.2EU",
                      short: "76.2",
                      long: "76.2",
                      display: "76.2",
                      display_with_size_set: "76.2",
                      display_with_size_system: "EU 76.2",
                      display_with_system_and_set: "EU 76.2",
                      equivalent_sizes: {
                        us: ["30"],
                        au: ["30AU"],
                        uk: ["30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "78.8EU",
                      short: "78.8",
                      long: "78.8",
                      display: "78.8",
                      display_with_size_set: "78.8",
                      display_with_size_system: "EU 78.8",
                      display_with_system_and_set: "EU 78.8",
                      equivalent_sizes: {
                        us: ["31"],
                        au: ["31AU"],
                        uk: ["31UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "81.3EU",
                      short: "81.3",
                      long: "81.3",
                      display: "81.3",
                      display_with_size_set: "81.3",
                      display_with_size_system: "EU 81.3",
                      display_with_system_and_set: "EU 81.3",
                      equivalent_sizes: {
                        us: ["32"],
                        au: ["32AU"],
                        uk: ["32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "83.8EU",
                      short: "83.8",
                      long: "83.8",
                      display: "83.8",
                      display_with_size_set: "83.8",
                      display_with_size_system: "EU 83.8",
                      display_with_system_and_set: "EU 83.8",
                      equivalent_sizes: {
                        us: ["33"],
                        au: ["33AU"],
                        uk: ["33UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "86.4EU",
                      short: "86.4",
                      long: "86.4",
                      display: "86.4",
                      display_with_size_set: "86.4",
                      display_with_size_system: "EU 86.4",
                      display_with_system_and_set: "EU 86.4",
                      equivalent_sizes: {
                        us: ["34"],
                        au: ["34AU"],
                        uk: ["34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "88.9EU",
                      short: "88.9",
                      long: "88.9",
                      display: "88.9",
                      display_with_size_set: "88.9",
                      display_with_size_system: "EU 88.9",
                      display_with_system_and_set: "EU 88.9",
                      equivalent_sizes: {
                        us: ["35"],
                        au: ["35AU"],
                        uk: ["35UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "91.4EU",
                      short: "91.4",
                      long: "91.4",
                      display: "91.4",
                      display_with_size_set: "91.4",
                      display_with_size_system: "EU 91.4",
                      display_with_system_and_set: "EU 91.4",
                      equivalent_sizes: {
                        us: ["36"],
                        au: ["36AU"],
                        uk: ["36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "94EU",
                      short: "94",
                      long: "94",
                      display: "94",
                      display_with_size_set: "94",
                      display_with_size_system: "EU 94",
                      display_with_system_and_set: "EU 94",
                      equivalent_sizes: {
                        us: ["37"],
                        au: ["37AU"],
                        uk: ["37UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "96.5EU",
                      short: "96.5",
                      long: "96.5",
                      display: "96.5",
                      display_with_size_set: "96.5",
                      display_with_size_system: "EU 96.5",
                      display_with_system_and_set: "EU 96.5",
                      equivalent_sizes: {
                        us: ["38"],
                        au: ["38AU"],
                        uk: ["38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "99.1EU",
                      short: "99.1",
                      long: "99.1",
                      display: "99.1",
                      display_with_size_set: "99.1",
                      display_with_size_system: "EU 99.1",
                      display_with_system_and_set: "EU 99.1",
                      equivalent_sizes: {
                        us: ["39"],
                        au: ["39AU"],
                        uk: ["39UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "101.6EU",
                      short: "101.6",
                      long: "101.6",
                      display: "101.6",
                      display_with_size_set: "101.6",
                      display_with_size_system: "EU 101.6",
                      display_with_system_and_set: "EU 101.6",
                      equivalent_sizes: {
                        us: ["40"],
                        au: ["40AU"],
                        uk: ["40UK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["28"],
                        au: ["28AU"],
                        eu: ["71.12EU"],
                        uk: ["28UK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "29UK",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "UK 29",
                      display_with_system_and_set: "UK 29",
                      equivalent_sizes: {
                        us: ["29"],
                        au: ["29AU"],
                        eu: ["73.66EU"],
                        uk: ["29UK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["30"],
                        au: ["30AU"],
                        eu: ["76.2EU"],
                        uk: ["30UK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "31UK",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "UK 31",
                      display_with_system_and_set: "UK 31",
                      equivalent_sizes: {
                        us: ["31"],
                        au: ["31AU"],
                        eu: ["78.8EU"],
                        uk: ["31UK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["32"],
                        au: ["32AU"],
                        eu: ["81.3EU"],
                        uk: ["32UK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "33UK",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "UK 33",
                      display_with_system_and_set: "UK 33",
                      equivalent_sizes: {
                        us: ["33"],
                        au: ["33AU"],
                        eu: ["83.8EU"],
                        uk: ["33UK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["34"],
                        au: ["34AU"],
                        eu: ["86.4EU"],
                        uk: ["34UK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "35UK",
                      short: "35",
                      long: "35",
                      display: "35",
                      display_with_size_set: "35",
                      display_with_size_system: "UK 35",
                      display_with_system_and_set: "UK 35",
                      equivalent_sizes: {
                        us: ["35"],
                        au: ["35AU"],
                        eu: ["88.9EU"],
                        uk: ["35UK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["36"],
                        au: ["36AU"],
                        eu: ["91.4EU"],
                        uk: ["36UK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "37UK",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "UK 37",
                      display_with_system_and_set: "UK 37",
                      equivalent_sizes: {
                        us: ["37"],
                        au: ["37AU"],
                        eu: ["94EU"],
                        uk: ["37UK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["38"],
                        au: ["38AU"],
                        eu: ["96.5EU"],
                        uk: ["38UK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "39UK",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "UK 39",
                      display_with_system_and_set: "UK 39",
                      equivalent_sizes: {
                        us: ["39"],
                        au: ["39AU"],
                        eu: ["99.1EU"],
                        uk: ["39UK"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40UK",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "UK 40",
                      display_with_system_and_set: "UK 40",
                      equivalent_sizes: {
                        us: ["40"],
                        au: ["40AU"],
                        eu: ["101.6EU"],
                        uk: ["40UK"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "d979bbb6aabb08aed90362fb",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "b8b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["3XL", "3XL", "3XL", "3XL"],
                ["4XL", "4XL", "4XL", "4XL"],
                ["5XL", "5XL", "5XL", "5XL"],
                ["6XL", "6XL", "6XL", "6XL"],
                ["LT", "LT", "LT", "LT"],
                ["XLT", "XLT", "XLT", "XLT"],
                ["2XLT", "2XLT", "2XLT", "2XLT"],
                ["3XLT", "3XLT", "3XLT", "3XLT"],
                ["4XLT", "4XLT", "4XLT", "4XLT"],
                ["5XLT", "5XLT", "5XLT", "5XLT"],
                ["6XLT", "6XLT", "6XLT", "6XLT"],
                ["Waist 36", "36", "91.4", "36"],
                ["Waist 37", "37", "94", "37"],
                ["Waist 38", "38", "96.5", "38"],
                ["Waist 39", "39", "99.1", "39"],
                ["Waist 40", "40", "101.6", "40"],
                ["Waist 41", "41", "104.1", "41"],
                ["Waist 42", "42", "106.7", "42"],
                ["Waist 43", "43", "109.2", "43"],
                ["Waist 44", "44", "111.8", "44"],
                ["Waist 46", "46", "116.8", "46"],
                ["Waist 48", "48", "121.9", "48"],
                ["Waist 50", "50", "127.0", "50"],
                ["Waist 52", "52", "132.1", "52"],
                ["Waist 54", "54", "137.2", "54"],
                ["Waist 56", "56", "142.2", "56"],
                ["Waist 58", "58", "147.3", "58"],
                ["Waist 60", "60", "152.4", "60"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XL",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "US 3XL",
                      display_with_system_and_set: "US 3XL",
                      equivalent_sizes: {
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XL",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "US 4XL",
                      display_with_system_and_set: "US 4XL",
                      equivalent_sizes: {
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XL",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "US 5XL",
                      display_with_system_and_set: "US 5XL",
                      equivalent_sizes: {
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XL",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "US 6XL",
                      display_with_system_and_set: "US 6XL",
                      equivalent_sizes: {
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LT",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "US LT",
                      display_with_system_and_set: "US LT",
                      equivalent_sizes: {
                        au: ["LTAU"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLT",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "US XLT",
                      display_with_system_and_set: "US XLT",
                      equivalent_sizes: {
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XLT",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "US 2XLT",
                      display_with_system_and_set: "US 2XLT",
                      equivalent_sizes: {
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XLT",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "US 3XLT",
                      display_with_system_and_set: "US 3XLT",
                      equivalent_sizes: {
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XLT",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "US 4XLT",
                      display_with_system_and_set: "US 4XLT",
                      equivalent_sizes: {
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XLT",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "US 5XLT",
                      display_with_system_and_set: "US 5XLT",
                      equivalent_sizes: {
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XLT",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "US 6XLT",
                      display_with_system_and_set: "US 6XLT",
                      equivalent_sizes: {
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36BT",
                      short: "Waist 36",
                      long: "Waist 36",
                      display: "Waist 36",
                      display_with_size_set: "Waist 36",
                      display_with_size_system: "US Waist 36",
                      display_with_system_and_set: "US Waist 36",
                      equivalent_sizes: {
                        au: ["36BTAU"],
                        eu: ["91.4BTEU"],
                        uk: ["36BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "37BT",
                      short: "Waist 37",
                      long: "Waist 37",
                      display: "Waist 37",
                      display_with_size_set: "Waist 37",
                      display_with_size_system: "US Waist 37",
                      display_with_system_and_set: "US Waist 37",
                      equivalent_sizes: {
                        au: ["37BTAU"],
                        eu: ["94BTEU"],
                        uk: ["37BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38BT",
                      short: "Waist 38",
                      long: "Waist 38",
                      display: "Waist 38",
                      display_with_size_set: "Waist 38",
                      display_with_size_system: "US Waist 38",
                      display_with_system_and_set: "US Waist 38",
                      equivalent_sizes: {
                        au: ["38BTAU"],
                        eu: ["96.5BTEU"],
                        uk: ["38BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "39BT",
                      short: "Waist 39",
                      long: "Waist 39",
                      display: "Waist 39",
                      display_with_size_set: "Waist 39",
                      display_with_size_system: "US Waist 39",
                      display_with_system_and_set: "US Waist 39",
                      equivalent_sizes: {
                        au: ["39BTAU"],
                        eu: ["99.1BTEU"],
                        uk: ["39BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40BT",
                      short: "Waist 40",
                      long: "Waist 40",
                      display: "Waist 40",
                      display_with_size_set: "Waist 40",
                      display_with_size_system: "US Waist 40",
                      display_with_system_and_set: "US Waist 40",
                      equivalent_sizes: {
                        au: ["40BTAU"],
                        eu: ["101.6BTEU"],
                        uk: ["40BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "41",
                      short: "Waist 41",
                      long: "Waist 41",
                      display: "Waist 41",
                      display_with_size_set: "Waist 41",
                      display_with_size_system: "US Waist 41",
                      display_with_system_and_set: "US Waist 41",
                      equivalent_sizes: {
                        au: ["41AU"],
                        eu: ["104.1EU"],
                        uk: ["41UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42",
                      short: "Waist 42",
                      long: "Waist 42",
                      display: "Waist 42",
                      display_with_size_set: "Waist 42",
                      display_with_size_system: "US Waist 42",
                      display_with_system_and_set: "US Waist 42",
                      equivalent_sizes: {
                        au: ["42AU"],
                        eu: ["106.7EU"],
                        uk: ["42UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "43",
                      short: "Waist 43",
                      long: "Waist 43",
                      display: "Waist 43",
                      display_with_size_set: "Waist 43",
                      display_with_size_system: "US Waist 43",
                      display_with_system_and_set: "US Waist 43",
                      equivalent_sizes: {
                        au: ["43AU"],
                        eu: ["109.2EU"],
                        uk: ["43UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44",
                      short: "Waist 44",
                      long: "Waist 44",
                      display: "Waist 44",
                      display_with_size_set: "Waist 44",
                      display_with_size_system: "US Waist 44",
                      display_with_system_and_set: "US Waist 44",
                      equivalent_sizes: {
                        au: ["44AU"],
                        eu: ["111.8EU"],
                        uk: ["44UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46",
                      short: "Waist 46",
                      long: "Waist 46",
                      display: "Waist 46",
                      display_with_size_set: "Waist 46",
                      display_with_size_system: "US Waist 46",
                      display_with_system_and_set: "US Waist 46",
                      equivalent_sizes: {
                        au: ["46AU"],
                        eu: ["116.8EU"],
                        uk: ["46UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48",
                      short: "Waist 48",
                      long: "Waist 48",
                      display: "Waist 48",
                      display_with_size_set: "Waist 48",
                      display_with_size_system: "US Waist 48",
                      display_with_system_and_set: "US Waist 48",
                      equivalent_sizes: {
                        au: ["48AU"],
                        eu: ["121.9EU"],
                        uk: ["48UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "50",
                      short: "Waist 50",
                      long: "Waist 50",
                      display: "Waist 50",
                      display_with_size_set: "Waist 50",
                      display_with_size_system: "US Waist 50",
                      display_with_system_and_set: "US Waist 50",
                      equivalent_sizes: {
                        au: ["50AU"],
                        eu: ["127.0EU"],
                        uk: ["50UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "52",
                      short: "Waist 52",
                      long: "Waist 52",
                      display: "Waist 52",
                      display_with_size_set: "Waist 52",
                      display_with_size_system: "US Waist 52",
                      display_with_system_and_set: "US Waist 52",
                      equivalent_sizes: {
                        au: ["52AU"],
                        eu: ["132.1EU"],
                        uk: ["52UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "54",
                      short: "Waist 54",
                      long: "Waist 54",
                      display: "Waist 54",
                      display_with_size_set: "Waist 54",
                      display_with_size_system: "US Waist 54",
                      display_with_system_and_set: "US Waist 54",
                      equivalent_sizes: {
                        au: ["54AU"],
                        eu: ["137.2EU"],
                        uk: ["54UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "56",
                      short: "Waist 56",
                      long: "Waist 56",
                      display: "Waist 56",
                      display_with_size_set: "Waist 56",
                      display_with_size_system: "US Waist 56",
                      display_with_system_and_set: "US Waist 56",
                      equivalent_sizes: {
                        au: ["56AU"],
                        eu: ["142.2EU"],
                        uk: ["56UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "58",
                      short: "Waist 58",
                      long: "Waist 58",
                      display: "Waist 58",
                      display_with_size_set: "Waist 58",
                      display_with_size_system: "US Waist 58",
                      display_with_system_and_set: "US Waist 58",
                      equivalent_sizes: {
                        au: ["58AU"],
                        eu: ["147.3EU"],
                        uk: ["58UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "60",
                      short: "Waist 60",
                      long: "Waist 60",
                      display: "Waist 60",
                      display_with_size_set: "Waist 60",
                      display_with_size_system: "US Waist 60",
                      display_with_system_and_set: "US Waist 60",
                      equivalent_sizes: {
                        au: ["60AU"],
                        eu: ["152.4EU"],
                        uk: ["60UK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLAU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "AU 3XL",
                      display_with_system_and_set: "AU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLAU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "AU 4XL",
                      display_with_system_and_set: "AU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLAU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "AU 5XL",
                      display_with_system_and_set: "AU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLAU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "AU 6XL",
                      display_with_system_and_set: "AU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LTAU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "AU LT",
                      display_with_system_and_set: "AU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLTAU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "AU XLT",
                      display_with_system_and_set: "AU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XLTAU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "AU 2XLT",
                      display_with_system_and_set: "AU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLTAU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "AU 3XLT",
                      display_with_system_and_set: "AU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLTAU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "AU 4XLT",
                      display_with_system_and_set: "AU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLTAU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "AU 5XLT",
                      display_with_system_and_set: "AU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLTAU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "AU 6XLT",
                      display_with_system_and_set: "AU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36BTAU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["36BT"],
                        eu: ["91.4BTEU"],
                        uk: ["36BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "37BTAU",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "AU 37",
                      display_with_system_and_set: "AU 37",
                      equivalent_sizes: {
                        us: ["37BT"],
                        eu: ["94BTEU"],
                        uk: ["37BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38BTAU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["38BT"],
                        eu: ["96.5BTEU"],
                        uk: ["38BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "39BTAU",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "AU 39",
                      display_with_system_and_set: "AU 39",
                      equivalent_sizes: {
                        us: ["39BT"],
                        eu: ["99.1BTEU"],
                        uk: ["39BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "40BTAU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "AU 40",
                      display_with_system_and_set: "AU 40",
                      equivalent_sizes: {
                        us: ["40BT"],
                        eu: ["101.6BTEU"],
                        uk: ["40BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "41AU",
                      short: "41",
                      long: "41",
                      display: "41",
                      display_with_size_set: "41",
                      display_with_size_system: "AU 41",
                      display_with_system_and_set: "AU 41",
                      equivalent_sizes: {
                        us: ["41"],
                        eu: ["104.1EU"],
                        uk: ["41UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "42AU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "AU 42",
                      display_with_system_and_set: "AU 42",
                      equivalent_sizes: {
                        us: ["42"],
                        eu: ["106.7EU"],
                        uk: ["42UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "43AU",
                      short: "43",
                      long: "43",
                      display: "43",
                      display_with_size_set: "43",
                      display_with_size_system: "AU 43",
                      display_with_system_and_set: "AU 43",
                      equivalent_sizes: {
                        us: ["43"],
                        eu: ["109.2EU"],
                        uk: ["43UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "44AU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "AU 44",
                      display_with_system_and_set: "AU 44",
                      equivalent_sizes: {
                        us: ["44"],
                        eu: ["111.8EU"],
                        uk: ["44UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "46AU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "AU 46",
                      display_with_system_and_set: "AU 46",
                      equivalent_sizes: {
                        us: ["46"],
                        eu: ["116.8EU"],
                        uk: ["46UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "48AU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "AU 48",
                      display_with_system_and_set: "AU 48",
                      equivalent_sizes: {
                        us: ["48"],
                        eu: ["121.9EU"],
                        uk: ["48UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "50AU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "AU 50",
                      display_with_system_and_set: "AU 50",
                      equivalent_sizes: {
                        us: ["50"],
                        eu: ["127.0EU"],
                        uk: ["50UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "52AU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "AU 52",
                      display_with_system_and_set: "AU 52",
                      equivalent_sizes: {
                        us: ["52"],
                        eu: ["132.1EU"],
                        uk: ["52UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "54AU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "AU 54",
                      display_with_system_and_set: "AU 54",
                      equivalent_sizes: {
                        us: ["54"],
                        eu: ["137.2EU"],
                        uk: ["54UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "56AU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "AU 56",
                      display_with_system_and_set: "AU 56",
                      equivalent_sizes: {
                        us: ["56"],
                        eu: ["142.2EU"],
                        uk: ["56UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "58AU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "AU 58",
                      display_with_system_and_set: "AU 58",
                      equivalent_sizes: {
                        us: ["58"],
                        eu: ["147.3EU"],
                        uk: ["58UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "60AU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "AU 60",
                      display_with_system_and_set: "AU 60",
                      equivalent_sizes: {
                        us: ["60"],
                        eu: ["152.4EU"],
                        uk: ["60UK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLEU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "EU 3XL",
                      display_with_system_and_set: "EU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLEU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "EU 4XL",
                      display_with_system_and_set: "EU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLEU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "EU 5XL",
                      display_with_system_and_set: "EU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLEU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "EU 6XL",
                      display_with_system_and_set: "EU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LTEU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "EU LT",
                      display_with_system_and_set: "EU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        uk: ["LTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLTEU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "EU XLT",
                      display_with_system_and_set: "EU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XLTEU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "EU 2XLT",
                      display_with_system_and_set: "EU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLTEU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "EU 3XLT",
                      display_with_system_and_set: "EU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLTEU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "EU 4XLT",
                      display_with_system_and_set: "EU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLTEU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "EU 5XLT",
                      display_with_system_and_set: "EU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLTEU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "EU 6XLT",
                      display_with_system_and_set: "EU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "91.4BTEU",
                      short: "91.4",
                      long: "91.4",
                      display: "91.4",
                      display_with_size_set: "91.4",
                      display_with_size_system: "EU 91.4",
                      display_with_system_and_set: "EU 91.4",
                      equivalent_sizes: {
                        us: ["36BT"],
                        au: ["36BTAU"],
                        uk: ["36BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "94BTEU",
                      short: "94",
                      long: "94",
                      display: "94",
                      display_with_size_set: "94",
                      display_with_size_system: "EU 94",
                      display_with_system_and_set: "EU 94",
                      equivalent_sizes: {
                        us: ["37BT"],
                        au: ["37BTAU"],
                        uk: ["37BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "96.5BTEU",
                      short: "96.5",
                      long: "96.5",
                      display: "96.5",
                      display_with_size_set: "96.5",
                      display_with_size_system: "EU 96.5",
                      display_with_system_and_set: "EU 96.5",
                      equivalent_sizes: {
                        us: ["38BT"],
                        au: ["38BTAU"],
                        uk: ["38BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "99.1BTEU",
                      short: "99.1",
                      long: "99.1",
                      display: "99.1",
                      display_with_size_set: "99.1",
                      display_with_size_system: "EU 99.1",
                      display_with_system_and_set: "EU 99.1",
                      equivalent_sizes: {
                        us: ["39BT"],
                        au: ["39BTAU"],
                        uk: ["39BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "101.6BTEU",
                      short: "101.6",
                      long: "101.6",
                      display: "101.6",
                      display_with_size_set: "101.6",
                      display_with_size_system: "EU 101.6",
                      display_with_system_and_set: "EU 101.6",
                      equivalent_sizes: {
                        us: ["40BT"],
                        au: ["40BTAU"],
                        uk: ["40BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "104.1EU",
                      short: "104.1",
                      long: "104.1",
                      display: "104.1",
                      display_with_size_set: "104.1",
                      display_with_size_system: "EU 104.1",
                      display_with_system_and_set: "EU 104.1",
                      equivalent_sizes: {
                        us: ["41"],
                        au: ["41AU"],
                        uk: ["41UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "106.7EU",
                      short: "106.7",
                      long: "106.7",
                      display: "106.7",
                      display_with_size_set: "106.7",
                      display_with_size_system: "EU 106.7",
                      display_with_system_and_set: "EU 106.7",
                      equivalent_sizes: {
                        us: ["42"],
                        au: ["42AU"],
                        uk: ["42UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "109.2EU",
                      short: "109.2",
                      long: "109.2",
                      display: "109.2",
                      display_with_size_set: "109.2",
                      display_with_size_system: "EU 109.2",
                      display_with_system_and_set: "EU 109.2",
                      equivalent_sizes: {
                        us: ["43"],
                        au: ["43AU"],
                        uk: ["43UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "111.8EU",
                      short: "111.8",
                      long: "111.8",
                      display: "111.8",
                      display_with_size_set: "111.8",
                      display_with_size_system: "EU 111.8",
                      display_with_system_and_set: "EU 111.8",
                      equivalent_sizes: {
                        us: ["44"],
                        au: ["44AU"],
                        uk: ["44UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "116.8EU",
                      short: "116.8",
                      long: "116.8",
                      display: "116.8",
                      display_with_size_set: "116.8",
                      display_with_size_system: "EU 116.8",
                      display_with_system_and_set: "EU 116.8",
                      equivalent_sizes: {
                        us: ["46"],
                        au: ["46AU"],
                        uk: ["46UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "121.9EU",
                      short: "121.9",
                      long: "121.9",
                      display: "121.9",
                      display_with_size_set: "121.9",
                      display_with_size_system: "EU 121.9",
                      display_with_system_and_set: "EU 121.9",
                      equivalent_sizes: {
                        us: ["48"],
                        au: ["48AU"],
                        uk: ["48UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "127.0EU",
                      short: "127.0",
                      long: "127.0",
                      display: "127.0",
                      display_with_size_set: "127.0",
                      display_with_size_system: "EU 127.0",
                      display_with_system_and_set: "EU 127.0",
                      equivalent_sizes: {
                        us: ["50"],
                        au: ["50AU"],
                        uk: ["50UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "132.1EU",
                      short: "132.1",
                      long: "132.1",
                      display: "132.1",
                      display_with_size_set: "132.1",
                      display_with_size_system: "EU 132.1",
                      display_with_system_and_set: "EU 132.1",
                      equivalent_sizes: {
                        us: ["52"],
                        au: ["52AU"],
                        uk: ["52UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "137.2EU",
                      short: "137.2",
                      long: "137.2",
                      display: "137.2",
                      display_with_size_set: "137.2",
                      display_with_size_system: "EU 137.2",
                      display_with_system_and_set: "EU 137.2",
                      equivalent_sizes: {
                        us: ["54"],
                        au: ["54AU"],
                        uk: ["54UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "142.2EU",
                      short: "142.2",
                      long: "142.2",
                      display: "142.2",
                      display_with_size_set: "142.2",
                      display_with_size_system: "EU 142.2",
                      display_with_system_and_set: "EU 142.2",
                      equivalent_sizes: {
                        us: ["56"],
                        au: ["56AU"],
                        uk: ["56UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "147.3EU",
                      short: "147.3",
                      long: "147.3",
                      display: "147.3",
                      display_with_size_set: "147.3",
                      display_with_size_system: "EU 147.3",
                      display_with_system_and_set: "EU 147.3",
                      equivalent_sizes: {
                        us: ["58"],
                        au: ["58AU"],
                        uk: ["58UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "152.4EU",
                      short: "152.4",
                      long: "152.4",
                      display: "152.4",
                      display_with_size_set: "152.4",
                      display_with_size_system: "EU 152.4",
                      display_with_system_and_set: "EU 152.4",
                      equivalent_sizes: {
                        us: ["60"],
                        au: ["60AU"],
                        uk: ["60UK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLUK",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "UK 3XL",
                      display_with_system_and_set: "UK 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLUK",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "UK 4XL",
                      display_with_system_and_set: "UK 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLUK",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "UK 5XL",
                      display_with_system_and_set: "UK 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLUK",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "UK 6XL",
                      display_with_system_and_set: "UK 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LTUK",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "UK LT",
                      display_with_system_and_set: "UK LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        eu: ["LTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLTUK",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "UK XLT",
                      display_with_system_and_set: "UK XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XLTUK",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "UK 2XLT",
                      display_with_system_and_set: "UK 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLTUK",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "UK 3XLT",
                      display_with_system_and_set: "UK 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLTUK",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "UK 4XLT",
                      display_with_system_and_set: "UK 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLTUK",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "UK 5XLT",
                      display_with_system_and_set: "UK 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLTUK",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "UK 6XLT",
                      display_with_system_and_set: "UK 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36BTUK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["36BT"],
                        au: ["36BTAU"],
                        eu: ["91.4BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "37BTUK",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "UK 37",
                      display_with_system_and_set: "UK 37",
                      equivalent_sizes: {
                        us: ["37BT"],
                        au: ["37BTAU"],
                        eu: ["94BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38BTUK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["38BT"],
                        au: ["38BTUK"],
                        eu: ["96.5BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "39BTUK",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "UK 39",
                      display_with_system_and_set: "UK 39",
                      equivalent_sizes: {
                        us: ["39BT"],
                        au: ["39BTUK"],
                        eu: ["99.1BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40BTUK",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "UK 40",
                      display_with_system_and_set: "UK 40",
                      equivalent_sizes: {
                        us: ["40BT"],
                        au: ["40BTUK"],
                        eu: ["101.6BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "41UK",
                      short: "41",
                      long: "41",
                      display: "41",
                      display_with_size_set: "41",
                      display_with_size_system: "UK 41",
                      display_with_system_and_set: "UK 41",
                      equivalent_sizes: {
                        us: ["41"],
                        au: ["41UK"],
                        eu: ["104.1EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42UK",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "UK 42",
                      display_with_system_and_set: "UK 42",
                      equivalent_sizes: {
                        us: ["42"],
                        au: ["42AU"],
                        eu: ["106.7EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "43UK",
                      short: "43",
                      long: "43",
                      display: "43",
                      display_with_size_set: "43",
                      display_with_size_system: "UK 43",
                      display_with_system_and_set: "UK 43",
                      equivalent_sizes: {
                        us: ["43"],
                        au: ["43AU"],
                        eu: ["109.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44UK",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "UK 44",
                      display_with_system_and_set: "UK 44",
                      equivalent_sizes: {
                        us: ["44"],
                        au: ["44AU"],
                        eu: ["111.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46UK",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "UK 46",
                      display_with_system_and_set: "UK 46",
                      equivalent_sizes: {
                        us: ["46"],
                        au: ["46AU"],
                        eu: ["116.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48UK",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "UK 48",
                      display_with_system_and_set: "UK 48",
                      equivalent_sizes: {
                        us: ["48"],
                        au: ["48AU"],
                        eu: ["121.9EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "50UK",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "UK 50",
                      display_with_system_and_set: "UK 50",
                      equivalent_sizes: {
                        us: ["50"],
                        au: ["50AU"],
                        eu: ["127.0EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "52UK",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "UK 52",
                      display_with_system_and_set: "UK 52",
                      equivalent_sizes: {
                        us: ["52"],
                        au: ["52AU"],
                        eu: ["132.1EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "54UK",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "UK 54",
                      display_with_system_and_set: "UK 54",
                      equivalent_sizes: {
                        us: ["54"],
                        au: ["54AU"],
                        eu: ["137.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "56UK",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "UK 56",
                      display_with_system_and_set: "UK 56",
                      equivalent_sizes: {
                        us: ["56"],
                        au: ["56AU"],
                        eu: ["142.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "58UK",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "UK 58",
                      display_with_system_and_set: "UK 58",
                      equivalent_sizes: {
                        us: ["58"],
                        au: ["58AU"],
                        eu: ["147.3EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "60UK",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "UK 60",
                      display_with_system_and_set: "UK 60",
                      equivalent_sizes: {
                        us: ["60"],
                        au: ["60AU"],
                        eu: ["152.4EU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "men_kilts",
          aliases: [],
          slug: "Kilts",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
        },
        {
          id: "b9b476dd402403bf28a2606b",
          display: "Kimonos & Yukatas",
          type: "c",
          size_sets: [
            {
              id: "bb9ecb3e402403cd9fcf606c",
              name: "Standard",
              tags: ["standard"],
              category_id: "b9b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["34-36", "28-30"],
                  ["36-38", "30-32"],
                  ["38-40", "32-34"],
                  ["40-42", "34-36"],
                  ["42-44", "36-38"],
                  ["44-46", "38-40"],
                  ["46-48", "40-42"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["86.4-91.4", "71.1-76.2"],
                  ["91.4-96.5", "76.2-81.3"],
                  ["96.5-101.6", "81.3-86.4"],
                  ["101.6-106.7", "86.4-91.4"],
                  ["106.7-111.8", "91.4-96.5"],
                  ["111.8-116.9", "96.5-101.6"],
                  ["116.9-121.9", "101.6-106.7"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "da79bbb6aabb08aed90362fb",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "b9b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["3XL", "3XL", "3XL", "3XL"],
                ["4XL", "4XL", "4XL", "4XL"],
                ["5XL", "5XL", "5XL", "5XL"],
                ["6XL", "6XL", "6XL", "6XL"],
                ["LT", "LT", "LT", "LT"],
                ["XLT", "XLT", "XLT", "XLT"],
                ["2XLT", "2XLT", "2XLT", "2XLT"],
                ["3XLT", "3XLT", "3XLT", "3XLT"],
                ["4XLT", "4XLT", "4XLT", "4XLT"],
                ["5XLT", "5XLT", "5XLT", "5XLT"],
                ["6XLT", "6XLT", "6XLT", "6XLT"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XL",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "US 3XL",
                      display_with_system_and_set: "US 3XL",
                      equivalent_sizes: {
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XL",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "US 4XL",
                      display_with_system_and_set: "US 4XL",
                      equivalent_sizes: {
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XL",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "US 5XL",
                      display_with_system_and_set: "US 5XL",
                      equivalent_sizes: {
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XL",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "US 6XL",
                      display_with_system_and_set: "US 6XL",
                      equivalent_sizes: {
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LT",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "US LT",
                      display_with_system_and_set: "US LT",
                      equivalent_sizes: {
                        au: ["LTAU"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLT",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "US XLT",
                      display_with_system_and_set: "US XLT",
                      equivalent_sizes: {
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XLT",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "US 2XLT",
                      display_with_system_and_set: "US 2XLT",
                      equivalent_sizes: {
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XLT",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "US 3XLT",
                      display_with_system_and_set: "US 3XLT",
                      equivalent_sizes: {
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XLT",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "US 4XLT",
                      display_with_system_and_set: "US 4XLT",
                      equivalent_sizes: {
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XLT",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "US 5XLT",
                      display_with_system_and_set: "US 5XLT",
                      equivalent_sizes: {
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XLT",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "US 6XLT",
                      display_with_system_and_set: "US 6XLT",
                      equivalent_sizes: {
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLAU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "AU 3XL",
                      display_with_system_and_set: "AU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLAU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "AU 4XL",
                      display_with_system_and_set: "AU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLAU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "AU 5XL",
                      display_with_system_and_set: "AU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLAU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "AU 6XL",
                      display_with_system_and_set: "AU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LTAU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "AU LT",
                      display_with_system_and_set: "AU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLTAU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "AU XLT",
                      display_with_system_and_set: "AU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XLTAU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "AU 2XLT",
                      display_with_system_and_set: "AU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLTAU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "AU 3XLT",
                      display_with_system_and_set: "AU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLTAU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "AU 4XLT",
                      display_with_system_and_set: "AU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLTAU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "AU 5XLT",
                      display_with_system_and_set: "AU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLTAU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "AU 6XLT",
                      display_with_system_and_set: "AU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLEU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "EU 3XL",
                      display_with_system_and_set: "EU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLEU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "EU 4XL",
                      display_with_system_and_set: "EU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLEU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "EU 5XL",
                      display_with_system_and_set: "EU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLEU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "EU 6XL",
                      display_with_system_and_set: "EU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LTEU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "EU LT",
                      display_with_system_and_set: "EU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        uk: ["LTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLTEU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "EU XLT",
                      display_with_system_and_set: "EU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XLTEU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "EU 2XLT",
                      display_with_system_and_set: "EU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLTEU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "EU 3XLT",
                      display_with_system_and_set: "EU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLTEU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "EU 4XLT",
                      display_with_system_and_set: "EU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLTEU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "EU 5XLT",
                      display_with_system_and_set: "EU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLTEU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "EU 6XLT",
                      display_with_system_and_set: "EU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLUK",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "UK 3XL",
                      display_with_system_and_set: "UK 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLUK",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "UK 4XL",
                      display_with_system_and_set: "UK 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLUK",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "UK 5XL",
                      display_with_system_and_set: "UK 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLUK",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "UK 6XL",
                      display_with_system_and_set: "UK 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LTUK",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "UK LT",
                      display_with_system_and_set: "UK LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        eu: ["LTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLTUK",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "UK XLT",
                      display_with_system_and_set: "UK XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XLTUK",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "UK 2XLT",
                      display_with_system_and_set: "UK 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLTUK",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "UK 3XLT",
                      display_with_system_and_set: "UK 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLTUK",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "UK 4XLT",
                      display_with_system_and_set: "UK 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLTUK",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "UK 5XLT",
                      display_with_system_and_set: "UK 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLTUK",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "UK 6XLT",
                      display_with_system_and_set: "UK 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "men_kimonos_yukatas",
          aliases: [],
          slug: "Kimonos_&_Yukatas",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
        },
        {
          id: "aeb476dd402403bf28a2606b",
          display: "Kurta Bottoms",
          type: "c",
          size_sets: [
            {
              id: "b09ecb3e402403cd9fcf606c",
              name: "Standard",
              tags: ["standard"],
              category_id: "aeb476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Waist "],
                  ["28-29"],
                  ["30-31"],
                  ["32-35"],
                  ["36-38"],
                  ["39-40"],
                  ["41-42"],
                  ["43-44"],
                ],
                centimeters: [
                  ["Waist "],
                  ["71.1-73.7"],
                  ["76.2-78.7"],
                  ["81.3-88.9"],
                  ["91.4-96.5"],
                  ["99.1-101.6"],
                  ["104.1-106.7"],
                  ["109.2-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["Waist 28", "28", "71.12", "28"],
                ["Waist 29", "29", "73.66", "29"],
                ["Waist 30", "30", "76.2", "30"],
                ["Waist 31", "31", "78.8", "31"],
                ["Waist 32", "32", "81.3", "32"],
                ["Waist 33", "33", "83.8", "33"],
                ["Waist 34", "34", "86.4", "34"],
                ["Waist 35", "35", "88.9", "35"],
                ["Waist 36", "36", "91.4", "36"],
                ["Waist 37", "37", "94", "37"],
                ["Waist 38", "38", "96.5", "38"],
                ["Waist 39", "39", "99.1", "39"],
                ["Waist 40", "40", "101.6", "40"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "Waist 28",
                      long: "Waist 28",
                      display: "Waist 28",
                      display_with_size_set: "Waist 28",
                      display_with_size_system: "US Waist 28",
                      display_with_system_and_set: "US Waist 28",
                      equivalent_sizes: {
                        au: ["28AU"],
                        eu: ["71.12EU"],
                        uk: ["28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "29",
                      short: "Waist 29",
                      long: "Waist 29",
                      display: "Waist 29",
                      display_with_size_set: "Waist 29",
                      display_with_size_system: "US Waist 29",
                      display_with_system_and_set: "US Waist 29",
                      equivalent_sizes: {
                        au: ["29AU"],
                        eu: ["73.66EU"],
                        uk: ["29UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "Waist 30",
                      long: "Waist 30",
                      display: "Waist 30",
                      display_with_size_set: "Waist 30",
                      display_with_size_system: "US Waist 30",
                      display_with_system_and_set: "US Waist 30",
                      equivalent_sizes: {
                        au: ["30AU"],
                        eu: ["76.2EU"],
                        uk: ["30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "31",
                      short: "Waist 31",
                      long: "Waist 31",
                      display: "Waist 31",
                      display_with_size_set: "Waist 31",
                      display_with_size_system: "US Waist 31",
                      display_with_system_and_set: "US Waist 31",
                      equivalent_sizes: {
                        au: ["31AU"],
                        eu: ["78.8EU"],
                        uk: ["31UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "Waist 32",
                      long: "Waist 32",
                      display: "Waist 32",
                      display_with_size_set: "Waist 32",
                      display_with_size_system: "US Waist 32",
                      display_with_system_and_set: "US Waist 32",
                      equivalent_sizes: {
                        au: ["32AU"],
                        eu: ["81.3EU"],
                        uk: ["32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "33",
                      short: "Waist 33",
                      long: "Waist 33",
                      display: "Waist 33",
                      display_with_size_set: "Waist 33",
                      display_with_size_system: "US Waist 33",
                      display_with_system_and_set: "US Waist 33",
                      equivalent_sizes: {
                        au: ["33AU"],
                        eu: ["83.8EU"],
                        uk: ["33UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34",
                      short: "Waist 34",
                      long: "Waist 34",
                      display: "Waist 34",
                      display_with_size_set: "Waist 34",
                      display_with_size_system: "US Waist 34",
                      display_with_system_and_set: "US Waist 34",
                      equivalent_sizes: {
                        au: ["34AU"],
                        eu: ["86.4EU"],
                        uk: ["34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "35",
                      short: "Waist 35",
                      long: "Waist 35",
                      display: "Waist 35",
                      display_with_size_set: "Waist 35",
                      display_with_size_system: "US Waist 35",
                      display_with_system_and_set: "US Waist 35",
                      equivalent_sizes: {
                        au: ["35AU"],
                        eu: ["88.9EU"],
                        uk: ["35UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36",
                      short: "Waist 36",
                      long: "Waist 36",
                      display: "Waist 36",
                      display_with_size_set: "Waist 36",
                      display_with_size_system: "US Waist 36",
                      display_with_system_and_set: "US Waist 36",
                      equivalent_sizes: {
                        au: ["36AU"],
                        eu: ["91.4EU"],
                        uk: ["36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "37",
                      short: "Waist 37",
                      long: "Waist 37",
                      display: "Waist 37",
                      display_with_size_set: "Waist 37",
                      display_with_size_system: "US Waist 37",
                      display_with_system_and_set: "US Waist 37",
                      equivalent_sizes: {
                        au: ["37AU"],
                        eu: ["94EU"],
                        uk: ["37UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38",
                      short: "Waist 38",
                      long: "Waist 38",
                      display: "Waist 38",
                      display_with_size_set: "Waist 38",
                      display_with_size_system: "US Waist 38",
                      display_with_system_and_set: "US Waist 38",
                      equivalent_sizes: {
                        au: ["38AU"],
                        eu: ["96.5EU"],
                        uk: ["38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "39",
                      short: "Waist 39",
                      long: "Waist 39",
                      display: "Waist 39",
                      display_with_size_set: "Waist 39",
                      display_with_size_system: "US Waist 39",
                      display_with_system_and_set: "US Waist 39",
                      equivalent_sizes: {
                        au: ["39AU"],
                        eu: ["99.1EU"],
                        uk: ["39UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40",
                      short: "Waist 40",
                      long: "Waist 40",
                      display: "Waist 40",
                      display_with_size_set: "Waist 40",
                      display_with_size_system: "US Waist 40",
                      display_with_system_and_set: "US Waist 40",
                      equivalent_sizes: {
                        au: ["40AU"],
                        eu: ["101.6EU"],
                        uk: ["40UK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["28"],
                        eu: ["71.12EU"],
                        uk: ["28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "29AU",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "AU 29",
                      display_with_system_and_set: "AU 29",
                      equivalent_sizes: {
                        us: ["29"],
                        eu: ["73.66EU"],
                        uk: ["29UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["30"],
                        eu: ["76.2EU"],
                        uk: ["30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "31AU",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "AU 31",
                      display_with_system_and_set: "AU 31",
                      equivalent_sizes: {
                        us: ["31"],
                        eu: ["78.8EU"],
                        uk: ["31UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["32"],
                        eu: ["81.3EU"],
                        uk: ["32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "33AU",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "AU 33",
                      display_with_system_and_set: "AU 33",
                      equivalent_sizes: {
                        us: ["33"],
                        eu: ["83.8EU"],
                        uk: ["33UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["34"],
                        eu: ["86.4EU"],
                        uk: ["34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "35AU",
                      short: "35",
                      long: "35",
                      display: "35",
                      display_with_size_set: "35",
                      display_with_size_system: "AU 35",
                      display_with_system_and_set: "AU 35",
                      equivalent_sizes: {
                        us: ["35"],
                        eu: ["88.9EU"],
                        uk: ["35UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["36"],
                        eu: ["91.4EU"],
                        uk: ["36UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "37AU",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "AU 37",
                      display_with_system_and_set: "AU 37",
                      equivalent_sizes: {
                        us: ["37"],
                        eu: ["94EU"],
                        uk: ["37UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["38"],
                        eu: ["96.5EU"],
                        uk: ["38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "39AU",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "AU 39",
                      display_with_system_and_set: "AU 39",
                      equivalent_sizes: {
                        us: ["39"],
                        eu: ["99.1EU"],
                        uk: ["39UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "40AU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "AU 40",
                      display_with_system_and_set: "AU 40",
                      equivalent_sizes: {
                        us: ["40"],
                        eu: ["101.6EU"],
                        uk: ["40UK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "71.12EU",
                      short: "71.12",
                      long: "71.12",
                      display: "71.12",
                      display_with_size_set: "71.12",
                      display_with_size_system: "EU 71.12",
                      display_with_system_and_set: "EU 71.12",
                      equivalent_sizes: {
                        us: ["28"],
                        au: ["28AU"],
                        uk: ["28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "73.66EU",
                      short: "73.66",
                      long: "73.66",
                      display: "73.66",
                      display_with_size_set: "73.66",
                      display_with_size_system: "EU 73.66",
                      display_with_system_and_set: "EU 73.66",
                      equivalent_sizes: {
                        us: ["29"],
                        au: ["29AU"],
                        uk: ["29UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "76.2EU",
                      short: "76.2",
                      long: "76.2",
                      display: "76.2",
                      display_with_size_set: "76.2",
                      display_with_size_system: "EU 76.2",
                      display_with_system_and_set: "EU 76.2",
                      equivalent_sizes: {
                        us: ["30"],
                        au: ["30AU"],
                        uk: ["30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "78.8EU",
                      short: "78.8",
                      long: "78.8",
                      display: "78.8",
                      display_with_size_set: "78.8",
                      display_with_size_system: "EU 78.8",
                      display_with_system_and_set: "EU 78.8",
                      equivalent_sizes: {
                        us: ["31"],
                        au: ["31AU"],
                        uk: ["31UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "81.3EU",
                      short: "81.3",
                      long: "81.3",
                      display: "81.3",
                      display_with_size_set: "81.3",
                      display_with_size_system: "EU 81.3",
                      display_with_system_and_set: "EU 81.3",
                      equivalent_sizes: {
                        us: ["32"],
                        au: ["32AU"],
                        uk: ["32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "83.8EU",
                      short: "83.8",
                      long: "83.8",
                      display: "83.8",
                      display_with_size_set: "83.8",
                      display_with_size_system: "EU 83.8",
                      display_with_system_and_set: "EU 83.8",
                      equivalent_sizes: {
                        us: ["33"],
                        au: ["33AU"],
                        uk: ["33UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "86.4EU",
                      short: "86.4",
                      long: "86.4",
                      display: "86.4",
                      display_with_size_set: "86.4",
                      display_with_size_system: "EU 86.4",
                      display_with_system_and_set: "EU 86.4",
                      equivalent_sizes: {
                        us: ["34"],
                        au: ["34AU"],
                        uk: ["34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "88.9EU",
                      short: "88.9",
                      long: "88.9",
                      display: "88.9",
                      display_with_size_set: "88.9",
                      display_with_size_system: "EU 88.9",
                      display_with_system_and_set: "EU 88.9",
                      equivalent_sizes: {
                        us: ["35"],
                        au: ["35AU"],
                        uk: ["35UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "91.4EU",
                      short: "91.4",
                      long: "91.4",
                      display: "91.4",
                      display_with_size_set: "91.4",
                      display_with_size_system: "EU 91.4",
                      display_with_system_and_set: "EU 91.4",
                      equivalent_sizes: {
                        us: ["36"],
                        au: ["36AU"],
                        uk: ["36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "94EU",
                      short: "94",
                      long: "94",
                      display: "94",
                      display_with_size_set: "94",
                      display_with_size_system: "EU 94",
                      display_with_system_and_set: "EU 94",
                      equivalent_sizes: {
                        us: ["37"],
                        au: ["37AU"],
                        uk: ["37UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "96.5EU",
                      short: "96.5",
                      long: "96.5",
                      display: "96.5",
                      display_with_size_set: "96.5",
                      display_with_size_system: "EU 96.5",
                      display_with_system_and_set: "EU 96.5",
                      equivalent_sizes: {
                        us: ["38"],
                        au: ["38AU"],
                        uk: ["38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "99.1EU",
                      short: "99.1",
                      long: "99.1",
                      display: "99.1",
                      display_with_size_set: "99.1",
                      display_with_size_system: "EU 99.1",
                      display_with_system_and_set: "EU 99.1",
                      equivalent_sizes: {
                        us: ["39"],
                        au: ["39AU"],
                        uk: ["39UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "101.6EU",
                      short: "101.6",
                      long: "101.6",
                      display: "101.6",
                      display_with_size_set: "101.6",
                      display_with_size_system: "EU 101.6",
                      display_with_system_and_set: "EU 101.6",
                      equivalent_sizes: {
                        us: ["40"],
                        au: ["40AU"],
                        uk: ["40UK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["28"],
                        au: ["28AU"],
                        eu: ["71.12EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "29UK",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "UK 29",
                      display_with_system_and_set: "UK 29",
                      equivalent_sizes: {
                        us: ["29"],
                        au: ["29AU"],
                        eu: ["73.66EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["30"],
                        au: ["30AU"],
                        eu: ["76.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "31UK",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "UK 31",
                      display_with_system_and_set: "UK 31",
                      equivalent_sizes: {
                        us: ["31"],
                        au: ["31AU"],
                        eu: ["78.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["32"],
                        au: ["32AU"],
                        eu: ["81.3EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "33UK",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "UK 33",
                      display_with_system_and_set: "UK 33",
                      equivalent_sizes: {
                        us: ["33"],
                        au: ["33AU"],
                        eu: ["83.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["34"],
                        au: ["34AU"],
                        eu: ["86.4EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "35UK",
                      short: "35",
                      long: "35",
                      display: "35",
                      display_with_size_set: "35",
                      display_with_size_system: "UK 35",
                      display_with_system_and_set: "UK 35",
                      equivalent_sizes: {
                        us: ["35"],
                        au: ["35AU"],
                        eu: ["88.9EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["36"],
                        au: ["36AU"],
                        eu: ["91.4EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "37UK",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "UK 37",
                      display_with_system_and_set: "UK 37",
                      equivalent_sizes: {
                        us: ["37"],
                        au: ["37AU"],
                        eu: ["94EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["38"],
                        au: ["38AU"],
                        eu: ["96.5EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "39UK",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "UK 39",
                      display_with_system_and_set: "UK 39",
                      equivalent_sizes: {
                        us: ["39"],
                        au: ["39AU"],
                        eu: ["99.1EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40UK",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "UK 40",
                      display_with_system_and_set: "UK 40",
                      equivalent_sizes: {
                        us: ["40"],
                        au: ["40AU"],
                        eu: ["101.6EU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "c5fc8ac54024036cbb2b62f2",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "aeb476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Waist "],
                  ["42-44"],
                  ["46-48"],
                  ["50-52"],
                  ["54-56"],
                  ["58-60"],
                  ["40-44"],
                  ["45-48.5"],
                  ["49-52"],
                  ["52-55"],
                  ["56-60"],
                  ["62-64"],
                ],
                centimeters: [
                  ["Waist "],
                  ["106.7-111.8"],
                  ["116.8-121.9"],
                  ["127-132.1"],
                  ["137.2-142.2"],
                  ["147.3-152.4"],
                  ["99.1-101.6"],
                  ["106.7-111.8"],
                  ["116.8-121.9"],
                  ["127-132.1"],
                  ["142.2-152.5"],
                  ["157.4-162.5"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["3XL", "3XL", "3XL", "3XL"],
                  ["4XL", "4XL", "4XL", "4XL"],
                  ["5XL", "5XL", "5XL", "5XL"],
                  ["6XL", "6XL", "6XL", "6XL"],
                  ["XLT", "XLT", "XLT", "XLT"],
                  ["3XLT", "3XLT", "3XLT", "3XLT"],
                  ["4XLT", "4XLT", "4XLT", "4XLT"],
                  ["5XLT", "5XLT", "5XLT", "5XLT"],
                  ["6XLT", "6XLT", "6XLT", "6XLT"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["3XL", "3XL", "3XL", "3XL"],
                ["4XL", "4XL", "4XL", "4XL"],
                ["5XL", "5XL", "5XL", "5XL"],
                ["6XL", "6XL", "6XL", "6XL"],
                ["LT", "LT", "LT", "LT"],
                ["XLT", "XLT", "XLT", "XLT"],
                ["2XLT", "2XLT", "2XLT", "2XLT"],
                ["3XLT", "3XLT", "3XLT", "3XLT"],
                ["4XLT", "4XLT", "4XLT", "4XLT"],
                ["5XLT", "5XLT", "5XLT", "5XLT"],
                ["6XLT", "6XLT", "6XLT", "6XLT"],
                ["Waist 36", "36", "91.4", "36"],
                ["Waist 37", "37", "94", "37"],
                ["Waist 38", "38", "96.5", "38"],
                ["Waist 39", "39", "99.1", "39"],
                ["Waist 40", "40", "101.6", "40"],
                ["Waist 41", "41", "104.1", "41"],
                ["Waist 42", "42", "106.7", "42"],
                ["Waist 43", "43", "109.2", "43"],
                ["Waist 44", "44", "111.8", "44"],
                ["Waist 46", "46", "116.8", "46"],
                ["Waist 48", "48", "121.9", "48"],
                ["Waist 50", "50", "127.0", "50"],
                ["Waist 52", "52", "132.1", "52"],
                ["Waist 54", "54", "137.2", "54"],
                ["Waist 56", "56", "142.2", "56"],
                ["Waist 58", "58", "147.3", "58"],
                ["Waist 60", "60", "152.4", "60"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XL",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "US 3XL",
                      display_with_system_and_set: "US 3XL",
                      equivalent_sizes: {
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XL",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "US 4XL",
                      display_with_system_and_set: "US 4XL",
                      equivalent_sizes: {
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XL",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "US 5XL",
                      display_with_system_and_set: "US 5XL",
                      equivalent_sizes: {
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XL",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "US 6XL",
                      display_with_system_and_set: "US 6XL",
                      equivalent_sizes: {
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LT",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "US LT",
                      display_with_system_and_set: "US LT",
                      equivalent_sizes: {
                        au: ["LTAU"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLT",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "US XLT",
                      display_with_system_and_set: "US XLT",
                      equivalent_sizes: {
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XLT",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "US 2XLT",
                      display_with_system_and_set: "US 2XLT",
                      equivalent_sizes: {
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XLT",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "US 3XLT",
                      display_with_system_and_set: "US 3XLT",
                      equivalent_sizes: {
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XLT",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "US 4XLT",
                      display_with_system_and_set: "US 4XLT",
                      equivalent_sizes: {
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XLT",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "US 5XLT",
                      display_with_system_and_set: "US 5XLT",
                      equivalent_sizes: {
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XLT",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "US 6XLT",
                      display_with_system_and_set: "US 6XLT",
                      equivalent_sizes: {
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36BT",
                      short: "Waist 36",
                      long: "Waist 36",
                      display: "Waist 36",
                      display_with_size_set: "Waist 36",
                      display_with_size_system: "US Waist 36",
                      display_with_system_and_set: "US Waist 36",
                      equivalent_sizes: {
                        au: ["36BTAU"],
                        eu: ["91.4BTEU"],
                        uk: ["36BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "37BT",
                      short: "Waist 37",
                      long: "Waist 37",
                      display: "Waist 37",
                      display_with_size_set: "Waist 37",
                      display_with_size_system: "US Waist 37",
                      display_with_system_and_set: "US Waist 37",
                      equivalent_sizes: {
                        au: ["37BTAU"],
                        eu: ["94BTEU"],
                        uk: ["37BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38BT",
                      short: "Waist 38",
                      long: "Waist 38",
                      display: "Waist 38",
                      display_with_size_set: "Waist 38",
                      display_with_size_system: "US Waist 38",
                      display_with_system_and_set: "US Waist 38",
                      equivalent_sizes: {
                        au: ["38BTAU"],
                        eu: ["96.5BTEU"],
                        uk: ["38BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "39BT",
                      short: "Waist 39",
                      long: "Waist 39",
                      display: "Waist 39",
                      display_with_size_set: "Waist 39",
                      display_with_size_system: "US Waist 39",
                      display_with_system_and_set: "US Waist 39",
                      equivalent_sizes: {
                        au: ["39BTAU"],
                        eu: ["99.1BTEU"],
                        uk: ["39BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40BT",
                      short: "Waist 40",
                      long: "Waist 40",
                      display: "Waist 40",
                      display_with_size_set: "Waist 40",
                      display_with_size_system: "US Waist 40",
                      display_with_system_and_set: "US Waist 40",
                      equivalent_sizes: {
                        au: ["40BTAU"],
                        eu: ["101.6BTEU"],
                        uk: ["40BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "41",
                      short: "Waist 41",
                      long: "Waist 41",
                      display: "Waist 41",
                      display_with_size_set: "Waist 41",
                      display_with_size_system: "US Waist 41",
                      display_with_system_and_set: "US Waist 41",
                      equivalent_sizes: {
                        au: ["41AU"],
                        eu: ["104.1EU"],
                        uk: ["41UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42",
                      short: "Waist 42",
                      long: "Waist 42",
                      display: "Waist 42",
                      display_with_size_set: "Waist 42",
                      display_with_size_system: "US Waist 42",
                      display_with_system_and_set: "US Waist 42",
                      equivalent_sizes: {
                        au: ["42AU"],
                        eu: ["106.7EU"],
                        uk: ["42UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "43",
                      short: "Waist 43",
                      long: "Waist 43",
                      display: "Waist 43",
                      display_with_size_set: "Waist 43",
                      display_with_size_system: "US Waist 43",
                      display_with_system_and_set: "US Waist 43",
                      equivalent_sizes: {
                        au: ["43AU"],
                        eu: ["109.2EU"],
                        uk: ["43UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44",
                      short: "Waist 44",
                      long: "Waist 44",
                      display: "Waist 44",
                      display_with_size_set: "Waist 44",
                      display_with_size_system: "US Waist 44",
                      display_with_system_and_set: "US Waist 44",
                      equivalent_sizes: {
                        au: ["44AU"],
                        eu: ["111.8EU"],
                        uk: ["44UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46",
                      short: "Waist 46",
                      long: "Waist 46",
                      display: "Waist 46",
                      display_with_size_set: "Waist 46",
                      display_with_size_system: "US Waist 46",
                      display_with_system_and_set: "US Waist 46",
                      equivalent_sizes: {
                        au: ["46AU"],
                        eu: ["116.8EU"],
                        uk: ["46UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48",
                      short: "Waist 48",
                      long: "Waist 48",
                      display: "Waist 48",
                      display_with_size_set: "Waist 48",
                      display_with_size_system: "US Waist 48",
                      display_with_system_and_set: "US Waist 48",
                      equivalent_sizes: {
                        au: ["48AU"],
                        eu: ["121.9EU"],
                        uk: ["48UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "50",
                      short: "Waist 50",
                      long: "Waist 50",
                      display: "Waist 50",
                      display_with_size_set: "Waist 50",
                      display_with_size_system: "US Waist 50",
                      display_with_system_and_set: "US Waist 50",
                      equivalent_sizes: {
                        au: ["50AU"],
                        eu: ["127.0EU"],
                        uk: ["50UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "52",
                      short: "Waist 52",
                      long: "Waist 52",
                      display: "Waist 52",
                      display_with_size_set: "Waist 52",
                      display_with_size_system: "US Waist 52",
                      display_with_system_and_set: "US Waist 52",
                      equivalent_sizes: {
                        au: ["52AU"],
                        eu: ["132.1EU"],
                        uk: ["52UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "54",
                      short: "Waist 54",
                      long: "Waist 54",
                      display: "Waist 54",
                      display_with_size_set: "Waist 54",
                      display_with_size_system: "US Waist 54",
                      display_with_system_and_set: "US Waist 54",
                      equivalent_sizes: {
                        au: ["54AU"],
                        eu: ["137.2EU"],
                        uk: ["54UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "56",
                      short: "Waist 56",
                      long: "Waist 56",
                      display: "Waist 56",
                      display_with_size_set: "Waist 56",
                      display_with_size_system: "US Waist 56",
                      display_with_system_and_set: "US Waist 56",
                      equivalent_sizes: {
                        au: ["56AU"],
                        eu: ["142.2EU"],
                        uk: ["56UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "58",
                      short: "Waist 58",
                      long: "Waist 58",
                      display: "Waist 58",
                      display_with_size_set: "Waist 58",
                      display_with_size_system: "US Waist 58",
                      display_with_system_and_set: "US Waist 58",
                      equivalent_sizes: {
                        au: ["58AU"],
                        eu: ["147.3EU"],
                        uk: ["58UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "60",
                      short: "Waist 60",
                      long: "Waist 60",
                      display: "Waist 60",
                      display_with_size_set: "Waist 60",
                      display_with_size_system: "US Waist 60",
                      display_with_system_and_set: "US Waist 60",
                      equivalent_sizes: {
                        au: ["60AU"],
                        eu: ["152.4EU"],
                        uk: ["60UK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLAU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "AU 3XL",
                      display_with_system_and_set: "AU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLAU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "AU 4XL",
                      display_with_system_and_set: "AU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLAU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "AU 5XL",
                      display_with_system_and_set: "AU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLAU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "AU 6XL",
                      display_with_system_and_set: "AU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LTAU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "AU LT",
                      display_with_system_and_set: "AU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLTAU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "AU XLT",
                      display_with_system_and_set: "AU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XLTAU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "AU 2XLT",
                      display_with_system_and_set: "AU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLTAU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "AU 3XLT",
                      display_with_system_and_set: "AU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLTAU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "AU 4XLT",
                      display_with_system_and_set: "AU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLTAU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "AU 5XLT",
                      display_with_system_and_set: "AU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLTAU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "AU 6XLT",
                      display_with_system_and_set: "AU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36BTAU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["36BT"],
                        eu: ["91.4BTEU"],
                        uk: ["36BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "37BTAU",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "AU 37",
                      display_with_system_and_set: "AU 37",
                      equivalent_sizes: {
                        us: ["37BT"],
                        eu: ["94BTEU"],
                        uk: ["37BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38BTAU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["38BT"],
                        eu: ["96.5BTEU"],
                        uk: ["38BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "39BTAU",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "AU 39",
                      display_with_system_and_set: "AU 39",
                      equivalent_sizes: {
                        us: ["39BT"],
                        eu: ["99.1BTEU"],
                        uk: ["39BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "40BTAU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "AU 40",
                      display_with_system_and_set: "AU 40",
                      equivalent_sizes: {
                        us: ["40BT"],
                        eu: ["101.6BTEU"],
                        uk: ["40BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "41AU",
                      short: "41",
                      long: "41",
                      display: "41",
                      display_with_size_set: "41",
                      display_with_size_system: "AU 41",
                      display_with_system_and_set: "AU 41",
                      equivalent_sizes: {
                        us: ["41"],
                        eu: ["104.1EU"],
                        uk: ["41UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "42AU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "AU 42",
                      display_with_system_and_set: "AU 42",
                      equivalent_sizes: {
                        us: ["42"],
                        eu: ["106.7EU"],
                        uk: ["42UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "43AU",
                      short: "43",
                      long: "43",
                      display: "43",
                      display_with_size_set: "43",
                      display_with_size_system: "AU 43",
                      display_with_system_and_set: "AU 43",
                      equivalent_sizes: {
                        us: ["43"],
                        eu: ["109.2EU"],
                        uk: ["43UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "44AU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "AU 44",
                      display_with_system_and_set: "AU 44",
                      equivalent_sizes: {
                        us: ["44"],
                        eu: ["111.8EU"],
                        uk: ["44UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "46AU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "AU 46",
                      display_with_system_and_set: "AU 46",
                      equivalent_sizes: {
                        us: ["46"],
                        eu: ["116.8EU"],
                        uk: ["46UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "48AU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "AU 48",
                      display_with_system_and_set: "AU 48",
                      equivalent_sizes: {
                        us: ["48"],
                        eu: ["121.9EU"],
                        uk: ["48UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "50AU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "AU 50",
                      display_with_system_and_set: "AU 50",
                      equivalent_sizes: {
                        us: ["50"],
                        eu: ["127.0EU"],
                        uk: ["50UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "52AU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "AU 52",
                      display_with_system_and_set: "AU 52",
                      equivalent_sizes: {
                        us: ["52"],
                        eu: ["132.1EU"],
                        uk: ["52UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "54AU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "AU 54",
                      display_with_system_and_set: "AU 54",
                      equivalent_sizes: {
                        us: ["54"],
                        eu: ["137.2EU"],
                        uk: ["54UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "56AU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "AU 56",
                      display_with_system_and_set: "AU 56",
                      equivalent_sizes: {
                        us: ["56"],
                        eu: ["142.2EU"],
                        uk: ["56UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "58AU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "AU 58",
                      display_with_system_and_set: "AU 58",
                      equivalent_sizes: {
                        us: ["58"],
                        eu: ["147.3EU"],
                        uk: ["58UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "60AU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "AU 60",
                      display_with_system_and_set: "AU 60",
                      equivalent_sizes: {
                        us: ["60"],
                        eu: ["152.4EU"],
                        uk: ["60UK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLEU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "EU 3XL",
                      display_with_system_and_set: "EU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLEU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "EU 4XL",
                      display_with_system_and_set: "EU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLEU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "EU 5XL",
                      display_with_system_and_set: "EU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLEU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "EU 6XL",
                      display_with_system_and_set: "EU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LTEU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "EU LT",
                      display_with_system_and_set: "EU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        uk: ["LTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLTEU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "EU XLT",
                      display_with_system_and_set: "EU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XLTEU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "EU 2XLT",
                      display_with_system_and_set: "EU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLTEU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "EU 3XLT",
                      display_with_system_and_set: "EU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLTEU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "EU 4XLT",
                      display_with_system_and_set: "EU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLTEU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "EU 5XLT",
                      display_with_system_and_set: "EU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLTEU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "EU 6XLT",
                      display_with_system_and_set: "EU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "91.4BTEU",
                      short: "91.4",
                      long: "91.4",
                      display: "91.4",
                      display_with_size_set: "91.4",
                      display_with_size_system: "EU 91.4",
                      display_with_system_and_set: "EU 91.4",
                      equivalent_sizes: {
                        us: ["36BT"],
                        au: ["36BTAU"],
                        uk: ["36BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "94BTEU",
                      short: "94",
                      long: "94",
                      display: "94",
                      display_with_size_set: "94",
                      display_with_size_system: "EU 94",
                      display_with_system_and_set: "EU 94",
                      equivalent_sizes: {
                        us: ["37BT"],
                        au: ["37BTAU"],
                        uk: ["37BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "96.5BTEU",
                      short: "96.5",
                      long: "96.5",
                      display: "96.5",
                      display_with_size_set: "96.5",
                      display_with_size_system: "EU 96.5",
                      display_with_system_and_set: "EU 96.5",
                      equivalent_sizes: {
                        us: ["38BT"],
                        au: ["38BTAU"],
                        uk: ["38BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "99.1BTEU",
                      short: "99.1",
                      long: "99.1",
                      display: "99.1",
                      display_with_size_set: "99.1",
                      display_with_size_system: "EU 99.1",
                      display_with_system_and_set: "EU 99.1",
                      equivalent_sizes: {
                        us: ["39BT"],
                        au: ["39BTAU"],
                        uk: ["39BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "101.6BTEU",
                      short: "101.6",
                      long: "101.6",
                      display: "101.6",
                      display_with_size_set: "101.6",
                      display_with_size_system: "EU 101.6",
                      display_with_system_and_set: "EU 101.6",
                      equivalent_sizes: {
                        us: ["40BT"],
                        au: ["40BTAU"],
                        uk: ["40BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "104.1EU",
                      short: "104.1",
                      long: "104.1",
                      display: "104.1",
                      display_with_size_set: "104.1",
                      display_with_size_system: "EU 104.1",
                      display_with_system_and_set: "EU 104.1",
                      equivalent_sizes: {
                        us: ["41"],
                        au: ["41AU"],
                        uk: ["41UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "106.7EU",
                      short: "106.7",
                      long: "106.7",
                      display: "106.7",
                      display_with_size_set: "106.7",
                      display_with_size_system: "EU 106.7",
                      display_with_system_and_set: "EU 106.7",
                      equivalent_sizes: {
                        us: ["42"],
                        au: ["42AU"],
                        uk: ["42UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "109.2EU",
                      short: "109.2",
                      long: "109.2",
                      display: "109.2",
                      display_with_size_set: "109.2",
                      display_with_size_system: "EU 109.2",
                      display_with_system_and_set: "EU 109.2",
                      equivalent_sizes: {
                        us: ["43"],
                        au: ["43AU"],
                        uk: ["43UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "111.8EU",
                      short: "111.8",
                      long: "111.8",
                      display: "111.8",
                      display_with_size_set: "111.8",
                      display_with_size_system: "EU 111.8",
                      display_with_system_and_set: "EU 111.8",
                      equivalent_sizes: {
                        us: ["44"],
                        au: ["44AU"],
                        uk: ["44UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "116.8EU",
                      short: "116.8",
                      long: "116.8",
                      display: "116.8",
                      display_with_size_set: "116.8",
                      display_with_size_system: "EU 116.8",
                      display_with_system_and_set: "EU 116.8",
                      equivalent_sizes: {
                        us: ["46"],
                        au: ["46AU"],
                        uk: ["46UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "121.9EU",
                      short: "121.9",
                      long: "121.9",
                      display: "121.9",
                      display_with_size_set: "121.9",
                      display_with_size_system: "EU 121.9",
                      display_with_system_and_set: "EU 121.9",
                      equivalent_sizes: {
                        us: ["48"],
                        au: ["48AU"],
                        uk: ["48UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "127.0EU",
                      short: "127.0",
                      long: "127.0",
                      display: "127.0",
                      display_with_size_set: "127.0",
                      display_with_size_system: "EU 127.0",
                      display_with_system_and_set: "EU 127.0",
                      equivalent_sizes: {
                        us: ["50"],
                        au: ["50AU"],
                        uk: ["50UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "132.1EU",
                      short: "132.1",
                      long: "132.1",
                      display: "132.1",
                      display_with_size_set: "132.1",
                      display_with_size_system: "EU 132.1",
                      display_with_system_and_set: "EU 132.1",
                      equivalent_sizes: {
                        us: ["52"],
                        au: ["52AU"],
                        uk: ["52UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "137.2EU",
                      short: "137.2",
                      long: "137.2",
                      display: "137.2",
                      display_with_size_set: "137.2",
                      display_with_size_system: "EU 137.2",
                      display_with_system_and_set: "EU 137.2",
                      equivalent_sizes: {
                        us: ["54"],
                        au: ["54AU"],
                        uk: ["54UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "142.2EU",
                      short: "142.2",
                      long: "142.2",
                      display: "142.2",
                      display_with_size_set: "142.2",
                      display_with_size_system: "EU 142.2",
                      display_with_system_and_set: "EU 142.2",
                      equivalent_sizes: {
                        us: ["56"],
                        au: ["56AU"],
                        uk: ["56UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "147.3EU",
                      short: "147.3",
                      long: "147.3",
                      display: "147.3",
                      display_with_size_set: "147.3",
                      display_with_size_system: "EU 147.3",
                      display_with_system_and_set: "EU 147.3",
                      equivalent_sizes: {
                        us: ["58"],
                        au: ["58AU"],
                        uk: ["58UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "152.4EU",
                      short: "152.4",
                      long: "152.4",
                      display: "152.4",
                      display_with_size_set: "152.4",
                      display_with_size_system: "EU 152.4",
                      display_with_system_and_set: "EU 152.4",
                      equivalent_sizes: {
                        us: ["60"],
                        au: ["60AU"],
                        uk: ["60UK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLUK",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "UK 3XL",
                      display_with_system_and_set: "UK 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLUK",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "UK 4XL",
                      display_with_system_and_set: "UK 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLUK",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "UK 5XL",
                      display_with_system_and_set: "UK 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLUK",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "UK 6XL",
                      display_with_system_and_set: "UK 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LTUK",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "UK LT",
                      display_with_system_and_set: "UK LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        eu: ["LTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLTUK",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "UK XLT",
                      display_with_system_and_set: "UK XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XLTUK",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "UK 2XLT",
                      display_with_system_and_set: "UK 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLTUK",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "UK 3XLT",
                      display_with_system_and_set: "UK 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLTUK",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "UK 4XLT",
                      display_with_system_and_set: "UK 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLTUK",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "UK 5XLT",
                      display_with_system_and_set: "UK 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLTUK",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "UK 6XLT",
                      display_with_system_and_set: "UK 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36BTUK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["36BT"],
                        au: ["36BTAU"],
                        eu: ["91.4BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "37BTUK",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "UK 37",
                      display_with_system_and_set: "UK 37",
                      equivalent_sizes: {
                        us: ["37BT"],
                        au: ["37BTAU"],
                        eu: ["94BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38BTUK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["38BT"],
                        au: ["38BTUK"],
                        eu: ["96.5BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "39BTUK",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "UK 39",
                      display_with_system_and_set: "UK 39",
                      equivalent_sizes: {
                        us: ["39BT"],
                        au: ["39BTUK"],
                        eu: ["99.1BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40BTUK",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "UK 40",
                      display_with_system_and_set: "UK 40",
                      equivalent_sizes: {
                        us: ["40BT"],
                        au: ["40BTUK"],
                        eu: ["101.6BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "41UK",
                      short: "41",
                      long: "41",
                      display: "41",
                      display_with_size_set: "41",
                      display_with_size_system: "UK 41",
                      display_with_system_and_set: "UK 41",
                      equivalent_sizes: {
                        us: ["41"],
                        au: ["41UK"],
                        eu: ["104.1EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42UK",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "UK 42",
                      display_with_system_and_set: "UK 42",
                      equivalent_sizes: {
                        us: ["42"],
                        au: ["42AU"],
                        eu: ["106.7EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "43UK",
                      short: "43",
                      long: "43",
                      display: "43",
                      display_with_size_set: "43",
                      display_with_size_system: "UK 43",
                      display_with_system_and_set: "UK 43",
                      equivalent_sizes: {
                        us: ["43"],
                        au: ["43AU"],
                        eu: ["109.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44UK",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "UK 44",
                      display_with_system_and_set: "UK 44",
                      equivalent_sizes: {
                        us: ["44"],
                        au: ["44AU"],
                        eu: ["111.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46UK",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "UK 46",
                      display_with_system_and_set: "UK 46",
                      equivalent_sizes: {
                        us: ["46"],
                        au: ["46AU"],
                        eu: ["116.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48UK",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "UK 48",
                      display_with_system_and_set: "UK 48",
                      equivalent_sizes: {
                        us: ["48"],
                        au: ["48AU"],
                        eu: ["121.9EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "50UK",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "UK 50",
                      display_with_system_and_set: "UK 50",
                      equivalent_sizes: {
                        us: ["50"],
                        au: ["50AU"],
                        eu: ["127.0EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "52UK",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "UK 52",
                      display_with_system_and_set: "UK 52",
                      equivalent_sizes: {
                        us: ["52"],
                        au: ["52AU"],
                        eu: ["132.1EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "54UK",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "UK 54",
                      display_with_system_and_set: "UK 54",
                      equivalent_sizes: {
                        us: ["54"],
                        au: ["54AU"],
                        eu: ["137.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "56UK",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "UK 56",
                      display_with_system_and_set: "UK 56",
                      equivalent_sizes: {
                        us: ["56"],
                        au: ["56AU"],
                        eu: ["142.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "58UK",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "UK 58",
                      display_with_system_and_set: "UK 58",
                      equivalent_sizes: {
                        us: ["58"],
                        au: ["58AU"],
                        eu: ["147.3EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "60UK",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "UK 60",
                      display_with_system_and_set: "UK 60",
                      equivalent_sizes: {
                        us: ["60"],
                        au: ["60AU"],
                        eu: ["152.4EU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "men_kurta_bottoms",
          aliases: [],
          slug: "Kurta_Bottoms",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
        },
        {
          id: "afb476dd402403bf28a2606b",
          display: "Kurtas",
          type: "c",
          size_sets: [
            {
              id: "b39ecb3e402403cd9fcf606c",
              name: "Standard",
              tags: ["standard"],
              category_id: "afb476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["34-36", "28-30"],
                  ["36-38", "30-32"],
                  ["38-40", "32-34"],
                  ["40-42", "34-36"],
                  ["42-44", "36-38"],
                  ["44-46", "38-40"],
                  ["46-48", "40-42"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["86.4-91.4", "71.1-76.2"],
                  ["91.4-96.5", "76.2-81.3"],
                  ["96.5-101.6", "81.3-86.4"],
                  ["101.6-106.7", "86.4-91.4"],
                  ["106.7-111.8", "91.4-96.5"],
                  ["111.8-116.9", "96.5-101.6"],
                  ["116.9-121.9", "101.6-106.7"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "d179bbb5aabb08aed90362fb",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "afb476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["46-48", "40-42"],
                  ["49-51", "43-45"],
                  ["52-54", "46-48"],
                  ["55-57", "49-51"],
                  ["58-60", "52-54"],
                  ["42-44", "36-39"],
                  ["46-48", "40-44"],
                  ["50-52", "45-48.5"],
                  ["54-56", "49-52"],
                  ["56-58", "52-55"],
                  ["58-60", "56-60"],
                  ["62-64", "62-64"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["116.8-121.9", "101.6-106.6"],
                  ["124.4-129.5", "109.2-114.3"],
                  ["132.1-137.2.6", "116.8-121.9"],
                  ["139.7-144.8", "124.4-129.5"],
                  ["147.3-152.4", "132-137.1"],
                  ["106.7-111.7", "91.4-99"],
                  ["116.8-121.9", "101.6-111.7"],
                  ["127-132", "114.3-123.1"],
                  ["137.1-142.2", "124.4-132"],
                  ["142.2-147.3", "132-139.7"],
                  ["147.3-152.4", "142.2-152.4"],
                  ["157.4-162.5", "157.4-162.5"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["3XL", "3XL", "3XL", "3XL"],
                  ["4XL", "4XL", "4XL", "4XL"],
                  ["5XL", "5XL", "5XL", "5XL"],
                  ["6XL", "6XL", "6XL", "6XL"],
                  ["LT", "LT", "LT", "LT"],
                  ["XLT", "XLT", "XLT", "XLT"],
                  ["2XLT", "2XLT", "2XLT", "2XLT"],
                  ["3XLT", "3XLT", "3XLT", "3XLT"],
                  ["4XLT", "4XLT", "4XLT", "4XLT"],
                  ["5XLT", "5XLT", "5XLT", "5XLT"],
                  ["6XLT", "6XLT", "6XLT", "6XLT"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["3XL", "3XL", "3XL", "3XL"],
                ["4XL", "4XL", "4XL", "4XL"],
                ["5XL", "5XL", "5XL", "5XL"],
                ["6XL", "6XL", "6XL", "6XL"],
                ["LT", "LT", "LT", "LT"],
                ["XLT", "XLT", "XLT", "XLT"],
                ["2XLT", "2XLT", "2XLT", "2XLT"],
                ["3XLT", "3XLT", "3XLT", "3XLT"],
                ["4XLT", "4XLT", "4XLT", "4XLT"],
                ["5XLT", "5XLT", "5XLT", "5XLT"],
                ["6XLT", "6XLT", "6XLT", "6XLT"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XL",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "US 3XL",
                      display_with_system_and_set: "US 3XL",
                      equivalent_sizes: {
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XL",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "US 4XL",
                      display_with_system_and_set: "US 4XL",
                      equivalent_sizes: {
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XL",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "US 5XL",
                      display_with_system_and_set: "US 5XL",
                      equivalent_sizes: {
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XL",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "US 6XL",
                      display_with_system_and_set: "US 6XL",
                      equivalent_sizes: {
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LT",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "US LT",
                      display_with_system_and_set: "US LT",
                      equivalent_sizes: {
                        au: ["LTAU"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLT",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "US XLT",
                      display_with_system_and_set: "US XLT",
                      equivalent_sizes: {
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XLT",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "US 2XLT",
                      display_with_system_and_set: "US 2XLT",
                      equivalent_sizes: {
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XLT",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "US 3XLT",
                      display_with_system_and_set: "US 3XLT",
                      equivalent_sizes: {
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XLT",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "US 4XLT",
                      display_with_system_and_set: "US 4XLT",
                      equivalent_sizes: {
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XLT",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "US 5XLT",
                      display_with_system_and_set: "US 5XLT",
                      equivalent_sizes: {
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XLT",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "US 6XLT",
                      display_with_system_and_set: "US 6XLT",
                      equivalent_sizes: {
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLAU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "AU 3XL",
                      display_with_system_and_set: "AU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLAU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "AU 4XL",
                      display_with_system_and_set: "AU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLAU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "AU 5XL",
                      display_with_system_and_set: "AU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLAU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "AU 6XL",
                      display_with_system_and_set: "AU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LTAU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "AU LT",
                      display_with_system_and_set: "AU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLTAU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "AU XLT",
                      display_with_system_and_set: "AU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XLTAU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "AU 2XLT",
                      display_with_system_and_set: "AU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLTAU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "AU 3XLT",
                      display_with_system_and_set: "AU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLTAU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "AU 4XLT",
                      display_with_system_and_set: "AU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLTAU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "AU 5XLT",
                      display_with_system_and_set: "AU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLTAU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "AU 6XLT",
                      display_with_system_and_set: "AU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLEU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "EU 3XL",
                      display_with_system_and_set: "EU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLEU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "EU 4XL",
                      display_with_system_and_set: "EU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLEU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "EU 5XL",
                      display_with_system_and_set: "EU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLEU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "EU 6XL",
                      display_with_system_and_set: "EU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LTEU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "EU LT",
                      display_with_system_and_set: "EU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        uk: ["LTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLTEU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "EU XLT",
                      display_with_system_and_set: "EU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XLTEU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "EU 2XLT",
                      display_with_system_and_set: "EU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLTEU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "EU 3XLT",
                      display_with_system_and_set: "EU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLTEU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "EU 4XLT",
                      display_with_system_and_set: "EU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLTEU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "EU 5XLT",
                      display_with_system_and_set: "EU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLTEU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "EU 6XLT",
                      display_with_system_and_set: "EU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLUK",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "UK 3XL",
                      display_with_system_and_set: "UK 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLUK",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "UK 4XL",
                      display_with_system_and_set: "UK 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLUK",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "UK 5XL",
                      display_with_system_and_set: "UK 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLUK",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "UK 6XL",
                      display_with_system_and_set: "UK 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LTUK",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "UK LT",
                      display_with_system_and_set: "UK LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        eu: ["LTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLTUK",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "UK XLT",
                      display_with_system_and_set: "UK XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XLTUK",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "UK 2XLT",
                      display_with_system_and_set: "UK 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLTUK",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "UK 3XLT",
                      display_with_system_and_set: "UK 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLTUK",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "UK 4XLT",
                      display_with_system_and_set: "UK 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLTUK",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "UK 5XLT",
                      display_with_system_and_set: "UK 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLTUK",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "UK 6XLT",
                      display_with_system_and_set: "UK 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "men_kurtas",
          aliases: [],
          slug: "Kurtas",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
        },
        {
          id: "bab476dd402403bf28a2606b",
          display: "Lederhosen",
          type: "c",
          size_sets: [
            {
              id: "b29ecb3e402403cd9fcf606c",
              name: "Standard",
              tags: ["standard"],
              category_id: "bab476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Waist "],
                  ["28-29"],
                  ["30-31"],
                  ["32-35"],
                  ["36-38"],
                  ["39-40"],
                  ["41-42"],
                  ["43-44"],
                ],
                centimeters: [
                  ["Waist "],
                  ["71.1-73.7"],
                  ["76.2-78.7"],
                  ["81.3-88.9"],
                  ["91.4-96.5"],
                  ["99.1-101.6"],
                  ["104.1-106.7"],
                  ["109.2-111.8"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["Waist 28", "28", "71.12", "28"],
                ["Waist 29", "29", "73.66", "29"],
                ["Waist 30", "30", "76.2", "30"],
                ["Waist 31", "31", "78.8", "31"],
                ["Waist 32", "32", "81.3", "32"],
                ["Waist 33", "33", "83.8", "33"],
                ["Waist 34", "34", "86.4", "34"],
                ["Waist 35", "35", "88.9", "35"],
                ["Waist 36", "36", "91.4", "36"],
                ["Waist 37", "37", "94", "37"],
                ["Waist 38", "38", "96.5", "38"],
                ["Waist 39", "39", "99.1", "39"],
                ["Waist 40", "40", "101.6", "40"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "28",
                      short: "Waist 28",
                      long: "Waist 28",
                      display: "Waist 28",
                      display_with_size_set: "Waist 28",
                      display_with_size_system: "US Waist 28",
                      display_with_system_and_set: "US Waist 28",
                      equivalent_sizes: {
                        au: ["28AU"],
                        eu: ["71.12EU"],
                        uk: ["28UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "29",
                      short: "Waist 29",
                      long: "Waist 29",
                      display: "Waist 29",
                      display_with_size_set: "Waist 29",
                      display_with_size_system: "US Waist 29",
                      display_with_system_and_set: "US Waist 29",
                      equivalent_sizes: {
                        au: ["29AU"],
                        eu: ["73.66EU"],
                        uk: ["29UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "30",
                      short: "Waist 30",
                      long: "Waist 30",
                      display: "Waist 30",
                      display_with_size_set: "Waist 30",
                      display_with_size_system: "US Waist 30",
                      display_with_system_and_set: "US Waist 30",
                      equivalent_sizes: {
                        au: ["30AU"],
                        eu: ["76.2EU"],
                        uk: ["30UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "31",
                      short: "Waist 31",
                      long: "Waist 31",
                      display: "Waist 31",
                      display_with_size_set: "Waist 31",
                      display_with_size_system: "US Waist 31",
                      display_with_system_and_set: "US Waist 31",
                      equivalent_sizes: {
                        au: ["31AU"],
                        eu: ["78.8EU"],
                        uk: ["31UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "32",
                      short: "Waist 32",
                      long: "Waist 32",
                      display: "Waist 32",
                      display_with_size_set: "Waist 32",
                      display_with_size_system: "US Waist 32",
                      display_with_system_and_set: "US Waist 32",
                      equivalent_sizes: {
                        au: ["32AU"],
                        eu: ["81.3EU"],
                        uk: ["32UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "33",
                      short: "Waist 33",
                      long: "Waist 33",
                      display: "Waist 33",
                      display_with_size_set: "Waist 33",
                      display_with_size_system: "US Waist 33",
                      display_with_system_and_set: "US Waist 33",
                      equivalent_sizes: {
                        au: ["33AU"],
                        eu: ["83.8EU"],
                        uk: ["33UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "34",
                      short: "Waist 34",
                      long: "Waist 34",
                      display: "Waist 34",
                      display_with_size_set: "Waist 34",
                      display_with_size_system: "US Waist 34",
                      display_with_system_and_set: "US Waist 34",
                      equivalent_sizes: {
                        au: ["34AU"],
                        eu: ["86.4EU"],
                        uk: ["34UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "35",
                      short: "Waist 35",
                      long: "Waist 35",
                      display: "Waist 35",
                      display_with_size_set: "Waist 35",
                      display_with_size_system: "US Waist 35",
                      display_with_system_and_set: "US Waist 35",
                      equivalent_sizes: {
                        au: ["35AU"],
                        eu: ["88.9EU"],
                        uk: ["35UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36",
                      short: "Waist 36",
                      long: "Waist 36",
                      display: "Waist 36",
                      display_with_size_set: "Waist 36",
                      display_with_size_system: "US Waist 36",
                      display_with_system_and_set: "US Waist 36",
                      equivalent_sizes: {
                        au: ["36AU"],
                        eu: ["91.4EU"],
                        uk: ["36UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "37",
                      short: "Waist 37",
                      long: "Waist 37",
                      display: "Waist 37",
                      display_with_size_set: "Waist 37",
                      display_with_size_system: "US Waist 37",
                      display_with_system_and_set: "US Waist 37",
                      equivalent_sizes: {
                        au: ["37AU"],
                        eu: ["94EU"],
                        uk: ["37UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38",
                      short: "Waist 38",
                      long: "Waist 38",
                      display: "Waist 38",
                      display_with_size_set: "Waist 38",
                      display_with_size_system: "US Waist 38",
                      display_with_system_and_set: "US Waist 38",
                      equivalent_sizes: {
                        au: ["38AU"],
                        eu: ["96.5EU"],
                        uk: ["38UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "39",
                      short: "Waist 39",
                      long: "Waist 39",
                      display: "Waist 39",
                      display_with_size_set: "Waist 39",
                      display_with_size_system: "US Waist 39",
                      display_with_system_and_set: "US Waist 39",
                      equivalent_sizes: {
                        au: ["39AU"],
                        eu: ["99.1EU"],
                        uk: ["39UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40",
                      short: "Waist 40",
                      long: "Waist 40",
                      display: "Waist 40",
                      display_with_size_set: "Waist 40",
                      display_with_size_system: "US Waist 40",
                      display_with_system_and_set: "US Waist 40",
                      equivalent_sizes: {
                        au: ["40AU"],
                        eu: ["101.6EU"],
                        uk: ["40UK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "28AU",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "AU 28",
                      display_with_system_and_set: "AU 28",
                      equivalent_sizes: {
                        us: ["28"],
                        eu: ["71.12EU"],
                        uk: ["28UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "29AU",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "AU 29",
                      display_with_system_and_set: "AU 29",
                      equivalent_sizes: {
                        us: ["29"],
                        eu: ["73.66EU"],
                        uk: ["29UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "30AU",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "AU 30",
                      display_with_system_and_set: "AU 30",
                      equivalent_sizes: {
                        us: ["30"],
                        eu: ["76.2EU"],
                        uk: ["30UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "31AU",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "AU 31",
                      display_with_system_and_set: "AU 31",
                      equivalent_sizes: {
                        us: ["31"],
                        eu: ["78.8EU"],
                        uk: ["31UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "32AU",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "AU 32",
                      display_with_system_and_set: "AU 32",
                      equivalent_sizes: {
                        us: ["32"],
                        eu: ["81.3EU"],
                        uk: ["32UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "33AU",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "AU 33",
                      display_with_system_and_set: "AU 33",
                      equivalent_sizes: {
                        us: ["33"],
                        eu: ["83.8EU"],
                        uk: ["33UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "34AU",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "AU 34",
                      display_with_system_and_set: "AU 34",
                      equivalent_sizes: {
                        us: ["34"],
                        eu: ["86.4EU"],
                        uk: ["34UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "35AU",
                      short: "35",
                      long: "35",
                      display: "35",
                      display_with_size_set: "35",
                      display_with_size_system: "AU 35",
                      display_with_system_and_set: "AU 35",
                      equivalent_sizes: {
                        us: ["35"],
                        eu: ["88.9EU"],
                        uk: ["35UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36AU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["36"],
                        eu: ["91.4EU"],
                        uk: ["36UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "37AU",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "AU 37",
                      display_with_system_and_set: "AU 37",
                      equivalent_sizes: {
                        us: ["37"],
                        eu: ["94EU"],
                        uk: ["37UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38AU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["38"],
                        eu: ["96.5EU"],
                        uk: ["38UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "39AU",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "AU 39",
                      display_with_system_and_set: "AU 39",
                      equivalent_sizes: {
                        us: ["39"],
                        eu: ["99.1EU"],
                        uk: ["39UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "40AU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "AU 40",
                      display_with_system_and_set: "AU 40",
                      equivalent_sizes: {
                        us: ["40"],
                        eu: ["101.6EU"],
                        uk: ["40UK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "71.12EU",
                      short: "71.12",
                      long: "71.12",
                      display: "71.12",
                      display_with_size_set: "71.12",
                      display_with_size_system: "EU 71.12",
                      display_with_system_and_set: "EU 71.12",
                      equivalent_sizes: {
                        us: ["28"],
                        au: ["28AU"],
                        uk: ["28UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "73.66EU",
                      short: "73.66",
                      long: "73.66",
                      display: "73.66",
                      display_with_size_set: "73.66",
                      display_with_size_system: "EU 73.66",
                      display_with_system_and_set: "EU 73.66",
                      equivalent_sizes: {
                        us: ["29"],
                        au: ["29AU"],
                        uk: ["29UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "76.2EU",
                      short: "76.2",
                      long: "76.2",
                      display: "76.2",
                      display_with_size_set: "76.2",
                      display_with_size_system: "EU 76.2",
                      display_with_system_and_set: "EU 76.2",
                      equivalent_sizes: {
                        us: ["30"],
                        au: ["30AU"],
                        uk: ["30UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "78.8EU",
                      short: "78.8",
                      long: "78.8",
                      display: "78.8",
                      display_with_size_set: "78.8",
                      display_with_size_system: "EU 78.8",
                      display_with_system_and_set: "EU 78.8",
                      equivalent_sizes: {
                        us: ["31"],
                        au: ["31AU"],
                        uk: ["31UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "81.3EU",
                      short: "81.3",
                      long: "81.3",
                      display: "81.3",
                      display_with_size_set: "81.3",
                      display_with_size_system: "EU 81.3",
                      display_with_system_and_set: "EU 81.3",
                      equivalent_sizes: {
                        us: ["32"],
                        au: ["32AU"],
                        uk: ["32UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "83.8EU",
                      short: "83.8",
                      long: "83.8",
                      display: "83.8",
                      display_with_size_set: "83.8",
                      display_with_size_system: "EU 83.8",
                      display_with_system_and_set: "EU 83.8",
                      equivalent_sizes: {
                        us: ["33"],
                        au: ["33AU"],
                        uk: ["33UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "86.4EU",
                      short: "86.4",
                      long: "86.4",
                      display: "86.4",
                      display_with_size_set: "86.4",
                      display_with_size_system: "EU 86.4",
                      display_with_system_and_set: "EU 86.4",
                      equivalent_sizes: {
                        us: ["34"],
                        au: ["34AU"],
                        uk: ["34UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "88.9EU",
                      short: "88.9",
                      long: "88.9",
                      display: "88.9",
                      display_with_size_set: "88.9",
                      display_with_size_system: "EU 88.9",
                      display_with_system_and_set: "EU 88.9",
                      equivalent_sizes: {
                        us: ["35"],
                        au: ["35AU"],
                        uk: ["35UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "91.4EU",
                      short: "91.4",
                      long: "91.4",
                      display: "91.4",
                      display_with_size_set: "91.4",
                      display_with_size_system: "EU 91.4",
                      display_with_system_and_set: "EU 91.4",
                      equivalent_sizes: {
                        us: ["36"],
                        au: ["36AU"],
                        uk: ["36UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "94EU",
                      short: "94",
                      long: "94",
                      display: "94",
                      display_with_size_set: "94",
                      display_with_size_system: "EU 94",
                      display_with_system_and_set: "EU 94",
                      equivalent_sizes: {
                        us: ["37"],
                        au: ["37AU"],
                        uk: ["37UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "96.5EU",
                      short: "96.5",
                      long: "96.5",
                      display: "96.5",
                      display_with_size_set: "96.5",
                      display_with_size_system: "EU 96.5",
                      display_with_system_and_set: "EU 96.5",
                      equivalent_sizes: {
                        us: ["38"],
                        au: ["38AU"],
                        uk: ["38UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "99.1EU",
                      short: "99.1",
                      long: "99.1",
                      display: "99.1",
                      display_with_size_set: "99.1",
                      display_with_size_system: "EU 99.1",
                      display_with_system_and_set: "EU 99.1",
                      equivalent_sizes: {
                        us: ["39"],
                        au: ["39AU"],
                        uk: ["39UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "101.6EU",
                      short: "101.6",
                      long: "101.6",
                      display: "101.6",
                      display_with_size_set: "101.6",
                      display_with_size_system: "EU 101.6",
                      display_with_system_and_set: "EU 101.6",
                      equivalent_sizes: {
                        us: ["40"],
                        au: ["40AU"],
                        uk: ["40UK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "28UK",
                      short: "28",
                      long: "28",
                      display: "28",
                      display_with_size_set: "28",
                      display_with_size_system: "UK 28",
                      display_with_system_and_set: "UK 28",
                      equivalent_sizes: {
                        us: ["28"],
                        au: ["28AU"],
                        eu: ["71.12EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "29UK",
                      short: "29",
                      long: "29",
                      display: "29",
                      display_with_size_set: "29",
                      display_with_size_system: "UK 29",
                      display_with_system_and_set: "UK 29",
                      equivalent_sizes: {
                        us: ["29"],
                        au: ["29AU"],
                        eu: ["73.66EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "30UK",
                      short: "30",
                      long: "30",
                      display: "30",
                      display_with_size_set: "30",
                      display_with_size_system: "UK 30",
                      display_with_system_and_set: "UK 30",
                      equivalent_sizes: {
                        us: ["30"],
                        au: ["30AU"],
                        eu: ["76.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "31UK",
                      short: "31",
                      long: "31",
                      display: "31",
                      display_with_size_set: "31",
                      display_with_size_system: "UK 31",
                      display_with_system_and_set: "UK 31",
                      equivalent_sizes: {
                        us: ["31"],
                        au: ["31AU"],
                        eu: ["78.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "32UK",
                      short: "32",
                      long: "32",
                      display: "32",
                      display_with_size_set: "32",
                      display_with_size_system: "UK 32",
                      display_with_system_and_set: "UK 32",
                      equivalent_sizes: {
                        us: ["32"],
                        au: ["32AU"],
                        eu: ["81.3EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "33UK",
                      short: "33",
                      long: "33",
                      display: "33",
                      display_with_size_set: "33",
                      display_with_size_system: "UK 33",
                      display_with_system_and_set: "UK 33",
                      equivalent_sizes: {
                        us: ["33"],
                        au: ["33AU"],
                        eu: ["83.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "34UK",
                      short: "34",
                      long: "34",
                      display: "34",
                      display_with_size_set: "34",
                      display_with_size_system: "UK 34",
                      display_with_system_and_set: "UK 34",
                      equivalent_sizes: {
                        us: ["34"],
                        au: ["34AU"],
                        eu: ["86.4EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "35UK",
                      short: "35",
                      long: "35",
                      display: "35",
                      display_with_size_set: "35",
                      display_with_size_system: "UK 35",
                      display_with_system_and_set: "UK 35",
                      equivalent_sizes: {
                        us: ["35"],
                        au: ["35AU"],
                        eu: ["88.9EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36UK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["36"],
                        au: ["36AU"],
                        eu: ["91.4EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "37UK",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "UK 37",
                      display_with_system_and_set: "UK 37",
                      equivalent_sizes: {
                        us: ["37"],
                        au: ["37AU"],
                        eu: ["94EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38UK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["38"],
                        au: ["38AU"],
                        eu: ["96.5EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "39UK",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "UK 39",
                      display_with_system_and_set: "UK 39",
                      equivalent_sizes: {
                        us: ["39"],
                        au: ["39AU"],
                        eu: ["99.1EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40UK",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "UK 40",
                      display_with_system_and_set: "UK 40",
                      equivalent_sizes: {
                        us: ["40"],
                        au: ["40AU"],
                        eu: ["101.6EU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "db79bbb6aabb08aed90362fb",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "bab476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Waist "],
                  ["42-44"],
                  ["46-48"],
                  ["50-52"],
                  ["54-56"],
                  ["58-60"],
                  ["40-44"],
                  ["45-48.5"],
                  ["49-52"],
                  ["52-55"],
                  ["56-60"],
                  ["62-64"],
                ],
                centimeters: [
                  ["Waist "],
                  ["106.7-111.8"],
                  ["116.8-121.9"],
                  ["127-132.1"],
                  ["137.2-142.2"],
                  ["147.3-152.4"],
                  ["99.1-101.6"],
                  ["106.7-111.8"],
                  ["116.8-121.9"],
                  ["127-132.1"],
                  ["142.2-152.5"],
                  ["157.4-162.5"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["3XL", "3XL", "3XL", "3XL"],
                  ["4XL", "4XL", "4XL", "4XL"],
                  ["5XL", "5XL", "5XL", "5XL"],
                  ["6XL", "6XL", "6XL", "6XL"],
                  ["XLT", "XLT", "XLT", "XLT"],
                  ["3XLT", "3XLT", "3XLT", "3XLT"],
                  ["4XLT", "4XLT", "4XLT", "4XLT"],
                  ["5XLT", "5XLT", "5XLT", "5XLT"],
                  ["6XLT", "6XLT", "6XLT", "6XLT"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["3XL", "3XL", "3XL", "3XL"],
                ["4XL", "4XL", "4XL", "4XL"],
                ["5XL", "5XL", "5XL", "5XL"],
                ["6XL", "6XL", "6XL", "6XL"],
                ["LT", "LT", "LT", "LT"],
                ["XLT", "XLT", "XLT", "XLT"],
                ["2XLT", "2XLT", "2XLT", "2XLT"],
                ["3XLT", "3XLT", "3XLT", "3XLT"],
                ["4XLT", "4XLT", "4XLT", "4XLT"],
                ["5XLT", "5XLT", "5XLT", "5XLT"],
                ["6XLT", "6XLT", "6XLT", "6XLT"],
                ["Waist 36", "36", "91.4", "36"],
                ["Waist 37", "37", "94", "37"],
                ["Waist 38", "38", "96.5", "38"],
                ["Waist 39", "39", "99.1", "39"],
                ["Waist 40", "40", "101.6", "40"],
                ["Waist 41", "41", "104.1", "41"],
                ["Waist 42", "42", "106.7", "42"],
                ["Waist 43", "43", "109.2", "43"],
                ["Waist 44", "44", "111.8", "44"],
                ["Waist 46", "46", "116.8", "46"],
                ["Waist 48", "48", "121.9", "48"],
                ["Waist 50", "50", "127.0", "50"],
                ["Waist 52", "52", "132.1", "52"],
                ["Waist 54", "54", "137.2", "54"],
                ["Waist 56", "56", "142.2", "56"],
                ["Waist 58", "58", "147.3", "58"],
                ["Waist 60", "60", "152.4", "60"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XL",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "US 3XL",
                      display_with_system_and_set: "US 3XL",
                      equivalent_sizes: {
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XL",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "US 4XL",
                      display_with_system_and_set: "US 4XL",
                      equivalent_sizes: {
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XL",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "US 5XL",
                      display_with_system_and_set: "US 5XL",
                      equivalent_sizes: {
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XL",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "US 6XL",
                      display_with_system_and_set: "US 6XL",
                      equivalent_sizes: {
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LT",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "US LT",
                      display_with_system_and_set: "US LT",
                      equivalent_sizes: {
                        au: ["LTAU"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLT",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "US XLT",
                      display_with_system_and_set: "US XLT",
                      equivalent_sizes: {
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XLT",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "US 2XLT",
                      display_with_system_and_set: "US 2XLT",
                      equivalent_sizes: {
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XLT",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "US 3XLT",
                      display_with_system_and_set: "US 3XLT",
                      equivalent_sizes: {
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XLT",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "US 4XLT",
                      display_with_system_and_set: "US 4XLT",
                      equivalent_sizes: {
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XLT",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "US 5XLT",
                      display_with_system_and_set: "US 5XLT",
                      equivalent_sizes: {
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XLT",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "US 6XLT",
                      display_with_system_and_set: "US 6XLT",
                      equivalent_sizes: {
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "36BT",
                      short: "Waist 36",
                      long: "Waist 36",
                      display: "Waist 36",
                      display_with_size_set: "Waist 36",
                      display_with_size_system: "US Waist 36",
                      display_with_system_and_set: "US Waist 36",
                      equivalent_sizes: {
                        au: ["36BTAU"],
                        eu: ["91.4BTEU"],
                        uk: ["36BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "37BT",
                      short: "Waist 37",
                      long: "Waist 37",
                      display: "Waist 37",
                      display_with_size_set: "Waist 37",
                      display_with_size_system: "US Waist 37",
                      display_with_system_and_set: "US Waist 37",
                      equivalent_sizes: {
                        au: ["37BTAU"],
                        eu: ["94BTEU"],
                        uk: ["37BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "38BT",
                      short: "Waist 38",
                      long: "Waist 38",
                      display: "Waist 38",
                      display_with_size_set: "Waist 38",
                      display_with_size_system: "US Waist 38",
                      display_with_system_and_set: "US Waist 38",
                      equivalent_sizes: {
                        au: ["38BTAU"],
                        eu: ["96.5BTEU"],
                        uk: ["38BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "39BT",
                      short: "Waist 39",
                      long: "Waist 39",
                      display: "Waist 39",
                      display_with_size_set: "Waist 39",
                      display_with_size_system: "US Waist 39",
                      display_with_system_and_set: "US Waist 39",
                      equivalent_sizes: {
                        au: ["39BTAU"],
                        eu: ["99.1BTEU"],
                        uk: ["39BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "40BT",
                      short: "Waist 40",
                      long: "Waist 40",
                      display: "Waist 40",
                      display_with_size_set: "Waist 40",
                      display_with_size_system: "US Waist 40",
                      display_with_system_and_set: "US Waist 40",
                      equivalent_sizes: {
                        au: ["40BTAU"],
                        eu: ["101.6BTEU"],
                        uk: ["40BTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "41",
                      short: "Waist 41",
                      long: "Waist 41",
                      display: "Waist 41",
                      display_with_size_set: "Waist 41",
                      display_with_size_system: "US Waist 41",
                      display_with_system_and_set: "US Waist 41",
                      equivalent_sizes: {
                        au: ["41AU"],
                        eu: ["104.1EU"],
                        uk: ["41UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "42",
                      short: "Waist 42",
                      long: "Waist 42",
                      display: "Waist 42",
                      display_with_size_set: "Waist 42",
                      display_with_size_system: "US Waist 42",
                      display_with_system_and_set: "US Waist 42",
                      equivalent_sizes: {
                        au: ["42AU"],
                        eu: ["106.7EU"],
                        uk: ["42UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "43",
                      short: "Waist 43",
                      long: "Waist 43",
                      display: "Waist 43",
                      display_with_size_set: "Waist 43",
                      display_with_size_system: "US Waist 43",
                      display_with_system_and_set: "US Waist 43",
                      equivalent_sizes: {
                        au: ["43AU"],
                        eu: ["109.2EU"],
                        uk: ["43UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "44",
                      short: "Waist 44",
                      long: "Waist 44",
                      display: "Waist 44",
                      display_with_size_set: "Waist 44",
                      display_with_size_system: "US Waist 44",
                      display_with_system_and_set: "US Waist 44",
                      equivalent_sizes: {
                        au: ["44AU"],
                        eu: ["111.8EU"],
                        uk: ["44UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "46",
                      short: "Waist 46",
                      long: "Waist 46",
                      display: "Waist 46",
                      display_with_size_set: "Waist 46",
                      display_with_size_system: "US Waist 46",
                      display_with_system_and_set: "US Waist 46",
                      equivalent_sizes: {
                        au: ["46AU"],
                        eu: ["116.8EU"],
                        uk: ["46UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "48",
                      short: "Waist 48",
                      long: "Waist 48",
                      display: "Waist 48",
                      display_with_size_set: "Waist 48",
                      display_with_size_system: "US Waist 48",
                      display_with_system_and_set: "US Waist 48",
                      equivalent_sizes: {
                        au: ["48AU"],
                        eu: ["121.9EU"],
                        uk: ["48UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "50",
                      short: "Waist 50",
                      long: "Waist 50",
                      display: "Waist 50",
                      display_with_size_set: "Waist 50",
                      display_with_size_system: "US Waist 50",
                      display_with_system_and_set: "US Waist 50",
                      equivalent_sizes: {
                        au: ["50AU"],
                        eu: ["127.0EU"],
                        uk: ["50UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "52",
                      short: "Waist 52",
                      long: "Waist 52",
                      display: "Waist 52",
                      display_with_size_set: "Waist 52",
                      display_with_size_system: "US Waist 52",
                      display_with_system_and_set: "US Waist 52",
                      equivalent_sizes: {
                        au: ["52AU"],
                        eu: ["132.1EU"],
                        uk: ["52UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "54",
                      short: "Waist 54",
                      long: "Waist 54",
                      display: "Waist 54",
                      display_with_size_set: "Waist 54",
                      display_with_size_system: "US Waist 54",
                      display_with_system_and_set: "US Waist 54",
                      equivalent_sizes: {
                        au: ["54AU"],
                        eu: ["137.2EU"],
                        uk: ["54UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "56",
                      short: "Waist 56",
                      long: "Waist 56",
                      display: "Waist 56",
                      display_with_size_set: "Waist 56",
                      display_with_size_system: "US Waist 56",
                      display_with_system_and_set: "US Waist 56",
                      equivalent_sizes: {
                        au: ["56AU"],
                        eu: ["142.2EU"],
                        uk: ["56UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "58",
                      short: "Waist 58",
                      long: "Waist 58",
                      display: "Waist 58",
                      display_with_size_set: "Waist 58",
                      display_with_size_system: "US Waist 58",
                      display_with_system_and_set: "US Waist 58",
                      equivalent_sizes: {
                        au: ["58AU"],
                        eu: ["147.3EU"],
                        uk: ["58UK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "60",
                      short: "Waist 60",
                      long: "Waist 60",
                      display: "Waist 60",
                      display_with_size_set: "Waist 60",
                      display_with_size_system: "US Waist 60",
                      display_with_system_and_set: "US Waist 60",
                      equivalent_sizes: {
                        au: ["60AU"],
                        eu: ["152.4EU"],
                        uk: ["60UK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLAU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "AU 3XL",
                      display_with_system_and_set: "AU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLAU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "AU 4XL",
                      display_with_system_and_set: "AU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLAU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "AU 5XL",
                      display_with_system_and_set: "AU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLAU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "AU 6XL",
                      display_with_system_and_set: "AU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LTAU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "AU LT",
                      display_with_system_and_set: "AU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLTAU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "AU XLT",
                      display_with_system_and_set: "AU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XLTAU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "AU 2XLT",
                      display_with_system_and_set: "AU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLTAU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "AU 3XLT",
                      display_with_system_and_set: "AU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLTAU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "AU 4XLT",
                      display_with_system_and_set: "AU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLTAU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "AU 5XLT",
                      display_with_system_and_set: "AU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLTAU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "AU 6XLT",
                      display_with_system_and_set: "AU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "36BTAU",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "AU 36",
                      display_with_system_and_set: "AU 36",
                      equivalent_sizes: {
                        us: ["36BT"],
                        eu: ["91.4BTEU"],
                        uk: ["36BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "37BTAU",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "AU 37",
                      display_with_system_and_set: "AU 37",
                      equivalent_sizes: {
                        us: ["37BT"],
                        eu: ["94BTEU"],
                        uk: ["37BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "38BTAU",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "AU 38",
                      display_with_system_and_set: "AU 38",
                      equivalent_sizes: {
                        us: ["38BT"],
                        eu: ["96.5BTEU"],
                        uk: ["38BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "39BTAU",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "AU 39",
                      display_with_system_and_set: "AU 39",
                      equivalent_sizes: {
                        us: ["39BT"],
                        eu: ["99.1BTEU"],
                        uk: ["39BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "40BTAU",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "AU 40",
                      display_with_system_and_set: "AU 40",
                      equivalent_sizes: {
                        us: ["40BT"],
                        eu: ["101.6BTEU"],
                        uk: ["40BTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "41AU",
                      short: "41",
                      long: "41",
                      display: "41",
                      display_with_size_set: "41",
                      display_with_size_system: "AU 41",
                      display_with_system_and_set: "AU 41",
                      equivalent_sizes: {
                        us: ["41"],
                        eu: ["104.1EU"],
                        uk: ["41UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "42AU",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "AU 42",
                      display_with_system_and_set: "AU 42",
                      equivalent_sizes: {
                        us: ["42"],
                        eu: ["106.7EU"],
                        uk: ["42UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "43AU",
                      short: "43",
                      long: "43",
                      display: "43",
                      display_with_size_set: "43",
                      display_with_size_system: "AU 43",
                      display_with_system_and_set: "AU 43",
                      equivalent_sizes: {
                        us: ["43"],
                        eu: ["109.2EU"],
                        uk: ["43UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "44AU",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "AU 44",
                      display_with_system_and_set: "AU 44",
                      equivalent_sizes: {
                        us: ["44"],
                        eu: ["111.8EU"],
                        uk: ["44UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "46AU",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "AU 46",
                      display_with_system_and_set: "AU 46",
                      equivalent_sizes: {
                        us: ["46"],
                        eu: ["116.8EU"],
                        uk: ["46UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "48AU",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "AU 48",
                      display_with_system_and_set: "AU 48",
                      equivalent_sizes: {
                        us: ["48"],
                        eu: ["121.9EU"],
                        uk: ["48UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "50AU",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "AU 50",
                      display_with_system_and_set: "AU 50",
                      equivalent_sizes: {
                        us: ["50"],
                        eu: ["127.0EU"],
                        uk: ["50UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "52AU",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "AU 52",
                      display_with_system_and_set: "AU 52",
                      equivalent_sizes: {
                        us: ["52"],
                        eu: ["132.1EU"],
                        uk: ["52UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "54AU",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "AU 54",
                      display_with_system_and_set: "AU 54",
                      equivalent_sizes: {
                        us: ["54"],
                        eu: ["137.2EU"],
                        uk: ["54UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "56AU",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "AU 56",
                      display_with_system_and_set: "AU 56",
                      equivalent_sizes: {
                        us: ["56"],
                        eu: ["142.2EU"],
                        uk: ["56UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "58AU",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "AU 58",
                      display_with_system_and_set: "AU 58",
                      equivalent_sizes: {
                        us: ["58"],
                        eu: ["147.3EU"],
                        uk: ["58UK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "60AU",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "AU 60",
                      display_with_system_and_set: "AU 60",
                      equivalent_sizes: {
                        us: ["60"],
                        eu: ["152.4EU"],
                        uk: ["60UK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLEU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "EU 3XL",
                      display_with_system_and_set: "EU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLEU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "EU 4XL",
                      display_with_system_and_set: "EU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLEU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "EU 5XL",
                      display_with_system_and_set: "EU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLEU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "EU 6XL",
                      display_with_system_and_set: "EU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LTEU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "EU LT",
                      display_with_system_and_set: "EU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        uk: ["LTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLTEU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "EU XLT",
                      display_with_system_and_set: "EU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XLTEU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "EU 2XLT",
                      display_with_system_and_set: "EU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLTEU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "EU 3XLT",
                      display_with_system_and_set: "EU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLTEU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "EU 4XLT",
                      display_with_system_and_set: "EU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLTEU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "EU 5XLT",
                      display_with_system_and_set: "EU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLTEU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "EU 6XLT",
                      display_with_system_and_set: "EU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "91.4BTEU",
                      short: "91.4",
                      long: "91.4",
                      display: "91.4",
                      display_with_size_set: "91.4",
                      display_with_size_system: "EU 91.4",
                      display_with_system_and_set: "EU 91.4",
                      equivalent_sizes: {
                        us: ["36BT"],
                        au: ["36BTAU"],
                        uk: ["36BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "94BTEU",
                      short: "94",
                      long: "94",
                      display: "94",
                      display_with_size_set: "94",
                      display_with_size_system: "EU 94",
                      display_with_system_and_set: "EU 94",
                      equivalent_sizes: {
                        us: ["37BT"],
                        au: ["37BTAU"],
                        uk: ["37BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "96.5BTEU",
                      short: "96.5",
                      long: "96.5",
                      display: "96.5",
                      display_with_size_set: "96.5",
                      display_with_size_system: "EU 96.5",
                      display_with_system_and_set: "EU 96.5",
                      equivalent_sizes: {
                        us: ["38BT"],
                        au: ["38BTAU"],
                        uk: ["38BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "99.1BTEU",
                      short: "99.1",
                      long: "99.1",
                      display: "99.1",
                      display_with_size_set: "99.1",
                      display_with_size_system: "EU 99.1",
                      display_with_system_and_set: "EU 99.1",
                      equivalent_sizes: {
                        us: ["39BT"],
                        au: ["39BTAU"],
                        uk: ["39BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "101.6BTEU",
                      short: "101.6",
                      long: "101.6",
                      display: "101.6",
                      display_with_size_set: "101.6",
                      display_with_size_system: "EU 101.6",
                      display_with_system_and_set: "EU 101.6",
                      equivalent_sizes: {
                        us: ["40BT"],
                        au: ["40BTAU"],
                        uk: ["40BTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "104.1EU",
                      short: "104.1",
                      long: "104.1",
                      display: "104.1",
                      display_with_size_set: "104.1",
                      display_with_size_system: "EU 104.1",
                      display_with_system_and_set: "EU 104.1",
                      equivalent_sizes: {
                        us: ["41"],
                        au: ["41AU"],
                        uk: ["41UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "106.7EU",
                      short: "106.7",
                      long: "106.7",
                      display: "106.7",
                      display_with_size_set: "106.7",
                      display_with_size_system: "EU 106.7",
                      display_with_system_and_set: "EU 106.7",
                      equivalent_sizes: {
                        us: ["42"],
                        au: ["42AU"],
                        uk: ["42UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "109.2EU",
                      short: "109.2",
                      long: "109.2",
                      display: "109.2",
                      display_with_size_set: "109.2",
                      display_with_size_system: "EU 109.2",
                      display_with_system_and_set: "EU 109.2",
                      equivalent_sizes: {
                        us: ["43"],
                        au: ["43AU"],
                        uk: ["43UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "111.8EU",
                      short: "111.8",
                      long: "111.8",
                      display: "111.8",
                      display_with_size_set: "111.8",
                      display_with_size_system: "EU 111.8",
                      display_with_system_and_set: "EU 111.8",
                      equivalent_sizes: {
                        us: ["44"],
                        au: ["44AU"],
                        uk: ["44UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "116.8EU",
                      short: "116.8",
                      long: "116.8",
                      display: "116.8",
                      display_with_size_set: "116.8",
                      display_with_size_system: "EU 116.8",
                      display_with_system_and_set: "EU 116.8",
                      equivalent_sizes: {
                        us: ["46"],
                        au: ["46AU"],
                        uk: ["46UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "121.9EU",
                      short: "121.9",
                      long: "121.9",
                      display: "121.9",
                      display_with_size_set: "121.9",
                      display_with_size_system: "EU 121.9",
                      display_with_system_and_set: "EU 121.9",
                      equivalent_sizes: {
                        us: ["48"],
                        au: ["48AU"],
                        uk: ["48UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "127.0EU",
                      short: "127.0",
                      long: "127.0",
                      display: "127.0",
                      display_with_size_set: "127.0",
                      display_with_size_system: "EU 127.0",
                      display_with_system_and_set: "EU 127.0",
                      equivalent_sizes: {
                        us: ["50"],
                        au: ["50AU"],
                        uk: ["50UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "132.1EU",
                      short: "132.1",
                      long: "132.1",
                      display: "132.1",
                      display_with_size_set: "132.1",
                      display_with_size_system: "EU 132.1",
                      display_with_system_and_set: "EU 132.1",
                      equivalent_sizes: {
                        us: ["52"],
                        au: ["52AU"],
                        uk: ["52UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "137.2EU",
                      short: "137.2",
                      long: "137.2",
                      display: "137.2",
                      display_with_size_set: "137.2",
                      display_with_size_system: "EU 137.2",
                      display_with_system_and_set: "EU 137.2",
                      equivalent_sizes: {
                        us: ["54"],
                        au: ["54AU"],
                        uk: ["54UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "142.2EU",
                      short: "142.2",
                      long: "142.2",
                      display: "142.2",
                      display_with_size_set: "142.2",
                      display_with_size_system: "EU 142.2",
                      display_with_system_and_set: "EU 142.2",
                      equivalent_sizes: {
                        us: ["56"],
                        au: ["56AU"],
                        uk: ["56UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "147.3EU",
                      short: "147.3",
                      long: "147.3",
                      display: "147.3",
                      display_with_size_set: "147.3",
                      display_with_size_system: "EU 147.3",
                      display_with_system_and_set: "EU 147.3",
                      equivalent_sizes: {
                        us: ["58"],
                        au: ["58AU"],
                        uk: ["58UK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "152.4EU",
                      short: "152.4",
                      long: "152.4",
                      display: "152.4",
                      display_with_size_set: "152.4",
                      display_with_size_system: "EU 152.4",
                      display_with_system_and_set: "EU 152.4",
                      equivalent_sizes: {
                        us: ["60"],
                        au: ["60AU"],
                        uk: ["60UK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLUK",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "UK 3XL",
                      display_with_system_and_set: "UK 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLUK",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "UK 4XL",
                      display_with_system_and_set: "UK 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLUK",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "UK 5XL",
                      display_with_system_and_set: "UK 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLUK",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "UK 6XL",
                      display_with_system_and_set: "UK 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LTUK",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "UK LT",
                      display_with_system_and_set: "UK LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        eu: ["LTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLTUK",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "UK XLT",
                      display_with_system_and_set: "UK XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XLTUK",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "UK 2XLT",
                      display_with_system_and_set: "UK 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLTUK",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "UK 3XLT",
                      display_with_system_and_set: "UK 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLTUK",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "UK 4XLT",
                      display_with_system_and_set: "UK 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLTUK",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "UK 5XLT",
                      display_with_system_and_set: "UK 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLTUK",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "UK 6XLT",
                      display_with_system_and_set: "UK 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "36BTUK",
                      short: "36",
                      long: "36",
                      display: "36",
                      display_with_size_set: "36",
                      display_with_size_system: "UK 36",
                      display_with_system_and_set: "UK 36",
                      equivalent_sizes: {
                        us: ["36BT"],
                        au: ["36BTAU"],
                        eu: ["91.4BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "37BTUK",
                      short: "37",
                      long: "37",
                      display: "37",
                      display_with_size_set: "37",
                      display_with_size_system: "UK 37",
                      display_with_system_and_set: "UK 37",
                      equivalent_sizes: {
                        us: ["37BT"],
                        au: ["37BTAU"],
                        eu: ["94BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "38BTUK",
                      short: "38",
                      long: "38",
                      display: "38",
                      display_with_size_set: "38",
                      display_with_size_system: "UK 38",
                      display_with_system_and_set: "UK 38",
                      equivalent_sizes: {
                        us: ["38BT"],
                        au: ["38BTUK"],
                        eu: ["96.5BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "39BTUK",
                      short: "39",
                      long: "39",
                      display: "39",
                      display_with_size_set: "39",
                      display_with_size_system: "UK 39",
                      display_with_system_and_set: "UK 39",
                      equivalent_sizes: {
                        us: ["39BT"],
                        au: ["39BTUK"],
                        eu: ["99.1BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "40BTUK",
                      short: "40",
                      long: "40",
                      display: "40",
                      display_with_size_set: "40",
                      display_with_size_system: "UK 40",
                      display_with_system_and_set: "UK 40",
                      equivalent_sizes: {
                        us: ["40BT"],
                        au: ["40BTUK"],
                        eu: ["101.6BTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "41UK",
                      short: "41",
                      long: "41",
                      display: "41",
                      display_with_size_set: "41",
                      display_with_size_system: "UK 41",
                      display_with_system_and_set: "UK 41",
                      equivalent_sizes: {
                        us: ["41"],
                        au: ["41UK"],
                        eu: ["104.1EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "42UK",
                      short: "42",
                      long: "42",
                      display: "42",
                      display_with_size_set: "42",
                      display_with_size_system: "UK 42",
                      display_with_system_and_set: "UK 42",
                      equivalent_sizes: {
                        us: ["42"],
                        au: ["42AU"],
                        eu: ["106.7EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "43UK",
                      short: "43",
                      long: "43",
                      display: "43",
                      display_with_size_set: "43",
                      display_with_size_system: "UK 43",
                      display_with_system_and_set: "UK 43",
                      equivalent_sizes: {
                        us: ["43"],
                        au: ["43AU"],
                        eu: ["109.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "44UK",
                      short: "44",
                      long: "44",
                      display: "44",
                      display_with_size_set: "44",
                      display_with_size_system: "UK 44",
                      display_with_system_and_set: "UK 44",
                      equivalent_sizes: {
                        us: ["44"],
                        au: ["44AU"],
                        eu: ["111.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "46UK",
                      short: "46",
                      long: "46",
                      display: "46",
                      display_with_size_set: "46",
                      display_with_size_system: "UK 46",
                      display_with_system_and_set: "UK 46",
                      equivalent_sizes: {
                        us: ["46"],
                        au: ["46AU"],
                        eu: ["116.8EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "48UK",
                      short: "48",
                      long: "48",
                      display: "48",
                      display_with_size_set: "48",
                      display_with_size_system: "UK 48",
                      display_with_system_and_set: "UK 48",
                      equivalent_sizes: {
                        us: ["48"],
                        au: ["48AU"],
                        eu: ["121.9EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "50UK",
                      short: "50",
                      long: "50",
                      display: "50",
                      display_with_size_set: "50",
                      display_with_size_system: "UK 50",
                      display_with_system_and_set: "UK 50",
                      equivalent_sizes: {
                        us: ["50"],
                        au: ["50AU"],
                        eu: ["127.0EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "52UK",
                      short: "52",
                      long: "52",
                      display: "52",
                      display_with_size_set: "52",
                      display_with_size_system: "UK 52",
                      display_with_system_and_set: "UK 52",
                      equivalent_sizes: {
                        us: ["52"],
                        au: ["52AU"],
                        eu: ["132.1EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "54UK",
                      short: "54",
                      long: "54",
                      display: "54",
                      display_with_size_set: "54",
                      display_with_size_system: "UK 54",
                      display_with_system_and_set: "UK 54",
                      equivalent_sizes: {
                        us: ["54"],
                        au: ["54AU"],
                        eu: ["137.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "56UK",
                      short: "56",
                      long: "56",
                      display: "56",
                      display_with_size_set: "56",
                      display_with_size_system: "UK 56",
                      display_with_system_and_set: "UK 56",
                      equivalent_sizes: {
                        us: ["56"],
                        au: ["56AU"],
                        eu: ["142.2EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "58UK",
                      short: "58",
                      long: "58",
                      display: "58",
                      display_with_size_set: "58",
                      display_with_size_system: "UK 58",
                      display_with_system_and_set: "UK 58",
                      equivalent_sizes: {
                        us: ["58"],
                        au: ["58AU"],
                        eu: ["147.3EU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "60UK",
                      short: "60",
                      long: "60",
                      display: "60",
                      display_with_size_set: "60",
                      display_with_size_system: "UK 60",
                      display_with_system_and_set: "UK 60",
                      equivalent_sizes: {
                        us: ["60"],
                        au: ["60AU"],
                        eu: ["152.4EU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "men_lederhosen",
          aliases: [],
          slug: "Lederhosen",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
        },
        {
          id: "b0b476dd402403bf28a2606b",
          display: "Nehru Jackets",
          type: "c",
          size_sets: [
            {
              id: "b49ecb3e402403cd9fcf606c",
              name: "Standard",
              tags: ["standard"],
              category_id: "b0b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["34-36", "28-30"],
                  ["36-38", "30-32"],
                  ["38-40", "32-34"],
                  ["40-42", "34-36"],
                  ["42-44", "36-38"],
                  ["44-46", "38-40"],
                  ["46-48", "40-42"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["86.4-91.4", "71.1-76.2"],
                  ["91.4-96.5", "76.2-81.3"],
                  ["96.5-101.6", "81.3-86.4"],
                  ["101.6-106.7", "86.4-91.4"],
                  ["106.7-111.8", "91.4-96.5"],
                  ["111.8-116.9", "96.5-101.6"],
                  ["116.9-121.9", "101.6-106.7"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "d279bbb5aabb08aed90362fb",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "b0b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["46-48", "40-42"],
                  ["49-51", "43-45"],
                  ["52-54", "46-48"],
                  ["55-57", "49-51"],
                  ["58-60", "52-54"],
                  ["42-44", "36-39"],
                  ["46-48", "40-44"],
                  ["50-52", "45-48.5"],
                  ["54-56", "49-52"],
                  ["56-58", "52-55"],
                  ["58-60", "56-60"],
                  ["62-64", "62-64"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["116.8-121.9", "101.6-106.6"],
                  ["124.4-129.5", "109.2-114.3"],
                  ["132.1-137.2.6", "116.8-121.9"],
                  ["139.7-144.8", "124.4-129.5"],
                  ["147.3-152.4", "132-137.1"],
                  ["106.7-111.7", "91.4-99"],
                  ["116.8-121.9", "101.6-111.7"],
                  ["127-132", "114.3-123.1"],
                  ["137.1-142.2", "124.4-132"],
                  ["142.2-147.3", "132-139.7"],
                  ["147.3-152.4", "142.2-152.4"],
                  ["157.4-162.5", "157.4-162.5"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["3XL", "3XL", "3XL", "3XL"],
                  ["4XL", "4XL", "4XL", "4XL"],
                  ["5XL", "5XL", "5XL", "5XL"],
                  ["6XL", "6XL", "6XL", "6XL"],
                  ["LT", "LT", "LT", "LT"],
                  ["XLT", "XLT", "XLT", "XLT"],
                  ["2XLT", "2XLT", "2XLT", "2XLT"],
                  ["3XLT", "3XLT", "3XLT", "3XLT"],
                  ["4XLT", "4XLT", "4XLT", "4XLT"],
                  ["5XLT", "5XLT", "5XLT", "5XLT"],
                  ["6XLT", "6XLT", "6XLT", "6XLT"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["3XL", "3XL", "3XL", "3XL"],
                ["4XL", "4XL", "4XL", "4XL"],
                ["5XL", "5XL", "5XL", "5XL"],
                ["6XL", "6XL", "6XL", "6XL"],
                ["LT", "LT", "LT", "LT"],
                ["XLT", "XLT", "XLT", "XLT"],
                ["2XLT", "2XLT", "2XLT", "2XLT"],
                ["3XLT", "3XLT", "3XLT", "3XLT"],
                ["4XLT", "4XLT", "4XLT", "4XLT"],
                ["5XLT", "5XLT", "5XLT", "5XLT"],
                ["6XLT", "6XLT", "6XLT", "6XLT"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XL",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "US 3XL",
                      display_with_system_and_set: "US 3XL",
                      equivalent_sizes: {
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XL",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "US 4XL",
                      display_with_system_and_set: "US 4XL",
                      equivalent_sizes: {
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XL",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "US 5XL",
                      display_with_system_and_set: "US 5XL",
                      equivalent_sizes: {
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XL",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "US 6XL",
                      display_with_system_and_set: "US 6XL",
                      equivalent_sizes: {
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LT",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "US LT",
                      display_with_system_and_set: "US LT",
                      equivalent_sizes: {
                        au: ["LTAU"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLT",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "US XLT",
                      display_with_system_and_set: "US XLT",
                      equivalent_sizes: {
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XLT",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "US 2XLT",
                      display_with_system_and_set: "US 2XLT",
                      equivalent_sizes: {
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XLT",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "US 3XLT",
                      display_with_system_and_set: "US 3XLT",
                      equivalent_sizes: {
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XLT",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "US 4XLT",
                      display_with_system_and_set: "US 4XLT",
                      equivalent_sizes: {
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XLT",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "US 5XLT",
                      display_with_system_and_set: "US 5XLT",
                      equivalent_sizes: {
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XLT",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "US 6XLT",
                      display_with_system_and_set: "US 6XLT",
                      equivalent_sizes: {
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLAU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "AU 3XL",
                      display_with_system_and_set: "AU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLAU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "AU 4XL",
                      display_with_system_and_set: "AU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLAU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "AU 5XL",
                      display_with_system_and_set: "AU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLAU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "AU 6XL",
                      display_with_system_and_set: "AU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LTAU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "AU LT",
                      display_with_system_and_set: "AU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLTAU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "AU XLT",
                      display_with_system_and_set: "AU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XLTAU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "AU 2XLT",
                      display_with_system_and_set: "AU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLTAU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "AU 3XLT",
                      display_with_system_and_set: "AU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLTAU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "AU 4XLT",
                      display_with_system_and_set: "AU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLTAU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "AU 5XLT",
                      display_with_system_and_set: "AU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLTAU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "AU 6XLT",
                      display_with_system_and_set: "AU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLEU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "EU 3XL",
                      display_with_system_and_set: "EU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLEU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "EU 4XL",
                      display_with_system_and_set: "EU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLEU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "EU 5XL",
                      display_with_system_and_set: "EU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLEU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "EU 6XL",
                      display_with_system_and_set: "EU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LTEU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "EU LT",
                      display_with_system_and_set: "EU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        uk: ["LTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLTEU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "EU XLT",
                      display_with_system_and_set: "EU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XLTEU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "EU 2XLT",
                      display_with_system_and_set: "EU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLTEU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "EU 3XLT",
                      display_with_system_and_set: "EU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLTEU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "EU 4XLT",
                      display_with_system_and_set: "EU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLTEU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "EU 5XLT",
                      display_with_system_and_set: "EU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLTEU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "EU 6XLT",
                      display_with_system_and_set: "EU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLUK",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "UK 3XL",
                      display_with_system_and_set: "UK 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLUK",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "UK 4XL",
                      display_with_system_and_set: "UK 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLUK",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "UK 5XL",
                      display_with_system_and_set: "UK 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLUK",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "UK 6XL",
                      display_with_system_and_set: "UK 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LTUK",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "UK LT",
                      display_with_system_and_set: "UK LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        eu: ["LTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLTUK",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "UK XLT",
                      display_with_system_and_set: "UK XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XLTUK",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "UK 2XLT",
                      display_with_system_and_set: "UK 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLTUK",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "UK 3XLT",
                      display_with_system_and_set: "UK 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLTUK",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "UK 4XLT",
                      display_with_system_and_set: "UK 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLTUK",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "UK 5XLT",
                      display_with_system_and_set: "UK 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLTUK",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "UK 6XLT",
                      display_with_system_and_set: "UK 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "men_nehru_jackets",
          aliases: [],
          slug: "Nehru_Jackets",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
        },
        {
          id: "bbb476dd402403bf28a2606b",
          display: "Ponchos & Serapes",
          type: "c",
          size_sets: [
            {
              id: "bc9ecb3e402403cd9fcf606c",
              name: "Standard",
              tags: ["standard"],
              category_id: "bbb476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["34-36", "28-30"],
                  ["36-38", "30-32"],
                  ["38-40", "32-34"],
                  ["40-42", "34-36"],
                  ["42-44", "36-38"],
                  ["44-46", "38-40"],
                  ["46-48", "40-42"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["86.4-91.4", "71.1-76.2"],
                  ["91.4-96.5", "76.2-81.3"],
                  ["96.5-101.6", "81.3-86.4"],
                  ["101.6-106.7", "86.4-91.4"],
                  ["106.7-111.8", "91.4-96.5"],
                  ["111.8-116.9", "96.5-101.6"],
                  ["116.9-121.9", "101.6-106.7"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "dc79bbb6aabb08aed90362fb",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "bbb476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["46-48", "40-42"],
                  ["49-51", "43-45"],
                  ["52-54", "46-48"],
                  ["55-57", "49-51"],
                  ["58-60", "52-54"],
                  ["42-44", "36-39"],
                  ["46-48", "40-44"],
                  ["50-52", "45-48.5"],
                  ["54-56", "49-52"],
                  ["56-58", "52-55"],
                  ["58-60", "56-60"],
                  ["62-64", "62-64"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["116.8-121.9", "101.6-106.6"],
                  ["124.4-129.5", "109.2-114.3"],
                  ["132.1-137.2.6", "116.8-121.9"],
                  ["139.7-144.8", "124.4-129.5"],
                  ["147.3-152.4", "132-137.1"],
                  ["106.7-111.7", "91.4-99"],
                  ["116.8-121.9", "101.6-111.7"],
                  ["127-132", "114.3-123.1"],
                  ["137.1-142.2", "124.4-132"],
                  ["142.2-147.3", "132-139.7"],
                  ["147.3-152.4", "142.2-152.4"],
                  ["157.4-162.5", "157.4-162.5"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["3XL", "3XL", "3XL", "3XL"],
                  ["4XL", "4XL", "4XL", "4XL"],
                  ["5XL", "5XL", "5XL", "5XL"],
                  ["6XL", "6XL", "6XL", "6XL"],
                  ["LT", "LT", "LT", "LT"],
                  ["XLT", "XLT", "XLT", "XLT"],
                  ["2XLT", "2XLT", "2XLT", "2XLT"],
                  ["3XLT", "3XLT", "3XLT", "3XLT"],
                  ["4XLT", "4XLT", "4XLT", "4XLT"],
                  ["5XLT", "5XLT", "5XLT", "5XLT"],
                  ["6XLT", "6XLT", "6XLT", "6XLT"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["3XL", "3XL", "3XL", "3XL"],
                ["4XL", "4XL", "4XL", "4XL"],
                ["5XL", "5XL", "5XL", "5XL"],
                ["6XL", "6XL", "6XL", "6XL"],
                ["LT", "LT", "LT", "LT"],
                ["XLT", "XLT", "XLT", "XLT"],
                ["2XLT", "2XLT", "2XLT", "2XLT"],
                ["3XLT", "3XLT", "3XLT", "3XLT"],
                ["4XLT", "4XLT", "4XLT", "4XLT"],
                ["5XLT", "5XLT", "5XLT", "5XLT"],
                ["6XLT", "6XLT", "6XLT", "6XLT"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XL",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "US 3XL",
                      display_with_system_and_set: "US 3XL",
                      equivalent_sizes: {
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XL",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "US 4XL",
                      display_with_system_and_set: "US 4XL",
                      equivalent_sizes: {
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XL",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "US 5XL",
                      display_with_system_and_set: "US 5XL",
                      equivalent_sizes: {
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XL",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "US 6XL",
                      display_with_system_and_set: "US 6XL",
                      equivalent_sizes: {
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LT",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "US LT",
                      display_with_system_and_set: "US LT",
                      equivalent_sizes: {
                        au: ["LTAU"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLT",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "US XLT",
                      display_with_system_and_set: "US XLT",
                      equivalent_sizes: {
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XLT",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "US 2XLT",
                      display_with_system_and_set: "US 2XLT",
                      equivalent_sizes: {
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XLT",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "US 3XLT",
                      display_with_system_and_set: "US 3XLT",
                      equivalent_sizes: {
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XLT",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "US 4XLT",
                      display_with_system_and_set: "US 4XLT",
                      equivalent_sizes: {
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XLT",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "US 5XLT",
                      display_with_system_and_set: "US 5XLT",
                      equivalent_sizes: {
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XLT",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "US 6XLT",
                      display_with_system_and_set: "US 6XLT",
                      equivalent_sizes: {
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLAU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "AU 3XL",
                      display_with_system_and_set: "AU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLAU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "AU 4XL",
                      display_with_system_and_set: "AU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLAU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "AU 5XL",
                      display_with_system_and_set: "AU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLAU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "AU 6XL",
                      display_with_system_and_set: "AU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LTAU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "AU LT",
                      display_with_system_and_set: "AU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLTAU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "AU XLT",
                      display_with_system_and_set: "AU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XLTAU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "AU 2XLT",
                      display_with_system_and_set: "AU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLTAU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "AU 3XLT",
                      display_with_system_and_set: "AU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLTAU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "AU 4XLT",
                      display_with_system_and_set: "AU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLTAU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "AU 5XLT",
                      display_with_system_and_set: "AU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLTAU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "AU 6XLT",
                      display_with_system_and_set: "AU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLEU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "EU 3XL",
                      display_with_system_and_set: "EU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLEU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "EU 4XL",
                      display_with_system_and_set: "EU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLEU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "EU 5XL",
                      display_with_system_and_set: "EU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLEU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "EU 6XL",
                      display_with_system_and_set: "EU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LTEU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "EU LT",
                      display_with_system_and_set: "EU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        uk: ["LTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLTEU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "EU XLT",
                      display_with_system_and_set: "EU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XLTEU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "EU 2XLT",
                      display_with_system_and_set: "EU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLTEU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "EU 3XLT",
                      display_with_system_and_set: "EU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLTEU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "EU 4XLT",
                      display_with_system_and_set: "EU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLTEU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "EU 5XLT",
                      display_with_system_and_set: "EU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLTEU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "EU 6XLT",
                      display_with_system_and_set: "EU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLUK",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "UK 3XL",
                      display_with_system_and_set: "UK 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLUK",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "UK 4XL",
                      display_with_system_and_set: "UK 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLUK",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "UK 5XL",
                      display_with_system_and_set: "UK 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLUK",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "UK 6XL",
                      display_with_system_and_set: "UK 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LTUK",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "UK LT",
                      display_with_system_and_set: "UK LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        eu: ["LTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLTUK",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "UK XLT",
                      display_with_system_and_set: "UK XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XLTUK",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "UK 2XLT",
                      display_with_system_and_set: "UK 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLTUK",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "UK 3XLT",
                      display_with_system_and_set: "UK 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLTUK",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "UK 4XLT",
                      display_with_system_and_set: "UK 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLTUK",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "UK 5XLT",
                      display_with_system_and_set: "UK 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLTUK",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "UK 6XLT",
                      display_with_system_and_set: "UK 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "men_ponchos_serapes",
          aliases: [],
          slug: "Ponchos_&_Serapes",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
        },
        {
          id: "b1b476dd402403bf28a2606b",
          display: "Sherwanis",
          type: "c",
          size_sets: [
            {
              id: "b59ecb3e402403cd9fcf606c",
              name: "Standard",
              tags: ["standard"],
              category_id: "b1b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["34-36", "28-30"],
                  ["36-38", "30-32"],
                  ["38-40", "32-34"],
                  ["40-42", "34-36"],
                  ["42-44", "36-38"],
                  ["44-46", "38-40"],
                  ["46-48", "40-42"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["86.4-91.4", "71.1-76.2"],
                  ["91.4-96.5", "76.2-81.3"],
                  ["96.5-101.6", "81.3-86.4"],
                  ["101.6-106.7", "86.4-91.4"],
                  ["106.7-111.8", "91.4-96.5"],
                  ["111.8-116.9", "96.5-101.6"],
                  ["116.9-121.9", "101.6-106.7"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XS",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS",
                      equivalent_sizes: {
                        au: ["XSAU"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "S",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S",
                      equivalent_sizes: {
                        au: ["SAU"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "M",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M",
                      equivalent_sizes: {
                        au: ["MAU"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "L",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L",
                      equivalent_sizes: {
                        au: ["LAU"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XL",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL",
                      equivalent_sizes: {
                        au: ["XLAU"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XSAU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        eu: ["XSEU"],
                        uk: ["XSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SAU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S",
                      equivalent_sizes: {
                        us: ["S"],
                        eu: ["SEU"],
                        uk: ["SUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MAU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M",
                      equivalent_sizes: {
                        us: ["M"],
                        eu: ["MEU"],
                        uk: ["MUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LAU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L",
                      equivalent_sizes: {
                        us: ["L"],
                        eu: ["LEU"],
                        uk: ["LUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLAU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        eu: ["XLEU"],
                        uk: ["XLUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XSEU",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        uk: ["XSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SEU",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        uk: ["SUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MEU",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        uk: ["MUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LEU",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        uk: ["LUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLEU",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        uk: ["XLUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XSUK",
                      short: "XS",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS",
                      equivalent_sizes: {
                        us: ["XS"],
                        au: ["XSAU"],
                        eu: ["XSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SUK",
                      short: "S",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S",
                      equivalent_sizes: {
                        us: ["S"],
                        au: ["SAU"],
                        eu: ["SEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MUK",
                      short: "M",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M",
                      equivalent_sizes: {
                        us: ["M"],
                        au: ["MAU"],
                        eu: ["MEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LUK",
                      short: "L",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L",
                      equivalent_sizes: {
                        us: ["L"],
                        au: ["LAU"],
                        eu: ["LEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLUK",
                      short: "XL",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL",
                      equivalent_sizes: {
                        us: ["XL"],
                        au: ["XLAU"],
                        eu: ["XLEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "d379bbb5aabb08aed90362fb",
              name: "Big & Tall",
              tags: ["big_&_tall"],
              category_id: "b1b476dd402403bf28a2606b",
              measurements_chart: {
                inches: [
                  ["Chest ", "Waist "],
                  ["46-48", "40-42"],
                  ["49-51", "43-45"],
                  ["52-54", "46-48"],
                  ["55-57", "49-51"],
                  ["58-60", "52-54"],
                  ["42-44", "36-39"],
                  ["46-48", "40-44"],
                  ["50-52", "45-48.5"],
                  ["54-56", "49-52"],
                  ["56-58", "52-55"],
                  ["58-60", "56-60"],
                  ["62-64", "62-64"],
                ],
                centimeters: [
                  ["Chest ", "Waist "],
                  ["116.8-121.9", "101.6-106.6"],
                  ["124.4-129.5", "109.2-114.3"],
                  ["132.1-137.2.6", "116.8-121.9"],
                  ["139.7-144.8", "124.4-129.5"],
                  ["147.3-152.4", "132-137.1"],
                  ["106.7-111.7", "91.4-99"],
                  ["116.8-121.9", "101.6-111.7"],
                  ["127-132", "114.3-123.1"],
                  ["137.1-142.2", "124.4-132"],
                  ["142.2-147.3", "132-139.7"],
                  ["147.3-152.4", "142.2-152.4"],
                  ["157.4-162.5", "157.4-162.5"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["XXL", "XXL", "XXL", "XXL"],
                  ["3XL", "3XL", "3XL", "3XL"],
                  ["4XL", "4XL", "4XL", "4XL"],
                  ["5XL", "5XL", "5XL", "5XL"],
                  ["6XL", "6XL", "6XL", "6XL"],
                  ["LT", "LT", "LT", "LT"],
                  ["XLT", "XLT", "XLT", "XLT"],
                  ["2XLT", "2XLT", "2XLT", "2XLT"],
                  ["3XLT", "3XLT", "3XLT", "3XLT"],
                  ["4XLT", "4XLT", "4XLT", "4XLT"],
                  ["5XLT", "5XLT", "5XLT", "5XLT"],
                  ["6XLT", "6XLT", "6XLT", "6XLT"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["XXL", "XXL", "XXL", "XXL"],
                ["3XL", "3XL", "3XL", "3XL"],
                ["4XL", "4XL", "4XL", "4XL"],
                ["5XL", "5XL", "5XL", "5XL"],
                ["6XL", "6XL", "6XL", "6XL"],
                ["LT", "LT", "LT", "LT"],
                ["XLT", "XLT", "XLT", "XLT"],
                ["2XLT", "2XLT", "2XLT", "2XLT"],
                ["3XLT", "3XLT", "3XLT", "3XLT"],
                ["4XLT", "4XLT", "4XLT", "4XLT"],
                ["5XLT", "5XLT", "5XLT", "5XLT"],
                ["6XLT", "6XLT", "6XLT", "6XLT"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "XXL",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL",
                      equivalent_sizes: {
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XL",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "US 3XL",
                      display_with_system_and_set: "US 3XL",
                      equivalent_sizes: {
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XL",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "US 4XL",
                      display_with_system_and_set: "US 4XL",
                      equivalent_sizes: {
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XL",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "US 5XL",
                      display_with_system_and_set: "US 5XL",
                      equivalent_sizes: {
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XL",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "US 6XL",
                      display_with_system_and_set: "US 6XL",
                      equivalent_sizes: {
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LT",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "US LT",
                      display_with_system_and_set: "US LT",
                      equivalent_sizes: {
                        au: ["LTAU"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLT",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "US XLT",
                      display_with_system_and_set: "US XLT",
                      equivalent_sizes: {
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2XLT",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "US 2XLT",
                      display_with_system_and_set: "US 2XLT",
                      equivalent_sizes: {
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3XLT",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "US 3XLT",
                      display_with_system_and_set: "US 3XLT",
                      equivalent_sizes: {
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4XLT",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "US 4XLT",
                      display_with_system_and_set: "US 4XLT",
                      equivalent_sizes: {
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5XLT",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "US 5XLT",
                      display_with_system_and_set: "US 5XLT",
                      equivalent_sizes: {
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XLT",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "US 6XLT",
                      display_with_system_and_set: "US 6XLT",
                      equivalent_sizes: {
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "XXLAU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        eu: ["XXLEU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLAU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "AU 3XL",
                      display_with_system_and_set: "AU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        eu: ["3XLEU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLAU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "AU 4XL",
                      display_with_system_and_set: "AU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        eu: ["4XLEU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLAU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "AU 5XL",
                      display_with_system_and_set: "AU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        eu: ["5XLEU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLAU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "AU 6XL",
                      display_with_system_and_set: "AU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        eu: ["6XLEU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LTAU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "AU LT",
                      display_with_system_and_set: "AU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        eu: ["LTEU"],
                        uk: ["LTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLTAU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "AU XLT",
                      display_with_system_and_set: "AU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        eu: ["XLTEU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2XLTAU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "AU 2XLT",
                      display_with_system_and_set: "AU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        eu: ["2XLTEU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3XLTAU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "AU 3XLT",
                      display_with_system_and_set: "AU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        eu: ["3XLTEU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4XLTAU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "AU 4XLT",
                      display_with_system_and_set: "AU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        eu: ["4XLTEU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5XLTAU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "AU 5XLT",
                      display_with_system_and_set: "AU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        eu: ["5XLTEU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6XLTAU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "AU 6XLT",
                      display_with_system_and_set: "AU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        eu: ["6XLTEU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "XXLEU",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        uk: ["XXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLEU",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "EU 3XL",
                      display_with_system_and_set: "EU 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        uk: ["3XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLEU",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "EU 4XL",
                      display_with_system_and_set: "EU 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        uk: ["4XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLEU",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "EU 5XL",
                      display_with_system_and_set: "EU 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        uk: ["5XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLEU",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "EU 6XL",
                      display_with_system_and_set: "EU 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        uk: ["6XLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LTEU",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "EU LT",
                      display_with_system_and_set: "EU LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        uk: ["LTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLTEU",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "EU XLT",
                      display_with_system_and_set: "EU XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        uk: ["XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "2XLTEU",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "EU 2XLT",
                      display_with_system_and_set: "EU 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        uk: ["2XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3XLTEU",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "EU 3XLT",
                      display_with_system_and_set: "EU 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        uk: ["3XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4XLTEU",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "EU 4XLT",
                      display_with_system_and_set: "EU 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        uk: ["4XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5XLTEU",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "EU 5XLT",
                      display_with_system_and_set: "EU 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        uk: ["5XLTUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XLTEU",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "EU 6XLT",
                      display_with_system_and_set: "EU 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        uk: ["6XLTUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "XXLUK",
                      short: "XXL",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL",
                      equivalent_sizes: {
                        us: ["XXL"],
                        au: ["XXLAU"],
                        eu: ["XXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLUK",
                      short: "3XL",
                      long: "3XL",
                      display: "3XL",
                      display_with_size_set: "3XL",
                      display_with_size_system: "UK 3XL",
                      display_with_system_and_set: "UK 3XL",
                      equivalent_sizes: {
                        us: ["3XL"],
                        au: ["3XLAU"],
                        eu: ["3XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLUK",
                      short: "4XL",
                      long: "4XL",
                      display: "4XL",
                      display_with_size_set: "4XL",
                      display_with_size_system: "UK 4XL",
                      display_with_system_and_set: "UK 4XL",
                      equivalent_sizes: {
                        us: ["4XL"],
                        au: ["4XLAU"],
                        eu: ["4XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLUK",
                      short: "5XL",
                      long: "5XL",
                      display: "5XL",
                      display_with_size_set: "5XL",
                      display_with_size_system: "UK 5XL",
                      display_with_system_and_set: "UK 5XL",
                      equivalent_sizes: {
                        us: ["5XL"],
                        au: ["5XLAU"],
                        eu: ["5XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLUK",
                      short: "6XL",
                      long: "6XL",
                      display: "6XL",
                      display_with_size_set: "6XL",
                      display_with_size_system: "UK 6XL",
                      display_with_system_and_set: "UK 6XL",
                      equivalent_sizes: {
                        us: ["6XL"],
                        au: ["6XLAU"],
                        eu: ["6XLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LTUK",
                      short: "LT",
                      long: "LT",
                      display: "LT",
                      display_with_size_set: "LT",
                      display_with_size_system: "UK LT",
                      display_with_system_and_set: "UK LT",
                      equivalent_sizes: {
                        us: ["LT"],
                        au: ["LTAU"],
                        eu: ["LTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLTUK",
                      short: "XLT",
                      long: "XLT",
                      display: "XLT",
                      display_with_size_set: "XLT",
                      display_with_size_system: "UK XLT",
                      display_with_system_and_set: "UK XLT",
                      equivalent_sizes: {
                        us: ["XLT"],
                        au: ["XLTAU"],
                        eu: ["XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2XLTUK",
                      short: "2XLT",
                      long: "2XLT",
                      display: "2XLT",
                      display_with_size_set: "2XLT",
                      display_with_size_system: "UK 2XLT",
                      display_with_system_and_set: "UK 2XLT",
                      equivalent_sizes: {
                        us: ["2XLT"],
                        au: ["2XLTAU"],
                        eu: ["2XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3XLTUK",
                      short: "3XLT",
                      long: "3XLT",
                      display: "3XLT",
                      display_with_size_set: "3XLT",
                      display_with_size_system: "UK 3XLT",
                      display_with_system_and_set: "UK 3XLT",
                      equivalent_sizes: {
                        us: ["3XLT"],
                        au: ["3XLTAU"],
                        eu: ["3XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4XLTUK",
                      short: "4XLT",
                      long: "4XLT",
                      display: "4XLT",
                      display_with_size_set: "4XLT",
                      display_with_size_system: "UK 4XLT",
                      display_with_system_and_set: "UK 4XLT",
                      equivalent_sizes: {
                        us: ["4XLT"],
                        au: ["4XLTAU"],
                        eu: ["4XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5XLTUK",
                      short: "5XLT",
                      long: "5XLT",
                      display: "5XLT",
                      display_with_size_set: "5XLT",
                      display_with_size_system: "UK 5XLT",
                      display_with_system_and_set: "UK 5XLT",
                      equivalent_sizes: {
                        us: ["5XLT"],
                        au: ["5XLTAU"],
                        eu: ["5XLTEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6XLTUK",
                      short: "6XLT",
                      long: "6XLT",
                      display: "6XLT",
                      display_with_size_set: "6XLT",
                      display_with_size_system: "UK 6XLT",
                      display_with_system_and_set: "UK 6XLT",
                      equivalent_sizes: {
                        us: ["6XLT"],
                        au: ["6XLTAU"],
                        eu: ["6XLTEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "men_sherwanis",
          aliases: [],
          slug: "Sherwanis",
          alias_slugs: [],
          parent_id: "01008c10d97b4e1245005764",
        },
      ],
    },
    {
      id: "20008c10d97b4e1245005764",
      display: "Kids",
      type: "d",
      message_id: "kids",
      aliases: [],
      size_sets: [],
      sizes: [],
      slug: "Kids",
      alias_slugs: [],
      parent_id: "55b966335632a0146f000001",
      categories: [
        {
          id: "29008c10d97b4e1245005764",
          display: "Shoes",
          size_sets: [
            {
              id: "09009fc1d97b4e911e005769",
              name: "Baby",
              tags: ["baby"],
              category_id: "29008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["in."],
                  ["3.1-3.3"],
                  ["3.5-3.6"],
                  ["3.5-4.0"],
                  ["3.75-4.0"],
                  ["4.1-4.25"],
                  ["4.1-4.6"],
                  ["4.5-5.0"],
                  ["4.75-5.0"],
                  ["5.1-5.25"],
                  ["5.1-5.25"],
                  ["5.25-5.5"],
                ],
                centimeters: [
                  ["cm."],
                  ["7.9-8.4"],
                  ["8.9-9.1"],
                  ["8.9-9.1"],
                  ["9.5-10.2"],
                  ["10.4-10.8"],
                  ["10.4-11.7"],
                  ["11.4-12.7"],
                  ["12.1-12.7"],
                  ["13.0-13.3"],
                  ["13.0-13.3"],
                  ["14.0"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["0,0.5", "0,0.5", "16", "0.5"],
                  ["1,1.5", "1", "16", "1"],
                  ["2,2.5", "1,1.5", "17,18", "1.5"],
                  ["2,2.5", "1,1.5", "17,18", "2"],
                  ["3,3.5", "2,2.5", "18,19", "2.5"],
                  ["3,3.5,4,4.5", "2,2.5,3,3.5", "18,19,20", "3"],
                  ["4,4.5,5,5.5", "3,3.5,4,4.5", "19,20,21", "3.5"],
                  ["5,5.5", "4,4.5", "20,21", "4.5"],
                  ["6,6.5", "5,5.5", "22", "5"],
                  ["6,6.5", "5,5.5", "22", "5.5"],
                  ["7", "6", "23", "6"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["0", "0,0.5", "16", "0.5"],
                ["0.5", "0,0.5", "16", "0.5"],
                ["1", "1", "16,17", "1,1.5"],
                ["1.5", "1", "16,17", "1,1.5"],
                ["2", "1,1.5", "17,18", "1.5,2"],
                ["2.5", "1,1.5", "17,18", "1.5,2"],
                ["3", "2,2.5", "18,19", "2.5,3"],
                ["3.5", "2,2.5", "18,19", "2.5,3"],
                ["4", "3,3.5", "19,20", "3,3.5"],
                ["4.5", "3,3.5", "19,20", "3,3.5"],
                ["5", "4,4.5", "20,21", "3.5,4.5"],
                ["5.5", "4,4.5", "20,21", "3.5,4.5"],
                ["6", "5,5.5", "22", "5,5.5"],
                ["6.5", "5,5.5", "22", "5,5.5"],
                ["7", "6", "23", "6"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "0BB",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "US 0",
                      display_with_system_and_set: "US 0",
                      equivalent_sizes: {
                        au: ["0BBAU", "0.5BBAU"],
                        eu: ["16BBEU"],
                        uk: ["0.5BBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0.5BB",
                      short: "0.5",
                      long: "0.5",
                      display: "0.5",
                      display_with_size_set: "0.5",
                      display_with_size_system: "US 0.5",
                      display_with_system_and_set: "US 0.5",
                      equivalent_sizes: {
                        au: ["0BBAU", "0.5BBAU"],
                        eu: ["16BBEU"],
                        uk: ["0.5BBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1BB",
                      short: "1",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1",
                      equivalent_sizes: {
                        au: ["1BBAU"],
                        eu: ["16BBEU", "17BBEU"],
                        uk: ["1BBUK", "1.5BBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1.5BB",
                      short: "1.5",
                      long: "1.5",
                      display: "1.5",
                      display_with_size_set: "1.5",
                      display_with_size_system: "US 1.5",
                      display_with_system_and_set: "US 1.5",
                      equivalent_sizes: {
                        au: ["1BBAU"],
                        eu: ["16BBEU", "17BBEU"],
                        uk: ["1BBUK", "1.5BBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2BB",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2",
                      equivalent_sizes: {
                        au: ["1BBAU", "1.5BBAU"],
                        eu: ["17BBEU", "18BBEU"],
                        uk: ["1.5BBUK", "2BBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2.5BB",
                      short: "2.5",
                      long: "2.5",
                      display: "2.5",
                      display_with_size_set: "2.5",
                      display_with_size_system: "US 2.5",
                      display_with_system_and_set: "US 2.5",
                      equivalent_sizes: {
                        au: ["1BBAU", "1.5BBAU"],
                        eu: ["17BBEU", "18BBEU"],
                        uk: ["1.5BBUK", "2BBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3BB",
                      short: "3",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3",
                      equivalent_sizes: {
                        au: ["2BBAU", "2.5BBAU"],
                        eu: ["18BBEU", "19BBEU"],
                        uk: ["2.5BBUK", "3BBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3.5BB",
                      short: "3.5",
                      long: "3.5",
                      display: "3.5",
                      display_with_size_set: "3.5",
                      display_with_size_system: "US 3.5",
                      display_with_system_and_set: "US 3.5",
                      equivalent_sizes: {
                        au: ["2BBAU", "2.5BBAU"],
                        eu: ["18BBEU", "19BBEU"],
                        uk: ["2.5BBUK", "3BBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4BB",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4",
                      equivalent_sizes: {
                        au: ["3BBAU", "3.5BBAU"],
                        eu: ["19BBEU", "20BBEU"],
                        uk: ["3BBUK", "3.5BBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4.5BB",
                      short: "4.5",
                      long: "4.5",
                      display: "4.5",
                      display_with_size_set: "4.5",
                      display_with_size_system: "US 4.5",
                      display_with_system_and_set: "US 4.5",
                      equivalent_sizes: {
                        au: ["3BBAU", "3.5BBAU"],
                        eu: ["19BBEU", "20BBEU"],
                        uk: ["3BBUK", "3.5BBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5BB",
                      short: "5",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5",
                      equivalent_sizes: {
                        au: ["4BBAU", "4.5BBAU"],
                        eu: ["20BBEU", "21BBEU"],
                        uk: ["3.5BBUK", "4.5BBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5.5BB",
                      short: "5.5",
                      long: "5.5",
                      display: "5.5",
                      display_with_size_set: "5.5",
                      display_with_size_system: "US 5.5",
                      display_with_system_and_set: "US 5.5",
                      equivalent_sizes: {
                        au: ["4BBAU", "4.5BBAU"],
                        eu: ["20BBEU", "21BBEU"],
                        uk: ["3.5BBUK", "4.5BBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6BB",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6",
                      equivalent_sizes: {
                        au: ["5BBAU", "5.5BBAU"],
                        eu: ["22BBEU"],
                        uk: ["5BBUK", "5.5BBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6.5BB",
                      short: "6.5",
                      long: "6.5",
                      display: "6.5",
                      display_with_size_set: "6.5",
                      display_with_size_system: "US 6.5",
                      display_with_system_and_set: "US 6.5",
                      equivalent_sizes: {
                        au: ["5BBAU", "5.5BBAU"],
                        eu: ["22BBEU"],
                        uk: ["5BBUK", "5.5BBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7BB",
                      short: "7",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7",
                      equivalent_sizes: {
                        au: ["6BBAU"],
                        eu: ["23BBEU"],
                        uk: ["6BBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "0BBAU",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "AU 0",
                      display_with_system_and_set: "AU 0",
                      equivalent_sizes: {
                        us: ["0BB", "0.5BB"],
                        eu: ["16BBEU"],
                        uk: ["0.5BBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0.5BBAU",
                      short: "0.5",
                      long: "0.5",
                      display: "0.5",
                      display_with_size_set: "0.5",
                      display_with_size_system: "AU 0.5",
                      display_with_system_and_set: "AU 0.5",
                      equivalent_sizes: {
                        us: ["0BB", "0.5BB"],
                        eu: ["16BBEU"],
                        uk: ["0.5BBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1BBAU",
                      short: "1",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1",
                      display_with_size_system: "AU 1",
                      display_with_system_and_set: "AU 1",
                      equivalent_sizes: {
                        us: ["1BB", "1.5BB", "2BB", "2.5BB"],
                        eu: ["16BBEU", "17BBEU", "18BBEU"],
                        uk: ["1BBUK", "1.5BBUK", "2BBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1.5BBAU",
                      short: "1.5",
                      long: "1.5",
                      display: "1.5",
                      display_with_size_set: "1.5",
                      display_with_size_system: "AU 1.5",
                      display_with_system_and_set: "AU 1.5",
                      equivalent_sizes: {
                        us: ["2BB", "2.5BB"],
                        eu: ["17BBEU", "18BBEU"],
                        uk: ["1.5BBUK", "2BBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2BBAU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["3BB", "3.5BB"],
                        eu: ["18BBEU", "19BBEU"],
                        uk: ["2.5BBUK", "3BBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2.5BBAU",
                      short: "2.5",
                      long: "2.5",
                      display: "2.5",
                      display_with_size_set: "2.5",
                      display_with_size_system: "AU 2.5",
                      display_with_system_and_set: "AU 2.5",
                      equivalent_sizes: {
                        us: ["3BB", "3.5BB"],
                        eu: ["18BBEU", "19BBEU"],
                        uk: ["2.5BBUK", "3BBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3BBAU",
                      short: "3",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3",
                      display_with_size_system: "AU 3",
                      display_with_system_and_set: "AU 3",
                      equivalent_sizes: {
                        us: ["4BB", "4.5BB"],
                        eu: ["19BBEU", "20BBEU"],
                        uk: ["3BBUK", "3.5BBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3.5BBAU",
                      short: "3.5",
                      long: "3.5",
                      display: "3.5",
                      display_with_size_set: "3.5",
                      display_with_size_system: "AU 3.5",
                      display_with_system_and_set: "AU 3.5",
                      equivalent_sizes: {
                        us: ["4BB", "4.5BB"],
                        eu: ["19BBEU", "20BBEU"],
                        uk: ["3BBUK", "3.5BBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4BBAU",
                      short: "4",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4",
                      equivalent_sizes: {
                        us: ["5BB", "5.5BB"],
                        eu: ["20BBEU", "21BBEU"],
                        uk: ["3.5BBUK", "4.5BBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4.5BBAU",
                      short: "4.5",
                      long: "4.5",
                      display: "4.5",
                      display_with_size_set: "4.5",
                      display_with_size_system: "AU 4.5",
                      display_with_system_and_set: "AU 4.5",
                      equivalent_sizes: {
                        us: ["5BB", "5.5BB"],
                        eu: ["20BBEU", "21BBEU"],
                        uk: ["3.5BBUK", "4.5BBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5BBAU",
                      short: "5",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5",
                      display_with_size_system: "AU 5",
                      display_with_system_and_set: "AU 5",
                      equivalent_sizes: {
                        us: ["6BB", "6.5BB"],
                        eu: ["22BBEU"],
                        uk: ["5BBUK", "5.5BBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5.5BBAU",
                      short: "5.5",
                      long: "5.5",
                      display: "5.5",
                      display_with_size_set: "5.5",
                      display_with_size_system: "AU 5.5",
                      display_with_system_and_set: "AU 5.5",
                      equivalent_sizes: {
                        us: ["6BB", "6.5BB"],
                        eu: ["22BBEU"],
                        uk: ["5BBUK", "5.5BBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6BBAU",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6",
                      equivalent_sizes: {
                        us: ["7BB"],
                        eu: ["23BBEU"],
                        uk: ["6BBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "16BBEU",
                      short: "16",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16",
                      display_with_size_system: "EU 16",
                      display_with_system_and_set: "EU 16",
                      equivalent_sizes: {
                        us: ["0BB", "0.5BB", "1BB", "1.5BB"],
                        au: ["0BBAU", "0.5BBAU", "1BBAU"],
                        uk: ["0.5BBUK", "1BBUK", "1.5BBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "17BBEU",
                      short: "17",
                      long: "17",
                      display: "17",
                      display_with_size_set: "17",
                      display_with_size_system: "EU 17",
                      display_with_system_and_set: "EU 17",
                      equivalent_sizes: {
                        us: ["1BB", "1.5BB", "2BB", "2.5BB"],
                        au: ["1BBAU", "1.5BBAU"],
                        uk: ["1BBUK", "1.5BBUK", "2BBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18BBEU",
                      short: "18",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18",
                      display_with_size_system: "EU 18",
                      display_with_system_and_set: "EU 18",
                      equivalent_sizes: {
                        us: ["2BB", "2.5BB", "3BB", "3.5BB"],
                        au: ["1BBAU", "1.5BBAU", "2BBAU", "2.5BBAU"],
                        uk: ["1.5BBUK", "2BBUK", "2.5BBUK", "3BBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "19BBEU",
                      short: "19",
                      long: "19",
                      display: "19",
                      display_with_size_set: "19",
                      display_with_size_system: "EU 19",
                      display_with_system_and_set: "EU 19",
                      equivalent_sizes: {
                        us: ["3BB", "3.5BB", "4BB", "4.5BB"],
                        au: ["2BBAU", "2.5BBAU", "3BBAU", "3.5BBAU"],
                        uk: ["2.5BBUK", "3BBUK", "3.5BBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "20BBEU",
                      short: "20",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20",
                      display_with_size_system: "EU 20",
                      display_with_system_and_set: "EU 20",
                      equivalent_sizes: {
                        us: ["4BB", "4.5BB", "5BB", "5.5BB"],
                        au: ["3BBAU", "3.5BBAU", "4BBAU", "4.5BBAU"],
                        uk: ["3BBUK", "3.5BBUK", "4.5BBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "21BBEU",
                      short: "21",
                      long: "21",
                      display: "21",
                      display_with_size_set: "21",
                      display_with_size_system: "EU 21",
                      display_with_system_and_set: "EU 21",
                      equivalent_sizes: {
                        us: ["5BB", "5.5BB"],
                        au: ["4BBAU", "4.5BBAU"],
                        uk: ["3.5BBUK", "4.5BBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "22BBEU",
                      short: "22",
                      long: "22",
                      display: "22",
                      display_with_size_set: "22",
                      display_with_size_system: "EU 22",
                      display_with_system_and_set: "EU 22",
                      equivalent_sizes: {
                        us: ["6BB", "6.5BB"],
                        au: ["5BBAU", "5.5BBAU"],
                        uk: ["5BBUK", "5.5BBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "23BBEU",
                      short: "23",
                      long: "23",
                      display: "23",
                      display_with_size_set: "23",
                      display_with_size_system: "EU 23",
                      display_with_system_and_set: "EU 23",
                      equivalent_sizes: {
                        us: ["7BB"],
                        au: ["6BBAU"],
                        uk: ["6BBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "0.5BBUK",
                      short: "0.5",
                      long: "0.5",
                      display: "0.5",
                      display_with_size_set: "0.5",
                      display_with_size_system: "UK 0.5",
                      display_with_system_and_set: "UK 0.5",
                      equivalent_sizes: {
                        us: ["0BB", "0.5BB"],
                        au: ["0BBAU", "0.5BBAU"],
                        eu: ["16BBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1BBUK",
                      short: "1",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1",
                      display_with_size_system: "UK 1",
                      display_with_system_and_set: "UK 1",
                      equivalent_sizes: {
                        us: ["1BB", "1.5BB"],
                        au: ["1BBAU"],
                        eu: ["16BBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1.5BBUK",
                      short: "1.5",
                      long: "1.5",
                      display: "1.5",
                      display_with_size_set: "1.5",
                      display_with_size_system: "UK 1.5",
                      display_with_system_and_set: "UK 1.5",
                      equivalent_sizes: {
                        us: ["2BB", "2.5BB"],
                        au: ["1BBAU", "1.5BBAU"],
                        eu: ["17BBEU", "18BBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2BBUK",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2",
                      equivalent_sizes: {
                        us: ["2BB", "2.5BB"],
                        au: ["1BBAU", "1.5BBAU"],
                        eu: ["17BBEU", "18BBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2.5BBUK",
                      short: "2.5",
                      long: "2.5",
                      display: "2.5",
                      display_with_size_set: "2.5",
                      display_with_size_system: "UK 2.5",
                      display_with_system_and_set: "UK 2.5",
                      equivalent_sizes: {
                        us: ["3BB", "3.5BB"],
                        au: ["2BBAU", "2.5BBAU"],
                        eu: ["18BBEU", "19BBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3BBUK",
                      short: "3",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3",
                      equivalent_sizes: {
                        us: ["3BB", "3.5BB", "4BB", "4.5BB"],
                        au: ["2BBAU", "2.5BBAU", "3BBAU", "3.5BBAU"],
                        eu: ["18BBEU", "19BBEU", "20BBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3.5BBUK",
                      short: "3.5",
                      long: "3.5",
                      display: "3.5",
                      display_with_size_set: "3.5",
                      display_with_size_system: "UK 3.5",
                      display_with_system_and_set: "UK 3.5",
                      equivalent_sizes: {
                        us: ["4BB", "4.5BB", "5BB", "5.5BB"],
                        au: ["3BBAU", "3.5BBAU", "4BBAU", "4.5BBAU"],
                        eu: ["19BBEU", "20BBEU", "21BBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4.5BBUK",
                      short: "4.5",
                      long: "4.5",
                      display: "4.5",
                      display_with_size_set: "4.5",
                      display_with_size_system: "UK 4.5",
                      display_with_system_and_set: "UK 4.5",
                      equivalent_sizes: {
                        us: ["5BB", "5.5BB"],
                        au: ["4BBAU", "4.5BBAU"],
                        eu: ["20BBEU", "21BBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5BBUK",
                      short: "5",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5",
                      display_with_size_system: "UK 5",
                      display_with_system_and_set: "UK 5",
                      equivalent_sizes: {
                        us: ["6BB", "6.5BB"],
                        au: ["5BBAU", "5.5BBAU"],
                        eu: ["22BBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5.5BBUK",
                      short: "5.5",
                      long: "5.5",
                      display: "5.5",
                      display_with_size_set: "5.5",
                      display_with_size_system: "UK 5.5",
                      display_with_system_and_set: "UK 5.5",
                      equivalent_sizes: {
                        us: ["6BB", "6.5BB"],
                        au: ["5BBAU", "5.5BBAU"],
                        eu: ["22BBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6BBUK",
                      short: "6",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6",
                      equivalent_sizes: {
                        us: ["7BB"],
                        au: ["6BBAU"],
                        eu: ["23BBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "0a009fc1d97b4e911e005769",
              name: "Girls",
              tags: ["girls"],
              category_id: "29008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["in."],
                  ["7.75-8.25"],
                  ["8.1-8.6"],
                  ["8.5-8.6"],
                  ["8.75-9.0"],
                  ["8.75-9.0"],
                  ["9.1-9.25"],
                  ["9.1-9.25"],
                  ["9.5-9.6"],
                  ["9.5-9.6"],
                  ["9.75"],
                  ["5.6-5.75"],
                  ["5.6-5.75"],
                  ["6.0-6.1"],
                  ["6.0-6.1"],
                  ["6.25-6.5"],
                  ["6.25-6.5"],
                  ["6.6-6.75"],
                  ["6.6-6.75"],
                  ["7.0-7.1"],
                  ["7.0-7.1"],
                  ["7.25-7.6"],
                  ["7.6"],
                ],
                centimeters: [
                  ["cm."],
                  ["19.7-21.0"],
                  ["20.6-21.0"],
                  ["21.6-21.8"],
                  ["22.2-22.9"],
                  ["22.2-22.9"],
                  ["23.1-23.5"],
                  ["23.1-23.5"],
                  ["24.1-24.4"],
                  ["24.1-24.4"],
                  ["24.8"],
                  ["14.2-14.6"],
                  ["14.2-14.6"],
                  ["15.2-15.5"],
                  ["15.2-15.5"],
                  ["15.9-16.5"],
                  ["15.9-16.5"],
                  ["16.8-17.1"],
                  ["16.8-17.1"],
                  ["17.8-18.0"],
                  ["17.8-18.0"],
                  ["18.4-19.1"],
                  ["19.3"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  [
                    "1 (Little Girl),1.5 (Little Girl),2 (Little Girl),2.5 (Little Girl)",
                    "1 (Little Girl),1.5 (Little Girl),13 (Little Girl),14 (Little Girl)",
                    "33 (Little Girl),34 (Little Girl)",
                    "1 (Little Girl)",
                  ],
                  [
                    "2 (Little Girl),2.5 (Little Girl),3 (Little Girl),3.5 (Big Girl)",
                    "1 (Little Girl),1.5 (Little Girl),2 (Little Girl),2.5 (Big Girl)",
                    "33 (Little Girl),34 (Little Girl),35 (Big Girl)",
                    "1.5 (Little Girl)",
                  ],
                  [
                    "3 (Little Girl),3.5 (Big Girl)",
                    "2 (Little Girl),2.5 (Big Girl)",
                    "34 (Little Girl),35 (Big Girl)",
                    "2.5 (Big Girl)",
                  ],
                  [
                    "4 (Big Girl),4.5 (Big Girl)",
                    "3 (Big Girl),3.5 (Big Girl)",
                    "36 (Big Girl)",
                    "3 (Big Girl)",
                  ],
                  [
                    "4 (Big Girl),4.5 (Big Girl)",
                    "3 (Big Girl),3.5 (Big Girl)",
                    "36 (Big Girl)",
                    "3.5 (Big Girl)",
                  ],
                  [
                    "5 (Big Girl),5.5 (Big Girl)",
                    "4 (Big Girl),4.5 (Big Girl)",
                    "37 (Big Girl)",
                    "4 (Big Girl)",
                  ],
                  [
                    "5 (Big Girl),5.5 (Big Girl)",
                    "4 (Big Girl),4.5 (Big Girl)",
                    "37 (Big Girl)",
                    "4.5 (Big Girl)",
                  ],
                  [
                    "6 (Big Girl),6.5 (Big Girl)",
                    "5 (Big Girl),5.5 (Big Girl)",
                    "38 (Big Girl)",
                    "5 (Big Girl)",
                  ],
                  [
                    "6 (Big Girl),6.5 (Big Girl)",
                    "5 (Big Girl),5.5 (Big Girl)",
                    "38 (Big Girl)",
                    "5.5 (Big Girl)",
                  ],
                  [
                    "7 (Big Girl),7.5 (Toddler Girl),8 (Toddler Girl)",
                    "6 (Big Girl),6.5 (Toddler Girl),7 (Toddler Girl)",
                    "23 (Toddler Girl),24 (Toddler Girl),39 (Big Girl)",
                    "6 (Big Girl)",
                  ],
                  [
                    "7 (Big Girl),7.5 (Toddler Girl),8 (Toddler Girl)",
                    "6 (Big Girl),6.5 (Toddler Girl),7 (Toddler Girl)",
                    "23 (Toddler Girl),24 (Toddler Girl),39 (Big Girl)",
                    "6 (Big Girl)",
                  ],
                  [
                    "7.5 (Toddler Girl),8 (Toddler Girl)",
                    "7 (Toddler Girl)",
                    "23 (Toddler Girl),24 (Toddler Girl)",
                    "7 (Toddler Girl)",
                  ],
                  [
                    "8.5 (Toddler Girl),9 (Toddler Girl)",
                    "7.5 (Toddler Girl),8 (Toddler Girl)",
                    "25 (Toddler Girl)",
                    "7.5 (Toddler Girl)",
                  ],
                  [
                    "8.5 (Toddler Girl),9 (Toddler Girl)",
                    "7.5 (Toddler Girl),8 (Toddler Girl)",
                    "25 (Toddler Girl)",
                    "8 (Toddler Girl)",
                  ],
                  [
                    "9.5 (Toddler Girl),10 (Toddler Girl)",
                    "8.5 (Toddler Girl),9 (Toddler Girl)",
                    "26 (Toddler Girl),27 (Toddler Girl)",
                    "8.5 (Toddler Girl)",
                  ],
                  [
                    "9.5 (Toddler Girl),10 (Toddler Girl)",
                    "8.5 (Toddler Girl),9 (Toddler Girl)",
                    "26 (Toddler Girl),27 (Toddler Girl)",
                    "9 (Toddler Girl)",
                  ],
                  [
                    "10.5 (Toddler Girl),11 (Toddler Girl)",
                    "9.5 (Toddler Girl),10 (Toddler Girl)",
                    "27 (Toddler Girl),28 (Toddler Girl)",
                    "9.5 (Toddler Girl)",
                  ],
                  [
                    "10.5 (Toddler Girl),11 (Toddler Girl)",
                    "9.5 (Toddler Girl),10 (Toddler Girl)",
                    "27 (Toddler Girl),28 (Toddler Girl)",
                    "10 (Toddler Girl)",
                  ],
                  [
                    "11.5 (Toddler Girl),12 (Toddler Girl)",
                    "10.5 (Toddler Girl),11 (Toddler Girl)",
                    "29 (Toddler Girl),30 (Toddler Girl)",
                    "11 (Toddler Girl)",
                  ],
                  [
                    "11.5 (Toddler Girl),12 (Toddler Girl),12.5 (Little Girl),13 (Little Girl)",
                    "10.5 (Toddler Girl),11 (Toddler Girl),11.5 (Little Girl),12 (Little Girl)",
                    "29 (Toddler Girl),30 (Toddler Girl),31 (Little Girl)",
                    "11.5 (Little Girl)",
                  ],
                  [
                    "12.5 (Little Girl),13 (Little Girl)",
                    "11.5 (Little Girl),12 (Little Girl),12.5 (Little Girl)",
                    "30 (Toddler Girl),31 (Little Girl),32 (Little Girl)",
                    "12.5 (Little Girl)",
                  ],
                  [
                    "13.5 (Little Girl)",
                    "12.5 (Little Girl)",
                    "31 (Little Girl),32 (Little Girl)",
                    "13 (Little Girl)",
                  ],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                [
                  "7.5 (Toddler Girl)",
                  "6.5 (Toddler Girl)",
                  "23 (Toddler Girl)",
                  "6 (Big Girl),7 (Toddler Girl)",
                ],
                [
                  "8 (Toddler Girl)",
                  "7 (Toddler Girl)",
                  "24 (Toddler Girl)",
                  "6 (Big Girl),7 (Toddler Girl)",
                ],
                [
                  "8.5 (Toddler Girl)",
                  "7.5 (Toddler Girl)",
                  "25 (Toddler Girl)",
                  "7.5 (Toddler Girl),8 (Toddler Girl)",
                ],
                [
                  "9 (Toddler Girl)",
                  "8 (Toddler Girl)",
                  "25 (Toddler Girl)",
                  "7.5 (Toddler Girl),8 (Toddler Girl)",
                ],
                [
                  "9.5 (Toddler Girl)",
                  "8.5 (Toddler Girl)",
                  "26 (Toddler Girl)",
                  "8.5 (Toddler Girl),9 (Toddler Girl)",
                ],
                [
                  "10 (Toddler Girl)",
                  "9 (Toddler Girl)",
                  "27 (Toddler Girl)",
                  "8.5 (Toddler Girl),9 (Toddler Girl)",
                ],
                [
                  "10.5 (Toddler Girl)",
                  "9.5 (Toddler Girl)",
                  "27 (Toddler Girl)",
                  "9.5 (Toddler Girl),10 (Toddler Girl)",
                ],
                [
                  "11 (Toddler Girl)",
                  "10 (Toddler Girl)",
                  "28 (Toddler Girl)",
                  "9.5 (Toddler Girl),10 (Toddler Girl)",
                ],
                [
                  "11.5 (Toddler Girl)",
                  "10.5 (Toddler Girl)",
                  "29 (Toddler Girl)",
                  "11 (Toddler Girl),11.5 (Little Girl)",
                ],
                [
                  "12 (Toddler Girl)",
                  "11 (Toddler Girl)",
                  "30 (Toddler Girl)",
                  "11 (Toddler Girl),11.5 (Little Girl)",
                ],
                [
                  "12.5 (Little Girl)",
                  "11.5 (Little Girl)",
                  "30 (Toddler Girl)",
                  "11.5 (Little Girl),12.5 (Little Girl)",
                ],
                [
                  "13 (Little Girl)",
                  "12 (Little Girl)",
                  "31 (Little Girl)",
                  "11.5 (Little Girl),12.5 (Little Girl)",
                ],
                [
                  "13.5 (Little Girl)",
                  "12.5 (Little Girl)",
                  "31 (Little Girl),32 (Little Girl)",
                  "12.5 (Little Girl),13 (Little Girl)",
                ],
                [
                  "1 (Little Girl)",
                  "13 (Little Girl)",
                  "33 (Little Girl)",
                  "1 (Little Girl)",
                ],
                [
                  "1.5 (Little Girl)",
                  "14 (Little Girl)",
                  "33 (Little Girl)",
                  "1 (Little Girl)",
                ],
                [
                  "2 (Little Girl)",
                  "1 (Little Girl)",
                  "33 (Little Girl)",
                  "1 (Little Girl),1.5 (Little Girl)",
                ],
                [
                  "2.5 (Little Girl)",
                  "1.5 (Little Girl)",
                  "34 (Little Girl)",
                  "1 (Little Girl),1.5 (Little Girl)",
                ],
                [
                  "3 (Little Girl)",
                  "2 (Little Girl)",
                  "34 (Little Girl)",
                  "1.5 (Little Girl),2.5 (Big Girl)",
                ],
                [
                  "3.5 (Big Girl)",
                  "2.5 (Big Girl)",
                  "35 (Big Girl)",
                  "1.5 (Little Girl),2.5 (Big Girl)",
                ],
                [
                  "4 (Big Girl)",
                  "3 (Big Girl)",
                  "36 (Big Girl)",
                  "3 (Big Girl),3.5 (Big Girl)",
                ],
                [
                  "4.5 (Big Girl)",
                  "3.5 (Big Girl)",
                  "36 (Big Girl)",
                  "3 (Big Girl),3.5 (Big Girl)",
                ],
                [
                  "5 (Big Girl)",
                  "4 (Big Girl)",
                  "37 (Big Girl)",
                  "4 (Big Girl),4.5 (Big Girl)",
                ],
                [
                  "5.5 (Big Girl)",
                  "4.5 (Big Girl)",
                  "37 (Big Girl)",
                  "4 (Big Girl),4.5 (Big Girl)",
                ],
                [
                  "6 (Big Girl)",
                  "5 (Big Girl)",
                  "38 (Big Girl)",
                  "5 (Big Girl),5.5 (Big Girl)",
                ],
                [
                  "6.5 (Big Girl)",
                  "5.5 (Big Girl)",
                  "38 (Big Girl)",
                  "5 (Big Girl),5.5 (Big Girl)",
                ],
                ["7 (Big Girl)", "6 (Big Girl)", "39 (Big Girl)", "6 (Big Girl)"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "7.5G",
                      short: "7.5 (Toddler Girl)",
                      long: "7.5 (Toddler Girl)",
                      display: "7.5 (Toddler Girl)",
                      display_with_size_set: "7.5 (Toddler Girl)",
                      display_with_size_system: "US 7.5",
                      display_with_system_and_set: "US 7.5 (Toddler Girl)",
                      equivalent_sizes: {
                        au: ["6.5GAU"],
                        eu: ["23GEU"],
                        uk: ["6GUK", "7GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8G",
                      short: "8 (Toddler Girl)",
                      long: "8 (Toddler Girl)",
                      display: "8 (Toddler Girl)",
                      display_with_size_set: "8 (Toddler Girl)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Toddler Girl)",
                      equivalent_sizes: {
                        au: ["7GAU"],
                        eu: ["24GEU"],
                        uk: ["6GUK", "7GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8.5G",
                      short: "8.5 (Toddler Girl)",
                      long: "8.5 (Toddler Girl)",
                      display: "8.5 (Toddler Girl)",
                      display_with_size_set: "8.5 (Toddler Girl)",
                      display_with_size_system: "US 8.5",
                      display_with_system_and_set: "US 8.5 (Toddler Girl)",
                      equivalent_sizes: {
                        au: ["7.5GAU"],
                        eu: ["25GEU"],
                        uk: ["7.5GUK", "8GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9G",
                      short: "9 (Toddler Girl)",
                      long: "9 (Toddler Girl)",
                      display: "9 (Toddler Girl)",
                      display_with_size_set: "9 (Toddler Girl)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Toddler Girl)",
                      equivalent_sizes: {
                        au: ["8GAU"],
                        eu: ["25GEU"],
                        uk: ["7.5GUK", "8GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9.5G",
                      short: "9.5 (Toddler Girl)",
                      long: "9.5 (Toddler Girl)",
                      display: "9.5 (Toddler Girl)",
                      display_with_size_set: "9.5 (Toddler Girl)",
                      display_with_size_system: "US 9.5",
                      display_with_system_and_set: "US 9.5 (Toddler Girl)",
                      equivalent_sizes: {
                        au: ["8.5GAU"],
                        eu: ["26GEU"],
                        uk: ["8.5GUK", "9GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10G",
                      short: "10 (Toddler Girl)",
                      long: "10 (Toddler Girl)",
                      display: "10 (Toddler Girl)",
                      display_with_size_set: "10 (Toddler Girl)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Toddler Girl)",
                      equivalent_sizes: {
                        au: ["9GAU"],
                        eu: ["27GEU"],
                        uk: ["8.5GUK", "9GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10.5G",
                      short: "10.5 (Toddler Girl)",
                      long: "10.5 (Toddler Girl)",
                      display: "10.5 (Toddler Girl)",
                      display_with_size_set: "10.5 (Toddler Girl)",
                      display_with_size_system: "US 10.5",
                      display_with_system_and_set: "US 10.5 (Toddler Girl)",
                      equivalent_sizes: {
                        au: ["9.5GAU"],
                        eu: ["27GEU"],
                        uk: ["9.5GUK", "10GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11G",
                      short: "11 (Toddler Girl)",
                      long: "11 (Toddler Girl)",
                      display: "11 (Toddler Girl)",
                      display_with_size_set: "11 (Toddler Girl)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Toddler Girl)",
                      equivalent_sizes: {
                        au: ["10GAU"],
                        eu: ["28GEU"],
                        uk: ["9.5GUK", "10GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11.5G",
                      short: "11.5 (Toddler Girl)",
                      long: "11.5 (Toddler Girl)",
                      display: "11.5 (Toddler Girl)",
                      display_with_size_set: "11.5 (Toddler Girl)",
                      display_with_size_system: "US 11.5",
                      display_with_system_and_set: "US 11.5 (Toddler Girl)",
                      equivalent_sizes: {
                        au: ["10.5GAU"],
                        eu: ["29GEU"],
                        uk: ["11GUK", "11.5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12G",
                      short: "12 (Toddler Girl)",
                      long: "12 (Toddler Girl)",
                      display: "12 (Toddler Girl)",
                      display_with_size_set: "12 (Toddler Girl)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Toddler Girl)",
                      equivalent_sizes: {
                        au: ["11GAU"],
                        eu: ["30GEU"],
                        uk: ["11GUK", "11.5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12.5G",
                      short: "12.5 (Little Girl)",
                      long: "12.5 (Little Girl)",
                      display: "12.5 (Little Girl)",
                      display_with_size_set: "12.5 (Little Girl)",
                      display_with_size_system: "US 12.5",
                      display_with_system_and_set: "US 12.5 (Little Girl)",
                      equivalent_sizes: {
                        au: ["11.5GAU"],
                        eu: ["30GEU"],
                        uk: ["11.5GUK", "12.5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13G",
                      short: "13 (Little Girl)",
                      long: "13 (Little Girl)",
                      display: "13 (Little Girl)",
                      display_with_size_set: "13 (Little Girl)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Little Girl)",
                      equivalent_sizes: {
                        au: ["12GAU"],
                        eu: ["31GEU"],
                        uk: ["11.5GUK", "12.5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13.5G",
                      short: "13.5 (Little Girl)",
                      long: "13.5 (Little Girl)",
                      display: "13.5 (Little Girl)",
                      display_with_size_set: "13.5 (Little Girl)",
                      display_with_size_system: "US 13.5",
                      display_with_system_and_set: "US 13.5 (Little Girl)",
                      equivalent_sizes: {
                        au: ["12.5GAU"],
                        eu: ["31GEU", "32GEU"],
                        uk: ["12.5GUK", "13GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1G",
                      short: "1 (Little Girl)",
                      long: "1 (Little Girl)",
                      display: "1 (Little Girl)",
                      display_with_size_set: "1 (Little Girl)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Little Girl)",
                      equivalent_sizes: {
                        au: ["13GAU"],
                        eu: ["33GEU"],
                        uk: ["1GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1.5G",
                      short: "1.5 (Little Girl)",
                      long: "1.5 (Little Girl)",
                      display: "1.5 (Little Girl)",
                      display_with_size_set: "1.5 (Little Girl)",
                      display_with_size_system: "US 1.5",
                      display_with_system_and_set: "US 1.5 (Little Girl)",
                      equivalent_sizes: {
                        au: ["14GAU"],
                        eu: ["33GEU"],
                        uk: ["1GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2G",
                      short: "2 (Little Girl)",
                      long: "2 (Little Girl)",
                      display: "2 (Little Girl)",
                      display_with_size_set: "2 (Little Girl)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Little Girl)",
                      equivalent_sizes: {
                        au: ["1GAU"],
                        eu: ["33GEU"],
                        uk: ["1GUK", "1.5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2.5G",
                      short: "2.5 (Little Girl)",
                      long: "2.5 (Little Girl)",
                      display: "2.5 (Little Girl)",
                      display_with_size_set: "2.5 (Little Girl)",
                      display_with_size_system: "US 2.5",
                      display_with_system_and_set: "US 2.5 (Little Girl)",
                      equivalent_sizes: {
                        au: ["1.5GAU"],
                        eu: ["34GEU"],
                        uk: ["1GUK", "1.5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3G",
                      short: "3 (Little Girl)",
                      long: "3 (Little Girl)",
                      display: "3 (Little Girl)",
                      display_with_size_set: "3 (Little Girl)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Little Girl)",
                      equivalent_sizes: {
                        au: ["2GAU"],
                        eu: ["34GEU"],
                        uk: ["1.5GUK", "2.5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3.5G",
                      short: "3.5 (Big Girl)",
                      long: "3.5 (Big Girl)",
                      display: "3.5 (Big Girl)",
                      display_with_size_set: "3.5 (Big Girl)",
                      display_with_size_system: "US 3.5",
                      display_with_system_and_set: "US 3.5 (Big Girl)",
                      equivalent_sizes: {
                        au: ["2.5GAU"],
                        eu: ["35GEU"],
                        uk: ["1.5GUK", "2.5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4G",
                      short: "4 (Big Girl)",
                      long: "4 (Big Girl)",
                      display: "4 (Big Girl)",
                      display_with_size_set: "4 (Big Girl)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Big Girl)",
                      equivalent_sizes: {
                        au: ["3GAU"],
                        eu: ["36GEU"],
                        uk: ["3GUK", "3.5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4.5G",
                      short: "4.5 (Big Girl)",
                      long: "4.5 (Big Girl)",
                      display: "4.5 (Big Girl)",
                      display_with_size_set: "4.5 (Big Girl)",
                      display_with_size_system: "US 4.5",
                      display_with_system_and_set: "US 4.5 (Big Girl)",
                      equivalent_sizes: {
                        au: ["3.5GAU"],
                        eu: ["36GEU"],
                        uk: ["3GUK", "3.5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5G",
                      short: "5 (Big Girl)",
                      long: "5 (Big Girl)",
                      display: "5 (Big Girl)",
                      display_with_size_set: "5 (Big Girl)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Big Girl)",
                      equivalent_sizes: {
                        au: ["4GAU"],
                        eu: ["37GEU"],
                        uk: ["4GUK", "4.5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5.5G",
                      short: "5.5 (Big Girl)",
                      long: "5.5 (Big Girl)",
                      display: "5.5 (Big Girl)",
                      display_with_size_set: "5.5 (Big Girl)",
                      display_with_size_system: "US 5.5",
                      display_with_system_and_set: "US 5.5 (Big Girl)",
                      equivalent_sizes: {
                        au: ["4.5GAU"],
                        eu: ["37GEU"],
                        uk: ["4GUK", "4.5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6G",
                      short: "6 (Big Girl)",
                      long: "6 (Big Girl)",
                      display: "6 (Big Girl)",
                      display_with_size_set: "6 (Big Girl)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Big Girl)",
                      equivalent_sizes: {
                        au: ["5GAU"],
                        eu: ["38GEU"],
                        uk: ["5GUK", "5.5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6.5G",
                      short: "6.5 (Big Girl)",
                      long: "6.5 (Big Girl)",
                      display: "6.5 (Big Girl)",
                      display_with_size_set: "6.5 (Big Girl)",
                      display_with_size_system: "US 6.5",
                      display_with_system_and_set: "US 6.5 (Big Girl)",
                      equivalent_sizes: {
                        au: ["5.5GAU"],
                        eu: ["38GEU"],
                        uk: ["5GUK", "5.5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7G",
                      short: "7 (Big Girl)",
                      long: "7 (Big Girl)",
                      display: "7 (Big Girl)",
                      display_with_size_set: "7 (Big Girl)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Big Girl)",
                      equivalent_sizes: {
                        au: ["6GAU"],
                        eu: ["39GEU"],
                        uk: ["6GUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "1GAU",
                      short: "1 (Little Girl)",
                      long: "1 (Little Girl)",
                      display: "1 (Little Girl)",
                      display_with_size_set: "1 (Little Girl)",
                      display_with_size_system: "AU 1",
                      display_with_system_and_set: "AU 1 (Little Girl)",
                      equivalent_sizes: {
                        us: ["2G", "2.5G"],
                        eu: ["33GEU", "34GEU"],
                        uk: ["1GUK", "1.5GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1.5GAU",
                      short: "1.5 (Little Girl)",
                      long: "1.5 (Little Girl)",
                      display: "1.5 (Little Girl)",
                      display_with_size_set: "1.5 (Little Girl)",
                      display_with_size_system: "AU 1.5",
                      display_with_system_and_set: "AU 1.5 (Little Girl)",
                      equivalent_sizes: {
                        us: ["2G", "2.5G"],
                        eu: ["33GEU", "34GEU"],
                        uk: ["1GUK", "1.5GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2GAU",
                      short: "2 (Little Girl)",
                      long: "2 (Little Girl)",
                      display: "2 (Little Girl)",
                      display_with_size_set: "2 (Little Girl)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Little Girl)",
                      equivalent_sizes: {
                        us: ["3G", "3.5G"],
                        eu: ["34GEU", "35GEU"],
                        uk: ["1.5GUK", "2.5GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2.5GAU",
                      short: "2.5 (Big Girl)",
                      long: "2.5 (Big Girl)",
                      display: "2.5 (Big Girl)",
                      display_with_size_set: "2.5 (Big Girl)",
                      display_with_size_system: "AU 2.5",
                      display_with_system_and_set: "AU 2.5 (Big Girl)",
                      equivalent_sizes: {
                        us: ["3G", "3.5G"],
                        eu: ["34GEU", "35GEU"],
                        uk: ["1.5GUK", "2.5GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3GAU",
                      short: "3 (Big Girl)",
                      long: "3 (Big Girl)",
                      display: "3 (Big Girl)",
                      display_with_size_set: "3 (Big Girl)",
                      display_with_size_system: "AU 3",
                      display_with_system_and_set: "AU 3 (Big Girl)",
                      equivalent_sizes: {
                        us: ["4G", "4.5G"],
                        eu: ["36GEU"],
                        uk: ["3GUK", "3.5GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3.5GAU",
                      short: "3.5 (Big Girl)",
                      long: "3.5 (Big Girl)",
                      display: "3.5 (Big Girl)",
                      display_with_size_set: "3.5 (Big Girl)",
                      display_with_size_system: "AU 3.5",
                      display_with_system_and_set: "AU 3.5 (Big Girl)",
                      equivalent_sizes: {
                        us: ["4G", "4.5G"],
                        eu: ["36GEU"],
                        uk: ["3GUK", "3.5GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4GAU",
                      short: "4 (Big Girl)",
                      long: "4 (Big Girl)",
                      display: "4 (Big Girl)",
                      display_with_size_set: "4 (Big Girl)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Big Girl)",
                      equivalent_sizes: {
                        us: ["5G", "5.5G"],
                        eu: ["37GEU"],
                        uk: ["4GUK", "4.5GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4.5GAU",
                      short: "4.5 (Big Girl)",
                      long: "4.5 (Big Girl)",
                      display: "4.5 (Big Girl)",
                      display_with_size_set: "4.5 (Big Girl)",
                      display_with_size_system: "AU 4.5",
                      display_with_system_and_set: "AU 4.5 (Big Girl)",
                      equivalent_sizes: {
                        us: ["5G", "5.5G"],
                        eu: ["37GEU"],
                        uk: ["4GUK", "4.5GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5GAU",
                      short: "5 (Big Girl)",
                      long: "5 (Big Girl)",
                      display: "5 (Big Girl)",
                      display_with_size_set: "5 (Big Girl)",
                      display_with_size_system: "AU 5",
                      display_with_system_and_set: "AU 5 (Big Girl)",
                      equivalent_sizes: {
                        us: ["6G", "6.5G"],
                        eu: ["38GEU"],
                        uk: ["5GUK", "5.5GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5.5GAU",
                      short: "5.5 (Big Girl)",
                      long: "5.5 (Big Girl)",
                      display: "5.5 (Big Girl)",
                      display_with_size_set: "5.5 (Big Girl)",
                      display_with_size_system: "AU 5.5",
                      display_with_system_and_set: "AU 5.5 (Big Girl)",
                      equivalent_sizes: {
                        us: ["6G", "6.5G"],
                        eu: ["38GEU"],
                        uk: ["5GUK", "5.5GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6GAU",
                      short: "6 (Big Girl)",
                      long: "6 (Big Girl)",
                      display: "6 (Big Girl)",
                      display_with_size_set: "6 (Big Girl)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Big Girl)",
                      equivalent_sizes: {
                        us: ["7G"],
                        eu: ["39GEU"],
                        uk: ["6GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6.5GAU",
                      short: "6.5 (Toddler Girl)",
                      long: "6.5 (Toddler Girl)",
                      display: "6.5 (Toddler Girl)",
                      display_with_size_set: "6.5 (Toddler Girl)",
                      display_with_size_system: "AU 6.5",
                      display_with_system_and_set: "AU 6.5 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["7.5G", "8G"],
                        eu: ["23GEU", "24GEU"],
                        uk: ["6GUK", "7GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "7GAU",
                      short: "7 (Toddler Girl)",
                      long: "7 (Toddler Girl)",
                      display: "7 (Toddler Girl)",
                      display_with_size_set: "7 (Toddler Girl)",
                      display_with_size_system: "AU 7",
                      display_with_system_and_set: "AU 7 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["7.5G", "8G"],
                        eu: ["23GEU", "24GEU"],
                        uk: ["6GUK", "7GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "7.5GAU",
                      short: "7.5 (Toddler Girl)",
                      long: "7.5 (Toddler Girl)",
                      display: "7.5 (Toddler Girl)",
                      display_with_size_set: "7.5 (Toddler Girl)",
                      display_with_size_system: "AU 7.5",
                      display_with_system_and_set: "AU 7.5 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["8.5G", "9G"],
                        eu: ["25GEU"],
                        uk: ["7.5GUK", "8GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8GAU",
                      short: "8 (Toddler Girl)",
                      long: "8 (Toddler Girl)",
                      display: "8 (Toddler Girl)",
                      display_with_size_set: "8 (Toddler Girl)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["8.5G", "9G"],
                        eu: ["25GEU"],
                        uk: ["7.5GUK", "8GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8.5GAU",
                      short: "8.5 (Toddler Girl)",
                      long: "8.5 (Toddler Girl)",
                      display: "8.5 (Toddler Girl)",
                      display_with_size_set: "8.5 (Toddler Girl)",
                      display_with_size_system: "AU 8.5",
                      display_with_system_and_set: "AU 8.5 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["9.5G", "10G"],
                        eu: ["26GEU", "27GEU"],
                        uk: ["8.5GUK", "9GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "9GAU",
                      short: "9 (Toddler Girl)",
                      long: "9 (Toddler Girl)",
                      display: "9 (Toddler Girl)",
                      display_with_size_set: "9 (Toddler Girl)",
                      display_with_size_system: "AU 9",
                      display_with_system_and_set: "AU 9 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["9.5G", "10G"],
                        eu: ["26GEU", "27GEU"],
                        uk: ["8.5GUK", "9GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "9.5GAU",
                      short: "9.5 (Toddler Girl)",
                      long: "9.5 (Toddler Girl)",
                      display: "9.5 (Toddler Girl)",
                      display_with_size_set: "9.5 (Toddler Girl)",
                      display_with_size_system: "AU 9.5",
                      display_with_system_and_set: "AU 9.5 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["10.5G", "11G"],
                        eu: ["27GEU", "28GEU"],
                        uk: ["9.5GUK", "10GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10GAU",
                      short: "10 (Toddler Girl)",
                      long: "10 (Toddler Girl)",
                      display: "10 (Toddler Girl)",
                      display_with_size_set: "10 (Toddler Girl)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["10.5G", "11G"],
                        eu: ["27GEU", "28GEU"],
                        uk: ["9.5GUK", "10GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10.5GAU",
                      short: "10.5 (Toddler Girl)",
                      long: "10.5 (Toddler Girl)",
                      display: "10.5 (Toddler Girl)",
                      display_with_size_set: "10.5 (Toddler Girl)",
                      display_with_size_system: "AU 10.5",
                      display_with_system_and_set: "AU 10.5 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["11.5G", "12G"],
                        eu: ["29GEU", "30GEU"],
                        uk: ["11GUK", "11.5GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11GAU",
                      short: "11 (Toddler Girl)",
                      long: "11 (Toddler Girl)",
                      display: "11 (Toddler Girl)",
                      display_with_size_set: "11 (Toddler Girl)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["11.5G", "12G"],
                        eu: ["29GEU", "30GEU"],
                        uk: ["11GUK", "11.5GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11.5GAU",
                      short: "11.5 (Little Girl)",
                      long: "11.5 (Little Girl)",
                      display: "11.5 (Little Girl)",
                      display_with_size_set: "11.5 (Little Girl)",
                      display_with_size_system: "AU 11.5",
                      display_with_system_and_set: "AU 11.5 (Little Girl)",
                      equivalent_sizes: {
                        us: ["12.5G", "13G"],
                        eu: ["30GEU", "31GEU"],
                        uk: ["11.5GUK", "12.5GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12GAU",
                      short: "12 (Little Girl)",
                      long: "12 (Little Girl)",
                      display: "12 (Little Girl)",
                      display_with_size_set: "12 (Little Girl)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Little Girl)",
                      equivalent_sizes: {
                        us: ["12.5G", "13G"],
                        eu: ["30GEU", "31GEU"],
                        uk: ["11.5GUK", "12.5GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12.5GAU",
                      short: "12.5 (Little Girl)",
                      long: "12.5 (Little Girl)",
                      display: "12.5 (Little Girl)",
                      display_with_size_set: "12.5 (Little Girl)",
                      display_with_size_system: "AU 12.5",
                      display_with_system_and_set: "AU 12.5 (Little Girl)",
                      equivalent_sizes: {
                        us: ["13.5G"],
                        eu: ["31GEU", "32GEU"],
                        uk: ["12.5GUK", "13GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "13GAU",
                      short: "13 (Little Girl)",
                      long: "13 (Little Girl)",
                      display: "13 (Little Girl)",
                      display_with_size_set: "13 (Little Girl)",
                      display_with_size_system: "AU 13",
                      display_with_system_and_set: "AU 13 (Little Girl)",
                      equivalent_sizes: {
                        us: ["1G", "1.5G"],
                        eu: ["33GEU"],
                        uk: ["1GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14GAU",
                      short: "14 (Little Girl)",
                      long: "14 (Little Girl)",
                      display: "14 (Little Girl)",
                      display_with_size_set: "14 (Little Girl)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Little Girl)",
                      equivalent_sizes: {
                        us: ["1G", "1.5G"],
                        eu: ["33GEU"],
                        uk: ["1GUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "23GEU",
                      short: "23 (Toddler Girl)",
                      long: "23 (Toddler Girl)",
                      display: "23 (Toddler Girl)",
                      display_with_size_set: "23 (Toddler Girl)",
                      display_with_size_system: "EU 23",
                      display_with_system_and_set: "EU 23 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["7.5G", "8G"],
                        au: ["6.5GAU", "7GAU"],
                        uk: ["6GUK", "7GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "24GEU",
                      short: "24 (Toddler Girl)",
                      long: "24 (Toddler Girl)",
                      display: "24 (Toddler Girl)",
                      display_with_size_set: "24 (Toddler Girl)",
                      display_with_size_system: "EU 24",
                      display_with_system_and_set: "EU 24 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["7.5G", "8G"],
                        au: ["6.5GAU", "7GAU"],
                        uk: ["6GUK", "7GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "25GEU",
                      short: "25 (Toddler Girl)",
                      long: "25 (Toddler Girl)",
                      display: "25 (Toddler Girl)",
                      display_with_size_set: "25 (Toddler Girl)",
                      display_with_size_system: "EU 25",
                      display_with_system_and_set: "EU 25 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["8.5G", "9G"],
                        au: ["7.5GAU", "8GAU"],
                        uk: ["7.5GUK", "8GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "26GEU",
                      short: "26 (Toddler Girl)",
                      long: "26 (Toddler Girl)",
                      display: "26 (Toddler Girl)",
                      display_with_size_set: "26 (Toddler Girl)",
                      display_with_size_system: "EU 26",
                      display_with_system_and_set: "EU 26 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["9.5G", "10G"],
                        au: ["8.5GAU", "9GAU"],
                        uk: ["8.5GUK", "9GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "27GEU",
                      short: "27 (Toddler Girl)",
                      long: "27 (Toddler Girl)",
                      display: "27 (Toddler Girl)",
                      display_with_size_set: "27 (Toddler Girl)",
                      display_with_size_system: "EU 27",
                      display_with_system_and_set: "EU 27 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["9.5G", "10G", "10.5G", "11G"],
                        au: ["8.5GAU", "9GAU", "9.5GAU", "10GAU"],
                        uk: ["9.5GUK", "10GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "28GEU",
                      short: "28 (Toddler Girl)",
                      long: "28 (Toddler Girl)",
                      display: "28 (Toddler Girl)",
                      display_with_size_set: "28 (Toddler Girl)",
                      display_with_size_system: "EU 28",
                      display_with_system_and_set: "EU 28 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["10.5G", "11G"],
                        au: ["9.5GAU", "10GAU"],
                        uk: ["9.5GUK", "10GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "29GEU",
                      short: "29 (Toddler Girl)",
                      long: "29 (Toddler Girl)",
                      display: "29 (Toddler Girl)",
                      display_with_size_set: "29 (Toddler Girl)",
                      display_with_size_system: "EU 29",
                      display_with_system_and_set: "EU 29 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["11.5G", "12G"],
                        au: ["10.5GAU", "11GAU"],
                        uk: ["11GUK", "11.5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "30GEU",
                      short: "30 (Toddler Girl)",
                      long: "30 (Toddler Girl)",
                      display: "30 (Toddler Girl)",
                      display_with_size_set: "30 (Toddler Girl)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["11.5G", "12G", "12.5G", "13G"],
                        au: ["10.5GAU", "11GAU", "11.5GAU", "12GAU"],
                        uk: ["11GUK", "11.5GUK", "12.5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "31GEU",
                      short: "31 (Little Girl)",
                      long: "31 (Little Girl)",
                      display: "31 (Little Girl)",
                      display_with_size_set: "31 (Little Girl)",
                      display_with_size_system: "EU 31",
                      display_with_system_and_set: "EU 31 (Little Girl)",
                      equivalent_sizes: {
                        us: ["12.5G", "13G", "13.5G"],
                        au: ["11.5GAU", "12GAU", "12.5GAU"],
                        uk: ["11.5GUK", "12.5GUK", "13GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32GEU",
                      short: "32 (Little Girl)",
                      long: "32 (Little Girl)",
                      display: "32 (Little Girl)",
                      display_with_size_set: "32 (Little Girl)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Little Girl)",
                      equivalent_sizes: {
                        us: ["13.5G"],
                        au: ["12.5GAU"],
                        uk: ["12.5GUK", "13GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "33GEU",
                      short: "33 (Little Girl)",
                      long: "33 (Little Girl)",
                      display: "33 (Little Girl)",
                      display_with_size_set: "33 (Little Girl)",
                      display_with_size_system: "EU 33",
                      display_with_system_and_set: "EU 33 (Little Girl)",
                      equivalent_sizes: {
                        us: ["1G", "1.5G", "2G", "2.5G"],
                        au: ["1GAU", "1.5GAU", "13GAU", "14GAU"],
                        uk: ["1GUK", "1.5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34GEU",
                      short: "34 (Little Girl)",
                      long: "34 (Little Girl)",
                      display: "34 (Little Girl)",
                      display_with_size_set: "34 (Little Girl)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Little Girl)",
                      equivalent_sizes: {
                        us: ["2G", "2.5G", "3G", "3.5G"],
                        au: ["1GAU", "1.5GAU", "2GAU", "2.5GAU"],
                        uk: ["1.5GUK", "2.5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "35GEU",
                      short: "35 (Big Girl)",
                      long: "35 (Big Girl)",
                      display: "35 (Big Girl)",
                      display_with_size_set: "35 (Big Girl)",
                      display_with_size_system: "EU 35",
                      display_with_system_and_set: "EU 35 (Big Girl)",
                      equivalent_sizes: {
                        us: ["3G", "3.5G"],
                        au: ["2GAU", "2.5GAU"],
                        uk: ["1.5GUK", "2.5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36GEU",
                      short: "36 (Big Girl)",
                      long: "36 (Big Girl)",
                      display: "36 (Big Girl)",
                      display_with_size_set: "36 (Big Girl)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Big Girl)",
                      equivalent_sizes: {
                        us: ["4G", "4.5G"],
                        au: ["3GAU", "3.5GAU"],
                        uk: ["3GUK", "3.5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "37GEU",
                      short: "37 (Big Girl)",
                      long: "37 (Big Girl)",
                      display: "37 (Big Girl)",
                      display_with_size_set: "37 (Big Girl)",
                      display_with_size_system: "EU 37",
                      display_with_system_and_set: "EU 37 (Big Girl)",
                      equivalent_sizes: {
                        us: ["5G", "5.5G"],
                        au: ["4GAU", "4.5GAU"],
                        uk: ["4GUK", "4.5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38GEU",
                      short: "38 (Big Girl)",
                      long: "38 (Big Girl)",
                      display: "38 (Big Girl)",
                      display_with_size_set: "38 (Big Girl)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Big Girl)",
                      equivalent_sizes: {
                        us: ["6G", "6.5G"],
                        au: ["5GAU", "5.5GAU"],
                        uk: ["5GUK", "5.5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "39GEU",
                      short: "39 (Big Girl)",
                      long: "39 (Big Girl)",
                      display: "39 (Big Girl)",
                      display_with_size_set: "39 (Big Girl)",
                      display_with_size_system: "EU 39",
                      display_with_system_and_set: "EU 39 (Big Girl)",
                      equivalent_sizes: {
                        us: ["7G"],
                        au: ["6GAU"],
                        uk: ["6GUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "1GUK",
                      short: "1 (Little Girl)",
                      long: "1 (Little Girl)",
                      display: "1 (Little Girl)",
                      display_with_size_set: "1 (Little Girl)",
                      display_with_size_system: "UK 1",
                      display_with_system_and_set: "UK 1 (Little Girl)",
                      equivalent_sizes: {
                        us: ["1G", "1.5G", "2G", "2.5G"],
                        au: ["1GAU", "1.5GAU", "13GAU", "14GAU"],
                        eu: ["33GEU", "34GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1.5GUK",
                      short: "1.5 (Little Girl)",
                      long: "1.5 (Little Girl)",
                      display: "1.5 (Little Girl)",
                      display_with_size_set: "1.5 (Little Girl)",
                      display_with_size_system: "UK 1.5",
                      display_with_system_and_set: "UK 1.5 (Little Girl)",
                      equivalent_sizes: {
                        us: ["2G", "2.5G", "3G", "3.5G"],
                        au: ["1GAU", "1.5GAU", "2GAU", "2.5GAU"],
                        eu: ["33GEU", "34GEU", "35GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2.5GUK",
                      short: "2.5 (Big Girl)",
                      long: "2.5 (Big Girl)",
                      display: "2.5 (Big Girl)",
                      display_with_size_set: "2.5 (Big Girl)",
                      display_with_size_system: "UK 2.5",
                      display_with_system_and_set: "UK 2.5 (Big Girl)",
                      equivalent_sizes: {
                        us: ["3G", "3.5G"],
                        au: ["2GAU", "2.5GAU"],
                        eu: ["34GEU", "35GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3GUK",
                      short: "3 (Big Girl)",
                      long: "3 (Big Girl)",
                      display: "3 (Big Girl)",
                      display_with_size_set: "3 (Big Girl)",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3 (Big Girl)",
                      equivalent_sizes: {
                        us: ["4G", "4.5G"],
                        au: ["3GAU", "3.5GAU"],
                        eu: ["36GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3.5GUK",
                      short: "3.5 (Big Girl)",
                      long: "3.5 (Big Girl)",
                      display: "3.5 (Big Girl)",
                      display_with_size_set: "3.5 (Big Girl)",
                      display_with_size_system: "UK 3.5",
                      display_with_system_and_set: "UK 3.5 (Big Girl)",
                      equivalent_sizes: {
                        us: ["4G", "4.5G"],
                        au: ["3GAU", "3.5GAU"],
                        eu: ["36GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4GUK",
                      short: "4 (Big Girl)",
                      long: "4 (Big Girl)",
                      display: "4 (Big Girl)",
                      display_with_size_set: "4 (Big Girl)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Big Girl)",
                      equivalent_sizes: {
                        us: ["5G", "5.5G"],
                        au: ["4GAU", "4.5GAU"],
                        eu: ["37GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4.5GUK",
                      short: "4.5 (Big Girl)",
                      long: "4.5 (Big Girl)",
                      display: "4.5 (Big Girl)",
                      display_with_size_set: "4.5 (Big Girl)",
                      display_with_size_system: "UK 4.5",
                      display_with_system_and_set: "UK 4.5 (Big Girl)",
                      equivalent_sizes: {
                        us: ["5G", "5.5G"],
                        au: ["4GAU", "4.5GAU"],
                        eu: ["37GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5GUK",
                      short: "5 (Big Girl)",
                      long: "5 (Big Girl)",
                      display: "5 (Big Girl)",
                      display_with_size_set: "5 (Big Girl)",
                      display_with_size_system: "UK 5",
                      display_with_system_and_set: "UK 5 (Big Girl)",
                      equivalent_sizes: {
                        us: ["6G", "6.5G"],
                        au: ["5GAU", "5.5GAU"],
                        eu: ["38GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5.5GUK",
                      short: "5.5 (Big Girl)",
                      long: "5.5 (Big Girl)",
                      display: "5.5 (Big Girl)",
                      display_with_size_set: "5.5 (Big Girl)",
                      display_with_size_system: "UK 5.5",
                      display_with_system_and_set: "UK 5.5 (Big Girl)",
                      equivalent_sizes: {
                        us: ["6G", "6.5G"],
                        au: ["5GAU", "5.5GAU"],
                        eu: ["38GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6GUK",
                      short: "6 (Big Girl)",
                      long: "6 (Big Girl)",
                      display: "6 (Big Girl)",
                      display_with_size_set: "6 (Big Girl)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Big Girl)",
                      equivalent_sizes: {
                        us: ["7G", "7.5G", "8G"],
                        au: ["6GAU", "6.5GAU", "7GAU"],
                        eu: ["23GEU", "24GEU", "39GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "7GUK",
                      short: "7 (Toddler Girl)",
                      long: "7 (Toddler Girl)",
                      display: "7 (Toddler Girl)",
                      display_with_size_set: "7 (Toddler Girl)",
                      display_with_size_system: "UK 7",
                      display_with_system_and_set: "UK 7 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["7.5G", "8G"],
                        au: ["7GAU"],
                        eu: ["23GEU", "24GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "7.5GUK",
                      short: "7.5 (Toddler Girl)",
                      long: "7.5 (Toddler Girl)",
                      display: "7.5 (Toddler Girl)",
                      display_with_size_set: "7.5 (Toddler Girl)",
                      display_with_size_system: "UK 7.5",
                      display_with_system_and_set: "UK 7.5 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["8.5G", "9G"],
                        au: ["7.5GAU", "8GAU"],
                        eu: ["25GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8GUK",
                      short: "8 (Toddler Girl)",
                      long: "8 (Toddler Girl)",
                      display: "8 (Toddler Girl)",
                      display_with_size_set: "8 (Toddler Girl)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["8.5G", "9G"],
                        au: ["7.5GAU", "8GAU"],
                        eu: ["25GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8.5GUK",
                      short: "8.5 (Toddler Girl)",
                      long: "8.5 (Toddler Girl)",
                      display: "8.5 (Toddler Girl)",
                      display_with_size_set: "8.5 (Toddler Girl)",
                      display_with_size_system: "UK 8.5",
                      display_with_system_and_set: "UK 8.5 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["9.5G", "10G"],
                        au: ["8.5GAU", "9GAU"],
                        eu: ["26GEU", "27GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9GUK",
                      short: "9 (Toddler Girl)",
                      long: "9 (Toddler Girl)",
                      display: "9 (Toddler Girl)",
                      display_with_size_set: "9 (Toddler Girl)",
                      display_with_size_system: "UK 9",
                      display_with_system_and_set: "UK 9 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["9.5G", "10G"],
                        au: ["8.5GAU", "9GAU"],
                        eu: ["26GEU", "27GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9.5GUK",
                      short: "9.5 (Toddler Girl)",
                      long: "9.5 (Toddler Girl)",
                      display: "9.5 (Toddler Girl)",
                      display_with_size_set: "9.5 (Toddler Girl)",
                      display_with_size_system: "UK 9.5",
                      display_with_system_and_set: "UK 9.5 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["10.5G", "11G"],
                        au: ["9.5GAU", "10GAU"],
                        eu: ["27GEU", "28GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10GUK",
                      short: "10 (Toddler Girl)",
                      long: "10 (Toddler Girl)",
                      display: "10 (Toddler Girl)",
                      display_with_size_set: "10 (Toddler Girl)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["10.5G", "11G"],
                        au: ["9.5GAU", "10GAU"],
                        eu: ["27GEU", "28GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11GUK",
                      short: "11 (Toddler Girl)",
                      long: "11 (Toddler Girl)",
                      display: "11 (Toddler Girl)",
                      display_with_size_set: "11 (Toddler Girl)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Toddler Girl)",
                      equivalent_sizes: {
                        us: ["11.5G", "12G"],
                        au: ["10.5GAU", "11GAU"],
                        eu: ["29GEU", "30GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11.5GUK",
                      short: "11.5 (Little Girl)",
                      long: "11.5 (Little Girl)",
                      display: "11.5 (Little Girl)",
                      display_with_size_set: "11.5 (Little Girl)",
                      display_with_size_system: "UK 11.5",
                      display_with_system_and_set: "UK 11.5 (Little Girl)",
                      equivalent_sizes: {
                        us: ["11.5G", "12G", "12.5G", "13G"],
                        au: ["10.5GAU", "11GAU", "11.5GAU", "12GAU"],
                        eu: ["29GEU", "30GEU", "31GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12.5GUK",
                      short: "12.5 (Little Girl)",
                      long: "12.5 (Little Girl)",
                      display: "12.5 (Little Girl)",
                      display_with_size_set: "12.5 (Little Girl)",
                      display_with_size_system: "UK 12.5",
                      display_with_system_and_set: "UK 12.5 (Little Girl)",
                      equivalent_sizes: {
                        us: ["12.5G", "13G"],
                        au: ["11.5GAU", "12GAU", "12.5GAU"],
                        eu: ["30GEU", "31GEU", "32GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "13GUK",
                      short: "13 (Little Girl)",
                      long: "13 (Little Girl)",
                      display: "13 (Little Girl)",
                      display_with_size_set: "13 (Little Girl)",
                      display_with_size_system: "UK 13",
                      display_with_system_and_set: "UK 13 (Little Girl)",
                      equivalent_sizes: {
                        us: ["13.5G"],
                        au: ["12.5GAU"],
                        eu: ["31GEU", "32GEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "12003667d97b4e6bdb005784",
              name: "Boys",
              tags: ["boys"],
              category_id: "29008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["in."],
                  ["7.75-8.25"],
                  ["8.1-8.6"],
                  ["8.5-8.6"],
                  ["8.75-9.0"],
                  ["8.75-9.0"],
                  ["9.1-9.25"],
                  ["9.1-9.25"],
                  ["9.5-9.6"],
                  ["9.5-9.6"],
                  ["9.75"],
                  ["5.6-5.75"],
                  ["5.6-5.75"],
                  ["6.0-6.1"],
                  ["6.0-6.1"],
                  ["6.25-6.5"],
                  ["6.25-6.5"],
                  ["6.6-6.75"],
                  ["6.6-6.75"],
                  ["7.0-7.1"],
                  ["7.0-7.1"],
                  ["7.25-7.6"],
                  ["7.6"],
                ],
                centimeters: [
                  ["cm."],
                  ["19.7-21.0"],
                  ["20.6-21.0"],
                  ["21.6-21.8"],
                  ["22.2-22.9"],
                  ["22.2-22.9"],
                  ["23.1-23.5"],
                  ["23.1-23.5"],
                  ["24.1-24.4"],
                  ["24.1-24.4"],
                  ["24.8"],
                  ["14.2-14.6"],
                  ["14.2-14.6"],
                  ["15.2-15.5"],
                  ["15.2-15.5"],
                  ["15.9-16.5"],
                  ["15.9-16.5"],
                  ["16.8-17.1"],
                  ["16.8-17.1"],
                  ["17.8-18.0"],
                  ["17.8-18.0"],
                  ["18.4-19.1"],
                  ["19.3"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  [
                    "1 (Little Boy),1.5 (Little Boy),2 (Little Boy),2.5 (Little Boy)",
                    "1 (Little Boy),1.5 (Little Boy),13 (Little Boy),14 (Little Boy)",
                    "33 (Little Boy),34 (Little Boy)",
                    "1 (Little Boy)",
                  ],
                  [
                    "2 (Little Boy),2.5 (Little Boy),3 (Little Boy),3.5 (Big Boy)",
                    "1 (Little Boy),1.5 (Little Boy),2 (Little Boy),2.5 (Big Boy)",
                    "33 (Little Boy),34 (Little Boy),35 (Big Boy)",
                    "1.5 (Little Boy)",
                  ],
                  [
                    "3 (Little Boy),3.5 (Big Boy)",
                    "2 (Little Boy),2.5 (Big Boy)",
                    "34 (Little Boy),35 (Big Boy)",
                    "2.5 (Big Boy)",
                  ],
                  [
                    "4 (Big Boy),4.5 (Big Boy)",
                    "3 (Big Boy),3.5 (Big Boy)",
                    "36 (Big Boy)",
                    "3 (Big Boy)",
                  ],
                  [
                    "4 (Big Boy),4.5 (Big Boy)",
                    "3 (Big Boy),3.5 (Big Boy)",
                    "36 (Big Boy)",
                    "3.5 (Big Boy)",
                  ],
                  [
                    "5 (Big Boy),5.5 (Big Boy)",
                    "4 (Big Boy),4.5 (Big Boy)",
                    "37 (Big Boy)",
                    "4 (Big Boy)",
                  ],
                  [
                    "5 (Big Boy),5.5 (Big Boy)",
                    "4 (Big Boy),4.5 (Big Boy)",
                    "37 (Big Boy)",
                    "4.5 (Big Boy)",
                  ],
                  [
                    "6 (Big Boy),6.5 (Big Boy)",
                    "5 (Big Boy),5.5 (Big Boy)",
                    "38 (Big Boy)",
                    "5 (Big Boy)",
                  ],
                  [
                    "6 (Big Boy),6.5 (Big Boy)",
                    "5 (Big Boy),5.5 (Big Boy)",
                    "38 (Big Boy)",
                    "5.5 (Big Boy)",
                  ],
                  [
                    "7 (Big Boy),7.5 (Toddler Boy),8 (Toddler Boy)",
                    "6 (Big Boy),6.5 (Toddler Boy),7 (Toddler Boy)",
                    "23 (Toddler Boy),24 (Toddler Boy),39 (Big Boy)",
                    "6 (Big Boy)",
                  ],
                  [
                    "7 (Big Boy),7.5 (Toddler Boy),8 (Toddler Boy)",
                    "6 (Big Boy),6.5 (Toddler Boy),7 (Toddler Boy)",
                    "23 (Toddler Boy),24 (Toddler Boy),39 (Big Boy)",
                    "6 (Big Boy)",
                  ],
                  [
                    "7.5 (Toddler Boy),8 (Toddler Boy)",
                    "7 (Toddler Boy)",
                    "23 (Toddler Boy),24 (Toddler Boy)",
                    "7 (Toddler Boy)",
                  ],
                  [
                    "8.5 (Toddler Boy),9 (Toddler Boy)",
                    "7.5 (Toddler Boy),8 (Toddler Boy)",
                    "25 (Toddler Boy)",
                    "7.5 (Toddler Boy)",
                  ],
                  [
                    "8.5 (Toddler Boy),9 (Toddler Boy)",
                    "7.5 (Toddler Boy),8 (Toddler Boy)",
                    "25 (Toddler Boy)",
                    "8 (Toddler Boy)",
                  ],
                  [
                    "9.5 (Toddler Boy),10 (Toddler Boy)",
                    "8.5 (Toddler Boy),9 (Toddler Boy)",
                    "26 (Toddler Boy),27 (Toddler Boy)",
                    "8.5 (Toddler Boy)",
                  ],
                  [
                    "9.5 (Toddler Boy),10 (Toddler Boy)",
                    "8.5 (Toddler Boy),9 (Toddler Boy)",
                    "26 (Toddler Boy),27 (Toddler Boy)",
                    "9 (Toddler Boy)",
                  ],
                  [
                    "10.5 (Toddler Boy),11 (Toddler Boy)",
                    "9.5 (Toddler Boy),10 (Toddler Boy)",
                    "27 (Toddler Boy),28 (Toddler Boy)",
                    "9.5 (Toddler Boy)",
                  ],
                  [
                    "10.5 (Toddler Boy),11 (Toddler Boy)",
                    "9.5 (Toddler Boy),10 (Toddler Boy)",
                    "27 (Toddler Boy),28 (Toddler Boy)",
                    "10 (Toddler Boy)",
                  ],
                  [
                    "11.5 (Toddler Boy),12 (Toddler Boy)",
                    "10.5 (Toddler Boy),11 (Toddler Boy)",
                    "29 (Toddler Boy),30 (Toddler Boy)",
                    "11 (Toddler Boy)",
                  ],
                  [
                    "11.5 (Toddler Boy),12 (Toddler Boy),12.5 (Little Boy),13 (Little Boy)",
                    "10.5 (Toddler Boy),11 (Toddler Boy),11.5 (Little Boy),12 (Little Boy)",
                    "29 (Toddler Boy),30 (Toddler Boy),31 (Little Boy)",
                    "11.5 (Little Boy)",
                  ],
                  [
                    "12.5 (Little Boy),13 (Little Boy)",
                    "11.5 (Little Boy),12 (Little Boy),12.5 (Little Boy)",
                    "30 (Toddler Boy),31 (Little Boy),32 (Little Boy)",
                    "12.5 (Little Boy)",
                  ],
                  [
                    "13.5 (Little Boy)",
                    "12.5 (Little Boy)",
                    "31 (Little Boy),32 (Little Boy)",
                    "13 (Little Boy)",
                  ],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                [
                  "7.5 (Toddler Boy)",
                  "6.5 (Toddler Boy)",
                  "23 (Toddler Boy)",
                  "6 (Big Boy),7 (Toddler Boy)",
                ],
                [
                  "8 (Toddler Boy)",
                  "7 (Toddler Boy)",
                  "24 (Toddler Boy)",
                  "6 (Big Boy),7 (Toddler Boy)",
                ],
                [
                  "8.5 (Toddler Boy)",
                  "7.5 (Toddler Boy)",
                  "25 (Toddler Boy)",
                  "7.5 (Toddler Boy),8 (Toddler Boy)",
                ],
                [
                  "9 (Toddler Boy)",
                  "8 (Toddler Boy)",
                  "25 (Toddler Boy)",
                  "7.5 (Toddler Boy),8 (Toddler Boy)",
                ],
                [
                  "9.5 (Toddler Boy)",
                  "8.5 (Toddler Boy)",
                  "26 (Toddler Boy)",
                  "8.5 (Toddler Boy),9 (Toddler Boy)",
                ],
                [
                  "10 (Toddler Boy)",
                  "9 (Toddler Boy)",
                  "27 (Toddler Boy)",
                  "8.5 (Toddler Boy),9 (Toddler Boy)",
                ],
                [
                  "10.5 (Toddler Boy)",
                  "9.5 (Toddler Boy)",
                  "27 (Toddler Boy)",
                  "9.5 (Toddler Boy),10 (Toddler Boy)",
                ],
                [
                  "11 (Toddler Boy)",
                  "10 (Toddler Boy)",
                  "28 (Toddler Boy)",
                  "9.5 (Toddler Boy),10 (Toddler Boy)",
                ],
                [
                  "11.5 (Toddler Boy)",
                  "10.5 (Toddler Boy)",
                  "29 (Toddler Boy)",
                  "11 (Toddler Boy),11.5 (Little Boy)",
                ],
                [
                  "12 (Toddler Boy)",
                  "11 (Toddler Boy)",
                  "30 (Toddler Boy)",
                  "11 (Toddler Boy),11.5 (Little Boy)",
                ],
                [
                  "12.5 (Little Boy)",
                  "11.5 (Little Boy)",
                  "30 (Toddler Boy)",
                  "11.5 (Little Boy),12.5 (Little Boy)",
                ],
                [
                  "13 (Little Boy)",
                  "12 (Little Boy)",
                  "31 (Little Boy)",
                  "11.5 (Little Boy),12.5 (Little Boy)",
                ],
                [
                  "13.5 (Little Boy)",
                  "12.5 (Little Boy)",
                  "31 (Little Boy),32 (Little Boy)",
                  "12.5 (Little Boy),13 (Little Boy)",
                ],
                [
                  "1 (Little Boy)",
                  "13 (Little Boy)",
                  "33 (Little Boy)",
                  "1 (Little Boy)",
                ],
                [
                  "1.5 (Little Boy)",
                  "14 (Little Boy)",
                  "33 (Little Boy)",
                  "1 (Little Boy)",
                ],
                [
                  "2 (Little Boy)",
                  "1 (Little Boy)",
                  "33 (Little Boy)",
                  "1 (Little Boy),1.5 (Little Boy)",
                ],
                [
                  "2.5 (Little Boy)",
                  "1.5 (Little Boy)",
                  "34 (Little Boy)",
                  "1 (Little Boy),1.5 (Little Boy)",
                ],
                [
                  "3 (Little Boy)",
                  "2 (Little Boy)",
                  "34 (Little Boy)",
                  "1.5 (Little Boy),2.5 (Big Boy)",
                ],
                [
                  "3.5 (Big Boy)",
                  "2.5 (Big Boy)",
                  "35 (Big Boy)",
                  "1.5 (Little Boy),2.5 (Big Boy)",
                ],
                [
                  "4 (Big Boy)",
                  "3 (Big Boy)",
                  "36 (Big Boy)",
                  "3 (Big Boy),3.5 (Big Boy)",
                ],
                [
                  "4.5 (Big Boy)",
                  "3.5 (Big Boy)",
                  "36 (Big Boy)",
                  "3 (Big Boy),3.5 (Big Boy)",
                ],
                [
                  "5 (Big Boy)",
                  "4 (Big Boy)",
                  "37 (Big Boy)",
                  "4 (Big Boy),4.5 (Big Boy)",
                ],
                [
                  "5.5 (Big Boy)",
                  "4.5 (Big Boy)",
                  "37 (Big Boy)",
                  "4 (Big Boy),4.5 (Big Boy)",
                ],
                [
                  "6 (Big Boy)",
                  "5 (Big Boy)",
                  "38 (Big Boy)",
                  "5 (Big Boy),5.5 (Big Boy)",
                ],
                [
                  "6.5 (Big Boy)",
                  "5.5 (Big Boy)",
                  "38 (Big Boy)",
                  "5 (Big Boy),5.5 (Big Boy)",
                ],
                ["7 (Big Boy)", "6 (Big Boy)", "39 (Big Boy)", "6 (Big Boy)"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "7.5B",
                      short: "7.5 (Toddler Boy)",
                      long: "7.5 (Toddler Boy)",
                      display: "7.5 (Toddler Boy)",
                      display_with_size_set: "7.5 (Toddler Boy)",
                      display_with_size_system: "US 7.5",
                      display_with_system_and_set: "US 7.5 (Toddler Boy)",
                      equivalent_sizes: {
                        au: ["6.5BAU"],
                        eu: ["23BEU"],
                        uk: ["6BUK", "7BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8B",
                      short: "8 (Toddler Boy)",
                      long: "8 (Toddler Boy)",
                      display: "8 (Toddler Boy)",
                      display_with_size_set: "8 (Toddler Boy)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Toddler Boy)",
                      equivalent_sizes: {
                        au: ["7BAU"],
                        eu: ["24BEU"],
                        uk: ["6BUK", "7BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8.5B",
                      short: "8.5 (Toddler Boy)",
                      long: "8.5 (Toddler Boy)",
                      display: "8.5 (Toddler Boy)",
                      display_with_size_set: "8.5 (Toddler Boy)",
                      display_with_size_system: "US 8.5",
                      display_with_system_and_set: "US 8.5 (Toddler Boy)",
                      equivalent_sizes: {
                        au: ["7.5BAU"],
                        eu: ["25BEU"],
                        uk: ["7.5BUK", "8BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9B",
                      short: "9 (Toddler Boy)",
                      long: "9 (Toddler Boy)",
                      display: "9 (Toddler Boy)",
                      display_with_size_set: "9 (Toddler Boy)",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9 (Toddler Boy)",
                      equivalent_sizes: {
                        au: ["8BAU"],
                        eu: ["25BEU"],
                        uk: ["7.5BUK", "8BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9.5B",
                      short: "9.5 (Toddler Boy)",
                      long: "9.5 (Toddler Boy)",
                      display: "9.5 (Toddler Boy)",
                      display_with_size_set: "9.5 (Toddler Boy)",
                      display_with_size_system: "US 9.5",
                      display_with_system_and_set: "US 9.5 (Toddler Boy)",
                      equivalent_sizes: {
                        au: ["8.5BAU"],
                        eu: ["26BEU"],
                        uk: ["8.5BUK", "9BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10B",
                      short: "10 (Toddler Boy)",
                      long: "10 (Toddler Boy)",
                      display: "10 (Toddler Boy)",
                      display_with_size_set: "10 (Toddler Boy)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Toddler Boy)",
                      equivalent_sizes: {
                        au: ["9BAU"],
                        eu: ["27BEU"],
                        uk: ["8.5BUK", "9BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10.5B",
                      short: "10.5 (Toddler Boy)",
                      long: "10.5 (Toddler Boy)",
                      display: "10.5 (Toddler Boy)",
                      display_with_size_set: "10.5 (Toddler Boy)",
                      display_with_size_system: "US 10.5",
                      display_with_system_and_set: "US 10.5 (Toddler Boy)",
                      equivalent_sizes: {
                        au: ["9.5BAU"],
                        eu: ["27BEU"],
                        uk: ["9.5BUK", "10BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11B",
                      short: "11 (Toddler Boy)",
                      long: "11 (Toddler Boy)",
                      display: "11 (Toddler Boy)",
                      display_with_size_set: "11 (Toddler Boy)",
                      display_with_size_system: "US 11",
                      display_with_system_and_set: "US 11 (Toddler Boy)",
                      equivalent_sizes: {
                        au: ["10BAU"],
                        eu: ["28BEU"],
                        uk: ["9.5BUK", "10BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "11.5B",
                      short: "11.5 (Toddler Boy)",
                      long: "11.5 (Toddler Boy)",
                      display: "11.5 (Toddler Boy)",
                      display_with_size_set: "11.5 (Toddler Boy)",
                      display_with_size_system: "US 11.5",
                      display_with_system_and_set: "US 11.5 (Toddler Boy)",
                      equivalent_sizes: {
                        au: ["10.5BAU"],
                        eu: ["29BEU"],
                        uk: ["11BUK", "11.5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12B",
                      short: "12 (Toddler Boy)",
                      long: "12 (Toddler Boy)",
                      display: "12 (Toddler Boy)",
                      display_with_size_set: "12 (Toddler Boy)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Toddler Boy)",
                      equivalent_sizes: {
                        au: ["11BAU"],
                        eu: ["30BEU"],
                        uk: ["11BUK", "11.5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12.5B",
                      short: "12.5 (Little Boy)",
                      long: "12.5 (Little Boy)",
                      display: "12.5 (Little Boy)",
                      display_with_size_set: "12.5 (Little Boy)",
                      display_with_size_system: "US 12.5",
                      display_with_system_and_set: "US 12.5 (Little Boy)",
                      equivalent_sizes: {
                        au: ["11.5BAU"],
                        eu: ["30BEU"],
                        uk: ["11.5BUK", "12.5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13B",
                      short: "13 (Little Boy)",
                      long: "13 (Little Boy)",
                      display: "13 (Little Boy)",
                      display_with_size_set: "13 (Little Boy)",
                      display_with_size_system: "US 13",
                      display_with_system_and_set: "US 13 (Little Boy)",
                      equivalent_sizes: {
                        au: ["12BAU"],
                        eu: ["31BEU"],
                        uk: ["11.5BUK", "12.5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "13.5B",
                      short: "13.5 (Little Boy)",
                      long: "13.5 (Little Boy)",
                      display: "13.5 (Little Boy)",
                      display_with_size_set: "13.5 (Little Boy)",
                      display_with_size_system: "US 13.5",
                      display_with_system_and_set: "US 13.5 (Little Boy)",
                      equivalent_sizes: {
                        au: ["12.5BAU"],
                        eu: ["31BEU", "32BEU"],
                        uk: ["12.5BUK", "13BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1B",
                      short: "1 (Little Boy)",
                      long: "1 (Little Boy)",
                      display: "1 (Little Boy)",
                      display_with_size_set: "1 (Little Boy)",
                      display_with_size_system: "US 1",
                      display_with_system_and_set: "US 1 (Little Boy)",
                      equivalent_sizes: {
                        au: ["13BAU"],
                        eu: ["33BEU"],
                        uk: ["1BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "1.5B",
                      short: "1.5 (Little Boy)",
                      long: "1.5 (Little Boy)",
                      display: "1.5 (Little Boy)",
                      display_with_size_set: "1.5 (Little Boy)",
                      display_with_size_system: "US 1.5",
                      display_with_system_and_set: "US 1.5 (Little Boy)",
                      equivalent_sizes: {
                        au: ["14BAU"],
                        eu: ["33BEU"],
                        uk: ["1BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2B",
                      short: "2 (Little Boy)",
                      long: "2 (Little Boy)",
                      display: "2 (Little Boy)",
                      display_with_size_set: "2 (Little Boy)",
                      display_with_size_system: "US 2",
                      display_with_system_and_set: "US 2 (Little Boy)",
                      equivalent_sizes: {
                        au: ["1BAU"],
                        eu: ["33BEU"],
                        uk: ["1BUK", "1.5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "2.5B",
                      short: "2.5 (Little Boy)",
                      long: "2.5 (Little Boy)",
                      display: "2.5 (Little Boy)",
                      display_with_size_set: "2.5 (Little Boy)",
                      display_with_size_system: "US 2.5",
                      display_with_system_and_set: "US 2.5 (Little Boy)",
                      equivalent_sizes: {
                        au: ["1.5BAU"],
                        eu: ["34BEU"],
                        uk: ["1BUK", "1.5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3B",
                      short: "3 (Little Boy)",
                      long: "3 (Little Boy)",
                      display: "3 (Little Boy)",
                      display_with_size_set: "3 (Little Boy)",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3 (Little Boy)",
                      equivalent_sizes: {
                        au: ["2BAU"],
                        eu: ["34BEU"],
                        uk: ["1.5BUK", "2.5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3.5B",
                      short: "3.5 (Big Boy)",
                      long: "3.5 (Big Boy)",
                      display: "3.5 (Big Boy)",
                      display_with_size_set: "3.5 (Big Boy)",
                      display_with_size_system: "US 3.5",
                      display_with_system_and_set: "US 3.5 (Big Boy)",
                      equivalent_sizes: {
                        au: ["2.5BAU"],
                        eu: ["35BEU"],
                        uk: ["1.5BUK", "2.5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4B",
                      short: "4 (Big Boy)",
                      long: "4 (Big Boy)",
                      display: "4 (Big Boy)",
                      display_with_size_set: "4 (Big Boy)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Big Boy)",
                      equivalent_sizes: {
                        au: ["3BAU"],
                        eu: ["36BEU"],
                        uk: ["3BUK", "3.5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4.5B",
                      short: "4.5 (Big Boy)",
                      long: "4.5 (Big Boy)",
                      display: "4.5 (Big Boy)",
                      display_with_size_set: "4.5 (Big Boy)",
                      display_with_size_system: "US 4.5",
                      display_with_system_and_set: "US 4.5 (Big Boy)",
                      equivalent_sizes: {
                        au: ["3.5BAU"],
                        eu: ["36BEU"],
                        uk: ["3BUK", "3.5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5B",
                      short: "5 (Big Boy)",
                      long: "5 (Big Boy)",
                      display: "5 (Big Boy)",
                      display_with_size_set: "5 (Big Boy)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Big Boy)",
                      equivalent_sizes: {
                        au: ["4BAU"],
                        eu: ["37BEU"],
                        uk: ["4BUK", "4.5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5.5B",
                      short: "5.5 (Big Boy)",
                      long: "5.5 (Big Boy)",
                      display: "5.5 (Big Boy)",
                      display_with_size_set: "5.5 (Big Boy)",
                      display_with_size_system: "US 5.5",
                      display_with_system_and_set: "US 5.5 (Big Boy)",
                      equivalent_sizes: {
                        au: ["4.5BAU"],
                        eu: ["37BEU"],
                        uk: ["4BUK", "4.5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6B",
                      short: "6 (Big Boy)",
                      long: "6 (Big Boy)",
                      display: "6 (Big Boy)",
                      display_with_size_set: "6 (Big Boy)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Big Boy)",
                      equivalent_sizes: {
                        au: ["5BAU"],
                        eu: ["38BEU"],
                        uk: ["5BUK", "5.5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6.5B",
                      short: "6.5 (Big Boy)",
                      long: "6.5 (Big Boy)",
                      display: "6.5 (Big Boy)",
                      display_with_size_set: "6.5 (Big Boy)",
                      display_with_size_system: "US 6.5",
                      display_with_system_and_set: "US 6.5 (Big Boy)",
                      equivalent_sizes: {
                        au: ["5.5BAU"],
                        eu: ["38BEU"],
                        uk: ["5BUK", "5.5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7B",
                      short: "7 (Big Boy)",
                      long: "7 (Big Boy)",
                      display: "7 (Big Boy)",
                      display_with_size_set: "7 (Big Boy)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Big Boy)",
                      equivalent_sizes: {
                        au: ["6BAU"],
                        eu: ["39BEU"],
                        uk: ["6BUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "1BAU",
                      short: "1 (Little Boy)",
                      long: "1 (Little Boy)",
                      display: "1 (Little Boy)",
                      display_with_size_set: "1 (Little Boy)",
                      display_with_size_system: "AU 1",
                      display_with_system_and_set: "AU 1 (Little Boy)",
                      equivalent_sizes: {
                        us: ["2B", "2.5B"],
                        eu: ["33BEU", "34BEU"],
                        uk: ["1BUK", "1.5BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1.5BAU",
                      short: "1.5 (Little Boy)",
                      long: "1.5 (Little Boy)",
                      display: "1.5 (Little Boy)",
                      display_with_size_set: "1.5 (Little Boy)",
                      display_with_size_system: "AU 1.5",
                      display_with_system_and_set: "AU 1.5 (Little Boy)",
                      equivalent_sizes: {
                        us: ["2B", "2.5B"],
                        eu: ["33BEU", "34BEU"],
                        uk: ["1BUK", "1.5BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2BAU",
                      short: "2 (Little Boy)",
                      long: "2 (Little Boy)",
                      display: "2 (Little Boy)",
                      display_with_size_set: "2 (Little Boy)",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2 (Little Boy)",
                      equivalent_sizes: {
                        us: ["3B", "3.5B"],
                        eu: ["34BEU", "35BEU"],
                        uk: ["1.5BUK", "2.5BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2.5BAU",
                      short: "2.5 (Big Boy)",
                      long: "2.5 (Big Boy)",
                      display: "2.5 (Big Boy)",
                      display_with_size_set: "2.5 (Big Boy)",
                      display_with_size_system: "AU 2.5",
                      display_with_system_and_set: "AU 2.5 (Big Boy)",
                      equivalent_sizes: {
                        us: ["3B", "3.5B"],
                        eu: ["34BEU", "35BEU"],
                        uk: ["1.5BUK", "2.5BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3BAU",
                      short: "3 (Big Boy)",
                      long: "3 (Big Boy)",
                      display: "3 (Big Boy)",
                      display_with_size_set: "3 (Big Boy)",
                      display_with_size_system: "AU 3",
                      display_with_system_and_set: "AU 3 (Big Boy)",
                      equivalent_sizes: {
                        us: ["4B", "4.5B"],
                        eu: ["36BEU"],
                        uk: ["3BUK", "3.5BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "3.5BAU",
                      short: "3.5 (Big Boy)",
                      long: "3.5 (Big Boy)",
                      display: "3.5 (Big Boy)",
                      display_with_size_set: "3.5 (Big Boy)",
                      display_with_size_system: "AU 3.5",
                      display_with_system_and_set: "AU 3.5 (Big Boy)",
                      equivalent_sizes: {
                        us: ["4B", "4.5B"],
                        eu: ["36BEU"],
                        uk: ["3BUK", "3.5BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4BAU",
                      short: "4 (Big Boy)",
                      long: "4 (Big Boy)",
                      display: "4 (Big Boy)",
                      display_with_size_set: "4 (Big Boy)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Big Boy)",
                      equivalent_sizes: {
                        us: ["5B", "5.5B"],
                        eu: ["37BEU"],
                        uk: ["4BUK", "4.5BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4.5BAU",
                      short: "4.5 (Big Boy)",
                      long: "4.5 (Big Boy)",
                      display: "4.5 (Big Boy)",
                      display_with_size_set: "4.5 (Big Boy)",
                      display_with_size_system: "AU 4.5",
                      display_with_system_and_set: "AU 4.5 (Big Boy)",
                      equivalent_sizes: {
                        us: ["5B", "5.5B"],
                        eu: ["37BEU"],
                        uk: ["4BUK", "4.5BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5BAU",
                      short: "5 (Big Boy)",
                      long: "5 (Big Boy)",
                      display: "5 (Big Boy)",
                      display_with_size_set: "5 (Big Boy)",
                      display_with_size_system: "AU 5",
                      display_with_system_and_set: "AU 5 (Big Boy)",
                      equivalent_sizes: {
                        us: ["6B", "6.5B"],
                        eu: ["38BEU"],
                        uk: ["5BUK", "5.5BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5.5BAU",
                      short: "5.5 (Big Boy)",
                      long: "5.5 (Big Boy)",
                      display: "5.5 (Big Boy)",
                      display_with_size_set: "5.5 (Big Boy)",
                      display_with_size_system: "AU 5.5",
                      display_with_system_and_set: "AU 5.5 (Big Boy)",
                      equivalent_sizes: {
                        us: ["6B", "6.5B"],
                        eu: ["38BEU"],
                        uk: ["5BUK", "5.5BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6BAU",
                      short: "6 (Big Boy)",
                      long: "6 (Big Boy)",
                      display: "6 (Big Boy)",
                      display_with_size_set: "6 (Big Boy)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Big Boy)",
                      equivalent_sizes: {
                        us: ["7B"],
                        eu: ["39BEU"],
                        uk: ["6BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6.5BAU",
                      short: "6.5 (Toddler Boy)",
                      long: "6.5 (Toddler Boy)",
                      display: "6.5 (Toddler Boy)",
                      display_with_size_set: "6.5 (Toddler Boy)",
                      display_with_size_system: "AU 6.5",
                      display_with_system_and_set: "AU 6.5 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["7.5B", "8B"],
                        eu: ["23BEU", "24BEU"],
                        uk: ["6BUK", "7BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "7BAU",
                      short: "7 (Toddler Boy)",
                      long: "7 (Toddler Boy)",
                      display: "7 (Toddler Boy)",
                      display_with_size_set: "7 (Toddler Boy)",
                      display_with_size_system: "AU 7",
                      display_with_system_and_set: "AU 7 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["7.5B", "8B"],
                        eu: ["23BEU", "24BEU"],
                        uk: ["6BUK", "7BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "7.5BAU",
                      short: "7.5 (Toddler Boy)",
                      long: "7.5 (Toddler Boy)",
                      display: "7.5 (Toddler Boy)",
                      display_with_size_set: "7.5 (Toddler Boy)",
                      display_with_size_system: "AU 7.5",
                      display_with_system_and_set: "AU 7.5 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["8.5B", "9B"],
                        eu: ["25BEU"],
                        uk: ["7.5BUK", "8BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8BAU",
                      short: "8 (Toddler Boy)",
                      long: "8 (Toddler Boy)",
                      display: "8 (Toddler Boy)",
                      display_with_size_set: "8 (Toddler Boy)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["8.5B", "9B"],
                        eu: ["25BEU"],
                        uk: ["7.5BUK", "8BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8.5BAU",
                      short: "8.5 (Toddler Boy)",
                      long: "8.5 (Toddler Boy)",
                      display: "8.5 (Toddler Boy)",
                      display_with_size_set: "8.5 (Toddler Boy)",
                      display_with_size_system: "AU 8.5",
                      display_with_system_and_set: "AU 8.5 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["9.5B", "10B"],
                        eu: ["26BEU", "27BEU"],
                        uk: ["8.5BUK", "9BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "9BAU",
                      short: "9 (Toddler Boy)",
                      long: "9 (Toddler Boy)",
                      display: "9 (Toddler Boy)",
                      display_with_size_set: "9 (Toddler Boy)",
                      display_with_size_system: "AU 9",
                      display_with_system_and_set: "AU 9 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["9.5B", "10B"],
                        eu: ["26BEU", "27BEU"],
                        uk: ["8.5BUK", "9BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "9.5BAU",
                      short: "9.5 (Toddler Boy)",
                      long: "9.5 (Toddler Boy)",
                      display: "9.5 (Toddler Boy)",
                      display_with_size_set: "9.5 (Toddler Boy)",
                      display_with_size_system: "AU 9.5",
                      display_with_system_and_set: "AU 9.5 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["10.5B", "11B"],
                        eu: ["27BEU", "28BEU"],
                        uk: ["9.5BUK", "10BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10BAU",
                      short: "10 (Toddler Boy)",
                      long: "10 (Toddler Boy)",
                      display: "10 (Toddler Boy)",
                      display_with_size_set: "10 (Toddler Boy)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["10.5B", "11B"],
                        eu: ["27BEU", "28BEU"],
                        uk: ["9.5BUK", "10BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10.5BAU",
                      short: "10.5 (Toddler Boy)",
                      long: "10.5 (Toddler Boy)",
                      display: "10.5 (Toddler Boy)",
                      display_with_size_set: "10.5 (Toddler Boy)",
                      display_with_size_system: "AU 10.5",
                      display_with_system_and_set: "AU 10.5 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["11.5B", "12B"],
                        eu: ["29BEU", "30BEU"],
                        uk: ["11BUK", "11.5BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11BAU",
                      short: "11 (Toddler Boy)",
                      long: "11 (Toddler Boy)",
                      display: "11 (Toddler Boy)",
                      display_with_size_set: "11 (Toddler Boy)",
                      display_with_size_system: "AU 11",
                      display_with_system_and_set: "AU 11 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["11.5B", "12B"],
                        eu: ["29BEU", "30BEU"],
                        uk: ["11BUK", "11.5BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "11.5BAU",
                      short: "11.5 (Little Boy)",
                      long: "11.5 (Little Boy)",
                      display: "11.5 (Little Boy)",
                      display_with_size_set: "11.5 (Little Boy)",
                      display_with_size_system: "AU 11.5",
                      display_with_system_and_set: "AU 11.5 (Little Boy)",
                      equivalent_sizes: {
                        us: ["12.5B", "13B"],
                        eu: ["30BEU", "31BEU"],
                        uk: ["11.5BUK", "12.5BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12BAU",
                      short: "12 (Little Boy)",
                      long: "12 (Little Boy)",
                      display: "12 (Little Boy)",
                      display_with_size_set: "12 (Little Boy)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Little Boy)",
                      equivalent_sizes: {
                        us: ["12.5B", "13B"],
                        eu: ["30BEU", "31BEU"],
                        uk: ["11.5BUK", "12.5BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12.5BAU",
                      short: "12.5 (Little Boy)",
                      long: "12.5 (Little Boy)",
                      display: "12.5 (Little Boy)",
                      display_with_size_set: "12.5 (Little Boy)",
                      display_with_size_system: "AU 12.5",
                      display_with_system_and_set: "AU 12.5 (Little Boy)",
                      equivalent_sizes: {
                        us: ["13.5B"],
                        eu: ["31BEU", "32BEU"],
                        uk: ["12.5BUK", "13BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "13BAU",
                      short: "13 (Little Boy)",
                      long: "13 (Little Boy)",
                      display: "13 (Little Boy)",
                      display_with_size_set: "13 (Little Boy)",
                      display_with_size_system: "AU 13",
                      display_with_system_and_set: "AU 13 (Little Boy)",
                      equivalent_sizes: {
                        us: ["1B", "1.5B"],
                        eu: ["33BEU"],
                        uk: ["1BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14BAU",
                      short: "14 (Little Boy)",
                      long: "14 (Little Boy)",
                      display: "14 (Little Boy)",
                      display_with_size_set: "14 (Little Boy)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Little Boy)",
                      equivalent_sizes: {
                        us: ["1B", "1.5B"],
                        eu: ["33BEU"],
                        uk: ["1BUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "23BEU",
                      short: "23 (Toddler Boy)",
                      long: "23 (Toddler Boy)",
                      display: "23 (Toddler Boy)",
                      display_with_size_set: "23 (Toddler Boy)",
                      display_with_size_system: "EU 23",
                      display_with_system_and_set: "EU 23 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["7.5B", "8B"],
                        au: ["6.5BAU", "7BAU"],
                        uk: ["6BUK", "7BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "24BEU",
                      short: "24 (Toddler Boy)",
                      long: "24 (Toddler Boy)",
                      display: "24 (Toddler Boy)",
                      display_with_size_set: "24 (Toddler Boy)",
                      display_with_size_system: "EU 24",
                      display_with_system_and_set: "EU 24 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["7.5B", "8B"],
                        au: ["6.5BAU", "7BAU"],
                        uk: ["6BUK", "7BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "25BEU",
                      short: "25 (Toddler Boy)",
                      long: "25 (Toddler Boy)",
                      display: "25 (Toddler Boy)",
                      display_with_size_set: "25 (Toddler Boy)",
                      display_with_size_system: "EU 25",
                      display_with_system_and_set: "EU 25 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["8.5B", "9B"],
                        au: ["7.5BAU", "8BAU"],
                        uk: ["7.5BUK", "8BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "26BEU",
                      short: "26 (Toddler Boy)",
                      long: "26 (Toddler Boy)",
                      display: "26 (Toddler Boy)",
                      display_with_size_set: "26 (Toddler Boy)",
                      display_with_size_system: "EU 26",
                      display_with_system_and_set: "EU 26 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["9.5B", "10B"],
                        au: ["8.5BAU", "9BAU"],
                        uk: ["8.5BUK", "9BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "27BEU",
                      short: "27 (Toddler Boy)",
                      long: "27 (Toddler Boy)",
                      display: "27 (Toddler Boy)",
                      display_with_size_set: "27 (Toddler Boy)",
                      display_with_size_system: "EU 27",
                      display_with_system_and_set: "EU 27 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["9.5B", "10B", "10.5B", "11B"],
                        au: ["8.5BAU", "9BAU", "9.5BAU", "10BAU"],
                        uk: ["9.5BUK", "10BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "28BEU",
                      short: "28 (Toddler Boy)",
                      long: "28 (Toddler Boy)",
                      display: "28 (Toddler Boy)",
                      display_with_size_set: "28 (Toddler Boy)",
                      display_with_size_system: "EU 28",
                      display_with_system_and_set: "EU 28 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["10.5B", "11B"],
                        au: ["9.5BAU", "10BAU"],
                        uk: ["9.5BUK", "10BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "29BEU",
                      short: "29 (Toddler Boy)",
                      long: "29 (Toddler Boy)",
                      display: "29 (Toddler Boy)",
                      display_with_size_set: "29 (Toddler Boy)",
                      display_with_size_system: "EU 29",
                      display_with_system_and_set: "EU 29 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["11.5B", "12B"],
                        au: ["10.5BAU", "11BAU"],
                        uk: ["11BUK", "11.5BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "30BEU",
                      short: "30 (Toddler Boy)",
                      long: "30 (Toddler Boy)",
                      display: "30 (Toddler Boy)",
                      display_with_size_set: "30 (Toddler Boy)",
                      display_with_size_system: "EU 30",
                      display_with_system_and_set: "EU 30 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["11.5B", "12B", "12.5B", "13B"],
                        au: ["10.5BAU", "11BAU", "11.5BAU", "12BAU"],
                        uk: ["11BUK", "11.5BUK", "12.5BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "31BEU",
                      short: "31 (Little Boy)",
                      long: "31 (Little Boy)",
                      display: "31 (Little Boy)",
                      display_with_size_set: "31 (Little Boy)",
                      display_with_size_system: "EU 31",
                      display_with_system_and_set: "EU 31 (Little Boy)",
                      equivalent_sizes: {
                        us: ["12.5B", "13B", "13.5B"],
                        au: ["11.5BAU", "12BAU", "12.5BAU"],
                        uk: ["11.5BUK", "12.5BUK", "13BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "32BEU",
                      short: "32 (Little Boy)",
                      long: "32 (Little Boy)",
                      display: "32 (Little Boy)",
                      display_with_size_set: "32 (Little Boy)",
                      display_with_size_system: "EU 32",
                      display_with_system_and_set: "EU 32 (Little Boy)",
                      equivalent_sizes: {
                        us: ["13.5B"],
                        au: ["12.5BAU"],
                        uk: ["12.5BUK", "13BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "33BEU",
                      short: "33 (Little Boy)",
                      long: "33 (Little Boy)",
                      display: "33 (Little Boy)",
                      display_with_size_set: "33 (Little Boy)",
                      display_with_size_system: "EU 33",
                      display_with_system_and_set: "EU 33 (Little Boy)",
                      equivalent_sizes: {
                        us: ["1B", "1.5B", "2B", "2.5B"],
                        au: ["1BAU", "1.5BAU", "13BAU", "14BAU"],
                        uk: ["1BUK", "1.5BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "34BEU",
                      short: "34 (Little Boy)",
                      long: "34 (Little Boy)",
                      display: "34 (Little Boy)",
                      display_with_size_set: "34 (Little Boy)",
                      display_with_size_system: "EU 34",
                      display_with_system_and_set: "EU 34 (Little Boy)",
                      equivalent_sizes: {
                        us: ["2B", "2.5B", "3B", "3.5B"],
                        au: ["1BAU", "1.5BAU", "2BAU", "2.5BAU"],
                        uk: ["1.5BUK", "2.5BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "35BEU",
                      short: "35 (Big Boy)",
                      long: "35 (Big Boy)",
                      display: "35 (Big Boy)",
                      display_with_size_set: "35 (Big Boy)",
                      display_with_size_system: "EU 35",
                      display_with_system_and_set: "EU 35 (Big Boy)",
                      equivalent_sizes: {
                        us: ["3B", "3.5B"],
                        au: ["2BAU", "2.5BAU"],
                        uk: ["1.5BUK", "2.5BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "36BEU",
                      short: "36 (Big Boy)",
                      long: "36 (Big Boy)",
                      display: "36 (Big Boy)",
                      display_with_size_set: "36 (Big Boy)",
                      display_with_size_system: "EU 36",
                      display_with_system_and_set: "EU 36 (Big Boy)",
                      equivalent_sizes: {
                        us: ["4B", "4.5B"],
                        au: ["3BAU", "3.5BAU"],
                        uk: ["3BUK", "3.5BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "37BEU",
                      short: "37 (Big Boy)",
                      long: "37 (Big Boy)",
                      display: "37 (Big Boy)",
                      display_with_size_set: "37 (Big Boy)",
                      display_with_size_system: "EU 37",
                      display_with_system_and_set: "EU 37 (Big Boy)",
                      equivalent_sizes: {
                        us: ["5B", "5.5B"],
                        au: ["4BAU", "4.5BAU"],
                        uk: ["4BUK", "4.5BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "38BEU",
                      short: "38 (Big Boy)",
                      long: "38 (Big Boy)",
                      display: "38 (Big Boy)",
                      display_with_size_set: "38 (Big Boy)",
                      display_with_size_system: "EU 38",
                      display_with_system_and_set: "EU 38 (Big Boy)",
                      equivalent_sizes: {
                        us: ["6B", "6.5B"],
                        au: ["5BAU", "5.5BAU"],
                        uk: ["5BUK", "5.5BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "39BEU",
                      short: "39 (Big Boy)",
                      long: "39 (Big Boy)",
                      display: "39 (Big Boy)",
                      display_with_size_set: "39 (Big Boy)",
                      display_with_size_system: "EU 39",
                      display_with_system_and_set: "EU 39 (Big Boy)",
                      equivalent_sizes: {
                        us: ["7B"],
                        au: ["6BAU"],
                        uk: ["6BUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "1BUK",
                      short: "1 (Little Boy)",
                      long: "1 (Little Boy)",
                      display: "1 (Little Boy)",
                      display_with_size_set: "1 (Little Boy)",
                      display_with_size_system: "UK 1",
                      display_with_system_and_set: "UK 1 (Little Boy)",
                      equivalent_sizes: {
                        us: ["1B", "1.5B", "2B", "2.5B"],
                        au: ["1BAU", "1.5BAU", "13BAU", "14BAU"],
                        eu: ["33BEU", "34BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "1.5BUK",
                      short: "1.5 (Little Boy)",
                      long: "1.5 (Little Boy)",
                      display: "1.5 (Little Boy)",
                      display_with_size_set: "1.5 (Little Boy)",
                      display_with_size_system: "UK 1.5",
                      display_with_system_and_set: "UK 1.5 (Little Boy)",
                      equivalent_sizes: {
                        us: ["2B", "2.5B", "3B", "3.5B"],
                        au: ["1BAU", "1.5BAU", "2BAU", "2.5BAU"],
                        eu: ["33BEU", "34BEU", "35BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "2.5BUK",
                      short: "2.5 (Big Boy)",
                      long: "2.5 (Big Boy)",
                      display: "2.5 (Big Boy)",
                      display_with_size_set: "2.5 (Big Boy)",
                      display_with_size_system: "UK 2.5",
                      display_with_system_and_set: "UK 2.5 (Big Boy)",
                      equivalent_sizes: {
                        us: ["3B", "3.5B"],
                        au: ["2BAU", "2.5BAU"],
                        eu: ["34BEU", "35BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3BUK",
                      short: "3 (Big Boy)",
                      long: "3 (Big Boy)",
                      display: "3 (Big Boy)",
                      display_with_size_set: "3 (Big Boy)",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3 (Big Boy)",
                      equivalent_sizes: {
                        us: ["4B", "4.5B"],
                        au: ["3BAU", "3.5BAU"],
                        eu: ["36BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3.5BUK",
                      short: "3.5 (Big Boy)",
                      long: "3.5 (Big Boy)",
                      display: "3.5 (Big Boy)",
                      display_with_size_set: "3.5 (Big Boy)",
                      display_with_size_system: "UK 3.5",
                      display_with_system_and_set: "UK 3.5 (Big Boy)",
                      equivalent_sizes: {
                        us: ["4B", "4.5B"],
                        au: ["3BAU", "3.5BAU"],
                        eu: ["36BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4BUK",
                      short: "4 (Big Boy)",
                      long: "4 (Big Boy)",
                      display: "4 (Big Boy)",
                      display_with_size_set: "4 (Big Boy)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Big Boy)",
                      equivalent_sizes: {
                        us: ["5B", "5.5B"],
                        au: ["4BAU", "4.5BAU"],
                        eu: ["37BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4.5BUK",
                      short: "4.5 (Big Boy)",
                      long: "4.5 (Big Boy)",
                      display: "4.5 (Big Boy)",
                      display_with_size_set: "4.5 (Big Boy)",
                      display_with_size_system: "UK 4.5",
                      display_with_system_and_set: "UK 4.5 (Big Boy)",
                      equivalent_sizes: {
                        us: ["5B", "5.5B"],
                        au: ["4BAU", "4.5BAU"],
                        eu: ["37BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5BUK",
                      short: "5 (Big Boy)",
                      long: "5 (Big Boy)",
                      display: "5 (Big Boy)",
                      display_with_size_set: "5 (Big Boy)",
                      display_with_size_system: "UK 5",
                      display_with_system_and_set: "UK 5 (Big Boy)",
                      equivalent_sizes: {
                        us: ["6B", "6.5B"],
                        au: ["5BAU", "5.5BAU"],
                        eu: ["38BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5.5BUK",
                      short: "5.5 (Big Boy)",
                      long: "5.5 (Big Boy)",
                      display: "5.5 (Big Boy)",
                      display_with_size_set: "5.5 (Big Boy)",
                      display_with_size_system: "UK 5.5",
                      display_with_system_and_set: "UK 5.5 (Big Boy)",
                      equivalent_sizes: {
                        us: ["6B", "6.5B"],
                        au: ["5BAU", "5.5BAU"],
                        eu: ["38BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6BUK",
                      short: "6 (Big Boy)",
                      long: "6 (Big Boy)",
                      display: "6 (Big Boy)",
                      display_with_size_set: "6 (Big Boy)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Big Boy)",
                      equivalent_sizes: {
                        us: ["7B", "7.5B", "8B"],
                        au: ["6BAU", "6.5BAU", "7BAU"],
                        eu: ["23BEU", "24BEU", "39BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "7BUK",
                      short: "7 (Toddler Boy)",
                      long: "7 (Toddler Boy)",
                      display: "7 (Toddler Boy)",
                      display_with_size_set: "7 (Toddler Boy)",
                      display_with_size_system: "UK 7",
                      display_with_system_and_set: "UK 7 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["7.5B", "8B"],
                        au: ["7BAU"],
                        eu: ["23BEU", "24BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "7.5BUK",
                      short: "7.5 (Toddler Boy)",
                      long: "7.5 (Toddler Boy)",
                      display: "7.5 (Toddler Boy)",
                      display_with_size_set: "7.5 (Toddler Boy)",
                      display_with_size_system: "UK 7.5",
                      display_with_system_and_set: "UK 7.5 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["8.5B", "9B"],
                        au: ["7.5BAU", "8BAU"],
                        eu: ["25BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8BUK",
                      short: "8 (Toddler Boy)",
                      long: "8 (Toddler Boy)",
                      display: "8 (Toddler Boy)",
                      display_with_size_set: "8 (Toddler Boy)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["8.5B", "9B"],
                        au: ["7.5BAU", "8BAU"],
                        eu: ["25BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8.5BUK",
                      short: "8.5 (Toddler Boy)",
                      long: "8.5 (Toddler Boy)",
                      display: "8.5 (Toddler Boy)",
                      display_with_size_set: "8.5 (Toddler Boy)",
                      display_with_size_system: "UK 8.5",
                      display_with_system_and_set: "UK 8.5 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["9.5B", "10B"],
                        au: ["8.5BAU", "9BAU"],
                        eu: ["26BEU", "27BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9BUK",
                      short: "9 (Toddler Boy)",
                      long: "9 (Toddler Boy)",
                      display: "9 (Toddler Boy)",
                      display_with_size_set: "9 (Toddler Boy)",
                      display_with_size_system: "UK 9",
                      display_with_system_and_set: "UK 9 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["9.5B", "10B"],
                        au: ["8.5BAU", "9BAU"],
                        eu: ["26BEU", "27BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9.5BUK",
                      short: "9.5 (Toddler Boy)",
                      long: "9.5 (Toddler Boy)",
                      display: "9.5 (Toddler Boy)",
                      display_with_size_set: "9.5 (Toddler Boy)",
                      display_with_size_system: "UK 9.5",
                      display_with_system_and_set: "UK 9.5 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["10.5B", "11B"],
                        au: ["9.5BAU", "10BAU"],
                        eu: ["27BEU", "28BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10BUK",
                      short: "10 (Toddler Boy)",
                      long: "10 (Toddler Boy)",
                      display: "10 (Toddler Boy)",
                      display_with_size_set: "10 (Toddler Boy)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["10.5B", "11B"],
                        au: ["9.5BAU", "10BAU"],
                        eu: ["27BEU", "28BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11BUK",
                      short: "11 (Toddler Boy)",
                      long: "11 (Toddler Boy)",
                      display: "11 (Toddler Boy)",
                      display_with_size_set: "11 (Toddler Boy)",
                      display_with_size_system: "UK 11",
                      display_with_system_and_set: "UK 11 (Toddler Boy)",
                      equivalent_sizes: {
                        us: ["11.5B", "12B"],
                        au: ["10.5BAU", "11BAU"],
                        eu: ["29BEU", "30BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "11.5BUK",
                      short: "11.5 (Little Boy)",
                      long: "11.5 (Little Boy)",
                      display: "11.5 (Little Boy)",
                      display_with_size_set: "11.5 (Little Boy)",
                      display_with_size_system: "UK 11.5",
                      display_with_system_and_set: "UK 11.5 (Little Boy)",
                      equivalent_sizes: {
                        us: ["11.5B", "12B", "12.5B", "13B"],
                        au: ["10.5BAU", "11BAU", "11.5BAU", "12BAU"],
                        eu: ["29BEU", "30BEU", "31BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12.5BUK",
                      short: "12.5 (Little Boy)",
                      long: "12.5 (Little Boy)",
                      display: "12.5 (Little Boy)",
                      display_with_size_set: "12.5 (Little Boy)",
                      display_with_size_system: "UK 12.5",
                      display_with_system_and_set: "UK 12.5 (Little Boy)",
                      equivalent_sizes: {
                        us: ["12.5B", "13B"],
                        au: ["11.5BAU", "12BAU", "12.5BAU"],
                        eu: ["30BEU", "31BEU", "32BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "13BUK",
                      short: "13 (Little Boy)",
                      long: "13 (Little Boy)",
                      display: "13 (Little Boy)",
                      display_with_size_set: "13 (Little Boy)",
                      display_with_size_system: "UK 13",
                      display_with_system_and_set: "UK 13 (Little Boy)",
                      equivalent_sizes: {
                        us: ["13.5B"],
                        au: ["12.5BAU"],
                        eu: ["31BEU", "32BEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "kids_shoes",
          aliases: [],
          slug: "Shoes",
          alias_slugs: [],
          parent_id: "20008c10d97b4e1245005764",
          category_features: [
            {
              id: "1a002f3cd97b4edf70005784",
              display: "Baby & Walker",
              type: "cf",
              message_id: "kids_shoes_baby_walker",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Baby_&_Walker",
              alias_slugs: [],
              parent_id: "29008c10d97b4e1245005764",
            },
            {
              id: "8e009813d97b4e3995005764",
              display: "Boots",
              type: "cf",
              message_id: "kids_shoes_boots",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Boots",
              alias_slugs: [],
              parent_id: "29008c10d97b4e1245005764",
            },
            {
              id: "8d009813d97b4e3995005764",
              display: "Dress Shoes",
              type: "cf",
              message_id: "kids_shoes_dress_shoes",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Dress_Shoes",
              alias_slugs: [],
              parent_id: "29008c10d97b4e1245005764",
            },
            {
              id: "1b002f3cd97b4edf70005784",
              display: "Moccasins",
              type: "cf",
              message_id: "kids_shoes_moccasins",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Moccasins",
              alias_slugs: [],
              parent_id: "29008c10d97b4e1245005764",
            },
            {
              id: "8f009813d97b4e3995005764",
              display: "Rain & Snow Boots",
              type: "cf",
              message_id: "kids_shoes_rain_snow_boots",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Rain_&_Snow_Boots",
              alias_slugs: [],
              parent_id: "29008c10d97b4e1245005764",
            },
            {
              id: "1c002f3cd97b4edf70005784",
              display: "Sandals & Flip Flops",
              type: "cf",
              message_id: "kids_shoes_sandals_flip_flops",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sandals_&_Flip_Flops",
              alias_slugs: [],
              parent_id: "29008c10d97b4e1245005764",
            },
            {
              id: "89009813d97b4e3995005764",
              display: "Slippers",
              type: "cf",
              message_id: "kids_shoes_slippers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Slippers",
              alias_slugs: [],
              parent_id: "29008c10d97b4e1245005764",
            },
            {
              id: "8b009813d97b4e3995005764",
              display: "Sneakers",
              type: "cf",
              message_id: "kids_shoes_sneakers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sneakers",
              alias_slugs: [],
              parent_id: "29008c10d97b4e1245005764",
            },
            {
              id: "8a009813d97b4e3995005764",
              display: "Water Shoes",
              type: "cf",
              message_id: "kids_shoes_water_shoes",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Water_Shoes",
              alias_slugs: [],
              parent_id: "29008c10d97b4e1245005764",
            },
          ],
        },
        {
          id: "21008c10d97b4e1245005764",
          display: "Accessories",
          size_sets: [
            {
              id: "25003667d97b4e6bdb005784",
              name: "Baby",
              tags: ["baby"],
              category_id: "21008c10d97b4e1245005764",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OSBB",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS (Baby)",
                      equivalent_sizes: {
                        au: ["OSBBAU"],
                        eu: ["OSBBEU"],
                        uk: ["OSBBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSBBAU",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS (Baby)",
                      equivalent_sizes: {
                        us: ["OSBB"],
                        eu: ["OSBBEU"],
                        uk: ["OSBBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSBBEU",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS (Baby)",
                      equivalent_sizes: {
                        us: ["OSBB"],
                        au: ["OSBBAU"],
                        uk: ["OSBBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSBBUK",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS (Baby)",
                      equivalent_sizes: {
                        us: ["OSBB"],
                        au: ["OSBBAU"],
                        eu: ["OSBBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "26003667d97b4e6bdb005784",
              name: "Boys",
              tags: ["boys"],
              category_id: "21008c10d97b4e1245005764",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OSB",
                      short: "OS (Boy)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Boy)",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS (Boy)",
                      equivalent_sizes: {
                        au: ["OSBAU"],
                        eu: ["OSBEU"],
                        uk: ["OSBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSBAU",
                      short: "OS (Boy)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Boy)",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS (Boy)",
                      equivalent_sizes: {
                        us: ["OSB"],
                        eu: ["OSBEU"],
                        uk: ["OSBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSBEU",
                      short: "OS (Boy)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Boy)",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS (Boy)",
                      equivalent_sizes: {
                        us: ["OSB"],
                        au: ["OSBAU"],
                        uk: ["OSBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSBUK",
                      short: "OS (Boy)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Boy)",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS (Boy)",
                      equivalent_sizes: {
                        us: ["OSB"],
                        au: ["OSBAU"],
                        eu: ["OSBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "27003667d97b4e6bdb005784",
              name: "Girls",
              tags: ["girls"],
              category_id: "21008c10d97b4e1245005764",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OSG",
                      short: "OS (Girl)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Girl)",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS (Girl)",
                      equivalent_sizes: {
                        au: ["OSGAU"],
                        eu: ["OSGEU"],
                        uk: ["OSGUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSGAU",
                      short: "OS (Girl)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Girl)",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS (Girl)",
                      equivalent_sizes: {
                        us: ["OSG"],
                        eu: ["OSGEU"],
                        uk: ["OSGUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSGEU",
                      short: "OS (Girl)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Girl)",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS (Girl)",
                      equivalent_sizes: {
                        us: ["OSG"],
                        au: ["OSGAU"],
                        uk: ["OSGUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSGUK",
                      short: "OS (Girl)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Girl)",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS (Girl)",
                      equivalent_sizes: {
                        us: ["OSG"],
                        au: ["OSGAU"],
                        eu: ["OSGEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "kids_accessories",
          aliases: [],
          slug: "Accessories",
          alias_slugs: [],
          parent_id: "20008c10d97b4e1245005764",
          category_features: [
            {
              id: "6b009813d97b4e3995005764",
              display: "Bags",
              type: "cf",
              message_id: "kids_accessories_bags",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bags",
              alias_slugs: [],
              parent_id: "21008c10d97b4e1245005764",
            },
            {
              id: "06002f3cd97b4edf70005784",
              display: "Belts",
              type: "cf",
              message_id: "kids_accessories_belts",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Belts",
              alias_slugs: [],
              parent_id: "21008c10d97b4e1245005764",
            },
            {
              id: "07002f3cd97b4edf70005784",
              display: "Bibs",
              type: "cf",
              message_id: "kids_accessories_bibs",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bibs",
              alias_slugs: [],
              parent_id: "21008c10d97b4e1245005764",
            },
            {
              id: "08002f3cd97b4edf70005784",
              display: "Diaper Covers",
              type: "cf",
              message_id: "kids_accessories_diaper_covers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Diaper_Covers",
              alias_slugs: [],
              parent_id: "21008c10d97b4e1245005764",
            },
            {
              id: "b67df9aaaabb083120f45ec2",
              display: "Face Masks",
              type: "cf",
              message_id: "kids_accessories_face_masks",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Face_Masks",
              alias_slugs: [],
              parent_id: "21008c10d97b4e1245005764",
            },
            {
              id: "6e009813d97b4e3995005764",
              display: "Hair Accessories",
              type: "cf",
              message_id: "kids_accessories_hair_accessories",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Hair_Accessories",
              alias_slugs: [],
              parent_id: "21008c10d97b4e1245005764",
            },
            {
              id: "09002f3cd97b4edf70005784",
              display: "Hats",
              type: "cf",
              message_id: "kids_accessories_hats",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Hats",
              alias_slugs: [],
              parent_id: "21008c10d97b4e1245005764",
            },
            {
              id: "6d009813d97b4e3995005764",
              display: "Jewelry",
              type: "cf",
              message_id: "kids_accessories_jewelry",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Jewelry",
              alias_slugs: [],
              parent_id: "21008c10d97b4e1245005764",
            },
            {
              id: "0a002f3cd97b4edf70005784",
              display: "Mittens",
              type: "cf",
              message_id: "kids_accessories_mittens",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Mittens",
              alias_slugs: [],
              parent_id: "21008c10d97b4e1245005764",
            },
            {
              id: "0b002f3cd97b4edf70005784",
              display: "Socks & Tights",
              type: "cf",
              message_id: "kids_accessories_socks_tights",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Socks_&_Tights",
              alias_slugs: [],
              parent_id: "21008c10d97b4e1245005764",
            },
            {
              id: "0c002f3cd97b4edf70005784",
              display: "Sunglasses",
              type: "cf",
              message_id: "kids_accessories_sunglasses",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sunglasses",
              alias_slugs: [],
              parent_id: "21008c10d97b4e1245005764",
            },
            {
              id: "0d002f3cd97b4edf70005784",
              display: "Suspenders",
              type: "cf",
              message_id: "kids_accessories_suspenders",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Suspenders",
              alias_slugs: [],
              parent_id: "21008c10d97b4e1245005764",
            },
            {
              id: "0e002f3cd97b4edf70005784",
              display: "Ties",
              type: "cf",
              message_id: "kids_accessories_ties",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Ties",
              alias_slugs: [],
              parent_id: "21008c10d97b4e1245005764",
            },
            {
              id: "0f002f3cd97b4edf70005784",
              display: "Underwear",
              type: "cf",
              message_id: "kids_accessories_underwear",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Underwear",
              alias_slugs: [],
              parent_id: "21008c10d97b4e1245005764",
            },
            {
              id: "6c009813d97b4e3995005764",
              display: "Watches",
              type: "cf",
              message_id: "kids_accessories_watches",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Watches",
              alias_slugs: [],
              parent_id: "21008c10d97b4e1245005764",
            },
          ],
        },
        {
          id: "24002b34d97b4efb71005784",
          display: "Bottoms",
          size_sets: [
            {
              id: "13003667d97b4e6bdb005784",
              name: "Baby",
              tags: ["baby"],
              category_id: "24002b34d97b4efb71005784",
              measurements_chart: {
                inches: [
                  ["Height ", "Waist ", "Hips "],
                  ["0-18", "15", "15"],
                  ["0-18", "16.5", "16.5"],
                  ["18-23", "17.5", "17.5"],
                  ["23-27", "18.5", "18.5"],
                  ["27-28", "19", "19"],
                  ["28-29", "19.5", "19.5"],
                  ["29-31", "20.25", "20.25"],
                  ["31-33", "21", "21"],
                  ["18-23", "17.5", "17.5"],
                  ["23-27", "18.5", "18.5"],
                  ["27-28", "19", "19"],
                  ["28-29", "19.5", "19.5"],
                  ["29-31", "20.25", "20.25"],
                  ["31-33", "21", "21"],
                ],
                centimeters: [
                  ["Height ", "Waist ", "Hips "],
                  ["0-45.7", "38.1", "38.1"],
                  ["0-45.7", "41.9", "41.9"],
                  ["45.7-58.4", "44.5", "44.5"],
                  ["58.4-68.6", "47.0", "47.0"],
                  ["68.6-71.1", "48.3", "48.3"],
                  ["71.1-73.7", "49.5", "49.5"],
                  ["73.7-78.7", "51.4", "51.4"],
                  ["78.7-83.8", "53.3", "53.3"],
                  ["45.7-58.4", "44.5", "44.5"],
                  ["58.4-68.6", "47.0", "47.0"],
                  ["68.6-71.1", "48.3", "48.3"],
                  ["71.1-73.7", "49.5", "49.5"],
                  ["73.7-78.7", "51.4", "51.4"],
                  ["78.7-83.8", "53.3", "53.3"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["Preemie", "00000", "Preemie", "Preemie"],
                  ["Newborn", "00000", "Newborn", "Newborn"],
                  ["0-3 Months", "0000", "0-3 Months", "0-3 Months"],
                  ["3-6 Months", "000", "3-6 Months", "3-6 Months"],
                  ["6-9 Months", "000", "6-9 Months", "6-9 Months"],
                  ["9-12 Months", "00", "9-12 Months", "9-12 Months"],
                  ["12-18 Months", "0", "12-18 Months", "12-18 Months"],
                  ["18-24 Months", "1", "18-24 Months", "18-24 Months"],
                  ["3 Months", "0000,000", "3 Months", "3 Months"],
                  ["6 Months", "000", "6 Months", "6 Months"],
                  ["9 Months", "000,00", "9 Months", "9 Months"],
                  ["12 Months", "00,0", "12 Months", "12 Months"],
                  ["18 Months", "0,1", "18 Months", "18 Months"],
                  ["24 Months", "1,2", "24 Months", "24 Months"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["Preemie", "00000", "Preemie", "Preemie"],
                ["Newborn", "00000", "Newborn", "Newborn"],
                ["0-3 Months", "0000", "0-3 Months", "0-3 Months"],
                ["3-6 Months", "000", "3-6 Months", "3-6 Months"],
                ["6-9 Months", "000", "6-9 Months", "6-9 Months"],
                ["9-12 Months", "00", "9-12 Months", "9-12 Months"],
                ["12-18 Months", "0", "12-18 Months", "12-18 Months"],
                ["18-24 Months", "1", "18-24 Months", "18-24 Months"],
                ["3 Months", "0000,000", "3 Months", "3 Months"],
                ["6 Months", "000", "6 Months", "6 Months"],
                ["9 Months", "000,00", "9 Months", "9 Months"],
                ["12 Months", "00,0", "12 Months", "12 Months"],
                ["18 Months", "0,1", "18 Months", "18 Months"],
                ["24 Months", "1,2", "24 Months", "24 Months"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "Preemie",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "US Preemie",
                      display_with_system_and_set: "US Preemie",
                      equivalent_sizes: {
                        au: ["00000MBAU"],
                        eu: ["PreemieEU"],
                        uk: ["PreemieUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "Newborn",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "US Newborn",
                      display_with_system_and_set: "US Newborn",
                      equivalent_sizes: {
                        au: ["00000MBAU"],
                        eu: ["NewbornEU"],
                        uk: ["NewbornUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0-3MB",
                      short: "0-3M",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3M",
                      display_with_size_system: "US 0-3",
                      display_with_system_and_set: "US 0-3M",
                      equivalent_sizes: {
                        au: ["0000MBAU"],
                        eu: ["0-3MBEU"],
                        uk: ["0-3MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3-6MB",
                      short: "3-6M",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6M",
                      display_with_size_system: "US 3-6",
                      display_with_system_and_set: "US 3-6M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["3-6MBEU"],
                        uk: ["3-6MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6-9MB",
                      short: "6-9M",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9M",
                      display_with_size_system: "US 6-9",
                      display_with_system_and_set: "US 6-9M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["6-9MBEU"],
                        uk: ["6-9MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9-12MB",
                      short: "9-12M",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12M",
                      display_with_size_system: "US 9-12",
                      display_with_system_and_set: "US 9-12M",
                      equivalent_sizes: {
                        au: ["00MBAU"],
                        eu: ["9-12MBEU"],
                        uk: ["9-12MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12-18MB",
                      short: "12-18M",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18M",
                      display_with_size_system: "US 12-18",
                      display_with_system_and_set: "US 12-18M",
                      equivalent_sizes: {
                        au: ["0MBAU"],
                        eu: ["12-18MBEU"],
                        uk: ["12-18MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18-24MB",
                      short: "18-24M",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24M",
                      display_with_size_system: "US 18-24",
                      display_with_system_and_set: "US 18-24M",
                      equivalent_sizes: {
                        au: ["1MBAU"],
                        eu: ["18-24MBEU"],
                        uk: ["18-24MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3MB",
                      short: "3M",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3M",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3M",
                      equivalent_sizes: {
                        au: ["0000MBAU", "000MBAU"],
                        eu: ["3MBEU"],
                        uk: ["3MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6MB",
                      short: "6M",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6M",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["6MBEU"],
                        uk: ["6MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9MB",
                      short: "9M",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9M",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9M",
                      equivalent_sizes: {
                        au: ["000MBAU", "00MBAU"],
                        eu: ["9MBEU"],
                        uk: ["9MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12MB",
                      short: "12M",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12M",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12M",
                      equivalent_sizes: {
                        au: ["00MBAU", "0MBAU"],
                        eu: ["12MBEU"],
                        uk: ["12MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18MB",
                      short: "18M",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18M",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18M",
                      equivalent_sizes: {
                        au: ["0MBAU", "1MBAU"],
                        eu: ["18MBEU"],
                        uk: ["18MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24MB",
                      short: "24M",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24M",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24M",
                      equivalent_sizes: {
                        au: ["1MBAU", "2MBAU"],
                        eu: ["24MBEU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "00000MBAU",
                      short: "00000",
                      long: "00000",
                      display: "00000",
                      display_with_size_set: "00000",
                      display_with_size_system: "AU 00000",
                      display_with_system_and_set: "AU 00000",
                      equivalent_sizes: {
                        us: ["Preemie ", "Newborn"],
                        eu: ["PreemieEU", "NewbornEU"],
                        uk: ["PreemieUK", "NewbornUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0000MBAU",
                      short: "0000",
                      long: "0000",
                      display: "0000",
                      display_with_size_set: "0000",
                      display_with_size_system: "AU 0000",
                      display_with_system_and_set: "AU 0000",
                      equivalent_sizes: {
                        us: ["0-3MB", "3MB"],
                        eu: ["0-3MBEU", "3MBEU"],
                        uk: ["0-3MBUK", "3MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "000MBAU",
                      short: "000",
                      long: "000",
                      display: "000",
                      display_with_size_set: "000",
                      display_with_size_system: "AU 000",
                      display_with_system_and_set: "AU 000",
                      equivalent_sizes: {
                        us: ["3MB", "6MB", "9MB", "3-6MB", "6-9MB"],
                        eu: ["3MBEU", "6MBEU", "9MBEU", "3-6MBEU", "6-9MBEU"],
                        uk: ["3MBUK", "6MBUK", "9MBUK", "3-6MBUK", "6-9MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "00MBAU",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "AU 00",
                      display_with_system_and_set: "AU 00",
                      equivalent_sizes: {
                        us: ["9MB", "12MB", "9-12MB"],
                        eu: ["9MBEU", "12MBEU", "9-12MBEU"],
                        uk: ["9MBUK", "12MBUK", "9-12MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0MBAU",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "AU 0",
                      display_with_system_and_set: "AU 0",
                      equivalent_sizes: {
                        us: ["12MB", "18MB", "12-18MB"],
                        eu: ["12MBEU", "18MBEU", "12-18MBEU"],
                        uk: ["12MBUK", "18MBUK", "12-18MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1MBAU",
                      short: "1",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1",
                      display_with_size_system: "AU 1",
                      display_with_system_and_set: "AU 1",
                      equivalent_sizes: {
                        us: ["18MB", "24MB", "18-24MB"],
                        eu: ["18MBEU", "24MBEU", "18-24MBEU"],
                        uk: ["18MBUK", "24MBUK", "18-24MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2MBAU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["24MB"],
                        eu: ["24MBEU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "PreemieEU",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "EU Preemie",
                      display_with_system_and_set: "EU Preemie",
                      equivalent_sizes: {
                        us: ["Preemie"],
                        au: ["00000MBAU"],
                        uk: ["PreemieUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "NewbornEU",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "EU Newborn",
                      display_with_system_and_set: "EU Newborn",
                      equivalent_sizes: {
                        us: ["Newborn"],
                        au: ["00000MBAU"],
                        uk: ["NewbornUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0-3MBEU",
                      short: "0-3",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3",
                      display_with_size_system: "EU 0-3",
                      display_with_system_and_set: "EU 0-3",
                      equivalent_sizes: {
                        us: ["0-3MB"],
                        au: ["0000MBAU"],
                        uk: ["0-3MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3-6MBEU",
                      short: "3-6",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6",
                      display_with_size_system: "EU 3-6",
                      display_with_system_and_set: "EU 3-6",
                      equivalent_sizes: {
                        us: ["3-6MB"],
                        au: ["000MBAU"],
                        uk: ["3-6MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6-9MBEU",
                      short: "6-9",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9",
                      display_with_size_system: "EU 6-9",
                      display_with_system_and_set: "EU 6-9",
                      equivalent_sizes: {
                        us: ["6-9MB"],
                        au: ["000MBAU"],
                        uk: ["6-9MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "9-12MBEU",
                      short: "9-12",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12",
                      display_with_size_system: "EU 9-12",
                      display_with_system_and_set: "EU 9-12",
                      equivalent_sizes: {
                        us: ["9-12MB"],
                        au: ["00MBAU"],
                        uk: ["9-12MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12-18MBEU",
                      short: "12-18",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18",
                      display_with_size_system: "EU 12-18",
                      display_with_system_and_set: "EU 12-18",
                      equivalent_sizes: {
                        us: ["12-18MB"],
                        au: ["0MBAU"],
                        uk: ["12-18MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18-24MBEU",
                      short: "18-24",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24",
                      display_with_size_system: "EU 18-24",
                      display_with_system_and_set: "EU 18-24",
                      equivalent_sizes: {
                        us: ["18-24MB"],
                        au: ["1MBAU"],
                        uk: ["18-24MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3MBEU",
                      short: "3",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3",
                      display_with_size_system: "EU 3",
                      display_with_system_and_set: "EU 3",
                      equivalent_sizes: {
                        us: ["3MB"],
                        au: ["0000MBAU", "000MBAU"],
                        uk: ["3MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6MBEU",
                      short: "6",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6",
                      display_with_size_system: "EU 6",
                      display_with_system_and_set: "EU 6",
                      equivalent_sizes: {
                        us: ["6MB"],
                        au: ["000MBAU"],
                        uk: ["6MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "9MBEU",
                      short: "9",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9",
                      display_with_size_system: "EU 9",
                      display_with_system_and_set: "EU 9",
                      equivalent_sizes: {
                        us: ["9MB"],
                        au: ["000MBAU", "00MBAU"],
                        uk: ["9MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12MBEU",
                      short: "12",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12",
                      display_with_size_system: "EU 12",
                      display_with_system_and_set: "EU 12",
                      equivalent_sizes: {
                        us: ["12MB"],
                        au: ["00MBAU", "0MBAU"],
                        uk: ["12MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18MBEU",
                      short: "18",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18",
                      display_with_size_system: "EU 18",
                      display_with_system_and_set: "EU 18",
                      equivalent_sizes: {
                        us: ["18MB"],
                        au: ["0MBAU", "1MBAU"],
                        uk: ["18MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "24MBEU",
                      short: "24",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24",
                      display_with_size_system: "EU 24",
                      display_with_system_and_set: "EU 24",
                      equivalent_sizes: {
                        us: ["24MB"],
                        au: ["1MBAU", "2MBAU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "PreemieUK",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "UK Preemie",
                      display_with_system_and_set: "UK Preemie",
                      equivalent_sizes: {
                        us: ["Preemie"],
                        au: ["00000MBAU"],
                        eu: ["PreemieEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "NewbornUK",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "UK Newborn",
                      display_with_system_and_set: "UK Newborn",
                      equivalent_sizes: {
                        us: ["Newborn"],
                        au: ["00000MBAU"],
                        eu: ["NewbornEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0-3MBUK",
                      short: "0-3M",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3M",
                      display_with_size_system: "UK 0-3",
                      display_with_system_and_set: "UK 0-3M",
                      equivalent_sizes: {
                        us: ["0-3MB"],
                        au: ["0000MBAU"],
                        eu: ["0-3MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3-6MBUK",
                      short: "3-6M",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6M",
                      display_with_size_system: "UK 3-6",
                      display_with_system_and_set: "UK 3-6M",
                      equivalent_sizes: {
                        us: ["3-6MB"],
                        au: ["000MBAU"],
                        eu: ["3-6MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6-9MBUK",
                      short: "6-9M",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9M",
                      display_with_size_system: "UK 6-9",
                      display_with_system_and_set: "UK 6-9M",
                      equivalent_sizes: {
                        us: ["6-9MB"],
                        au: ["000MBAU"],
                        eu: ["6-9MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9-12MBUK",
                      short: "9-12M",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12M",
                      display_with_size_system: "UK 9-12",
                      display_with_system_and_set: "UK 9-12M",
                      equivalent_sizes: {
                        us: ["9-12MB"],
                        au: ["00MBAU"],
                        eu: ["9-12MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12-18MBUK",
                      short: "12-18M",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18M",
                      display_with_size_system: "UK 12-18",
                      display_with_system_and_set: "UK 12-18M",
                      equivalent_sizes: {
                        us: ["12-18MB"],
                        au: ["0MBAU"],
                        eu: ["12-18MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18-24MBUK",
                      short: "18-24M",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24M",
                      display_with_size_system: "UK 18-24",
                      display_with_system_and_set: "UK 18-24M",
                      equivalent_sizes: {
                        us: ["18-24MB"],
                        au: ["1MBAU"],
                        eu: ["18-24MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3MBUK",
                      short: "3M",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3M",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3M",
                      equivalent_sizes: {
                        us: ["3MB"],
                        au: ["0000MBAU", "000MBAU"],
                        eu: ["3MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MBUK",
                      short: "6M",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6M",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6M",
                      equivalent_sizes: {
                        us: ["6MB"],
                        au: ["000MBAU"],
                        eu: ["6MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9MBUK",
                      short: "9M",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9M",
                      display_with_size_system: "UK 9",
                      display_with_system_and_set: "UK 9M",
                      equivalent_sizes: {
                        us: ["9MB"],
                        au: ["000MBAU", "00MBAU"],
                        eu: ["9MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MBUK",
                      short: "12M",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12M",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12M",
                      equivalent_sizes: {
                        us: ["12MB"],
                        au: ["00MBAU", "0MBAU"],
                        eu: ["12MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MBUK",
                      short: "18M",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18M",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18M",
                      equivalent_sizes: {
                        us: ["18MB"],
                        au: ["0MBAU", "1MBAU"],
                        eu: ["18MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MBUK",
                      short: "24M",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24M",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24M",
                      equivalent_sizes: {
                        us: ["24MB"],
                        au: ["1MBAU", "2MBAU"],
                        eu: ["24MBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "14003667d97b4e6bdb005784",
              name: "Girls",
              tags: ["girls"],
              category_id: "24002b34d97b4efb71005784",
              measurements_chart: {
                inches: [
                  ["Height ", "Waist ", "Hips "],
                  ["33.5-35.5", "21", "21"],
                  ["36-38", "21.5", "22"],
                  ["38.5-41.5", "22", "23"],
                  ["42-44.5", "22.5", "24"],
                  ["38.5-41.5", "22", "23"],
                  ["42-44.5", "22.5", "24"],
                  ["45-46.5", "23", "25"],
                  ["47-49.5", "23.5", "26.5"],
                  ["49.5-50.5", "24", "27.5"],
                  ["51-52.5", "24.5", "28.5"],
                  ["53-55", "25", "30"],
                  ["55.5-58", "26", "31.5"],
                  ["58.5-61", "27.5", "33"],
                  ["61.5-62.5", "29", "34.5"],
                  ["38.5-44.5", "22.0-22.5", "23.0-24.0"],
                  ["45-50.5", "23.0-24.0", "25.0-27.5"],
                  ["51-52.5", "24.5", "28.5"],
                  ["53-55", "25", "30"],
                  ["55.5-58", "26", "31.5"],
                  ["58.5-62.5", "27.5-29.0", "33-34.5"],
                ],
                centimeters: [
                  ["Height ", "Waist ", "Hips "],
                  ["85.1-90.2", "53.3", "53.3"],
                  ["91.4-96.5", "54.6", "55.9"],
                  ["97.8-105.4", "55.9", "58.4"],
                  ["106.7-113.0", "57.2", "61"],
                  ["97.8-105.4", "55.9", "58.4"],
                  ["106.7-113.0", "57.2", "61"],
                  ["114.3-118.1", "58.4", "63.5"],
                  ["119.4-125.7", "59.7", "67.3"],
                  ["125.7-128.3", "61", "69.9"],
                  ["129.5-133.4", "62.2", "72.4"],
                  ["134.6-139.7", "63.5", "76.2"],
                  ["141.0-147.3", "66", "80"],
                  ["148.6-154.9", "69.9", "83.8"],
                  ["156.2-158.8", "73.7", "87.6"],
                  ["97.8-113.0", "55.9-57.2", "58.4-61.0"],
                  ["114.3-128.3", "58.4-61.0", "63.5-69.9"],
                  ["129.5-133.4", "62.2", "72.4"],
                  ["134.6-139.7", "63.5", "76.2"],
                  ["141.0-147.3", "66", "80"],
                  ["148.6-158.8", "69.9-73.7", "83.8-87.6"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["2T", "3", "2A", "2"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["6", "6", "6", "6"],
                  ["6X,7", "7", "6X,7", "7"],
                  ["6X,7", "7", "6X,7", "7"],
                  ["8", "8", "8", "8"],
                  ["10", "10", "10", "10"],
                  ["12", "12", "12", "12"],
                  ["14", "14", "14", "14"],
                  ["16", "16", "16", "16"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["2T", "3", "2A", "2,3"],
                ["3T", "3", "3A", "3"],
                ["4T", "4", "4A", "4"],
                ["5T", "5", "5A", "5"],
                ["4", "4", "4", "4"],
                ["5", "5", "5", "5"],
                ["6", "6", "6", "6"],
                ["6X", "7", "6X", "7"],
                ["7", "7", "7", "7"],
                ["8", "8", "8", "8"],
                ["10", "10", "10", "10"],
                ["12", "12", "12", "12"],
                ["14", "14", "14", "14"],
                ["16", "16", "16", "16"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "2TG",
                      short: "2T (Girl)",
                      long: "2T",
                      display: "2T",
                      display_with_size_set: "2T (Girl)",
                      display_with_size_system: "US 2T",
                      display_with_system_and_set: "US 2T (Girl)",
                      equivalent_sizes: {
                        au: ["3GAU"],
                        eu: ["2AGEU"],
                        uk: ["2GUK", "3GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3TG",
                      short: "3T (Girl)",
                      long: "3T",
                      display: "3T",
                      display_with_size_set: "3T (Girl)",
                      display_with_size_system: "US 3T",
                      display_with_system_and_set: "US 3T (Girl)",
                      equivalent_sizes: {
                        au: ["3GAU"],
                        eu: ["3AGEU"],
                        uk: ["3GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4TG",
                      short: "4T (Girl)",
                      long: "4T",
                      display: "4T",
                      display_with_size_set: "4T (Girl)",
                      display_with_size_system: "US 4T",
                      display_with_system_and_set: "US 4T (Girl)",
                      equivalent_sizes: {
                        au: ["4GAU"],
                        eu: ["4AGEU"],
                        uk: ["4GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5TG",
                      short: "5T (Girl)",
                      long: "5T",
                      display: "5T",
                      display_with_size_set: "5T (Girl)",
                      display_with_size_system: "US 5T",
                      display_with_system_and_set: "US 5T (Girl)",
                      equivalent_sizes: {
                        au: ["5GAU"],
                        eu: ["5AGEU"],
                        uk: ["5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4G",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Girl)",
                      equivalent_sizes: {
                        au: ["4GAU"],
                        eu: ["4GEU"],
                        uk: ["4GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5G",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Girl)",
                      equivalent_sizes: {
                        au: ["5GAU"],
                        eu: ["5GEU"],
                        uk: ["5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6G",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Girl)",
                      equivalent_sizes: {
                        au: ["6GAU"],
                        eu: ["6GEU"],
                        uk: ["6GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XG",
                      short: "6X (Girl)",
                      long: "6X",
                      display: "6X",
                      display_with_size_set: "6X (Girl)",
                      display_with_size_system: "US 6X",
                      display_with_system_and_set: "US 6X (Girl)",
                      equivalent_sizes: {
                        au: ["7GAU"],
                        eu: ["6XGEU"],
                        uk: ["7GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7G",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Girl)",
                      equivalent_sizes: {
                        au: ["7GAU"],
                        eu: ["7GEU"],
                        uk: ["7GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8G",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Girl)",
                      equivalent_sizes: {
                        au: ["8GAU"],
                        eu: ["8GEU"],
                        uk: ["8GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10G",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Girl)",
                      equivalent_sizes: {
                        au: ["10GAU"],
                        eu: ["10GEU"],
                        uk: ["10GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12G",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Girl)",
                      equivalent_sizes: {
                        au: ["12GAU"],
                        eu: ["12GEU"],
                        uk: ["12GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14G",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Girl)",
                      equivalent_sizes: {
                        au: ["14GAU"],
                        eu: ["14GEU"],
                        uk: ["14GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16G",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Girl)",
                      equivalent_sizes: {
                        au: ["16GAU"],
                        eu: ["16GEU"],
                        uk: ["16GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSG",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Girl)",
                      equivalent_sizes: {
                        au: ["XSGAU"],
                        eu: ["XSGEU"],
                        uk: ["XSGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SG",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Girl)",
                      equivalent_sizes: {
                        au: ["SGAU"],
                        eu: ["SGEU"],
                        uk: ["SGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MG",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Girl)",
                      equivalent_sizes: {
                        au: ["MGAU"],
                        eu: ["MGEU"],
                        uk: ["MGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LG",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Girl)",
                      equivalent_sizes: {
                        au: ["LGAU"],
                        eu: ["LGEU"],
                        uk: ["LGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLG",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Girl)",
                      equivalent_sizes: {
                        au: ["XLGAU"],
                        eu: ["XLGEU"],
                        uk: ["XLGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLG",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Girl)",
                      equivalent_sizes: {
                        au: ["XXLGAU"],
                        eu: ["XXLGEU"],
                        uk: ["XXLGUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "3GAU",
                      short: "3 (Girl)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Girl)",
                      display_with_size_system: "AU 3",
                      display_with_system_and_set: "AU 3 (Girl)",
                      equivalent_sizes: {
                        us: ["2TG", "3TG"],
                        eu: ["2AGEU", "3AGEU"],
                        uk: ["2GUK", "3GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4GAU",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Girl)",
                      equivalent_sizes: {
                        us: ["4TG", "4G"],
                        eu: ["4AGEU", "4GEU"],
                        uk: ["4GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5GAU",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "AU 5",
                      display_with_system_and_set: "AU 5 (Girl)",
                      equivalent_sizes: {
                        us: ["5TG", "5G"],
                        eu: ["5AGEU", "5GEU"],
                        uk: ["5GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6GAU",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Girl)",
                      equivalent_sizes: {
                        us: ["6G"],
                        eu: ["6GEU"],
                        uk: ["6GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "7GAU",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "AU 7",
                      display_with_system_and_set: "AU 7 (Girl)",
                      equivalent_sizes: {
                        us: ["6XG", "7G"],
                        eu: ["6XGEU", "7GEU"],
                        uk: ["7GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8GAU",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Girl)",
                      equivalent_sizes: {
                        us: ["8G"],
                        eu: ["8GEU"],
                        uk: ["8GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10GAU",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Girl)",
                      equivalent_sizes: {
                        us: ["10G"],
                        eu: ["10GEU"],
                        uk: ["10GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12GAU",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Girl)",
                      equivalent_sizes: {
                        us: ["12G"],
                        eu: ["12GEU"],
                        uk: ["12GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14GAU",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Girl)",
                      equivalent_sizes: {
                        us: ["14G"],
                        eu: ["14GEU"],
                        uk: ["14GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16GAU",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Girl)",
                      equivalent_sizes: {
                        us: ["16G"],
                        eu: ["16GEU"],
                        uk: ["16GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSGAU",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Girl)",
                      equivalent_sizes: {
                        us: ["XSG"],
                        eu: ["XSGEU"],
                        uk: ["XSGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SGAU",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Girl)",
                      equivalent_sizes: {
                        us: ["SG"],
                        eu: ["SGEU"],
                        uk: ["SGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MGAU",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Girl)",
                      equivalent_sizes: {
                        us: ["MG"],
                        eu: ["MGEU"],
                        uk: ["MGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LGAU",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Girl)",
                      equivalent_sizes: {
                        us: ["LG"],
                        eu: ["LGEU"],
                        uk: ["LGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLGAU",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Girl)",
                      equivalent_sizes: {
                        us: ["XLG"],
                        eu: ["XLGEU"],
                        uk: ["XLGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLGAU",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Girl)",
                      equivalent_sizes: {
                        us: ["XXLG"],
                        eu: ["XXLGEU"],
                        uk: ["XXLGUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "2AGEU",
                      short: "2A (Girl)",
                      long: "2A",
                      display: "2A",
                      display_with_size_set: "2A (Girl)",
                      display_with_size_system: "EU 2A",
                      display_with_system_and_set: "EU 2A (Girl)",
                      equivalent_sizes: {
                        us: ["2TG"],
                        au: ["3GAU"],
                        uk: ["2GUK", "3GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3AGEU",
                      short: "3A (Girl)",
                      long: "3A",
                      display: "3A",
                      display_with_size_set: "3A (Girl)",
                      display_with_size_system: "EU 3A",
                      display_with_system_and_set: "EU 3A (Girl)",
                      equivalent_sizes: {
                        us: ["3TG"],
                        au: ["3GAU"],
                        uk: ["3GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4AGEU",
                      short: "4A (Girl)",
                      long: "4A",
                      display: "4A",
                      display_with_size_set: "4A (Girl)",
                      display_with_size_system: "EU 4A",
                      display_with_system_and_set: "EU 4A (Girl)",
                      equivalent_sizes: {
                        us: ["4TG"],
                        au: ["4GAU"],
                        uk: ["4GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4GEU",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "EU 4",
                      display_with_system_and_set: "EU 4 (Girl)",
                      equivalent_sizes: {
                        us: ["4G"],
                        au: ["4GAU"],
                        uk: ["4GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5AGEU",
                      short: "5A (Girl)",
                      long: "5A",
                      display: "5A",
                      display_with_size_set: "5A (Girl)",
                      display_with_size_system: "EU 5A",
                      display_with_system_and_set: "EU 5A (Girl)",
                      equivalent_sizes: {
                        us: ["5TG"],
                        au: ["5GAU"],
                        uk: ["5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5GEU",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "EU 5",
                      display_with_system_and_set: "EU 5 (Girl)",
                      equivalent_sizes: {
                        us: ["5G"],
                        au: ["5GAU"],
                        uk: ["5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XGEU",
                      short: "6X (Girl)",
                      long: "6X",
                      display: "6X",
                      display_with_size_set: "6X (Girl)",
                      display_with_size_system: "EU 6X",
                      display_with_system_and_set: "EU 6X (Girl)",
                      equivalent_sizes: {
                        us: ["6XG"],
                        au: ["7GAU"],
                        uk: ["7GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6GEU",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "EU 6",
                      display_with_system_and_set: "EU 6 (Girl)",
                      equivalent_sizes: {
                        us: ["6G"],
                        au: ["6GAU"],
                        uk: ["6GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "7GEU",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "EU 7",
                      display_with_system_and_set: "EU 7 (Girl)",
                      equivalent_sizes: {
                        us: ["7G"],
                        au: ["7GAU"],
                        uk: ["7GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "8GEU",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "EU 8",
                      display_with_system_and_set: "EU 8 (Girl)",
                      equivalent_sizes: {
                        us: ["8G"],
                        au: ["8GAU"],
                        uk: ["8GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "10GEU",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "EU 10",
                      display_with_system_and_set: "EU 10 (Girl)",
                      equivalent_sizes: {
                        us: ["10G"],
                        au: ["10GAU"],
                        uk: ["10GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12GEU",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "EU 12",
                      display_with_system_and_set: "EU 12 (Girl)",
                      equivalent_sizes: {
                        us: ["12G"],
                        au: ["12GAU"],
                        uk: ["12GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "14GEU",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "EU 14",
                      display_with_system_and_set: "EU 14 (Girl)",
                      equivalent_sizes: {
                        us: ["14G"],
                        au: ["14GAU"],
                        uk: ["14GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "16GEU",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "EU 16",
                      display_with_system_and_set: "EU 16 (Girl)",
                      equivalent_sizes: {
                        us: ["16G"],
                        au: ["16GAU"],
                        uk: ["16GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSGEU",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Girl)",
                      equivalent_sizes: {
                        us: ["XSG"],
                        au: ["XSGAU"],
                        uk: ["XSGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SGEU",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Girl)",
                      equivalent_sizes: {
                        us: ["SG"],
                        au: ["SGAU"],
                        uk: ["SGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MGEU",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Girl)",
                      equivalent_sizes: {
                        us: ["MG"],
                        au: ["MGAU"],
                        uk: ["MGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LGEU",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Girl)",
                      equivalent_sizes: {
                        us: ["LG"],
                        au: ["LGAU"],
                        uk: ["LGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLGEU",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Girl)",
                      equivalent_sizes: {
                        us: ["XLG"],
                        au: ["XLGAU"],
                        uk: ["XLGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLGEU",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Girl)",
                      equivalent_sizes: {
                        us: ["XXLG"],
                        au: ["XXLGAU"],
                        uk: ["XXLGUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2GUK",
                      short: "2 (Girl)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Girl)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Girl)",
                      equivalent_sizes: {
                        us: ["2TG"],
                        au: ["3GAU"],
                        eu: ["2AGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3GUK",
                      short: "3 (Girl)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Girl)",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3 (Girl)",
                      equivalent_sizes: {
                        us: ["2TG", "3TG"],
                        au: ["3GAU"],
                        eu: ["2AGEU", "3AGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4GUK",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Girl)",
                      equivalent_sizes: {
                        us: ["4TG", "4G"],
                        au: ["4GAU"],
                        eu: ["4AGEU", "4GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5GUK",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "UK 5",
                      display_with_system_and_set: "UK 5 (Girl)",
                      equivalent_sizes: {
                        us: ["5TG", "5G"],
                        au: ["5GAU"],
                        eu: ["5AGEU", "5GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6GUK",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Girl)",
                      equivalent_sizes: {
                        us: ["6G"],
                        au: ["6GAU"],
                        eu: ["6GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "7GUK",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "UK 7",
                      display_with_system_and_set: "UK 7 (Girl)",
                      equivalent_sizes: {
                        us: ["6XG", "7G"],
                        au: ["7GAU"],
                        eu: ["6XGEU", "7GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8GUK",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Girl)",
                      equivalent_sizes: {
                        us: ["8G"],
                        au: ["8GAU"],
                        eu: ["8GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10GUK",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Girl)",
                      equivalent_sizes: {
                        us: ["10G"],
                        au: ["10GAU"],
                        eu: ["10GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12GUK",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Girl)",
                      equivalent_sizes: {
                        us: ["12G"],
                        au: ["12GAU"],
                        eu: ["12GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14GUK",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Girl)",
                      equivalent_sizes: {
                        us: ["14G"],
                        au: ["14GAU"],
                        eu: ["14GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16GUK",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Girl)",
                      equivalent_sizes: {
                        us: ["16G"],
                        au: ["16GAU"],
                        eu: ["16GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSGUK",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Girl)",
                      equivalent_sizes: {
                        us: ["XSG"],
                        au: ["XSGAU"],
                        eu: ["XSGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SGUK",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Girl)",
                      equivalent_sizes: {
                        us: ["SG"],
                        au: ["SGAU"],
                        eu: ["SGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MGUK",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Girl)",
                      equivalent_sizes: {
                        us: ["MG"],
                        au: ["MGAU"],
                        eu: ["MGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LGUK",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Girl)",
                      equivalent_sizes: {
                        us: ["LG"],
                        au: ["LGAU"],
                        eu: ["LGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLGUK",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Girl)",
                      equivalent_sizes: {
                        us: ["XLG"],
                        au: ["XLGAU"],
                        eu: ["XLGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLGUK",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Girl)",
                      equivalent_sizes: {
                        us: ["XXLG"],
                        au: ["XXLGAU"],
                        eu: ["XXLGEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "15003667d97b4e6bdb005784",
              name: "Boys",
              tags: ["boys"],
              category_id: "24002b34d97b4efb71005784",
              measurements_chart: {
                inches: [
                  ["Height ", "Waist ", "Hips "],
                  ["33.5-35.5", "21", "21"],
                  ["36-38", "21.5", "22"],
                  ["38.5-41.5", "22", "23"],
                  ["42-44.5", "22.5", "24"],
                  ["38.5-41.5", "22", "23"],
                  ["42-44.5", "22.5", "24"],
                  ["45-46.5", "23", "25"],
                  ["47-49.5", "23.5", "26.5"],
                  ["50-52", "24.5", "27"],
                  ["50-52", "24.5", "27"],
                  ["52.5-55.5", "25.5", "28"],
                  ["56-57.5", "26.5", "29"],
                  ["58-59.5", "28", "30.5"],
                  ["60-61.5", "29.5", "32"],
                  ["62-63.5", "30.5", "33"],
                  ["64-65", "31.5", "34"],
                  ["38.5-44.5", "22.0-22.5", "23.0-24.0"],
                  ["45-52", "23.0-24.5", "25.0-27.0"],
                  ["50-52", "24.5", "27"],
                  ["52.5-55.5", "25.5", "28"],
                  ["56-57.5", "26.5", "29"],
                  ["58-61.5", "28.0-29.5", "30.5-32.0"],
                ],
                centimeters: [
                  ["Height ", "Waist ", "Hips "],
                  ["85.1-90.2", "53.3", "53.3"],
                  ["91.4-96.5", "54.6", "55.9"],
                  ["97.8-105.4", "55.9", "58.4"],
                  ["106.7-113.0", "57.2", "61"],
                  ["97.8-105.4", "55.9", "58.4"],
                  ["106.7-113.0", "57.2", "61"],
                  ["114.3-118.1", "58.4", "63.5"],
                  ["119.4-125.7", "59.7", "67.3"],
                  ["127-132.1", "62.2", "68.6"],
                  ["127-132.1", "62.2", "68.6"],
                  ["133.4-141.0", "64.8", "71.1"],
                  ["142.2-146.1", "67.3", "73.7"],
                  ["147.3-151.1", "71.1", "77.5"],
                  ["152.4-156.2", "74.9", "81.3"],
                  ["157.5-161.3", "77.5", "83.8"],
                  ["162.6-165.1", "80", "86.4"],
                  ["97.8-113.0", "55.9-57.2", "58.4-61.0"],
                  ["114.3-132.1", "58.4-62.2", "63.5-68.6"],
                  ["127-132.1", "62.2", "68.6"],
                  ["133.4-141.0", "64.8", "71.1"],
                  ["142.2-146.1", "67.3", "73.7"],
                  ["147.3-156.2", "71.1-74.9", "77.5-81.3"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["2T", "3", "2A", "2"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["6", "6", "6", "6"],
                  ["7", "7", "7", "7"],
                  ["7X,8", "8", "7X,8", "8"],
                  ["7X,8", "8", "7X,8", "8"],
                  ["10", "10", "10", "10"],
                  ["12", "12", "12", "12"],
                  ["14", "14", "14", "14"],
                  ["16", "16", "16", "16"],
                  ["18", "18", "18", "18"],
                  ["20", "20", "20", "20"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["2T", "3", "2A", "2,3"],
                ["3T", "3", "3A", "3"],
                ["4T", "4", "4A", "4"],
                ["5T", "5", "5A", "5"],
                ["4", "4", "4", "4"],
                ["5", "5", "5", "5"],
                ["6", "6", "6", "6"],
                ["7", "7", "7", "7"],
                ["7X", "8", "7X", "8"],
                ["8", "8", "8", "8"],
                ["10", "10", "10", "10"],
                ["12", "12", "12", "12"],
                ["14", "14", "14", "14"],
                ["16", "16", "16", "16"],
                ["18", "18", "18", "18"],
                ["20", "20", "20", "20"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "2TB",
                      short: "2T (Boy)",
                      long: "2T",
                      display: "2T",
                      display_with_size_set: "2T (Boy)",
                      display_with_size_system: "US 2T",
                      display_with_system_and_set: "US 2T (Boy)",
                      equivalent_sizes: {
                        au: ["3BAU"],
                        eu: ["2ABEU"],
                        uk: ["2BUK", "3BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3TB",
                      short: "3T (Boy)",
                      long: "3T",
                      display: "3T",
                      display_with_size_set: "3T (Boy)",
                      display_with_size_system: "US 3T",
                      display_with_system_and_set: "US 3T (Boy)",
                      equivalent_sizes: {
                        au: ["3BAU"],
                        eu: ["3ABEU"],
                        uk: ["3BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4TB",
                      short: "4T (Boy)",
                      long: "4T",
                      display: "4T",
                      display_with_size_set: "4T (Boy)",
                      display_with_size_system: "US 4T",
                      display_with_system_and_set: "US 4T (Boy)",
                      equivalent_sizes: {
                        au: ["4BAU"],
                        eu: ["4ABEU"],
                        uk: ["4BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5TB",
                      short: "5T (Boy)",
                      long: "5T",
                      display: "5T",
                      display_with_size_set: "5T (Boy)",
                      display_with_size_system: "US 5T",
                      display_with_system_and_set: "US 5T (Boy)",
                      equivalent_sizes: {
                        au: ["5BAU"],
                        eu: ["5ABEU"],
                        uk: ["5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4B",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Boy)",
                      equivalent_sizes: {
                        au: ["4BAU"],
                        eu: ["4BEU"],
                        uk: ["4BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5B",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Boy)",
                      equivalent_sizes: {
                        au: ["5BAU"],
                        eu: ["5BEU"],
                        uk: ["5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6B",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Boy)",
                      equivalent_sizes: {
                        au: ["6BAU"],
                        eu: ["6BEU"],
                        uk: ["6BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7B",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Boy)",
                      equivalent_sizes: {
                        au: ["7BAU"],
                        eu: ["7BEU"],
                        uk: ["7BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7XB",
                      short: "7X (Boy)",
                      long: "7X",
                      display: "7X",
                      display_with_size_set: "7X (Boy)",
                      display_with_size_system: "US 7X",
                      display_with_system_and_set: "US 7X (Boy)",
                      equivalent_sizes: {
                        au: ["8BAU"],
                        eu: ["7XBEU"],
                        uk: ["8BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8B",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Boy)",
                      equivalent_sizes: {
                        au: ["8BAU"],
                        eu: ["8BEU"],
                        uk: ["8BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10B",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Boy)",
                      equivalent_sizes: {
                        au: ["10BAU"],
                        eu: ["10BEU"],
                        uk: ["10BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12B",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Boy)",
                      equivalent_sizes: {
                        au: ["12BAU"],
                        eu: ["12BEU"],
                        uk: ["12BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14B",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Boy)",
                      equivalent_sizes: {
                        au: ["14BAU"],
                        eu: ["14BEU"],
                        uk: ["14BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16B",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Boy)",
                      equivalent_sizes: {
                        au: ["16BAU"],
                        eu: ["16BEU"],
                        uk: ["16BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18B",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Boy)",
                      equivalent_sizes: {
                        au: ["18BAU"],
                        eu: ["18BEU"],
                        uk: ["18BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20B",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Boy)",
                      equivalent_sizes: {
                        au: ["20BAU"],
                        eu: ["20BEU"],
                        uk: ["20BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSB",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Boy)",
                      equivalent_sizes: {
                        au: ["XSBAU"],
                        eu: ["XSBEU"],
                        uk: ["XSBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SB",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Boy)",
                      equivalent_sizes: {
                        au: ["SBAU"],
                        eu: ["SBEU"],
                        uk: ["SBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MB",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Boy)",
                      equivalent_sizes: {
                        au: ["MBAU"],
                        eu: ["MBEU"],
                        uk: ["MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LB",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Boy)",
                      equivalent_sizes: {
                        au: ["LBAU"],
                        eu: ["LBEU"],
                        uk: ["LBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLB",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Boy)",
                      equivalent_sizes: {
                        au: ["XLBAU"],
                        eu: ["XLBEU"],
                        uk: ["XLBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLB",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Boy)",
                      equivalent_sizes: {
                        au: ["XXLBAU"],
                        eu: ["XXLBEU"],
                        uk: ["XXLBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "3BAU",
                      short: "3 (Boy)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Boy)",
                      display_with_size_system: "AU 3",
                      display_with_system_and_set: "AU 3 (Boy)",
                      equivalent_sizes: {
                        us: ["2TB", "3TB"],
                        eu: ["2ABEU", "3ABEU"],
                        uk: ["2BUK", "3BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4BAU",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Boy)",
                      equivalent_sizes: {
                        us: ["4TB", "4B"],
                        eu: ["4ABEU", "4BEU"],
                        uk: ["4BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5BAU",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "AU 5",
                      display_with_system_and_set: "AU 5 (Boy)",
                      equivalent_sizes: {
                        us: ["5TB", "5B"],
                        eu: ["5ABEU", "5BEU"],
                        uk: ["5BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6BAU",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Boy)",
                      equivalent_sizes: {
                        us: ["6B"],
                        eu: ["6BEU"],
                        uk: ["6BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "7BAU",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "AU 7",
                      display_with_system_and_set: "AU 7 (Boy)",
                      equivalent_sizes: {
                        us: ["7B"],
                        eu: ["7BEU"],
                        uk: ["7BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8BAU",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Boy)",
                      equivalent_sizes: {
                        us: ["7XB", "8B"],
                        eu: ["7XBEU", "8BEU"],
                        uk: ["8BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10BAU",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Boy)",
                      equivalent_sizes: {
                        us: ["10B"],
                        eu: ["10BEU"],
                        uk: ["10BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12BAU",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Boy)",
                      equivalent_sizes: {
                        us: ["12B"],
                        eu: ["12BEU"],
                        uk: ["12BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14BAU",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Boy)",
                      equivalent_sizes: {
                        us: ["14B"],
                        eu: ["14BEU"],
                        uk: ["14BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16BAU",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Boy)",
                      equivalent_sizes: {
                        us: ["16B"],
                        eu: ["16BEU"],
                        uk: ["16BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18BAU",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Boy)",
                      equivalent_sizes: {
                        us: ["18B"],
                        eu: ["18BEU"],
                        uk: ["18BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20BAU",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Boy)",
                      equivalent_sizes: {
                        us: ["20B"],
                        eu: ["20BEU"],
                        uk: ["20BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSBAU",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Boy)",
                      equivalent_sizes: {
                        us: ["XSB"],
                        eu: ["XSBEU"],
                        uk: ["XSBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SBAU",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Boy)",
                      equivalent_sizes: {
                        us: ["SB"],
                        eu: ["SBEU"],
                        uk: ["SBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MBAU",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Boy)",
                      equivalent_sizes: {
                        us: ["MB"],
                        eu: ["MBEU"],
                        uk: ["MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LBAU",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Boy)",
                      equivalent_sizes: {
                        us: ["LB"],
                        eu: ["LBEU"],
                        uk: ["LBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLBAU",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Boy)",
                      equivalent_sizes: {
                        us: ["XLB"],
                        eu: ["XLBEU"],
                        uk: ["XLBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLBAU",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Boy)",
                      equivalent_sizes: {
                        us: ["XXLB"],
                        eu: ["XXLBEU"],
                        uk: ["XXLBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "2ABEU",
                      short: "2A (Boy)",
                      long: "2A",
                      display: "2A",
                      display_with_size_set: "2A (Boy)",
                      display_with_size_system: "EU 2A",
                      display_with_system_and_set: "EU 2A (Boy)",
                      equivalent_sizes: {
                        us: ["2TB"],
                        au: ["3BAU"],
                        uk: ["2BUK", "3BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3ABEU",
                      short: "3A (Boy)",
                      long: "3A",
                      display: "3A",
                      display_with_size_set: "3A (Boy)",
                      display_with_size_system: "EU 3A",
                      display_with_system_and_set: "EU 3A (Boy)",
                      equivalent_sizes: {
                        us: ["3TB"],
                        au: ["3BAU"],
                        uk: ["3BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4ABEU",
                      short: "4A (Boy)",
                      long: "4A",
                      display: "4A",
                      display_with_size_set: "4A (Boy)",
                      display_with_size_system: "EU 4A",
                      display_with_system_and_set: "EU 4A (Boy)",
                      equivalent_sizes: {
                        us: ["4TB"],
                        au: ["4BAU"],
                        uk: ["4BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4BEU",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "EU 4",
                      display_with_system_and_set: "EU 4 (Boy)",
                      equivalent_sizes: {
                        us: ["4B"],
                        au: ["4BAU"],
                        uk: ["4BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5ABEU",
                      short: "5A (Boy)",
                      long: "5A",
                      display: "5A",
                      display_with_size_set: "5A (Boy)",
                      display_with_size_system: "EU 5A",
                      display_with_system_and_set: "EU 5A (Boy)",
                      equivalent_sizes: {
                        us: ["5TB"],
                        au: ["5BAU"],
                        uk: ["5BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5BEU",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "EU 5",
                      display_with_system_and_set: "EU 5 (Boy)",
                      equivalent_sizes: {
                        us: ["5B"],
                        au: ["5BAU"],
                        uk: ["5BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6BEU",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "EU 6",
                      display_with_system_and_set: "EU 6 (Boy)",
                      equivalent_sizes: {
                        us: ["6B"],
                        au: ["6BAU"],
                        uk: ["6BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "7XBEU",
                      short: "7X (Boy)",
                      long: "7X",
                      display: "7X",
                      display_with_size_set: "7X (Boy)",
                      display_with_size_system: "EU 7X",
                      display_with_system_and_set: "EU 7X (Boy)",
                      equivalent_sizes: {
                        us: ["7XB"],
                        au: ["8BAU"],
                        uk: ["8BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "7BEU",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "EU 7",
                      display_with_system_and_set: "EU 7 (Boy)",
                      equivalent_sizes: {
                        us: ["7B"],
                        au: ["7BAU"],
                        uk: ["7BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "8BEU",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "EU 8",
                      display_with_system_and_set: "EU 8 (Boy)",
                      equivalent_sizes: {
                        us: ["8B"],
                        au: ["8BAU"],
                        uk: ["8BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "10BEU",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "EU 10",
                      display_with_system_and_set: "EU 10 (Boy)",
                      equivalent_sizes: {
                        us: ["10B"],
                        au: ["10BAU"],
                        uk: ["10BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12BEU",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "EU 12",
                      display_with_system_and_set: "EU 12 (Boy)",
                      equivalent_sizes: {
                        us: ["12B"],
                        au: ["12BAU"],
                        uk: ["12BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "14BEU",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "EU 14",
                      display_with_system_and_set: "EU 14 (Boy)",
                      equivalent_sizes: {
                        us: ["14B"],
                        au: ["14BAU"],
                        uk: ["14BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "16BEU",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "EU 16",
                      display_with_system_and_set: "EU 16 (Boy)",
                      equivalent_sizes: {
                        us: ["16B"],
                        au: ["16BAU"],
                        uk: ["16BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18BEU",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "EU 18",
                      display_with_system_and_set: "EU 18 (Boy)",
                      equivalent_sizes: {
                        us: ["18B"],
                        au: ["18BAU"],
                        uk: ["18BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "20BEU",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "EU 20",
                      display_with_system_and_set: "EU 20 (Boy)",
                      equivalent_sizes: {
                        us: ["20B"],
                        au: ["20BAU"],
                        uk: ["20BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSBEU",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Boy)",
                      equivalent_sizes: {
                        us: ["XSB"],
                        au: ["XSBAU"],
                        uk: ["XSBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SBEU",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Boy)",
                      equivalent_sizes: {
                        us: ["SB"],
                        au: ["SBAU"],
                        uk: ["SBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MBEU",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Boy)",
                      equivalent_sizes: {
                        us: ["MB"],
                        au: ["MBAU"],
                        uk: ["MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LBEU",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Boy)",
                      equivalent_sizes: {
                        us: ["LB"],
                        au: ["LBAU"],
                        uk: ["LBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLBEU",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Boy)",
                      equivalent_sizes: {
                        us: ["XLB"],
                        au: ["XLBAU"],
                        uk: ["XLBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLBEU",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Boy)",
                      equivalent_sizes: {
                        us: ["XXLB"],
                        au: ["XXLBAU"],
                        uk: ["XXLBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "2BUK",
                      short: "2 (Boy)",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2 (Boy)",
                      display_with_size_system: "UK 2",
                      display_with_system_and_set: "UK 2 (Boy)",
                      equivalent_sizes: {
                        us: ["2TB"],
                        au: ["3BAU"],
                        eu: ["2ABEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3BUK",
                      short: "3 (Boy)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Boy)",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3 (Boy)",
                      equivalent_sizes: {
                        us: ["2TB", "3TB"],
                        au: ["3BAU"],
                        eu: ["2ABEU", "3ABEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4BUK",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Boy)",
                      equivalent_sizes: {
                        us: ["4TB", "4B"],
                        au: ["4BAU"],
                        eu: ["4ABEU", "4BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5BUK",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "UK 5",
                      display_with_system_and_set: "UK 5 (Boy)",
                      equivalent_sizes: {
                        us: ["5TB", "5B"],
                        au: ["5BAU"],
                        eu: ["5ABEU", "5BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6BUK",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Boy)",
                      equivalent_sizes: {
                        us: ["6B"],
                        au: ["6BAU"],
                        eu: ["6BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "7BUK",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "UK 7",
                      display_with_system_and_set: "UK 7 (Boy)",
                      equivalent_sizes: {
                        us: ["7B"],
                        au: ["7BAU"],
                        eu: ["7BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8BUK",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Boy)",
                      equivalent_sizes: {
                        us: ["7XB", "8B"],
                        au: ["8BAU"],
                        eu: ["7XBEU", "8BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10BUK",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Boy)",
                      equivalent_sizes: {
                        us: ["10B"],
                        au: ["10BAU"],
                        eu: ["10BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12BUK",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Boy)",
                      equivalent_sizes: {
                        us: ["12B"],
                        au: ["12BAU"],
                        eu: ["12BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14BUK",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Boy)",
                      equivalent_sizes: {
                        us: ["14B"],
                        au: ["14BAU"],
                        eu: ["14BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16BUK",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Boy)",
                      equivalent_sizes: {
                        us: ["16B"],
                        au: ["16BAU"],
                        eu: ["16BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18BUK",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Boy)",
                      equivalent_sizes: {
                        us: ["18B"],
                        au: ["18BAU"],
                        eu: ["18BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20BUK",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Boy)",
                      equivalent_sizes: {
                        us: ["20B"],
                        au: ["20BAU"],
                        eu: ["20BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSBUK",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Boy)",
                      equivalent_sizes: {
                        us: ["XSB"],
                        au: ["XSBAU"],
                        eu: ["XSBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SBUK",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Boy)",
                      equivalent_sizes: {
                        us: ["SB"],
                        au: ["SBAU"],
                        eu: ["SBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MBUK",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Boy)",
                      equivalent_sizes: {
                        us: ["MB"],
                        au: ["MBAU"],
                        eu: ["MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LBUK",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Boy)",
                      equivalent_sizes: {
                        us: ["LB"],
                        au: ["LBAU"],
                        eu: ["LBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLBUK",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Boy)",
                      equivalent_sizes: {
                        us: ["XLB"],
                        au: ["XLBAU"],
                        eu: ["XLBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLBUK",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Boy)",
                      equivalent_sizes: {
                        us: ["XXLB"],
                        au: ["XXLBAU"],
                        eu: ["XXLBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "kids_bottoms",
          aliases: [],
          slug: "Bottoms",
          alias_slugs: [],
          parent_id: "20008c10d97b4e1245005764",
          category_features: [
            {
              id: "10002f3cd97b4edf70005784",
              display: "Casual",
              type: "cf",
              message_id: "kids_bottoms_casual",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Casual",
              alias_slugs: [],
              parent_id: "24002b34d97b4efb71005784",
            },
            {
              id: "11002f3cd97b4edf70005784",
              display: "Formal",
              type: "cf",
              message_id: "kids_bottoms_formal",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Formal",
              alias_slugs: [],
              parent_id: "24002b34d97b4efb71005784",
            },
            {
              id: "12002f3cd97b4edf70005784",
              display: "Jeans",
              type: "cf",
              message_id: "kids_bottoms_jeans",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Jeans",
              alias_slugs: [],
              parent_id: "24002b34d97b4efb71005784",
            },
            {
              id: "84009813d97b4e3995005764",
              display: "Jumpsuits & Rompers",
              type: "cf",
              message_id: "kids_bottoms_jumpsuits_rompers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Jumpsuits_&_Rompers",
              alias_slugs: [],
              parent_id: "24002b34d97b4efb71005784",
            },
            {
              id: "85009813d97b4e3995005764",
              display: "Leggings",
              type: "cf",
              message_id: "kids_bottoms_leggings",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Leggings",
              alias_slugs: [],
              parent_id: "24002b34d97b4efb71005784",
            },
            {
              id: "13002f3cd97b4edf70005784",
              display: "Overalls",
              type: "cf",
              message_id: "kids_bottoms_overalls",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Overalls",
              alias_slugs: [],
              parent_id: "24002b34d97b4efb71005784",
            },
            {
              id: "14002f3cd97b4edf70005784",
              display: "Shorts",
              type: "cf",
              message_id: "kids_bottoms_shorts",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Shorts",
              alias_slugs: [],
              parent_id: "24002b34d97b4efb71005784",
            },
            {
              id: "93009813d97b4e3995005764",
              display: "Skirts",
              type: "cf",
              message_id: "kids_bottoms_skirts",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Skirts",
              alias_slugs: [],
              parent_id: "24002b34d97b4efb71005784",
            },
            {
              id: "94009813d97b4e3995005764",
              display: "Skorts",
              type: "cf",
              message_id: "kids_bottoms_skorts",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Skorts",
              alias_slugs: [],
              parent_id: "24002b34d97b4efb71005784",
            },
            {
              id: "86009813d97b4e3995005764",
              display: "Sweatpants & Joggers",
              type: "cf",
              message_id: "kids_bottoms_sweatpants_joggers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sweatpants_&_Joggers",
              alias_slugs: [],
              parent_id: "24002b34d97b4efb71005784",
            },
          ],
        },
        {
          id: "22008c10d97b4e1245005764",
          display: "Dresses",
          size_sets: [
            {
              id: "05009fc1d97b4e911e005769",
              name: "Baby",
              tags: ["baby"],
              category_id: "22008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["14.5", "0-18", "15", "15"],
                  ["16", "0-18", "16.5", "16.5"],
                  ["17", "18-23", "17.5", "17.5"],
                  ["18", "23-27", "18.5", "18.5"],
                  ["18.5", "27-28", "19", "19"],
                  ["19", "28-29", "19.5", "19.5"],
                  ["19.75", "29-31", "20.25", "20.25"],
                  ["20.5", "31-33", "21", "21"],
                  ["17", "18-23", "17.5", "17.5"],
                  ["18", "23-27", "18.5", "18.5"],
                  ["18.5", "27-28", "19", "19"],
                  ["19", "28-29", "19.5", "19.5"],
                  ["19.75", "29-31", "20.25", "20.25"],
                  ["20.5", "31-33", "21", "21"],
                ],
                centimeters: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["36.8", "0-45.7", "38.1", "38.1"],
                  ["40.6", "0-45.7", "41.9", "41.9"],
                  ["43.2", "45.7-58.4", "44.5", "44.5"],
                  ["45.7", "58.4-68.6", "47.0", "47.0"],
                  ["47.0", "68.6-71.1", "48.3", "48.3"],
                  ["48.3", "71.1-73.7", "49.5", "49.5"],
                  ["50.2", "73.7-78.7", "51.4", "51.4"],
                  ["52.1", "78.7-83.8", "53.3", "53.3"],
                  ["43.2", "45.7-58.4", "44.5", "44.5"],
                  ["45.7", "58.4-68.6", "47.0", "47.0"],
                  ["47.0", "68.6-71.1", "48.3", "48.3"],
                  ["48.3", "71.1-73.7", "49.5", "49.5"],
                  ["50.2", "73.7-78.7", "51.4", "51.4"],
                  ["52.1", "78.7-83.8", "53.3", "53.3"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["Preemie", "00000", "Preemie", "Preemie"],
                  ["Newborn", "00000", "Newborn", "Newborn"],
                  ["0-3 Months", "0000", "0-3 Months", "0-3 Months"],
                  ["3-6 Months", "000", "3-6 Months", "3-6 Months"],
                  ["6-9 Months", "000", "6-9 Months", "6-9 Months"],
                  ["9-12 Months", "00", "9-12 Months", "9-12 Months"],
                  ["12-18 Months", "0", "12-18 Months", "12-18 Months"],
                  ["18-24 Months", "1", "18-24 Months", "18-24 Months"],
                  ["3 Months", "0000,000", "3 Months", "3 Months"],
                  ["6 Months", "000", "6 Months", "6 Months"],
                  ["9 Months", "000,00", "9 Months", "9 Months"],
                  ["12 Months", "00,0", "12 Months", "12 Months"],
                  ["18 Months", "0,1", "18 Months", "18 Months"],
                  ["24 Months", "1,2", "24 Months", "24 Months"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["Preemie", "00000", "Preemie", "Preemie"],
                ["Newborn", "00000", "Newborn", "Newborn"],
                ["0-3 Months", "0000", "0-3 Months", "0-3 Months"],
                ["3-6 Months", "000", "3-6 Months", "3-6 Months"],
                ["6-9 Months", "000", "6-9 Months", "6-9 Months"],
                ["9-12 Months", "00", "9-12 Months", "9-12 Months"],
                ["12-18 Months", "0", "12-18 Months", "12-18 Months"],
                ["18-24 Months", "1", "18-24 Months", "18-24 Months"],
                ["3 Months", "0000,000", "3 Months", "3 Months"],
                ["6 Months", "000", "6 Months", "6 Months"],
                ["9 Months", "000,00", "9 Months", "9 Months"],
                ["12 Months", "00,0", "12 Months", "12 Months"],
                ["18 Months", "0,1", "18 Months", "18 Months"],
                ["24 Months", "1,2", "24 Months", "24 Months"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "Preemie",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "US Preemie",
                      display_with_system_and_set: "US Preemie",
                      equivalent_sizes: {
                        au: ["00000MBAU"],
                        eu: ["PreemieEU"],
                        uk: ["PreemieUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "Newborn",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "US Newborn",
                      display_with_system_and_set: "US Newborn",
                      equivalent_sizes: {
                        au: ["00000MBAU"],
                        eu: ["NewbornEU"],
                        uk: ["NewbornUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0-3MB",
                      short: "0-3M",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3M",
                      display_with_size_system: "US 0-3",
                      display_with_system_and_set: "US 0-3M",
                      equivalent_sizes: {
                        au: ["0000MBAU"],
                        eu: ["0-3MBEU"],
                        uk: ["0-3MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3-6MB",
                      short: "3-6M",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6M",
                      display_with_size_system: "US 3-6",
                      display_with_system_and_set: "US 3-6M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["3-6MBEU"],
                        uk: ["3-6MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6-9MB",
                      short: "6-9M",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9M",
                      display_with_size_system: "US 6-9",
                      display_with_system_and_set: "US 6-9M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["6-9MBEU"],
                        uk: ["6-9MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9-12MB",
                      short: "9-12M",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12M",
                      display_with_size_system: "US 9-12",
                      display_with_system_and_set: "US 9-12M",
                      equivalent_sizes: {
                        au: ["00MBAU"],
                        eu: ["9-12MBEU"],
                        uk: ["9-12MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12-18MB",
                      short: "12-18M",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18M",
                      display_with_size_system: "US 12-18",
                      display_with_system_and_set: "US 12-18M",
                      equivalent_sizes: {
                        au: ["0MBAU"],
                        eu: ["12-18MBEU"],
                        uk: ["12-18MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18-24MB",
                      short: "18-24M",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24M",
                      display_with_size_system: "US 18-24",
                      display_with_system_and_set: "US 18-24M",
                      equivalent_sizes: {
                        au: ["1MBAU"],
                        eu: ["18-24MBEU"],
                        uk: ["18-24MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3MB",
                      short: "3M",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3M",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3M",
                      equivalent_sizes: {
                        au: ["0000MBAU", "000MBAU"],
                        eu: ["3MBEU"],
                        uk: ["3MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6MB",
                      short: "6M",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6M",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["6MBEU"],
                        uk: ["6MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9MB",
                      short: "9M",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9M",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9M",
                      equivalent_sizes: {
                        au: ["000MBAU", "00MBAU"],
                        eu: ["9MBEU"],
                        uk: ["9MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12MB",
                      short: "12M",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12M",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12M",
                      equivalent_sizes: {
                        au: ["00MBAU", "0MBAU"],
                        eu: ["12MBEU"],
                        uk: ["12MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18MB",
                      short: "18M",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18M",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18M",
                      equivalent_sizes: {
                        au: ["0MBAU", "1MBAU"],
                        eu: ["18MBEU"],
                        uk: ["18MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24MB",
                      short: "24M",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24M",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24M",
                      equivalent_sizes: {
                        au: ["1MBAU", "2MBAU"],
                        eu: ["24MBEU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "00000MBAU",
                      short: "00000",
                      long: "00000",
                      display: "00000",
                      display_with_size_set: "00000",
                      display_with_size_system: "AU 00000",
                      display_with_system_and_set: "AU 00000",
                      equivalent_sizes: {
                        us: ["Preemie ", "Newborn"],
                        eu: ["PreemieEU", "NewbornEU"],
                        uk: ["PreemieUK", "NewbornUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0000MBAU",
                      short: "0000",
                      long: "0000",
                      display: "0000",
                      display_with_size_set: "0000",
                      display_with_size_system: "AU 0000",
                      display_with_system_and_set: "AU 0000",
                      equivalent_sizes: {
                        us: ["0-3MB", "3MB"],
                        eu: ["0-3MBEU", "3MBEU"],
                        uk: ["0-3MBUK", "3MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "000MBAU",
                      short: "000",
                      long: "000",
                      display: "000",
                      display_with_size_set: "000",
                      display_with_size_system: "AU 000",
                      display_with_system_and_set: "AU 000",
                      equivalent_sizes: {
                        us: ["3MB", "6MB", "9MB", "3-6MB", "6-9MB"],
                        eu: ["3MBEU", "6MBEU", "9MBEU", "3-6MBEU", "6-9MBEU"],
                        uk: ["3MBUK", "6MBUK", "9MBUK", "3-6MBUK", "6-9MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "00MBAU",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "AU 00",
                      display_with_system_and_set: "AU 00",
                      equivalent_sizes: {
                        us: ["9MB", "12MB", "9-12MB"],
                        eu: ["9MBEU", "12MBEU", "9-12MBEU"],
                        uk: ["9MBUK", "12MBUK", "9-12MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0MBAU",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "AU 0",
                      display_with_system_and_set: "AU 0",
                      equivalent_sizes: {
                        us: ["12MB", "18MB", "12-18MB"],
                        eu: ["12MBEU", "18MBEU", "12-18MBEU"],
                        uk: ["12MBUK", "18MBUK", "12-18MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1MBAU",
                      short: "1",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1",
                      display_with_size_system: "AU 1",
                      display_with_system_and_set: "AU 1",
                      equivalent_sizes: {
                        us: ["18MB", "24MB", "18-24MB"],
                        eu: ["18MBEU", "24MBEU", "18-24MBEU"],
                        uk: ["18MBUK", "24MBUK", "18-24MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2MBAU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["24MB"],
                        eu: ["24MBEU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "PreemieEU",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "EU Preemie",
                      display_with_system_and_set: "EU Preemie",
                      equivalent_sizes: {
                        us: ["Preemie"],
                        au: ["00000MBAU"],
                        uk: ["PreemieUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "NewbornEU",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "EU Newborn",
                      display_with_system_and_set: "EU Newborn",
                      equivalent_sizes: {
                        us: ["Newborn"],
                        au: ["00000MBAU"],
                        uk: ["NewbornUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0-3MBEU",
                      short: "0-3",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3",
                      display_with_size_system: "EU 0-3",
                      display_with_system_and_set: "EU 0-3",
                      equivalent_sizes: {
                        us: ["0-3MB"],
                        au: ["0000MBAU"],
                        uk: ["0-3MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3MBEU",
                      short: "3",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3",
                      display_with_size_system: "EU 3",
                      display_with_system_and_set: "EU 3",
                      equivalent_sizes: {
                        us: ["3MB"],
                        au: ["0000MBAU", "000MBAU"],
                        uk: ["3MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3-6MBEU",
                      short: "3-6",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6",
                      display_with_size_system: "EU 3-6",
                      display_with_system_and_set: "EU 3-6",
                      equivalent_sizes: {
                        us: ["3-6MB"],
                        au: ["000MBAU"],
                        uk: ["3-6MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6-9MBEU",
                      short: "6-9",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9",
                      display_with_size_system: "EU 6-9",
                      display_with_system_and_set: "EU 6-9",
                      equivalent_sizes: {
                        us: ["6-9MB"],
                        au: ["000MBAU"],
                        uk: ["6-9MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "9-12MBEU",
                      short: "9-12",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12",
                      display_with_size_system: "EU 9-12",
                      display_with_system_and_set: "EU 9-12",
                      equivalent_sizes: {
                        us: ["9-12MB"],
                        au: ["00MBAU"],
                        uk: ["9-12MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12-18MBEU",
                      short: "12-18",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18",
                      display_with_size_system: "EU 12-18",
                      display_with_system_and_set: "EU 12-18",
                      equivalent_sizes: {
                        us: ["12-18MB"],
                        au: ["0MBAU"],
                        uk: ["12-18MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18-24MBEU",
                      short: "18-24",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24",
                      display_with_size_system: "EU 18-24",
                      display_with_system_and_set: "EU 18-24",
                      equivalent_sizes: {
                        us: ["18-24MB"],
                        au: ["1MBAU"],
                        uk: ["18-24MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6MBEU",
                      short: "6",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6",
                      display_with_size_system: "EU 6",
                      display_with_system_and_set: "EU 6",
                      equivalent_sizes: {
                        us: ["6MB"],
                        au: ["000MBAU"],
                        uk: ["6MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "9MBEU",
                      short: "9",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9",
                      display_with_size_system: "EU 9",
                      display_with_system_and_set: "EU 9",
                      equivalent_sizes: {
                        us: ["9MB"],
                        au: ["000MBAU", "00MBAU"],
                        uk: ["9MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12MBEU",
                      short: "12",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12",
                      display_with_size_system: "EU 12",
                      display_with_system_and_set: "EU 12",
                      equivalent_sizes: {
                        us: ["12MB"],
                        au: ["00MBAU", "0MBAU"],
                        uk: ["12MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18MBEU",
                      short: "18",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18",
                      display_with_size_system: "EU 18",
                      display_with_system_and_set: "EU 18",
                      equivalent_sizes: {
                        us: ["18MB"],
                        au: ["0MBAU", "1MBAU"],
                        uk: ["18MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "24MBEU",
                      short: "24",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24",
                      display_with_size_system: "EU 24",
                      display_with_system_and_set: "EU 24",
                      equivalent_sizes: {
                        us: ["24MB"],
                        au: ["1MBAU", "2MBAU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "PreemieUK",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "UK Preemie",
                      display_with_system_and_set: "UK Preemie",
                      equivalent_sizes: {
                        us: ["Preemie"],
                        au: ["00000MBAU"],
                        eu: ["PreemieEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "NewbornUK",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "UK Newborn",
                      display_with_system_and_set: "UK Newborn",
                      equivalent_sizes: {
                        us: ["Newborn"],
                        au: ["00000MBAU"],
                        eu: ["NewbornEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0-3MBUK",
                      short: "0-3M",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3M",
                      display_with_size_system: "UK 0-3",
                      display_with_system_and_set: "UK 0-3M",
                      equivalent_sizes: {
                        us: ["0-3MB"],
                        au: ["0000MBAU"],
                        eu: ["0-3MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3-6MBUK",
                      short: "3-6M",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6M",
                      display_with_size_system: "UK 3-6",
                      display_with_system_and_set: "UK 3-6M",
                      equivalent_sizes: {
                        us: ["3-6MB"],
                        au: ["000MBAU"],
                        eu: ["3-6MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6-9MBUK",
                      short: "6-9M",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9M",
                      display_with_size_system: "UK 6-9",
                      display_with_system_and_set: "UK 6-9M",
                      equivalent_sizes: {
                        us: ["6-9MB"],
                        au: ["000MBAU"],
                        eu: ["6-9MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9-12MBUK",
                      short: "9-12M",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12M",
                      display_with_size_system: "UK 9-12",
                      display_with_system_and_set: "UK 9-12M",
                      equivalent_sizes: {
                        us: ["9-12MB"],
                        au: ["00MBAU"],
                        eu: ["9-12MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12-18MBUK",
                      short: "12-18M",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18M",
                      display_with_size_system: "UK 12-18",
                      display_with_system_and_set: "UK 12-18M",
                      equivalent_sizes: {
                        us: ["12-18MB"],
                        au: ["0MBAU"],
                        eu: ["12-18MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18-24MBUK",
                      short: "18-24M",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24M",
                      display_with_size_system: "UK 18-24",
                      display_with_system_and_set: "UK 18-24M",
                      equivalent_sizes: {
                        us: ["18-24MB"],
                        au: ["1MBAU"],
                        eu: ["18-24MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3MBUK",
                      short: "3M",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3M",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3M",
                      equivalent_sizes: {
                        us: ["3MB"],
                        au: ["0000MBAU", "000MBAU"],
                        eu: ["3MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MBUK",
                      short: "6M",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6M",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6M",
                      equivalent_sizes: {
                        us: ["6MB"],
                        au: ["000MBAU"],
                        eu: ["6MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9MBUK",
                      short: "9M",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9M",
                      display_with_size_system: "UK 9",
                      display_with_system_and_set: "UK 9M",
                      equivalent_sizes: {
                        us: ["9MB"],
                        au: ["000MBAU", "00MBAU"],
                        eu: ["9MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MBUK",
                      short: "12M",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12M",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12M",
                      equivalent_sizes: {
                        us: ["12MB"],
                        au: ["00MBAU", "0MBAU"],
                        eu: ["12MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MBUK",
                      short: "18M",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18M",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18M",
                      equivalent_sizes: {
                        us: ["18MB"],
                        au: ["0MBAU", "1MBAU"],
                        eu: ["18MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MBUK",
                      short: "24M",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24M",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24M",
                      equivalent_sizes: {
                        us: ["24MB"],
                        au: ["1MBAU", "2MBAU"],
                        eu: ["24MBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "06009fc1d97b4e911e005769",
              name: "Girls",
              tags: ["girls"],
              category_id: "22008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["20.5", "33.5-35.5", "21", "21"],
                  ["21", "36-38", "21.5", "22"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["24", "45-46.5", "23", "25"],
                  ["25.5", "47-49.5", "23.5", "26.5"],
                  ["26", "49-50.5", "24", "27.5"],
                  ["27", "51-52.5", "24.5", "28.5"],
                  ["28.5", "53-55", "25", "30"],
                  ["30", "55.5-58", "26", "31.5"],
                  ["31.5", "58.5-61", "27.5", "33"],
                  ["33", "61.5-62.5", "29", "34.5"],
                  ["22.0-23.0", "38.5-44.5", "22.0-22.5", "23.0-24.0"],
                  ["24.0-26.0", "45-50.5", "23.0-24.0", "25.0-27.5"],
                  ["27", "51-52.5", "24.5", "28.5"],
                  ["28.5", "53-55", "25", "30"],
                  ["30", "55.5-58", "26", "31.5"],
                  ["31.5-33.0", "58.5-62.5", "27.5-29.0", "33-34.5"],
                ],
                centimeters: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["52.1", "85.1-90.2", "53.3", "53.3"],
                  ["53.3", "91.4-96.5", "54.6", "55.9"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["61", "114.3-118.1", "58.4", "63.5"],
                  ["64.8", "119.4-125.7", "59.7", "67.3"],
                  ["66", "125.7-128.3", "61", "69.9"],
                  ["68.6", "129.5-133.4", "62.2", "72.4"],
                  ["72.4", "134.6-139.7", "63.5", "76.2"],
                  ["76.2", "141.0-147.3", "66", "80"],
                  ["80", "148.6-154.9", "69.9", "83.8"],
                  ["83.8", "156.2-158.8", "73.7", "87.6"],
                  ["55.9-58.4", "97.8-113.0", "55.9-57.2", "58.4-61.0"],
                  ["61.0-66.0", "114.3-128.3", "58.4-61.0", "63.5-69.9"],
                  ["68.6", "129.5-133.4", "62.2", "72.4"],
                  ["72.4", "134.6-139.7", "63.5", "76.2"],
                  ["76.2", "141.0-147.3", "66", "80"],
                  ["80.0-83.8", "148.6-158.8", "69.9-73.7", "83.8-87.6"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["6", "6", "6", "6"],
                  ["6X,7", "7", "6X,7", "7"],
                  ["6X,7", "7", "6X,7", "7"],
                  ["8", "8", "8", "8"],
                  ["10", "10", "10", "10"],
                  ["12", "12", "12", "12"],
                  ["14", "14", "14", "14"],
                  ["16", "16", "16", "16"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["2T", "3", "2A", "3"],
                ["3T", "3", "3A", "3"],
                ["4T", "4", "4A", "4"],
                ["5T", "5", "5A", "5"],
                ["4", "4", "4", "4"],
                ["5", "5", "5", "5"],
                ["6", "6", "6", "6"],
                ["6X", "7", "6X", "7"],
                ["7", "7", "7", "7"],
                ["8", "8", "8", "8"],
                ["10", "10", "10", "10"],
                ["12", "12", "12", "12"],
                ["14", "14", "14", "14"],
                ["16", "16", "16", "16"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "2TG",
                      short: "2T (Girl)",
                      long: "2T",
                      display: "2T",
                      display_with_size_set: "2T (Girl)",
                      display_with_size_system: "US 2T",
                      display_with_system_and_set: "US 2T (Girl)",
                      equivalent_sizes: {
                        au: ["3GAU"],
                        eu: ["2AGEU"],
                        uk: ["3GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3TG",
                      short: "3T (Girl)",
                      long: "3T",
                      display: "3T",
                      display_with_size_set: "3T (Girl)",
                      display_with_size_system: "US 3T",
                      display_with_system_and_set: "US 3T (Girl)",
                      equivalent_sizes: {
                        au: ["3GAU"],
                        eu: ["3AGEU"],
                        uk: ["3GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4TG",
                      short: "4T (Girl)",
                      long: "4T",
                      display: "4T",
                      display_with_size_set: "4T (Girl)",
                      display_with_size_system: "US 4T",
                      display_with_system_and_set: "US 4T (Girl)",
                      equivalent_sizes: {
                        au: ["4GAU"],
                        eu: ["4AGEU"],
                        uk: ["4GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5TG",
                      short: "5T (Girl)",
                      long: "5T",
                      display: "5T",
                      display_with_size_set: "5T (Girl)",
                      display_with_size_system: "US 5T",
                      display_with_system_and_set: "US 5T (Girl)",
                      equivalent_sizes: {
                        au: ["5GAU"],
                        eu: ["5AGEU"],
                        uk: ["5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4G",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Girl)",
                      equivalent_sizes: {
                        au: ["4GAU"],
                        eu: ["4GEU"],
                        uk: ["4GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5G",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Girl)",
                      equivalent_sizes: {
                        au: ["5GAU"],
                        eu: ["5GEU"],
                        uk: ["5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6G",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Girl)",
                      equivalent_sizes: {
                        au: ["6GAU"],
                        eu: ["6GEU"],
                        uk: ["6GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XG",
                      short: "6X (Girl)",
                      long: "6X",
                      display: "6X",
                      display_with_size_set: "6X (Girl)",
                      display_with_size_system: "US 6X",
                      display_with_system_and_set: "US 6X (Girl)",
                      equivalent_sizes: {
                        au: ["7GAU"],
                        eu: ["6XGEU"],
                        uk: ["7GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7G",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Girl)",
                      equivalent_sizes: {
                        au: ["7GAU"],
                        eu: ["7GEU"],
                        uk: ["7GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8G",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Girl)",
                      equivalent_sizes: {
                        au: ["8GAU"],
                        eu: ["8GEU"],
                        uk: ["8GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10G",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Girl)",
                      equivalent_sizes: {
                        au: ["10GAU"],
                        eu: ["10GEU"],
                        uk: ["10GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12G",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Girl)",
                      equivalent_sizes: {
                        au: ["12GAU"],
                        eu: ["12GEU"],
                        uk: ["12GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14G",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Girl)",
                      equivalent_sizes: {
                        au: ["14GAU"],
                        eu: ["14GEU"],
                        uk: ["14GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16G",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Girl)",
                      equivalent_sizes: {
                        au: ["16GAU"],
                        eu: ["16GEU"],
                        uk: ["16GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSG",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Girl)",
                      equivalent_sizes: {
                        au: ["XSGAU"],
                        eu: ["XSGEU"],
                        uk: ["XSGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SG",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Girl)",
                      equivalent_sizes: {
                        au: ["SGAU"],
                        eu: ["SGEU"],
                        uk: ["SGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MG",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Girl)",
                      equivalent_sizes: {
                        au: ["MGAU"],
                        eu: ["MGEU"],
                        uk: ["MGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LG",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Girl)",
                      equivalent_sizes: {
                        au: ["LGAU"],
                        eu: ["LGEU"],
                        uk: ["LGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLG",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Girl)",
                      equivalent_sizes: {
                        au: ["XLGAU"],
                        eu: ["XLGEU"],
                        uk: ["XLGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLG",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Girl)",
                      equivalent_sizes: {
                        au: ["XXLGAU"],
                        eu: ["XXLGEU"],
                        uk: ["XXLGUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "3GAU",
                      short: "3 (Girl)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Girl)",
                      display_with_size_system: "AU 3",
                      display_with_system_and_set: "AU 3 (Girl)",
                      equivalent_sizes: {
                        us: ["2TG", "3TG"],
                        eu: ["2AGEU", "3AGEU"],
                        uk: ["3GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4GAU",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Girl)",
                      equivalent_sizes: {
                        us: ["4TG", "4G"],
                        eu: ["4AGEU", "4GEU"],
                        uk: ["4GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5GAU",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "AU 5",
                      display_with_system_and_set: "AU 5 (Girl)",
                      equivalent_sizes: {
                        us: ["5TG", "5G"],
                        eu: ["5AGEU", "5GEU"],
                        uk: ["5GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6GAU",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Girl)",
                      equivalent_sizes: {
                        us: ["6G"],
                        eu: ["6GEU"],
                        uk: ["6GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "7GAU",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "AU 7",
                      display_with_system_and_set: "AU 7 (Girl)",
                      equivalent_sizes: {
                        us: ["6XG", "7G"],
                        eu: ["6XGEU", "7GEU"],
                        uk: ["7GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8GAU",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Girl)",
                      equivalent_sizes: {
                        us: ["8G"],
                        eu: ["8GEU"],
                        uk: ["8GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10GAU",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Girl)",
                      equivalent_sizes: {
                        us: ["10G"],
                        eu: ["10GEU"],
                        uk: ["10GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12GAU",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Girl)",
                      equivalent_sizes: {
                        us: ["12G"],
                        eu: ["12GEU"],
                        uk: ["12GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14GAU",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Girl)",
                      equivalent_sizes: {
                        us: ["14G"],
                        eu: ["14GEU"],
                        uk: ["14GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16GAU",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Girl)",
                      equivalent_sizes: {
                        us: ["16G"],
                        eu: ["16GEU"],
                        uk: ["16GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSGAU",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Girl)",
                      equivalent_sizes: {
                        us: ["XSG"],
                        eu: ["XSGEU"],
                        uk: ["XSGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SGAU",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Girl)",
                      equivalent_sizes: {
                        us: ["SG"],
                        eu: ["SGEU"],
                        uk: ["SGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MGAU",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Girl)",
                      equivalent_sizes: {
                        us: ["MG"],
                        eu: ["MGEU"],
                        uk: ["MGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LGAU",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Girl)",
                      equivalent_sizes: {
                        us: ["LG"],
                        eu: ["LGEU"],
                        uk: ["LGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLGAU",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Girl)",
                      equivalent_sizes: {
                        us: ["XLG"],
                        eu: ["XLGEU"],
                        uk: ["XLGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLGAU",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Girl)",
                      equivalent_sizes: {
                        us: ["XXLG"],
                        eu: ["XXLGEU"],
                        uk: ["XXLGUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "2AGEU",
                      short: "2A (Girl)",
                      long: "2A",
                      display: "2A",
                      display_with_size_set: "2A (Girl)",
                      display_with_size_system: "EU 2A",
                      display_with_system_and_set: "EU 2A (Girl)",
                      equivalent_sizes: {
                        us: ["2TG"],
                        au: ["3GAU"],
                        uk: ["3GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3AGEU",
                      short: "3A (Girl)",
                      long: "3A",
                      display: "3A",
                      display_with_size_set: "3A (Girl)",
                      display_with_size_system: "EU 3A",
                      display_with_system_and_set: "EU 3A (Girl)",
                      equivalent_sizes: {
                        us: ["3TG"],
                        au: ["3GAU"],
                        uk: ["3GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4AGEU",
                      short: "4A (Girl)",
                      long: "4A",
                      display: "4A",
                      display_with_size_set: "4A (Girl)",
                      display_with_size_system: "EU 4A",
                      display_with_system_and_set: "EU 4A (Girl)",
                      equivalent_sizes: {
                        us: ["4TG"],
                        au: ["4GAU"],
                        uk: ["4GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4GEU",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "EU 4",
                      display_with_system_and_set: "EU 4 (Girl)",
                      equivalent_sizes: {
                        us: ["4G"],
                        au: ["4GAU"],
                        uk: ["4GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5AGEU",
                      short: "5A (Girl)",
                      long: "5A",
                      display: "5A",
                      display_with_size_set: "5A (Girl)",
                      display_with_size_system: "EU 5A",
                      display_with_system_and_set: "EU 5A (Girl)",
                      equivalent_sizes: {
                        us: ["5TG"],
                        au: ["5GAU"],
                        uk: ["5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5GEU",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "EU 5",
                      display_with_system_and_set: "EU 5 (Girl)",
                      equivalent_sizes: {
                        us: ["5G"],
                        au: ["5GAU"],
                        uk: ["5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XGEU",
                      short: "6X (Girl)",
                      long: "6X",
                      display: "6X",
                      display_with_size_set: "6X (Girl)",
                      display_with_size_system: "EU 6X",
                      display_with_system_and_set: "EU 6X (Girl)",
                      equivalent_sizes: {
                        us: ["6XG"],
                        au: ["7GAU"],
                        uk: ["7GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6GEU",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "EU 6",
                      display_with_system_and_set: "EU 6 (Girl)",
                      equivalent_sizes: {
                        us: ["6G"],
                        au: ["6GAU"],
                        uk: ["6GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "7GEU",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "EU 7",
                      display_with_system_and_set: "EU 7 (Girl)",
                      equivalent_sizes: {
                        us: ["7G"],
                        au: ["7GAU"],
                        uk: ["7GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "8GEU",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "EU 8",
                      display_with_system_and_set: "EU 8 (Girl)",
                      equivalent_sizes: {
                        us: ["8G"],
                        au: ["8GAU"],
                        uk: ["8GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "10GEU",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "EU 10",
                      display_with_system_and_set: "EU 10 (Girl)",
                      equivalent_sizes: {
                        us: ["10G"],
                        au: ["10GAU"],
                        uk: ["10GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12GEU",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "EU 12",
                      display_with_system_and_set: "EU 12 (Girl)",
                      equivalent_sizes: {
                        us: ["12G"],
                        au: ["12GAU"],
                        uk: ["12GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "14GEU",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "EU 14",
                      display_with_system_and_set: "EU 14 (Girl)",
                      equivalent_sizes: {
                        us: ["14G"],
                        au: ["14GAU"],
                        uk: ["14GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "16GEU",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "EU 16",
                      display_with_system_and_set: "EU 16 (Girl)",
                      equivalent_sizes: {
                        us: ["16G"],
                        au: ["16GAU"],
                        uk: ["16GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSGEU",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Girl)",
                      equivalent_sizes: {
                        us: ["XSG"],
                        au: ["XSGAU"],
                        uk: ["XSGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SGEU",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Girl)",
                      equivalent_sizes: {
                        us: ["SG"],
                        au: ["SGAU"],
                        uk: ["SGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MGEU",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Girl)",
                      equivalent_sizes: {
                        us: ["MG"],
                        au: ["MGAU"],
                        uk: ["MGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LGEU",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Girl)",
                      equivalent_sizes: {
                        us: ["LG"],
                        au: ["LGAU"],
                        uk: ["LGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLGEU",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Girl)",
                      equivalent_sizes: {
                        us: ["XLG"],
                        au: ["XLGAU"],
                        uk: ["XLGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLGEU",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Girl)",
                      equivalent_sizes: {
                        us: ["XXLG"],
                        au: ["XXLGAU"],
                        uk: ["XXLGUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "3GUK",
                      short: "3 (Girl)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Girl)",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3 (Girl)",
                      equivalent_sizes: {
                        us: ["2TG", "3TG"],
                        au: ["3GAU"],
                        eu: ["2AGEU", "3AGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4GUK",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Girl)",
                      equivalent_sizes: {
                        us: ["4TG", "4G"],
                        au: ["4GAU"],
                        eu: ["4AGEU", "4GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5GUK",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "UK 5",
                      display_with_system_and_set: "UK 5 (Girl)",
                      equivalent_sizes: {
                        us: ["5TG", "5G"],
                        au: ["5GAU"],
                        eu: ["5AGEU", "5GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6GUK",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Girl)",
                      equivalent_sizes: {
                        us: ["6G"],
                        au: ["6GAU"],
                        eu: ["6GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "7GUK",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "UK 7",
                      display_with_system_and_set: "UK 7 (Girl)",
                      equivalent_sizes: {
                        us: ["6XG", "7G"],
                        au: ["7GAU"],
                        eu: ["6XGEU", "7GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8GUK",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Girl)",
                      equivalent_sizes: {
                        us: ["8G"],
                        au: ["8GAU"],
                        eu: ["8GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10GUK",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Girl)",
                      equivalent_sizes: {
                        us: ["10G"],
                        au: ["10GAU"],
                        eu: ["10GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12GUK",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Girl)",
                      equivalent_sizes: {
                        us: ["12G"],
                        au: ["12GAU"],
                        eu: ["12GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14GUK",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Girl)",
                      equivalent_sizes: {
                        us: ["14G"],
                        au: ["14GAU"],
                        eu: ["14GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16GUK",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Girl)",
                      equivalent_sizes: {
                        us: ["16G"],
                        au: ["16GAU"],
                        eu: ["16GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSGUK",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Girl)",
                      equivalent_sizes: {
                        us: ["XSG"],
                        au: ["XSGAU"],
                        eu: ["XSGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SGUK",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Girl)",
                      equivalent_sizes: {
                        us: ["SG"],
                        au: ["SGAU"],
                        eu: ["SGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MGUK",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Girl)",
                      equivalent_sizes: {
                        us: ["MG"],
                        au: ["MGAU"],
                        eu: ["MGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LGUK",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Girl)",
                      equivalent_sizes: {
                        us: ["LG"],
                        au: ["LGAU"],
                        eu: ["LGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLGUK",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Girl)",
                      equivalent_sizes: {
                        us: ["XLG"],
                        au: ["XLGAU"],
                        eu: ["XLGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLGUK",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Girl)",
                      equivalent_sizes: {
                        us: ["XXLG"],
                        au: ["XXLGAU"],
                        eu: ["XXLGEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "kids_dresses",
          aliases: [],
          slug: "Dresses",
          alias_slugs: [],
          parent_id: "20008c10d97b4e1245005764",
          category_features: [
            {
              id: "70009813d97b4e3995005764",
              display: "Casual",
              type: "cf",
              message_id: "kids_dresses_casual",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Casual",
              alias_slugs: [],
              parent_id: "22008c10d97b4e1245005764",
            },
            {
              id: "6f009813d97b4e3995005764",
              display: "Formal",
              type: "cf",
              message_id: "kids_dresses_formal",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Formal",
              alias_slugs: [],
              parent_id: "22008c10d97b4e1245005764",
            },
          ],
        },
        {
          id: "23008c10d97b4e1245005764",
          display: "Jackets & Coats",
          size_sets: [
            {
              id: "16003667d97b4e6bdb005784",
              name: "Baby",
              tags: ["baby"],
              category_id: "23008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["14.5", "0-18", "15", "15"],
                  ["16", "0-18", "16.5", "16.5"],
                  ["17", "18-23", "17.5", "17.5"],
                  ["18", "23-27", "18.5", "18.5"],
                  ["18.5", "27-28", "19", "19"],
                  ["19", "28-29", "19.5", "19.5"],
                  ["19.75", "29-31", "20.25", "20.25"],
                  ["20.5", "31-33", "21", "21"],
                  ["17", "18-23", "17.5", "17.5"],
                  ["18", "23-27", "18.5", "18.5"],
                  ["18.5", "27-28", "19", "19"],
                  ["19", "28-29", "19.5", "19.5"],
                  ["19.75", "29-31", "20.25", "20.25"],
                  ["20.5", "31-33", "21", "21"],
                ],
                centimeters: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["36.8", "0-45.7", "38.1", "38.1"],
                  ["40.6", "0-45.7", "41.9", "41.9"],
                  ["43.2", "45.7-58.4", "44.5", "44.5"],
                  ["45.7", "58.4-68.6", "47.0", "47.0"],
                  ["47.0", "68.6-71.1", "48.3", "48.3"],
                  ["48.3", "71.1-73.7", "49.5", "49.5"],
                  ["50.2", "73.7-78.7", "51.4", "51.4"],
                  ["52.1", "78.7-83.8", "53.3", "53.3"],
                  ["43.2", "45.7-58.4", "44.5", "44.5"],
                  ["45.7", "58.4-68.6", "47.0", "47.0"],
                  ["47.0", "68.6-71.1", "48.3", "48.3"],
                  ["48.3", "71.1-73.7", "49.5", "49.5"],
                  ["50.2", "73.7-78.7", "51.4", "51.4"],
                  ["52.1", "78.7-83.8", "53.3", "53.3"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["Preemie", "00000", "Preemie", "Preemie"],
                  ["Newborn", "00000", "Newborn", "Newborn"],
                  ["0-3 Months", "0000", "0-3 Months", "0-3 Months"],
                  ["3-6 Months", "000", "3-6 Months", "3-6 Months"],
                  ["6-9 Months", "000", "6-9 Months", "6-9 Months"],
                  ["9-12 Months", "00", "9-12 Months", "9-12 Months"],
                  ["12-18 Months", "0", "12-18 Months", "12-18 Months"],
                  ["18-24 Months", "1", "18-24 Months", "18-24 Months"],
                  ["3 Months", "0000,000", "3 Months", "3 Months"],
                  ["6 Months", "000", "6 Months", "6 Months"],
                  ["9 Months", "000,00", "9 Months", "9 Months"],
                  ["12 Months", "00,0", "12 Months", "12 Months"],
                  ["18 Months", "0,1", "18 Months", "18 Months"],
                  ["24 Months", "1,2", "24 Months", "24 Months"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["Preemie", "00000", "Preemie", "Preemie"],
                ["Newborn", "00000", "Newborn", "Newborn"],
                ["0-3 Months", "0000", "0-3 Months", "0-3 Months"],
                ["3-6 Months", "000", "3-6 Months", "3-6 Months"],
                ["6-9 Months", "000", "6-9 Months", "6-9 Months"],
                ["9-12 Months", "00", "9-12 Months", "9-12 Months"],
                ["12-18 Months", "0", "12-18 Months", "12-18 Months"],
                ["18-24 Months", "1", "18-24 Months", "18-24 Months"],
                ["3 Months", "0000,000", "3 Months", "3 Months"],
                ["6 Months", "000", "6 Months", "6 Months"],
                ["9 Months", "000,00", "9 Months", "9 Months"],
                ["12 Months", "00,0", "12 Months", "12 Months"],
                ["18 Months", "0,1", "18 Months", "18 Months"],
                ["24 Months", "1,2", "24 Months", "24 Months"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "Preemie",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "US Preemie",
                      display_with_system_and_set: "US Preemie",
                      equivalent_sizes: {
                        au: ["00000MBAU"],
                        eu: ["PreemieEU"],
                        uk: ["PreemieUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "Newborn",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "US Newborn",
                      display_with_system_and_set: "US Newborn",
                      equivalent_sizes: {
                        au: ["00000MBAU"],
                        eu: ["NewbornEU"],
                        uk: ["NewbornUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0-3MB",
                      short: "0-3M",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3M",
                      display_with_size_system: "US 0-3",
                      display_with_system_and_set: "US 0-3M",
                      equivalent_sizes: {
                        au: ["0000MBAU"],
                        eu: ["0-3MBEU"],
                        uk: ["0-3MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3-6MB",
                      short: "3-6M",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6M",
                      display_with_size_system: "US 3-6",
                      display_with_system_and_set: "US 3-6M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["3-6MBEU"],
                        uk: ["3-6MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6-9MB",
                      short: "6-9M",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9M",
                      display_with_size_system: "US 6-9",
                      display_with_system_and_set: "US 6-9M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["6-9MBEU"],
                        uk: ["6-9MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9-12MB",
                      short: "9-12M",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12M",
                      display_with_size_system: "US 9-12",
                      display_with_system_and_set: "US 9-12M",
                      equivalent_sizes: {
                        au: ["00MBAU"],
                        eu: ["9-12MBEU"],
                        uk: ["9-12MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12-18MB",
                      short: "12-18M",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18M",
                      display_with_size_system: "US 12-18",
                      display_with_system_and_set: "US 12-18M",
                      equivalent_sizes: {
                        au: ["0MBAU"],
                        eu: ["12-18MBEU"],
                        uk: ["12-18MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18-24MB",
                      short: "18-24M",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24M",
                      display_with_size_system: "US 18-24",
                      display_with_system_and_set: "US 18-24M",
                      equivalent_sizes: {
                        au: ["1MBAU"],
                        eu: ["18-24MBEU"],
                        uk: ["18-24MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3MB",
                      short: "3M",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3M",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3M",
                      equivalent_sizes: {
                        au: ["0000MBAU", "000MBAU"],
                        eu: ["3MBEU"],
                        uk: ["3MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6MB",
                      short: "6M",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6M",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["6MBEU"],
                        uk: ["6MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9MB",
                      short: "9M",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9M",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9M",
                      equivalent_sizes: {
                        au: ["000MBAU", "00MBAU"],
                        eu: ["9MBEU"],
                        uk: ["9MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12MB",
                      short: "12M",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12M",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12M",
                      equivalent_sizes: {
                        au: ["00MBAU", "0MBAU"],
                        eu: ["12MBEU"],
                        uk: ["12MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18MB",
                      short: "18M",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18M",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18M",
                      equivalent_sizes: {
                        au: ["0MBAU", "1MBAU"],
                        eu: ["18MBEU"],
                        uk: ["18MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24MB",
                      short: "24M",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24M",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24M",
                      equivalent_sizes: {
                        au: ["1MBAU", "2MBAU"],
                        eu: ["24MBEU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "00000MBAU",
                      short: "00000",
                      long: "00000",
                      display: "00000",
                      display_with_size_set: "00000",
                      display_with_size_system: "AU 00000",
                      display_with_system_and_set: "AU 00000",
                      equivalent_sizes: {
                        us: ["Preemie ", "Newborn"],
                        eu: ["PreemieEU", "NewbornEU"],
                        uk: ["PreemieUK", "NewbornUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0000MBAU",
                      short: "0000",
                      long: "0000",
                      display: "0000",
                      display_with_size_set: "0000",
                      display_with_size_system: "AU 0000",
                      display_with_system_and_set: "AU 0000",
                      equivalent_sizes: {
                        us: ["0-3MB", "3MB"],
                        eu: ["0-3MBEU", "3MBEU"],
                        uk: ["0-3MBUK", "3MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "000MBAU",
                      short: "000",
                      long: "000",
                      display: "000",
                      display_with_size_set: "000",
                      display_with_size_system: "AU 000",
                      display_with_system_and_set: "AU 000",
                      equivalent_sizes: {
                        us: ["3MB", "6MB", "9MB", "3-6MB", "6-9MB"],
                        eu: ["3MBEU", "6MBEU", "9MBEU", "3-6MBEU", "6-9MBEU"],
                        uk: ["3MBUK", "6MBUK", "9MBUK", "3-6MBUK", "6-9MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "00MBAU",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "AU 00",
                      display_with_system_and_set: "AU 00",
                      equivalent_sizes: {
                        us: ["9MB", "12MB", "9-12MB"],
                        eu: ["9MBEU", "12MBEU", "9-12MBEU"],
                        uk: ["9MBUK", "12MBUK", "9-12MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0MBAU",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "AU 0",
                      display_with_system_and_set: "AU 0",
                      equivalent_sizes: {
                        us: ["12MB", "18MB", "12-18MB"],
                        eu: ["12MBEU", "18MBEU", "12-18MBEU"],
                        uk: ["12MBUK", "18MBUK", "12-18MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1MBAU",
                      short: "1",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1",
                      display_with_size_system: "AU 1",
                      display_with_system_and_set: "AU 1",
                      equivalent_sizes: {
                        us: ["18MB", "24MB", "18-24MB"],
                        eu: ["18MBEU", "24MBEU", "18-24MBEU"],
                        uk: ["18MBUK", "24MBUK", "18-24MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2MBAU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["24MB"],
                        eu: ["24MBEU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "PreemieEU",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "EU Preemie",
                      display_with_system_and_set: "EU Preemie",
                      equivalent_sizes: {
                        us: ["Preemie"],
                        au: ["00000MBAU"],
                        uk: ["PreemieUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "NewbornEU",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "EU Newborn",
                      display_with_system_and_set: "EU Newborn",
                      equivalent_sizes: {
                        us: ["Newborn"],
                        au: ["00000MBAU"],
                        uk: ["NewbornUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0-3MBEU",
                      short: "0-3",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3",
                      display_with_size_system: "EU 0-3",
                      display_with_system_and_set: "EU 0-3",
                      equivalent_sizes: {
                        us: ["0-3MB"],
                        au: ["0000MBAU"],
                        uk: ["0-3MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3-6MBEU",
                      short: "3-6",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6",
                      display_with_size_system: "EU 3-6",
                      display_with_system_and_set: "EU 3-6",
                      equivalent_sizes: {
                        us: ["3-6MB"],
                        au: ["000MBAU"],
                        uk: ["3-6MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6-9MBEU",
                      short: "6-9",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9",
                      display_with_size_system: "EU 6-9",
                      display_with_system_and_set: "EU 6-9",
                      equivalent_sizes: {
                        us: ["6-9MB"],
                        au: ["000MBAU"],
                        uk: ["6-9MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "9-12MBEU",
                      short: "9-12",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12",
                      display_with_size_system: "EU 9-12",
                      display_with_system_and_set: "EU 9-12",
                      equivalent_sizes: {
                        us: ["9-12MB"],
                        au: ["00MBAU"],
                        uk: ["9-12MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12-18MBEU",
                      short: "12-18",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18",
                      display_with_size_system: "EU 12-18",
                      display_with_system_and_set: "EU 12-18",
                      equivalent_sizes: {
                        us: ["12-18MB"],
                        au: ["0MBAU"],
                        uk: ["12-18MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18-24MBEU",
                      short: "18-24",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24",
                      display_with_size_system: "EU 18-24",
                      display_with_system_and_set: "EU 18-24",
                      equivalent_sizes: {
                        us: ["18-24MB"],
                        au: ["1MBAU"],
                        uk: ["18-24MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3MBEU",
                      short: "3",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3",
                      display_with_size_system: "EU 3",
                      display_with_system_and_set: "EU 3",
                      equivalent_sizes: {
                        us: ["3MB"],
                        au: ["0000MBAU", "000MBAU"],
                        uk: ["3MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6MBEU",
                      short: "6",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6",
                      display_with_size_system: "EU 6",
                      display_with_system_and_set: "EU 6",
                      equivalent_sizes: {
                        us: ["6MB"],
                        au: ["000MBAU"],
                        uk: ["6MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "9MBEU",
                      short: "9",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9",
                      display_with_size_system: "EU 9",
                      display_with_system_and_set: "EU 9",
                      equivalent_sizes: {
                        us: ["9MB"],
                        au: ["000MBAU", "00MBAU"],
                        uk: ["9MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12MBEU",
                      short: "12",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12",
                      display_with_size_system: "EU 12",
                      display_with_system_and_set: "EU 12",
                      equivalent_sizes: {
                        us: ["12MB"],
                        au: ["00MBAU", "0MBAU"],
                        uk: ["12MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18MBEU",
                      short: "18",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18",
                      display_with_size_system: "EU 18",
                      display_with_system_and_set: "EU 18",
                      equivalent_sizes: {
                        us: ["18MB"],
                        au: ["0MBAU", "1MBAU"],
                        uk: ["18MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "24MBEU",
                      short: "24",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24",
                      display_with_size_system: "EU 24",
                      display_with_system_and_set: "EU 24",
                      equivalent_sizes: {
                        us: ["24MB"],
                        au: ["1MBAU", "2MBAU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "PreemieUK",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "UK Preemie",
                      display_with_system_and_set: "UK Preemie",
                      equivalent_sizes: {
                        us: ["Preemie"],
                        au: ["00000MBAU"],
                        eu: ["PreemieEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "NewbornUK",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "UK Newborn",
                      display_with_system_and_set: "UK Newborn",
                      equivalent_sizes: {
                        us: ["Newborn"],
                        au: ["00000MBAU"],
                        eu: ["NewbornEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0-3MBUK",
                      short: "0-3M",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3M",
                      display_with_size_system: "UK 0-3",
                      display_with_system_and_set: "UK 0-3M",
                      equivalent_sizes: {
                        us: ["0-3MB"],
                        au: ["0000MBAU"],
                        eu: ["0-3MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3-6MBUK",
                      short: "3-6M",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6M",
                      display_with_size_system: "UK 3-6",
                      display_with_system_and_set: "UK 3-6M",
                      equivalent_sizes: {
                        us: ["3-6MB"],
                        au: ["000MBAU"],
                        eu: ["3-6MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6-9MBUK",
                      short: "6-9M",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9M",
                      display_with_size_system: "UK 6-9",
                      display_with_system_and_set: "UK 6-9M",
                      equivalent_sizes: {
                        us: ["6-9MB"],
                        au: ["000MBAU"],
                        eu: ["6-9MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9-12MBUK",
                      short: "9-12M",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12M",
                      display_with_size_system: "UK 9-12",
                      display_with_system_and_set: "UK 9-12M",
                      equivalent_sizes: {
                        us: ["9-12MB"],
                        au: ["00MBAU"],
                        eu: ["9-12MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12-18MBUK",
                      short: "12-18M",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18M",
                      display_with_size_system: "UK 12-18",
                      display_with_system_and_set: "UK 12-18M",
                      equivalent_sizes: {
                        us: ["12-18MB"],
                        au: ["0MBAU"],
                        eu: ["12-18MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18-24MBUK",
                      short: "18-24M",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24M",
                      display_with_size_system: "UK 18-24",
                      display_with_system_and_set: "UK 18-24M",
                      equivalent_sizes: {
                        us: ["18-24MB"],
                        au: ["1MBAU"],
                        eu: ["18-24MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3MBUK",
                      short: "3M",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3M",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3M",
                      equivalent_sizes: {
                        us: ["3MB"],
                        au: ["0000MBAU", "000MBAU"],
                        eu: ["3MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MBUK",
                      short: "6M",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6M",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6M",
                      equivalent_sizes: {
                        us: ["6MB"],
                        au: ["000MBAU"],
                        eu: ["6MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9MBUK",
                      short: "9M",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9M",
                      display_with_size_system: "UK 9",
                      display_with_system_and_set: "UK 9M",
                      equivalent_sizes: {
                        us: ["9MB"],
                        au: ["000MBAU", "00MBAU"],
                        eu: ["9MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MBUK",
                      short: "12M",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12M",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12M",
                      equivalent_sizes: {
                        us: ["12MB"],
                        au: ["00MBAU", "0MBAU"],
                        eu: ["12MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MBUK",
                      short: "18M",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18M",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18M",
                      equivalent_sizes: {
                        us: ["18MB"],
                        au: ["0MBAU", "1MBAU"],
                        eu: ["18MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MBUK",
                      short: "24M",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24M",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24M",
                      equivalent_sizes: {
                        us: ["24MB"],
                        au: ["1MBAU", "2MBAU"],
                        eu: ["24MBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "17003667d97b4e6bdb005784",
              name: "Girls",
              tags: ["girls"],
              category_id: "23008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["20.5", "33.5-35.5", "21", "21"],
                  ["21", "36-38", "21.5", "22"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["24", "45-46.5", "23", "25"],
                  ["25.5", "47-49.5", "23.5", "26.5"],
                  ["26", "49-50.5", "24", "27.5"],
                  ["27", "51-52.5", "24.5", "28.5"],
                  ["28.5", "53-55", "25", "30"],
                  ["30", "55.5-58", "26", "31.5"],
                  ["31.5", "58.5-61", "27.5", "33"],
                  ["33", "61.5-62.5", "29", "34.5"],
                  ["22.0-23.0", "38.5-44.5", "22.0-22.5", "23.0-24.0"],
                  ["24.0-26.0", "45-50.5", "23.0-24.0", "25.0-27.5"],
                  ["27", "51-52.5", "24.5", "28.5"],
                  ["28.5", "53-55", "25", "30"],
                  ["30", "55.5-58", "26", "31.5"],
                  ["31.5-33.0", "58.5-62.5", "27.5-29.0", "33-34.5"],
                ],
                centimeters: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["52.1", "85.1-90.2", "53.3", "53.3"],
                  ["53.3", "91.4-96.5", "54.6", "55.9"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["61", "114.3-118.1", "58.4", "63.5"],
                  ["64.8", "119.4-125.7", "59.7", "67.3"],
                  ["66", "125.7-128.3", "61", "69.9"],
                  ["68.6", "129.5-133.4", "62.2", "72.4"],
                  ["72.4", "134.6-139.7", "63.5", "76.2"],
                  ["76.2", "141.0-147.3", "66", "80"],
                  ["80", "148.6-154.9", "69.9", "83.8"],
                  ["83.8", "156.2-158.8", "73.7", "87.6"],
                  ["55.9-58.4", "97.8-113.0", "55.9-57.2", "58.4-61.0"],
                  ["61.0-66.0", "114.3-128.3", "58.4-61.0", "63.5-69.9"],
                  ["68.6", "129.5-133.4", "62.2", "72.4"],
                  ["72.4", "134.6-139.7", "63.5", "76.2"],
                  ["76.2", "141.0-147.3", "66", "80"],
                  ["80.0-83.8", "148.6-158.8", "69.9-73.7", "83.8-87.6"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["6", "6", "6", "6"],
                  ["6X,7", "7", "6X,7", "7"],
                  ["6X,7", "7", "6X,7", "7"],
                  ["8", "8", "8", "8"],
                  ["10", "10", "10", "10"],
                  ["12", "12", "12", "12"],
                  ["14", "14", "14", "14"],
                  ["16", "16", "16", "16"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["2T", "3", "2A", "3"],
                ["3T", "3", "3A", "3"],
                ["4T", "4", "4A", "4"],
                ["5T", "5", "5A", "5"],
                ["4", "4", "4", "4"],
                ["5", "5", "5", "5"],
                ["6", "6", "6", "6"],
                ["6X", "7", "6X", "7"],
                ["7", "7", "7", "7"],
                ["8", "8", "8", "8"],
                ["10", "10", "10", "10"],
                ["12", "12", "12", "12"],
                ["14", "14", "14", "14"],
                ["16", "16", "16", "16"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "2TG",
                      short: "2T (Girl)",
                      long: "2T",
                      display: "2T",
                      display_with_size_set: "2T (Girl)",
                      display_with_size_system: "US 2T",
                      display_with_system_and_set: "US 2T (Girl)",
                      equivalent_sizes: {
                        au: ["3GAU"],
                        eu: ["2AGEU"],
                        uk: ["3GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3TG",
                      short: "3T (Girl)",
                      long: "3T",
                      display: "3T",
                      display_with_size_set: "3T (Girl)",
                      display_with_size_system: "US 3T",
                      display_with_system_and_set: "US 3T (Girl)",
                      equivalent_sizes: {
                        au: ["3GAU"],
                        eu: ["3AGEU"],
                        uk: ["3GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4TG",
                      short: "4T (Girl)",
                      long: "4T",
                      display: "4T",
                      display_with_size_set: "4T (Girl)",
                      display_with_size_system: "US 4T",
                      display_with_system_and_set: "US 4T (Girl)",
                      equivalent_sizes: {
                        au: ["4GAU"],
                        eu: ["4AGEU"],
                        uk: ["4GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5TG",
                      short: "5T (Girl)",
                      long: "5T",
                      display: "5T",
                      display_with_size_set: "5T (Girl)",
                      display_with_size_system: "US 5T",
                      display_with_system_and_set: "US 5T (Girl)",
                      equivalent_sizes: {
                        au: ["5GAU"],
                        eu: ["5AGEU"],
                        uk: ["5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4G",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Girl)",
                      equivalent_sizes: {
                        au: ["4GAU"],
                        eu: ["4GEU"],
                        uk: ["4GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5G",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Girl)",
                      equivalent_sizes: {
                        au: ["5GAU"],
                        eu: ["5GEU"],
                        uk: ["5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6G",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Girl)",
                      equivalent_sizes: {
                        au: ["6GAU"],
                        eu: ["6GEU"],
                        uk: ["6GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XG",
                      short: "6X (Girl)",
                      long: "6X",
                      display: "6X",
                      display_with_size_set: "6X (Girl)",
                      display_with_size_system: "US 6X",
                      display_with_system_and_set: "US 6X (Girl)",
                      equivalent_sizes: {
                        au: ["7GAU"],
                        eu: ["6XGEU"],
                        uk: ["7GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7G",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Girl)",
                      equivalent_sizes: {
                        au: ["7GAU"],
                        eu: ["7GEU"],
                        uk: ["7GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8G",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Girl)",
                      equivalent_sizes: {
                        au: ["8GAU"],
                        eu: ["8GEU"],
                        uk: ["8GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10G",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Girl)",
                      equivalent_sizes: {
                        au: ["10GAU"],
                        eu: ["10GEU"],
                        uk: ["10GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12G",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Girl)",
                      equivalent_sizes: {
                        au: ["12GAU"],
                        eu: ["12GEU"],
                        uk: ["12GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14G",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Girl)",
                      equivalent_sizes: {
                        au: ["14GAU"],
                        eu: ["14GEU"],
                        uk: ["14GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16G",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Girl)",
                      equivalent_sizes: {
                        au: ["16GAU"],
                        eu: ["16GEU"],
                        uk: ["16GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSG",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Girl)",
                      equivalent_sizes: {
                        au: ["XSGAU"],
                        eu: ["XSGEU"],
                        uk: ["XSGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SG",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Girl)",
                      equivalent_sizes: {
                        au: ["SGAU"],
                        eu: ["SGEU"],
                        uk: ["SGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MG",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Girl)",
                      equivalent_sizes: {
                        au: ["MGAU"],
                        eu: ["MGEU"],
                        uk: ["MGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LG",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Girl)",
                      equivalent_sizes: {
                        au: ["LGAU"],
                        eu: ["LGEU"],
                        uk: ["LGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLG",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Girl)",
                      equivalent_sizes: {
                        au: ["XLGAU"],
                        eu: ["XLGEU"],
                        uk: ["XLGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLG",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Girl)",
                      equivalent_sizes: {
                        au: ["XXLGAU"],
                        eu: ["XXLGEU"],
                        uk: ["XXLGUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "3GAU",
                      short: "3 (Girl)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Girl)",
                      display_with_size_system: "AU 3",
                      display_with_system_and_set: "AU 3 (Girl)",
                      equivalent_sizes: {
                        us: ["2TG", "3TG"],
                        eu: ["2AGEU", "3AGEU"],
                        uk: ["3GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4GAU",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Girl)",
                      equivalent_sizes: {
                        us: ["4TG", "4G"],
                        eu: ["4AGEU", "4GEU"],
                        uk: ["4GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5GAU",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "AU 5",
                      display_with_system_and_set: "AU 5 (Girl)",
                      equivalent_sizes: {
                        us: ["5TG", "5G"],
                        eu: ["5AGEU", "5GEU"],
                        uk: ["5GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6GAU",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Girl)",
                      equivalent_sizes: {
                        us: ["6G"],
                        eu: ["6GEU"],
                        uk: ["6GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "7GAU",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "AU 7",
                      display_with_system_and_set: "AU 7 (Girl)",
                      equivalent_sizes: {
                        us: ["6XG", "7G"],
                        eu: ["6XGEU", "7GEU"],
                        uk: ["7GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8GAU",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Girl)",
                      equivalent_sizes: {
                        us: ["8G"],
                        eu: ["8GEU"],
                        uk: ["8GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10GAU",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Girl)",
                      equivalent_sizes: {
                        us: ["10G"],
                        eu: ["10GEU"],
                        uk: ["10GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12GAU",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Girl)",
                      equivalent_sizes: {
                        us: ["12G"],
                        eu: ["12GEU"],
                        uk: ["12GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14GAU",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Girl)",
                      equivalent_sizes: {
                        us: ["14G"],
                        eu: ["14GEU"],
                        uk: ["14GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16GAU",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Girl)",
                      equivalent_sizes: {
                        us: ["16G"],
                        eu: ["16GEU"],
                        uk: ["16GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSGAU",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Girl)",
                      equivalent_sizes: {
                        us: ["XSG"],
                        eu: ["XSGEU"],
                        uk: ["XSGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SGAU",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Girl)",
                      equivalent_sizes: {
                        us: ["SG"],
                        eu: ["SGEU"],
                        uk: ["SGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MGAU",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Girl)",
                      equivalent_sizes: {
                        us: ["MG"],
                        eu: ["MGEU"],
                        uk: ["MGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LGAU",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Girl)",
                      equivalent_sizes: {
                        us: ["LG"],
                        eu: ["LGEU"],
                        uk: ["LGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLGAU",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Girl)",
                      equivalent_sizes: {
                        us: ["XLG"],
                        eu: ["XLGEU"],
                        uk: ["XLGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLGAU",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Girl)",
                      equivalent_sizes: {
                        us: ["XXLG"],
                        eu: ["XXLGEU"],
                        uk: ["XXLGUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "2AGEU",
                      short: "2A (Girl)",
                      long: "2A",
                      display: "2A",
                      display_with_size_set: "2A (Girl)",
                      display_with_size_system: "EU 2A",
                      display_with_system_and_set: "EU 2A (Girl)",
                      equivalent_sizes: {
                        us: ["2TG"],
                        au: ["3GAU"],
                        uk: ["3GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3AGEU",
                      short: "3A (Girl)",
                      long: "3A",
                      display: "3A",
                      display_with_size_set: "3A (Girl)",
                      display_with_size_system: "EU 3A",
                      display_with_system_and_set: "EU 3A (Girl)",
                      equivalent_sizes: {
                        us: ["3TG"],
                        au: ["3GAU"],
                        uk: ["3GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4AGEU",
                      short: "4A (Girl)",
                      long: "4A",
                      display: "4A",
                      display_with_size_set: "4A (Girl)",
                      display_with_size_system: "EU 4A",
                      display_with_system_and_set: "EU 4A (Girl)",
                      equivalent_sizes: {
                        us: ["4TG"],
                        au: ["4GAU"],
                        uk: ["4GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4GEU",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "EU 4",
                      display_with_system_and_set: "EU 4 (Girl)",
                      equivalent_sizes: {
                        us: ["4G"],
                        au: ["4GAU"],
                        uk: ["4GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5AGEU",
                      short: "5A (Girl)",
                      long: "5A",
                      display: "5A",
                      display_with_size_set: "5A (Girl)",
                      display_with_size_system: "EU 5A",
                      display_with_system_and_set: "EU 5A (Girl)",
                      equivalent_sizes: {
                        us: ["5TG"],
                        au: ["5GAU"],
                        uk: ["5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5GEU",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "EU 5",
                      display_with_system_and_set: "EU 5 (Girl)",
                      equivalent_sizes: {
                        us: ["5G"],
                        au: ["5GAU"],
                        uk: ["5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XGEU",
                      short: "6X (Girl)",
                      long: "6X",
                      display: "6X",
                      display_with_size_set: "6X (Girl)",
                      display_with_size_system: "EU 6X",
                      display_with_system_and_set: "EU 6X (Girl)",
                      equivalent_sizes: {
                        us: ["6XG"],
                        au: ["7GAU"],
                        uk: ["7GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6GEU",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "EU 6",
                      display_with_system_and_set: "EU 6 (Girl)",
                      equivalent_sizes: {
                        us: ["6G"],
                        au: ["6GAU"],
                        uk: ["6GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "7GEU",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "EU 7",
                      display_with_system_and_set: "EU 7 (Girl)",
                      equivalent_sizes: {
                        us: ["7G"],
                        au: ["7GAU"],
                        uk: ["7GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "8GEU",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "EU 8",
                      display_with_system_and_set: "EU 8 (Girl)",
                      equivalent_sizes: {
                        us: ["8G"],
                        au: ["8GAU"],
                        uk: ["8GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "10GEU",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "EU 10",
                      display_with_system_and_set: "EU 10 (Girl)",
                      equivalent_sizes: {
                        us: ["10G"],
                        au: ["10GAU"],
                        uk: ["10GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12GEU",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "EU 12",
                      display_with_system_and_set: "EU 12 (Girl)",
                      equivalent_sizes: {
                        us: ["12G"],
                        au: ["12GAU"],
                        uk: ["12GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "14GEU",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "EU 14",
                      display_with_system_and_set: "EU 14 (Girl)",
                      equivalent_sizes: {
                        us: ["14G"],
                        au: ["14GAU"],
                        uk: ["14GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "16GEU",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "EU 16",
                      display_with_system_and_set: "EU 16 (Girl)",
                      equivalent_sizes: {
                        us: ["16G"],
                        au: ["16GAU"],
                        uk: ["16GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSGEU",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Girl)",
                      equivalent_sizes: {
                        us: ["XSG"],
                        au: ["XSGAU"],
                        uk: ["XSGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SGEU",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Girl)",
                      equivalent_sizes: {
                        us: ["SG"],
                        au: ["SGAU"],
                        uk: ["SGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MGEU",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Girl)",
                      equivalent_sizes: {
                        us: ["MG"],
                        au: ["MGAU"],
                        uk: ["MGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LGEU",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Girl)",
                      equivalent_sizes: {
                        us: ["LG"],
                        au: ["LGAU"],
                        uk: ["LGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLGEU",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Girl)",
                      equivalent_sizes: {
                        us: ["XLG"],
                        au: ["XLGAU"],
                        uk: ["XLGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLGEU",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Girl)",
                      equivalent_sizes: {
                        us: ["XXLG"],
                        au: ["XXLGAU"],
                        uk: ["XXLGUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "3GUK",
                      short: "3 (Girl)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Girl)",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3 (Girl)",
                      equivalent_sizes: {
                        us: ["2TG", "3TG"],
                        au: ["3GAU"],
                        eu: ["2AGEU", "3AGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4GUK",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Girl)",
                      equivalent_sizes: {
                        us: ["4TG", "4G"],
                        au: ["4GAU"],
                        eu: ["4AGEU", "4GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5GUK",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "UK 5",
                      display_with_system_and_set: "UK 5 (Girl)",
                      equivalent_sizes: {
                        us: ["5TG", "5G"],
                        au: ["5GAU"],
                        eu: ["5AGEU", "5GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6GUK",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Girl)",
                      equivalent_sizes: {
                        us: ["6G"],
                        au: ["6GAU"],
                        eu: ["6GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "7GUK",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "UK 7",
                      display_with_system_and_set: "UK 7 (Girl)",
                      equivalent_sizes: {
                        us: ["6XG", "7G"],
                        au: ["7GAU"],
                        eu: ["6XGEU", "7GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8GUK",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Girl)",
                      equivalent_sizes: {
                        us: ["8G"],
                        au: ["8GAU"],
                        eu: ["8GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10GUK",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Girl)",
                      equivalent_sizes: {
                        us: ["10G"],
                        au: ["10GAU"],
                        eu: ["10GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12GUK",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Girl)",
                      equivalent_sizes: {
                        us: ["12G"],
                        au: ["12GAU"],
                        eu: ["12GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14GUK",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Girl)",
                      equivalent_sizes: {
                        us: ["14G"],
                        au: ["14GAU"],
                        eu: ["14GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16GUK",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Girl)",
                      equivalent_sizes: {
                        us: ["16G"],
                        au: ["16GAU"],
                        eu: ["16GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSGUK",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Girl)",
                      equivalent_sizes: {
                        us: ["XSG"],
                        au: ["XSGAU"],
                        eu: ["XSGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SGUK",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Girl)",
                      equivalent_sizes: {
                        us: ["SG"],
                        au: ["SGAU"],
                        eu: ["SGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MGUK",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Girl)",
                      equivalent_sizes: {
                        us: ["MG"],
                        au: ["MGAU"],
                        eu: ["MGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LGUK",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Girl)",
                      equivalent_sizes: {
                        us: ["LG"],
                        au: ["LGAU"],
                        eu: ["LGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLGUK",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Girl)",
                      equivalent_sizes: {
                        us: ["XLG"],
                        au: ["XLGAU"],
                        eu: ["XLGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLGUK",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Girl)",
                      equivalent_sizes: {
                        us: ["XXLG"],
                        au: ["XXLGAU"],
                        eu: ["XXLGEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "18003667d97b4e6bdb005784",
              name: "Boys",
              tags: ["boys"],
              category_id: "23008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["20.5", "33.5-35.5", "21", "21"],
                  ["21", "36-38", "21.5", "22"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["24", "45-46.5", "23", "25"],
                  ["25.5", "47-49.5", "23.5", "26.5"],
                  ["26.5", "50-52", "24.5", "27"],
                  ["27.5", "52.5-55.5", "25.5", "28"],
                  ["28.5", "56-57.5", "26.5", "29"],
                  ["30", "58-59.5", "28", "30.5"],
                  ["31.5", "60-61.5", "29.5", "32"],
                  ["33", "62-63.5", "30.5", "33"],
                  ["34.5", "64-65", "31.5", "34"],
                  ["22.0-23.0", "38.5-44.5", "22.0-22.5", "23.0-24.0"],
                  ["24.0-26.5", "45-52", "23.0-24.5", "25.0-27.0"],
                  ["26.5", "50-52", "24.5", "27"],
                  ["27.5", "52.5-55.5", "25.5", "28"],
                  ["28.5", "56-57.5", "26.5", "29"],
                  ["30.0-31.5", "58-61.5", "28.0-29.5", "30.5-32.0"],
                ],
                centimeters: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["52.1", "85.1-90.2", "53.3", "53.3"],
                  ["53.3", "91.4-96.5", "54.6", "55.9"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["61", "114.3-118.1", "58.4", "63.5"],
                  ["64.8", "119.4-125.7", "59.7", "67.3"],
                  ["67.3", "127-132.1", "62.2", "68.6"],
                  ["69.9", "133.4-141.0", "64.8", "71.1"],
                  ["72.4", "142.2-146.1", "67.3", "73.7"],
                  ["76.2", "147.3-151.1", "71.1", "77.5"],
                  ["80", "152.4-156.2", "74.9", "81.3"],
                  ["83.8", "157.5-161.3", "77.5", "83.8"],
                  ["87.6", "162.6-165.1", "80", "86.4"],
                  ["55.9-58.4", "97.8-113.0", "55.9-57.2", "58.4-61.0"],
                  ["61.0-67.3", "114.3-132.1", "58.4-62.2", "63.5-68.6"],
                  ["67.3", "127-132.1", "62.2", "68.6"],
                  ["69.9", "133.4-141.0", "64.8", "71.1"],
                  ["72.4", "142.2-146.1", "67.3", "73.7"],
                  ["76.2-80.0", "147.3-156.2", "71.1-74.9", "77.5-81.3"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["6", "6", "6", "6"],
                  ["7", "7", "7", "7"],
                  ["7X,8", "8", "7X,8", "8"],
                  ["10", "10", "10", "10"],
                  ["12", "12", "12", "12"],
                  ["14", "14", "14", "14"],
                  ["16", "16", "16", "16"],
                  ["18", "18", "18", "18"],
                  ["20", "20", "20", "20"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["2T", "3", "2A", "3"],
                ["3T", "3", "3A", "3"],
                ["4T", "4", "4A", "4"],
                ["5T", "5", "5A", "5"],
                ["4", "4", "4", "4"],
                ["5", "5", "5", "5"],
                ["6", "6", "6", "6"],
                ["7", "7", "7", "7"],
                ["7X", "8", "7X", "8"],
                ["8", "8", "8", "8"],
                ["10", "10", "10", "10"],
                ["12", "12", "12", "12"],
                ["14", "14", "14", "14"],
                ["16", "16", "16", "16"],
                ["18", "18", "18", "18"],
                ["20", "20", "20", "20"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "2TB",
                      short: "2T (Boy)",
                      long: "2T",
                      display: "2T",
                      display_with_size_set: "2T (Boy)",
                      display_with_size_system: "US 2T",
                      display_with_system_and_set: "US 2T (Boy)",
                      equivalent_sizes: {
                        au: ["3BAU"],
                        eu: ["2ABEU"],
                        uk: ["3BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3TB",
                      short: "3T (Boy)",
                      long: "3T",
                      display: "3T",
                      display_with_size_set: "3T (Boy)",
                      display_with_size_system: "US 3T",
                      display_with_system_and_set: "US 3T (Boy)",
                      equivalent_sizes: {
                        au: ["3BAU"],
                        eu: ["3ABEU"],
                        uk: ["3BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4TB",
                      short: "4T (Boy)",
                      long: "4T",
                      display: "4T",
                      display_with_size_set: "4T (Boy)",
                      display_with_size_system: "US 4T",
                      display_with_system_and_set: "US 4T (Boy)",
                      equivalent_sizes: {
                        au: ["4BAU"],
                        eu: ["4ABEU"],
                        uk: ["4BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5TB",
                      short: "5T (Boy)",
                      long: "5T",
                      display: "5T",
                      display_with_size_set: "5T (Boy)",
                      display_with_size_system: "US 5T",
                      display_with_system_and_set: "US 5T (Boy)",
                      equivalent_sizes: {
                        au: ["5BAU"],
                        eu: ["5ABEU"],
                        uk: ["5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4B",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Boy)",
                      equivalent_sizes: {
                        au: ["4BAU"],
                        eu: ["4BEU"],
                        uk: ["4BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5B",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Boy)",
                      equivalent_sizes: {
                        au: ["5BAU"],
                        eu: ["5BEU"],
                        uk: ["5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6B",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Boy)",
                      equivalent_sizes: {
                        au: ["6BAU"],
                        eu: ["6BEU"],
                        uk: ["6BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7B",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Boy)",
                      equivalent_sizes: {
                        au: ["7BAU"],
                        eu: ["7BEU"],
                        uk: ["7BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7XB",
                      short: "7X (Boy)",
                      long: "7X",
                      display: "7X",
                      display_with_size_set: "7X (Boy)",
                      display_with_size_system: "US 7X",
                      display_with_system_and_set: "US 7X (Boy)",
                      equivalent_sizes: {
                        au: ["8BAU"],
                        eu: ["7XBEU"],
                        uk: ["8BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8B",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Boy)",
                      equivalent_sizes: {
                        au: ["8BAU"],
                        eu: ["8BEU"],
                        uk: ["8BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10B",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Boy)",
                      equivalent_sizes: {
                        au: ["10BAU"],
                        eu: ["10BEU"],
                        uk: ["10BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12B",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Boy)",
                      equivalent_sizes: {
                        au: ["12BAU"],
                        eu: ["12BEU"],
                        uk: ["12BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14B",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Boy)",
                      equivalent_sizes: {
                        au: ["14BAU"],
                        eu: ["14BEU"],
                        uk: ["14BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16B",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Boy)",
                      equivalent_sizes: {
                        au: ["16BAU"],
                        eu: ["16BEU"],
                        uk: ["16BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18B",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Boy)",
                      equivalent_sizes: {
                        au: ["18BAU"],
                        eu: ["18BEU"],
                        uk: ["18BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20B",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Boy)",
                      equivalent_sizes: {
                        au: ["20BAU"],
                        eu: ["20BEU"],
                        uk: ["20BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSB",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Boy)",
                      equivalent_sizes: {
                        au: ["XSBAU"],
                        eu: ["XSBEU"],
                        uk: ["XSBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SB",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Boy)",
                      equivalent_sizes: {
                        au: ["SBAU"],
                        eu: ["SBEU"],
                        uk: ["SBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MB",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Boy)",
                      equivalent_sizes: {
                        au: ["MBAU"],
                        eu: ["MBEU"],
                        uk: ["MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LB",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Boy)",
                      equivalent_sizes: {
                        au: ["LBAU"],
                        eu: ["LBEU"],
                        uk: ["LBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLB",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Boy)",
                      equivalent_sizes: {
                        au: ["XLBAU"],
                        eu: ["XLBEU"],
                        uk: ["XLBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLB",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Boy)",
                      equivalent_sizes: {
                        au: ["XXLBAU"],
                        eu: ["XXLBEU"],
                        uk: ["XXLBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "3BAU",
                      short: "3 (Boy)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Boy)",
                      display_with_size_system: "AU 3",
                      display_with_system_and_set: "AU 3 (Boy)",
                      equivalent_sizes: {
                        us: ["2TB", "3TB"],
                        eu: ["2ABEU", "3ABEU"],
                        uk: ["3BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4BAU",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Boy)",
                      equivalent_sizes: {
                        us: ["4TB", "4B"],
                        eu: ["4ABEU", "4BEU"],
                        uk: ["4BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5BAU",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "AU 5",
                      display_with_system_and_set: "AU 5 (Boy)",
                      equivalent_sizes: {
                        us: ["5TB", "5B"],
                        eu: ["5ABEU", "5BEU"],
                        uk: ["5BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6BAU",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Boy)",
                      equivalent_sizes: {
                        us: ["6B"],
                        eu: ["6BEU"],
                        uk: ["6BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "7BAU",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "AU 7",
                      display_with_system_and_set: "AU 7 (Boy)",
                      equivalent_sizes: {
                        us: ["7B"],
                        eu: ["7BEU"],
                        uk: ["7BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8BAU",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Boy)",
                      equivalent_sizes: {
                        us: ["7XB", "8B"],
                        eu: ["7XBEU", "8BEU"],
                        uk: ["8BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10BAU",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Boy)",
                      equivalent_sizes: {
                        us: ["10B"],
                        eu: ["10BEU"],
                        uk: ["10BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12BAU",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Boy)",
                      equivalent_sizes: {
                        us: ["12B"],
                        eu: ["12BEU"],
                        uk: ["12BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14BAU",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Boy)",
                      equivalent_sizes: {
                        us: ["14B"],
                        eu: ["14BEU"],
                        uk: ["14BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16BAU",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Boy)",
                      equivalent_sizes: {
                        us: ["16B"],
                        eu: ["16BEU"],
                        uk: ["16BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18BAU",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Boy)",
                      equivalent_sizes: {
                        us: ["18B"],
                        eu: ["18BEU"],
                        uk: ["18BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20BAU",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Boy)",
                      equivalent_sizes: {
                        us: ["20B"],
                        eu: ["20BEU"],
                        uk: ["20BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSBAU",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Boy)",
                      equivalent_sizes: {
                        us: ["XSB"],
                        eu: ["XSBEU"],
                        uk: ["XSBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SBAU",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Boy)",
                      equivalent_sizes: {
                        us: ["SB"],
                        eu: ["SBEU"],
                        uk: ["SBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MBAU",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Boy)",
                      equivalent_sizes: {
                        us: ["MB"],
                        eu: ["MBEU"],
                        uk: ["MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LBAU",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Boy)",
                      equivalent_sizes: {
                        us: ["LB"],
                        eu: ["LBEU"],
                        uk: ["LBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLBAU",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Boy)",
                      equivalent_sizes: {
                        us: ["XLB"],
                        eu: ["XLBEU"],
                        uk: ["XLBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLBAU",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Boy)",
                      equivalent_sizes: {
                        us: ["XXLB"],
                        eu: ["XXLBEU"],
                        uk: ["XXLBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "2ABEU",
                      short: "2A (Boy)",
                      long: "2A",
                      display: "2A",
                      display_with_size_set: "2A (Boy)",
                      display_with_size_system: "EU 2A",
                      display_with_system_and_set: "EU 2A (Boy)",
                      equivalent_sizes: {
                        us: ["2TB"],
                        au: ["3BAU"],
                        uk: ["3BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3ABEU",
                      short: "3A (Boy)",
                      long: "3A",
                      display: "3A",
                      display_with_size_set: "3A (Boy)",
                      display_with_size_system: "EU 3A",
                      display_with_system_and_set: "EU 3A (Boy)",
                      equivalent_sizes: {
                        us: ["3TB"],
                        au: ["3BAU"],
                        uk: ["3BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4ABEU",
                      short: "4A (Boy)",
                      long: "4A",
                      display: "4A",
                      display_with_size_set: "4A (Boy)",
                      display_with_size_system: "EU 4A",
                      display_with_system_and_set: "EU 4A (Boy)",
                      equivalent_sizes: {
                        us: ["4TB"],
                        au: ["4BAU"],
                        uk: ["4BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4BEU",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "EU 4",
                      display_with_system_and_set: "EU 4 (Boy)",
                      equivalent_sizes: {
                        us: ["4B"],
                        au: ["4BAU"],
                        uk: ["4BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5ABEU",
                      short: "5A (Boy)",
                      long: "5A",
                      display: "5A",
                      display_with_size_set: "5A (Boy)",
                      display_with_size_system: "EU 5A",
                      display_with_system_and_set: "EU 5A (Boy)",
                      equivalent_sizes: {
                        us: ["5TB"],
                        au: ["5BAU"],
                        uk: ["5BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5BEU",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "EU 5",
                      display_with_system_and_set: "EU 5 (Boy)",
                      equivalent_sizes: {
                        us: ["5B"],
                        au: ["5BAU"],
                        uk: ["5BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6BEU",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "EU 6",
                      display_with_system_and_set: "EU 6 (Boy)",
                      equivalent_sizes: {
                        us: ["6B"],
                        au: ["6BAU"],
                        uk: ["6BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "7XBEU",
                      short: "7X (Boy)",
                      long: "7X",
                      display: "7X",
                      display_with_size_set: "7X (Boy)",
                      display_with_size_system: "EU 7X",
                      display_with_system_and_set: "EU 7X (Boy)",
                      equivalent_sizes: {
                        us: ["7XB"],
                        au: ["8BAU"],
                        uk: ["8BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "7BEU",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "EU 7",
                      display_with_system_and_set: "EU 7 (Boy)",
                      equivalent_sizes: {
                        us: ["7B"],
                        au: ["7BAU"],
                        uk: ["7BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "8BEU",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "EU 8",
                      display_with_system_and_set: "EU 8 (Boy)",
                      equivalent_sizes: {
                        us: ["8B"],
                        au: ["8BAU"],
                        uk: ["8BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "10BEU",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "EU 10",
                      display_with_system_and_set: "EU 10 (Boy)",
                      equivalent_sizes: {
                        us: ["10B"],
                        au: ["10BAU"],
                        uk: ["10BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12BEU",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "EU 12",
                      display_with_system_and_set: "EU 12 (Boy)",
                      equivalent_sizes: {
                        us: ["12B"],
                        au: ["12BAU"],
                        uk: ["12BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "14BEU",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "EU 14",
                      display_with_system_and_set: "EU 14 (Boy)",
                      equivalent_sizes: {
                        us: ["14B"],
                        au: ["14BAU"],
                        uk: ["14BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "16BEU",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "EU 16",
                      display_with_system_and_set: "EU 16 (Boy)",
                      equivalent_sizes: {
                        us: ["16B"],
                        au: ["16BAU"],
                        uk: ["16BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18BEU",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "EU 18",
                      display_with_system_and_set: "EU 18 (Boy)",
                      equivalent_sizes: {
                        us: ["18B"],
                        au: ["18BAU"],
                        uk: ["18BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "20BEU",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "EU 20",
                      display_with_system_and_set: "EU 20 (Boy)",
                      equivalent_sizes: {
                        us: ["20B"],
                        au: ["20BAU"],
                        uk: ["20BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSBEU",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Boy)",
                      equivalent_sizes: {
                        us: ["XSB"],
                        au: ["XSBAU"],
                        uk: ["XSBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SBEU",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Boy)",
                      equivalent_sizes: {
                        us: ["SB"],
                        au: ["SBAU"],
                        uk: ["SBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MBEU",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Boy)",
                      equivalent_sizes: {
                        us: ["MB"],
                        au: ["MBAU"],
                        uk: ["MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LBEU",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Boy)",
                      equivalent_sizes: {
                        us: ["LB"],
                        au: ["LBAU"],
                        uk: ["LBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLBEU",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Boy)",
                      equivalent_sizes: {
                        us: ["XLB"],
                        au: ["XLBAU"],
                        uk: ["XLBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLBEU",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Boy)",
                      equivalent_sizes: {
                        us: ["XXLB"],
                        au: ["XXLBAU"],
                        uk: ["XXLBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "3BUK",
                      short: "3 (Boy)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Boy)",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3 (Boy)",
                      equivalent_sizes: {
                        us: ["2TB", "3TB"],
                        au: ["3BAU"],
                        eu: ["2ABEU", "3ABEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4BUK",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Boy)",
                      equivalent_sizes: {
                        us: ["4TB", "4B"],
                        au: ["4BAU"],
                        eu: ["4ABEU", "4BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5BUK",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "UK 5",
                      display_with_system_and_set: "UK 5 (Boy)",
                      equivalent_sizes: {
                        us: ["5TB", "5B"],
                        au: ["5BAU"],
                        eu: ["5ABEU", "5BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6BUK",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Boy)",
                      equivalent_sizes: {
                        us: ["6B"],
                        au: ["6BAU"],
                        eu: ["6BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "7BUK",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "UK 7",
                      display_with_system_and_set: "UK 7 (Boy)",
                      equivalent_sizes: {
                        us: ["7B"],
                        au: ["7BAU"],
                        eu: ["7BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8BUK",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Boy)",
                      equivalent_sizes: {
                        us: ["7XB", "8B"],
                        au: ["8BAU"],
                        eu: ["7XBEU", "8BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10BUK",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Boy)",
                      equivalent_sizes: {
                        us: ["10B"],
                        au: ["10BAU"],
                        eu: ["10BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12BUK",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Boy)",
                      equivalent_sizes: {
                        us: ["12B"],
                        au: ["12BAU"],
                        eu: ["12BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14BUK",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Boy)",
                      equivalent_sizes: {
                        us: ["14B"],
                        au: ["14BAU"],
                        eu: ["14BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16BUK",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Boy)",
                      equivalent_sizes: {
                        us: ["16B"],
                        au: ["16BAU"],
                        eu: ["16BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18BUK",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Boy)",
                      equivalent_sizes: {
                        us: ["18B"],
                        au: ["18BAU"],
                        eu: ["18BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20BUK",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Boy)",
                      equivalent_sizes: {
                        us: ["20B"],
                        au: ["20BAU"],
                        eu: ["20BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSBUK",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Boy)",
                      equivalent_sizes: {
                        us: ["XSB"],
                        au: ["XSBAU"],
                        eu: ["XSBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SBUK",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Boy)",
                      equivalent_sizes: {
                        us: ["SB"],
                        au: ["SBAU"],
                        eu: ["SBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MBUK",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Boy)",
                      equivalent_sizes: {
                        us: ["MB"],
                        au: ["MBAU"],
                        eu: ["MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LBUK",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Boy)",
                      equivalent_sizes: {
                        us: ["LB"],
                        au: ["LBAU"],
                        eu: ["LBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLBUK",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Boy)",
                      equivalent_sizes: {
                        us: ["XLB"],
                        au: ["XLBAU"],
                        eu: ["XLBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLBUK",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Boy)",
                      equivalent_sizes: {
                        us: ["XXLB"],
                        au: ["XXLBAU"],
                        eu: ["XXLBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "kids_jackets_coats",
          aliases: [],
          slug: "Jackets_&_Coats",
          alias_slugs: [],
          parent_id: "20008c10d97b4e1245005764",
          category_features: [
            {
              id: "71009813d97b4e3995005764",
              display: "Blazers",
              type: "cf",
              message_id: "kids_jackets_coats_blazers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Blazers",
              alias_slugs: [],
              parent_id: "23008c10d97b4e1245005764",
            },
            {
              id: "72009813d97b4e3995005764",
              display: "Capes",
              type: "cf",
              message_id: "kids_jackets_coats_capes",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Capes",
              alias_slugs: [],
              parent_id: "23008c10d97b4e1245005764",
            },
            {
              id: "73009813d97b4e3995005764",
              display: "Jean Jackets",
              type: "cf",
              message_id: "kids_jackets_coats_jean_jackets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Jean_Jackets",
              alias_slugs: [],
              parent_id: "23008c10d97b4e1245005764",
            },
            {
              id: "75009813d97b4e3995005764",
              display: "Pea Coats",
              type: "cf",
              message_id: "kids_jackets_coats_pea_coats",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Pea_Coats",
              alias_slugs: [],
              parent_id: "23008c10d97b4e1245005764",
            },
            {
              id: "76009813d97b4e3995005764",
              display: "Puffers",
              type: "cf",
              message_id: "kids_jackets_coats_puffers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Puffers",
              alias_slugs: [],
              parent_id: "23008c10d97b4e1245005764",
            },
            {
              id: "77009813d97b4e3995005764",
              display: "Raincoats",
              type: "cf",
              message_id: "kids_jackets_coats_raincoats",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Raincoats",
              alias_slugs: [],
              parent_id: "23008c10d97b4e1245005764",
            },
            {
              id: "78009813d97b4e3995005764",
              display: "Vests",
              type: "cf",
              message_id: "kids_jackets_coats_vests",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Vests",
              alias_slugs: [],
              parent_id: "23008c10d97b4e1245005764",
            },
          ],
        },
        {
          id: "26008c10d97b4e1245005764",
          display: "Matching Sets",
          size_sets: [
            {
              id: "19003667d97b4e6bdb005784",
              name: "Baby",
              tags: ["baby"],
              category_id: "26008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["14.5", "0-18", "15", "15"],
                  ["16", "0-18", "16.5", "16.5"],
                  ["17", "18-23", "17.5", "17.5"],
                  ["18", "23-27", "18.5", "18.5"],
                  ["18.5", "27-28", "19", "19"],
                  ["19", "28-29", "19.5", "19.5"],
                  ["19.75", "29-31", "20.25", "20.25"],
                  ["20.5", "31-33", "21", "21"],
                  ["17", "18-23", "17.5", "17.5"],
                  ["18", "23-27", "18.5", "18.5"],
                  ["18.5", "27-28", "19", "19"],
                  ["19", "28-29", "19.5", "19.5"],
                  ["19.75", "29-31", "20.25", "20.25"],
                  ["20.5", "31-33", "21", "21"],
                ],
                centimeters: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["36.8", "0-45.7", "38.1", "38.1"],
                  ["40.6", "0-45.7", "41.9", "41.9"],
                  ["43.2", "45.7-58.4", "44.5", "44.5"],
                  ["45.7", "58.4-68.6", "47.0", "47.0"],
                  ["47.0", "68.6-71.1", "48.3", "48.3"],
                  ["48.3", "71.1-73.7", "49.5", "49.5"],
                  ["50.2", "73.7-78.7", "51.4", "51.4"],
                  ["52.1", "78.7-83.8", "53.3", "53.3"],
                  ["43.2", "45.7-58.4", "44.5", "44.5"],
                  ["45.7", "58.4-68.6", "47.0", "47.0"],
                  ["47.0", "68.6-71.1", "48.3", "48.3"],
                  ["48.3", "71.1-73.7", "49.5", "49.5"],
                  ["50.2", "73.7-78.7", "51.4", "51.4"],
                  ["52.1", "78.7-83.8", "53.3", "53.3"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["Preemie", "00000", "Preemie", "Preemie"],
                  ["Newborn", "00000", "Newborn", "Newborn"],
                  ["0-3 Months", "0000", "0-3 Months", "0-3 Months"],
                  ["3-6 Months", "000", "3-6 Months", "3-6 Months"],
                  ["6-9 Months", "000", "6-9 Months", "6-9 Months"],
                  ["9-12 Months", "00", "9-12 Months", "9-12 Months"],
                  ["12-18 Months", "0", "12-18 Months", "12-18 Months"],
                  ["18-24 Months", "1", "18-24 Months", "18-24 Months"],
                  ["3 Months", "0000,000", "3 Months", "3 Months"],
                  ["6 Months", "000", "6 Months", "6 Months"],
                  ["9 Months", "000,00", "9 Months", "9 Months"],
                  ["12 Months", "00,0", "12 Months", "12 Months"],
                  ["18 Months", "0,1", "18 Months", "18 Months"],
                  ["24 Months", "1,2", "24 Months", "24 Months"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["Preemie", "00000", "Preemie", "Preemie"],
                ["Newborn", "00000", "Newborn", "Newborn"],
                ["0-3 Months", "0000", "0-3 Months", "0-3 Months"],
                ["3-6 Months", "000", "3-6 Months", "3-6 Months"],
                ["6-9 Months", "000", "6-9 Months", "6-9 Months"],
                ["9-12 Months", "00", "9-12 Months", "9-12 Months"],
                ["12-18 Months", "0", "12-18 Months", "12-18 Months"],
                ["18-24 Months", "1", "18-24 Months", "18-24 Months"],
                ["3 Months", "0000,000", "3 Months", "3 Months"],
                ["6 Months", "000", "6 Months", "6 Months"],
                ["9 Months", "000,00", "9 Months", "9 Months"],
                ["12 Months", "00,0", "12 Months", "12 Months"],
                ["18 Months", "0,1", "18 Months", "18 Months"],
                ["24 Months", "1,2", "24 Months", "24 Months"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "Preemie",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "US Preemie",
                      display_with_system_and_set: "US Preemie",
                      equivalent_sizes: {
                        au: ["00000MBAU"],
                        eu: ["PreemieEU"],
                        uk: ["PreemieUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "Newborn",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "US Newborn",
                      display_with_system_and_set: "US Newborn",
                      equivalent_sizes: {
                        au: ["00000MBAU"],
                        eu: ["NewbornEU"],
                        uk: ["NewbornUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0-3MB",
                      short: "0-3M",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3M",
                      display_with_size_system: "US 0-3",
                      display_with_system_and_set: "US 0-3M",
                      equivalent_sizes: {
                        au: ["0000MBAU"],
                        eu: ["0-3MBEU"],
                        uk: ["0-3MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3-6MB",
                      short: "3-6M",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6M",
                      display_with_size_system: "US 3-6",
                      display_with_system_and_set: "US 3-6M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["3-6MBEU"],
                        uk: ["3-6MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6-9MB",
                      short: "6-9M",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9M",
                      display_with_size_system: "US 6-9",
                      display_with_system_and_set: "US 6-9M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["6-9MBEU"],
                        uk: ["6-9MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9-12MB",
                      short: "9-12M",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12M",
                      display_with_size_system: "US 9-12",
                      display_with_system_and_set: "US 9-12M",
                      equivalent_sizes: {
                        au: ["00MBAU"],
                        eu: ["9-12MBEU"],
                        uk: ["9-12MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12-18MB",
                      short: "12-18M",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18M",
                      display_with_size_system: "US 12-18",
                      display_with_system_and_set: "US 12-18M",
                      equivalent_sizes: {
                        au: ["0MBAU"],
                        eu: ["12-18MBEU"],
                        uk: ["12-18MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18-24MB",
                      short: "18-24M",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24M",
                      display_with_size_system: "US 18-24",
                      display_with_system_and_set: "US 18-24M",
                      equivalent_sizes: {
                        au: ["1MBAU"],
                        eu: ["18-24MBEU"],
                        uk: ["18-24MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3MB",
                      short: "3M",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3M",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3M",
                      equivalent_sizes: {
                        au: ["0000MBAU", "000MBAU"],
                        eu: ["3MBEU"],
                        uk: ["3MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6MB",
                      short: "6M",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6M",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["6MBEU"],
                        uk: ["6MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9MB",
                      short: "9M",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9M",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9M",
                      equivalent_sizes: {
                        au: ["000MBAU", "00MBAU"],
                        eu: ["9MBEU"],
                        uk: ["9MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12MB",
                      short: "12M",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12M",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12M",
                      equivalent_sizes: {
                        au: ["00MBAU", "0MBAU"],
                        eu: ["12MBEU"],
                        uk: ["12MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18MB",
                      short: "18M",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18M",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18M",
                      equivalent_sizes: {
                        au: ["0MBAU", "1MBAU"],
                        eu: ["18MBEU"],
                        uk: ["18MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24MB",
                      short: "24M",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24M",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24M",
                      equivalent_sizes: {
                        au: ["1MBAU", "2MBAU"],
                        eu: ["24MBEU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "00000MBAU",
                      short: "00000",
                      long: "00000",
                      display: "00000",
                      display_with_size_set: "00000",
                      display_with_size_system: "AU 00000",
                      display_with_system_and_set: "AU 00000",
                      equivalent_sizes: {
                        us: ["Preemie ", "Newborn"],
                        eu: ["PreemieEU", "NewbornEU"],
                        uk: ["PreemieUK", "NewbornUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0000MBAU",
                      short: "0000",
                      long: "0000",
                      display: "0000",
                      display_with_size_set: "0000",
                      display_with_size_system: "AU 0000",
                      display_with_system_and_set: "AU 0000",
                      equivalent_sizes: {
                        us: ["0-3MB", "3MB"],
                        eu: ["0-3MBEU", "3MBEU"],
                        uk: ["0-3MBUK", "3MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "000MBAU",
                      short: "000",
                      long: "000",
                      display: "000",
                      display_with_size_set: "000",
                      display_with_size_system: "AU 000",
                      display_with_system_and_set: "AU 000",
                      equivalent_sizes: {
                        us: ["3MB", "6MB", "9MB", "3-6MB", "6-9MB"],
                        eu: ["3MBEU", "6MBEU", "9MBEU", "3-6MBEU", "6-9MBEU"],
                        uk: ["3MBUK", "6MBUK", "9MBUK", "3-6MBUK", "6-9MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "00MBAU",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "AU 00",
                      display_with_system_and_set: "AU 00",
                      equivalent_sizes: {
                        us: ["9MB", "12MB", "9-12MB"],
                        eu: ["9MBEU", "12MBEU", "9-12MBEU"],
                        uk: ["9MBUK", "12MBUK", "9-12MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0MBAU",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "AU 0",
                      display_with_system_and_set: "AU 0",
                      equivalent_sizes: {
                        us: ["12MB", "18MB", "12-18MB"],
                        eu: ["12MBEU", "18MBEU", "12-18MBEU"],
                        uk: ["12MBUK", "18MBUK", "12-18MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1MBAU",
                      short: "1",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1",
                      display_with_size_system: "AU 1",
                      display_with_system_and_set: "AU 1",
                      equivalent_sizes: {
                        us: ["18MB", "24MB", "18-24MB"],
                        eu: ["18MBEU", "24MBEU", "18-24MBEU"],
                        uk: ["18MBUK", "24MBUK", "18-24MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2MBAU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["24MB"],
                        eu: ["24MBEU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "PreemieEU",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "EU Preemie",
                      display_with_system_and_set: "EU Preemie",
                      equivalent_sizes: {
                        us: ["Preemie"],
                        au: ["00000MBAU"],
                        uk: ["PreemieUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "NewbornEU",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "EU Newborn",
                      display_with_system_and_set: "EU Newborn",
                      equivalent_sizes: {
                        us: ["Newborn"],
                        au: ["00000MBAU"],
                        uk: ["NewbornUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0-3MBEU",
                      short: "0-3",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3",
                      display_with_size_system: "EU 0-3",
                      display_with_system_and_set: "EU 0-3",
                      equivalent_sizes: {
                        us: ["0-3MB"],
                        au: ["0000MBAU"],
                        uk: ["0-3MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3-6MBEU",
                      short: "3-6",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6",
                      display_with_size_system: "EU 3-6",
                      display_with_system_and_set: "EU 3-6",
                      equivalent_sizes: {
                        us: ["3-6MB"],
                        au: ["000MBAU"],
                        uk: ["3-6MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6-9MBEU",
                      short: "6-9",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9",
                      display_with_size_system: "EU 6-9",
                      display_with_system_and_set: "EU 6-9",
                      equivalent_sizes: {
                        us: ["6-9MB"],
                        au: ["000MBAU"],
                        uk: ["6-9MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "9-12MBEU",
                      short: "9-12",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12",
                      display_with_size_system: "EU 9-12",
                      display_with_system_and_set: "EU 9-12",
                      equivalent_sizes: {
                        us: ["9-12MB"],
                        au: ["00MBAU"],
                        uk: ["9-12MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12-18MBEU",
                      short: "12-18",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18",
                      display_with_size_system: "EU 12-18",
                      display_with_system_and_set: "EU 12-18",
                      equivalent_sizes: {
                        us: ["12-18MB"],
                        au: ["0MBAU"],
                        uk: ["12-18MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18-24MBEU",
                      short: "18-24",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24",
                      display_with_size_system: "EU 18-24",
                      display_with_system_and_set: "EU 18-24",
                      equivalent_sizes: {
                        us: ["18-24MB"],
                        au: ["1MBAU"],
                        uk: ["18-24MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3MBEU",
                      short: "3",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3",
                      display_with_size_system: "EU 3",
                      display_with_system_and_set: "EU 3",
                      equivalent_sizes: {
                        us: ["3MB"],
                        au: ["0000MBAU", "000MBAU"],
                        uk: ["3MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6MBEU",
                      short: "6",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6",
                      display_with_size_system: "EU 6",
                      display_with_system_and_set: "EU 6",
                      equivalent_sizes: {
                        us: ["6MB"],
                        au: ["000MBAU"],
                        uk: ["6MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "9MBEU",
                      short: "9",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9",
                      display_with_size_system: "EU 9",
                      display_with_system_and_set: "EU 9",
                      equivalent_sizes: {
                        us: ["9MB"],
                        au: ["000MBAU", "00MBAU"],
                        uk: ["9MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12MBEU",
                      short: "12",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12",
                      display_with_size_system: "EU 12",
                      display_with_system_and_set: "EU 12",
                      equivalent_sizes: {
                        us: ["12MB"],
                        au: ["00MBAU", "0MBAU"],
                        uk: ["12MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18MBEU",
                      short: "18",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18",
                      display_with_size_system: "EU 18",
                      display_with_system_and_set: "EU 18",
                      equivalent_sizes: {
                        us: ["18MB"],
                        au: ["0MBAU", "1MBAU"],
                        uk: ["18MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "24MBEU",
                      short: "24",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24",
                      display_with_size_system: "EU 24",
                      display_with_system_and_set: "EU 24",
                      equivalent_sizes: {
                        us: ["24MB"],
                        au: ["1MBAU", "2MBAU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "PreemieUK",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "UK Preemie",
                      display_with_system_and_set: "UK Preemie",
                      equivalent_sizes: {
                        us: ["Preemie"],
                        au: ["00000MBAU"],
                        eu: ["PreemieEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "NewbornUK",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "UK Newborn",
                      display_with_system_and_set: "UK Newborn",
                      equivalent_sizes: {
                        us: ["Newborn"],
                        au: ["00000MBAU"],
                        eu: ["NewbornEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0-3MBUK",
                      short: "0-3M",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3M",
                      display_with_size_system: "UK 0-3",
                      display_with_system_and_set: "UK 0-3M",
                      equivalent_sizes: {
                        us: ["0-3MB"],
                        au: ["0000MBAU"],
                        eu: ["0-3MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3-6MBUK",
                      short: "3-6M",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6M",
                      display_with_size_system: "UK 3-6",
                      display_with_system_and_set: "UK 3-6M",
                      equivalent_sizes: {
                        us: ["3-6MB"],
                        au: ["000MBAU"],
                        eu: ["3-6MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6-9MBUK",
                      short: "6-9M",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9M",
                      display_with_size_system: "UK 6-9",
                      display_with_system_and_set: "UK 6-9M",
                      equivalent_sizes: {
                        us: ["6-9MB"],
                        au: ["000MBAU"],
                        eu: ["6-9MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9-12MBUK",
                      short: "9-12M",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12M",
                      display_with_size_system: "UK 9-12",
                      display_with_system_and_set: "UK 9-12M",
                      equivalent_sizes: {
                        us: ["9-12MB"],
                        au: ["00MBAU"],
                        eu: ["9-12MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12-18MBUK",
                      short: "12-18M",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18M",
                      display_with_size_system: "UK 12-18",
                      display_with_system_and_set: "UK 12-18M",
                      equivalent_sizes: {
                        us: ["12-18MB"],
                        au: ["0MBAU"],
                        eu: ["12-18MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18-24MBUK",
                      short: "18-24M",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24M",
                      display_with_size_system: "UK 18-24",
                      display_with_system_and_set: "UK 18-24M",
                      equivalent_sizes: {
                        us: ["18-24MB"],
                        au: ["1MBAU"],
                        eu: ["18-24MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3MBUK",
                      short: "3M",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3M",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3M",
                      equivalent_sizes: {
                        us: ["3MB"],
                        au: ["0000MBAU", "000MBAU"],
                        eu: ["3MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MBUK",
                      short: "6M",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6M",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6M",
                      equivalent_sizes: {
                        us: ["6MB"],
                        au: ["000MBAU"],
                        eu: ["6MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9MBUK",
                      short: "9M",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9M",
                      display_with_size_system: "UK 9",
                      display_with_system_and_set: "UK 9M",
                      equivalent_sizes: {
                        us: ["9MB"],
                        au: ["000MBAU", "00MBAU"],
                        eu: ["9MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MBUK",
                      short: "12M",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12M",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12M",
                      equivalent_sizes: {
                        us: ["12MB"],
                        au: ["00MBAU", "0MBAU"],
                        eu: ["12MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MBUK",
                      short: "18M",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18M",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18M",
                      equivalent_sizes: {
                        us: ["18MB"],
                        au: ["0MBAU", "1MBAU"],
                        eu: ["18MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MBUK",
                      short: "24M",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24M",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24M",
                      equivalent_sizes: {
                        us: ["24MB"],
                        au: ["1MBAU", "2MBAU"],
                        eu: ["24MBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "1a003667d97b4e6bdb005784",
              name: "Girls",
              tags: ["girls"],
              category_id: "26008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["20.5", "33.5-35.5", "21", "21"],
                  ["21", "36-38", "21.5", "22"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["24", "45-46.5", "23", "25"],
                  ["25.5", "47-49.5", "23.5", "26.5"],
                  ["26", "49-50.5", "24", "27.5"],
                  ["27", "51-52.5", "24.5", "28.5"],
                  ["28.5", "53-55", "25", "30"],
                  ["30", "55.5-58", "26", "31.5"],
                  ["31.5", "58.5-61", "27.5", "33"],
                  ["33", "61.5-62.5", "29", "34.5"],
                  ["22.0-23.0", "38.5-44.5", "22.0-22.5", "23.0-24.0"],
                  ["24.0-26.0", "45-50.5", "23.0-24.0", "25.0-27.5"],
                  ["27", "51-52.5", "24.5", "28.5"],
                  ["28.5", "53-55", "25", "30"],
                  ["30", "55.5-58", "26", "31.5"],
                  ["31.5-33.0", "58.5-62.5", "27.5-29.0", "33-34.5"],
                ],
                centimeters: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["52.1", "85.1-90.2", "53.3", "53.3"],
                  ["53.3", "91.4-96.5", "54.6", "55.9"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["61", "114.3-118.1", "58.4", "63.5"],
                  ["64.8", "119.4-125.7", "59.7", "67.3"],
                  ["66", "125.7-128.3", "61", "69.9"],
                  ["68.6", "129.5-133.4", "62.2", "72.4"],
                  ["72.4", "134.6-139.7", "63.5", "76.2"],
                  ["76.2", "141.0-147.3", "66", "80"],
                  ["80", "148.6-154.9", "69.9", "83.8"],
                  ["83.8", "156.2-158.8", "73.7", "87.6"],
                  ["55.9-58.4", "97.8-113.0", "55.9-57.2", "58.4-61.0"],
                  ["61.0-66.0", "114.3-128.3", "58.4-61.0", "63.5-69.9"],
                  ["68.6", "129.5-133.4", "62.2", "72.4"],
                  ["72.4", "134.6-139.7", "63.5", "76.2"],
                  ["76.2", "141.0-147.3", "66", "80"],
                  ["80.0-83.8", "148.6-158.8", "69.9-73.7", "83.8-87.6"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["6", "6", "6", "6"],
                  ["6X,7", "7", "6X,7", "7"],
                  ["6X,7", "7", "6X,7", "7"],
                  ["8", "8", "8", "8"],
                  ["10", "10", "10", "10"],
                  ["12", "12", "12", "12"],
                  ["14", "14", "14", "14"],
                  ["16", "16", "16", "16"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["2T", "3", "2A", "3"],
                ["3T", "3", "3A", "3"],
                ["4T", "4", "4A", "4"],
                ["5T", "5", "5A", "5"],
                ["4", "4", "4", "4"],
                ["5", "5", "5", "5"],
                ["6", "6", "6", "6"],
                ["6X", "7", "6X", "7"],
                ["7", "7", "7", "7"],
                ["8", "8", "8", "8"],
                ["10", "10", "10", "10"],
                ["12", "12", "12", "12"],
                ["14", "14", "14", "14"],
                ["16", "16", "16", "16"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "2TG",
                      short: "2T (Girl)",
                      long: "2T",
                      display: "2T",
                      display_with_size_set: "2T (Girl)",
                      display_with_size_system: "US 2T",
                      display_with_system_and_set: "US 2T (Girl)",
                      equivalent_sizes: {
                        au: ["3GAU"],
                        eu: ["2AGEU"],
                        uk: ["3GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3TG",
                      short: "3T (Girl)",
                      long: "3T",
                      display: "3T",
                      display_with_size_set: "3T (Girl)",
                      display_with_size_system: "US 3T",
                      display_with_system_and_set: "US 3T (Girl)",
                      equivalent_sizes: {
                        au: ["3GAU"],
                        eu: ["3AGEU"],
                        uk: ["3GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4TG",
                      short: "4T (Girl)",
                      long: "4T",
                      display: "4T",
                      display_with_size_set: "4T (Girl)",
                      display_with_size_system: "US 4T",
                      display_with_system_and_set: "US 4T (Girl)",
                      equivalent_sizes: {
                        au: ["4GAU"],
                        eu: ["4AGEU"],
                        uk: ["4GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5TG",
                      short: "5T (Girl)",
                      long: "5T",
                      display: "5T",
                      display_with_size_set: "5T (Girl)",
                      display_with_size_system: "US 5T",
                      display_with_system_and_set: "US 5T (Girl)",
                      equivalent_sizes: {
                        au: ["5GAU"],
                        eu: ["5AGEU"],
                        uk: ["5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4G",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Girl)",
                      equivalent_sizes: {
                        au: ["4GAU"],
                        eu: ["4GEU"],
                        uk: ["4GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5G",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Girl)",
                      equivalent_sizes: {
                        au: ["5GAU"],
                        eu: ["5GEU"],
                        uk: ["5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6G",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Girl)",
                      equivalent_sizes: {
                        au: ["6GAU"],
                        eu: ["6GEU"],
                        uk: ["6GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XG",
                      short: "6X (Girl)",
                      long: "6X",
                      display: "6X",
                      display_with_size_set: "6X (Girl)",
                      display_with_size_system: "US 6X",
                      display_with_system_and_set: "US 6X (Girl)",
                      equivalent_sizes: {
                        au: ["7GAU"],
                        eu: ["6XGEU"],
                        uk: ["7GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7G",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Girl)",
                      equivalent_sizes: {
                        au: ["7GAU"],
                        eu: ["7GEU"],
                        uk: ["7GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8G",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Girl)",
                      equivalent_sizes: {
                        au: ["8GAU"],
                        eu: ["8GEU"],
                        uk: ["8GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10G",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Girl)",
                      equivalent_sizes: {
                        au: ["10GAU"],
                        eu: ["10GEU"],
                        uk: ["10GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12G",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Girl)",
                      equivalent_sizes: {
                        au: ["12GAU"],
                        eu: ["12GEU"],
                        uk: ["12GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14G",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Girl)",
                      equivalent_sizes: {
                        au: ["14GAU"],
                        eu: ["14GEU"],
                        uk: ["14GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16G",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Girl)",
                      equivalent_sizes: {
                        au: ["16GAU"],
                        eu: ["16GEU"],
                        uk: ["16GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSG",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Girl)",
                      equivalent_sizes: {
                        au: ["XSGAU"],
                        eu: ["XSGEU"],
                        uk: ["XSGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SG",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Girl)",
                      equivalent_sizes: {
                        au: ["SGAU"],
                        eu: ["SGEU"],
                        uk: ["SGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MG",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Girl)",
                      equivalent_sizes: {
                        au: ["MGAU"],
                        eu: ["MGEU"],
                        uk: ["MGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LG",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Girl)",
                      equivalent_sizes: {
                        au: ["LGAU"],
                        eu: ["LGEU"],
                        uk: ["LGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLG",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Girl)",
                      equivalent_sizes: {
                        au: ["XLGAU"],
                        eu: ["XLGEU"],
                        uk: ["XLGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLG",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Girl)",
                      equivalent_sizes: {
                        au: ["XXLGAU"],
                        eu: ["XXLGEU"],
                        uk: ["XXLGUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "3GAU",
                      short: "3 (Girl)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Girl)",
                      display_with_size_system: "AU 3",
                      display_with_system_and_set: "AU 3 (Girl)",
                      equivalent_sizes: {
                        us: ["2TG", "3TG"],
                        eu: ["2AGEU", "3AGEU"],
                        uk: ["3GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4GAU",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Girl)",
                      equivalent_sizes: {
                        us: ["4TG", "4G"],
                        eu: ["4AGEU", "4GEU"],
                        uk: ["4GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5GAU",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "AU 5",
                      display_with_system_and_set: "AU 5 (Girl)",
                      equivalent_sizes: {
                        us: ["5TG", "5G"],
                        eu: ["5AGEU", "5GEU"],
                        uk: ["5GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6GAU",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Girl)",
                      equivalent_sizes: {
                        us: ["6G"],
                        eu: ["6GEU"],
                        uk: ["6GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "7GAU",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "AU 7",
                      display_with_system_and_set: "AU 7 (Girl)",
                      equivalent_sizes: {
                        us: ["6XG", "7G"],
                        eu: ["6XGEU", "7GEU"],
                        uk: ["7GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8GAU",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Girl)",
                      equivalent_sizes: {
                        us: ["8G"],
                        eu: ["8GEU"],
                        uk: ["8GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10GAU",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Girl)",
                      equivalent_sizes: {
                        us: ["10G"],
                        eu: ["10GEU"],
                        uk: ["10GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12GAU",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Girl)",
                      equivalent_sizes: {
                        us: ["12G"],
                        eu: ["12GEU"],
                        uk: ["12GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14GAU",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Girl)",
                      equivalent_sizes: {
                        us: ["14G"],
                        eu: ["14GEU"],
                        uk: ["14GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16GAU",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Girl)",
                      equivalent_sizes: {
                        us: ["16G"],
                        eu: ["16GEU"],
                        uk: ["16GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSGAU",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Girl)",
                      equivalent_sizes: {
                        us: ["XSG"],
                        eu: ["XSGEU"],
                        uk: ["XSGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SGAU",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Girl)",
                      equivalent_sizes: {
                        us: ["SG"],
                        eu: ["SGEU"],
                        uk: ["SGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MGAU",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Girl)",
                      equivalent_sizes: {
                        us: ["MG"],
                        eu: ["MGEU"],
                        uk: ["MGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LGAU",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Girl)",
                      equivalent_sizes: {
                        us: ["LG"],
                        eu: ["LGEU"],
                        uk: ["LGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLGAU",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Girl)",
                      equivalent_sizes: {
                        us: ["XLG"],
                        eu: ["XLGEU"],
                        uk: ["XLGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLGAU",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Girl)",
                      equivalent_sizes: {
                        us: ["XXLG"],
                        eu: ["XXLGEU"],
                        uk: ["XXLGUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "2AGEU",
                      short: "2A (Girl)",
                      long: "2A",
                      display: "2A",
                      display_with_size_set: "2A (Girl)",
                      display_with_size_system: "EU 2A",
                      display_with_system_and_set: "EU 2A (Girl)",
                      equivalent_sizes: {
                        us: ["2TG"],
                        au: ["3GAU"],
                        uk: ["3GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3AGEU",
                      short: "3A (Girl)",
                      long: "3A",
                      display: "3A",
                      display_with_size_set: "3A (Girl)",
                      display_with_size_system: "EU 3A",
                      display_with_system_and_set: "EU 3A (Girl)",
                      equivalent_sizes: {
                        us: ["3TG"],
                        au: ["3GAU"],
                        uk: ["3GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4AGEU",
                      short: "4A (Girl)",
                      long: "4A",
                      display: "4A",
                      display_with_size_set: "4A (Girl)",
                      display_with_size_system: "EU 4A",
                      display_with_system_and_set: "EU 4A (Girl)",
                      equivalent_sizes: {
                        us: ["4TG"],
                        au: ["4GAU"],
                        uk: ["4GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4GEU",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "EU 4",
                      display_with_system_and_set: "EU 4 (Girl)",
                      equivalent_sizes: {
                        us: ["4G"],
                        au: ["4GAU"],
                        uk: ["4GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5AGEU",
                      short: "5A (Girl)",
                      long: "5A",
                      display: "5A",
                      display_with_size_set: "5A (Girl)",
                      display_with_size_system: "EU 5A",
                      display_with_system_and_set: "EU 5A (Girl)",
                      equivalent_sizes: {
                        us: ["5TG"],
                        au: ["5GAU"],
                        uk: ["5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5GEU",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "EU 5",
                      display_with_system_and_set: "EU 5 (Girl)",
                      equivalent_sizes: {
                        us: ["5G"],
                        au: ["5GAU"],
                        uk: ["5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XGEU",
                      short: "6X (Girl)",
                      long: "6X",
                      display: "6X",
                      display_with_size_set: "6X (Girl)",
                      display_with_size_system: "EU 6X",
                      display_with_system_and_set: "EU 6X (Girl)",
                      equivalent_sizes: {
                        us: ["6XG"],
                        au: ["7GAU"],
                        uk: ["7GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6GEU",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "EU 6",
                      display_with_system_and_set: "EU 6 (Girl)",
                      equivalent_sizes: {
                        us: ["6G"],
                        au: ["6GAU"],
                        uk: ["6GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "7GEU",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "EU 7",
                      display_with_system_and_set: "EU 7 (Girl)",
                      equivalent_sizes: {
                        us: ["7G"],
                        au: ["7GAU"],
                        uk: ["7GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "8GEU",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "EU 8",
                      display_with_system_and_set: "EU 8 (Girl)",
                      equivalent_sizes: {
                        us: ["8G"],
                        au: ["8GAU"],
                        uk: ["8GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "10GEU",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "EU 10",
                      display_with_system_and_set: "EU 10 (Girl)",
                      equivalent_sizes: {
                        us: ["10G"],
                        au: ["10GAU"],
                        uk: ["10GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12GEU",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "EU 12",
                      display_with_system_and_set: "EU 12 (Girl)",
                      equivalent_sizes: {
                        us: ["12G"],
                        au: ["12GAU"],
                        uk: ["12GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "14GEU",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "EU 14",
                      display_with_system_and_set: "EU 14 (Girl)",
                      equivalent_sizes: {
                        us: ["14G"],
                        au: ["14GAU"],
                        uk: ["14GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "16GEU",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "EU 16",
                      display_with_system_and_set: "EU 16 (Girl)",
                      equivalent_sizes: {
                        us: ["16G"],
                        au: ["16GAU"],
                        uk: ["16GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSGEU",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Girl)",
                      equivalent_sizes: {
                        us: ["XSG"],
                        au: ["XSGAU"],
                        uk: ["XSGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SGEU",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Girl)",
                      equivalent_sizes: {
                        us: ["SG"],
                        au: ["SGAU"],
                        uk: ["SGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MGEU",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Girl)",
                      equivalent_sizes: {
                        us: ["MG"],
                        au: ["MGAU"],
                        uk: ["MGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LGEU",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Girl)",
                      equivalent_sizes: {
                        us: ["LG"],
                        au: ["LGAU"],
                        uk: ["LGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLGEU",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Girl)",
                      equivalent_sizes: {
                        us: ["XLG"],
                        au: ["XLGAU"],
                        uk: ["XLGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLGEU",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Girl)",
                      equivalent_sizes: {
                        us: ["XXLG"],
                        au: ["XXLGAU"],
                        uk: ["XXLGUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "3GUK",
                      short: "3 (Girl)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Girl)",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3 (Girl)",
                      equivalent_sizes: {
                        us: ["2TG", "3TG"],
                        au: ["3GAU"],
                        eu: ["2AGEU", "3AGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4GUK",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Girl)",
                      equivalent_sizes: {
                        us: ["4TG", "4G"],
                        au: ["4GAU"],
                        eu: ["4AGEU", "4GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5GUK",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "UK 5",
                      display_with_system_and_set: "UK 5 (Girl)",
                      equivalent_sizes: {
                        us: ["5TG", "5G"],
                        au: ["5GAU"],
                        eu: ["5AGEU", "5GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6GUK",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Girl)",
                      equivalent_sizes: {
                        us: ["6G"],
                        au: ["6GAU"],
                        eu: ["6GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "7GUK",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "UK 7",
                      display_with_system_and_set: "UK 7 (Girl)",
                      equivalent_sizes: {
                        us: ["6XG", "7G"],
                        au: ["7GAU"],
                        eu: ["6XGEU", "7GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8GUK",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Girl)",
                      equivalent_sizes: {
                        us: ["8G"],
                        au: ["8GAU"],
                        eu: ["8GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10GUK",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Girl)",
                      equivalent_sizes: {
                        us: ["10G"],
                        au: ["10GAU"],
                        eu: ["10GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12GUK",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Girl)",
                      equivalent_sizes: {
                        us: ["12G"],
                        au: ["12GAU"],
                        eu: ["12GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14GUK",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Girl)",
                      equivalent_sizes: {
                        us: ["14G"],
                        au: ["14GAU"],
                        eu: ["14GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16GUK",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Girl)",
                      equivalent_sizes: {
                        us: ["16G"],
                        au: ["16GAU"],
                        eu: ["16GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSGUK",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Girl)",
                      equivalent_sizes: {
                        us: ["XSG"],
                        au: ["XSGAU"],
                        eu: ["XSGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SGUK",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Girl)",
                      equivalent_sizes: {
                        us: ["SG"],
                        au: ["SGAU"],
                        eu: ["SGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MGUK",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Girl)",
                      equivalent_sizes: {
                        us: ["MG"],
                        au: ["MGAU"],
                        eu: ["MGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LGUK",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Girl)",
                      equivalent_sizes: {
                        us: ["LG"],
                        au: ["LGAU"],
                        eu: ["LGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLGUK",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Girl)",
                      equivalent_sizes: {
                        us: ["XLG"],
                        au: ["XLGAU"],
                        eu: ["XLGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLGUK",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Girl)",
                      equivalent_sizes: {
                        us: ["XXLG"],
                        au: ["XXLGAU"],
                        eu: ["XXLGEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "1b003667d97b4e6bdb005784",
              name: "Boys",
              tags: ["boys"],
              category_id: "26008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["20.5", "33.5-35.5", "21", "21"],
                  ["21", "36-38", "21.5", "22"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["24", "45-46.5", "23", "25"],
                  ["25.5", "47-49.5", "23.5", "26.5"],
                  ["26.5", "50-52", "24.5", "27"],
                  ["27.5", "52.5-55.5", "25.5", "28"],
                  ["28.5", "56-57.5", "26.5", "29"],
                  ["30", "58-59.5", "28", "30.5"],
                  ["31.5", "60-61.5", "29.5", "32"],
                  ["33", "62-63.5", "30.5", "33"],
                  ["34.5", "64-65", "31.5", "34"],
                  ["22.0-23.0", "38.5-44.5", "22.0-22.5", "23.0-24.0"],
                  ["24.0-26.5", "45-52", "23.0-24.5", "25.0-27.0"],
                  ["26.5", "50-52", "24.5", "27"],
                  ["27.5", "52.5-55.5", "25.5", "28"],
                  ["28.5", "56-57.5", "26.5", "29"],
                  ["30.0-31.5", "58-61.5", "28.0-29.5", "30.5-32.0"],
                ],
                centimeters: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["52.1", "85.1-90.2", "53.3", "53.3"],
                  ["53.3", "91.4-96.5", "54.6", "55.9"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["61", "114.3-118.1", "58.4", "63.5"],
                  ["64.8", "119.4-125.7", "59.7", "67.3"],
                  ["67.3", "127-132.1", "62.2", "68.6"],
                  ["69.9", "133.4-141.0", "64.8", "71.1"],
                  ["72.4", "142.2-146.1", "67.3", "73.7"],
                  ["76.2", "147.3-151.1", "71.1", "77.5"],
                  ["80", "152.4-156.2", "74.9", "81.3"],
                  ["83.8", "157.5-161.3", "77.5", "83.8"],
                  ["87.6", "162.6-165.1", "80", "86.4"],
                  ["55.9-58.4", "97.8-113.0", "55.9-57.2", "58.4-61.0"],
                  ["61.0-67.3", "114.3-132.1", "58.4-62.2", "63.5-68.6"],
                  ["67.3", "127-132.1", "62.2", "68.6"],
                  ["69.9", "133.4-141.0", "64.8", "71.1"],
                  ["72.4", "142.2-146.1", "67.3", "73.7"],
                  ["76.2-80.0", "147.3-156.2", "71.1-74.9", "77.5-81.3"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["6", "6", "6", "6"],
                  ["7", "7", "7", "7"],
                  ["7X,8", "8", "7X,8", "8"],
                  ["10", "10", "10", "10"],
                  ["12", "12", "12", "12"],
                  ["14", "14", "14", "14"],
                  ["16", "16", "16", "16"],
                  ["18", "18", "18", "18"],
                  ["20", "20", "20", "20"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["2T", "3", "2A", "3"],
                ["3T", "3", "3A", "3"],
                ["4T", "4", "4A", "4"],
                ["5T", "5", "5A", "5"],
                ["4", "4", "4", "4"],
                ["5", "5", "5", "5"],
                ["6", "6", "6", "6"],
                ["7", "7", "7", "7"],
                ["7X", "8", "7X", "8"],
                ["8", "8", "8", "8"],
                ["10", "10", "10", "10"],
                ["12", "12", "12", "12"],
                ["14", "14", "14", "14"],
                ["16", "16", "16", "16"],
                ["18", "18", "18", "18"],
                ["20", "20", "20", "20"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "2TB",
                      short: "2T (Boy)",
                      long: "2T",
                      display: "2T",
                      display_with_size_set: "2T (Boy)",
                      display_with_size_system: "US 2T",
                      display_with_system_and_set: "US 2T (Boy)",
                      equivalent_sizes: {
                        au: ["3BAU"],
                        eu: ["2ABEU"],
                        uk: ["3BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3TB",
                      short: "3T (Boy)",
                      long: "3T",
                      display: "3T",
                      display_with_size_set: "3T (Boy)",
                      display_with_size_system: "US 3T",
                      display_with_system_and_set: "US 3T (Boy)",
                      equivalent_sizes: {
                        au: ["3BAU"],
                        eu: ["3ABEU"],
                        uk: ["3BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4TB",
                      short: "4T (Boy)",
                      long: "4T",
                      display: "4T",
                      display_with_size_set: "4T (Boy)",
                      display_with_size_system: "US 4T",
                      display_with_system_and_set: "US 4T (Boy)",
                      equivalent_sizes: {
                        au: ["4BAU"],
                        eu: ["4ABEU"],
                        uk: ["4BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5TB",
                      short: "5T (Boy)",
                      long: "5T",
                      display: "5T",
                      display_with_size_set: "5T (Boy)",
                      display_with_size_system: "US 5T",
                      display_with_system_and_set: "US 5T (Boy)",
                      equivalent_sizes: {
                        au: ["5BAU"],
                        eu: ["5ABEU"],
                        uk: ["5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4B",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Boy)",
                      equivalent_sizes: {
                        au: ["4BAU"],
                        eu: ["4BEU"],
                        uk: ["4BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5B",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Boy)",
                      equivalent_sizes: {
                        au: ["5BAU"],
                        eu: ["5BEU"],
                        uk: ["5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6B",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Boy)",
                      equivalent_sizes: {
                        au: ["6BAU"],
                        eu: ["6BEU"],
                        uk: ["6BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7B",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Boy)",
                      equivalent_sizes: {
                        au: ["7BAU"],
                        eu: ["7BEU"],
                        uk: ["7BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7XB",
                      short: "7X (Boy)",
                      long: "7X",
                      display: "7X",
                      display_with_size_set: "7X (Boy)",
                      display_with_size_system: "US 7X",
                      display_with_system_and_set: "US 7X (Boy)",
                      equivalent_sizes: {
                        au: ["8BAU"],
                        eu: ["7XBEU"],
                        uk: ["8BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8B",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Boy)",
                      equivalent_sizes: {
                        au: ["8BAU"],
                        eu: ["8BEU"],
                        uk: ["8BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10B",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Boy)",
                      equivalent_sizes: {
                        au: ["10BAU"],
                        eu: ["10BEU"],
                        uk: ["10BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12B",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Boy)",
                      equivalent_sizes: {
                        au: ["12BAU"],
                        eu: ["12BEU"],
                        uk: ["12BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14B",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Boy)",
                      equivalent_sizes: {
                        au: ["14BAU"],
                        eu: ["14BEU"],
                        uk: ["14BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16B",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Boy)",
                      equivalent_sizes: {
                        au: ["16BAU"],
                        eu: ["16BEU"],
                        uk: ["16BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18B",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Boy)",
                      equivalent_sizes: {
                        au: ["18BAU"],
                        eu: ["18BEU"],
                        uk: ["18BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20B",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Boy)",
                      equivalent_sizes: {
                        au: ["20BAU"],
                        eu: ["20BEU"],
                        uk: ["20BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSB",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Boy)",
                      equivalent_sizes: {
                        au: ["XSBAU"],
                        eu: ["XSBEU"],
                        uk: ["XSBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SB",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Boy)",
                      equivalent_sizes: {
                        au: ["SBAU"],
                        eu: ["SBEU"],
                        uk: ["SBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MB",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Boy)",
                      equivalent_sizes: {
                        au: ["MBAU"],
                        eu: ["MBEU"],
                        uk: ["MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LB",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Boy)",
                      equivalent_sizes: {
                        au: ["LBAU"],
                        eu: ["LBEU"],
                        uk: ["LBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLB",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Boy)",
                      equivalent_sizes: {
                        au: ["XLBAU"],
                        eu: ["XLBEU"],
                        uk: ["XLBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLB",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Boy)",
                      equivalent_sizes: {
                        au: ["XXLBAU"],
                        eu: ["XXLBEU"],
                        uk: ["XXLBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "3BAU",
                      short: "3 (Boy)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Boy)",
                      display_with_size_system: "AU 3",
                      display_with_system_and_set: "AU 3 (Boy)",
                      equivalent_sizes: {
                        us: ["2TB", "3TB"],
                        eu: ["2ABEU", "3ABEU"],
                        uk: ["3BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4BAU",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Boy)",
                      equivalent_sizes: {
                        us: ["4TB", "4B"],
                        eu: ["4ABEU", "4BEU"],
                        uk: ["4BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5BAU",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "AU 5",
                      display_with_system_and_set: "AU 5 (Boy)",
                      equivalent_sizes: {
                        us: ["5TB", "5B"],
                        eu: ["5ABEU", "5BEU"],
                        uk: ["5BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6BAU",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Boy)",
                      equivalent_sizes: {
                        us: ["6B"],
                        eu: ["6BEU"],
                        uk: ["6BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "7BAU",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "AU 7",
                      display_with_system_and_set: "AU 7 (Boy)",
                      equivalent_sizes: {
                        us: ["7B"],
                        eu: ["7BEU"],
                        uk: ["7BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8BAU",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Boy)",
                      equivalent_sizes: {
                        us: ["7XB", "8B"],
                        eu: ["7XBEU", "8BEU"],
                        uk: ["8BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10BAU",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Boy)",
                      equivalent_sizes: {
                        us: ["10B"],
                        eu: ["10BEU"],
                        uk: ["10BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12BAU",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Boy)",
                      equivalent_sizes: {
                        us: ["12B"],
                        eu: ["12BEU"],
                        uk: ["12BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14BAU",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Boy)",
                      equivalent_sizes: {
                        us: ["14B"],
                        eu: ["14BEU"],
                        uk: ["14BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16BAU",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Boy)",
                      equivalent_sizes: {
                        us: ["16B"],
                        eu: ["16BEU"],
                        uk: ["16BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18BAU",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Boy)",
                      equivalent_sizes: {
                        us: ["18B"],
                        eu: ["18BEU"],
                        uk: ["18BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20BAU",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Boy)",
                      equivalent_sizes: {
                        us: ["20B"],
                        eu: ["20BEU"],
                        uk: ["20BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSBAU",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Boy)",
                      equivalent_sizes: {
                        us: ["XSB"],
                        eu: ["XSBEU"],
                        uk: ["XSBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SBAU",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Boy)",
                      equivalent_sizes: {
                        us: ["SB"],
                        eu: ["SBEU"],
                        uk: ["SBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MBAU",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Boy)",
                      equivalent_sizes: {
                        us: ["MB"],
                        eu: ["MBEU"],
                        uk: ["MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LBAU",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Boy)",
                      equivalent_sizes: {
                        us: ["LB"],
                        eu: ["LBEU"],
                        uk: ["LBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLBAU",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Boy)",
                      equivalent_sizes: {
                        us: ["XLB"],
                        eu: ["XLBEU"],
                        uk: ["XLBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLBAU",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Boy)",
                      equivalent_sizes: {
                        us: ["XXLB"],
                        eu: ["XXLBEU"],
                        uk: ["XXLBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "2ABEU",
                      short: "2A (Boy)",
                      long: "2A",
                      display: "2A",
                      display_with_size_set: "2A (Boy)",
                      display_with_size_system: "EU 2A",
                      display_with_system_and_set: "EU 2A (Boy)",
                      equivalent_sizes: {
                        us: ["2TB"],
                        au: ["3BAU"],
                        uk: ["3BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3ABEU",
                      short: "3A (Boy)",
                      long: "3A",
                      display: "3A",
                      display_with_size_set: "3A (Boy)",
                      display_with_size_system: "EU 3A",
                      display_with_system_and_set: "EU 3A (Boy)",
                      equivalent_sizes: {
                        us: ["3TB"],
                        au: ["3BAU"],
                        uk: ["3BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4ABEU",
                      short: "4A (Boy)",
                      long: "4A",
                      display: "4A",
                      display_with_size_set: "4A (Boy)",
                      display_with_size_system: "EU 4A",
                      display_with_system_and_set: "EU 4A (Boy)",
                      equivalent_sizes: {
                        us: ["4TB"],
                        au: ["4BAU"],
                        uk: ["4BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4BEU",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "EU 4",
                      display_with_system_and_set: "EU 4 (Boy)",
                      equivalent_sizes: {
                        us: ["4B"],
                        au: ["4BAU"],
                        uk: ["4BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5ABEU",
                      short: "5A (Boy)",
                      long: "5A",
                      display: "5A",
                      display_with_size_set: "5A (Boy)",
                      display_with_size_system: "EU 5A",
                      display_with_system_and_set: "EU 5A (Boy)",
                      equivalent_sizes: {
                        us: ["5TB"],
                        au: ["5BAU"],
                        uk: ["5BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5BEU",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "EU 5",
                      display_with_system_and_set: "EU 5 (Boy)",
                      equivalent_sizes: {
                        us: ["5B"],
                        au: ["5BAU"],
                        uk: ["5BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6BEU",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "EU 6",
                      display_with_system_and_set: "EU 6 (Boy)",
                      equivalent_sizes: {
                        us: ["6B"],
                        au: ["6BAU"],
                        uk: ["6BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "7XBEU",
                      short: "7X (Boy)",
                      long: "7X",
                      display: "7X",
                      display_with_size_set: "7X (Boy)",
                      display_with_size_system: "EU 7X",
                      display_with_system_and_set: "EU 7X (Boy)",
                      equivalent_sizes: {
                        us: ["7XB"],
                        au: ["8BAU"],
                        uk: ["8BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "7BEU",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "EU 7",
                      display_with_system_and_set: "EU 7 (Boy)",
                      equivalent_sizes: {
                        us: ["7B"],
                        au: ["7BAU"],
                        uk: ["7BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "8BEU",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "EU 8",
                      display_with_system_and_set: "EU 8 (Boy)",
                      equivalent_sizes: {
                        us: ["8B"],
                        au: ["8BAU"],
                        uk: ["8BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "10BEU",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "EU 10",
                      display_with_system_and_set: "EU 10 (Boy)",
                      equivalent_sizes: {
                        us: ["10B"],
                        au: ["10BAU"],
                        uk: ["10BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12BEU",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "EU 12",
                      display_with_system_and_set: "EU 12 (Boy)",
                      equivalent_sizes: {
                        us: ["12B"],
                        au: ["12BAU"],
                        uk: ["12BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "14BEU",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "EU 14",
                      display_with_system_and_set: "EU 14 (Boy)",
                      equivalent_sizes: {
                        us: ["14B"],
                        au: ["14BAU"],
                        uk: ["14BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "16BEU",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "EU 16",
                      display_with_system_and_set: "EU 16 (Boy)",
                      equivalent_sizes: {
                        us: ["16B"],
                        au: ["16BAU"],
                        uk: ["16BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18BEU",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "EU 18",
                      display_with_system_and_set: "EU 18 (Boy)",
                      equivalent_sizes: {
                        us: ["18B"],
                        au: ["18BAU"],
                        uk: ["18BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "20BEU",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "EU 20",
                      display_with_system_and_set: "EU 20 (Boy)",
                      equivalent_sizes: {
                        us: ["20B"],
                        au: ["20BAU"],
                        uk: ["20BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSBEU",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Boy)",
                      equivalent_sizes: {
                        us: ["XSB"],
                        au: ["XSBAU"],
                        uk: ["XSBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SBEU",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Boy)",
                      equivalent_sizes: {
                        us: ["SB"],
                        au: ["SBAU"],
                        uk: ["SBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MBEU",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Boy)",
                      equivalent_sizes: {
                        us: ["MB"],
                        au: ["MBAU"],
                        uk: ["MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LBEU",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Boy)",
                      equivalent_sizes: {
                        us: ["LB"],
                        au: ["LBAU"],
                        uk: ["LBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLBEU",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Boy)",
                      equivalent_sizes: {
                        us: ["XLB"],
                        au: ["XLBAU"],
                        uk: ["XLBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLBEU",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Boy)",
                      equivalent_sizes: {
                        us: ["XXLB"],
                        au: ["XXLBAU"],
                        uk: ["XXLBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "3BUK",
                      short: "3 (Boy)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Boy)",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3 (Boy)",
                      equivalent_sizes: {
                        us: ["2TB", "3TB"],
                        au: ["3BAU"],
                        eu: ["2ABEU", "3ABEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4BUK",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Boy)",
                      equivalent_sizes: {
                        us: ["4TB", "4B"],
                        au: ["4BAU"],
                        eu: ["4ABEU", "4BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5BUK",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "UK 5",
                      display_with_system_and_set: "UK 5 (Boy)",
                      equivalent_sizes: {
                        us: ["5TB", "5B"],
                        au: ["5BAU"],
                        eu: ["5ABEU", "5BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6BUK",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Boy)",
                      equivalent_sizes: {
                        us: ["6B"],
                        au: ["6BAU"],
                        eu: ["6BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "7BUK",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "UK 7",
                      display_with_system_and_set: "UK 7 (Boy)",
                      equivalent_sizes: {
                        us: ["7B"],
                        au: ["7BAU"],
                        eu: ["7BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8BUK",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Boy)",
                      equivalent_sizes: {
                        us: ["7XB", "8B"],
                        au: ["8BAU"],
                        eu: ["7XBEU", "8BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10BUK",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Boy)",
                      equivalent_sizes: {
                        us: ["10B"],
                        au: ["10BAU"],
                        eu: ["10BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12BUK",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Boy)",
                      equivalent_sizes: {
                        us: ["12B"],
                        au: ["12BAU"],
                        eu: ["12BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14BUK",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Boy)",
                      equivalent_sizes: {
                        us: ["14B"],
                        au: ["14BAU"],
                        eu: ["14BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16BUK",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Boy)",
                      equivalent_sizes: {
                        us: ["16B"],
                        au: ["16BAU"],
                        eu: ["16BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18BUK",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Boy)",
                      equivalent_sizes: {
                        us: ["18B"],
                        au: ["18BAU"],
                        eu: ["18BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20BUK",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Boy)",
                      equivalent_sizes: {
                        us: ["20B"],
                        au: ["20BAU"],
                        eu: ["20BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSBUK",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Boy)",
                      equivalent_sizes: {
                        us: ["XSB"],
                        au: ["XSBAU"],
                        eu: ["XSBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SBUK",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Boy)",
                      equivalent_sizes: {
                        us: ["SB"],
                        au: ["SBAU"],
                        eu: ["SBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MBUK",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Boy)",
                      equivalent_sizes: {
                        us: ["MB"],
                        au: ["MBAU"],
                        eu: ["MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LBUK",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Boy)",
                      equivalent_sizes: {
                        us: ["LB"],
                        au: ["LBAU"],
                        eu: ["LBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLBUK",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Boy)",
                      equivalent_sizes: {
                        us: ["XLB"],
                        au: ["XLBAU"],
                        eu: ["XLBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLBUK",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Boy)",
                      equivalent_sizes: {
                        us: ["XXLB"],
                        au: ["XXLBAU"],
                        eu: ["XXLBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "kids_matching_sets",
          aliases: [],
          slug: "Matching_Sets",
          alias_slugs: [],
          parent_id: "20008c10d97b4e1245005764",
        },
        {
          id: "25008c10d97b4e1245005764",
          display: "One Pieces",
          size_sets: [
            {
              id: "10003667d97b4e6bdb005784",
              name: "Baby",
              tags: ["baby"],
              category_id: "25008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["14.5", "0-18", "15", "15"],
                  ["16", "0-18", "16.5", "16.5"],
                  ["17", "18-23", "17.5", "17.5"],
                  ["18", "23-27", "18.5", "18.5"],
                  ["18.5", "27-28", "19", "19"],
                  ["19", "28-29", "19.5", "19.5"],
                  ["19.75", "29-31", "20.25", "20.25"],
                  ["20.5", "31-33", "21", "21"],
                  ["17", "18-23", "17.5", "17.5"],
                  ["18", "23-27", "18.5", "18.5"],
                  ["18.5", "27-28", "19", "19"],
                  ["19", "28-29", "19.5", "19.5"],
                  ["19.75", "29-31", "20.25", "20.25"],
                  ["20.5", "31-33", "21", "21"],
                ],
                centimeters: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["36.8", "0-45.7", "38.1", "38.1"],
                  ["40.6", "0-45.7", "41.9", "41.9"],
                  ["43.2", "45.7-58.4", "44.5", "44.5"],
                  ["45.7", "58.4-68.6", "47.0", "47.0"],
                  ["47.0", "68.6-71.1", "48.3", "48.3"],
                  ["48.3", "71.1-73.7", "49.5", "49.5"],
                  ["50.2", "73.7-78.7", "51.4", "51.4"],
                  ["52.1", "78.7-83.8", "53.3", "53.3"],
                  ["43.2", "45.7-58.4", "44.5", "44.5"],
                  ["45.7", "58.4-68.6", "47.0", "47.0"],
                  ["47.0", "68.6-71.1", "48.3", "48.3"],
                  ["48.3", "71.1-73.7", "49.5", "49.5"],
                  ["50.2", "73.7-78.7", "51.4", "51.4"],
                  ["52.1", "78.7-83.8", "53.3", "53.3"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["Preemie", "00000", "Preemie", "Preemie"],
                  ["Newborn", "00000", "Newborn", "Newborn"],
                  ["0-3 Months", "0000", "0-3 Months", "0-3 Months"],
                  ["3-6 Months", "000", "3-6 Months", "3-6 Months"],
                  ["6-9 Months", "000", "6-9 Months", "6-9 Months"],
                  ["9-12 Months", "00", "9-12 Months", "9-12 Months"],
                  ["12-18 Months", "0", "12-18 Months", "12-18 Months"],
                  ["18-24 Months", "1", "18-24 Months", "18-24 Months"],
                  ["3 Months", "0000,000", "3 Months", "3 Months"],
                  ["6 Months", "000", "6 Months", "6 Months"],
                  ["9 Months", "000,00", "9 Months", "9 Months"],
                  ["12 Months", "00,0", "12 Months", "12 Months"],
                  ["18 Months", "0,1", "18 Months", "18 Months"],
                  ["24 Months", "1,2", "24 Months", "24 Months"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
                ["Preemie", "00000", "Preemie", "Preemie"],
                ["Newborn", "00000", "Newborn", "Newborn"],
                ["0-3 Months", "0000", "0-3 Months", "0-3 Months"],
                ["3-6 Months", "000", "3-6 Months", "3-6 Months"],
                ["6-9 Months", "000", "6-9 Months", "6-9 Months"],
                ["9-12 Months", "00", "9-12 Months", "9-12 Months"],
                ["12-18 Months", "0", "12-18 Months", "12-18 Months"],
                ["18-24 Months", "1", "18-24 Months", "18-24 Months"],
                ["3 Months", "0000,000", "3 Months", "3 Months"],
                ["6 Months", "000", "6 Months", "6 Months"],
                ["9 Months", "000,00", "9 Months", "9 Months"],
                ["12 Months", "00,0", "12 Months", "12 Months"],
                ["18 Months", "0,1", "18 Months", "18 Months"],
                ["24 Months", "1,2", "24 Months", "24 Months"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OSBB",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS (Baby)",
                      equivalent_sizes: {
                        au: ["OSBBAU"],
                        eu: ["OSBBEU"],
                        uk: ["OSBBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "Preemie",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "US Preemie",
                      display_with_system_and_set: "US Preemie",
                      equivalent_sizes: {
                        au: ["00000MBAU"],
                        eu: ["PreemieEU"],
                        uk: ["PreemieUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "Newborn",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "US Newborn",
                      display_with_system_and_set: "US Newborn",
                      equivalent_sizes: {
                        au: ["00000MBAU"],
                        eu: ["NewbornEU"],
                        uk: ["NewbornUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0-3MB",
                      short: "0-3M",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3M",
                      display_with_size_system: "US 0-3",
                      display_with_system_and_set: "US 0-3M",
                      equivalent_sizes: {
                        au: ["0000MBAU"],
                        eu: ["0-3MBEU"],
                        uk: ["0-3MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3-6MB",
                      short: "3-6M",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6M",
                      display_with_size_system: "US 3-6",
                      display_with_system_and_set: "US 3-6M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["3-6MBEU"],
                        uk: ["3-6MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6-9MB",
                      short: "6-9M",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9M",
                      display_with_size_system: "US 6-9",
                      display_with_system_and_set: "US 6-9M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["6-9MBEU"],
                        uk: ["6-9MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9-12MB",
                      short: "9-12M",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12M",
                      display_with_size_system: "US 9-12",
                      display_with_system_and_set: "US 9-12M",
                      equivalent_sizes: {
                        au: ["00MBAU"],
                        eu: ["9-12MBEU"],
                        uk: ["9-12MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12-18MB",
                      short: "12-18M",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18M",
                      display_with_size_system: "US 12-18",
                      display_with_system_and_set: "US 12-18M",
                      equivalent_sizes: {
                        au: ["0MBAU"],
                        eu: ["12-18MBEU"],
                        uk: ["12-18MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18-24MB",
                      short: "18-24M",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24M",
                      display_with_size_system: "US 18-24",
                      display_with_system_and_set: "US 18-24M",
                      equivalent_sizes: {
                        au: ["1MBAU"],
                        eu: ["18-24MBEU"],
                        uk: ["18-24MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3MB",
                      short: "3M",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3M",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3M",
                      equivalent_sizes: {
                        au: ["0000MBAU", "000MBAU"],
                        eu: ["3MBEU"],
                        uk: ["3MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6MB",
                      short: "6M",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6M",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["6MBEU"],
                        uk: ["6MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9MB",
                      short: "9M",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9M",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9M",
                      equivalent_sizes: {
                        au: ["000MBAU", "00MBAU"],
                        eu: ["9MBEU"],
                        uk: ["9MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12MB",
                      short: "12M",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12M",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12M",
                      equivalent_sizes: {
                        au: ["00MBAU", "0MBAU"],
                        eu: ["12MBEU"],
                        uk: ["12MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18MB",
                      short: "18M",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18M",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18M",
                      equivalent_sizes: {
                        au: ["0MBAU", "1MBAU"],
                        eu: ["18MBEU"],
                        uk: ["18MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24MB",
                      short: "24M",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24M",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24M",
                      equivalent_sizes: {
                        au: ["1MBAU", "2MBAU"],
                        eu: ["24MBEU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSBBAU",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS (Baby)",
                      equivalent_sizes: {
                        us: ["OSBB"],
                        eu: ["OSBBEU"],
                        uk: ["OSBBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "00000MBAU",
                      short: "00000",
                      long: "00000",
                      display: "00000",
                      display_with_size_set: "00000",
                      display_with_size_system: "AU 00000",
                      display_with_system_and_set: "AU 00000",
                      equivalent_sizes: {
                        us: ["Preemie ", "Newborn"],
                        eu: ["PreemieEU", "NewbornEU"],
                        uk: ["PreemieUK", "NewbornUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0000MBAU",
                      short: "0000",
                      long: "0000",
                      display: "0000",
                      display_with_size_set: "0000",
                      display_with_size_system: "AU 0000",
                      display_with_system_and_set: "AU 0000",
                      equivalent_sizes: {
                        us: ["0-3MB", "3MB"],
                        eu: ["0-3MBEU", "3MBEU"],
                        uk: ["0-3MBUK", "3MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "000MBAU",
                      short: "000",
                      long: "000",
                      display: "000",
                      display_with_size_set: "000",
                      display_with_size_system: "AU 000",
                      display_with_system_and_set: "AU 000",
                      equivalent_sizes: {
                        us: ["3MB", "6MB", "9MB", "3-6MB", "6-9MB"],
                        eu: ["3MBEU", "6MBEU", "9MBEU", "3-6MBEU", "6-9MBEU"],
                        uk: ["3MBUK", "6MBUK", "9MBUK", "3-6MBUK", "6-9MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "00MBAU",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "AU 00",
                      display_with_system_and_set: "AU 00",
                      equivalent_sizes: {
                        us: ["9MB", "12MB", "9-12MB"],
                        eu: ["9MBEU", "12MBEU", "9-12MBEU"],
                        uk: ["9MBUK", "12MBUK", "9-12MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0MBAU",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "AU 0",
                      display_with_system_and_set: "AU 0",
                      equivalent_sizes: {
                        us: ["12MB", "18MB", "12-18MB"],
                        eu: ["12MBEU", "18MBEU", "12-18MBEU"],
                        uk: ["12MBUK", "18MBUK", "12-18MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1MBAU",
                      short: "1",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1",
                      display_with_size_system: "AU 1",
                      display_with_system_and_set: "AU 1",
                      equivalent_sizes: {
                        us: ["18MB", "24MB", "18-24MB"],
                        eu: ["18MBEU", "24MBEU", "18-24MBEU"],
                        uk: ["18MBUK", "24MBUK", "18-24MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2MBAU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["24MB"],
                        eu: ["24MBEU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSBBEU",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS (Baby)",
                      equivalent_sizes: {
                        us: ["OSBB"],
                        au: ["OSBBAU"],
                        uk: ["OSBBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "PreemieEU",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "EU Preemie",
                      display_with_system_and_set: "EU Preemie",
                      equivalent_sizes: {
                        us: ["Preemie"],
                        au: ["00000MBAU"],
                        uk: ["PreemieUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "NewbornEU",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "EU Newborn",
                      display_with_system_and_set: "EU Newborn",
                      equivalent_sizes: {
                        us: ["Newborn"],
                        au: ["00000MBAU"],
                        uk: ["NewbornUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0-3MBEU",
                      short: "0-3",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3",
                      display_with_size_system: "EU 0-3",
                      display_with_system_and_set: "EU 0-3",
                      equivalent_sizes: {
                        us: ["0-3MB"],
                        au: ["0000MBAU"],
                        uk: ["0-3MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3-6MBEU",
                      short: "3-6",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6",
                      display_with_size_system: "EU 3-6",
                      display_with_system_and_set: "EU 3-6",
                      equivalent_sizes: {
                        us: ["3-6MB"],
                        au: ["000MBAU"],
                        uk: ["3-6MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6-9MBEU",
                      short: "6-9",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9",
                      display_with_size_system: "EU 6-9",
                      display_with_system_and_set: "EU 6-9",
                      equivalent_sizes: {
                        us: ["6-9MB"],
                        au: ["000MBAU"],
                        uk: ["6-9MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "9-12MBEU",
                      short: "9-12",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12",
                      display_with_size_system: "EU 9-12",
                      display_with_system_and_set: "EU 9-12",
                      equivalent_sizes: {
                        us: ["9-12MB"],
                        au: ["00MBAU"],
                        uk: ["9-12MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12-18MBEU",
                      short: "12-18",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18",
                      display_with_size_system: "EU 12-18",
                      display_with_system_and_set: "EU 12-18",
                      equivalent_sizes: {
                        us: ["12-18MB"],
                        au: ["0MBAU"],
                        uk: ["12-18MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18-24MBEU",
                      short: "18-24",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24",
                      display_with_size_system: "EU 18-24",
                      display_with_system_and_set: "EU 18-24",
                      equivalent_sizes: {
                        us: ["18-24MB"],
                        au: ["1MBAU"],
                        uk: ["18-24MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3MBEU",
                      short: "3",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3",
                      display_with_size_system: "EU 3",
                      display_with_system_and_set: "EU 3",
                      equivalent_sizes: {
                        us: ["3MB"],
                        au: ["0000MBAU", "000MBAU"],
                        uk: ["3MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6MBEU",
                      short: "6",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6",
                      display_with_size_system: "EU 6",
                      display_with_system_and_set: "EU 6",
                      equivalent_sizes: {
                        us: ["6MB"],
                        au: ["000MBAU"],
                        uk: ["6MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "9MBEU",
                      short: "9",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9",
                      display_with_size_system: "EU 9",
                      display_with_system_and_set: "EU 9",
                      equivalent_sizes: {
                        us: ["9MB"],
                        au: ["000MBAU", "00MBAU"],
                        uk: ["9MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12MBEU",
                      short: "12",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12",
                      display_with_size_system: "EU 12",
                      display_with_system_and_set: "EU 12",
                      equivalent_sizes: {
                        us: ["12MB"],
                        au: ["00MBAU", "0MBAU"],
                        uk: ["12MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18MBEU",
                      short: "18",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18",
                      display_with_size_system: "EU 18",
                      display_with_system_and_set: "EU 18",
                      equivalent_sizes: {
                        us: ["18MB"],
                        au: ["0MBAU", "1MBAU"],
                        uk: ["18MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "24MBEU",
                      short: "24",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24",
                      display_with_size_system: "EU 24",
                      display_with_system_and_set: "EU 24",
                      equivalent_sizes: {
                        us: ["24MB"],
                        au: ["1MBAU", "2MBAU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSBBUK",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS (Baby)",
                      equivalent_sizes: {
                        us: ["OSBB"],
                        au: ["OSBBAU"],
                        eu: ["OSBBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "PreemieUK",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "UK Preemie",
                      display_with_system_and_set: "UK Preemie",
                      equivalent_sizes: {
                        us: ["Preemie"],
                        au: ["00000MBAU"],
                        eu: ["PreemieEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "NewbornUK",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "UK Newborn",
                      display_with_system_and_set: "UK Newborn",
                      equivalent_sizes: {
                        us: ["Newborn"],
                        au: ["00000MBAU"],
                        eu: ["NewbornEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0-3MBUK",
                      short: "0-3M",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3M",
                      display_with_size_system: "UK 0-3",
                      display_with_system_and_set: "UK 0-3M",
                      equivalent_sizes: {
                        us: ["0-3MB"],
                        au: ["0000MBAU"],
                        eu: ["0-3MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3-6MBUK",
                      short: "3-6M",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6M",
                      display_with_size_system: "UK 3-6",
                      display_with_system_and_set: "UK 3-6M",
                      equivalent_sizes: {
                        us: ["3-6MB"],
                        au: ["000MBAU"],
                        eu: ["3-6MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6-9MBUK",
                      short: "6-9M",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9M",
                      display_with_size_system: "UK 6-9",
                      display_with_system_and_set: "UK 6-9M",
                      equivalent_sizes: {
                        us: ["6-9MB"],
                        au: ["000MBAU"],
                        eu: ["6-9MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9-12MBUK",
                      short: "9-12M",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12M",
                      display_with_size_system: "UK 9-12",
                      display_with_system_and_set: "UK 9-12M",
                      equivalent_sizes: {
                        us: ["9-12MB"],
                        au: ["00MBAU"],
                        eu: ["9-12MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12-18MBUK",
                      short: "12-18M",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18M",
                      display_with_size_system: "UK 12-18",
                      display_with_system_and_set: "UK 12-18M",
                      equivalent_sizes: {
                        us: ["12-18MB"],
                        au: ["0MBAU"],
                        eu: ["12-18MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18-24MBUK",
                      short: "18-24M",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24M",
                      display_with_size_system: "UK 18-24",
                      display_with_system_and_set: "UK 18-24M",
                      equivalent_sizes: {
                        us: ["18-24MB"],
                        au: ["1MBAU"],
                        eu: ["18-24MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3MBUK",
                      short: "3M",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3M",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3M",
                      equivalent_sizes: {
                        us: ["3MB"],
                        au: ["0000MBAU", "000MBAU"],
                        eu: ["3MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MBUK",
                      short: "6M",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6M",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6M",
                      equivalent_sizes: {
                        us: ["6MB"],
                        au: ["000MBAU"],
                        eu: ["6MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9MBUK",
                      short: "9M",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9M",
                      display_with_size_system: "UK 9",
                      display_with_system_and_set: "UK 9M",
                      equivalent_sizes: {
                        us: ["9MB"],
                        au: ["000MBAU", "00MBAU"],
                        eu: ["9MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MBUK",
                      short: "12M",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12M",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12M",
                      equivalent_sizes: {
                        us: ["12MB"],
                        au: ["00MBAU", "0MBAU"],
                        eu: ["12MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MBUK",
                      short: "18M",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18M",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18M",
                      equivalent_sizes: {
                        us: ["18MB"],
                        au: ["0MBAU", "1MBAU"],
                        eu: ["18MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MBUK",
                      short: "24M",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24M",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24M",
                      equivalent_sizes: {
                        us: ["24MB"],
                        au: ["1MBAU", "2MBAU"],
                        eu: ["24MBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "kids_one_pieces",
          aliases: [],
          slug: "One_Pieces",
          alias_slugs: [],
          parent_id: "20008c10d97b4e1245005764",
          category_features: [
            {
              id: "15002f3cd97b4edf70005784",
              display: "Bodysuits",
              type: "cf",
              message_id: "kids_one_pieces_bodysuits",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bodysuits",
              alias_slugs: [],
              parent_id: "25008c10d97b4e1245005764",
            },
            {
              id: "16002f3cd97b4edf70005784",
              display: "Footies",
              type: "cf",
              message_id: "kids_one_pieces_footies",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Footies",
              alias_slugs: [],
              parent_id: "25008c10d97b4e1245005764",
            },
          ],
        },
        {
          id: "27008c10d97b4e1245005764",
          display: "Pajamas",
          size_sets: [
            {
              id: "1c003667d97b4e6bdb005784",
              name: "Baby",
              tags: ["baby"],
              category_id: "27008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["14.5", "0-18", "15", "15"],
                  ["16", "0-18", "16.5", "16.5"],
                  ["17", "18-23", "17.5", "17.5"],
                  ["18", "23-27", "18.5", "18.5"],
                  ["18.5", "27-28", "19", "19"],
                  ["19", "28-29", "19.5", "19.5"],
                  ["19.75", "29-31", "20.25", "20.25"],
                  ["20.5", "31-33", "21", "21"],
                  ["17", "18-23", "17.5", "17.5"],
                  ["18", "23-27", "18.5", "18.5"],
                  ["18.5", "27-28", "19", "19"],
                  ["19", "28-29", "19.5", "19.5"],
                  ["19.75", "29-31", "20.25", "20.25"],
                  ["20.5", "31-33", "21", "21"],
                ],
                centimeters: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["36.8", "0-45.7", "38.1", "38.1"],
                  ["40.6", "0-45.7", "41.9", "41.9"],
                  ["43.2", "45.7-58.4", "44.5", "44.5"],
                  ["45.7", "58.4-68.6", "47.0", "47.0"],
                  ["47.0", "68.6-71.1", "48.3", "48.3"],
                  ["48.3", "71.1-73.7", "49.5", "49.5"],
                  ["50.2", "73.7-78.7", "51.4", "51.4"],
                  ["52.1", "78.7-83.8", "53.3", "53.3"],
                  ["43.2", "45.7-58.4", "44.5", "44.5"],
                  ["45.7", "58.4-68.6", "47.0", "47.0"],
                  ["47.0", "68.6-71.1", "48.3", "48.3"],
                  ["48.3", "71.1-73.7", "49.5", "49.5"],
                  ["50.2", "73.7-78.7", "51.4", "51.4"],
                  ["52.1", "78.7-83.8", "53.3", "53.3"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["Preemie", "00000", "Preemie", "Preemie"],
                  ["Newborn", "00000", "Newborn", "Newborn"],
                  ["0-3 Months", "0000", "0-3 Months", "0-3 Months"],
                  ["3-6 Months", "000", "3-6 Months", "3-6 Months"],
                  ["6-9 Months", "000", "6-9 Months", "6-9 Months"],
                  ["9-12 Months", "00", "9-12 Months", "9-12 Months"],
                  ["12-18 Months", "0", "12-18 Months", "12-18 Months"],
                  ["18-24 Months", "1", "18-24 Months", "18-24 Months"],
                  ["3 Months", "0000,000", "3 Months", "3 Months"],
                  ["6 Months", "000", "6 Months", "6 Months"],
                  ["9 Months", "000,00", "9 Months", "9 Months"],
                  ["12 Months", "00,0", "12 Months", "12 Months"],
                  ["18 Months", "0,1", "18 Months", "18 Months"],
                  ["24 Months", "1,2", "24 Months", "24 Months"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["Preemie", "00000", "Preemie", "Preemie"],
                ["Newborn", "00000", "Newborn", "Newborn"],
                ["0-3 Months", "0000", "0-3 Months", "0-3 Months"],
                ["3-6 Months", "000", "3-6 Months", "3-6 Months"],
                ["6-9 Months", "000", "6-9 Months", "6-9 Months"],
                ["9-12 Months", "00", "9-12 Months", "9-12 Months"],
                ["12-18 Months", "0", "12-18 Months", "12-18 Months"],
                ["18-24 Months", "1", "18-24 Months", "18-24 Months"],
                ["3 Months", "0000,000", "3 Months", "3 Months"],
                ["6 Months", "000", "6 Months", "6 Months"],
                ["9 Months", "000,00", "9 Months", "9 Months"],
                ["12 Months", "00,0", "12 Months", "12 Months"],
                ["18 Months", "0,1", "18 Months", "18 Months"],
                ["24 Months", "1,2", "24 Months", "24 Months"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "Preemie",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "US Preemie",
                      display_with_system_and_set: "US Preemie",
                      equivalent_sizes: {
                        au: ["00000MBAU"],
                        eu: ["PreemieEU"],
                        uk: ["PreemieUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "Newborn",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "US Newborn",
                      display_with_system_and_set: "US Newborn",
                      equivalent_sizes: {
                        au: ["00000MBAU"],
                        eu: ["NewbornEU"],
                        uk: ["NewbornUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0-3MB",
                      short: "0-3M",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3M",
                      display_with_size_system: "US 0-3",
                      display_with_system_and_set: "US 0-3M",
                      equivalent_sizes: {
                        au: ["0000MBAU"],
                        eu: ["0-3MBEU"],
                        uk: ["0-3MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3-6MB",
                      short: "3-6M",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6M",
                      display_with_size_system: "US 3-6",
                      display_with_system_and_set: "US 3-6M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["3-6MBEU"],
                        uk: ["3-6MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6-9MB",
                      short: "6-9M",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9M",
                      display_with_size_system: "US 6-9",
                      display_with_system_and_set: "US 6-9M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["6-9MBEU"],
                        uk: ["6-9MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9-12MB",
                      short: "9-12M",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12M",
                      display_with_size_system: "US 9-12",
                      display_with_system_and_set: "US 9-12M",
                      equivalent_sizes: {
                        au: ["00MBAU"],
                        eu: ["9-12MBEU"],
                        uk: ["9-12MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12-18MB",
                      short: "12-18M",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18M",
                      display_with_size_system: "US 12-18",
                      display_with_system_and_set: "US 12-18M",
                      equivalent_sizes: {
                        au: ["0MBAU"],
                        eu: ["12-18MBEU"],
                        uk: ["12-18MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18-24MB",
                      short: "18-24M",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24M",
                      display_with_size_system: "US 18-24",
                      display_with_system_and_set: "US 18-24M",
                      equivalent_sizes: {
                        au: ["1MBAU"],
                        eu: ["18-24MBEU"],
                        uk: ["18-24MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3MB",
                      short: "3M",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3M",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3M",
                      equivalent_sizes: {
                        au: ["0000MBAU", "000MBAU"],
                        eu: ["3MBEU"],
                        uk: ["3MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6MB",
                      short: "6M",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6M",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["6MBEU"],
                        uk: ["6MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9MB",
                      short: "9M",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9M",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9M",
                      equivalent_sizes: {
                        au: ["000MBAU", "00MBAU"],
                        eu: ["9MBEU"],
                        uk: ["9MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12MB",
                      short: "12M",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12M",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12M",
                      equivalent_sizes: {
                        au: ["00MBAU", "0MBAU"],
                        eu: ["12MBEU"],
                        uk: ["12MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18MB",
                      short: "18M",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18M",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18M",
                      equivalent_sizes: {
                        au: ["0MBAU", "1MBAU"],
                        eu: ["18MBEU"],
                        uk: ["18MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24MB",
                      short: "24M",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24M",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24M",
                      equivalent_sizes: {
                        au: ["1MBAU", "2MBAU"],
                        eu: ["24MBEU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "00000MBAU",
                      short: "00000",
                      long: "00000",
                      display: "00000",
                      display_with_size_set: "00000",
                      display_with_size_system: "AU 00000",
                      display_with_system_and_set: "AU 00000",
                      equivalent_sizes: {
                        us: ["Preemie ", "Newborn"],
                        eu: ["PreemieEU", "NewbornEU"],
                        uk: ["PreemieUK", "NewbornUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0000MBAU",
                      short: "0000",
                      long: "0000",
                      display: "0000",
                      display_with_size_set: "0000",
                      display_with_size_system: "AU 0000",
                      display_with_system_and_set: "AU 0000",
                      equivalent_sizes: {
                        us: ["0-3MB", "3MB"],
                        eu: ["0-3MBEU", "3MBEU"],
                        uk: ["0-3MBUK", "3MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "000MBAU",
                      short: "000",
                      long: "000",
                      display: "000",
                      display_with_size_set: "000",
                      display_with_size_system: "AU 000",
                      display_with_system_and_set: "AU 000",
                      equivalent_sizes: {
                        us: ["3MB", "6MB", "9MB", "3-6MB", "6-9MB"],
                        eu: ["3MBEU", "6MBEU", "9MBEU", "3-6MBEU", "6-9MBEU"],
                        uk: ["3MBUK", "6MBUK", "9MBUK", "3-6MBUK", "6-9MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "00MBAU",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "AU 00",
                      display_with_system_and_set: "AU 00",
                      equivalent_sizes: {
                        us: ["9MB", "12MB", "9-12MB"],
                        eu: ["9MBEU", "12MBEU", "9-12MBEU"],
                        uk: ["9MBUK", "12MBUK", "9-12MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0MBAU",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "AU 0",
                      display_with_system_and_set: "AU 0",
                      equivalent_sizes: {
                        us: ["12MB", "18MB", "12-18MB"],
                        eu: ["12MBEU", "18MBEU", "12-18MBEU"],
                        uk: ["12MBUK", "18MBUK", "12-18MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1MBAU",
                      short: "1",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1",
                      display_with_size_system: "AU 1",
                      display_with_system_and_set: "AU 1",
                      equivalent_sizes: {
                        us: ["18MB", "24MB", "18-24MB"],
                        eu: ["18MBEU", "24MBEU", "18-24MBEU"],
                        uk: ["18MBUK", "24MBUK", "18-24MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2MBAU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["24MB"],
                        eu: ["24MBEU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "PreemieEU",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "EU Preemie",
                      display_with_system_and_set: "EU Preemie",
                      equivalent_sizes: {
                        us: ["Preemie"],
                        au: ["00000MBAU"],
                        uk: ["PreemieUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "NewbornEU",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "EU Newborn",
                      display_with_system_and_set: "EU Newborn",
                      equivalent_sizes: {
                        us: ["Newborn"],
                        au: ["00000MBAU"],
                        uk: ["NewbornUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0-3MBEU",
                      short: "0-3",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3",
                      display_with_size_system: "EU 0-3",
                      display_with_system_and_set: "EU 0-3",
                      equivalent_sizes: {
                        us: ["0-3MB"],
                        au: ["0000MBAU"],
                        uk: ["0-3MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3-6MBEU",
                      short: "3-6",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6",
                      display_with_size_system: "EU 3-6",
                      display_with_system_and_set: "EU 3-6",
                      equivalent_sizes: {
                        us: ["3-6MB"],
                        au: ["000MBAU"],
                        uk: ["3-6MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6-9MBEU",
                      short: "6-9",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9",
                      display_with_size_system: "EU 6-9",
                      display_with_system_and_set: "EU 6-9",
                      equivalent_sizes: {
                        us: ["6-9MB"],
                        au: ["000MBAU"],
                        uk: ["6-9MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "9-12MBEU",
                      short: "9-12",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12",
                      display_with_size_system: "EU 9-12",
                      display_with_system_and_set: "EU 9-12",
                      equivalent_sizes: {
                        us: ["9-12MB"],
                        au: ["00MBAU"],
                        uk: ["9-12MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12-18MBEU",
                      short: "12-18",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18",
                      display_with_size_system: "EU 12-18",
                      display_with_system_and_set: "EU 12-18",
                      equivalent_sizes: {
                        us: ["12-18MB"],
                        au: ["0MBAU"],
                        uk: ["12-18MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18-24MBEU",
                      short: "18-24",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24",
                      display_with_size_system: "EU 18-24",
                      display_with_system_and_set: "EU 18-24",
                      equivalent_sizes: {
                        us: ["18-24MB"],
                        au: ["1MBAU"],
                        uk: ["18-24MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3MBEU",
                      short: "3",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3",
                      display_with_size_system: "EU 3",
                      display_with_system_and_set: "EU 3",
                      equivalent_sizes: {
                        us: ["3MB"],
                        au: ["0000MBAU", "000MBAU"],
                        uk: ["3MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6MBEU",
                      short: "6",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6",
                      display_with_size_system: "EU 6",
                      display_with_system_and_set: "EU 6",
                      equivalent_sizes: {
                        us: ["6MB"],
                        au: ["000MBAU"],
                        uk: ["6MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "9MBEU",
                      short: "9",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9",
                      display_with_size_system: "EU 9",
                      display_with_system_and_set: "EU 9",
                      equivalent_sizes: {
                        us: ["9MB"],
                        au: ["000MBAU", "00MBAU"],
                        uk: ["9MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12MBEU",
                      short: "12",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12",
                      display_with_size_system: "EU 12",
                      display_with_system_and_set: "EU 12",
                      equivalent_sizes: {
                        us: ["12MB"],
                        au: ["00MBAU", "0MBAU"],
                        uk: ["12MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18MBEU",
                      short: "18",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18",
                      display_with_size_system: "EU 18",
                      display_with_system_and_set: "EU 18",
                      equivalent_sizes: {
                        us: ["18MB"],
                        au: ["0MBAU", "1MBAU"],
                        uk: ["18MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "24MBEU",
                      short: "24",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24",
                      display_with_size_system: "EU 24",
                      display_with_system_and_set: "EU 24",
                      equivalent_sizes: {
                        us: ["24MB"],
                        au: ["1MBAU", "2MBAU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "PreemieUK",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "UK Preemie",
                      display_with_system_and_set: "UK Preemie",
                      equivalent_sizes: {
                        us: ["Preemie"],
                        au: ["00000MBAU"],
                        eu: ["PreemieEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "NewbornUK",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "UK Newborn",
                      display_with_system_and_set: "UK Newborn",
                      equivalent_sizes: {
                        us: ["Newborn"],
                        au: ["00000MBAU"],
                        eu: ["NewbornEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0-3MBUK",
                      short: "0-3M",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3M",
                      display_with_size_system: "UK 0-3",
                      display_with_system_and_set: "UK 0-3M",
                      equivalent_sizes: {
                        us: ["0-3MB"],
                        au: ["0000MBAU"],
                        eu: ["0-3MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3-6MBUK",
                      short: "3-6M",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6M",
                      display_with_size_system: "UK 3-6",
                      display_with_system_and_set: "UK 3-6M",
                      equivalent_sizes: {
                        us: ["3-6MB"],
                        au: ["000MBAU"],
                        eu: ["3-6MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6-9MBUK",
                      short: "6-9M",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9M",
                      display_with_size_system: "UK 6-9",
                      display_with_system_and_set: "UK 6-9M",
                      equivalent_sizes: {
                        us: ["6-9MB"],
                        au: ["000MBAU"],
                        eu: ["6-9MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9-12MBUK",
                      short: "9-12M",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12M",
                      display_with_size_system: "UK 9-12",
                      display_with_system_and_set: "UK 9-12M",
                      equivalent_sizes: {
                        us: ["9-12MB"],
                        au: ["00MBAU"],
                        eu: ["9-12MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12-18MBUK",
                      short: "12-18M",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18M",
                      display_with_size_system: "UK 12-18",
                      display_with_system_and_set: "UK 12-18M",
                      equivalent_sizes: {
                        us: ["12-18MB"],
                        au: ["0MBAU"],
                        eu: ["12-18MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18-24MBUK",
                      short: "18-24M",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24M",
                      display_with_size_system: "UK 18-24",
                      display_with_system_and_set: "UK 18-24M",
                      equivalent_sizes: {
                        us: ["18-24MB"],
                        au: ["1MBAU"],
                        eu: ["18-24MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3MBUK",
                      short: "3M",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3M",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3M",
                      equivalent_sizes: {
                        us: ["3MB"],
                        au: ["0000MBAU", "000MBAU"],
                        eu: ["3MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MBUK",
                      short: "6M",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6M",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6M",
                      equivalent_sizes: {
                        us: ["6MB"],
                        au: ["000MBAU"],
                        eu: ["6MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9MBUK",
                      short: "9M",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9M",
                      display_with_size_system: "UK 9",
                      display_with_system_and_set: "UK 9M",
                      equivalent_sizes: {
                        us: ["9MB"],
                        au: ["000MBAU", "00MBAU"],
                        eu: ["9MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MBUK",
                      short: "12M",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12M",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12M",
                      equivalent_sizes: {
                        us: ["12MB"],
                        au: ["00MBAU", "0MBAU"],
                        eu: ["12MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MBUK",
                      short: "18M",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18M",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18M",
                      equivalent_sizes: {
                        us: ["18MB"],
                        au: ["0MBAU", "1MBAU"],
                        eu: ["18MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MBUK",
                      short: "24M",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24M",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24M",
                      equivalent_sizes: {
                        us: ["24MB"],
                        au: ["1MBAU", "2MBAU"],
                        eu: ["24MBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "1d003667d97b4e6bdb005784",
              name: "Girls",
              tags: ["girls"],
              category_id: "27008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["20.5", "33.5-35.5", "21", "21"],
                  ["21", "36-38", "21.5", "22"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["24", "45-46.5", "23", "25"],
                  ["25.5", "47-49.5", "23.5", "26.5"],
                  ["26", "49-50.5", "24", "27.5"],
                  ["27", "51-52.5", "24.5", "28.5"],
                  ["28.5", "53-55", "25", "30"],
                  ["30", "55.5-58", "26", "31.5"],
                  ["31.5", "58.5-61", "27.5", "33"],
                  ["33", "61.5-62.5", "29", "34.5"],
                  ["22.0-23.0", "38.5-44.5", "22.0-22.5", "23.0-24.0"],
                  ["24.0-26.0", "45-50.5", "23.0-24.0", "25.0-27.5"],
                  ["27", "51-52.5", "24.5", "28.5"],
                  ["28.5", "53-55", "25", "30"],
                  ["30", "55.5-58", "26", "31.5"],
                  ["31.5-33.0", "58.5-62.5", "27.5-29.0", "33-34.5"],
                ],
                centimeters: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["52.1", "85.1-90.2", "53.3", "53.3"],
                  ["53.3", "91.4-96.5", "54.6", "55.9"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["61", "114.3-118.1", "58.4", "63.5"],
                  ["64.8", "119.4-125.7", "59.7", "67.3"],
                  ["66", "125.7-128.3", "61", "69.9"],
                  ["68.6", "129.5-133.4", "62.2", "72.4"],
                  ["72.4", "134.6-139.7", "63.5", "76.2"],
                  ["76.2", "141.0-147.3", "66", "80"],
                  ["80", "148.6-154.9", "69.9", "83.8"],
                  ["83.8", "156.2-158.8", "73.7", "87.6"],
                  ["55.9-58.4", "97.8-113.0", "55.9-57.2", "58.4-61.0"],
                  ["61.0-66.0", "114.3-128.3", "58.4-61.0", "63.5-69.9"],
                  ["68.6", "129.5-133.4", "62.2", "72.4"],
                  ["72.4", "134.6-139.7", "63.5", "76.2"],
                  ["76.2", "141.0-147.3", "66", "80"],
                  ["80.0-83.8", "148.6-158.8", "69.9-73.7", "83.8-87.6"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["6", "6", "6", "6"],
                  ["6X,7", "7", "6X,7", "7"],
                  ["6X,7", "7", "6X,7", "7"],
                  ["8", "8", "8", "8"],
                  ["10", "10", "10", "10"],
                  ["12", "12", "12", "12"],
                  ["14", "14", "14", "14"],
                  ["16", "16", "16", "16"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["2T", "3", "2A", "3"],
                ["3T", "3", "3A", "3"],
                ["4T", "4", "4A", "4"],
                ["5T", "5", "5A", "5"],
                ["4", "4", "4", "4"],
                ["5", "5", "5", "5"],
                ["6", "6", "6", "6"],
                ["6X", "7", "6X", "7"],
                ["7", "7", "7", "7"],
                ["8", "8", "8", "8"],
                ["10", "10", "10", "10"],
                ["12", "12", "12", "12"],
                ["14", "14", "14", "14"],
                ["16", "16", "16", "16"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "2TG",
                      short: "2T (Girl)",
                      long: "2T",
                      display: "2T",
                      display_with_size_set: "2T (Girl)",
                      display_with_size_system: "US 2T",
                      display_with_system_and_set: "US 2T (Girl)",
                      equivalent_sizes: {
                        au: ["3GAU"],
                        eu: ["2AGEU"],
                        uk: ["3GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3TG",
                      short: "3T (Girl)",
                      long: "3T",
                      display: "3T",
                      display_with_size_set: "3T (Girl)",
                      display_with_size_system: "US 3T",
                      display_with_system_and_set: "US 3T (Girl)",
                      equivalent_sizes: {
                        au: ["3GAU"],
                        eu: ["3AGEU"],
                        uk: ["3GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4TG",
                      short: "4T (Girl)",
                      long: "4T",
                      display: "4T",
                      display_with_size_set: "4T (Girl)",
                      display_with_size_system: "US 4T",
                      display_with_system_and_set: "US 4T (Girl)",
                      equivalent_sizes: {
                        au: ["4GAU"],
                        eu: ["4AGEU"],
                        uk: ["4GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5TG",
                      short: "5T (Girl)",
                      long: "5T",
                      display: "5T",
                      display_with_size_set: "5T (Girl)",
                      display_with_size_system: "US 5T",
                      display_with_system_and_set: "US 5T (Girl)",
                      equivalent_sizes: {
                        au: ["5GAU"],
                        eu: ["5AGEU"],
                        uk: ["5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4G",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Girl)",
                      equivalent_sizes: {
                        au: ["4GAU"],
                        eu: ["4GEU"],
                        uk: ["4GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5G",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Girl)",
                      equivalent_sizes: {
                        au: ["5GAU"],
                        eu: ["5GEU"],
                        uk: ["5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6G",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Girl)",
                      equivalent_sizes: {
                        au: ["6GAU"],
                        eu: ["6GEU"],
                        uk: ["6GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XG",
                      short: "6X (Girl)",
                      long: "6X",
                      display: "6X",
                      display_with_size_set: "6X (Girl)",
                      display_with_size_system: "US 6X",
                      display_with_system_and_set: "US 6X (Girl)",
                      equivalent_sizes: {
                        au: ["7GAU"],
                        eu: ["6XGEU"],
                        uk: ["7GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7G",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Girl)",
                      equivalent_sizes: {
                        au: ["7GAU"],
                        eu: ["7GEU"],
                        uk: ["7GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8G",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Girl)",
                      equivalent_sizes: {
                        au: ["8GAU"],
                        eu: ["8GEU"],
                        uk: ["8GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10G",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Girl)",
                      equivalent_sizes: {
                        au: ["10GAU"],
                        eu: ["10GEU"],
                        uk: ["10GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12G",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Girl)",
                      equivalent_sizes: {
                        au: ["12GAU"],
                        eu: ["12GEU"],
                        uk: ["12GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14G",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Girl)",
                      equivalent_sizes: {
                        au: ["14GAU"],
                        eu: ["14GEU"],
                        uk: ["14GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16G",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Girl)",
                      equivalent_sizes: {
                        au: ["16GAU"],
                        eu: ["16GEU"],
                        uk: ["16GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSG",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Girl)",
                      equivalent_sizes: {
                        au: ["XSGAU"],
                        eu: ["XSGEU"],
                        uk: ["XSGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SG",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Girl)",
                      equivalent_sizes: {
                        au: ["SGAU"],
                        eu: ["SGEU"],
                        uk: ["SGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MG",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Girl)",
                      equivalent_sizes: {
                        au: ["MGAU"],
                        eu: ["MGEU"],
                        uk: ["MGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LG",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Girl)",
                      equivalent_sizes: {
                        au: ["LGAU"],
                        eu: ["LGEU"],
                        uk: ["LGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLG",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Girl)",
                      equivalent_sizes: {
                        au: ["XLGAU"],
                        eu: ["XLGEU"],
                        uk: ["XLGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLG",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Girl)",
                      equivalent_sizes: {
                        au: ["XXLGAU"],
                        eu: ["XXLGEU"],
                        uk: ["XXLGUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "3GAU",
                      short: "3 (Girl)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Girl)",
                      display_with_size_system: "AU 3",
                      display_with_system_and_set: "AU 3 (Girl)",
                      equivalent_sizes: {
                        us: ["2TG", "3TG"],
                        eu: ["2AGEU", "3AGEU"],
                        uk: ["3GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4GAU",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Girl)",
                      equivalent_sizes: {
                        us: ["4TG", "4G"],
                        eu: ["4AGEU", "4GEU"],
                        uk: ["4GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5GAU",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "AU 5",
                      display_with_system_and_set: "AU 5 (Girl)",
                      equivalent_sizes: {
                        us: ["5TG", "5G"],
                        eu: ["5AGEU", "5GEU"],
                        uk: ["5GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6GAU",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Girl)",
                      equivalent_sizes: {
                        us: ["6G"],
                        eu: ["6GEU"],
                        uk: ["6GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "7GAU",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "AU 7",
                      display_with_system_and_set: "AU 7 (Girl)",
                      equivalent_sizes: {
                        us: ["6XG", "7G"],
                        eu: ["6XGEU", "7GEU"],
                        uk: ["7GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8GAU",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Girl)",
                      equivalent_sizes: {
                        us: ["8G"],
                        eu: ["8GEU"],
                        uk: ["8GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10GAU",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Girl)",
                      equivalent_sizes: {
                        us: ["10G"],
                        eu: ["10GEU"],
                        uk: ["10GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12GAU",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Girl)",
                      equivalent_sizes: {
                        us: ["12G"],
                        eu: ["12GEU"],
                        uk: ["12GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14GAU",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Girl)",
                      equivalent_sizes: {
                        us: ["14G"],
                        eu: ["14GEU"],
                        uk: ["14GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16GAU",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Girl)",
                      equivalent_sizes: {
                        us: ["16G"],
                        eu: ["16GEU"],
                        uk: ["16GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSGAU",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Girl)",
                      equivalent_sizes: {
                        us: ["XSG"],
                        eu: ["XSGEU"],
                        uk: ["XSGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SGAU",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Girl)",
                      equivalent_sizes: {
                        us: ["SG"],
                        eu: ["SGEU"],
                        uk: ["SGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MGAU",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Girl)",
                      equivalent_sizes: {
                        us: ["MG"],
                        eu: ["MGEU"],
                        uk: ["MGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LGAU",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Girl)",
                      equivalent_sizes: {
                        us: ["LG"],
                        eu: ["LGEU"],
                        uk: ["LGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLGAU",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Girl)",
                      equivalent_sizes: {
                        us: ["XLG"],
                        eu: ["XLGEU"],
                        uk: ["XLGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLGAU",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Girl)",
                      equivalent_sizes: {
                        us: ["XXLG"],
                        eu: ["XXLGEU"],
                        uk: ["XXLGUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "2AGEU",
                      short: "2A (Girl)",
                      long: "2A",
                      display: "2A",
                      display_with_size_set: "2A (Girl)",
                      display_with_size_system: "EU 2A",
                      display_with_system_and_set: "EU 2A (Girl)",
                      equivalent_sizes: {
                        us: ["2TG"],
                        au: ["3GAU"],
                        uk: ["3GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3AGEU",
                      short: "3A (Girl)",
                      long: "3A",
                      display: "3A",
                      display_with_size_set: "3A (Girl)",
                      display_with_size_system: "EU 3A",
                      display_with_system_and_set: "EU 3A (Girl)",
                      equivalent_sizes: {
                        us: ["3TG"],
                        au: ["3GAU"],
                        uk: ["3GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4AGEU",
                      short: "4A (Girl)",
                      long: "4A",
                      display: "4A",
                      display_with_size_set: "4A (Girl)",
                      display_with_size_system: "EU 4A",
                      display_with_system_and_set: "EU 4A (Girl)",
                      equivalent_sizes: {
                        us: ["4TG"],
                        au: ["4GAU"],
                        uk: ["4GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4GEU",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "EU 4",
                      display_with_system_and_set: "EU 4 (Girl)",
                      equivalent_sizes: {
                        us: ["4G"],
                        au: ["4GAU"],
                        uk: ["4GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5AGEU",
                      short: "5A (Girl)",
                      long: "5A",
                      display: "5A",
                      display_with_size_set: "5A (Girl)",
                      display_with_size_system: "EU 5A",
                      display_with_system_and_set: "EU 5A (Girl)",
                      equivalent_sizes: {
                        us: ["5TG"],
                        au: ["5GAU"],
                        uk: ["5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5GEU",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "EU 5",
                      display_with_system_and_set: "EU 5 (Girl)",
                      equivalent_sizes: {
                        us: ["5G"],
                        au: ["5GAU"],
                        uk: ["5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XGEU",
                      short: "6X (Girl)",
                      long: "6X",
                      display: "6X",
                      display_with_size_set: "6X (Girl)",
                      display_with_size_system: "EU 6X",
                      display_with_system_and_set: "EU 6X (Girl)",
                      equivalent_sizes: {
                        us: ["6XG"],
                        au: ["7GAU"],
                        uk: ["7GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6GEU",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "EU 6",
                      display_with_system_and_set: "EU 6 (Girl)",
                      equivalent_sizes: {
                        us: ["6G"],
                        au: ["6GAU"],
                        uk: ["6GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "7GEU",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "EU 7",
                      display_with_system_and_set: "EU 7 (Girl)",
                      equivalent_sizes: {
                        us: ["7G"],
                        au: ["7GAU"],
                        uk: ["7GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "8GEU",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "EU 8",
                      display_with_system_and_set: "EU 8 (Girl)",
                      equivalent_sizes: {
                        us: ["8G"],
                        au: ["8GAU"],
                        uk: ["8GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "10GEU",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "EU 10",
                      display_with_system_and_set: "EU 10 (Girl)",
                      equivalent_sizes: {
                        us: ["10G"],
                        au: ["10GAU"],
                        uk: ["10GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12GEU",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "EU 12",
                      display_with_system_and_set: "EU 12 (Girl)",
                      equivalent_sizes: {
                        us: ["12G"],
                        au: ["12GAU"],
                        uk: ["12GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "14GEU",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "EU 14",
                      display_with_system_and_set: "EU 14 (Girl)",
                      equivalent_sizes: {
                        us: ["14G"],
                        au: ["14GAU"],
                        uk: ["14GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "16GEU",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "EU 16",
                      display_with_system_and_set: "EU 16 (Girl)",
                      equivalent_sizes: {
                        us: ["16G"],
                        au: ["16GAU"],
                        uk: ["16GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSGEU",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Girl)",
                      equivalent_sizes: {
                        us: ["XSG"],
                        au: ["XSGAU"],
                        uk: ["XSGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SGEU",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Girl)",
                      equivalent_sizes: {
                        us: ["SG"],
                        au: ["SGAU"],
                        uk: ["SGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MGEU",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Girl)",
                      equivalent_sizes: {
                        us: ["MG"],
                        au: ["MGAU"],
                        uk: ["MGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LGEU",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Girl)",
                      equivalent_sizes: {
                        us: ["LG"],
                        au: ["LGAU"],
                        uk: ["LGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLGEU",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Girl)",
                      equivalent_sizes: {
                        us: ["XLG"],
                        au: ["XLGAU"],
                        uk: ["XLGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLGEU",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Girl)",
                      equivalent_sizes: {
                        us: ["XXLG"],
                        au: ["XXLGAU"],
                        uk: ["XXLGUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "3GUK",
                      short: "3 (Girl)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Girl)",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3 (Girl)",
                      equivalent_sizes: {
                        us: ["2TG", "3TG"],
                        au: ["3GAU"],
                        eu: ["2AGEU", "3AGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4GUK",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Girl)",
                      equivalent_sizes: {
                        us: ["4TG", "4G"],
                        au: ["4GAU"],
                        eu: ["4AGEU", "4GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5GUK",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "UK 5",
                      display_with_system_and_set: "UK 5 (Girl)",
                      equivalent_sizes: {
                        us: ["5TG", "5G"],
                        au: ["5GAU"],
                        eu: ["5AGEU", "5GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6GUK",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Girl)",
                      equivalent_sizes: {
                        us: ["6G"],
                        au: ["6GAU"],
                        eu: ["6GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "7GUK",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "UK 7",
                      display_with_system_and_set: "UK 7 (Girl)",
                      equivalent_sizes: {
                        us: ["6XG", "7G"],
                        au: ["7GAU"],
                        eu: ["6XGEU", "7GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8GUK",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Girl)",
                      equivalent_sizes: {
                        us: ["8G"],
                        au: ["8GAU"],
                        eu: ["8GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10GUK",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Girl)",
                      equivalent_sizes: {
                        us: ["10G"],
                        au: ["10GAU"],
                        eu: ["10GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12GUK",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Girl)",
                      equivalent_sizes: {
                        us: ["12G"],
                        au: ["12GAU"],
                        eu: ["12GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14GUK",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Girl)",
                      equivalent_sizes: {
                        us: ["14G"],
                        au: ["14GAU"],
                        eu: ["14GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16GUK",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Girl)",
                      equivalent_sizes: {
                        us: ["16G"],
                        au: ["16GAU"],
                        eu: ["16GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSGUK",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Girl)",
                      equivalent_sizes: {
                        us: ["XSG"],
                        au: ["XSGAU"],
                        eu: ["XSGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SGUK",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Girl)",
                      equivalent_sizes: {
                        us: ["SG"],
                        au: ["SGAU"],
                        eu: ["SGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MGUK",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Girl)",
                      equivalent_sizes: {
                        us: ["MG"],
                        au: ["MGAU"],
                        eu: ["MGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LGUK",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Girl)",
                      equivalent_sizes: {
                        us: ["LG"],
                        au: ["LGAU"],
                        eu: ["LGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLGUK",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Girl)",
                      equivalent_sizes: {
                        us: ["XLG"],
                        au: ["XLGAU"],
                        eu: ["XLGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLGUK",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Girl)",
                      equivalent_sizes: {
                        us: ["XXLG"],
                        au: ["XXLGAU"],
                        eu: ["XXLGEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "1e003667d97b4e6bdb005784",
              name: "Boys",
              tags: ["boys"],
              category_id: "27008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["20.5", "33.5-35.5", "21", "21"],
                  ["21", "36-38", "21.5", "22"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["24", "45-46.5", "23", "25"],
                  ["25.5", "47-49.5", "23.5", "26.5"],
                  ["26.5", "50-52", "24.5", "27"],
                  ["27.5", "52.5-55.5", "25.5", "28"],
                  ["28.5", "56-57.5", "26.5", "29"],
                  ["30", "58-59.5", "28", "30.5"],
                  ["31.5", "60-61.5", "29.5", "32"],
                  ["33", "62-63.5", "30.5", "33"],
                  ["34.5", "64-65", "31.5", "34"],
                  ["22.0-23.0", "38.5-44.5", "22.0-22.5", "23.0-24.0"],
                  ["24.0-26.5", "45-52", "23.0-24.5", "25.0-27.0"],
                  ["26.5", "50-52", "24.5", "27"],
                  ["27.5", "52.5-55.5", "25.5", "28"],
                  ["28.5", "56-57.5", "26.5", "29"],
                  ["30.0-31.5", "58-61.5", "28.0-29.5", "30.5-32.0"],
                ],
                centimeters: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["52.1", "85.1-90.2", "53.3", "53.3"],
                  ["53.3", "91.4-96.5", "54.6", "55.9"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["61", "114.3-118.1", "58.4", "63.5"],
                  ["64.8", "119.4-125.7", "59.7", "67.3"],
                  ["67.3", "127-132.1", "62.2", "68.6"],
                  ["69.9", "133.4-141.0", "64.8", "71.1"],
                  ["72.4", "142.2-146.1", "67.3", "73.7"],
                  ["76.2", "147.3-151.1", "71.1", "77.5"],
                  ["80", "152.4-156.2", "74.9", "81.3"],
                  ["83.8", "157.5-161.3", "77.5", "83.8"],
                  ["87.6", "162.6-165.1", "80", "86.4"],
                  ["55.9-58.4", "97.8-113.0", "55.9-57.2", "58.4-61.0"],
                  ["61.0-67.3", "114.3-132.1", "58.4-62.2", "63.5-68.6"],
                  ["67.3", "127-132.1", "62.2", "68.6"],
                  ["69.9", "133.4-141.0", "64.8", "71.1"],
                  ["72.4", "142.2-146.1", "67.3", "73.7"],
                  ["76.2-80.0", "147.3-156.2", "71.1-74.9", "77.5-81.3"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["6", "6", "6", "6"],
                  ["7", "7", "7", "7"],
                  ["7X,8", "8", "7X,8", "8"],
                  ["10", "10", "10", "10"],
                  ["12", "12", "12", "12"],
                  ["14", "14", "14", "14"],
                  ["16", "16", "16", "16"],
                  ["18", "18", "18", "18"],
                  ["20", "20", "20", "20"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["2T", "3", "2A", "3"],
                ["3T", "3", "3A", "3"],
                ["4T", "4", "4A", "4"],
                ["5T", "5", "5A", "5"],
                ["4", "4", "4", "4"],
                ["5", "5", "5", "5"],
                ["6", "6", "6", "6"],
                ["7", "7", "7", "7"],
                ["7X", "8", "7X", "8"],
                ["8", "8", "8", "8"],
                ["10", "10", "10", "10"],
                ["12", "12", "12", "12"],
                ["14", "14", "14", "14"],
                ["16", "16", "16", "16"],
                ["18", "18", "18", "18"],
                ["20", "20", "20", "20"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "2TB",
                      short: "2T (Boy)",
                      long: "2T",
                      display: "2T",
                      display_with_size_set: "2T (Boy)",
                      display_with_size_system: "US 2T",
                      display_with_system_and_set: "US 2T (Boy)",
                      equivalent_sizes: {
                        au: ["3BAU"],
                        eu: ["2ABEU"],
                        uk: ["3BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3TB",
                      short: "3T (Boy)",
                      long: "3T",
                      display: "3T",
                      display_with_size_set: "3T (Boy)",
                      display_with_size_system: "US 3T",
                      display_with_system_and_set: "US 3T (Boy)",
                      equivalent_sizes: {
                        au: ["3BAU"],
                        eu: ["3ABEU"],
                        uk: ["3BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4TB",
                      short: "4T (Boy)",
                      long: "4T",
                      display: "4T",
                      display_with_size_set: "4T (Boy)",
                      display_with_size_system: "US 4T",
                      display_with_system_and_set: "US 4T (Boy)",
                      equivalent_sizes: {
                        au: ["4BAU"],
                        eu: ["4ABEU"],
                        uk: ["4BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5TB",
                      short: "5T (Boy)",
                      long: "5T",
                      display: "5T",
                      display_with_size_set: "5T (Boy)",
                      display_with_size_system: "US 5T",
                      display_with_system_and_set: "US 5T (Boy)",
                      equivalent_sizes: {
                        au: ["5BAU"],
                        eu: ["5ABEU"],
                        uk: ["5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4B",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Boy)",
                      equivalent_sizes: {
                        au: ["4BAU"],
                        eu: ["4BEU"],
                        uk: ["4BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5B",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Boy)",
                      equivalent_sizes: {
                        au: ["5BAU"],
                        eu: ["5BEU"],
                        uk: ["5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6B",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Boy)",
                      equivalent_sizes: {
                        au: ["6BAU"],
                        eu: ["6BEU"],
                        uk: ["6BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7B",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Boy)",
                      equivalent_sizes: {
                        au: ["7BAU"],
                        eu: ["7BEU"],
                        uk: ["7BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7XB",
                      short: "7X (Boy)",
                      long: "7X",
                      display: "7X",
                      display_with_size_set: "7X (Boy)",
                      display_with_size_system: "US 7X",
                      display_with_system_and_set: "US 7X (Boy)",
                      equivalent_sizes: {
                        au: ["8BAU"],
                        eu: ["7XBEU"],
                        uk: ["8BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8B",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Boy)",
                      equivalent_sizes: {
                        au: ["8BAU"],
                        eu: ["8BEU"],
                        uk: ["8BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10B",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Boy)",
                      equivalent_sizes: {
                        au: ["10BAU"],
                        eu: ["10BEU"],
                        uk: ["10BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12B",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Boy)",
                      equivalent_sizes: {
                        au: ["12BAU"],
                        eu: ["12BEU"],
                        uk: ["12BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14B",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Boy)",
                      equivalent_sizes: {
                        au: ["14BAU"],
                        eu: ["14BEU"],
                        uk: ["14BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16B",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Boy)",
                      equivalent_sizes: {
                        au: ["16BAU"],
                        eu: ["16BEU"],
                        uk: ["16BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18B",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Boy)",
                      equivalent_sizes: {
                        au: ["18BAU"],
                        eu: ["18BEU"],
                        uk: ["18BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20B",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Boy)",
                      equivalent_sizes: {
                        au: ["20BAU"],
                        eu: ["20BEU"],
                        uk: ["20BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSB",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Boy)",
                      equivalent_sizes: {
                        au: ["XSBAU"],
                        eu: ["XSBEU"],
                        uk: ["XSBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SB",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Boy)",
                      equivalent_sizes: {
                        au: ["SBAU"],
                        eu: ["SBEU"],
                        uk: ["SBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MB",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Boy)",
                      equivalent_sizes: {
                        au: ["MBAU"],
                        eu: ["MBEU"],
                        uk: ["MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LB",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Boy)",
                      equivalent_sizes: {
                        au: ["LBAU"],
                        eu: ["LBEU"],
                        uk: ["LBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLB",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Boy)",
                      equivalent_sizes: {
                        au: ["XLBAU"],
                        eu: ["XLBEU"],
                        uk: ["XLBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLB",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Boy)",
                      equivalent_sizes: {
                        au: ["XXLBAU"],
                        eu: ["XXLBEU"],
                        uk: ["XXLBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "3BAU",
                      short: "3 (Boy)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Boy)",
                      display_with_size_system: "AU 3",
                      display_with_system_and_set: "AU 3 (Boy)",
                      equivalent_sizes: {
                        us: ["2TB", "3TB"],
                        eu: ["2ABEU", "3ABEU"],
                        uk: ["3BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4BAU",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Boy)",
                      equivalent_sizes: {
                        us: ["4TB", "4B"],
                        eu: ["4ABEU", "4BEU"],
                        uk: ["4BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5BAU",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "AU 5",
                      display_with_system_and_set: "AU 5 (Boy)",
                      equivalent_sizes: {
                        us: ["5TB", "5B"],
                        eu: ["5ABEU", "5BEU"],
                        uk: ["5BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6BAU",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Boy)",
                      equivalent_sizes: {
                        us: ["6B"],
                        eu: ["6BEU"],
                        uk: ["6BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "7BAU",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "AU 7",
                      display_with_system_and_set: "AU 7 (Boy)",
                      equivalent_sizes: {
                        us: ["7B"],
                        eu: ["7BEU"],
                        uk: ["7BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8BAU",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Boy)",
                      equivalent_sizes: {
                        us: ["7XB", "8B"],
                        eu: ["7XBEU", "8BEU"],
                        uk: ["8BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10BAU",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Boy)",
                      equivalent_sizes: {
                        us: ["10B"],
                        eu: ["10BEU"],
                        uk: ["10BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12BAU",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Boy)",
                      equivalent_sizes: {
                        us: ["12B"],
                        eu: ["12BEU"],
                        uk: ["12BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14BAU",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Boy)",
                      equivalent_sizes: {
                        us: ["14B"],
                        eu: ["14BEU"],
                        uk: ["14BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16BAU",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Boy)",
                      equivalent_sizes: {
                        us: ["16B"],
                        eu: ["16BEU"],
                        uk: ["16BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18BAU",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Boy)",
                      equivalent_sizes: {
                        us: ["18B"],
                        eu: ["18BEU"],
                        uk: ["18BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20BAU",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Boy)",
                      equivalent_sizes: {
                        us: ["20B"],
                        eu: ["20BEU"],
                        uk: ["20BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSBAU",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Boy)",
                      equivalent_sizes: {
                        us: ["XSB"],
                        eu: ["XSBEU"],
                        uk: ["XSBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SBAU",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Boy)",
                      equivalent_sizes: {
                        us: ["SB"],
                        eu: ["SBEU"],
                        uk: ["SBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MBAU",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Boy)",
                      equivalent_sizes: {
                        us: ["MB"],
                        eu: ["MBEU"],
                        uk: ["MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LBAU",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Boy)",
                      equivalent_sizes: {
                        us: ["LB"],
                        eu: ["LBEU"],
                        uk: ["LBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLBAU",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Boy)",
                      equivalent_sizes: {
                        us: ["XLB"],
                        eu: ["XLBEU"],
                        uk: ["XLBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLBAU",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Boy)",
                      equivalent_sizes: {
                        us: ["XXLB"],
                        eu: ["XXLBEU"],
                        uk: ["XXLBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "2ABEU",
                      short: "2A (Boy)",
                      long: "2A",
                      display: "2A",
                      display_with_size_set: "2A (Boy)",
                      display_with_size_system: "EU 2A",
                      display_with_system_and_set: "EU 2A (Boy)",
                      equivalent_sizes: {
                        us: ["2TB"],
                        au: ["3BAU"],
                        uk: ["3BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3ABEU",
                      short: "3A (Boy)",
                      long: "3A",
                      display: "3A",
                      display_with_size_set: "3A (Boy)",
                      display_with_size_system: "EU 3A",
                      display_with_system_and_set: "EU 3A (Boy)",
                      equivalent_sizes: {
                        us: ["3TB"],
                        au: ["3BAU"],
                        uk: ["3BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4ABEU",
                      short: "4A (Boy)",
                      long: "4A",
                      display: "4A",
                      display_with_size_set: "4A (Boy)",
                      display_with_size_system: "EU 4A",
                      display_with_system_and_set: "EU 4A (Boy)",
                      equivalent_sizes: {
                        us: ["4TB"],
                        au: ["4BAU"],
                        uk: ["4BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4BEU",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "EU 4",
                      display_with_system_and_set: "EU 4 (Boy)",
                      equivalent_sizes: {
                        us: ["4B"],
                        au: ["4BAU"],
                        uk: ["4BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5ABEU",
                      short: "5A (Boy)",
                      long: "5A",
                      display: "5A",
                      display_with_size_set: "5A (Boy)",
                      display_with_size_system: "EU 5A",
                      display_with_system_and_set: "EU 5A (Boy)",
                      equivalent_sizes: {
                        us: ["5TB"],
                        au: ["5BAU"],
                        uk: ["5BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5BEU",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "EU 5",
                      display_with_system_and_set: "EU 5 (Boy)",
                      equivalent_sizes: {
                        us: ["5B"],
                        au: ["5BAU"],
                        uk: ["5BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6BEU",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "EU 6",
                      display_with_system_and_set: "EU 6 (Boy)",
                      equivalent_sizes: {
                        us: ["6B"],
                        au: ["6BAU"],
                        uk: ["6BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "7XBEU",
                      short: "7X (Boy)",
                      long: "7X",
                      display: "7X",
                      display_with_size_set: "7X (Boy)",
                      display_with_size_system: "EU 7X",
                      display_with_system_and_set: "EU 7X (Boy)",
                      equivalent_sizes: {
                        us: ["7XB"],
                        au: ["8BAU"],
                        uk: ["8BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "7BEU",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "EU 7",
                      display_with_system_and_set: "EU 7 (Boy)",
                      equivalent_sizes: {
                        us: ["7B"],
                        au: ["7BAU"],
                        uk: ["7BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "8BEU",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "EU 8",
                      display_with_system_and_set: "EU 8 (Boy)",
                      equivalent_sizes: {
                        us: ["8B"],
                        au: ["8BAU"],
                        uk: ["8BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "10BEU",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "EU 10",
                      display_with_system_and_set: "EU 10 (Boy)",
                      equivalent_sizes: {
                        us: ["10B"],
                        au: ["10BAU"],
                        uk: ["10BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12BEU",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "EU 12",
                      display_with_system_and_set: "EU 12 (Boy)",
                      equivalent_sizes: {
                        us: ["12B"],
                        au: ["12BAU"],
                        uk: ["12BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "14BEU",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "EU 14",
                      display_with_system_and_set: "EU 14 (Boy)",
                      equivalent_sizes: {
                        us: ["14B"],
                        au: ["14BAU"],
                        uk: ["14BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "16BEU",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "EU 16",
                      display_with_system_and_set: "EU 16 (Boy)",
                      equivalent_sizes: {
                        us: ["16B"],
                        au: ["16BAU"],
                        uk: ["16BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18BEU",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "EU 18",
                      display_with_system_and_set: "EU 18 (Boy)",
                      equivalent_sizes: {
                        us: ["18B"],
                        au: ["18BAU"],
                        uk: ["18BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "20BEU",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "EU 20",
                      display_with_system_and_set: "EU 20 (Boy)",
                      equivalent_sizes: {
                        us: ["20B"],
                        au: ["20BAU"],
                        uk: ["20BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSBEU",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Boy)",
                      equivalent_sizes: {
                        us: ["XSB"],
                        au: ["XSBAU"],
                        uk: ["XSBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SBEU",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Boy)",
                      equivalent_sizes: {
                        us: ["SB"],
                        au: ["SBAU"],
                        uk: ["SBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MBEU",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Boy)",
                      equivalent_sizes: {
                        us: ["MB"],
                        au: ["MBAU"],
                        uk: ["MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LBEU",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Boy)",
                      equivalent_sizes: {
                        us: ["LB"],
                        au: ["LBAU"],
                        uk: ["LBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLBEU",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Boy)",
                      equivalent_sizes: {
                        us: ["XLB"],
                        au: ["XLBAU"],
                        uk: ["XLBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLBEU",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Boy)",
                      equivalent_sizes: {
                        us: ["XXLB"],
                        au: ["XXLBAU"],
                        uk: ["XXLBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "3BUK",
                      short: "3 (Boy)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Boy)",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3 (Boy)",
                      equivalent_sizes: {
                        us: ["2TB", "3TB"],
                        au: ["3BAU"],
                        eu: ["2ABEU", "3ABEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4BUK",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Boy)",
                      equivalent_sizes: {
                        us: ["4TB", "4B"],
                        au: ["4BAU"],
                        eu: ["4ABEU", "4BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5BUK",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "UK 5",
                      display_with_system_and_set: "UK 5 (Boy)",
                      equivalent_sizes: {
                        us: ["5TB", "5B"],
                        au: ["5BAU"],
                        eu: ["5ABEU", "5BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6BUK",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Boy)",
                      equivalent_sizes: {
                        us: ["6B"],
                        au: ["6BAU"],
                        eu: ["6BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "7BUK",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "UK 7",
                      display_with_system_and_set: "UK 7 (Boy)",
                      equivalent_sizes: {
                        us: ["7B"],
                        au: ["7BAU"],
                        eu: ["7BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8BUK",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Boy)",
                      equivalent_sizes: {
                        us: ["7XB", "8B"],
                        au: ["8BAU"],
                        eu: ["7XBEU", "8BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10BUK",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Boy)",
                      equivalent_sizes: {
                        us: ["10B"],
                        au: ["10BAU"],
                        eu: ["10BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12BUK",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Boy)",
                      equivalent_sizes: {
                        us: ["12B"],
                        au: ["12BAU"],
                        eu: ["12BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14BUK",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Boy)",
                      equivalent_sizes: {
                        us: ["14B"],
                        au: ["14BAU"],
                        eu: ["14BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16BUK",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Boy)",
                      equivalent_sizes: {
                        us: ["16B"],
                        au: ["16BAU"],
                        eu: ["16BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18BUK",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Boy)",
                      equivalent_sizes: {
                        us: ["18B"],
                        au: ["18BAU"],
                        eu: ["18BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20BUK",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Boy)",
                      equivalent_sizes: {
                        us: ["20B"],
                        au: ["20BAU"],
                        eu: ["20BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSBUK",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Boy)",
                      equivalent_sizes: {
                        us: ["XSB"],
                        au: ["XSBAU"],
                        eu: ["XSBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SBUK",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Boy)",
                      equivalent_sizes: {
                        us: ["SB"],
                        au: ["SBAU"],
                        eu: ["SBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MBUK",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Boy)",
                      equivalent_sizes: {
                        us: ["MB"],
                        au: ["MBAU"],
                        eu: ["MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LBUK",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Boy)",
                      equivalent_sizes: {
                        us: ["LB"],
                        au: ["LBAU"],
                        eu: ["LBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLBUK",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Boy)",
                      equivalent_sizes: {
                        us: ["XLB"],
                        au: ["XLBAU"],
                        eu: ["XLBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLBUK",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Boy)",
                      equivalent_sizes: {
                        us: ["XXLB"],
                        au: ["XXLBAU"],
                        eu: ["XXLBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "kids_pajamas",
          aliases: [],
          slug: "Pajamas",
          alias_slugs: [],
          parent_id: "20008c10d97b4e1245005764",
          category_features: [
            {
              id: "7d009813d97b4e3995005764",
              display: "Nightgowns",
              type: "cf",
              message_id: "kids_pajamas_nightgowns",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Nightgowns",
              alias_slugs: [],
              parent_id: "27008c10d97b4e1245005764",
            },
            {
              id: "80009813d97b4e3995005764",
              display: "Pajama Bottoms",
              type: "cf",
              message_id: "kids_pajamas_pajama_bottoms",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Pajama_Bottoms",
              alias_slugs: [],
              parent_id: "27008c10d97b4e1245005764",
            },
            {
              id: "18002f3cd97b4edf70005784",
              display: "Pajama Sets",
              type: "cf",
              message_id: "kids_pajamas_pajama_sets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Pajama_Sets",
              alias_slugs: [],
              parent_id: "27008c10d97b4e1245005764",
            },
            {
              id: "7f009813d97b4e3995005764",
              display: "Pajama Tops",
              type: "cf",
              message_id: "kids_pajamas_pajama_tops",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Pajama_Tops",
              alias_slugs: [],
              parent_id: "27008c10d97b4e1245005764",
            },
            {
              id: "81009813d97b4e3995005764",
              display: "Robes",
              type: "cf",
              message_id: "kids_pajamas_robes",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Robes",
              alias_slugs: [],
              parent_id: "27008c10d97b4e1245005764",
            },
            {
              id: "82009813d97b4e3995005764",
              display: "Sleep Sacks",
              type: "cf",
              message_id: "kids_pajamas_sleep_sacks",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sleep_Sacks",
              alias_slugs: [],
              parent_id: "27008c10d97b4e1245005764",
            },
          ],
        },
        {
          id: "2e008c10d97b4e1245005764",
          display: "Shirts & Tops",
          size_sets: [
            {
              id: "11003667d97b4e6bdb005784",
              name: "Baby",
              tags: ["baby"],
              category_id: "2e008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["14.5", "0-18", "15", "15"],
                  ["16", "0-18", "16.5", "16.5"],
                  ["17", "18-23", "17.5", "17.5"],
                  ["18", "23-27", "18.5", "18.5"],
                  ["18.5", "27-28", "19", "19"],
                  ["19", "28-29", "19.5", "19.5"],
                  ["19.75", "29-31", "20.25", "20.25"],
                  ["20.5", "31-33", "21", "21"],
                  ["17", "18-23", "17.5", "17.5"],
                  ["18", "23-27", "18.5", "18.5"],
                  ["18.5", "27-28", "19", "19"],
                  ["19", "28-29", "19.5", "19.5"],
                  ["19.75", "29-31", "20.25", "20.25"],
                  ["20.5", "31-33", "21", "21"],
                ],
                centimeters: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["36.8", "0-45.7", "38.1", "38.1"],
                  ["40.6", "0-45.7", "41.9", "41.9"],
                  ["43.2", "45.7-58.4", "44.5", "44.5"],
                  ["45.7", "58.4-68.6", "47.0", "47.0"],
                  ["47.0", "68.6-71.1", "48.3", "48.3"],
                  ["48.3", "71.1-73.7", "49.5", "49.5"],
                  ["50.2", "73.7-78.7", "51.4", "51.4"],
                  ["52.1", "78.7-83.8", "53.3", "53.3"],
                  ["43.2", "45.7-58.4", "44.5", "44.5"],
                  ["45.7", "58.4-68.6", "47.0", "47.0"],
                  ["47.0", "68.6-71.1", "48.3", "48.3"],
                  ["48.3", "71.1-73.7", "49.5", "49.5"],
                  ["50.2", "73.7-78.7", "51.4", "51.4"],
                  ["52.1", "78.7-83.8", "53.3", "53.3"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["Preemie", "00000", "Preemie", "Preemie"],
                  ["Newborn", "00000", "Newborn", "Newborn"],
                  ["0-3 Months", "0000", "0-3 Months", "0-3 Months"],
                  ["3-6 Months", "000", "3-6 Months", "3-6 Months"],
                  ["6-9 Months", "000", "6-9 Months", "6-9 Months"],
                  ["9-12 Months", "00", "9-12 Months", "9-12 Months"],
                  ["12-18 Months", "0", "12-18 Months", "12-18 Months"],
                  ["18-24 Months", "1", "18-24 Months", "18-24 Months"],
                  ["3 Months", "0000,000", "3 Months", "3 Months"],
                  ["6 Months", "000", "6 Months", "6 Months"],
                  ["9 Months", "000,00", "9 Months", "9 Months"],
                  ["12 Months", "00,0", "12 Months", "12 Months"],
                  ["18 Months", "0,1", "18 Months", "18 Months"],
                  ["24 Months", "1,2", "24 Months", "24 Months"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["Preemie", "00000", "Preemie", "Preemie"],
                ["Newborn", "00000", "Newborn", "Newborn"],
                ["0-3 Months", "0000", "0-3 Months", "0-3 Months"],
                ["3-6 Months", "000", "3-6 Months", "3-6 Months"],
                ["6-9 Months", "000", "6-9 Months", "6-9 Months"],
                ["9-12 Months", "00", "9-12 Months", "9-12 Months"],
                ["12-18 Months", "0", "12-18 Months", "12-18 Months"],
                ["18-24 Months", "1", "18-24 Months", "18-24 Months"],
                ["3 Months", "0000,000", "3 Months", "3 Months"],
                ["6 Months", "000", "6 Months", "6 Months"],
                ["9 Months", "000,00", "9 Months", "9 Months"],
                ["12 Months", "00,0", "12 Months", "12 Months"],
                ["18 Months", "0,1", "18 Months", "18 Months"],
                ["24 Months", "1,2", "24 Months", "24 Months"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "Preemie",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "US Preemie",
                      display_with_system_and_set: "US Preemie",
                      equivalent_sizes: {
                        au: ["00000MBAU"],
                        eu: ["PreemieEU"],
                        uk: ["PreemieUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "Newborn",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "US Newborn",
                      display_with_system_and_set: "US Newborn",
                      equivalent_sizes: {
                        au: ["00000MBAU"],
                        eu: ["NewbornEU"],
                        uk: ["NewbornUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0-3MB",
                      short: "0-3M",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3M",
                      display_with_size_system: "US 0-3",
                      display_with_system_and_set: "US 0-3M",
                      equivalent_sizes: {
                        au: ["0000MBAU"],
                        eu: ["0-3MBEU"],
                        uk: ["0-3MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3-6MB",
                      short: "3-6M",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6M",
                      display_with_size_system: "US 3-6",
                      display_with_system_and_set: "US 3-6M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["3-6MBEU"],
                        uk: ["3-6MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6-9MB",
                      short: "6-9M",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9M",
                      display_with_size_system: "US 6-9",
                      display_with_system_and_set: "US 6-9M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["6-9MBEU"],
                        uk: ["6-9MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9-12MB",
                      short: "9-12M",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12M",
                      display_with_size_system: "US 9-12",
                      display_with_system_and_set: "US 9-12M",
                      equivalent_sizes: {
                        au: ["00MBAU"],
                        eu: ["9-12MBEU"],
                        uk: ["9-12MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12-18MB",
                      short: "12-18M",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18M",
                      display_with_size_system: "US 12-18",
                      display_with_system_and_set: "US 12-18M",
                      equivalent_sizes: {
                        au: ["0MBAU"],
                        eu: ["12-18MBEU"],
                        uk: ["12-18MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18-24MB",
                      short: "18-24M",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24M",
                      display_with_size_system: "US 18-24",
                      display_with_system_and_set: "US 18-24M",
                      equivalent_sizes: {
                        au: ["1MBAU"],
                        eu: ["18-24MBEU"],
                        uk: ["18-24MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3MB",
                      short: "3M",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3M",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3M",
                      equivalent_sizes: {
                        au: ["0000MBAU", "000MBAU"],
                        eu: ["3MBEU"],
                        uk: ["3MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6MB",
                      short: "6M",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6M",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["6MBEU"],
                        uk: ["6MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9MB",
                      short: "9M",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9M",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9M",
                      equivalent_sizes: {
                        au: ["000MBAU", "00MBAU"],
                        eu: ["9MBEU"],
                        uk: ["9MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12MB",
                      short: "12M",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12M",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12M",
                      equivalent_sizes: {
                        au: ["00MBAU", "0MBAU"],
                        eu: ["12MBEU"],
                        uk: ["12MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18MB",
                      short: "18M",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18M",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18M",
                      equivalent_sizes: {
                        au: ["0MBAU", "1MBAU"],
                        eu: ["18MBEU"],
                        uk: ["18MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24MB",
                      short: "24M",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24M",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24M",
                      equivalent_sizes: {
                        au: ["1MBAU", "2MBAU"],
                        eu: ["24MBEU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "00000MBAU",
                      short: "00000",
                      long: "00000",
                      display: "00000",
                      display_with_size_set: "00000",
                      display_with_size_system: "AU 00000",
                      display_with_system_and_set: "AU 00000",
                      equivalent_sizes: {
                        us: ["Preemie ", "Newborn"],
                        eu: ["PreemieEU", "NewbornEU"],
                        uk: ["PreemieUK", "NewbornUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0000MBAU",
                      short: "0000",
                      long: "0000",
                      display: "0000",
                      display_with_size_set: "0000",
                      display_with_size_system: "AU 0000",
                      display_with_system_and_set: "AU 0000",
                      equivalent_sizes: {
                        us: ["0-3MB", "3MB"],
                        eu: ["0-3MBEU", "3MBEU"],
                        uk: ["0-3MBUK", "3MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "000MBAU",
                      short: "000",
                      long: "000",
                      display: "000",
                      display_with_size_set: "000",
                      display_with_size_system: "AU 000",
                      display_with_system_and_set: "AU 000",
                      equivalent_sizes: {
                        us: ["3MB", "6MB", "9MB", "3-6MB", "6-9MB"],
                        eu: ["3MBEU", "6MBEU", "9MBEU", "3-6MBEU", "6-9MBEU"],
                        uk: ["3MBUK", "6MBUK", "9MBUK", "3-6MBUK", "6-9MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "00MBAU",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "AU 00",
                      display_with_system_and_set: "AU 00",
                      equivalent_sizes: {
                        us: ["9MB", "12MB", "9-12MB"],
                        eu: ["9MBEU", "12MBEU", "9-12MBEU"],
                        uk: ["9MBUK", "12MBUK", "9-12MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0MBAU",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "AU 0",
                      display_with_system_and_set: "AU 0",
                      equivalent_sizes: {
                        us: ["12MB", "18MB", "12-18MB"],
                        eu: ["12MBEU", "18MBEU", "12-18MBEU"],
                        uk: ["12MBUK", "18MBUK", "12-18MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1MBAU",
                      short: "1",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1",
                      display_with_size_system: "AU 1",
                      display_with_system_and_set: "AU 1",
                      equivalent_sizes: {
                        us: ["18MB", "24MB", "18-24MB"],
                        eu: ["18MBEU", "24MBEU", "18-24MBEU"],
                        uk: ["18MBUK", "24MBUK", "18-24MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2MBAU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["24MB"],
                        eu: ["24MBEU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "PreemieEU",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "EU Preemie",
                      display_with_system_and_set: "EU Preemie",
                      equivalent_sizes: {
                        us: ["Preemie"],
                        au: ["00000MBAU"],
                        uk: ["PreemieUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "NewbornEU",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "EU Newborn",
                      display_with_system_and_set: "EU Newborn",
                      equivalent_sizes: {
                        us: ["Newborn"],
                        au: ["00000MBAU"],
                        uk: ["NewbornUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0-3MBEU",
                      short: "0-3",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3",
                      display_with_size_system: "EU 0-3",
                      display_with_system_and_set: "EU 0-3",
                      equivalent_sizes: {
                        us: ["0-3MB"],
                        au: ["0000MBAU"],
                        uk: ["0-3MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3-6MBEU",
                      short: "3-6",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6",
                      display_with_size_system: "EU 3-6",
                      display_with_system_and_set: "EU 3-6",
                      equivalent_sizes: {
                        us: ["3-6MB"],
                        au: ["000MBAU"],
                        uk: ["3-6MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6-9MBEU",
                      short: "6-9",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9",
                      display_with_size_system: "EU 6-9",
                      display_with_system_and_set: "EU 6-9",
                      equivalent_sizes: {
                        us: ["6-9MB"],
                        au: ["000MBAU"],
                        uk: ["6-9MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "9-12MBEU",
                      short: "9-12",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12",
                      display_with_size_system: "EU 9-12",
                      display_with_system_and_set: "EU 9-12",
                      equivalent_sizes: {
                        us: ["9-12MB"],
                        au: ["00MBAU"],
                        uk: ["9-12MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12-18MBEU",
                      short: "12-18",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18",
                      display_with_size_system: "EU 12-18",
                      display_with_system_and_set: "EU 12-18",
                      equivalent_sizes: {
                        us: ["12-18MB"],
                        au: ["0MBAU"],
                        uk: ["12-18MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18-24MBEU",
                      short: "18-24",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24",
                      display_with_size_system: "EU 18-24",
                      display_with_system_and_set: "EU 18-24",
                      equivalent_sizes: {
                        us: ["18-24MB"],
                        au: ["1MBAU"],
                        uk: ["18-24MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3MBEU",
                      short: "3",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3",
                      display_with_size_system: "EU 3",
                      display_with_system_and_set: "EU 3",
                      equivalent_sizes: {
                        us: ["3MB"],
                        au: ["0000MBAU", "000MBAU"],
                        uk: ["3MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6MBEU",
                      short: "6",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6",
                      display_with_size_system: "EU 6",
                      display_with_system_and_set: "EU 6",
                      equivalent_sizes: {
                        us: ["6MB"],
                        au: ["000MBAU"],
                        uk: ["6MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "9MBEU",
                      short: "9",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9",
                      display_with_size_system: "EU 9",
                      display_with_system_and_set: "EU 9",
                      equivalent_sizes: {
                        us: ["9MB"],
                        au: ["000MBAU", "00MBAU"],
                        uk: ["9MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12MBEU",
                      short: "12",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12",
                      display_with_size_system: "EU 12",
                      display_with_system_and_set: "EU 12",
                      equivalent_sizes: {
                        us: ["12MB"],
                        au: ["00MBAU", "0MBAU"],
                        uk: ["12MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18MBEU",
                      short: "18",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18",
                      display_with_size_system: "EU 18",
                      display_with_system_and_set: "EU 18",
                      equivalent_sizes: {
                        us: ["18MB"],
                        au: ["0MBAU", "1MBAU"],
                        uk: ["18MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "24MBEU",
                      short: "24",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24",
                      display_with_size_system: "EU 24",
                      display_with_system_and_set: "EU 24",
                      equivalent_sizes: {
                        us: ["24MB"],
                        au: ["1MBAU", "2MBAU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "PreemieUK",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "UK Preemie",
                      display_with_system_and_set: "UK Preemie",
                      equivalent_sizes: {
                        us: ["Preemie"],
                        au: ["00000MBAU"],
                        eu: ["PreemieEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "NewbornUK",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "UK Newborn",
                      display_with_system_and_set: "UK Newborn",
                      equivalent_sizes: {
                        us: ["Newborn"],
                        au: ["00000MBAU"],
                        eu: ["NewbornEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0-3MBUK",
                      short: "0-3M",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3M",
                      display_with_size_system: "UK 0-3",
                      display_with_system_and_set: "UK 0-3M",
                      equivalent_sizes: {
                        us: ["0-3MB"],
                        au: ["0000MBAU"],
                        eu: ["0-3MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3-6MBUK",
                      short: "3-6M",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6M",
                      display_with_size_system: "UK 3-6",
                      display_with_system_and_set: "UK 3-6M",
                      equivalent_sizes: {
                        us: ["3-6MB"],
                        au: ["000MBAU"],
                        eu: ["3-6MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6-9MBUK",
                      short: "6-9M",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9M",
                      display_with_size_system: "UK 6-9",
                      display_with_system_and_set: "UK 6-9M",
                      equivalent_sizes: {
                        us: ["6-9MB"],
                        au: ["000MBAU"],
                        eu: ["6-9MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9-12MBUK",
                      short: "9-12M",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12M",
                      display_with_size_system: "UK 9-12",
                      display_with_system_and_set: "UK 9-12M",
                      equivalent_sizes: {
                        us: ["9-12MB"],
                        au: ["00MBAU"],
                        eu: ["9-12MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12-18MBUK",
                      short: "12-18M",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18M",
                      display_with_size_system: "UK 12-18",
                      display_with_system_and_set: "UK 12-18M",
                      equivalent_sizes: {
                        us: ["12-18MB"],
                        au: ["0MBAU"],
                        eu: ["12-18MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18-24MBUK",
                      short: "18-24M",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24M",
                      display_with_size_system: "UK 18-24",
                      display_with_system_and_set: "UK 18-24M",
                      equivalent_sizes: {
                        us: ["18-24MB"],
                        au: ["1MBAU"],
                        eu: ["18-24MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3MBUK",
                      short: "3M",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3M",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3M",
                      equivalent_sizes: {
                        us: ["3MB"],
                        au: ["0000MBAU", "000MBAU"],
                        eu: ["3MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MBUK",
                      short: "6M",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6M",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6M",
                      equivalent_sizes: {
                        us: ["6MB"],
                        au: ["000MBAU"],
                        eu: ["6MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9MBUK",
                      short: "9M",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9M",
                      display_with_size_system: "UK 9",
                      display_with_system_and_set: "UK 9M",
                      equivalent_sizes: {
                        us: ["9MB"],
                        au: ["000MBAU", "00MBAU"],
                        eu: ["9MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MBUK",
                      short: "12M",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12M",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12M",
                      equivalent_sizes: {
                        us: ["12MB"],
                        au: ["00MBAU", "0MBAU"],
                        eu: ["12MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MBUK",
                      short: "18M",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18M",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18M",
                      equivalent_sizes: {
                        us: ["18MB"],
                        au: ["0MBAU", "1MBAU"],
                        eu: ["18MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MBUK",
                      short: "24M",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24M",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24M",
                      equivalent_sizes: {
                        us: ["24MB"],
                        au: ["1MBAU", "2MBAU"],
                        eu: ["24MBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "07009fc1d97b4e911e005769",
              name: "Girls",
              tags: ["girls"],
              category_id: "2e008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["20.5", "33.5-35.5", "21", "21"],
                  ["21", "36-38", "21.5", "22"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["24", "45-46.5", "23", "25"],
                  ["25.5", "47-49.5", "23.5", "26.5"],
                  ["26", "49-50.5", "24", "27.5"],
                  ["27", "51-52.5", "24.5", "28.5"],
                  ["28.5", "53-55", "25", "30"],
                  ["30", "55.5-58", "26", "31.5"],
                  ["31.5", "58.5-61", "27.5", "33"],
                  ["33", "61.5-62.5", "29", "34.5"],
                  ["22.0-23.0", "38.5-44.5", "22.0-22.5", "23.0-24.0"],
                  ["24.0-26.0", "45-50.5", "23.0-24.0", "25.0-27.5"],
                  ["27", "51-52.5", "24.5", "28.5"],
                  ["28.5", "53-55", "25", "30"],
                  ["30", "55.5-58", "26", "31.5"],
                  ["31.5-33.0", "58.5-62.5", "27.5-29.0", "33-34.5"],
                ],
                centimeters: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["52.1", "85.1-90.2", "53.3", "53.3"],
                  ["53.3", "91.4-96.5", "54.6", "55.9"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["61", "114.3-118.1", "58.4", "63.5"],
                  ["64.8", "119.4-125.7", "59.7", "67.3"],
                  ["66", "125.7-128.3", "61", "69.9"],
                  ["68.6", "129.5-133.4", "62.2", "72.4"],
                  ["72.4", "134.6-139.7", "63.5", "76.2"],
                  ["76.2", "141.0-147.3", "66", "80"],
                  ["80", "148.6-154.9", "69.9", "83.8"],
                  ["83.8", "156.2-158.8", "73.7", "87.6"],
                  ["55.9-58.4", "97.8-113.0", "55.9-57.2", "58.4-61.0"],
                  ["61.0-66.0", "114.3-128.3", "58.4-61.0", "63.5-69.9"],
                  ["68.6", "129.5-133.4", "62.2", "72.4"],
                  ["72.4", "134.6-139.7", "63.5", "76.2"],
                  ["76.2", "141.0-147.3", "66", "80"],
                  ["80.0-83.8", "148.6-158.8", "69.9-73.7", "83.8-87.6"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["6", "6", "6", "6"],
                  ["6X,7", "7", "6X,7", "7"],
                  ["6X,7", "7", "6X,7", "7"],
                  ["8", "8", "8", "8"],
                  ["10", "10", "10", "10"],
                  ["12", "12", "12", "12"],
                  ["14", "14", "14", "14"],
                  ["16", "16", "16", "16"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["2T", "3", "2A", "3"],
                ["3T", "3", "3A", "3"],
                ["4T", "4", "4A", "4"],
                ["5T", "5", "5A", "5"],
                ["4", "4", "4", "4"],
                ["5", "5", "5", "5"],
                ["6", "6", "6", "6"],
                ["6X", "7", "6X", "7"],
                ["7", "7", "7", "7"],
                ["8", "8", "8", "8"],
                ["10", "10", "10", "10"],
                ["12", "12", "12", "12"],
                ["14", "14", "14", "14"],
                ["16", "16", "16", "16"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "2TG",
                      short: "2T (Girl)",
                      long: "2T",
                      display: "2T",
                      display_with_size_set: "2T (Girl)",
                      display_with_size_system: "US 2T",
                      display_with_system_and_set: "US 2T (Girl)",
                      equivalent_sizes: {
                        au: ["3GAU"],
                        eu: ["2AGEU"],
                        uk: ["3GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3TG",
                      short: "3T (Girl)",
                      long: "3T",
                      display: "3T",
                      display_with_size_set: "3T (Girl)",
                      display_with_size_system: "US 3T",
                      display_with_system_and_set: "US 3T (Girl)",
                      equivalent_sizes: {
                        au: ["3GAU"],
                        eu: ["3AGEU"],
                        uk: ["3GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4TG",
                      short: "4T (Girl)",
                      long: "4T",
                      display: "4T",
                      display_with_size_set: "4T (Girl)",
                      display_with_size_system: "US 4T",
                      display_with_system_and_set: "US 4T (Girl)",
                      equivalent_sizes: {
                        au: ["4GAU"],
                        eu: ["4AGEU"],
                        uk: ["4GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5TG",
                      short: "5T (Girl)",
                      long: "5T",
                      display: "5T",
                      display_with_size_set: "5T (Girl)",
                      display_with_size_system: "US 5T",
                      display_with_system_and_set: "US 5T (Girl)",
                      equivalent_sizes: {
                        au: ["5GAU"],
                        eu: ["5AGEU"],
                        uk: ["5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4G",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Girl)",
                      equivalent_sizes: {
                        au: ["4GAU"],
                        eu: ["4GEU"],
                        uk: ["4GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5G",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Girl)",
                      equivalent_sizes: {
                        au: ["5GAU"],
                        eu: ["5GEU"],
                        uk: ["5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6G",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Girl)",
                      equivalent_sizes: {
                        au: ["6GAU"],
                        eu: ["6GEU"],
                        uk: ["6GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XG",
                      short: "6X (Girl)",
                      long: "6X",
                      display: "6X",
                      display_with_size_set: "6X (Girl)",
                      display_with_size_system: "US 6X",
                      display_with_system_and_set: "US 6X (Girl)",
                      equivalent_sizes: {
                        au: ["7GAU"],
                        eu: ["6XGEU"],
                        uk: ["7GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7G",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Girl)",
                      equivalent_sizes: {
                        au: ["7GAU"],
                        eu: ["7GEU"],
                        uk: ["7GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8G",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Girl)",
                      equivalent_sizes: {
                        au: ["8GAU"],
                        eu: ["8GEU"],
                        uk: ["8GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10G",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Girl)",
                      equivalent_sizes: {
                        au: ["10GAU"],
                        eu: ["10GEU"],
                        uk: ["10GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12G",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Girl)",
                      equivalent_sizes: {
                        au: ["12GAU"],
                        eu: ["12GEU"],
                        uk: ["12GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14G",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Girl)",
                      equivalent_sizes: {
                        au: ["14GAU"],
                        eu: ["14GEU"],
                        uk: ["14GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16G",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Girl)",
                      equivalent_sizes: {
                        au: ["16GAU"],
                        eu: ["16GEU"],
                        uk: ["16GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSG",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Girl)",
                      equivalent_sizes: {
                        au: ["XSGAU"],
                        eu: ["XSGEU"],
                        uk: ["XSGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SG",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Girl)",
                      equivalent_sizes: {
                        au: ["SGAU"],
                        eu: ["SGEU"],
                        uk: ["SGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MG",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Girl)",
                      equivalent_sizes: {
                        au: ["MGAU"],
                        eu: ["MGEU"],
                        uk: ["MGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LG",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Girl)",
                      equivalent_sizes: {
                        au: ["LGAU"],
                        eu: ["LGEU"],
                        uk: ["LGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLG",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Girl)",
                      equivalent_sizes: {
                        au: ["XLGAU"],
                        eu: ["XLGEU"],
                        uk: ["XLGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLG",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Girl)",
                      equivalent_sizes: {
                        au: ["XXLGAU"],
                        eu: ["XXLGEU"],
                        uk: ["XXLGUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "3GAU",
                      short: "3 (Girl)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Girl)",
                      display_with_size_system: "AU 3",
                      display_with_system_and_set: "AU 3 (Girl)",
                      equivalent_sizes: {
                        us: ["2TG", "3TG"],
                        eu: ["2AGEU", "3AGEU"],
                        uk: ["3GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4GAU",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Girl)",
                      equivalent_sizes: {
                        us: ["4TG", "4G"],
                        eu: ["4AGEU", "4GEU"],
                        uk: ["4GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5GAU",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "AU 5",
                      display_with_system_and_set: "AU 5 (Girl)",
                      equivalent_sizes: {
                        us: ["5TG", "5G"],
                        eu: ["5AGEU", "5GEU"],
                        uk: ["5GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6GAU",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Girl)",
                      equivalent_sizes: {
                        us: ["6G"],
                        eu: ["6GEU"],
                        uk: ["6GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "7GAU",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "AU 7",
                      display_with_system_and_set: "AU 7 (Girl)",
                      equivalent_sizes: {
                        us: ["6XG", "7G"],
                        eu: ["6XGEU", "7GEU"],
                        uk: ["7GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8GAU",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Girl)",
                      equivalent_sizes: {
                        us: ["8G"],
                        eu: ["8GEU"],
                        uk: ["8GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10GAU",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Girl)",
                      equivalent_sizes: {
                        us: ["10G"],
                        eu: ["10GEU"],
                        uk: ["10GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12GAU",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Girl)",
                      equivalent_sizes: {
                        us: ["12G"],
                        eu: ["12GEU"],
                        uk: ["12GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14GAU",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Girl)",
                      equivalent_sizes: {
                        us: ["14G"],
                        eu: ["14GEU"],
                        uk: ["14GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16GAU",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Girl)",
                      equivalent_sizes: {
                        us: ["16G"],
                        eu: ["16GEU"],
                        uk: ["16GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSGAU",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Girl)",
                      equivalent_sizes: {
                        us: ["XSG"],
                        eu: ["XSGEU"],
                        uk: ["XSGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SGAU",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Girl)",
                      equivalent_sizes: {
                        us: ["SG"],
                        eu: ["SGEU"],
                        uk: ["SGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MGAU",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Girl)",
                      equivalent_sizes: {
                        us: ["MG"],
                        eu: ["MGEU"],
                        uk: ["MGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LGAU",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Girl)",
                      equivalent_sizes: {
                        us: ["LG"],
                        eu: ["LGEU"],
                        uk: ["LGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLGAU",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Girl)",
                      equivalent_sizes: {
                        us: ["XLG"],
                        eu: ["XLGEU"],
                        uk: ["XLGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLGAU",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Girl)",
                      equivalent_sizes: {
                        us: ["XXLG"],
                        eu: ["XXLGEU"],
                        uk: ["XXLGUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "2AGEU",
                      short: "2A (Girl)",
                      long: "2A",
                      display: "2A",
                      display_with_size_set: "2A (Girl)",
                      display_with_size_system: "EU 2A",
                      display_with_system_and_set: "EU 2A (Girl)",
                      equivalent_sizes: {
                        us: ["2TG"],
                        au: ["3GAU"],
                        uk: ["3GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3AGEU",
                      short: "3A (Girl)",
                      long: "3A",
                      display: "3A",
                      display_with_size_set: "3A (Girl)",
                      display_with_size_system: "EU 3A",
                      display_with_system_and_set: "EU 3A (Girl)",
                      equivalent_sizes: {
                        us: ["3TG"],
                        au: ["3GAU"],
                        uk: ["3GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4AGEU",
                      short: "4A (Girl)",
                      long: "4A",
                      display: "4A",
                      display_with_size_set: "4A (Girl)",
                      display_with_size_system: "EU 4A",
                      display_with_system_and_set: "EU 4A (Girl)",
                      equivalent_sizes: {
                        us: ["4TG"],
                        au: ["4GAU"],
                        uk: ["4GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4GEU",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "EU 4",
                      display_with_system_and_set: "EU 4 (Girl)",
                      equivalent_sizes: {
                        us: ["4G"],
                        au: ["4GAU"],
                        uk: ["4GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5AGEU",
                      short: "5A (Girl)",
                      long: "5A",
                      display: "5A",
                      display_with_size_set: "5A (Girl)",
                      display_with_size_system: "EU 5A",
                      display_with_system_and_set: "EU 5A (Girl)",
                      equivalent_sizes: {
                        us: ["5TG"],
                        au: ["5GAU"],
                        uk: ["5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5GEU",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "EU 5",
                      display_with_system_and_set: "EU 5 (Girl)",
                      equivalent_sizes: {
                        us: ["5G"],
                        au: ["5GAU"],
                        uk: ["5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XGEU",
                      short: "6X (Girl)",
                      long: "6X",
                      display: "6X",
                      display_with_size_set: "6X (Girl)",
                      display_with_size_system: "EU 6X",
                      display_with_system_and_set: "EU 6X (Girl)",
                      equivalent_sizes: {
                        us: ["6XG"],
                        au: ["7GAU"],
                        uk: ["7GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6GEU",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "EU 6",
                      display_with_system_and_set: "EU 6 (Girl)",
                      equivalent_sizes: {
                        us: ["6G"],
                        au: ["6GAU"],
                        uk: ["6GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "7GEU",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "EU 7",
                      display_with_system_and_set: "EU 7 (Girl)",
                      equivalent_sizes: {
                        us: ["7G"],
                        au: ["7GAU"],
                        uk: ["7GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "8GEU",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "EU 8",
                      display_with_system_and_set: "EU 8 (Girl)",
                      equivalent_sizes: {
                        us: ["8G"],
                        au: ["8GAU"],
                        uk: ["8GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "10GEU",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "EU 10",
                      display_with_system_and_set: "EU 10 (Girl)",
                      equivalent_sizes: {
                        us: ["10G"],
                        au: ["10GAU"],
                        uk: ["10GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12GEU",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "EU 12",
                      display_with_system_and_set: "EU 12 (Girl)",
                      equivalent_sizes: {
                        us: ["12G"],
                        au: ["12GAU"],
                        uk: ["12GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "14GEU",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "EU 14",
                      display_with_system_and_set: "EU 14 (Girl)",
                      equivalent_sizes: {
                        us: ["14G"],
                        au: ["14GAU"],
                        uk: ["14GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "16GEU",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "EU 16",
                      display_with_system_and_set: "EU 16 (Girl)",
                      equivalent_sizes: {
                        us: ["16G"],
                        au: ["16GAU"],
                        uk: ["16GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSGEU",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Girl)",
                      equivalent_sizes: {
                        us: ["XSG"],
                        au: ["XSGAU"],
                        uk: ["XSGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SGEU",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Girl)",
                      equivalent_sizes: {
                        us: ["SG"],
                        au: ["SGAU"],
                        uk: ["SGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MGEU",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Girl)",
                      equivalent_sizes: {
                        us: ["MG"],
                        au: ["MGAU"],
                        uk: ["MGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LGEU",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Girl)",
                      equivalent_sizes: {
                        us: ["LG"],
                        au: ["LGAU"],
                        uk: ["LGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLGEU",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Girl)",
                      equivalent_sizes: {
                        us: ["XLG"],
                        au: ["XLGAU"],
                        uk: ["XLGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLGEU",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Girl)",
                      equivalent_sizes: {
                        us: ["XXLG"],
                        au: ["XXLGAU"],
                        uk: ["XXLGUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "3GUK",
                      short: "3 (Girl)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Girl)",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3 (Girl)",
                      equivalent_sizes: {
                        us: ["2TG", "3TG"],
                        au: ["3GAU"],
                        eu: ["2AGEU", "3AGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4GUK",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Girl)",
                      equivalent_sizes: {
                        us: ["4TG", "4G"],
                        au: ["4GAU"],
                        eu: ["4AGEU", "4GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5GUK",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "UK 5",
                      display_with_system_and_set: "UK 5 (Girl)",
                      equivalent_sizes: {
                        us: ["5TG", "5G"],
                        au: ["5GAU"],
                        eu: ["5AGEU", "5GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6GUK",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Girl)",
                      equivalent_sizes: {
                        us: ["6G"],
                        au: ["6GAU"],
                        eu: ["6GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "7GUK",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "UK 7",
                      display_with_system_and_set: "UK 7 (Girl)",
                      equivalent_sizes: {
                        us: ["6XG", "7G"],
                        au: ["7GAU"],
                        eu: ["6XGEU", "7GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8GUK",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Girl)",
                      equivalent_sizes: {
                        us: ["8G"],
                        au: ["8GAU"],
                        eu: ["8GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10GUK",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Girl)",
                      equivalent_sizes: {
                        us: ["10G"],
                        au: ["10GAU"],
                        eu: ["10GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12GUK",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Girl)",
                      equivalent_sizes: {
                        us: ["12G"],
                        au: ["12GAU"],
                        eu: ["12GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14GUK",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Girl)",
                      equivalent_sizes: {
                        us: ["14G"],
                        au: ["14GAU"],
                        eu: ["14GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16GUK",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Girl)",
                      equivalent_sizes: {
                        us: ["16G"],
                        au: ["16GAU"],
                        eu: ["16GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSGUK",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Girl)",
                      equivalent_sizes: {
                        us: ["XSG"],
                        au: ["XSGAU"],
                        eu: ["XSGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SGUK",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Girl)",
                      equivalent_sizes: {
                        us: ["SG"],
                        au: ["SGAU"],
                        eu: ["SGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MGUK",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Girl)",
                      equivalent_sizes: {
                        us: ["MG"],
                        au: ["MGAU"],
                        eu: ["MGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LGUK",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Girl)",
                      equivalent_sizes: {
                        us: ["LG"],
                        au: ["LGAU"],
                        eu: ["LGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLGUK",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Girl)",
                      equivalent_sizes: {
                        us: ["XLG"],
                        au: ["XLGAU"],
                        eu: ["XLGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLGUK",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Girl)",
                      equivalent_sizes: {
                        us: ["XXLG"],
                        au: ["XXLGAU"],
                        eu: ["XXLGEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "08009fc1d97b4e911e005769",
              name: "Boys",
              tags: ["boys"],
              category_id: "2e008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["20.5", "33.5-35.5", "21", "21"],
                  ["21", "36-38", "21.5", "22"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["24", "45-46.5", "23", "25"],
                  ["25.5", "47-49.5", "23.5", "26.5"],
                  ["26.5", "50-52", "24.5", "27"],
                  ["27.5", "52.5-55.5", "25.5", "28"],
                  ["28.5", "56-57.5", "26.5", "29"],
                  ["30", "58-59.5", "28", "30.5"],
                  ["31.5", "60-61.5", "29.5", "32"],
                  ["33", "62-63.5", "30.5", "33"],
                  ["34.5", "64-65", "31.5", "34"],
                  ["22.0-23.0", "38.5-44.5", "22.0-22.5", "23.0-24.0"],
                  ["24.0-26.5", "45-52", "23.0-24.5", "25.0-27.0"],
                  ["26.5", "50-52", "24.5", "27"],
                  ["27.5", "52.5-55.5", "25.5", "28"],
                  ["28.5", "56-57.5", "26.5", "29"],
                  ["30.0-31.5", "58-61.5", "28.0-29.5", "30.5-32.0"],
                ],
                centimeters: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["52.1", "85.1-90.2", "53.3", "53.3"],
                  ["53.3", "91.4-96.5", "54.6", "55.9"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["61", "114.3-118.1", "58.4", "63.5"],
                  ["64.8", "119.4-125.7", "59.7", "67.3"],
                  ["67.3", "127-132.1", "62.2", "68.6"],
                  ["69.9", "133.4-141.0", "64.8", "71.1"],
                  ["72.4", "142.2-146.1", "67.3", "73.7"],
                  ["76.2", "147.3-151.1", "71.1", "77.5"],
                  ["80", "152.4-156.2", "74.9", "81.3"],
                  ["83.8", "157.5-161.3", "77.5", "83.8"],
                  ["87.6", "162.6-165.1", "80", "86.4"],
                  ["55.9-58.4", "97.8-113.0", "55.9-57.2", "58.4-61.0"],
                  ["61.0-67.3", "114.3-132.1", "58.4-62.2", "63.5-68.6"],
                  ["67.3", "127-132.1", "62.2", "68.6"],
                  ["69.9", "133.4-141.0", "64.8", "71.1"],
                  ["72.4", "142.2-146.1", "67.3", "73.7"],
                  ["76.2-80.0", "147.3-156.2", "71.1-74.9", "77.5-81.3"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["6", "6", "6", "6"],
                  ["7", "7", "7", "7"],
                  ["7X,8", "8", "7X,8", "8"],
                  ["10", "10", "10", "10"],
                  ["12", "12", "12", "12"],
                  ["14", "14", "14", "14"],
                  ["16", "16", "16", "16"],
                  ["18", "18", "18", "18"],
                  ["20", "20", "20", "20"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["2T", "3", "2A", "3"],
                ["3T", "3", "3A", "3"],
                ["4T", "4", "4A", "4"],
                ["5T", "5", "5A", "5"],
                ["4", "4", "4", "4"],
                ["5", "5", "5", "5"],
                ["6", "6", "6", "6"],
                ["7", "7", "7", "7"],
                ["7X", "8", "7X", "8"],
                ["8", "8", "8", "8"],
                ["10", "10", "10", "10"],
                ["12", "12", "12", "12"],
                ["14", "14", "14", "14"],
                ["16", "16", "16", "16"],
                ["18", "18", "18", "18"],
                ["20", "20", "20", "20"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "2TB",
                      short: "2T (Boy)",
                      long: "2T",
                      display: "2T",
                      display_with_size_set: "2T (Boy)",
                      display_with_size_system: "US 2T",
                      display_with_system_and_set: "US 2T (Boy)",
                      equivalent_sizes: {
                        au: ["3BAU"],
                        eu: ["2ABEU"],
                        uk: ["3BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3TB",
                      short: "3T (Boy)",
                      long: "3T",
                      display: "3T",
                      display_with_size_set: "3T (Boy)",
                      display_with_size_system: "US 3T",
                      display_with_system_and_set: "US 3T (Boy)",
                      equivalent_sizes: {
                        au: ["3BAU"],
                        eu: ["3ABEU"],
                        uk: ["3BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4TB",
                      short: "4T (Boy)",
                      long: "4T",
                      display: "4T",
                      display_with_size_set: "4T (Boy)",
                      display_with_size_system: "US 4T",
                      display_with_system_and_set: "US 4T (Boy)",
                      equivalent_sizes: {
                        au: ["4BAU"],
                        eu: ["4ABEU"],
                        uk: ["4BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5TB",
                      short: "5T (Boy)",
                      long: "5T",
                      display: "5T",
                      display_with_size_set: "5T (Boy)",
                      display_with_size_system: "US 5T",
                      display_with_system_and_set: "US 5T (Boy)",
                      equivalent_sizes: {
                        au: ["5BAU"],
                        eu: ["5ABEU"],
                        uk: ["5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4B",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Boy)",
                      equivalent_sizes: {
                        au: ["4BAU"],
                        eu: ["4BEU"],
                        uk: ["4BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5B",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Boy)",
                      equivalent_sizes: {
                        au: ["5BAU"],
                        eu: ["5BEU"],
                        uk: ["5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6B",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Boy)",
                      equivalent_sizes: {
                        au: ["6BAU"],
                        eu: ["6BEU"],
                        uk: ["6BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7B",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Boy)",
                      equivalent_sizes: {
                        au: ["7BAU"],
                        eu: ["7BEU"],
                        uk: ["7BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7XB",
                      short: "7X (Boy)",
                      long: "7X",
                      display: "7X",
                      display_with_size_set: "7X (Boy)",
                      display_with_size_system: "US 7X",
                      display_with_system_and_set: "US 7X (Boy)",
                      equivalent_sizes: {
                        au: ["8BAU"],
                        eu: ["7XBEU"],
                        uk: ["8BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8B",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Boy)",
                      equivalent_sizes: {
                        au: ["8BAU"],
                        eu: ["8BEU"],
                        uk: ["8BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10B",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Boy)",
                      equivalent_sizes: {
                        au: ["10BAU"],
                        eu: ["10BEU"],
                        uk: ["10BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12B",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Boy)",
                      equivalent_sizes: {
                        au: ["12BAU"],
                        eu: ["12BEU"],
                        uk: ["12BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14B",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Boy)",
                      equivalent_sizes: {
                        au: ["14BAU"],
                        eu: ["14BEU"],
                        uk: ["14BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16B",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Boy)",
                      equivalent_sizes: {
                        au: ["16BAU"],
                        eu: ["16BEU"],
                        uk: ["16BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18B",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Boy)",
                      equivalent_sizes: {
                        au: ["18BAU"],
                        eu: ["18BEU"],
                        uk: ["18BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20B",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Boy)",
                      equivalent_sizes: {
                        au: ["20BAU"],
                        eu: ["20BEU"],
                        uk: ["20BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSB",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Boy)",
                      equivalent_sizes: {
                        au: ["XSBAU"],
                        eu: ["XSBEU"],
                        uk: ["XSBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SB",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Boy)",
                      equivalent_sizes: {
                        au: ["SBAU"],
                        eu: ["SBEU"],
                        uk: ["SBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MB",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Boy)",
                      equivalent_sizes: {
                        au: ["MBAU"],
                        eu: ["MBEU"],
                        uk: ["MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LB",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Boy)",
                      equivalent_sizes: {
                        au: ["LBAU"],
                        eu: ["LBEU"],
                        uk: ["LBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLB",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Boy)",
                      equivalent_sizes: {
                        au: ["XLBAU"],
                        eu: ["XLBEU"],
                        uk: ["XLBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLB",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Boy)",
                      equivalent_sizes: {
                        au: ["XXLBAU"],
                        eu: ["XXLBEU"],
                        uk: ["XXLBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "3BAU",
                      short: "3 (Boy)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Boy)",
                      display_with_size_system: "AU 3",
                      display_with_system_and_set: "AU 3 (Boy)",
                      equivalent_sizes: {
                        us: ["2TB", "3TB"],
                        eu: ["2ABEU", "3ABEU"],
                        uk: ["3BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4BAU",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Boy)",
                      equivalent_sizes: {
                        us: ["4TB", "4B"],
                        eu: ["4ABEU", "4BEU"],
                        uk: ["4BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5BAU",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "AU 5",
                      display_with_system_and_set: "AU 5 (Boy)",
                      equivalent_sizes: {
                        us: ["5TB", "5B"],
                        eu: ["5ABEU", "5BEU"],
                        uk: ["5BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6BAU",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Boy)",
                      equivalent_sizes: {
                        us: ["6B"],
                        eu: ["6BEU"],
                        uk: ["6BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "7BAU",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "AU 7",
                      display_with_system_and_set: "AU 7 (Boy)",
                      equivalent_sizes: {
                        us: ["7B"],
                        eu: ["7BEU"],
                        uk: ["7BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8BAU",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Boy)",
                      equivalent_sizes: {
                        us: ["7XB", "8B"],
                        eu: ["7XBEU", "8BEU"],
                        uk: ["8BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10BAU",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Boy)",
                      equivalent_sizes: {
                        us: ["10B"],
                        eu: ["10BEU"],
                        uk: ["10BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12BAU",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Boy)",
                      equivalent_sizes: {
                        us: ["12B"],
                        eu: ["12BEU"],
                        uk: ["12BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14BAU",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Boy)",
                      equivalent_sizes: {
                        us: ["14B"],
                        eu: ["14BEU"],
                        uk: ["14BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16BAU",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Boy)",
                      equivalent_sizes: {
                        us: ["16B"],
                        eu: ["16BEU"],
                        uk: ["16BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18BAU",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Boy)",
                      equivalent_sizes: {
                        us: ["18B"],
                        eu: ["18BEU"],
                        uk: ["18BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20BAU",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Boy)",
                      equivalent_sizes: {
                        us: ["20B"],
                        eu: ["20BEU"],
                        uk: ["20BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSBAU",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Boy)",
                      equivalent_sizes: {
                        us: ["XSB"],
                        eu: ["XSBEU"],
                        uk: ["XSBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SBAU",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Boy)",
                      equivalent_sizes: {
                        us: ["SB"],
                        eu: ["SBEU"],
                        uk: ["SBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MBAU",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Boy)",
                      equivalent_sizes: {
                        us: ["MB"],
                        eu: ["MBEU"],
                        uk: ["MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LBAU",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Boy)",
                      equivalent_sizes: {
                        us: ["LB"],
                        eu: ["LBEU"],
                        uk: ["LBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLBAU",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Boy)",
                      equivalent_sizes: {
                        us: ["XLB"],
                        eu: ["XLBEU"],
                        uk: ["XLBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLBAU",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Boy)",
                      equivalent_sizes: {
                        us: ["XXLB"],
                        eu: ["XXLBEU"],
                        uk: ["XXLBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "2ABEU",
                      short: "2A (Boy)",
                      long: "2A",
                      display: "2A",
                      display_with_size_set: "2A (Boy)",
                      display_with_size_system: "EU 2A",
                      display_with_system_and_set: "EU 2A (Boy)",
                      equivalent_sizes: {
                        us: ["2TB"],
                        au: ["3BAU"],
                        uk: ["3BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3ABEU",
                      short: "3A (Boy)",
                      long: "3A",
                      display: "3A",
                      display_with_size_set: "3A (Boy)",
                      display_with_size_system: "EU 3A",
                      display_with_system_and_set: "EU 3A (Boy)",
                      equivalent_sizes: {
                        us: ["3TB"],
                        au: ["3BAU"],
                        uk: ["3BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4ABEU",
                      short: "4A (Boy)",
                      long: "4A",
                      display: "4A",
                      display_with_size_set: "4A (Boy)",
                      display_with_size_system: "EU 4A",
                      display_with_system_and_set: "EU 4A (Boy)",
                      equivalent_sizes: {
                        us: ["4TB"],
                        au: ["4BAU"],
                        uk: ["4BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4BEU",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "EU 4",
                      display_with_system_and_set: "EU 4 (Boy)",
                      equivalent_sizes: {
                        us: ["4B"],
                        au: ["4BAU"],
                        uk: ["4BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5ABEU",
                      short: "5A (Boy)",
                      long: "5A",
                      display: "5A",
                      display_with_size_set: "5A (Boy)",
                      display_with_size_system: "EU 5A",
                      display_with_system_and_set: "EU 5A (Boy)",
                      equivalent_sizes: {
                        us: ["5TB"],
                        au: ["5BAU"],
                        uk: ["5BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5BEU",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "EU 5",
                      display_with_system_and_set: "EU 5 (Boy)",
                      equivalent_sizes: {
                        us: ["5B"],
                        au: ["5BAU"],
                        uk: ["5BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6BEU",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "EU 6",
                      display_with_system_and_set: "EU 6 (Boy)",
                      equivalent_sizes: {
                        us: ["6B"],
                        au: ["6BAU"],
                        uk: ["6BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "7XBEU",
                      short: "7X (Boy)",
                      long: "7X",
                      display: "7X",
                      display_with_size_set: "7X (Boy)",
                      display_with_size_system: "EU 7X",
                      display_with_system_and_set: "EU 7X (Boy)",
                      equivalent_sizes: {
                        us: ["7XB"],
                        au: ["8BAU"],
                        uk: ["8BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "7BEU",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "EU 7",
                      display_with_system_and_set: "EU 7 (Boy)",
                      equivalent_sizes: {
                        us: ["7B"],
                        au: ["7BAU"],
                        uk: ["7BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "8BEU",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "EU 8",
                      display_with_system_and_set: "EU 8 (Boy)",
                      equivalent_sizes: {
                        us: ["8B"],
                        au: ["8BAU"],
                        uk: ["8BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "10BEU",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "EU 10",
                      display_with_system_and_set: "EU 10 (Boy)",
                      equivalent_sizes: {
                        us: ["10B"],
                        au: ["10BAU"],
                        uk: ["10BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12BEU",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "EU 12",
                      display_with_system_and_set: "EU 12 (Boy)",
                      equivalent_sizes: {
                        us: ["12B"],
                        au: ["12BAU"],
                        uk: ["12BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "14BEU",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "EU 14",
                      display_with_system_and_set: "EU 14 (Boy)",
                      equivalent_sizes: {
                        us: ["14B"],
                        au: ["14BAU"],
                        uk: ["14BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "16BEU",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "EU 16",
                      display_with_system_and_set: "EU 16 (Boy)",
                      equivalent_sizes: {
                        us: ["16B"],
                        au: ["16BAU"],
                        uk: ["16BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18BEU",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "EU 18",
                      display_with_system_and_set: "EU 18 (Boy)",
                      equivalent_sizes: {
                        us: ["18B"],
                        au: ["18BAU"],
                        uk: ["18BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "20BEU",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "EU 20",
                      display_with_system_and_set: "EU 20 (Boy)",
                      equivalent_sizes: {
                        us: ["20B"],
                        au: ["20BAU"],
                        uk: ["20BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSBEU",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Boy)",
                      equivalent_sizes: {
                        us: ["XSB"],
                        au: ["XSBAU"],
                        uk: ["XSBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SBEU",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Boy)",
                      equivalent_sizes: {
                        us: ["SB"],
                        au: ["SBAU"],
                        uk: ["SBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MBEU",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Boy)",
                      equivalent_sizes: {
                        us: ["MB"],
                        au: ["MBAU"],
                        uk: ["MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LBEU",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Boy)",
                      equivalent_sizes: {
                        us: ["LB"],
                        au: ["LBAU"],
                        uk: ["LBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLBEU",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Boy)",
                      equivalent_sizes: {
                        us: ["XLB"],
                        au: ["XLBAU"],
                        uk: ["XLBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLBEU",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Boy)",
                      equivalent_sizes: {
                        us: ["XXLB"],
                        au: ["XXLBAU"],
                        uk: ["XXLBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "3BUK",
                      short: "3 (Boy)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Boy)",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3 (Boy)",
                      equivalent_sizes: {
                        us: ["2TB", "3TB"],
                        au: ["3BAU"],
                        eu: ["2ABEU", "3ABEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4BUK",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Boy)",
                      equivalent_sizes: {
                        us: ["4TB", "4B"],
                        au: ["4BAU"],
                        eu: ["4ABEU", "4BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5BUK",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "UK 5",
                      display_with_system_and_set: "UK 5 (Boy)",
                      equivalent_sizes: {
                        us: ["5TB", "5B"],
                        au: ["5BAU"],
                        eu: ["5ABEU", "5BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6BUK",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Boy)",
                      equivalent_sizes: {
                        us: ["6B"],
                        au: ["6BAU"],
                        eu: ["6BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "7BUK",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "UK 7",
                      display_with_system_and_set: "UK 7 (Boy)",
                      equivalent_sizes: {
                        us: ["7B"],
                        au: ["7BAU"],
                        eu: ["7BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8BUK",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Boy)",
                      equivalent_sizes: {
                        us: ["7XB", "8B"],
                        au: ["8BAU"],
                        eu: ["7XBEU", "8BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10BUK",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Boy)",
                      equivalent_sizes: {
                        us: ["10B"],
                        au: ["10BAU"],
                        eu: ["10BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12BUK",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Boy)",
                      equivalent_sizes: {
                        us: ["12B"],
                        au: ["12BAU"],
                        eu: ["12BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14BUK",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Boy)",
                      equivalent_sizes: {
                        us: ["14B"],
                        au: ["14BAU"],
                        eu: ["14BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16BUK",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Boy)",
                      equivalent_sizes: {
                        us: ["16B"],
                        au: ["16BAU"],
                        eu: ["16BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18BUK",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Boy)",
                      equivalent_sizes: {
                        us: ["18B"],
                        au: ["18BAU"],
                        eu: ["18BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20BUK",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Boy)",
                      equivalent_sizes: {
                        us: ["20B"],
                        au: ["20BAU"],
                        eu: ["20BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSBUK",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Boy)",
                      equivalent_sizes: {
                        us: ["XSB"],
                        au: ["XSBAU"],
                        eu: ["XSBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SBUK",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Boy)",
                      equivalent_sizes: {
                        us: ["SB"],
                        au: ["SBAU"],
                        eu: ["SBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MBUK",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Boy)",
                      equivalent_sizes: {
                        us: ["MB"],
                        au: ["MBAU"],
                        eu: ["MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LBUK",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Boy)",
                      equivalent_sizes: {
                        us: ["LB"],
                        au: ["LBAU"],
                        eu: ["LBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLBUK",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Boy)",
                      equivalent_sizes: {
                        us: ["XLB"],
                        au: ["XLBAU"],
                        eu: ["XLBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLBUK",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Boy)",
                      equivalent_sizes: {
                        us: ["XXLB"],
                        au: ["XXLBAU"],
                        eu: ["XXLBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "kids_shirts_tops",
          aliases: [],
          slug: "Shirts_&_Tops",
          alias_slugs: [],
          parent_id: "20008c10d97b4e1245005764",
          category_features: [
            {
              id: "9f009813d97b4e3995005764",
              display: "Blouses",
              type: "cf",
              message_id: "kids_shirts_tops_blouses",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Blouses",
              alias_slugs: [],
              parent_id: "2e008c10d97b4e1245005764",
            },
            {
              id: "a0009813d97b4e3995005764",
              display: "Button Down Shirts",
              type: "cf",
              message_id: "kids_shirts_tops_button_down_shirts",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Button_Down_Shirts",
              alias_slugs: [],
              parent_id: "2e008c10d97b4e1245005764",
            },
            {
              id: "a1009813d97b4e3995005764",
              display: "Camisoles",
              type: "cf",
              message_id: "kids_shirts_tops_camisoles",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Camisoles",
              alias_slugs: [],
              parent_id: "2e008c10d97b4e1245005764",
            },
            {
              id: "b77df9aaaabb083120f45ec2",
              display: "Jerseys",
              type: "cf",
              message_id: "kids_shirts_tops_jerseys",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Jerseys",
              alias_slugs: [],
              parent_id: "2e008c10d97b4e1245005764",
            },
            {
              id: "a2009813d97b4e3995005764",
              display: "Polos",
              type: "cf",
              message_id: "kids_shirts_tops_polos",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Polos",
              alias_slugs: [],
              parent_id: "2e008c10d97b4e1245005764",
            },
            {
              id: "19002f3cd97b4edf70005784",
              display: "Sweaters",
              type: "cf",
              message_id: "kids_shirts_tops_sweaters",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sweaters",
              alias_slugs: [],
              parent_id: "2e008c10d97b4e1245005764",
            },
            {
              id: "a3009813d97b4e3995005764",
              display: "Sweatshirts & Hoodies",
              type: "cf",
              message_id: "kids_shirts_tops_sweatshirts_hoodies",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sweatshirts_&_Hoodies",
              alias_slugs: [],
              parent_id: "2e008c10d97b4e1245005764",
            },
            {
              id: "a4009813d97b4e3995005764",
              display: "Tank Tops",
              type: "cf",
              message_id: "kids_shirts_tops_tank_tops",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Tank_Tops",
              alias_slugs: [],
              parent_id: "2e008c10d97b4e1245005764",
            },
            {
              id: "a5009813d97b4e3995005764",
              display: "Tees - Long Sleeve",
              type: "cf",
              message_id: "kids_shirts_tops_tees_long_sleeve",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Tees___Long_Sleeve",
              alias_slugs: [],
              parent_id: "2e008c10d97b4e1245005764",
            },
            {
              id: "a6009813d97b4e3995005764",
              display: "Tees - Short Sleeve",
              type: "cf",
              message_id: "kids_shirts_tops_tees_short_sleeve",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Tees___Short_Sleeve",
              alias_slugs: [],
              parent_id: "2e008c10d97b4e1245005764",
            },
          ],
        },
        {
          id: "2d008c10d97b4e1245005764",
          display: "Swim",
          size_sets: [
            {
              id: "1f003667d97b4e6bdb005784",
              name: "Baby",
              tags: ["baby"],
              category_id: "2d008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["14.5", "0-18", "15", "15"],
                  ["16", "0-18", "16.5", "16.5"],
                  ["17", "18-23", "17.5", "17.5"],
                  ["18", "23-27", "18.5", "18.5"],
                  ["18.5", "27-28", "19", "19"],
                  ["19", "28-29", "19.5", "19.5"],
                  ["19.75", "29-31", "20.25", "20.25"],
                  ["20.5", "31-33", "21", "21"],
                  ["17", "18-23", "17.5", "17.5"],
                  ["18", "23-27", "18.5", "18.5"],
                  ["18.5", "27-28", "19", "19"],
                  ["19", "28-29", "19.5", "19.5"],
                  ["19.75", "29-31", "20.25", "20.25"],
                  ["20.5", "31-33", "21", "21"],
                ],
                centimeters: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["36.8", "0-45.7", "38.1", "38.1"],
                  ["40.6", "0-45.7", "41.9", "41.9"],
                  ["43.2", "45.7-58.4", "44.5", "44.5"],
                  ["45.7", "58.4-68.6", "47.0", "47.0"],
                  ["47.0", "68.6-71.1", "48.3", "48.3"],
                  ["48.3", "71.1-73.7", "49.5", "49.5"],
                  ["50.2", "73.7-78.7", "51.4", "51.4"],
                  ["52.1", "78.7-83.8", "53.3", "53.3"],
                  ["43.2", "45.7-58.4", "44.5", "44.5"],
                  ["45.7", "58.4-68.6", "47.0", "47.0"],
                  ["47.0", "68.6-71.1", "48.3", "48.3"],
                  ["48.3", "71.1-73.7", "49.5", "49.5"],
                  ["50.2", "73.7-78.7", "51.4", "51.4"],
                  ["52.1", "78.7-83.8", "53.3", "53.3"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["Preemie", "00000", "Preemie", "Preemie"],
                  ["Newborn", "00000", "Newborn", "Newborn"],
                  ["0-3 Months", "0000", "0-3 Months", "0-3 Months"],
                  ["3-6 Months", "000", "3-6 Months", "3-6 Months"],
                  ["6-9 Months", "000", "6-9 Months", "6-9 Months"],
                  ["9-12 Months", "00", "9-12 Months", "9-12 Months"],
                  ["12-18 Months", "0", "12-18 Months", "12-18 Months"],
                  ["18-24 Months", "1", "18-24 Months", "18-24 Months"],
                  ["3 Months", "0000,000", "3 Months", "3 Months"],
                  ["6 Months", "000", "6 Months", "6 Months"],
                  ["9 Months", "000,00", "9 Months", "9 Months"],
                  ["12 Months", "00,0", "12 Months", "12 Months"],
                  ["18 Months", "0,1", "18 Months", "18 Months"],
                  ["24 Months", "1,2", "24 Months", "24 Months"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["Preemie", "00000", "Preemie", "Preemie"],
                ["Newborn", "00000", "Newborn", "Newborn"],
                ["0-3 Months", "0000", "0-3 Months", "0-3 Months"],
                ["3-6 Months", "000", "3-6 Months", "3-6 Months"],
                ["6-9 Months", "000", "6-9 Months", "6-9 Months"],
                ["9-12 Months", "00", "9-12 Months", "9-12 Months"],
                ["12-18 Months", "0", "12-18 Months", "12-18 Months"],
                ["18-24 Months", "1", "18-24 Months", "18-24 Months"],
                ["3 Months", "0000,000", "3 Months", "3 Months"],
                ["6 Months", "000", "6 Months", "6 Months"],
                ["9 Months", "000,00", "9 Months", "9 Months"],
                ["12 Months", "00,0", "12 Months", "12 Months"],
                ["18 Months", "0,1", "18 Months", "18 Months"],
                ["24 Months", "1,2", "24 Months", "24 Months"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "Preemie",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "US Preemie",
                      display_with_system_and_set: "US Preemie",
                      equivalent_sizes: {
                        au: ["00000MBAU"],
                        eu: ["PreemieEU"],
                        uk: ["PreemieUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "Newborn",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "US Newborn",
                      display_with_system_and_set: "US Newborn",
                      equivalent_sizes: {
                        au: ["00000MBAU"],
                        eu: ["NewbornEU"],
                        uk: ["NewbornUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "0-3MB",
                      short: "0-3M",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3M",
                      display_with_size_system: "US 0-3",
                      display_with_system_and_set: "US 0-3M",
                      equivalent_sizes: {
                        au: ["0000MBAU"],
                        eu: ["0-3MBEU"],
                        uk: ["0-3MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3-6MB",
                      short: "3-6M",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6M",
                      display_with_size_system: "US 3-6",
                      display_with_system_and_set: "US 3-6M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["3-6MBEU"],
                        uk: ["3-6MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6-9MB",
                      short: "6-9M",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9M",
                      display_with_size_system: "US 6-9",
                      display_with_system_and_set: "US 6-9M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["6-9MBEU"],
                        uk: ["6-9MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9-12MB",
                      short: "9-12M",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12M",
                      display_with_size_system: "US 9-12",
                      display_with_system_and_set: "US 9-12M",
                      equivalent_sizes: {
                        au: ["00MBAU"],
                        eu: ["9-12MBEU"],
                        uk: ["9-12MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12-18MB",
                      short: "12-18M",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18M",
                      display_with_size_system: "US 12-18",
                      display_with_system_and_set: "US 12-18M",
                      equivalent_sizes: {
                        au: ["0MBAU"],
                        eu: ["12-18MBEU"],
                        uk: ["12-18MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18-24MB",
                      short: "18-24M",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24M",
                      display_with_size_system: "US 18-24",
                      display_with_system_and_set: "US 18-24M",
                      equivalent_sizes: {
                        au: ["1MBAU"],
                        eu: ["18-24MBEU"],
                        uk: ["18-24MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3MB",
                      short: "3M",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3M",
                      display_with_size_system: "US 3",
                      display_with_system_and_set: "US 3M",
                      equivalent_sizes: {
                        au: ["0000MBAU", "000MBAU"],
                        eu: ["3MBEU"],
                        uk: ["3MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6MB",
                      short: "6M",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6M",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6M",
                      equivalent_sizes: {
                        au: ["000MBAU"],
                        eu: ["6MBEU"],
                        uk: ["6MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "9MB",
                      short: "9M",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9M",
                      display_with_size_system: "US 9",
                      display_with_system_and_set: "US 9M",
                      equivalent_sizes: {
                        au: ["000MBAU", "00MBAU"],
                        eu: ["9MBEU"],
                        uk: ["9MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12MB",
                      short: "12M",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12M",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12M",
                      equivalent_sizes: {
                        au: ["00MBAU", "0MBAU"],
                        eu: ["12MBEU"],
                        uk: ["12MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18MB",
                      short: "18M",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18M",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18M",
                      equivalent_sizes: {
                        au: ["0MBAU", "1MBAU"],
                        eu: ["18MBEU"],
                        uk: ["18MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "24MB",
                      short: "24M",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24M",
                      display_with_size_system: "US 24",
                      display_with_system_and_set: "US 24M",
                      equivalent_sizes: {
                        au: ["1MBAU", "2MBAU"],
                        eu: ["24MBEU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "00000MBAU",
                      short: "00000",
                      long: "00000",
                      display: "00000",
                      display_with_size_set: "00000",
                      display_with_size_system: "AU 00000",
                      display_with_system_and_set: "AU 00000",
                      equivalent_sizes: {
                        us: ["Preemie ", "Newborn"],
                        eu: ["PreemieEU", "NewbornEU"],
                        uk: ["PreemieUK", "NewbornUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0000MBAU",
                      short: "0000",
                      long: "0000",
                      display: "0000",
                      display_with_size_set: "0000",
                      display_with_size_system: "AU 0000",
                      display_with_system_and_set: "AU 0000",
                      equivalent_sizes: {
                        us: ["0-3MB", "3MB"],
                        eu: ["0-3MBEU", "3MBEU"],
                        uk: ["0-3MBUK", "3MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "000MBAU",
                      short: "000",
                      long: "000",
                      display: "000",
                      display_with_size_set: "000",
                      display_with_size_system: "AU 000",
                      display_with_system_and_set: "AU 000",
                      equivalent_sizes: {
                        us: ["3MB", "6MB", "9MB", "3-6MB", "6-9MB"],
                        eu: ["3MBEU", "6MBEU", "9MBEU", "3-6MBEU", "6-9MBEU"],
                        uk: ["3MBUK", "6MBUK", "9MBUK", "3-6MBUK", "6-9MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "00MBAU",
                      short: "00",
                      long: "00",
                      display: "00",
                      display_with_size_set: "00",
                      display_with_size_system: "AU 00",
                      display_with_system_and_set: "AU 00",
                      equivalent_sizes: {
                        us: ["9MB", "12MB", "9-12MB"],
                        eu: ["9MBEU", "12MBEU", "9-12MBEU"],
                        uk: ["9MBUK", "12MBUK", "9-12MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "0MBAU",
                      short: "0",
                      long: "0",
                      display: "0",
                      display_with_size_set: "0",
                      display_with_size_system: "AU 0",
                      display_with_system_and_set: "AU 0",
                      equivalent_sizes: {
                        us: ["12MB", "18MB", "12-18MB"],
                        eu: ["12MBEU", "18MBEU", "12-18MBEU"],
                        uk: ["12MBUK", "18MBUK", "12-18MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "1MBAU",
                      short: "1",
                      long: "1",
                      display: "1",
                      display_with_size_set: "1",
                      display_with_size_system: "AU 1",
                      display_with_system_and_set: "AU 1",
                      equivalent_sizes: {
                        us: ["18MB", "24MB", "18-24MB"],
                        eu: ["18MBEU", "24MBEU", "18-24MBEU"],
                        uk: ["18MBUK", "24MBUK", "18-24MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "2MBAU",
                      short: "2",
                      long: "2",
                      display: "2",
                      display_with_size_set: "2",
                      display_with_size_system: "AU 2",
                      display_with_system_and_set: "AU 2",
                      equivalent_sizes: {
                        us: ["24MB"],
                        eu: ["24MBEU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "PreemieEU",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "EU Preemie",
                      display_with_system_and_set: "EU Preemie",
                      equivalent_sizes: {
                        us: ["Preemie"],
                        au: ["00000MBAU"],
                        uk: ["PreemieUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "NewbornEU",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "EU Newborn",
                      display_with_system_and_set: "EU Newborn",
                      equivalent_sizes: {
                        us: ["Newborn"],
                        au: ["00000MBAU"],
                        uk: ["NewbornUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "0-3MBEU",
                      short: "0-3",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3",
                      display_with_size_system: "EU 0-3",
                      display_with_system_and_set: "EU 0-3",
                      equivalent_sizes: {
                        us: ["0-3MB"],
                        au: ["0000MBAU"],
                        uk: ["0-3MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3-6MBEU",
                      short: "3-6",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6",
                      display_with_size_system: "EU 3-6",
                      display_with_system_and_set: "EU 3-6",
                      equivalent_sizes: {
                        us: ["3-6MB"],
                        au: ["000MBAU"],
                        uk: ["3-6MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6-9MBEU",
                      short: "6-9",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9",
                      display_with_size_system: "EU 6-9",
                      display_with_system_and_set: "EU 6-9",
                      equivalent_sizes: {
                        us: ["6-9MB"],
                        au: ["000MBAU"],
                        uk: ["6-9MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "9-12MBEU",
                      short: "9-12",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12",
                      display_with_size_system: "EU 9-12",
                      display_with_system_and_set: "EU 9-12",
                      equivalent_sizes: {
                        us: ["9-12MB"],
                        au: ["00MBAU"],
                        uk: ["9-12MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12-18MBEU",
                      short: "12-18",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18",
                      display_with_size_system: "EU 12-18",
                      display_with_system_and_set: "EU 12-18",
                      equivalent_sizes: {
                        us: ["12-18MB"],
                        au: ["0MBAU"],
                        uk: ["12-18MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18-24MBEU",
                      short: "18-24",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24",
                      display_with_size_system: "EU 18-24",
                      display_with_system_and_set: "EU 18-24",
                      equivalent_sizes: {
                        us: ["18-24MB"],
                        au: ["1MBAU"],
                        uk: ["18-24MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3MBEU",
                      short: "3",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3",
                      display_with_size_system: "EU 3",
                      display_with_system_and_set: "EU 3",
                      equivalent_sizes: {
                        us: ["3MB"],
                        au: ["0000MBAU", "000MBAU"],
                        uk: ["3MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6MBEU",
                      short: "6",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6",
                      display_with_size_system: "EU 6",
                      display_with_system_and_set: "EU 6",
                      equivalent_sizes: {
                        us: ["6MB"],
                        au: ["000MBAU"],
                        uk: ["6MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "9MBEU",
                      short: "9",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9",
                      display_with_size_system: "EU 9",
                      display_with_system_and_set: "EU 9",
                      equivalent_sizes: {
                        us: ["9MB"],
                        au: ["000MBAU", "00MBAU"],
                        uk: ["9MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12MBEU",
                      short: "12",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12",
                      display_with_size_system: "EU 12",
                      display_with_system_and_set: "EU 12",
                      equivalent_sizes: {
                        us: ["12MB"],
                        au: ["00MBAU", "0MBAU"],
                        uk: ["12MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18MBEU",
                      short: "18",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18",
                      display_with_size_system: "EU 18",
                      display_with_system_and_set: "EU 18",
                      equivalent_sizes: {
                        us: ["18MB"],
                        au: ["0MBAU", "1MBAU"],
                        uk: ["18MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "24MBEU",
                      short: "24",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24",
                      display_with_size_system: "EU 24",
                      display_with_system_and_set: "EU 24",
                      equivalent_sizes: {
                        us: ["24MB"],
                        au: ["1MBAU", "2MBAU"],
                        uk: ["24MBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "PreemieUK",
                      short: "Preemie",
                      long: "Preemie",
                      display: "Preemie",
                      display_with_size_set: "Preemie",
                      display_with_size_system: "UK Preemie",
                      display_with_system_and_set: "UK Preemie",
                      equivalent_sizes: {
                        us: ["Preemie"],
                        au: ["00000MBAU"],
                        eu: ["PreemieEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "NewbornUK",
                      short: "Newborn",
                      long: "Newborn",
                      display: "Newborn",
                      display_with_size_set: "Newborn",
                      display_with_size_system: "UK Newborn",
                      display_with_system_and_set: "UK Newborn",
                      equivalent_sizes: {
                        us: ["Newborn"],
                        au: ["00000MBAU"],
                        eu: ["NewbornEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "0-3MBUK",
                      short: "0-3M",
                      long: "0-3 Months",
                      display: "0-3 Months",
                      display_with_size_set: "0-3M",
                      display_with_size_system: "UK 0-3",
                      display_with_system_and_set: "UK 0-3M",
                      equivalent_sizes: {
                        us: ["0-3MB"],
                        au: ["0000MBAU"],
                        eu: ["0-3MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3-6MBUK",
                      short: "3-6M",
                      long: "3-6 Months",
                      display: "3-6 Months",
                      display_with_size_set: "3-6M",
                      display_with_size_system: "UK 3-6",
                      display_with_system_and_set: "UK 3-6M",
                      equivalent_sizes: {
                        us: ["3-6MB"],
                        au: ["000MBAU"],
                        eu: ["3-6MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6-9MBUK",
                      short: "6-9M",
                      long: "6-9 Months",
                      display: "6-9 Months",
                      display_with_size_set: "6-9M",
                      display_with_size_system: "UK 6-9",
                      display_with_system_and_set: "UK 6-9M",
                      equivalent_sizes: {
                        us: ["6-9MB"],
                        au: ["000MBAU"],
                        eu: ["6-9MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9-12MBUK",
                      short: "9-12M",
                      long: "9-12 Months",
                      display: "9-12 Months",
                      display_with_size_set: "9-12M",
                      display_with_size_system: "UK 9-12",
                      display_with_system_and_set: "UK 9-12M",
                      equivalent_sizes: {
                        us: ["9-12MB"],
                        au: ["00MBAU"],
                        eu: ["9-12MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12-18MBUK",
                      short: "12-18M",
                      long: "12-18 Months",
                      display: "12-18 Months",
                      display_with_size_set: "12-18M",
                      display_with_size_system: "UK 12-18",
                      display_with_system_and_set: "UK 12-18M",
                      equivalent_sizes: {
                        us: ["12-18MB"],
                        au: ["0MBAU"],
                        eu: ["12-18MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18-24MBUK",
                      short: "18-24M",
                      long: "18-24 Months",
                      display: "18-24 Months",
                      display_with_size_set: "18-24M",
                      display_with_size_system: "UK 18-24",
                      display_with_system_and_set: "UK 18-24M",
                      equivalent_sizes: {
                        us: ["18-24MB"],
                        au: ["1MBAU"],
                        eu: ["18-24MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "3MBUK",
                      short: "3M",
                      long: "3 Months",
                      display: "3 Months",
                      display_with_size_set: "3M",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3M",
                      equivalent_sizes: {
                        us: ["3MB"],
                        au: ["0000MBAU", "000MBAU"],
                        eu: ["3MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6MBUK",
                      short: "6M",
                      long: "6 Months",
                      display: "6 Months",
                      display_with_size_set: "6M",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6M",
                      equivalent_sizes: {
                        us: ["6MB"],
                        au: ["000MBAU"],
                        eu: ["6MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "9MBUK",
                      short: "9M",
                      long: "9 Months",
                      display: "9 Months",
                      display_with_size_set: "9M",
                      display_with_size_system: "UK 9",
                      display_with_system_and_set: "UK 9M",
                      equivalent_sizes: {
                        us: ["9MB"],
                        au: ["000MBAU", "00MBAU"],
                        eu: ["9MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12MBUK",
                      short: "12M",
                      long: "12 Months",
                      display: "12 Months",
                      display_with_size_set: "12M",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12M",
                      equivalent_sizes: {
                        us: ["12MB"],
                        au: ["00MBAU", "0MBAU"],
                        eu: ["12MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18MBUK",
                      short: "18M",
                      long: "18 Months",
                      display: "18 Months",
                      display_with_size_set: "18M",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18M",
                      equivalent_sizes: {
                        us: ["18MB"],
                        au: ["0MBAU", "1MBAU"],
                        eu: ["18MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "24MBUK",
                      short: "24M",
                      long: "24 Months",
                      display: "24 Months",
                      display_with_size_set: "24M",
                      display_with_size_system: "UK 24",
                      display_with_system_and_set: "UK 24M",
                      equivalent_sizes: {
                        us: ["24MB"],
                        au: ["1MBAU", "2MBAU"],
                        eu: ["24MBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "20003667d97b4e6bdb005784",
              name: "Girls",
              tags: ["girls"],
              category_id: "2d008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["20.5", "33.5-35.5", "21", "21"],
                  ["21", "36-38", "21.5", "22"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["24", "45-46.5", "23", "25"],
                  ["25.5", "47-49.5", "23.5", "26.5"],
                  ["26", "49-50.5", "24", "27.5"],
                  ["27", "51-52.5", "24.5", "28.5"],
                  ["28.5", "53-55", "25", "30"],
                  ["30", "55.5-58", "26", "31.5"],
                  ["31.5", "58.5-61", "27.5", "33"],
                  ["33", "61.5-62.5", "29", "34.5"],
                  ["22.0-23.0", "38.5-44.5", "22.0-22.5", "23.0-24.0"],
                  ["24.0-26.0", "45-50.5", "23.0-24.0", "25.0-27.5"],
                  ["27", "51-52.5", "24.5", "28.5"],
                  ["28.5", "53-55", "25", "30"],
                  ["30", "55.5-58", "26", "31.5"],
                  ["31.5-33.0", "58.5-62.5", "27.5-29.0", "33-34.5"],
                ],
                centimeters: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["52.1", "85.1-90.2", "53.3", "53.3"],
                  ["53.3", "91.4-96.5", "54.6", "55.9"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["61", "114.3-118.1", "58.4", "63.5"],
                  ["64.8", "119.4-125.7", "59.7", "67.3"],
                  ["66", "125.7-128.3", "61", "69.9"],
                  ["68.6", "129.5-133.4", "62.2", "72.4"],
                  ["72.4", "134.6-139.7", "63.5", "76.2"],
                  ["76.2", "141.0-147.3", "66", "80"],
                  ["80", "148.6-154.9", "69.9", "83.8"],
                  ["83.8", "156.2-158.8", "73.7", "87.6"],
                  ["55.9-58.4", "97.8-113.0", "55.9-57.2", "58.4-61.0"],
                  ["61.0-66.0", "114.3-128.3", "58.4-61.0", "63.5-69.9"],
                  ["68.6", "129.5-133.4", "62.2", "72.4"],
                  ["72.4", "134.6-139.7", "63.5", "76.2"],
                  ["76.2", "141.0-147.3", "66", "80"],
                  ["80.0-83.8", "148.6-158.8", "69.9-73.7", "83.8-87.6"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["6", "6", "6", "6"],
                  ["6X,7", "7", "6X,7", "7"],
                  ["6X,7", "7", "6X,7", "7"],
                  ["8", "8", "8", "8"],
                  ["10", "10", "10", "10"],
                  ["12", "12", "12", "12"],
                  ["14", "14", "14", "14"],
                  ["16", "16", "16", "16"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["2T", "3", "2A", "3"],
                ["3T", "3", "3A", "3"],
                ["4T", "4", "4A", "4"],
                ["5T", "5", "5A", "5"],
                ["4", "4", "4", "4"],
                ["5", "5", "5", "5"],
                ["6", "6", "6", "6"],
                ["6X", "7", "6X", "7"],
                ["7", "7", "7", "7"],
                ["8", "8", "8", "8"],
                ["10", "10", "10", "10"],
                ["12", "12", "12", "12"],
                ["14", "14", "14", "14"],
                ["16", "16", "16", "16"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "2TG",
                      short: "2T (Girl)",
                      long: "2T",
                      display: "2T",
                      display_with_size_set: "2T (Girl)",
                      display_with_size_system: "US 2T",
                      display_with_system_and_set: "US 2T (Girl)",
                      equivalent_sizes: {
                        au: ["3GAU"],
                        eu: ["2AGEU"],
                        uk: ["3GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3TG",
                      short: "3T (Girl)",
                      long: "3T",
                      display: "3T",
                      display_with_size_set: "3T (Girl)",
                      display_with_size_system: "US 3T",
                      display_with_system_and_set: "US 3T (Girl)",
                      equivalent_sizes: {
                        au: ["3GAU"],
                        eu: ["3AGEU"],
                        uk: ["3GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4TG",
                      short: "4T (Girl)",
                      long: "4T",
                      display: "4T",
                      display_with_size_set: "4T (Girl)",
                      display_with_size_system: "US 4T",
                      display_with_system_and_set: "US 4T (Girl)",
                      equivalent_sizes: {
                        au: ["4GAU"],
                        eu: ["4AGEU"],
                        uk: ["4GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5TG",
                      short: "5T (Girl)",
                      long: "5T",
                      display: "5T",
                      display_with_size_set: "5T (Girl)",
                      display_with_size_system: "US 5T",
                      display_with_system_and_set: "US 5T (Girl)",
                      equivalent_sizes: {
                        au: ["5GAU"],
                        eu: ["5AGEU"],
                        uk: ["5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4G",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Girl)",
                      equivalent_sizes: {
                        au: ["4GAU"],
                        eu: ["4GEU"],
                        uk: ["4GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5G",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Girl)",
                      equivalent_sizes: {
                        au: ["5GAU"],
                        eu: ["5GEU"],
                        uk: ["5GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6G",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Girl)",
                      equivalent_sizes: {
                        au: ["6GAU"],
                        eu: ["6GEU"],
                        uk: ["6GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6XG",
                      short: "6X (Girl)",
                      long: "6X",
                      display: "6X",
                      display_with_size_set: "6X (Girl)",
                      display_with_size_system: "US 6X",
                      display_with_system_and_set: "US 6X (Girl)",
                      equivalent_sizes: {
                        au: ["7GAU"],
                        eu: ["6XGEU"],
                        uk: ["7GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7G",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Girl)",
                      equivalent_sizes: {
                        au: ["7GAU"],
                        eu: ["7GEU"],
                        uk: ["7GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8G",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Girl)",
                      equivalent_sizes: {
                        au: ["8GAU"],
                        eu: ["8GEU"],
                        uk: ["8GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10G",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Girl)",
                      equivalent_sizes: {
                        au: ["10GAU"],
                        eu: ["10GEU"],
                        uk: ["10GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12G",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Girl)",
                      equivalent_sizes: {
                        au: ["12GAU"],
                        eu: ["12GEU"],
                        uk: ["12GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14G",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Girl)",
                      equivalent_sizes: {
                        au: ["14GAU"],
                        eu: ["14GEU"],
                        uk: ["14GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16G",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Girl)",
                      equivalent_sizes: {
                        au: ["16GAU"],
                        eu: ["16GEU"],
                        uk: ["16GUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSG",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Girl)",
                      equivalent_sizes: {
                        au: ["XSGAU"],
                        eu: ["XSGEU"],
                        uk: ["XSGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SG",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Girl)",
                      equivalent_sizes: {
                        au: ["SGAU"],
                        eu: ["SGEU"],
                        uk: ["SGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MG",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Girl)",
                      equivalent_sizes: {
                        au: ["MGAU"],
                        eu: ["MGEU"],
                        uk: ["MGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LG",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Girl)",
                      equivalent_sizes: {
                        au: ["LGAU"],
                        eu: ["LGEU"],
                        uk: ["LGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLG",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Girl)",
                      equivalent_sizes: {
                        au: ["XLGAU"],
                        eu: ["XLGEU"],
                        uk: ["XLGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLG",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Girl)",
                      equivalent_sizes: {
                        au: ["XXLGAU"],
                        eu: ["XXLGEU"],
                        uk: ["XXLGUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "3GAU",
                      short: "3 (Girl)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Girl)",
                      display_with_size_system: "AU 3",
                      display_with_system_and_set: "AU 3 (Girl)",
                      equivalent_sizes: {
                        us: ["2TG", "3TG"],
                        eu: ["2AGEU", "3AGEU"],
                        uk: ["3GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4GAU",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Girl)",
                      equivalent_sizes: {
                        us: ["4TG", "4G"],
                        eu: ["4AGEU", "4GEU"],
                        uk: ["4GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5GAU",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "AU 5",
                      display_with_system_and_set: "AU 5 (Girl)",
                      equivalent_sizes: {
                        us: ["5TG", "5G"],
                        eu: ["5AGEU", "5GEU"],
                        uk: ["5GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6GAU",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Girl)",
                      equivalent_sizes: {
                        us: ["6G"],
                        eu: ["6GEU"],
                        uk: ["6GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "7GAU",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "AU 7",
                      display_with_system_and_set: "AU 7 (Girl)",
                      equivalent_sizes: {
                        us: ["6XG", "7G"],
                        eu: ["6XGEU", "7GEU"],
                        uk: ["7GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8GAU",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Girl)",
                      equivalent_sizes: {
                        us: ["8G"],
                        eu: ["8GEU"],
                        uk: ["8GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10GAU",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Girl)",
                      equivalent_sizes: {
                        us: ["10G"],
                        eu: ["10GEU"],
                        uk: ["10GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12GAU",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Girl)",
                      equivalent_sizes: {
                        us: ["12G"],
                        eu: ["12GEU"],
                        uk: ["12GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14GAU",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Girl)",
                      equivalent_sizes: {
                        us: ["14G"],
                        eu: ["14GEU"],
                        uk: ["14GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16GAU",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Girl)",
                      equivalent_sizes: {
                        us: ["16G"],
                        eu: ["16GEU"],
                        uk: ["16GUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSGAU",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Girl)",
                      equivalent_sizes: {
                        us: ["XSG"],
                        eu: ["XSGEU"],
                        uk: ["XSGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SGAU",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Girl)",
                      equivalent_sizes: {
                        us: ["SG"],
                        eu: ["SGEU"],
                        uk: ["SGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MGAU",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Girl)",
                      equivalent_sizes: {
                        us: ["MG"],
                        eu: ["MGEU"],
                        uk: ["MGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LGAU",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Girl)",
                      equivalent_sizes: {
                        us: ["LG"],
                        eu: ["LGEU"],
                        uk: ["LGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLGAU",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Girl)",
                      equivalent_sizes: {
                        us: ["XLG"],
                        eu: ["XLGEU"],
                        uk: ["XLGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLGAU",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Girl)",
                      equivalent_sizes: {
                        us: ["XXLG"],
                        eu: ["XXLGEU"],
                        uk: ["XXLGUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "2AGEU",
                      short: "2A (Girl)",
                      long: "2A",
                      display: "2A",
                      display_with_size_set: "2A (Girl)",
                      display_with_size_system: "EU 2A",
                      display_with_system_and_set: "EU 2A (Girl)",
                      equivalent_sizes: {
                        us: ["2TG"],
                        au: ["3GAU"],
                        uk: ["3GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3AGEU",
                      short: "3A (Girl)",
                      long: "3A",
                      display: "3A",
                      display_with_size_set: "3A (Girl)",
                      display_with_size_system: "EU 3A",
                      display_with_system_and_set: "EU 3A (Girl)",
                      equivalent_sizes: {
                        us: ["3TG"],
                        au: ["3GAU"],
                        uk: ["3GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4AGEU",
                      short: "4A (Girl)",
                      long: "4A",
                      display: "4A",
                      display_with_size_set: "4A (Girl)",
                      display_with_size_system: "EU 4A",
                      display_with_system_and_set: "EU 4A (Girl)",
                      equivalent_sizes: {
                        us: ["4TG"],
                        au: ["4GAU"],
                        uk: ["4GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4GEU",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "EU 4",
                      display_with_system_and_set: "EU 4 (Girl)",
                      equivalent_sizes: {
                        us: ["4G"],
                        au: ["4GAU"],
                        uk: ["4GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5AGEU",
                      short: "5A (Girl)",
                      long: "5A",
                      display: "5A",
                      display_with_size_set: "5A (Girl)",
                      display_with_size_system: "EU 5A",
                      display_with_system_and_set: "EU 5A (Girl)",
                      equivalent_sizes: {
                        us: ["5TG"],
                        au: ["5GAU"],
                        uk: ["5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5GEU",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "EU 5",
                      display_with_system_and_set: "EU 5 (Girl)",
                      equivalent_sizes: {
                        us: ["5G"],
                        au: ["5GAU"],
                        uk: ["5GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6XGEU",
                      short: "6X (Girl)",
                      long: "6X",
                      display: "6X",
                      display_with_size_set: "6X (Girl)",
                      display_with_size_system: "EU 6X",
                      display_with_system_and_set: "EU 6X (Girl)",
                      equivalent_sizes: {
                        us: ["6XG"],
                        au: ["7GAU"],
                        uk: ["7GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6GEU",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "EU 6",
                      display_with_system_and_set: "EU 6 (Girl)",
                      equivalent_sizes: {
                        us: ["6G"],
                        au: ["6GAU"],
                        uk: ["6GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "7GEU",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "EU 7",
                      display_with_system_and_set: "EU 7 (Girl)",
                      equivalent_sizes: {
                        us: ["7G"],
                        au: ["7GAU"],
                        uk: ["7GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "8GEU",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "EU 8",
                      display_with_system_and_set: "EU 8 (Girl)",
                      equivalent_sizes: {
                        us: ["8G"],
                        au: ["8GAU"],
                        uk: ["8GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "10GEU",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "EU 10",
                      display_with_system_and_set: "EU 10 (Girl)",
                      equivalent_sizes: {
                        us: ["10G"],
                        au: ["10GAU"],
                        uk: ["10GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12GEU",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "EU 12",
                      display_with_system_and_set: "EU 12 (Girl)",
                      equivalent_sizes: {
                        us: ["12G"],
                        au: ["12GAU"],
                        uk: ["12GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "14GEU",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "EU 14",
                      display_with_system_and_set: "EU 14 (Girl)",
                      equivalent_sizes: {
                        us: ["14G"],
                        au: ["14GAU"],
                        uk: ["14GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "16GEU",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "EU 16",
                      display_with_system_and_set: "EU 16 (Girl)",
                      equivalent_sizes: {
                        us: ["16G"],
                        au: ["16GAU"],
                        uk: ["16GUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSGEU",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Girl)",
                      equivalent_sizes: {
                        us: ["XSG"],
                        au: ["XSGAU"],
                        uk: ["XSGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SGEU",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Girl)",
                      equivalent_sizes: {
                        us: ["SG"],
                        au: ["SGAU"],
                        uk: ["SGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MGEU",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Girl)",
                      equivalent_sizes: {
                        us: ["MG"],
                        au: ["MGAU"],
                        uk: ["MGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LGEU",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Girl)",
                      equivalent_sizes: {
                        us: ["LG"],
                        au: ["LGAU"],
                        uk: ["LGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLGEU",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Girl)",
                      equivalent_sizes: {
                        us: ["XLG"],
                        au: ["XLGAU"],
                        uk: ["XLGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLGEU",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Girl)",
                      equivalent_sizes: {
                        us: ["XXLG"],
                        au: ["XXLGAU"],
                        uk: ["XXLGUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "3GUK",
                      short: "3 (Girl)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Girl)",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3 (Girl)",
                      equivalent_sizes: {
                        us: ["2TG", "3TG"],
                        au: ["3GAU"],
                        eu: ["2AGEU", "3AGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4GUK",
                      short: "4 (Girl)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Girl)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Girl)",
                      equivalent_sizes: {
                        us: ["4TG", "4G"],
                        au: ["4GAU"],
                        eu: ["4AGEU", "4GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5GUK",
                      short: "5 (Girl)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Girl)",
                      display_with_size_system: "UK 5",
                      display_with_system_and_set: "UK 5 (Girl)",
                      equivalent_sizes: {
                        us: ["5TG", "5G"],
                        au: ["5GAU"],
                        eu: ["5AGEU", "5GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6GUK",
                      short: "6 (Girl)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Girl)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Girl)",
                      equivalent_sizes: {
                        us: ["6G"],
                        au: ["6GAU"],
                        eu: ["6GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "7GUK",
                      short: "7 (Girl)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Girl)",
                      display_with_size_system: "UK 7",
                      display_with_system_and_set: "UK 7 (Girl)",
                      equivalent_sizes: {
                        us: ["6XG", "7G"],
                        au: ["7GAU"],
                        eu: ["6XGEU", "7GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8GUK",
                      short: "8 (Girl)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Girl)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Girl)",
                      equivalent_sizes: {
                        us: ["8G"],
                        au: ["8GAU"],
                        eu: ["8GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10GUK",
                      short: "10 (Girl)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Girl)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Girl)",
                      equivalent_sizes: {
                        us: ["10G"],
                        au: ["10GAU"],
                        eu: ["10GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12GUK",
                      short: "12 (Girl)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Girl)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Girl)",
                      equivalent_sizes: {
                        us: ["12G"],
                        au: ["12GAU"],
                        eu: ["12GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14GUK",
                      short: "14 (Girl)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Girl)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Girl)",
                      equivalent_sizes: {
                        us: ["14G"],
                        au: ["14GAU"],
                        eu: ["14GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16GUK",
                      short: "16 (Girl)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Girl)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Girl)",
                      equivalent_sizes: {
                        us: ["16G"],
                        au: ["16GAU"],
                        eu: ["16GEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSGUK",
                      short: "XS (Girl)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Girl)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Girl)",
                      equivalent_sizes: {
                        us: ["XSG"],
                        au: ["XSGAU"],
                        eu: ["XSGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SGUK",
                      short: "S (Girl)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Girl)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Girl)",
                      equivalent_sizes: {
                        us: ["SG"],
                        au: ["SGAU"],
                        eu: ["SGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MGUK",
                      short: "M (Girl)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Girl)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Girl)",
                      equivalent_sizes: {
                        us: ["MG"],
                        au: ["MGAU"],
                        eu: ["MGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LGUK",
                      short: "L (Girl)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Girl)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Girl)",
                      equivalent_sizes: {
                        us: ["LG"],
                        au: ["LGAU"],
                        eu: ["LGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLGUK",
                      short: "XL (Girl)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Girl)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Girl)",
                      equivalent_sizes: {
                        us: ["XLG"],
                        au: ["XLGAU"],
                        eu: ["XLGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLGUK",
                      short: "XXL (Girl)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Girl)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Girl)",
                      equivalent_sizes: {
                        us: ["XXLG"],
                        au: ["XXLGAU"],
                        eu: ["XXLGEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "21003667d97b4e6bdb005784",
              name: "Boys",
              tags: ["boys"],
              category_id: "2d008c10d97b4e1245005764",
              measurements_chart: {
                inches: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["20.5", "33.5-35.5", "21", "21"],
                  ["21", "36-38", "21.5", "22"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["22", "38.5-41.5", "22", "23"],
                  ["23", "42-44.5", "22.5", "24"],
                  ["24", "45-46.5", "23", "25"],
                  ["25.5", "47-49.5", "23.5", "26.5"],
                  ["26.5", "50-52", "24.5", "27"],
                  ["27.5", "52.5-55.5", "25.5", "28"],
                  ["28.5", "56-57.5", "26.5", "29"],
                  ["30", "58-59.5", "28", "30.5"],
                  ["31.5", "60-61.5", "29.5", "32"],
                  ["33", "62-63.5", "30.5", "33"],
                  ["34.5", "64-65", "31.5", "34"],
                  ["22.0-23.0", "38.5-44.5", "22.0-22.5", "23.0-24.0"],
                  ["24.0-26.5", "45-52", "23.0-24.5", "25.0-27.0"],
                  ["26.5", "50-52", "24.5", "27"],
                  ["27.5", "52.5-55.5", "25.5", "28"],
                  ["28.5", "56-57.5", "26.5", "29"],
                  ["30.0-31.5", "58-61.5", "28.0-29.5", "30.5-32.0"],
                ],
                centimeters: [
                  ["Chest ", "Height ", "Waist ", "Hips "],
                  ["52.1", "85.1-90.2", "53.3", "53.3"],
                  ["53.3", "91.4-96.5", "54.6", "55.9"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["55.9", "97.8-105.4", "55.9", "58.4"],
                  ["58.4", "106.7-113.0", "57.2", "61"],
                  ["61", "114.3-118.1", "58.4", "63.5"],
                  ["64.8", "119.4-125.7", "59.7", "67.3"],
                  ["67.3", "127-132.1", "62.2", "68.6"],
                  ["69.9", "133.4-141.0", "64.8", "71.1"],
                  ["72.4", "142.2-146.1", "67.3", "73.7"],
                  ["76.2", "147.3-151.1", "71.1", "77.5"],
                  ["80", "152.4-156.2", "74.9", "81.3"],
                  ["83.8", "157.5-161.3", "77.5", "83.8"],
                  ["87.6", "162.6-165.1", "80", "86.4"],
                  ["55.9-58.4", "97.8-113.0", "55.9-57.2", "58.4-61.0"],
                  ["61.0-67.3", "114.3-132.1", "58.4-62.2", "63.5-68.6"],
                  ["67.3", "127-132.1", "62.2", "68.6"],
                  ["69.9", "133.4-141.0", "64.8", "71.1"],
                  ["72.4", "142.2-146.1", "67.3", "73.7"],
                  ["76.2-80.0", "147.3-156.2", "71.1-74.9", "77.5-81.3"],
                ],
                size_systems: [
                  ["us", "au", "eu", "uk"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["2T,3T", "3", "2A,3A", "3"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["4T,4", "4", "4A,4", "4"],
                  ["5T,5", "5", "5A,5", "5"],
                  ["6", "6", "6", "6"],
                  ["7", "7", "7", "7"],
                  ["7X,8", "8", "7X,8", "8"],
                  ["10", "10", "10", "10"],
                  ["12", "12", "12", "12"],
                  ["14", "14", "14", "14"],
                  ["16", "16", "16", "16"],
                  ["18", "18", "18", "18"],
                  ["20", "20", "20", "20"],
                  ["XS", "XS", "XS", "XS"],
                  ["S", "S", "S", "S"],
                  ["M", "M", "M", "M"],
                  ["L", "L", "L", "L"],
                  ["XL", "XL", "XL", "XL"],
                  ["XXL", "XXL", "XXL", "XXL"],
                ],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["2T", "3", "2A", "3"],
                ["3T", "3", "3A", "3"],
                ["4T", "4", "4A", "4"],
                ["5T", "5", "5A", "5"],
                ["4", "4", "4", "4"],
                ["5", "5", "5", "5"],
                ["6", "6", "6", "6"],
                ["7", "7", "7", "7"],
                ["7X", "8", "7X", "8"],
                ["8", "8", "8", "8"],
                ["10", "10", "10", "10"],
                ["12", "12", "12", "12"],
                ["14", "14", "14", "14"],
                ["16", "16", "16", "16"],
                ["18", "18", "18", "18"],
                ["20", "20", "20", "20"],
                ["XS", "XS", "XS", "XS"],
                ["S", "S", "S", "S"],
                ["M", "M", "M", "M"],
                ["L", "L", "L", "L"],
                ["XL", "XL", "XL", "XL"],
                ["XXL", "XXL", "XXL", "XXL"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "2TB",
                      short: "2T (Boy)",
                      long: "2T",
                      display: "2T",
                      display_with_size_set: "2T (Boy)",
                      display_with_size_system: "US 2T",
                      display_with_system_and_set: "US 2T (Boy)",
                      equivalent_sizes: {
                        au: ["3BAU"],
                        eu: ["2ABEU"],
                        uk: ["3BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "3TB",
                      short: "3T (Boy)",
                      long: "3T",
                      display: "3T",
                      display_with_size_set: "3T (Boy)",
                      display_with_size_system: "US 3T",
                      display_with_system_and_set: "US 3T (Boy)",
                      equivalent_sizes: {
                        au: ["3BAU"],
                        eu: ["3ABEU"],
                        uk: ["3BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4TB",
                      short: "4T (Boy)",
                      long: "4T",
                      display: "4T",
                      display_with_size_set: "4T (Boy)",
                      display_with_size_system: "US 4T",
                      display_with_system_and_set: "US 4T (Boy)",
                      equivalent_sizes: {
                        au: ["4BAU"],
                        eu: ["4ABEU"],
                        uk: ["4BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5TB",
                      short: "5T (Boy)",
                      long: "5T",
                      display: "5T",
                      display_with_size_set: "5T (Boy)",
                      display_with_size_system: "US 5T",
                      display_with_system_and_set: "US 5T (Boy)",
                      equivalent_sizes: {
                        au: ["5BAU"],
                        eu: ["5ABEU"],
                        uk: ["5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "4B",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "US 4",
                      display_with_system_and_set: "US 4 (Boy)",
                      equivalent_sizes: {
                        au: ["4BAU"],
                        eu: ["4BEU"],
                        uk: ["4BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "5B",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "US 5",
                      display_with_system_and_set: "US 5 (Boy)",
                      equivalent_sizes: {
                        au: ["5BAU"],
                        eu: ["5BEU"],
                        uk: ["5BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "6B",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "US 6",
                      display_with_system_and_set: "US 6 (Boy)",
                      equivalent_sizes: {
                        au: ["6BAU"],
                        eu: ["6BEU"],
                        uk: ["6BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7B",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "US 7",
                      display_with_system_and_set: "US 7 (Boy)",
                      equivalent_sizes: {
                        au: ["7BAU"],
                        eu: ["7BEU"],
                        uk: ["7BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "7XB",
                      short: "7X (Boy)",
                      long: "7X",
                      display: "7X",
                      display_with_size_set: "7X (Boy)",
                      display_with_size_system: "US 7X",
                      display_with_system_and_set: "US 7X (Boy)",
                      equivalent_sizes: {
                        au: ["8BAU"],
                        eu: ["7XBEU"],
                        uk: ["8BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "8B",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "US 8",
                      display_with_system_and_set: "US 8 (Boy)",
                      equivalent_sizes: {
                        au: ["8BAU"],
                        eu: ["8BEU"],
                        uk: ["8BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "10B",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "US 10",
                      display_with_system_and_set: "US 10 (Boy)",
                      equivalent_sizes: {
                        au: ["10BAU"],
                        eu: ["10BEU"],
                        uk: ["10BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "12B",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "US 12",
                      display_with_system_and_set: "US 12 (Boy)",
                      equivalent_sizes: {
                        au: ["12BAU"],
                        eu: ["12BEU"],
                        uk: ["12BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "14B",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "US 14",
                      display_with_system_and_set: "US 14 (Boy)",
                      equivalent_sizes: {
                        au: ["14BAU"],
                        eu: ["14BEU"],
                        uk: ["14BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "16B",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "US 16",
                      display_with_system_and_set: "US 16 (Boy)",
                      equivalent_sizes: {
                        au: ["16BAU"],
                        eu: ["16BEU"],
                        uk: ["16BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "18B",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "US 18",
                      display_with_system_and_set: "US 18 (Boy)",
                      equivalent_sizes: {
                        au: ["18BAU"],
                        eu: ["18BEU"],
                        uk: ["18BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "20B",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "US 20",
                      display_with_system_and_set: "US 20 (Boy)",
                      equivalent_sizes: {
                        au: ["20BAU"],
                        eu: ["20BEU"],
                        uk: ["20BUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XSB",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "US XS",
                      display_with_system_and_set: "US XS (Boy)",
                      equivalent_sizes: {
                        au: ["XSBAU"],
                        eu: ["XSBEU"],
                        uk: ["XSBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "SB",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "US S",
                      display_with_system_and_set: "US S (Boy)",
                      equivalent_sizes: {
                        au: ["SBAU"],
                        eu: ["SBEU"],
                        uk: ["SBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "MB",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "US M",
                      display_with_system_and_set: "US M (Boy)",
                      equivalent_sizes: {
                        au: ["MBAU"],
                        eu: ["MBEU"],
                        uk: ["MBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "LB",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "US L",
                      display_with_system_and_set: "US L (Boy)",
                      equivalent_sizes: {
                        au: ["LBAU"],
                        eu: ["LBEU"],
                        uk: ["LBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XLB",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "US XL",
                      display_with_system_and_set: "US XL (Boy)",
                      equivalent_sizes: {
                        au: ["XLBAU"],
                        eu: ["XLBEU"],
                        uk: ["XLBUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "XXLB",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "US XXL",
                      display_with_system_and_set: "US XXL (Boy)",
                      equivalent_sizes: {
                        au: ["XXLBAU"],
                        eu: ["XXLBEU"],
                        uk: ["XXLBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "3BAU",
                      short: "3 (Boy)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Boy)",
                      display_with_size_system: "AU 3",
                      display_with_system_and_set: "AU 3 (Boy)",
                      equivalent_sizes: {
                        us: ["2TB", "3TB"],
                        eu: ["2ABEU", "3ABEU"],
                        uk: ["3BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "4BAU",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "AU 4",
                      display_with_system_and_set: "AU 4 (Boy)",
                      equivalent_sizes: {
                        us: ["4TB", "4B"],
                        eu: ["4ABEU", "4BEU"],
                        uk: ["4BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "5BAU",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "AU 5",
                      display_with_system_and_set: "AU 5 (Boy)",
                      equivalent_sizes: {
                        us: ["5TB", "5B"],
                        eu: ["5ABEU", "5BEU"],
                        uk: ["5BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "6BAU",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "AU 6",
                      display_with_system_and_set: "AU 6 (Boy)",
                      equivalent_sizes: {
                        us: ["6B"],
                        eu: ["6BEU"],
                        uk: ["6BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "7BAU",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "AU 7",
                      display_with_system_and_set: "AU 7 (Boy)",
                      equivalent_sizes: {
                        us: ["7B"],
                        eu: ["7BEU"],
                        uk: ["7BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "8BAU",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "AU 8",
                      display_with_system_and_set: "AU 8 (Boy)",
                      equivalent_sizes: {
                        us: ["7XB", "8B"],
                        eu: ["7XBEU", "8BEU"],
                        uk: ["8BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "10BAU",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "AU 10",
                      display_with_system_and_set: "AU 10 (Boy)",
                      equivalent_sizes: {
                        us: ["10B"],
                        eu: ["10BEU"],
                        uk: ["10BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "12BAU",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "AU 12",
                      display_with_system_and_set: "AU 12 (Boy)",
                      equivalent_sizes: {
                        us: ["12B"],
                        eu: ["12BEU"],
                        uk: ["12BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "14BAU",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "AU 14",
                      display_with_system_and_set: "AU 14 (Boy)",
                      equivalent_sizes: {
                        us: ["14B"],
                        eu: ["14BEU"],
                        uk: ["14BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "16BAU",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "AU 16",
                      display_with_system_and_set: "AU 16 (Boy)",
                      equivalent_sizes: {
                        us: ["16B"],
                        eu: ["16BEU"],
                        uk: ["16BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "18BAU",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "AU 18",
                      display_with_system_and_set: "AU 18 (Boy)",
                      equivalent_sizes: {
                        us: ["18B"],
                        eu: ["18BEU"],
                        uk: ["18BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "20BAU",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "AU 20",
                      display_with_system_and_set: "AU 20 (Boy)",
                      equivalent_sizes: {
                        us: ["20B"],
                        eu: ["20BEU"],
                        uk: ["20BUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XSBAU",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "AU XS",
                      display_with_system_and_set: "AU XS (Boy)",
                      equivalent_sizes: {
                        us: ["XSB"],
                        eu: ["XSBEU"],
                        uk: ["XSBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "SBAU",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "AU S",
                      display_with_system_and_set: "AU S (Boy)",
                      equivalent_sizes: {
                        us: ["SB"],
                        eu: ["SBEU"],
                        uk: ["SBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "MBAU",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "AU M",
                      display_with_system_and_set: "AU M (Boy)",
                      equivalent_sizes: {
                        us: ["MB"],
                        eu: ["MBEU"],
                        uk: ["MBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "LBAU",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "AU L",
                      display_with_system_and_set: "AU L (Boy)",
                      equivalent_sizes: {
                        us: ["LB"],
                        eu: ["LBEU"],
                        uk: ["LBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XLBAU",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "AU XL",
                      display_with_system_and_set: "AU XL (Boy)",
                      equivalent_sizes: {
                        us: ["XLB"],
                        eu: ["XLBEU"],
                        uk: ["XLBUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "XXLBAU",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "AU XXL",
                      display_with_system_and_set: "AU XXL (Boy)",
                      equivalent_sizes: {
                        us: ["XXLB"],
                        eu: ["XXLBEU"],
                        uk: ["XXLBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "2ABEU",
                      short: "2A (Boy)",
                      long: "2A",
                      display: "2A",
                      display_with_size_set: "2A (Boy)",
                      display_with_size_system: "EU 2A",
                      display_with_system_and_set: "EU 2A (Boy)",
                      equivalent_sizes: {
                        us: ["2TB"],
                        au: ["3BAU"],
                        uk: ["3BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "3ABEU",
                      short: "3A (Boy)",
                      long: "3A",
                      display: "3A",
                      display_with_size_set: "3A (Boy)",
                      display_with_size_system: "EU 3A",
                      display_with_system_and_set: "EU 3A (Boy)",
                      equivalent_sizes: {
                        us: ["3TB"],
                        au: ["3BAU"],
                        uk: ["3BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4ABEU",
                      short: "4A (Boy)",
                      long: "4A",
                      display: "4A",
                      display_with_size_set: "4A (Boy)",
                      display_with_size_system: "EU 4A",
                      display_with_system_and_set: "EU 4A (Boy)",
                      equivalent_sizes: {
                        us: ["4TB"],
                        au: ["4BAU"],
                        uk: ["4BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "4BEU",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "EU 4",
                      display_with_system_and_set: "EU 4 (Boy)",
                      equivalent_sizes: {
                        us: ["4B"],
                        au: ["4BAU"],
                        uk: ["4BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5ABEU",
                      short: "5A (Boy)",
                      long: "5A",
                      display: "5A",
                      display_with_size_set: "5A (Boy)",
                      display_with_size_system: "EU 5A",
                      display_with_system_and_set: "EU 5A (Boy)",
                      equivalent_sizes: {
                        us: ["5TB"],
                        au: ["5BAU"],
                        uk: ["5BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "5BEU",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "EU 5",
                      display_with_system_and_set: "EU 5 (Boy)",
                      equivalent_sizes: {
                        us: ["5B"],
                        au: ["5BAU"],
                        uk: ["5BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "6BEU",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "EU 6",
                      display_with_system_and_set: "EU 6 (Boy)",
                      equivalent_sizes: {
                        us: ["6B"],
                        au: ["6BAU"],
                        uk: ["6BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "7XBEU",
                      short: "7X (Boy)",
                      long: "7X",
                      display: "7X",
                      display_with_size_set: "7X (Boy)",
                      display_with_size_system: "EU 7X",
                      display_with_system_and_set: "EU 7X (Boy)",
                      equivalent_sizes: {
                        us: ["7XB"],
                        au: ["8BAU"],
                        uk: ["8BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "7BEU",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "EU 7",
                      display_with_system_and_set: "EU 7 (Boy)",
                      equivalent_sizes: {
                        us: ["7B"],
                        au: ["7BAU"],
                        uk: ["7BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "8BEU",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "EU 8",
                      display_with_system_and_set: "EU 8 (Boy)",
                      equivalent_sizes: {
                        us: ["8B"],
                        au: ["8BAU"],
                        uk: ["8BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "10BEU",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "EU 10",
                      display_with_system_and_set: "EU 10 (Boy)",
                      equivalent_sizes: {
                        us: ["10B"],
                        au: ["10BAU"],
                        uk: ["10BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "12BEU",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "EU 12",
                      display_with_system_and_set: "EU 12 (Boy)",
                      equivalent_sizes: {
                        us: ["12B"],
                        au: ["12BAU"],
                        uk: ["12BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "14BEU",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "EU 14",
                      display_with_system_and_set: "EU 14 (Boy)",
                      equivalent_sizes: {
                        us: ["14B"],
                        au: ["14BAU"],
                        uk: ["14BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "16BEU",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "EU 16",
                      display_with_system_and_set: "EU 16 (Boy)",
                      equivalent_sizes: {
                        us: ["16B"],
                        au: ["16BAU"],
                        uk: ["16BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "18BEU",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "EU 18",
                      display_with_system_and_set: "EU 18 (Boy)",
                      equivalent_sizes: {
                        us: ["18B"],
                        au: ["18BAU"],
                        uk: ["18BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "20BEU",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "EU 20",
                      display_with_system_and_set: "EU 20 (Boy)",
                      equivalent_sizes: {
                        us: ["20B"],
                        au: ["20BAU"],
                        uk: ["20BUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XSBEU",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "EU XS",
                      display_with_system_and_set: "EU XS (Boy)",
                      equivalent_sizes: {
                        us: ["XSB"],
                        au: ["XSBAU"],
                        uk: ["XSBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "SBEU",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "EU S",
                      display_with_system_and_set: "EU S (Boy)",
                      equivalent_sizes: {
                        us: ["SB"],
                        au: ["SBAU"],
                        uk: ["SBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "MBEU",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "EU M",
                      display_with_system_and_set: "EU M (Boy)",
                      equivalent_sizes: {
                        us: ["MB"],
                        au: ["MBAU"],
                        uk: ["MBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "LBEU",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "EU L",
                      display_with_system_and_set: "EU L (Boy)",
                      equivalent_sizes: {
                        us: ["LB"],
                        au: ["LBAU"],
                        uk: ["LBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XLBEU",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "EU XL",
                      display_with_system_and_set: "EU XL (Boy)",
                      equivalent_sizes: {
                        us: ["XLB"],
                        au: ["XLBAU"],
                        uk: ["XLBUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "XXLBEU",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "EU XXL",
                      display_with_system_and_set: "EU XXL (Boy)",
                      equivalent_sizes: {
                        us: ["XXLB"],
                        au: ["XXLBAU"],
                        uk: ["XXLBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "3BUK",
                      short: "3 (Boy)",
                      long: "3",
                      display: "3",
                      display_with_size_set: "3 (Boy)",
                      display_with_size_system: "UK 3",
                      display_with_system_and_set: "UK 3 (Boy)",
                      equivalent_sizes: {
                        us: ["2TB", "3TB"],
                        au: ["3BAU"],
                        eu: ["2ABEU", "3ABEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "4BUK",
                      short: "4 (Boy)",
                      long: "4",
                      display: "4",
                      display_with_size_set: "4 (Boy)",
                      display_with_size_system: "UK 4",
                      display_with_system_and_set: "UK 4 (Boy)",
                      equivalent_sizes: {
                        us: ["4TB", "4B"],
                        au: ["4BAU"],
                        eu: ["4ABEU", "4BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "5BUK",
                      short: "5 (Boy)",
                      long: "5",
                      display: "5",
                      display_with_size_set: "5 (Boy)",
                      display_with_size_system: "UK 5",
                      display_with_system_and_set: "UK 5 (Boy)",
                      equivalent_sizes: {
                        us: ["5TB", "5B"],
                        au: ["5BAU"],
                        eu: ["5ABEU", "5BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "6BUK",
                      short: "6 (Boy)",
                      long: "6",
                      display: "6",
                      display_with_size_set: "6 (Boy)",
                      display_with_size_system: "UK 6",
                      display_with_system_and_set: "UK 6 (Boy)",
                      equivalent_sizes: {
                        us: ["6B"],
                        au: ["6BAU"],
                        eu: ["6BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "7BUK",
                      short: "7 (Boy)",
                      long: "7",
                      display: "7",
                      display_with_size_set: "7 (Boy)",
                      display_with_size_system: "UK 7",
                      display_with_system_and_set: "UK 7 (Boy)",
                      equivalent_sizes: {
                        us: ["7B"],
                        au: ["7BAU"],
                        eu: ["7BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "8BUK",
                      short: "8 (Boy)",
                      long: "8",
                      display: "8",
                      display_with_size_set: "8 (Boy)",
                      display_with_size_system: "UK 8",
                      display_with_system_and_set: "UK 8 (Boy)",
                      equivalent_sizes: {
                        us: ["7XB", "8B"],
                        au: ["8BAU"],
                        eu: ["7XBEU", "8BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "10BUK",
                      short: "10 (Boy)",
                      long: "10",
                      display: "10",
                      display_with_size_set: "10 (Boy)",
                      display_with_size_system: "UK 10",
                      display_with_system_and_set: "UK 10 (Boy)",
                      equivalent_sizes: {
                        us: ["10B"],
                        au: ["10BAU"],
                        eu: ["10BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "12BUK",
                      short: "12 (Boy)",
                      long: "12",
                      display: "12",
                      display_with_size_set: "12 (Boy)",
                      display_with_size_system: "UK 12",
                      display_with_system_and_set: "UK 12 (Boy)",
                      equivalent_sizes: {
                        us: ["12B"],
                        au: ["12BAU"],
                        eu: ["12BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "14BUK",
                      short: "14 (Boy)",
                      long: "14",
                      display: "14",
                      display_with_size_set: "14 (Boy)",
                      display_with_size_system: "UK 14",
                      display_with_system_and_set: "UK 14 (Boy)",
                      equivalent_sizes: {
                        us: ["14B"],
                        au: ["14BAU"],
                        eu: ["14BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "16BUK",
                      short: "16 (Boy)",
                      long: "16",
                      display: "16",
                      display_with_size_set: "16 (Boy)",
                      display_with_size_system: "UK 16",
                      display_with_system_and_set: "UK 16 (Boy)",
                      equivalent_sizes: {
                        us: ["16B"],
                        au: ["16BAU"],
                        eu: ["16BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "18BUK",
                      short: "18 (Boy)",
                      long: "18",
                      display: "18",
                      display_with_size_set: "18 (Boy)",
                      display_with_size_system: "UK 18",
                      display_with_system_and_set: "UK 18 (Boy)",
                      equivalent_sizes: {
                        us: ["18B"],
                        au: ["18BAU"],
                        eu: ["18BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "20BUK",
                      short: "20 (Boy)",
                      long: "20",
                      display: "20",
                      display_with_size_set: "20 (Boy)",
                      display_with_size_system: "UK 20",
                      display_with_system_and_set: "UK 20 (Boy)",
                      equivalent_sizes: {
                        us: ["20B"],
                        au: ["20BAU"],
                        eu: ["20BEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XSBUK",
                      short: "XS (Boy)",
                      long: "XS",
                      display: "XS",
                      display_with_size_set: "XS (Boy)",
                      display_with_size_system: "UK XS",
                      display_with_system_and_set: "UK XS (Boy)",
                      equivalent_sizes: {
                        us: ["XSB"],
                        au: ["XSBAU"],
                        eu: ["XSBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "SBUK",
                      short: "S (Boy)",
                      long: "S",
                      display: "S",
                      display_with_size_set: "S (Boy)",
                      display_with_size_system: "UK S",
                      display_with_system_and_set: "UK S (Boy)",
                      equivalent_sizes: {
                        us: ["SB"],
                        au: ["SBAU"],
                        eu: ["SBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "MBUK",
                      short: "M (Boy)",
                      long: "M",
                      display: "M",
                      display_with_size_set: "M (Boy)",
                      display_with_size_system: "UK M",
                      display_with_system_and_set: "UK M (Boy)",
                      equivalent_sizes: {
                        us: ["MB"],
                        au: ["MBAU"],
                        eu: ["MBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "LBUK",
                      short: "L (Boy)",
                      long: "L",
                      display: "L",
                      display_with_size_set: "L (Boy)",
                      display_with_size_system: "UK L",
                      display_with_system_and_set: "UK L (Boy)",
                      equivalent_sizes: {
                        us: ["LB"],
                        au: ["LBAU"],
                        eu: ["LBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XLBUK",
                      short: "XL (Boy)",
                      long: "XL",
                      display: "XL",
                      display_with_size_set: "XL (Boy)",
                      display_with_size_system: "UK XL",
                      display_with_system_and_set: "UK XL (Boy)",
                      equivalent_sizes: {
                        us: ["XLB"],
                        au: ["XLBAU"],
                        eu: ["XLBEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "XXLBUK",
                      short: "XXL (Boy)",
                      long: "XXL",
                      display: "XXL",
                      display_with_size_set: "XXL (Boy)",
                      display_with_size_system: "UK XXL",
                      display_with_system_and_set: "UK XXL (Boy)",
                      equivalent_sizes: {
                        us: ["XXLB"],
                        au: ["XXLBAU"],
                        eu: ["XXLBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "kids_swim",
          aliases: [],
          slug: "Swim",
          alias_slugs: [],
          parent_id: "20008c10d97b4e1245005764",
          category_features: [
            {
              id: "9b009813d97b4e3995005764",
              display: "Bikinis",
              type: "cf",
              message_id: "kids_swim_bikinis",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bikinis",
              alias_slugs: [],
              parent_id: "2d008c10d97b4e1245005764",
            },
            {
              id: "9c009813d97b4e3995005764",
              display: "Coverups",
              type: "cf",
              message_id: "kids_swim_coverups",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Coverups",
              alias_slugs: [],
              parent_id: "2d008c10d97b4e1245005764",
            },
            {
              id: "9a009813d97b4e3995005764",
              display: "One Piece",
              type: "cf",
              message_id: "kids_swim_one_piece",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "One_Piece",
              alias_slugs: [],
              parent_id: "2d008c10d97b4e1245005764",
            },
            {
              id: "9d009813d97b4e3995005764",
              display: "Rashguards",
              type: "cf",
              message_id: "kids_swim_rashguards",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Rashguards",
              alias_slugs: [],
              parent_id: "2d008c10d97b4e1245005764",
            },
            {
              id: "9e009813d97b4e3995005764",
              display: "Swim Trunks",
              type: "cf",
              message_id: "kids_swim_swim_trunks",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Swim_Trunks",
              alias_slugs: [],
              parent_id: "2d008c10d97b4e1245005764",
            },
          ],
        },
        {
          id: "31008c10d97b4e1245005764",
          display: "Other",
          size_sets: [
            {
              id: "28003667d97b4e6bdb005784",
              name: "Baby",
              tags: ["baby"],
              category_id: "31008c10d97b4e1245005764",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OSBB",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS (Baby)",
                      equivalent_sizes: {
                        au: ["OSBBAU"],
                        eu: ["OSBBEU"],
                        uk: ["OSBBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSBBAU",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS (Baby)",
                      equivalent_sizes: {
                        us: ["OSBB"],
                        eu: ["OSBBEU"],
                        uk: ["OSBBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSBBEU",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS (Baby)",
                      equivalent_sizes: {
                        us: ["OSBB"],
                        au: ["OSBBAU"],
                        uk: ["OSBBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSBBUK",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS (Baby)",
                      equivalent_sizes: {
                        us: ["OSBB"],
                        au: ["OSBBAU"],
                        eu: ["OSBBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "29003667d97b4e6bdb005784",
              name: "Boys",
              tags: ["boys"],
              category_id: "31008c10d97b4e1245005764",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OSB",
                      short: "OS (Boy)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Boy)",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS (Boy)",
                      equivalent_sizes: {
                        au: ["OSBAU"],
                        eu: ["OSBEU"],
                        uk: ["OSBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSBAU",
                      short: "OS (Boy)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Boy)",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS (Boy)",
                      equivalent_sizes: {
                        us: ["OSB"],
                        eu: ["OSBEU"],
                        uk: ["OSBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSBEU",
                      short: "OS (Boy)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Boy)",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS (Boy)",
                      equivalent_sizes: {
                        us: ["OSB"],
                        au: ["OSBAU"],
                        uk: ["OSBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSBUK",
                      short: "OS (Boy)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Boy)",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS (Boy)",
                      equivalent_sizes: {
                        us: ["OSB"],
                        au: ["OSBAU"],
                        eu: ["OSBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "2a003667d97b4e6bdb005784",
              name: "Girls",
              tags: ["girls"],
              category_id: "31008c10d97b4e1245005764",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OSG",
                      short: "OS (Girl)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Girl)",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS (Girl)",
                      equivalent_sizes: {
                        au: ["OSGAU"],
                        eu: ["OSGEU"],
                        uk: ["OSGUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSGAU",
                      short: "OS (Girl)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Girl)",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS (Girl)",
                      equivalent_sizes: {
                        us: ["OSG"],
                        eu: ["OSGEU"],
                        uk: ["OSGUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSGEU",
                      short: "OS (Girl)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Girl)",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS (Girl)",
                      equivalent_sizes: {
                        us: ["OSG"],
                        au: ["OSGAU"],
                        uk: ["OSGUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSGUK",
                      short: "OS (Girl)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Girl)",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS (Girl)",
                      equivalent_sizes: {
                        us: ["OSG"],
                        au: ["OSGAU"],
                        eu: ["OSGEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "kids_other",
          aliases: [],
          slug: "Other",
          alias_slugs: [],
          parent_id: "20008c10d97b4e1245005764",
        },
        {
          id: "727df9aaaabb083120f45ec2",
          display: "Bath, Skin & Hair",
          type: "c",
          size_sets: [
            {
              id: "d07df9aaaabb083120f45ec2",
              name: "Baby",
              tags: ["baby"],
              category_id: "727df9aaaabb083120f45ec2",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OSBB",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS (Baby)",
                      equivalent_sizes: {
                        au: ["OSBBAU"],
                        eu: ["OSBBEU"],
                        uk: ["OSBBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSBBAU",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS (Baby)",
                      equivalent_sizes: {
                        us: ["OSBB"],
                        eu: ["OSBBEU"],
                        uk: ["OSBBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSBBEU",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS (Baby)",
                      equivalent_sizes: {
                        us: ["OSBB"],
                        au: ["OSBBAU"],
                        uk: ["OSBBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSBBUK",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS (Baby)",
                      equivalent_sizes: {
                        us: ["OSBB"],
                        au: ["OSBBAU"],
                        eu: ["OSBBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "d17df9aaaabb083120f45ec2",
              name: "Boys",
              tags: ["boys"],
              category_id: "727df9aaaabb083120f45ec2",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OSB",
                      short: "OS (Boy)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Boy)",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS (Boy)",
                      equivalent_sizes: {
                        au: ["OSBAU"],
                        eu: ["OSBEU"],
                        uk: ["OSBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSBAU",
                      short: "OS (Boy)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Boy)",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS (Boy)",
                      equivalent_sizes: {
                        us: ["OSB"],
                        eu: ["OSBEU"],
                        uk: ["OSBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSBEU",
                      short: "OS (Boy)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Boy)",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS (Boy)",
                      equivalent_sizes: {
                        us: ["OSB"],
                        au: ["OSBAU"],
                        uk: ["OSBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSBUK",
                      short: "OS (Boy)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Boy)",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS (Boy)",
                      equivalent_sizes: {
                        us: ["OSB"],
                        au: ["OSBAU"],
                        eu: ["OSBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "d27df9aaaabb083120f45ec2",
              name: "Girls",
              tags: ["girls"],
              category_id: "727df9aaaabb083120f45ec2",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OSG",
                      short: "OS (Girl)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Girl)",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS (Girl)",
                      equivalent_sizes: {
                        au: ["OSGAU"],
                        eu: ["OSGEU"],
                        uk: ["OSGUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSGAU",
                      short: "OS (Girl)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Girl)",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS (Girl)",
                      equivalent_sizes: {
                        us: ["OSG"],
                        eu: ["OSGEU"],
                        uk: ["OSGUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSGEU",
                      short: "OS (Girl)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Girl)",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS (Girl)",
                      equivalent_sizes: {
                        us: ["OSG"],
                        au: ["OSGAU"],
                        uk: ["OSGUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSGUK",
                      short: "OS (Girl)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Girl)",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS (Girl)",
                      equivalent_sizes: {
                        us: ["OSG"],
                        au: ["OSGAU"],
                        eu: ["OSGEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "kids_bath_skin_hair",
          aliases: [],
          slug: "Bath,_Skin_&_Hair",
          alias_slugs: [],
          parent_id: "20008c10d97b4e1245005764",
          category_features: [
            {
              id: "b87df9aaaabb083120f45ec2",
              display: "Bath & Body",
              type: "cf",
              message_id: "kids_bath_skin_hair_bath_body",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bath_&_Body",
              alias_slugs: [],
              parent_id: "727df9aaaabb083120f45ec2",
            },
            {
              id: "b97df9aaaabb083120f45ec2",
              display: "Hair Care",
              type: "cf",
              message_id: "kids_bath_skin_hair_hair_care",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Hair_Care",
              alias_slugs: [],
              parent_id: "727df9aaaabb083120f45ec2",
            },
            {
              id: "ba7df9aaaabb083120f45ec2",
              display: "Moisturizer",
              type: "cf",
              message_id: "kids_bath_skin_hair_moisturizer",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Moisturizer",
              alias_slugs: [],
              parent_id: "727df9aaaabb083120f45ec2",
            },
            {
              id: "bb7df9aaaabb083120f45ec2",
              display: "Suncare",
              type: "cf",
              message_id: "kids_bath_skin_hair_suncare",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Suncare",
              alias_slugs: [],
              parent_id: "727df9aaaabb083120f45ec2",
            },
            {
              id: "bc7df9aaaabb083120f45ec2",
              display: "Tools",
              type: "cf",
              message_id: "kids_bath_skin_hair_tools",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Tools",
              alias_slugs: [],
              parent_id: "727df9aaaabb083120f45ec2",
            },
          ],
        },
        {
          id: "737df9aaaabb083120f45ec2",
          display: "Toys",
          type: "c",
          size_sets: [
            {
              id: "d37df9aaaabb083120f45ec2",
              name: "Baby",
              tags: ["baby"],
              category_id: "737df9aaaabb083120f45ec2",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OSBB",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS (Baby)",
                      equivalent_sizes: {
                        au: ["OSBBAU"],
                        eu: ["OSBBEU"],
                        uk: ["OSBBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSBBAU",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS (Baby)",
                      equivalent_sizes: {
                        us: ["OSBB"],
                        eu: ["OSBBEU"],
                        uk: ["OSBBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSBBEU",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS (Baby)",
                      equivalent_sizes: {
                        us: ["OSBB"],
                        au: ["OSBBAU"],
                        uk: ["OSBBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSBBUK",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS (Baby)",
                      equivalent_sizes: {
                        us: ["OSBB"],
                        au: ["OSBBAU"],
                        eu: ["OSBBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "d47df9aaaabb083120f45ec2",
              name: "Boys",
              tags: ["boys"],
              category_id: "737df9aaaabb083120f45ec2",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OSB",
                      short: "OS (Boy)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Boy)",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS (Boy)",
                      equivalent_sizes: {
                        au: ["OSBAU"],
                        eu: ["OSBEU"],
                        uk: ["OSBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSBAU",
                      short: "OS (Boy)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Boy)",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS (Boy)",
                      equivalent_sizes: {
                        us: ["OSB"],
                        eu: ["OSBEU"],
                        uk: ["OSBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSBEU",
                      short: "OS (Boy)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Boy)",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS (Boy)",
                      equivalent_sizes: {
                        us: ["OSB"],
                        au: ["OSBAU"],
                        uk: ["OSBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSBUK",
                      short: "OS (Boy)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Boy)",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS (Boy)",
                      equivalent_sizes: {
                        us: ["OSB"],
                        au: ["OSBAU"],
                        eu: ["OSBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "d57df9aaaabb083120f45ec2",
              name: "Girls",
              tags: ["girls"],
              category_id: "737df9aaaabb083120f45ec2",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OSG",
                      short: "OS (Girl)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Girl)",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS (Girl)",
                      equivalent_sizes: {
                        au: ["OSGAU"],
                        eu: ["OSGEU"],
                        uk: ["OSGUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSGAU",
                      short: "OS (Girl)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Girl)",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS (Girl)",
                      equivalent_sizes: {
                        us: ["OSG"],
                        eu: ["OSGEU"],
                        uk: ["OSGUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSGEU",
                      short: "OS (Girl)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Girl)",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS (Girl)",
                      equivalent_sizes: {
                        us: ["OSG"],
                        au: ["OSGAU"],
                        uk: ["OSGUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSGUK",
                      short: "OS (Girl)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Girl)",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS (Girl)",
                      equivalent_sizes: {
                        us: ["OSG"],
                        au: ["OSGAU"],
                        eu: ["OSGEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "kids_toys",
          aliases: [],
          slug: "Toys",
          alias_slugs: [],
          parent_id: "20008c10d97b4e1245005764",
          category_features: [
            {
              id: "bd7df9aaaabb083120f45ec2",
              display: "Action Figures & Playsets",
              type: "cf",
              message_id: "kids_toys_action_figures_playsets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Action_Figures_&_Playsets",
              alias_slugs: [],
              parent_id: "737df9aaaabb083120f45ec2",
            },
            {
              id: "be7df9aaaabb083120f45ec2",
              display: "Building Sets & Blocks",
              type: "cf",
              message_id: "kids_toys_building_sets_blocks",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Building_Sets_&_Blocks",
              alias_slugs: [],
              parent_id: "737df9aaaabb083120f45ec2",
            },
            {
              id: "c37df9aaaabb083120f45ec2",
              display: "Cars & Vehicles",
              type: "cf",
              message_id: "kids_toys_cars_vehicles",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cars_&_Vehicles",
              alias_slugs: [],
              parent_id: "737df9aaaabb083120f45ec2",
            },
            {
              id: "bf7df9aaaabb083120f45ec2",
              display: "Dolls & Accessories",
              type: "cf",
              message_id: "kids_toys_dolls_accessories",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Dolls_&_Accessories",
              alias_slugs: [],
              parent_id: "737df9aaaabb083120f45ec2",
            },
            {
              id: "c07df9aaaabb083120f45ec2",
              display: "Learning Toys",
              type: "cf",
              message_id: "kids_toys_learning_toys",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Learning_Toys",
              alias_slugs: [],
              parent_id: "737df9aaaabb083120f45ec2",
            },
            {
              id: "c47df9aaaabb083120f45ec2",
              display: "Puzzles & Games",
              type: "cf",
              message_id: "kids_toys_puzzles_games",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Puzzles_&_Games",
              alias_slugs: [],
              parent_id: "737df9aaaabb083120f45ec2",
            },
            {
              id: "c17df9aaaabb083120f45ec2",
              display: "Stuffed Animals",
              type: "cf",
              message_id: "kids_toys_stuffed_animals",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Stuffed_Animals",
              alias_slugs: [],
              parent_id: "737df9aaaabb083120f45ec2",
            },
            {
              id: "c27df9aaaabb083120f45ec2",
              display: "Trading Cards",
              type: "cf",
              message_id: "kids_toys_trading_cards",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Trading_Cards",
              alias_slugs: [],
              parent_id: "737df9aaaabb083120f45ec2",
            },
          ],
        },
        {
          id: "30008c10d97b4e1245005764",
          display: "Costumes",
          size_sets: [
            {
              id: "22003667d97b4e6bdb005784",
              name: "Baby",
              tags: ["baby"],
              category_id: "30008c10d97b4e1245005764",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OSBB",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS (Baby)",
                      equivalent_sizes: {
                        au: ["OSBBAU"],
                        eu: ["OSBBEU"],
                        uk: ["OSBBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSBBAU",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS (Baby)",
                      equivalent_sizes: {
                        us: ["OSBB"],
                        eu: ["OSBBEU"],
                        uk: ["OSBBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSBBEU",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS (Baby)",
                      equivalent_sizes: {
                        us: ["OSBB"],
                        au: ["OSBBAU"],
                        uk: ["OSBBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSBBUK",
                      short: "OS (Baby)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Baby)",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS (Baby)",
                      equivalent_sizes: {
                        us: ["OSBB"],
                        au: ["OSBBAU"],
                        eu: ["OSBBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "23003667d97b4e6bdb005784",
              name: "Boys",
              tags: ["boys"],
              category_id: "30008c10d97b4e1245005764",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OSB",
                      short: "OS (Boy)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Boy)",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS (Boy)",
                      equivalent_sizes: {
                        au: ["OSBAU"],
                        eu: ["OSBEU"],
                        uk: ["OSBUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSBAU",
                      short: "OS (Boy)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Boy)",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS (Boy)",
                      equivalent_sizes: {
                        us: ["OSB"],
                        eu: ["OSBEU"],
                        uk: ["OSBUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSBEU",
                      short: "OS (Boy)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Boy)",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS (Boy)",
                      equivalent_sizes: {
                        us: ["OSB"],
                        au: ["OSBAU"],
                        uk: ["OSBUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSBUK",
                      short: "OS (Boy)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Boy)",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS (Boy)",
                      equivalent_sizes: {
                        us: ["OSB"],
                        au: ["OSBAU"],
                        eu: ["OSBEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
            {
              id: "24003667d97b4e6bdb005784",
              name: "Girls",
              tags: ["girls"],
              category_id: "30008c10d97b4e1245005764",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OSG",
                      short: "OS (Girl)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Girl)",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS (Girl)",
                      equivalent_sizes: {
                        au: ["OSGAU"],
                        eu: ["OSGEU"],
                        uk: ["OSGUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSGAU",
                      short: "OS (Girl)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Girl)",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS (Girl)",
                      equivalent_sizes: {
                        us: ["OSG"],
                        eu: ["OSGEU"],
                        uk: ["OSGUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSGEU",
                      short: "OS (Girl)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Girl)",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS (Girl)",
                      equivalent_sizes: {
                        us: ["OSG"],
                        au: ["OSGAU"],
                        uk: ["OSGUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSGUK",
                      short: "OS (Girl)",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS (Girl)",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS (Girl)",
                      equivalent_sizes: {
                        us: ["OSG"],
                        au: ["OSGAU"],
                        eu: ["OSGEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          type: "c",
          message_id: "kids_costumes",
          aliases: [],
          slug: "Costumes",
          alias_slugs: [],
          parent_id: "20008c10d97b4e1245005764",
          category_features: [
            {
              id: "1d002f3cd97b4edf70005784",
              display: "Dance",
              type: "cf",
              message_id: "kids_costumes_dance",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Dance",
              alias_slugs: [],
              parent_id: "30008c10d97b4e1245005764",
            },
            {
              id: "1e002f3cd97b4edf70005784",
              display: "Halloween",
              type: "cf",
              message_id: "kids_costumes_halloween",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Halloween",
              alias_slugs: [],
              parent_id: "30008c10d97b4e1245005764",
            },
            {
              id: "21002f3cd97b4edf70005784",
              display: "Seasonal",
              type: "cf",
              message_id: "kids_costumes_seasonal",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Seasonal",
              alias_slugs: [],
              parent_id: "30008c10d97b4e1245005764",
            },
            {
              id: "20002f3cd97b4edf70005784",
              display: "Superhero",
              type: "cf",
              message_id: "kids_costumes_superhero",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Superhero",
              alias_slugs: [],
              parent_id: "30008c10d97b4e1245005764",
            },
            {
              id: "1f002f3cd97b4edf70005784",
              display: "Theater",
              type: "cf",
              message_id: "kids_costumes_theater",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Theater",
              alias_slugs: [],
              parent_id: "30008c10d97b4e1245005764",
            },
          ],
        },
      ],
    },
    {
      id: "5b3b13d30640fd0aeb9c5cb6",
      display: "Home",
      type: "d",
      message_id: "home",
      aliases: [],
      size_sets: [],
      sizes: [],
      slug: "Home",
      alias_slugs: [],
      parent_id: "55b966335632a0146f000001",
      categories: [
        {
          id: "5c3b13d30640fd0aeb9c5cb6",
          display: "Accents",
          type: "c",
          size_sets: [
            {
              id: "a13b13d30640fd0aeb9c5cb6",
              name: "Standard",
              tags: ["standard"],
              category_id: "5c3b13d30640fd0aeb9c5cb6",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "home_accents",
          aliases: [],
          slug: "Accents",
          alias_slugs: [],
          parent_id: "5b3b13d30640fd0aeb9c5cb6",
          category_features: [
            {
              id: "643b13d30640fd0aeb9c5cb6",
              display: "Accent Pillows",
              type: "cf",
              message_id: "home_accents_accent_pillows",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Accent_Pillows",
              alias_slugs: [],
              parent_id: "5c3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "653b13d30640fd0aeb9c5cb6",
              display: "Baskets & Bins",
              type: "cf",
              message_id: "home_accents_baskets_bins",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Baskets_&_Bins",
              alias_slugs: [],
              parent_id: "5c3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "663b13d30640fd0aeb9c5cb6",
              display: "Candles & Holders",
              type: "cf",
              message_id: "home_accents_candles_holders",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Candles_&_Holders",
              alias_slugs: [],
              parent_id: "5c3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "673b13d30640fd0aeb9c5cb6",
              display: "Coffee Table Books",
              type: "cf",
              message_id: "home_accents_coffee_table_books",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Coffee_Table_Books",
              alias_slugs: [],
              parent_id: "5c3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "683b13d30640fd0aeb9c5cb6",
              display: "Curtains & Drapes",
              type: "cf",
              message_id: "home_accents_curtains_drapes",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Curtains_&_Drapes",
              alias_slugs: [],
              parent_id: "5c3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "693b13d30640fd0aeb9c5cb6",
              display: "Decor",
              type: "cf",
              message_id: "home_accents_decor",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Decor",
              alias_slugs: [],
              parent_id: "5c3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "6a3b13d30640fd0aeb9c5cb6",
              display: "Door Mats",
              type: "cf",
              message_id: "home_accents_door_mats",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Door_Mats",
              alias_slugs: [],
              parent_id: "5c3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "6b3b13d30640fd0aeb9c5cb6",
              display: "Faux Florals",
              type: "cf",
              message_id: "home_accents_faux_florals",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Faux_Florals",
              alias_slugs: [],
              parent_id: "5c3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "6c3b13d30640fd0aeb9c5cb6",
              display: "Furniture Covers",
              type: "cf",
              message_id: "home_accents_furniture_covers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Furniture_Covers",
              alias_slugs: [],
              parent_id: "5c3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "b65eb22d0640fd1ab51c5d0b",
              display: "Lanterns",
              type: "cf",
              message_id: "home_accents_lanterns",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Lanterns",
              alias_slugs: [],
              parent_id: "5c3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "6d3b13d30640fd0aeb9c5cb6",
              display: "Picture Frames",
              type: "cf",
              message_id: "home_accents_picture_frames",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Picture_Frames",
              alias_slugs: [],
              parent_id: "5c3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "b55eb22d0640fd1ab51c5d0b",
              display: "Vases",
              type: "cf",
              message_id: "home_accents_vases",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Vases",
              alias_slugs: [],
              parent_id: "5c3b13d30640fd0aeb9c5cb6",
            },
          ],
        },
        {
          id: "c66f798f402403a7f0016033",
          display: "Art",
          type: "c",
          size_sets: [
            {
              id: "e06f7990402403a7f0016033",
              name: "Standard",
              tags: ["standard"],
              category_id: "c66f798f402403a7f0016033",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "home_art",
          aliases: [],
          slug: "Art",
          alias_slugs: [],
          parent_id: "5b3b13d30640fd0aeb9c5cb6",
          category_features: [
            {
              id: "c86f798f402403a7f0016033",
              display: "Ceramics",
              type: "cf",
              message_id: "home_art_ceramics",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Ceramics",
              alias_slugs: [],
              parent_id: "c66f798f402403a7f0016033",
            },
            {
              id: "c96f798f402403a7f0016033",
              display: "Drawing & Illustrations",
              type: "cf",
              message_id: "home_art_drawing_illustrations",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Drawing_&_Illustrations",
              alias_slugs: [],
              parent_id: "c66f798f402403a7f0016033",
            },
            {
              id: "ca6f798f402403a7f0016033",
              display: "Fiber Arts",
              type: "cf",
              message_id: "home_art_fiber_arts",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Fiber_Arts",
              alias_slugs: [],
              parent_id: "c66f798f402403a7f0016033",
            },
            {
              id: "cb6f7990402403a7f0016033",
              display: "Glass Art",
              type: "cf",
              message_id: "home_art_glass_art",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Glass_Art",
              alias_slugs: [],
              parent_id: "c66f798f402403a7f0016033",
            },
            {
              id: "cc6f7990402403a7f0016033",
              display: "Mixed Media",
              type: "cf",
              message_id: "home_art_mixed_media",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Mixed_Media",
              alias_slugs: [],
              parent_id: "c66f798f402403a7f0016033",
            },
            {
              id: "cd6f7990402403a7f0016033",
              display: "Painting",
              type: "cf",
              message_id: "home_art_painting",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Painting",
              alias_slugs: [],
              parent_id: "c66f798f402403a7f0016033",
            },
            {
              id: "ce6f7990402403a7f0016033",
              display: "Photography",
              type: "cf",
              message_id: "home_art_photography",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Photography",
              alias_slugs: [],
              parent_id: "c66f798f402403a7f0016033",
            },
            {
              id: "cf6f7990402403a7f0016033",
              display: "Posters",
              type: "cf",
              message_id: "home_art_posters",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Posters",
              alias_slugs: [],
              parent_id: "c66f798f402403a7f0016033",
            },
            {
              id: "d06f7990402403a7f0016033",
              display: "Prints",
              type: "cf",
              message_id: "home_art_prints",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Prints",
              alias_slugs: [],
              parent_id: "c66f798f402403a7f0016033",
            },
            {
              id: "d16f7990402403a7f0016033",
              display: "Sculpture",
              type: "cf",
              message_id: "home_art_sculpture",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sculpture",
              alias_slugs: [],
              parent_id: "c66f798f402403a7f0016033",
            },
          ],
        },
        {
          id: "5d3b13d30640fd0aeb9c5cb6",
          display: "Bath",
          type: "c",
          size_sets: [
            {
              id: "a23b13d30640fd0aeb9c5cb6",
              name: "Standard",
              tags: ["standard"],
              category_id: "5d3b13d30640fd0aeb9c5cb6",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "home_bath",
          aliases: [],
          slug: "Bath",
          alias_slugs: [],
          parent_id: "5b3b13d30640fd0aeb9c5cb6",
          category_features: [
            {
              id: "b95eb22d0640fd1ab51c5d0b",
              display: "Bath Accessories",
              type: "cf",
              message_id: "home_bath_bath_accessories",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bath_Accessories",
              alias_slugs: [],
              parent_id: "5d3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "6e3b13d30640fd0aeb9c5cb6",
              display: "Bath Storage",
              type: "cf",
              message_id: "home_bath_bath_storage",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bath_Storage",
              alias_slugs: [],
              parent_id: "5d3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "6f3b13d30640fd0aeb9c5cb6",
              display: "Bath Towels",
              type: "cf",
              message_id: "home_bath_bath_towels",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bath_Towels",
              alias_slugs: [],
              parent_id: "5d3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "703b13d30640fd0aeb9c5cb6",
              display: "Beach Towels",
              type: "cf",
              message_id: "home_bath_beach_towels",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Beach_Towels",
              alias_slugs: [],
              parent_id: "5d3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "713b13d30640fd0aeb9c5cb6",
              display: "Hand Towels",
              type: "cf",
              message_id: "home_bath_hand_towels",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Hand_Towels",
              alias_slugs: [],
              parent_id: "5d3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "723b13d30640fd0aeb9c5cb6",
              display: "Mats",
              type: "cf",
              message_id: "home_bath_mats",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Mats",
              alias_slugs: [],
              parent_id: "5d3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "733b13d30640fd0aeb9c5cb6",
              display: "Shower Curtains",
              type: "cf",
              message_id: "home_bath_shower_curtains",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Shower_Curtains",
              alias_slugs: [],
              parent_id: "5d3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "ba5eb22d0640fd1ab51c5d0b",
              display: "Vanity Mirrors",
              type: "cf",
              message_id: "home_bath_vanity_mirrors",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Vanity_Mirrors",
              alias_slugs: [],
              parent_id: "5d3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "743b13d30640fd0aeb9c5cb6",
              display: "Vanity Trays",
              type: "cf",
              message_id: "home_bath_vanity_trays",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Vanity_Trays",
              alias_slugs: [],
              parent_id: "5d3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "753b13d30640fd0aeb9c5cb6",
              display: "Wash Cloths",
              type: "cf",
              message_id: "home_bath_wash_cloths",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Wash_Cloths",
              alias_slugs: [],
              parent_id: "5d3b13d30640fd0aeb9c5cb6",
            },
          ],
        },
        {
          id: "5e3b13d30640fd0aeb9c5cb6",
          display: "Bedding",
          type: "c",
          size_sets: [
            {
              id: "a33b13d30640fd0aeb9c5cb6",
              name: "Standard",
              tags: ["standard"],
              category_id: "5e3b13d30640fd0aeb9c5cb6",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
                ["Twin", "Twin", "Twin", "Twin"],
                ["Twin XL", "Twin XL", "Twin XL", "Twin XL"],
                ["Full", "Full", "Full", "Full"],
                ["Queen", "Queen", "Queen", "Queen"],
                ["King", "King", "King", "King"],
                ["Cal King", "Cal King", "Cal King", "Cal King"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "TWIN",
                      short: "Twin",
                      long: "Twin",
                      display: "Twin",
                      display_with_size_set: "Twin",
                      display_with_size_system: "US Twin",
                      display_with_system_and_set: "US Twin",
                      equivalent_sizes: {
                        au: ["TWINAU"],
                        eu: ["TWINEU"],
                        uk: ["TWINUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "TWINXL",
                      short: "Twin XL",
                      long: "Twin XL",
                      display: "Twin XL",
                      display_with_size_set: "Twin XL",
                      display_with_size_system: "US Twin XL",
                      display_with_system_and_set: "US Twin XL",
                      equivalent_sizes: {
                        au: ["TWINXLAU"],
                        eu: ["TWINXLEU"],
                        uk: ["TWINXLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "FULL",
                      short: "Full",
                      long: "Full",
                      display: "Full",
                      display_with_size_set: "Full",
                      display_with_size_system: "US Full",
                      display_with_system_and_set: "US Full",
                      equivalent_sizes: {
                        au: ["FULLAU"],
                        eu: ["FULLEU"],
                        uk: ["FULLUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "QUEEN",
                      short: "Queen",
                      long: "Queen",
                      display: "Queen",
                      display_with_size_set: "Queen",
                      display_with_size_system: "US Queen",
                      display_with_system_and_set: "US Queen",
                      equivalent_sizes: {
                        au: ["QUEENAU"],
                        eu: ["QUEENEU"],
                        uk: ["QUEENUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "KING",
                      short: "King",
                      long: "King",
                      display: "King",
                      display_with_size_set: "King",
                      display_with_size_system: "US King",
                      display_with_system_and_set: "US King",
                      equivalent_sizes: {
                        au: ["KINGAU"],
                        eu: ["KINGEU"],
                        uk: ["KINGUK"],
                      },
                      size_system: "us",
                    },
                    {
                      id: "CALKING",
                      short: "Cal King",
                      long: "Cal King",
                      display: "Cal King",
                      display_with_size_set: "Cal King",
                      display_with_size_system: "US Cal",
                      display_with_system_and_set: "US Cal King",
                      equivalent_sizes: {
                        au: ["CALKINGAU"],
                        eu: ["CALKINGEU"],
                        uk: ["CALKINGUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "TWINAU",
                      short: "Twin",
                      long: "Twin",
                      display: "Twin",
                      display_with_size_set: "Twin",
                      display_with_size_system: "AU Twin",
                      display_with_system_and_set: "AU Twin",
                      equivalent_sizes: {
                        us: ["TWIN"],
                        eu: ["TWINEU"],
                        uk: ["TWINUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "TWINXLAU",
                      short: "Twin XL",
                      long: "Twin XL",
                      display: "Twin XL",
                      display_with_size_set: "Twin XL",
                      display_with_size_system: "AU Twin XL",
                      display_with_system_and_set: "AU Twin XL",
                      equivalent_sizes: {
                        us: ["TWINXL"],
                        eu: ["TWINXLEU"],
                        uk: ["TWINXLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "FULLAU",
                      short: "Full",
                      long: "Full",
                      display: "Full",
                      display_with_size_set: "Full",
                      display_with_size_system: "AU Full",
                      display_with_system_and_set: "AU Full",
                      equivalent_sizes: {
                        us: ["FULL"],
                        eu: ["FULLEU"],
                        uk: ["FULLUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "QUEENAU",
                      short: "Queen",
                      long: "Queen",
                      display: "Queen",
                      display_with_size_set: "Queen",
                      display_with_size_system: "AU Queen",
                      display_with_system_and_set: "AU Queen",
                      equivalent_sizes: {
                        us: ["QUEEN"],
                        eu: ["QUEENEU"],
                        uk: ["QUEENUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "KINGAU",
                      short: "King",
                      long: "King",
                      display: "King",
                      display_with_size_set: "King",
                      display_with_size_system: "AU King",
                      display_with_system_and_set: "AU King",
                      equivalent_sizes: {
                        us: ["KING"],
                        eu: ["KINGEU"],
                        uk: ["KINGUK"],
                      },
                      size_system: "au",
                    },
                    {
                      id: "CALKINGAU",
                      short: "Cal King",
                      long: "Cal King",
                      display: "Cal King",
                      display_with_size_set: "Cal King",
                      display_with_size_system: "AU Cal",
                      display_with_system_and_set: "AU Cal King",
                      equivalent_sizes: {
                        us: ["CALKING"],
                        eu: ["CALKINGEU"],
                        uk: ["CALKINGUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "TWINEU",
                      short: "Twin",
                      long: "Twin",
                      display: "Twin",
                      display_with_size_set: "Twin",
                      display_with_size_system: "EU Twin",
                      display_with_system_and_set: "EU Twin",
                      equivalent_sizes: {
                        us: ["TWIN"],
                        au: ["TWINAU"],
                        uk: ["TWINUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "TWINXLEU",
                      short: "Twin XL",
                      long: "Twin XL",
                      display: "Twin XL",
                      display_with_size_set: "Twin XL",
                      display_with_size_system: "EU Twin XL",
                      display_with_system_and_set: "EU Twin XL",
                      equivalent_sizes: {
                        us: ["TWINXL"],
                        au: ["TWINXLAU"],
                        uk: ["TWINXLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "FULLEU",
                      short: "Full",
                      long: "Full",
                      display: "Full",
                      display_with_size_set: "Full",
                      display_with_size_system: "EU Full",
                      display_with_system_and_set: "EU Full",
                      equivalent_sizes: {
                        us: ["FULL"],
                        au: ["FULLAU"],
                        uk: ["FULLUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "QUEENEU",
                      short: "Queen",
                      long: "Queen",
                      display: "Queen",
                      display_with_size_set: "Queen",
                      display_with_size_system: "EU Queen",
                      display_with_system_and_set: "EU Queen",
                      equivalent_sizes: {
                        us: ["QUEEN"],
                        au: ["QUEENAU"],
                        uk: ["QUEENUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "KINGEU",
                      short: "King",
                      long: "King",
                      display: "King",
                      display_with_size_set: "King",
                      display_with_size_system: "EU King",
                      display_with_system_and_set: "EU King",
                      equivalent_sizes: {
                        us: ["KING"],
                        au: ["KINGAU"],
                        uk: ["KINGUK"],
                      },
                      size_system: "eu",
                    },
                    {
                      id: "CALKINGEU",
                      short: "Cal King",
                      long: "Cal King",
                      display: "Cal King",
                      display_with_size_set: "Cal King",
                      display_with_size_system: "EU Cal",
                      display_with_system_and_set: "EU Cal King",
                      equivalent_sizes: {
                        us: ["CALKING"],
                        au: ["CALKINGAU"],
                        uk: ["CALKINGUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "TWINUK",
                      short: "Twin",
                      long: "Twin",
                      display: "Twin",
                      display_with_size_set: "Twin",
                      display_with_size_system: "UK Twin",
                      display_with_system_and_set: "UK Twin",
                      equivalent_sizes: {
                        us: ["TWIN"],
                        au: ["TWINAU"],
                        eu: ["TWINEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "TWINXLUK",
                      short: "Twin XL",
                      long: "Twin XL",
                      display: "Twin XL",
                      display_with_size_set: "Twin XL",
                      display_with_size_system: "UK Twin XL",
                      display_with_system_and_set: "UK Twin XL",
                      equivalent_sizes: {
                        us: ["TWINXL"],
                        au: ["TWINXLAU"],
                        eu: ["TWINXLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "FULLUK",
                      short: "Full",
                      long: "Full",
                      display: "Full",
                      display_with_size_set: "Full",
                      display_with_size_system: "UK Full",
                      display_with_system_and_set: "UK Full",
                      equivalent_sizes: {
                        us: ["FULL"],
                        au: ["FULLAU"],
                        eu: ["FULLEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "QUEENUK",
                      short: "Queen",
                      long: "Queen",
                      display: "Queen",
                      display_with_size_set: "Queen",
                      display_with_size_system: "UK Queen",
                      display_with_system_and_set: "UK Queen",
                      equivalent_sizes: {
                        us: ["QUEEN"],
                        au: ["QUEENAU"],
                        eu: ["QUEENEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "KINGUK",
                      short: "King",
                      long: "King",
                      display: "King",
                      display_with_size_set: "King",
                      display_with_size_system: "UK King",
                      display_with_system_and_set: "UK King",
                      equivalent_sizes: {
                        us: ["KING"],
                        au: ["KINGAU"],
                        eu: ["KINGEU"],
                      },
                      size_system: "uk",
                    },
                    {
                      id: "CALKINGUK",
                      short: "Cal King",
                      long: "Cal King",
                      display: "Cal King",
                      display_with_size_set: "Cal King",
                      display_with_size_system: "UK Cal",
                      display_with_system_and_set: "UK Cal King",
                      equivalent_sizes: {
                        us: ["CALKING"],
                        au: ["CALKINGAU"],
                        eu: ["CALKINGEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "home_bedding",
          aliases: [],
          slug: "Bedding",
          alias_slugs: [],
          parent_id: "5b3b13d30640fd0aeb9c5cb6",
          category_features: [
            {
              id: "763b13d30640fd0aeb9c5cb6",
              display: "Blankets & Throws",
              type: "cf",
              message_id: "home_bedding_blankets_throws",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Blankets_&_Throws",
              alias_slugs: [],
              parent_id: "5e3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "773b13d30640fd0aeb9c5cb6",
              display: "Comforters",
              type: "cf",
              message_id: "home_bedding_comforters",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Comforters",
              alias_slugs: [],
              parent_id: "5e3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "783b13d30640fd0aeb9c5cb6",
              display: "Duvet Covers",
              type: "cf",
              message_id: "home_bedding_duvet_covers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Duvet_Covers",
              alias_slugs: [],
              parent_id: "5e3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "793b13d30640fd0aeb9c5cb6",
              display: "Mattress Covers",
              type: "cf",
              message_id: "home_bedding_mattress_covers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Mattress_Covers",
              alias_slugs: [],
              parent_id: "5e3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "7a3b13d30640fd0aeb9c5cb6",
              display: "Pillows",
              type: "cf",
              message_id: "home_bedding_pillows",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Pillows",
              alias_slugs: [],
              parent_id: "5e3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "7b3b13d30640fd0aeb9c5cb6",
              display: "Quilts",
              type: "cf",
              message_id: "home_bedding_quilts",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Quilts",
              alias_slugs: [],
              parent_id: "5e3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "7c3b13d30640fd0aeb9c5cb6",
              display: "Sheets",
              type: "cf",
              message_id: "home_bedding_sheets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sheets",
              alias_slugs: [],
              parent_id: "5e3b13d30640fd0aeb9c5cb6",
            },
          ],
        },
        {
          id: "c76f798f402403a7f0016033",
          display: "Design",
          type: "c",
          size_sets: [
            {
              id: "e16f7990402403a7f0016033",
              name: "Standard",
              tags: ["standard"],
              category_id: "c76f798f402403a7f0016033",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "home_design",
          aliases: [],
          slug: "Design",
          alias_slugs: [],
          parent_id: "5b3b13d30640fd0aeb9c5cb6",
          category_features: [
            {
              id: "d26f7990402403a7f0016033",
              display: "Birthday Cards",
              type: "cf",
              message_id: "home_design_birthday_cards",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Birthday_Cards",
              alias_slugs: [],
              parent_id: "c76f798f402403a7f0016033",
            },
            {
              id: "d36f7990402403a7f0016033",
              display: "Business Cards",
              type: "cf",
              message_id: "home_design_business_cards",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Business_Cards",
              alias_slugs: [],
              parent_id: "c76f798f402403a7f0016033",
            },
            {
              id: "d46f7990402403a7f0016033",
              display: "Planners",
              type: "cf",
              message_id: "home_design_planners",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Planners",
              alias_slugs: [],
              parent_id: "c76f798f402403a7f0016033",
            },
            {
              id: "d56f7990402403a7f0016033",
              display: "Stamps",
              type: "cf",
              message_id: "home_design_stamps",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Stamps",
              alias_slugs: [],
              parent_id: "c76f798f402403a7f0016033",
            },
            {
              id: "d66f7990402403a7f0016033",
              display: "Stickers",
              type: "cf",
              message_id: "home_design_stickers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Stickers",
              alias_slugs: [],
              parent_id: "c76f798f402403a7f0016033",
            },
            {
              id: "d76f7990402403a7f0016033",
              display: "Thank You Cards",
              type: "cf",
              message_id: "home_design_thank_you_cards",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Thank_You_Cards",
              alias_slugs: [],
              parent_id: "c76f798f402403a7f0016033",
            },
          ],
        },
        {
          id: "bc5eb22d0640fd1ab51c5d0b",
          display: "Dining",
          type: "c",
          size_sets: [
            {
              id: "cd5eb22d0640fd1ab51c5d0b",
              name: "Standard",
              tags: ["standard"],
              category_id: "bc5eb22d0640fd1ab51c5d0b",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "home_dining",
          aliases: [],
          slug: "Dining",
          alias_slugs: [],
          parent_id: "5b3b13d30640fd0aeb9c5cb6",
          category_features: [
            {
              id: "bd5eb22d0640fd1ab51c5d0b",
              display: "Bar Accessories",
              type: "cf",
              message_id: "home_dining_bar_accessories",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bar_Accessories",
              alias_slugs: [],
              parent_id: "bc5eb22d0640fd1ab51c5d0b",
            },
            {
              id: "be5eb22d0640fd1ab51c5d0b",
              display: "Dinnerware",
              type: "cf",
              message_id: "home_dining_dinnerware",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Dinnerware",
              alias_slugs: [],
              parent_id: "bc5eb22d0640fd1ab51c5d0b",
            },
            {
              id: "bf5eb22d0640fd1ab51c5d0b",
              display: "Drinkware",
              type: "cf",
              message_id: "home_dining_drinkware",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Drinkware",
              alias_slugs: [],
              parent_id: "bc5eb22d0640fd1ab51c5d0b",
            },
            {
              id: "c05eb22d0640fd1ab51c5d0b",
              display: "Flatware",
              type: "cf",
              message_id: "home_dining_flatware",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Flatware",
              alias_slugs: [],
              parent_id: "bc5eb22d0640fd1ab51c5d0b",
            },
            {
              id: "c67df9aaaabb083120f45ec2",
              display: "Mugs",
              type: "cf",
              message_id: "home_dining_mugs",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Mugs",
              alias_slugs: [],
              parent_id: "bc5eb22d0640fd1ab51c5d0b",
            },
            {
              id: "c15eb22d0640fd1ab51c5d0b",
              display: "Serveware",
              type: "cf",
              message_id: "home_dining_serveware",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Serveware",
              alias_slugs: [],
              parent_id: "bc5eb22d0640fd1ab51c5d0b",
            },
            {
              id: "c25eb22d0640fd1ab51c5d0b",
              display: "Serving Utensils",
              type: "cf",
              message_id: "home_dining_serving_utensils",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Serving_Utensils",
              alias_slugs: [],
              parent_id: "bc5eb22d0640fd1ab51c5d0b",
            },
            {
              id: "c35eb22d0640fd1ab51c5d0b",
              display: "Table Linens",
              type: "cf",
              message_id: "home_dining_table_linens",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Table_Linens",
              alias_slugs: [],
              parent_id: "bc5eb22d0640fd1ab51c5d0b",
            },
            {
              id: "c77df9aaaabb083120f45ec2",
              display: "Water Bottles & Thermoses",
              type: "cf",
              message_id: "home_dining_water_bottles_thermoses",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Water_Bottles_&_Thermoses",
              alias_slugs: [],
              parent_id: "bc5eb22d0640fd1ab51c5d0b",
            },
          ],
        },
        {
          id: "747df9aaaabb083120f45ec2",
          display: "Games",
          type: "c",
          size_sets: [
            {
              id: "d67df9aaaabb083120f45ec2",
              name: "Standard",
              tags: ["standard"],
              category_id: "747df9aaaabb083120f45ec2",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "home_games",
          aliases: [],
          slug: "Games",
          alias_slugs: [],
          parent_id: "5b3b13d30640fd0aeb9c5cb6",
          category_features: [
            {
              id: "c87df9aaaabb083120f45ec2",
              display: "Board Games",
              type: "cf",
              message_id: "home_games_board_games",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Board_Games",
              alias_slugs: [],
              parent_id: "747df9aaaabb083120f45ec2",
            },
            {
              id: "c97df9aaaabb083120f45ec2",
              display: "Card Games",
              type: "cf",
              message_id: "home_games_card_games",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Card_Games",
              alias_slugs: [],
              parent_id: "747df9aaaabb083120f45ec2",
            },
            {
              id: "cb7df9aaaabb083120f45ec2",
              display: "Outdoor Games",
              type: "cf",
              message_id: "home_games_outdoor_games",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Outdoor_Games",
              alias_slugs: [],
              parent_id: "747df9aaaabb083120f45ec2",
            },
            {
              id: "ca7df9aaaabb083120f45ec2",
              display: "Puzzles",
              type: "cf",
              message_id: "home_games_puzzles",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Puzzles",
              alias_slugs: [],
              parent_id: "747df9aaaabb083120f45ec2",
            },
          ],
        },
        {
          id: "5f3b13d30640fd0aeb9c5cb6",
          display: "Holiday",
          type: "c",
          size_sets: [
            {
              id: "a43b13d30640fd0aeb9c5cb6",
              name: "Standard",
              tags: ["standard"],
              category_id: "5f3b13d30640fd0aeb9c5cb6",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "home_holiday",
          aliases: [],
          slug: "Holiday",
          alias_slugs: [],
          parent_id: "5b3b13d30640fd0aeb9c5cb6",
          category_features: [
            {
              id: "7d3b13d30640fd0aeb9c5cb6",
              display: "Garland",
              type: "cf",
              message_id: "home_holiday_garland",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Garland",
              alias_slugs: [],
              parent_id: "5f3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "7e3b13d30640fd0aeb9c5cb6",
              display: "Holiday Blankets & Throws",
              type: "cf",
              message_id: "home_holiday_holiday_blankets_throws",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Holiday_Blankets_&_Throws",
              alias_slugs: [],
              parent_id: "5f3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "7f3b13d30640fd0aeb9c5cb6",
              display: "Holiday Decor",
              type: "cf",
              message_id: "home_holiday_holiday_decor",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Holiday_Decor",
              alias_slugs: [],
              parent_id: "5f3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "803b13d30640fd0aeb9c5cb6",
              display: "Holiday Pillows",
              type: "cf",
              message_id: "home_holiday_holiday_pillows",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Holiday_Pillows",
              alias_slugs: [],
              parent_id: "5f3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "813b13d30640fd0aeb9c5cb6",
              display: "Ornaments",
              type: "cf",
              message_id: "home_holiday_ornaments",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Ornaments",
              alias_slugs: [],
              parent_id: "5f3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "823b13d30640fd0aeb9c5cb6",
              display: "String Lights",
              type: "cf",
              message_id: "home_holiday_string_lights",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "String_Lights",
              alias_slugs: [],
              parent_id: "5f3b13d30640fd0aeb9c5cb6",
            },
            {
              id: "833b13d30640fd0aeb9c5cb6",
              display: "Wreaths",
              type: "cf",
              message_id: "home_holiday_wreaths",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Wreaths",
              alias_slugs: [],
              parent_id: "5f3b13d30640fd0aeb9c5cb6",
            },
          ],
        },
        {
          id: "bb5eb22d0640fd1ab51c5d0b",
          display: "Kitchen",
          type: "c",
          size_sets: [
            {
              id: "ce5eb22d0640fd1ab51c5d0b",
              name: "Standard",
              tags: ["standard"],
              category_id: "bb5eb22d0640fd1ab51c5d0b",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "home_kitchen",
          aliases: [],
          slug: "Kitchen",
          alias_slugs: [],
          parent_id: "5b3b13d30640fd0aeb9c5cb6",
          category_features: [
            {
              id: "cc7df9aaaabb083120f45ec2",
              display: "BBQ & Grilling Tools",
              type: "cf",
              message_id: "home_kitchen_bbq_grilling_tools",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "BBQ_&_Grilling_Tools",
              alias_slugs: [],
              parent_id: "bb5eb22d0640fd1ab51c5d0b",
            },
            {
              id: "c45eb22d0640fd1ab51c5d0b",
              display: "Bakeware",
              type: "cf",
              message_id: "home_kitchen_bakeware",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bakeware",
              alias_slugs: [],
              parent_id: "bb5eb22d0640fd1ab51c5d0b",
            },
            {
              id: "c65eb22d0640fd1ab51c5d0b",
              display: "Coffee & Tea Accessories",
              type: "cf",
              message_id: "home_kitchen_coffee_tea_accessories",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Coffee_&_Tea_Accessories",
              alias_slugs: [],
              parent_id: "bb5eb22d0640fd1ab51c5d0b",
            },
            {
              id: "c75eb22d0640fd1ab51c5d0b",
              display: "Cookbooks",
              type: "cf",
              message_id: "home_kitchen_cookbooks",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cookbooks",
              alias_slugs: [],
              parent_id: "bb5eb22d0640fd1ab51c5d0b",
            },
            {
              id: "c85eb22d0640fd1ab51c5d0b",
              display: "Cooking Utensils",
              type: "cf",
              message_id: "home_kitchen_cooking_utensils",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cooking_Utensils",
              alias_slugs: [],
              parent_id: "bb5eb22d0640fd1ab51c5d0b",
            },
            {
              id: "c95eb22d0640fd1ab51c5d0b",
              display: "Cookware",
              type: "cf",
              message_id: "home_kitchen_cookware",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cookware",
              alias_slugs: [],
              parent_id: "bb5eb22d0640fd1ab51c5d0b",
            },
            {
              id: "c55eb22d0640fd1ab51c5d0b",
              display: "Food Storage",
              type: "cf",
              message_id: "home_kitchen_food_storage",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Food_Storage",
              alias_slugs: [],
              parent_id: "bb5eb22d0640fd1ab51c5d0b",
            },
            {
              id: "cb5eb22d0640fd1ab51c5d0b",
              display: "Kitchen Linens",
              type: "cf",
              message_id: "home_kitchen_kitchen_linens",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Kitchen_Linens",
              alias_slugs: [],
              parent_id: "bb5eb22d0640fd1ab51c5d0b",
            },
            {
              id: "ca5eb22d0640fd1ab51c5d0b",
              display: "Kitchen Tools",
              type: "cf",
              message_id: "home_kitchen_kitchen_tools",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Kitchen_Tools",
              alias_slugs: [],
              parent_id: "bb5eb22d0640fd1ab51c5d0b",
            },
            {
              id: "cc5eb22d0640fd1ab51c5d0b",
              display: "Knives & Cutlery",
              type: "cf",
              message_id: "home_kitchen_knives_cutlery",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Knives_&_Cutlery",
              alias_slugs: [],
              parent_id: "bb5eb22d0640fd1ab51c5d0b",
            },
          ],
        },
        {
          id: "603b13d30640fd0aeb9c5cb6",
          display: "Office",
          type: "c",
          size_sets: [
            {
              id: "a53b13d30640fd0aeb9c5cb6",
              name: "Standard",
              tags: ["standard"],
              category_id: "603b13d30640fd0aeb9c5cb6",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "home_office",
          aliases: [],
          slug: "Office",
          alias_slugs: [],
          parent_id: "5b3b13d30640fd0aeb9c5cb6",
          category_features: [
            {
              id: "843b13d30640fd0aeb9c5cb6",
              display: "Arts & Crafts",
              type: "cf",
              message_id: "home_office_arts_crafts",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Arts_&_Crafts",
              alias_slugs: [],
              parent_id: "603b13d30640fd0aeb9c5cb6",
            },
            {
              id: "853b13d30640fd0aeb9c5cb6",
              display: "Binders & Folders",
              type: "cf",
              message_id: "home_office_binders_folders",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Binders_&_Folders",
              alias_slugs: [],
              parent_id: "603b13d30640fd0aeb9c5cb6",
            },
            {
              id: "863b13d30640fd0aeb9c5cb6",
              display: "Calendars",
              type: "cf",
              message_id: "home_office_calendars",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Calendars",
              alias_slugs: [],
              parent_id: "603b13d30640fd0aeb9c5cb6",
            },
            {
              id: "873b13d30640fd0aeb9c5cb6",
              display: "Labels & Label Makers",
              type: "cf",
              message_id: "home_office_labels_label_makers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Labels_&_Label_Makers",
              alias_slugs: [],
              parent_id: "603b13d30640fd0aeb9c5cb6",
            },
            {
              id: "883b13d30640fd0aeb9c5cb6",
              display: "Notebooks & Journals",
              type: "cf",
              message_id: "home_office_notebooks_journals",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Notebooks_&_Journals",
              alias_slugs: [],
              parent_id: "603b13d30640fd0aeb9c5cb6",
            },
            {
              id: "893b13d30640fd0aeb9c5cb6",
              display: "Pencil Cases",
              type: "cf",
              message_id: "home_office_pencil_cases",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Pencil_Cases",
              alias_slugs: [],
              parent_id: "603b13d30640fd0aeb9c5cb6",
            },
            {
              id: "8a3b13d30640fd0aeb9c5cb6",
              display: "Planners",
              type: "cf",
              message_id: "home_office_planners",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Planners",
              alias_slugs: [],
              parent_id: "603b13d30640fd0aeb9c5cb6",
            },
            {
              id: "8b3b13d30640fd0aeb9c5cb6",
              display: "Shipping Supplies",
              type: "cf",
              message_id: "home_office_shipping_supplies",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Shipping_Supplies",
              alias_slugs: [],
              parent_id: "603b13d30640fd0aeb9c5cb6",
            },
            {
              id: "8c3b13d30640fd0aeb9c5cb6",
              display: "Stationery",
              type: "cf",
              message_id: "home_office_stationery",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Stationery",
              alias_slugs: [],
              parent_id: "603b13d30640fd0aeb9c5cb6",
            },
          ],
        },
        {
          id: "613b13d30640fd0aeb9c5cb6",
          display: "Party Supplies",
          type: "c",
          size_sets: [
            {
              id: "a63b13d30640fd0aeb9c5cb6",
              name: "Standard",
              tags: ["standard"],
              category_id: "613b13d30640fd0aeb9c5cb6",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "home_party_supplies",
          aliases: [],
          slug: "Party_Supplies",
          alias_slugs: [],
          parent_id: "5b3b13d30640fd0aeb9c5cb6",
          category_features: [
            {
              id: "8d3b13d30640fd0aeb9c5cb6",
              display: "Cake Candles",
              type: "cf",
              message_id: "home_party_supplies_cake_candles",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cake_Candles",
              alias_slugs: [],
              parent_id: "613b13d30640fd0aeb9c5cb6",
            },
            {
              id: "8e3b13d30640fd0aeb9c5cb6",
              display: "Cake Toppers",
              type: "cf",
              message_id: "home_party_supplies_cake_toppers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cake_Toppers",
              alias_slugs: [],
              parent_id: "613b13d30640fd0aeb9c5cb6",
            },
            {
              id: "8f3b13d30640fd0aeb9c5cb6",
              display: "Cards & Invitations",
              type: "cf",
              message_id: "home_party_supplies_cards_invitations",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cards_&_Invitations",
              alias_slugs: [],
              parent_id: "613b13d30640fd0aeb9c5cb6",
            },
            {
              id: "903b13d30640fd0aeb9c5cb6",
              display: "Decorations",
              type: "cf",
              message_id: "home_party_supplies_decorations",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Decorations",
              alias_slugs: [],
              parent_id: "613b13d30640fd0aeb9c5cb6",
            },
            {
              id: "953b13d30640fd0aeb9c5cb6",
              display: "Disposable Tableware",
              type: "cf",
              message_id: "home_party_supplies_disposable_tableware",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Disposable_Tableware",
              alias_slugs: [],
              parent_id: "613b13d30640fd0aeb9c5cb6",
            },
            {
              id: "913b13d30640fd0aeb9c5cb6",
              display: "Favors",
              type: "cf",
              message_id: "home_party_supplies_favors",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Favors",
              alias_slugs: [],
              parent_id: "613b13d30640fd0aeb9c5cb6",
            },
            {
              id: "923b13d30640fd0aeb9c5cb6",
              display: "Gift Wrap",
              type: "cf",
              message_id: "home_party_supplies_gift_wrap",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Gift_Wrap",
              alias_slugs: [],
              parent_id: "613b13d30640fd0aeb9c5cb6",
            },
            {
              id: "933b13d30640fd0aeb9c5cb6",
              display: "Hats",
              type: "cf",
              message_id: "home_party_supplies_hats",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Hats",
              alias_slugs: [],
              parent_id: "613b13d30640fd0aeb9c5cb6",
            },
            {
              id: "943b13d30640fd0aeb9c5cb6",
              display: "Party Lights",
              type: "cf",
              message_id: "home_party_supplies_party_lights",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Party_Lights",
              alias_slugs: [],
              parent_id: "613b13d30640fd0aeb9c5cb6",
            },
          ],
        },
        {
          id: "623b13d30640fd0aeb9c5cb6",
          display: "Storage & Organization",
          type: "c",
          size_sets: [
            {
              id: "a73b13d30640fd0aeb9c5cb6",
              name: "Standard",
              tags: ["standard"],
              category_id: "623b13d30640fd0aeb9c5cb6",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "home_storage_organization",
          aliases: [],
          slug: "Storage_&_Organization",
          alias_slugs: [],
          parent_id: "5b3b13d30640fd0aeb9c5cb6",
          category_features: [
            {
              id: "963b13d30640fd0aeb9c5cb6",
              display: "Closet Accessories",
              type: "cf",
              message_id: "home_storage_organization_closet_accessories",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Closet_Accessories",
              alias_slugs: [],
              parent_id: "623b13d30640fd0aeb9c5cb6",
            },
            {
              id: "973b13d30640fd0aeb9c5cb6",
              display: "Drawer Liners",
              type: "cf",
              message_id: "home_storage_organization_drawer_liners",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Drawer_Liners",
              alias_slugs: [],
              parent_id: "623b13d30640fd0aeb9c5cb6",
            },
            {
              id: "983b13d30640fd0aeb9c5cb6",
              display: "Garment Bags",
              type: "cf",
              message_id: "home_storage_organization_garment_bags",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Garment_Bags",
              alias_slugs: [],
              parent_id: "623b13d30640fd0aeb9c5cb6",
            },
            {
              id: "993b13d30640fd0aeb9c5cb6",
              display: "Jewelry Organizers",
              type: "cf",
              message_id: "home_storage_organization_jewelry_organizers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Jewelry_Organizers",
              alias_slugs: [],
              parent_id: "623b13d30640fd0aeb9c5cb6",
            },
            {
              id: "9a3b13d30640fd0aeb9c5cb6",
              display: "Makeup Organizers",
              type: "cf",
              message_id: "home_storage_organization_makeup_organizers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Makeup_Organizers",
              alias_slugs: [],
              parent_id: "623b13d30640fd0aeb9c5cb6",
            },
            {
              id: "9b3b13d30640fd0aeb9c5cb6",
              display: "Storage",
              type: "cf",
              message_id: "home_storage_organization_storage",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Storage",
              alias_slugs: [],
              parent_id: "623b13d30640fd0aeb9c5cb6",
            },
          ],
        },
        {
          id: "633b13d30640fd0aeb9c5cb6",
          display: "Wall Decor",
          type: "c",
          size_sets: [
            {
              id: "a83b13d30640fd0aeb9c5cb6",
              name: "Standard",
              tags: ["standard"],
              category_id: "633b13d30640fd0aeb9c5cb6",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          aliases: ["Wall Art"],
          message_id: "home_wall_decor",
          slug: "Wall_Decor",
          alias_slugs: ["Wall_Art"],
          parent_id: "5b3b13d30640fd0aeb9c5cb6",
          category_features: [
            {
              id: "9c3b13d30640fd0aeb9c5cb6",
              display: "Art & Decals",
              type: "cf",
              message_id: "home_wall_decor_art_decals",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Art_&_Decals",
              alias_slugs: [],
              parent_id: "633b13d30640fd0aeb9c5cb6",
            },
            {
              id: "b75eb22d0640fd1ab51c5d0b",
              display: "Clocks",
              type: "cf",
              message_id: "home_wall_decor_clocks",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Clocks",
              alias_slugs: [],
              parent_id: "633b13d30640fd0aeb9c5cb6",
            },
            {
              id: "9d3b13d30640fd0aeb9c5cb6",
              display: "Display Shelves",
              type: "cf",
              message_id: "home_wall_decor_display_shelves",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Display_Shelves",
              alias_slugs: [],
              parent_id: "633b13d30640fd0aeb9c5cb6",
            },
            {
              id: "9e3b13d30640fd0aeb9c5cb6",
              display: "Hooks",
              type: "cf",
              message_id: "home_wall_decor_hooks",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Hooks",
              alias_slugs: [],
              parent_id: "633b13d30640fd0aeb9c5cb6",
            },
            {
              id: "b85eb22d0640fd1ab51c5d0b",
              display: "Mirrors",
              type: "cf",
              message_id: "home_wall_decor_mirrors",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Mirrors",
              alias_slugs: [],
              parent_id: "633b13d30640fd0aeb9c5cb6",
            },
            {
              id: "9f3b13d30640fd0aeb9c5cb6",
              display: "Tapestries",
              type: "cf",
              message_id: "home_wall_decor_tapestries",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Tapestries",
              alias_slugs: [],
              parent_id: "633b13d30640fd0aeb9c5cb6",
            },
            {
              id: "a03b13d30640fd0aeb9c5cb6",
              display: "Wallpaper",
              type: "cf",
              message_id: "home_wall_decor_wallpaper",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Wallpaper",
              alias_slugs: [],
              parent_id: "633b13d30640fd0aeb9c5cb6",
            },
          ],
        },
        {
          id: "a93b13d30640fd0aeb9c5cb6",
          display: "Other",
          type: "c",
          size_sets: [
            {
              id: "aa3b13d30640fd0aeb9c5cb6",
              name: "Standard",
              tags: ["standard"],
              category_id: "a93b13d30640fd0aeb9c5cb6",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "home_other",
          aliases: [],
          slug: "Other",
          alias_slugs: [],
          parent_id: "5b3b13d30640fd0aeb9c5cb6",
        },
      ],
    },
    {
      id: "af08bf904024037d7a7b5fad",
      display: "Pets",
      type: "d",
      message_id: "pets",
      aliases: [],
      size_sets: [],
      sizes: [],
      slug: "Pets",
      alias_slugs: [],
      parent_id: "55b966335632a0146f000001",
      categories: [
        {
          id: "b008bf914024037d7a7b5fad",
          display: "Dog",
          type: "c",
          size_sets: [
            {
              id: "9aee8fef402403392d9d5fd0",
              name: "Standard",
              tags: ["standard"],
              category_id: "b008bf914024037d7a7b5fad",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "pets_dog",
          aliases: [],
          slug: "Dog",
          alias_slugs: [],
          parent_id: "af08bf904024037d7a7b5fad",
          category_features: [
            {
              id: "b708bf914024037d7a7b5fad",
              display: "Bedding & Blankets",
              type: "cf",
              message_id: "pets_dog_bedding_blankets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bedding_&_Blankets",
              alias_slugs: [],
              parent_id: "b008bf914024037d7a7b5fad",
            },
            {
              id: "b808bf914024037d7a7b5fad",
              display: "Bowls & Feeders",
              type: "cf",
              message_id: "pets_dog_bowls_feeders",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bowls_&_Feeders",
              alias_slugs: [],
              parent_id: "b008bf914024037d7a7b5fad",
            },
            {
              id: "b908bf914024037d7a7b5fad",
              display: "Carriers & Travel",
              type: "cf",
              message_id: "pets_dog_carriers_travel",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Carriers_&_Travel",
              alias_slugs: [],
              parent_id: "b008bf914024037d7a7b5fad",
            },
            {
              id: "ba08bf914024037d7a7b5fad",
              display: "Clothing & Accessories",
              type: "cf",
              message_id: "pets_dog_clothing_accessories",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Clothing_&_Accessories",
              alias_slugs: [],
              parent_id: "b008bf914024037d7a7b5fad",
            },
            {
              id: "bb08bf914024037d7a7b5fad",
              display: "Collars, Leashes & Harnesses",
              type: "cf",
              message_id: "pets_dog_collars_leashes_harnesses",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Collars,_Leashes_&_Harnesses",
              alias_slugs: [],
              parent_id: "b008bf914024037d7a7b5fad",
            },
            {
              id: "bc08bf914024037d7a7b5fad",
              display: "Grooming",
              type: "cf",
              message_id: "pets_dog_grooming",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Grooming",
              alias_slugs: [],
              parent_id: "b008bf914024037d7a7b5fad",
            },
            {
              id: "bd08bf914024037d7a7b5fad",
              display: "Housebreaking",
              type: "cf",
              message_id: "pets_dog_housebreaking",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Housebreaking",
              alias_slugs: [],
              parent_id: "b008bf914024037d7a7b5fad",
            },
            {
              id: "be08bf914024037d7a7b5fad",
              display: "Toys",
              type: "cf",
              message_id: "pets_dog_toys",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Toys",
              alias_slugs: [],
              parent_id: "b008bf914024037d7a7b5fad",
            },
          ],
        },
        {
          id: "b108bf914024037d7a7b5fad",
          display: "Cat",
          type: "c",
          size_sets: [
            {
              id: "9bee8fef402403392d9d5fd0",
              name: "Standard",
              tags: ["standard"],
              category_id: "b108bf914024037d7a7b5fad",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "pets_cat",
          aliases: [],
          slug: "Cat",
          alias_slugs: [],
          parent_id: "af08bf904024037d7a7b5fad",
          category_features: [
            {
              id: "bf08bf914024037d7a7b5fad",
              display: "Beds",
              type: "cf",
              message_id: "pets_cat_beds",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Beds",
              alias_slugs: [],
              parent_id: "b108bf914024037d7a7b5fad",
            },
            {
              id: "c008bf914024037d7a7b5fad",
              display: "Bowls & Feeders",
              type: "cf",
              message_id: "pets_cat_bowls_feeders",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bowls_&_Feeders",
              alias_slugs: [],
              parent_id: "b108bf914024037d7a7b5fad",
            },
            {
              id: "c108bf914024037d7a7b5fad",
              display: "Carriers & Travel",
              type: "cf",
              message_id: "pets_cat_carriers_travel",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Carriers_&_Travel",
              alias_slugs: [],
              parent_id: "b108bf914024037d7a7b5fad",
            },
            {
              id: "c208bf914024037d7a7b5fad",
              display: "Clothing & Accessories",
              type: "cf",
              message_id: "pets_cat_clothing_accessories",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Clothing_&_Accessories",
              alias_slugs: [],
              parent_id: "b108bf914024037d7a7b5fad",
            },
            {
              id: "c308bf914024037d7a7b5fad",
              display: "Collars, Leashes  & Harnesses",
              type: "cf",
              message_id: "pets_cat_collars_leashes_harnesses",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Collars,_Leashes__&_Harnesses",
              alias_slugs: [],
              parent_id: "b108bf914024037d7a7b5fad",
            },
            {
              id: "c408bf914024037d7a7b5fad",
              display: "Grooming",
              type: "cf",
              message_id: "pets_cat_grooming",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Grooming",
              alias_slugs: [],
              parent_id: "b108bf914024037d7a7b5fad",
            },
            {
              id: "c508bf914024037d7a7b5fad",
              display: "Scratchers",
              type: "cf",
              message_id: "pets_cat_scratchers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Scratchers",
              alias_slugs: [],
              parent_id: "b108bf914024037d7a7b5fad",
            },
            {
              id: "c608bf914024037d7a7b5fad",
              display: "Toys",
              type: "cf",
              message_id: "pets_cat_toys",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Toys",
              alias_slugs: [],
              parent_id: "b108bf914024037d7a7b5fad",
            },
          ],
        },
        {
          id: "b208bf914024037d7a7b5fad",
          display: "Bird",
          type: "c",
          size_sets: [
            {
              id: "9cee8fef402403392d9d5fd0",
              name: "Standard",
              tags: ["standard"],
              category_id: "b208bf914024037d7a7b5fad",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "pets_bird",
          aliases: [],
          slug: "Bird",
          alias_slugs: [],
          parent_id: "af08bf904024037d7a7b5fad",
          category_features: [
            {
              id: "c708bf914024037d7a7b5fad",
              display: "Cages & Covers",
              type: "cf",
              message_id: "pets_bird_cages_covers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cages_&_Covers",
              alias_slugs: [],
              parent_id: "b208bf914024037d7a7b5fad",
            },
            {
              id: "c808bf914024037d7a7b5fad",
              display: "Feeders & Waterers",
              type: "cf",
              message_id: "pets_bird_feeders_waterers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Feeders_&_Waterers",
              alias_slugs: [],
              parent_id: "b208bf914024037d7a7b5fad",
            },
            {
              id: "c908bf914024037d7a7b5fad",
              display: "Perches & Swings",
              type: "cf",
              message_id: "pets_bird_perches_swings",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Perches_&_Swings",
              alias_slugs: [],
              parent_id: "b208bf914024037d7a7b5fad",
            },
            {
              id: "ca08bf914024037d7a7b5fad",
              display: "Toys",
              type: "cf",
              message_id: "pets_bird_toys",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Toys",
              alias_slugs: [],
              parent_id: "b208bf914024037d7a7b5fad",
            },
          ],
        },
        {
          id: "b308bf914024037d7a7b5fad",
          display: "Fish",
          type: "c",
          size_sets: [
            {
              id: "9dee8fef402403392d9d5fd0",
              name: "Standard",
              tags: ["standard"],
              category_id: "b308bf914024037d7a7b5fad",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "pets_fish",
          aliases: [],
          slug: "Fish",
          alias_slugs: [],
          parent_id: "af08bf904024037d7a7b5fad",
          category_features: [
            {
              id: "cb08bf914024037d7a7b5fad",
              display: "Aquarium Kits",
              type: "cf",
              message_id: "pets_fish_aquarium_kits",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Aquarium_Kits",
              alias_slugs: [],
              parent_id: "b308bf914024037d7a7b5fad",
            },
            {
              id: "cc08bf914024037d7a7b5fad",
              display: "Cleaning & Maintenance",
              type: "cf",
              message_id: "pets_fish_cleaning_maintenance",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cleaning_&_Maintenance",
              alias_slugs: [],
              parent_id: "b308bf914024037d7a7b5fad",
            },
            {
              id: "cd08bf914024037d7a7b5fad",
              display: "Decor & Accessories",
              type: "cf",
              message_id: "pets_fish_decor_accessories",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Decor_&_Accessories",
              alias_slugs: [],
              parent_id: "b308bf914024037d7a7b5fad",
            },
          ],
        },
        {
          id: "b408bf914024037d7a7b5fad",
          display: "Reptile",
          type: "c",
          size_sets: [
            {
              id: "9eee8fef402403392d9d5fd0",
              name: "Standard",
              tags: ["standard"],
              category_id: "b408bf914024037d7a7b5fad",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "pets_reptile",
          aliases: [],
          slug: "Reptile",
          alias_slugs: [],
          parent_id: "af08bf904024037d7a7b5fad",
          category_features: [
            {
              id: "ce08bf914024037d7a7b5fad",
              display: "Cleaning & Maintenance",
              type: "cf",
              message_id: "pets_reptile_cleaning_maintenance",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cleaning_&_Maintenance",
              alias_slugs: [],
              parent_id: "b408bf914024037d7a7b5fad",
            },
            {
              id: "cf08bf914024037d7a7b5fad",
              display: "Habitats",
              type: "cf",
              message_id: "pets_reptile_habitats",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Habitats",
              alias_slugs: [],
              parent_id: "b408bf914024037d7a7b5fad",
            },
            {
              id: "d008bf914024037d7a7b5fad",
              display: "Habitat Accessories",
              type: "cf",
              message_id: "pets_reptile_habitat_accessories",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Habitat_Accessories",
              alias_slugs: [],
              parent_id: "b408bf914024037d7a7b5fad",
            },
            {
              id: "d108bf914024037d7a7b5fad",
              display: "Heating & Lights",
              type: "cf",
              message_id: "pets_reptile_heating_lights",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Heating_&_Lights",
              alias_slugs: [],
              parent_id: "b408bf914024037d7a7b5fad",
            },
          ],
        },
        {
          id: "b508bf914024037d7a7b5fad",
          display: "Small Pets",
          type: "c",
          size_sets: [
            {
              id: "9fee8fef402403392d9d5fd0",
              name: "Standard",
              tags: ["standard"],
              category_id: "b508bf914024037d7a7b5fad",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "pets_small_pets",
          aliases: [],
          slug: "Small_Pets",
          alias_slugs: [],
          parent_id: "af08bf904024037d7a7b5fad",
          category_features: [
            {
              id: "d208bf914024037d7a7b5fad",
              display: "Bedding",
              type: "cf",
              message_id: "pets_small_pets_bedding",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bedding",
              alias_slugs: [],
              parent_id: "b508bf914024037d7a7b5fad",
            },
            {
              id: "d308bf914024037d7a7b5fad",
              display: "Bowls & Feeders",
              type: "cf",
              message_id: "pets_small_pets_bowls_feeders",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bowls_&_Feeders",
              alias_slugs: [],
              parent_id: "b508bf914024037d7a7b5fad",
            },
            {
              id: "d408bf914024037d7a7b5fad",
              display: "Cages & Habitats",
              type: "cf",
              message_id: "pets_small_pets_cages_habitats",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cages_&_Habitats",
              alias_slugs: [],
              parent_id: "b508bf914024037d7a7b5fad",
            },
            {
              id: "d508bf914024037d7a7b5fad",
              display: "Carriers",
              type: "cf",
              message_id: "pets_small_pets_carriers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Carriers",
              alias_slugs: [],
              parent_id: "b508bf914024037d7a7b5fad",
            },
            {
              id: "d608bf914024037d7a7b5fad",
              display: "Grooming",
              type: "cf",
              message_id: "pets_small_pets_grooming",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Grooming",
              alias_slugs: [],
              parent_id: "b508bf914024037d7a7b5fad",
            },
            {
              id: "d708bf914024037d7a7b5fad",
              display: "Habitat Accessories",
              type: "cf",
              message_id: "pets_small_pets_habitat_accessories",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Habitat_Accessories",
              alias_slugs: [],
              parent_id: "b508bf914024037d7a7b5fad",
            },
            {
              id: "d808bf914024037d7a7b5fad",
              display: "Toys",
              type: "cf",
              message_id: "pets_small_pets_toys",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Toys",
              alias_slugs: [],
              parent_id: "b508bf914024037d7a7b5fad",
            },
          ],
        },
        {
          id: "b608bf914024037d7a7b5fad",
          display: "Other",
          type: "c",
          size_sets: [
            {
              id: "a0ee8fef402403392d9d5fd0",
              name: "Standard",
              tags: ["standard"],
              category_id: "b608bf914024037d7a7b5fad",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "pets_other",
          aliases: [],
          slug: "Other",
          alias_slugs: [],
          parent_id: "af08bf904024037d7a7b5fad",
        },
      ],
    },
    {
      id: "583c7d134024035188906153",
      display: "Electronics",
      type: "d",
      message_id: "electronics",
      aliases: [],
      size_sets: [],
      sizes: [],
      slug: "Electronics",
      alias_slugs: [],
      parent_id: "55b966335632a0146f000001",
      categories: [
        {
          id: "428e4884402403bc7f6c6157",
          display: "Cameras, Photo & Video",
          type: "c",
          size_sets: [
            {
              id: "47c83694402403da5111615b",
              name: "Standard",
              tags: ["standard"],
              category_id: "428e4884402403bc7f6c6157",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "electronics_cameras_photo_video",
          aliases: [],
          slug: "Cameras,_Photo_&_Video",
          alias_slugs: [],
          parent_id: "583c7d134024035188906153",
          category_features: [
            {
              id: "85c8296f402403e02d3d615b",
              display: "Digital Cameras",
              type: "cf",
              message_id: "electronics_cameras_photo_video_digital_cameras",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Digital_Cameras",
              alias_slugs: [],
              parent_id: "428e4884402403bc7f6c6157",
            },
            {
              id: "86c8296f402403e02d3d615b",
              display: "Bags & Cases",
              type: "cf",
              message_id: "electronics_cameras_photo_video_bags_cases",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Bags_&_Cases",
              alias_slugs: [],
              parent_id: "428e4884402403bc7f6c6157",
            },
            {
              id: "87c8296f402403e02d3d615b",
              display: "Binoculars & Scopes",
              type: "cf",
              message_id: "electronics_cameras_photo_video_binoculars_scopes",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Binoculars_&_Scopes",
              alias_slugs: [],
              parent_id: "428e4884402403bc7f6c6157",
            },
            {
              id: "88c8296f402403e02d3d615b",
              display: "Film Photography",
              type: "cf",
              message_id: "electronics_cameras_photo_video_film_photography",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Film_Photography",
              alias_slugs: [],
              parent_id: "428e4884402403bc7f6c6157",
            },
            {
              id: "89c8296f402403e02d3d615b",
              display: "Flashes",
              type: "cf",
              message_id: "electronics_cameras_photo_video_flashes",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Flashes",
              alias_slugs: [],
              parent_id: "428e4884402403bc7f6c6157",
            },
            {
              id: "8ac8296f402403e02d3d615b",
              display: "Lenses",
              type: "cf",
              message_id: "electronics_cameras_photo_video_lenses",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Lenses",
              alias_slugs: [],
              parent_id: "428e4884402403bc7f6c6157",
            },
            {
              id: "8cc8296f402403e02d3d615b",
              display: "Memory Cards",
              type: "cf",
              message_id: "electronics_cameras_photo_video_memory_cards",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Memory_Cards",
              alias_slugs: [],
              parent_id: "428e4884402403bc7f6c6157",
            },
            {
              id: "8dc8296f402403e02d3d615b",
              display: "Simulated Cameras",
              type: "cf",
              message_id: "electronics_cameras_photo_video_simulated_cameras",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Simulated_Cameras",
              alias_slugs: [],
              parent_id: "428e4884402403bc7f6c6157",
            },
            {
              id: "8ec8296f402403e02d3d615b",
              display: "Tripods & Monopods",
              type: "cf",
              message_id: "electronics_cameras_photo_video_tripods_monopods",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Tripods_&_Monopods",
              alias_slugs: [],
              parent_id: "428e4884402403bc7f6c6157",
            },
            {
              id: "8fc8296f402403e02d3d615b",
              display: "Underwater Photography",
              type: "cf",
              message_id:
                "electronics_cameras_photo_video_underwater_photography",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Underwater_Photography",
              alias_slugs: [],
              parent_id: "428e4884402403bc7f6c6157",
            },
            {
              id: "90c8296f402403e02d3d615b",
              display: "Video",
              type: "cf",
              message_id: "electronics_cameras_photo_video_video",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Video",
              alias_slugs: [],
              parent_id: "428e4884402403bc7f6c6157",
            },
            {
              id: "91c8296f402403e02d3d615b",
              display: "Camera Straps",
              type: "cf",
              message_id: "electronics_cameras_photo_video_camera_straps",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Camera_Straps",
              alias_slugs: [],
              parent_id: "428e4884402403bc7f6c6157",
            },
          ],
        },
        {
          id: "438e4884402403bc7f6c6157",
          display: "Computers, Laptops & Parts",
          type: "c",
          size_sets: [
            {
              id: "48c83694402403da5111615b",
              name: "Standard",
              tags: ["standard"],
              category_id: "438e4884402403bc7f6c6157",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "electronics_computers_laptops_parts",
          aliases: [],
          slug: "Computers,_Laptops_&_Parts",
          alias_slugs: [],
          parent_id: "583c7d134024035188906153",
          category_features: [
            {
              id: "92c8296f402403e02d3d615b",
              display: "Laptops",
              type: "cf",
              message_id: "electronics_computers_laptops_parts_laptops",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Laptops",
              alias_slugs: [],
              parent_id: "438e4884402403bc7f6c6157",
            },
            {
              id: "93c8296f402403e02d3d615b",
              display: "Cables & Interconnects",
              type: "cf",
              message_id:
                "electronics_computers_laptops_parts_cables_interconnects",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cables_&_Interconnects",
              alias_slugs: [],
              parent_id: "438e4884402403bc7f6c6157",
            },
            {
              id: "94c8296f402403e02d3d615b",
              display: "Camera Privacy Covers",
              type: "cf",
              message_id:
                "electronics_computers_laptops_parts_camera_privacy_covers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Camera_Privacy_Covers",
              alias_slugs: [],
              parent_id: "438e4884402403bc7f6c6157",
            },
            {
              id: "95c8296f402403e02d3d615b",
              display: "Computer Cable Adapters",
              type: "cf",
              message_id:
                "electronics_computers_laptops_parts_computer_cable_adapters",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Computer_Cable_Adapters",
              alias_slugs: [],
              parent_id: "438e4884402403bc7f6c6157",
            },
            {
              id: "96c8296f402403e02d3d615b",
              display: "Computer Headsets",
              type: "cf",
              message_id: "electronics_computers_laptops_parts_computer_headsets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Computer_Headsets",
              alias_slugs: [],
              parent_id: "438e4884402403bc7f6c6157",
            },
            {
              id: "97c8296f402403e02d3d615b",
              display: "Computer Microphones",
              type: "cf",
              message_id:
                "electronics_computers_laptops_parts_computer_microphones",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Computer_Microphones",
              alias_slugs: [],
              parent_id: "438e4884402403bc7f6c6157",
            },
            {
              id: "98c8296f402403e02d3d615b",
              display: "External Components",
              type: "cf",
              message_id:
                "electronics_computers_laptops_parts_external_components",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "External_Components",
              alias_slugs: [],
              parent_id: "438e4884402403bc7f6c6157",
            },
            {
              id: "99c8296f402403e02d3d615b",
              display: "Graphics Cards",
              type: "cf",
              message_id: "electronics_computers_laptops_parts_graphics_cards",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Graphics_Cards",
              alias_slugs: [],
              parent_id: "438e4884402403bc7f6c6157",
            },
            {
              id: "9ac8296f402403e02d3d615b",
              display: "Internal Components",
              type: "cf",
              message_id:
                "electronics_computers_laptops_parts_internal_components",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Internal_Components",
              alias_slugs: [],
              parent_id: "438e4884402403bc7f6c6157",
            },
            {
              id: "9bc8296f402403e02d3d615b",
              display: "Keyboards",
              type: "cf",
              message_id: "electronics_computers_laptops_parts_keyboards",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Keyboards",
              alias_slugs: [],
              parent_id: "438e4884402403bc7f6c6157",
            },
            {
              id: "9cc8296f402403e02d3d615b",
              display: "Memory Card Readers",
              type: "cf",
              message_id:
                "electronics_computers_laptops_parts_memory_card_readers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Memory_Card_Readers",
              alias_slugs: [],
              parent_id: "438e4884402403bc7f6c6157",
            },
            {
              id: "9dc8296f402403e02d3d615b",
              display: "Mice",
              type: "cf",
              message_id: "electronics_computers_laptops_parts_mice",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Mice",
              alias_slugs: [],
              parent_id: "438e4884402403bc7f6c6157",
            },
            {
              id: "9ec8296f402403e02d3d615b",
              display: "Mounts & Stands",
              type: "cf",
              message_id: "electronics_computers_laptops_parts_mounts_stands",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Mounts_&_Stands",
              alias_slugs: [],
              parent_id: "438e4884402403bc7f6c6157",
            },
            {
              id: "9fc8296f402403e02d3d615b",
              display: "Surge Protectors",
              type: "cf",
              message_id: "electronics_computers_laptops_parts_surge_protectors",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Surge_Protectors",
              alias_slugs: [],
              parent_id: "438e4884402403bc7f6c6157",
            },
            {
              id: "a0c8296f402403e02d3d615b",
              display: "Single Board Computers",
              type: "cf",
              message_id:
                "electronics_computers_laptops_parts_single_board_computers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Single_Board_Computers",
              alias_slugs: [],
              parent_id: "438e4884402403bc7f6c6157",
            },
            {
              id: "a1c8296f402403e02d3d615b",
              display: "USB Hubs",
              type: "cf",
              message_id: "electronics_computers_laptops_parts_usb_hubs",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "USB_Hubs",
              alias_slugs: [],
              parent_id: "438e4884402403bc7f6c6157",
            },
            {
              id: "a2c8296f402403e02d3d615b",
              display: "Webcams",
              type: "cf",
              message_id: "electronics_computers_laptops_parts_webcams",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Webcams",
              alias_slugs: [],
              parent_id: "438e4884402403bc7f6c6157",
            },
          ],
        },
        {
          id: "4e8e4884402403bc7f6c6157",
          display: "Tablets & Accessories",
          type: "c",
          size_sets: [
            {
              id: "53c83694402403da5111615b",
              name: "Standard",
              tags: ["standard"],
              category_id: "4e8e4884402403bc7f6c6157",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "electronics_tablets_accessories",
          aliases: [],
          slug: "Tablets_&_Accessories",
          alias_slugs: [],
          parent_id: "583c7d134024035188906153",
          category_features: [
            {
              id: "c2c8296f402403e02d3d615b",
              display: "Tablets",
              type: "cf",
              message_id: "electronics_tablets_accessories_tablets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Tablets",
              alias_slugs: [],
              parent_id: "4e8e4884402403bc7f6c6157",
            },
            {
              id: "c3c8296f402403e02d3d615b",
              display: "eBook Readers",
              type: "cf",
              message_id: "electronics_tablets_accessories_ebook_readers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "eBook_Readers",
              alias_slugs: [],
              parent_id: "4e8e4884402403bc7f6c6157",
            },
            {
              id: "c4c8296f402403e02d3d615b",
              display: "Cases",
              type: "cf",
              message_id: "electronics_tablets_accessories_cases",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cases",
              alias_slugs: [],
              parent_id: "4e8e4884402403bc7f6c6157",
            },
            {
              id: "c5c8296f402403e02d3d615b",
              display: "Chargers",
              type: "cf",
              message_id: "electronics_tablets_accessories_chargers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Chargers",
              alias_slugs: [],
              parent_id: "4e8e4884402403bc7f6c6157",
            },
            {
              id: "c6c8296f402403e02d3d615b",
              display: "Covers",
              type: "cf",
              message_id: "electronics_tablets_accessories_covers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Covers",
              alias_slugs: [],
              parent_id: "4e8e4884402403bc7f6c6157",
            },
            {
              id: "c7c8296f402403e02d3d615b",
              display: "Power Adapters",
              type: "cf",
              message_id: "electronics_tablets_accessories_power_adapters",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Power_Adapters",
              alias_slugs: [],
              parent_id: "4e8e4884402403bc7f6c6157",
            },
            {
              id: "c8c8296f402403e02d3d615b",
              display: "Power Cables",
              type: "cf",
              message_id: "electronics_tablets_accessories_power_cables",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Power_Cables",
              alias_slugs: [],
              parent_id: "4e8e4884402403bc7f6c6157",
            },
            {
              id: "c9c8296f402403e02d3d615b",
              display: "Reading Lights",
              type: "cf",
              message_id: "electronics_tablets_accessories_reading_lights",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Reading_Lights",
              alias_slugs: [],
              parent_id: "4e8e4884402403bc7f6c6157",
            },
            {
              id: "cac8296f402403e02d3d615b",
              display: "Screen Protectors",
              type: "cf",
              message_id: "electronics_tablets_accessories_screen_protectors",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Screen_Protectors",
              alias_slugs: [],
              parent_id: "4e8e4884402403bc7f6c6157",
            },
            {
              id: "cbc8296f402403e02d3d615b",
              display: "Skins",
              type: "cf",
              message_id: "electronics_tablets_accessories_skins",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Skins",
              alias_slugs: [],
              parent_id: "4e8e4884402403bc7f6c6157",
            },
            {
              id: "ccc8296f402403e02d3d615b",
              display: "Sleeves",
              type: "cf",
              message_id: "electronics_tablets_accessories_sleeves",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Sleeves",
              alias_slugs: [],
              parent_id: "4e8e4884402403bc7f6c6157",
            },
            {
              id: "cdc8296f402403e02d3d615b",
              display: "Stands",
              type: "cf",
              message_id: "electronics_tablets_accessories_stands",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Stands",
              alias_slugs: [],
              parent_id: "4e8e4884402403bc7f6c6157",
            },
            {
              id: "cec8296f402403e02d3d615b",
              display: "Tablet Keyboards",
              type: "cf",
              message_id: "electronics_tablets_accessories_tablet_keyboards",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Tablet_Keyboards",
              alias_slugs: [],
              parent_id: "4e8e4884402403bc7f6c6157",
            },
          ],
        },
        {
          id: "448e4884402403bc7f6c6157",
          display: "Cell Phones & Accessories",
          type: "c",
          size_sets: [
            {
              id: "49c83694402403da5111615b",
              name: "Standard",
              tags: ["standard"],
              category_id: "448e4884402403bc7f6c6157",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "electronics_cell_phones_accessories",
          aliases: [],
          slug: "Cell_Phones_&_Accessories",
          alias_slugs: [],
          parent_id: "583c7d134024035188906153",
          category_features: [
            {
              id: "a3c8296f402403e02d3d615b",
              display: "Cell Phones",
              type: "cf",
              message_id: "electronics_cell_phones_accessories_cell_phones",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cell_Phones",
              alias_slugs: [],
              parent_id: "448e4884402403bc7f6c6157",
            },
            {
              id: "a4c8296f402403e02d3d615b",
              display: "Holsters & Clips",
              type: "cf",
              message_id: "electronics_cell_phones_accessories_holsters_clips",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Holsters_&_Clips",
              alias_slugs: [],
              parent_id: "448e4884402403bc7f6c6157",
            },
            {
              id: "a5c8296f402403e02d3d615b",
              display: "Headsets",
              type: "cf",
              message_id: "electronics_cell_phones_accessories_headsets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Headsets",
              alias_slugs: [],
              parent_id: "448e4884402403bc7f6c6157",
            },
            {
              id: "a6c8296f402403e02d3d615b",
              display: "Screen Protectors",
              type: "cf",
              message_id: "electronics_cell_phones_accessories_screen_protectors",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Screen_Protectors",
              alias_slugs: [],
              parent_id: "448e4884402403bc7f6c6157",
            },
            {
              id: "a7c8296f402403e02d3d615b",
              display: "Cases",
              type: "cf",
              message_id: "electronics_cell_phones_accessories_cases",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cases",
              alias_slugs: [],
              parent_id: "448e4884402403bc7f6c6157",
            },
            {
              id: "a8c8296f402403e02d3d615b",
              display: "Covers",
              type: "cf",
              message_id: "electronics_cell_phones_accessories_covers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Covers",
              alias_slugs: [],
              parent_id: "448e4884402403bc7f6c6157",
            },
            {
              id: "a9c8296f402403e02d3d615b",
              display: "Skins & Bumpers",
              type: "cf",
              message_id: "electronics_cell_phones_accessories_skins_bumpers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Skins_&_Bumpers",
              alias_slugs: [],
              parent_id: "448e4884402403bc7f6c6157",
            },
            {
              id: "aac8296f402403e02d3d615b",
              display: "Chargers",
              type: "cf",
              message_id: "electronics_cell_phones_accessories_chargers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Chargers",
              alias_slugs: [],
              parent_id: "448e4884402403bc7f6c6157",
            },
            {
              id: "abc8296f402403e02d3d615b",
              display: "Adapters",
              type: "cf",
              message_id: "electronics_cell_phones_accessories_adapters",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Adapters",
              alias_slugs: [],
              parent_id: "448e4884402403bc7f6c6157",
            },
            {
              id: "acc8296f402403e02d3d615b",
              display: "Cables",
              type: "cf",
              message_id: "electronics_cell_phones_accessories_cables",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cables",
              alias_slugs: [],
              parent_id: "448e4884402403bc7f6c6157",
            },
          ],
        },
        {
          id: "468e4884402403bc7f6c6157",
          display: "Video Games & Consoles",
          type: "c",
          size_sets: [
            {
              id: "4bc83694402403da5111615b",
              name: "Standard",
              tags: ["standard"],
              category_id: "468e4884402403bc7f6c6157",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "electronics_video_games_consoles",
          aliases: [],
          slug: "Video_Games_&_Consoles",
          alias_slugs: [],
          parent_id: "583c7d134024035188906153",
          category_features: [
            {
              id: "cfc8296f402403e02d3d615b",
              display: "Consoles",
              type: "cf",
              message_id: "electronics_video_games_consoles_consoles",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Consoles",
              alias_slugs: [],
              parent_id: "468e4884402403bc7f6c6157",
            },
            {
              id: "b56d79e5402403fe0e5a615b",
              display: "Handheld Consoles",
              type: "cf",
              message_id: "electronics_video_games_consoles_handheld_consoles",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Handheld_Consoles",
              alias_slugs: [],
              parent_id: "468e4884402403bc7f6c6157",
            },
            {
              id: "d0c8296f402403e02d3d615b",
              display: "Batteries & Chargers",
              type: "cf",
              message_id: "electronics_video_games_consoles_batteries_chargers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Batteries_&_Chargers",
              alias_slugs: [],
              parent_id: "468e4884402403bc7f6c6157",
            },
            {
              id: "d1c8296f402403e02d3d615b",
              display: "Cables",
              type: "cf",
              message_id: "electronics_video_games_consoles_cables",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cables",
              alias_slugs: [],
              parent_id: "468e4884402403bc7f6c6157",
            },
            {
              id: "d2c8296f402403e02d3d615b",
              display: "Controllers",
              type: "cf",
              message_id: "electronics_video_games_consoles_controllers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Controllers",
              alias_slugs: [],
              parent_id: "468e4884402403bc7f6c6157",
            },
            {
              id: "d3c8296f402403e02d3d615b",
              display: "Headsets",
              type: "cf",
              message_id: "electronics_video_games_consoles_headsets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Headsets",
              alias_slugs: [],
              parent_id: "468e4884402403bc7f6c6157",
            },
            {
              id: "d4c8296f402403e02d3d615b",
              display: "Gaming Guides",
              type: "cf",
              message_id: "electronics_video_games_consoles_gaming_guides",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Gaming_Guides",
              alias_slugs: [],
              parent_id: "468e4884402403bc7f6c6157",
            },
            {
              id: "d5c8296f402403e02d3d615b",
              display: "Keyboards",
              type: "cf",
              message_id: "electronics_video_games_consoles_keyboards",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Keyboards",
              alias_slugs: [],
              parent_id: "468e4884402403bc7f6c6157",
            },
            {
              id: "d6c8296f402403e02d3d615b",
              display: "Digital Games",
              type: "cf",
              message_id: "electronics_video_games_consoles_digital_games",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Digital_Games",
              alias_slugs: [],
              parent_id: "468e4884402403bc7f6c6157",
            },
            {
              id: "d7c8296f402403e02d3d615b",
              display: "PC Games",
              type: "cf",
              message_id: "electronics_video_games_consoles_pc_games",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "PC_Games",
              alias_slugs: [],
              parent_id: "468e4884402403bc7f6c6157",
            },
            {
              id: "d8c8296f402403e02d3d615b",
              display: "Video Games",
              type: "cf",
              message_id: "electronics_video_games_consoles_video_games",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Video_Games",
              alias_slugs: [],
              parent_id: "468e4884402403bc7f6c6157",
            },
          ],
        },
        {
          id: "478e4884402403bc7f6c6157",
          display: "Car Audio, Video & GPS",
          type: "c",
          size_sets: [
            {
              id: "4cc83694402403da5111615b",
              name: "Standard",
              tags: ["standard"],
              category_id: "478e4884402403bc7f6c6157",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "electronics_car_audio_video_gps",
          aliases: [],
          slug: "Car_Audio,_Video_&_GPS",
          alias_slugs: [],
          parent_id: "583c7d134024035188906153",
          category_features: [
            {
              id: "e19ea6f24024034403ea6160",
              display: "GPS & Navigation",
              type: "cf",
              message_id: "electronics_car_audio_video_gps_gps_navigation",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "GPS_&_Navigation",
              alias_slugs: [],
              parent_id: "478e4884402403bc7f6c6157",
            },
            {
              id: "e29ea6f24024034403ea6160",
              display: "Amplifiers",
              type: "cf",
              message_id: "electronics_car_audio_video_gps_amplifiers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Amplifiers",
              alias_slugs: [],
              parent_id: "478e4884402403bc7f6c6157",
            },
            {
              id: "e39ea6f24024034403ea6160",
              display: "Car Stereo Receivers",
              type: "cf",
              message_id: "electronics_car_audio_video_gps_car_stereo_receivers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Car_Stereo_Receivers",
              alias_slugs: [],
              parent_id: "478e4884402403bc7f6c6157",
            },
            {
              id: "e49ea6f24024034403ea6160",
              display: "Changers",
              type: "cf",
              message_id: "electronics_car_audio_video_gps_changers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Changers",
              alias_slugs: [],
              parent_id: "478e4884402403bc7f6c6157",
            },
            {
              id: "e59ea6f24024034403ea6160",
              display: "Digital Media Receivers",
              type: "cf",
              message_id:
                "electronics_car_audio_video_gps_digital_media_receivers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Digital_Media_Receivers",
              alias_slugs: [],
              parent_id: "478e4884402403bc7f6c6157",
            },
            {
              id: "e69ea6f24024034403ea6160",
              display: "Equalizers",
              type: "cf",
              message_id: "electronics_car_audio_video_gps_equalizers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Equalizers",
              alias_slugs: [],
              parent_id: "478e4884402403bc7f6c6157",
            },
            {
              id: "e79ea6f24024034403ea6160",
              display: "Satellite Radio",
              type: "cf",
              message_id: "electronics_car_audio_video_gps_satellite_radio",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Satellite_Radio",
              alias_slugs: [],
              parent_id: "478e4884402403bc7f6c6157",
            },
            {
              id: "e89ea6f24024034403ea6160",
              display: "Car Headphones",
              type: "cf",
              message_id: "electronics_car_audio_video_gps_car_headphones",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Car_Headphones",
              alias_slugs: [],
              parent_id: "478e4884402403bc7f6c6157",
            },
            {
              id: "e99ea6f24024034403ea6160",
              display: "In-Mirror Video",
              type: "cf",
              message_id: "electronics_car_audio_video_gps_inmirror_video",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "In_Mirror_Video",
              alias_slugs: [],
              parent_id: "478e4884402403bc7f6c6157",
            },
            {
              id: "ea9ea6f24024034403ea6160",
              display: "In-Visor Video",
              type: "cf",
              message_id: "electronics_car_audio_video_gps_invisor_video",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "In_Visor_Video",
              alias_slugs: [],
              parent_id: "478e4884402403bc7f6c6157",
            },
            {
              id: "eb9ea6f24024034403ea6160",
              display: "On-Dash Cameras",
              type: "cf",
              message_id: "electronics_car_audio_video_gps_ondash_cameras",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "On_Dash_Cameras",
              alias_slugs: [],
              parent_id: "478e4884402403bc7f6c6157",
            },
            {
              id: "ec9ea6f24024034403ea6160",
              display: "Overhead Video",
              type: "cf",
              message_id: "electronics_car_audio_video_gps_overhead_video",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Overhead_Video",
              alias_slugs: [],
              parent_id: "478e4884402403bc7f6c6157",
            },
            {
              id: "ed9ea6f24024034403ea6160",
              display: "Surround Processors",
              type: "cf",
              message_id: "electronics_car_audio_video_gps_surround_processors",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Surround_Processors",
              alias_slugs: [],
              parent_id: "478e4884402403bc7f6c6157",
            },
            {
              id: "ee9ea6f24024034403ea6160",
              display: "TV Tuners",
              type: "cf",
              message_id: "electronics_car_audio_video_gps_tv_tuners",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "TV_Tuners",
              alias_slugs: [],
              parent_id: "478e4884402403bc7f6c6157",
            },
            {
              id: "ef9ea6f24024034403ea6160",
              display: "Vehicle Backup Cameras",
              type: "cf",
              message_id:
                "electronics_car_audio_video_gps_vehicle_backup_cameras",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Vehicle_Backup_Cameras",
              alias_slugs: [],
              parent_id: "478e4884402403bc7f6c6157",
            },
          ],
        },
        {
          id: "488e4884402403bc7f6c6157",
          display: "Media",
          type: "c",
          size_sets: [
            {
              id: "4dc83694402403da5111615b",
              name: "Standard",
              tags: ["standard"],
              category_id: "488e4884402403bc7f6c6157",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "electronics_media",
          aliases: [],
          slug: "Media",
          alias_slugs: [],
          parent_id: "583c7d134024035188906153",
          category_features: [
            {
              id: "dfc8296f402403e02d3d615b",
              display: "Blank Media",
              type: "cf",
              message_id: "electronics_media_blank_media",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Blank_Media",
              alias_slugs: [],
              parent_id: "488e4884402403bc7f6c6157",
            },
            {
              id: "e0c8296f402403e02d3d615b",
              display: "CDs",
              type: "cf",
              message_id: "electronics_media_cds",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "CDs",
              alias_slugs: [],
              parent_id: "488e4884402403bc7f6c6157",
            },
            {
              id: "e1c8296f402403e02d3d615b",
              display: "DVDs & Blu-ray Discs",
              type: "cf",
              message_id: "electronics_media_dvds_bluray_discs",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "DVDs_&_Blu_ray_Discs",
              alias_slugs: [],
              parent_id: "488e4884402403bc7f6c6157",
            },
            {
              id: "e2c8296f402403e02d3d615b",
              display: "Media Streamers",
              type: "cf",
              message_id: "electronics_media_media_streamers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Media_Streamers",
              alias_slugs: [],
              parent_id: "488e4884402403bc7f6c6157",
            },
            {
              id: "e3c8296f402403e02d3d615b",
              display: "Media Cases & Organization",
              type: "cf",
              message_id: "electronics_media_media_cases_organization",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Media_Cases_&_Organization",
              alias_slugs: [],
              parent_id: "488e4884402403bc7f6c6157",
            },
            {
              id: "e4c8296f402403e02d3d615b",
              display: "Vinyl Records",
              type: "cf",
              message_id: "electronics_media_vinyl_records",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Vinyl_Records",
              alias_slugs: [],
              parent_id: "488e4884402403bc7f6c6157",
            },
          ],
        },
        {
          id: "498e4884402403bc7f6c6157",
          display: "Wearables",
          type: "c",
          size_sets: [
            {
              id: "4ec83694402403da5111615b",
              name: "Standard",
              tags: ["standard"],
              category_id: "498e4884402403bc7f6c6157",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "electronics_wearables",
          aliases: [],
          slug: "Wearables",
          alias_slugs: [],
          parent_id: "583c7d134024035188906153",
          category_features: [
            {
              id: "bcc8296f402403e02d3d615b",
              display: "Smartwatches",
              type: "cf",
              message_id: "electronics_wearables_smartwatches",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Smartwatches",
              alias_slugs: [],
              parent_id: "498e4884402403bc7f6c6157",
            },
            {
              id: "b46d79e5402403fe0e5a615b",
              display: "Body Mounted Cameras",
              type: "cf",
              message_id: "electronics_wearables_body_mounted_cameras",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Body_Mounted_Cameras",
              alias_slugs: [],
              parent_id: "498e4884402403bc7f6c6157",
            },
            {
              id: "bdc8296f402403e02d3d615b",
              display: "Clips, Arm & Wristbands",
              type: "cf",
              message_id: "electronics_wearables_clips_arm_wristbands",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Clips,_Arm_&_Wristbands",
              alias_slugs: [],
              parent_id: "498e4884402403bc7f6c6157",
            },
            {
              id: "bec8296f402403e02d3d615b",
              display: "Glasses",
              type: "cf",
              message_id: "electronics_wearables_glasses",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Glasses",
              alias_slugs: [],
              parent_id: "498e4884402403bc7f6c6157",
            },
            {
              id: "bfc8296f402403e02d3d615b",
              display: "Rings",
              type: "cf",
              message_id: "electronics_wearables_rings",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Rings",
              alias_slugs: [],
              parent_id: "498e4884402403bc7f6c6157",
            },
            {
              id: "c0c8296f402403e02d3d615b",
              display: "Smartwatch Cases",
              type: "cf",
              message_id: "electronics_wearables_smartwatch_cases",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Smartwatch_Cases",
              alias_slugs: [],
              parent_id: "498e4884402403bc7f6c6157",
            },
            {
              id: "c1c8296f402403e02d3d615b",
              display: "Wearables Chargers",
              type: "cf",
              message_id: "electronics_wearables_wearables_chargers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Wearables_Chargers",
              alias_slugs: [],
              parent_id: "498e4884402403bc7f6c6157",
            },
          ],
        },
        {
          id: "4c8e4884402403bc7f6c6157",
          display: "Headphones",
          type: "c",
          size_sets: [
            {
              id: "51c83694402403da5111615b",
              name: "Standard",
              tags: ["standard"],
              category_id: "4c8e4884402403bc7f6c6157",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "electronics_headphones",
          aliases: [],
          slug: "Headphones",
          alias_slugs: [],
          parent_id: "583c7d134024035188906153",
          category_features: [
            {
              id: "22c83694402403da5111615b",
              display: "Earbud Headphones",
              type: "cf",
              message_id: "electronics_headphones_earbud_headphones",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Earbud_Headphones",
              alias_slugs: [],
              parent_id: "4c8e4884402403bc7f6c6157",
            },
            {
              id: "23c83694402403da5111615b",
              display: "On-Ear Headphones",
              type: "cf",
              message_id: "electronics_headphones_onear_headphones",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "On_Ear_Headphones",
              alias_slugs: [],
              parent_id: "4c8e4884402403bc7f6c6157",
            },
            {
              id: "24c83694402403da5111615b",
              display: "Over-Ear Headphones",
              type: "cf",
              message_id: "electronics_headphones_overear_headphones",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Over_Ear_Headphones",
              alias_slugs: [],
              parent_id: "4c8e4884402403bc7f6c6157",
            },
          ],
        },
        {
          id: "4d8e4884402403bc7f6c6157",
          display: "Portable Audio & Video",
          type: "c",
          size_sets: [
            {
              id: "52c83694402403da5111615b",
              name: "Standard",
              tags: ["standard"],
              category_id: "4d8e4884402403bc7f6c6157",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "electronics_portable_audio_video",
          aliases: [],
          slug: "Portable_Audio_&_Video",
          alias_slugs: [],
          parent_id: "583c7d134024035188906153",
          category_features: [
            {
              id: "30c83694402403da5111615b",
              display: "Boomboxes",
              type: "cf",
              message_id: "electronics_portable_audio_video_boomboxes",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Boomboxes",
              alias_slugs: [],
              parent_id: "4d8e4884402403bc7f6c6157",
            },
            {
              id: "25c83694402403da5111615b",
              display: "CB & Two-Way Radios",
              type: "cf",
              message_id: "electronics_portable_audio_video_cb_twoway_radios",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "CB_&_Two_Way_Radios",
              alias_slugs: [],
              parent_id: "4d8e4884402403bc7f6c6157",
            },
            {
              id: "26c83694402403da5111615b",
              display: "Cassette Players",
              type: "cf",
              message_id: "electronics_portable_audio_video_cassette_players",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cassette_Players",
              alias_slugs: [],
              parent_id: "4d8e4884402403bc7f6c6157",
            },
            {
              id: "27c83694402403da5111615b",
              display: "Digital Voice Recorders",
              type: "cf",
              message_id:
                "electronics_portable_audio_video_digital_voice_recorders",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Digital_Voice_Recorders",
              alias_slugs: [],
              parent_id: "4d8e4884402403bc7f6c6157",
            },
            {
              id: "28c83694402403da5111615b",
              display: "MP3 & MP4 Players",
              type: "cf",
              message_id: "electronics_portable_audio_video_mp3_mp4_players",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "MP3_&_MP4_Players",
              alias_slugs: [],
              parent_id: "4d8e4884402403bc7f6c6157",
            },
            {
              id: "29c83694402403da5111615b",
              display: "Microcassette Recorders",
              type: "cf",
              message_id:
                "electronics_portable_audio_video_microcassette_recorders",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Microcassette_Recorders",
              alias_slugs: [],
              parent_id: "4d8e4884402403bc7f6c6157",
            },
            {
              id: "2ac83694402403da5111615b",
              display: "Minidisc Players",
              type: "cf",
              message_id: "electronics_portable_audio_video_minidisc_players",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Minidisc_Players",
              alias_slugs: [],
              parent_id: "4d8e4884402403bc7f6c6157",
            },
            {
              id: "2bc83694402403da5111615b",
              display: "Portable & Handheld TVs",
              type: "cf",
              message_id:
                "electronics_portable_audio_video_portable_handheld_tvs",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Portable_&_Handheld_TVs",
              alias_slugs: [],
              parent_id: "4d8e4884402403bc7f6c6157",
            },
            {
              id: "2cc83694402403da5111615b",
              display: "Portable CD Players",
              type: "cf",
              message_id: "electronics_portable_audio_video_portable_cd_players",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Portable_CD_Players",
              alias_slugs: [],
              parent_id: "4d8e4884402403bc7f6c6157",
            },
            {
              id: "2dc83694402403da5111615b",
              display: "Portable DVD Players",
              type: "cf",
              message_id: "electronics_portable_audio_video_portable_dvd_players",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Portable_DVD_Players",
              alias_slugs: [],
              parent_id: "4d8e4884402403bc7f6c6157",
            },
            {
              id: "2ec83694402403da5111615b",
              display: "Portable Speakers",
              type: "cf",
              message_id: "electronics_portable_audio_video_portable_speakers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Portable_Speakers",
              alias_slugs: [],
              parent_id: "4d8e4884402403bc7f6c6157",
            },
            {
              id: "2fc83694402403da5111615b",
              display: "Radios",
              type: "cf",
              message_id: "electronics_portable_audio_video_radios",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Radios",
              alias_slugs: [],
              parent_id: "4d8e4884402403bc7f6c6157",
            },
          ],
        },
        {
          id: "508e4884402403bc7f6c6157",
          display: "VR, AR & Accessories",
          type: "c",
          size_sets: [
            {
              id: "55c83694402403da5111615b",
              name: "Standard",
              tags: ["standard"],
              category_id: "508e4884402403bc7f6c6157",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "electronics_vr_ar_accessories",
          aliases: [],
          slug: "VR,_AR_&_Accessories",
          alias_slugs: [],
          parent_id: "583c7d134024035188906153",
          category_features: [
            {
              id: "d9c8296f402403e02d3d615b",
              display: "PC & Console VR Headsets",
              type: "cf",
              message_id: "electronics_vr_ar_accessories_pc_console_vr_headsets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "PC_&_Console_VR_Headsets",
              alias_slugs: [],
              parent_id: "508e4884402403bc7f6c6157",
            },
            {
              id: "dac8296f402403e02d3d615b",
              display: "Smartphone VR Headsets",
              type: "cf",
              message_id: "electronics_vr_ar_accessories_smartphone_vr_headsets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Smartphone_VR_Headsets",
              alias_slugs: [],
              parent_id: "508e4884402403bc7f6c6157",
            },
            {
              id: "dbc8296f402403e02d3d615b",
              display: "Standalone VR Headsets",
              type: "cf",
              message_id: "electronics_vr_ar_accessories_standalone_vr_headsets",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Standalone_VR_Headsets",
              alias_slugs: [],
              parent_id: "508e4884402403bc7f6c6157",
            },
            {
              id: "dcc8296f402403e02d3d615b",
              display: "Cases, Covers & Skins",
              type: "cf",
              message_id: "electronics_vr_ar_accessories_cases_covers_skins",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Cases,_Covers_&_Skins",
              alias_slugs: [],
              parent_id: "508e4884402403bc7f6c6157",
            },
            {
              id: "ddc8296f402403e02d3d615b",
              display: "Controllers & Sensors",
              type: "cf",
              message_id: "electronics_vr_ar_accessories_controllers_sensors",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Controllers_&_Sensors",
              alias_slugs: [],
              parent_id: "508e4884402403bc7f6c6157",
            },
            {
              id: "dec8296f402403e02d3d615b",
              display: "Parts",
              type: "cf",
              message_id: "electronics_vr_ar_accessories_parts",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Parts",
              alias_slugs: [],
              parent_id: "508e4884402403bc7f6c6157",
            },
          ],
        },
        {
          id: "518e4884402403bc7f6c6157",
          display: "Networking",
          type: "c",
          size_sets: [
            {
              id: "56c83694402403da5111615b",
              name: "Standard",
              tags: ["standard"],
              category_id: "518e4884402403bc7f6c6157",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "electronics_networking",
          aliases: [],
          slug: "Networking",
          alias_slugs: [],
          parent_id: "583c7d134024035188906153",
          category_features: [
            {
              id: "e5c8296f402403e02d3d615b",
              display: "Boosters & Antennas",
              type: "cf",
              message_id: "electronics_networking_boosters_antennas",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Boosters_&_Antennas",
              alias_slugs: [],
              parent_id: "518e4884402403bc7f6c6157",
            },
            {
              id: "e6c8296f402403e02d3d615b",
              display: "Mobile Broadband Devices",
              type: "cf",
              message_id: "electronics_networking_mobile_broadband_devices",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Mobile_Broadband_Devices",
              alias_slugs: [],
              parent_id: "518e4884402403bc7f6c6157",
            },
            {
              id: "e7c8296f402403e02d3d615b",
              display: "Modems",
              type: "cf",
              message_id: "electronics_networking_modems",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Modems",
              alias_slugs: [],
              parent_id: "518e4884402403bc7f6c6157",
            },
            {
              id: "e8c8296f402403e02d3d615b",
              display: "Modem-Router Combos",
              type: "cf",
              message_id: "electronics_networking_modemrouter_combos",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Modem_Router_Combos",
              alias_slugs: [],
              parent_id: "518e4884402403bc7f6c6157",
            },
            {
              id: "1ac83694402403da5111615b",
              display: "Powerline Networking",
              type: "cf",
              message_id: "electronics_networking_powerline_networking",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Powerline_Networking",
              alias_slugs: [],
              parent_id: "518e4884402403bc7f6c6157",
            },
            {
              id: "1bc83694402403da5111615b",
              display: "USB Bluetooth Adapters",
              type: "cf",
              message_id: "electronics_networking_usb_bluetooth_adapters",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "USB_Bluetooth_Adapters",
              alias_slugs: [],
              parent_id: "518e4884402403bc7f6c6157",
            },
            {
              id: "1cc83694402403da5111615b",
              display: "USB Wi-Fi Adapters",
              type: "cf",
              message_id: "electronics_networking_usb_wifi_adapters",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "USB_Wi_Fi_Adapters",
              alias_slugs: [],
              parent_id: "518e4884402403bc7f6c6157",
            },
            {
              id: "1dc83694402403da5111615b",
              display: "VoIP Home Phones",
              type: "cf",
              message_id: "electronics_networking_voip_home_phones",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "VoIP_Home_Phones",
              alias_slugs: [],
              parent_id: "518e4884402403bc7f6c6157",
            },
            {
              id: "1ec83694402403da5111615b",
              display: "VoIP Phone Adapters",
              type: "cf",
              message_id: "electronics_networking_voip_phone_adapters",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "VoIP_Phone_Adapters",
              alias_slugs: [],
              parent_id: "518e4884402403bc7f6c6157",
            },
            {
              id: "1fc83694402403da5111615b",
              display: "Wired Routers",
              type: "cf",
              message_id: "electronics_networking_wired_routers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Wired_Routers",
              alias_slugs: [],
              parent_id: "518e4884402403bc7f6c6157",
            },
            {
              id: "20c83694402403da5111615b",
              display: "Wireless Access Points",
              type: "cf",
              message_id: "electronics_networking_wireless_access_points",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Wireless_Access_Points",
              alias_slugs: [],
              parent_id: "518e4884402403bc7f6c6157",
            },
            {
              id: "21c83694402403da5111615b",
              display: "Wireless Routers",
              type: "cf",
              message_id: "electronics_networking_wireless_routers",
              aliases: [],
              size_sets: [],
              sizes: [],
              slug: "Wireless_Routers",
              alias_slugs: [],
              parent_id: "518e4884402403bc7f6c6157",
            },
          ],
        },
        {
          id: "548e4884402403bc7f6c6157",
          display: "Other",
          type: "c",
          size_sets: [
            {
              id: "59c83694402403da5111615b",
              name: "Standard",
              tags: ["standard"],
              category_id: "548e4884402403bc7f6c6157",
              measurements_chart: {
                inches: [],
                centimeters: [],
                size_systems: [["us", "au", "eu", "uk"]],
              },
              size_chart: [
                ["us", "au", "eu", "uk"],
                ["One Size", "One Size", "One Size", "One Size"],
              ],
              size_systems: {
                us: {
                  sizes: [
                    {
                      id: "OS",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "US OS",
                      display_with_system_and_set: "US OS",
                      equivalent_sizes: {
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "us",
                    },
                  ],
                },
                au: {
                  sizes: [
                    {
                      id: "OSAU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "AU OS",
                      display_with_system_and_set: "AU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "au",
                    },
                  ],
                },
                eu: {
                  sizes: [
                    {
                      id: "OSEU",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "EU OS",
                      display_with_system_and_set: "EU OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        uk: ["OSUK"],
                      },
                      size_system: "eu",
                    },
                  ],
                },
                uk: {
                  sizes: [
                    {
                      id: "OSUK",
                      short: "OS",
                      long: "One Size",
                      display: "One Size",
                      display_with_size_set: "OS",
                      display_with_size_system: "UK OS",
                      display_with_system_and_set: "UK OS",
                      equivalent_sizes: {
                        us: ["OS"],
                        au: ["OSAU"],
                        eu: ["OSEU"],
                        uk: ["OSUK"],
                      },
                      size_system: "uk",
                    },
                  ],
                },
              },
            },
          ],
          message_id: "electronics_other",
          aliases: [],
          slug: "Other",
          alias_slugs: [],
          parent_id: "583c7d134024035188906153",
        },
      ],
    },
  ];

  export default departments;